import {all, put, takeEvery} from "@redux-saga/core/effects";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/tariff/scale";

const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST";
const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS";
const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR";

const initialState = {
    list: [],
    card: {},
    progress: false,
    progressCard: false,
    totalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS:
            return {
                ...state,
                scale: payload,
            };
        default: return state;
    }
};

export const getTariffScaleRateByObjectAddress = (payload) => ({
    type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST,
    payload,
});

//POST /api/tariff/scale/getRate
export const getTariffScaleRateSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/getRateByObjectAddress`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST, getTariffScaleRateSaga),
    ]);
}