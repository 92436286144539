import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";
import OrderDropdown from "../../../../../../../components/OrderDropdown";

import {useSettingsDirectoriesBanksBalancesEditModalForm} from "./hooks/useForm";

import {COLOR} from "../../../../../../../constants/color";

import {
    settingsDirectoriesBanksBalancesBanksOptionsSelector,
    settingsDirectoriesBanksBalancesProgressActionSelector,
} from "../../../../../../../ducks/bff/settings/directories/naimix/banks/selectors";

export const SettingsDirectoriesBanksBalancesEditModal = (props) => {
    const {
        isEdit,
        editData = {},
        onClose = () => {},
        fetchList = () => {},
    } = props;

    const progress = useSelector(settingsDirectoriesBanksBalancesProgressActionSelector);
    const bankOptions = useSelector(settingsDirectoriesBanksBalancesBanksOptionsSelector);

    const {
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = useSettingsDirectoriesBanksBalancesEditModalForm({
        isEdit,
        editData,
        onClose,
        fetchList,
    });

    const renderDropdownAdditionalContent = () => {
        return (
            <div className="p-4">
                <Text
                    type="text"
                    level="3"
                    color={COLOR.SECONDARY_75}
                >
                    Если вам не подходит ни одно из представленного наименования банков, вы можете добавить свое. Для
                    этого просто введите наименование банка в поле и нажмите Enter для добавления.
                </Text>
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактировать значение баланса" : "Добавить значение баланса"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                <OrderDropdown
                    disabled={isEdit}
                    required={true}
                    search={true}
                    label="Наименование банка"
                    name="bankBalanceId"
                    placeholder="Выберите или введите наименование банка"
                    options={bankOptions}
                    onChange={handleChange}
                    value={values.bankBalanceId}
                    valueWhichNotInOptions={values.bankName}
                    saveSpecialityByEnter={(bankName) => {
                        setFieldValue("bankName", bankName);
                    }}
                    error={
                        touched.bankName && errors.bankName
                    }
                    additionalContent={renderDropdownAdditionalContent()}
                />
                <AmountInput
                    newInput={true}
                    required={true}
                    maskChar={null}
                    size="xl"
                    label="Баланс, ₽"
                    placeholder="Введите значение баланса"
                    name="balance"
                    onChange={handleChange}
                    value={values.balance}
                    error={touched.balance && errors.balance}
                />
                <NmDatePicker
                    required={true}
                    showTimeSelect={true}
                    isClearable={true}
                    size="xl"
                    label="Последние изменения"
                    timeCaption="время"
                    timeIntervals={1}
                    placeholderText="дд.мм.гг чч.мм"
                    dateFormat="dd.MM.yy HH:mm"
                    dateFormatMask="99.99.99 99:99"
                    name="lastUpdateDatetime"
                    onChange={handleChange}
                    selected={values.lastUpdateDatetime}
                    error={touched.lastUpdateDatetime && errors.lastUpdateDatetime}
                />
            </NmForm>
        </NmModal>
    );
};