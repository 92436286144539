import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import Box from "../../../../../components/ActualComponents/Box";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmReadonlyRating from "../../../../../components/NmReadonlyRating";
import NmTitle from "../../../../../components/NmTitle";

import {handleFormString} from "../../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {addReviewsClientReply, updateReviewsClientReply} from "../../../../../ducks/bff/reviews/client/actionCreators";
import {reviewsClientProgressActionSelector} from "../../../../../ducks/bff/reviews/client/selectors";
import {updateReviewsContractorReply} from "../../../../../ducks/bff/reviews/contractor/actionCreators";

const ClientReviewReplyEditModal = (props) => {
    const {
        review,
        onClose,
        fetchList,
        title,
        isEdit = false,
        contractorScore = false,
        data,
        openSuccessConfirm,
    } = props;

    const progress = useSelector(reviewsClientProgressActionSelector);

    const dispatch = useDispatch();

    const reviewRating =  contractorScore
        ? review?.baseModel?.workQualityScore
        : review?.baseModel?.score;

    const onSubmit = () => {
        const reqData = {
            reviewId: review?.baseModel?.id,
            text: handleFormString(values.text),
            onSuccess: () => {
                if (openSuccessConfirm) {
                    openSuccessConfirm();
                }

                if (fetchList) {
                    fetchList();
                }

                onClose();
            },
        };

        if (!isValid) {
            return;
        }

        if (isEdit && contractorScore) {
            dispatch(updateReviewsContractorReply(reqData));

            return;
        }

        if (isEdit) {
            dispatch(updateReviewsClientReply(reqData));

            return;
        }

        dispatch(addReviewsClientReply(reqData));
    };

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        initialValues: {
            text: data?.text || "",
        },
        validationSchema: yup.object().shape({
            text: yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .max(1000 , "Не более 1000 символов"),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile={true}
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                <Box
                    theme="list"
                    className="col-16"
                >
                    <NmListCard
                        noDivider={true}
                        primaryHeader={
                            contractorScore
                                ? "Отзыв компании"
                                : "Отзыв исполнителя"
                        }
                        labels={[
                            {
                                label: "ФИО",
                                text: review?.contractor?.fioOrFullName || "-",
                            },
                            {
                                label: "Оценка",
                                text: <NmReadonlyRating
                                    rating={reviewRating || 0}
                                    maxRating={5}
                                />,
                            },
                            {
                                label: "Текст отзыва",
                                text: review?.baseModel?.reviewText || "-",
                            },
                        ]}
                        classNameMainContent="col-16"
                    />
                </Box>
                <Text level="4">
                    Напишите ответ на отзыв исполнителя
                </Text>
                <NmTextareaV2
                    placeholder="Введите ответ"
                    name="text"
                    maxLength={1000}
                    minRows={3}
                    value={values.text}
                    error={touched.text && errors.text}
                    onChange={(event, {name, value}) => {
                        setFieldValue(name, value);
                    }}
                />
            </NmForm>
        </NmModal>
    );
};

export default ClientReviewReplyEditModal;