import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/common/naimix/job";

//POST /bff/adm/common/naimix/job/update-all-bank-status
const paymentsUpdateAllBankStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/update-all-bank-status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
            payload: error.message,
        });
    }
};

//GET /bff/adm/common/naimix/job/get-running-update-all-bank-status-task-id
const getRunningPaymentsUpdateAllBankStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.get(`${controller}/get-running-update-all-bank-status-task-id`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
            payload: error.messages,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST, paymentsUpdateAllBankStatusSaga),
        takeEvery(GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST, getRunningPaymentsUpdateAllBankStatusSaga),
    ]);
}