import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_TASK_GROUPS_ADD_ERROR,
    BFF_CROWD_TASK_GROUPS_ADD_REQUEST,
    BFF_CROWD_TASK_GROUPS_ADD_SUCCESS,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
    BFF_CROWD_TASK_GROUPS_DELETE_REQUEST,
    BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
    BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/groups",
        client: "/client-adm/crowd-tasks/groups",
    });
};

const getPageCrowdTaskGroups = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
        });
    }
};

const addCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_ADD_ERROR,
        });
    }
};

const updateCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
        });
    }
};

const deleteCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
        });
    }
};

const archiveCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
        });
    }
};

const unarchiveCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/remove`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST, getPageCrowdTaskGroups),
        takeEvery(BFF_CROWD_TASK_GROUPS_ADD_REQUEST, addCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST, updateCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_DELETE_REQUEST, deleteCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST, archiveCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST, unarchiveCrowdTaskGroup),
    ]);
}