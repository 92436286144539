import {
    BFF_PROMOTION_CHANNELS_ADD_REQUEST, BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST,
    BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST,
    UPDATE_PROMOTION_CHANNELS_STORE,
} from "./actions";

export const getPagePromotionChannels = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addPromotionChannel = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_ADD_REQUEST,
        payload,
    };
};

export const getPagePromotionChannelClientsWithPartners = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST,
        payload,
    };
};

export const checkHasPromotionPromotionChannels = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST,
        payload,
    };
};

export const updateStorePromotionChannels = (payload) => {
    return {
        type: UPDATE_PROMOTION_CHANNELS_STORE,
        payload,
    };
};