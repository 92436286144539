import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {kedoDocumentRouteValidationSchema} from "../validation";

import {toastError} from "../../../../../../../utils/toastHelper";
import {getKedoDocumentRouteInitialSignType} from "../utils/getInitialSignType";
import {
    getKedoDocumentRouteEditModalInitialValues,
    getKedoDocumentRouteEditModalInitialValuesStepsItem,
} from "../utils/getInitialValues";
import {getKedoDocumentRouteEditModalRequestData} from "../utils/getRequestData";

import {KEDO_DOCUMENT_ROUTE_STEP_INITIAL_VALUES} from "../constants";

import {
    addFlexKedoDocumentRoutes,
    addKedoDocumentRoutes,
    getKedoDocumentRoutesFullModel,
    updateFlexKedoDocumentRoutes,
    updateKedoDirectoriesStore,
    updateKedoDocumentRoutes,
} from "../../../../../../../ducks/kedo/directories/actionCreators";
import {kedoDirectoriesDocumentRouteDetailsSelector} from "../../../../../../../ducks/kedo/directories/selectors";

import {
    KEDO_DOCUMENT_ROUTE_ACTION_TYPE,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE,
} from "../../../../../../../constants/kedo/document-route";

export const useKedoDocumentRouteEditModalForm = (props) => {
    const {
        clientId,
        editData,
        isEdit,
    } = props;

    const dispatch = useDispatch();

    const {
        details,
        progressDetails,
    } = useSelector(kedoDirectoriesDocumentRouteDetailsSelector);

    useEffect(() => {
        if (editData?.routeId) {
            dispatch(getKedoDocumentRoutesFullModel({
                routeId: editData.routeId,
            }));
        }

        return () => {
            dispatch(updateKedoDirectoriesStore({
                details: {},
            }));
        };
    }, [editData]);

    const initialValues = useMemo(() => {
        return getKedoDocumentRouteEditModalInitialValues({
            isClientPage: Boolean(clientId),
            clientId,
            type: editData?.type,
            ...details,
        });
    }, [details]);

    const clientInitialOption = useMemo(() => {
        return {
            key: details.clientId,
            value: details.clientId,
            text: details.clientName,
        };
    }, [details]);

    const onChange = (_event, {name, value, checked}) => {
        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const onChangeStepTemplates = (index, params) => {
        const stepTemplates = values.stepTemplates.map((step, stepIndex) => {
            if (index !== stepIndex) {
                return step;
            }

            const {
                name,
                value,
                isRootDepartment,
            } = params;

            if (name === "matchParticipantType") {
                return {
                    ...step,
                    ...KEDO_DOCUMENT_ROUTE_STEP_INITIAL_VALUES,
                    [name]: value,
                    signType: getKedoDocumentRouteInitialSignType({
                        actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN,
                        matchParticipantType: value,
                    }),
                };
            }

            if (name === "actionType") {
                return {
                    ...step,
                    [name]: value,
                    signType: getKedoDocumentRouteInitialSignType({
                        actionType: value,
                        matchParticipantType: step.matchParticipantType,
                    }),
                };
            }

            if (name === "kedoDepartmentId") {
                return {
                    ...step,
                    [name]: value,
                    isRootDepartment,
                    departmentRole: isRootDepartment
                        ? KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE.DEPUTY_DEPARTMENT_HEAD
                        : step.departmentRole,
                };
            }

            return {
                ...step,
                [name]: value,
            };
        });

        setFieldValue("stepTemplates", stepTemplates);
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            flexRouteType,
            stepTemplates,
        } = values;

        const matchParticipantTypes = stepTemplates.map(item => item.matchParticipantType);

        if (
            !matchParticipantTypes.some(item => {
                return [
                    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.CURRENT_USER,
                    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.ROOT_DEPARTMENT_HEAD_OR_MCHD_USER,
                ].includes(item);
            })
        ) {
            toastError("Добавьте в маршрут участника с типом \"Сотрудник\" или \"Руководитель компании\"");

            return;
        }

        const reqData = getKedoDocumentRouteEditModalRequestData({
            ...values,
        });

        if (flexRouteType && isEdit) {
            dispatch(updateFlexKedoDocumentRoutes(reqData));

            return;
        }

        if (isEdit) {
            dispatch(updateKedoDocumentRoutes(reqData));

            return;
        }

        if (flexRouteType) {
            dispatch(addFlexKedoDocumentRoutes(reqData));

            return;
        }

        dispatch(addKedoDocumentRoutes(reqData));
    };

    const addStepsItem = () => {
        setFieldValue("stepTemplates", [
            ...values.stepTemplates,
            getKedoDocumentRouteEditModalInitialValuesStepsItem(),
        ]);
    };

    const onDeleteStepsItem = (index) => {
        const value = values.stepTemplates;
        value.splice(index, 1);

        setFieldValue("stepTemplates", value);
    };

    const {
        handleSubmit,
        values,
        touched,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: kedoDocumentRouteValidationSchema(),
        validateOnBlur: false,
    });

    return {
        errors,
        handleSubmit,
        values,
        onChange,
        touched,
        onChangeStepTemplates,
        onDeleteStepsItem,
        addStepsItem,
        progressDetails,
        clientInitialOption,
    };
};