import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import Filter, {FILTER} from "../../../../components/ActualComponents/Filter";
import NmPage from "../../../../components/NmPage";
import SpecialtyOffer from "../../../../components/SpecialtyOffer";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import bem from "../../../../utils/bem";

import {
    clearFieldsDirectoriesSpecialtyOffer,
    getDirectoriesSpecialtyOfferPage,
} from "../../../../ducks/bff/settings/directories/naimix/specialty-offer/actionCreators";
import {
    settingsDirectoriesNaimixSpecialtyOfferListSelector,
    settingsDirectoriesNaimixSpecialtyOfferTotalCountSelector,
    settingsDirectoriesNaimixSpecialtyOfferTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/specialty-offer/selectors";

export const SpecialtyOfferContainer = () => {
    const offers = useSelector(settingsDirectoriesNaimixSpecialtyOfferListSelector);
    const totalCount =  useSelector(settingsDirectoriesNaimixSpecialtyOfferTotalCountSelector);
    const totalPages =  useSelector(settingsDirectoriesNaimixSpecialtyOfferTotalPagesSelector);

    const dispatch = useDispatch();

    const [block ] = bem("specialty-offer-container");

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {valueFilter: ""},
        pageSize,
        setPagination,
    });

    useEffect(() => {
        return () => {
            dispatch(clearFieldsDirectoriesSpecialtyOffer());
        };
    }, []);

    useEffect(() => {
        dispatch(getDirectoriesSpecialtyOfferPage({
            clientIdFilter: filterData.clientIdFilter,
            pageNum,
            pageSize,
        }));
    }, [pageNum, pageSize, filterData]);

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Заказчик",
                        placeholder: "Не выбрано",
                        name: "clientIdFilter",
                        isClearable: true,
                        isBff: true,
                        search: true,
                    },
                ],
            },
        ];
    }, []);

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            typeFilter="vertical"
            className={block()}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            showHeaderBlock={false}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            onPaginationChange={onPaginationChange}
        >
            <SpecialtyOffer
                isSearch={isSearch}
                list={offers}
                totalCount={totalCount}
            />
        </NmPage>
    );
};
