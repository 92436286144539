import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const contractorFinanceBalanceSelector = state => state.bff.contractorCardFinanceBalance;
export const getContractorFinanceBalanceTotalPagesSelector = createSelector(contractorFinanceBalanceSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getContractorFinanceBalanceTotalCountSelector = createSelector(contractorFinanceBalanceSelector, ({totalCount}) => totalCount);
export const getContractorFinanceBalanceListSelector = createSelector(contractorFinanceBalanceSelector, ({list}) => list);
export const getContractorFinanceBalanceProgressSelector = createSelector(contractorFinanceBalanceSelector, ({progress}) => progress);
export const getContractorFinanceBalanceProgressActionSelector = createSelector(contractorFinanceBalanceSelector, ({progressAction}) => progressAction);
export const getContractorBalanceSelector = createSelector(contractorFinanceBalanceSelector, ({contractorBalance}) => contractorBalance);