import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    getCountAggregationCrowdTasksAnalytic,
    getPageCrowdTasksAnalyticAtWork,
    getPageCrowdTasksAnalyticHired,
    getPageCrowdTasksAnalyticInProcessPayment,
    getPageCrowdTasksAnalyticNoResponses,
    getPageCrowdTasksAnalyticsOnContractSigning,
    getPageCrowdTasksAnalyticsOnVerification,
    updateStoreCrowdTasksAnalytics,
} from "../../../../ducks/bff/crowd/tasksAnalytics/actionCreators";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

import {SUB_PAGE_CROWD_TASK_ANALYTICS} from "../../../../constants/link-params";

const useCrowdTaskAnalyticsFetch = (props) => {
    const {
        clientId,
        subpage,
        pageNum,
        pageSize,
        filter,
    } = props;

    const [sortType, setSortType] = useState("ASC");

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.addCrowdPayments,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTaskAnalytics,
            ],
        }));

        return () => {
            dispatch(updateStoreCrowdTasksAnalytics({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, subpage, sortType, filter]);

    useEffect(() => {
        fetchCounters();
    }, [filter, subpage]);

    const fetchMethod = useMemo(() => {
        switch (subpage) {
            case SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK:
                return getPageCrowdTasksAnalyticNoResponses;
            case SUB_PAGE_CROWD_TASK_ANALYTICS.SIGN_CONTRACT.LINK:
                return getPageCrowdTasksAnalyticsOnContractSigning;
            case SUB_PAGE_CROWD_TASK_ANALYTICS.HIRED.LINK:
                return getPageCrowdTasksAnalyticHired;
            case SUB_PAGE_CROWD_TASK_ANALYTICS.IN_WORK.LINK:
                return getPageCrowdTasksAnalyticAtWork;
            case SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK:
                return getPageCrowdTasksAnalyticsOnVerification;
            case SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK:
                return getPageCrowdTasksAnalyticInProcessPayment;
            default:
                return () => {};
        }
    }, [subpage]);

    const fetchCounters = () => {
        dispatch(getCountAggregationCrowdTasksAnalytic({
            clientIdFilter: clientId,
            ...filter,
        }));
    };

    const fetchList = () => {
        dispatch(fetchMethod({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            sortType,
            ...filter,
        }));
    };

    const fetchInfo = () => {
        fetchCounters();
        fetchList();
    };

    return {
        fetchInfo,
        sortType,
        setSortType,
    };
};

export default useCrowdTaskAnalyticsFetch;