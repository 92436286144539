import {
    BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    progressList: false,
    progressAction: false,
    error: {},
    foremanContractorsRichList: [],
    totalCount: 0,
    totalAmount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS:
            const {totalCount, totalAmount, payments} = payload;

            return {
                ...state,
                list: payments,
                progressList: false,
                totalCount,
                totalAmount,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST:
            return {
                ...state,
                progressList: true,
                pageData: payload,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS:
            return {
                ...state,
                foremanContractorsRichList: payload,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR:
            return {
                ...state,
                confirmTextDuplicateForemanPayment: payload,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS:
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS:
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST:
        case BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};