import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "adm/client-user-restrictions";

//*  TYPES  *//

const GET_CLIENT_USER_RESTRICTIONS_LIST_REQUEST = "GET_CLIENT_USER_RESTRICTIONS_LIST_REQUEST";
const GET_CLIENT_USER_RESTRICTIONS_LIST_SUCCESS = "GET_CLIENT_USER_RESTRICTIONS_LIST_SUCCESS";
const GET_CLIENT_USER_RESTRICTIONS_LIST_ERROR = "GET_CLIENT_USER_RESTRICTIONS_LIST_ERROR";

const GET_CLIENT_USER_RESTRICTIONS_ALL_REQUEST = "GET_CLIENT_USER_RESTRICTIONS_ALL_REQUEST";
const GET_CLIENT_USER_RESTRICTIONS_ALL_SUCCESS = "GET_CLIENT_USER_RESTRICTIONS_ALL_SUCCESS";
const GET_CLIENT_USER_RESTRICTIONS_ALL_ERROR = "GET_CLIENT_USER_RESTRICTIONS_ALL_ERROR";

const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST";
const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS";
const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR";

const UPDATE_CLIENT_USER_RESTRICTIONS__FIELD = "UPDATE_CLIENT_USER_RESTRICTIONS__FIELD";

//*  INITIAL STATE  *//

const initial = {
    currentUserRestrictions: [],
    allRestrictions: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CLIENT_USER_RESTRICTIONS_LIST_SUCCESS:
            const {
                reqList,
                resultList,
            } = payload;

            return {
                ...state,
                currentUserRestrictions: state.currentUserRestrictions
                    .filter(item => !reqList.includes(item))
                    .concat(Object.values(resultList)),
            };
        case GET_CLIENT_USER_RESTRICTIONS_ALL_SUCCESS:
        case GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS:
            return {
                ...state,
                allRestrictions: payload || [],
            };
        case UPDATE_CLIENT_USER_RESTRICTIONS__FIELD:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientUserRestrictionsList(payload) {
    return {
        type: GET_CLIENT_USER_RESTRICTIONS_LIST_REQUEST,
        payload,
    };
}

export function getClientUserRestrictionsAll() {
    return {
        type: GET_CLIENT_USER_RESTRICTIONS_ALL_REQUEST,
    };
}

export function getClientUserRestrictionsByRole(payload) {
    return {
        type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST,
        payload,
    };
}

export function updateFieldClientUserRestrictions(payload) {
    return {
        type: UPDATE_CLIENT_USER_RESTRICTIONS__FIELD,
        payload,
    };
}

//*  SELECTORS  *//

const clientUserRestrictionsSelector = state => state.clientUserRestrictions;
export const currentUserRestrictionsSelector = createSelector(clientUserRestrictionsSelector, ({currentUserRestrictions}) => currentUserRestrictions);
export const currentUserRestrictionsAllOptionsSelector = createSelector(clientUserRestrictionsSelector, ({allRestrictions}) => {
    return allRestrictions.map(({variable, name}) => {
        return {
            key: variable,
            text: name,
            value: variable,
        };
    },
    );
});

//*  SAGA  *//

//GET /bff/adm/client-user-restrictions/get
const getClientUserRestrictionsListSaga = function* ({payload}) {
    try {
        const {
            restrictions = [],
        } = payload;

        const _restrictions = restrictions.map(item => `restrictions=${item}`).join("&");

        const {errorMessage, ...result} = yield request.bff.get(`${controller}/get?${_restrictions}`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_USER_RESTRICTIONS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_USER_RESTRICTIONS_LIST_SUCCESS,
            payload: {
                reqList: restrictions,
                resultList: result,
            },
        });
    } catch (error) {
        console.error(error);
        yield put({type: GET_CLIENT_USER_RESTRICTIONS_LIST_ERROR, payload: error});
    }
};

//GET /bff/adm/client-user-restrictions/get-all
const getClientUserRestrictionsAllSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/get-all`);

        yield put({type: GET_CLIENT_USER_RESTRICTIONS_ALL_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: GET_CLIENT_USER_RESTRICTIONS_ALL_ERROR, payload: error});
    }
};

//GET /bff/adm/client-user-restrictions/get-by-role
const getClientUserRestrictionsByRoleSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${controller}/get-by-role`, {params});

        yield put({type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_USER_RESTRICTIONS_LIST_REQUEST, getClientUserRestrictionsListSaga),
        takeEvery(GET_CLIENT_USER_RESTRICTIONS_ALL_REQUEST, getClientUserRestrictionsAllSaga),
        takeEvery(GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST, getClientUserRestrictionsByRoleSaga),
    ]);
}