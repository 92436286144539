import {
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENTS_STORE,
} from "./actions";

export function getPageSettingsClientGroupsCardClients(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsClientGroupsCardClients(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_REQUEST,
        payload,
    };
}

export function deleteSettingsClientGroupsCardClients(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_REQUEST,
        payload,
    };
}

export const updateStoreSettingsClientGroupsClients = (payload) => {
    return {
        type: UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENTS_STORE,
        payload,
    };
};