export const BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST = "BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST";
export const BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS = "BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS";
export const BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR =  "BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR";

export const BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST = "BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST";
export const BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS = "BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS";
export const BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR =  "BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR";

export const BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST = "GET_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST";
export const BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS = "GET_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS";
export const BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR = "GET_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR";

export const BFF_FOREMEN_REQUESTS_CLEAR_STORE = "BGG_FOREMEN_REQUESTS_CLEAR_STORE";