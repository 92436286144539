import React from "react";

import HelpTooltip, {IHelpTooltip} from "../HelpTooltip";
import Text, {IText} from "../Text";

interface TextWithTooltipProps extends IText {
    tooltipProps: IHelpTooltip
}

const TextWithTooltip = (props: TextWithTooltipProps) => {
    const {
        tooltipProps,
        ...otherProps
    } = props;

    return (
        <div className="d-flex align-items-center">
            <Text {...otherProps} />
            {
                tooltipProps &&
                <HelpTooltip
                    className="ms-1"
                    info={true}
                    hover={true}
                    position="bottom-left"
                    {...tooltipProps}
                />
            }
        </div>
    );
};

export default TextWithTooltip;