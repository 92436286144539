import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useKedoDepartments} from "../../../../components/KedoDepartmentsDropdown/hooks/useDepartments";
import {usePositions} from "../../../../components/PositionsDropdown/hooks/usePositions";
import {useSubdivisions} from "../../../../components/SubdivisionsDropdown/hooks/useSubdivisions";
import {useFilter} from "../../../../hooks/useFilter";

import {
    bffCommonDictsKedoRoleDictOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoStaffRegistryFilters = (params) => {
    const {
        pageSize,
        setPagination,
        clientId,
    } = params;

    const subdivisions = useSubdivisions({clientId});
    const positions = usePositions({clientId});
    const departments = useKedoDepartments({
        clientId,
    });
    const roleOptions = useSelector(bffCommonDictsKedoRoleDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО сотрудника",
                        placeholder: "Введите ФИО",
                        name: "fioFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "innFilter",
                        label: "ИНН",
                        placeholder: "Введите ИНН",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "snilsFilter",
                        label: "СНИЛС",
                        placeholder: "Введите СНИЛС",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        placeholder: "Номер телефона",
                        label: "Номер телефона",
                        name: "phoneFilter",
                        mask: "+7 (999) 999 9999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        size: "lg",
                        label: "E-mail",
                        placeholder: "Введите email",
                        name: "emailFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Логин",
                        placeholder: "Введите логин сотрудника",
                        name: "loginFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: roleOptions,
                        name: "rolesFilter",
                        label: "Роль",
                        direction: "top",
                        strongDirection: true,
                        placeholder: "Выберите роль",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: [],
                        name: "positionIdsFilter",
                        label: "Должность",
                        placeholder: "Выберите должность",
                        direction: "top",
                        strongDirection: true,
                        ...positions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        name: "subdivisionIdsFilter",
                        direction: "top",
                        strongDirection: true,
                        ...subdivisions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        name: "kedoDepartmentIds",
                        direction: "top",
                        strongDirection: true,
                        ...departments,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.CHECKBOX,
                        label: "Может подписывать документы УКЭП компании",
                        name: "ukepAvailableFilter",
                    },
                ],
            },
        ];
    }, [
        roleOptions,
        positions.options,
        subdivisions.options,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};