import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getKedoStaffFioPage,
    updateCommonPaymentDicts,
} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoStaffFioOptionsSelector,
} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoStaffDropdown = (params) => {
    const {
        clientId,
        excludeRootDepartmentHead,
        sortType,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(bffCommonDictsKedoStaffFioOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                kedoStaffFioList: [],
            }));
        };
    }, []);

    const fetchList = (fioFilter) => {
        dispatch(getKedoStaffFioPage({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            fioFilter: handleFormString(fioFilter),
            archiveFilter: false,
            excludeRootDepartmentHead,
            sortType,
        }));
    };

    const onSearchChange = (nameFilter) => {
        fetchList(nameFilter);
    };

    return {
        options,
        label: "ФИО сотрудника",
        placeholder: "Выберите сотрудника",
        onSearchChange,
        search: true,
    };
};