import {
    REVIEWS_CLIENT_CLEAR_STORE,
    REVIEWS_CLIENT_CONFIRM_REQUEST,
    REVIEWS_CLIENT_DECLINE_REQUEST,
    REVIEWS_CLIENT_DELETE_REQUEST,
    REVIEWS_CLIENT_GET_COUNT_REQUEST,
    REVIEWS_CLIENT_GET_PAGE_REQUEST,
    REVIEWS_CLIENT_REPLY_ADD_REQUEST,
    REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST,
    REVIEWS_CLIENT_REPLY_UPDATE_REQUEST,
    REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST,
    REVIEWS_CLIENT_UPDATE_REQUEST,
} from "./actions";

export const getPageReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCountReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_GET_COUNT_REQUEST,
        payload,
    };
};

export const declineReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_DECLINE_REQUEST,
        payload,
    };
};

export const confirmReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_CONFIRM_REQUEST,
        payload,
    };
};

export const updateReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_UPDATE_REQUEST,
        payload,
    };
};


export const deleteReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_DELETE_REQUEST,
        payload,
    };
};

export const updateReviewsClientReply = (payload) => {
    return {
        type: REVIEWS_CLIENT_REPLY_UPDATE_REQUEST,
        payload,
    };
};

export const updateStatusReviewsClientReply = (payload) => {
    return {
        type: REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST,
        payload,
    };
};

export const addReviewsClientReply = (payload) => {
    return {
        type: REVIEWS_CLIENT_REPLY_ADD_REQUEST,
        payload,
    };
};

export const sendComplaintReviewsClient = (payload) => {
    return {
        type: REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST,
        payload,
    };
};

export const clearReviewsClientStore = () => {
    return {
        type: REVIEWS_CLIENT_CLEAR_STORE,
    };
};