import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/actregistry/entries",
        client: "/client-adm/crowd-tasks/actregistry/entries",
    });
};

const getPageCrowdActRegistryEntry = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
        });
    }
};

const getPageErrorsCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page/short/validation/failed`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
        });
    }
};

const createCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/create`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
        });
    }
};

const editCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/edit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
        });
    }
};

const getByIdCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            entryId,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${entryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
        });
    }
};

const deleteCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            entryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${entryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
        });
    }
};

const massDeleteCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/mass/delete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
        });
    }
};

const checkDuplicateCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/check/duplicates`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
        });
    }
};

const validationCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/validation/for-registry-run`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
        });
    }
};

const repeatCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/repeat`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
        });
    }
};

const importCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/import/${registryId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
        });
    }
};

const exportCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/export`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST, getPageCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST, createCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST, editCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST, getByIdCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST, deleteCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST, massDeleteCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST, checkDuplicateCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST, getPageErrorsCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST, validationCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST, repeatCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST, importCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST, exportCrowdActRegistry),
    ]);
}