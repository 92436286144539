import {isBefore} from "date-fns";

import {getDateWithoutTime} from "../../../../utils/dateFormat";

/**
 * Валидация для поля "Период выполнения работ",
 * по настройке компании "Период выполнения работ не ранее текущей даты"
 * @param params
 * @returns {boolean}
 */
export const isWorkPeriodNotEarlierCurrentDate = (params) => {
    const {
        workPeriodIsNotEarlierCurrentDate,
    } = params;

    if (!workPeriodIsNotEarlierCurrentDate) {
        return true;
    }

    const {
        workStartDate,
        workEndDate,
    } = params;

    if (
        !workStartDate
        && !workEndDate
    ) {
        return true;
    }

    const today = getDateWithoutTime(new Date());
    const isWorkStartDateBeforeToday = workStartDate && isBefore(getDateWithoutTime(workStartDate), today);
    const isWorkEndDateBeforeToday = workEndDate && isBefore(getDateWithoutTime(workEndDate), today);

    if (
        isWorkStartDateBeforeToday
        || isWorkEndDateBeforeToday
    ) {
        return false;
    }

    return true;
};