import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import DropzoneV2 from "../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCreateOrUpdatePortfolio from "./hooks/useCreateOrUpdatePortfolio";

import {
    deleteContractorPortfolio,
    getContractorPortfolioFile,
} from "../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export default function ContractorPortfolioEditModal(props) {
    const {
        onClose,
        getProfile,
        contractorId,
        editData = {},
    } = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        submit,
        files,
        setFiles,
    } = useCreateOrUpdatePortfolio({contractorId, onClose, getProfile});

    const getFile = () => {
        dispatch(getContractorPortfolioFile({
            portfolioId: editData.portfolioId,
            fileName: editData.fileName,
            contractorId,
            getResult: (file) => {
                if (file) {
                    setFiles([file]);
                }
            },
        }));
    };

    const deleteElem = () => {
        dispatch(deleteContractorPortfolio({
            contractorId,
            portfolioId: editData.portfolioId,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    useEffect(() => {
        if (editData.portfolioId) {
            getFile();
        }
    }, [editData.portfolioId]);

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                    additionalBtnContent={editData.portfolioId ? "Удалить" : null}
                    additionalBtnColor={editData.portfolioId ? "red" : null}
                    onClickAdditional={editData.portfolioId ? deleteElem : null}
                />
            }
            header={
                <NmTitle size="lg">
                    Портфолио
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <DropzoneV2
                    onChange={setFiles}
                    name="file"
                    // error={errors['file']}
                    validFormats="png, jpeg, jpg, doc, docx, pdf"
                    accept=".png, .jpeg, .jpg, .doc, .docx, .pdf"
                    maxSize="25"
                    files={files}
                />
            </NmForm>
        </NmModal>
    );
}