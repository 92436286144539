import {
    BFF_GET_TIMESHEET_METRICS_ERROR,
    BFF_GET_TIMESHEET_METRICS_REQUEST,
    BFF_GET_TIMESHEET_METRICS_SUCCESS,
    BFF_GET_TIMESHEET_PAGE_ERROR,
    BFF_GET_TIMESHEET_PAGE_REQUEST,
    BFF_GET_TIMESHEET_PAGE_SUCCESS,
    BFF_TIMESHEETS_CLEAR_STORE,
} from "./actions";

const initialState = {
    timesheet: {},
    list: [],
    progress: false,
    progressMetrics: false,
    totalCount: 0,
    pageData: {},
    metrics: {},
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_GET_TIMESHEET_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case BFF_GET_TIMESHEET_PAGE_SUCCESS:
            const {
                timeSheets: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case BFF_GET_TIMESHEET_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case BFF_GET_TIMESHEET_METRICS_REQUEST:
            return {
                ...state,
                progressMetrics: true,
            };
        case BFF_GET_TIMESHEET_METRICS_SUCCESS:
            return {
                ...state,
                progressMetrics: false,
                metrics: payload,
            };
        case BFF_GET_TIMESHEET_METRICS_ERROR:
            return {
                ...state,
                progressMetrics: true,
                error: payload,
            };
        case BFF_TIMESHEETS_CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};