import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import TableDiv from "../../../../components/TableDiv";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useSettingsDirectoriesFlashCallAction} from "./hooks/useAction";
import {useSettingsDirectoriesFlashCallFetch} from "./hooks/useFetch";

import bem from "../../../../utils/bem";
import {handleFormString} from "../../../../utils/stringHelper";

import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {settingsDirectoriesFlashCallHeaders} from "../../../../constants/headersTable";
import {ADMIN} from "../../../../constants/roles";

import {
    bffCommonDictsFlashCallListSelector,
    bffCommonDictsFlashCallProgressSelector,
    bffCommonDictsFlashCallTotalCountSelector,
    bffCommonDictsFlashCallTotalPagesSelector,
} from "../../../../ducks/bff/common/dicts/selectors";
import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

import "./style.sass";


const SettingsDirectoriesFlashCall = () => {
    const list = useSelector(bffCommonDictsFlashCallListSelector);
    const totalCount = useSelector(bffCommonDictsFlashCallTotalCountSelector);
    const totalPages = useSelector(bffCommonDictsFlashCallTotalPagesSelector);
    const progress = useSelector(bffCommonDictsFlashCallProgressSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN],
        currentUserRestrictions,
    });

    const [block, element] = bem("settings-directories-flash-call");

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("settings");

    function mapFilterDto(filter) {
        return {
            countryNameFilter: handleFormString(filter.countryNameFilter),
            phoneCodeFilter: handleFormString(filter.phoneCodeFilter),
            callsSupported: filter.callsSupported === EMPTY_OPTION_KEY ? undefined : filter.callsSupported,
        };
    }

    const initFilter = {
        countryNameFilter: "",
        phoneCodeFilter: "",
        callsSupported: EMPTY_OPTION_KEY,
    };

    const {
        onClear,
        onSearch,
        isSearch,
        filterData,
    } = useFilter({
        initFilter,
        mapFilterDto,
        setPagination,
        pageSize,
    });

    const {
        filters,
    } = useSettingsDirectoriesFlashCallFetch({
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        updateCallsSupported,
        progressUpdateItems,
    } = useSettingsDirectoriesFlashCallAction();

    const mapTableData = () => {
        const rows = list.map(item => {
            const {
                codeId,
                code,
                callsSupported,
            } = item;

            return {
                ...item,
                code: code ? `+${code}` : "-",
                callsSupported: <NmHorizontalToggleV2
                    disabled={progressUpdateItems.includes(codeId) || !isAccessEdit}
                    leftLabel="Звонки разрешены"
                    single
                    onChange={(e, {checked}) => {
                        updateCallsSupported(codeId, checked);
                    }}
                    checked={callsSupported}
                    duplex={false}
                />,
            };
        });

        return {
            rows,
            headers: settingsDirectoriesFlashCallHeaders,
        };
    };

    return (
        <NmPage
            overflowUnset
            className={block()}
            header={
                <NmPageHeader
                    text="Звонки через Flash Call"
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    isInitStateEqualEmpty={true}
                />
            }
            noPadding
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
        >
            {
                totalCount > 0 ?
                    <TableDiv
                        className={element("table")}
                        tableData={mapTableData()}
                        isOverflowX={true}
                    /> : <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default SettingsDirectoriesFlashCall;
