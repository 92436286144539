import React, {Component} from "react";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmButton from "../../../components/NmButton";
import NmIcon from "../../../components/NmIcon";
import NmPage from "../../../components/NmPage";
import TableDiv from "../../../components/TableDiv";
import BrigadePaymentsEditModal from "./brigade-payments-edit-modal";
import BrigadePaymentsFilter from "./brigade-payments-filter";

import bem from "../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {replacer} from "../../../utils/replacer";
import {formatAmount, getFullName} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {brigadePaymentsHeaders} from "../../../constants/headersTable";
import {NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";

import {
    confirmClientForemenPaymentsPayment,
    rejectClientForemenPaymentsPayment,
} from "../../../ducks/bff/clients/foremen/payments/actionCreators";
import {
    clientForemenPaymentsListSelector, 
    clientForemenPaymentsProgressActionSelector,
    clientForemenPaymentsProgressListSelector,
    clientForemenPaymentsTotalAmountSelector,
    clientForemenPaymentsTotalCountSelector,
    clientForemenPaymentsTotalPagesSelector,
    confirmTextDuplicateClientForemenPaymentsSelector,
} from "../../../ducks/bff/clients/foremen/payments/selectors";
import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";
import {getPaymentInfo, paymentInfoSelector} from "../../../ducks/financeReport";
import {foremanPaymentStatusesDictSelector, getOrderById, getOrderCardSelector} from "../../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

const STATUS = {
    REJECTED: "REJECTED",
    NEW: "NEW",
    CONFIRMED: "CONFIRMED",
};

export const ACTION_TYPE = {
    CONFIRM: "CONFIRM",
    CONFIRM_WITH_DUPLICATE: "CONFIRM_WITH_DUPLICATE",
    REJECT: "REJECT",
    CONFIRM_LIST: "CONFIRM_LIST",
};

const MESSAGE_BY_ACTION = {
    CONFIRM: "Вы действительно хотите подтвердить выплату :number?",
    REJECT: "Вы действительно хотите отклонить выплату :number?",
    CONFIRM_LIST: "Вы действительно хотите подтвердить выплаты: :number?",
};

export const BRIGADE_PAYMENTS_TOAST_MESSAGE = {
    SUCCESS_REJECT: "Выплата отклонена",
    SUCCESS_CONFIRM: "Выплата подтверждена",
    SUCCESS_CONFIRM_LIST: "Выплаты подтверждены",
    ERROR_UPDATE: "Не удалось обновить выплату",
    SUCCESS_UPDATE: "Сумма выплаты успешно обновлена",
};

class BrigadePayments extends Component {
    static propTypes = {
        pageData: PropTypes.shape({
            pageNum: PropTypes.number,
            pageSize: PropTypes.number,
        }),
        translate: PropTypes.object,
        handlePaginationChange: PropTypes.func,
        handleChangePageSize: PropTypes.func,
        submitFilter: PropTypes.func,
        clientId: PropTypes.string,
    };

    static defaultProps = {
        clientId: "",
    };

    constructor(props) {
        super(props);

        const [block, element] = bem("brigade-payments", props.className);

        this.block = block;
        this.element = element;

        this.state = {
            selectedList: [],
            editData: {},
            selectedSum: 0,
            actionType: "",
            selectedPayments: [],
            confirmData: {},
            message: "",
            description: "",
            classNameConfirm: "",
        };

        this.role = ls(USER_ROLE);
    }

    componentDidUpdate(prevProps) {
        const {list, confirmTextDuplicateForemanPayment} = this.props;
        const {list: _list, confirmTextDuplicateForemanPayment: _confirmTextDuplicateForemanPayment} = prevProps;

        if (list.length && !isEqual(list, _list)) {
            this.setState({
                selectedList: [],
                selectedPayments: [],
                selectedSum: 0,
            });
        }

        if (confirmTextDuplicateForemanPayment !== _confirmTextDuplicateForemanPayment && !isNullOrWhitespace(confirmTextDuplicateForemanPayment)) {
            this.setState({
                message: confirmTextDuplicateForemanPayment,
                isOpenConfirm: true,
                actionType: ACTION_TYPE.CONFIRM_WITH_DUPLICATE,
                classNameConfirm: "brigade-payments__confirm-content",
            });
        }
    };

    fetchOrderAndPaymentInfo = ({orderId, contractorId}) => {
        return () => {
            const {
                getPaymentInfo,
                getOrderById,
                clientId,
            } = this.props;

            getPaymentInfo({
                clientId,
                contractorIdFilter: contractorId,
                orderId,
            });

            getOrderById({
                clientId,
                orderId,
            });
        };
    };

    handleOpenConfirm = (actionType, confirmData) => {
        let message = "";

        if (actionType === ACTION_TYPE.CONFIRM || actionType === ACTION_TYPE.REJECT) {
            const {paymentNumber} = confirmData;

            message = replacer(":number", MESSAGE_BY_ACTION[actionType], paymentNumber);
        }

        if (actionType === ACTION_TYPE.CONFIRM_LIST) {
            const {selectedPayments} = this.state;
            const selectedNumbers = selectedPayments.map(value => value.paymentNumber).join(", ");

            message = replacer(":number", MESSAGE_BY_ACTION[actionType], selectedNumbers);
        }

        this.setState({
            actionType,
            confirmData,
            isOpenConfirm: true,
            message,
        });
    };

    handleOpenEditPopup = (editData) => {
        this.setState({
            isOpenEditPopup: true,
            editData,
        }, this.fetchOrderAndPaymentInfo(editData));
    };

    handleCloseEditPopup = () => {
        this.setState({
            isOpenEditPopup: false,
            editData: {},
            message: "",
            actionType: "",
        });
    };

    renderApproveButton = (status, confirmData, {isDisabled}) => {
        const {translate} = this.props;
        const {buttonConfirm, confirmedText} = translate;
        const className = status === STATUS.CONFIRMED ? this.element("button-approved") : "";

        return (
            <NmButton
                size="md"
                color="light-green"
                disabled={isDisabled}
                className={`${this.element("button")} ${className}`}
                onClick={() => {
                    this.handleOpenEditPopup({
                        ...confirmData,
                        status: STATUS.CONFIRMED,
                    });
                }}
            >
                {status === STATUS.CONFIRMED ? confirmedText : buttonConfirm}
            </NmButton>
        );
    };

    renderRejectButton = (status, confirmData, {isDisabled}) => {
        const {translate} = this.props;
        const {buttonReject, rejectedText} = translate;
        const className = status === STATUS.REJECTED ? this.element("button-rejected") : "";

        return (
            <NmButton
                size="md"
                color="red"
                disabled={isDisabled}
                className={`${this.element("button")} ${className}`}
                onClick={() => {
                    this.handleOpenConfirm(ACTION_TYPE.REJECT, confirmData);
                }}
            >
                {status === STATUS.REJECTED ? rejectedText : buttonReject}
            </NmButton>
        );
    };

    renderAction = (payment) => {
        const {
            currentUserRestrictions,
        } = this.props;

        if(
            [NM_OPERATOR].includes(this.role)
            || currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.confirmPaymentForemans)
        ) {
            return null;
        }
        const {
            status,
            amount,
            paymentNumber,
            clientId,
            contractorId,
            foremanContractorId,
            orderKind,
            orderContractPaymentType,
            orderId,
            blocked,
            orderCreatedByTemplateId,
            actOfAcceptanceOfWorkTemplateId,
        } = payment;

        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        if (isClientArchived) {
            return null;
        }

        const isDisabled = (status !== STATUS.NEW) || (status === STATUS.NEW && this.role === NM_MANAGER);

        return (
            <div className={this.element("actions")}>
                {this.renderApproveButton(status, {
                    amount,
                    paymentNumber,
                    clientId,
                    orderKind,
                    orderContractPaymentType,
                    orderCreatedByTemplateId,
                    actOfAcceptanceOfWorkTemplateId,
                    contractorId,
                    foremanContractorId,
                    orderId,
                }, {
                    isDisabled: isDisabled || blocked,
                })}
                {this.renderRejectButton(status, {
                    clientId,
                    orderId,
                    contractorId,
                    paymentNumber,
                }, {isDisabled})}
            </div>
        );
    };

    getForemanInfo = (payment) => {
        const {
            foremanContractorLastName,
            foremanContractorFirstName,
            foremanContractorPatronymic,
            blocked,
            blockedReason,
        } = payment;

        return (
            <div className={this.element("foreman")}>
                {
                    blocked &&
                    <NmAdvancedTooltip
                        trigger={
                            <NmIcon
                                className="mr-2 brigade-payments__blocked"
                                name="lock"
                            />
                        }
                        hover
                    >
                        {blockedReason}
                    </NmAdvancedTooltip>
                }
                <div className={this.element("foreman-name")}>
                    {`${foremanContractorLastName} ${foremanContractorFirstName} ${foremanContractorPatronymic}`}
                </div>
            </div>
        );
    };

    mapTableData = () => {
        const {
            list,
        } = this.props;
        const {selectedList} = this.state;

        const rows = list.map(payment => {
            const {
                contractorLastName,
                contractorFirstName,
                contractorPatronymic,
                amount = 0,
                operationDate,
                paymentNumber,
                status,
                orderName,
                orderNum,
            } = payment;
            const paymentSelected = selectedList.find(item => (item.paymentNumber === paymentNumber)) || {};
            const {isSelected = false} = paymentSelected;

            return {
                ...payment,
                orderInfo: `${orderNum} - ${orderName}`,
                foremanFullName: this.getForemanInfo(payment),
                contractorFullName: getFullName(contractorLastName, contractorFirstName, contractorPatronymic),
                payAmount: `${formatAmount(amount)} ₽`,
                action: this.renderAction(payment),
                operationDate: formatDate(convertUtcToLocal(operationDate)),
                isSelected,
                showCheckBox: status === STATUS.NEW,
            };
        });

        return {
            headers: brigadePaymentsHeaders,
            rows,
        };
    };

    onSelectedRows = (selectedList, isAllSelected) => {
        const selectedSum = selectedList.filter(value => value.isSelected).reduce((prev, {amount}) => (prev + amount), 0);
        const selectedPayments = selectedList.filter(value => value.isSelected).map(value => ({
            clientId: value.clientId,
            contractorId: value.contractorId,
            orderId: value.orderId,
            paymentNumber: value.paymentNumber,
        }));

        this.setState({
            selectedList,
            isAllSelected,
            selectedSum,
            selectedPayments,
        });
    };

    updateForemanPayment = requestData => {
        const {
            confirmClientForemenPaymentsPayment,
        } = this.props;
        const {
            editData,
        } = this.state;
        const {
            orderKind,
            orderContractPaymentType,
            orderCreatedByTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            ...otherEditData
        } = editData;

        confirmClientForemenPaymentsPayment({
            ...otherEditData,
            ...requestData,
            confirmModalFoundDuplicateUncompletedPayments: false,
            onSuccess: this.handleCloseEditPopup,
        });
        this.setState(prevState => ({
            ...prevState,
            confirmData: {
                ...editData,
                ...requestData,
            },
        }));
    };

    renderEditPopup() {
        const {
            progressAction,
            paymentInfo,
            orderCard,
        } = this.props;

        const {
            isOpenEditPopup,
            editData: {
                amount,
                contractorId,
                clientId,
                orderKind,
                orderCreatedByTemplateId,
                actOfAcceptanceOfWorkTemplateId,
                orderContractPaymentType,
                orderId,
            },
        } = this.state;

        return isOpenEditPopup &&
            <BrigadePaymentsEditModal
                className="brigade-payments__modal"
                paymentInfo={paymentInfo}
                orderCard={orderCard}
                clientId={clientId}
                orderId={orderId}
                orderCreatedByTemplateId={orderCreatedByTemplateId}
                actOfAcceptanceOfWorkTemplateId={actOfAcceptanceOfWorkTemplateId}
                orderContractPaymentType={orderContractPaymentType}
                orderKind={orderKind}
                contractorId={contractorId}
                close={this.handleCloseEditPopup}
                submit={this.updateForemanPayment}
                progressAction={progressAction}
                amount={amount}
            />;
    }

    closeConfirm = () => {
        this.setState({
            isOpenConfirm: false,
            message: "",
            actionType: "",
            confirmData: {},
            classNameConfirm: "",
        });
    };

    submitConfirm = () => {
        const {
            actionType,
            confirmData,
        } = this.state;

        const {
            rejectClientForemenPaymentsPayment,
            confirmClientForemenPaymentsPayment,
        } = this.props;

        switch (actionType) {
            case ACTION_TYPE.REJECT: {
                rejectClientForemenPaymentsPayment({
                    ...confirmData,
                    onSuccess: () => {
                        this.closeConfirm();
                        this.handleCloseEditPopup();
                    },
                });

                return;
            }
            case ACTION_TYPE.CONFIRM: {
                confirmClientForemenPaymentsPayment({
                    ...confirmData,
                    onSuccess: () => {
                        this.closeConfirm();
                        this.handleCloseEditPopup();
                    },
                });

                return;
            }
            case ACTION_TYPE.CONFIRM_WITH_DUPLICATE: {
                confirmClientForemenPaymentsPayment({
                    ...confirmData,
                    confirmModalFoundDuplicateUncompletedPayments: true,
                    onSuccess: () => {
                        this.closeConfirm();
                        this.handleCloseEditPopup();
                    },
                });

                return;
            }
            // no default
        }
    };

    renderConfirmPopup() {
        const {translate} = this.props;
        const {isOpenConfirm, message} = this.state;
        const {buttonYes, buttonNo} = translate;

        return (
            isOpenConfirm &&
            <NmConfirmV2
                size="sm"
                content={message}
                onCancel={this.closeConfirm}
                onConfirm={this.submitConfirm}
                confirmButton={buttonYes}
                cancelButton={buttonNo}
            />
        );
    }

    render() {
        const {
            totalPages,
            handleChangePageSize,
            handlePaginationChange,
            pageData: {pageNum, pageSize},
            submitFilter,
            totalCount,
            progressList,
        } = this.props;

        return (
            <NmPage
                className={this.block()}
                filtersBase={
                    <BrigadePaymentsFilter
                        submitFilter={submitFilter}
                    />
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={handleChangePageSize}
                onPaginationChange={handlePaginationChange}
                classNameContent={this.element("content")}
                totalCount={totalCount}
                showHeaderBlock={false}
                isLoaded={progressList}
            >
                {this.renderConfirmPopup()}
                {this.renderEditPopup()}
                {
                    !(totalCount || progressList) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                        /> :
                        <TableDiv
                            //isCheckBox
                            onSelectedRows={![NM_OPERATOR].includes(this.role) && this.onSelectedRows}
                            className={this.element("table")}
                            tableData={this.mapTableData()}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: clientForemenPaymentsListSelector(state),
        totalPages: clientForemenPaymentsTotalPagesSelector(state),
        totalAmount: clientForemenPaymentsTotalAmountSelector(state),
        progressAction: clientForemenPaymentsProgressActionSelector(state),
        paymentInfo: paymentInfoSelector(state),
        orderCard: getOrderCardSelector(state),
        foremanPaymentStatusesDict: foremanPaymentStatusesDictSelector(state),
        progressList: clientForemenPaymentsProgressListSelector(state),
        totalCount: clientForemenPaymentsTotalCountSelector(state),
        confirmTextDuplicateForemanPayment: confirmTextDuplicateClientForemenPaymentsSelector(state),
        client: clientCardInfoSelector(state),
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        getPaymentInfo,
        getOrderById,
        rejectClientForemenPaymentsPayment,
        confirmClientForemenPaymentsPayment,
    },
)(BrigadePayments);