import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../../constants/page";

import {getKedoStaffFioPage} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoStaffDepartmentDeputyHeadListOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoDepartmentDeputyHead = (params = {}) => {
    const {
        errors,
        touched,
        clientId,
        filterId,
        isFetchAll,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(
        (state) => bffCommonDictsKedoStaffDepartmentDeputyHeadListOptionsSelector(state, filterId),
    );

    return {
        name: "deputyHeadStaffId",
        onSearchChange: (fioFilter) => {
            dispatch(getKedoStaffFioPage({
                pageNum: 1,
                pageSize: PAGE_DEFAULT_SIZE,
                fioFilter: handleFormString(fioFilter),
                clientIdFilter: clientId,
                onlyDepartmentDeputyHead: true,
                isFetchAll,
                archiveFilter: false,
            }));
        },
        search: true,
        options,
        label: "Заместитель руководителя отдела",
        placeholder: "Выберите заместителя",
        error: touched?.deputyHeadStaffId && errors?.deputyHeadStaffId ?
            errors?.deputyHeadStaffId :
            undefined,
    };
};