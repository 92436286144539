import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {clientPhoneMask} from "../../../../utils/clientHelper";
import {phoneFormat, removePhoneMask} from "../../../../utils/stringFormat";
import validate from "../../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {clientCardRule} from "../../../../constants/validationRules";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector,
    getBossInfoSelector,
    getClientTypeSelector,
} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class BossCardBlock extends Component {
    static propTypes = {
        clientType: PropTypes.object,
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        clientType: {},
        isEditable: true,
    };

    constructor() {
        super();

        this.state = {
            isEdit: false,
            errorForm: {},
            clientType: {},
        };
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            bossInfo: _bossInfo,
            isEdit,
        } = state;
        const {bossInfo} = newProps;

        if (
            JSON.stringify(bossInfo) !== JSON.stringify(_bossInfo) &&
            !isEdit
        ) {
            return {
                ...state,
                editBossInfo: {
                    ...bossInfo,
                },
                bossInfo,
            };
        }

        return null;
    }

    isValidEditClient(editClient) {
        const rule = clientCardRule.bossInfo;

        const errorForm = validate(editClient, rule, "");

        this.setState(prevState => ({
            ...prevState,
            errorForm,
        }));

        return Object.values(errorForm).length === 0;
    }

    submitBossInfo = () => {
        const {updateClientCardInfo, bossInfo, client} = this.props;

        const {editBossInfo} = this.state;

        if (JSON.stringify(editBossInfo) === JSON.stringify(bossInfo)) {
            return;
        }

        const requestData = {
            ...client,
            ...editBossInfo,
            [CLIENT_FIELD_NAME.MANAGER_PHONE]: removePhoneMask(
                editBossInfo[CLIENT_FIELD_NAME.MANAGER_PHONE],
            ),
        };

        updateClientCardInfo(requestData);
    };

    toggleCard = () => {
        const {isEdit, editBossInfo} = this.state;

        if (isEdit) {
            const validationData = {
                ...editBossInfo,
                [CLIENT_FIELD_NAME.MANAGER_PHONE]: removePhoneMask(
                    editBossInfo[CLIENT_FIELD_NAME.MANAGER_PHONE],
                ),
            };

            if (!this.isValidEditClient(validationData)) {
                return;
            }
        } else {
            this.setState({
                editBossInfoCopy: editBossInfo,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {this.submitBossInfo();}
            },
        );
    };

    handleChange = (e, {value, mask, name}) => {
        if (mask && !mask.test(value) && value !== "") {return;}

        this.setState(prevState => ({
            ...prevState,
            editBossInfo: {
                ...prevState.editBossInfo,
                [name]: value,
            },
        }));
    };

    cancelEdit = () => {
        const {editBossInfoCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            editBossInfo: editBossInfoCopy,
        }));
    };

    renderCardReadOnly(client) {
        const {t} = this.props;

        return (
            <>
                <LabelTextApp
                    label={t("client-supervisor-info.full-name")}
                    className="client-card-block__label mt-15"
                    text={
                        client[CLIENT_FIELD_NAME.MANAGER_NAME] ||
                        t("is-not-specified.content")
                    }
                />
                <LabelTextApp
                    label={t("client-supervisor-info.phone-number")}
                    className="client-card-block__label mt-15"
                    text={
                        phoneFormat(client[CLIENT_FIELD_NAME.MANAGER_PHONE]) ||
                        t("is-not-specified.content")
                    }
                />
            </>
        );
    }

    renderCardEditMode(client) {
        const {clientTypeName, t} = this.props;
        const {errorForm} = this.state;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-supervisor-info.full-name")}
                            placeholder={t("client-supervisor-info.full-name")}
                            name={CLIENT_FIELD_NAME.MANAGER_NAME}
                            value={client[CLIENT_FIELD_NAME.MANAGER_NAME] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.MANAGER_NAME]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-supervisor-info.phone-number")}
                            value={client[CLIENT_FIELD_NAME.MANAGER_PHONE]}
                            mask={clientPhoneMask[clientTypeName]}
                            placeholder={t("client-supervisor-info.phone-number")}
                            name={CLIENT_FIELD_NAME.MANAGER_PHONE}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.MANAGER_PHONE]}
                        />
                    </div>
                </div>
            </NmForm>
        );
    }

    render() {
        const {isEdit, editBossInfo} = this.state;
        const {
            clientType: {isIndividualClient},
            isEditable,
            t,
        } = this.props;

        if (isIndividualClient) {
            return null;
        }

        return (
            <CardApp
                title={t("client-supervisor-info.client-supervisor-title")}
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEdit}
                isEdit={isEdit}
            >
                {!isEdit ?
                    this.renderCardReadOnly(editBossInfo) :
                    this.renderCardEditMode(editBossInfo)}
            </CardApp>
        );
    }
}

export default connect(state => ({
    bossInfo: getBossInfoSelector(state),
    client: clientCardInfoSelector(state),
    clientTypeName: getClientTypeSelector(state),
}), {
    updateClientCardInfo,
})(withTranslation()(BossCardBlock));