import {filterEmptyValues} from "../../../../../../../utils/objectHelper";
import {handleFormString} from "../../../../../../../utils/stringHelper";

import {KEDO_DOCUMENT_ROUTE_PARTICIPANT_NUMBER_WORDS} from "../../../../../../../constants/kedo/document-route";

export const getKedoDocumentRouteEditModalRequestData = (data) => {
    const {
        flexRouteType,
        isClientPage,
        name,
        nameForClient,
        stepTemplates,
        files,
        ...otherValues
    } = data;

    if (flexRouteType) {
        const model = {
            ...otherValues,
            name: handleFormString(name),
            nameForClient: handleFormString(nameForClient),
        };

        const formData = new FormData();

        formData.append("addUpdateModel", new Blob([JSON.stringify(model)], {
            type: "application/json",
        }));
        formData.append("bpmnSchemaFile", files[0]);

        return {formData};
    }

    return filterEmptyValues({
        ...otherValues,
        name: handleFormString(name),
        nameForClient: handleFormString(nameForClient),
        stepTemplates: stepTemplates.map((item, index) => {
            return {
                actionType: `${item.actionType}_${item.signType}`,
                stepName: `${KEDO_DOCUMENT_ROUTE_PARTICIPANT_NUMBER_WORDS[index]} участник`,
                matchParticipantType: item.matchParticipantType,
                matchParticipantParams: filterEmptyValues({
                    kedoStaffId: item.kedoStaffId,
                    departmentRole: item.departmentRole,
                    kedoDepartmentId: item.kedoDepartmentId,
                }),
            };
        }),
    });
};