import {useDispatch} from "react-redux";

import {getRecruitmentVacancyCandidatesCard} from "../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";

export const useFetchCandidateCard = (params) => {
    const {
        candidateId,
        vacancyId,
    } = params;
    const dispatch = useDispatch();

    const fetchCard = () => {
        dispatch(getRecruitmentVacancyCandidatesCard({
            vacancyId,
            candidateId,
        }));
    };

    return {
        fetchCard,
    };
};