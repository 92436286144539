import {
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
    UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    list: [],
    totalCount: 0,
    progress: false,
    errorsPageData: {},
    errorsList: [],
    errorsTotalCount: 0,
    errorsProgress: false,
    progressCard: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST:
            return {
                ...state,
                errorsPageData: payload,
                errorsProgress: true,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS:
            return {
                ...state,
                errorsList: payload.results,
                errorsTotalCount: payload.totalCount,
                errorsProgress: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR:
            return {
                ...state,
                errorsProgress: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST:
        case BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR:
        case BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR:
        case BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS:
        case BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};