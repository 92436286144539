import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getPageSettingsClientGroupsCardClientUsers} from "./actionCreators";
import {
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_SUCCESS,
} from "./actions";
import {settingsClientGroupsCardClientsPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/client-groups/card/client-user";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsClientGroupsCardClientsPageDataSelector(state);

    yield put(getPageSettingsClientGroupsCardClientUsers(pageData));
};

const getPageSettingsClientGroupsCardClientUsersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addSettingsClientGroupsCardClientUsersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientGroupId,
            requestData,
        } = payload;

        const result = yield request.bff.post(`${controller}/add-to-group/${clientGroupId}`, requestData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Выбранные сотрудники успешно добавлены");

        if (onSuccess) {
            onSuccess();
        }

        yield fetchList();
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteSettingsClientGroupsCardClientUsersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/remove-from-group`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удален");

        if (onSuccess) {
            onSuccess();
        }

        yield fetchList();

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST, getPageSettingsClientGroupsCardClientUsersSaga),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST, addSettingsClientGroupsCardClientUsersSaga),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST, deleteSettingsClientGroupsCardClientUsersSaga),
    ]);
}