import {all, put,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controllerV2 = "v2/specialities";
//*  TYPES  *//

const GET_ALL_SPECIALITIES_V2_REQUEST = "GET_ALL_SPECIALITIES_V2_REQUEST";
const GET_ALL_SPECIALITIES_V2_SUCCESS = "GET_ALL_SPECIALITIES_V2_SUCCESS";
const GET_ALL_SPECIALITIES_V2_ERROR = "GET_ALL_SPECIALITIES_V2_ERROR";

const SPECIALITIES_UPDATE_STORE = "SPECIALITIES_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    progressPage: false,
    progressAction: false,
    totalCount: 0,
    list: [],
    allList: [],
    allListV2: [],
    clientVisibilitySpecialities: [],
    progressAllList: false,
    progressAllListV2: false,
    isSpecialitiesLoaded: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ALL_SPECIALITIES_V2_REQUEST:
            return {
                ...state,
                progressAllListV2: true,
            };
        case GET_ALL_SPECIALITIES_V2_SUCCESS:
            const {
                clientIdVisibilityQualifier,
                result,
            } = payload;


            if (clientIdVisibilityQualifier) {
                return {
                    ...state,
                    clientVisibilitySpecialities: result,
                    progressAllListV2: false,
                };
            }

            return {
                ...state,
                allListV2: result,
                progressAllListV2: false,
            };
        case GET_ALL_SPECIALITIES_V2_ERROR:
            return {
                ...state,
                progressAllListV2: false,
            };
        case SPECIALITIES_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//


export function getSpecialitiesAllV2(payload) {
    return {
        type: GET_ALL_SPECIALITIES_V2_REQUEST,
        payload,
    };
}

export const updateSpecialitiesStore = (payload) => ({
    type: SPECIALITIES_UPDATE_STORE,
    payload,
});

//*  SELECTORS  *//
export const specialitiesSelector = state => state.speciality;
export const specialitiesAllListV2Selector = createSelector(specialitiesSelector, ({allListV2}) => allListV2);

const formatSpecialityOption = (item) => {
    return {
        key: item.id,
        value: item.id,
        text: item.value,
    };
};

export const specialitiesClientVisibilityAllListV2Selector = createSelector(
    specialitiesSelector,
    ({clientVisibilitySpecialities}) => {
        return clientVisibilitySpecialities.map(formatSpecialityOption);
    },
);

export const specialitiesAllV2OptionsSelector = createSelector(
    specialitiesSelector,
    ({allListV2}) => {
        return allListV2.map(formatSpecialityOption);
    },
);

export const specialitiesAllV2DictSelector = createSelector(specialitiesSelector, ({allListV2}) => {
    return allListV2.reduce((result, {id, value}) => {
        result.set(id, value);
        return result;
    }, new Map());
},
);

//*  SAGA  *//

export const getSpecialitiesAllV2Saga = function* ({payload = {}}) {
    try {
        const result = yield request.get(`${controllerV2}/all`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ALL_SPECIALITIES_V2_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ALL_SPECIALITIES_V2_SUCCESS,
            payload: {
                result,
                ...payload,
            },
        });
    } catch (error) {
        yield put({type: GET_ALL_SPECIALITIES_V2_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ALL_SPECIALITIES_V2_REQUEST, getSpecialitiesAllV2Saga),
    ]);
}