import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {clientCurrentMemberSelector} from "./clientMember";
import {getAllTasks} from "./job";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/patents/registry";

//*  TYPES  *//

const PATENTS_REGISTRY_LIST_REQUEST = "PATENTS_REGISTRY_LIST_REQUEST";
const PATENTS_REGISTRY_LIST_SUCCESS = "PATENTS_REGISTRY_LIST_SUCCESS";
const PATENTS_REGISTRY_LIST_ERROR = "PATENTS_REGISTRY_LIST_ERROR";

const GET_PATENT_REGISTRY_BY_ID_REQUEST = "GET_PATENT_REGISTRY_BY_ID_REQUEST";
const GET_PATENT_REGISTRY_BY_ID_SUCCESS = "GET_PATENT_REGISTRY_BY_ID_SUCCESS";
const GET_PATENT_REGISTRY_BY_ID_ERROR = "GET_PATENT_REGISTRY_BY_ID_ERROR";

const ADD_PATENTS_REGISTRY_REQUEST = "ADD_PATENTS_REGISTRY_REQUEST";
const ADD_PATENTS_REGISTRY_SUCCESS = "ADD_PATENTS_REGISTRY_SUCCESS";
const ADD_PATENTS_REGISTRY_ERROR = "ADD_PATENTS_REGISTRY_ERROR";

const UPDATE_PATENTS_REGISTRY_REQUEST = "UPDATE_PATENTS_REGISTRY_REQUEST";
const UPDATE_PATENTS_REGISTRY_SUCCESS = "UPDATE_PATENTS_REGISTRY_SUCCESS";
const UPDATE_PATENTS_REGISTRY_ERROR = "UPDATE_PATENTS_REGISTRY_ERROR";

const ARCHIVE_PATENTS_REGISTRY_REQUEST = "ARCHIVE_PATENTS_REGISTRY_REQUEST";
const ARCHIVE_PATENTS_REGISTRY_SUCCESS = "ARCHIVE_PATENTS_REGISTRY_SUCCESS";
const ARCHIVE_PATENTS_REGISTRY_ERROR = "ARCHIVE_PATENTS_REGISTRY_ERROR";

const RESTORE_PATENTS_REGISTRY_REQUEST = "RESTORE_PATENTS_REGISTRY_REQUEST";
const RESTORE_PATENTS_REGISTRY_SUCCESS = "RESTORE_PATENTS_REGISTRY_SUCCESS";
const RESTORE_PATENTS_REGISTRY_ERROR = "RESTORE_PATENTS_REGISTRY_ERROR";

const PAY_PATENT_REGISTRY_BY_ID_REQUEST = "PAY_PATENT_REGISTRY_BY_ID_REQUEST";
const PAY_PATENT_REGISTRY_BY_ID_SUCCESS = "PAY_PATENT_REGISTRY_BY_ID_SUCCESS";
const PAY_PATENT_REGISTRY_BY_ID_ERROR = "PAY_PATENT_REGISTRY_BY_ID_ERROR";

const PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST";
const PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS";
const PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR";

const CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST = "CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST";
const CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS = "CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS";
const CHECK_PATENT_REGISTRY_DUPLICATES_ERROR = "CHECK_PATENT_REGISTRY_DUPLICATES_ERROR";

const CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST = "CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST";
const CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS = "CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS";
const CREATE_DUPLICATE_PATENT_REGISTRY_ERROR = "CREATE_DUPLICATE_PATENT_REGISTRY_ERROR";

const DELETE_FAILED_PATENT_REGISTRY_REQUEST = "DELETE_FAILED_PATENT_REGISTRY_REQUEST";
const DELETE_FAILED_PATENT_REGISTRY_SUCCESS = "DELETE_FAILED_PATENT_REGISTRY_SUCCESS";
const DELETE_FAILED_PATENT_REGISTRY_ERROR = "DELETE_FAILED_PATENT_REGISTRY_ERROR";

const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST";
const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS";
const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR";

const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST";
const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS";
const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR";

const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST";
const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS";
const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR";

const UPDATE_FIELD_PATENT_REGISTRY_STORE = "UPDATE_FIELD_PATENT_REGISTRY_STORE";


//*  INITIAL STATE  *//

const initial = {
    registries: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    registryStatusDict: {},
    isSuccessAction: false,
    paymentAbilityPatentContractor: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case PATENTS_REGISTRY_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case GET_PATENT_REGISTRY_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case ADD_PATENTS_REGISTRY_REQUEST:
        case UPDATE_PATENTS_REGISTRY_REQUEST:
        case ARCHIVE_PATENTS_REGISTRY_REQUEST:
        case RESTORE_PATENTS_REGISTRY_REQUEST:
        case PAY_PATENT_REGISTRY_BY_ID_REQUEST:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST:
        case CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST:
        case CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST:
        case DELETE_FAILED_PATENT_REGISTRY_REQUEST:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case PATENTS_REGISTRY_LIST_SUCCESS:
            const {
                registries,
                totalCount,
            } = payload;

            return {
                ...state,
                registries,
                progressList: false,
                totalCount,
            };
        case GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS:
            return {
                ...state,
                paymentAbilityPatentContractor: payload,
            };
        case GET_PATENT_REGISTRY_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case ADD_PATENTS_REGISTRY_SUCCESS:
        case UPDATE_PATENTS_REGISTRY_SUCCESS:
        case ARCHIVE_PATENTS_REGISTRY_SUCCESS:
        case RESTORE_PATENTS_REGISTRY_SUCCESS:
        case PAY_PATENT_REGISTRY_BY_ID_SUCCESS:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS:
        case CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS:
        case CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS:
        case DELETE_FAILED_PATENT_REGISTRY_SUCCESS:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
            };
        case PATENTS_REGISTRY_LIST_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        case GET_PATENT_REGISTRY_BY_ID_ERROR:
            return {
                ...state,
                error: payload,
                progressCard: false,
            };
        case ADD_PATENTS_REGISTRY_ERROR:
        case UPDATE_PATENTS_REGISTRY_ERROR:
        case ARCHIVE_PATENTS_REGISTRY_ERROR:
        case RESTORE_PATENTS_REGISTRY_ERROR:
        case PAY_PATENT_REGISTRY_BY_ID_ERROR:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR:
        case CHECK_PATENT_REGISTRY_DUPLICATES_ERROR:
        case CREATE_DUPLICATE_PATENT_REGISTRY_ERROR:
        case DELETE_FAILED_PATENT_REGISTRY_ERROR:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR:
            return {
                ...state,
                error: payload,
                actionRegistryProgress: false,
            };
        case UPDATE_FIELD_PATENT_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};


//*  ACTION CREATORS  *//

export function getPatentsRegistries(payload) {
    return {
        type: PATENTS_REGISTRY_LIST_REQUEST,
        payload,
    };
}

export function addPatentsRegistry(payload) {
    return {
        type: ADD_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function updatePatentsRegistry(payload) {
    return {
        type: UPDATE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function archivePatentsRegistry(payload) {
    return {
        type: ARCHIVE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function restorePatentsRegistry(payload) {
    return {
        type: RESTORE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function getPatentRegistryById(payload) {
    return {
        type: GET_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function payPatentRegistry(payload) {
    return {
        type: PAY_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function payUnpaidPatentRegistry(payload) {
    return {
        type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function checkPatentRegistryPaymentsDuplicates(payload) {
    return {
        type: CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST,
        payload,
    };
}

export function createDuplicatePatentRegistry(payload) {
    return {
        type: CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST,
        payload,
    };
}

export function deleteFailedPatentRegistryPayments(payload) {
    return {
        type: DELETE_FAILED_PATENT_REGISTRY_REQUEST,
        payload,
    };
}

export function getContractorsPaymentAbilityPatent(payload) {
    return {
        type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST,
        payload,
    };
}

export function checkPatentRegistryContractorPresentPayments(payload) {
    return {
        type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST,
        payload,
    };
}

export function updateFieldPatentRegistryStore(payload) {
    return {
        type: UPDATE_FIELD_PATENT_REGISTRY_STORE,
        payload,
    };
}

export function refreshPatentRegistryPaymentsStatus(payload) {
    return {
        type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const patentsRegistriesSelector = ({patentsRegistry}) => patentsRegistry;
export const patentsRegistriesListSelector = createSelector(patentsRegistriesSelector, ({registries}) => registries);
export const patentsRegistriesProgressListSelector = createSelector(patentsRegistriesSelector, ({progressList}) => progressList);
export const patentsRegistriesTotalCountSelector = createSelector(patentsRegistriesSelector, ({totalCount}) => totalCount);
export const patentsRegistriesListTotalPagesSelector = createSelector(patentsRegistriesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const patentsRegistriesActionRegistryProgressSelector = createSelector(patentsRegistriesSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const patentsRegistryCardSelector = createSelector(patentsRegistriesSelector, ({card}) => card);
export const patentsRegistryArchivedParamSelector = createSelector(patentsRegistriesSelector, ({card: {archived = false}}) => archived);
export const patentsRegistriesProgressCardSelector = createSelector(patentsRegistriesSelector, ({progressCard}) => progressCard);
export const patentsRegistryPaymentAbility = createSelector(patentsRegistriesSelector, ({paymentAbilityPatentContractor}) => {
    return paymentAbilityPatentContractor;
});

//*  SAGA  *//

//POST /api/patents/registry/getRegistryPage
export const getPatentsRegistriesSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getRegistryPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_REGISTRY_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_REGISTRY_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PATENTS_REGISTRY_LIST_ERROR, payload: error});
    }
};

//POST /api/patents/registry/addRegistry
export const addPatentsRegistrySaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.post(`${controller}/addRegistry`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_PATENTS_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Реестр успешно добавлен");

        yield put({type: ADD_PATENTS_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_PATENTS_REGISTRY_ERROR, payload: error});
    }
};

//POST /api/patents/registry/updateRegistry
export const updatePatentsRegistrySaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.post(`${controller}/updateRegistry`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_PATENTS_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Реестр успешно изменен");

        yield put({type: UPDATE_PATENTS_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_PATENTS_REGISTRY_ERROR, payload: error});
    }
};

//POST /api/patents/registry/archiveRegistryById
export const archivePatentsRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/archiveRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ARCHIVE_PATENTS_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен в архив");

        const {
            patentsRegistry: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsRegistries(pageData));
        yield put({type: ARCHIVE_PATENTS_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ARCHIVE_PATENTS_REGISTRY_ERROR, payload: error});
    }
};

//POST /api/patents/registry/restoreRegistryById
export const restorePatentsRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/restoreRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: RESTORE_PATENTS_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно восстановлен из архива");

        const {
            patentsRegistry: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsRegistries(pageData));
        yield put({type: RESTORE_PATENTS_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: RESTORE_PATENTS_REGISTRY_ERROR, payload: error});
    }
};

//POST /api/patents/registry/getRegistryById
export const getPatentRegistryByIdSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.post(`${controller}/getRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_PATENT_REGISTRY_BY_ID_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: GET_PATENT_REGISTRY_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PATENT_REGISTRY_BY_ID_ERROR, payload: error});
    }
};

//POST /api/patents/registry/payRegistryById
export const payPatentRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/payRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PAY_PATENT_REGISTRY_BY_ID_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const {
            onSuccess = () => {
            },
        } = payload;

        onSuccess();

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        yield put({type: PAY_PATENT_REGISTRY_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PAY_PATENT_REGISTRY_BY_ID_ERROR, payload: error});
    }
};

//POST /api/patents/registry/payRegistryUnpaidPaymentsById
export const payUnpaidPatentRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/payRegistryUnpaidPaymentsById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const {
            onSuccess = () => {
            },
        } = payload;

        onSuccess();

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        yield put({type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR, payload: error});
    }
};

//POST /api/patents/registry/hasDuplicates
export const checkPatentRegistryPaymentsDuplicatesSaga = function* ({payload}) {
    const {
        data,
        onSuccess,
    } = payload;

    try {
        const result = yield request.post(`${controller}/hasDuplicates`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_PATENT_REGISTRY_DUPLICATES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_PATENT_REGISTRY_DUPLICATES_ERROR, payload: error});
    }
};

//POST /api/patents/registry/createDuplicate
export const createDuplicatePatentRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/createDuplicate`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CREATE_DUPLICATE_PATENT_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен");

        const {
            patentsRegistry: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsRegistries(pageData));

        yield put({type: CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CREATE_DUPLICATE_PATENT_REGISTRY_ERROR, payload: error});
    }
};

//POST /api/patents/registry/deleteFailed
export const deleteFailedPatentRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/deleteFailed`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_FAILED_PATENT_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const {
            onSuccess = () => {
            },
        } = payload;

        onSuccess();

        yield put({type: DELETE_FAILED_PATENT_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_FAILED_PATENT_REGISTRY_ERROR, payload: error});
    }
};

function* getContractorsPaymentAbilityPatentSaga({payload}) {
    try {
        const {
            handleResponse = () => {
            },
            ...reqData
        } = payload;
        const data = yield request.get(`${controller}/getContractorPaymentAbility`, {params: reqData});

        handleResponse(data);

        yield put({
            type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS,
            payload: {
                ...data,
                status: "COMPLETED",
            },
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR,
            payload: error,
        });
    }
}

//POST /api/patents/registry/isContractorPresent
export const checkPatentRegistryContractorPresentPaymentsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;
        const result = yield request.post(`${controller}/isContractorPresent`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR, payload: error});
    }
};

//POST /api/patents/registry/refreshPaymentsUsingHandler
export const refreshPatentRegistryPaymentsStatusSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/refreshPaymentsUsingHandler`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Задача на обновление статусов оплат по патентам успешно запущена");
        yield put({type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(PATENTS_REGISTRY_LIST_REQUEST, getPatentsRegistriesSaga),
        takeEvery(ADD_PATENTS_REGISTRY_REQUEST, addPatentsRegistrySaga),
        takeEvery(UPDATE_PATENTS_REGISTRY_REQUEST, updatePatentsRegistrySaga),
        takeEvery(ARCHIVE_PATENTS_REGISTRY_REQUEST, archivePatentsRegistrySaga),
        takeEvery(RESTORE_PATENTS_REGISTRY_REQUEST, restorePatentsRegistrySaga),
        takeEvery(GET_PATENT_REGISTRY_BY_ID_REQUEST, getPatentRegistryByIdSaga),
        takeEvery(PAY_PATENT_REGISTRY_BY_ID_REQUEST, payPatentRegistrySaga),
        takeEvery(CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST, checkPatentRegistryPaymentsDuplicatesSaga),
        takeEvery(CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST, createDuplicatePatentRegistrySaga),
        takeEvery(DELETE_FAILED_PATENT_REGISTRY_REQUEST, deleteFailedPatentRegistryPaymentsSaga),
        takeEvery(GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST, getContractorsPaymentAbilityPatentSaga),
        takeEvery(CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST, checkPatentRegistryContractorPresentPaymentsSaga),
        takeEvery(REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST, refreshPatentRegistryPaymentsStatusSaga),
        takeEvery(PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST, payUnpaidPatentRegistrySaga),
    ]);
}
