import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";

import Tabs from "../../../components/ActualComponents/Tabs";
import NmLoader from "../../../components/NmLoader";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {KedoOrganizationStructureDepartments} from "./components/departments";
import {KedoOrganizationStructureEdit} from "./components/edit";
import {KedoOrganizationStructureAddFromExcel} from "./components/import-from-file";
import {KedoOrganizationStructureLogs} from "./components/logs";
import {KedoOrganizationStructureStaffRegistry} from "./components/staff-registry";

import {useKedoOrganizationStructureAction} from "./hooks/useAction";

import {getUserRole} from "../../../utils/access";
import {openLinkByUrl} from "../../../utils/downloadBlob";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS,
} from "../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isClientUser, isUserFromNm, NM_MANAGER} from "../../../constants/roles";

import {updateCommonPaymentDicts} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    clearKedoDepartmentsStore,
    getKedoDepartmentCard,
    getRootKedoDepartment,
} from "../../../ducks/kedo/departments/actionCreators";
import {
    kedoDepartmentsCardSelector,
    kedoDepartmentsProgressCardSelector,
    kedoDepartmentsRootSelector,
} from "../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructure = (props) => {
    const {
        match: {
            params: {
                clientId,
                kedoDepartmentId,
            },
            path,
        },
        isRootDepartment = true,
    } = props;

    const dispatch = useDispatch();
    const progressCard = useSelector(kedoDepartmentsProgressCardSelector);

    const {rootDepartmentId} = useSelector(kedoDepartmentsRootSelector);

    const staffRegistryPath = `${path}/staff-registry`;
    const departmentsPath = `${path}/departments`;

    const staffRegistryLink = staffRegistryPath
        .replace(":kedoDepartmentId", kedoDepartmentId)
        .replace(":clientId", clientId);
    const departmentsLink = departmentsPath
        .replace(":kedoDepartmentId", kedoDepartmentId)
        .replace(":clientId", clientId);

    const {
        name = "",
    } = useSelector(kedoDepartmentsCardSelector);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useKedoOrganizationStructureAction();

    useEffect(() => {
        if (kedoDepartmentId) {
            dispatch(getKedoDepartmentCard({
                kedoDepartmentId,
            }));

            return;
        }

        dispatch(getRootKedoDepartment({
            clientId,
        }));
    }, [
        kedoDepartmentId,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearKedoDepartmentsStore());
            dispatch(updateCommonPaymentDicts({
                kedoStaffDepartmentDeputyHeadList: [],
                kedoStaffDepartmentHeadList: [],
            }));
        };
    }, []);

    const role = getUserRole();

    const pageMediaControls = useMemo(() => {
        return {
            renderCount: {
                mobile: 0,
                tablet: isRootDepartment ? 0 : 1,
                desktop: 1,
            },
            size: "xl",
            buttons: [
                isRootDepartment && {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        children: "Добавить отдел",
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                isFetchList: true,
                            });
                        },
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                },
                isRootDepartment && {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Загрузить организационную структуру",
                        onClick: () => {
                            onOpenModal({
                                isFetchList: true,
                                isImportFile: true,
                            });
                        },
                    },
                    visible: [ADMIN, CLIENT_ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать отдел",
                        color: "grey",
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                isFetchList: isRootDepartment,
                                isRootDepartment,
                                isFetchCard: true,
                                kedoDepartmentId: isRootDepartment ? rootDepartmentId : kedoDepartmentId,
                            });
                        },
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                },
                isRootDepartment && {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Лог организационной структуры",
                        onClick: () => {
                            onOpenModal({
                                isOpenLogs: true,
                            });
                        },
                    },
                    visible: isUserFromNm(),
                },
            ].filter(Boolean),
        };
    }, [
        kedoDepartmentId,
        rootDepartmentId,
    ]);

    const getTabs = () => {
        return (
            <Tabs
                secondary
                className="flex-grow-1"
                panes={[
                    {
                        active: departmentsLink,
                        name: "Отделы",
                        link: departmentsLink,
                    },
                    {
                        active: staffRegistryLink,
                        name: "Сотрудники",
                        link: staffRegistryLink,
                    },
                ]}
            />
        );
    };

    const getPage = () => {
        return (
            <NmPage
                noPadding={true}
                hardVisibleFilter={true}
                titleHiddenInMobile={false}
                header={
                    <NmPageHeader
                        text={name}
                        noWrap={false}
                        backLink={
                            !isRootDepartment
                            && LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS.replace(":clientId", clientId)
                        }
                        tooltipText="Высший уровень в иерархии, включает в себя все отделы компании"
                    />
                }
                subHeader={getTabs()}
                mediaControls={pageMediaControls}
            >
                {getEdit()}
                {getLogs()}
                <Switch>
                    <Route
                        exact
                        path={departmentsPath}
                        component={KedoOrganizationStructureDepartments}
                    />
                    <Route
                        exact
                        path={staffRegistryPath}
                        component={KedoOrganizationStructureStaffRegistry}
                    />
                </Switch>
            </NmPage>
        );
    };

    const getLogs = () => {
        if (!modalData?.isOpenLogs) {
            return null;
        }

        return (
            <KedoOrganizationStructureLogs
                clientId={clientId}
                onClose={onCloseModal}
                kedoDepartmentId={kedoDepartmentId}
            />
        );
    };

    const getEdit = () => {
        if (modalData?.isOpenEdit) {
            return (
                <KedoOrganizationStructureEdit
                    isFetchList={modalData.isFetchList}
                    isFetchCard={modalData.isFetchCard}
                    isRootDepartment={modalData.isRootDepartment}
                    kedoDepartmentId={modalData.kedoDepartmentId}
                    onClose={onCloseModal}
                    clientId={clientId}
                />
            );
        }

        if (modalData?.isImportFile) {
            return (
                <KedoOrganizationStructureAddFromExcel
                    isFetchList={modalData.isFetchList}
                    onClose={onCloseModal}
                    clientId={clientId}
                />
            );
        }

        return null;
    };

    if (progressCard) {
        return (
            <NmLoader
                inverted={true}
                active={true}
            />
        );
    }

    if (isClientUser(role)) {
        return (
            <NmPage
                noPadding={true}
                header={
                    <NmPageHeader
                        noWrapTitle={true}
                        text="Организационная структура"
                    />
                }
                mediaControls={{
                    renderCount: {
                        mobile: 0,
                        tablet: 1,
                        desktop: 1,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                size: "xl",
                                color: "light-green",
                                children: "Войти в КЭДО",
                                onClick: () => {
                                    openLinkByUrl(process.env.REACT_APP_NAIMIX_KEDO);
                                },
                            },
                        },
                    ],
                }}
            >
                {getPage()}
            </NmPage>
        );
    }

    return getPage();
};