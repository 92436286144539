import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesNaimixSpecialitiesSelector = state => state.bff.settingsDirectoriesNaimixSpecialities;
export const settingsDirectoriesNaimixSpecialitiesPageDataSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({pageData}) => pageData);
export const settingsDirectoriesNaimixSpecialitiesTotalPagesSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesNaimixSpecialitiesTotalCountSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({totalCount}) => totalCount);
export const settingsDirectoriesNaimixSpecialitiesListSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({list}) => list);
export const settingsDirectoriesNaimixSpecialitiesProgressSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({progress}) => progress);
export const settingsDirectoriesNaimixSpecialitiesProgressActionSelector = createSelector(settingsDirectoriesNaimixSpecialitiesSelector, ({progressAction}) => progressAction);