import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";

import Tabs from "../../../components/ActualComponents/Tabs";
import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import SettingsServiceSystem from "./system-settings";
import SettingsServiceTaskStats from "./task-stats";
import {SettingsServiceTkbTransfers} from "./tkb-transfers";

import {
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_TKB_TRANSFERS,
} from "../../../constants/links";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getTkbPaymentsAllowedEmails, updateInternalPaymentsStore} from "../../../ducks/internalPayments/actionCreators";
import {getInternalPaymentsInitialState} from "../../../ducks/internalPayments/reducer";
import {internalPaymentsAllowedEmailsSelector} from "../../../ducks/internalPayments/selectors";

const SettingsService = () => {
    const dispatch = useDispatch();

    const allowedTkbEmails = useSelector(internalPaymentsAllowedEmailsSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    useEffect(() => {
        dispatch(getTkbPaymentsAllowedEmails());

        return () => {
            const initialState = getInternalPaymentsInitialState();

            dispatch(updateInternalPaymentsStore(initialState));
        };
    }, []);

    return (
        <>
            <Tabs
                secondary={true}
                panes={[
                    {
                        active: LINK_SETTINGS_SERVICE_SETTINGS,
                        link: LINK_SETTINGS_SERVICE_SETTINGS,
                        name: "Сервисные настройки",
                    },
                    {
                        active: LINK_SETTINGS_SERVICE_TASKS_STATS,
                        link: LINK_SETTINGS_SERVICE_TASKS_STATS,
                        name: "График исполнения джобов",
                    },
                    {
                        active: LINK_SETTINGS_TKB_TRANSFERS,
                        link: LINK_SETTINGS_TKB_TRANSFERS,
                        name: "Внутренние переводы ТКБ",
                        isVisible: allowedTkbEmails.includes(currentMember.email),
                    },
                ]}
                className="mb-3 mb-md-4"
            />
            <Switch>
                {
                    allowedTkbEmails.includes(currentMember.email) &&
                    <Route
                        path={LINK_SETTINGS_TKB_TRANSFERS}
                        component={SettingsServiceTkbTransfers}
                    />
                }
                <CheckRoleRoute
                    exact
                    path={LINK_SETTINGS_SERVICE_SETTINGS}
                    component={SettingsServiceSystem}
                />
                <CheckRoleRoute
                    exact
                    path={LINK_SETTINGS_SERVICE_TASKS_STATS}
                    component={SettingsServiceTaskStats}
                />
            </Switch>
        </>
    );
};

export default SettingsService;