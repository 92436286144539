import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import {TICKET_SORT_TYPE} from "../../../constants/crm/ticket";

import {getTicketRichPage, ticketOptionsSelector,updateTicketStore} from "../../../ducks/crm/ticket";

interface IFilterTicket {
    value: [],
    name?: string,
    onChange: (event: any, params: any) => void,
    className?: string,
    isMountLoad?: boolean,
    onSubmit?: () => void,
    label?: string | object,
    defaultFilter?: {},
    phoneFilter?: string,
    clientId?: string,
    size?: "xl" | "lg",
    pageSize?: number
}

const FilterTicket = (props: IFilterTicket) => {
    const {
        label = "Обращение",
        size = "lg",
        pageSize = 1000,
        phoneFilter,
        ...otherProps
    } = props;
    const dispatch = useDispatch();

    const options = useSelector(ticketOptionsSelector);

    useEffect(() => {
        fetch();
        return () => {
            dispatch(updateTicketStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, [phoneFilter]);

    const fetch = () => {
        const reqData = {
            pageSize,
            pageNum: 1,
            orderType: TICKET_SORT_TYPE.RECALL_IN_WORK_MISSED_CLOSED,
            phoneFilter,
        };

        dispatch(getTicketRichPage(reqData));
    };

    return (
        <NmDropdownV2
            {...otherProps}
            label={label}
            search
            options={options}
        />
    );
};

export default FilterTicket;