import {
    BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST,
} from "./actions";

export const getForemenPaymentsRichPage = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST,
        payload,
    };
};

export const getForemenPaymentsForemanContractorsRichList = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST,
        payload,
    };
};

export const rejectClientForemenPaymentsPayment = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST,
        payload,
    };
};

export const confirmClientForemenPaymentsPayment = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST,
        payload,
    };
};

export const clearClientForemenPaymentsStore = () => {
    return {
        type: BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE,
    };
};