import React, {FC, ReactNode, useState} from "react";

import {NmHintButton} from "./Button";

import bem from "../../utils/bem";

import "./style.sass";

interface INmHint {
    openButtonText?: string,
    closeButtonText?: string,
    className?: string,
    buttonClassName?: string,
    children: ReactNode,
    defaultOpen?: boolean,
    reverse?: boolean,
    buttonIcon?: ReactNode,
    buttonColor?: "blue" | "primary" | "gray" | "black",
    customButton?: ReactNode,
    requiredContent?: ReactNode,
    actions?: ReactNode,
    chevronSize?: number,
    chevronColor?: string,
    fullWidth?: boolean,
}

const NmHint: FC<INmHint> = (props) => {
    const {
        openButtonText = "Развернуть",
        closeButtonText = "Свернуть",
        children,
        className,
        buttonClassName = "",
        defaultOpen = false,
        reverse = false,
        buttonIcon,
        buttonColor,
        customButton,
        chevronSize,
        chevronColor,
        fullWidth,
        requiredContent,
        actions,
    } = props;

    const [block, element] = bem("nm-hint", className);

    const [isShown, setShown] = useState<boolean>(defaultOpen);

    const handleChangeContentVisibility = () => {
        setShown(!isShown);
    };

    return (
        <div className={block({reverse})}>
            {
                isShown &&
                <div className={element("content")}>
                    {children}
                </div>
            }
            {requiredContent}
            <NmHintButton
                onClick={handleChangeContentVisibility}
                isShown={isShown}
                closeButtonText={closeButtonText}
                openButtonText={openButtonText}
                className={buttonClassName}
                buttonIcon={buttonIcon}
                color={buttonColor}
                customButton={customButton}
                chevronSize={chevronSize}
                chevronColor={chevronColor}
                fullWidth={fullWidth}
                actions={actions}
            />
        </div>
    );
};

export default NmHint;