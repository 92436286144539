import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesNaimixCategorySpecialitiesSelector = state => state.bff.settingsDirectoriesNaimixCategorySpecialities;
export const settingsDirectoriesNaimixCategorySpecialitiesPageDataSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({pageData}) => pageData);
export const settingsDirectoriesNaimixCategorySpecialitiesTotalPagesSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesNaimixCategorySpecialitiesTotalCountSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({totalCount}) => totalCount);
export const settingsDirectoriesNaimixCategorySpecialitiesListSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({list}) => list);
export const settingsDirectoriesNaimixCategorySpecialitiesFilterListSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({filterList}) => filterList.map((item) => {
    return {
        key: item.id,
        value: item.id,
        text: item.value,
    };
}));
export const settingsDirectoriesNaimixCategorySpecialitiesProgressSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({progress}) => progress);
export const settingsDirectoriesNaimixCategorySpecialitiesProgressActionSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({progressAction}) => progressAction);
export const settingsDirectoriesNaimixCategorySpecialitiesCardSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({card}) => card);
export const settingsDirectoriesNaimixCategorySpecialitiesProgressCardSelector = createSelector(settingsDirectoriesNaimixCategorySpecialitiesSelector, ({progressCard}) => progressCard);