import {isEqual} from "lodash";

import {getNumberFromFormattedAmount} from "../../../../../../utils/stringFormat";

export const isClientSettingsAdditionalModulesChanged = (params) => {
    const {
        values,
        card,
    } = params;

    const _values = formatValuesToCard(values);

    return Object.keys(_values).length === Object.keys(card).length &&
        !isEqual(_values, card);
};

const formatValuesToCard = (values) => {
    return {
        ...values,
        migrantLicensePaymentCommission: values.migrantLicensePaymentCommission ?
            getNumberFromFormattedAmount(values.migrantLicensePaymentCommission) : "",
    };
};