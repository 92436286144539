import {
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST,
    UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE,
} from "./actions";

export const getPageContractorSupport = (payload) => {
    return {
        type: SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCardContractorSupport = (payload) => {
    return {
        type: SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST,
        payload,
    };
};

export const updateContractorSupport = (payload) => {
    return {
        type: SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST,
        payload,
    };
};

export const updateStoreSettingsContractorSupport = (payload) => {
    return {
        type: UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE,
        payload,
    };
};