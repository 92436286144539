import React, {useEffect} from "react";
import {isEmpty} from "lodash";
import {nanoid} from "nanoid";

import CheckboxList from "../../CheckboxList";
import NmPage from "../../NmPage";
import NmTitle from "../../NmTitle";
import Filter, {FILTER} from "../Filter";
import {INmDropdownV2} from "../NmDropdownV2";
import {ILabelText} from "../NmLabelText";
import NmListCard from "../NmList/Card";
import NmModal from "../NmModal";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import {getClassNames} from "../../../utils/classNames";
import {formatLocalDate} from "../../../utils/dateFormat";

import {FilterComponentParams} from "../Filter/types";

interface KedoOrganizationStructureLogsProps<T> {
    onClose: () => void,
    totalCount?: number,
    totalPages?: number,
    list?: Array<T>,
    fetchList: (data: {
        pageNum: number,
        pageSize: number,
    }) => void,
    progress?: boolean,
    getDescription?: (item: T) => React.ReactNode | null,
    getLabels?: (item: T) => Array<ILabelText>,
    dateFieldName?: string,
    initiatorFieldName?: string,
    getInitiatorNameText?: (item: T) => string | undefined,
    uniqName?: string,
    eventTypeOptions: Array<OptionType>,
    filters?: Array<FilterComponentParams>,
    getFilters?: () => React.ReactNode,
    date?: {
        startDate: string,
        endDate: string,
    },
    eventType?: INmDropdownV2,
    nameFieldStartDate?: string,
    nameFieldEndDate?: string,
    nameFieldEventType?: string,
    mapFilterDto?: (filter: Record<string, any>) => Record<string, any>,
    classNameFilter?: string,
    classNameFilterField?: string,
    title?: string,
}

interface LogItem {
    logId?: string,
    description?: string,
}

export const LogsModal = <T extends LogItem>(props: KedoOrganizationStructureLogsProps<T>) => {
    const {
        onClose,
        totalCount,
        totalPages,
        list,
        progress,
        fetchList,
        dateFieldName,
        initiatorFieldName,
        getInitiatorNameText,
        uniqName = "logId",
        eventTypeOptions,
        filters,
        mapFilterDto,
        date,
        eventType,
        classNameFilter,
        classNameFilterField,
        title,
    } = props;

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        setPagination,
        onPaginationChange,
    } = usePagination();

    const {
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
        mapFilterDto,
    });

    useEffect(() => {
        fetchList({
            pageNum,
            pageSize,
            ...filterData,
        });
    }, [
        pageNum,
        filterData,
    ]);

    const getDescription = (item: T) => {
        if (props.getDescription) {
            return props.getDescription(item);
        }

        return item.description;
    };

    const getLabels = (item: T) => {
        const getInitiatorText = () => {
            if (initiatorFieldName) {
                return item[initiatorFieldName as keyof typeof item];
            }

            if (getInitiatorNameText) {
                return getInitiatorNameText(item);
            }
        };

        const isShowInitiator = Boolean(initiatorFieldName || getInitiatorNameText);
        const initiator = isShowInitiator && {
            label: "Инициатор",
            text: getInitiatorText(),
        };
        const date = Boolean(dateFieldName) && {
            label: "Дата и время",
            text: formatLocalDate(item[dateFieldName as keyof typeof item], "dd.MM.yyyy HH:mm:ss"),
        };

        const mainLabels = [
            date,
            initiator,
        ];

        if (props.getLabels) {
            const labels = props.getLabels(item);

            return [
                ...mainLabels,
                ...labels,
            ];
        }

        return mainLabels;
    };

    const getRows = () => {
        return list?.map(item => {
            const key = item[uniqName as keyof typeof item] || nanoid(3);

            return {
                key: key as string,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={getDescription(item)}
                        labels={getLabels(item)}
                    />
                ),
            };
        });
    };

    const getFilters = () => {
        const defaultFilters = [
            {
                component: FILTER.DATE_RANGE,
                startFieldName: date?.startDate,
                endFieldName: date?.endDate,
                isClearable: true,
            },
            {
                component: FILTER.DROPDOWN,
                multiple: eventType?.multiple,
                search: true,
                options: eventTypeOptions,
                name: eventType?.name,
                placeholder: "Выберите тип события",
            },
        ];

        if (filters) {
            return [
                ...defaultFilters,
                ...filters,
            ];
        }

        return defaultFilters;
    };

    const getFilter = () => {
        if (props.getFilters) {
            return props.getFilters();
        }

        return (
            <Filter
                initState={{}}
                className={getClassNames([
                    classNameFilter,
                    "mb-4",
                ])}
                classNameField={classNameFilterField}
                wrapFilters={getFilters()}
                onSubmit={onSearch}
                clearFilter={onClear}
                actionsBottomPosition={true}
            />
        );
    };

    return (
        <NmModal
            size="lg"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            onClose={onClose}
            loading={progress}
            classNameContent="flex-column"
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={getFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
                isEmptyPage={isEmpty(list)}
            >
                <CheckboxList rows={getRows()} />
            </NmPage>
        </NmModal>
    );
};