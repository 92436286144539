import {ORDER_FIELDS_NAME} from "./clientList";
import {EMPTY_OPTION_KEY} from "./dropdown";
import {ORDER_WORK_REPORT_TYPE} from "./finance";
import {STATUS} from "./status";

export const patternNumber = /^\d*(,\d{0,2})?$/;

export const LABEL_ORDER_HISTORY_BY_ROLE = {
    ADMIN: "Причина отклонения",
    CLIENT_ADMIN: "Причина отклонения администратором Компании",
    FOREMAN: "Причина отклонения прорабом",
    CONTRACTOR: "Причина отклонения самозанятым",
};

export const ORDER_SELF_CONTRACTOR_PAGE_DATA_LS = "orderSelfContractorPageData";

export const ORDER_HISTORY_FIELD_NAME = {
    CLIENT_ID: "clientId",
    CONTRACTOR_FIO: "contractorFio",
    CONTRACTOR_ID: "contractorId",
    CONTRACTOR_PHONE: "contractorPhone",
    DATE_TIME: "dateTime",
    EVENT_TYPE: "eventType",
    FOREMAN_FIO: "foremanFio",
    ORDER_ID: "orderId",
    STATUS: "status",
    // form search
    CONTRACTOR_FIO_FILTER: "contractorFioFilter",
    CONTRACTOR_PHONE_FILTER: "contractorPhoneFilter",
    LOG_END_DATE_FILTER: "logEndDateFilter",
    LOG_START_DATE_FILTER: "logStartDateFilter",
};

export const ORDER_FIELD = {
    CREATOR_CLIENT_USER_ID: "creatorClientUserId",
    CREATOR_CLIENT_ID: "creatorClientId",
    CREATE_DATE: "createDate",
    INSURANCE: "insurance",
    CHECK_IN_REQUIRED: "checkInRequired",
    CHECK_OUT_REQUIRED: "checkOutRequired",
    CHECK_IN_PHOTO_REQUIRED: "checkInPhotoRequired",
    CHECK_OUT_PHOTO_REQUIRED: "checkOutPhotoRequired",
    AMOUNT_FOR_CONTRACTOR: "amountForContractor",
    ACCOMODATION: "accomodation",
    ADDRESS: "address",
    AMOUNT: "amount",
    ARCHIVED: "archived",
    CLIENT_ID: "clientId",
    CLIENT_NAME: "clientName",
    CONFIRMED: "confirmed",
    CONTRACT_SUBJECT: "contractSubject",
    CONTRACT_TYPE: "contractType",
    CONTRACTORS_FOUND: "contractorsFound",
    CONTRACTORS_HIRED_COUNT: "contractorsHiredCount",
    CONTRACTORS_NEEDED_COUNT: "contractorsNeededCount",
    DESCRIPTION: "description",
    HAS_REJECT_PAYMENT: "hasRejectedPayment",
    INDUSTRY: "industry",
    LAST_UPDATE_OF_PAYMENT: "lastUpdateOfPayment",
    NAME: "name",
    NOT_CONFIRMED: "notConfirmed",
    OBJECT_ID: "objectId",
    ORDER_ID: "orderId",
    ORDER_MANAGER: "orderManager",
    ORDER_MANAGER_ID: "orderManagerId",
    ORDER_MANAGER_PHONE: "orderManagerPhone",
    ORDER_NUM: "orderNum",
    PAID_MEAL: "paidMeal",
    PUBLIC_AVAILABLE: "publicAvailable",
    STATUS: "status",
    VOLUME_OF_WORK: "volumeOfWork",
    WORK_END_DATE: "workEndDate",
    WORK_START_DATE: "workStartDate",
    WORK_UNIT: "workUnit",
    WORK_UNIT_PRICE: "workUnitPrice",
    SPECIALTY_LIST: "specialtyList",
};

export const ORDER_CONTRACTOR_FIELD = {
    CLIENT_ID: "clientId",
    CONTRACT_DATE: "contractDate",
    CONTRACTOR_ID: "contractorId",
    CONTRACTOR_STATUS: "contractorStatus",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    ORDER_ID: "orderId",
    PATRONYMIC: "patronymic",
    PHONE: "phone",
};

export const ORDER_CONTRACTOR_COUNTS_FIELD_NAME = {
    AWAITING_COUNT: "awaitingCount",
    CLIENT_ID: "clientId",
    HIRED_COUNT: "hiredCount",
    ORDER_ID: "orderId",
};

export const ORDER_CONTRACTOR_STATUS_FIELD_NAME = {
    CLIENT_ID: ORDER_FIELD.CLIENT_ID,
    CONTRACTOR_ID: "contractorId",
    CONTRACTOR_STATUS: "contractorStatus",
    ORDER_ID: ORDER_FIELD.ORDER_ID,
    REASON_FOR_REFUSAL: "reasonForRefusal",
};

export const orderListDefaultFilter = (params = {}) => {
    const {
        projectId = null,
        objectId = null,
        isOrdersFilter,
    } = params;

    return {
        objectIdsFilter: objectId ? [objectId] : [],
        projectIdsFilter: projectId ? [projectId] : [],
        nameSubstringFilter: undefined,
        orderNumFilter: undefined,
        statusFilter: "ALL",
        orderContractPaymentTypeFilter: "ALL",
        toDateCreateFilter: undefined,
        fromDateCreateFilter: undefined,
        dateWorkStartFilter: undefined,
        dateWorkEndFilter: undefined,
        specialityIds: [],
        regionsFilter: [],
        visibleToContractor: "all",
        contractorRecruitmentRequiredFilter: isOrdersFilter ? EMPTY_OPTION_KEY : undefined,
        addressFiasIds: [],
    };
};

// Вкладки - карточка заказа - исполнители
export const ORDER_CARD_PERFORMERS_TABS = {
    RESPONSES: "responses",
    INVITED: "invited",
    PRE_APPROVED: "pre-approved",
    SIGN_AGREEMENT: "sign-agreement",
    HIRED: "hired",
    REFUSALS: "refusals",
};

export const END_ORDER_BY_CLIENTS_REASONS_BAD = [
    "Случайно выбрал не того исполнителя",
    "Исполнитель изменил условия сотрудничества",
    "Исполнитель отказался выполнять задание",
    "Исполнитель опоздал",
    "Работа выполнена не полностью или некачественно",
    "Я столкнулся с мошенничеством",
    "Задание перестало быть актуальным",
    "Другое",
];

export const END_ORDER_BY_CLIENTS_REASONS = [
    {
        value: "CONTRACTOR_COMPLETED_ORDER",
        text: "Исполнитель выполнил работы по заказу",
    },
    {
        value: "CONTRACTOR_REFUSE_ORDER",
        text: "Я отказываюсь от работы с исполнителем",
        options: END_ORDER_BY_CLIENTS_REASONS_BAD.map((text, index) => ({
            value: index,
            text,
        })),
    },
];

const AWAITING_ORDER_COMPLETION_CONFIRMATION_MESSAGES = {
    COMPLETED_ORDER: "Исполнитель предложил завершение работ по заказу",
    REFUSE_ORDER: "Исполнитель отказывается от выполнения работ по заказу по причине: :reason",
    CONTRACTOR_COMPLETED_ORDER: "Заказчик предложил завершение работ по заказу",
    CONTRACTOR_REFUSE_ORDER: "Заказчик отказывается от работы с исполнителем по причине: :reason",
};

export const REASONS_BY_STATUS = {
    [STATUS.AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION]: AWAITING_ORDER_COMPLETION_CONFIRMATION_MESSAGES,
    [STATUS.AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION]: AWAITING_ORDER_COMPLETION_CONFIRMATION_MESSAGES,
    [STATUS.NEGOTIATIONS_ON_THE_DISPUTE]: {
        COMPLETED_ORDER: "Исполнитель предложил завершение работ по заказу. " +
            "Заказчик отклонил данное предложение. По заказу открыт спор",
        REFUSE_ORDER: "Исполнитель отказывается от выполнения работ по заказу по причине: :reason. " +
            "Заказчик отклонил данное предложение. По заказу открыт спор",
        CONTRACTOR_COMPLETED_ORDER: "Заказчик предложил завершение работ по заказу. " +
            "Исполнитель отклонил данное предложение. По заказу открыт спор",
        CONTRACTOR_REFUSE_ORDER: "Заказчик отказывается от работы с исполнителем по причине: :reason. " +
            "Исполнитель отклонил данное предложение. По заказу открыт спор",
    },
    [STATUS.COMPLETED]: {
        CONTRACTOR_COMPLETED_ORDER: "Исполнитель выполнил работы по заказу",
        REFUSE_ORDER: "Исполнитель отказался от выполнения работ по заказу по причине: :reason",
        CONTRACTOR_REFUSE_ORDER: "Заказчик отказался от работы с исполнителем по причине: :reason",
    },
};

export const COMPLETE_REASON = {
    CONTRACTOR_REFUSE_ORDER: "CONTRACTOR_REFUSE_ORDER",
};

export const ORDER_SCHEDULE_DAYS = [
    {
        text: "Понедельник",
        name: "MONDAY",
        checked: false,
        times: [],
    },
    {
        text: "Вторник",
        name: "TUESDAY",
        checked: false,
        times: [],
    },
    {
        text: "Среда",
        name: "WEDNESDAY",
        checked: false,
        times: [],
    },
    {
        text: "Четверг",
        name: "THURSDAY",
        checked: false,
        times: [],
    },
    {
        text: "Пятница",
        name: "FRIDAY",
        checked: false,
        times: [],
    },
    {
        text: "Суббота",
        name: "SATURDAY",
        checked: false,
        times: [],
    },
    {
        text: "Воскресенье",
        name: "SUNDAY",
        checked: false,
        times: [],
    },
];

export const CREATE_ORDER_CURRENT_LOCATION = {
    PRE_CONDITIONS: 1,
    FIRST_STEP: 2,
    SECOND_STEP: 3,
    PUBLISH_STEP: 4,
};

export const getOrderFormInitState = (params) => {
    const {
        isTemplate,
    } = params;

    return {
        [ORDER_FIELDS_NAME.PUBLIC_AVAILABLE]: true,
        [ORDER_FIELDS_NAME.NAME]: "",
        [ORDER_FIELDS_NAME.ADDRESS]: "",
        [ORDER_FIELDS_NAME.WORK_START_DATE]: null,
        [ORDER_FIELDS_NAME.WORK_END_DATE]: null,
        [ORDER_FIELDS_NAME.ACCOMODATION]: false,
        [ORDER_FIELDS_NAME.PAID_MEAL]: false,
        [ORDER_FIELDS_NAME.DESCRIPTION]: "",
        [ORDER_FIELDS_NAME.RESPONSIBLE]: undefined,
        [ORDER_FIELDS_NAME.AMOUNT]: "",
        [ORDER_FIELDS_NAME.WORK_UNIT_PRICE]: "",
        [ORDER_FIELDS_NAME.VOLUME_OF_WORK]: "",
        [ORDER_FIELDS_NAME.WORK_UNIT]: null,
        [ORDER_FIELDS_NAME.CONTRACTORS_NEEDED_COUNT]: "",
        contractorsNeededCountSecondType: isTemplate ? "" : "1",
        [ORDER_FIELDS_NAME.WORK_UNIT]: "SERVICE",
        [ORDER_FIELDS_NAME.VOLUME_OF_WORK]: "1",
        [ORDER_FIELDS_NAME.CONTRACTORS_NEEDED_COUNT]: "",
        [ORDER_FIELDS_NAME.OBJECT_ID]: null,
        [ORDER_FIELDS_NAME.SPECIALTY_LIST]: [],
        [ORDER_FIELDS_NAME.INSURANCE]: false,
        [ORDER_FIELDS_NAME.CHECK_IN_REQUIRED]: false,
        [ORDER_FIELDS_NAME.CHECK_OUT_REQUIRED]: false,
        [ORDER_FIELDS_NAME.ORDER_SUB_CATEGORY_ID]: "",
        [ORDER_FIELDS_NAME.CATEGORY_ID]: "",
        [ORDER_FIELDS_NAME.KEYWORDS]: [],
        //[ORDER_FIELDS_NAME.FOOD_TYPES]: [],
        [ORDER_FIELDS_NAME.DRIVER_LICENSE_TYPES]: [],
        [ORDER_FIELDS_NAME.ADDITIONAL_TERMS]: "",
        checkOutTimePeriod: {},
        checkInTimePeriod: {},
        contractorRecruitmentRequired: false,
        orderManagerPhoneVisible: false,
        orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
        timeHours: "",
        timeMinutes: "",
        timeForExecutionHours: "",
        timeForExecutionMinutes: "",
        useContractorJoinMessage: false,
        contractorJoinMessage: null,
        templateParams: {},
    };
};

export const ORDER_STEP = {
    FIRST: 0,
    SECOND: 1,
};

export const ORDER_KIND = {
    AMOUNT_BASED_ORDER: "AMOUNT_BASED_ORDER", // 1й тип заказа
    VOLUME_OF_FORK_BASED_ORDER: "VOLUME_OF_FORK_BASED_ORDER", // 2й тип заказа
};

export const VISIBLE_TO_CONTRACTOR_TRANSLATE = {
    all: undefined,
    yes: true,
    no: false,
};

export const ACCESS_TO_RESPONSES_OPTIONS = [
    {
        key: "all",
        text: "Все",
        value: "all",
    },
    {
        key: "yes",
        text: "Да",
        value: "yes",
    },
    {
        key: "no",
        text: "Нет",
        value: "no",
    },
];

// Коды ошибок при которых не надо закрывать форму закзаа после отправки
export const ORDER_ERROR_CODE = {
    // Сумма списания с депозита(лимита) <= Бюджет объекта
    OBJECT_AMOUNT_SMALL: "OBJECT_AMOUNT_SMALL",
    // Сумма заказа / Количество исполнителей >= 60
    INVALID_ORDER_AMOUNT_PER_CONTRACTOR: "INVALID_ORDER_AMOUNT_PER_CONTRACTOR",
    // Шаблон в рамках которого осуществляется создание заказа был изменен. Пожалуйста, заполните поля согласно обновлённому шаблону
    TEMPLATE_IS_OUT_OF_DATE: "TEMPLATE_IS_OUT_OF_DATE",
    // Шаблон в рамках которого осуществляется создание заказа был перемещен в архив. Пожалуйста, выберете другой шаблон или создайте свободный заказ
    TEMPLATE_IS_ARCHIVED: "TEMPLATE_IS_ARCHIVED",
};

export const ORDER_EDIT_SPECIALTIES_TEXT = {
    INSTRUCTION: "Если вам не подходит ни один из представленных видов деятельности, вы можете предложить свой. " +
        "Для этого просто введите предлагаемый Вами вид деятельности в поле и нажмите Enter для добавления.",
    ABOUT: "Заказ сохранится в статусе «Черновик», а предложенный вид деятельности будет рассмотрен администратором. " +
        "Как только администратор примет решение по одобрению/отказу у соответствующего заказа в реестре заказов " +
        "появится индикатор",
};

export const SPECIALITY_EXISTS_ERROR = "Доступность указанного вида деятельности ограничена настройками компании. " +
    "Пожалуйста выберите иной вид деятельности или свяжитесь с администратором компании, чтобы изменить настройки";

export const ORDERS_HEADER_ACTIONS = {
    CALCULATOR: {
        VALUE: "calculator",
        TEXT: "Калькулятор",
    },
    ARCHIVE: {
        VALUE: "to-archive",
        TEXT: "В архив",
    },
    UPLOAD_LIST: {
        VALUE: "upload-list",
        TEXT: "Загрузить группу заказов",
    },
    FILTER: {
        VALUE: "filter",
        TEXT: "Фильтр",
    },
    CREATE_ORDER: {
        VALUE: "create-order",
        TEXT: "Создать заказ",
    },
    ADD_ORDER: {
        VALUE: "add-order",
        TEXT: "Добавить заказ",
    },
    IMPORT_ORDERS_TO_GROUP: {
        VALUE: "import-orders-to-group",
        TEXT: "Загрузить список",
    },
    EXPORT_GROUP_ORDERS: {
        VALUE: "export-group-orders",
        TEXT: "Выгрузить",
    },
    HIDE_DETAILS: {
        VALUE: "hide-details",
    },
};

// Для разделения логики применяемой в форме создания/редактирования заказа
export const ORDER_CREATION_MODE = {
    // Создание шаблона
    TEMPLATE: "TEMPLATE",
    // Создание заказа по шаблону
    FROM_TEMPLATE: "FROM_TEMPLATE",
    // Создание заказа с предзаполненными полями Отвественный, Объект из МО выбора способа создания заказа
    PRE_FILLED_FROM_CREATION_TYPE_MODAL: "PRE_FILLED_FROM_CREATION_TYPE_MODAL", // не используется
};

export const ORDER_TEMPLATES_UPDATE_PARAMETERS = {
    // Невозможно выбрать данный объект, поскольку в рамках него не создано ни одного шаблона
    TEMPLATE_NOT_FOUND_IN_OBJECTS: "TEMPLATE_NOT_FOUND_IN_OBJECTS",
};

export const ORDER_TEMPLATE_ARCHIVE = {
    LAST_TEMPLATE_IN_OBJECT_ERROR: "LAST_TEMPLATE_IN_OBJECT_ERROR",
};

export const ORDER_WARNING_CODES = {
    // Заказ с обеспечением
    // "Сумма списания с депозита (лимита)" <= "Остаток бюджета объекта" + "Сумма списания с депозита (лимита)" <= "Остаток лимита"
    AMOUNT_MORE_OBJECT_BUDGET_AND_FREE_BALANCE: "AMOUNT_MORE_OBJECT_BUDGET_AND_FREE_BALANCE",
    // "Сумма списания с депозита (лимита)" <= "Остаток бюджета объекта"
    AMOUNT_MORE_OBJECT_BUDGET: "AMOUNT_MORE_OBJECT_BUDGET",
    // "Сумма списания с депозита (лимита)" <= "Остаток лимита"
    AMOUNT_MORE_FREE_BALANCE: "AMOUNT_MORE_FREE_BALANCE",
    // Заказ без обеспечения
    // "Сумма списания с депозита (лимита)" <= "Остаток бюджета объекта" + "Сумма списания с депозита (лимита)" <= "Остаток лимита"
    AMOUNT_MORE_OBJECT_BALANCE_AND_LIMIT_BALANCE: "AMOUNT_MORE_OBJECT_BALANCE_AND_LIMIT_BALANCE",
    // "Сумма списания с депозита (лимита)" <= "Остаток бюджета объекта"
    AMOUNT_MORE_OBJECT_BALANCE: "AMOUNT_MORE_OBJECT_BALANCE",
    // "Сумма списания с депозита (лимита)" <= "Остаток лимита"
    AMOUNT_MORE_LIMIT_BALANCE: "AMOUNT_MORE_LIMIT_BALANCE",
};
export const ORDER_ACT_REGISTRY_MIN_IMPORT_FIELD_LENGTH = 5;

export const MIN_PAYOUT_TO_CONTRACTOR = 10;

export const MIN_PAYOUT_TO_CONTRACTOR_ERROR_MESSAGE = "Сумма заказа на одного исполнителя не может быть менее 10 рублей";

export const ORDER_ACTION = {
    SHOW_HISTORY: "show-history",
    EDIT_ORDER: "edit-order",
    GET_CHECKS: "get-checks",
    CLOSE_ORDER: "close-order",
    ARCHIVE_ORDER: "archive-order",
    COMPLETE_SELECTION: "complete-selection",
    SHARE: "share",
    CLOSE_ORDERS: "close-orders",
    GO_CHAT: "go-chat",
    GO_DISPUTES: "go-disputes",
    DELETE_DRAFT_ORDER: "delete-draft-order",
};

// Допустимые типы на BE, для отправки в поле actionType, метод - api/orders/multiple/checkToAction
export const ORDER_MASS_ACTION_TYPE = {
    COMPLETE: "COMPLETE",
    ARCHIVE: "ARCHIVE",
    DELETE: "DELETE",
    CREATE: "CREATE",
};

export const ORDER_MASS_ACTION = {
    CLOSE_ORDERS: "COMPLETE",
    ARCHIVE_CLOSED_ORDERS: "ARCHIVE",
    DELETE_DRAFTS: "DELETE",
    PUBLISH_ORDERS: "CREATE",
    PUBLISH_ORDERS_IN_WORK: "PUBLISH_IN_WORK",
    COMPLETE_SELECTION: "COMPLETE_SELECTION",
    DELETE_ORDERS_FROM_GROUP: "DELETE_ORDERS_FROM_GROUP",
};

export const ORDER_CONTEXT_MENU_MASS_ACTION_OPTION = {
    CLOSE_ORDERS: {
        key: ORDER_MASS_ACTION.CLOSE_ORDERS,
        text: "Закрыть заказы",
        value: ORDER_MASS_ACTION.CLOSE_ORDERS,
    },
    ARCHIVE_CLOSED_ORDERS: {
        key: ORDER_MASS_ACTION.ARCHIVE_CLOSED_ORDERS,
        text: "Архивировать закрытые заказы",
        value: ORDER_MASS_ACTION.ARCHIVE_CLOSED_ORDERS,
    },
    DELETE_DRAFTS: {
        key: ORDER_MASS_ACTION.DELETE_DRAFTS,
        text: "Удалить черновики",
        value: ORDER_MASS_ACTION.DELETE_DRAFTS,
    },
    PUBLISH_ORDERS: {
        key: ORDER_MASS_ACTION.PUBLISH_ORDERS,
        text: "Опубликовать в статус \"Поиск исполнителей\"",
        value: ORDER_MASS_ACTION.PUBLISH_ORDERS,
    },
    PUBLISH_ORDERS_IN_WORK: {
        key: ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK,
        text: "Опубликовать в статус \"В работе\"",
        value: ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK,
    },
    COMPLETE_SELECTION: {
        key: ORDER_MASS_ACTION.COMPLETE_SELECTION,
        text: "Завершить подбор",
        value: ORDER_MASS_ACTION.COMPLETE_SELECTION,
    },
    DELETE_ORDERS_FROM_GROUP: {
        key: ORDER_MASS_ACTION.DELETE_ORDERS_FROM_GROUP,
        text: "Удалить из группы",
        value: ORDER_MASS_ACTION.DELETE_ORDERS_FROM_GROUP,
    },
};

export const ORDER_SOURCE_TYPES = {
    API: "API",
    TYPE_1: "TYPE_1",
    TYPE_2: "TYPE_2",
    REGISTRY: "REGISTRY",
};

export const ORDER_SOURCE_DESCRIPTIONS = {
    [ORDER_SOURCE_TYPES.TYPE_1]: "1 тип заказа",
    [ORDER_SOURCE_TYPES.TYPE_2]: "2 тип заказа",
    [ORDER_SOURCE_TYPES.REGISTRY]: "Реестровый",
    [ORDER_SOURCE_TYPES.API]: "API",
};

export const ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE = {
    [ORDER_MASS_ACTION.CLOSE_ORDERS]: {
        TITLE: "Закрытие заказов",
        TEXT: "Вы действительно хотите закрыть нижепредставленные заказы?",
        WARNING_TEXT: "В случае подтверждения закрытия выплаты исполнителям по данным заказам будут недоступны. " +
            "Исполнители, которые выполняют работы (оказывают услуги) будут оповещены о закрытии заказов.",
        SUCCESS_LABEL: "К закрытию",
        WARNING_LABEL: "Невозможно закрыть",
        TOOLTIP: "Заказы, которые были выбраны в списке, но не могут быть закрыты " +
            "по причине некорректного статуса или наличия выплат на согласовании",
        NO_CORRECT_ORDERS: "Ни один из выбранных заказов не может быть закрыт " +
            "по причине некорректного статуса или наличия висящих выплат",
    },
    [ORDER_MASS_ACTION.PUBLISH_ORDERS]: {
        TITLE: "Публикация заказов",
        TEXT: "Вы действительно хотите опубликовать нижепредставленные заказы?",
        SUCCESS_LABEL: "К публикации",
        WARNING_LABEL: "Невозможно опубликовать",
        TOOLTIP: "Заказы, которые были выбраны в списке, но не могут быть опубликованы " +
            "по причине некорректного статуса, нежелательного контента в названии/описании, " +
            "незаполненных обязательных полей или суммы заказа менее 10 рублей",
        NO_CORRECT_ORDERS: "Ни один из выбранных заказов не может быть опубликован " +
            "по причине некорректного статуса, незаполненных обязательных полей, " +
            "нежелательного контента в названии/описании или суммы заказа менее 10 рублей",
    },
    [ORDER_MASS_ACTION.ARCHIVE_CLOSED_ORDERS]: {
        TITLE: "Архивирование закрытых заказов",
        TEXT: "Вы действительно хотите добавить нижепредставленные закрытые заказы в архив?",
        SUCCESS_LABEL: "К архивированию",
        WARNING_LABEL: "Невозможно архивировать",
        TOOLTIP: "Заказы, которые были выбраны в списке, но не могут быть заархивированы по причине некорректного статуса",
    },
    [ORDER_MASS_ACTION.DELETE_DRAFTS]: {
        TITLE: "Удаление черновиков",
        TEXT: "Вы действительно хотите удалить нижепредставленные черновики?",
        SUCCESS_LABEL: "К удалению",
        WARNING_LABEL: "Невозможно удалить",
        TOOLTIP: "Заказы, которые были выбраны в списке, но не могут быть удалены по причине некорректного статуса",
    },
    PUBLICATION_ORDERS_WITH_WARNING: {
        TITLE: "Публикация заказов",
        TEXT: "У некоторых заказов были обнаружены следующие предупреждения. " +
            "Выберите заказы, которые Вы готовы опубликовать, несмотря на полученную информацию",
        TOOLTIP: "Счетчик включает заказы, которые прошли финансовые проверки, а также заказы, " +
            "которые не прошли проверки, но будут выбраны в рамках данного окна " +
            "(при выборе заказов значение обновляется автоматически)",
    },
    [ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK]: {
        TITLE: "Публикация заказов",
        TEXT: "Вы действительно хотите опубликовать нижепредставленные заказы?",
        SUCCESS_LABEL: "К публикации",
        WARNING_LABEL: "Невозможно опубликовать",
        TOOLTIP: "Заказы, которые были выбраны в списке, но не могут быть опубликованы " +
            "по причине некорректного статуса, нежелательного контента в названии/описании, " +
            "незаполненных обязательных полей или суммы заказа менее 10 рублей",
        NO_CORRECT_ORDERS: "Ни один из выбранных заказов не может быть опубликован " +
            "по причине некорректного статуса, незаполненных обязательных полей, " +
            "нежелательного контента в названии/описании или суммы заказа менее 10 рублей",
    },
    [ORDER_MASS_ACTION.COMPLETE_SELECTION]: {
        TITLE: "Завершение подбора",
        TEXT: "Вы действительно хотите завершить подбор для нижепредставленных заказов?",
        SUCCESS_LABEL: "К переводу в статус \"В работе\"",
        WARNING_LABEL: "",
        TOOLTIP: "",
    },
};

export const ORDER_MASS_ACTION_MESSAGE = {
    CLOSE_ORDERS: {
        SUCCESS: "Задача по закрытию заказов передана в обработку",
    },
    ARCHIVE_CLOSED_ORDERS: {
        SUCCESS: "Задача по архивированию заказов передана в обработку",
    },
    DELETE_DRAFTS: {
        SUCCESS: "Задача по удалению заказов передана в обработку",
    },
    PUBLISH_ORDERS: {
        WARNING_NO_SELECTED_ORDERS: "Отсутствуют заказ для публикации (К публикации: 0)",
        SUCCESS: "Задача по публикации заказов передана в обработку",
    },
    COMPLETE_SELECTION: {
        SUCCESS: "Задача по завершению подбора на заказах передана в обработку",
    },
};

export const ORDER_SOURCE_TYPE_OPTIONS = [
    {
        key: ORDER_SOURCE_TYPES.TYPE_1,
        value: ORDER_SOURCE_TYPES.TYPE_1,
        text: "1 тип заказа",
    },
    {
        key: ORDER_SOURCE_TYPES.TYPE_2,
        value: ORDER_SOURCE_TYPES.TYPE_2,
        text: "2 тип заказа",
    },
    {
        key: ORDER_SOURCE_TYPES.API,
        value: ORDER_SOURCE_TYPES.API,
        text: "Созданный через API",
    },
    {
        key: ORDER_SOURCE_TYPES.REGISTRY,
        value: ORDER_SOURCE_TYPES.REGISTRY,
        text: "Реестровый",
    },
];

export const ORDER_SOURCE_TYPE = {
    API: "API",
    TYPE_1: "TYPE_1",
    TYPE_2: "TYPE_2",
    REGISTRY: "REGISTRY",
};

export const ORDER_OFFERED_SPECIALITY_WARNING_PUBLICATION_MESSAGE = "Заказ сохранен в статусе \"Черновик\", " +
    "поскольку предложен новый вид деятельности. " +
    "После согласования администратором Наймикс нового вида деятельности заказ можно будет опубликовать";

export const ORDER_WORK_REPORT_ERROR_CODE = {
    FNS_CONNECT_TIME_OUT: "FNS_CONNECT_TIME_OUT",
};

// Общий объем работ для типа "Услуга" все равен 1
export const ORDER_MIN_SERVICE_VALUE = "1";

export const ORDER_EDIT_ACCESS = {
    ALLOWED: "ALLOWED",
    FORBIDDEN: "FORBIDDEN",
};

export const ORDER_EDIT_ACCESS_DICT = {
    ALLOWED: "Можно редактировать",
    FORBIDDEN: "Нельзя редактировать",
};