import {isStartDateOutThreeDays} from "../isStartDateOutThreeDays";
import {isWorkPeriodNotEarlierCurrentDate} from "../isWorkPeriodNotEarlierCurrentDate";
import {isWorkPeriodWithinOneMonthOnly} from "../isWorkPeriodWithinOneMonthOnly";

import {requiredMessage} from "../../../../../constants/validationRules";
import {ORDER_CREATE_STEPS} from "../../constants";

/**
 * Хелпер для валидации даты начала работ
 * @param params
 * @returns {null|string}
 */
export const validateOrderEditWorkStartDate = (params) => {
    const {
        currentStep,
        workStartDate,
        workEndDate,
        currentMember,
        clientProperties,
        isTemplate,
        isForemans,
    } = params;

    if (currentStep !== ORDER_CREATE_STEPS.FIRST_STEP.VALUE) {
        return null;
    }

    const isWorkStartDateRequired = !isTemplate;

    if (isWorkStartDateRequired && !workStartDate) {
        return requiredMessage;
    }

    const isWorkPeriodWithinOneMonthOnlyError = !isWorkPeriodWithinOneMonthOnly({
        workPeriodWithinOneMonthOnly: clientProperties.workPeriodWithinOneMonthOnly,
        workStartDate,
        workEndDate,
    });

    const isWorkPeriodNotEarlierCurrentDateError = !isWorkPeriodNotEarlierCurrentDate({
        workPeriodIsNotEarlierCurrentDate: clientProperties.workPeriodIsNotEarlierCurrentDate,
        workStartDate,
        workEndDate,
    });

    if (isWorkPeriodNotEarlierCurrentDateError) {
        return "Вы не можете создавать заказы с периодом ранее текущей даты";
    }

    if (isWorkPeriodWithinOneMonthOnlyError) {
        return "Вы можете создавать заказы только в рамках одного календарного месяца";
    }

    const isStartDateOutThreeDaysError = !isTemplate
        && !isForemans
        && currentMember.requireWorkStartDateThreeDaysOffsetForOrder
        && isStartDateOutThreeDays(workStartDate);

    if (isStartDateOutThreeDaysError) {
        return "Вы не можете создавать заказы с датой начала работ ранее, чем через 3 дня от текущей даты";
    }

    return null;
};