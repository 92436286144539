import {
    CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR,
    CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST,
    CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS,
    CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS,
    SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR,
    SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST,
    SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    card: {},
    pageData: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    contractorBalance: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS:
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST:
        case SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST:
        case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST:
        case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS:
        case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR:
        case SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS:
        case SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR:
        case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS:
        case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR:
        case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS:
        case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS:
            return {
                ...state,
                contractorBalance: payload,
            };
        case GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR:
            return {
                ...state,
                contractorBalance: {
                    isError: true,
                },
            };
        case CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};