import {
    DELETE_EMPTY_RECORD_REQUEST,
    GET_EMPTY_RECORD_REQUEST,
    MAINTENANCE_NOTICE_PUBLISHED_REQUEST,
    NDFL_DEPOSIT_CORRECT_REQUEST,
    NPD_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_SET_REQUEST,
    UPDATE_SETTINGS_SERVICE_STORE,
} from "./actions";

export const getMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    payload,
});

export const setMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    payload,
});

export const getMaintenanceNoticePublished = () => ({
    type: MAINTENANCE_NOTICE_PUBLISHED_REQUEST,
});

export const getSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_GET_REQUEST,
    payload,
});

export const setSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_SET_REQUEST,
    payload,
});

export const setExpasoftCollingSetting = (payload) => ({
    type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    payload,
});

export function getEmptyRecord(payload) {
    return {
        type: GET_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function deleteEmptyRecord(payload) {
    return {
        type: DELETE_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function npdDepositCorrect(payload) {
    return {
        type: NPD_DEPOSIT_CORRECT_REQUEST,
        payload,
    };
}

export function ndflDepositCorrect(payload) {
    return {
        type: NDFL_DEPOSIT_CORRECT_REQUEST,
        payload,
    };
}

export const updateStoreSettingsStats = (payload) => {
    return {
        type: UPDATE_SETTINGS_SERVICE_STORE,
        payload,
    };
};