import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR,
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST,
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS,
    INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_ERROR,
    INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST,
    INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS,
    INTERNAL_PAYMENTS_TKB_PAY_ERROR,
    INTERNAL_PAYMENTS_TKB_PAY_REQUEST,
    INTERNAL_PAYMENTS_TKB_PAY_SUCCESS,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR, INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS,
} from "./actions";

import request from "../../utils/postman";
import {toastError} from "../../utils/toastHelper";

const controller = "/adm/settings/service/internal-payments";

// POST /bff/adm/settings/internal-payments/tkb/pay
// Создать внутренний перевод средств между счетами в ТКБ
const createTkbPaymentSaga = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/tkb/pay`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: INTERNAL_PAYMENTS_TKB_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: INTERNAL_PAYMENTS_TKB_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: INTERNAL_PAYMENTS_TKB_PAY_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/internal-payments/logs/getPage
// Получение страницы записей о переводах
const getTkbPaymentsLogsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/logs/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/settings/internal-payments/tkb/status/batch
// Получение статуса перевода
const getInternalPaymentsTkbStatusBatchSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/tkb/status/batch`, payload);
        const {errorMessage, list} = result;

        if (errorMessage) {
            yield put({
                type: INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS,
            payload: list,
        });
    } catch (error) {
        yield put({
            type: INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/settings/internal-payments/allowed-emails
// Получение списка имейлов пользователей, авторизованных для осуществления платежей
const getInternalPaymentsAllowedEmailsSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/allowed-emails`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(INTERNAL_PAYMENTS_TKB_PAY_REQUEST, createTkbPaymentSaga),
        takeEvery(INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST, getTkbPaymentsLogsSaga),
        takeEvery(INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST, getInternalPaymentsAllowedEmailsSaga),
        takeEvery(INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST, getInternalPaymentsTkbStatusBatchSaga),
    ]);
}