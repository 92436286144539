import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useClientBrandBff} from "./useBff";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {updateClientBrandsStore} from "../../../../ducks/client/brands/actionCreators";
import {getClientBrandInitialState} from "../../../../ducks/client/brands/reducer";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

export const useClientBrandsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        filterData,
        clientId,
        archived,
    } = params;

    const methods = useClientBrandBff();

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        archived,
    ]);

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientBrands,
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientObjectBrand,
            ],
        }));
        
        return () => {
            const initState = getClientBrandInitialState();

            dispatch(updateClientBrandsStore(initState));
        };
    }, []);

    const fetchList = () => {
        methods.fetchList({
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            isArchiveFilter: archived,
            ...filterData,
        });
    };

    return {
        fetchList,
        methods,
    };
};