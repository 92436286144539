export const ORDER_FIELDS_NAME = {
    CHECK_IN_REQUIRED: "checkInRequired",
    CHECK_IN_PHOTO_REQUIRED: "checkInPhotoRequired",
    CHECK_OUT_REQUIRED: "checkOutRequired",
    CHECK_OUT_PHOTO_REQUIRED: "checkOutPhotoRequired",
    ORDER_ID: "orderId",
    NAME: "name",
    SPECIALTY: "specialty",
    TYPE_OF_WORK: "clientPriceId",
    PAID_MEAL: "paidMeal",
    PUBLIC_AVAILABLE: "publicAvailable",
    INDUSTRY: "industry",
    INSURANCE: "insurance",
    ACCOMODATION: "accomodation",
    DESCRIPTION: "description",
    NUMBER_OF_WORKERS: "numOfWorkers",
    WORK_END_DATE: "workEndDate",
    WORK_START_DATE: "workStartDate",
    RESPONSIBLE: "orderManagerId",
    SCOPE: "scope",
    OBJECT: "objectId",
    AMOUNT: "amount",
    ADDRESS: "address",
    CONTRACTORS_HIRED_COUNT: "contractorsHiredCount",
    CONTRACTORS_NEEDED_COUNT: "contractorsNeededCount",
    CONTRACTORS_NEEDED_COUNT_SECOND_TYPE: "contractorsNeededCountSecondType",
    VOLUME_OF_WORK: "volumeOfWork",
    WORK_UNIT: "workUnit",
    WORK_UNIT_PRICE: "workUnitPrice",
    CONTRACT_TYPE: "contractType",
    OBJECT_ID: "objectId",
    SUBJECT_CONTRACT: "contractSubject",
    SPECIALTY_LIST: "specialtyList",
    KEYWORDS: "keywords",
    CATEGORY_ID: "orderCategoryId",
    ORDER_SUB_CATEGORY_ID: "orderSubcategoryId",
    STATUS: "status",
    NEED_MEDICAL_BOOK: "needMedicalBook",
    TRANSFER_TO_OBJECT: "canTransferContractorToObject",
    TRANSFER_FROM_OBJECT: "canTransferContractorFromObject",
    OVERALLS_PROVIDED: "overallsProvided",
    HAS_CHANGE_HOUSE: "hasChangeHouse",
    NEED_DRIVER_LICENSE: "needDriverLicense",
    CONTRACTOR_HAS_CAR: "contractorHasCar",
    NEED_UNIFORM: "needUniform",
    ADDITIONAL_TERMS: "additionalTerms",
    TOOLS_PROVIDED: "toolsProvided",
    FOOD_TYPES: "foodTypes",
    DRIVER_LICENSE_TYPES: "driverLicenseTypes",
    SCHEDULES: "schedules",
    SPECIALTY_ID: "specialityId",
};

export const CLIENT_FIELD_NAME = {
    //ФИО контактного лица
    REPRESENTATIVE_NAME: "representativeName",
    ID: "clientId",
    CLIENT_TYPE: "clientType",
    NAME: "name",
    BRAND: "brand",
    //Полное имя компании
    FULL_NAME: "fullName",
    //Номер договора
    CONTRACT_NUMBER: "nmContractNumber",
    //КПП
    REGISTRATION_REASON_CODE: "registrationReasonCode",
    //Зарегистрированный адрес
    REGISTERED_ADDRESS: "registeredAddress",
    //Фактический адрес
    ACTUAL_ADDRESS: "actualAddress",
    //к/с
    BANK_CORR_ACCOUNT: "bankCorrAccount",
    //р/c
    BANK_CHECK_ACCOUNT: "bankCheckAccount",
    //БИК
    BIC: "bic",
    NAIMIX_JOINING_DATE: "naimixJoiningDate",
    //Адрес банка
    BANK_ADDRESS: "bankAddress",
    //Наименование банка
    BANK_NAME: "bankName",
    EMAIL: "email",
    PHONE: "phone",
    //Адрес компаний
    ADDRESS: "address",
    OGRN: "ogrn",
    INN: "inn",
    // Телефон главного бухгалтера
    ACCOUNTANT_PHONE: "accountantPhone",
    // Главный бухгалтер
    ACCOUNTANT_NAME: "accountantName",
    //Телефон директора
    MANAGER_PHONE: "managerPhone",
    //Директор
    MANAGER_NAME: "managerName",
    RIGHT_TO_SIGN_DOCUMENT_DETAILS: "rightToSignDocumentDetails",
    ARCHIVED: "archived",
    DELETED: "deleted",
    LOCKED: "locked",
    REPRESENTATIVE_NAME_GENITIVE: "representativeNameGenitive",
    CURRENT_COMMISSION_RATE: "currentCommissionRate",
    INSURANCE_AVAILABLE: "insuranceAvailable",
    REGISTRY_PAYMENTS_AVAILABLE: "registryPaymentsAvailable",
    REPRESENTATIVE_PHONE: "representativePhone",
    REPRESENTATIVE_EMAIL: "representativeEmail",
    CATEGORY: "categoryId",
    SIGNATORY: "signatory",
    SIGNATORY_DECODING: "signatoryDecoding",
    OKVED: "okved",
    ORDERS_LIMIT: "ordersLimit",
    ORDERS_UNSECURED: "ordersUnsecured",
    FOREMAN_FUNCTIONALITY_AVAILABLE: "foremanFunctionalityAvailable",
    EDM_AVAILABLE: "edmAvailable",
    MIGRANT_LICENSE_PAYMENT_ENABLED: "migrantLicensePaymentEnabled",
    MIGRANT_LICENSE_PAYMENT_COMMISSION: "migrantLicensePaymentCommission",
    SPECIALITY_IDS: "specialityIds",
    MAIN_SPECIALITY_ID: "mainSpecialityId",
    ABOUT: "about",
    //Дата договора по оплате патентов
    PATENT_CONTRACT_DATE: "patentContractDate",
    //Номер договора по оплате патентов
    PATENT_CONTRACT_NUMBER: "patentContractNumber",
    WITHOUT_CONTRACT: "withoutContract",
    //Оплаты реестрами (НДФЛ)
    CIVIL_REGISTRY_PAYMENTS_AVAILABLE: "civilRegistryPaymentsAvailable",
    //лимит для заказов без обеспечения по НДФЛ
    CIVIL_ORDERS_LIMIT: "civilOrdersLimit",
    //% ставки комиссии компании для выплат по договорам НДФЛ
    CIVIL_PAYMENT_COMMISSION: "civilPaymentCommission",
    CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION: "contractorCivilOrderPaymentsCommission", // комиссия исполнителей за выплаты по заказам (НДФЛ)
    CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION: "contractorCivilRegistryPaymentsCommission", // комиссия исполнителей за выплаты по реестрам/АПИ (НДФЛ)
    CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION: "contractorSmzOrderPaymentsCommission", // комиссия исполнителей за выплаты по заказам (НПД)
    CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION: "contractorSmzRegistryPaymentsCommission", // комиссия исполнителей за выплаты по реестрам/АПИ (НПД)
    MIN_COMMISSION_AMOUNT: "minCommissionAmount", // Дополнительная сумма комиссии компании
    MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT: "minimalCommissionNdflPaymentsAmount", // Минимальная комиссия компании за выплаты (НДФЛ)
    PAYMENTS_THRESHOLD_AMOUNT: "paymentsThresholdAmount", // Минимальная сумма выплаты без доп. комиссии компании
    DEPOSIT_DISTRIBUTED_BY_OBJECTS: "depositDistributedByObjects", // Распределение депозита по объектам
    MERCHANDISING_RESPONSIBLE_PHONE: "merchandisingResponsiblePhone", // Контакты ответственного за мерчандайзинг
};

/* Поля из формы карточки компании Контакты */
export const CLIENT_INFO_CONTACTS_FIELDS = [
    CLIENT_FIELD_NAME.INN,
    CLIENT_FIELD_NAME.CLIENT_TYPE,
    CLIENT_FIELD_NAME.MANAGER_NAME,
    CLIENT_FIELD_NAME.MANAGER_PHONE,
    CLIENT_FIELD_NAME.ACCOUNTANT_NAME,
    CLIENT_FIELD_NAME.ACCOUNTANT_PHONE,
    CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS,
    CLIENT_FIELD_NAME.ACTUAL_ADDRESS,
    CLIENT_FIELD_NAME.REGISTERED_ADDRESS,
    CLIENT_FIELD_NAME.PHONE,
    CLIENT_FIELD_NAME.EMAIL,
    CLIENT_FIELD_NAME.REPRESENTATIVE_NAME,
    CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE,
    CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL,
    CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE,
];

/* Поля из формы карточки компании Реквизиты */
export const CLIENT_CARD_FIELDS = {
    BANK_INFO: [
        CLIENT_FIELD_NAME.BANK_NAME,
        CLIENT_FIELD_NAME.BANK_ADDRESS,
        CLIENT_FIELD_NAME.BIC,
        CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT,
        CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT,
    ],
    CLIENT_INFO: [
        CLIENT_FIELD_NAME.NAME,
        CLIENT_FIELD_NAME.FULL_NAME,
        CLIENT_FIELD_NAME.REPRESENTATIVE_NAME,
        CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE,
        CLIENT_FIELD_NAME.CLIENT_TYPE,
        CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL,
        CLIENT_FIELD_NAME.ACTUAL_ADDRESS,
        CLIENT_FIELD_NAME.REGISTERED_ADDRESS,
        CLIENT_FIELD_NAME.PHONE,
        CLIENT_FIELD_NAME.EMAIL,
        CLIENT_FIELD_NAME.OGRN,
        CLIENT_FIELD_NAME.INN,
        CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE,
        CLIENT_FIELD_NAME.BRAND,
        CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE,
    ],
    BOSS_INFO: [
        CLIENT_FIELD_NAME.MANAGER_NAME,
        CLIENT_FIELD_NAME.MANAGER_PHONE,
    ],
    ACCOUNTANT_INFO: [
        CLIENT_FIELD_NAME.ACCOUNTANT_NAME,
        CLIENT_FIELD_NAME.ACCOUNTANT_PHONE,
    ],
    REPRESENTATIVE_INFO: [
        CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE,
        CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS,
    ],
    SIGNATORY_INFO: [
        CLIENT_FIELD_NAME.SIGNATORY,
        CLIENT_FIELD_NAME.SIGNATORY_DECODING,
    ],
};

export const FEDERAL_MIGRATION_FIELD = {
    ADDRESS: "address",
    DISTRICT: "district",
    DISTRICT_ID: "districtId",
    FEDERAL_ID: "federalId",
    NAME_DEPARTMENT: "nameDepartment",
    REGION: "region",
};
/*
    clientUserId: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        patronymic: "",
        position: "",
        phone: "",
        login: "",
        role: ""
*/

export const OBJECT_FIELD_NAME = {
    ADDRESS: "address",
    AMOUNT: "amount",
    UNLIMITED: "unlimited",
    CLIENT_ID: "clientId",
    CONTRACTOR_NUMBER: "contractNumber",
    DESCRIPTION: "description",
    FEDERAL_ID: "federalId",
    LATITUDE: "latitude",
    OBJECT_EXT_ID: "objectExtId",
    CAN_EDIT_EXT_ID: "canEditExtId",
    LONGITUDE: "longitude",
    MANAGER_ID: "managerId",
    NAME: "name",
    OBJECT_ID: "objectId",
    ALLOWABLE_DISTANCE: "allowableDistance",
    REGION: "region",
    PROJECT_ID: "projectId",
    BANK_CHECK_ACCOUNT: "bankCheckAccount",
    ADD_OBJECT_ADDRESS: "addObjectAddress",
};

export const FINANCE_UPLOAD_FIELD_NAME = {
    END_PERIOD_DATE: "endPeriodDate",
    START_PERIOD_DATE: "startPeriodDate",
};

export const CLIENT_MEMBER_FIELD_NAME = {
    ACTIVE: "active",
    CLIENT_ID: "clientId",
    ID: "clientUserId",
    DELETED: "deleted",
    LOCKED: "locked",
    SNILS: "snils",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    PASSWORD: "password",
    PATRONYMIC: "patronymic",
    POSITION: "position",
    PHONE: "phone",
    LOGIN: "login",
    ROLE: "role",
    ROLE_ID: "roleId",
    EMAIL: "email",
    REQUIRE_WORK_START_DATE_THREE_DAYS_OFFSET_FOR_ORDER: "requireWorkStartDateThreeDaysOffsetForOrder",
    CONTRACTOR_INVITATION_PROHIBITED: "contractorInvitationProhibited",
    FULL_NAME: "fullName",
    INN: "inn",
    PARTNER_PROMOTION_CONTRACTOR_ID_LIST: "partnerPromotionContractorIdList",
    PARTNER_PROMOTION_CLIENTS_ID_LIST: "partnerPromotionClientIdList",
    AVAILABLE_CLIENTS: "availableClients",
    SAMPLE_TYPE: "sampleType",
    SIP_NUMBER: "sipNumber",
    EXTENSION_NUMBER: "extensionNumber",
};

export const SUPPORT_FIELD_NAME = {
    ANSWER: "answer",
    CASE_NUMBER: "caseNumber",
    CASE_STATUS: "caseStatus",
    CONTRACTOR_ID: "contractorId",
    DATETIME: "dateTime",
    TYPE_OF_APPEAL: "typeOfAppeal",
    TOPIC_OF_APPEAL: "topicOfAppeal",
    DESCRIPTION: "description",
    PAYMENT_NUMBER: "paymentNumber",
    ORDER_NUM: "orderNum",
};

export const PROMOCODE_CHANNEL_FIELD_NAME = {
    PROMOTION_ID: "promotionId",
    NAME: "name",
    WORD: "word",
    CONTRACTOR_ID: "contractorId",
    POSTFIX_PROMOTION: "postfixPromotion",
    TYPE_PROMOTION: "typePromotion",
    MULTIPLE_COUNT: "multipleCount",
    COUNT: "count",
    FROM_DATE: "fromDate",
    TO_DATE: "toDate",
    IS_DELETED: "isDeleted",
    APPLICATION_COUNT: "applicationCount",
    REFERRAL_RATE: "referralRate",
    REQUISITES: "requisites",
    TYPE: "type",
    CLIENT_ID: "clientId",
    SIGN_CONTRACT_FRAME_CLIENT_IDS: "signContractFrameClientIds",
    CLIENT_GROUP_FILTER: "clientGroupFilter",
    CLIENT_GROUP_ID: "clientGroupId",
    SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER: "signContractFrameAfterMobileFilter",
    SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER: "signContractFrameAfterAdminCheckFilter",
};

export const STAFF_FIELDS_NAME = {
    ID: "staffId",
    COUNT: "count",
};

export const ORDER_BUTTON_NAME = {
    ADD_TYPE_OF_WORK: "add-type-of-work",
    ADD_PERSON: "add-person",
};

export const CLIENT_FILE_TYPES = {
    SIGNATURE: "SIGNATURE",
    STAMP: "STAMP",
    LOGO: "LOGO",
    LOGO_THUMBNAIL: "LOGO_THUMBNAIL",
};

export const CONTRACTOR_FILE_TYPES = {
    SCAN_PERSONAL_INFO: "PERSONALDATA",
    SCAN_REGISTRATION: "RESIDENCE", // Страница с пропиской
    SELFIE_CHECK_SCAN: "PASSPORT_SELFIE", // Селфи с паспортом
    SCAN_MIGRATION_CARD: "MIGRATIONCARD", //Скан миграционной карты
    SCAN_BACK_SIDE_MIGRATION_CARD: "MIGRATION_CARD_BACK_SIDE",
    SCAN_TEMPORARY_HOUSING_FIRST: "TEMPORARYHOUSINGFIRST",
    SCAN_TEMPORARY_HOUSING_SECOND: "TEMPORARYHOUSINGSECOND",
    INTERNATIONAL_PERSONAL_DATA: "INTERNATIONAL_PERSONAL_DATA", // Скан заграничного паспорта с персональной информацией
    INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE: "INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE", //Скан заграничного паспорта с местом рождения
    NOTIFICATION_OF_ARRIVAL_FIRST: "NOTIFICATION_OF_ARRIVAL_FIRST",//Скан уведомления о прибытии (1 сторона)
    NOTIFICATION_OF_ARRIVAL_SECOND: "NOTIFICATION_OF_ARRIVAL_SECOND",//Скан уведомления о прибытии (2 сторона)
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
    INTERNAL_PERSONAL_DATA_FRONT_SIDE: "INTERNAL_PERSONAL_DATA_FRONT_SIDE",//Скан лицевой стороны паспорта
    INTERNAL_PERSONAL_DATA_BACK_SIDE: "INTERNAL_PERSONAL_DATA_BACK_SIDE", // Скан обратной стороны паспорта
    EMPLOYMENT_HISTORY_SPREAD_SCAN: "EMPLOYMENT_HISTORY_SPREAD_SCAN",//Фотограция разворота трудовой книжки с Вашим ФИО
    SNILS_FRONT_SIDE_SCAN: "SNILS_FRONT_SIDE_SCAN",//лицевая сторона СНИЛС
    EMPLOYMENT_PATENT_FRONT_SIDE_SCAN: "EMPLOYMENT_PATENT_FRONT_SIDE_SCAN",//Лицевая сторона патента
    EMPLOYMENT_PATENT_BACK_SIDE_SCAN: "EMPLOYMENT_PATENT_BACK_SIDE_SCAN", //Обратная сторона патента
    PAID_EMPLOYMENT_PATENT_CHECK_SCAN: "PAID_EMPLOYMENT_PATENT_CHECK_SCAN", //Чек об оплате патента
    VHI_POLICY_SCAN: "VHI_POLICY_SCAN",//Договор ДМС или Полис ДМС,
    RESIDENCE_PERMIT_ADDRESS: "RESIDENCE_PERMIT_ADDRESS", // Скан страницы с адресом места жительства
    PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR: "PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR", //Cогласие на совершение сделок несовершеннолетними
    PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA: "PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA", //Cогласие на обработку ПД родителя,
    PREVIOUS_PASSPORT: "PREVIOUS_PASSPORT", //Сведения о ранее выданных паспортах
    INN: "INN", //Свидетельство о постановке на учет физического лица в налоговом органе (ИНН),
    VISA_PERSONAL_DATA_SPREAD: "VISA_PERSONAL_DATA_SPREAD", //Разворот визы с персональной информацией
    CERTIFICATE_FROM_UNIVERSITY: "CERTIFICATE_FROM_UNIVERSITY", //Справка из ВУЗа
    UNIVERSITY_SCHEDULE_FOR_SEMESTER: "UNIVERSITY_SCHEDULE_FOR_SEMESTER", //Расписание занятий на семестр
    UNIVERSITY_ACCREDITATION_BACK_SIDE: "UNIVERSITY_ACCREDITATION_BACK_SIDE", //Обратная сторона аккредитации ВУЗа
    UNIVERSITY_ACCREDITATION_FRONT_SIDE: "UNIVERSITY_ACCREDITATION_FRONT_SIDE", //Лицевая сторона аккредитации ВУЗа
    UNIVERSITY_LICENSE_BACK_SIDE: "UNIVERSITY_LICENSE_BACK_SIDE", //Обратная сторона лицензии ВУЗа
    UNIVERSITY_LICENSE_FRONT_SIDE: "UNIVERSITY_LICENSE_FRONT_SIDE", //Лицевая сторона лицензии ВУЗа
    MAIN_DOCUMENT_STAMP_ADDRESS: "MAIN_DOCUMENT_STAMP_ADDRESS", //Штамп в документе
    PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE: "PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE", //Пластиковый страховой полис, 1 сторона
    PLASTIC_VHI_POLICY_SCAN_BACK_SIDE: "PLASTIC_VHI_POLICY_SCAN_BACK_SIDE", //Пластиковый страховой полис, 2 сторона
    REFUGEE_CERTIFICATE_EXTENSION_MARK: "REFUGEE_CERTIFICATE_EXTENSION_MARK", //Разворот удостоверения беженца с отметкой о продлении срока действия
    PASSPORT_TRANSLATION_NOTARIZATION: "PASSPORT_TRANSLATION_NOTARIZATION", //Нотариальное заверение перевода паспорта
    REFUGEE_CERTIFICATE_SPREAD: "REFUGEE_CERTIFICATE_SPREAD", //Разворот удостоверения беженца
    PASSPORT_TRANSLATION: "PASSPORT_TRANSLATION", //Перевод паспорта
    TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA: "TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA", //Разворот РВП с персональной информацией
    STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA: "STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA", //Разворот вида на жительство лица без гражданства
    REFUGEE_CERTIFICATE_SELFIE: "REFUGEE_CERTIFICATE_SELFIE", //Селфи с удостоверением беженца
    TEMPORARY_REFUGEE_CERTIFICATE_SELFIE: "TEMPORARY_REFUGEE_CERTIFICATE_SELFIE", //Селфи со свидетельством о предоставлении временного убежища
    STATELESS_PERSON_TEMPORARY_ID_SELFIE: "STATELESS_PERSON_TEMPORARY_ID_SELFIE", //Селфи с временным удостоверением личности лица без гражданства
    STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD: "STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD", //Разворот временного удостоверения личности лица без гражданства с персональной информацией
    TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA: "TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA", //Свидетельство о предоставлении временного убежища с персональной информацией
};

export const CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES = {
    COVID_19_VACCINATION_CERTIFICATE: "COVID_19_VACCINATION_CERTIFICATE", //"Сертификат о вакцинации от COVID-19"
    EMPLOYMENT_HISTORY: "EMPLOYMENT_HISTORY", //"Трудовая книжка"
    FINGERPRINTING: "FINGERPRINTING", //"Дактилоскопия"
    MEDICAL_BOOK: "MEDICAL_BOOK", //"Медицинская книжка"
    MEDICAL_CERTIFICATION_REPORT: "MEDICAL_CERTIFICATION_REPORT", //"Медицинское освидетельствование"
    PREVIOUSLY_ISSUED_PASSPORTS_INFO: "PREVIOUSLY_ISSUED_PASSPORTS_INFO", //"Сведения о ранее выданных паспортах"
    SNILS: "SNILS", //"СНИЛС"
};

export const CONTRACTOR_ADDITIONAL_FILE_TYPES = {
    PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN: "PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN", //Разворот паспорта со сведениями о ранее выданных паспортах
    FINGERPRINTING_PASSING_FRONT_SIDE_SCAN: "FINGERPRINTING_PASSING_FRONT_SIDE_SCAN", //Документ о прохождении дактилоскопии, лицевая сторона
    FINGERPRINTING_PASSING_BACK_SIDE_SCAN: "FINGERPRINTING_PASSING_BACK_SIDE_SCAN", //Документ о прохождении дактилоскопии, обратная сторона
    MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN: "MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN", //Медицинское освидетельствование, персональные информация
    MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN: "MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN", //Медицинское освидетельствование, сертификат об отсутствии ВИЧ
    MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN: "MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN", //Медицинское освидетельствование, подписи и печати врачей
    MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN: "MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN", //Медицинское освидетельствование, результаты заключения
    MEDICAL_BOOK_SPREAD_SCAN: "MEDICAL_BOOK_SPREAD_SCAN", //Разворот главной страницы медицинской книжки
    MEDICAL_BOOK_QR_SCAN: "MEDICAL_BOOK_QR_SCAN", //QR-код для проверки медицинской книжки
    COVID_19_VACCINATION_CERTIFICATE_SCAN: "COVID_19_VACCINATION_CERTIFICATE_SCAN", //Сертификат о вакцинации от COVID-19 или QR-код
    EMPLOYMENT_HISTORY_SPREAD_SCAN: "EMPLOYMENT_HISTORY_SPREAD_SCAN", // Фотография разворота трудовой книжки с Вашим ФИО или сведения о трудовой деятельности, предоставляемые работнику работодателем, форма СТД-Р
    SNILS_FRONT_SIDE_SCAN: "SNILS_FRONT_SIDE_SCAN", //лицевая сторона СНИЛС
};

export const CONTRACTOR_FILE_TYPES_TRANSLATE = {
    [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: "Персональная информация",
    [CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION]: "Регистрация",
    [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT]: "Скан разворота вида на жительство",
    [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: "Скан миграционной карты, лицевая сторона",
    [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: "Скан миграционной карты, обратная сторона",
    [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: "Скан заграничного паспорта с персональной информацией",
    [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE]: "Скан заграничного паспорта с местом рождения",
    [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: "Скан уведомления о прибытии, 1 сторона",
    [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: "Скан уведомления о прибытии, 2 сторона",
    [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: "Скан лицевой стороны паспорта",
    [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: "Скан обратной стороны паспорта",
    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN]: "Скан трудовой книжки",
    [CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]: "Скан СНИЛСа",
    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: "Скан патента, 1 сторона",
    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: "Скан патента, 2 сторона",
    [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: "Скан чека об оплате патента",
    [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: "Скан договора ДМС или полиса ДМС",
    [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR]: "Согласие на совершение сделок несовершеннолетними",
    [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA]: "Согласие на обработку персональных данных родителя",
    [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: "Селфи с паспортом",
    [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT_ADDRESS]: "Скан страницы с адресом места жительства",
    [CONTRACTOR_FILE_TYPES.INN]: "Скан свидетельства о постановке на учет физического лица в налоговом органе (ИНН)",
    [CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD]: "Разворот визы с персональной информацией",
    [CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY]: "Справка из ВУЗа",
    [CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER]: "Расписание занятий на семестр",
    [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE]: "Обратная сторона аккредитации ВУЗа",
    [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE]: "Лицевая сторона аккредитации ВУЗа",
    [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE]: "Обратная сторона лицензии ВУЗа",
    [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE]: "Лицевая сторона лицензии ВУЗа",
    [CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS]: "Штамп в документе",
    [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE]: "Пластиковый страховой полис, 1 сторона",
    [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE]: "Пластиковый страховой полис, 2 сторона",
    [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK]: "Разворот удостоверения беженца с отметкой о продлении срока действия",
    [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION]: "Нотариальное заверение перевода паспорта",
    [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD]: "Разворот удостоверения беженца",
    [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION]: "Перевод паспорта",
    [CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA]: "Разворот РВП с персональной информацией",
    [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA]: "Разворот вида на жительство лица без гражданства",
    [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE]: "Селфи с удостоверением беженца",
    [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE]: "Селфи со свидетельством о предоставлении временного убежища",
    [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE]: "Селфи с временным удостоверением личности лица без гражданства",
    [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD]: "Разворот временного удостоверения личности лица без гражданства с персональной информацией",
    [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA]: "Свидетельство о предоставлении временного убежища с персональной информацией",
};


export const CONTRACTOR_PASSWORD_TYPE = {
    INTERNAL_PASSPORT: "",
    INTERNATIONAL_PASSPORT: "",
};

export const ORDER_STATUS = {
    DRAFT: "DRAFT",
    CONTRACTOR_SEARCH: "CONTRACTOR_SEARCH",
    PUBLISHED: "PUBLISHED",
    CLOSED: "CLOSED",
    TEMPLATE: "TEMPLATE",
};

export const ORDER_STATUS_COLOR = {
    DRAFT: {color: "#999999"},
    PUBLISHED: {color: "#41A140"},
    CLOSED: {color: "#999999"},
};

export const UPLOAD_1C_FIELD_NAME = {
    DOCUMENT_TYPE: "documentType",
    START_DATE: "startPeriodDate",
    END_DATE: "endPeriodDate",
};

export const CARD_TYPE_FIELD = {
    EDIT_CLIENT_INFO: "editClientInfo",
    EDIT_BANK_INFO: "editBankInfo",
    EDIT_DIRECTOR_INFO: "editDirectorInfo",
    EDIT_BOSS_INFO: "editBossInfo",
    EDIT_SIGNATORY_INFO: "editSignatoryInfo",
    EDIT_REPRESENTATIVE_INFO: "editRepresentativeInfo",
    EDIT_ACCOUNTANT_INFO: "editAccountantInfo",
};

export const CARD_TYPE = {
    CLIENT_INFO: "clientInfo",
    BANK_INFO: "bankInfo",
    BANK_FOREIGN_INFO: "bankForeignInfo",
    BOSS_INFO: "bossInfo",
    SIGNATORY_INFO: "signatoryInfo",
    REPRESENTATIVE_INFO: "representativeInfo",
    ACCOUNTANT_INFO: "accountantInfo",
    INDUSTRIES_INFO: "industriesInfo",
};

export const CLIENT_INFO_FILE_TYPE = {
    STAMP: {
        NAME: "STAMP",
        MESSAGE: "Вы уверены, что хотите удалить изображение печати?",
    },
    SIGNATURE: {
        NAME: "SIGNATURE",
        MESSAGE: "Вы уверены, что хотите удалить изображение подписи?",
    },
};

export const MASK_LENGTH = 7;
export const MAX_LENGTH = 17;
export const CONTRACT_NAME_MASK = "aa-9999";
/*
adminAmountPerDayOneContractor: 500000
adminAmountPerMonthOneContractor: 600000
adminOrdersLimit: 2222220

* */
export const CLIENT_PROPERTIES_FIELD = {
    CURRENT_COMMISSION_RATE: "currentCommissionRate",
    INSURANCE_AVAILABLE: "insuranceAvailable",
    ADMIN_LIMIT: "adminAmountOneTransactionLimitation",
    ADMIN_DAY_LIMIT: "adminAmountPerDayOneContractorLimitation",
    ADMIN_MONTH_LIMIT: "adminAmountPerMonthOneContractorLimitation",
    ADMIN_ORDERS_LIMIT: "adminOrdersLimitLimitation",
    INDIVIDUAL_AMOUNT_ONE_TRANSACTION: "individualAmountOneTransaction", // Лимит 1 транзакции (ИП)
    INDIVIDUAL_AMOUNT_PER_DAY_ONE_CONTRACTOR: "individualAmountPerDayOneContractor", // Лимит в сутки на 1 исполнителя (ИП)
    INDIVIDUAL_AMOUNT_PER_MONTH_ONE_CONTRACTOR: "individualAmountPerMonthOneContractor", // Лимит в месяц на 1 исполнителя (ИП)
    // Ограничение для лимита для заказов без обеспечения (НДФЛ), Р
    ADMIN_CIVIL_ORDERS_LIMIT: "adminCivilOrdersLimitLimitation",
    LIMIT: "amountOneTransaction",
    DAY_LIMIT: "amountPerDayOneContractor",
    MONTH_LIMIT: "amountPerMonthOneContractor",
    REGISTRY_PAYMENTS_AVAILABLE: "registryPaymentsAvailable",
    REGISTRY_BANK: "clientIdBank",
    ORDERS_LIMIT: "ordersLimit",
    ORDERS_UNSECURED: "ordersUnsecured",
    ACT_DATE_AVAILABLE: "actDateAvailable",
    INN_NECESSARY: "innNecessary",
    ON_WEBSITE: "onWebsite",
    PROHIBITION_CONTRACTING_NON_RESIDENTS: "prohibitionContractingNonResidents",
    CAN_VIEW_CONTRACTORS_CONTRACTS: "canViewContractorContacts",
    FOREMAN_ENABLED: "foremanEnabled",
    CAN_CREATE_ONLY_VOLUME_OF_WORK_BASED_ORDERS: "canCreateOnlyVolumeOfWorkBasedOrders",
    RETAIL_CLIENT: "retailClient",
    RETAIL_CLIENT_USER_IDS: "retailClientUserIds",
    PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN: "paymentsWithoutConfirmNmAdmin",
    EDM_AVAILABLE: "edmAvailable",
    ORDER_BY_TEMPLATE: "orderByTemplate",
    API_ACCESS_ENABLED: "apiAccessEnabled",
    ORDER_BY_TEMPLATE_OBJECT_IDS: "orderByTemplateObjectIds",
    ORDER_BY_TEMPLATE_PROJECT_IDS: "orderByTemplateProjectIds",
    MIGRANT_LICENSE_PAYMENT_ENABLED: "migrantLicensePaymentEnabled",
    MIGRANT_LICENSE_PAYMENT_COMMISSION: "migrantLicensePaymentCommission",
    CAN_VIEW_CONTRACTOR_EVENT_LOG_CLIENT_ADMIN: "canViewContractorEventLogClientAdmin",
    CIVIL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN: "civilPaymentsWithoutConfirmNmAdmin",
    // лимит для заказов без обеспечения по НДФЛ.
    CIVIL_ORDERS_LIMIT: "civilOrdersLimit",
    // % ставки комиссии компании для выплат по договорам НДФЛ
    CIVIL_PAYMENT_COMMISSION: "civilPaymentCommission",
    //Оплаты реестрами (НДФЛ)
    CIVIL_REGISTRY_PAYMENTS_AVAILABLE: "civilRegistryPaymentsAvailable",
    WITHOUT_CONTRACT: "withoutContract",
    WARN_MATCH_PAYMENTS_WITH_LAST_MONTH: "warnMatchPaymentsWithLastMonth", // Предупреждать о совпадении количества актов в текущем и предыдущем месяцах
    // Предупреждать, если сумма оплат в текущем месяце не отличается на заданный процент от суммы оплат предыдущего месяца
    WARN_PAYMENT_AMOUNT_DOES_NOT_DIFFER_GIVEN_PERCENTAGE_WITH_LAST_MONTH: "warnPaymentAmountDoesNotDifferGivenPercentageWithLastMonth",
    WARN_PAYMENT_AMOUNT_DOES_NOT_DIFFER_GIVEN_PERCENTAGE: "warnPaymentAmountDoesNotDifferGivenPercentage", // Задание процента от выплат
    CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION: "contractorCivilOrderPaymentsCommission", // комиссия исполнителей за выплаты по заказам (НДФЛ)
    CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION: "contractorCivilRegistryPaymentsCommission", // комиссия исполнителей за выплаты по реестрам/АПИ (НДФЛ)
    CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION: "contractorSmzOrderPaymentsCommission", // комиссия исполнителей за выплаты по заказам (НПД)
    CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION: "contractorSmzRegistryPaymentsCommission", // комиссия исполнителей за выплаты по реестрам/АПИ (НПД)
    MIN_COMMISSION_AMOUNT: "minCommissionAmount", // Дополнительная сумма комиссии компании
    PAYMENTS_THRESHOLD_AMOUNT: "paymentsThresholdAmount", // Минимальная сумма выплаты без доп. комиссии компании
    HAS_ACCESS_TO_CONTRACTOR_PD_FILES: "hasAccessToContractorPDFiles", // Доступ к сканам документов исполнителя,
    PERSONAL_DATA_ACCESS_GRANTED: "personalDataAccessGranted", // Доступ к персональным данным исполнителя,
    EDO_TARIFF: "edoTariff",
    DEPOSIT_DISTRIBUTED_BY_OBJECTS: "depositDistributedByObjects",
    OBJECT_ID_REQUIRED: "objectIdRequired",
    CUSTOM_DOCUMENT_TEMPLATES_AVAILABLE: "customDocumentTemplatesAvailable",
    PAID_APIS_ENABLED: "paidApisEnabled",
    APIS_AMOUNT: "apisAmount",
    OBJECT_ID_FOR_PAID_APIS: "objectIdForPaidApis",
    DOCUMENT_TABLES_ENABLED: "documentTablesEnabled",
    CIVIL_DOCUMENT_TABLES_ENABLED: "civilDocumentTablesEnabled",
    EDM_SIGN_WITHOUT_SMS_CODE_ENABLE: "edmSignWithoutSmsCodeEnable",
    DOCUMENT_DATES_BY_WORK_DATES: "documentDatesByWorkDates",
    DOCUMENT_DATES_CHECK_REQUIRED: "documentDatesCheckRequired",
    RECRUITMENT_AVAILABLE: "recruitmentAvailable",
    RECRUITMENT_TARIFF: "recruitmentTariff",
    SIGNING_FRAME_CONTRACT_WITHOUT_TAXPAYER_AND_PARTNER: "signingFrameContractWithoutTaxpayerAndPartner",
    ONBOARDING_CONTRACTOR_REGISTRATION_WITHOUT_INN_AVAILABLE: "onboardingContractorRegistrationWithoutInnAvailable",
    MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT: "minimalCommissionNdflPaymentsAmount",
    PD_REQUESTS_CREATIONS_FOR_ANY_CONTRACTORS_ENABLED: "pdrequestsCreationsForAnyContractorsEnabled",
    BANK_TYPE_CARD: "bankTypeCard", // Банк для платежей На карты РФ
    BANK_TYPE_SBP: "bankTypeSbp", // Банк для платежей Через СБП
    BANK_TYPE_REQUISITES: "bankTypeRequisites", // Банк для платежей По банковским платежным реквизитам
    PAYMENT_METHOD_MARKETPLACE: "paymentMethodMarketplace", // Выплаты по маркетплейсовым заказам
    PAYMENT_METHOD_REGISTRY: "paymentMethodRegistry", // Реестровые выплаты
    PAYMENT_METHOD_API: "paymentMethodAPI", // Выплаты через внешнее API
    WORK_REPORT_PERIOD_VALIDATION_ENABLED: "workReportPeriodValidationEnabled", // Запрет на формирование актов в рамках одного заказа с пересекающимся периодами выполнения работ
    CROWD_TASK_PAYMENTS_COMMISSION: "crowdTaskPaymentsCommission", // Комиссия компании за оплату по заданию, %
    MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT: "minimalCommissionIndividualPaymentsAmount", // Комиссия компании за выплаты (ИП), %
    INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT: "individualPaymentsThresholdAmount", //  Минимальная сумма выплаты без доп. комиссии компании (ИП)
    MIN_INDIVIDUAL_COMMISSION_AMOUNT: "minIndividualCommissionAmount", // Дополнительная сумма комиссии компании за выплаты (ИП)
    CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION: "contractorIndividualOrderPaymentsCommission", // Комиссия исполнителей за выплаты по заказам (ИП), %
    CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION: "contractorIndividualRegistryPaymentsCommission", // Комиссия исполнителей за реестровые выплаты (ИП), % они действуют аналогично существующим аналогам
};

export const CLIENT_PROPERTIES_MESSAGE = {
    SUCCESS_UPDATE: "Настройки компании успешно обновлены",
    ERROR_UPDATE: "Неизвестная ошибка",
};

export const SYSTEM_PROPERTIES_MESSAGE = {
    SUCCESS_UPDATE: "Системные настройки успешно обновлены",
    SUCCESS_CANCEL_SET_PAYMENT_WITHOUT_ADMIN: "У всех компаний отключено ведение оплат без согласования администратором Наймикс",
    SUCCESS_SET_PAYMENT_WITHOUT_ADMIN: "У всех компаний включено ведение оплат без согласования администратором Наймикс",
    ERROR_UPDATE: "Неизвестная ошибка",
};

export const DEFINING_REGISTRY_PARAMETER_TYPE = {
    PHONE: "PHONE",
    INN: "INN",
};

export const DEFINING_REGISTRY_PARAMETER_TYPE_DICT = {
    [DEFINING_REGISTRY_PARAMETER_TYPE.PHONE]: "Номер телефона",
    [DEFINING_REGISTRY_PARAMETER_TYPE.INN]: "ИНН",
};

export const NM_CLIENT_INFO = {
    ID: "00000000-0000-0000-0000-000000000000",
    NAME: "Наймикс",
};