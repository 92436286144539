import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {typesLidOptions} from "../hooks/useCollectionBankDetailsFilter";
import useDataForm from "./hooks/useFormData";

import {collectionBankDetailsProgressSelector} from "../../../../ducks/bff/settings/collection-bank-details/selectors";

export default function CollectionBankDetailsAddModal(props) {
    const {
        card,
        onClose,
        fetchList,
    } = props;

    const progress = useSelector(collectionBankDetailsProgressSelector);

    const isEdit = !isEmpty(card);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
    } = useDataForm({card, isEdit, fetchList, onClose});


    const {
        leadType,
        projectParam,
    } = values;

    return (
        <NmModal
            loading={progress}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование условия" : "Добавление условия"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    label="ProjectParams"
                    placeholder="Введите ProjectParams"
                    error={touched.projectParam && errors.projectParam}
                    name="projectParam"
                    value={projectParam}
                    onChange={handleChange}
                />
                <NmDropdownV2
                    placeholder="Выберите тип лида"
                    size="xl"
                    selectOnBlur={false}
                    name="leadType"
                    value={leadType}
                    options={typesLidOptions}
                    label="Тип лида"
                    onChange={handleChange}
                    error={touched.leadType && errors.leadType}
                />
            </NmForm>
        </NmModal>
    );
}