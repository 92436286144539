import React, {useState} from "react";
import {useSelector} from "react-redux";
import {eachDayOfInterval} from "date-fns";
import {ceil, round} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import ButtonCalculator from "../../../../components/ButtonCalculator";
import NmAccordion from "../../../../components/NmAccordion";
import DepositCalculator from "../../../deposit/deposit-calculator";

import bem from "../../../../utils/bem";
import {getRoundedPercentInLocalFormat} from "../../../../utils/mathHelper";
import {
    clearSpace,
    formatAmountWithNullChecking,
    formatNumberWithComma,
    getNumberFromFormattedAmount,
} from "../../../../utils/stringFormat";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

export const calculateAmounts = (formData, isSecondType, clientCard) => {
    const {
        currentCommissionRate = 0,
        contractorSmzOrderPaymentsCommission = 0,
        minCommissionAmount = 0, // Дополнительная сумма комиссии компании
        paymentsThresholdAmount = 0, // Минимальная сумма выплаты без доп. комиссии компании
    } = clientCard;
    const {
        insurance = false,
        workStartDate: start,
        workEndDate: end,
        contractorsNeededCountSecondType = "",
    } = formData;

    let {
        amount = "",
        workUnitPrice = "",
        volumeOfWork = "",
        contractorsNeededCount: contractorsCount = "",
    } = formData;

    const _contractorCount = isSecondType ? contractorsNeededCountSecondType : contractorsCount;

    amount = !amount ? 0 : getNumberFromFormattedAmount(amount);
    volumeOfWork = !volumeOfWork ? 0 : getNumberFromFormattedAmount(volumeOfWork);
    contractorsCount = !_contractorCount ? 0 : getNumberFromFormattedAmount(_contractorCount);

    let intervalInDays = 0;
    let amountPerOne = 0;

    if (start && end) {
        const now = new Date();

        if (now > end) {
            intervalInDays = 0;
        } else {
            intervalInDays = now <= start ?
                eachDayOfInterval({start, end}).length :
                eachDayOfInterval({start: now, end}).length;
        }
    }

    if (isSecondType) {
        //Сумма заказа на 1 исполнителя
        workUnitPrice = !workUnitPrice ? 0 : parseFloat(clearSpace(workUnitPrice).replace(",", "."));

        //Сумма заказа на всех исполнителей/Сумма заказа
        amount = workUnitPrice * volumeOfWork;

        //Сумма заказа на 1 исполнителя
        amountPerOne = contractorsCount ? round(workUnitPrice * volumeOfWork / contractorsCount, 2) : 0;
    } else {
        //Сумма заказа на 1 исполнителя
        amountPerOne = contractorsCount ? round(amount / contractorsCount, 2) : 0;
    }

    // В случае, если  сумма на 1 исполнителя имеет значение менее 3000,00 рублей, то под значением
    // "Комиссия компании за выплаты (НПД), Р" должна появляться сумма, вычисляемая по формуле "Количество исполнителей" * (умножить) на 40 рублей
    const amountAdditional = amountPerOne < paymentsThresholdAmount ? contractorsCount * minCommissionAmount : 0;

    const insuranceAmount = insurance ? intervalInDays * contractorsCount * 30 : 0;

    //Комиссия компании за выплаты (НПД), Р
    const siteCommission = amount * currentCommissionRate;
    //Сумма списания с депозита
    const depositAmount = amount * (1 + currentCommissionRate) + insuranceAmount + amountAdditional;
    //Налог исполнителя
    const performerTax = ceil(amountPerOne * 0.06, 2);
    //Комиссия с исполнителя
    const performerCommission = round(amountPerOne * contractorSmzOrderPaymentsCommission, 2);
    //На карту исполнителю
    const amountToCard = amountPerOne - performerTax - performerCommission;

    //Налог исполнителей
    // const draftPerformersTaxes = amount * 0.06;
    // После расчета показателя "Налог исполнителей, Р" система должна проверять получившееся значение. Данное значение округляется по мат правилу до четырех знаков в дробной части.
    // Если в дробной части, 3 И/ИЛИ 4 знак > 0, то ко второму знаку должна быть прибавлена 1 копейка (Пример: было 3 111,1110 руб, стало 3 111,12 руб)
    // const performersTaxes = ceil(draftPerformersTaxes, 2);
    const performersTaxes = performerTax * contractorsCount;

    //Комиссия с исполнителей
    //const performerCommissions = round(amount * 0.04, 2);
    const performerCommissions = performerCommission * contractorsCount;

    //На карты исполнителям
    //const amountToCards = amount - performersTaxes - performerCommissions;
    const amountToCards = amountToCard * contractorsCount;

    return {
        insuranceAmount,
        siteCommission,
        depositAmount,
        performerTax,
        performerCommission,
        amountToCard,
        performersTaxes,
        performerCommissions,
        amountToCards,
        amountPerOne,
        amount,
        amountAdditional,
    };
};

const OrderEditCalculator = (props) => {
    const {
        className = "",
        insurance = false,
        isSecondType,
        calculatorData: {
            insuranceAmount = 0,
            siteCommission = 0,
            depositAmount = 0,
            performerTax = 0,
            performerCommission = 0,
            amountToCard = 0,
            performersTaxes = 0,
            performerCommissions = 0,
            amountToCards = 0,
            amountPerOne = 0,
            amount = 0,
            amountAdditional,
        },
        isMobile,
    } = props;
    const {
        currentCommissionRate = 0,
        contractorSmzOrderPaymentsCommission = 0,
        minCommissionAmount = 0, // Дополнительная сумма комиссии компании
        paymentsThresholdAmount = 0, // Минимальная сумма выплаты без доп. комиссии компании
    } = useSelector(clientCardPropertiesSelector);

    const [isOpenAccordion, setOpenAccordion] = useState(false);
    const [block, element] = bem("order-edit-calculator", className);
    const [isOpenCalculator, setOpen] = useState(false);

    const getData = () => {
        const insuranceBlock = insurance && !isSecondType ? [{
            label: "Страхование, ₽:",
            subTitle: true,
            value: `+ ${formatNumberWithComma(insuranceAmount || 0)}`,
        }] : [];

        return  [
            {
                values: [
                    {
                        label: "Сумма списания с депозита (лимита)",
                        value: formatNumberWithComma(depositAmount),
                        suggestion: "Необходимо пополнить р/с компании на данную сумму",
                    },
                    {
                        label: "Сумма заказа, ₽:",
                        value: formatNumberWithComma(amount),
                    },
                    {
                        label: `Комиссия компании за выплаты (НПД), ₽ (${getRoundedPercentInLocalFormat(currentCommissionRate)}%):`,
                        value: `+ ${formatNumberWithComma(siteCommission)}`,
                        subValue: `+ ${formatNumberWithComma(amountAdditional)}`,
                        isShowSubValue: amountAdditional > 0,
                        alignItems: "center",
                        classNameTooltip: "order-edit-calculator__commission-tooltip",
                        classNameLabel: "order-edit-calculator__commission-label",
                        suggestion: `Согласно договору между Заказчиком и Оператором, при сумме оплаты меньше ${formatAmountWithNullChecking(paymentsThresholdAmount)} ₽ вознаграждение, выплачиваемое Заказчиком Оператору, увеличивается на ${formatAmountWithNullChecking(minCommissionAmount)} ₽ за каждую выплату`,
                    },
                ],
            },
            {
                values: [
                    {
                        label: "Сумма на 1 исполнителя",
                        value: formatNumberWithComma(amountPerOne),
                        suggestion: "Сумма заказа на 1 исполнителя.\n" +
                            "Обращаем внимание, что заказ будет создан на сумму из расчета на всех исполнителей, представленную ниже",
                    },
                    {
                        label: "На карту исполнителю, ₽:",
                        value: formatNumberWithComma(amountToCard),
                    },
                    {
                        label: "Налог исполнителя, ₽:",
                        value: `+ ${formatNumberWithComma(performerTax)}`,
                    },
                    {
                        label: `Комиссия с исполнителя, ₽ (${getRoundedPercentInLocalFormat(contractorSmzOrderPaymentsCommission)}%):`,
                        value: `+ ${formatNumberWithComma(performerCommission)}`,
                    },
                ],
            },
            {
                values: [
                    {
                        label: isSecondType ? "Сумма на всех исполнителей": "Сумма заказа" ,
                        value: formatNumberWithComma(amount),
                    },
                    ...insuranceBlock,
                    {
                        label: "На карты исполнителям, ₽:",
                        value: formatNumberWithComma(amountToCards),
                    },
                    {
                        label: "Налог исполнителей, ₽:",
                        value: `+ ${formatNumberWithComma(performersTaxes)}`,
                    },
                    {
                        label: `Комиссия с исполнителей, ₽ (${getRoundedPercentInLocalFormat(contractorSmzOrderPaymentsCommission)}%):`,
                        value: `+ ${formatNumberWithComma(performerCommissions)}`,
                    },
                ],
            },
        ];
    };

    const onOpenCalculator = () => {
        setOpen(!isOpenCalculator);
    };

    const onOpenAccordion = (open) => {
        setOpenAccordion(open);
    };

    const getValues = () => {
        const data = getData();

        return data.map(({values}, index) =>
            <div
                key={index}
                className={element("section")}
            >
                {values.map((item, index) => {
                    const {
                        label,
                        value,
                        suggestion,
                        subTitle,
                        subValue,
                        alignItems,
                        classNameLabel = "",
                        classNameTooltip = "",
                        isShowSubValue,
                    } = item;

                    return (
                        <div
                            key={index}
                            className={element("row", {subTitle, alignItems})}
                        >
                            <div className={`${element("label")} ${classNameLabel}`}>
                                <div className={element("text")}>
                                    {label}
                                    {
                                        suggestion &&
                                        <HelpTooltip
                                            hover
                                            info
                                            position={isMobile ? "bottom-left" : "bottom"}
                                            className={`${element("tooltip")} ${classNameTooltip}`}
                                        >
                                            {suggestion}
                                        </HelpTooltip>
                                    }
                                </div>
                            </div>
                            <div className={element("value")}>
                                <span>
                                    {value}
                                </span>
                                {
                                    isShowSubValue ?
                                        <span className={element("sub-value")}>
                                            {subValue}
                                        </span> :
                                        null
                                }
                            </div>
                        </div>
                    );
                })
                }
            </div>,
        );
    };

    const getContent = (mobile) => {
        return (
            <>
                {
                    !mobile &&
                    <div className={element("header")}>
Расчетные показатели заказа
                    </div>
                }
                <div className={element("container", {mobile})}>
                    {getValues()}
                </div>
                <ButtonCalculator
                    size="lg"
                    className="order-edit-calculator__button"
                    onClick={onOpenCalculator}
                />
            </>
        );
    };

    return (
        <div className={block()}>
            {
                isMobile ?
                    <NmAccordion
                        className={element("accordion", {open: isOpenAccordion})}
                        name="Расчетные показатели заказа"
                        onOpen={onOpenAccordion}
                    >
                        {getContent(isMobile)}
                    </NmAccordion> :
                    getContent()
            }
            {
                isOpenCalculator &&
                <DepositCalculator
                    handleClose={onOpenCalculator}
                />
            }
        </div>
    );
};

OrderEditCalculator.propTypes = {};

export default OrderEditCalculator;