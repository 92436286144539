import React from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import ContractorEventDocumentLogFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useContractorEventDocumentsLogFilter} from "./hooks/useEventLogDocumentFilter";
import {useFetchListDocumentsLog} from "./hooks/useFetchListDocumentsLog";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {phoneFormat} from "../../../../utils/stringFormat";
import {
    getDeviceInfoLabels,
    getNetworkInfoLabels,
} from "../utils/getLabels";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_INFO,
    LINK_CONTRACTOR_PERSONAL_INFO,
} from "../../../../constants/links";

import {
    contractorCardDocumentSigningListSelector,
    contractorCardDocumentSigningProgressSelector,
    contractorCardDocumentSigningTotalCountSelector,
    contractorCardDocumentSigningTotalPagesSelector,
} from "../../../../ducks/bff/contractor-сard/event-log/document-signing/selectors";
import {
    documentTypeSignatureLogDictsSelector,
    downloadDocument,
} from "../../../../ducks/documents";

import "./style.sass";

const initFilter = {
    transactionIdFilter: "",
    documentIdFilter: "",
    clientIdFilter: "",
    fromDateFilter: null,
    toDateFilter: null,
    documentTypeFilter: null,
};

function ContractorEventDocumentLogList(props) {
    const {
        match: {
            params: {
                contractorId: contractorIdFilter,
            },
        },
    } = props;

    const list = useSelector(contractorCardDocumentSigningListSelector);
    const totalPages = useSelector(contractorCardDocumentSigningTotalPagesSelector);
    const totalCount = useSelector(contractorCardDocumentSigningTotalCountSelector);
    const progress = useSelector(contractorCardDocumentSigningProgressSelector);
    const documentTypeDict = useSelector(documentTypeSignatureLogDictsSelector);

    const {
        setFilter,
        filter,
        onChangeFilter,
    } = useFilter({initFilter});
    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
    } = useContractorEventDocumentsLogFilter();
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("contractor-event-log-common");

    useFetchListDocumentsLog({
        filter: filterDto,
        pageSize,
        pageNum,
        contractorIdFilter,
    });

    const dispatch = useDispatch();

    function renderNameCell(event) {
        const {
            clientPerformerId,
            contractorPerformerId,
            performerName,
        } = event;

        if (!clientPerformerId && !contractorPerformerId) {
            return performerName;
        }

        let to = LINK_CLIENT_INFO.replace(":clientId", clientPerformerId);

        if (contractorPerformerId) {
            to = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorPerformerId);
        }

        return (
            <ExtLink
                to={to}
                historyEnabled
            >
                {performerName}
            </ExtLink>
        );
    }

    const _downloadDocument = ({downloadLink, documentType}) => {
        dispatch(downloadDocument({
            isDownload: true,
            documentType,
            downloadLink,
        }));
    };

    const renderActions = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => _downloadDocument(item),
                        color: "grey",
                        children: "Скачать",
                    },
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            const {
                transactionDatetime,
                contractorPerformerPhone,
                clientUserPerformerEmail,
                contractorPerformerSmsCode,
                performerIpAddress,
                documentType,
                transactionId,
                documentId,
                clientPerformerId,
                contractorPerformerId,
                device,
                network,
            } = item;

            const labels = !clientPerformerId && !contractorPerformerId ? [] : [
                {label: "Идентификатор документа", text: documentId, columnOnMobile: true},
                {label: "№ лога транзакции", text: transactionId, columnOnMobile: true},
                {label: "Подписант", text: renderNameCell(item)},
                contractorPerformerId && {label: "Номер телефона", text: phoneFormat(contractorPerformerPhone)},
                contractorPerformerId && !["ORDER_APPLICATION", "ACT_OF_ACCEPTANCE_OF_WORK"].includes(documentType) &&
                {label: "Код подтверждения из SMS", text: contractorPerformerSmsCode || "-"},
                clientPerformerId && {label: "Логин (e-mail)", text: clientUserPerformerEmail || "-"},
                (clientPerformerId || contractorPerformerId) && {label: "IP-адрес", text: performerIpAddress},
                ...getDeviceInfoLabels(device),
                ...getNetworkInfoLabels(network),
            ];

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={formatDate(convertUtcToLocal(transactionDatetime), "dd.MM.yyyy HH:mm:ss")}
                        primaryHeader={documentTypeDict[documentType]}
                        classNameMainContent="col-16"
                        labels={labels}
                        mediaControls={renderActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    return (
        <NmPage
            noPadding
            heightUnset
            header={
                <NmPageHeader
                    text="Журнал подписания документов"
                />
            }
            isLoaded={progress}
            typeFilter="vertical"
            filtersBase={
                <ContractorEventDocumentLogFilter
                    onChangeFilter={onChangeFilter}
                    initFilter={initFilter}
                    filter={filter}
                    submitFilter={submitFilter}
                    setFilter={setFilter}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {
                list.length
                    ? <CheckboxList
                        rows={getRows()}
                    />
                    : <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
}

export default ContractorEventDocumentLogList;