import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {RecruitmentCandidatesEdit} from "../../../containers/recruitment/candidates/edit";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import {IconButton} from "../../ActualComponents/IconButton";
import NmLabelText from "../../ActualComponents/NmLabelText";
import Text from "../../ActualComponents/Text";
import ButtonBack from "../../ButtonBack";
import NmSelectedList from "../../NmSelectedList";
import NmTitle from "../../NmTitle";
import {RecruitmentCandidateAvailableIndicator} from "../CandidateAvailableIndicator";
import {RecruitmentCandidateAvatar} from "../CandidateAvatar";
import {RecruitmentContacts} from "../Contacts";
import {RecruitmentContractorAvailableIndicator} from "../ContractorAvailableIndicator";
import {ExternalResumeLink} from "../ExternalResumeLink";
import {HhPolitenessIndex} from "../HhPolitenessIndex";
import RecruitmentResponseSource from "../ResponseSource";
import {RecruitmentCandidatesCardHeaderContextMenu} from "./components/ContextMenu";

import {useRecruitmentVacancyDownloadResume} from "../../../containers/recruitment/vacancies/hooks/useDownloadResume";
import {useFetchDicts} from "../../../hooks/useFetchDict";
import {useRecruitmentCandidatesCardHeaderActions} from "./hooks/useAction";

import {getUserRole, isVisibleRecruitmentActions} from "../../../utils/access";
import {getClassNames} from "../../../utils/classNames";
import {getContractorCitizenGenderAgeInfo} from "../../../utils/contractors";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {phoneFormat} from "../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {ADMIN, CLIENT_ADMIN, RECRUITER} from "../../../constants/roles";
import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../../containers/recruitment/vacancies/constants";

import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";
import {getCitizenshipSelector} from "../../../ducks/contractor";

import "./style.sass";

const RecruitmentCandidatesCardHeader = (props) => {
    const {
        clientId,
        candidateId,
        fetchList,
        responseId,
        vacancyId,
        card,
        activeFunnelStatusFilter,
        isVacancyCandidate,
        fetchCard,
        buttonFunnelMoveName,
        reject,
        isNewResponses,
        cardLink,
        progressAction,
    } = props;

    const citizenshipDict = useSelector(getCitizenshipSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    useFetchDicts();

    const additionalInformation = getContractorCitizenGenderAgeInfo({
        citizenship: card.citizenship ? citizenshipDict[card.citizenship] : null,
        gender: card.gender,
        age: card.age,
    });

    const {
        onClickEditPersonalData,
        onClickBack,
        isOpenEditPersonalData,
        onCloseEditPersonalData,
        editPersonalData,
    } = useRecruitmentCandidatesCardHeaderActions({
        clientId,
    });

    const {
        openResume,
    } = useRecruitmentVacancyDownloadResume();

    const role = getUserRole();
    const isAccessManageCandidatesAdmin = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.manageCandidatesAdmin);
    const isAccessManageVacanciesAdmin = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin);
    const isAccessEditCandidate = isVisibleRecruitmentActions()
        && isAccessManageCandidatesAdmin
        && isAccessManageVacanciesAdmin
        && !responseId;

    const getCreationCandidateData = () => {
        return dateFormat(convertUtcToLocal(card.createDate), "dd.MM.yyyy HH:mm");
    };

    const getContacts = () => {
        if (!card.contacts) {
            return "-";
        }

        return (
            <RecruitmentContacts
                vacancyId={vacancyId}
                clientId={clientId}
                fio={card.name}
                contacts={card.contacts}
                responseId={responseId}
                candidateId={candidateId}
            />
        );
    };

    const getInfoBlocks = () => {
        const phone = {
            label: "Телефон",
            text: card.phone ? phoneFormat(card.phone) : "Сведения пока не добавлены",
        };
        const info = {
            text: additionalInformation.length ? additionalInformation : "-",
        };
        const email = {
            label: "E-mail",
            text: card.email || "Сведения пока не добавлены",
        };
        const availableOnSite = {
            label: "Есть на площадке",
            text: (
                <RecruitmentContractorAvailableIndicator
                    contractorId={card.contractorId}
                    contractorName={card.contractorName}
                />
            ),
        };
        const communicationMethods = (
            !!card.phone
            && isAccessManageCandidatesAdmin
            && isAccessManageVacanciesAdmin
        ) && {
            label: "Способы связи",
            columnOnMobile: true,
            noWrap: false,
            alignItems: "center",
            text: getContacts(),
        };

        const candidateCreatedText = `${getCreationCandidateData()} ${card.authorName || ""}`;
        const candidateCreated = {
            label: "Кандидат создан",
            text: candidateCreatedText,
            textTitle: candidateCreatedText,
        };

        if (responseId) {
            const linkWithCandidate = {
                label: "Привязан к кандидату",
                text: (
                    <RecruitmentCandidateAvailableIndicator
                        candidateId={card.candidateId}
                        candidateName={card.candidateName}
                        similarCandidate={card.similarCandidate}
                        clientId={clientId}
                    />
                ),
                columnOnMobile: Boolean(card.candidateId),
                noWrap: false,
            };
            const city = {
                label: "Город",
                text: card.workAddressInfo || "Сведения пока не добавлены",
            };
            const source = {
                label: "Источник",
                alignItems: "center",
                text: (
                    <RecruitmentResponseSource
                        source={card.source}
                        isShowBoardNameText={false}
                    />
                ),
            };
            const responseSource = card.source?.responseSource;
            const politenessIndex = responseSource === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER
                ? {
                    label: "Индекс вежливости hh",
                    noWrap: false,
                    flexWrap: true,
                    text: (
                        <HhPolitenessIndex
                            source={card.source}
                            hhPolitenessIndex={card.hhPolitenessIndex}
                        />
                    ),
                }
                : null;
            const isNaimixResume = card.contractorId && responseSource === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX;
            const isResume = card.resume || isNaimixResume;
            const resume = {
                label: "Резюме",
                cursorPointer: true,
                text: (
                    <div className="d-flex align-items-center">
                        <Text
                            level="3"
                            title="Открыть в новой вкладке"
                            color={COLOR.PASSIVE_100}
                            onClick={() => {
                                openResume({
                                    isNaimixResume,
                                    responseId,
                                    fileName: card.resume?.fileName,
                                    contractorId: card.contractorId,
                                });
                            }}
                        >
                            Открыть
                        </Text>
                        {
                            card.resume?.originalUrl &&
                            <ExternalResumeLink
                                className="ms-2"
                                originalUrl={card.resume?.originalUrl}
                            />
                        }
                    </div>
                ),
            };

            return [
                info,
                linkWithCandidate,
                city,
                source,
                phone,
                politenessIndex,
                email,
                communicationMethods,
                availableOnSite,
                isResume && resume,
            ];
        }

        const specialities = {
            text: isEmpty(card.specialities)
                ? "-"
                : (
                    <NmSelectedList
                        type="page"
                        showListWithoutValue
                        showedcardsCount={1}
                        modalTitle="Виды деятельности"
                        highlightingFirstcard={false}
                        isShowDetailModal={true}
                        list={card.specialities}
                    />
                ),
        };
        const birthDate = {
            label: "Дата рождения",
            text: card.birthDate ?
                dateFormat(new Date(card.birthDate), "dd.MM.yyyy") :
                "Сведения пока не добавлены",
        };
        const cities = {
            label: "Город",
            text: !isEmpty(card.cities) ? (
                <NmSelectedList
                    type="page"
                    showListWithoutValue
                    modalTitle="Город"
                    showedcardsCount={1}
                    isShowDetailModal={true}
                    highlightingFirstcard={false}
                    list={card.cities}
                />
            ) : "Сведения пока не добавлены",
        };

        return  [
            specialities,
            phone,
            info,
            email,
            birthDate,
            availableOnSite,
            cities,
            communicationMethods,
            candidateCreated,
        ];
    };

    const getInfo = () => {
        const infoBlocks = getInfoBlocks();

        return infoBlocks
            .filter(Boolean)
            .map((card, index, array) => {
                const isEven = index % 2 === 0;
                const isLastElement = index === array.length - 1;

                return (
                    <NmLabelText
                        {...card}
                        key={index}
                        type="page"
                        className={getClassNames([
                            !isLastElement && "mb-2",
                            isEven ? "col-16 col-xxl-6" : "col-16 col-xxl-10",
                            card.className,
                        ])}
                    />
                );
            });
    };

    return (
        <div className="recruitment-candidates-card-header">
            {
                isOpenEditPersonalData &&
                <RecruitmentCandidatesEdit
                    clientId={clientId}
                    editData={editPersonalData}
                    onClose={onCloseEditPersonalData}
                />
            }
            <div className="recruitment-candidates-card-header__top-content">
                <ButtonBack
                    isMobile={true}
                    className="me-2"
                    onClick={onClickBack}
                />
                <NmTitle
                    size="xl"
                    className="recruitment-candidates-card-header__title"
                >
                    {card.name || "ФИО не указано"}
                </NmTitle>
                {
                    !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin) &&
                    <RecruitmentCandidatesCardHeaderContextMenu
                        card={card}
                        candidateId={candidateId}
                        vacancyId={vacancyId}
                        clientId={clientId}
                        responseId={responseId}
                        fetchList={fetchList}
                        activeFunnelStatusFilter={activeFunnelStatusFilter}
                        isVacancyCandidate={isVacancyCandidate}
                        fetchCard={fetchCard}
                        buttonFunnelMoveName={buttonFunnelMoveName}
                        reject={reject}
                        isNewResponses={isNewResponses}
                        cardLink={cardLink}
                        progressAction={progressAction}
                    />
                }
            </div>
            <div className="d-flex">
                <RecruitmentCandidateAvatar
                    isClearOnClose={false}
                    nameField="activeAvatarBase64Str"
                    responseId={responseId}
                    candidateId={candidateId}
                    isEditable={isAccessEditCandidate}
                    className="recruitment-candidates-card-header__avatar-image me-6"
                />
                <div className="recruitment-candidates-card-header__personal-data">
                    <div className="d-flex justify-content-between justify-content-xxl-start align-cards-center mb-1 mb-xxl-2">
                        <Text.Sub
                            color={COLOR.SECONDARY_100}
                            level="2"
                            medium={true}
                        >
                            Личные данные
                        </Text.Sub>
                        {
                            isAccessEditCandidate &&
                            <IconButton
                                icon={ModeIcon}
                                title="Редактировать данные кандидата"
                                width={24}
                                height={24}
                                className="ms-2"
                                onClick={onClickEditPersonalData}
                            />
                        }
                    </div>
                    <div className="row">
                        {getInfo()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruitmentCandidatesCardHeader;