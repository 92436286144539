export const REVIEWS_CONTRACTOR_GET_PAGE_REQUEST = "REVIEWS_CONTRACTOR_GET_PAGE_REQUEST";
export const REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS = "REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS";
export const REVIEWS_CONTRACTOR_GET_PAGE_ERROR = "REVIEWS_CONTRACTOR_GET_PAGE_ERROR";

export const REVIEWS_CONTRACTOR_GET_COUNT_REQUEST = "REVIEWS_CONTRACTOR_GET_COUNT_REQUEST";
export const REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS = "REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS";
export const REVIEWS_CONTRACTOR_GET_COUNT_ERROR = "REVIEWS_CONTRACTOR_GET_COUNT_ERROR";

export const REVIEWS_CONTRACTOR_UPDATE_REQUEST = "REVIEWS_CONTRACTOR_UPDATE_REQUEST";
export const REVIEWS_CONTRACTOR_UPDATE_SUCCESS = "REVIEWS_CONTRACTOR_UPDATE_SUCCESS";
export const REVIEWS_CONTRACTOR_UPDATE_ERROR = "REVIEWS_CONTRACTOR_UPDATE_ERROR";

export const REVIEWS_CONTRACTOR_DELETE_REQUEST = "REVIEWS_CONTRACTOR_DELETE_REQUEST";
export const REVIEWS_CONTRACTOR_DELETE_SUCCESS = "REVIEWS_CONTRACTOR_DELETE_SUCCESS";
export const REVIEWS_CONTRACTOR_DELETE_ERROR = "REVIEWS_CONTRACTOR_DELETE_ERROR";

export const REVIEWS_CONTRACTOR_ADD_REQUEST = "REVIEWS_CONTRACTOR_ADD_REQUEST";
export const REVIEWS_CONTRACTOR_ADD_SUCCESS = "REVIEWS_CONTRACTOR_ADD_SUCCESS";
export const REVIEWS_CONTRACTOR_ADD_ERROR = "REVIEWS_CONTRACTOR_ADD_ERROR";

export const REVIEWS_CONTRACTOR_DECLINE_REQUEST = "REVIEWS_CONTRACTOR_DECLINE_REQUEST";
export const REVIEWS_CONTRACTOR_DECLINE_SUCCESS = "REVIEWS_CONTRACTOR_DECLINE_SUCCESS";
export const REVIEWS_CONTRACTOR_DECLINE_ERROR = "REVIEWS_CONTRACTOR_DECLINE_ERROR";

export const REVIEWS_CONTRACTOR_CONFIRM_REQUEST = "REVIEWS_CONTRACTOR_CONFIRM_REQUEST";
export const REVIEWS_CONTRACTOR_CONFIRM_SUCCESS = "REVIEWS_CONTRACTOR_CONFIRM_SUCCESS";
export const REVIEWS_CONTRACTOR_CONFIRM_ERROR = "REVIEWS_CONTRACTOR_CONFIRM_ERROR";

export const REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST = "REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST";
export const REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS = "REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS";
export const REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR = "REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR";

export const REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST = "REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST";
export const REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS = "REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS";
export const REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR = "REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR";

export const REVIEWS_CONTRACTOR_CLEAR_STORE = "REVIEWS_CONTRACTOR_CLEAR_STORE";