import {
    CLEAR_CONTRACTOR_DOCUMENTS_STORE,
    CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST,
    CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST,
    CONTRACTOR_DOCUMENTS_REFRESH_REQUEST,
    GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST,
    PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST,
} from "./actions";

export const getPageContractorDocuments = (payload) => {
    return {
        type: CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCountsContractorDocuments = (payload) => {
    return {
        type: CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST,
        payload,
    };
};

export const refreshContractorDocuments = (payload) => {
    return {
        type: CONTRACTOR_DOCUMENTS_REFRESH_REQUEST,
        payload,
    };
};

export function getRegistryFrameContractShortItems(payload) {
    return {
        type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST,
        payload,
    };
}
export function getContractorIdentificationSheet(payload) {
    return {
        type: GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST,
        payload,
    };
}

export function getContractorPendingContractsList(payload) {
    return {
        type: PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}

export const clearStoreContractorDocuments = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_DOCUMENTS_STORE,
        payload,
    };
};