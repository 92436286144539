import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getCategorySpecialitiesInitialValues} from "../utils/getInitialValues";
import {getCategorySpecialitiesEditValidation} from "../utils/getValidation";

import {
    addDirectoriesCategorySpecialities,
    getDirectoriesCategorySpecialitiesCard,
    updateDirectoriesCategorySpecialities,
    updateFieldsDirectoriesCategorySpecialities,
} from "../../../../../../../ducks/bff/settings/directories/naimix/category-specialities/actionCreators";
import {
    settingsDirectoriesNaimixCategorySpecialitiesCardSelector,
    settingsDirectoriesNaimixCategorySpecialitiesProgressActionSelector,
    settingsDirectoriesNaimixCategorySpecialitiesProgressCardSelector,
} from "../../../../../../../ducks/bff/settings/directories/naimix/category-specialities/selectors";

export const useCategorySpecialitiesEdit = ({editData, onClose}) => {
    const dispatch = useDispatch();

    const initialValues = useSelector(settingsDirectoriesNaimixCategorySpecialitiesCardSelector);
    const progressCard = useSelector(settingsDirectoriesNaimixCategorySpecialitiesProgressCardSelector);
    const progressAction = useSelector(settingsDirectoriesNaimixCategorySpecialitiesProgressActionSelector);

    useEffect(() => {
        if (editData) {
            const {id} = editData;

            dispatch(getDirectoriesCategorySpecialitiesCard({id}));
        }

        return () => {
            dispatch(updateFieldsDirectoriesCategorySpecialities({card: {}}));
        };
    }, []);

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: getCategorySpecialitiesInitialValues(initialValues),
        validationSchema: getCategorySpecialitiesEditValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        if (
            name === "fieldCode" &&
            !/^[0-9a-zA-Z]+$/.test(value) &&
            value !== ""
        ) {
            return;
        }

        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        if (editData) {
            const {id} = editData;

            dispatch(updateDirectoriesCategorySpecialities({
                ...values,
                iconCode: initialValues.iconCode,
                id,
                onSuccess: onClose,
            }));

            return;
        }

        dispatch(addDirectoriesCategorySpecialities({
            ...values,
            onSuccess: onClose,
        }));
    }

    return {
        errors,
        handleSubmit,
        values,
        progressCard,
        progressAction,
        onChange,
        touched,
    };
};