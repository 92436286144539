export const  SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST = "SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST";
export const  SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS = "SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS";
export const  SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR = "SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR";

export const  SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST = "SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST";
export const  SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS = "SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS";
export const  SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR = "SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR";

export const  SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST = "SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST";
export const  SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS = "SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS";
export const  SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR = "SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR";

export const UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE = "UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE";