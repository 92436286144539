import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import Box from "../../../../components/ActualComponents/Box";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import AgencyContractInfoEdit from "../../components/info-edit";

import dateFormat from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {
    isNullOrWhitespace,
} from "../../../../utils/stringHelper";

import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

class AgencyContractInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorForm: {},
            isEdit: false,
        };

        this.role = ls(USER_ROLE);
        this.isEditable = this.role === ADMIN || this.role === NM_MANAGER;
    }

    get notContentText() {
        const {t} = this.props;
        return t("is-not-specified.content");
    }

    get nmContractDateConclusionLabel() {
        const {t} = this.props;
        return t("agency-contract-info.nmContractDateConclusion");
    }

    get nmContractNumberLabel() {
        const {t} = this.props;
        return t("agency-contract-info.nmContractNumber");
    }

    onOpenEdit = () => {
        this.setState({
            isEdit: true,
        });
    };

    onCloseEdit = () => {
        this.setState({
            isEdit: false,
        });
    };

    renderCardReadOnly() {
        const {
            card: {
                nmContractNumber,
                nmContractDateConclusion,
            },
        } = this.props;

        return (
            <>
                <NmLabelText
                    type="page"
                    label={this.nmContractNumberLabel}
                    className="flex-column mb-2"
                    text={nmContractNumber || this.notContentText}
                />
                <NmLabelText
                    type="page"
                    label={this.nmContractDateConclusionLabel}
                    className="flex-column"
                    text={!isNullOrWhitespace(nmContractDateConclusion) ? dateFormat(nmContractDateConclusion, "dd.MM.yyyy") : this.notContentText}
                />
            </>
        );
    }

    render() {
        const {
            isEdit,
        } = this.state;
        const {t, isAccessEdit} = this.props;

        return (
            <Box
                title={t("agency-contract-info.card-title")}
                theme="page"
                isEdit={isAccessEdit}
                onClickEdit={this.onOpenEdit}
            >
                {this.renderCardReadOnly()}
                {
                    isEdit &&
                    <AgencyContractInfoEdit
                        onClose={this.onCloseEdit}
                    />
                }
            </Box>
        );
    }
}

export default connect(
    state => ({
        card: clientCardInfoSelector(state),
    }),
    null,
)(withTranslation()(AgencyContractInfo));
