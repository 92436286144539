import {useEffect} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {calculateAge} from "../../../utils/dateFormat";
import {getFullRegistrationSteps} from "../utils/getSteps";
import {getFullRegistrationStepsConfig} from "../utils/getStepsConfig";

import {citizenshipsDict} from "../../../constants/citizenships";
import {FULL_REGISTRATION_GENDER_DICT_OPTIONS, FULL_REGISTRATION_STEP} from "../constants";

import {contractorCardInfoSelector} from "../../../ducks/bff/contractor-сard/selectors";
import {
    contractorVhiPolicyFormatsDictSelector,
    contractorVhiPolicyTypesDictSelector,
    getCitizenshipWithFlagsOptionsSelector,
} from "../../../ducks/contractor";
import {getPassportTypeOptionsSelector} from "../../../ducks/dict";

export const useFullRegistrationSteps = (params) => {
    const {
        setSteps,
        values = {},
        migrationStatusOptions,
    } = params;

    const vhiPolicyTypeDict = useSelector(contractorVhiPolicyTypesDictSelector);
    const vhiPolicyFormatDict = useSelector(contractorVhiPolicyFormatsDictSelector);
    const passportTypeOptions = useSelector(getPassportTypeOptionsSelector);
    const citizenshipOptions = useSelector(getCitizenshipWithFlagsOptionsSelector);
    const contractor = useSelector(contractorCardInfoSelector);

    useEffect(() => {
        if (isEmpty(values)) {
            return;
        }

        const steps = getFullRegistrationSteps({
            citizenship: values.citizenship,
            migrationStatus: values.migrationStatus,
            locatedOutsideRussia: values.locatedOutsideRussia,
        });

        const formSteps = getFullRegistrationStepsConfig({
            steps,
            isRequiredFields: false,
            citizenship: values.citizenship,
            migrationStatus: values.migrationStatus,
            locatedOutsideRussia: values.locatedOutsideRussia,
            vhiPolicyTypeDict,
            vhiPolicyFormatDict,
            passportTypeOptions,
            genderOptions: FULL_REGISTRATION_GENDER_DICT_OPTIONS,
            citizenshipOptions: contractor.locatedOutsideRussia ?
                citizenshipOptions.filter(({value}) =>
                    ![
                        citizenshipsDict.RU.value,
                        citizenshipsDict.NOT_SPECIFIED.value,
                    ].includes(value),
                ) :
                citizenshipOptions,
            migrationStatusOptions,
            contractor,
        });

        if (values.birthDate) {
            const age = calculateAge(values.birthDate);

            // Если самозанятому 18, то удаляем шаг с согласием родителей
            if (age > 17) {
                const steps = formSteps.filter((item) => ![
                    FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
                ].includes(item.value));

                setSteps(steps);

                return;
            }
        }

        setSteps(formSteps);
    }, [
        migrationStatusOptions,
        vhiPolicyTypeDict,
        vhiPolicyFormatDict,
        passportTypeOptions,
        values.citizenship,
        values.migrationStatus,
        values.birthDate,
    ]);

    return {
    };
};