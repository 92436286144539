import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearClientsMonitoringRisksContractorsStore,
    getClientsMonitoringRisksContractors,
} from "../../../../ducks/bff/clients/monitoring-risk/actionCreators";

export function useClientMonitoringRiskFetchContractorsList(props) {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
        riskId,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearClientsMonitoringRisksContractorsStore());
        };
    }, []);

    useEffect(() => {
        dispatch(getClientsMonitoringRisksContractors({
            clientId,
            pageNum,
            pageSize,
            riskId,
            ...filterData,
        }));
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);
}