import {all, put, takeEvery} from "@redux-saga/core/effects";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/client/speciality/offer";

const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST";
const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS";
const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR";

const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST";
const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS";
const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR";

const initial = {
    progress: false,
    nonDecisionCount: 0,
    offers: [],
    isOffersLoaded: false,
    isDictionaryLoaded: false,
    clientSpecialityOffers: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS:
            return {
                ...state,
                progress: false,
                nonDecisionCount: payload,
            };
        case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

export const getNonDecisionCount = () => ({
    type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST,
});

export const findByOrderAndClientIds = payload => ({
    type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST,
    payload,
});

export const findByOrderAndClientIdsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            reqData,
        } = payload;

        const result = yield request.post(`${controller}/findByOrderAndClientIds`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        if (callBack) {
            callBack(result);
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR,
            payload: error,
        });
    }
};

export const getNonDecisionCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getNonDecisionCount`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST, getNonDecisionCountSaga),
        takeEvery(CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST, findByOrderAndClientIdsSaga),
    ]);
}