import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as EditIcon} from "../../../images/mode.svg";
import CollectionBankDetailsAddModal from "./add-modal";

import {typesLidDict} from "./hooks/useCollectionBankDetailsFilter";
import {useCollectionBankDetailsList} from "./hooks/useCollectionBankDetailsList";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {ADMIN} from "../../../constants/roles";

import {
    collectionBankDetailsPageProgressSelector,
    collectionBankDetailsPageSelector,
    collectionBankDetailsProgressSelector,
    collectionBankDetailsTotalCountSelector,
    collectionBankDetailsTotalPagesSelector,
} from "../../../ducks/bff/settings/collection-bank-details/selectors";
import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";

import "./style.sass";

function CollectionBankDetails() {
    const list = useSelector(collectionBankDetailsPageSelector);
    const totalCount = useSelector(collectionBankDetailsTotalCountSelector);
    const totalPages = useSelector(collectionBankDetailsTotalPagesSelector);
    const pageProgress = useSelector(collectionBankDetailsPageProgressSelector);
    const progressAction = useSelector(collectionBankDetailsProgressSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);
    const isAccessActions = [ADMIN].includes(role)
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingBankRequisites);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        filtersBase,
        submitFilter,
        clearFilter,
        toggleAddModal,
        isOpenAddModal,
        fetchList,
        currentItem,
        setCurrentItem,
        closeEditModal,
        confirmData,
        setConfirmData,
        handleCloseConfirm,
        handleDelete,
    } = useCollectionBankDetailsList();

    const {onConfirm, confirmContent} = confirmData;

    function getRows() {
        return list.map(item => {
            const {
                projectParam,
                addedByClientUserFirstName,
                addedByClientUserLastName,
                addedByClientUserPatronymic,
                leadType,
                addedDateTime,
            } = item;
            return {
                ...item,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Условия от ${formatLocalDate(addedDateTime, "dd.MM.yyyy HH:mm")}`}
                        classNameMainContent="col-16 col-md-7 col-xl-10 col-xxl-10"
                        labels={[
                            {

                                label: "ProjectParam",
                                text: projectParam || "-",
                            },
                            {

                                label: "Тип лида",
                                text: typesLidDict[leadType] || "-",
                            },
                            {

                                label: "Инициатор",
                                text: getFullName(addedByClientUserLastName, addedByClientUserFirstName, addedByClientUserPatronymic),
                            },
                        ]}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 2,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: () => {
                                            setCurrentItem(item);
                                            toggleAddModal();
                                        },
                                        onlyIcon: true,
                                        icon: <EditIcon />,
                                    },
                                    asset: {
                                        mobile: {children: "Редактровать"},
                                    },
                                    visible: isAccessActions,
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: () => {
                                            setCurrentItem(item);
                                            setConfirmData({
                                                onConfirm: () => handleDelete(item.id),
                                                confirmContent: "Вы действительно хотите удалить условие?",
                                            });
                                        },
                                        onlyIcon: true,
                                        icon: <DeleteIcon />,
                                    },
                                    asset: {
                                        mobile: {
                                            children: "Удалить",
                                        },
                                    },
                                    visible: isAccessActions,
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    }

    return (
        <NmPage
            className="collection-bank-details"
            header={<NmPageHeader
                text="Сбор банковских реквизитов"
                addingTitle={<HelpTooltip
                    position="bottom-right"
                    width={24}
                    height={24}
                    text="При регистрации исполнителя с ProjectParam и типом лида, которые одновременно совпадают с этими параметрами в одном
                    из созданных в данном разделе условий, этому исполнителю будет установлен признак обязательности заполнения банковских платежных реквизитов."
                />}
            />}
            controls={
                isAccessActions &&
                <NmButton
                    onClick={toggleAddModal}
                    icon={<AddIcon />}
                    size="xl"
                >
                    Добавить условие
                </NmButton>
            }
            typeFilter="vertical"
            filtersBase={<Filter
                initState={{leadTypeFilter: "ALL"}}
                filters={filtersBase}
                onSubmit={submitFilter}
                clearFilter={clearFilter}
            />}
            noPadding
            currentPageNum={pageNum}
            totalPages={totalPages}
            currentPageSize={pageSize}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={pageProgress}
        >
            {!isEmpty(confirmData) && <NmConfirmV2
                loading={progressAction}
                onCancel={handleCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                content={confirmContent}
                size="sm"
            />}
            {isOpenAddModal && <CollectionBankDetailsAddModal
                card={currentItem}
                onClose={closeEditModal}
                fetchList={fetchList}
            />}
            {list.length === 0 && <NmEmptyPageV2
                title="Информация отсутствует"
                fetchProgress={pageProgress}
            />
            }
            {list.length > 0 && <CheckboxList
                rows={getRows()}
            />}
        </NmPage>
    );
}

export default CollectionBankDetails;