import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffUrl} from "../utils/url";

import {LINK_CONTRACTORS_RESOURCES} from "../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [LINK_CONTRACTORS_RESOURCES]: "/adm/finances/mutual-settlements-reconciliation-act",
            isClientCard: true,
            clientRolesUrl: "/client-adm/documents/mutual-settlements-reconciliation-act",
            adminRolesUrl: "/adm/clients/client-card/documents/mutual-settlements-reconciliation-act",
        })
    );
};

//*  TYPES  *//

const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_REQUEST = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_REQUEST";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_SUCCESS = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_SUCCESS";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_ERROR = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ERROR";

const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_REQUEST = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_REQUEST";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_SUCCESS = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_SUCCESS";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_ERROR = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_ERROR";

const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_REQUEST = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_REQUEST";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_SUCCESS = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_SUCCESS";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_ERROR = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_ERROR";

const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_REQUEST = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_REQUEST";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_SUCCESS = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_SUCCESS";
const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_ERROR = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_ERROR";

const MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_UPDATE_STORE = "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    progress: false,
    pageData: {},
    progressAction: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                progress: false,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_SUCCESS:
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_ERROR:
            return {
                ...state,
                progressAdd: false,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_REQUEST:
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_SUCCESS:
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_ERROR:
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_SUCCESS:
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export function getMutualSettlementsReconciliationActs(payload) {
    return {
        type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_REQUEST,
        payload,
    };
}

export function exportMutualSettlementsReconciliationAct(payload) {
    return {
        type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_REQUEST,
        payload,
    };
}

export function addMutualSettlementsReconciliationAct(payload) {
    return {
        type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_REQUEST,
        payload,
    };
}

export function deleteMutualSettlementsReconciliationActById(payload) {
    return {
        type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_REQUEST,
        payload,
    };
}

export function updateMutualSettlementsReconciliationActsStore(payload) {
    return {
        type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_UPDATE_STORE,
        payload,
    };
}


//*  SELECTORS  *//
export const mutualSettlementsReconciliationActSelector = state => state.mutualSettlementsReconciliationActs;
export const mutualSettlementsReconciliationActListSelector = createSelector(mutualSettlementsReconciliationActSelector, ({list}) => list);
export const mutualSettlementsReconciliationActTotalPagesSelector = createSelector(mutualSettlementsReconciliationActSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});
export const mutualSettlementsReconciliationActTotalCountSelector = createSelector(mutualSettlementsReconciliationActSelector, ({totalCount}) => totalCount);
export const mutualSettlementsReconciliationActProgressSelector = createSelector(mutualSettlementsReconciliationActSelector, ({progress}) => progress);
export const mutualSettlementsReconciliationActProgressActionSelector = createSelector(mutualSettlementsReconciliationActSelector, ({progressAction}) => progressAction);
export const mutualSettlementsReconciliationActProgressAddSelector = createSelector(mutualSettlementsReconciliationActSelector, ({progressAdd}) => progressAdd);

//*  SAGA  *//

// Получение страницы
export const getMutualSettlementsReconciliationActsSaga = function* (action) {
    const url = `${getController()}/getPage`;

    try {
        const result = yield request.bff.post(url, action.payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// Инициализация выгрузки
export const exportMutualSettlementsReconciliationActSaga = function* (action) {
    const url = `${getController()}/export`;

    try {
        const result = yield request.bff.post(url, action.payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт отчетов\"");

        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_ERROR,
            payload: error,
        });
    }
};

// Добавление акта
export const addMutualSettlementsReconciliationActSaga = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    const url = `${getController()}/add`;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_ERROR,
            payload: error,
        });
    }
};

// Удаление акта
export const deleteMutualSettlementsReconciliationActByIdSaga = function* ({payload}) {
    const {
        onSuccess,
        actId,
    } = payload;

    const url = `${getController()}/deleteById`;

    try {
        const result = yield request.bff.delete(`${url}?actId=${actId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_GET_PAGE_REQUEST, getMutualSettlementsReconciliationActsSaga),
        takeEvery(MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT_REQUEST, exportMutualSettlementsReconciliationActSaga),
        takeEvery(MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_ADD_REQUEST, addMutualSettlementsReconciliationActSaga),
        takeEvery(MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_DELETE_BY_ID_REQUEST, deleteMutualSettlementsReconciliationActByIdSaga),
    ]);
}
