import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getSpecialitiesPage} from "./actionCreators";
import {
    ADD_SPECIALTY_ERROR,
    ADD_SPECIALTY_REQUEST,
    ADD_SPECIALTY_SUCCESS,
    DELETE_SPECIALTY_ERROR,
    DELETE_SPECIALTY_REQUEST,
    DELETE_SPECIALTY_SUCCESS,
    GET_PAGE_SPECIALITIES_ERROR,
    GET_PAGE_SPECIALITIES_REQUEST,
    GET_PAGE_SPECIALITIES_SUCCESS,
    UPDATE_SPECIALTY_ERROR,
    UPDATE_SPECIALTY_REQUEST,
    UPDATE_SPECIALTY_SUCCESS,
} from "./actions";
import {
    settingsDirectoriesNaimixSpecialitiesPageDataSelector,
} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/specialty-list";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsDirectoriesNaimixSpecialitiesPageDataSelector(state);

    yield put(getSpecialitiesPage(pageData));
};

const getSpecialitiesPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_SPECIALITIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const addSpecialtySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_SPECIALTY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {value} = payload;
        toastSuccess(`Вид деятельности "${value}" успешно добавлен`);

        yield fetchList();

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_SPECIALTY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_SPECIALTY_ERROR,
            payload: error,
        });
    }
};

const updateSpecialtySaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.patch(`${controller}/${id}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_SPECIALTY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        yield fetchList();

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_SPECIALTY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_SPECIALTY_ERROR,
            payload: error,
        });
    }
};

const deleteSpecialtySaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_SPECIALTY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Вид деятельности успешно удален");

        yield fetchList();

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_SPECIALTY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_SPECIALTY_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_SPECIALITIES_REQUEST, getSpecialitiesPageSaga),
        takeEvery(UPDATE_SPECIALTY_REQUEST, updateSpecialtySaga),
        takeEvery(DELETE_SPECIALTY_REQUEST, deleteSpecialtySaga),
        takeEvery(ADD_SPECIALTY_REQUEST, addSpecialtySaga),
    ]);
}