import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {ReactComponent as DescriptionIcon} from "../../../../images/description_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import NmListCard from "../../../ActualComponents/NmList/Card";
import CheckboxList from "../../../CheckboxList";
import InstructionEdit from "../edit";

import {useEdit} from "../../hooks/useEdit";

import dateFormat from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";
import {COMPONENT} from "../../../ActualComponents/MediaControls/constants";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

export const InstructionsPage = (props) => {
    const {
        fetchList,
        instructionInfoGroup,
        addFile,
        save,
        instructionTypeDict,
        isKedo,
    } = props;

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);
    const {t} = useTranslation();

    const {
        open,
        onOpenEdit,
        onCloseEdit,
        modalData,
    } = useEdit();

    useEffect(() => {
        fetchList();
    }, []);

    const getFile = (item) => {
        props.getFile(item);
    };

    const getLabel = (item) => {
        return (
            <div className="instruction__file">
                <DescriptionIcon
                    width={24}
                    height={24}
                    className="instruction__file-icon"
                />
                <div
                    title="Скачать инструкцию"
                    className="instruction__file-value"
                    onClick={() => {
                        getFile(item);
                    }}
                >
                    {item.filename}
                </div>
            </div>
        );
    };

    const getRows = () => {
        return instructionInfoGroup.map(item => {
            const {
                instructionType,
                version,
                date,
            } = item;

            return {
                ...item,
                key: instructionType,
                contentRow: (
                    <NmListCard
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-xxl-15"
                        secondaryHeader={`${t("instruction.version")} ${version} ${t("instruction.dated")} ${dateFormat(date, "dd.MM.yyyy")}`}
                        primaryHeader={instructionTypeDict[instructionType].TEXT}
                        primaryHeaderNoWrap
                        labels={[{text: getLabel(item)}]}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={{
                            renderCount: {
                                mobile: 1,
                                tablet: 1,
                                desktop: 1,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        icon: <ModeIcon />,
                                        onlyIcon: true,
                                        onClick: () => {
                                            onOpenEdit(item);
                                        },
                                    },
                                    visible: [ADMIN, NM_MANAGER].includes(role)
                                        && !(!isKedo && currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editUserInstructions)),
                                    asset: {
                                        mobile: {size: "sm"},
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    return (
        <>
            {
                open &&
                <InstructionEdit
                    onClose={onCloseEdit}
                    addFile={addFile}
                    save={save}
                    data={modalData}
                    instructionTypeDict={instructionTypeDict}
                />
            }
            <CheckboxList rows={getRows()} />
        </>
    );
};
