import {all, put, takeEvery} from "redux-saga/effects";

import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/orders/group";
//*  TYPES  *//

const UPLOAD_ORDER_LIST_REQUEST = "UPLOAD_ORDER_LIST_REQUEST";
const UPLOAD_ORDER_LIST_SUCCESS = "UPLOAD_ORDER_LIST_SUCCESS";
const UPLOAD_ORDER_LIST_ERROR = "UPLOAD_ORDER_LIST_ERROR";

const DOWNLOAD_ORDER_GROUPS_REQUEST = "DOWNLOAD_ORDER_GROUPS_REQUEST";
const DOWNLOAD_ORDER_GROUPS_SUCCESS = "DOWNLOAD_ORDER_GROUPS_SUCCESS";
const DOWNLOAD_ORDER_GROUPS_ERROR = "DOWNLOAD_ORDER_GROUPS_ERROR";

const ADD_ORDERS_TO_GROUP_REQUEST = "ADD_ORDERS_TO_GROUP_REQUEST";
const ADD_ORDERS_TO_GROUP_SUCCESS = "ADD_ORDERS_TO_GROUP_SUCCESS";
const ADD_ORDERS_TO_GROUP_ERROR = "ADD_ORDERS_TO_GROUP_ERROR";

const DELETE_ORDERS_FROM_GROUP_REQUEST = "DELETE_ORDERS_FROM_GROUP_REQUEST";
const DELETE_ORDERS_FROM_GROUP_SUCCESS = "DELETE_ORDERS_FROM_GROUP_SUCCESS";
const DELETE_ORDERS_FROM_GROUP_ERROR = "DELETE_ORDERS_FROM_GROUP_ERROR";

//*  INITIAL STATE  *//

const initial = {
    card: {},
    actionProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type}) => {
    switch (type) {
        case DOWNLOAD_ORDER_GROUPS_REQUEST:
        case UPLOAD_ORDER_LIST_REQUEST:
        case ADD_ORDERS_TO_GROUP_REQUEST:
        case DELETE_ORDERS_FROM_GROUP_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case DOWNLOAD_ORDER_GROUPS_SUCCESS:
        case UPLOAD_ORDER_LIST_SUCCESS:
        case ADD_ORDERS_TO_GROUP_SUCCESS:
        case DELETE_ORDERS_FROM_GROUP_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case DOWNLOAD_ORDER_GROUPS_ERROR:
        case UPLOAD_ORDER_LIST_ERROR:
        case ADD_ORDERS_TO_GROUP_ERROR:
        case DELETE_ORDERS_FROM_GROUP_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function uploadOrderList(payload) {
    return {
        type: UPLOAD_ORDER_LIST_REQUEST,
        payload,
    };
}

export function downloadOrderGroups(payload) {
    return {
        type: DOWNLOAD_ORDER_GROUPS_REQUEST,
        payload,
    };
}

export function addOrdersToGroup(payload) {
    return {
        type: ADD_ORDERS_TO_GROUP_REQUEST,
        payload,
    };
}

export function deleteOrdersFromGroup(payload) {
    return {
        type: DELETE_ORDERS_FROM_GROUP_REQUEST,
        payload,
    };
}

//*  SAGA  *//

//POST /api/orders/group/uploadOrderList
const uploadOrderListSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            orderGroupId,
            type,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`/job/import/orders/import/${clientId}/${orderGroupId}/${type}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPLOAD_ORDER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Задача по импорту заказов передана в обработку");

        yield put({
            type: UPLOAD_ORDER_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPLOAD_ORDER_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/group/downloadOrderGroups
export const downloadOrderGroupsSaga = function* ({payload}) {
    try {
        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };

        const result = yield request.getFile(`${window.location.origin}/api${controller}/downloadOrderGroups`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                yield put({type: DOWNLOAD_ORDER_GROUPS_ERROR, payload: result});
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();
        yield downloadBlob(blob, headers);

        yield put({type: DOWNLOAD_ORDER_GROUPS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: DOWNLOAD_ORDER_GROUPS_ERROR, payload: error.message});
    }
};

//POST /api/orders/group/addOrdersToGroup
export const addOrdersToGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/addOrdersToGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ORDERS_TO_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_ORDERS_TO_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_ORDERS_TO_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/deleteOrdersFromGroup
export const deleteOrdersFromGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteOrdersFromGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ORDERS_FROM_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_ORDERS_FROM_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ORDERS_FROM_GROUP_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(UPLOAD_ORDER_LIST_REQUEST, uploadOrderListSaga),
        takeEvery(DOWNLOAD_ORDER_GROUPS_REQUEST, downloadOrderGroupsSaga),
        takeEvery(ADD_ORDERS_TO_GROUP_REQUEST, addOrdersToGroupSaga),
        takeEvery(DELETE_ORDERS_FROM_GROUP_REQUEST, deleteOrdersFromGroupSaga),
    ]);
}
