import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {toastError} from "../../../../../../utils/toastHelper";
import {getAdditionalDocumentVerificationFiles} from "../utils/getDocumentFiles";
import {getSavedAdditionalDocumentsDecisions} from "../utils/getSavedDecisions";

import {LINK_CONTRACTOR_PERSONAL_INFO} from "../../../../../../constants/links";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS,
} from "../constants";

import {history} from "../../../../../../store/configureStore";

import {getContractorAdditionalDocumentAggregation} from "../../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    contractorAdditionalDocumentsAdministratorCheckListSelector,
    contractorAdditionalDocumentsAggregationSelector,
} from "../../../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {
    getContractorAdditionalDocument,
} from "../../../../../../ducks/contractorAdditionalDocuments";

export const useContractorAdditionalDocumentsVerificationNavigate = (params) => {
    const {
        contractorId,
    } = params;
    const [currentStep, setStep] = useState({
        pendingFileTypes: [], // шаги проверки конкретного документа
    });

    const dispatch = useDispatch();
    const [checkedDocumentFileTypes, setCheckedDocumentFileTypes] = useState([]);

    const documentsAggregation = useSelector(contractorAdditionalDocumentsAggregationSelector);
    const documentsInAdministratorCheckStatus = useSelector(contractorAdditionalDocumentsAdministratorCheckListSelector);

    const fetchDocument = ({changeId, contractorId}) => {
        dispatch(getContractorAdditionalDocument({
            changeId,
            contractorId,
        }));
    };

    useEffect(() => {
        if (!isEmpty(documentsAggregation)) {
            // Если документов в мапе нет, то и находиться в разделе нет смысла
            if (isEmpty(documentsInAdministratorCheckStatus)) {
                toastError("Нет доп. документов для проверки");

                const link = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId);

                history.push(link);

                return;
            }

            // собираем типы документов для проверки
            const stepList = Object.keys(CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS);

            const fileTypes = stepList.filter((fileType) => {
                return documentsInAdministratorCheckStatus.includes(fileType);
            });

            // первый отображаемый тип документа для проверки
            const [firstStepFileType] = fileTypes;

            const stepInfo = documentsAggregation[firstStepFileType];

            setCheckedDocumentFileTypes(fileTypes);

            fetchDocument({
                changeId: stepInfo.changeId,
                contractorId: stepInfo.contractorId,
            });

            updateStep({
                fileType: firstStepFileType,
                fileLinks: stepInfo.fileLinks,
                fileDecisions: stepInfo.fileDecisions,
                position: 0,
            });
        }
    }, [documentsAggregation]);

    const updateStep = (data) => {
        const {
            fileType,
            fileLinks,
            fileDecisions,
            position,
        } = data;

        const pendingFileTypes = getAdditionalDocumentVerificationFiles({
            pendingFileTypes: Object.keys(fileLinks),
            fileType,
        });

        setStep({
            fileType,
            pendingFileTypes,
            position,
            savedFiles: getSavedAdditionalDocumentsDecisions(fileDecisions),
        });
    };

    const updateDocumentStepForm = () => {
        const positionNextFileType = currentStep.position + 1;
        const nextFileType = checkedDocumentFileTypes[positionNextFileType];

        if (nextFileType) {
            const nextStepInfo = documentsAggregation[nextFileType];

            fetchDocument({
                changeId: nextStepInfo.changeId,
                contractorId: nextStepInfo.contractorId,
            });

            updateStep({
                fileType: nextFileType,
                fileLinks: nextStepInfo.fileLinks,
                fileDecisions: nextStepInfo.fileDecisions,
                position: positionNextFileType,
            });

            return;
        }

        handleClose();
    };

    function handleClose() {
        dispatch(getContractorAdditionalDocumentAggregation({
            contractorId,
        }));

        history.push(LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId));
    }

    return {
        updateDocumentStepForm,
        currentStep,
        setStep,
    };
};