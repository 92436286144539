import {isEmpty} from "lodash";

import {CURRENT_CLIENT_USER_ID, ls} from "./localstorage";

import {CROWD_CHAT_MESSAGE_STATE} from "../constants/crowd/chats";

const getCrowdChatUnreadMessageCount = ({unreadMessages = 0, markedUnread = false}) => {
    if (unreadMessages) {
        return unreadMessages;
    }

    return markedUnread ? 1 : 0;
};

export const crowdChatsItemToGeneralFormat = (chat) => {
    const {
        unreadMessageCount,
        unreadMessages,
        lastMessageText,
        lastMessageState,
        markedUnread,
        ...otherParams
    } = chat;

    const currentUserId = ls(CURRENT_CLIENT_USER_ID);

    return {
        ...otherParams,
        markedUnread,
        unreadMessageCount: getCrowdChatUnreadMessageCount({unreadMessages, markedUnread}),
        lastMessage: {
            content: lastMessageText,
            read: lastMessageState === CROWD_CHAT_MESSAGE_STATE.READ,
            userId: [
                CROWD_CHAT_MESSAGE_STATE.READ,
                CROWD_CHAT_MESSAGE_STATE.UNREAD,
            ].includes(lastMessageState) ?
                currentUserId :
                null,
        },
    };
};

export const crowdChatMessagesItemToGeneralFormat = (message) => {
    const {
        senderId,
        senderName,
        state,
        fileInfoList,
        roleType,
        ...otherParams
    } = message;

    return {
        ...otherParams,
        partiesId: senderId,
        partiesType: roleType,
        fullName: senderName,
        read: state === CROWD_CHAT_MESSAGE_STATE.READ,
        files: !isEmpty(fileInfoList)
            ? fileInfoList?.map(item => {
                const {
                    fileLink,
                    fileId,
                    ...otherParams
                } = item;

                return {
                    ...otherParams,
                    fileUuid: fileId,
                    contentUrl: fileLink,
                };
            })
            : [],
    };
};