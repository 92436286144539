import {all, call, put, takeEvery} from "@redux-saga/core/effects";

import {DEPOSIT_SUB_PAGE} from "../../containers/deposit/client-list";
import {
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
    BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_REJECT_ERROR,
    BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_PAYMENTS_REJECT_SUCCESS,
} from "./actions";

import request from "../../utils/postman";
import {toastError, toastSuccess} from "../../utils/toastHelper";
import {getBffUrl} from "../../utils/url";

import {
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
} from "../../constants/links";
import {CONTRACTOR_RECEIPT} from "../../constants/messages";

const controllers = {
    clientPage: {
        npd: {
            clientRoles: "/client-adm/finances/npd-payments",
            adminRoles: "/adm/clients/client-card/finances/npd-payments",
        },
        ndlf: {
            clientRoles: "/client-adm/finances/ndfl-payments",
            adminRoles: "/adm/clients/client-card/finances/ndfl-payments",
        },
        individual: {
            clientRoles: "/client-adm/finances/individual-payments",
            adminRoles: "/adm/clients/client-card/finances/individual-payments",
        },
        depositCompletedPayments: {
            clientRoles: "/client-adm/finances/npd-deposit/completed-payments/order",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/completed-payments/order",
        },
        depositCompletedPaymentsTasks: {
            clientRoles: "/client-adm/finances/npd-deposit/payments-in-process/task",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/payments-in-process/task",
        },
        depositInProgressPayments: {
            clientRoles: "/client-adm/finances/npd-deposit/payments-in-process/order",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/payments-in-process/order",
        },
        depositInProgressPaymentsTasks: {
            clientRoles: "/client-adm/finances/npd-deposit/completed-payments/task",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/completed-payments/task",
        },
        depositPendingPayments: {
            clientRoles: "/client-adm/finances/npd-deposit/pending-payments/order",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/pending-payments/order",
        },
        depositPendingPaymentsTasks: {
            clientRoles: "/client-adm/finances/npd-deposit/pending-payments/task",
            adminRoles: "/adm/clients/client-card/finances/npd-deposit/pending-payments/task",
        },
        ndflDepositCompletedPayments: {
            clientRoles: "/client-adm/finances/ndfl-deposit/completed-payments/contractors",
            adminRoles: "/adm/clients/client-card/finances/ndfl-deposit/completed-payments/contractors",
        },
        ndflDepositInProgressPayments: {
            clientRoles: "/client-adm/finances/ndfl-deposit/payments-in-process/contractors",
            adminRoles: "/adm/clients/client-card/finances/ndfl-deposit/payments-in-process/contractors",
        },
        ndflDepositPendingPayments: {
            clientRoles: "/client-adm/finances/ndfl-deposit/pending-payments/contractors",
            adminRoles: "/adm/clients/client-card/finances/ndfl-deposit/pending-payments/contractors",
        },
    },
    adminPage: {
        npd: "/adm/finances/npd-payments",
        ndlf: "/adm/finances/ndfl-payments",
        individual: "/adm/finances/individual-payments",
    },
};

const links = {
    adminPage: {
        npd: LINK_FINANCE_PAYMENT_LIST,
        ndfl: LINK_FINANCE_NDFL_PAYMENT_LIST,
        individual: LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    },
    clientPage: {
        npd: LINK_CLIENT_PAYMENTS_ORDERS_LIST,
        ndfl: LINK_CLIENT_NDFL_PAYMENTS_LIST,
        individual: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
        depositCompletedPayments: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)}`,
        depositCompletedPaymentsTasks: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)}/${DEPOSIT_SUB_PAGE.TASKS}`,
        depositInProgressPayments: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)}`,
        depositInProgressPaymentsTasks: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)}/${DEPOSIT_SUB_PAGE.TASKS}`,
        depositPendingPayments: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING)}`,
        depositPendingPaymentsTasks: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING)}/${DEPOSIT_SUB_PAGE.TASKS}`,
        ndflDepositCompletedPayments: `${LINK_CLIENT_NDFL_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)}`,
        ndflDepositInProgressPayments: `${LINK_CLIENT_NDFL_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)}`,
        ndflDepositPendingPayments: `${LINK_CLIENT_NDFL_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING)}`,
        depositCompletedPaymentsIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
        depositPaymentsInProgressIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
        depositPaymentsPendingIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
    },
};

const getController = (clientId) => {
    const individualPaymentsController = getBffUrl({
        isClientCard: true,
        clientRolesUrl: controllers.clientPage.individual.clientRoles,
        adminRolesUrl: controllers.clientPage.individual.adminRoles,
    });

    return getBffUrl(
        {
            [links.adminPage.npd]: controllers.adminPage.npd,
            [links.adminPage.ndfl]: controllers.adminPage.ndlf,
            [links.adminPage.individual]: controllers.adminPage.individual,
            [links.clientPage.npd.replace(":clientId", clientId)]: getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.npd.clientRoles,
                adminRolesUrl: controllers.clientPage.npd.adminRoles,
            }),
            [links.clientPage.ndfl.replace(":clientId", clientId)]: getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.ndlf.clientRoles,
                adminRolesUrl: controllers.clientPage.ndlf.adminRoles,
            }),
            [links.clientPage.depositCompletedPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositCompletedPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.depositCompletedPayments.adminRoles,
            }),
            [links.clientPage.depositCompletedPaymentsTasks.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositCompletedPaymentsTasks.clientRoles,
                adminRolesUrl: controllers.clientPage.depositCompletedPaymentsTasks.adminRoles,
            }),
            [links.clientPage.depositInProgressPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositInProgressPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.depositInProgressPayments.adminRoles,
            }),
            [links.clientPage.depositInProgressPaymentsTasks.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositInProgressPaymentsTasks.clientRoles,
                adminRolesUrl: controllers.clientPage.depositInProgressPaymentsTasks.adminRoles,
            }),
            [links.clientPage.depositPendingPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositPendingPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.depositPendingPayments.adminRoles,
            }),
            [links.clientPage.depositPendingPaymentsTasks.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.depositPendingPaymentsTasks.clientRoles,
                adminRolesUrl: controllers.clientPage.depositPendingPaymentsTasks.adminRoles,
            }),
            [links.clientPage.ndflDepositCompletedPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.ndflDepositCompletedPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.ndflDepositCompletedPayments.adminRoles,
            }),
            [links.clientPage.ndflDepositInProgressPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.ndflDepositInProgressPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.ndflDepositInProgressPayments.adminRoles,
            }),
            [links.clientPage.ndflDepositPendingPayments.replace(":clientId", clientId)]:  getBffUrl({
                isClientCard: true,
                clientRolesUrl: controllers.clientPage.ndflDepositPendingPayments.clientRoles,
                adminRolesUrl: controllers.clientPage.ndflDepositPendingPayments.adminRoles,
            }),
            [links.clientPage.individual.replace(":clientId", clientId)]: individualPaymentsController,
            [links.clientPage.depositCompletedPaymentsIndividual.replace(":clientId", clientId)]: individualPaymentsController,
            [links.clientPage.depositPaymentsInProgressIndividual.replace(":clientId", clientId)]: individualPaymentsController,
            [links.clientPage.depositPaymentsPendingIndividual.replace(":clientId", clientId)]: individualPaymentsController,
        },
        controllers.adminPage.npd,
        false,
        true,
    );
};

const getPageFinancesPayments = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController(payload.clientId)}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
        });
    }
};

const confirmFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/confirmPayment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
        });
    }
};

const rejectFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/rejectPayment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_REJECT_ERROR,
        });
    }
};

const createReceiptFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/createReceipt`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
        });
    }
};

const cancelReceiptFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/cancelReceipt`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
        });
    }
};

const getReceiptFinancesPayments = function* ({payload}) {
    try {
        const {
            paymentNumber,
            getSuccess,
            setLoading,
            clientId,
            orderId,
            orderWorkReportId,
        } = payload;

        const {
            errorMessage,
            downloadLink,
        } = yield request.bff.get(`${getController(payload.clientId)}/get-receipt-by-id`, {
            params: {
                paymentNumber,
                clientId,
                orderId,
                orderWorkReportId,
            },
        });

        if (setLoading) {
            yield call(setLoading, false);
        }

        if (errorMessage) {
            yield put({
                type: BFF_FINANCES_PAYMENTS_GET_RECEIPT_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        if (getSuccess) {
            yield call(getSuccess, downloadLink);
        }

        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_RECEIPT_SUCCESS,
            payload: downloadLink,
        });

        toastSuccess(CONTRACTOR_RECEIPT.CREATE_SUCCESS);
    } catch (error) {
        toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_RECEIPT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST, getPageFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST, confirmFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_REJECT_REQUEST, rejectFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST, createReceiptFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST, cancelReceiptFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST, getReceiptFinancesPayments),
    ]);
}