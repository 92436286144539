import {
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE,
} from "./actions";

export const getRecruitmentJobBoardsInitialState = () => {
    return {
        list: [],
        pageData: {},
        progress: false,
        progressAction: false,
        progressAuthorization: false,
    };
};

const initialState = getRecruitmentJobBoardsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST: {
            return {
                ...state,
                progressAuthorization: true,
                pageData: payload,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS: {
            return {
                ...state,
                progressAuthorization: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR: {
            return {
                ...state,
                progressAuthorization: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST: {
            return {
                ...state,
                progressAction: true,
                pageData: payload,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST: {
            return {
                ...state,
                progress: true,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS: {
            return {
                ...state,
                list: payload,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};