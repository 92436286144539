import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const kedoDepartmentsStaff = state => state.kedoDepartmentsStaff;
export const kedoDepartmentsStaffListSelector = createSelector(
    kedoDepartmentsStaff,
    ({list}) => list,
);
export const kedoDepartmentsStaffPageDataSelector = createSelector(
    kedoDepartmentsStaff,
    ({pageData}) => pageData,
);
export const kedoDepartmentsTotalCountSelector = createSelector(
    kedoDepartmentsStaff,
    ({totalCount}) => totalCount,
);
export const kedoDepartmentsTotalPagesSelector = createSelector(
    kedoDepartmentsStaff,
    ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize),
);
export const kedoDepartmentsProgressSelector = createSelector(
    kedoDepartmentsStaff,
    ({progress}) => progress,
);
export const kedoDepartmentsProgressActionSelector = createSelector(
    kedoDepartmentsStaff,
    ({progressAction}) => progressAction,
);
export const kedoDepartmentsErrorSelector = createSelector(
    kedoDepartmentsStaff,
    ({error}) => error,
);