import {
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    UPDATE_CONTRACTOR_REGISTRY_STORE,
} from "./actions";

export const getPageContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
        payload,
    };
};

export const importContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
        payload,
    };
};

export const addContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
        payload,
    };
};

export const updateStoreContractorsRegistry = (payload) => {
    return {
        type: UPDATE_CONTRACTOR_REGISTRY_STORE,
        payload,
    };
};