export const CLIENT_USER_RESTRICTIONS_VARIABLE = {
    editDepositNpd: "editDepositNpd", //Возможность редактирования депозита НПД
    editDepositNdfl: "editDepositNdfl", //Возможность редактирования депозита НДФЛ
    accessCompanySettings: "accessCompanySettings", //Настройка параметров компании
    settingAutoSendPayment: "settingAutoSendPayment", //Настройка автоотправки платежей в банк
    addFavoritesClient: "addFavoritesClient", //Добавление/Удаление из избранного компании
    accessClientLog: "accessClientLog", //Просмотр лога изменений настроек компании
    addClient: "addClient", //Добавление компании
    accessClientArchive: "accessClientArchive", //Архивирование компании
    accessOperatorReports: "accessOperatorReports", //Доступ к подразделу Отчеты оператора
    actionsOperatorReports: "actionsOperatorReports", //Редактирование отчётов оператора
    exportOperatorReportsDocx: "exportOperatorReportsDocx", //Скачивание отчётов оператора в docx
    accessBank: "accessBank", //Доступ к подразделу Финансы > Банк
    accessOrders: "accessOrders", //Доступ к подразделу Финансы > Заказы
    editInterestRateNpd: "editInterestRateNpd", //Редактирование процентной ставки комиссии компании по выплатам НПД
    depositNpdRefill: "depositNpdRefill", //Пополнение депозита компании по выплатам НПД
    depositNpdRefund: "depositNpdRefund", //Возврат депозита компании по выплатам НПД
    editInterestRateNdfl: "editInterestRateNdfl", //Редактирование процентной ставки комиссии компании по выплатам НДФЛ
    depositNdflRefill: "depositNdflRefill", //Пополнение депозита компании по выплатам НДФЛ
    depositNdflRefund: "depositNdflRefund", //Возврат депозита компании по выплатам НДФЛ
    paymentPatentCancel: "paymentPatentCancel", //Отмена оплаты патента со статусом "Платеж не выполнен"
    updateStatusBankPatentPayment: "updateStatusBankPatentPayment", //Обновление статуса патента из банка, просмотр лога взаимодействия с банком
    accessCanceledChecksFts: "accessCanceledChecksFts", //Доступ к подразделу Аннулированные чеки ФНС России
    uploadCanceledChecks: "uploadCanceledChecks", //Массовая выгрузка аннулированных чеков по компании
    unloadFinancesCancelledReceipts: "unloadFinancesCancelledReceipts", //Выгрузка списка аннулированных чеков ФНС
    accessSystemTransactions: "accessSystemTransactions", //Доступ к подразделу Финансы > Системные транзакции
    paymentNpdSolution: "paymentNpdSolution", //Подтверждение/Отклонение оплат НПД
    actionCompanyNpdPayments: "actionCompanyNpdPayments", //Подтверждение/Отклонение оплат НПД компании
    verificationCompanyNpdPayment: "verificationCompanyNpdPayment", //Проверка оплаты в статусе "На подтверждении заказчиком" в компании
    verificationCompanyNdflPayment: "verificationCompanyNdflPayment", //Проверка оплаты в статусе "На подтверждении заказчиком" в компании
    actionsOrderPayments: "actionsOrderPayments", //Действия с оплатами НПД
    paymentNpdReceipt: "paymentNpdReceipt", //Создание и отправка Offline-чеков об оплате НПД
    accessRecruitmentAdmin: "accessRecruitmentAdmin", //ограничение на доступ к рекрутменту для юзеров наймикса
    accessClientCrowdTasks: "accessClientCrowdTasks", //Доступ к разделу "Задания"
    accessFinancesExport: "accessFinancesExport", //Доступ к вкладке Отчеты компании
    actionsClient1c: "actionsClient1c", //Действия для выгрузки отчёта в 1С
    accessDevClient1c: "accessDevClient1c", //Отображение раздела обработчиков для 1С
    accessCrowdTasks: "accessCrowdTasks", //Доступ к подразделу Финансы > Задания
    addCrowdPayments: "addCrowdPayments", //Формирование актов по заданию
    payCrowdRegistry: "payCrowdRegistry", //Запуск и повтор строк реестра актов по заданиям
    actionsCrowdTasksList: "actionsCrowdTasksList", //Действия на списковой форме заданий: создание, завершение, архив, удаление. публикация, продление периода
    actionsCrowdTasksCard: "actionsCrowdTasksCard", //Действия в карточке задания: завершение, приглашение, дубликат, продление, проверка работы
    actionsCrowdTaskGroups: "actionsCrowdTaskGroups", //Действия на списковой форме группы заданий: добавление, редактирование, удаление, архив
    actionsCrowdTaskOneGroup: "actionsCrowdTaskOneGroup", //Действия в карточке группы заданий
    blockCrowdTaskChat: "blockCrowdTaskChat", //Блокировка/Разблокировка исполнителя в чате по заданиям
    actionsCrowdActRegistries: "actionsCrowdActRegistries", //Действия в реестре актов по заданиям
    actionsCrowdActRegistryItem: "actionsCrowdActRegistryItem", //Действия в карточке реестра актов по заданиям
    accessCrowdTaskAnalytics: "accessCrowdTaskAnalytics", //Доступ к вкладке "Аналитика по заданиям"
    repeatFailedCrowdPayments: "repeatFailedCrowdPayments", //Массовый повтор проверок оплат по заданиям в статусе "Проверка не пройдена"
    actionsCrowdPayments: "actionsCrowdPayments", //Работа с оплатами по заданиям
    getActFileOrOrderApplicationFileOrFrameContractFile: "getActFileOrOrderApplicationFileOrFrameContractFile", //Просмотр акта ,заявки, РД (НПД)
    getNdflActFileOrOrderApplicationFileOrFrameContractFile: "getNdflActFileOrOrderApplicationFileOrFrameContractFile", //Просмотр акта ,заявки, РД (НДФЛ)
    paymentNpdReceiptCancel: "paymentNpdReceiptCancel", //Аннулирование чеков ФНС об оплате НПД
    paymentNdflSolution: "paymentNdflSolution", //Подтверждение/Отклонение оплат НДФЛ
    actionCompanyNdflPayments: "actionCompanyNdflPayments", //Подтверждение/Отклонение оплат НДФЛ компании
    actionsNdflOrderPayments: "actionsNdflOrderPayments", //Действия с оплатами НДФЛ
    accessReconciliationReports: "accessReconciliationReports", //Доступ к подразделу Акты сверки
    actionsReconciliationReports: "actionsReconciliationReports", //Действия с формированием и удалением актов сверки
    deleteReportMutualSettlementsReconciliationAct: "deleteReportMutualSettlementsReconciliationAct", //Удаление отчета
    exportReconciliationReports: "exportReconciliationReports", //Скачивание акт сверки в формате pdf или xlsx
    closeRequestCrm: "closeRequestCrm", //	Закрытие обращения в разделе  CRM КЦ, если пользоваться не является ответственным
    editParamSystemTickets: "editParamSystemTickets", //Изменение параметра "Системные тикеты"
    activateSystemTickets: "activateSystemTickets", // Применение настроек системных тикетов
    accessRegisterPayments: "accessRegisterPayments", //Доступ к подразделу Реестровые выплаты
    getRegisterPaymentsLog: "getRegisterPaymentsLog", //Просмотр лога по реестру оплат
    paymentRegisterPayments: "paymentRegisterPayments", //Передача в оплату реестра платежей
    creatDuplicateRegistry: "creatDuplicateRegistry", //Создание дубликата реестра выплат
    changeTickets: "changeTickets", //Взятие обращение в работу/изменение параметров/перевод на другого сотрудника
    addTicketsComment: "addTicketsComment", //Просмотр и добавление комментария к обращению
    editTicketsParameters: "editTicketsParameters", //Редактирование пар-ров обращения
    callInMango: "callInMango", //Совершение звонков через Наймикс в Манго
    getCivilReceipt: "getCivilReceipt", //Просмотр квитанций НДФЛ
    getNpdReceipt: "getNpdReceipt", //Просмотр квитанций НПД
    createReceiptAtolOrderPayments: "createReceiptAtolOrderPayments", //Формирование АТОЛ чека
    getPaymentTransactionLogs: "getPaymentTransactionLogs", //Просмотр лога транзакций по платежу (НПД, НДФЛ)
    accessAllDepositSMZ: "accessAllDepositSMZ", //Доступ к разделу Депозит (НПД)
    accessAllDepositCivil: "accessAllDepositCivil", //Доступ к разделу Депозит (НДФЛ)
    manageSubscriptionAdmin: "manageSubscriptionAdmin", //Управление подписками пользователя (Административная часть)
    addVacanciesAdmin: "addVacanciesAdmin", //Создание вакансий вручную (Административная часть)
    accessManageVacanciesAdmin: "accessManageVacanciesAdmin", //Действия с вакансиями (Административная часть)
    addCommentAdmin: "addCommentAdmin", //Действия с вакансиями (Административная часть)
    manageCommentAdmin: "manageCommentAdmin", //Управление чужими комментариями (Административная часть)
    manageCandidatesAdmin: "manageCandidatesAdmin", //Управление кандидатами (Административная часть)
    accessResponsesAdmin: "accessResponsesAdmin", //Действия с откликами (Административная часть)
    manageDirectoriesAdmin: "manageDirectoriesAdmin", //Действия в меню Справочники (Административная часть)
    uploadTypePaymentsTransactionOr1c: "uploadTypePaymentsTransactionOr1c", //Выгрузка отчетов по оплатам _сист транзакции или 1с
    uploadTypePayments1cSmzOrCivil: "uploadTypePayments1cSmzOrCivil", //Выгрузка отчетов по оплатам _1с НПД и НДФЛ
    addExportTypePaymentChecksLeads: "addExportTypePaymentChecksLeads", //Выгрузка отчётов: Оплаты, чеки, 1с, лиды
    accessSmartLink: "accessSmartLink", //Доступ к подразделу Конструктор ссылок
    changePromotion: "changePromotion", //Управление каналами привлечения
    accessDocumentsExport: "accessDocumentsExport", //Доступ к подразделу Экспорт документов
    addExportPaymentType: "addExportPaymentType", //Выгрузка отчётов по оплатам
    addExportTypePaidApis: "addExportTypePaidApis", //Выгрузка отчета "Тарифицируемые вызовы API" - админка
    uploadPaidApis: "uploadPaidApis", //Выгрузка отчета "Тарифицируемые вызовы API" - через карточку компании
    startRetailAnalysis: "startRetailAnalysis", //Запуск анализа по ритэйлу
    startResourceAnalysis: "startResourceAnalysis", //Запуск анализа ресурсов компании
    accessClientRemote: "accessClientRemote", //Отображение подраздела "Исполнители заказчиков"
    editSettingBankRequisites: "editSettingBankRequisites", //Добавление/изменение/удаление условий сбора банковских реквизитов
    accessAdmTaskList: "accessAdmTaskList", //Доступ к подразделу список задач
    accessSettingCorrectionDocument: "accessSettingCorrectionDocument", //Доступ к вкладке "Исправление документов"
    accessClientProjectsAndObjects: "accessClientProjectsAndObjects", //Доступ к объектам, проектам компании
    accessClientTabs: "accessClientTabs", //Доступ к разделам компании
    clientPropertiesCommissionAndLimitsAndProjectParams: "clientPropertiesCommissionAndLimitsAndProjectParams", //Редактирование блока "Комиссия и лимиты", "Параметры проекта"
    checkDadataClientInfo: "checkDadataClientInfo", //Проверка данных компании
    accessContractorsList: "accessContractorsList", //Доступ к разделу Исполнители
    accessRetail: "accessRetail", //Доступ к подразделу Ресурсы
    addContractors: "addContractors", //Добавление исполнителя через UI/файл
    accessClients: "accessClients", //Доступ к разделу Компании
    accessClientBrands: "accessClientBrands", //Доступ к брендам компании
    accessMarketing: "accessMarketing", //Доступ к разделу Маркетинг
    accessAtolReceiptsExport: "accessAtolReceiptsExport", //Доступ к вкладке "1С /Выгрузка онлайн-чеков Атол"
    addContractorPushNotification: "addContractorPushNotification", //Создание рассылки
    changeContractorPushNotification: "changeContractorPushNotification", //Действия над рассылками
    accessContractorsPromotion: "accessContractorsPromotion", //Доступ к разделу Каналы привлечения
    accessMap: "accessMap", // Доступ к разделу "Карта"
    accessCrmTicket: "accessCrmTicket", // Доступ к разделу CRM КЦ
    accessExport: "accessExport", //Доступ к разделу  "Экспорт"
    accessAllSettings: "accessAllSettings", // Доступ к разделу Настройки
    accessSettingsTabs: "accessSettingsTabs", // Доступ к вкладкам раздела "Настройки": Сообщения техподдержки, Ролевая модель, Реквизиты, Инструкции, Группы компаний, Сбор банковских реквизитов
    editContractorProjectParam: "editContractorProjectParam", //Редактирование ProjectParam у исполнителя
    updateContractorProjectParams: "updateContractorProjectParams", //Редактирование ProjectParam и банковских реквизитов
    addNotes: "addNotes", //Работа с заметками по исполнителю
    updateProfileInfo: "updateProfileInfo", //Добавление информации об исполнителе на вкладке "Профиль"
    sendBankPersonalData: "sendBankPersonalData", //Отправка ПД/сканов исполнителя в банк на вкладке "Банки"
    updateContractors: "updateContractors", //Редактирование контактных данных исполнителя на вкладке "Персональные данные"
    removeCheckContractors: "removeCheckContractors", //Снятие с проверки на вкладке "Персональные данные"
    blockContractors: "blockContractors", //Блокировка/разблокировка исполнителя в карточке Исполнителя
    accessProfileBlocks: "accessProfileBlocks", //Отображение вкладок в карточке исполнителя: Профиль, история заказов, заданий, документы, финансы, ЖС, обращения
    updateContractorDocuments: "updateContractorDocuments", //Обновление документов на вкладке "Документы" на карточке исполнителя
    setContractorSmzTaxOffer: "setContractorSmzTaxOffer", //Включение/выключение автоматической уплаты налогов для исполнителя на вкладке Финансы - Баланс
    deleteContractorScores: "deleteContractorScores", //Возможность удаления отзывов исполнителя
    archivingContractor: "archivingContractor", //Архивирование учетной записи исполнителя
    needBankDetails: "needBankDetails", //Изменение обязательности сбора банковских реквизитов
    cancelVerificationContractor: "cancelVerificationContractor", //Снятие с проверки заявки ПД исполнителя
    accessFinanceBlocks: "accessFinanceBlocks", // Доступ ко всей группе раздела Финансы
    accessIndividualPayments: "accessIndividualPayments", // Доступ к странице Оплаты (ИП)
    actionsIndividualPayments: "actionsIndividualPayments", // Действия с оплатами ИП
    actionsIndividualPaymentDocs: "actionsIndividualPaymentDocs", // Просмотр документов, квитанций по оплатам ИП
    accessClientTickets: "accessClientTickets", // Доступ к вкладке Обращения
    accessContractorTickets: "accessContractorTickets", // Отображение вкладки "Обращения" на карточке исполнителя
    accessFtsService: "accessFtsService", // Доступ к вкладке "ФНС России сервис" раздела "Настройки"
    accessRoleModel: "accessRoleModel", // Доступ к вкладке "Ролевая модель"
    accessSettingsService: "accessSettingsService", // Доступ к вкладке "Сервис" раздела "Настройки"
    editUserInstructions: "editUserInstructions", // Изменение инструкций
    accessSettingInstructionsKedo: "accessSettingInstructionsKedo", // Доступ к вкладке "Инструкции для пользователей Клиентской части / КЭДО"
    editSystemSettings: "editSystemSettings", // Изменение сервисных настроек на вкладке "Сервис" раздела "Настройки"
    editSettingDirectory: "editSettingDirectory", // Управление справочниками
    editSystemRequisites: "editSystemRequisites", // Изменение реквизитов системы
    editSettingClientGroups: "editSettingClientGroups", // Управление группой компаний
    editClientGroupUsersSetting: "editClientGroupUsersSetting", // Доступ к настройкам сотрудников в группе компаний
    deleteEmptyAccounts: "deleteEmptyAccounts", // Удаление пустых учетных записей
    editExpasoftCalling: "editExpasoftCalling", // Включение обзвона Expasoft
    accessRequestConnection: "accessRequestConnection", // Доступ к вкладке "Заявки на подключение компаний" раздела "Настройки"
    accessSettingsEmployees: "accessSettingsEmployees", // Доступ к вкладке "Сотрудники" раздела "Настройки"
    accessEmployees: "accessEmployees", // Действия на вкладке "Сотрудники" раздела "Настройки"
    accessSettingsMachineLearning: "accessSettingsMachineLearning", // Доступ к вкладке Настройки > Машинное обучение
    editStatusRequest: "editStatusRequest", // Изменение статуса обращения
    accessAdmAllTaskList: "accessAdmAllTaskList", // Отображение подраздела "Все задачи"
    actionsClientObjectBrand: "actionsClientObjectBrand", // Действия с брендами компании
    confirmPaymentForemans: "confirmPaymentForemans", // Подтверждение и отклонение выплаты бригадиру
    confirmObjectApplicationForemans: "confirmObjectApplicationForemans", // Подтверждение/отклонение заявок с объектов
    setForemans: "setForemans", // Назначение/снятие с бригадира
    accessReportsSummary: "accessReportsSummary", // Доступ к подразделу Финансы > Сводная НПД/НДФЛ/по патентам
    accessReviews: "accessReviews", // Доступ к разделу  "Отзывы"
    actionsReviews: "actionsReviews", // подтверждение и отклонение отзывов
    actionsClientScores: "actionsClientScores", // Действия над отзывами
    submitClientComplaints: "submitClientComplaints", // Подача жалобы на отзыв
    addGroupOrder: "addGroupOrder", // Добавление группы заказов
    changeGroupOrder: "changeGroupOrder", // Действия над группой заказов
    editClientDepositHistory: "editClientDepositHistory", // Редактирование суммы для операций "Возврат денежных средств" и "Пополнение депозита" НПД
    editCivilClientDepositHistory: "editCivilClientDepositHistory", // Редактирование суммы для операций "Возврат денежных средств" и "Пополнение депозита" НДФЛ
    accessClientForemans: "accessClientForemans", // Доступ ко вкладке Бригадиры
    //Клиентские
    CLNT_accessClientScores: "CLNT_accessClientScores", //Доступ к подразделам  "Отзывы"
};