import React, {useMemo} from "react";

import CustomDocumentTemplateNamesDropdown
    from "../../../../../../components/ActualComponents/CustomDocumentTemplateNamesDropdown";
import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import {KedoDocumentCodes} from "../../../../../../components/KedoDocumentCodes";
import {KedoDocumentRouteDropdown} from "../../../../../../components/KedoDocumentRouteDropdown";
import NmTitle from "../../../../../../components/NmTitle";

import {useKedoTemplateDocumentTypesEdit} from "./hooks/useEdit";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../templates/constants";

export const KedoTemplateDocumentTypesEdit = (props) => {
    const {
        editData,
        fetchList,
        onClose,
        fetchCounts,
    } = props;

    const {
        errors,
        handleSubmit,
        progressAction,
        onChange,
        values,
        touched,
        documentRouteTypesFilter,
    } = useKedoTemplateDocumentTypesEdit({
        editData,
        fetchList,
        onClose,
        fetchCounts,
    });

    const initialOption = useMemo(() => {
        if (editData) {
            return {
                key: editData?.templateId,
                value: editData?.templateId,
                text: editData?.customDocumentTemplateName,
            };
        }
    }, []);

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        editData ?
                            "Редактирование типа шаблонных документов" :
                            "Добавление типа шаблонных документов"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    label="Наименование"
                    placeholder="Введите наименование"
                    maxLength={255}
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                <NmInputV2
                    size="xl"
                    label="Наименование для компании"
                    placeholder="Введите наименование"
                    name="nameForClient"
                    value={values.nameForClient}
                    maxLength={255}
                    required={true}
                    onChange={onChange}
                    error={
                        touched?.nameForClient && errors?.nameForClient ?
                            errors?.nameForClient :
                            undefined
                    }
                />
                <CustomDocumentTemplateNamesDropdown
                    required
                    label="Наименование шаблона"
                    placeholder="Не выбрано"
                    name="templateId"
                    value={values.templateId}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.KEDO_DOCUMENT}
                    initialOption={initialOption}
                    onChange={onChange}
                    error={
                        touched?.templateId && errors?.templateId ?
                            errors?.templateId :
                            undefined
                    }
                />
                <KedoDocumentCodes
                    required={true}
                    value={values.documentCode}
                    onChange={onChange}
                    error={
                        touched?.documentCode && errors?.documentCode ?
                            errors?.documentCode :
                            undefined
                    }
                />
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <NmRadioV2
                        name="baseRouteType"
                        value={true}
                        checked={values.baseRouteType === true}
                        className="me-md-4 mb-2 mb-md-0"
                        label="Базовый маршрут"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="baseRouteType"
                        checked={values.baseRouteType === false}
                        value={false}
                        label="Маршрут из справочника"
                        onChange={onChange}
                    />
                </div>
                <KedoDocumentRouteDropdown
                    required={true}
                    baseRouteTypeFilter={values.baseRouteType}
                    typesFilter={documentRouteTypesFilter}
                    initialOption={
                        editData?.routeId && {
                            key: editData.routeId,
                            value: editData.routeId,
                            text: editData.routeName,
                        }
                    }
                    value={values.routeId}
                    onChange={onChange}
                    error={touched?.routeId && errors?.routeId}
                />
                <NmCheckboxV2
                    name="canCreatedByClientUser"
                    onChange={onChange}
                    checked={values.canCreatedByClientUser}
                    label="Может создавать сотрудник"
                />
            </NmForm>
        </NmModal>
    );
};