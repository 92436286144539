import React, {FC, ReactNode, useState} from "react";
import {isEmpty} from "lodash";

import ApplyButtons from "../../ApplyButtons";
import NmTitle from "../../NmTitle";
import {TNmCheckboxParams} from "../NmCheckboxV2";
import NmHorizontalToggleV2 from "../NmHorizontalToggleV2";
import NmModal from "../NmModal";
import Text from "../Text";
import {ImportFromFilePatternForm} from "./Form";

import bem from "../../../utils/bem";

interface IImportFromFilePattern {
    patternLink: string,
    onSubmit: ({setProgress, file}: {
        setProgress: (state: boolean) => void,
        file: File,
        formData?: FormData,
    }) => void,
    onClose: () => void,
    progress?: boolean,
    maxSize?: number,
    headerControls?: ReactNode,
    templateText?: string,
    textTooltip?: string,
    headerTitle?: string,
    returnFormData?: boolean,
    additionalContent?: ReactNode,
    classNameContent?: string,
    isDefiningParam?: boolean,
    definingParamChecked?: boolean,
    onChangeDefiningParam?: (event: React.ChangeEvent<HTMLInputElement>, params: TNmCheckboxParams) => void,
}

const ImportFromFilePatternV2: FC<IImportFromFilePattern> = (props) => {
    const {
        onSubmit,
        patternLink,
        headerControls,
        maxSize = 10,
        onClose,
        templateText,
        textTooltip,
        headerTitle = "",
        returnFormData,
        additionalContent,
        classNameContent,
        isDefiningParam = false,
        definingParamChecked,
        onChangeDefiningParam,
    } = props;

    const [, element] = bem("import-from-file-pattern-v2");

    const [files, setFiles] = useState<Array<File>>([]);
    const [progress, setProgress] = useState(false);

    const getFormData = (file: File) => {
        if (!returnFormData) {
            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        return formData;
    };

    const _onSubmit = () => {
        if (props.progress === undefined) {
            setProgress(true);
        }

        const [file] = files;

        onSubmit({
            setProgress,
            file,
            formData: getFormData(file),
        });
    };

    const getHeaderControls = () => {
        if (
            isDefiningParam
            && definingParamChecked !== undefined
            && onChangeDefiningParam
        ) {
            return (
                <div className="flex flex-wrap flex-aligned-center">
                    <Text
                        className="me-2"
                        level="4"
                    >
                        Определяющий параметр:
                    </Text>
                    <NmHorizontalToggleV2
                        leftLabel="Телефон"
                        rightLabel="ИНН"
                        checked={definingParamChecked}
                        onChange={onChangeDefiningParam}
                    />
                </div>
            );
        }

        if (headerControls) {
            return headerControls;
        }

        return null;
    };

    return (
        <NmModal
            size="md"
            classNameContent={classNameContent}
            onClose={onClose}
            header={
                headerTitle &&
                <NmTitle size="lg">
                    {headerTitle}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={_onSubmit}
                    onClose={onClose}
                    loading={progress}
                    mobile="column"
                    disabled={props.progress || progress || isEmpty(files)}
                    submitBtnContent="Загрузить"
                    cancelBtnContent="Отменить"
                />
            }
        >
            <ImportFromFilePatternForm
                headerControls={getHeaderControls()}
                maxSize={maxSize}
                files={files}
                setFiles={setFiles}
                patternLink={patternLink}
                templateText={templateText}
                textTooltip={textTooltip}
            />
            {additionalContent}
        </NmModal>
    );
};

export default ImportFromFilePatternV2;