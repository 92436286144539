import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getSpecialitiesEditValidation} from "../utils/getValidation";

import {
    addSpecialty,
    updateSpecialty,
} from "../../../../../../../ducks/bff/settings/directories/naimix/specialities/actionCreators";
import {settingsDirectoriesNaimixSpecialitiesProgressActionSelector} from "../../../../../../../ducks/bff/settings/directories/naimix/specialities/selectors";

export const useSpecialitiesEdit = (props) => {
    const {
        editData,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(settingsDirectoriesNaimixSpecialitiesProgressActionSelector);

    useEffect(() => {
        if (editData) {
            setValues({
                ...values,
                ...editData,
            });
        }
    }, []);

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            value: "",
        },
        validationSchema: getSpecialitiesEditValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        if (editData) {
            const {id} = editData;

            dispatch(updateSpecialty({
                ...values,
                id,
                onSuccess: onClose,
            }));

            return;
        }

        dispatch(addSpecialty({
            ...values,
            onSuccess: onClose,
        }));
    }

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
    };
};