import {useState} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {
    convertDateToDateWithoutTimeString,
    handleFormString,
    isNullOrWhitespace,
} from "../../../../../../../utils/stringHelper";
import {toastError} from "../../../../../../../utils/toastHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {
    addContractorCourseCertificate,
    addContractorCourseCertificateFile,
    updateContractorCourseCertificate,
} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultCourseCertificateForm = {
    courseCertificateId: null,
    courseName: "",
    fileName: "",
    finishedDate: null,
    organization: "",
    medicalBookPresent: false,
};

export default function useCreateOrUpdateCourseCertificate({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultCourseCertificateForm});
    const [files, setFiles] = useState([]);
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        courseCertificateId,
        courseName,
        finishedDate,
        organization,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const validateForm = () => {
        let _error = {};
        let isValid = true;

        if (isNullOrWhitespace(courseName)) {
            _error = {
                ..._error,
                courseName: requiredMessage,
            };
            isValid = false;
        }

        if (isNullOrWhitespace(organization)) {
            _error = {
                ..._error,
                organization: requiredMessage,
            };
            isValid = false;
        }

        if (isNullOrWhitespace(finishedDate)) {
            _error = {
                ..._error,
                finishedDate: requiredMessage,
            };
            isValid = false;
        }

        setError(_error);

        return isValid;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }
        const method = courseCertificateId ? updateContractorCourseCertificate : addContractorCourseCertificate;
        dispatch(method({
            courseName: handleFormString(courseName),
            courseCertificateId,
            contractorId,
            fileName: files[0]?.fileName,
            organization: handleFormString(organization),
            finishedDate: convertDateToDateWithoutTimeString(finishedDate),
            getResult: ({errorMessage, courseCertificateId}) => {
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }
                !isEmpty(files[0]) && addFile(courseCertificateId);
                onClose();
            },
        }));
    };

    const addFile = (certificateId) => {
        const formData = new FormData();

        formData.append("file", files[0]);
        formData.append("contractorId", contractorId);
        formData.append("certificateId", certificateId);

        dispatch(addContractorCourseCertificateFile({
            formData,
            getResult: (errorMessage) => {
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }
                getProfile();
            },
        }));
    };

    const handleDropFile = (files) => {
        setFiles(files);
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        files,
        handleDropFile,
        setFiles,
        error,
    };
}