import React from "react";
import {useSelector} from "react-redux";

import ContextMenu from "../../../../../components/ActualComponents/ContextMenu";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import {ReactComponent as CheckIcon} from "../../../../../images/checkV2.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as DeleteIcon} from "../../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../../images/mode.svg";

import {useNoteAction} from "./hooks/useNoteAction";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
} from "../../../../../constants/roles";

import {contractorCardNoteSelector} from "../../../../../ducks/bff/contractor-сard/selectors";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";

const NOTE_HEADER_ACTIONS = {
    EDIT: {
        VALUE: "EDIT",
        TEXT: "Редактировать",
    },
    DELETE: {
        VALUE: "DELETE",
        TEXT: "Удалить",
    },
    SAVE: {
        VALUE: "SAVE",
        TEXT: "Сохранить",
    },
    CANCEL: {
        VALUE: "CANCEL",
        TEXT: "Отмена",
    },
};

export default function ContractorNoteBlock(props) {
    const {
        isDesktop,
        contractorId,
    } = props;

    const noteInitial = useSelector(contractorCardNoteSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);

    const {
        note,
        setNote,
        isEditNote,
        setEditNote,
        handleSubmitNote,
        deleteNote,
    } = useNoteAction({contractorId, noteInitial});

    const getOptions = () => {
        return (
            isEditNote ? [
                {
                    key: NOTE_HEADER_ACTIONS.SAVE.VALUE,
                    text: NOTE_HEADER_ACTIONS.SAVE.TEXT,
                    value: NOTE_HEADER_ACTIONS.SAVE.VALUE,
                },
                {
                    key: NOTE_HEADER_ACTIONS.CANCEL.VALUE,
                    text: NOTE_HEADER_ACTIONS.CANCEL.TEXT,
                    value: NOTE_HEADER_ACTIONS.CANCEL.VALUE,
                },
            ] : [
                {
                    key: NOTE_HEADER_ACTIONS.EDIT.VALUE,
                    text: NOTE_HEADER_ACTIONS.EDIT.TEXT,
                    value: NOTE_HEADER_ACTIONS.EDIT.VALUE,
                },
                {
                    key: NOTE_HEADER_ACTIONS.DELETE.VALUE,
                    text: NOTE_HEADER_ACTIONS.DELETE.TEXT,
                    value: NOTE_HEADER_ACTIONS.DELETE.VALUE,
                },
            ]
        );
    };

    const onClickActionItem = (option) => {
        const {value} = option;

        switch (value) {
            //Отменить
            case NOTE_HEADER_ACTIONS.SAVE.VALUE:
                handleSubmitNote();

                return;
            case NOTE_HEADER_ACTIONS.CANCEL.VALUE:
                setEditNote(false);
                setNote(noteInitial);

                return;
            case NOTE_HEADER_ACTIONS.EDIT.VALUE:
                setEditNote(true);

                return;
            case NOTE_HEADER_ACTIONS.DELETE.VALUE:
                deleteNote();

                return;
            default:
                return;
        }
    };

    const renderNoteBlockActions = () => {
        return (
            isEditNote ?
                <>
                    <CheckIcon
                        className="contractor-general-info__note-actions-icon me-1"
                        onClick={handleSubmitNote}
                    />
                    <CloseIcon
                        className="contractor-general-info__note-actions-icon"
                        onClick={() => {
                            setEditNote(false);
                            setNote(noteInitial);
                        }}
                    />
                </> :
                <>
                    <ModeIcon
                        className="contractor-general-info__note-actions-icon me-1"
                        onClick={() => {
                            setEditNote(true);
                        }}
                    />
                    {
                        note &&
                        <DeleteIcon
                            className="contractor-general-info__note-actions-icon"
                            onClick={deleteNote}
                        />
                    }
                </>
        );
    };

    const getNote = () => {
        if (!note) {
            return null;
        }

        return (
            <Text
                level="3"
                className="mt-2"
            >
                {note}
            </Text>
        );
    };

    return (
        <div className={`${isDesktop ? "col-3" : ""}`}>
            <div className={`flex flex-content-spaced ${!isDesktop ? "flex-vertical-center" : ""}`}>
                <div className="contractor-general-info__note-text">
                    Заметка об исполнителе
                </div>
                {
                    ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                    !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.addNotes) &&
                    <div className="contractor-general-info__note-actions">
                        {
                            isDesktop ?
                                renderNoteBlockActions() :
                                <ContextMenu
                                    horizontalIcon
                                    notBorder
                                    options={getOptions()}
                                    onClickItem={(option) => {
                                        onClickActionItem(option);
                                    }}
                                />
                        }
                    </div>
                }
            </div>
            {
                isEditNote ?
                    <NmTextareaV2
                        placeholder="Добавьте заметку об исполнителе, которую будете видеть только вы."
                        value={note}
                        minRows={3}
                        maxLength={255}
                        onChange={(event, {value}) => {
                            setNote(value);
                        }}
                    /> :
                    getNote()
            }
        </div>
    );
}