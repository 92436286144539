import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {clientPhoneMask} from "../../../../utils/clientHelper";
import {phoneFormat, removePhoneMask} from "../../../../utils/stringFormat";
import validate from "../../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {clientCardRule} from "../../../../constants/validationRules";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector,
    getAccountantInfoSelector,
    getClientTypeSelector,
} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class AccountantCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            clientType: {},
            errorForm: {},
            accountantInfo: {},
            editAccountantInfo: {},
        };
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            accountantInfo: _accountantInfo,
            isEdit,
        } = state;
        const {accountantInfo} = newProps;

        if (
            JSON.stringify(accountantInfo) !== JSON.stringify(_accountantInfo) &&
            !isEdit
        ) {
            return {
                ...state,
                editAccountantInfo: {
                    ...accountantInfo,
                },
                accountantInfo,
            };
        }

        return null;
    }

    cancelEditMode = () => {
        const {editAccountantInfoCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            editAccountantInfo: editAccountantInfoCopy,
        }));
    };

    isValidEditClient(editClient) {
        const rule = clientCardRule.accountantInfo;

        const errorForm = validate(editClient, rule, "");

        this.setState(prevState => ({
            ...prevState,
            errorForm,
        }));

        return Object.values(errorForm).length === 0;
    }

    toggleCard = cardType => {
        const {isEdit, editAccountantInfo} = this.state;

        if (isEdit) {
            const validationData = {
                ...editAccountantInfo,
                [CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]: removePhoneMask(
                    editAccountantInfo[CLIENT_FIELD_NAME.ACCOUNTANT_PHONE],
                ),
            };

            if (!this.isValidEditClient(validationData, cardType)) {
                return;
            }
        } else {
            this.setState({
                editAccountantInfoCopy: editAccountantInfo,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {this.submitAccountantInfo();}
            },
        );
    };

    submitAccountantInfo = () => {
        const {updateClientCardInfo, accountantInfo, client} = this.props;

        const {editAccountantInfo} = this.state;

        if (JSON.stringify(editAccountantInfo) === JSON.stringify(accountantInfo)) {
            return;
        }

        const requestData = {
            ...client,
            ...editAccountantInfo,
            [CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]: removePhoneMask(
                editAccountantInfo[CLIENT_FIELD_NAME.ACCOUNTANT_PHONE],
            ),
        };

        updateClientCardInfo(requestData);
    };

    handleChange = (e, {value, mask, name}) => {
        if (mask && !mask.test(value) && value !== "") {return;}

        this.setState(prevState => ({
            ...prevState,
            editAccountantInfo: {
                ...prevState.editAccountantInfo,
                [name]: value,
            },
        }));
    };

    renderCardReadOnly(client) {
        const {t} = this.props;

        return (
            <>
                <LabelTextApp
                    label={t("client-accountant-general-info.full-name")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.ACCOUNTANT_NAME] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={t("client-accountant-general-info.phone-number")}
                    className="client-card-block__label"
                    text={
                        phoneFormat(client[CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]) ||
                        t("is-not-specified.content")
                    }
                />
            </>
        );
    }

    renderCardEditMode(client) {
        const {errorForm} = this.state;
        const {
            clientTypeName,
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-accountant-general-info.full-name")}
                            className="client-form__control"
                            placeholder={t("client-accountant-general-info.client-accountant-general-title")}
                            name={CLIENT_FIELD_NAME.ACCOUNTANT_NAME}
                            value={client[CLIENT_FIELD_NAME.ACCOUNTANT_NAME] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.ACCOUNTANT_NAME]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-accountant-general-info.phone-number")}
                            mask={clientPhoneMask[clientTypeName]}
                            className="client-form__control"
                            placeholder={t("client-contract-info.client-phone-number")}
                            name={CLIENT_FIELD_NAME.ACCOUNTANT_PHONE}
                            value={client[CLIENT_FIELD_NAME.ACCOUNTANT_PHONE] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]}
                        />
                    </div>
                </div>
            </NmForm>
        );
    }

    render() {
        const {
            isEdit,
            editAccountantInfo,
        } = this.state;

        const {
            isEditable,
            t,
        } = this.props;

        return (
            <CardApp
                title={t("client-accountant-general-info.client-accountant-general-title")}
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEditMode}
                isEdit={isEdit}
            >
                {isEdit
                    ? this.renderCardEditMode(editAccountantInfo)
                    : this.renderCardReadOnly(editAccountantInfo)}
            </CardApp>
        );
    }
}

export default connect(state => ({
    accountantInfo: getAccountantInfoSelector(state),
    client: clientCardInfoSelector(state),
    clientTypeName: getClientTypeSelector(state),
}), {
    updateClientCardInfo,
})(withTranslation()(AccountantCardBlock));