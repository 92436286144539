import React, {useMemo} from "react";

import NmTextareaV2 from "../NmTextareaV2";

interface TextareaWithParamsProps {
    paramDict: Record<string, number>,
    [name: string]: any,
}

export const TextareaWithParams: React.FC<TextareaWithParamsProps> = (props) => {
    const {
        value,
        paramDict,
        maxLength,
        ...otherProps
    } = props;

    const params = Object.keys(paramDict);

    const getCount = (value: string) => {
        const activeParams = params
            .filter(param => value.includes(`{{${param}}}`));

        const factParamCount = activeParams.reduce((result, value) => {
            const COUNT_BRACES_IN_PARAM = 4;

            return result + value.length + COUNT_BRACES_IN_PARAM;
        }, 0);
        const predictParamCount = activeParams
            .reduce((result, value) => {
                return result + paramDict[value];
            }, 0);

        return value.length + predictParamCount - factParamCount;
    };

    const count = useMemo(() => {
        if (!value) {
            return 0;
        }

        return getCount(value);
    }, [value]);

    const isInputStopped = (value: string) => {
        const count = getCount(value);

        return count > maxLength;
    };

    return (
        <NmTextareaV2
            {...otherProps}
            value={value}
            maxLength={maxLength}
            count={count}
            isInputStopped={isInputStopped}
        />
    );
};