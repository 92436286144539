import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    getPageAdminCrowdTasks,
    updateStoreCrowdTasks,
} from "../../../../ducks/bff/crowd/tasks/actionCreators";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

const useCrowdTaskRegistryFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTasksList,
            ],
        }));

        return () => {
            dispatch(updateStoreCrowdTasks({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
    ]);

    const fetchList = () => {
        dispatch(getPageAdminCrowdTasks({
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};

export default useCrowdTaskRegistryFetch;