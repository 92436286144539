import {
    KEDO_DEPARTMENTS_STAFF_CLEAR_STORE,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST,
    UPDATE_KEDO_DEPARTMENTS_STAFF_STORE,
} from "./actions";

export const getKedoDepartmentsStaff = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearKedoDepartmentsStaffStore = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_STAFF_CLEAR_STORE,
        payload,
    };
};

export const moveKedoDepartmentStaff = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST,
        payload,
    };
};

export const updateKedoDepartmentsStaff = (payload) => {
    return {
        type: UPDATE_KEDO_DEPARTMENTS_STAFF_STORE,
        payload,
    };
};