import {
    BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST,
    UPDATE_PROMOTION_CHANNELS_CARD_STORE,
} from "./actions";

export const getPagePromotionChannelsCard = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST,
        payload,
    };
};

export const updatePromotionChannelCard = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST,
        payload,
    };
};

export const getPagePromotionChannelCardPromocode = (payload) => {
    return {
        type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateStorePromotionChannelsCard = (payload) => {
    return {
        type: UPDATE_PROMOTION_CHANNELS_CARD_STORE,
        payload,
    };
};