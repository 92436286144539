import {
    BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_REQUEST,
    BFF_FINANCES_NDFL_SUMMARY_REPORTS_REQUEST,
    BFF_FINANCES_NDFL_SUMMARY_UPDATE_STORE,
} from "./actions";

export function getNdflSummaryReports(payload) {
    return {
        type: BFF_FINANCES_NDFL_SUMMARY_REPORTS_REQUEST,
        payload,
    };
}

export function getNdflSummaryDepositHistory(payload) {
    return {
        type: BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_REQUEST,
        payload,
    };
}

export function updateNdflSummaryStore(payload) {
    return {
        type: BFF_FINANCES_NDFL_SUMMARY_UPDATE_STORE,
        payload,
    };
}
