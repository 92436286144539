import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/stopwords";

/*TYPES*/
const CHECK_STOP_WORDS_REQUEST = "CHECK_STOP_WORDS_REQUEST";
const CHECK_STOP_WORDS_SUCCESS = "CHECK_STOP_WORDS_SUCCESS";
const CHECK_STOP_WORDS_ERROR = "CHECK_STOP_WORDS_ERROR";

//*INITIAL STATE*//
const initial = {
    pageData: {},
    progressPage: false,
    totalCount: 0,
    stopWordsList: [],
};

//*REDUCER*//
export default (state = initial, {type, payload}) => {
    switch (type) {
        case CHECK_STOP_WORDS_REQUEST:
            return {
                ...state,
                progressPage: true,
            };
        case CHECK_STOP_WORDS_SUCCESS:
            return {
                ...state,
                progressPage: false,
            };
        case CHECK_STOP_WORDS_ERROR:
            return {
                ...state,
                progressPage: false,
                error: payload,
            };
        default:
            return state;
    }
};

//*ACTION CREATORS*//

export function checkStopWords({data, onSuccess}) {
    return {
        type: CHECK_STOP_WORDS_REQUEST,
        payload: data,
        onSuccess,
    };
}

//*SELECTORS*//
export const stopWordsSelector = state => state.stopWords;
export const getStopWordProgressPageSelector = createSelector(stopWordsSelector, ({progressPage}) => progressPage);

//*SAGA*//

// /api/stopwords/filter/check
export const checkStopWordsSaga = function* ({payload, onSuccess}) {
    try {
        const result = yield request.post(`${controller}/filter/check`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: CHECK_STOP_WORDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({type: CHECK_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_STOP_WORDS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CHECK_STOP_WORDS_REQUEST, checkStopWordsSaga),
    ]);
}
