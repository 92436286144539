import React from "react";

import TaskList, {SOURCE_TYPE_FILTER} from "../list";

const KedoAllTaskList = () => {
    return (
        <TaskList
            sourceTypeFilter={SOURCE_TYPE_FILTER.KEDO}
            onlyMyTasks={false}
        />
    );
};

export default KedoAllTaskList;