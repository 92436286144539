export const CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST = "CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST";
export const CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS = "CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS";
export const CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR = "CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR";

export const CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST = "CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST";
export const CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS = "CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS";
export const CONTRACTOR_DOCUMENTS_GET_COUNTS_ERROR = "CONTRACTOR_DOCUMENTS_GET_COUNTS_ERROR";

export const CONTRACTOR_DOCUMENTS_REFRESH_REQUEST = "CONTRACTOR_DOCUMENTS_REFRESH_REQUEST";
export const CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS = "CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS";
export const CONTRACTOR_DOCUMENTS_REFRESH_ERROR = "CONTRACTOR_DOCUMENTS_REFRESH_ERROR";

export const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST";
export const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS";
export const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR";

export const PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST = "PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST";
export const PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS = "PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS";
export const PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR = "PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR";

export const GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST = "GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST";
export const GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS = "GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS";
export const GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR = "GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR";

export const CLEAR_CONTRACTOR_DOCUMENTS_STORE = "CLEAR_CONTRACTOR_DOCUMENTS_STORE";