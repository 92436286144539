import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPageSettingsClientGroupsCardContractors} from "../../../../../../ducks/bff/settings/client-groups/card/contractors/actionCreators";

export function useGetListContractors({filter, pageSize, pageNum, importProgress}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!importProgress) {
            fetchList();
        }
    }, [filter, pageSize, pageNum]);

    function fetchList() {
        dispatch(getPageSettingsClientGroupsCardContractors({
            pageSize,
            pageNum,
            ...filter,
        }));
    }

    return {
        fetchList,
    };
}