import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateDriverLicense, {defaultDriverLicenseForm} from "./hooks/useUpdateDriverLicense";

import {updateContractorDriverLicense} from "../../../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {contractorDrivingLicenseDictOptionsSelector} from "../../../../../../ducks/bff/contractor-сard/profile/selectors";

export default function ContractorDriverLicenseEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultDriverLicenseForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const contractorDrivingLicenseDictOptions = useSelector(contractorDrivingLicenseDictOptionsSelector);

    const {
        onChangeLicenses,
        submit,
        form,
        setForm,
        onChangeForm,
    } = useUpdateDriverLicense({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm({...editData});
    }, []);

    const {
        licenses,
        ownCar,
        driverLicensePresent,
    } = form;

    const deleteElem = () => {
        dispatch(updateContractorDriverLicense({
            contractorId,
            licenses: [],
            ownCar: false,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    function mapContent() {
        return contractorDrivingLicenseDictOptions.map(item => {
            return (
                <NmCheckboxV2
                    checked={licenses.includes(item.value)}
                    name={item.value}
                    onChange={onChangeLicenses}
                    label={`${item.value} - ${item.text}`}
                />
            );
        });
    }

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                    additionalBtnContent="Удалить"
                    additionalBtnColor="red"
                    onClickAdditional={deleteElem}
                />
            }
            header={
                <NmTitle size="lg">
                    Водительские права
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    checked={ownCar}
                    name="ownCar"
                    onChange={onChangeForm}
                    label="Имеется личный автомобиль"
                />
                <NmCheckboxV2
                    name="driverLicensePresent"
                    checked={driverLicensePresent}
                    onChange={onChangeForm}
                    label="Водительские права отсутствуют"
                />
                {!driverLicensePresent && <>
                    <div className="nm-line mb-2" />
                    {mapContent()}
                </>}
            </NmForm>
        </NmModal>
    );
}