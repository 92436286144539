import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST,
} from "./actions";

export const editRecruitmentVacancyCandidate = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyCandidate = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST,
        payload,
    };
};

export const createRecruitmentVacancyCandidate = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCandidates = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCandidatesCard = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST,
        payload,
    };
};

export const setRecruitmentVacancyCandidateStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyCandidatesStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE,
        payload,
    };
};

export const updateRecruitmentVacancyCandidatesFromResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST,
        payload,
    };
};

export const uploadRecruitmentVacancyCandidatesResume = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST,
        payload,
    };
};

export const uploadRecruitmentVacancyCandidatesFile = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyCandidatesFile = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyCandidatesResume = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCandidatesForResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST,
        payload,
    };
};

export const rejectRecruitmentCandidate = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST,
        payload,
    };
};

export const getFeedListRecruitmentVacancyCandidates = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST,
        payload,
    };
};

export const getRecruitmentMessageTemplates = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST,
        payload,
    };
};