import {
    KEDO_LOGS_INTEGRATION_1C_PAGE_ERROR,
    KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST,
    KEDO_LOGS_INTEGRATION_1C_PAGE_SUCCESS,
    KEDO_LOGS_UPDATE_STORE,
} from "./actions";

export const getKedoLogsInitialState = () => {
    return {
        pageData: {},
        list: [],
        totalCount: 0,
        progress: false,
        error: null,
    };
};

const initialState = getKedoLogsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case KEDO_LOGS_INTEGRATION_1C_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results || [],
                totalCount: payload.totalCount || 0,
                progress: false,
            };
        case KEDO_LOGS_INTEGRATION_1C_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case KEDO_LOGS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};