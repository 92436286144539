import {
    ADD_FILE_TO_MODEL_ERROR,
    ADD_FILE_TO_MODEL_REQUEST,
    ADD_FILE_TO_MODEL_SUCCESS,
    ADD_ML_MODEL_ERROR,
    ADD_ML_MODEL_REQUEST,
    ADD_ML_MODEL_SUCCESS,
    DELETE_ML_MODEL_ERROR,
    DELETE_ML_MODEL_REQUEST,
    DELETE_ML_MODEL_SUCCESS,
    DELETE_MODEL_FILE_ERROR,
    DELETE_MODEL_FILE_REQUEST,
    DELETE_MODEL_FILE_SUCCESS,
    GET_ML_MODEL_FILE_LIST_ERROR,
    GET_ML_MODEL_FILE_LIST_REQUEST,
    GET_ML_MODEL_FILE_LIST_SUCCESS,
    GET_ML_MODEL_LIST_ERROR,
    GET_ML_MODEL_LIST_REQUEST,
    GET_ML_MODEL_LIST_SUCCESS,
    ML_MODELS_PREDICT_AMOUNT_SUCCESS,
    ML_MODELS_STORE_CLEAR,
    SET_MAIN_MODEL_ERROR,
    SET_MAIN_MODEL_REQUEST,
    SET_MAIN_MODEL_SUCCESS,
    UPDATE_ML_MODEL_ERROR,
    UPDATE_ML_MODEL_REQUEST,
    UPDATE_ML_MODEL_SUCCESS,
    UPDATE_MODEL_FILE_ERROR,
    UPDATE_MODEL_FILE_REQUEST,
    UPDATE_MODEL_FILE_SUCCESS,
} from "./actions";

const initial = {
    listProgress: false,
    fileListProgress: false,
    listPageData: {},
    modelList: [],
    modelFileList: [],
    actionProgress: false,
    predictAmount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case DELETE_MODEL_FILE_REQUEST:
        case ADD_FILE_TO_MODEL_REQUEST:
        case DELETE_ML_MODEL_REQUEST:
        case SET_MAIN_MODEL_REQUEST:
        case UPDATE_ML_MODEL_REQUEST:
        case UPDATE_MODEL_FILE_REQUEST:
        case ADD_ML_MODEL_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case GET_ML_MODEL_LIST_REQUEST:
            return {
                ...state,
                listPageData: payload,
                listProgress: true,
            };
        case GET_ML_MODEL_FILE_LIST_REQUEST:
            return {
                ...state,
                fileListProgress: false,
            };
        case DELETE_MODEL_FILE_SUCCESS:
        case UPDATE_MODEL_FILE_SUCCESS:
        case ADD_FILE_TO_MODEL_SUCCESS:
        case DELETE_ML_MODEL_SUCCESS:
        case SET_MAIN_MODEL_SUCCESS:
        case UPDATE_ML_MODEL_SUCCESS:
        case ADD_ML_MODEL_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_ML_MODEL_LIST_SUCCESS:
            return {
                ...state,
                modelList: payload.results,
                listProgress: false,
            };
        case GET_ML_MODEL_FILE_LIST_SUCCESS:
            return {
                ...state,
                fileModelList: payload.result,
                fileListProgress: false,
            };
        case GET_ML_MODEL_FILE_LIST_ERROR:
            return {
                ...state,
                fileListProgress: false,
                error: payload,
            };
        case GET_ML_MODEL_LIST_ERROR:
            return {
                ...state,
                listProgress: false,
                error: payload,
            };
        case DELETE_MODEL_FILE_ERROR:
        case UPDATE_MODEL_FILE_ERROR:
        case ADD_FILE_TO_MODEL_ERROR:
        case SET_MAIN_MODEL_ERROR:
        case DELETE_ML_MODEL_ERROR:
        case UPDATE_ML_MODEL_ERROR:
        case ADD_ML_MODEL_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case ML_MODELS_PREDICT_AMOUNT_SUCCESS:
            return {
                ...state,
                predictAmount: payload,
            };
        case ML_MODELS_STORE_CLEAR:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};