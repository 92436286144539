import React from "react";
import {useDispatch} from "react-redux";

import NmButton from "../../../../../../../components/NmButton";
import {deleteRecruitmentVacancyCandidatesComment} from "../../../../../../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";
import {ReactComponent as AttachFileIcon} from "../../../../../../../images/attach_file_24.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../../../../images/mode.svg";

import {CURRENT_CLIENT_USER_ID, ls} from "../../../../../../../utils/localstorage";

import {COMPONENT} from "../../../../../../../components/ActualComponents/MediaControls/constants";
import {RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE} from "../../../constants";

const useRecruitmentCandidatesFeedOperationListAction = (props) => {
    const {
        vacancyId,
        onOpenConfirm,
        onCloseConfirm,
        onOpenEditModal,
        fetchList,
        uploadFiles,
    } = props;

    const dispatch = useDispatch();

    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const getMediaControls = (item) => {
        const isAccessAction = item.initiatorId === currentClientUserId;
        const fileNames = item.comment?.fileNames || [];
        const disabledAttachFiles = fileNames.length > 4;

        if (!isAccessAction || !vacancyId) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 3,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenEditModal({
                            ...item.comment,
                            createdBy: item.initiatorId,
                        }),
                        color: "grey",
                        title: "Редактировать",
                        onlyIcon: true,
                        icon: <ModeIcon />,
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                    visible: item.operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.NEW_COMMENT,
                },
                {
                    component: COMPONENT.DEFAULT_DROPZONE,
                    props: {
                        disabled: disabledAttachFiles,
                        multiple: true,
                        files: fileNames,
                        maxFiles: 5,
                        onDropFiles: (files) => uploadFiles({
                            files: files.filter(item => !fileNames.includes(item)),
                            commentId: item.comment?.id,
                            createdBy: item.initiatorId,
                        }),
                        children: <NmButton
                            size="lg"
                            onlyIcon={true}
                            color="grey"
                            title="Прикрепить файл"
                            icon={<AttachFileIcon />}
                            disabled={disabledAttachFiles}
                        />,
                    },
                    asset: {
                        mobile: {
                            children: "Прикрепить файл",
                        },
                    },
                    visible: item.operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.NEW_COMMENT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите удалить комментарий по кандидату?",
                            onConfirm: () => deleteComment(item),
                        }),
                        color: "grey",
                        title: "Удалить",
                        onlyIcon: true,
                        icon: <DeleteIcon />,
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                    visible: item.operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.NEW_COMMENT,
                },
            ],
        };
    };

    const isMediaControlsVisible = (item) => {
        const isAccessAction = item.initiatorId === currentClientUserId;

        return isAccessAction && vacancyId && item.operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.NEW_COMMENT;
    };

    const deleteComment = (params) => {
        const {
            comment,
            initiatorId,
        } = params || {};

        dispatch(deleteRecruitmentVacancyCandidatesComment({
            id: comment.id,
            createdBy: initiatorId,
            onSuccess: () => {
                onCloseConfirm();
                fetchList();
            },
        }));
    };

    return {
        isMediaControlsVisible,
        getMediaControls,
    };
};

export default useRecruitmentCandidatesFeedOperationListAction;