import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import Text from "../../../../components/ActualComponents/Text";
import DownloadDataFromDeposits from "../../../../components/DownloadDataFromDeposits";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";
import SystemSettingsCorrectDepositResults from "./components/correct-deposit-results";

import {convertUtcToLocal, formatLocalDate, getUtcDateFilter} from "../../../../utils/dateFormat";
import {phoneFormat, removePhoneMask} from "../../../../utils/stringFormat";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../constants/finance";

import {
    deleteEmptyRecord,
    getEmptyRecord,
    getMaintenanceNoticeSettings,
    getSystemSetting,
    ndflDepositCorrect,
    npdDepositCorrect,
    setExpasoftCollingSetting,
    setMaintenanceNoticeSettings,
    setSystemSetting,
} from "../../../../ducks/bff/settings/service/actionCreators";
import {
    bffSettingsServiceEmptyRecordProgressSelector,
    bffSettingsServiceProgressCorrectionSelector,
    bffSettingsServiceProgressSelector,
} from "../../../../ducks/bff/settings/service/selectors";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../../ducks/clientUserRestrictions";

import {PHONE_NUMBER} from "../../../../constants/ mailings/phoneNumber";

const SYSTEM_SETTINGS = [
    {
        name: "Проверка на уникальность ИНН",
        key: "checkInnDuplicate",
    },
    {
        name: "Проверка ИНН и ПД",
        key: "fnsValidPassportDataAndInn",
    },
    {
        name: "Выключить ФНС",
        key: "deadFns",
    },
    {
        name: "Заглушка ФНС",
        key: "mockFns",
    },
];

const SYSTEM_SETTINGS_EXPASOFT = [
    {
        name: "Автообзвон исполнителей с предложением о найме через Expasoft",
        key: "orderOfferDsysAiEnabled",
    },
];

const SettingsServiceSystem = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({});
    const [formNoticeSettings, setFormNoticeSettings] = useState({});
    const [clientId, setClientId] = useState("");
    const [clientName, setClientName] = useState("");
    const [modalData, setModalData] = useState(null);
    const [contractorPhone, setContractorPhoneNumber] = useState("");
    const [emptyRecord, setEmptyRecord] = useState({});
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const progressCorrection = useSelector(bffSettingsServiceProgressCorrectionSelector);
    const setSettingsProgress = useSelector(bffSettingsServiceProgressSelector);
    const emptyRecordProgress = useSelector(bffSettingsServiceEmptyRecordProgressSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const isAccessEditSystemSettings = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editSystemSettings);
    const isAccessDeleteEmptyAccounts = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.deleteEmptyAccounts);
    const isAccessEditExpasoftCalling = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editExpasoftCalling);

    useEffect(() => {
        [
            ...SYSTEM_SETTINGS,
            ...SYSTEM_SETTINGS_EXPASOFT,
        ].forEach(value => {
            const {
                key,
            } = value;

            dispatch(getSystemSetting({
                key,
                getResult: (result) => {
                    setForm(form => ({
                        ...form,
                        [key]: result,
                    }));
                },
            }));
        });

        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.editSystemSettings,
                CLIENT_USER_RESTRICTIONS_VARIABLE.deleteEmptyAccounts,
                CLIENT_USER_RESTRICTIONS_VARIABLE.editExpasoftCalling,
            ],
        }));

        fetchMaintenanceNoticeSettings();
    }, []);

    const onChangeSystemSettings = (event, {name, checked}) => {
        const reqData = {
            key: name,
            value: checked,
            onSuccess: () => {
                setForm({
                    ...form,
                    [name]: checked,
                });
            },
        };

        if (name === "orderOfferDsysAiEnabled") {
            dispatch(setExpasoftCollingSetting(reqData));

            return;
        }

        dispatch(setSystemSetting(reqData));
    };

    const fetchMaintenanceNoticeSettings = () => {
        dispatch(getMaintenanceNoticeSettings({
            getResult: (result) => {
                setFormNoticeSettings({
                    ...result,
                    timeIntervalStart: convertUtcToLocal(result.timeIntervalStart),
                    timeIntervalEnd: convertUtcToLocal(result.timeIntervalEnd),
                });
            },
        }));
    };

    const onChangeMaintenanceNoticeSettings  = (event, {name, value, checked}) => {
        if (name === "publish") {
            onSaveMaintenanceNoticeSettings({[name]: checked});
        }

        setFormNoticeSettings({
            ...formNoticeSettings,
            [name]: value || checked,
        });
    };

    const onSaveMaintenanceNoticeSettings = (reqData = {}) => {
        dispatch(setMaintenanceNoticeSettings({
            ...formNoticeSettings,
            timeIntervalStart: getUtcDateFilter(formNoticeSettings.timeIntervalStart),
            timeIntervalEnd: getUtcDateFilter(formNoticeSettings.timeIntervalEnd),
            ...reqData,
            onSuccess: () => {
                fetchMaintenanceNoticeSettings();
                toastSuccess("Параметры публикации уведомления успешно обновлены");
            },
        }));
    };

    const onChange = (event, {value, text}) => {
        setClientId(value);
        setClientName(text);
    };

    const searchEmptyRecord = () => {
        if (removePhoneMask(contractorPhone).length !== PHONE_NUMBER.LENGTH) {
            toastError("Введите номер телефона полностью");

            return;
        }

        dispatch(getEmptyRecord({
            contractorPhone: removePhoneMask(contractorPhone),
            getResult: (result) => {
                if (!isEmpty(result)) {
                    setEmptyRecord(result);

                    return;
                }

                toastError("Пустая учетная запись не найдена");
            },
        }));
    };

    const confirmDeleteEmptyRecord = () => {
        dispatch(deleteEmptyRecord({
            contractorId: emptyRecord.contractorId,
            onSuccess: () => {
                toastSuccess("Учетная запись успешно удалена");
                setContractorPhoneNumber("");
                setEmptyRecord({});
                setIsOpenConfirm(false);
            },
        }));
    };

    const onCorrectDeposit = (depositType) => {
        if (!clientId) {
            toastError("Выберите клиента для корректировки депозита");
        }

        const reqData = {
            clientId,
            getResult: (result) => {
                const {
                    depositWasCorrected,
                } = result;

                if (!depositWasCorrected) {
                    toastSuccess("Корректировка депозита не требуется");

                    return;
                }

                toastSuccess(`Корректировка депозита (${ORDER_WORK_REPORT_TYPE_TRANSLATE[depositType]}) успешно выполнена`);

                setModalData({
                    ...result,
                    depositType,
                });
            },
        };

        if (depositType === ORDER_WORK_REPORT_TYPE.SMZ) {
            dispatch(npdDepositCorrect(reqData));

            return;
        }

        dispatch(ndflDepositCorrect(reqData));
    };

    const renderConfirm = () => {
        return isOpenConfirm &&
            <NmConfirmV2
                size="sm"
                isNeedClosing={false}
                onCancel={() => setIsOpenConfirm(false)}
                onConfirm={confirmDeleteEmptyRecord}
                content={`Вы уверены, что хотите удалить учетную запись с номером телефона ${phoneFormat(emptyRecord.phone)}?`}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />;
    };

    return (
        <>
            {renderConfirm()}
            <DownloadDataFromDeposits />
            <NmTitle
                size="xl"
                className="mb-5"
            >
                Системные настройки
            </NmTitle>
            {
                SYSTEM_SETTINGS.map(item => {
                    return (
                        <NmCheckboxV2
                            label={item.name}
                            checked={form[item.key]}
                            className="mb-2"
                            disabled={setSettingsProgress || !isAccessEditSystemSettings}
                            onChange={onChangeSystemSettings}
                            name={item.key}
                            toggle
                        />
                    );
                })
            }
            <NmTitle
                size="xl"
                className="mt-2 mb-5"
            >
                Обзвон Expasoft
            </NmTitle>
            {
                SYSTEM_SETTINGS_EXPASOFT.map(item => {
                    return (
                        <NmCheckboxV2
                            label={item.name}
                            checked={form[item.key]}
                            className="mb-2"
                            disabled={setSettingsProgress || !isAccessEditExpasoftCalling}
                            onChange={onChangeSystemSettings}
                            name={item.key}
                            toggle
                        />
                    );
                })
            }
            <NmTitle
                size="xl"
                className="mb-5 mt-5"
            >
                Корректировка депозитов
            </NmTitle>
            <div className="col-4">
                <FilterCustomer
                    isBff={true}
                    name="clientId"
                    label={null}
                    placeholder="Выберите клиента"
                    value={clientId}
                    className="mb-2"
                    onChange={onChange}
                    isMountLoad={false}
                />
            </div>
            <div className="mb-2">
                <NmButton
                    onClick={() => onCorrectDeposit(ORDER_WORK_REPORT_TYPE.SMZ)}
                    color="light-green"
                    size="lg"
                    disabled={progressCorrection}
                    children="Выполнить корректировку депозита НПД"
                />
            </div>
            <div className="mb-2">
                <NmButton
                    onClick={() => onCorrectDeposit(ORDER_WORK_REPORT_TYPE.CIVIL)}
                    color="light-green"
                    size="lg"
                    disabled={progressCorrection}
                    children="Выполнить корректировку депозита НДФЛ"
                />
            </div>
            {
                modalData &&
                <SystemSettingsCorrectDepositResults
                    clientName={clientName}
                    onClose={() => {
                        setModalData(null);
                    }}
                    data={modalData}
                />
            }
            <NmTitle
                size="xl"
                className="mb-5 mt-5"
            >
                Удаление пустых учетных записей
            </NmTitle>
            <div className="flex flex-aligned-end mb-2 col-16 col-md-4">
                <NmInputV2
                    size="lg"
                    label="Телефон"
                    mask="+7 (999) 999 99 99"
                    value={contractorPhone}
                    onChange={(event, {value}) => {
                        setContractorPhoneNumber(value);
                    }}
                    disabled={!isAccessDeleteEmptyAccounts}
                />
                <NmButton
                    className="ms-2"
                    onClick={searchEmptyRecord}
                    color="light-green"
                    size="lg"
                    children="Найти"
                    loading={emptyRecordProgress}
                    disabled={emptyRecordProgress || !isAccessDeleteEmptyAccounts}
                />
            </div>
            {
                !isEmpty(emptyRecord) &&
                <>
                    <Text level="3">
                        Пустая учетная запись найдена:
                    </Text>
                    <div className="flex flex-aligned-center mb-2 col-16 col-md-4">
                        <Text level="3">
                            {`${emptyRecord.phone} ${emptyRecord.lastLoginDate ? `(${formatLocalDate(emptyRecord.lastLoginDate, "dd.MM.yyyy HH:mm")})` : ""}`}
                        </Text>
                        <NmButton
                            className="ms-2"
                            onClick={() => setIsOpenConfirm(true)}
                            color="red"
                            size="lg"
                            children="Удалить"
                            loading={emptyRecordProgress}
                            disabled={emptyRecordProgress}
                        />
                    </div>
                </>
            }
            <NmTitle
                size="xl"
                className="mb-5 mt-5"
            >
                Уведомление о ведении тех. работ
            </NmTitle>
            <NmCheckboxV2
                label="Опубликовать уведомление"
                checked={formNoticeSettings.publish}
                className="mb-2"
                disabled={setSettingsProgress}
                onChange={onChangeMaintenanceNoticeSettings}
                name="publish"
                toggle
            />
            <NmCheckboxV2
                label="Установить дату и время публикации"
                checked={formNoticeSettings.inTimeInterval}
                className="mb-2"
                disabled={setSettingsProgress}
                onChange={onChangeMaintenanceNoticeSettings}
                name="inTimeInterval"
            />
            <div className="flex flex-wrap align-items-end col-16">
                <div className="col-16 col-md-6 me-2">
                    <NmDateRangePickerV2
                        className="col-6"
                        size="xl"
                        startFieldName="timeIntervalStart"
                        endFieldName="timeIntervalEnd"
                        value={{
                            timeIntervalStart: formNoticeSettings.timeIntervalStart,
                            timeIntervalEnd: formNoticeSettings.timeIntervalEnd,
                        }}
                        label="Дата и время публикации уведомления"
                        onChange={onChangeMaintenanceNoticeSettings}
                        showTimeSelect
                        timeIntervals={1}
                        datePickerDateFormat="dd.MM.yy HH:mm"
                        dateFormatMask="99.99.99 99:99"
                        placeholderStart="дд.мм.гг чч:мм"
                        placeholderEnd="дд.мм.гг чч:мм"
                        disabled={setSettingsProgress || !formNoticeSettings.inTimeInterval}
                        returnString={false}
                    />
                </div>
                <div>
                    <NmButton
                        className="mt-2"
                        size="xl"
                        onClick={() => onSaveMaintenanceNoticeSettings()}
                        disabled={setSettingsProgress || !formNoticeSettings.inTimeInterval}
                    >
                        Cохранить
                    </NmButton>
                </div>
            </div>
        </>
    );
};

export default SettingsServiceSystem;