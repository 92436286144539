import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import settingsInstructionsNaimix from "./reducer";

import {getVisibleInstructionByType} from "../../../../../components/Instructions/utils/access";

import {USER_INSTRUCTION_TYPE_DICT} from "../../../../../constants/dicts";

export const settingsInstructionsNaimixSelector = state => state.bff.settingsInstructionsNaimix;

export const naimixInstructionsSelector = ({list}) => {
    if (isEmpty(list)) {
        return [];
    }

    const filterListByInstructionType = (instructionType) => list.find(item => item.instructionType === instructionType) || {};

    return [
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.CLIENT_ADMIN_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.FOREMAN_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.PROJECT_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.OBJECT_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.HR_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.CLIENT_ACCOUNTANT_INSTRUCTION.VALUE),
    ].filter((item) => getVisibleInstructionByType(item.instructionType));
};

export const settingsInstructionsInfoGroupSelector = createSelector(
    settingsInstructionsNaimixSelector,
    naimixInstructionsSelector,
);