export const KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE = {
    CURRENT_USER: "CURRENT_USER",
    FIXED_USER: "FIXED_USER",
    DEPARTMENT_USER: "DEPARTMENT_USER",
    DEPARTMENT_HEAD_USER: "DEPARTMENT_HEAD_USER",
    PARENT_DEPARTMENT_HEAD_USER: "PARENT_DEPARTMENT_HEAD_USER",
    ROOT_DEPARTMENT_HEAD_OR_MCHD_USER: "ROOT_DEPARTMENT_HEAD_OR_MCHD_USER",
};

export const KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE_DICT = {
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.CURRENT_USER]: "Сотрудник",
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.FIXED_USER]: "Фиксированный",
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.DEPARTMENT_USER]: "Роль в организационной структуре",
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.ROOT_DEPARTMENT_HEAD_OR_MCHD_USER]: "Руководитель компании",
};

export const KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE = {
    DEPARTMENT_HEAD: "DEPARTMENT_HEAD",
    DEPUTY_DEPARTMENT_HEAD : "DEPUTY_DEPARTMENT_HEAD",
};

export const KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE_DICT = {
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE.DEPARTMENT_HEAD]: "Руководитель отдела",
    [KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE.DEPUTY_DEPARTMENT_HEAD]: "Заместитель руководителя отдела",
};

export const KEDO_DOCUMENT_ROUTE_ACTION_TYPE = {
    SIGN: "SIGN",
    APPROVE: "APPROVE",
};

export const KEDO_DOCUMENT_ROUTE_ACTION_TYPE_DICT = {
    [KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN]: "Подписать",
    [KEDO_DOCUMENT_ROUTE_ACTION_TYPE.APPROVE]: "Согласовать",
};

export const KEDO_DOCUMENT_ROUTE_SING_TYPE = {
    UKEP: "UKEP",
    UNEP: "UNEP",
    PEP: "PEP",
};

export const KEDO_DOCUMENT_ROUTE_SING_TYPE_DICT = {
    [KEDO_DOCUMENT_ROUTE_SING_TYPE.UKEP]: "УКЭП",
    [KEDO_DOCUMENT_ROUTE_SING_TYPE.UNEP]: "УНЭП",
    [KEDO_DOCUMENT_ROUTE_SING_TYPE.PEP]: "ПЭП",
};

export const KEDO_DOCUMENT_ROUTE_PARTICIPANT_NUMBER_WORDS = [
    "Первый",
    "Второй",
    "Третий",
    "Четвертый",
    "Пятый",
    "Шестой",
    "Седьмой",
    "Восьмой",
    "Девятый",
    "Десятый",
    "Одиннадцатый",
    "Двенадцатый",
    "Тринадцатый",
    "Четырнадцатый",
    "Пятнадцатый",
    "Шестнадцатый",
    "Семнадцатый",
    "Восемнадцатый",
    "Девятнадцатый",
    "Двадцатый",
];