import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {select} from "../../node_modules/redux-saga/effects";

import {edmPositionsToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/positions";
//*  TYPES  *//

const EDM_POSITION_LIST_READ_REQUEST = "EDM_POSITION_LIST_READ_REQUEST";
const EDM_POSITION_LIST_READ_SUCCESS = "EDM_POSITION_LIST_READ_SUCCESS";
const EDM_POSITION_LIST_READ_ERROR = "EDM_POSITION_LIST_READ_ERROR";

const EDM_POSITION_UPDATE_REQUEST = "EDM_POSITION_UPDATE_REQUEST";
const EDM_POSITION_UPDATE_SUCCESS = "EDM_POSITION_UPDATE_SUCCESS";
const EDM_POSITION_UPDATE_ERROR = "EDM_POSITION_UPDATE_ERROR";

const EDM_POSITION_DELETE_REQUEST = "EDM_POSITION_DELETE_REQUEST";
const EDM_POSITION_DELETE_SUCCESS = "EDM_POSITION_DELETE_SUCCESS";
const EDM_POSITION_DELETE_ERROR = "EDM_POSITION_DELETE_ERROR";

const EDM_POSITION_ADD_REQUEST = "EDM_POSITION_ADD_REQUEST";
const EDM_POSITION_ADD_SUCCESS = "EDM_POSITION_ADD_SUCCESS";
const EDM_POSITION_ADD_ERROR = "EDM_POSITION_ADD_ERROR";

const EDM_POSITION_COMMON_COUNT_REQUEST = "EDM_POSITION__COMMON_COUNT_REQUEST";
const EDM_POSITION_COMMON_COUNT_SUCCESS = "EDM_POSITION__COMMON_COUNT_SUCCESS";
const EDM_POSITION_COMMON_COUNT_ERROR = "EDM_POSITION__COMMON_COUNT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    progress: false,
    actionProgress: false,
    commonCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EDM_POSITION_ADD_REQUEST:
        case EDM_POSITION_DELETE_REQUEST:
        case EDM_POSITION_UPDATE_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case EDM_POSITION_LIST_READ_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case EDM_POSITION_COMMON_COUNT_SUCCESS:
            return {
                ...state,
                commonCount: payload,
            };
        case EDM_POSITION_ADD_SUCCESS:
        case EDM_POSITION_DELETE_SUCCESS:
        case EDM_POSITION_UPDATE_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case EDM_POSITION_LIST_READ_SUCCESS:
            const {
                results = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case EDM_POSITION_ADD_ERROR:
        case EDM_POSITION_DELETE_ERROR:
        case EDM_POSITION_UPDATE_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case EDM_POSITION_LIST_READ_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getEdmPositionList(payload) {
    return {
        type: EDM_POSITION_LIST_READ_REQUEST,
        payload,
    };
}

export function updateEdmPosition(payload) {
    return {
        type: EDM_POSITION_UPDATE_REQUEST,
        payload,
    };
}

export function deleteEdmPosition(payload) {
    return {
        type: EDM_POSITION_DELETE_REQUEST,
        payload,
    };
}

export function addEdmPosition(payload) {
    return {
        type: EDM_POSITION_ADD_REQUEST,
        payload,
    };
}

export function getEdmPositionCommonCount(payload) {
    return {
        type: EDM_POSITION_COMMON_COUNT_REQUEST,
        payload,
    };
}


//*  SELECTORS  *//

export const edmPositionsSelector = state => state.edmPositions;

export const edmPositionTotalCountSelector = createSelector(edmPositionsSelector, ({totalCount}) => totalCount);

export const edmPositionListSelector = createSelector(edmPositionsSelector, ({list}) => list);

export const edmPositionListOptionsSelector = createSelector(edmPositionsSelector, ({list}) => edmPositionsToOptions(list));

export const edmPositionListProgressSelector = createSelector(edmPositionsSelector, ({progress}) => progress);

export const edmPositionActionProgressSelector = createSelector(edmPositionsSelector, ({actionProgress}) => actionProgress);

export const edmPositionListTotalPagesSelector = createSelector(edmPositionsSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const edmPositionCommonCountSelector = createSelector(edmPositionsSelector, ({commonCount}) => commonCount);

export const edmPositionListPageDataSelector = createSelector(edmPositionsSelector, ({pageData}) => pageData);


//*  SAGA  *//

export const getEdmPositionListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDM_POSITION_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const updateEdmPositionSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            positionId,
        } = data;

        const result = yield request.patch(`${controller}/${clientId}/${positionId}`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_UPDATE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно изменена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
    } catch (error) {
        yield put({
            type: EDM_POSITION_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const addEdmPositionSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_ADD_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно добавлена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
        yield put(getEdmPositionCommonCount({clientId: data.clientId}));

    } catch (error) {
        yield put({
            type: EDM_POSITION_ADD_ERROR,
            payload: error,
        });
    }
};

export const deleteEdmPositionSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            positionId,
        } = data;

        const result = yield request.delete(`${controller}/${clientId}/${positionId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_DELETE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно удалена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
        yield put(getEdmPositionCommonCount({clientId}));
    } catch (error) {
        yield put({
            type: EDM_POSITION_DELETE_ERROR,
            payload: error,
        });
    }
};

export const getEdmPositionCommonCountSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            clientId,
        } = payload;

        const state = yield select();
        const pageData = yield edmPositionListPageDataSelector(state);

        const result = yield request.post(`${controller}/count`, {clientId, ...pageData});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_COMMON_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_COMMON_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: EDM_POSITION_COMMON_COUNT_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(EDM_POSITION_LIST_READ_REQUEST, getEdmPositionListSaga),
        takeEvery(EDM_POSITION_UPDATE_REQUEST, updateEdmPositionSaga),
        takeEvery(EDM_POSITION_DELETE_REQUEST, deleteEdmPositionSaga),
        takeEvery(EDM_POSITION_ADD_REQUEST, addEdmPositionSaga),
        takeEvery(EDM_POSITION_COMMON_COUNT_REQUEST, getEdmPositionCommonCountSaga),
    ]);
}
