import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

const monitoringRiskSelector = (state) => state.bff.clientsMonitoringRisk;

export const bffClientMonitoringRiskListSelector = createSelector(
    monitoringRiskSelector,
    ({list}) => list,
);

export const bffClientMonitoringRiskListLoadingSelector = createSelector(
    monitoringRiskSelector,
    ({listLoading}) => listLoading,
);

export const bffClientMonitoringRiskLastExportDateTimeSelector = createSelector(
    monitoringRiskSelector,
    ({lastExportDateTime}) => lastExportDateTime,
);

export const bffClientMonitoringRiskIdentificationRiskSelector = createSelector(
    monitoringRiskSelector,
    ({identificationRisk}) => identificationRisk,
);

export const bffClientMonitoringRiskStartedExportSelector = createSelector(
    monitoringRiskSelector,
    ({startedExport}) => startedExport,
);

export const bffClientMonitoringRiskGenerateReportLoadingSelector = createSelector(
    monitoringRiskSelector,
    ({generateReportLoading}) => generateReportLoading,
);

export const bffClientMonitoringRiskUpdateDataLoadingSelector = createSelector(
    monitoringRiskSelector,
    ({updateDataLoading}) => updateDataLoading,
);

export const bffClientMonitoringRiskContractorsListSelector = createSelector(
    monitoringRiskSelector,
    ({contractorsList}) => contractorsList,
);

export const bffClientMonitoringRiskContractorsListLoadingSelector = createSelector(
    monitoringRiskSelector,
    ({contractorsListLoading}) => contractorsListLoading,
);

export const bffClientMonitoringRiskContractorsListTotalCountSelector = createSelector(
    monitoringRiskSelector,
    ({contractorsListTotalCount}) => contractorsListTotalCount,
);

export const bffClientMonitoringRiskContractorsListTotalPagesSelector = createSelector(
    monitoringRiskSelector,
    ({contractorsListTotalCount, contractorsListPageData: {pageSize}}) => getTotalPages(contractorsListTotalCount, pageSize),
);