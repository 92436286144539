import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty,isEqual} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as ArchiveIcon} from "../../../images/archive.svg";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {ReactComponent as ListIcon} from "../../../images/list.svg";
import OrderTemplateInfo from "../../order/template-info";
import DocumentFilter from "../document-filter";
import DocumentNotificationRecreateForm from "../document-recreate-form";

import dateFormat, {convertUtcToLocal, formatDateWithoutTime} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {handleDateFieldFilter, handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    DOCUMENT_STATUS,
    DOCUMENT_STATUS_COLOR_TRANSCRIPT,
    DOCUMENT_TYPE,
    DOCUMENT_UNFILLED_REQUISITES_OVM,
} from "../../../constants/documentType";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";
import {
    DOCUMENTS_LIST_EXTERNAL_FRAME_CONTRACT_NAME,
    DOCUMENTS_LIST_TEMPLATE_LABEL_BY_DOCUMENT_TYPE,
} from "./constants";

import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    downloadDocument,
    getDocumentList,
    getDocumentListFormattedToTaskIdsSelector,
    getDocumentStatusDictSelector,
    getDocumentTypeDictsSelector,
    updateFieldDocumentStore,
    updateWorkNotificationsArchived,
} from "../../../ducks/documents";
import {addDocumentsExport} from "../../../ducks/documentsExport";
import {getAllTasks, jobTasksContractorStatusProcessingSelector} from "../../../ducks/job";
import {
    orderContractsProgressSelector,
    terminateFrameContract,
    validateContractorForContractAction,
} from "../../../ducks/orderContracts";

import {documentType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

import {SELF_CONTRACTOR, SUB_PAGE_CROWD_TASK_CARD, SUB_PAGE_ORDER_CONTRACTOR} from "../../../constants/link-params";

class DocumentList extends Component {
    static propTypes = {
        documentList: PropTypes.arrayOf(documentType),
        totalPages: PropTypes.number,
        getDocumentList: PropTypes.func,
        progressList: PropTypes.bool,
        documentTypeDict: PropTypes.object,
        terminateFrameContract: PropTypes.func,
        downloadDocument: PropTypes.func,
        isSettings: PropTypes.bool,
        noPadding: PropTypes.bool,
    };

    static defaultProps = {
        documentList: [],
        totalPages: 0,
        getDocumentList: () => {
        },
        progressList: false,
        documentTypeDict: {},
        updateFieldDocumentStore: () => {
        },
        terminateFrameContract: () => {
        },
        downloadDocument: () => {
        },
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
            location: {
                pathname,
            },
        } = props;

        this.clientId = clientId;
        this.isNotificationPage = pathname.includes("notifications");

        this.defaultFilter = {
            clientId: undefined,
            documentTypeFilter: [],
            orderNumFilter: "",
            contractorPhoneFilter: "",
            contractorNameFilter: "",
            orderNameFilter: "",
            taskNumFilter: "",
            taskNameFilter: "",
            fromDateFilter: null,
            toDateFilter: null,
            projectIdsFilter: [],
            objectIdsFilter: [],
            externalDocumentFilter: null,
            contractorIdFilter: null,
        };

        this.state = {
            isSearch: false,
            pageNum: 1,
            pageSize: 25,
            isLoading: true,
            archived: false,
            documentTypeDictOptions: [],
            isOpenConfirm: false,
            isOpenRecreateModal: false,
            contractClientId: "",
            contractContractorId: "",
            selectedList: [],
            documentList: [],
            numberSelected: 0,
            action: {},
            document: {},
            ...this.defaultFilter,
        };

        this.role = ls(USER_ROLE);
        this.isAccessArchived = ![NM_COORDINATOR, NM_CONSULTANT, NM_CHIEF_ACCOUNTANT].includes(this.role);
    }

    componentDidMount() {
        const {
            pageNum,
            pageSize,
            filterData,
        } = this.props;

        if (pageNum) {
            this.setState(prevState => ({
                ...prevState,
                ...filterData,
                pageNum,
                pageSize,
            }), this.initDocumentTypeFilter);

            return;
        }
        this.initDocumentTypeFilter();
    }

    static getDerivedStateFromProps(props, state) {
        const {documentList} = props;
        const {documentList: oldList} = state;

        if (!isEqual(documentList, oldList)) {
            const selectedList = documentList.map(item => ({
                ...item,
            }));

            return {
                ...state,
                selectedList,
                documentList,
                numberSelected: 0,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, {isLoading}) {
        const {
            documentTypeDict,
            progressList,
            location: {
                pathname,
            },
        } = this.props;
        const {
            documentTypeDict: oldDocumentTypeDict,
            progressList: oldProgressList,
            location: {
                pathname: oldPathname,
            },
        } = prevProps;

        if (pathname !== oldPathname) {
            this.isNotificationPage = pathname.includes("notifications");

            this.initDocumentTypeFilter();
        }

        if (JSON.stringify(oldDocumentTypeDict) !== JSON.stringify(documentTypeDict)) {
            this.initDocumentTypeFilter();
        }

        if (progressList !== oldProgressList && progressList !== isLoading) {
            this.setState({
                isLoading: progressList,
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldDocumentStore} = this.props;

        updateFieldDocumentStore({list: []});
    }

    get localizationData() {
        const {t} = this.props;

        return {
            title: t("documents-list.title"),
            titleNotification: t("documents-list.notifications"),
            downloadBtn: t("button.download"),
            terminateBtn: t("documents-list.terminate-contract"),
            recreateBtn: t("button.update"),
            confirmNotification: t("documents-list.confirm-notification-filed"),
            confirmTerminateContract: t("documents-list.confirm-terminate-contract"),
            specifyTypesDocumentsAndPeriod: t("documents-list.specify-types-documents-and-period"),
            actionUnarchive: t("documents-list.action-unarchive"),
            notificationFiled: t("documents-list.notification-filed"),
            nothingToUnload: t("documents-list.nothingToUnload"),
        };
    }

    initDocumentTypeFilter() {
        const {documentTypeDict} = this.props;

        this.setState({
            documentTypeDictOptions: dictionaryToOptions(documentTypeDict).filter(({value}) => this.filterCondition(value)),
            ...this.defaultFilter,
        }, this.fetchList);
    };

    filterCondition = item => {
        const generalCondition = ![
            DOCUMENT_TYPE.AGENCY_CONTRACT,
            DOCUMENT_TYPE.AGENCY_CONTRACT_CLIENT,
            DOCUMENT_TYPE.CONSENT_TO_PROCESSING_PERSONAL_DATA,
            DOCUMENT_TYPE.CASH_RECEIPT,
            DOCUMENT_TYPE.NAIMIX_REWARD_RECEIPT,
            DOCUMENT_TYPE.START_WORK_NOTIFY,
            DOCUMENT_TYPE.END_WORK_NOTIFY,
            DOCUMENT_TYPE.RECEIPT_OF_PAYMENT,
            DOCUMENT_TYPE.TRANSACTION_RECEIPT,
            DOCUMENT_TYPE.CONTRACTOR_PHONE_TRANSACTION_RECEIPT,
        ].includes(item);

        if (this.isNotificationPage) {
            return [DOCUMENT_TYPE.START_WORK_NOTIFY, DOCUMENT_TYPE.END_WORK_NOTIFY].includes(item);
        }

        return generalCondition;
    };

    getFilterForRequest() {
        const {
            documentTypeDict,
        } = this.props;

        const {
            documentTypeFilter,
        } = this.state;

        return documentTypeFilter.length ? documentTypeFilter : Object.keys(documentTypeDict).filter(this.filterCondition);
    }

    fetchList = () => {
        const {
            fetchList,
            getDocumentList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            orderNumFilter,
            contractorPhoneFilter: phoneFilter,
            contractorNameFilter,
            contractorIdFilter,
            orderNameFilter,
            fromDateFilter,
            toDateFilter,
            clientId,
            archived,
            objectIdsFilter,
            projectIdsFilter,
            externalDocumentFilter,
            taskNumFilter,
            taskNameFilter,
        } = this.state;

        const reqData = {
            pageNum,
            pageSize,
            clientId: clientId || this.clientId,
            documentTypeFilter: this.getFilterForRequest(),
            orderNumFilter: isNullOrWhitespace(orderNumFilter) ? undefined : orderNumFilter,
            contractorId: handleFormString(contractorIdFilter),
            contractorNameFilter: handleFormString(contractorNameFilter),
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
            contractorNameInDocFilter: false,
            orderNameFilter: handleFormString(orderNameFilter),
            fromDateFilter,
            toDateFilter,
            actualFilter: this.isNotificationPage ? !archived : undefined,
            projectIdsFilter: isEmpty(projectIdsFilter) ? undefined : projectIdsFilter,
            objectIdsFilter: isEmpty(objectIdsFilter) ? undefined : objectIdsFilter,
            externalDocumentFilter,
            taskNumFilter,
            taskNameFilter,
        };

        if (fetchList) {
            fetchList(reqData);

            return;
        }

        getDocumentList(reqData);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".nm-page").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    downloadOVMNotifications = (document) => {
        const {
            workAddress,
            ovmUnitName,
            documentType,
        } = document;

        if ((!workAddress && documentType !== DOCUMENT_TYPE.END_WORK_NOTIFY) || !ovmUnitName) {
            toastError(DOCUMENT_UNFILLED_REQUISITES_OVM);

            return;
        }

        this.handleDownload(document);
    };

    handleDownload = ({downloadLink, documentType}) => {
        const {downloadDocument} = this.props;

        let extension = "pdf";

        if (documentType === DOCUMENT_TYPE.START_WORK_NOTIFY || documentType === DOCUMENT_TYPE.END_WORK_NOTIFY) {
            extension = "xlsx";
        } else if (documentType === DOCUMENT_TYPE.AGENCY_CONTRACT_CLIENT) {
            extension = "doc";
        }

        downloadDocument({
            isFillOrigin: documentType !== DOCUMENT_TYPE.INSURANCE_POLICY,
            downloadLink,
            extension,
            documentType,
        });
    };

    handleMassArchive = () => {
        const {updateWorkNotificationsArchived} = this.props;

        const {archived, selectedList} = this.state;

        updateWorkNotificationsArchived({
            actuality: archived,
            clientId: this.clientId,
            documentIds: selectedList.filter(item => item.isSelected).map(({documentsId}) => documentsId),
        });

        this.closeWindowConfirm();
    };

    openConfirmArchive = () => {
        const {confirmNotification} = this.localizationData;

        this.openWindowConfirm({
            confirmText: confirmNotification,
            onConfirm: this.handleMassArchive,
        });
    };

    onDownload = (document) => {
        const {
            documentType,
        } = document;

        if (documentType === DOCUMENT_TYPE.INSURANCE_POLICY) {
            window.open(document.downloadLink, "_blank", "noopener noreferrer");
        }

        if ([DOCUMENT_TYPE.START_WORK_NOTIFY, DOCUMENT_TYPE.END_WORK_NOTIFY].includes(documentType)) {
            this.downloadOVMNotifications(document);

            return;
        }

        this.handleDownload(document);
    };

    onTerminateContract = (document) => {
        this.openWindowConfirm({
            onConfirm: this.validateContractorForContractAction,
            confirmHeader: `Вы действительно хотите расторгнуть рамочный договор с исполнителем ${document.contractorName}?`,
            warning: true,
            confirmText: "В случае подтверждения исполнитель не сможет оказывать услуги (выполнять работы) на заказах вашей компании.",
            ...document,
        });
    };

    closeWindowConfirm = () => {
        this.setState({
            isOpenConfirm: false,
            contractClientId: "",
            contractContractorId: "",
            action: {},
        });
    };

    openWindowConfirm(action) {
        this.setState({
            isOpenConfirm: true,
            action,
        });
    };

    renderConfirmWindow() {
        const {
            t,
            frameContractActionProgress,
        } = this.props;

        const {
            isOpenConfirm,
            action: {
                confirmText,
                confirmHeader,
                onConfirm,
                warning,
                submitBtnTxt = t("button.yes"),
                cancelBtnTxt = t("button.no"),
            },
        } = this.state;

        return (isOpenConfirm &&
            <NmConfirmV2
                warning={warning}
                title={confirmHeader}
                content={confirmText}
                onCancel={this.closeWindowConfirm}
                onConfirm={onConfirm}
                confirmButton={submitBtnTxt}
                cancelButton={cancelBtnTxt}
                loading={frameContractActionProgress}
            />
        );
    }

    validateContractorForContractAction = () => {
        const {
            validateContractorForContractAction,
        } = this.props;

        const {
            action: {
                documentsId: documentId,
                documentType,
            },
        } = this.state;

        if (documentType === DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT) {
            this.terminateFrameContract(documentId);

            return;
        }

        validateContractorForContractAction(
            {
                data: {
                    documentId,
                },
                onSuccess: () => {
                    this.terminateFrameContract(documentId);
                },
                onNeedShowWarning: () => {
                    this.openWindowConfirm({
                        onConfirm: () => {
                            this.terminateFrameContract(documentId);
                        },
                        confirmText: "\tОбращаем внимание, что вы собираетесь расторгнуть рамочный договор с иностранным гражданином (лицом без гражданства).\n\n\t В соответствии с законодательством РФ необходимо уведомить территориальное подразделение МВД России по вопросам миграции о расторжении договора с иностранным гражданином (лицом без гражданства) в течение 3 рабочих дней с даты расторжения такого договора",
                        documentsId: documentId,
                        submitBtnTxt: "Подтвердить",
                        cancelBtnTxt: "Отменить",
                    });
                },
            },
        );
    };

    terminateFrameContract = (documentId) => {
        const {
            currentMember: {
                clientId,
                clientUserId,
            },
            terminateFrameContract,
            getAllTasks,
        } = this.props;

        terminateFrameContract({
            data: {
                documentId,
            },
            onSuccess: ({jobId}) => {
                if (!jobId) {
                    this.fetchList();

                    return;
                }

                getAllTasks({
                    clientId,
                    clientUserId,
                });
            },
        });

        this.closeWindowConfirm();
    };

    renderOrder = ({orderNum, orderName, orderId, clientId}) => {
        const {
            pageNum,
            pageSize,
            documentTypeFilter,
            orderNumFilter,
            contractorPhoneFilter,
            contractorNameFilter,
            orderNameFilter,
            fromDateFilter,
            toDateFilter,
            taskNumFilter,
            taskNameFilter,
        } = this.state;

        if (isNullOrWhitespace(orderId)) {
            return "--- / ---";
        }
        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", this.clientId || clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
        const text = `${orderNum} ${orderName}`;

        if ([FOREMAN].includes(this.role)) {
            return text;
        }

        return (
            <ExtLink
                to={orderLink}
                pageData={{pageNum, pageSize}}
                filterData={{
                    documentTypeFilter,
                    orderNumFilter,
                    contractorPhoneFilter,
                    contractorNameFilter,
                    orderNameFilter,
                    fromDateFilter,
                    toDateFilter,
                    taskNumFilter,
                    taskNameFilter,
                }}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    };

    renderTask= ({orderName, orderNum, crowdTaskId, clientId}) => {
        const {
            pageNum,
            pageSize,
            documentTypeFilter,
            orderNumFilter,
            contractorPhoneFilter,
            contractorNameFilter,
            orderNameFilter,
            fromDateFilter,
            toDateFilter,
            taskNumFilter,
            taskNameFilter,
        } = this.state;

        if (isNullOrWhitespace(crowdTaskId)) {
            return "--- / ---";
        }

        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", crowdTaskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED);
        const text = `${orderNum} ${orderName}`;

        if ([FOREMAN].includes(this.role)) {
            return text;
        }

        return (
            <ExtLink
                to={link}
                pageData={{pageNum, pageSize}}
                filterData={{
                    documentTypeFilter,
                    orderNumFilter,
                    contractorPhoneFilter,
                    contractorNameFilter,
                    orderNameFilter,
                    fromDateFilter,
                    toDateFilter,
                    taskNumFilter,
                    taskNameFilter,
                }}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    };

    renderFrameContractLink = (item) => {
        const link = LINK_CLIENT_REGISTRY_FC_CARD
            .replace(":clientId", item.clientId)
            .replace(":registryId", item.registryId);

        return this.renderLink(link, "Ссылка на реестр");
    };

    renderLink = (link, linkName) => {
        const {
            pageNum,
            pageSize,
            documentTypeFilter,
            orderNumFilter,
            contractorPhoneFilter,
            contractorNameFilter,
            orderNameFilter,
            fromDateFilter,
            toDateFilter,
            taskNumFilter,
            taskNameFilter,
        } = this.state;

        return (
            <ExtLink
                to={link}
                pageData={{pageNum, pageSize}}
                filterData={{
                    documentTypeFilter,
                    orderNumFilter,
                    contractorPhoneFilter,
                    contractorNameFilter,
                    orderNameFilter,
                    fromDateFilter,
                    toDateFilter,
                    taskNumFilter,
                    taskNameFilter,
                }}
                historyEnabled
            >
                {linkName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        if ([FOREMAN].includes(this.role)) {
            return objectName;
        }

        const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return this.renderLink(objectLink, objectName);
    };

    renderStatus = ({documentStatus, expirationDatetime}) => {
        const {
            documentStatusDict,
        } = this.props;
        console.log(documentStatus);

        const date = formatDateWithoutTime(convertUtcToLocal(expirationDatetime));
        const text = [
            DOCUMENT_STATUS.FRAME_CONTRACT_TERMINATED,
            DOCUMENT_STATUS.ADDITIONAL_AGREEMENT_TERMINATED,
        ].includes(documentStatus) ?
            `${documentStatusDict[documentStatus]} ${date}`
            : documentStatusDict[documentStatus];

        return (
            <NmBadge
                noWrap
                mod={DOCUMENT_STATUS_COLOR_TRANSCRIPT[documentStatus] || "gray"}
                text={text}
            />
        );
    };

    getMediaControls = (document) => {
        const {documentType, documentStatus} = document;
        const {
            client: {
                archived: isClientArchived,
            },
            getMediaControls,
        } = this.props;
        const {downloadBtn, terminateBtn, recreateBtn} = this.localizationData;
        const isAccessRecreateNotification = [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !isClientArchived;
        const isAccessTerminateFrameContract = [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) && !isClientArchived;

        //Исправление документов
        if (getMediaControls) {
            const controls = getMediaControls(document);

            const {
                renderCount,
                buttons,
            } = controls;

            return {
                renderCount,
                buttons: [
                    ...buttons,
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {this.onDownload(document);},
                            color: "light-green",
                            children: downloadBtn,
                        },
                    },
                ],
            };
        }

        // Уведомления ОВМ
        if (this.isNotificationPage) {
            return {
                renderCount: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {this.onDownload(document);},
                            color: "light-green",
                            children: downloadBtn,
                        },
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: recreateBtn,
                            onClick: () => {this.onRecreateNotification(document);},
                        },
                        visible: isAccessRecreateNotification,
                    },
                ],
            };
        }

        // Реестр документов
        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: terminateBtn,
                        color: "grey",
                        onClick: () => {this.onTerminateContract(document);},
                    },
                    visible: isAccessTerminateFrameContract && [
                        DOCUMENT_TYPE.FRAME_CONTRACT,
                        DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT,
                        DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT,
                    ].includes(documentType) && documentStatus === DOCUMENT_STATUS.FRAME_CONTRACT_ACTIVE,
                    asset: {
                        mobile: {children: terminateBtn},
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {this.onDownload(document);},
                        color: "light-green",
                        onlyIcon: true,
                        icon: <FileDownloadIcon />,
                    },
                    asset: {
                        mobile: {children: downloadBtn},
                    },
                },
            ],
        };
    };

    getTemplate = (item) => {
        if (
            ![
                DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_WORK,
                DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_TASK,
                DOCUMENT_TYPE.ORDER_APPLICATION,
                DOCUMENT_TYPE.TASK_APPLICATION,
                DOCUMENT_TYPE.FRAME_CONTRACT,
                DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT,
                DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT,
                DOCUMENT_TYPE.OTHER_DOCUMENT,
                DOCUMENT_TYPE.ADDITIONAL_AGREEMENT,
            ].includes(item.documentType)
        ) {
            return null;
        }

        return {
            label: DOCUMENTS_LIST_TEMPLATE_LABEL_BY_DOCUMENT_TYPE[item.documentType],
            noWrap: false,
            columnOnMobile: true,
            text: (
                <OrderTemplateInfo
                    id={item.templateInstanceId}
                    linkName={item.templateInstanceName}
                    link={item.templateInstancePdfDownloadLink}
                />
            ),
        };
    };

    getPrimaryHeader = (item) => {
        const {
            documentType,
            externalDocument,
            additionalAgreementInfo,
        } = item;
        const {
            documentTypeDict,
        } = this.props;

        if (additionalAgreementInfo) {
            return additionalAgreementInfo.documentName;
        }

        if (externalDocument) {
            if ([DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT].includes(documentType)) {
                return DOCUMENTS_LIST_EXTERNAL_FRAME_CONTRACT_NAME.replace(":type", "НДФЛ");
            }

            if ([DOCUMENT_TYPE.FRAME_CONTRACT].includes(documentType)) {
                return DOCUMENTS_LIST_EXTERNAL_FRAME_CONTRACT_NAME.replace(":type", "НПД");
            }
        }

        return documentTypeDict[documentType];
    };

    getRows = () => {
        const {
            selectedList,
        } = this.state;

        return selectedList.map(item => {
            const {
                contractorPhone,
                contractorLocatedOutsideRussia,
                contractorName,
                ovmUnitName,
                documentSubmissionDeadline,
                documentType,
                workAddress,
                objectId,
                externalDocument,
                contractNumber,
                externalDocumentDate,
            } = item;

            const notificationLabels = this.isNotificationPage ? [
                {label: "Адрес места выполнения работ", text: workAddress || "-", columnOnMobile: true},
                {label: "Поздразделение ОВМ", text: ovmUnitName || "-", columnOnMobile: true},
                {label: "Срок подачи", text: documentSubmissionDeadline ? `До ${dateFormat(documentSubmissionDeadline, "dd.MM.yyyy")} 23:59 (МСК)` : "-", columnOnMobile: true},
            ] : [];

            const orderInfo = ![
                DOCUMENT_TYPE.FRAME_CONTRACT,
                DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT,
                DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT,
                DOCUMENT_TYPE.OTHER_DOCUMENT,
                DOCUMENT_TYPE.TASK_APPLICATION,
                DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_TASK,
                DOCUMENT_TYPE.ADDITIONAL_AGREEMENT,
            ].includes(documentType) ?
                [{label: "Номер и наименование заказа", text: this.renderOrder(item), columnOnMobile: true}] :
                [];

            const taskInfo = [
                DOCUMENT_TYPE.TASK_APPLICATION,
                DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_TASK,
            ].includes(documentType) ?
                [{label: "Номер и наименование задания", text: this.renderTask(item), columnOnMobile: true}] :
                [];

            const objectInfo = (this.isNotificationPage || [
                DOCUMENT_TYPE.FRAME_CONTRACT,
                DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT,
                DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT,
                DOCUMENT_TYPE.OTHER_DOCUMENT,
            ].includes(documentType)) && objectId ?
                [{label: "Объект", text: this.renderObjectInfo(item), columnOnMobile: true}] :
                [];

            const frameContractRegistryLink = item.registryId ? [
                {
                    label: "Ссылка на реестр РД",
                    text: this.renderFrameContractLink(item),
                },
            ] :
                [];

            const mainAgreement = [DOCUMENT_TYPE.ADDITIONAL_AGREEMENT].includes(documentType)
            && item.additionalAgreementInfo ? [
                    {
                        label: "Договор",
                        text: `Договор №${item.additionalAgreementInfo.relatedFrameContractNum} 
                        от ${dateFormat(item.additionalAgreementInfo.relatedFrameContractDate, "dd.MM.yyyy")}`,
                    },
                ] : [];

            const personalDataDate = item.additionalAgreementInfo ? [
                {
                    label: "Версия персональных данных",
                    text: `от ${dateFormat(item.additionalAgreementInfo.personalDataDate, "dd.MM.yyyy")}`,
                },
            ] : [];

            const externalDocumentLabel = externalDocument && {
                text: "Внешний договор",
                noWrap: false,
                columnOnMobile: false,
                alignItems: "center",
                textTooltip: (
                    <HelpTooltip
                        hover={false}
                        height={18}
                        width={18}
                        type="light"
                        text={
                            <div>
                                <div>
                                    Номер договора:
                                    {" "}
                                    {contractNumber}
                                </div>
                                <div>
                                    Дата договора:
                                    {" "}
                                    {dateFormat(externalDocumentDate, "dd.MM.yyyy")}
                                </div>
                            </div>
                        }
                        position="bottom-left"
                    />
                ),
            };

            return {
                ...item,
                key: item.documentsId,
                showCheckBox: true,
                isSelected: Boolean(item.isSelected),
                contentRow: (
                    <NmListCard
                        checkbox
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-15"
                        primaryHeader={this.getPrimaryHeader(item)}
                        labels={[
                            externalDocumentLabel,
                            ...frameContractRegistryLink,
                            ...objectInfo,
                            ...orderInfo,
                            ...taskInfo,
                            ...mainAgreement,
                            ...personalDataDate,
                            {label: "Исполнитель", text: contractorName, columnOnMobile: true},
                            {label: "Телефон", text: phoneFormat(contractorPhone, contractorLocatedOutsideRussia), columnOnMobile: true},
                            this.getTemplate(item),
                            ...notificationLabels,
                        ]}
                        secondaryHeader={`Дата создания ${dateFormat(convertUtcToLocal(item.datetime))}`}
                        secondaryHeaderStatus={this.renderStatus(item)}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={this.getMediaControls(item)}
                    />
                ),
            };
        });
    };

    onChangeFilter = (e, {value, name}) => {
        this.setState({[name]: value});
    };

    clearFilter = () => {
        this.setState({
            pageNum: 1,
            ...this.defaultFilter,
        }, this.fetchList);
    };

    sendFilter = () => {
        this.setState({pageNum: 1, isSearch: true}, this.fetchList);
    };

    onSelectedRows = (selectedList) => {
        this.setState({
            selectedList,
            numberSelected: selectedList.filter(item => item.isSelected).length,
        });
    };

    exportDocuments = () => {
        const {addDocumentsExport} = this.props;
        const {specifyTypesDocumentsAndPeriod} = this.localizationData;

        const {
            documentTypeFilter,
            fromDateFilter,
            toDateFilter,
            orderNameFilter,
            orderNumFilter,
            projectIdsFilter,
            objectIdsFilter,
            externalDocumentFilter,
            taskNumFilter,
            taskNameFilter,
            contractorIdFilter,
            contractorNameFilter,
            contractorPhoneFilter,
        } = this.state;

        if (!fromDateFilter || !toDateFilter || !documentTypeFilter.length) {
            return toastError(specifyTypesDocumentsAndPeriod);
        }

        addDocumentsExport({
            clientId: this.clientId,
            fromClientId: this.clientId,
            contractorId: handleFormString(contractorIdFilter),
            contractorFio: handleFormString(contractorNameFilter),
            contractorPhone: isNullOrWhitespace(contractorPhoneFilter) ? undefined : removePhoneMask(contractorPhoneFilter),
            documentTypeFilter,
            fromDate: handleDateFieldFilter(fromDateFilter),
            orderName: handleFormString(orderNameFilter),
            orderNum: handleFormString(orderNumFilter),
            taskName: handleFormString(taskNameFilter),
            taskNum: handleFormString(taskNumFilter),
            toDate: handleDateFieldFilter(toDateFilter),
            projectIdsFilter: projectIdsFilter.length ? projectIdsFilter : undefined,
            objectIdsFilter: objectIdsFilter.length ? objectIdsFilter : undefined,
            externalDocumentFilter,
        });
    };

    toggleArchived = () => {
        this.setState(prevState => ({
            ...prevState,
            archived: !prevState.archived,
            pageNum: 1,
        }), this.fetchList);
    };

    closeRecreateNotificationWindow = () => {
        this.setState({
            isOpenRecreateModal: false,
            document: {},
        });
    };

    onRecreateNotification(document) {
        this.setState({
            isOpenRecreateModal: true,
            document,
        });
    };

    renderRecreateNotificationWindow() {
        const {
            isOpenRecreateModal,
            document,
        } = this.state;

        if (isOpenRecreateModal) {
            return (
                <DocumentNotificationRecreateForm
                    handleCancel={this.closeRecreateNotificationWindow}
                    document={document}
                />
            );
        }
        return null;
    }

    getHeaderMediaControls() {
        const {isSettings} = this.props;

        if (this.role === NM_CONSULTANT || isSettings) {
            return null;
        }

        const {archived} = this.state;
        const {progressList, t} = this.props;

        const archive = {
            component: COMPONENT.BUTTON,
            props: {
                disabled: progressList,
                onClick: this.toggleArchived,
                icon: archived ? <ListIcon /> : <ArchiveIcon />,
                children: archived ? t("order-list.list") : t("order-list.archive"),
                color: "grey",
            },
            visible: this.isNotificationPage,
        };

        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            size: "xl",
            buttons: [
                archive,
            ],
        };
    }

    renderHeader() {
        const {header} = this.props;

        if (header) {
            return header;
        }

        return (
            <NmTitle size="xl">
                {this.isNotificationPage ? "Уведомления ОВМ" : "Реестр документов"}
            </NmTitle>
        );
    }

    render() {
        const {
            pageNum,
            pageSize,
            documentTypeFilter,
            documentTypeDictOptions,
            orderNumFilter,
            orderNameFilter,
            fromDateFilter,
            toDateFilter,
            isOpenConfirm,
            numberSelected,
            archived,
            selectedList,
            isSearch,
            clientId,
            objectIdsFilter,
            projectIdsFilter,
            externalDocumentFilter,
            taskNumFilter,
            taskNameFilter,
            contractorIdFilter,
        } = this.state;

        const {
            totalPages,
            progressList,
            frameContractActionProgress,
            totalCount,
            client: {
                archived: isClientArchived,
            },
            isSettings,
            noPadding,
            tasksIds,
            documentsFormattedToTaskIds,
        } = this.props;

        const {actionUnarchive, notificationFiled} = this.localizationData;
        const isShowControls = !!selectedList?.length || isSearch;

        return (
            <NmPage
                noPadding={noPadding}
                header={this.renderHeader()}
                filtersBase={
                    <DocumentFilter
                        clientIdDefault={this.clientId}
                        isSettings={isSettings}
                        documentTypeDictOptions={documentTypeDictOptions}
                        documentTypeFilter={documentTypeFilter}
                        filter={{
                            documentTypeFilter,
                            orderNumFilter,
                            orderNameFilter,
                            fromDateFilter,
                            toDateFilter,
                            clientId,
                            objectIdsFilter,
                            projectIdsFilter,
                            externalDocumentFilter,
                            taskNumFilter,
                            taskNameFilter,
                            contractorIdFilter,
                        }}
                        onExport={this.exportDocuments}
                        onChange={this.onChangeFilter}
                        sendFilter={this.sendFilter}
                        clearFilter={this.clearFilter}
                        isShowExportButton={isShowControls}
                        isNotificationPage={this.isNotificationPage}
                    />
                }
                typeFilter="vertical"
                mediaControls={this.getHeaderMediaControls(isShowControls)}
                totalCount={totalCount}
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                isLoaded={progressList || frameContractActionProgress}
            >
                <Task />
                <ByTaskUpdater
                    fetch={this.fetchList}
                    taskIds={tasksIds}
                    dataIds={documentsFormattedToTaskIds}
                />
                {
                    this.renderRecreateNotificationWindow()
                }
                {
                    isOpenConfirm &&
                    this.renderConfirmWindow()
                }
                {
                    selectedList?.length ?
                        <CheckboxList
                            count={numberSelected}
                            mediaControls={{
                                buttons: [
                                    {
                                        component: COMPONENT.BUTTON,
                                        props: {
                                            disabled: numberSelected === 0,
                                            onClick: this.openConfirmArchive,
                                            children: archived ? actionUnarchive : notificationFiled,
                                            color: "green",
                                        },
                                    },
                                ],
                            }}
                            rows={this.getRows()}
                            onSelectedRows={
                                isShowControls &&
                                this.isNotificationPage &&
                                this.isAccessArchived &&
                                !isClientArchived &&
                                this.onSelectedRows
                            }
                        /> :
                        <NmEmptyPageV2
                            title={this.isNotificationPage ? "Данные отсутствуют" : "Документы отсутствуют"}
                            description={!this.isNotificationPage && "В реестре будут отображаться рамочные договоры, акты выполненных работ, заявки на заказ, страховые полисы, чеки ФНС России"}
                            isSearch={isSearch}
                            fetchProgress={progressList}
                        />
                }
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        documentTypeDict: getDocumentTypeDictsSelector(state),
        documentStatusDict: getDocumentStatusDictSelector(state),
        frameContractActionProgress: orderContractsProgressSelector(state),
        documentTypeFilter: state.documents.pageData.documentTypeFilter,
        client: clientCardInfoSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        tasksIds: jobTasksContractorStatusProcessingSelector(state),
        documentsFormattedToTaskIds: getDocumentListFormattedToTaskIdsSelector(state),
    }),
    {
        getDocumentList,
        updateFieldDocumentStore,
        downloadDocument,
        addDocumentsExport,
        terminateFrameContract,
        validateContractorForContractAction,
        updateWorkNotificationsArchived,
        getAllTasks,
    },
)(withTranslation()(DocumentList)));
