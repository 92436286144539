import {
    BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_REQUEST,
    BFF_FINANCES_NPD_SUMMARY_REPORTS_REQUEST,
    BFF_FINANCES_NPD_SUMMARY_UPDATE_STORE,
} from "./actions";

export function getNpdSummaryReports(payload) {
    return {
        type: BFF_FINANCES_NPD_SUMMARY_REPORTS_REQUEST,
        payload,
    };
}

export function getNpdSummaryDepositHistory(payload) {
    return {
        type: BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_REQUEST,
        payload,
    };
}

export function updateNpdSummaryStore(payload) {
    return {
        type: BFF_FINANCES_NPD_SUMMARY_UPDATE_STORE,
        payload,
    };
}
