import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getSettingsDirectoriesBanksPage,
    updateStoreSettingsDirectoriesBanks,
} from "../../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";

export const useSettingsDirectoriesBanksDetailsFetch = ({pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsDirectoriesBanks({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getSettingsDirectoriesBanksPage({
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};