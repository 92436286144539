import {createSelector} from "reselect";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getTotalPages} from "../../../../utils/mathHelper";
import {filterObject} from "../../../../utils/objectHelper";

import {CLIENT_CARD_FIELDS} from "../../../../constants/clientList";
import {REF_TYPE} from "../../../../constants/refProgram";
import {FOREMAN} from "../../../../constants/roles";

export const clientCardSelector = state => state.bff.clientCardInfo;
export const clientCardInfoSelector = createSelector(clientCardSelector, ({card}) => card);
export const clientCardCurrentInfoSelector = createSelector(clientCardSelector, ({currentClient}) => currentClient);
export const getCurrentClientNameSelector = createSelector(clientCardSelector, ({currentClient}) => currentClient ? currentClient.name : "");

export const getCurrentNmContractDateConclusionSelector = createSelector(clientCardSelector, ({currentClient = {}, card}) => {
    if (ls(USER_ROLE) === FOREMAN) {
        return currentClient.nmContractDateConclusion;
    }
    return card.nmContractDateConclusion;
});
export const getClientInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.CLIENT_INFO, card));

export const getClientTypeSelector = createSelector(clientCardSelector, ({card: {clientType}}) => clientType);

export const getAccountantInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.ACCOUNTANT_INFO, card));

export const getBossInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.BOSS_INFO, card));

export const getSignatoryInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.SIGNATORY_INFO, card));

export const getRepresentativeInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.REPRESENTATIVE_INFO, card));

export const getCategoryInfoSelector = createSelector(clientCardSelector, ({card: {categoryId, okved, specialityIds}}) => {
    return {categoryId, okved, specialityIds};
});
export const getBankInfoSelector = createSelector(clientCardSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.BANK_INFO, card));
export const clientCurrentDataSelector = createSelector(clientCardSelector, ({card, currentClient}) => card.clientId ? card : currentClient);
export const getClientCommissionRateSelector = createSelector(clientCardSelector, ({card, currentClient}) => card.currentCommissionRate || currentClient.currentCommissionRate);
export const clientCardPropertiesSelector = createSelector(clientCardSelector, ({properties}) => properties);
export const clientCardPropertiesCanViewContractorsContactsSelector = createSelector(clientCardSelector, ({properties: {canViewContractorContacts}}) => canViewContractorContacts);
export const getMigrantLicensePaymentProperties = createSelector(clientCardSelector, ({migrantLicensePaymentEnabled}) => migrantLicensePaymentEnabled);
export const clientCardInfoProgressSelector = createSelector(clientCardSelector, ({progress}) => progress);
export const clientCardInfoProgressActionSelector = createSelector(clientCardSelector, ({progressAction}) => progressAction);
export const clientReferralHistorySelector = createSelector(clientCardSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.list);
export const contractorsReferralHistorySelector = createSelector(clientCardSelector, ({[REF_TYPE.CONTRACTORS]: refData}) => refData.list);
export const clientReferralHistoryProgressSelector = createSelector(clientCardSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.progress);
export const contractorsReferralHistoryProgressSelector = createSelector(clientCardSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.progress);
export const clientReferralDataSelector = createSelector(clientCardSelector, ({clientRefData}) => clientRefData);
export const clientReferralHistoryPageDataSelector = createSelector(clientCardSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.pageData);
export const clientRefTotalPagesSelector = createSelector(clientCardSelector, ({[REF_TYPE.CLIENTS]: refData}) => getTotalPages(refData.totalCount, refData.pageData.pageSize));
export const contractorsRefTotalPagesSelector = createSelector(clientCardSelector, ({[REF_TYPE.CONTRACTORS]: refData}) => getTotalPages(refData.totalCount, refData.pageData.pageSize));
export const clientSpecialitiesSelector = createSelector(clientCardSelector, ({specialities}) => specialities);
export const clientSpecialitiesOptionsSelector = createSelector(clientCardSelector, ({specialities}) => {
    return specialities.map(item => ({
        key: item.id,
        value: item.id,
        text: item.value,
    }));
});
export const clientProgressRequisitesSelector = createSelector(clientCardSelector, ({progressRequisites}) => progressRequisites);
export const clientSpecialitiesProgressSelector = createSelector(clientCardSelector, ({progressSpecialities}) => progressSpecialities);
export const responseFromCheckClientPromocodeSelector = createSelector(clientCardSelector, ({checkPromocodeProgress, errorMessagePromocodeCheck, isPromocodeCheckSuccess}) => ({
    checkProgress: checkPromocodeProgress,
    errorMessageCheck: errorMessagePromocodeCheck,
    isCheckSuccess: isPromocodeCheckSuccess,
}));