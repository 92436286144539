import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_ORDERS_ADD_ORDER_GROUP_ERROR,
    BFF_ORDERS_ADD_ORDER_GROUP_REQUEST,
    BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_DELETE_ORDER_GROUP_ERROR,
    BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS,
    BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR,
    BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/order-groups",
        client: "/client-adm/orders/order-groups",
    });
};

// POST /bff/adm/clients/client-card/orders/order-groups/getPage
// POST /bff/client-adm/orders/order-groups/getPage
// Получение списка групп заказов
export const getOrdersOrderGroupPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR,
            payload: error,
        });
    }
};


// POST /bff/adm/clients/client-card/orders/order-groups/get-order-group-by-id
// POST /bff/client-adm/orders/order-groups/get-order-group-by-id
export const getOrdersOrderGroupByIdSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${getController()}/order-group-card/get-order-group-by-id`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/order-groups/createOrderGroup
// POST /bff/client-adm/orders/order-groups/createOrderGroup
// Создать группу заказов
export const addOrdersOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/createOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_ADD_ORDER_GROUP_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        yield put({
            type: BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_ADD_ORDER_GROUP_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/order-groups/deleteOrderGroup
// POST /bff/client-adm/orders/order-groups/deleteOrderGroup
// Удалить группу заказов
export const deleteOrdersOrderGroupSaga = function* ({payload} ) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/deleteOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_DELETE_ORDER_GROUP_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        yield put({
            type: BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_DELETE_ORDER_GROUP_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/order-groups/updateOrderGroup
// POST /bff/client-adm/orders/order-groups/updateOrderGroup
// Редактировать группу заказов
export const updateOrdersOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/updateOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        yield put({
            type: BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR,
            payload: error,
        });
    }
};


// POST /bff/adm/clients/client-card/orders/order-groups/archiveOrRestoreOrderGroup
// POST /bff/client-adm/orders/order-groups/archiveOrRestoreOrderGroup
// Добавить в архив / Восстановить из архива группу заказов
export const archiveOrRestoreOrdersOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/archiveOrRestoreOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        yield put({
            type: BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST, getOrdersOrderGroupPageSaga),
        takeEvery(BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST, getOrdersOrderGroupByIdSaga),
        takeEvery(BFF_ORDERS_ADD_ORDER_GROUP_REQUEST, addOrdersOrderGroupSaga),
        takeEvery(BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST, deleteOrdersOrderGroupSaga),
        takeEvery(BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST, updateOrdersOrderGroupSaga),
        takeEvery(BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST, archiveOrRestoreOrdersOrderGroupSaga),
    ]);
}
