import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmAdvancedTooltip from "../../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../../../components/ActualComponents/Text";
import ContractorNoteForClientTooltip from "../../../../../../components/ContractorNoteForClientTooltip";
import ExtLink from "../../../../../../components/ExtLink";
import NmBadge from "../../../../../../components/NmBadge";
import NmHint from "../../../../../../components/NmHint";
import {ReactComponent as BlacklistIcon} from "../../../../../../images/blacklisted-contractor.svg";
import {ReactComponent as BlockedIcon} from "../../../../../../images/lock_24.svg";
import CrowdTaskContractorInfo from "../../../components/contractor-info";
import CrowdTaskContractorWorkInfoDetail from "../work-detail-info";
import {Divider} from "semantic-ui-react";

import {formatLocalDate} from "../../../../../../utils/dateFormat";

import {COLOR} from "../../../../../../constants/color";
import {STATUS_TASK_CONTRACTOR_REJECT_TYPE_TRANSLATE} from "../../../../../../constants/crowd/task";
import {LINK_CONTRACTOR_PROFILE} from "../../../../../../constants/links";

import {getCitizenshipSelector} from "../../../../../../ducks/contractor";

const CrowdTaskContractorCard = (props) => {
    const {
        contractor,
        rejectedContractorData,
        mediaControls,
        isInvitedPage,
        isRejectedPage,
    } = props;

    const citizenshipDict = useSelector(getCitizenshipSelector);

    const [showTaskDetails, setShowTaskDetails] = useState(false);

    const {
        contractorId,
        name,
        citizenship,
        statusDateTime,
        noteForClient,
        blocked,
        blockedReason,
        blackListed,
        blacklistedReason,
    } = contractor || {};

    const getStatusDateLabel = () => {
        if (isInvitedPage) {
            return "Дата приглашения";
        }

        return "Дата отклика";
    };

    return (
        <NmListCard
            avatar={true}
            primaryHeader={
                <ExtLink
                    to={LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId)}
                    historyEnabled={true}
                >
                    {name}
                </ExtLink>
            }
            primaryHeaderTooltip={
                <div className="d-flex align-items-center">
                    <ContractorNoteForClientTooltip
                        note={noteForClient}
                    />
                    {
                        blocked &&
                        <NmAdvancedTooltip
                            className="d-flex align-items-center pointer me-2"
                            trigger={
                                <BlockedIcon
                                    width={24}
                                    height={24}
                                    color="red"
                                />
                            }
                            hover={true}
                        >
                            {blockedReason}
                        </NmAdvancedTooltip>
                    }
                    {
                        blackListed &&
                        <NmAdvancedTooltip
                            className="d-flex align-items-center pointer"
                            trigger={
                                <BlacklistIcon
                                    width={24}
                                    height={24}
                                    color="red"
                                />
                            }
                            hover={true}
                        >
                            {blacklistedReason}
                        </NmAdvancedTooltip>
                    }
                </div>
            }
            secondaryHeader={
                isRejectedPage && rejectedContractorData.rejectionReason &&
                <Text color={COLOR.NEGATIVE_100}>
                    {rejectedContractorData.rejectionReason}
                </Text>
            }
            secondaryHeaderStatus={
                isRejectedPage &&
                <NmBadge
                    mod="red"
                    text={STATUS_TASK_CONTRACTOR_REJECT_TYPE_TRANSLATE[rejectedContractorData.rejectionInitiator] || ""}
                />
            }
            labels={[
                {
                    label: getStatusDateLabel(),
                    text: statusDateTime ? formatLocalDate(statusDateTime, "dd.MM.yyyy HH:mm") : "-",
                },
                {
                    text: <CrowdTaskContractorInfo
                        contractor={contractor}
                    />,
                    textOverflowUnset: true,
                },
                {
                    label: "Гражданство",
                    text: citizenship ? citizenshipDict[citizenship] : "-",
                },
                isRejectedPage && {
                    text: <div className="mt-2">
                        <Divider />
                        <NmHint
                            className="mt-2"
                            onClick={() => setShowTaskDetails(prevState => !prevState)}
                            isShown={showTaskDetails}
                            closeButtonText="Cкрыть детали"
                            openButtonText="Показать детали"
                            reverse={true}
                            buttonColor="blue"
                        >
                            <CrowdTaskContractorWorkInfoDetail
                                className="mt-4"
                                contractorId={contractor.contractorId}
                                card={rejectedContractorData}
                                isRejectedPage={true}
                            />
                        </NmHint>
                    </div>,
                },
            ]}
            isFixedActions={true}
            mediaControls={mediaControls}
        />
    );
};

export default CrowdTaskContractorCard;