import React from "react";
import {useSelector} from "react-redux";

import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmButton from "../../../../components/NmButton";

import {bffExportDocumentsActionSelector} from "../../../../ducks/bff/export/documents/selectors";

import "./style.sass";

function ExportDocumentFilter(props) {
    const {
        filter = {},
        onChangeFilter,
        submit,
    } = props;
    const {clientId = "", periodStartDateTime = null, periodEndDateTime = null} = filter;

    const loadingFilter = useSelector(bffExportDocumentsActionSelector);

    return (
        <NmForm
            horizontal
            className="export-document-filter"
        >
            <FilterCustomer
                isClearable
                classNameError="export-document-filter__error"
                className="export-document-filter__item export-document-filter__item_pos-relative"
                value={clientId}
                name="clientId"
                onChange={onChangeFilter}
            />
            <NmDateRangePickerV2
                classNameError="export-document-filter__error"
                className="export-document-filter__item export-document-filter__item_pos-relative"
                size="lg"
                label="Период"
                isClearable
                startFieldName="periodStartDateTime"
                endFieldName="periodEndDateTime"
                value={{
                    periodStartDateTime,
                    periodEndDateTime,
                }}
                onChange={onChangeFilter}
                dateFormat="dd.MM.yy"
                returnString={false}
            />
            <div>
                <NmButton
                    onClick={submit}
                    loading={loadingFilter}
                    disabled={loadingFilter}
                >
                    Выгрузить
                </NmButton>
            </div>
        </NmForm>
    );
}

export default ExportDocumentFilter;