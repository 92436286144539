import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import useDataForm from "./hooks/useDataForm";
import useEditFormWarning from "./hooks/useEditFormWraning";
import useReadFormWarning from "./hooks/useReadFormWarning";

import dateFormat from "../../../../utils/dateFormat";

import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE, CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {CONTRACTOR_CARD_SUB_BLOCK_FIELDS, CONTRACTOR_SUB_BLOCK_NAME} from "../../../../constants/contractorInfo";

import {pendingPersonalDataSelector} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {contractorArrivalNoticeData} from "../../../../ducks/bff/contractor-сard/selectors";
import {
    getContractorArrivalDocTypeDictOptionsSelector,
    getContractorArrivalDocTypeDictSelector,
} from "../../../../ducks/dict";

import "./style.sass";

const ArrivalNoticeCard2 = (props) => {
    const {
        isEditable,
        className,
        card,
    } = props;

    const {t} = useTranslation();

    const arrivalNoticeData = useSelector(contractorArrivalNoticeData);
    const arrivalDocTypeOptions = useSelector(getContractorArrivalDocTypeDictOptionsSelector);
    const arrivalDocTypeDict = useSelector(getContractorArrivalDocTypeDictSelector);
    const pendingPersonalData = useSelector(pendingPersonalDataSelector);

    const {
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        errors,
        touched,
        isEdit,
        values,
        cancelEdit,
        toggleCard,
    } = useDataForm(card);

    const {
        warning,
        setWarning,
    } = useEditFormWarning(values, card);

    const {
        warningReadOnlyCard,
    } = useReadFormWarning(values, card);

    const localizationData = {
        title: t("arrival-notice-card.title"),
        arrivalDocType: t("arrival-notice-card.arrival-doc-type"),
        address: t("arrival-notice-card.address"),
        registrationDate: t("arrival-notice-card.registration-date"),
        residencePermitValidToDate: t("arrival-notice-card.residence-permit-valid-to-date"),
        arrivalNoticeStayEndDate: t("arrival-notice-card.arrival-notice-stay-end-date"),
        residencePermitDecisionNumber: t("arrival-notice-card.residence-permit-decision-number"),
        residencePermitDecisionDate: t("arrival-notice-card.residence-permit-decision-date"),
        residencePermitNumber: t("arrival-notice-card.residence-permit-number"),
        residencePermitIssuedBy: t("arrival-notice-card.residence-permit-issued-by"),
        residencePermitIssuedDate: t("arrival-notice-card.residence-permit-issued-date"),
        notSpecified: t("is-not-specified.content"),
    };

    const renderText = (value) => {
        return value || localizationData.notSpecified;
    };

    const classNameCard = () => {
        if (hasAllArrivalNoticeData()) {
            return `${className} arrival-notice-card-2 arrival-notice-card-2_border`;
        }

        return className;
    };

    const classNameSubBlock = (firstSubBlockName, secondSubBlockName) => {
        const {data} = pendingPersonalData;

        const {pendingPersonalDataFields = []} = data || {};

        if (isEmpty(pendingPersonalDataFields)) {
            return "";
        }

        if (hasAllArrivalNoticeData()) {
            return "";
        }

        if (getHasPendingSubBlock(firstSubBlockName, secondSubBlockName)) {
            return "arrival-notice-card-2__divider arrival-notice-card-2__divider_yellow";
        }

        return "";
    };

    const hasAllArrivalNoticeData = () => {
        const hasDocTypePending = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.ARRIVAL_NOTICE_DATA_DOC_TYPE);

        if (arrivalNoticeData[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT) {
            return hasDocTypePending &&
                getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.RESIDENCE_PERMIT_FIRST_BLOCK) &&
                getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.RESIDENCE_PERMIT_SECOND_BLOCK);
        }

        return getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.ARRIVAL_NOTICE_DATA_CONTACT_INFO) && hasDocTypePending;
    };

    const getHasPendingSubBlock = (subBlockName, secondSubBlockName) => {
        const {data} = pendingPersonalData;

        const {pendingPersonalDataFields = []} = data || {};

        const subBlockFields = secondSubBlockName ?
            [...CONTRACTOR_CARD_SUB_BLOCK_FIELDS[subBlockName], ...CONTRACTOR_CARD_SUB_BLOCK_FIELDS[secondSubBlockName]]
            : CONTRACTOR_CARD_SUB_BLOCK_FIELDS[subBlockName];

        return subBlockFields.some(field => pendingPersonalDataFields.includes(field));
    };

    const renderArrivalNotificationReadOnlyCard = () => {
        return (
            <>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.address}
                        text={renderText(arrivalNoticeData[CONTRACTOR_FIELD.ADDRESS])}
                    />
                </div>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.registrationDate}
                        text={renderText(dateFormat(arrivalNoticeData[CONTRACTOR_FIELD.REGISTRATION_DATE], "dd.MM.yyyy"))}
                    />
                    <LabelTextApp
                        className="col-16 col-md-8 mt-3 mt-md-0"
                        label={localizationData.residencePermitValidToDate}
                        text={renderText(dateFormat(arrivalNoticeData[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE], "dd.MM.yyyy"))}
                    />
                </div>
            </>
        );
    };

    const renderResidencePermitReadOnlyCard = () => {
        return (
            <>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.residencePermitNumber}
                        text={renderText(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER])}
                    />
                </div>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.residencePermitDecisionNumber}
                        text={renderText(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER])}
                    />
                    <LabelTextApp
                        className="col-16 col-md-8 mt-3 mt-md-0"
                        label={localizationData.residencePermitDecisionDate}
                        text={renderText(dateFormat(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE], "dd.MM.yyyy"))}
                    />
                </div>
                <div className={`arrival-notice-card-2__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.RESIDENCE_PERMIT_FIRST_BLOCK, CONTRACTOR_SUB_BLOCK_NAME.RESIDENCE_PERMIT_SECOND_BLOCK)}`} />
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16"
                        label="Адрес места жительства"
                        text={renderText(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS])}
                    />
                </div>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16"
                        label={localizationData.residencePermitIssuedBy}
                        text={renderText(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY])}
                    />
                </div>
                <div className="row contractor-form-card__item">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.residencePermitIssuedDate}
                        text={renderText(dateFormat(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE], "dd.MM.yyyy"))}
                    />
                    <LabelTextApp
                        className="col-16 col-md-8 mt-3 mt-md-0"
                        label={localizationData.residencePermitValidToDate}
                        text={renderText(dateFormat(arrivalNoticeData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE], "dd.MM.yyyy"))}
                    />
                </div>

            </>
        );
    };

    const renderCardReadOnly = () => {

        const secondBlockName = arrivalNoticeData[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT ?
            CONTRACTOR_SUB_BLOCK_NAME.RESIDENCE_PERMIT_FIRST_BLOCK
            :
            CONTRACTOR_SUB_BLOCK_NAME.ARRIVAL_NOTICE_DATA_CONTACT_INFO;

        return (
            <>
                <LabelTextApp
                    label={localizationData.arrivalDocType}
                    text={renderText(arrivalDocTypeDict[arrivalNoticeData[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]])}
                />
                <div
                    className={`arrival-notice-card-2__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.ARRIVAL_NOTICE_DATA_DOC_TYPE, secondBlockName)}`}
                />
                {
                    arrivalNoticeData[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT
                        ?
                        renderResidencePermitReadOnlyCard()
                        :
                        renderArrivalNotificationReadOnlyCard()
                }
            </>
        );
    };

    function getMinDateResidencePermit() {
        const decisionDate = new Date(values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]);

        if (decisionDate < new Date()) {
            return decisionDate;
        }

        if (decisionDate > new Date()) {
            return new Date();
        }
    }

    const renderResidencePermitEditCard = () => {
        return (
            <>
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="lg"
                            required
                            label={localizationData.residencePermitNumber}
                            mask="99 9999999"
                            maskChar={null}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER}
                            value={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER] :
                                undefined
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="lg"
                            label={localizationData.residencePermitDecisionNumber}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER}
                            value={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER] :
                                undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            required
                            maxDate={new Date()}
                            label={localizationData.residencePermitDecisionDate}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE, true)}
                            selected={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE] :
                                undefined
                            }
                        />
                    </div>
                </div>
                <div className="arrival-notice-card-2__divider" />
                <div className="row">
                    <div className="col-16">
                        <NmDadataInput
                            size="lg"
                            label="Адрес места жительства"
                            query={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS]}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS] :
                                undefined
                            }
                            fromBound="house"
                            toBound="house"
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS}
                            onChange={({value}) => setFieldValue(CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS, value)}
                            placeholder="Адрес места жительства"
                            address={{}}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-16">
                        <NmInputV2
                            size="lg"
                            required
                            label={localizationData.residencePermitIssuedBy}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY}
                            value={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY] :
                                undefined
                            }
                        />
                    </div>
                </div>
                <div className="row arrival-notice-card-2__dates">
                    <div className="col-16 col-md-8">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            required
                            maxDate={new Date()}
                            label={localizationData.residencePermitIssuedDate}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE}
                            selected={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE, true)}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE] :
                                undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            isClearable
                            minDate={getMinDateResidencePermit()}
                            label={localizationData.residencePermitValidToDate}
                            name={CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE, true)}
                            selected={values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]}
                            error={touched && touched[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE] :
                                undefined
                            }
                            warning={warning && warning[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]}
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderArrivalNotificationEditCard = () => {
        return (
            <>
                <div className="col-md-16">
                    <NmDadataInput
                        size="lg"
                        label={localizationData.address}
                        query={values[CONTRACTOR_FIELD.ADDRESS]}
                        onChange={({value} ) => setFieldValue(CONTRACTOR_FIELD.ADDRESS, value)}
                        onBlur={() => setFieldTouched(CONTRACTOR_FIELD.ADDRESS, true)}
                        error={touched && touched[CONTRACTOR_FIELD.ADDRESS] ?
                            errors && errors[CONTRACTOR_FIELD.ADDRESS] :
                            undefined
                        }
                    />
                </div>
                <div className="row arrival-notice-card-2__dates">
                    <div className="col-16 col-md-8">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            required
                            maxDate={new Date()}
                            label={localizationData.registrationDate}
                            name={CONTRACTOR_FIELD.REGISTRATION_DATE}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            selected={values[CONTRACTOR_FIELD.REGISTRATION_DATE]}
                            error={touched && touched[CONTRACTOR_FIELD.REGISTRATION_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.REGISTRATION_DATE] :
                                undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            required
                            minDate={new Date()}
                            label={localizationData.arrivalNoticeStayEndDate}
                            name={CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            selected={values[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]}
                            error={touched && touched[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE] :
                                undefined
                            }
                            warning={warning && warning[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]}
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm
                addMargin
                className="fluid"
            >
                <div className="row contractor-form-card__item">
                    <div className="col-md-16">
                        <NmDropdownV2
                            size="lg"
                            label={localizationData.arrivalDocType}
                            required
                            name={CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE}
                            value={values[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]}
                            options={arrivalDocTypeOptions}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, true)}
                            error={touched && touched[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] ?
                                errors && errors[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] :
                                undefined}
                        />
                    </div>
                </div>
                <div className="arrival-notice-card-2__divider" />
                {
                    values[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT
                        ? renderResidencePermitEditCard() : renderArrivalNotificationEditCard()
                }
            </NmForm>
        );
    };

    return  (
        <CardApp
            className={classNameCard()}
            title={localizationData.title}
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={() => {
                cancelEdit();
                setWarning({});
            }}
            isEdit={isEdit}
            warning={warningReadOnlyCard}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
};

export default ArrivalNoticeCard2;