import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FinancePivot from "../../../components/FinancePivot";

import {getFinancePivotTableHeaders} from "../../../components/FinancePivot/utils/getTableHeaders";

import {
    getNpdSummaryDepositHistory,
    getNpdSummaryReports,
    updateNpdSummaryStore,
} from "../../../ducks/bff/finances/npd-summary/actionCreators";
import {
    bffFinancesNpdSummaryListSelector,
    bffFinancesNpdSummaryProgressReportsSelector,
    bffFinancesNpdSummaryProgressSelector,
    bffFinancesNpdSummaryTotalCountSelector,
    bffFinancesNpdSummaryTotalPagesSelector,
    bffFinancesNpdSummaryTotalSummarySelector,
} from "../../../ducks/bff/finances/npd-summary/selectors";

const headers = getFinancePivotTableHeaders({defaultDateSortType: "desc"});

const FinancePivotSmz = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(bffFinancesNpdSummaryListSelector);
    const summary = useSelector(bffFinancesNpdSummaryTotalSummarySelector);
    const totalCount = useSelector(bffFinancesNpdSummaryTotalCountSelector);
    const totalPages = useSelector(bffFinancesNpdSummaryTotalPagesSelector);
    const progressDepositHistory = useSelector(bffFinancesNpdSummaryProgressSelector);
    const progressReports = useSelector(bffFinancesNpdSummaryProgressReportsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateNpdSummaryStore({
                reports: [],
                progress: false,
                totalSummary: {},
                totalCount: 0,
                pageData: {},
                error: null,
            }));
        };
    }, []);

    const fetchList = (data) => {
        dispatch(getNpdSummaryReports(data));
    };

    const onClickHistory = (data) => {
        dispatch(getNpdSummaryDepositHistory(data));
    };

    return (
        <FinancePivot
            header="Сводная (НПД)"
            defaultDateSortType="DESC"
            isInitLoad={false}
            list={list}
            headers={headers}
            summary={summary}
            totalCount={totalCount}
            totalPages={totalPages}
            fetchList={fetchList}
            onClickHistory={onClickHistory}
            progressDepositHistory={progressDepositHistory}
            progressReports={progressReports}
            {...props}
        />
    );
};

export default FinancePivotSmz;