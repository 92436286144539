import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import ContractorCardFinancesTabs from "../tabs";
import ProcessPaymentListModal from "./components/process-payment-list-modal";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useContractorIncomeCustomerPaymentsAmountData} from "./hooks/useAmountData";
import {useContractorFinanceBalanceFetch} from "./hooks/useFetch";
import {useContractorFinanceBalanceFilter} from "./hooks/useFilter";
import {useContractorFinanceBalancePageActions} from "./hooks/usePageActions";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {formatAmountWithNullChecking, phoneFormat} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    CONTRACTOR_FINANCE_PAYMENT_TYPE,
    OPERATION_CONTRACTOR_FINANCE_STATUS,
    OPERATION_TYPES_CONTRACTOR_FINANCE,
    OPERATION_TYPES_CONTRACTOR_FINANCE_DICT,
    PAYMENT_TYPES_CONTRACTOR_FINANCE_DICT,
} from "../../../../constants/contractorFinance";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_ORDER_CARD,
} from "../../../../constants/links";

import {
    getSummaryContractorFinanceBalance,
    updateContractorFinanceBalanceStore,
} from "../../../../ducks/bff/contractor-сard/finance/balance/actionCreators";
import {
    getContractorBalanceSelector,
    getContractorFinanceBalanceListSelector,
    getContractorFinanceBalanceProgressSelector,
    getContractorFinanceBalanceTotalCountSelector,
    getContractorFinanceBalanceTotalPagesSelector,
} from "../../../../ducks/bff/contractor-сard/finance/balance/selectors";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../constants/link-params";

const ContractorFinanceBalance = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const totalPages = useSelector(getContractorFinanceBalanceTotalPagesSelector);
    const totalCount = useSelector(getContractorFinanceBalanceTotalCountSelector);
    const list = useSelector(getContractorFinanceBalanceListSelector);
    const progress = useSelector(getContractorFinanceBalanceProgressSelector);

    const {
        pageActions,
    } = useContractorFinanceBalancePageActions({
        contractorId,
    });

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        initFilter,
        mapFilterDto,
        filters,
    } = useContractorFinanceBalanceFilter();

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter,
        mapFilterDto,
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useContractorFinanceBalanceFetch({
        filter: filterData,
        pageSize,
        pageNum,
        contractorId,
    });

    const {
        cards,
        cardInfoTax,
        cardBalance,
        confirmData,
        onCloseConfirm,
        onDownload,
        processOperationModalData,
        setProcessOperationModalData,
    } = useContractorIncomeCustomerPaymentsAmountData({
        fetchData: getSummaryContractorFinanceBalance,
        clearStore: updateContractorFinanceBalanceStore,
        balanceSelector: getContractorBalanceSelector,
        isBalancePage: true,
        contractorId,
        filter: filterData,
    });

    const renderLink = (text, link) => {
        return (
            <ExtLink to={link}>
                {text}
            </ExtLink>
        );
    };

    const getMediaControls = (param) => {
        const {
            taxUrl,
            atolUrl,
            naimixRewardReceiptUrl,
            cashReceiptUrl,
            paymentOrderUrl,
            systemTransactionReceiptUrl,
            operationType,
        } = param || {};

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            openLinkByUrl(taxUrl);
                        },
                        children: "Чек ФНС",
                    },
                    visible: Boolean(taxUrl) &&
                        ![OPERATION_TYPES_CONTRACTOR_FINANCE_DICT.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT].includes(operationType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: atolUrl,
                                isDownload: true,
                            });
                        },
                        children: "Чек АТОЛ",
                    },
                    visible: Boolean(atolUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: paymentOrderUrl,
                                isDownload: true,
                            });
                        },
                        children: "Платежное поручение по уплате патента",
                    },
                    visible: Boolean(paymentOrderUrl) &&
                        ![OPERATION_TYPES_CONTRACTOR_FINANCE_DICT.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT].includes(operationType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: naimixRewardReceiptUrl,
                                isDownload: true,
                            });
                        },
                        children: "Квитанция о переводе комиссии в пользу Наймикс",
                    },
                    visible: Boolean(naimixRewardReceiptUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: cashReceiptUrl,
                                isDownload: true,
                            });
                        },
                        children: "Квитанция о переводе средств на карту",
                    },
                    visible: Boolean(cashReceiptUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: systemTransactionReceiptUrl,
                                isDownload: true,
                            });
                        },
                        children: "Квитанция",
                    },
                    visible: Boolean(systemTransactionReceiptUrl) &&
                        ![OPERATION_TYPES_CONTRACTOR_FINANCE_DICT.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT].includes(operationType),
                },
            ],
        };
    };

    const getAmountCardLabel = ({operationType}) => {
        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_BALANCE_REPLENISHMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_CROWD_BALANCE_REPLENISHMENT,
            ].includes(operationType)
        ) {
            return "Сумма на баланс, ₽";
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_CARD,
                OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAW_FROM_CONTRACTOR_BALANCE_TO_CARD,
            ].includes(operationType)
        ) {
            return "Сумма на карту, ₽";
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD,
            ].includes(operationType)
        ) {
            return "Списано со счета Наймикс, ₽";
        }

        return "Сумма операции, ₽";
    };

    const getTransactionRequisites = (operationType, billingInformation) => {
        if (operationType === OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT) {
            return billingInformation;
        }

        if (operationType === OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAWAL_TO_CONTRACTOR_PHONE) {
            return phoneFormat(billingInformation);
        }

        const cardNumber = billingInformation.replaceAll(" ", "");

        return cardNumber.substr(cardNumber.length - 8);
    };

    const getPaymentNumberLink = ({operationType, paymentNumber, paymentType}) => {
        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT,
            ].includes(operationType)
        ) {
            return (
                <ExtLink
                    historyEnabled={true}
                    isLoadDataTarget={true}
                    filterData={{
                        paymentNumber,
                    }}
                    to={LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_CROWD_BALANCE_REPLENISHMENT,
            ].includes(operationType)
        ) {
            return (
                <ExtLink
                    historyEnabled
                    to={LINK_FINANCE_CROWD_PAYMENT_LIST.replace(":paymentNumberFilter?", paymentNumber)}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_BALANCE_REPLENISHMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_MONEY_BOX_REPLENISHMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAW_FROM_CONTRACTOR_BALANCE_TO_CARD,
            ].includes(operationType)
        ) {
            const paymentCrowdLink = LINK_FINANCE_CROWD_PAYMENT_LIST.replace(":paymentNumberFilter?", paymentNumber);

            return (
                <ExtLink
                    historyEnabled={true}
                    isLoadDataTarget={true}
                    filterData={{
                        paymentNumber,
                    }}
                    to={
                        paymentType === CONTRACTOR_FINANCE_PAYMENT_TYPE.CROWD ?
                            paymentCrowdLink :
                            LINK_FINANCE_PAYMENT_LIST
                    }
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_CARD,
            ].includes(operationType)
        ) {
            const link = paymentType === ORDER_WORK_REPORT_TYPE.SMZ
                ? LINK_FINANCE_PAYMENT_LIST
                : LINK_FINANCE_NDFL_PAYMENT_LIST;

            return (
                <ExtLink
                    historyEnabled={true}
                    isLoadDataTarget={true}
                    filterData={{
                        paymentNumber,
                    }}
                    to={link}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.REPLENISHMENT_FOR_PATENT_PAYMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.PATENT_PAYMENT,
            ].includes(operationType)
        ) {
            const paymentPatentLink = LINK_FINANCE_PATENT_PAYMENT_LIST.replace(":paymentNumberFilter?", paymentNumber);

            return (
                <ExtLink
                    historyEnabled
                    to={paymentPatentLink}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        if (
            [
                OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_PAYMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.PENALTY_PAYMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.DEBT_PAYMENT,
                OPERATION_TYPES_CONTRACTOR_FINANCE.BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_CARD,
                OPERATION_TYPES_CONTRACTOR_FINANCE.BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_PHONE,
                OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAWAL_TO_CONTRACTOR_CARD,
                OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAWAL_TO_CONTRACTOR_PHONE,
                OPERATION_TYPES_CONTRACTOR_FINANCE.TRANSFER_TAX_TO_CONTRACTOR_CARD,
                OPERATION_TYPES_CONTRACTOR_FINANCE.CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD,
            ].includes(operationType)
        ) {
            const systemTransactionLink = LINK_FINANCE_SYSTEM_TRANSACTIONS.replace(":transactionNumFilter?", paymentNumber);

            return (
                <ExtLink
                    historyEnabled
                    to={systemTransactionLink}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            );
        }

        return paymentNumber;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                createdAt,
                operationType,
                status,
                paymentType,
                param,
                fullNameInitOperation,
                bankType,
                paymentPath,
                paymentNumber,
                amount,
                direction,
            } = item;

            const {
                clientId,
                orderId,
                taskId,
                paymentId,
                paymentDescription,
                clientName,
                clientBrand,
                orderNum,
                orderName,
                recipientBankName,
                paymentTaxFor,
                paidBefore,
                contractorCommissionAmount,
                billingInformation,
                bankErrorDetails,
                taskNumber,
                taskName,
                amountToCard ,
                commissionAmount,
            } = param || {};

            const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);
            const orderLink = LINK_ORDER_CARD
                .replace(":clientId", clientId)
                .replace(":orderId", orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
            const tasksLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                .replace(":clientId", clientId)
                .replace(":taskId", taskId)
                .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={formatDate(convertUtcToLocal(createdAt), "dd.MM.yyyy HH:mm:ss")}
                        primaryHeader={OPERATION_TYPES_CONTRACTOR_FINANCE_DICT[operationType]}
                        classNameMainContent="col-16 col-xxl-7"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={OPERATION_CONTRACTOR_FINANCE_STATUS[status]?.TEXT}
                                mod={OPERATION_CONTRACTOR_FINANCE_STATUS[status]?.MOD || "gray"}
                                className="me-2"
                                description={bankErrorDetails}
                                tooltipType="dark"
                                tooltipPosition="bottom"
                                tooltipHover
                            />
                        }
                        labels={[
                            paymentType && {
                                label: "Тип платежа",
                                text: PAYMENT_TYPES_CONTRACTOR_FINANCE_DICT[paymentType],
                            },
                            clientId && {
                                label: "Заказчик",
                                text: renderLink(`${clientName} ${clientBrand ? `(${clientBrand})` : ""}`, clientLink),
                            },
                            orderId && {
                                label: "Заказ",
                                text: renderLink(`№${orderNum} ${orderName}`, orderLink),
                            },
                            taskId && {
                                label: "Задание",
                                text: renderLink(`№${taskNumber} ${taskName}`, tasksLink),
                            },
                            fullNameInitOperation && {
                                label: "Инициатор операции",
                                text: fullNameInitOperation,
                            },
                            bankType && {
                                label: "Банк-провайдер",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            recipientBankName && !taskId && {
                                label: "Банк-получатель платежа",
                                text: recipientBankName,
                            },
                            paymentTaxFor && {
                                label: "Оплата налога за",
                                text: paymentTaxFor,
                            },
                            paidBefore && {
                                label: "Оплатить до",
                                text: paidBefore,
                            },
                            billingInformation && {
                                label: "Реквизиты",
                                text: getTransactionRequisites(operationType, billingInformation),
                            },
                            paymentNumber && {
                                label: [
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_CROWD_BALANCE_REPLENISHMENT,
                                ].includes(operationType) ? "Номер оплаты по заданию" : "Номер платежа",
                                text: getPaymentNumberLink(item),
                            },
                        ]}
                        cards={[
                            !isNullOrWhitespace(amount) && {
                                title: getAmountCardLabel(item),
                                value: `${direction ? "+" : "-"}${formatAmountWithNullChecking(amount)}`,
                                className: "col-16 col-md-5",
                            },
                            (
                                [
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD,
                                ].includes(operationType) && !isNullOrWhitespace(amountToCard)
                            ) && {
                                title: "Сумма на карту, ₽",
                                value: formatAmountWithNullChecking(amountToCard),
                                className: "col-16 col-md-5",
                            },
                            (
                                [
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD,
                                ].includes(operationType) && !isNullOrWhitespace(commissionAmount )
                            ) && {
                                title: "Сумма комиссии, ₽",
                                value: formatAmountWithNullChecking(commissionAmount ),
                                className: "col-16 col-md-5",
                            },
                            (
                                [
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_BALANCE_REPLENISHMENT,
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_CARD,
                                    OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT,
                                ].includes(operationType) && !isNullOrWhitespace(contractorCommissionAmount)
                            ) && {
                                title: "Комиссия исполнителя, ₽",
                                value: formatAmountWithNullChecking(contractorCommissionAmount),
                                className: "col-16 col-md-5",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-8 align-items-end mt-md-4 mt-xxl-0"
                        mediaControls={getMediaControls(param)}
                        actionsClassName="col-1 justify-content-end"
                    />
                ),
            };
        });
    };

    const renderConfirmWindow = () => {
        const {
            content,
            warning,
            onConfirm,
        } = confirmData;
        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                warning={warning}
                confirmButton="Подтвердить"
                cancelButton="Отмена"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    const renderProcessPaymentsModal = () => {
        return (
            !isEmpty(processOperationModalData) &&
            <ProcessPaymentListModal
                {...processOperationModalData}
                onClose={() => setProcessOperationModalData({})}
            />
        );
    };

    return (
        <NmPage
            noPadding
            overflowUnset
            header={<ContractorCardFinancesTabs{...props} />}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            isLoaded={progress}
            typeFilter="vertical"
            mediaControls={pageActions}
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
        >
            {renderProcessPaymentsModal()}
            {renderConfirmWindow()}
            <div className="flex flex-wrap col-16 mb-4 mb-xl-5">
                <div className="flex flex-column mb-2 mb-xxl-0">
                    <NmPageInfoCardsAccordion
                        desktopViewFrom="xxl"
                        isEachCardAccordion
                        className="col-16 mb-4 mb-xl-5"
                        cards={cards}
                    />
                    <NmPageInfoCardsAccordion
                        desktopViewFrom="xxl"
                        isEachCardAccordion
                        className="col-16"
                        cards={[cardInfoTax]}
                    />
                </div>
                <NmPageInfoCardsAccordion
                    desktopViewFrom="xxl"
                    isEachCardAccordion
                    className="col-16 col-xxl-8 ms-xxl-5"
                    cards={[cardBalance]}
                />
            </div>
            {totalCount < 1 ?
                <NmEmptyPageV2
                    title="Информация отсутствует"
                    isSearch={isSearch}
                    fetchProgress={progress}
                /> : <CheckboxList
                    rows={getRows()}
                />
            }
        </NmPage>
    );
};

export default ContractorFinanceBalance;