export const ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR = "ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR = "ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR = "ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_ERROR = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR = "ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST";
export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS";
export const ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR = "ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR";

export const ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE = "ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE";