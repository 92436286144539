import React, {FC} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmDivider {
    className?: string,
    color?: string
    vertical?: "md"
}

const NmDivider: FC<INmDivider> = (props) => {
    const {
        className = "",
        color,
        vertical,
    } = props;

    const [block] = bem("nm-divider", className);

    return (
        <div
            style={{backgroundColor: color}}
            className={block({vertical})}
        />
    );
};

export default NmDivider;