import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const financesOrdersSelector = state => state.bff.financesOrders;
export const financesOrdersTotalPagesSelector = createSelector(financesOrdersSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const financesOrdersTotalCountSelector = createSelector(financesOrdersSelector, ({totalCount}) => totalCount);
export const financesOrdersListSelector = createSelector(financesOrdersSelector, ({list}) => list);
export const financesOrdersProgressSelector = createSelector(financesOrdersSelector, ({progress}) => progress);
export const financesOrdersHistoryTotalPagesSelector = createSelector(financesOrdersSelector, ({historyTotalCount, historyPageData: {pageSize = 0}}) => getTotalPages(historyTotalCount, pageSize));
export const financesOrdersHistoryTotalCountSelector = createSelector(financesOrdersSelector, ({historyTotalCount}) => historyTotalCount);
export const financesOrdersHistoryListSelector = createSelector(financesOrdersSelector, ({historyList}) => historyList);
export const financesOrdersHistoryProgressSelector = createSelector(financesOrdersSelector, ({historyProgress}) => historyProgress);