export const GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST = "GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST";
export const GET_PAGE_DIRECTORIES_STOP_WORDS_SUCCESS = "GET_PAGE_DIRECTORIES_STOP_WORDS_SUCCESS";
export const GET_PAGE_DIRECTORIES_STOP_WORDS_ERROR = "GET_PAGE_DIRECTORIES_STOP_WORDS_ERROR";

export const ADD_DIRECTORIES_STOP_WORDS_REQUEST = "ADD_DIRECTORIES_STOP_WORDS_REQUEST";
export const ADD_DIRECTORIES_STOP_WORDS_SUCCESS = "ADD_DIRECTORIES_STOP_WORDS_SUCCESS";
export const ADD_DIRECTORIES_STOP_WORDS_ERROR = "ADD_DIRECTORIES_STOP_WORDS_ERROR";

export const UPDATE_DIRECTORIES_STOP_WORDS_REQUEST = "UPDATE_DIRECTORIES_STOP_WORDS_REQUEST";
export const UPDATE_DIRECTORIES_STOP_WORDS_SUCCESS = "UPDATE_DIRECTORIES_STOP_WORDS_SUCCESS";
export const UPDATE_DIRECTORIES_STOP_WORDS_ERROR = "UPDATE_DIRECTORIES_STOP_WORDS_ERROR";

export const DELETE_DIRECTORIES_STOP_WORDS_REQUEST = "DELETE_DIRECTORIES_STOP_WORDS_REQUEST";
export const DELETE_DIRECTORIES_STOP_WORDS_SUCCESS = "DELETE_DIRECTORIES_STOP_WORDS_SUCCESS";
export const DELETE_DIRECTORIES_STOP_WORDS_ERROR = "DELETE_DIRECTORIES_STOP_WORDS_ERROR";

export const CLEAR_FIELDS_DIRECTORIES_STOP_WORDS = "CLEAR_FIELDS_DIRECTORIES_STOP_WORDS";