import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useKedoDocumentCodes} from "./hooks/useKedoDocumentCodes";

export const KedoDocumentCodes = (props) => {
    const {
        required,
        search,
        label,
        placeholder,
        name,
        options,
    } = useKedoDocumentCodes();

    return (
        <NmDropdownV2
            required={required}
            search={search}
            label={label}
            placeholder={placeholder}
            name={name}
            options={options}
            {...props}
        />
    );
};