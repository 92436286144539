/**
 * Валидация для поля "Период выполнения работ",
 * по настройке компании "Период выполнения работ только в рамках одного календарного месяца"
 * @param params
 * @returns {boolean}
 */
export const isWorkPeriodWithinOneMonthOnly = (params) => {
    const {
        workPeriodWithinOneMonthOnly,
    } = params;

    if (!workPeriodWithinOneMonthOnly) {
        return true;
    }

    const {
        workStartDate,
        workEndDate,
    } = params;

    if (
        !workStartDate
        || !workEndDate
    ) {
        return true;
    }

    const startDateMonthNumber = workStartDate?.getMonth();
    const endDateMonthNumber = workEndDate?.getMonth();

    return startDateMonthNumber === endDateMonthNumber;
};