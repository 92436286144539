import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {MarkerList} from "../../../../../components/ActualComponents/MarkerList";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ExtLink from "../../../../../components/ExtLink";
import NmTitle from "../../../../../components/NmTitle";

import {useModal} from "../../../../../hooks/useModal";

import {getClassNames} from "../../../../../utils/classNames";
import dateFormat from "../../../../../utils/dateFormat";
import {isLastElementInArray} from "../../../../../utils/objectHelper";
import {formatAmount} from "../../../../../utils/stringFormat";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../../../../../components/ProjectsObjectsCheckboxList/constants";
import {COLOR} from "../../../../../constants/color";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST, LINK_ORDER_CARD,
} from "../../../../../constants/links";
import {MESSAGE_NOT_CHOSEN} from "../../../../../constants/messages";
import {SMART_LINK_FORM_TYPE} from "../../../smart-link-edit/constants";

import {bffCommonDictsClientCategoriesDictSelector} from "../../../../../ducks/bff/common/dicts/selectors";
import {
    getSmartLinkCard,
    getSmartLinkDetailsTree,
    updateSmartLinkStore,
} from "../../../../../ducks/smart-link/actionCreators";
import {
    smartLinkCardSelector,
    smartLinkDetailsTreeProgressSelector,
    smartLinkDetailsTreeSelector,
    smartLinkProgressCardSelector,
} from "../../../../../ducks/smart-link/selectors";
import {specialitiesAllV2DictSelector} from "../../../../../ducks/speciality";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../../constants/link-params";

export const SmartLinkDetails = (props) => {
    const {
        onClose,
        smartLinkId,
    } = props;

    const dispatch = useDispatch();

    const card = useSelector(smartLinkCardSelector);
    const progressCard = useSelector(smartLinkProgressCardSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const detailsTree = useSelector(smartLinkDetailsTreeSelector);
    const progressClientSettings = useSelector(smartLinkDetailsTreeProgressSelector);
    const clientCategoriesDict = useSelector(bffCommonDictsClientCategoriesDictSelector);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    useEffect(() => {
        dispatch(getSmartLinkCard({
            smartLinkId,
        }));

        return () => {
            dispatch(updateSmartLinkStore({
                card: {},
                detailsTree: null,
            }));
        };
    }, []);

    const getSingleAndMultipleParam = (params) => {
        const {
            label,
            values,
            getDictValue,
        } = params;

        const param = {label};

        if (isEmpty(values)) {
            param.text = MESSAGE_NOT_CHOSEN;

            return param;
        }

        if (values.length === 1) {
            param.text = getDictValue(values[0]);

            return param;
        }

        param.className = "flex-column";
        param.wrapped = true;
        param.text = (
            <MarkerList
                classNameOffset="mb-1"
                list={
                    values.map(id => {
                        return {
                            text: getDictValue(id),
                        };
                    })
                }
            />
        );

        return param;
    };

    const getContent = () => {
        if (isEmpty(card)) {
            return null;
        }

        const isOrders = card.type === SMART_LINK_FORM_TYPE.ORDER;

        if (card.numbers) {
            return (
                <>
                    <Text
                        color={COLOR.SECONDARY_100}
                        level="4"
                        className="mb-4"
                    >
                        {`Номера ${isOrders ? "заказов" : "заданий"}:`}
                    </Text>
                    <MarkerList
                        classNameOffset="mb-2"
                        list={
                            card.numbers.map(item => {
                                const {
                                    id,
                                    clientId,
                                    number,
                                } = item;

                                return {
                                    text: (
                                        <ExtLink
                                            key={item.orderId}
                                            title={`Перейти в карточку ${isOrders ? "заказа" : "задания"}`}
                                            to={
                                                isOrders
                                                    ? LINK_ORDER_CARD.replace(":clientId", clientId)
                                                        .replace(":orderId", id)
                                                        .replace(":page", SELF_CONTRACTOR)
                                                        .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK)
                                                    : LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                                                        .replace(":clientId", clientId)
                                                        .replace(":taskId", id)
                                                        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK)
                                            }
                                        >
                                            {number}
                                        </ExtLink>
                                    ),
                                };
                            })
                        }
                    />
                </>
            );
        }

        return getInfos();
    };

    const getPeriod = () => {
        const fromDate = card.fromDate ? dateFormat(card.fromDate, "dd.MM.yyyy") : "";
        const toDate = card.toDate ? dateFormat(card.toDate, "dd.MM.yyyy") : "";

        if (!fromDate && !toDate) {
            return MESSAGE_NOT_CHOSEN;
        }

        if (!fromDate) {
            return `до ${toDate}`;
        }

        if (!toDate) {
            return `от ${fromDate}`;
        }

        return `${fromDate} - ${toDate}`;
    };

    const onClickShowMore = ({clientId, clientName}) => {
        if (progressClientSettings) {
            return;
        }

        dispatch(getSmartLinkDetailsTree({
            clientId,
            smartLinkId,
            onSuccess: () => {
                onOpenModal({
                    isClientDetailsOpen: true,
                    clientName,
                    clientId,
                });
            },
        }));
    };

    const getClients = () => {
        if (isEmpty(card.clients)) {
            return MESSAGE_NOT_CHOSEN;
        }

        return (
            <MarkerList
                classNameOffset="mb-2"
                list={
                    card.clients.map(item => {
                        return {
                            link: LINK_CLIENT_INFO.replace(":clientId", item.clientId),
                            text: item.clientName,
                            children: (
                                <NmLabelText
                                    label="Проекты и объекты"
                                    noWrap={false}
                                    text={
                                        <>
                                            {
                                                [
                                                    CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                                                    CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY,
                                                ].includes(item.type) &&
                                                "Все"
                                            }
                                            {
                                                item.type === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY &&
                                                <div className="d-flex align-items-center">
                                                    <Text
                                                        level="2"
                                                        color={COLOR.SECONDARY_90}
                                                    >
                                                        Множественный выбор
                                                    </Text>
                                                    <Text
                                                        level="2"
                                                        underline
                                                        title={progressClientSettings ? "Загрузка..." : undefined}
                                                        className="ms-1"
                                                        color={COLOR.PASSIVE_100}
                                                        onClick={() => {
                                                            onClickShowMore(item);
                                                        }}
                                                    >
                                                        Подробнее
                                                    </Text>
                                                </div>
                                            }
                                        </>
                                    }
                                />
                            ),
                        };
                    })
                }
            />
        );
    };

    const getInfos = () => {
        const fromAmount = card.fromAmount ? formatAmount(card.fromAmount) : "";
        const toAmount = card.toAmount ? formatAmount(card.toAmount) : "";

        return [
            {
                label: "Период выполнения работ",
                columnOnMobile: true,
                text: getPeriod(),
            },
            {
                label: "Стоимость заказа, ₽",
                columnOnMobile: true,
                text: !fromAmount && !toAmount ? MESSAGE_NOT_CHOSEN : `от ${fromAmount || "-"} до ${toAmount || "-"} ₽`,
            },
            getSingleAndMultipleParam({
                label: "Вид деятельности",
                values: card.specialityIds,
                getDictValue: (id) => specialityDict.get(id),
            }),
            {
                label: "Регион",
                text: card.region?.fiasName || MESSAGE_NOT_CHOSEN,
            },
            {
                label: "Город",
                text: card.city?.fiasName || MESSAGE_NOT_CHOSEN,
            },
            getSingleAndMultipleParam({
                label: "Категория заказчика",
                values: card.clientCategoryIds,
                getDictValue: (id) => clientCategoriesDict[id],
            }),
            {
                label: "Заказчик",
                className: "flex-column",
                text: getClients(),
            },
        ].map((item, index, array) => {
            const isLast = isLastElementInArray(array, index);

            return (
                <NmLabelText
                    key={index}
                    noWrap={false}
                    type="page"
                    label={item.label}
                    text={item.text}
                    wrapped={item.wrapped}
                    className={getClassNames([
                        !isLast && "mb-1 mb-md-2",
                        item.className,
                    ])}
                />
            );
        });
    };

    const getClientDetailsContent = () => {
        if (isEmpty(detailsTree)) {
            return null;
        }

        const {
            projects = [],
        } = detailsTree;

        return projects.map((project, index) => {
            const {
                objects,
                projectName,
                projectId,
            } = project;

            const isLast = isLastElementInArray(projects, index);

            return (
                <div
                    key={projectId}
                    className={getClassNames([
                        !isLast && "mb-1 mb-md-2",
                    ])}
                >
                    <NmLabelText
                        type="page"
                        label="Проект"
                        noWrap={false}
                        text={
                            <ExtLink
                                title="Перейти в карточку проекта"
                                to={
                                    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
                                        .replace(":clientId", modalData.clientId)
                                        .replace(":projectId", projectId)
                                }
                            >
                                {projectName}
                            </ExtLink>
                        }
                        className={getClassNames([
                            !isEmpty(objects) && "mb-1",
                        ])}
                    />
                    {
                        !isEmpty(objects) ?
                            <NmLabelText
                                label="Объекты"
                                noWrap={false}
                                className="flex-column"
                                text={
                                    <MarkerList
                                        classNameOffset="mb-1"
                                        list={
                                            objects.map(object => {
                                                const {
                                                    objectId,
                                                    objectName,
                                                } = object;

                                                return {
                                                    text: (
                                                        <ExtLink
                                                            key={objectId}
                                                            title="Перейти в карточку объекта"
                                                            to={
                                                                LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
                                                                    .replace(":clientId", modalData.clientId)
                                                                    .replace(":projectId", projectId)
                                                                    .replace(":objectId", objectId)
                                                            }
                                                        >
                                                            {objectName}
                                                        </ExtLink>
                                                    ),
                                                };
                                            })
                                        }
                                    />
                                }
                            /> :
                            <NmLabelText
                                label="Объекты"
                                text="Все"
                            />
                    }
                </div>
            );
        });
    };

    if (modalData?.isClientDetailsOpen) {
        return (
            <NmModal
                size="md"
                isShowBackButton={true}
                isAutoSize={true}
                isBackIconAlwaysVisible={true}
                onClose={onClose}
                goBack={() => {
                    onCloseModal();
                }}
                header={
                    <NmTitle size="lg">
                        {modalData.clientName}
                    </NmTitle>
                }
                loading={progressCard}
                classNameContent="flex-column"
            >
                {getClientDetailsContent()}
            </NmModal>
        );
    }

    return (
        <NmModal
            isAutoSize={true}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Детали ссылки
                </NmTitle>
            }
            loading={progressCard}
            classNameContent="flex-column justify-content-start"
        >
            {getContent()}
        </NmModal>
    );
};