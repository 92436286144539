import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS} from "../../../../../constants/recruitment";
import {archiveRecruitmentAccessControlJobBoardAccount} from "../../../../../ducks/bff/recruitment/access-control/job-boards/actionsCreators";
import {bffRecruitmentAccessControlJobBoardsListLoadingSelector} from "../../../../../ducks/bff/recruitment/access-control/job-boards/selectors";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {useRecruitmentJobBoardAuth} from "./useJobBoardAuth";

import {useJobBoardConnect} from "../../../../../components/JobBoardsConnect/hooks/useConnect";
import {useModal} from "../../../../../hooks/useModal";

import {getArchiveButton} from "../../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {getUserRole} from "../../../../../utils/access";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {
    ADMIN,
    CLIENT_ADMIN,
    RECRUITER,
} from "../../../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";

export const useRecruitmentAccessControlJobBoardsAction = (props) => {
    const {
        fetchList,
        archived,
        setArchived,
    } = props;

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        connectJobBoard,
    } = useJobBoardConnect();

    useRecruitmentJobBoardAuth({
        fetchList,
    });

    const progress = useSelector(bffRecruitmentAccessControlJobBoardsListLoadingSelector);
    const currentUser = useSelector(clientCurrentMemberSelector);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const role = getUserRole();

    const archiveJobBoard = (params) => {
        const {
            jobBoardAccountId,
        } = params;

        dispatch(archiveRecruitmentAccessControlJobBoardAccount({
            jobBoardAccountId,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    const headerMediaControls = useMemo(() => {
        const archiveButton = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                desktop: 2,
                tablet: 1,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => {
                            onOpenModal({
                                isOpenAdd: true,
                            });
                        },
                        icon: <AddIcon />,
                        children: "Добавить аккаунт",
                        color: "green",
                    },
                    visible: [ADMIN, CLIENT_ADMIN, RECRUITER].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archiveButton.props,
                        onClick: () => {
                            setArchived(!archived);
                        },
                        disabled: progress,
                    },
                },
            ],
        };
    }, [
        archived,
    ]);

    const getListArchiveButton = (item) => {
        const {
            archived,
            canArchive,
            canUnarchive,
        } = item;

        const archiveText = archived ? "Из архива" : "В архив";
        const visible = archived ? canUnarchive : canArchive;

        return {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        isOpenConfirm: true,
                        content: `Вы действительно хотите перенести аккаунт ${archiveText.toLowerCase()}?`,
                        confirmButton: "Перенести",
                        cancelButton: "Отменить",
                        onConfirm: () => {
                            archiveJobBoard(item);
                        },
                    });
                },
                children: archiveText,
            },
            visible,
        };
    };

    const getListMediaControls = (item) => {
        const {
            jobBoardType,
            clientId,
            archived,
            clientUserId,
            status,
        } = item;

        const archiveButton = getListArchiveButton(item);

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            connectJobBoard({
                                jobBoardType,
                                clientId,
                            });
                        },
                        color: "grey",
                        children: "Обновить",
                    },
                    visible: status === RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS.NOT_ACTIVE
                        && !archived
                        && (
                            [
                                ADMIN,
                            ].includes(role) ||
                        [
                            RECRUITER,
                        ].includes(role)
                        && clientUserId === currentUser.clientUserId
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenSubscriptions: true,
                                jobBoardAccountId: item.jobBoardAccountId,
                            });
                        },
                        children: "Подписки",
                    },
                },
                archiveButton,
            ],
        };
    };

    return {
        archived,
        modalData,
        headerMediaControls,
        getListMediaControls,
        onCloseModal,
    };
};