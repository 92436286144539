import {
    SETTINGS_CLIENT_GROUP_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_UPDATE_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_STORE,
} from "./actions";

export function getPageSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_ADD_REQUEST,
        payload,
    };
}

export function updateSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_UPDATE_REQUEST,
        payload,
    };
}

export function deleteSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_DELETE_REQUEST,
        payload,
    };
}

export function getRelatedUsersSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST,
        payload,
    };
}

export function setEnabledRelatedUsersSettingsClientGroups(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST,
        payload,
    };
}

export const updateStoreSettingsClientGroups = (payload) => {
    return {
        type: UPDATE_SETTINGS_CLIENT_GROUPS_STORE,
        payload,
    };
};