import {useState} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

export const typesLidDict= {
    SMZ: "SMZ",
    CIVIL: "GPH",
};

export const typesLidOptions = [
    {
        key: "SMZ",
        value: "SMZ",
        text: "SMZ",
    },
    {
        key: "CIVIL",
        value: "CIVIL",
        text: "GPH",
    },
];

export function useCollectionBankDetailsFilter({setPagination, pageSize}) {
    const [filter, setFilter] = useState({type: "ALL"});
    const [isSearch, setIsSearch] = useState(false);

    const filtersBase = [
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "addedDateTimeFromFilter",
                    endFieldName: "addedDateTimeToFilter",
                    label: "Дата создания",
                    isClearable: true,
                    value: {
                        fromDate: null,
                        toDate: null,
                    },
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "ProjectParam",
                    name: "projectParamFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    size: "lg",
                    label: "Тип лида",
                    name: "leadTypeFilter",
                    options: [
                        {
                            key: "ALL",
                            text: "Все",
                            value: "ALL",
                        },
                        ...typesLidOptions,
                    ],
                    defaultValue: "ALL",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "Инициатор",
                    name: "addedByClientUserFilter",
                },
            ],
        },
    ];

    const submitFilter = (data) => {
        setIsSearch(true);
        setFilter({...data});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setIsSearch(false);
        setFilter({});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    return {
        filter,
        submitFilter,
        clearFilter,
        filtersBase,
        isSearch,
    };
}