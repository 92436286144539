import {
    BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressCard: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload.result,
                progressCard: false,
            };
        case BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.isLoadMore ? state.list.concat(payload.results) : payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_ERROR:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR: {
            return {
                ...state,
                progressAction: false,
                progressCard: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};