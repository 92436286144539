import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/specialty-offer";

const getDirectoriesSpecialtyOfferPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR,
            payload: error,
        });
    }
};

const editDirectoriesSpecialtyOfferSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/edit`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        yield put({
            type: EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR,
            payload: error,
        });
    }
};

const decisionDirectoriesSpecialtyOfferSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/decision`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(payload.accept ? "Вид деятельности добавлен в справочник" : "Вид деятельности отклонён");

        yield put({
            type: DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST, getDirectoriesSpecialtyOfferPageSaga),
        takeEvery(EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST, editDirectoriesSpecialtyOfferSaga),
        takeEvery(DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST, decisionDirectoriesSpecialtyOfferSaga),
    ]);
}