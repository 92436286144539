import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {getClientNameWithoutBrand} from "../../utils/stringHelper";

import {getPagePromotionChannelClientsWithPartners} from "../../ducks/bff/promotion-channels/actionCreators";
import {bffPromotionChannelsClientWithPartnersOptionsSelector} from "../../ducks/bff/promotion-channels/selectors";

import "./style.sass";

const PromocodeClientsInput = props => {
    const {
        onChange,
        value,
        name,
        size,
        label,
        placeholder,
        error,
        searchQuery,
        disabled = false,
        onBlur,
        onChangeInput,
        nameSearchQuery,
        archivedFilter = false,
        onlyPartners = false,
        isNeedClearShowedText,
    } = props;
    const dispatch = useDispatch();
    const options = useSelector(bffPromotionChannelsClientWithPartnersOptionsSelector);

    useEffect(() => {
        fetchClientListForFilter();
    }, [onlyPartners]);

    const fetchClientListForFilter = (valueSearchQuery = "") => {
        dispatch(getPagePromotionChannelClientsWithPartners({
            pageNum: 1,
            pageSize: 25,
            archivedFilter,
            nameSubstringFilter: getClientNameWithoutBrand(valueSearchQuery),
            onlyPartners,
        }));
    };

    return (
        <NmDropdownV2
            size={size}
            label={label}
            placeholder={placeholder}
            selectOnBlur={false}
            onBlur={onBlur}
            searchQuery={searchQuery}
            noResultsMessage="Нет результата поиска"
            onSearchChange={fetchClientListForFilter}
            onChange={onChange}
            search
            name={name}
            value={value}
            options={options}
            error={error}
            disabled={disabled}
            minSearchLength={2}
            nameSearchQuery={nameSearchQuery}
            onChangeInput={onChangeInput}
            isClearSearchOnBlur={isNeedClearShowedText}
            isNeedClearShowedText={isNeedClearShowedText}
            inputLogic
            required
        />
    );
};

export default PromocodeClientsInput;