import {useState} from "react";
import {useDispatch} from "react-redux";

import {getEndDate} from "../../../utils/dateFormat";
import {handleDateFieldFilter} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";

import {addExportDocuments} from "../../../ducks/bff/export/documents/actionCreators";

function useAddExportDocument(fetchList) {
    const dispatch = useDispatch();
    const [exportDocumentForm, setExportDocumentForm] = useState({});

    const clearForm = () => {
        setExportDocumentForm({});
    };

    const onChangeForm = (e, {value, name}) => {
        setExportDocumentForm({
            ...exportDocumentForm,
            [name]: value,
        });
    };

    const addExportDocument = () => {
        const {
            clientId,
            periodStartDateTime,
            periodEndDateTime,
        } = exportDocumentForm;

        if (!(clientId && periodStartDateTime && periodEndDateTime)) {
            toastError("Выберите заказчика и заполните период, за который необходимо сформировать архив документов");
            return;
        }

        dispatch(addExportDocuments({
            clientId,
            periodStartDateTime: handleDateFieldFilter(periodStartDateTime),
            periodEndDateTime: handleDateFieldFilter(getEndDate(periodEndDateTime)),
            getResult: ({errorMessage}) => {
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }

                clearForm();
                fetchList();
            },
        }));
    };

    return {
        exportDocumentForm,
        onChangeForm,
        addExportDocument,
    };
}

export default useAddExportDocument;