import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {downloadBlob, downloadByUrl} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {EDM_EXPORT_STATUS} from "../constants/edmExport";

export const STATEMENT_FILE_TYPE = {
    SOURCE_FILE: "source",
    ATTACHMENT_FILE: "attachment",
    SIGNED_FILE: "signed",
};

const controller = "/documents/edo";
//*  TYPES  *//

export const GET_STATEMENTS_PAGE_REQUEST = "GET_STATEMENTS_PAGE_REQUEST";
export const GET_STATEMENTS_PAGE_SUCCESS = "GET_STATEMENTS_PAGE_SUCCESS";

export const GET_STATEMENTS_INFO_COUNT_REQUEST = "GET_STATEMENTS_INFO_COUNT_REQUEST";
export const GET_STATEMENTS_INFO_COUNT_SUCCESS = "GET_STATEMENTS_INFO_COUNT_SUCCESS";
export const GET_STATEMENTS_INFO_COUNT_ERROR = "GET_STATEMENTS_INFO_COUNT_ERROR";

export const GET_STATEMENTS_TYPES_PAGE_REQUEST = "GET_STATEMENTS_TYPES_PAGE_REQUEST";
export const GET_STATEMENTS_TYPES_PAGE_SUCCESS = "GET_STATEMENTS_TYPES_PAGE_SUCCESS";

export const GET_STATEMENTS_ARCHIVE_REQUEST = "GET_STATEMENTS_ARCHIVE_REQUEST";

export const GET_STATEMENTS_SOURCE_FILE_REQUEST = "GET_STATEMENTS_SOURCE_FILE_REQUEST";

export const UPLOAD_EDO_STATEMENT_REQUEST = "UPLOAD_EDO_STATEMENT_REQUEST";

export const REPLACE_EDO_STATEMENT_REQUEST = "REPLACE_EDO_STATEMENT_REQUEST";

export const SEND_STATEMENT_VERIFICATION_CODE_REQUEST = "SEND_STATEMENT_VERIFICATION_CODE_REQUEST";

export const SIGN_STATEMENT_REQUEST = "SIGN_STATEMENT_REQUEST";

export const REJECT_STATEMENT_REQUEST = "REJECT_STATEMENT_REQUEST";

export const REVIEW_STATEMENT_REQUEST = "REVIEW_STATEMENT_REQUEST";

export const GET_EDO_COUNTS_BY_STAFF_ID_REQUEST = "GET_EDO_COUNTS_BY_STAFF_ID_REQUEST";
export const GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS = "GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS";

export const TASK_EXPORT_EDO_DOCUMENT_REQUEST = "TASK_EXPORT_EDO_DOCUMENT_REQUEST";
export const TASK_EXPORT_EDO_DOCUMENT_SUCCESS = "TASK_EXPORT_EDO_DOCUMENT_SUCCESS";

export const CHECK_STATEMENT_FILE_REQUEST = "CHECK_STATEMENT_FILE_REQUEST";

export const GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST = "GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST";

export const GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST = "GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST";

export const GET_EDO_EXPORT_LIST_REQUEST = "GET_EDO_EXPORT_LIST_REQUEST";
export const GET_EDO_EXPORT_LIST_SUCCESS = "GET_EDO_EXPORT_LIST_SUCCESS";
export const GET_EDO_EXPORT_LIST_ERROR = "GET_EDO_EXPORT_LIST_ERROR";

export const GET_EDO_EXPORT_STATUSES_REQUEST = "GET_EDO_EXPORT_STATUSES_REQUEST";
export const GET_EDO_EXPORT_STATUSES_SUCCESS = "GET_EDO_EXPORT_STATUSES_SUCCESS";


export const DOWNLOAD_EDO_EXPORT_FILES_REQUEST = "DOWNLOAD_EDO_EXPORT_FILES_REQUEST";
export const DOWNLOAD_EDO_EXPORT_FILES_SUCCESS = "DOWNLOAD_EDO_EXPORT_FILES_SUCCESS";
export const DOWNLOAD_EDO_EXPORT_FILES_ERROR = "DOWNLOAD_EDO_EXPORT_FILES_ERROR";

//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    totalCount: 0,
    list: [],
    types: [],
    edoCounts: {
        documentsCount: 0,
        statementsCount: 0,
    },
    statementsInfoCount: 0,
    exportList: [],
    exportListPageData: {},
    exportListProgress: false,
    exportStatuses: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case DOWNLOAD_EDO_EXPORT_FILES_REQUEST:
            return {
                ...state,
                exportListProgress: true,
            };
        case GET_EDO_EXPORT_LIST_REQUEST:
            return {
                ...state,
                exportListPageData: payload.data,
                exportListProgress: true,
            };
        case GET_STATEMENTS_PAGE_REQUEST:
            const {
                pageSize,
                pageNum,
            } = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_EDO_EXPORT_STATUSES_SUCCESS:
            return {
                ...state,
                exportStatuses: payload,
            };
        case DOWNLOAD_EDO_EXPORT_FILES_SUCCESS:
            return {
                ...state,
                exportListProgress: false,
            };
        case GET_EDO_EXPORT_LIST_SUCCESS:
            return {
                ...state,
                exportListTotalCount: payload.totalCount,
                exportList: payload.models,
                exportListProgress: false,
            };
        case GET_STATEMENTS_PAGE_SUCCESS:
            const {totalCount, models} = payload;
            return {
                ...state,
                totalCount,
                list: models,
            };
        case GET_STATEMENTS_TYPES_PAGE_SUCCESS:
            const {results} = payload;

            return {
                ...state,
                types: results,
            };
        case GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS:
            return {
                ...state,
                edoCounts: {...payload},
            };
        case GET_STATEMENTS_INFO_COUNT_SUCCESS:
            return {
                ...state,
                statementsInfoCount: payload,
            };
        case DOWNLOAD_EDO_EXPORT_FILES_ERROR:
        case GET_EDO_EXPORT_LIST_ERROR:
            return {
                ...state,
                exportListProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getEdoExportStatuses(payload) {
    return {
        type: GET_EDO_EXPORT_STATUSES_REQUEST,
        payload,
    };
}

export function downloadEdoExportFiles(payload) {
    return {
        type: DOWNLOAD_EDO_EXPORT_FILES_REQUEST,
        payload,
    };
}


export function getEdoExportList(payload) {
    return {
        type: GET_EDO_EXPORT_LIST_REQUEST,
        payload,
    };
}

export function taskEdoExportDocument(payload) {
    return {
        type: TASK_EXPORT_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function getStatementsPage(payload) {
    return {
        type: GET_STATEMENTS_PAGE_REQUEST,
        payload,
    };
}

export function getStatementTypesPage(payload) {
    return {
        type: GET_STATEMENTS_TYPES_PAGE_REQUEST,
        payload,
    };
}

export function archiveStatement(payload) {
    return {
        type: GET_STATEMENTS_ARCHIVE_REQUEST,
        payload,
    };
}

export function getStatementsSourceFile(payload) {
    return {
        type: GET_STATEMENTS_SOURCE_FILE_REQUEST,
        payload,
    };
}

export function getStatementsTemplatePreFilledFile(payload) {
    return {
        type: GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST,
        payload,
    };
}

export function getStatementTemplateEmptyFile(payload) {
    return {
        type: GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST,
        payload,
    };
}

export function uploadEdoStatement(payload) {
    return {
        type: UPLOAD_EDO_STATEMENT_REQUEST,
        payload,
    };
}

export function replaceEdoStatement(payload) {
    return {
        type: REPLACE_EDO_STATEMENT_REQUEST,
        payload,
    };
}

export function sendStatementCodeVerification(payload) {
    return {
        type: SEND_STATEMENT_VERIFICATION_CODE_REQUEST,
        payload,
    };
}

export function signStatement(payload) {
    return {
        type: SIGN_STATEMENT_REQUEST,
        payload,
    };
}

export function rejectStatement(payload) {
    return {
        type: REJECT_STATEMENT_REQUEST,
        payload,
    };
}

export function reviewStatement(payload) {
    return {
        type: REVIEW_STATEMENT_REQUEST,
        payload,
    };
}

export function getCountsEdoByStaffId(payload) {
    return {
        type: GET_EDO_COUNTS_BY_STAFF_ID_REQUEST,
        payload,
    };
}

export function checkStatementFile(payload) {
    return {
        type: CHECK_STATEMENT_FILE_REQUEST,
        payload,
    };
}

export function getStatementsInfoCount(payload) {
    return {
        type: GET_STATEMENTS_INFO_COUNT_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const statementsSelector = state => state.statements;
export const edoCountsByStaffSelector = createSelector(statementsSelector, ({edoCounts}) => edoCounts);
export const statementsListSelector = createSelector(statementsSelector, ({list}) => list);
export const statementsTotalPagesSelector = createSelector(statementsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const statementsTotalCountSelector = createSelector(statementsSelector, ({totalCount}) => totalCount);
export const statementsInfoCountSelector = createSelector(statementsSelector, ({statementsInfoCount}) => statementsInfoCount);
export const statementsTypesSelector = createSelector(statementsSelector, ({types}) => types.map(({
    templateType,
    id,
    value,
}) => ({
    value: id,
    templateType,
    key: id,
    text: value,
    id,
})));


export const edoExportListSelector = createSelector(statementsSelector, ({exportList}) => exportList);
export const edoExportListTotalPagesSelector = createSelector(statementsSelector, ({exportListTotalCount, exportListPageData: {pageSize = 0}}) => getTotalPages(exportListTotalCount, pageSize));
export const edoExportListTotalCountSelector = createSelector(statementsSelector, ({exportListTotalCount}) => exportListTotalCount);
//*  SAGA  *//

export const edoExportListProgressSelector = createSelector(statementsSelector, ({exportListProgress}) => exportListProgress);

export const edoExportStatusListSelector = createSelector(statementsSelector, ({exportStatuses}) => exportStatuses);

export const edoExportDataTaskIdsSelector = createSelector(statementsSelector, ({exportList}) => (exportList
    .filter(({state}) => state === EDM_EXPORT_STATUS.IN_PROGRESS))
    .map(({exportId}) => (exportId))
    .filter(Boolean));

//GET /api/documents/edo/info/statement/page
export const getStatementsPageSaga = function* ({payload}) {
    try {
        const {getResult} = payload;
        const result = yield request.post(`${controller}/info/statement/page`, payload);

        getResult(result);

        yield put({type: GET_STATEMENTS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error.message);
    }
};

export const getStatementTypesPageSaga = function* ({payload}) {
    try {
        const result = yield request.post("/statements/types/page", payload);

        yield put({type: GET_STATEMENTS_TYPES_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error.message);
    }
};

//PATCH /api/documents/edo/statement/archive/{statementId}
//PATCH /api/documents/edo/statement/unArchive/{statementId}
export const archiveStatementSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            archived,
            statementId,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/statement/${archived ? "archive" : "unArchive"}/${statementId}`);

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: archiveStatementSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess(`Заявление успешно ${archived ? "архивировано" : "восстановлено из архива"}`);
    } catch (error) {
        console.error("Method: archiveStatementSaga: ", error);
    }
};

//GET /api/documents/edo/info/statement/source
//GET /api/documents/edo/info/statement/signed
//GET /api/documents/edo/info/statement/attachment
export const getStatementsSourceFileSaga = function* ({payload}) {
    try {
        const {
            statementId,
            clientId,
            fileType = STATEMENT_FILE_TYPE.SOURCE_FILE,
            getResult = () => {
            },
            hasDownload = true,
        } = payload;

        const response = yield request.getFile(`${window.location.origin}/api${controller}/info/statement/${fileType}?statementId=${statementId}&clientId=${clientId}`);

        const {headers} = response;

        if (response.status === 200 && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(data === "FILE_NOT_FOUND" ? "Файл не существует" : data || errorMessage);
                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        getResult(blob);

        if (hasDownload) {
            yield downloadBlob(blob, headers);
        }
    } catch (error) {
        console.error("Method: getStatementsSourceFileSaga: ", error);
    }
};

//GET /api/documents/edo/info/statement/count
export const getStatementsCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/info/statement/count`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: GET_STATEMENTS_INFO_COUNT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_STATEMENTS_INFO_COUNT_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getStatementsCountSaga: ", error);
    }
};

//POST /api/documents/edo/statement/upload
//POST /api/documents/edo/template/statement/upload
export const uploadEdoStatementSaga = function* ({payload}) {
    try {
        const {
            hasTemplate = false,
            getResult = () => {
            },
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.post(`${controller}${hasTemplate ? "/template" : ""}/statement/upload`, formData, {...getMultipartHeaders()});
        getResult(errorMessage);
        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: getPageStaffSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

//GET /api/documents/edo/statement/verification
export const sendStatementCodeVerificationSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...params
        } = payload;

        const result = yield request.get(`${controller}/statement/verification`, {params});

        getResult(result);

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("Method: sendStatementCodeVerificationSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Код успешно отправлен");
    } catch (error) {
        console.error("Method: sendStatementCodeVerificationSaga: ", error);
    }
};

//POST /api/documents/edo/statement/replace
//POST /api/documents/edo/template/statement/replace
export const replaceEdoStatementtSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            hasTemplate = false,
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.post(
            `${controller}${hasTemplate ? "/template" : ""}/statement/replace`, formData, {...getMultipartHeaders()});
        getResult(errorMessage);
        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: getPageStaffSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

//PATCH api/documents/edo/statement/sign
export const signEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(
            `${controller}/statement/sign`
            , data);

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: getPageStaffSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подписано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

//PATCH /api/documents/edo/statement/reject
export const rejectEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(
            `${controller}/statement/reject`
            , data);

        getResult(errorMessage);

        if (errorMessage) {
            console.error("Method: rejectEdoStatementSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield put(getStatementsInfoCount());
        yield toastSuccess("Заявление успешно отклонено");
    } catch (error) {
        console.error("Method: rejectEdoStatementSaga: ", error);
    }
};

//PATCH  /api/documents/edo/statement/review
export const reviewEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(
            `${controller}/statement/review`
            , data);

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: reviewEdoStatementSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield put(getStatementsInfoCount());
        yield toastSuccess("Заявление успешно рассмотрено");
    } catch (error) {
        console.error("Method: reviewEdoStatementSaga: ", error);
    }
};

///api/edo/info/{clientId}/counts/{staffId}

export const getCountsEdoByStaffIdSaga = function* ({payload}) {
    try {
        const {
            staffId,
            clientId,
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.get(
            `edo/info/${clientId}/counts/${staffId}`,
        );

        if (errorMessage) {
            console.error("Method: getCountsEdoByStaffIdSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield put({type: GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS, payload: result});

    } catch (error) {
        console.error("Method: getCountsEdoByStaffIdSaga: ", error);
    }
};

export const getEdoExportStatusesSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.get(
            `${controller}/export/getProcessState`, {params: {exportIds: data}});

        yield put({type: GET_EDO_EXPORT_STATUSES_SUCCESS, payload: result});

        onSuccess && onSuccess();

    } catch (error) {
        console.error("Method: getCountsEdoByStaffIdSaga: ", error);
    }
};

//POST /api/documents/edo/statement/checkFile
export const checkStatementFileSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.post(
            `${controller}/statement/checkFile`
            , formData, {...getMultipartHeaders()});
        getResult(errorMessage);
        if (errorMessage) {
            console.error("Method: checkStatementFileSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Файл успешно проверен");
    } catch (error) {
        console.error("Method: checkStatementFileSaga: ", error);
    }
};

//GET /api/documents/edo/template/statement/empty
export const getStatementTemplateEmptyFileSaga = function* ({payload}) {
    try {
        const {
            type,
        } = payload;

        const result = yield request.getFile(
            `${window.location.origin}/api/${controller}/template/statement/empty?type=${type}`,
        );

        if (result.errorMessage) {
            console.error("Method: getStatementTemplateEmptyFileSaga: ", result.errorMessage);
            toastError(result.errorMessage);
            return {
                done: true,
            };
        }

        const blob = yield result.blob();
        yield downloadBlob(blob, result.headers);
    } catch (error) {
        console.error("Method: getStatementTemplateEmptyFileSaga: ", error);
    }
};

//GET /api/documents/edo/template/statement/pre-filled
export const getStatementsTemplatePreFilledFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const response = yield request.getFile(
            `${window.location.origin}/api${controller}/template/statement/pre-filled`
            , {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Authorization:
                        `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                    ,
                    "Content-Type": "application/json",
                },
            });

        const {
            headers,
            status,
        } = response;

        if (status === 200 && headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(data === "FILE_NOT_FOUND" ? "Файл не существует" : errorMessage);
                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        yield getResult(blob);
    } catch (error) {
        console.error("Method: getStatementsTemplatePreFilledFileSaga: ", error.message);
    }
};

//POST
// /api/documents/edo/export/documents Экспорт документов
export const taskEdoExportDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            resourceUrl = "documents",
            ...data
        } = payload;

        const {
            errorMessage,
        } = yield request.post(`${controller}/export/${resourceUrl}`, data);

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: taskEdoExportDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield put({type: TASK_EXPORT_EDO_DOCUMENT_SUCCESS, payload: errorMessage});
    } catch (error) {
        console.error("Method: taskEdoExportDocumentSaga: ", error);
    }
};

export const getEdoExportListSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${controller}/export/getPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_EDO_EXPORT_LIST_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: GET_EDO_EXPORT_LIST_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_EDO_EXPORT_LIST_ERROR, payload: error.message});
    }
};

export const downloadEdoExportFilesSaga = function* ({payload}) {
    try {
        const {
            exportId,
        } = payload;

        const result = yield request.get(`documents/edo/export/get/${exportId}`);

        const {errorMessage, fileName, link} = result;
        console.log("result", result);
        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DOWNLOAD_EDO_EXPORT_FILES_ERROR, payload: result});
            return {
                done: true,
            };
        }

        downloadByUrl(link, fileName);

        yield put({type: DOWNLOAD_EDO_EXPORT_FILES_SUCCESS, payload: result});

    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_EDO_EXPORT_LIST_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_STATEMENTS_PAGE_REQUEST, getStatementsPageSaga),
        takeEvery(GET_STATEMENTS_TYPES_PAGE_REQUEST, getStatementTypesPageSaga),
        takeEvery(GET_EDO_EXPORT_LIST_REQUEST, getEdoExportListSaga),
        takeEvery(GET_STATEMENTS_ARCHIVE_REQUEST, archiveStatementSaga),
        takeEvery(GET_STATEMENTS_SOURCE_FILE_REQUEST, getStatementsSourceFileSaga),
        takeEvery(UPLOAD_EDO_STATEMENT_REQUEST, uploadEdoStatementSaga),
        takeEvery(SEND_STATEMENT_VERIFICATION_CODE_REQUEST, sendStatementCodeVerificationSaga),
        takeEvery(REPLACE_EDO_STATEMENT_REQUEST, replaceEdoStatementtSaga),
        takeEvery(SIGN_STATEMENT_REQUEST, signEdoStatementSaga),
        takeEvery(REJECT_STATEMENT_REQUEST, rejectEdoStatementSaga),
        takeEvery(REVIEW_STATEMENT_REQUEST, reviewEdoStatementSaga),
        takeEvery(GET_EDO_EXPORT_STATUSES_REQUEST, getEdoExportStatusesSaga),
        takeEvery(GET_EDO_COUNTS_BY_STAFF_ID_REQUEST, getCountsEdoByStaffIdSaga),
        takeEvery(GET_STATEMENTS_INFO_COUNT_REQUEST, getStatementsCountSaga),
        takeEvery(DOWNLOAD_EDO_EXPORT_FILES_REQUEST, downloadEdoExportFilesSaga),
        takeEvery(CHECK_STATEMENT_FILE_REQUEST, checkStatementFileSaga),
        takeEvery(GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST, getStatementTemplateEmptyFileSaga),
        takeEvery(GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST, getStatementsTemplatePreFilledFileSaga),
        takeEvery(TASK_EXPORT_EDO_DOCUMENT_REQUEST, taskEdoExportDocumentSaga),
    ],
    );
}
