import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {getInitialTouched} from "../../utils/objectHelper";
import {handleFormString, isNullOrWhitespace} from "../../utils/stringHelper";
import {toastSuccess} from "../../utils/toastHelper";

import {updateContractorNoteForClient} from "../../ducks/bff/contractor/note-for-client/actionCreators";
import {contractorNoteForClientProgressActionSelector} from "../../ducks/bff/contractor/note-for-client/selectors";
import {getInfoContractorCard} from "../../ducks/bff/contractor-сard/actionCreators";

const ContractorNoteForClientEditModal = (props) => {
    const {
        contractor,
        onClose,
    } = props;

    const progressAction = useSelector(contractorNoteForClientProgressActionSelector);

    const dispatch = useDispatch();

    const isEdit = !isNullOrWhitespace(contractor.noteForClient);

    const initialValues = {
        text: contractor.noteForClient || "",
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(updateContractorNoteForClient({
            contractorId: contractor.contractorId,
            text: handleFormString(values.text),
            onSuccess: () => {
                onClose();
                toastSuccess(
                    isEdit
                        ? "Примечание успешно изменено"
                        : "Примечание успешно добавлено",
                );
                dispatch(getInfoContractorCard(contractor.contractorId));
            },
        }));
    };

    const {
        values,
        errors,
        touched,
        isValid,
        handleSubmit,
        handleChange,
    } = useFormik({
        initialValues,
        onSubmit,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: yup.object().shape({
            text: yup.string()
                .nullable()
                .max(100, "Не более 100 символов"),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle
                    size="lg"
                    tooltipText="Примечание отобразится при просмотре карточки или списка исполнителей"
                >
                    {
                        isEdit
                            ? "Редактирование примечание для заказчика"
                            : "Примечание для заказчика"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    onClose={onClose}
                    disabled={progressAction}
                    submit={handleSubmit}
                />
            }
        >
            <NmForm>
                <NmInputV2
                    size="xl"
                    maxLength={100}
                    onChange={handleChange}
                    name="text"
                    placeholder="Введите текст"
                    value={values.text}
                    error={touched.text && errors.text}
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorNoteForClientEditModal;