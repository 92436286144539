import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_GET_TIMESHEET_METRICS_ERROR,
    BFF_GET_TIMESHEET_METRICS_REQUEST,
    BFF_GET_TIMESHEET_METRICS_SUCCESS,
    BFF_GET_TIMESHEET_PAGE_ERROR,
    BFF_GET_TIMESHEET_PAGE_REQUEST,
    BFF_GET_TIMESHEET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/foremen/timesheet",
        client: "/client-adm/client/foremen/timesheet",
    });
};

// POST /bff/adm/clients/client-card/foremen/timesheet/getPage
// POST /bff//client-adm/client/foremen/timesheet/getPage
// Получение страницы табелей
export const getForemenTimesheetPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_TIMESHEET_PAGE_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_TIMESHEET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_TIMESHEET_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/foremen/timesheet/getMetricsForTimeSheet
// GET /bff//client-adm/client/foremen/timesheet/getMetricsForTimeSheet
// Получение количества посещаемости данного табелая
export const getForemenTimesheetMetricsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getMetricsForTimeSheet`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_TIMESHEET_METRICS_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_TIMESHEET_METRICS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_TIMESHEET_METRICS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_TIMESHEET_PAGE_REQUEST, getForemenTimesheetPageSaga),
        takeEvery(BFF_GET_TIMESHEET_METRICS_REQUEST, getForemenTimesheetMetricsSaga),
    ]);
}
