import {useState} from "react";
import {useDispatch} from "react-redux";

import validate from "../../../../../../../utils/validate";

import {
    CONTRACTOR_WORK_EXPERIENCE_RULE,
} from "../../../../../../../constants/validationRules";

import {
    addContractorWorkExperience,
    deleteAllContractorWorkExperience,
    updateContractorWorkExperience,
} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultWorkExperienceForm = {
    workExperienceId: null,
    workExperience: false,
    employerName: "",
    startWorkDate: null,
    endWorkDate: null,
    position: "",
    responsibilities: "",
};

export default function useCreateOrUpdateWorkExperience({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultWorkExperienceForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);

    const {
        workExperienceId,
        employerName,
        workExperience,
        startWorkDate,
        endWorkDate,
        position,
        responsibilities,
    } = form;

    const deleteAll = () => {
        dispatch(deleteAllContractorWorkExperience({
            contractorId,
            getResult: () => {
                getProfile();
            },
        }));
    };

    const validateForm = () => {
        const _errorForm = validate(form, CONTRACTOR_WORK_EXPERIENCE_RULE, "");
        setError({..._errorForm});

        return Object.values(_errorForm).length === 0;
    };

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "workExperience" && checked) {
            setConfirmData({
                onConfirm: () => {
                    deleteAll();
                    onClose();
                },
                confirmContent: "При установке признака сведения об опыте работы будут удалены. Продолжить?",
            });
            setShowConfirm(true);
            setForm({
                ...defaultWorkExperienceForm,
                workExperience: checked,
            });

            setError({});

            return;
        }
        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));

        setError(prevState => ({
            ...prevState,
            [name]: undefined,
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(prevState => !prevState);
    };

    const submit = () => {
        if (workExperience) {
            onClose();
            return;
        }
        
        if (!validateForm()) {
            return;
        }

        const method = workExperienceId
            ? updateContractorWorkExperience
            : addContractorWorkExperience;

        dispatch(method({
            workExperienceId,
            contractorId,
            employerName,
            startWorkDate,
            endWorkDate,
            position,
            responsibilities,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        isShowConfirm,
        confirmData,
        handleCloseConfirm,
        error,
    };
}