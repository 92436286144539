import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";
import SettingsDirectoriesKeywordsEdit from "./components/edit";

import {usePagination} from "../../../../hooks/usePagination";
import {useSettingsDirectoriesKeywordsAction} from "./hooks/useAction";
import {useSettingsDirectoriesKeywordsFetch} from "./hooks/useFetch";
import {useSettingsDirectoriesKeywordsFilter} from "./hooks/useFilter";

import bem from "../../../../utils/bem";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    settingsDirectoriesNaimixKeywordsListSelector,
    settingsDirectoriesNaimixKeywordsTotalCountSelector,
    settingsDirectoriesNaimixKeywordsTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/keywords/selectors";
import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

import "./style.sass";

const KeywordsPage = () => {
    const {t} = useTranslation();

    const [block ] = bem("keywords-list");

    const totalPages = useSelector(settingsDirectoriesNaimixKeywordsTotalPagesSelector);
    const list = useSelector(settingsDirectoriesNaimixKeywordsListSelector);
    const totalCount = useSelector(settingsDirectoriesNaimixKeywordsTotalCountSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER],
        currentUserRestrictions,
    });

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useSettingsDirectoriesKeywordsFilter({
        pageSize,
        setPagination,
    });

    const {
        editData,
        onClickSort,
        confirmData,
        onOpenEdit,
        onCloseEdit,
        onOpenConfirm,
        usageCountSort,
        sortOptions,
        onClickCancelConfirm,
        onClickDeleteKeyword,
    } = useSettingsDirectoriesKeywordsAction();

    const {
    } = useSettingsDirectoriesKeywordsFetch({
        usageCountSort,
        filterData,
        pageNum,
        pageSize,
    });

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        const {
            keyword,
            keywordUuid,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit({
                                keyword,
                                keywordUuid,
                            }, true);
                        },
                        title: t("card-app.edit-title"),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({keywordUuid});
                        },
                        color: "grey",
                        title: t("button.delete"),
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map(item => {
            return {
                ...item,
                key: item.keywordUuid,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={item.keyword}
                        classNameMainContent="col-16 col-xxl-14"
                        labels={[
                            {
                                label: "Количество применений",
                                text: item.usageCount,
                            },
                        ]}
                        isFixedActions
                        actionsClassName="col-16 col-xxl-2"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const getConfirm = () => {
        if (!confirmData) {
            return null;
        }
        
        return (
            <NmConfirmV2
                content={t("keyword.confirm-delete")}
                onCancel={onClickCancelConfirm}
                onConfirm={onClickDeleteKeyword}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    const getEdit = () => {
        if (!editData) {
            return null;
        }
        
        return (
            <SettingsDirectoriesKeywordsEdit
                isEdit={editData.isEdit}
                onClose={onCloseEdit}
                keywordData={editData.keywordData}
            />
        );
    };

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            onPaginationChange={onPaginationChange}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            typeFilter="vertical"
            className={block()}
            controls={
                isAccessEdit &&
                <NmButton
                    size="xl"
                    onClick={() => {
                        onOpenEdit();
                    }}
                    icon={<AddIcon />}
                >
                    {t("keyword.add-btn")}
                </NmButton>
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
        >
            {getEdit()}
            {getConfirm()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        sort
                        sortOptions={sortOptions}
                        onClickSort={onClickSort}
                        rows={rows}
                    />
            }
        </NmPage>
    );
};


export default KeywordsPage;
