import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../components/ActualComponents/NmLabelText";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import NmModal from "../../components/ActualComponents/NmModal";
import NmPagination from "../../components/ActualComponents/NmPagination";
import CheckboxList from "../../components/CheckboxList";
import NmTitle from "../../components/NmTitle";

import dateFormat, {convertUtcToLocal} from "../../utils/dateFormat";

import {BANK_TYPE} from "../../constants/clientSettings";
import {FINANCE_LOG_TYPE} from "../../constants/financeLogType";

import {getAdmClientPageOptimized} from "../../ducks/bff/adm/plutonium/transaction-log/services";
import {getClientCardInfo} from "../../ducks/bff/clients/info/actionCreators";
import {
    bffPlutoniumTransactionLogListSelector,
    bffPlutoniumTransactionLogTotalCountSelector,
    bffPlutoniumTransactionLogTotalPagesSelector,
} from "../../ducks/bff/plutonium/transaction-log/selectors";
import {
    getPageLogSystemTransaction,
    updateStoreSystemTransactions,
} from "../../ducks/bff/system-transactions/actionCreators";
import {
    bffSystemTransactionsLogListSelector,
    bffSystemTransactionsLogProgressSelector,
    bffSystemTransactionsLogTotalCountSelector,
    bffSystemTransactionsLogTotalPagesSelector,
} from "../../ducks/bff/system-transactions/selectors";;
import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";
import {
    updateFieldClientMemberStore,
} from "../../ducks/clientMember";
import {
    financeLogListSelector,
    financeLogTotalCountSelector,
    financeLogTotalPagesSelector,
    getPaymentStepsLog,
    updateFinanceLogField,
} from "../../ducks/financeLog";
import {getOrderById, getOrderCardSelector} from "../../ducks/order";
import {
    getLogLoadingDataSelector,
    getOrderWorkReportLogs,
    getOrderWorkReportLogsSelector,
    getOrderWorkReportLogsTotalPagesSelector,
    getTotalCountLogSelector,
    updateFieldOrderWorkReportStore,
} from "../../ducks/orderWorkReport";

import {clientType, logDataType} from "../../types";
import PropTypes from "prop-types";

import "./style.sass";

class FinanceLogPopup extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        logType: PropTypes.string,
        logData: logDataType,
        logTransaction: PropTypes.array,
        logTransactionTotalPages: PropTypes.number,
        logTransactionTotalCount: PropTypes.number,
        reportLogs: PropTypes.array,
        reportLogTotalPages: PropTypes.number,
        reportLogTotalCount: PropTypes.number,
        order: PropTypes.object,
        client: clientType,
        getOrderWorkReportLogs: PropTypes.func,
        updateFieldOrderWorkReportStore: PropTypes.func,
        getFinanceLog: PropTypes.func,
        updateFinanceLogField: PropTypes.func,
        getOrderById: PropTypes.func,
        getClientCardInfo: PropTypes.func,
        updateFieldClientMemberStore: PropTypes.func,
        isShowClientPayments: PropTypes.bool,
        isCivil: PropTypes.bool,
    };

    static defaultProps = {
        handleClose: () => {
        },
        logType: "",
        logTransaction: [],
        logTransactionTotalPages: 0,
        logTransactionTotalCount: 0,
        reportLogs: [],
        reportLogTotalPages: 0,
        reportLogTotalCount: 0,
        order: {},
        client: {},
        getOrderWorkReportLogs: () => {
        },
        updateFieldOrderWorkReportStore: () => {
        },
        getFinanceLog: () => {
        },
        updateFinanceLogField: () => {
        },
        getOrderById: () => {
        },
        getClientCardInfo: () => {
        },
        updateFieldClientMemberStore: () => {
        },
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: this.pageSizes[0],
            reportLogs: [],
        };
    }

    componentDidMount() {
        const {
            getOrderById,
            getClientCardInfo,
            logData: {
                orderId,
                clientId,
            },
            logType,
        } = this.props;
        this.fetchList();

        if (![FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG, FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG].includes(logType)) {
            getOrderById({
                clientId,
                orderId,
            });
        }

        if (![FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG].includes(logType)) {
            getClientCardInfo({clientId});
        }
    }

    componentWillUnmount() {
        const {
            updateFieldOrderWorkReportStore,
            updateFinanceLogField,
            updateFieldClientMemberStore,
            updateStoreSystemTransactions,
        } = this.props;

        updateFieldOrderWorkReportStore({orderWorkReportLogs: []});
        updateFieldClientMemberStore({list: []});
        updateFinanceLogField({transactionLog: []});
        updateStoreSystemTransactions({
            logList: [],
            logTotalCount: 0,
        });
    }

    fetchList = () => {
        const {
            logType,
            getAdmClientPageOptimized,
            getPaymentStepsLog,
            getOrderWorkReportLogs,
            isShowClientPayments,
            getPageLogSystemTransaction,
            logData: {
                clientId,
                orderId,
                paymentNumber: paymentNumberFilter,
                patentPaymentId,
                bankType,
                orderWorkReportId,
                transactionId,
            },
            isCivil,
        } = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        if (logType === FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG) {
            getPageLogSystemTransaction({
                transactionId,
                pageNum,
                pageSize,
            });

            return;
        }

        if (logType === FINANCE_LOG_TYPE.TRANSACTION_LOG) {
            const isPlutonium = bankType === BANK_TYPE.PLUTONIUM_W1.VALUE;
            const reqData = isPlutonium ? {
                clientId,
                orderId,
                pageNum,
                pageSize,
                paymentNumberFilter,
            } : {
                paymentId: orderWorkReportId,
            };

            getPaymentStepsLog({
                isCivil,
                isPlutonium,
                pageNum,
                pageSize,
                ...reqData,
            });
            return;
        }

        if (logType === FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG) {
            getAdmClientPageOptimized({
                clientId,
                pageNum,
                pageSize,
                patentPaymentId,
            });
            return;
        }

        getOrderWorkReportLogs({
            isCivil,
            isShowClientPayments,
            pageNum,
            pageSize,
            paymentNumberFilter,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        const {
            reportLogTotalCount,
            logTransactionTotalCount,
            logType,
        } = this.props;

        const totalCount = logType === FINANCE_LOG_TYPE.TRANSACTION_LOG ? logTransactionTotalCount : reportLogTotalCount;
        this.setState(prevState => {
            return {
                ...prevState,
                pageSize,
                pageNum: totalCount <= pageSize ? 1 : prevState.pageNum,
            };
        },
        this.fetchList,
        );
    };

    renderInfoBlock() {
        const {
            logType,
            order: {
                name,
                orderNum,
            },
            logData: {
                paymentNumber,
                description,
                contractorFio,
                paymentSum,
                orderWorkReportId,
            },
            t,
        } = this.props;

        if (logType === FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG) {
            return;
        }

        if (logType === FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG) {
            return (
                <>
                    <NmLabelText
                        type="page"
                        className="mb-2"
                        label={t("finance-log.contractor")}
                        text={contractorFio}
                    />
                    <NmLabelText
                        type="page"
                        className="mb-4"
                        label={t("finance-log.sum")}
                        text={paymentSum}
                    />
                </>
            );
        }

        return (
            <>
                <NmLabelText
                    type="page"
                    className="mb-2"
                    label={`${t("finance-log.order")} №${orderNum}`}
                    text={name}
                />
                <NmLabelText
                    type="page"
                    className="mb-2"
                    label={t("finance-log.payment")}
                    text={`№${paymentNumber}`}
                />
                <NmLabelText
                    type="page"
                    className="mb-2"
                    label="Идентификатор платежа"
                    text={orderWorkReportId}
                />
                <NmLabelText
                    type="page"
                    className="mb-4"
                    noWrap={false}
                    label={t("finance-log.description-work")}
                    inline={true}
                    columnOnMobile={true}
                    text={description}
                />
            </>
        );
    };


    getPaymentLogLabels(item) {
        const {
            logType,
            logData: {
                bankType,
            },
        } = this.props;

        const {
            date,
            action,
            description,
            actionStep,
            attemptNum,
            status,
            createdAt,
            updatedAt,
            completedAt,
            message,
            params,
        } = item;

        if (![FINANCE_LOG_TYPE.TRANSACTION_LOG, FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG].includes(logType) || bankType === BANK_TYPE.PLUTONIUM_W1.VALUE) {
            return [
                {
                    label: "Дата",
                    text: dateFormat(convertUtcToLocal(date), "dd.MM.yyyy HH:mm:ss"),
                    columnOnMobile: true,
                },
                {
                    label: "Действие",
                    text: action,
                    columnOnMobile: true,
                },
                {
                    label: "Описание",
                    text: description,
                    inline: true,
                    noWrap: false,
                    columnOnMobile: true,
                },
            ];
        }

        return [
            {
                label: "Шаг",
                text: actionStep ? `${actionStep.description} / ${actionStep.value}` : "",
                inline: true,
                noWrap: false,
                columnOnMobile: true,
            },
            {
                label: "Попыток проведения",
                text: attemptNum,
                columnOnMobile: true,
            },
            {
                label: "Статус выполнения",
                text: status ? `${status.description} / ${status.value}` : "",
                columnOnMobile: true,
            },
            {
                label: "Дата создания шага",
                text: dateFormat(convertUtcToLocal(createdAt), "dd.MM.yyyy HH:mm:ss"),
                columnOnMobile: true,
            },
            {
                label: "Дата обновления шага",
                text: dateFormat(convertUtcToLocal(updatedAt), "dd.MM.yyyy HH:mm:ss"),
                columnOnMobile: true,
            },
            {
                label: "Дата завершения шага",
                text: dateFormat(convertUtcToLocal(completedAt), "dd.MM.yyyy HH:mm:ss"),
                columnOnMobile: true,
            },
            {
                label: "Комментарий",
                text: message,
                noWrap: false,
                columnOnMobile: true,
            },
            {
                label: "Параметры",
                text: params,
                inline: true,
                noWrap: false,
                columnOnMobile: true,
            },
        ];
    };

    getRows() {
        const {logType} = this.props;

        if ([FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG].includes(logType)) {
            const {logSystemTransactionList} = this.props;

            return logSystemTransactionList.map(item => {
                return {
                    ...item,
                    contentRow: (
                        <NmListCard
                            noDivider
                            labels={this.getPaymentLogLabels(item)}
                        />
                    ),
                };
            });
        }

        if (logType === FINANCE_LOG_TYPE.TRANSACTION_LOG) {
            const {logTransaction} = this.props;

            return logTransaction.map(item => {
                return {
                    ...item,
                    contentRow: (
                        <NmListCard
                            noDivider
                            labels={this.getPaymentLogLabels(item)}
                        />
                    ),
                };
            });
        }

        if (logType === FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG) {
            const {patentLogTransaction} = this.props;

            return patentLogTransaction.map(item => {
                return {
                    ...item,
                    contentRow: (
                        <NmListCard
                            noDivider
                            labels={this.getPaymentLogLabels(item)}
                        />
                    ),
                };
            });
        }

        const {reportLogs} = this.props;

        return reportLogs.map(item => {
            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={[
                            {label: "Дата", text: dateFormat(convertUtcToLocal(item.datetime), "dd.MM.yyyy HH:mm:ss"), columnOnMobile: true},
                            {
                                label: "Инициатор",
                                text: item.performerFio,
                                columnOnMobile: true,
                            },
                            {
                                label: "Описание",
                                text: item.paymentStatus?.description,
                                inline: true,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    getPageData = () => {
        const {
            logTransactionTotalPages,
            logTransactionTotalCount,
            reportLogTotalPages,
            reportLogTotalCount,
            logType,
            logSystemTransactionTotalPages,
            logSystemTransactionTotalCount,
            patentLogTransactionTotalPages,
            patentLogTransactionTotalCount,
        } = this.props;

        if (logType === FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG) {
            return {
                totalPages: logSystemTransactionTotalPages,
                totalCount: logSystemTransactionTotalCount,
            };
        }

        if (logType === FINANCE_LOG_TYPE.TRANSACTION_LOG) {
            return {
                totalPages: logTransactionTotalPages,
                totalCount: logTransactionTotalCount,
            };
        }

        if (logType === FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG) {
            return {
                totalPages: patentLogTransactionTotalPages,
                totalCount: patentLogTransactionTotalCount,
            };
        }

        return {
            totalPages: reportLogTotalPages,
            totalCount: reportLogTotalCount,
        };
    };

    render() {
        const {
            handleClose,
            logType,
            loadingData,
            client: {
                name: clientName,
                brand,
            },
            logSystemTransactionProgress,
            t,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        const isTransactionLog = [
            FINANCE_LOG_TYPE.TRANSACTION_LOG,
            FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG,
            FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG,
        ].includes(logType);
        const {
            totalPages,
            totalCount,
        } = this.getPageData();

        return (
            <NmModal
                size="md"
                loading={Boolean(loadingData) || logSystemTransactionProgress}
                classNameContent="finance-log-popup__modal-content"
                onClose={handleClose}
                header={
                    <NmTitle size="lg">
                        {isTransactionLog ? t("finance-log.bank-log-title") : t("finance-log.payment-log-title")}
                    </NmTitle>
                }
            >
                {
                    logType !== FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG &&
                    <NmLabelText
                        type="page"
                        className="mb-2"
                        label={t("finance-log.client")}
                        text={brand ? `${clientName} (${brand})` : clientName}
                    />
                }
                {this.renderInfoBlock()}
                {
                    totalCount ?
                        <CheckboxList
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2 />

                }
                <NmPagination
                    className="mt-4 mt-md-5 mt-xxl-7"
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onChangePageSize={this.handleChangePageSize}
                    onChangePagination={this.handlePaginationChange}
                />
            </NmModal>
        );
    }
}

export default connect(state => ({
    logTransaction: financeLogListSelector(state),
    logTransactionTotalPages: financeLogTotalPagesSelector(state),
    logTransactionTotalCount: financeLogTotalCountSelector(state),
    patentLogTransaction: bffPlutoniumTransactionLogListSelector(state),
    patentLogTransactionTotalPages: bffPlutoniumTransactionLogTotalPagesSelector(state),
    patentLogTransactionTotalCount: bffPlutoniumTransactionLogTotalCountSelector(state),
    reportLogs: getOrderWorkReportLogsSelector(state),
    reportLogTotalPages: getOrderWorkReportLogsTotalPagesSelector(state),
    reportLogTotalCount: getTotalCountLogSelector(state),
    order: getOrderCardSelector(state),
    client: clientCardInfoSelector(state),
    loadingData: getLogLoadingDataSelector(state),
    logSystemTransactionList: bffSystemTransactionsLogListSelector(state),
    logSystemTransactionTotalPages: bffSystemTransactionsLogTotalPagesSelector(state),
    logSystemTransactionTotalCount: bffSystemTransactionsLogTotalCountSelector(state),
    logSystemTransactionProgress: bffSystemTransactionsLogProgressSelector(state),
}),
{
    getOrderWorkReportLogs,
    updateFieldOrderWorkReportStore,
    getAdmClientPageOptimized,
    updateFinanceLogField,
    getOrderById,
    getClientCardInfo,
    updateFieldClientMemberStore,
    getPaymentStepsLog,
    getPageLogSystemTransaction,
    updateStoreSystemTransactions,
},
)(withTranslation()(FinanceLogPopup));
