import {
    BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS,
    BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS,
    BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS,
    BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS,
    BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS,
    BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS,
    BFF_COMMON_DICTS_UPDATE_STORE,
    GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS,
    GET_DICTIONARIES_EDUCATION_FORM_SUCCESS,
    GET_DICTIONARIES_TASK_STATUSES_SUCCESS,
    GET_DICTIONARIES_TASK_TYPES_SUCCESS,
} from "./actions";

const initial = {
    onBoardingScenarioFrameContractTypeDict: {},
    onBoardingContractorSourceDict: {},
    onBoardingLeadRegistrationStatusDict: {},
    menuDesignDict: {},
    phoneCodeList: [],
    flashCallPageData: {},
    flashCallList: [],
    flashCallTotalCount: 0,
    flashCallProgress: false,
    flashCallProgressAction: false,
    clientWorkTypeDict: {},
    positions: [],
    subdivisions: [],
    kedoRoleDict: {},
    kedoDocumentStatesDict: {},
    kedoDocumentFileSourceTypesDict: {},
    kedoParticipantStatesDict: {},
    kedoDocumentHistoryEventTypes: {},
    kedoExportStateTypes: {},
    kedoExportTypes: {},
    kedoSignaturesTypes: {},
    kedoOfficeTypes: {},
    kedoDocumentsCreatedViaTypes: {},
    kedoSignatureTransactionsActionTypes: {},
    kedoSignatureTransactionsDecideTypes: {},
    kedoAllDocumentCodes: {},
    kedoAllDocumentTypes: [],
    kedoDepartmentNames: [],
    kedoStaffDepartmentDeputyHeadList: [],
    kedoStaffDepartmentHeadList: [],
    kedoStaffFioList: [],
    kedoStaffPositionsList: [],
    kedoDocumentRouteList: [],
    kedoDepartmentLogTypes: {},
    kedoDepartmentLogMergeTypes: {},
    taskTypes: {},
    customDocumentTemplateLogEventTypes: {},
    clientUsersFio: [],
    clientNames: [],
    customDocumentTemplateNames: [],
    clientUsagesLogTypes: {},
    clientUsagesOrderTypes: {},
    clientBrands: [],
    clientCategoriesDict: [],
    contractorApplicationTypes: {},
    taskStatuses: [],
    serviceTaskTypes: [],
    educationDict: {},
    contractorLanguageLevelsDict: {},
    uploadedDocumentTypes: {},
    templateDocumentTypes: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS:
            return {
                ...state,
                contractorApplicationTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS:
            return {
                ...state,
                clientCategoriesDict: payload,
            };
        case BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS:
            return {
                ...state,
                clientBrands: payload.results,
            };
        case BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS:
            return {
                ...state,
                clientUsagesLogTypes: payload,
            };
        case BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS:
            return {
                ...state,
                clientUsagesOrderTypes: payload,
            };
        case BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS:
            return {
                ...state,
                customDocumentTemplateNames: payload.results,
            };
        case BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS:
            return {
                ...state,
                clientNames: payload.results,
            };
        case BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS:
            return {
                ...state,
                clientUsersFio: payload.results,
            };
        case BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS:
            return {
                ...state,
                customDocumentTemplateLogEventTypes: payload,
            };
        case BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS:
            return {
                ...state,
                kedoDepartmentLogMergeTypes: payload,
            };
        case BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS:
            return {
                ...state,
                kedoDepartmentLogTypes: payload,
            };
        case BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS:
            return {
                ...state,
                kedoAllDocumentTypes: payload.results,
            };
        case BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS:
            if (payload.onlyDepartmentDeputyHead) {
                return {
                    ...state,
                    kedoStaffDepartmentDeputyHeadList: payload.results,
                };
            }

            if (payload.onlyDepartmentHead) {
                return {
                    ...state,
                    kedoStaffDepartmentHeadList: payload.results,
                };
            }

            return {
                ...state,
                kedoStaffFioList: payload.results,
            };
        case BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS:
            return {
                ...state,
                kedoAllDocumentCodes: payload,
            };
        case BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS:
            return {
                ...state,
                kedoDepartmentNames: payload.results,
            };
        case BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS:
            return {
                ...state,
                kedoExportTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS:
            return {
                ...state,
                kedoExportStateTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS:
            return {
                ...state,
                taskTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS:
            return {
                ...state,
                kedoDocumentHistoryEventTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS:
            return {
                ...state,
                kedoParticipantStatesDict: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS:
            return {
                ...state,
                kedoDocumentFileSourceTypesDict: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS:
            return {
                ...state,
                kedoDocumentStatesDict: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS:
            return {
                ...state,
                kedoRoleDict: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS:
            return {
                ...state,
                kedoSignaturesTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS:
            return {
                ...state,
                kedoOfficeTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS:
            return {
                ...state,
                kedoDocumentsCreatedViaTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS:
            return {
                ...state,
                kedoSignatureTransactionsActionTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS:
            return {
                ...state,
                kedoSignatureTransactionsDecideTypes: payload,
            };
        case BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS:
            return {
                ...state,
                positions: payload.results,
            };
        case BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS:
            return {
                ...state,
                subdivisions: payload.results,
            };
        case BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS:
            return {
                ...state,
                clientWorkTypeDict: payload,
            };
        case BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS:
            return {
                ...state,
                menuDesignDict: payload,
            };
        case BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS:
            return {
                ...state,
                onBoardingLeadRegistrationStatusDict: payload,
            };
        case BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS:
            return {
                ...state,
                onBoardingContractorSourceDict: payload,
            };
        case BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS:
            return {
                ...state,
                onBoardingScenarioFrameContractTypeDict: payload,
            };
        case BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS:
            return {
                ...state,
                phoneCodeList: payload.list,
            };
        case BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST:
            return {
                ...state,
                flashCallPageData: payload,
                flashCallProgress: true,
            };
        case BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS:
            return {
                ...state,
                flashCallList: payload.results,
                flashCallTotalCount: payload.totalCount,
                flashCallProgress: false,
            };
        case BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR:
            return {
                ...state,
                flashCallProgress: false,
            };
        case BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST:
            return {
                ...state,
                flashCallProgressAction: true,
            };
        case BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS:
        case BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR:
            return {
                ...state,
                flashCallProgressAction: false,
            };
        case BFF_COMMON_DICTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_DICTIONARIES_TASK_STATUSES_SUCCESS:
            return {
                ...state,
                taskStatuses: payload,
            };
        case GET_DICTIONARIES_TASK_TYPES_SUCCESS:
            return {
                ...state,
                serviceTaskTypes: payload,
            };
        case GET_DICTIONARIES_EDUCATION_FORM_SUCCESS:
            return {
                ...state,
                educationDict: payload,
            };
        case GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS:
            return {
                ...state,
                contractorLanguageLevelsDict: payload,
            };
        case BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS:
            return {
                ...state,
                kedoStaffPositionsList: payload.results,
            };
        case BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS:
            return {
                ...state,
                kedoDocumentRouteList: payload.results,
            };
        case BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST:
            return {
                ...state,
                uploadedDocumentTypes: {
                    ...state.uploadedDocumentTypes,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS:
            return {
                ...state,
                uploadedDocumentTypes: {
                    ...state.uploadedDocumentTypes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR:
            return {
                ...state,
                uploadedDocumentTypes: {
                    ...state.uploadedDocumentTypes,
                    error: payload,
                    progress: false,
                },
            };
        case BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    error: payload,
                    progress: false,
                },
            };
        default:
            return state;
    }
};