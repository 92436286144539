import {
    SMART_LINK_CACHED_CLIENTS_ERROR,
    SMART_LINK_CACHED_CLIENTS_REQUEST,
    SMART_LINK_CACHED_CLIENTS_SUCCESS,
    SMART_LINK_CHECK_ORDER_EXISTS_ERROR,
    SMART_LINK_CHECK_ORDER_EXISTS_REQUEST,
    SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS,
    SMART_LINK_CLIENT_PART_SAVE_ERROR,
    SMART_LINK_CLIENT_PART_SAVE_REQUEST,
    SMART_LINK_CLIENT_PART_SAVE_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS,
    SMART_LINK_CREATE_ERROR,
    SMART_LINK_CREATE_REQUEST,
    SMART_LINK_CREATE_SUCCESS,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS,
    SMART_LINK_DELETE_CACHED_CLIENT_ERROR,
    SMART_LINK_DELETE_CACHED_CLIENT_REQUEST,
    SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS,
    SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR,
    SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS,
    SMART_LINK_FILTER_PART_SAVE_ERROR,
    SMART_LINK_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_FILTER_PART_SAVE_SUCCESS,
    SMART_LINK_GET_CARD_ERROR,
    SMART_LINK_GET_CARD_REQUEST,
    SMART_LINK_GET_CARD_SUCCESS,
    SMART_LINK_GET_COUNT_BY_FILTER_ERROR,
    SMART_LINK_GET_COUNT_BY_FILTER_REQUEST,
    SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS,
    SMART_LINK_GET_DETAILS_TREE_ERROR,
    SMART_LINK_GET_DETAILS_TREE_REQUEST,
    SMART_LINK_GET_DETAILS_TREE_SUCCESS,
    SMART_LINK_IMPORT_NUMBER_ERROR,
    SMART_LINK_IMPORT_NUMBER_REQUEST,
    SMART_LINK_IMPORT_NUMBER_SUCCESS,
    SMART_LINK_PAGE_ERROR,
    SMART_LINK_PAGE_REQUEST,
    SMART_LINK_PAGE_SUCCESS,
    SMART_LINK_UPDATE_ERROR,
    SMART_LINK_UPDATE_REQUEST,
    SMART_LINK_UPDATE_STORE,
    SMART_LINK_UPDATE_SUCCESS,
} from "./actions";

import {getTotalPages} from "../../utils/mathHelper";

export const getSmartLinkInitialState = () => {
    return {
        progress: false,
        list: [],
        totalCount: 0,
        pageData: {},
        card: {},
        progressCard: false,
        progressAction: false,
        progressCheck: false,
        numbers: [],
        count: 0,
        progressCount: false,
        tree: {},
        projects: {
            clientInfo: {},
            progress: true,
            list: [],
            totalCount: 0,
            pageData: {},
        },
        objects: {
            lists: {},
        },
        clients: [],
        detailsTree: {},
        projectsAndObjects: {},
        progressSearch: false,
        progressClientSettings: false,
        progressClearAll: false,
        progressClientId: "",
        error: null,
    };
};

const initialState = getSmartLinkInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR:
        case SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS:
            return {
                ...state,
                progressClearAll: false,
            };
        case SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST:
            return {
                ...state,
                progressClearAll: true,
            };
        case SMART_LINK_DELETE_CACHED_CLIENT_ERROR:
        case SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS:
            return {
                ...state,
                progressClientId: payload.clientId,
            };
        case SMART_LINK_DELETE_CACHED_CLIENT_REQUEST:
            return {
                ...state,
                progressClientId: payload.clientId,
            };
        case SMART_LINK_CACHED_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: payload.results,
                progressCard: false,
            };
        case SMART_LINK_GET_DETAILS_TREE_REQUEST:
            return {
                ...state,
                progressClientSettings: true,
            };
        case SMART_LINK_GET_DETAILS_TREE_ERROR:
            return {
                ...state,
                progressClientSettings: false,
            };
        case SMART_LINK_GET_DETAILS_TREE_SUCCESS:
            return {
                ...state,
                detailsTree: payload.result,
                progressClientSettings: false,
            };
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST:
            return {
                ...state,
                progressSearch: true,
            };
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectsAndObjects: payload,
                progressSearch: false,
            };
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR:
            return {
                ...state,
                progressSearch: false,
            };
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST: {
            const [projectId] = payload.projectIds;

            const {
                [projectId]: objects = {},
            } = state.objects.lists;

            return {
                ...state,
                objects: {
                    ...state.objects,
                    lists: {
                        ...state.objects.lists,
                        [projectId]: {
                            ...objects,
                            isLoading: true,
                            pageData: payload,
                        },
                    },
                },
            };
        }
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS: {
            const {
                [payload.projectId]: objects = {},
            } = state.objects.lists;

            const {list = []} = objects;

            const {
                totalCount,
                results,
                nextAllPagesSelectionType,
                ...otherPayload
            } = payload;

            const _results = results || [];

            return {
                ...state,
                objects: {
                    ...state.objects,
                    ...otherPayload,
                    lists: {
                        ...state.objects.lists,
                        [payload.projectId]: {
                            ...objects,
                            list: [
                                ...list,
                                ..._results,
                            ],
                            nextAllPagesSelectionType,
                            isLoading: false,
                            totalCount: totalCount,
                            totalPages: getTotalPages(totalCount, objects.pageData.pageSize),
                        },
                    },
                },
            };
        }
        case SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR: {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    progress: false,
                },
            };
        }
        case SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    pageData: payload,
                    progress: true,
                },
            };
        case SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS: {
            const {
                results,
                totalCount,
                ...otherPayload
            } = payload;

            return {
                ...state,
                projects: {
                    ...state.projects,
                    clientInfo: otherPayload,
                    list: state.projects.pageData.isSearchCleared ?
                        results :
                        [
                            ...state.projects.list,
                            ...results,
                        ],
                    totalCount,
                    progress: false,
                },
            };
        }
        case SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    progress: false,
                },
            };
        }
        case SMART_LINK_GET_COUNT_BY_FILTER_REQUEST:
            return {
                ...state,
                progressCount: true,
            };
        case SMART_LINK_GET_COUNT_BY_FILTER_ERROR:
            return {
                ...state,
                progressCount: false,
            };
        case SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS:
            return {
                ...state,
                count: payload.result,
                progressCount: false,
            };
        case SMART_LINK_CHECK_ORDER_EXISTS_REQUEST:
            return {
                ...state,
                progressCheck: true,
            };
        case SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS:
            return {
                ...state,
                progressCheck: false,
            };
        case SMART_LINK_CHECK_ORDER_EXISTS_ERROR:
            return {
                ...state,
                progressCheck: false,
                errorOrderExists: payload.errorMessage,
            };
        case SMART_LINK_IMPORT_NUMBER_SUCCESS:
            return {
                ...state,
                numbers: payload.result,
                progressAction: false,
            };
        case SMART_LINK_CLIENT_PART_SAVE_ERROR:
            return {
                ...state,
                error: payload,
                progressAction: false,
            };
        case SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR:
        case SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS:
        case SMART_LINK_CLIENT_PART_SAVE_SUCCESS:
        case SMART_LINK_CREATE_SUCCESS:
        case SMART_LINK_CREATE_ERROR:
        case SMART_LINK_FILTER_PART_SAVE_SUCCESS:
        case SMART_LINK_FILTER_PART_SAVE_ERROR:
        case SMART_LINK_IMPORT_NUMBER_ERROR:
        case SMART_LINK_UPDATE_SUCCESS:
        case SMART_LINK_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST:
        case SMART_LINK_CLIENT_PART_SAVE_REQUEST:
        case SMART_LINK_CREATE_REQUEST:
        case SMART_LINK_FILTER_PART_SAVE_REQUEST:
        case SMART_LINK_IMPORT_NUMBER_REQUEST:
        case SMART_LINK_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SMART_LINK_CACHED_CLIENTS_REQUEST:
        case SMART_LINK_GET_CARD_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case SMART_LINK_CACHED_CLIENTS_ERROR:
        case SMART_LINK_GET_CARD_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case SMART_LINK_GET_CARD_SUCCESS:
            return {
                ...state,
                card: payload.result,
                progressCard: false,
            };
        case SMART_LINK_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case SMART_LINK_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case SMART_LINK_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SMART_LINK_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};