import {
    CLEAR_MARKETING_NOTIFICATIONS_STORE,
    MARKETING_NOTIFICATIONS_ADD_REQUEST,
    MARKETING_NOTIFICATIONS_CANCEL_REQUEST,
    MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST,
    MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST,
    MARKETING_NOTIFICATIONS_GET_STATS_REQUEST,
    MARKETING_NOTIFICATIONS_RETRY_REQUEST,
    UPDATE_MARKETING_NOTIFICATIONS_STORE,
} from "./actions";

export const getPageMarketingNotifications = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addMarketingNotification = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_ADD_REQUEST,
        payload,
    };
};

export const cancelMarketingNotification = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_CANCEL_REQUEST,
        payload,
    };
};

export const retryMarketingNotification = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_RETRY_REQUEST,
        payload,
    };
};

export const getStatsMarketingNotification = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_GET_STATS_REQUEST,
        payload,
    };
};

export const getContractorsCountMarketingNotification = (payload) => {
    return {
        type: MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST,
        payload,
    };
};

export const clearStoreMarketingNotifications = (payload) => {
    return {
        type: CLEAR_MARKETING_NOTIFICATIONS_STORE,
        payload,
    };
};

export const updateStoreMarketingNotifications = (payload) => {
    return {
        type: UPDATE_MARKETING_NOTIFICATIONS_STORE,
        payload,
    };
};