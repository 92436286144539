import {
    BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
    UPDATE_CONTRACTOR_REGISTRY_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    secondTryingIndicator: false,
    recommendationModelType: "",
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS:
            const {
                contractors = [],
                totalCount = 0,
                secondTry = false,
                recommendationModelType,
            } = payload;

            return {
                ...state,
                progress: false,
                list: contractors,
                totalCount,
                secondTryingIndicator: secondTry,
                recommendationModelType,
            };
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST:
        case BFF_CONTRACTOR_REGISTRY_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS:
        case BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR:
        case BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS:
        case BFF_CONTRACTOR_REGISTRY_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_CONTRACTOR_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};