import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

import {getClientCardInfo, updateClientCardRequisites} from "../../../../../../ducks/bff/clients/info/actionCreators";
import {getDadataRequisitesByInn} from "../../../../../../ducks/clientDadata";

const transformRequisites = ({requisites, autoCompleteFields}) => {
    const {
        type,
        clientId,
        ...otherRequisites
    } = requisites;

    return Object.keys(otherRequisites)
        .filter(key =>
            autoCompleteFields.includes(key) &&
            !isNullOrWhitespace(otherRequisites[key]),
        )
        .reduce((accum, key) => {
            accum[key] = otherRequisites[key];

            return accum;
        }, {});
};

export const useDadataInnCheck = (params) => {
    const {
        inn,
        clientType,
        onSuccess,
        clientId,
        autoCompleteFields,
    } = params;
    const dispatch = useDispatch();

    const progress = useSelector(({clientDadata}) => clientDadata.progress);

    const onCheck = () => {
        if (!inn) {
            toastError("Для получения данных о компании заполните ИНН и повторите попытку");

            return;
        }

        dispatch(getDadataRequisitesByInn({
            inn,
            clientType,
            onSuccess: ({requisites}) => {
                if (isEmpty(requisites)) {
                    return;
                }

                const result = transformRequisites({
                    requisites,
                    autoCompleteFields,
                });

                onSuccess(result);
            },
        }));
    };

    const onUpdate = () => {
        dispatch(updateClientCardRequisites({
            clientId,
            onError: ({errorMessage, errorCode}) => {
                if (errorCode === "CLIENT_REQUISITES_NOT_UPDATED") {
                    toastSuccess(errorMessage);
                    dispatch(getClientCardInfo({clientId}));

                    return;
                }

                toastError(errorMessage);
            },
            onSuccess: () => {
                toastSuccess("Реквизиты компании успешно обновлены");
                dispatch(getClientCardInfo({clientId}));
            },
        }));
    };

    return {
        onCheck,
        progress,
        onUpdate,
    };
};