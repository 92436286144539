import {
    BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST,
    BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST,
    BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST,
    BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST,
    BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST,
    BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST,
    BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST,
    BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_MENU_DESIGN_REQUEST,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST,
    BFF_COMMON_DICTS_UPDATE_STORE,
    GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST,
    GET_DICTIONARIES_EDUCATION_FORM_REQUEST,
    GET_DICTIONARIES_TASK_STATUSES_REQUEST,
    GET_DICTIONARIES_TASK_TYPES_REQUEST,
} from "./actions";

export function updateCommonPaymentDicts(payload) {
    return {
        type: BFF_COMMON_DICTS_UPDATE_STORE,
        payload,
    };
}

export function getCommonOnBoardingScenarioFrameContractTypeDict(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST,
        payload,
    };
}

export function getOnboardingContractorSourceDict(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST,
        payload,
    };
}

export function getOnboardingLeadRegistrationStatusDict(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST,
        payload,
    };
}

export function getMenuDesignDict(payload) {
    return {
        type: BFF_COMMON_DICTS_MENU_DESIGN_REQUEST,
        payload,
    };
}

export function getPhoneCodeAll(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST,
        payload,
    };
}

export function getPageDictionaryFlashCall(payload) {
    return {
        type: BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST,
        payload,
    };
}

export function getCommonClientWorkType(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST,
        payload,
    };
}

export function getSubdivisionsPage(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST,
        payload,
    };
}

export function getPositionsPage(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST,
        payload,
    };
}

export function getKedoRoleDict(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST,
        payload,
    };
}

export function getKedoDocumentStates(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST,
        payload,
    };
}

export function getKedoDocumentFileSourceTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST,
        payload,
    };
}

export function getKedoParticipantStates(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST,
        payload,
    };
}

export function getKedoDocumentHistoryEventTypes() {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST,
    };
}

export function getKedoOfficeTypes() {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST,
    };
}

export function getKedoSignaturesTypes() {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST,
    };
}

export function getKedoDocumentsCreatedViaTypes() {
    return {
        type:  BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST,
    };
}

export function getKedoSignatureTransactionsActionTypes() {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST,
    };
}

export function getKedoSignatureTransactionsDecideTypes() {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST,
    };
}

export function updateDictionaryFlashCall(payload) {
    return {
        type: BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST,
        payload,
    };
}

export function getTaskTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST,
        payload,
    };
}

export function getKedoExportsStateTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST,
        payload,
    };
}

export function getKedoExportsTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST,
        payload,
    };
}

export function getCustomDocumentTemplateLogEventTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST,
        payload,
    };
}

export function getClientNames(payload) {
    return {
        type: BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST,
        payload,
    };
}

export function getCustomDocumentTemplateNames(payload) {
    return {
        type: BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST,
        payload,
    };
}

export function getTemplateDocumentClientUsagesOrderTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST,
        payload,
    };
}

export function getTemplateDocumentClientUsagesLogTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST,
        payload,
    };
}

export function getClientBrandsByClientId(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST,
        payload,
    };
}

export function getClientsCategories(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST,
        payload,
    };
}

export function getClientUsersFio(payload) {
    return {
        type: BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST,
        payload,
    };
}

export function getContractorsApplicationTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST,
        payload,
    };
}

export function getKedoAllDocumentCodes(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST,
        payload,
    };
}

export function getKedoDepartmentNames(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST,
        payload,
    };
}

export function getDictionariesTaskStatuses(payload) {
    return {
        type: GET_DICTIONARIES_TASK_STATUSES_REQUEST,
        payload,
    };
}

export function getDictionariesTaskTypes(payload) {
    return {
        type: GET_DICTIONARIES_TASK_TYPES_REQUEST,
        payload,
    };
}

export function getDictionariesEducationForm(payload) {
    return {
        type: GET_DICTIONARIES_EDUCATION_FORM_REQUEST,
        payload,
    };
}

export function getDictionariesContractorLanguageLevels(payload) {
    return {
        type: GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST,
        payload,
    };
}

export function getKedoStaffFioPage(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST,
        payload,
    };
}

export function getKedoStaffPositionsPage(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST,
        payload,
    };
}

export function getKedoAllDocumentTypes(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST,
        payload,
    };
}

export function getKedoDocumentRoutePage(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST,
        payload,
    };
}

export function getKedoDepartmentLogTypes() {
    return {
        type: BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST,
    };
}

export function getKedoDepartmentLogMergeTypes() {
    return {
        type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST,
    };
}

export function getKedoUploadedDocumentTypesPage(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST,
        payload,
    };
}

export function getKedoTemplateDocumentTypesPage(payload) {
    return {
        type: BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST,
        payload,
    };
}