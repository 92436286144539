import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS,
    SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR,
    SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST,
    SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";

const controller = "/adm/contractors/contractors-registry/contractor-card/finances/balance";

const getPageContractorFinanceBalance = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR, payload: error});
    }
};

const getSummaryContractorFinanceBalance = function* ({payload} ) {
    try {
        const {
            contractorId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/get-summary/${contractorId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR, payload: error});
    }
};

const setContractorSmzTaxOffer = function* ({payload} ) {
    try {
        const {
            contractorId,
            smzTaxOffer,
            onSuccess,
        } = payload;

        const result = yield request.bff.put(`${controller}/set-smz-tax-offer?contractorId=${contractorId}&smzTaxOffer=${smzTaxOffer}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR, payload: error});
    }
};

const cancelContractorSmzTaxOffer = function* ({payload} ) {
    try {
        const {
            contractorId,
            onSuccess,
        } = payload;

        const result = yield request.bff.put(`${controller}/cancel-enabled-smz-tax-offer-next-month?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR, payload: error});
    }
};

const changeOpenWithdrawalMoneyOfWalletInBank = function* ({payload} ) {
    try {
        const {
            contractorId,
            onSuccess,
        } = payload;

        const result = yield request.bff.put(`${controller}/change-open-withdrawal-money-of-wallet-in-bank/${contractorId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR, payload: error});
    }
};

const changeContractorBlockedBankOperation = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/change-contractor-blocked-bank-operation`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST, getPageContractorFinanceBalance),
        takeEvery(GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST, getSummaryContractorFinanceBalance),
        takeEvery(SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST, setContractorSmzTaxOffer),
        takeEvery(CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST, cancelContractorSmzTaxOffer),
        takeEvery(CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST, changeOpenWithdrawalMoneyOfWalletInBank),
        takeEvery(CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST, changeContractorBlockedBankOperation),
    ]);
}