import {
    DIRECTORIES_BANKS_GET_SETTINGS_REQUEST,
    DIRECTORIES_BANKS_SET_SETTINGS_REQUEST,
    REFRESH_DIRECTORIES_SBP_BANKS_REQUEST,
    SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST,
} from "./actions";

export const getSettingsDirectoriesBanksPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST,
    payload,
});

export const getSettingsDirectoriesSbpBanksPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST,
    payload,
});

export const refreshSettingsDirectoriesSbpBanks = () => ({
    type: REFRESH_DIRECTORIES_SBP_BANKS_REQUEST,
});

export const updateSettingsDirectoriesBank = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST,
    payload,
});

export const getSettingsDirectoriesBank = (payload) => ({
    type: DIRECTORIES_BANKS_GET_SETTINGS_REQUEST,
    payload,
});

export const setSettingsDirectoriesBank = (payload) => ({
    type: DIRECTORIES_BANKS_SET_SETTINGS_REQUEST,
    payload,
});

export const getSettingsDirectoriesBanksLogsPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST,
    payload,
});

export const getSettingsDirectoriesBanksBalancesPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST,
    payload,
});

export const addSettingsDirectoriesBanksBalance = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST,
    payload,
});

export const editSettingsDirectoriesBanksBalance = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST,
    payload,
});

export const getNewSettingsDirectoriesBanksBalance = () => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST,
});

export const getSettingsDirectoriesBanksBalanceLogPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST,
    payload,
});

export const checkAccessSettingsDirectoriesBanksBalance = () => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST,
});

export const updateStoreSettingsDirectoriesBanks = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE,
    payload,
});