import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearStoreContractorNotificationLog,
    getPageContractorNotificationLog,
} from "../../../../../ducks/bff/contractor-сard/event-log/notification-log/actionCreators";
import {
    getContractorNotificationEventDict,
    updateFieldContractorNotificationLog,
} from "../../../../../ducks/contractorNotificationLog";

export function useFetchListNotificationLog({filter = {}, pageSize, pageNum, contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContractorNotificationEventDict());

        return () => {
            dispatch(clearStoreContractorNotificationLog());
            dispatch(updateFieldContractorNotificationLog({
                eventDict: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getPageContractorNotificationLog({
            pageNum,
            pageSize,
            contractorId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}