import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {
    ADD_FILE_TO_MODEL_ERROR,
    ADD_FILE_TO_MODEL_REQUEST,
    ADD_FILE_TO_MODEL_SUCCESS,
    ADD_ML_MODEL_ERROR,
    ADD_ML_MODEL_REQUEST,
    ADD_ML_MODEL_SUCCESS,
    DELETE_ML_MODEL_ERROR,
    DELETE_ML_MODEL_REQUEST,
    DELETE_ML_MODEL_SUCCESS,
    DELETE_MODEL_FILE_ERROR,
    DELETE_MODEL_FILE_REQUEST,
    DELETE_MODEL_FILE_SUCCESS,
    GET_ML_MODEL_FILE_LIST_ERROR,
    GET_ML_MODEL_FILE_LIST_REQUEST,
    GET_ML_MODEL_FILE_LIST_SUCCESS,
    GET_ML_MODEL_LIST_ERROR,
    GET_ML_MODEL_LIST_REQUEST,
    GET_ML_MODEL_LIST_SUCCESS,
    ML_MODELS_PREDICT_AMOUNT_ERROR,
    ML_MODELS_PREDICT_AMOUNT_REQUEST,
    ML_MODELS_PREDICT_AMOUNT_SUCCESS,
    SET_MAIN_MODEL_ERROR,
    SET_MAIN_MODEL_REQUEST,
    SET_MAIN_MODEL_SUCCESS,
    UPDATE_ML_MODEL_ERROR,
    UPDATE_ML_MODEL_REQUEST,
    UPDATE_ML_MODEL_SUCCESS,
    UPDATE_MODEL_FILE_ERROR,
    UPDATE_MODEL_FILE_REQUEST,
    UPDATE_MODEL_FILE_SUCCESS,
} from "./actions";

import {ACCESS_TOKEN_KEY, ls} from "../../../../utils/localstorage";
import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const controller = "/adm/settings/machine-learning";

const addFileToMlModelSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data: {
                description,
                modelId,
                name,
                file,
            },
        } = payload;

        const formData = new FormData();

        formData.append("file", file);

        const result = yield call(axios, {
            url: `/bff${controller}/files/add`,
            method: "post",
            params: {
                description,
                modelId,
                name,
            },
            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        },
        );

        const {
            data: {
                errorMessage,
            },
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_FILE_TO_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_FILE_TO_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: ADD_FILE_TO_MODEL_ERROR, payload: error});
    }
};

const deleteMLModelFileSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data: {
                fileId,
            },
        } = payload;

        const result = yield request.bff.post(`${controller}/files/delete?fileId=${fileId}`);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_MODEL_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_MODEL_FILE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: DELETE_MODEL_FILE_ERROR, payload: error});
    }
};

const deleteMlModelSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data: {
                modelId,
            },
        } = payload;

        const result = yield request.bff.post(`${controller}/delete?modelId=${modelId}`);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: DELETE_ML_MODEL_ERROR, payload: error});
    }
};

const setMainModelSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${controller}/main-model/set`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_MAIN_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SET_MAIN_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: SET_MAIN_MODEL_ERROR, payload: error});
    }
};

const updateModelFileSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${controller}/files/update`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_MODEL_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: UPDATE_MODEL_FILE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: UPDATE_MODEL_FILE_ERROR, payload: error});
    }
};

const updateMlModelSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${controller}/update`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: UPDATE_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: UPDATE_ML_MODEL_ERROR, payload: error});
    }
};

const addMlModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: ADD_ML_MODEL_ERROR, payload: error});
    }
};

const getMlModelListSaga = function* ({payload}) {
    try {

        const result = yield request.bff.post(`${controller}/page`, payload);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ML_MODEL_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ML_MODEL_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: GET_ML_MODEL_LIST_ERROR, payload: error});
    }
};


// POST /api/email/complaintEmail/send
const getMlModelFileListSaga = function* ({payload}) {

    try {
        const result = yield request.bff.post(`${controller}/files/list`, {params: payload});

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ML_MODEL_FILE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ML_MODEL_FILE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_ML_MODEL_FILE_LIST_ERROR,
            payload: error,
        });
    }
};

const predictMlModelsAmountSaga = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/orders/orders-registry/card/predict-amount",
                adminRolesUrl: `${controller}/predict-amount`,
            },
        );

        const {predictAmount} = yield request.bff.post(url, payload);

        yield put({
            type: ML_MODELS_PREDICT_AMOUNT_SUCCESS,
            payload: predictAmount,
        });
    } catch (error) {
        yield put({
            type: ML_MODELS_PREDICT_AMOUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ML_MODEL_LIST_REQUEST, getMlModelListSaga),
        takeEvery(GET_ML_MODEL_FILE_LIST_REQUEST, getMlModelFileListSaga),
        takeEvery(ADD_ML_MODEL_REQUEST, addMlModelSaga),
        takeEvery(UPDATE_ML_MODEL_REQUEST, updateMlModelSaga),
        takeEvery(DELETE_ML_MODEL_REQUEST, deleteMlModelSaga),
        takeEvery(ADD_FILE_TO_MODEL_REQUEST, addFileToMlModelSaga),
        takeEvery(UPDATE_MODEL_FILE_REQUEST, updateModelFileSaga),
        takeEvery(SET_MAIN_MODEL_REQUEST, setMainModelSaga),
        takeEvery(DELETE_MODEL_FILE_REQUEST, deleteMLModelFileSaga),
        takeEvery(ML_MODELS_PREDICT_AMOUNT_REQUEST, predictMlModelsAmountSaga),
    ]);
}