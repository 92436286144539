import {
    KEDO_DOCUMENT_ROUTE_ACTION_TYPE,
    KEDO_DOCUMENT_ROUTE_SING_TYPE,
} from "../../../../../../../constants/kedo/document-route";

export const KEDO_DOCUMENT_ROUTE_STEP_INITIAL_VALUES = {
    matchParticipantType: null,
    actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN,
    signType: null,
    kedoStaffId: null,
    kedoDepartmentId: null,
    departmentRole: null,
};

export const KEDO_DOCUMENT_ROUTE_STEP_ACTION_TYPE_DICT = {
    SIGN_PEP: {
        actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN,
        signType: KEDO_DOCUMENT_ROUTE_SING_TYPE.PEP,
    },
    APPROVE_PEP: {
        actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.APPROVE,
        signType: KEDO_DOCUMENT_ROUTE_SING_TYPE.PEP,
    },
    SIGN_UKEP: {
        actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN,
        signType: KEDO_DOCUMENT_ROUTE_SING_TYPE.UKEP,
    },
    SIGN_UNEP: {
        actionType: KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN,
        signType: KEDO_DOCUMENT_ROUTE_SING_TYPE.UNEP,
    },
};

export const KEDO_DOCUMENT_ROUTE_MAX_STEPS_COUNT = 20;