import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmButton from "../../../components/NmButton";
import NmIcon from "../../../components/NmIcon";
import NmPage from "../../../components/NmPage";
import TableDiv from "../../../components/TableDiv";
import BrigadeSearch from "../brigade-search";
import {
    Button,
} from "semantic-ui-react";

import dateFormat from "../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    getFormattedFullName,
    getFullName,
    phoneFormat,
} from "../../../utils/stringFormat";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {headersBrigadeList as headers} from "../../../constants/headersTable";
import {NM_OPERATOR} from "../../../constants/roles";

import {
    clearClientForemenForemanContractorsStore,
    deleteClientForemenForemanContractors,
    getClientForemenForemanContractorsRichPage,
} from "../../../ducks/bff/clients/foremen/foreman-contractors/actionCreators";
import {
    clientForemenForemanContractorsPageSelector,
    clientForemenForemanContractorsProgressActionsSelector,
    clientForemenForemanContractorsProgressSelector,
    clientForemenForemanContractorsTotalCountSelector,
    clientForemenForemanContractorsTotalPagesSelector,
} from "../../../ducks/bff/clients/foremen/foreman-contractors/selectors";
import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../ducks/clientUserRestrictions";

class BrigadeList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageSize: 25,
            pageNum: 1,
            isOpenSearchModal: false,
            rejectProgressId: "",
            action: {},
            isOpenConfirm: false,
        };

        this.role = ls(USER_ROLE);
        this.isAccessAction = ![NM_OPERATOR].includes(this.role);
    }

    componentDidMount() {
        const {
            getClientUserRestrictionsList,
        } = this.props;

        getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.setForemans],
        });
        
        this.fetchList();
    }

    componentWillUnmount() {
        const {clearClientForemenForemanContractorsStore} = this.props;

        clearClientForemenForemanContractorsStore();
    };

    get clientId() {
        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = this.props;

        return clientId;
    }

    fetchList = () => {
        const {getClientForemenForemanContractorsRichPage} = this.props;

        const {
            pageSize,
            pageNum,
        } = this.state;

        getClientForemenForemanContractorsRichPage({
            clientId: this.clientId,
            pageSize,
            pageNum,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    setRejectProgress(rejectProgressId = "") {
        this.setState({rejectProgressId});
    }

    reject(item) {
        const {deleteClientForemenForemanContractors} = this.props;
        const {foremanContractorId} = item;

        this.handleCancelConfirm();

        this.setRejectProgress(foremanContractorId);

        deleteClientForemenForemanContractors({
            clientId: this.clientId,
            foremanContractorId,
            handleResponse: this.handleResponse,
        });
    }

    handleResponse = (result) => {
        const {t} = this.props;
        const {errorMessage} = result;
        this.setRejectProgress();
        if (errorMessage) {
            toastError(errorMessage);

            return;
        }

        toastSuccess(t("brigade-list.contractor-removed-from-post-foreman"));
        this.fetchList();
    };

    renderAction(item) {
        if (!this.isAccessAction) {
            return null;
        }

        const {
            t,
            currentUserRestrictions,
        } = this.props;
        const {rejectProgressId} = this.state;
        const {foremanContractorId} = item;

        if (currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.setForemans)) {
            return null;
        }

        return (
            <Button
                loading={rejectProgressId === foremanContractorId}
                className="button-reject"
                onClick={() => {
                    this.showRejectConfirm(item);
                }}
            >
                {t("button.reject")}
            </Button>
        );
    }

    renderForemanInfo(item) {
        const {
            foremanContractorLastName,
            foremanContractorFirstName,
            foremanContractorPatronymic,
            blocked,
        } = item;

        return (
            <div className="flex flex-aligned-center">
                {
                    blocked &&
                    <NmIcon
                        className="mr-2"
                        name="lock"
                    />
                }
                <div>
                    {getFormattedFullName(getFullName(foremanContractorLastName, foremanContractorFirstName, foremanContractorPatronymic))}
                </div>
            </div>
        );
    }


    mapTableData() {
        const {list} = this.props;

        const rows = list.map((item, index) => {
            const {
                assignedByClientUserFirstName,
                assignedByClientUserLastName,
                assignedByClientUserPatronymic,
                assignmentDate,
                foremanContractorPhone,
            } = item;

            return {
                ...item,
                index: index + 1,
                phone: phoneFormat(foremanContractorPhone),
                coordinatorName: getFormattedFullName(getFullName(assignedByClientUserLastName, assignedByClientUserFirstName, assignedByClientUserPatronymic)),
                fullName: this.renderForemanInfo(item),
                datetime: dateFormat(assignmentDate, "dd.MM.yyyy"),
                action: this.renderAction(item),
            };
        });

        return {
            rows,
            headers,
        };
    }

    openSearchModal = () => {
        this.setState({isOpenSearchModal: true});
    };

    closeSearchModal = () => {
        this.setState({isOpenSearchModal: false}, this.fetchList);
    };

    renderControls() {
        if (!this.isAccessAction) {
            return null;
        }

        const {
            t,
            client: {
                archived: isClientArchived,
            },
            currentUserRestrictions,
        } = this.props;

        if (
            isClientArchived
            || currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.setForemans)
        ) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                onClick={this.openSearchModal}
            >
                {t("brigade-list.appoint-foreman")}
            </NmButton>
        );
    }

    showRejectConfirm(item) {
        const {t} = this.props;
        this.setState({
            isOpenConfirm: true,
            action: {
                onConfirm: () => {
                    this.reject(item);
                },
                textConfirm: t("brigade-list.confirm-contractor-removed-from-post-foreman"),
            },
        });
    }

    handleCancelConfirm = () => {
        this.setState({action: {}, isOpenConfirm: false});
    };

    renderConfirmWindow() {
        const {t} = this.props;
        const {
            isOpenConfirm,
            action: {
                textConfirm,
                onConfirm,
            },
        } = this.state;

        return (
            isOpenConfirm &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={onConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                content={textConfirm}
            />
        );
    }

    render() {
        const {
            isOpenSearchModal,
            pageNum,
            pageSize,
        } = this.state;

        const {
            totalPages,
            totalCount,
            progress,
            t,
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return (
            <NmPage
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                isLoaded={progress}
                controls={
                    totalCount &&
                    this.renderControls()
                }
                className="container-app"
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                {this.renderConfirmWindow()}
                {
                    isOpenSearchModal &&
                    <BrigadeSearch
                        clientId={this.clientId}
                        handleClose={this.closeSearchModal}
                    />
                }
                {
                    !(totalCount || progress) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction={!isClientArchived && this.isAccessAction && t("brigade-list.appoint-foreman")}
                            onClickAction={!isClientArchived && this.isAccessAction && this.openSearchModal}
                        /> :
                        <TableDiv
                            tableData={this.mapTableData()}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: clientForemenForemanContractorsPageSelector(state),
        totalPages: clientForemenForemanContractorsTotalPagesSelector(state),
        totalCount: clientForemenForemanContractorsTotalCountSelector(state),
        progress: clientForemenForemanContractorsProgressSelector(state),
        progressAction: clientForemenForemanContractorsProgressActionsSelector(state),
        client: clientCardInfoSelector(state),
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        getClientForemenForemanContractorsRichPage,
        clearClientForemenForemanContractorsStore,
        deleteClientForemenForemanContractors,
        getClientUserRestrictionsList,
    },
)(withTranslation()(BrigadeList));
