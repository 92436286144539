import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_ERROR,
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST,
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_SUCCESS,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_ERROR,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {LINK_FINANCE_CANCELED_CHECKS} from "../../../../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [LINK_FINANCE_CANCELED_CHECKS]: "/adm/finances/receipts/cancelled",
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/canceled-receipt",
            adminRolesUrl: "/adm/clients/client-card/finances/canceled-receipt",
        })
    );
};


// POST /adm/finances/canceled-receipts/page
// POST /bff/adm/clients/client-card/finances/canceled-receipt/page
// POST /bff/client-adm/finances/canceled-receipt/page
// Получение списка аннулированных платежей
const getPageFinancesReceiptsCancelled = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_ERROR,
        });
    }
};

// POST /adm/finances/canceled-receipts/cancelled-payment-export
// POST /bff/adm/clients/client-card/finances/canceled-receipt/cancelled-payment-export
// POST /bff/client-adm/finances/canceled-receipt/cancelled-payment-export
// Выгрузка аннулированных чеков
const exportFinancesReceiptsCancelled = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/cancelled-payment-export`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST, getPageFinancesReceiptsCancelled),
        takeEvery(BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST, exportFinancesReceiptsCancelled),
    ]);
}