import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import FilterObject from "../../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../../components/ActualComponents/FilterProject";
import FilterSpecialities from "../../../../../components/ActualComponents/FilterSpecialities";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {handleFormString} from "../../../../../utils/stringHelper";

import {CROWD_CHAT_STATUS_FILTER_TYPE_DICT} from "../../../../../constants/crowd/chats";

import {objectsOptimizedSelector} from "../../../../../ducks/objects";
import {getSearchOrderList, orderListOptionsSelector, orderListSelector} from "../../../../../ducks/order";

const ChatsFilter = props => {
    const {
        clientId,
        submitFilter,
        clearFilter,
        onChange,
        isFiltersReset,
        filter,
        isCrowd,
    } = props;

    const orderOptions = useSelector(orderListOptionsSelector);
    const orderList = useSelector(orderListSelector);
    const objectList = useSelector(objectsOptimizedSelector);

    const [orderQueryFilter, setOrderQueryFilter] = useState("");

    const dispatch = useDispatch();

    const {
        contractorNameFilter,
        projectIdFilter,
        objectIdFilter,
        orderIdFilter,
        contractorPhone,
        specialityIds,
        messageStatus,
        currentUserIsOrderManager,
    } = filter;

    useEffect(() => {
        fetchOrders();
    }, [orderQueryFilter, objectIdFilter]);

    useEffect(() => {
        if (objectIdFilter && projectIdFilter && objectList.find(item => (item.objectId === objectIdFilter))?.projectId !== projectIdFilter) {
            onChange(null, {name: "objectIdFilter", value: null});
        }
    }, [projectIdFilter, objectIdFilter]);

    useEffect(() => {
        const order = orderList.find(item => (item.orderId === orderIdFilter)) || {};

        if (objectIdFilter && orderIdFilter && order.objectId !== objectIdFilter) {
            onChange(null, {name: "orderIdFilter", value: null});
        }
    }, [projectIdFilter, objectIdFilter, orderIdFilter]);

    const fetchOrders = () => {
        dispatch(getSearchOrderList({
            clientIdFilter: clientId,
            searchFilter: handleFormString(orderQueryFilter),
            objectIdFilter,
            pageNum: 1,
            pageSize: 300,
        }));
    };

    const onSearchClientChangeFilter = (value) => {
        setOrderQueryFilter(value);
    };

    const getOtherFields = () => {
        if (isCrowd) {
            return (
                <>
                    <NmInputV2
                        size="lg"
                        mask="+7 (999) 999 99 99"
                        label="Номер телефона"
                        name="contractorPhone"
                        value={contractorPhone}
                        placeholder="Телефон"
                        onChange={onChange}
                    />
                    <FilterSpecialities
                        label="Вид деятельности исполнителя"
                        name="specialityIds"
                        onChange={onChange}
                        value={specialityIds}
                        multiple={true}
                    />
                    <NmDropdownV2
                        name="messageStatus"
                        options={dictionaryToOptions(CROWD_CHAT_STATUS_FILTER_TYPE_DICT)}
                        onChange={onChange}
                        value={messageStatus}
                        label="Статус"
                        placeholder="Все"
                        size="lg"
                    />
                </>
            );
        }

        return (
            <>
                <FilterProject
                    clientId={clientId}
                    onChange={onChange}
                    projectIdsFilter={projectIdFilter}
                    name="projectIdFilter"
                    placeholder="Показать все"
                    multiple={false}
                    isResetValueFilter={isFiltersReset}
                    isClearable
                />
                <FilterObject
                    clientId={clientId}
                    name="objectIdFilter"
                    onChange={onChange}
                    projectIdsFilter={projectIdFilter ? [projectIdFilter] : []}
                    objectIdsFilter={objectIdFilter}
                    placeholder="Показать все"
                    multiple={false}
                    isResetValueFilter={isFiltersReset}
                    pageSize={50}
                    isClearable
                />
                <NmDropdownV2
                    search
                    searchQuery={orderQueryFilter}
                    onSearchChange={onSearchClientChangeFilter}
                    name="orderIdFilter"
                    options={orderOptions}
                    onChange={onChange}
                    value={orderIdFilter || ""}
                    label="Заказ"
                    placeholder="Название или номер заказа"
                    size="lg"
                    isClearable
                />
                <NmCheckboxV2
                    name="currentUserIsOrderManager"
                    onChange={onChange}
                    checked={currentUserIsOrderManager}
                    label="Чаты, где я ответственный"
                />
            </>
        );
    };

    return (
        <NmForm addMargin>
            <NmInputV2
                name="contractorNameFilter"
                onChange={onChange}
                value={contractorNameFilter || ""}
                label="ФИО исполнителя"
                placeholder="Введите ФИО исполнителя"
                size="lg"
                maxLength={100}
            />
            {getOtherFields()}
            <FilterButtonsV2
                onSearch={() => submitFilter(filter)}
                onClear={clearFilter}
            />
        </NmForm>
    );
};

export default ChatsFilter;