import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ImportFromFilePatternV2 from "../../../../../components/ActualComponents/ImportFromFilePatternV2";

import {importKedoDepartments} from "../../../../../ducks/kedo/departments/actionCreators";
import {kedoDepartmentsProgressActionSelector} from "../../../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructureAddFromExcel = (props) => {
    const {
        clientId,
        isFetchList,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(kedoDepartmentsProgressActionSelector);

    function onSubmit({file}) {
        const formData = new FormData();

        formData.append("file", file);

        dispatch(importKedoDepartments({
            isFetchList,
            clientId,
            file: formData,
        }));
    }

    return (
        <ImportFromFilePatternV2
            headerTitle="Добавление организационной структуры"
            maxSize={10}
            textTooltip="Чтобы распределить сотрудников по отделам, сначала добавьте их в КЭДО"
            patternLink="/files/Шаблон_Орг_структура.xlsx"
            onClose={onClose}
            onSubmit={onSubmit}
            progress={progress}
        />
    );
};