import React from "react";
import {useSelector} from "react-redux";

import DocumentList from "../document-list";

import {
    getDocumentListSelector,
    getDocumentProgressListSelector,
    getDocumentTotalCountSelector,
    getDocumentTotalPagesSelector,
} from "../../../ducks/documents";

const ClientDocumentList = (props) => {
    const list = useSelector(getDocumentListSelector);
    const totalCount = useSelector(getDocumentTotalCountSelector);
    const totalPages = useSelector(getDocumentTotalPagesSelector);
    const progress = useSelector(getDocumentProgressListSelector);

    return (
        <DocumentList
            documentList={list}
            progressList={progress}
            totalCount={totalCount}
            totalPages={totalPages}
            {...props}
        />
    );
};

export default ClientDocumentList;