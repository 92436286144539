import {
    ADD_DIRECTORIES_STATEMENTS_REQUEST,
    CLEAR_FIELD_DIRECTORIES_STATEMENTS,
    DELETE_DIRECTORIES_STATEMENTS_REQUEST,
    GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST,
    UPDATE_DIRECTORIES_STATEMENTS_REQUEST,
} from "./actions";

export function getDirectoriesStatementsPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST,
        payload,
    };
}

export function updateDirectoriesStatements(payload) {
    return {
        type: UPDATE_DIRECTORIES_STATEMENTS_REQUEST,
        payload,
    };
}

export function addDirectoriesStatements(payload) {
    return {
        type: ADD_DIRECTORIES_STATEMENTS_REQUEST,
        payload,
    };
}

export function deleteDirectoriesStatements(payload) {
    return {
        type: DELETE_DIRECTORIES_STATEMENTS_REQUEST,
        payload,
    };
}

export function clearFieldsDirectoriesStatements() {
    return {
        type: CLEAR_FIELD_DIRECTORIES_STATEMENTS,
    };
}