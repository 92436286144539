import React from "react";

import Avatar from "../../Avatar";
import ButtonBack from "../../ButtonBack";
import MediaQueriesRenderer from "../MediaQueriesRenderer";
import Text from "../Text";

import {COLOR} from "../../../constants/color";

import {history} from "../../../store/configureStore";

import "./style.sass";

export const NmPageCardHeader = props => {
    const {
        to,
        content,
        statusDict,
        status = "",
        statusLoading,
        statusBadge,
        mediaQueries,
        controls,
        controlsByMediaQueries,
        isAvatar,
        subTitle,
    } = props;

    const handleOnClickBack = () => {
        if (props.handleOnClickBack) {
            props.handleOnClickBack();

            return;
        }

        history.push(to);
    };

    const renderStatus = () => {
        if (statusBadge) {
            return (
                <div className="nm-page-card-header__status">
                    {statusBadge}
                </div>
            );
        }

        if (!status) {
            return;
        }

        if (typeof status === "string") {
            return (
                <div
                    className="nm-page-card-header__status nm-page-card-header__status_theme"
                    style={{
                        border: statusDict[status].BORDER,
                        color: statusDict[status].COLOR,
                        background: statusDict[status].BACKGROUND,
                    }}
                >
                    {statusDict[status].TEXT}
                </div>
            );
        }

        return status;
    };

    const getTitle = () => {
        const title = (
            <Text.Title
                level="1"
                className="nm-page-card-header__title"
            >
                {content}
            </Text.Title>
        );
        
        if (subTitle) {
            return (
                <div>
                    {title}
                    <Text
                        color={COLOR.SECONDARY_70}
                        level="3"
                    >
                        {subTitle}
                    </Text>
                </div>
            );
        }

        return title;
    };

    return (
        <div className="nm-page-card-header">
            <div className="nm-page-card-header__main-content">
                {
                    (to || props.handleOnClickBack) &&
                    <ButtonBack
                        className="nm-page-card-header__back"
                        onClick={handleOnClickBack}
                    />
                }
                {
                    isAvatar &&
                    <Avatar
                        size="xl"
                        className="ms-1 me-4"
                    />
                }
                {getTitle()}
                {renderStatus()}
                {statusLoading}
            </div>
            <div className="nm-page-card-header__controls">
                {
                    controlsByMediaQueries ?
                        <MediaQueriesRenderer
                            config={controlsByMediaQueries}
                            mediaQueries={mediaQueries}
                        /> :
                        controls
                }
            </div>
        </div>
    );
};
