import React, {FC, ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Media, {MediaQueryObject} from "react-media";

import {ReactComponent as FilterIcon} from "../../images/filter_outline_24.svg";
import ContextMenuMobile from "../ActualComponents/ContextMenuMobile";
import {MediaButtons} from "../ActualComponents/MediaControls";
import MediaQueriesRenderer, {TMediaQueriesRendererConfig} from "../ActualComponents/MediaQueriesRenderer";
import NmEmptyPageV2, {TNmEmptyPageV2} from "../ActualComponents/NmEmptyPageV2";
import NmPagination from "../ActualComponents/NmPagination";
import NmButton from "../NmButton";
import NmHint from "../NmHint";
import NmPageVerticalFilter from "./components/VerticalFilter";
import {Dimmer, Loader} from "semantic-ui-react";

import {OptionType} from "../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../utils/bem";

import {COMPONENT} from "../ActualComponents/MediaControls/constants";

import {TMediaButtonsConfig} from "../ActualComponents/MediaControls/types";

import "./style.sass";

interface INmPage {
    header?: ReactNode,
    controls?: ReactNode,
    filtersBase?: ReactNode,
    filtersAdditional?: ReactNode,
    filterClassName?: string,
    headerClassName?: string,
    isLoaded?: boolean,
    pageSizes?: [number, number, number],
    currentPageSize?: number,
    currentPageNum?: number,
    totalPages?: number,
    classNameContentContainer?: string,
    typeFilter?: "horizontal" | "vertical",
    className?: string,
    classNameContent?: string,
    controlsClassName?: string,
    totalCount?: number,
    onChangePageSize?: () => void,
    onPaginationChange?: () => void,
    children: ReactNode,
    showHeaderBlock?: boolean,
    widthByFilter?: boolean,
    overflowUnset?: boolean,
    openFilter?: boolean,
    onOpenFilter?: (open: boolean) => void,
    subHeader?: ReactNode,
    titleClassName?: string,
    noPadding?: boolean,
    contextMenu?: ReactNode,
    textLoading?: string,
    contextMenuOptions?: { [name: string]: OptionType[] },
    mediaQueries?: { [id: string]: MediaQueryObject },
    onClickContextMenuItem?: (option: OptionType) => void,
    isAutoPositionForFilterButton?: boolean,
    isHasPageSize?: boolean,
    mediaControls?: TMediaButtonsConfig,
    controlsByMediaQueries?: TMediaQueriesRendererConfig,
    paginationClassName?: string,
    paginationPaddingBottom?: boolean,
    heightUnset?: boolean,
    isHideHeader?: boolean,
    isRelative?: boolean,
    isVisiblePagination?: boolean,
    // для сохранения фильтров (дропдауны с постраничным поиском) в state фильтра при переоткрытииф фильтра,
    // при значении true, фильтр при закрытии полностью убирается из dom,
    // при значениее false, фильтр остается в dom, убирается путем css, задания display: none
    hardVisibleFilter?: boolean,
    titleHiddenInMobile?: boolean,
    titleOverflowVisible?: boolean,
    isVisibleFilterButton?: boolean,
    isEmptyPage?: boolean,
    emptyPageProps?: TNmEmptyPageV2,
    titleHidden?: boolean,
}

export const FILTER_OPTION = {
    key: "FILTER",
    value: "FILTER",
    text: "Фильтр",
};

const NmPage: FC<INmPage> = (props) => {
    const {
        header,
        controls,
        filtersBase,
        filtersAdditional,
        filterClassName,
        headerClassName = "",
        isLoaded = false,
        pageSizes = [25, 50, 100],
        currentPageSize,
        currentPageNum,
        totalPages,
        classNameContentContainer = "",
        typeFilter = "horizontal",
        classNameContent = "",
        controlsClassName = "",
        className,
        onChangePageSize,
        onPaginationChange,
        totalCount = 0,
        children,
        showHeaderBlock = true,
        widthByFilter = false,
        overflowUnset = false,
        onOpenFilter,
        titleClassName = "",
        subHeader,
        noPadding,
        contextMenu,
        contextMenuOptions,
        mediaQueries,
        onClickContextMenuItem,
        mediaControls,
        textLoading,
        controlsByMediaQueries,
        isAutoPositionForFilterButton = true,
        isHasPageSize,
        paginationClassName = "",
        paginationPaddingBottom = false,
        heightUnset = false,
        isHideHeader = false,
        isRelative,
        isVisiblePagination = true,
        hardVisibleFilter = true,
        isVisibleFilterButton = true,
        titleHiddenInMobile = true,
        titleHidden,
        titleOverflowVisible,
        isEmptyPage,
    } = props;

    const [openFilter, setOpenFilter] = useState<boolean>(props.openFilter || false);

    const [block, element] = bem("nm-page", className);

    const {t} = useTranslation();

    const isHeaderShown = Boolean(showHeaderBlock && (header || controls || typeFilter));
    const isSubHeaderShown = Boolean(subHeader);
    const isControlsOnly = Boolean((controls || mediaControls) && !header);
    const isVerticalFilter = typeFilter === "vertical";

    useEffect(() => {
        isVerticalFilter && typeof props.openFilter === "boolean" && setOpenFilter(props.openFilter);
    }, [props.openFilter]);

    const toggleFilter = () => {
        if (onOpenFilter) {
            onOpenFilter(!openFilter);
        }

        setOpenFilter(!openFilter);
    };

    const onClickContextMenu = (option: OptionType) => {
        const {value: action} = option;

        switch (action) {
            case "FILTER":
                toggleFilter();

                return;
            default:
                onClickContextMenuItem &&
                onClickContextMenuItem(option);
        }
    };

    const getFilterButton = () => {
        if (!isVerticalFilter) {
            return null;
        }

        const button = (
            <NmButton
                className={element("toggle-filter")}
                onlyIcon
                color="grey"
                icon={<FilterIcon />}
                size="xl"
                onClick={toggleFilter}
            />
        );

        // Если нет, значит фильтр не убирается в контекстное меню, отображаем всегда
        if (!contextMenuOptions && !contextMenu) {
            return button;
        }

        return (
            <Media query={{minWidth: 768}}>
                {button}
            </Media>
        );
    };

    const getHeader = () => {
        if (isHideHeader) {
            return null;
        }

        return (
            <>
                <header className={`${element("header", {"controls-only": isControlsOnly})} ${headerClassName}`}>
                    {
                        header &&
                        <div
                            className={`${element("title", {
                                hidden: titleHidden,
                                mobileHidden: titleHiddenInMobile,
                                "overflow-visible": titleOverflowVisible,
                            })} ${titleClassName}`}
                        >
                            {header}
                        </div>
                    }
                    {
                        (controls || controlsByMediaQueries || mediaControls || isVerticalFilter) &&
                        <div className={`${element("controls")} ${controlsClassName}`}>
                            {controls}
                            {
                                mediaControls &&
                                <MediaButtons
                                    config={{
                                        ...mediaControls,
                                        size: "xl",
                                        buttons: [
                                            ...mediaControls.buttons,
                                            {
                                                component: COMPONENT.BUTTON,
                                                props: {
                                                    onClick: toggleFilter,
                                                    onlyIcon: true,
                                                    color: "grey",
                                                    icon: <FilterIcon />,
                                                },
                                                settings: {
                                                    filter: isAutoPositionForFilterButton,
                                                },
                                                visible: isVerticalFilter && isVisibleFilterButton,
                                                asset: {
                                                    mobile: {
                                                        children: openFilter ? "Закрыть фильтр" : "Открыть фильтр",
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                />
                            }
                            {
                                !mediaQueries &&
                                !mediaControls &&
                                isVisibleFilterButton &&
                                getFilterButton()
                            }
                            {
                                mediaQueries && contextMenuOptions &&
                                <>
                                    {
                                        controlsByMediaQueries &&
                                        <MediaQueriesRenderer
                                            config={controlsByMediaQueries}
                                            mediaQueries={mediaQueries}
                                        />
                                    }
                                    {filtersBase && getFilterButton()}
                                    {
                                        contextMenuOptions &&
                                        <ContextMenuMobile
                                            size="xl"
                                            className={element("button-dropdown")}
                                            mediaQueries={mediaQueries}
                                            inline={false}
                                            configOptions={contextMenuOptions}
                                            onClickItem={onClickContextMenu}
                                        />
                                    }
                                </>
                            }
                            {
                                contextMenu &&
                                <div className={element("button-dropdown")}>
                                    {contextMenu}
                                </div>
                            }
                        </div>
                    }
                </header>
                {
                    isSubHeaderShown &&
                    <div className={element("sub-header")}>
                        {subHeader}
                    </div>
                }
            </>
        );
    };

    const getFilter = () => {
        if (!filtersBase || !isVerticalFilter) {
            return null;
        }

        if (hardVisibleFilter) {
            if (openFilter) {
                return (
                    <NmPageVerticalFilter
                        open={openFilter}
                        className={filterClassName}
                        filtersBase={filtersBase}
                        toggleFilter={toggleFilter}
                    />
                );
            }

            return null;
        }

        return (
            <NmPageVerticalFilter
                open={openFilter}
                hardVisibleFilter={hardVisibleFilter}
                className={filterClassName}
                filtersBase={filtersBase}
                toggleFilter={toggleFilter}
            />
        );
    };

    const getEmptyPage = () => {
        const {
            emptyPageProps = {},
        } = props;

        return <NmEmptyPageV2 {...emptyPageProps} />;
    };

    const getContent = () => {
        if (isEmptyPage) {
            return getEmptyPage();
        }

        return children;
    };

    return (
        <div
            className={block({
                overflowUnset,
                noPadding,
                heightUnset,
                relative: isRelative,
            })}
        >
            {
                isHeaderShown &&
                !isVerticalFilter &&
                getHeader()
            }
            {
                filtersBase &&
                !isVerticalFilter &&
                <div className={`${element("filters-base")} ${filterClassName}`}>
                    {filtersBase}
                </div>
            }
            {
                filtersAdditional &&
                <NmHint
                    className={element("filters-additional")}
                    closeButtonText={`${t("contractor-list.collapse")} ${t("contractor-list.filter")}`}
                    openButtonText={`${t("contractor-list.expand")} ${t("contractor-list.filter")}`}
                >
                    {filtersAdditional}
                </NmHint>
            }
            {
                isLoaded &&
                <Dimmer
                    active
                    inverted
                >
                    <Loader content={textLoading || t("loader.content")} />
                </Dimmer>
            }
            <div className={element("all-content", {"right-filter": isVerticalFilter})}>
                <div className={element("content-container", {"width-by-filter": widthByFilter && openFilter})}>
                    {
                        isVerticalFilter &&
                        getHeader()
                    }
                    <div className={`${element("content")} ${classNameContent}`}>
                        {getContent()}
                    </div>
                    {
                        isVisiblePagination &&
                        <NmPagination
                            pageSizes={pageSizes}
                            className={`${element("footer", {paddingBottom: paginationPaddingBottom})} ${paginationClassName}`}
                            totalCount={totalCount}
                            pageNum={currentPageNum}
                            totalPages={totalPages}
                            pageSize={currentPageSize}
                            isHasPageSize={isHasPageSize}
                            onChangePageSize={onChangePageSize}
                            onChangePagination={onPaginationChange}
                        />
                    }
                </div>
                {getFilter()}
            </div>

        </div>
    );
};

export default NmPage;