import React from "react";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDivider from "../../../../components/ActualComponents/NmDivider";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import Text from "../../../../components/ActualComponents/Text";
import NmButton from "../../../../components/NmButton";
import NmPageCard from "../../../../components/NmPageCard";

import {useCrmSettingsAction} from "./hooks/useAction";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../constants/color";
import {CRM_SYSTEM_SETTINGS} from "../../../../constants/crm/ticket";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";
import {crmSettingsProgressSelector} from "../../../../ducks/crmSettings";

export default function TicketSettings() {
    const progress = useSelector(crmSettingsProgressSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const accessActivateSystemTickets = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.activateSystemTickets);
    const accessEditParams = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editParamSystemTickets);

    const {
        onChangeSystemTickets,
        values,
        setFieldValue,
        onSubmit,
        isChanges,
    } = useCrmSettingsAction({});

    return (
        <NmPageCard
            header="Настройки CRM"
        >
            <div>
                <NmHorizontalToggleV2
                    noWrapLabel
                    rightLabel="Системные тикеты"
                    single
                    onChange={onChangeSystemTickets}
                    checked={values.systemTicketsEnabled}
                    duplex={false}
                    disabled={!accessEditParams}
                />
            </div>
            <NmDivider className="mt-4 mb-4" />
            <NmForm addMargin={true}>
                <Text
                    type="title"
                    level="4"
                    color={COLOR.SECONDARY_75}
                >
                    Создавать тикеты по событиям:
                </Text>
                {
                    CRM_SYSTEM_SETTINGS.map(({key, text}) => {
                        return (
                            <NmCheckboxV2
                                key={key}
                                disabled={
                                    !values.systemTicketsEnabled
                                    || !accessActivateSystemTickets
                                    || !accessEditParams
                                }
                                name={key}
                                onChange={() => {
                                    setFieldValue(key, !values[key]);
                                }}
                                checked={values[key]}
                                label={text}
                            />
                        );
                    })
                }
                <NmButton
                    size="xl"
                    color="green"
                    disabled={
                        progress
                        || !isChanges
                        || !values.systemTicketsEnabled
                        || !accessActivateSystemTickets
                        || !accessEditParams
                    }
                    onClick={onSubmit}
                    children="Сохранить настройки"
                />
            </NmForm>
        </NmPageCard>
    );
}