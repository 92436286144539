import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    REVIEWS_CONTRACTOR_ADD_ERROR,
    REVIEWS_CONTRACTOR_ADD_REQUEST,
    REVIEWS_CONTRACTOR_ADD_SUCCESS,
    REVIEWS_CONTRACTOR_CONFIRM_ERROR,
    REVIEWS_CONTRACTOR_CONFIRM_REQUEST,
    REVIEWS_CONTRACTOR_CONFIRM_SUCCESS,
    REVIEWS_CONTRACTOR_DECLINE_ERROR,
    REVIEWS_CONTRACTOR_DECLINE_REQUEST,
    REVIEWS_CONTRACTOR_DECLINE_SUCCESS,
    REVIEWS_CONTRACTOR_DELETE_ERROR,
    REVIEWS_CONTRACTOR_DELETE_REQUEST,
    REVIEWS_CONTRACTOR_DELETE_SUCCESS,
    REVIEWS_CONTRACTOR_GET_COUNT_ERROR,
    REVIEWS_CONTRACTOR_GET_COUNT_REQUEST,
    REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS,
    REVIEWS_CONTRACTOR_GET_PAGE_ERROR,
    REVIEWS_CONTRACTOR_GET_PAGE_REQUEST,
    REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS,
    REVIEWS_CONTRACTOR_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {LINK_REVIEWS_LIST} from "../../../../constants/links";

import {SUBPAGE_CONTRACTORS_REVIEWS} from "../../../../constants/link-params";

const adminPageLink = LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CONTRACTORS_REVIEWS);
const adminPageController = "/adm/review/review-on-contractor";

const getController = () => {
    return (
        getBffUrl({
            [adminPageLink]: adminPageController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/contractors/contractor-card/reviews",
            adminRolesUrl: "/adm/contractors/contractors-registry/contractor-card/reviews",
        }, adminPageController)
    );
};

const getPageReviewsContractorSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_GET_PAGE_ERROR,
            payload: error,
        });
    }
};


const getCountReviewsContractorSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${adminPageController}/count`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_GET_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_GET_COUNT_ERROR,
            payload: error,
        });
    }
};

const confirmReviewsContractorSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/confirm/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_CONFIRM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_CONFIRM_ERROR,
            payload: error,
        });
    }
};

const declineReviewsContractorSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/decline/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_DECLINE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_DECLINE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_DECLINE_ERROR,
            payload: error,
        });
    }
};

const deleteReviewsContractorSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_DELETE_ERROR,
            payload: error,
        });
    }
};

const addReviewsContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_ADD_ERROR,
            payload: error,
        });
    }
};

const updateReviewsContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_UPDATE_ERROR,
            payload: error,
        });
    }
};

const updateStatusReviewsContractorReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${reviewId}/reply/change-status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR,
            payload: error,
        });
    }
};

const updateReviewsContractorReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${reviewId}/reply/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REVIEWS_CONTRACTOR_GET_PAGE_REQUEST, getPageReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_GET_COUNT_REQUEST, getCountReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_CONFIRM_REQUEST, confirmReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_DECLINE_REQUEST, declineReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_DELETE_REQUEST, deleteReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_ADD_REQUEST, addReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_UPDATE_REQUEST, updateReviewsContractorSaga),
        takeEvery(REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST, updateStatusReviewsContractorReplySaga),
        takeEvery(REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST, updateReviewsContractorReplySaga),
    ]);
}