import incomeForPeriod from "../containers/fns-service/send-modal/src/incomeForPeriod";
import innByPersonalInfo from "../containers/fns-service/send-modal/src/innByPersonalInfo";
import newlyUnboundTaxpayers from "../containers/fns-service/send-modal/src/newlyUnboundTaxpayers";
import partnerStatus from "../containers/fns-service/send-modal/src/partnerStatus";
import partnerWithInn from "../containers/fns-service/send-modal/src/partnerWithInn";
import partnerWithPhone from "../containers/fns-service/send-modal/src/partnerWithPhone";

const controller = "/adm/settings/tax-authorities";

export const FNS_METHOD_LIST = [
    {
        title: "Запрос на привязку НП НПД к платформе-партнеру по ИНН",
        method: `${controller}/bind-partner-with-inn`,
        data: partnerWithInn,
    },
    {
        title: "Запрос на привязку НП НПД к платформе-партнеру по номеру телефона",
        method: `${controller}/bind-partner-with-phone`,
        data: partnerWithPhone,
    },
    {
        title: "Получение информации о неоплаченных налоговых начислениях",
        method: `${controller}/get-accruals-and-debts`,
        data: partnerWithInn,
    },
    {
        title: "Получение статуса заявки на привязку НП НПД к платформе-партнеру",
        method: `${controller}/get-bind-partner-status`,
        data: partnerStatus,
    },
    {
        title: "Получение списка разрешений, предоставленных партнеру",
        method: `${controller}/get-granted-permissions`,
        data: partnerWithInn,
    },
    {
        title: "Получение статуса заявки на изменение прав НП НПД",
        method: `${controller}/get-granted-permissions-status`,
        data: partnerStatus,
    },
    {
        title: "Получение информации по доходу НП НПД за период",
        method: `${controller}/get-income-for-period`,
        data: incomeForPeriod,
    },
    {
        title: "Получение ИНН по персональным данным",
        method: `${controller}/get-inn-by-personal-info`,
        data: innByPersonalInfo,
        typeMethod: "post",
    },
    {
        title: "Получение списка вновь отвязанных от партнера НП НПД",
        method: `${controller}/get-newly-unbound-taxpayers`,
        typeMethod: "post",
        data: newlyUnboundTaxpayers,
    },
    {
        title: "Получение платежных документов на оплату налоговых начислений, задолженности и пеней",
        method: `${controller}/get-payment-documents`,
        data: partnerWithInn,
    },
    {
        title: "Получение состояния лицевого счета",
        method: `${controller}/get-taxpayer-account-status`,
        data: partnerWithInn,
    },
    {
        title: "Получение детального статуса НП НПД",
        method: `${controller}/get-taxpayer-status`,
        data: partnerWithInn,
    },
    {
        title: "Запрос на изменение набора прав, выданных НП НПД банку/платформе-партнеру",
        method: `${controller}/granted-permissions`,
        typeMethod: "post",
        data: partnerWithInn,
    },
];

export const FNS_METHOD_LIST_NM_MANAGER = [
    {
        title: "Получение списка вновь отвязанных от партнера НП НПД",
        method: `${controller}/get-newly-unbound-taxpayers`,
        typeMethod: "post",
        data: newlyUnboundTaxpayers,
    },
    {
        title: "Получение списка разрешений, предоставленных партнеру",
        method: `${controller}/get-granted-permissions`,
        data: partnerWithInn,
    },
    {
        title: "Получение детального статуса НП НПД",
        method: `${controller}/get-taxpayer-status`,
        data: partnerWithInn,
    },
    {
        title: "Получение платежных документов на оплату налоговых начислений, задолженности и пеней",
        method: `${controller}/get-payment-documents`,
        data: partnerWithInn,
    },
    {
        title: "Получение состояния лицевого счета",
        method: `${controller}/get-taxpayer-account-status`,
        data: partnerWithInn,
    },
    {
        title: "Получение информации о неоплаченных налоговых начислениях",
        method: `${controller}/get-accruals-and-debts`,
        data: partnerWithInn,
    },
    {
        title: "Получение ИНН по персональным данным",
        method: `${controller}/get-inn-by-personal-info`,
        data: innByPersonalInfo,
        typeMethod: "post",
    },
];

