import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getContractorList} from "./contractor";
import {getPageOrderContractors} from "./order";

import request from "../utils/postman";
import {toastError, toastSuccess, toastWarning} from "../utils/toastHelper";

import {STAFF} from "../constants/messages";

const controller = "/ordercontractors";
//*  TYPES  *//

const ORDER_CONTRACTOR_LIST_REQUEST = "ORDER_CONTRACTOR_LIST_REQUEST";
const ORDER_CONTRACTOR_LIST_SUCCESS = "ORDER_CONTRACTOR_LIST_SUCCESS";
const ORDER_CONTRACTOR_LIST_ERROR = "ORDER_CONTRACTOR_LIST_ERROR";

const ORDER_CONTRACTOR_ADD_REQUEST = "ORDER_CONTRACTOR_ADD_REQUEST";
const ORDER_CONTRACTOR_ADD_SUCCESS = "ORDER_CONTRACTOR_ADD_SUCCESS";
const ORDER_CONTRACTOR_ADD_ERROR = "ORDER_CONTRACTOR_ADD_ERROR";

const ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST = "ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST";
const ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS = "ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS";
const ORDER_CONTRACTOR_ADD_SEVERAL_ERROR = "ORDER_CONTRACTOR_ADD_SEVERAL_ERROR";

const ORDER_CONTRACTOR_STATUS_REQUEST = "ORDER_CONTRACTOR_STATUS_REQUEST";
const ORDER_CONTRACTOR_STATUS_SUCCESS = "ORDER_CONTRACTOR_STATUS_SUCCESS";
const ORDER_CONTRACTOR_STATUS_ERROR = "ORDER_CONTRACTOR_STATUS_ERROR";

const ORDER_CONTRACTOR_UPDATE_FIELD_STORE = "ORDER_CONTRACTOR_UPDATE_FIELD_STORE";

const ORDER_CONTRACTOR_COUNT_REQUEST = "ORDER_CONTRACTOR_COUNT_REQUEST";
const ORDER_CONTRACTOR_COUNT_SUCCESS = "ORDER_CONTRACTOR_COUNT_SUCCESS";
const ORDER_CONTRACTOR_COUNT_ERROR = "ORDER_CONTRACTOR_COUNT_ERROR";

const ORDER_CONTRACTOR_CLIENTS_COUNT_REQUEST = "ORDER_CONTRACTOR_CLIENTS_COUNT_REQUEST";
const ORDER_CONTRACTOR_CLIENTS_COUNT_SUCCESS = "ORDER_CONTRACTOR_CLIENTS_COUNT_SUCCESS";
const ORDER_CONTRACTOR_CLIENTS_COUNT_ERROR = "ORDER_CONTRACTOR_CLIENTS_COUNT_ERROR";

const ORDER_CONTRACTOR_REJECT_REQUEST = "ORDER_CONTRACTOR_REJECT_REQUEST";
const ORDER_CONTRACTOR_REJECT_ERROR = "ORDER_CONTRACTOR_REJECT_ERROR";
const ORDER_CONTRACTOR_REJECT_SUCCESS = "ORDER_CONTRACTOR_REJECT_SUCCESS";

const ORDER_CONTRACTOR_APPROVE_REQUEST = "ORDER_CONTRACTOR_APPROVE_REQUEST";
const ORDER_CONTRACTOR_APPROVE_ERROR = "ORDER_CONTRACTOR_APPROVE_ERROR";
const ORDER_CONTRACTOR_APPROVE_SUCCESS = "ORDER_CONTRACTOR_APPROVE_SUCCESS";

const ORDER_CONTRACTOR_DECLINE_REQUEST = "ORDER_CONTRACTOR_DECLINE_REQUEST";
const ORDER_CONTRACTOR_DECLINE_ERROR = "ORDER_CONTRACTOR_DECLINE_ERROR";
const ORDER_CONTRACTOR_DECLINE_SUCCESS = "ORDER_CONTRACTOR_DECLINE_SUCCESS";

const ORDER_CONTRACTOR_COMPLETE_REQUEST = "ORDER_CONTRACTOR_COMPLETE_REQUEST";
const ORDER_CONTRACTOR_COMPLETE_SUCCESS = "ORDER_CONTRACTOR_COMPLETE_SUCCESS";
const ORDER_CONTRACTOR_COMPLETE_ERROR = "ORDER_CONTRACTOR_COMPLETE_ERROR";

const ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST = "ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST";
const ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS = "ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS";
const ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR = "ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR";

const REFUSAL_COMPLETE_ORDER_REQUEST = "REFUSAL_COMPLETE_ORDER_REQUEST";
const REFUSAL_COMPLETE_ORDER_SUCCESS = "REFUSAL_COMPLETE_ORDER_SUCCESS";
const REFUSAL_COMPLETE_ORDER_ERROR = "REFUSAL_COMPLETE_ORDER_ERROR";

const ORDER_CONTRACTOR_UPDATE_STORE = "ORDER_CONTRACTOR_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    currentRole: "",
    list: [],
    statuses: [],
    statusesParams: {},
    contractorClientsCounts: [],
    contractorCounts: [],
    progressContractorCount: false,
    progressContractorClientsCount: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDER_CONTRACTOR_CLIENTS_COUNT_REQUEST:
            return {
                ...state,
                progressContractorClientsCount: true,
            };
        case ORDER_CONTRACTOR_COUNT_REQUEST:
            return {
                ...state,
                progressContractorCount: true,
            };
        case ORDER_CONTRACTOR_LIST_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case ORDER_CONTRACTOR_UPDATE_FIELD_STORE:
            const {
                field,
                value,
            } = payload;
            return {
                ...state,
                progress: false,
                [field]: value,
            };
        case ORDER_CONTRACTOR_STATUS_REQUEST:
            return {
                ...state,
                statusesParams: payload,
            };
        case ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST:
            return {
                ...state,
                progress: true,
                actionPageData: payload,
            };
        case REFUSAL_COMPLETE_ORDER_REQUEST:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST:
        case ORDER_CONTRACTOR_COMPLETE_REQUEST:
        case ORDER_CONTRACTOR_ADD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_CONTRACTOR_LIST_SUCCESS:
            const {orderContractors, totalCount} = payload;

            return {
                ...state,
                ...payload,
                list: orderContractors,
                progress: false,
                totalCount,
            };
        case ORDER_CONTRACTOR_STATUS_SUCCESS:
            return {
                ...state,
                statuses: payload.orderContractors,
            };
        case ORDER_CONTRACTOR_CLIENTS_COUNT_SUCCESS:
            return {
                ...state,
                progressContractorClientsCount: false,
                contractorClientsCounts: payload,
            };
        case ORDER_CONTRACTOR_COUNT_SUCCESS:
            return {
                ...state,
                progressContractorCount: false,
                contractorCounts: payload,
            };
        case REFUSAL_COMPLETE_ORDER_SUCCESS:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS:
        case ORDER_CONTRACTOR_COMPLETE_SUCCESS:
        case ORDER_CONTRACTOR_ADD_SUCCESS:
        case ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case REFUSAL_COMPLETE_ORDER_ERROR:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR:
        case ORDER_CONTRACTOR_COMPLETE_ERROR:
        case ORDER_CONTRACTOR_CLIENTS_COUNT_ERROR:
        case ORDER_CONTRACTOR_LIST_ERROR:
            return {
                ...state,
                progress: false,
            };
        case ORDER_CONTRACTOR_ADD_ERROR:
        case ORDER_CONTRACTOR_ADD_SEVERAL_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ORDER_CONTRACTOR_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getOrderListContractor(payload) {
    return {type: ORDER_CONTRACTOR_LIST_REQUEST, payload};
}

export function rejectContractorByOrder(payload) {
    return {
        type: ORDER_CONTRACTOR_REJECT_REQUEST,
        payload,
    };
}

export function approveContractorByOrder(payload) {
    return {
        type: ORDER_CONTRACTOR_APPROVE_REQUEST,
        payload,
    };
}

export function declineContractorByOrder(payload) {
    return {
        type: ORDER_CONTRACTOR_DECLINE_REQUEST,
        payload,
    };
}

export function addOrderContractor(data, params) {
    return {type: ORDER_CONTRACTOR_ADD_REQUEST, payload: {data, params}};
}

export function addSeveralOrderContractors(data, params) {
    return {type: ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST, payload: {data, params}};
}

export function getStatusesOrderContractor(payload) {
    return {type: ORDER_CONTRACTOR_STATUS_REQUEST, payload};
}

export function updateFieldOrderContractorStore(field, value) {
    return {
        type: ORDER_CONTRACTOR_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function updateOrderContractorsStore(payload) {
    return {
        type: ORDER_CONTRACTOR_UPDATE_STORE,
        payload,
    };
}

export function getOrderContractorCount(payload) {
    return {
        type: ORDER_CONTRACTOR_COUNT_REQUEST,
        payload,
    };
}

export function getOrderContractorClientsCount(payload) {
    return {
        type: ORDER_CONTRACTOR_CLIENTS_COUNT_REQUEST,
        payload,
    };
}

// "Подтвердить завершение заказа". Перевод заявки в статус "Заказ завершен"
export const completeOrderContractor = payload => ({
    type: ORDER_CONTRACTOR_COMPLETE_REQUEST,
    payload,
});

// "Завершить заказ". Переводит на статус "Ожидание подтверждения завершения заказа"
export const orderContractorCompleteOrder = payload => ({
    type: ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST,
    payload,
});

// "Отклонить завершение заказа". Перевод заявки в статус "Переговоры по спору"
export const refusalCompleteOrder = payload => ({
    type: REFUSAL_COMPLETE_ORDER_REQUEST,
    payload,
});

//*  SELECTORS  *//

export const orderContractorSelector = state => state.orderContractors;

export const orderContractorList = createSelector(orderContractorSelector, ({list}) => list);

export const orderContractorTotalPagesSelector = createSelector(orderContractorSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const orderContractorPageDataSelector = createSelector(orderContractorSelector, ({pageData}) => pageData);

export const orderProgressListSelector = createSelector(orderContractorSelector, ({progress}) => progress);
export const orderContractorCountsSelector = createSelector(orderContractorSelector, ({contractorCounts}) => contractorCounts);
export const orderContractorClientsCountProgressSelector = createSelector(orderContractorSelector, ({progressContractorClientsCount}) => progressContractorClientsCount);
export const orderContractorClientsCountSelector = createSelector(orderContractorSelector, ({contractorClientsCounts}) => contractorClientsCounts);

export const orderProgressContractorCountsSelector = createSelector(orderContractorSelector, ({progressContractorCount}) => progressContractorCount);
export const orderContractorStatusesSelector = createSelector(orderContractorSelector, ({statuses}) => statuses);

export const orderContractorTotalCountSelector = createSelector(orderContractorSelector, ({totalCount}) => totalCount);

export const orderContractorOptionsListSelector = createSelector(orderContractorSelector, ({list}) => list.map(({contractorId: value, firstName, lastName, patronymic}) => ({
    key: value, value, text: `${lastName} ${firstName} ${patronymic ? patronymic : ""}`,
})));

export const orderContractorErrorSelector = createSelector(
    orderContractorSelector,
    ({error}) => error,
);
export const orderContractorActionPageDataSelector = createSelector(orderContractorSelector, ({actionPageData}) => actionPageData);

//*  SAGA  *//

export const orderContractorListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_CONTRACTOR_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_CONTRACTOR_LIST_ERROR, payload: error});
    }
};

export const addSeveralOrderContractorsSaga = function* (action) {
    try {
        const {payload} = action;
        const {data, params} = payload;
        const {orderIds} = data;

        const {
            toastMessage,
            onSuccess,
        } = params;

        const result = yield request.post(`${controller}/addAll`, data);

        const {
            errorMessage,
            chosenProcessingType,
            syncResult,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        if (chosenProcessingType === "SYNC") {
            const {
                failCount,
                successCount,
                totalCount,
                orderInvitationResult,
            } = syncResult;

            const {contractorInvitationResult} = orderInvitationResult[`${orderIds[0]}`];
            const error = Object.values({...contractorInvitationResult}).find(value => !value.success);


            if (error) {
                const {errorMessage, errorCode} = error;
                let _errorMessage = errorMessage;

                if ((errorCode === "PROHIBITION_CONTRACTING_NON_RESIDENTS")) {
                    _errorMessage = successCount > 0 ? "Не удалось пригласить некоторых исполнителей. В компании стоит запрет на заключение договоров с нерезидентами, обратитесь к администратору компании" : errorMessage;
                }

                toastError(_errorMessage);

                yield put({
                    type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
                    payload: result,
                });

                return {
                    done: true,
                };
            }

            if (successCount === totalCount) {
                toastSuccess("Выбранные исполнители успешно приглашены");
            }
            if (successCount !== 0 && failCount !== 0) {
                toastWarning("Не удалось пригласить некоторых исполнителей. Возможно они уже откликнулись или оказывают услуги(выполняют работы) на выбранных заказах");
            }
            if (failCount === totalCount) {
                toastError("Не удалось пригласить выбранных исполнителей. Возможно они уже откликнулись или оказывают услуги(выполняют работы) на выбранных заказах");
            }
        } else {
            toastSuccess(toastMessage);
        }

        yield put({
            type: ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS,
        });

    } catch (error) {
        toastError(STAFF.STAFF_ADD_ERROR_TEXT);
        yield put({type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR, payload: error});
    }
};

export const orderContractorAddSaga = function* (action) {
    try {
        const {payload} = action;
        const {data, params} = payload;
        const {isFetchList, toastMessage, onSuccess} = params;

        const result = yield request.post(`${controller}/add`, data);

        const {errorMessage} = result;

        if (onSuccess) {
            onSuccess();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(toastMessage);

        if (isFetchList) {
            const state = yield select();
            yield put(getContractorList(state.contractor.pageData));
            yield put(getStatusesOrderContractor(state.orderContractors.statusesParams));
            yield put(getOrderListContractor(state.orderContractors.pageData));
        }

        yield put({
            type: ORDER_CONTRACTOR_ADD_SUCCESS,
        });

    } catch (error) {
        toastError(STAFF.STAFF_ADD_ERROR_TEXT);
        yield put({type: ORDER_CONTRACTOR_ADD_ERROR, payload: error});
    }
};

//POST /api/ordercontractors/getOrderStatus
export const orderContractorStatusesSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getOrderStatus`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_CONTRACTOR_STATUS_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: ORDER_CONTRACTOR_STATUS_ERROR, payload: error});
    }
};


//POST /api/ordercontractors/getOrderContractorCount
export const getOrderContractorCountSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getOrderContractorCount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_CONTRACTOR_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_CONTRACTOR_COUNT_ERROR, payload: error});
    }
};

export const getOrderContractorClientsCountSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getOrderContractorClientsCount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_CLIENTS_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_CONTRACTOR_CLIENTS_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_CONTRACTOR_CLIENTS_COUNT_ERROR, payload: error});
    }
};

export const rejectContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError = () => {
            },
            toastMsg,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_REJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastMsg && toastSuccess(toastMsg);

        yield put({
            type: ORDER_CONTRACTOR_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_REJECT_ERROR,
            payload: error.message,
        });
    }
};

export const approveContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_APPROVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_CONTRACTOR_APPROVE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_APPROVE_ERROR,
            payload: error.message,
        });
    }
};

export const completeContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/complete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_COMPLETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ERROR,
            payload: error.message,
        });
    }
};

export const declineContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/decline`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_DECLINE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_DECLINE_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_DECLINE_ERROR,
            payload: error.message,
        });
    }
};

export const orderContractorCompleteOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/completeOrder`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();

        yield put(getPageOrderContractors({...state.contractor.pageDataContractorsByOrder}));

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
            payload: error.message,
        });
    }
};

export const refusalCompleteOrderSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.post(`${controller}/refusalCompleteOrder`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFUSAL_COMPLETE_ORDER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REFUSAL_COMPLETE_ORDER_SUCCESS,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: REFUSAL_COMPLETE_ORDER_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_CONTRACTOR_LIST_REQUEST, orderContractorListSaga),
        takeEvery(ORDER_CONTRACTOR_ADD_REQUEST, orderContractorAddSaga),
        takeEvery(ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST, addSeveralOrderContractorsSaga),
        takeEvery(ORDER_CONTRACTOR_STATUS_REQUEST, orderContractorStatusesSaga),
        takeEvery(ORDER_CONTRACTOR_COUNT_REQUEST, getOrderContractorCountSaga),
        takeEvery(ORDER_CONTRACTOR_CLIENTS_COUNT_REQUEST, getOrderContractorClientsCountSaga),
        takeEvery(ORDER_CONTRACTOR_REJECT_REQUEST, rejectContractorSaga),
        takeEvery(ORDER_CONTRACTOR_APPROVE_REQUEST, approveContractorSaga),
        takeEvery(ORDER_CONTRACTOR_DECLINE_REQUEST, declineContractorSaga),
        takeEvery(ORDER_CONTRACTOR_COMPLETE_REQUEST, completeContractorSaga),
        takeEvery(ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST, orderContractorCompleteOrderSaga),
        takeEvery(REFUSAL_COMPLETE_ORDER_REQUEST, refusalCompleteOrderSaga),
    ]);
}
