import {
    ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    progressCard: false,
    progress: false,
    totalCount: 0,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS:
            const {
                migrations,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: migrations,
            };
        case GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST:
        case UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST:
        case DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS:
        case UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS:
        case DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS:
        case ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR:
        case UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR:
        case DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION:
            return initial;
        default:
            return state;
    }
};