import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getSettingsDirectoriesBanksBalancesPage,
    updateStoreSettingsDirectoriesBanks,
} from "../../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";
import {initialSettingsBanksBalancesPage} from "../../../../../../ducks/bff/settings/directories/naimix/banks/reducer";

export const useSettingsDirectoriesBanksBalancesFetch = ({pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsDirectoriesBanks(initialSettingsBanksBalancesPage));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getSettingsDirectoriesBanksBalancesPage({
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};