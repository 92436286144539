import React from "react";
import {useSelector} from "react-redux";

import NmListCard from "../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../components/CheckboxList";
import NmButton from "../../components/NmButton";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import AgencyContractActual from "./components/actual";
import AgencyContractNew from "./components/edit";
import {AgencyContractFileLink} from "./components/file-link";
import AgencyContractInfo from "./components/info";
import AgencyContractTerminate from "./components/terminate";

import {usePagination} from "../../hooks/usePagination";
import {useAction} from "./hooks/useAction";

import dateFormat, {convertUtcToLocal} from "../../utils/dateFormat";
import {ls, USER_ROLE} from "../../utils/localstorage";

import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
} from "../../constants/roles";

import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";
import {
    getContractHistoryListSelector,
    getTotalCountHistorySelector,
    getTotalPagesHistorySelector,
} from "../../ducks/contractHistory";

import "./style.sass";

const AgencyContract = (props) => {
    const {
        match: {
            params: {clientId},
        },
    } = props;

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        isOpenAdd,
        onOpenAdd,
        onCloseAdd,
        getFile,
    } = useAction({pageNum, pageSize, clientId});

    const client = useSelector(clientCardInfoSelector);
    const totalCount = useSelector(getTotalCountHistorySelector);
    const totalPages = useSelector(getTotalPagesHistorySelector);
    const historyList = useSelector(getContractHistoryListSelector);

    const role = ls(USER_ROLE);
    const isAccessEdit = ![NM_COORDINATOR, CLIENT_ADMIN, NM_CHIEF_ACCOUNTANT, CLIENT_ACCOUNTANT].includes(role) && !client?.archived;
    const isAccessEditContractInfo = isAccessEdit && !client?.archived;

    const renderFileNames = (item) => {
        const {
            fileNames,
            contractHistoryId,
        } = item;

        if (!fileNames.length) {
            return null;
        }

        return fileNames.map((fileName, index) => (
            <AgencyContractFileLink
                key={index}
                className="agency-contract__file"
                onClick={() => {getFile({contractHistoryId, fileName});}}
                isLastElement={index === fileNames.length - 1}
                fileName={fileName}
            />
        ),
        );
    };

    const getRows = () => {
        return historyList.map(item => {
            const {
                contractHistoryId,
                createDatetime,
                creatorClientUserName,
            } = item;

            return {
                key: contractHistoryId,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={[
                            {label: "Дата добавления", text: dateFormat(convertUtcToLocal(createDatetime))},
                            {
                                label: "ФИО администратора",
                                text: creatorClientUserName,
                                columnOnMobile: true,
                            },
                            {
                                label: "Документы",
                                text: renderFileNames(item),
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    Агентский договор
                </NmTitle>
            }
            controls={
                isAccessEditContractInfo &&
                <NmButton
                    size="xl"
                    color="green"
                    icon={<AddIcon />}
                    onClick={onOpenAdd}
                >
                    Добавить договор
                </NmButton>
            }
            subHeader={
                <div className="row">
                    <div className="col-16 col-xl-5">
                        <AgencyContractInfo
                            isAccessEdit={isAccessEditContractInfo}
                        />
                    </div>
                    <div className="col-16 col-xl-5 mt-2 mt-md-4 mt-xl-0">
                        <AgencyContractActual
                            isAccessEdit={isAccessEdit}
                            clientId={clientId}
                        />
                    </div>
                    <div className="col-16 col-xl-5 mt-2 mt-md-4 mt-xl-0">
                        <AgencyContractTerminate
                            clientId={clientId}
                        />
                    </div>
                </div>
            }
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
        >
            {
                isOpenAdd &&
                <AgencyContractNew
                    clientId={clientId}
                    onCloseModel={onCloseAdd}
                />
            }
            <NmTitle
                size="lg"
                className="mb-2 mb-md-4"
            >
                История
            </NmTitle>
            <CheckboxList
                rows={getRows()}
            />
        </NmPage>
    );
};

export default AgencyContract;
