import {
    clearSpace,
    formatAmountWithNullChecking,
} from "../../../../../../../utils/stringFormat";
import {
    convertStringToDate,
    handleDateFieldFilter,
    handleFormString,
    handleNumber,
} from "../../../../../../../utils/stringHelper";

/***
 * Обработка значений с бэка
 * @param card
 * @returns {{orderAmount: *, orderDescriptionFns: *, orderWorkDescription: *, comment: *, orderWorkEndDate: *, orderWorkStartDate: *, orderDescription: *, actDate: *}}
 */
export const getActRegistryEditPaymentInitialValues = (card) => {
    return {
        orderWorkDescription: card.orderWorkDescription || "",
        orderDescription: card.orderDescription || "",
        orderDescriptionFns: card.orderDescriptionFns || "",
        comment: card.comment || "",
        orderWorkStartDate: convertStringToDate(card.orderWorkStartDate),
        orderWorkEndDate: convertStringToDate(card.orderWorkEndDate),
        sumOrAmount: card.sumOrAmount ? formatAmountWithNullChecking(card.sumOrAmount) : "",
        amountForReport: card.amountForReport ? formatAmountWithNullChecking(card.amountForReport) : "",
        actDate: convertStringToDate(card.actDate),
        orderNumber: card.orderNumber || "",
        contractorWorkQualityScore: card.contractorWorkQualityScore || "",
        contractorEstimatedTimeScore: card.contractorEstimatedTimeScore || "",
    };
};

/***
 * Обработка значений для отправки в запросе
 * @param formData -
 * @returns {{orderAmount: *, orderDescriptionFns: *, orderWorkDescription: *, comment: *, orderWorkEndDate: *, orderWorkStartDate: *, orderDescription: *, actDate: *}}
 */
export const getActRegistryEditPaymentRequestData = (formData) => {
    return {
        orderWorkDescription: handleFormString(formData.orderWorkDescription),
        orderDescription: handleFormString(formData.orderDescription),
        orderDescriptionFns: handleFormString(formData.orderDescriptionFns),
        comment: handleFormString(formData.comment),
        orderWorkStartDate: handleDateFieldFilter(formData.orderWorkStartDate),
        orderWorkEndDate: handleDateFieldFilter(formData.orderWorkEndDate),
        sumOrAmount: handleNumber(clearSpace(formData.sumOrAmount)),
        amountForReport: handleNumber(clearSpace(formData.amountForReport)),
        actDate: handleDateFieldFilter(formData.actDate),
        orderNumber: +formData.orderNumber,
        contractorWorkQualityScore: formData.contractorWorkQualityScore,
        contractorEstimatedTimeScore: formData.contractorEstimatedTimeScore,
    };
};