import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {
    CONTRACTOR_CORRECTION_RECEIPT,
    CONTRACTOR_RECEIPT,
    CONTRACTOR_RETURN_RECEIPT,
} from "../constants/messages";

const controller = "/contractorReceiptController";

const CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_REQUEST = "CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_REQUEST";
const CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_SUCCESS = "CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_SUCCESS";
const CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_ERROR = "CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_ERROR";

const CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_REQUEST = "CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_REQUEST";
const CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_SUCCESS = "CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_SUCCESS";
const CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_ERROR = "CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_ERROR";

const CONTRACTOR_CREATE_CORRECTION_RECEIPT_REQUEST = "CONTRACTOR_CREATE_CORRECTION_RECEIPT_REQUEST";
const CONTRACTOR_CREATE_CORRECTION_RECEIPT_SUCCESS = "CONTRACTOR_CREATE_CORRECTION_RECEIPT_SUCCESS";
const CONTRACTOR_CREATE_CORRECTION_RECEIPT_ERROR = "CONTRACTOR_CREATE_CORRECTION_RECEIPT_ERROR";

const CONTRACTOR_CREATE_RETURN_RECEIPT_REQUEST = "CONTRACTOR_CREATE_RETURN_RECEIPT_REQUEST";
const CONTRACTOR_CREATE_RETURN_RECEIPT_SUCCESS = "CONTRACTOR_CREATE_RETURN_RECEIPT_SUCCESS";
const CONTRACTOR_CREATE_RETURN_RECEIPT_ERROR = "CONTRACTOR_CREATE_RETURN_RECEIPT_ERROR";

const GET_RECEIPT_DUPLICATES_REQUEST = "GET_RECEIPT_DUPLICATES_REQUEST";
const GET_RECEIPT_DUPLICATES_SUCCESS = "GET_RECEIPT_DUPLICATES_SUCCESS";
const GET_RECEIPT_DUPLICATES_ERROR = "GET_RECEIPT_DUPLICATES_ERROR";

const GET_CORRECTION_RECEIPT_INFO_REQUEST = "GET_CORRECTION_RECEIPT_INFO_REQUEST";
const GET_CORRECTION_RECEIPT_INFO_SUCCESS = "GET_CORRECTION_RECEIPT_INFO_SUCCESS";
const GET_CORRECTION_RECEIPT_INFO_ERROR = "GET_CORRECTION_RECEIPT_INFO_ERROR";

const GET_RETURN_RECEIPT_INFO_REQUEST = "GET_RETURN_RECEIPT_INFO_REQUEST";
const GET_RETURN_RECEIPT_INFO_SUCCESS = "GET_RETURN_RECEIPT_INFO_SUCCESS";
const GET_RETURN_RECEIPT_INFO_ERROR = "GET_RETURN_RECEIPT_INFO_ERROR";

const initialState = {
    downLoadLink: null,
    error: "",
    actionProgress: false,
    correctionReceiptInfo: {},
    returnReceiptInfo: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_RETURN_RECEIPT_INFO_REQUEST:
        case GET_CORRECTION_RECEIPT_INFO_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_SUCCESS:
        case CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_REQUEST:
            return {
                ...state,
                downLoadLink: payload,
            };
        case CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_ERROR:
        case CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case GET_RETURN_RECEIPT_INFO_SUCCESS:
            return {
                ...state,
                returnReceiptInfo: payload.receipt || payload.correction,
                actionProgress: false,
            };
        case GET_CORRECTION_RECEIPT_INFO_SUCCESS:
            return {
                ...state,
                correctionReceiptInfo: payload.correction,
                actionProgress: false,
            };
        case CONTRACTOR_CREATE_CORRECTION_RECEIPT_SUCCESS:
        case CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_SUCCESS:
            return {
                ...state,
                downLoadLink: payload,
            };
        case CONTRACTOR_CREATE_CORRECTION_RECEIPT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CONTRACTOR_CREATE_RETURN_RECEIPT_SUCCESS:
            return {
                ...state,
                downLoadLink: payload,
            };
        case CONTRACTOR_CREATE_RETURN_RECEIPT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case GET_RETURN_RECEIPT_INFO_ERROR:
        case GET_CORRECTION_RECEIPT_INFO_ERROR:
            return {
                ...state,
                error: payload,
                actionProgress: false,
            };
        default:
            return state;
    }
};

const contractorReceiptSelector = ({contractorReceipt}) => contractorReceipt;
export const correctionReceiptInfoSelector = createSelector(contractorReceiptSelector, ({correctionReceiptInfo}) => correctionReceiptInfo);
export const returnReceiptInfoSelector = createSelector(contractorReceiptSelector, ({returnReceiptInfo}) => returnReceiptInfo);

export function getCorrectionReceiptInfo(payload) {
    return {
        type: GET_CORRECTION_RECEIPT_INFO_REQUEST,
        payload,
    };
}

export function getReturnReceiptInfo(payload) {
    return {
        type: GET_RETURN_RECEIPT_INFO_REQUEST,
        payload,
    };
}

export function createContractorReceipt(payload) {
    return {
        type: CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_REQUEST,
        payload,
    };
}

export function createContractorCorrectionReceipt(payload) {
    return {
        type: CONTRACTOR_CREATE_CORRECTION_RECEIPT_REQUEST,
        payload,
    };
}

export function createContractorReturnReceipt(payload) {
    return {
        type: CONTRACTOR_CREATE_RETURN_RECEIPT_REQUEST,
        payload,
    };
}

export function getContractorCorrectionReceipt(payload) {
    return {
        type: CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_REQUEST,
        payload,
    };
}

export function getReceiptDuplicates(payload) {
    return {
        type: GET_RECEIPT_DUPLICATES_REQUEST,
        payload,
    };
}

// GET /bff/adm/common/naimix/download-payment-files/create-receipt
export const createReceiptSaga = function* ({payload}) {
    try {
        const {
            paymentNumber,
            getSuccess,
            setLoading,
        } = payload;

        const {
            errorMessage,
            downloadLink,
        } = yield request.bff.get("/adm/common/naimix/download-payment-files/create-receipt", {params: {paymentNumber}});

        setLoading(false);

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        getSuccess(downloadLink);

        yield put({
            type: CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_SUCCESS,
            payload: downloadLink,
        });

        toastSuccess(CONTRACTOR_RECEIPT.CREATE_SUCCESS);
    } catch (error) {
        toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

        yield put({
            type: CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_ERROR,
            payload: error,
        });
    }
};

// POST /api/contractorReceiptController/createCorrectionReceipt

export const createCorrectionReceiptSaga = function* (action) {
    try {
        const {payload} = action;

        const {data, getSuccess, setLoading} = payload;

        const {
            errorMessage,
            downloadLink,
        } = yield request.post(`${controller}/createCorrectionReceipt`, data);

        setLoading(false);

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_CREATE_CORRECTION_RECEIPT_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_CORRECTION_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        getSuccess(downloadLink);

        yield put({
            type: CONTRACTOR_CREATE_CORRECTION_RECEIPT_SUCCESS,
            payload: downloadLink,
        });

        toastSuccess(CONTRACTOR_CORRECTION_RECEIPT.CREATE_SUCCESS);
    } catch (error) {
        toastError(CONTRACTOR_CORRECTION_RECEIPT.CREATE_ERROR);

        yield put({
            type: CONTRACTOR_CREATE_CORRECTION_RECEIPT_ERROR,
            payload: error,
        });
    }
};

export const getCorrectionReceiptInfoSaga = function* (action) {
    try {
        const {payload} = action;
        const {data, onSuccess} = payload;

        const result = yield request.bff.post("/adm/common/naimix/download-payment-files/get-correction-receipt-info", data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            yield put({
                type: GET_CORRECTION_RECEIPT_INFO_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RETURN_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: GET_CORRECTION_RECEIPT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CORRECTION_RECEIPT_INFO_ERROR,
            payload: error,
        });
    }
};

export const getReturnReceiptInfoSaga = function* (action) {
    try {
        const {payload} = action;
        const {data, onSuccess} = payload;

        const result = yield request.bff.post("/adm/common/naimix/download-payment-files/get-return-receipt-info", data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            yield put({
                type: GET_RETURN_RECEIPT_INFO_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RETURN_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: GET_RETURN_RECEIPT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_RETURN_RECEIPT_INFO_ERROR,
            payload: error,
        });
    }
};

// POST /api/contractorReceiptController/returnReceipt

export const createReturnReceiptSaga = function* (action) {
    try {
        const {payload} = action;
        const {data, getSuccess, setLoading} = payload;

        const {
            errorMessage,
            downloadLink,
        } = yield request.post(`${controller}/returnReceipt`, data);

        setLoading(false);

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_CREATE_RETURN_RECEIPT_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RETURN_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        getSuccess(downloadLink);

        yield put({
            type: CONTRACTOR_CREATE_RETURN_RECEIPT_SUCCESS,
            payload: downloadLink,
        });

        toastSuccess(CONTRACTOR_RETURN_RECEIPT.CREATE_SUCCESS);
    } catch (error) {
        toastError(CONTRACTOR_RETURN_RECEIPT.CREATE_ERROR);

        yield put({
            type: CONTRACTOR_CREATE_RETURN_RECEIPT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractorReceiptController/getReceiptForCancel/{paymentNumber}
export const getReceiptDuplicatesSaga = function* ({payload}) {
    try {
        const {paymentNumber, onSuccess} = payload;

        const {
            errorMessage,
            errorCode,
            duplicates,
        } = yield request.get(`${controller}/getReceiptForCancel/${paymentNumber}`);


        if (errorMessage && errorCode !== "DUPLICATE NOT EXIST") {
            yield put({
                type: GET_RECEIPT_DUPLICATES_ERROR,
                payload: errorMessage,
            });


            return {
                done: true,
            };
        }

        onSuccess(duplicates);

        yield put({
            type: GET_RECEIPT_DUPLICATES_SUCCESS,
            payload: duplicates,
        });

    } catch (error) {
        yield put({
            type: GET_RECEIPT_DUPLICATES_ERROR,
            payload: error,
        });
    }
};

//api/contractorReceiptController/getReceiptById

export const getReceiptSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            paymentNumber,
            getSuccess,
            setLoading,
            clientId,
            orderId,
            orderWorkReportId,
        } = payload;

        const {
            errorMessage,
            downloadLink,
        } = yield request.get(`${controller}/getReceiptById`, {
            params: {
                paymentNumber,
                clientId,
                orderId,
                orderWorkReportId,
            },
        });


        if (setLoading) {
            yield call(setLoading, false);
        }

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_ERROR,
                payload: errorMessage,
            });

            toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

            return {
                done: true,
            };
        }

        if (getSuccess) {
            yield call(getSuccess, downloadLink);
        }

        yield put({
            type: CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_SUCCESS,
            payload: downloadLink,
        });

        toastSuccess(CONTRACTOR_RECEIPT.CREATE_SUCCESS);
    } catch (error) {
        toastError(CONTRACTOR_RECEIPT.CREATE_ERROR);

        yield put({
            type: CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_RECEIPT_CONTROLLER_CREATE_RECEIPT_REQUEST, createReceiptSaga),
        takeEvery(CONTRACTOR_CREATE_CORRECTION_RECEIPT_REQUEST, createCorrectionReceiptSaga),
        takeEvery(CONTRACTOR_CREATE_RETURN_RECEIPT_REQUEST, createReturnReceiptSaga),
        takeEvery(CONTRACTOR_RECEIPT_CONTROLLER_GET_RECEIPT_REQUEST, getReceiptSaga),
        takeEvery(GET_CORRECTION_RECEIPT_INFO_REQUEST, getCorrectionReceiptInfoSaga),
        takeEvery(GET_RETURN_RECEIPT_INFO_REQUEST, getReturnReceiptInfoSaga),
        takeEvery(GET_RECEIPT_DUPLICATES_REQUEST, getReceiptDuplicatesSaga),
    ]);
}