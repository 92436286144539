import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import useDataForm from "./hooks/useDataForm";
import useEditFormWarning from "./hooks/useEditFormWarning";
import useReadFormWarning from "./hooks/useReadFormWarning";

import dateFormat  from "../../../../utils/dateFormat";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {
    CONTRACTOR_CARD_SUB_BLOCK_FIELDS,
    CONTRACTOR_SUB_BLOCK_NAME,
    contractorMask,
} from "../../../../constants/contractorInfo";

import {pendingPersonalDataSelector} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {contractorCardInfoSelector} from "../../../../ducks/bff/contractor-сard/selectors";

import "./style.sass";

const MigrationCard2 = (props) => {
    const {
        isEditable,
        className,
    } = props;

    const {t} = useTranslation();

    const card = useSelector(contractorCardInfoSelector);
    const pendingPersonalData = useSelector(pendingPersonalDataSelector);

    const {
        values,
        handleBlur,
        handleChange,
        touched,
        errors,
        isEdit,
        setFieldTouched,
        setFieldValue,
        cancelEdit,
        toggleCard,
    } = useDataForm(card);

    const {
        warning,
        setWarning,
    } = useEditFormWarning(values, card);

    const {
        warningReadOnlyCard,
    } = useReadFormWarning(values, card);

    const hasAllPendingMigrationData = () => {
        const hasSeriesNumberPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.CARD_SERIES_NUMBER);
        const hasDatesPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.DATES);

        return hasSeriesNumberPendingPersonalData && hasDatesPendingPersonalData;
    };

    const getHasPendingSubBlock = (subBlockName, secondSubBlockName) => {
        const {data} = pendingPersonalData;

        const {pendingPersonalDataFields = []} = data || {};

        const subBlockFields = secondSubBlockName ?
            [...CONTRACTOR_CARD_SUB_BLOCK_FIELDS.MIGRATION_DATA[subBlockName], ...CONTRACTOR_CARD_SUB_BLOCK_FIELDS.MIGRATION_DATA[secondSubBlockName]]
            : CONTRACTOR_CARD_SUB_BLOCK_FIELDS.MIGRATION_DATA[subBlockName];

        return subBlockFields.some(field => pendingPersonalDataFields.includes(field));
    };

    const classNameSubBlock = (subBlockName, secondSubBlockName) => {
        const {data} = pendingPersonalData;

        const {pendingPersonalDataFields} = data || {};

        if (isEmpty(pendingPersonalDataFields)) {
            return "";
        }

        if (hasAllPendingMigrationData()) {
            return "";
        }

        if (getHasPendingSubBlock(subBlockName, secondSubBlockName)) {
            return "migration-card migration-card__divider_yellow";
        }

        return "";
    };

    const classNameCard = () => {
        if (hasAllPendingMigrationData()) {
            return `${className} migration-card migration-card_border`;
        }

        return className;
    };

    const localizationData = {
        title: t("contractor-migration-card.title"),
        cardSeriesNumber: t("contractor-migration-card.card-series-number"),
        issueDate: t("contractor-migration-card.issue-date"),
        expirationDate: t("contractor-migration-card.expiration-date"),
        notSpecified: t("is-not-specified.content"),
    };

    const renderText = (value) => {
        return value || localizationData.notSpecified;
    };

    const renderCardReadOnly = () => {
        return (
            <>
                <LabelTextApp
                    label={localizationData.cardSeriesNumber}
                    text={renderText(values[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER])}
                />
                <div
                    className={`migration-card__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.CARD_SERIES_NUMBER, CONTRACTOR_SUB_BLOCK_NAME.DATES)}`}
                />
                <div className="row">
                    <LabelTextApp
                        className="col-md-8"
                        label={localizationData.issueDate}
                        text={renderText(dateFormat(values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE], "dd.MM.yyyy"))}
                    />
                    <LabelTextApp
                        className="col-md-8 mt-3 mt-md-0"
                        label={localizationData.expirationDate}
                        text={renderText(dateFormat(values[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE], "dd.MM.yyyy"))}
                    />
                </div>
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm
                noValidate
                addMargin
                className="container fluid no-padding-container"
            >
                <div className="row contractor-form-card__item">
                    <div className="col-md-6">
                        <NmInputV2
                            size="lg"
                            required
                            label={localizationData.cardSeriesNumber}
                            mask={contractorMask.MIGRATION_CARD.NUMBER}
                            maskChar={null}
                            value={values[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]}
                            name={CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched && touched[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER] ?
                                errors && errors[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER] :
                                undefined
                            }
                        />
                    </div>
                </div>
                <div className="migration-card__divider" />
                <div className="row contractor-form-card__item">
                    <div className="col-md-8">
                        <NmDatePicker
                            size="lg"
                            dateFormatMask="99.99.9999"
                            label={localizationData.issueDate}
                            maxDate={new Date()}
                            required
                            selected={values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]}
                            name={CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE, true)}
                            error={touched && touched[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE] :
                                undefined
                            }
                            warning={warning && warning[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]}
                        />
                    </div>
                    <div className="col-md-8 mt-3 mt-md-0">
                        <NmDatePicker
                            dateFormatMask="99.99.9999"
                            size="lg"
                            label={localizationData.expirationDate}
                            minDate={new Date()}
                            required
                            name={CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE}
                            selected={values[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE, true)}
                            error={touched && touched[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE] ?
                                errors && errors[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE] :
                                undefined
                            }
                            warning={warning && warning[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]}
                        />
                    </div>
                </div>
            </NmForm>
        );
    };

    return (
        <CardApp
            className={classNameCard()}
            title={localizationData.title}
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={() => {
                cancelEdit();
                setWarning({});
            }}
            isEdit={isEdit}
            warning={warningReadOnlyCard}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
};

export default MigrationCard2;