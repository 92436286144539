import React from "react";

import {
    RECRUITMENT_JOB_BOARD_PUBLICATION_STATUS_DICT,
} from "../../../constants/recruitment";
import NmBadge from "../../NmBadge";

const MOD = {
    ERROR: "red",
    PROCESS: "orange",
};

export const RecruitmentJobBoardPublicationStatus = (props) => {
    const {
        status,
        errorMessage,
        className,
    } = props;

    return (
        <NmBadge
            className={className}
            mod={MOD[status]}
            text={RECRUITMENT_JOB_BOARD_PUBLICATION_STATUS_DICT[status]}
            rightTooltipText={errorMessage}
        />
    );
};