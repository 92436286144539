import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../../constants";

export const getCreateVacancyJobBoardRequestData = (params) => {
    const {
        jobBoardType,
        values,
    } = params;

    const {
        businessArea,
        profession,
        jobBoardAccountId,
        brandTemplate,
        ...otherValues
    } = values;

    return {
        jobBoardType,
        ...otherValues,
        profession: [RECRUITMENT_SOURCE_JOB_BOARD.AVITO].includes(jobBoardType) ? +profession : profession,
        businessArea: businessArea ? parseFloat(businessArea) : undefined,
        brandTemplate: jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER
        && brandTemplate.value
            ? {
                id: brandTemplate.value,
                name: brandTemplate.text,
            }
            : undefined,
        manager: [RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER].includes(jobBoardType)
            ? getManager(values.manager)
            : undefined,
    };
};

const getManager = (manager) => {
    const {
        key,
        value,
        text,
        ...data
    } = manager;

    return data;
};