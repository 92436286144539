import React, {KeyboardEvent} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

interface IProps {
    children: React.ReactNode,
    onSubmit?: () => void,
    id?: string,
    className?: string,
    type?: string,
    noValidate?: boolean,
    addMargin?: boolean,
    onKeyDown?: (event: KeyboardEvent<HTMLFormElement>) => void,
    autoComplete?: string
    horizontal?: boolean,
    fullWidth?: boolean,
    onKeyPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void,
}

const NmForm = (props: IProps) => {
    const {
        children,
        onSubmit,
        type,
        horizontal,
        className = "",
        addMargin,
        fullWidth = true,
        ...otherProps
    } = props;
    const [block] = bem("nm-form", className);

    const isFilterForm = type === "filter";

    const _onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <form
            {...otherProps}
            onSubmit={_onSubmit}
            className={block({
                fullWidth,
                filter: isFilterForm,
                addMargin,
                horizontal,
            })}
        >
            {children}
        </form>
    );
};

export default NmForm;