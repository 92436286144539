import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_CLIENT_GROUP_ADD_ERROR,
    SETTINGS_CLIENT_GROUP_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_ADD_SUCCESS,
    SETTINGS_CLIENT_GROUP_DELETE_ERROR,
    SETTINGS_CLIENT_GROUP_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_DELETE_SUCCESS,
    SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR,
    SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS,
    SETTINGS_CLIENT_GROUP_UPDATE_ERROR,
    SETTINGS_CLIENT_GROUP_UPDATE_REQUEST,
    SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "/adm/settings/client-groups";

const getPageSettingsClientGroups = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addSettingsClientGroups = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_ADD_ERROR,
            payload: error,
        });
    }
};

const updateSettingsClientGroups = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_UPDATE_ERROR,
            payload: error,
        });
    }
};

const deleteSettingsClientGroups = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.delete(`${controller}/delete`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_DELETE_ERROR,
            payload: error,
        });
    }
};

const getRelatedUsersSettingsClientGroups = function* ({payload}) {
    try {
        const {clientUserId} = payload;

        const result = yield request.bff.get(`${controller}/clientUsers/${clientUserId}/relatedUsers`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR,
            payload: error,
        });
    }
};

const setEnabledRelatedUsersSettingsClientGroups = function* ({payload}) {
    try {
        const {
            clientUserId,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/clientUsers/${clientUserId}/relatedUsers/setEnabled`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST, getPageSettingsClientGroups),
        takeEvery(SETTINGS_CLIENT_GROUP_ADD_REQUEST, addSettingsClientGroups),
        takeEvery(SETTINGS_CLIENT_GROUP_UPDATE_REQUEST, updateSettingsClientGroups),
        takeEvery(SETTINGS_CLIENT_GROUP_DELETE_REQUEST, deleteSettingsClientGroups),
        takeEvery(SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST, getRelatedUsersSettingsClientGroups),
        takeEvery(SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST, setEnabledRelatedUsersSettingsClientGroups),

    ]);
}