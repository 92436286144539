import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorEducationEditModal from "./components/сontractor-education-edit-modal";

import {isNMUsers} from "../../../../utils/access";
import {getYearFromDateString} from "../../../../utils/dateFormat";

import {getDictionariesEducationForm} from "../../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsContractorEducationDictSelector} from "../../../../ducks/bff/common/dicts/selectors";
import {
    deleteContractorEducation,
    getContractorEducationLevelDict,
} from "../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {
    contractorEducationLevelDictSelector,
    contractorEducationsSelector,
} from "../../../../ducks/bff/contractor-сard/profile/selectors";

export const EDUCATION_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

export const EDUCATION_OPTIONS = [
    {
        key: EDUCATION_OPTION.EDIT,
        text: "Редактировать",
        value: EDUCATION_OPTION.EDIT,
    },
    {
        key: EDUCATION_OPTION.REMOVE,
        text: "Удалить",
        value: EDUCATION_OPTION.REMOVE,
    },
];

export default function ContractorEducation(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;
    const dispatch = useDispatch();

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const educations = useSelector(contractorEducationsSelector);
    const educationLevelDict = useSelector(contractorEducationLevelDictSelector);
    const educationDict = useSelector(bffCommonDictsContractorEducationDictSelector);

    useEffect(() => {
        dispatch(getDictionariesEducationForm());
        dispatch(getContractorEducationLevelDict());
    }, []);

    const onClickAction = ({value}, item) => {
        const {educationId} = item;

        if (value === EDUCATION_OPTION.EDIT) {
            toggleEditModal(item);
            return;
        }

        if (value === EDUCATION_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem(educationId);
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?",
            });
            setShowConfirm(true);
        }
    };

    const deleteElem = (educationId) => {
        dispatch(deleteContractorEducation({
            contractorId,
            educationId,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    function mapContent() {
        if (!educations.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return educations.map(item => {
            const {
                educationLevel,
                educationForm,
                graduationYear,
                faculty,
                speciality,
                educationInstitutionName,
                educationId,
            } = item;

            return (
                <div
                    className="d-flex flex-column"
                    key={educationId}
                >
                    <div className="flex flex-aligned-center justify-content-between mb-md-2 mb-xxl-2 mb-xl-2">
                        <Text>
                            Год окончания: 
                            {" "}
                            {graduationYear ? getYearFromDateString(graduationYear) : "—"}
                        </Text>
                        {isNMUsers() && <ContextMenu
                            notBorder
                            horizontalIcon
                            transform={false}
                            disabled={false}
                            options={EDUCATION_OPTIONS}
                            onClickItem={(data) => {
                                onClickAction(data, item);
                            }}
                        />}
                    </div>
                    <Text
                        bold
                        className="mb-2"
                    >
                        {educationInstitutionName}
                    </Text>
                    <NmLabelText
                        className="mb-2"
                        label="Уровень образования"
                        text={educationLevelDict[educationLevel]}
                    />
                    {educationForm && <NmLabelText
                        className="mb-2"
                        label="Форма обучения"
                        text={educationDict[educationForm]}
                    />}
                    {faculty && <NmLabelText
                        className="mb-2"
                        label="Факультет"
                        text={faculty}
                    />}
                    {speciality && <NmLabelText
                        className="mb-2"
                        label="Специальность"
                        text={speciality}
                    />}
                </div>
            );
        });
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal && <ContractorEducationEditModal
                editData={editData}
                contractorId={contractorId}
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Образование"
                onClickAdd={() => toggleEditModal()}
                options={EDUCATION_OPTIONS}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}