import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getSubdivisionsPage, updateCommonPaymentDicts} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsSubdivisionsOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

interface UseSubdivisionsParams {
    clientId: string,
    searchQuery?: string,
}

export const useSubdivisions = (params: UseSubdivisionsParams) => {
    const {
        searchQuery,
        clientId,
    } = params;
    const dispatch = useDispatch();
    const options = useSelector(bffCommonDictsSubdivisionsOptionsSelector);

    //TODO: убрать, логика выполняется внутри компонента
    useEffect(() => {
        if (searchQuery) {
            fetchList(searchQuery);
        }
    }, [searchQuery]);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                subdivisions: [],
            }));
        };
    }, []);

    const fetchList = (nameFilter?: string) => {
        dispatch(getSubdivisionsPage({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize: 25,
            nameFilter: nameFilter ? nameFilter : undefined,
        }));
    };

    const onSearchChange = (nameFilter: string) => {
        fetchList(nameFilter);
    };

    return {
        onSearchChange,
        options,
        label: "Структурное подразделение компании",
        placeholder: "Выберите подразделение",
    };
};
