export const COLOR = {
    WHITE: "#ffffff",
    INERT_100: "#FFB713",
    INERT_70: "#FFDB4D",

    PRIMARY_80: "#3C9C3B",
    PRIMARY_100: "#128A10",

    NEGATIVE_50: "#FA8072",
    NEGATIVE_100: "#EE1616",
    PASSIVE_100: "#174A97",
    PASSIVE_50: "#6FA0EB",
    SECONDARY_5: "#F1F3F7",
    SECONDARY_10: "#E4E4E4",
    SECONDARY_35: "#B2B2B2",
    SECONDARY_40: "#B6BAC3",
    SECONDARY_45: "#999999",
    SECONDARY_70: "#666666",
    SECONDARY_75: "#555555",
    SECONDARY_80: "#444444",
    SECONDARY_90: "#333333",
    SECONDARY_100: "#222222",

    BLACK_50: "#7B92A4",
    BLACK_80: "#263742",
    BLACK_100: "#000000",

    OPEN_NAIMIX_PRIMARY_100: "#F25430",
    OPEN_NAIMIX_SECONDARY_100: "#0E6666",
};

export const COLOR_HO_RE_CA = {
    SECONDARY_100: "#14060F",
};