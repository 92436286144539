import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import Task from "../../../../../components/NmTask";
import TableDiv from "../../../../../components/TableDiv";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";

import {usePagination} from "../../../../../hooks/usePagination";
import {useImportTasks} from "../hooks/useImportTasks";
import {useContractorsActions} from "./hooks/useContractorActions";
import {useContractorsFilter} from "./hooks/useContractorFilter";
import {useGetListContractors} from "./hooks/useGetListContractors";

import bem from "../../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {phoneFormat} from "../../../../../utils/stringFormat";

import {groupContractorsListHeaders} from "../../../../../constants/headersTable";
import {LINK_CONTRACTOR_PROFILE} from "../../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../../constants/roles";

import {
    settingsClientGroupsCardContractorsListSelector,
    settingsClientGroupsCardContractorsProgressSelector,
    settingsClientGroupsCardContractorsTotalCountSelector,
    settingsClientGroupsCardContractorsTotalPagesSelector,
} from "../../../../../ducks/bff/settings/client-groups/card/contractors/selectors";
import {settingsClientGroupsCardInfoSelector} from "../../../../../ducks/bff/settings/client-groups/card/selectors";
import {tasksImportContractorsGroupSelector} from "../../../../../ducks/job";

import PropTypes from "prop-types";

import "./style.sass";

const ContractorList = (props) => {
    const {
        groupId,
        isAccessEdit,
    } = props;

    const [block, element] = bem("contractors");

    const list = useSelector(settingsClientGroupsCardContractorsListSelector);
    const totalPage = useSelector(settingsClientGroupsCardContractorsTotalPagesSelector);
    const totalCount = useSelector(settingsClientGroupsCardContractorsTotalCountSelector);
    const loading = useSelector(settingsClientGroupsCardContractorsProgressSelector);
    const role = ls(USER_ROLE);
    const tasksImportContractorsGroup = useSelector(tasksImportContractorsGroupSelector);
    const {groupName} = useSelector(settingsClientGroupsCardInfoSelector);

    const {importProgress} = useImportTasks(tasksImportContractorsGroup, groupId);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {filterDto, setFilterDto} = useContractorsFilter(groupId);

    const {
        fetchList,
    } = useGetListContractors({filter: filterDto, pageSize, pageNum, importProgress});

    const {
        dataModal,
        onCloseModal,
        onClickAddContractor,
        onClickDeleteContractor,
    } = useContractorsActions({
        fetchList,
    });

    const submitFilter = (filter, isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = (filter) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const renderModalWindow = () => {
        const {
            isShow,
            title,
            submit,
            formId,
        } = dataModal;

        if (!isShow) {
            return null;
        }

        return (
            <NmConfirmV2
                content={title}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={!formId && submit}
                onCancel={onCloseModal}
            />
        );
    };

    const renderActions = (item) => {
        if (!isAccessEdit || [NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        return (
            <div className={element("table-action")}>
                <CloseIcon
                    color="grey"
                    className={element("table-action-icon")}
                    onClick={() => onClickDeleteContractor(item, groupName)}
                />
            </div>
        );
    };

    const filter = [
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите ФИО исполнителя",
                    label: "ФИО исполнителя",
                    name: "fioFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.PHONE_WITH_CODE,
                    name: "phoneFilter",
                    label: "Телефон исполнителя",
                },
            ],
        },
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите ИНН исполнителя",
                    maskChar: null,
                    mask: "999999999999999999999999999999999999999999999999",
                    label: "ИНН исполнителя",
                    name: "innFilter",
                },
            ],
        },

    ];

    const mapTableData = () => {
        const headers = groupContractorsListHeaders;
        const rows = list.map(item => {
            const {contractorId, fio} = item;
            const linkContractor = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

            return {
                ...item,
                fio: <Link to={linkContractor}>
                    {fio}
                </Link>,
                phone: phoneFormat(item.phone),
                delete: renderActions(item),
            };
        });

        return {
            rows,
            headers,
        };
    };

    return (
        <NmPage
            headerClassName={!isAccessEdit && "justify-content-end"}
            controls={
                isAccessEdit &&
                ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={() => onClickAddContractor(groupId)}
                >
                    Добавить исполнителя
                </NmButton>
            }
            onChangePageSize={onChangePageSize}
            className={block()}
            onPaginationChange={onPaginationChange}
            totalPages={totalPage}
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            isLoaded={loading}
            filtersBase={
                <Filter
                    onSubmit={submitFilter}
                    clearFilter={clearFilter}
                    filters={filter}
                    isInitStateEqualEmpty={false}
                />
            }
            typeFilter="vertical"
        >
            {!!tasksImportContractorsGroup.length && <Task />}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                    />
                    :
                    <TableDiv
                        className={element("table")}
                        tableData={mapTableData()}
                    />
            }
            {
                renderModalWindow()
            }
        </NmPage>
    );
};

ContractorList.propTypes = {
    groupId: PropTypes.string,
};

export default ContractorList;