import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import UniversityInfoCardEditForm from "./components/edit-form";

import {useContractorAdditionalDocuments} from "../../hooks/useAdditionalDocuments";
import {useMedicalPersonalInfoCardAction} from "./hooks/useAction";

import {getCardModeByStatusSegment} from "../../utils/statusSegment";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../../constants/contractor";

import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";

const MedicalPersonalInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const card = useSelector(contractorCardInfoSelector);

    const {
        contractorId,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useMedicalPersonalInfoCardAction({});

    const {
        requestDocumentButton,
        documentInfo,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
    } = useContractorAdditionalDocuments({
        contractorId,
        documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_CERTIFICATION_REPORT,
    });

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <UniversityInfoCardEditForm
                contractorId={contractorId}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Медицинское освидетельствование (доп. документ)"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            onClickDelete={toggleShowDeleteConfirm}
            isVisibleDeleteButton={isEditable && documentInfo.canBeDeleted}
            accessEdit={isEditable}
            mode={getCardModeByStatusSegment(documentInfo.status)}
            lastUpdate={documentInfo.updateDateTime}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 3,
                desktop: 3,
            }}
            mediaControlsButtons={[requestDocumentButton]}
        >
            {renderDeleteConfirm()}
            {renderEditForm()}
            {
                documentInfo.status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        scans={[
                            {
                                label: "Фотография медицинского заключения с персональной информацией",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN,
                            },
                            {
                                label: "Фотография стороны сертификата об отсутствии ВИЧ",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN,
                            },  {
                                label: "Фотография с подписями и печатями врачей",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN,
                            },
                            {
                                label: "Фотография медицинского заключения с результатами",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN,
                            },
                        ]}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default MedicalPersonalInfoCard;