import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {debounce, isEmpty} from "lodash";

import {ADM_EXPORT_DOCUMENT_STATUS} from "../index";

import {toastError} from "../../../utils/toastHelper";

import {getProgressExportDocuments} from "../../../ducks/bff/export/documents/actionCreators";

function useProgressStatus({list, fetchList}) {
    const [progressList, setProgressList] = useState([]);

    const dispatch = useDispatch();

    const debounceProgress = debounce((data) => {
        getProgressList(data);
    }, 500);

    useEffect(() => {
        const data = progressList.filter(item => item.status === ADM_EXPORT_DOCUMENT_STATUS.IN_PROGRESS);

        if (!isEmpty(data)) {
            debounceProgress(data);
        }

        if (isEmpty(data) && !isEmpty(progressList)) {
            fetchList();
        }

        return () => debounceProgress.cancel();
    }, [progressList]);

    useEffect(() => {
        const data = list.filter(item => item.status === ADM_EXPORT_DOCUMENT_STATUS.IN_PROGRESS);

        if (!isEmpty(data)) {
            getProgressList(data);
        }
    }, [list]);

    const getProgressList = (data) => {
        dispatch(getProgressExportDocuments({
            list: data.map(({exportId, clientId}) => ({
                exportId,
                clientId,
            })),
            getResult: (result) => {
                const {errorMessage} = result;
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }
                setProgressList([...result]);
            },
        }));
    };

    return {
        progressList,
    };
}

export default useProgressStatus;