import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {getOrderManagers, orderClearFieldStore, orderManagersOptionsSelector} from "../../../ducks/order";

export const OrderManagersDropdown = (props) => {
    const {
        clientId,
        ...otherProps
    } = props;

    const dispatch = useDispatch();
    const options = useSelector(orderManagersOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(orderClearFieldStore({
                orderManagers: [],
            }));
        };
    }, []);

    const onSearchChange = (fioFilter) => {
        if (!fioFilter) {
            return;
        }

        dispatch(getOrderManagers({
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            clientId,
            fioFilter: handleFormString(fioFilter),
        }));
    };

    return (
        <NmDropdownV2
            search={true}
            size="lg"
            label="Ответственный"
            placeholder="Не выбрано"
            isClearSearchOnBlur={true}
            options={options}
            onSearchChange={onSearchChange}
            {...otherProps}
        />
    );
};