import React, {useContext} from "react";

import AccessEditField from "../../../../components/ActualComponents/AccessEditField";
import {OrderEditContext} from "../context-provider";

import {ORDER_CREATION_MODE} from "../../../../constants/order";

export const OrderEditAccessField = (props) => {
    const {
        children,
        name,
        className,
        isColumn,
    } = props;

    const {
        setForm,
        formData: {
            templateParams,
        },
        creatingMode,
    } = useContext(OrderEditContext);

    return (
        <AccessEditField
            className={className}
            isAccessEdit={creatingMode === ORDER_CREATION_MODE.TEMPLATE}
            onChange={(_event, {value}) => {
                setForm({
                    templateParams: {
                        ...templateParams,
                        [name]: value,
                    },
                });
            }}
            value={templateParams?.[name]}
            isColumn={isColumn}
        >
            {children}
        </AccessEditField>
    );
};