import {getPageCrowdPayment} from "../../crowd/payment/actionCreators";

const controller = "/adm/contractors/contractors-registry/contractor-card/finances/npd-income/tasks";

export const getPageCrowdPaymentContractorCompleted = (payload) => {
    return getPageCrowdPayment({
        url: `${controller}/completed/page`,
        ...payload,
    });
};

export const getPageCrowdPaymentContractorInProgress = (payload) => {
    return getPageCrowdPayment({
        url: `${controller}/in-progress/page`,
        ...payload,
    });
};

export const getPageCrowdPaymentContractorAwaiting = (payload) => {
    return getPageCrowdPayment({
        url: `${controller}/awaiting/page`,
        ...payload,
    });
};