import {
    EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS,
} from "./actions";

export const getEventsContractorInitialState = () => {
    return {
        aggregatedParamsBySmartLink: {},
        aggregatedParamsBySmartLinkProgress: false,
    };
};

const initialState = getEventsContractorInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST:
            return {
                ...state,
                aggregatedParamsBySmartLinkProgress: true,
            };
        case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS:
            return {
                ...state,
                aggregatedParamsBySmartLinkProgress: false,
                aggregatedParamsBySmartLink: payload,
            };
        case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR:
            return {
                ...state,
                aggregatedParamsBySmartLinkProgress: false,
            };
        case EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK:
            return {
                ...state,
                aggregatedParamsBySmartLinkProgress: initialState.aggregatedParamsBySmartLinkProgress,
                aggregatedParamsBySmartLink: initialState.aggregatedParamsBySmartLink,
            };
        default:
            return state;
    }
};