import React from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

import KedoDocumentRouteStepsListItem from "./components";

import {guid} from "../../utils/stringHelper";

const KedoDocumentRouteStepsList = (props) => {
    const {
        clientId,
        list,
        name,
        onChange,
        onChangeStepTemplates,
        onDeleteStep,
        errors,
        touched,
    } = props;

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const valueCopy = list.slice();
        const [removed] = valueCopy.splice(result.source.index, 1);
        valueCopy.splice(result.destination.index, 0, removed);

        onChange(null, {name, value: valueCopy});
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={guid()}>
                {
                    (provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {
                                list.map((item, index) => (
                                    <KedoDocumentRouteStepsListItem
                                        clientId={clientId}
                                        index={index}
                                        step={item}
                                        errors={errors?.[index]}
                                        touched={touched?.[index]}
                                        onChange={onChangeStepTemplates}
                                        onDelete={onDeleteStep}
                                    />
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};

export default KedoDocumentRouteStepsList;