import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {getContractorVerificationPersonalData} from "../../../../containers/contractor/contractor-verification-data/utils/dto";

import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../constants/contractor";

const contractorPersonalDataSelector = state => state.bff.contractorCardPersonalData;
export const contractorPendingPersonalDataWizardSelector = createSelector(contractorPersonalDataSelector, ({verificationSteps}) => {
    return verificationSteps.map(item => item.name);
});
export const contractorPendingPersonalDataWizardStepNamesSelector = createSelector(contractorPersonalDataSelector, ({verificationSteps}) => {
    return verificationSteps.reduce((result, item) => {
        return {
            ...result,
            [item.name]: item.description,
        };
    }, {});
});
export const contractorPendingPersonalDataProgressWizardSelector = createSelector(contractorPersonalDataSelector, ({progressVerificationSteps}) => progressVerificationSteps);
export const contractorPersonalDataProgressActionSelector = createSelector(contractorPersonalDataSelector, ({progressVerificationSteps}) => progressVerificationSteps);
export const checkInnProgressSelector = createSelector(contractorPersonalDataSelector, ({progressCheckInn}) => progressCheckInn);
export const contractorActionProgressCheckOnEndSelector = createSelector(contractorPersonalDataSelector, ({progressCheckOnEnd}) => progressCheckOnEnd);
export const contractorAdditionalDocumentsProgressUpdateDataSelector = createSelector(contractorPersonalDataSelector, ({progressAdditionalDocumentUpdateData}) => progressAdditionalDocumentUpdateData);
export const contractorAdditionalDocumentsProgressAggregationSelector = createSelector(contractorPersonalDataSelector, ({progressAdditionalDocumentAggregation}) => progressAdditionalDocumentAggregation);
export const contractorAdditionalDocumentsAggregationSelector = createSelector(contractorPersonalDataSelector, ({additionalDocumentAggregation}) => additionalDocumentAggregation);
export const contractorIsAdditionalDocumentsAdministratorCheckSelector = createSelector(contractorPersonalDataSelector, ({additionalDocumentAggregation}) => {
    if (isEmpty(additionalDocumentAggregation)) {
        return false;
    }

    return Object.keys(additionalDocumentAggregation).some((fileType) => {
        return additionalDocumentAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    });
});
// Типы сканов доп. документов для проверки
export const contractorAdditionalDocumentsAdministratorCheckListSelector = createSelector(contractorPersonalDataSelector, ({additionalDocumentAggregation}) => {
    return Object.keys(additionalDocumentAggregation).filter((fileType) => {
        return additionalDocumentAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    });
});
export const contractorAdditionalDocumentsAdministratorCheckFileTypesSelector = createSelector(contractorPersonalDataSelector, ({additionalDocumentAggregation}) => {
    return Object.keys(additionalDocumentAggregation).filter((fileType) => {
        return additionalDocumentAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    }).reduce((result, fileType) => {
        const filesInfo = additionalDocumentAggregation[fileType]?.filesInfo;

        if (!filesInfo) {
            return result;
        }

        const data = Object.keys(filesInfo).reduce((accum, scanFileType) => {
            const fileInfo = filesInfo[scanFileType];

            if (!fileInfo) {
                return accum;
            }

            const {
                fileId,
                contractorId,
            } = fileInfo;

            return {
                ...accum,
                [scanFileType]: {
                    fileId,
                    fileType: scanFileType,
                    changeId: additionalDocumentAggregation[fileType].changeId,
                    contractorId,
                },
            };
        }, {});

        return {
            ...result,
            ...data,
        };
    }, {});
});
export const pendingPersonalDataSelector = createSelector(contractorPersonalDataSelector, ({pendingPersonalData}) => pendingPersonalData);
export const contractorComparisonDataFormSelector = createSelector(contractorPersonalDataSelector, ({comparisonData}) => {
    const {
        pendingPersonalData = {},
        pendingPersonalData: {
            personalData = {},
            noFileFileDecisions,
            files = [],
        } = {},
        previousPersonalData = {},
        previousFiles = [],
    } = comparisonData;

    return {
        pendingPersonalData,
        files: files.concat(noFileFileDecisions || []),
        personalData: !isEmpty(comparisonData) ? getContractorVerificationPersonalData(personalData) : {},
        previousPersonalData: getContractorVerificationPersonalData(previousPersonalData),
        previousFiles,
    };
});
export const migrationComparisonDataProgressSelector = createSelector(contractorPersonalDataSelector, ({progressComparison}) => progressComparison);
export const contractorComparisonDataSavedFilesSelector = createSelector(contractorPersonalDataSelector, ({comparisonData}) => {
    const {
        pendingPersonalData: {
            files = [],
            rejectionReasons,
        } = {},
    } = comparisonData;

    return files.filter(({decisionType}) => decisionType).map(({type, decisionType, fileId}) => ({
        [type]: {
            decisionType,
            fileId,
            fileType: type,
            rejectionReason: rejectionReasons?.[type] || "",
        },
    }));
});
export const contractorBankStatusSelector = createSelector(contractorPersonalDataSelector, ({contractorBankStatus}) => contractorBankStatus);