import {all} from "redux-saga/effects";
import {createSelector} from "reselect";

const SSE_UPDATE_EVENT_DATA = "SSE_UPDATE_EVENT_DATA";

const initial = {
    event: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SSE_UPDATE_EVENT_DATA:
            return {
                ...state,
                event: payload,
            };
        default:
            return state;
    }
};

export function updateSseEventData(payload) {
    return {
        type: SSE_UPDATE_EVENT_DATA,
        payload,
    };
}

export const sseEventSelector = state => state.serverSentEvents;
export const sseEventDataSelector = createSelector(sseEventSelector, ({event}) => event);

export function* saga() {
    yield all([

    ]);
}