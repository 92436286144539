import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FinancePivot from "../../../components/FinancePivot";

import {getFinancePivotTableHeaders} from "../../../components/FinancePivot/utils/getTableHeaders";

import {
    getNdflSummaryDepositHistory,
    getNdflSummaryReports,
    updateNdflSummaryStore,
} from "../../../ducks/bff/finances/ndfl-summary/actionCreators";
import {
    bffFinancesNdflSummaryListSelector,
    bffFinancesNdflSummaryProgressReportsSelector,
    bffFinancesNdflSummaryProgressSelector,
    bffFinancesNdflSummaryTotalCountSelector,
    bffFinancesNdflSummaryTotalPagesSelector,
    bffFinancesNdflSummaryTotalSummarySelector,
} from "../../../ducks/bff/finances/ndfl-summary/selectors";

const headers = getFinancePivotTableHeaders({defaultDateSortType: "asc", isCivil: true});

const FinancePivotGph = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(bffFinancesNdflSummaryListSelector);
    const summary = useSelector(bffFinancesNdflSummaryTotalSummarySelector);
    const totalCount = useSelector(bffFinancesNdflSummaryTotalCountSelector);
    const totalPages = useSelector(bffFinancesNdflSummaryTotalPagesSelector);
    const progressDepositHistory = useSelector(bffFinancesNdflSummaryProgressSelector);
    const progressReports = useSelector(bffFinancesNdflSummaryProgressReportsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateNdflSummaryStore({
                reports: [],
                totalSummary: {},
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchList = (data) => {
        dispatch(getNdflSummaryReports(data));
    };

    const onClickHistory = (data) => {
        dispatch(getNdflSummaryDepositHistory(data));
    };

    return (
        <FinancePivot
            header="Сводная (НДФЛ)"
            defaultDateSortType="ASC"
            list={list}
            headers={headers}
            summary={summary}
            totalCount={totalCount}
            totalPages={totalPages}
            fetchList={fetchList}
            onClickHistory={onClickHistory}
            progressDepositHistory={progressDepositHistory}
            progressReports={progressReports}
            {...props}
        />
    );
};

export default FinancePivotGph;