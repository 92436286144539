import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import {
    bffRecruitmentAccessControlRecruitersGroupsCardListLoadingSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardListSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardListTotalCountSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardListTotalPagesSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardNameSelector,
} from "../../../../../ducks/bff/recruitment/access-control/recruiters-groups/selectors";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {RecruitmentAccessControlRecruitersGroupsCardAddRecruiters} from "./components/add-form";

import {usePagination} from "../../../../../hooks/usePagination";
import {useRecruitmentAccessControlRecruitersGroupsCardAction} from "./hooks/useAction";
import {useRecruitmentAccessControlRecruitersGroupsCardFetchList} from "./hooks/useFetchList";
import {useRecruitmentAccessControlRecruitersGroupsCardFilter} from "./hooks/useFilter";

import {getUserRole} from "../../../../../utils/access";
import {getFullName, phoneFormat} from "../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
} from "../../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, RECRUITER} from "../../../../../constants/roles";

export const ClientRecruitmentAccessControlRecruitersGroupsCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                id,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardListSelector);
    const listLoading = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardListLoadingSelector);
    const listTotalCount = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardListTotalCountSelector);
    const listTotalPages = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardListTotalPagesSelector);
    const cardName = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardNameSelector);

    const role = getUserRole();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useRecruitmentAccessControlRecruitersGroupsCardFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentAccessControlRecruitersGroupsCardFetchList({
        groupId: id,
        pageSize,
        pageNum,
        filterData,
    });

    const {
        onCloseModal,
        onOpenModal,
        modalData,
        onDelete,
        onAdd,
    } = useRecruitmentAccessControlRecruitersGroupsCardAction({
        fetchList,
        groupId: id,
    });

    const getRows = () => {
        return list.map((item) => {
            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={getFullName(item.lastName, item.firstName, item.patronymic)}
                        isFixedActions
                        noDivider
                        mediaControls={{
                            renderCount: {
                                desktop: 1,
                                tablet: 1,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: () => onOpenModal({
                                            isOpenDeleteModal: true,
                                            clientUserId: item.clientUserId,
                                            content: "Вы действительно хотите удалить пользователя из группы?",
                                        }),
                                        children: "Удалить рекрутера",
                                    },
                                    visible: [ADMIN, CLIENT_ADMIN, RECRUITER].includes(role),
                                },
                            ],
                        }}
                        labels={[
                            {label: "Должность", text: item.position || "-"},
                            {label: "Телефон", text: phoneFormat(item.phone)},
                            {label: "E-mail", text: item.email},
                        ]}
                    />
                ),
            };
        });
    };

    const renderDeleteModal = () => {
        if (!modalData?.isOpenDeleteModal) {
            return null;
        }

        return (
            <NmConfirmV2
                content={modalData.content}
                confirmButton="Удалить"
                cancelButton="Отменить"
                onConfirm={onDelete}
                onCancel={onCloseModal}
            />
        );
    };

    const renderAddModal = () => {
        if (!modalData?.isOpenAddModal) {
            return null;
        }

        return (
            <RecruitmentAccessControlRecruitersGroupsCardAddRecruiters
                onClose={onCloseModal}
                groupId={id}
                onAdd={onAdd}
                clientId={clientId}
            />
        );
    };

    return (
        <NmPage
            isLoaded={listLoading}
            header={
                <NmPageHeader
                    text={cardName}
                    buttonBackSize="lg"
                    backLink={
                        LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS
                            .replace(":clientId", clientId)
                    }
                />
            }
            typeFilter="vertical"
            controls={
                [ADMIN, CLIENT_ADMIN, RECRUITER].includes(role) &&
                <NmButton
                    size="xl"
                    icon={<AddIcon />}
                    onClick={() => onOpenModal({
                        isOpenAddModal: true,
                    })}
                >
                    Добавить рекрутера
                </NmButton>
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            totalPages={listTotalPages}
            totalCount={listTotalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
        >
            {renderDeleteModal()}
            {renderAddModal()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2 isSearch={isSearch} /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};