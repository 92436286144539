import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

import {UPLOAD_STATUS} from "../../../../constants/financeExport";

export const bffExportReportsSelector = state => state.bff.exportReports;
export const bffExportReportsListSelector  = createSelector(bffExportReportsSelector, ({list}) => list);
export const bffExportReportsTotalCountSelector  = createSelector(bffExportReportsSelector, ({totalCount}) => totalCount);
export const bffExportReportsTotalPagesSelector  = createSelector(bffExportReportsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffExportReportsProgressSelector  = createSelector(bffExportReportsSelector, ({progress}) => progress);
export const bffExportReportsActionSelector  = createSelector(bffExportReportsSelector, ({progressAction}) => progressAction);
export const bffExportReportsEdoAccessListSelector  = createSelector(bffExportReportsSelector, ({edoAccessList}) => edoAccessList);
export const bffExportReportsTaskIdsSelector = createSelector(bffExportReportsSelector, ({list}) => {
    return list
        .filter(({uploadStatus}) => uploadStatus === UPLOAD_STATUS.INITIALIZATION.key)
        .map(({taskId}) => (taskId))
        .filter(Boolean);
});
