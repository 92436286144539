import {createSelector} from "reselect";

import {filterObject} from "../../../utils/objectHelper";

import {CONTRACTOR_CARD_FIELDS} from "../../../constants/contractorInfo";

export const contractorCardSelector = state => state.bff.contractorCard;
export const contractorCardNoteSelector = createSelector(
    contractorCardSelector,
    ({note}) => note,
);
export const contractorCardInfoSelector = createSelector(
    contractorCardSelector,
    ({card}) => card,
);
export const contractorPaymentDataSelector = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.PAYMENT_DATA, card),
);
export const contractorInitialPersonalData = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.INITIAL_PERSONAL_DATA, card),
);
export const contractorArrivalNoticeData = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.ARRIVAL_NOTICE_DATA, card),
);
export const contractorPassportData = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.PASSPORT_DATA, card),
);
export const contractorEmploymentHistoryData = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.EMPLOYMENT_HISTORY, card),
);
export const contractorSnilsInfo = createSelector(contractorCardSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.SNILS_INFO, card),
);
export const contractorCardRatingsSelector = createSelector(
    contractorCardSelector,
    ({ratings}) => ratings,
);
export const contractorCardProgressActionSelector = createSelector(
    contractorCardSelector,
    ({progressAction}) => progressAction,
);