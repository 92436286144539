import axios from "axios";
import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {clientCurrentMemberSelector} from "./clientMember";
import {getUserAvatarListThumbnails} from "./fileStore";
import {getAllTasks} from "./job";

import {getWorkAddressInfoArr} from "../utils/dadata";
import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {ADVERTISEMENT_TOAST_MESSAGE} from "../containers/advertisement/list/constants";

const controller = "/advertisement";

const GET_ADVERTISEMENT_PAGE_REQUEST = "GET_ADVERTISEMENT_PAGE_REQUEST";
const GET_ADVERTISEMENT_PAGE_SUCCESS = "GET_ADVERTISEMENT_PAGE_SUCCESS";
const GET_ADVERTISEMENT_PAGE_ERROR = "GET_ADVERTISEMENT_PAGE_ERROR";

const GET_MAP_INFO_REQUEST = "GET_MAP_INFO_REQUEST";
const GET_MAP_INFO_SUCCESS = "GET_MAP_INFO_SUCCESS";
const GET_MAP_INFO_ERROR = "GET_MAP_INFO_ERROR";

const DOWNLOAD_CONTRACTORS_GEO_INFO_REQUEST = "DOWNLOAD_CONTRACTORS_GEO_INFO_REQUEST";
const DOWNLOAD_CONTRACTORS_GEO_INFO_SUCCESS = "DOWNLOAD_CONTRACTORS_GEO_INFO_SUCCESS";
const DOWNLOAD_CONTRACTORS_GEO_INFO_ERROR = "DOWNLOAD_CONTRACTORS_GEO_INFO_ERROR";

const ADD_ADVERTISEMENT_DRAFT_REQUEST = "ADD_ADVERTISEMENT_DRAFT_REQUEST";
const ADD_ADVERTISEMENT_DRAFT_SUCCESS = "ADD_ADVERTISEMENT_DRAFT_SUCCESS";
const ADD_ADVERTISEMENT_DRAFT_ERROR = "ADD_ADVERTISEMENT_DRAFT_ERROR";

const ADD_ADVERTISEMENT_PUBLISH_REQUEST = "ADD_ADVERTISEMENT_PUBLISH_REQUEST";
const ADD_ADVERTISEMENT_PUBLISH_SUCCESS = "ADD_ADVERTISEMENT_PUBLISH_SUCCESS";
const ADD_ADVERTISEMENT_PUBLISH_ERROR = "ADD_ADVERTISEMENT_PUBLISH_ERROR";

const UPDATE_ADVERTISEMENT_REQUEST = "UPDATE_ADVERTISEMENT_REQUEST";
const UPDATE_ADVERTISEMENT_SUCCESS = "UPDATE_ADVERTISEMENT_SUCCESS";
const UPDATE_ADVERTISEMENT_ERROR = "UPDATE_ADVERTISEMENT_ERROR";

const GET_ADVERTISEMENT_REQUEST = "GET_ADVERTISEMENT_REQUEST";
const GET_ADVERTISEMENT_SUCCESS = "GET_ADVERTISEMENT_SUCCESS";
const GET_ADVERTISEMENT_ERROR = "GET_ADVERTISEMENT_ERROR";

const ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST = "ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST";
const ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS = "ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS";
const ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR = "ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR";

const ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST = "ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST";
const ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS = "ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS";
const ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR = "ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR";

const ADVERTISEMENT_CLOSE_REQUEST = "ADVERTISEMENT_CLOSE_REQUEST";
const ADVERTISEMENT_CLOSE_SUCCESS = "ADVERTISEMENT_CLOSE_SUCCESS";
const ADVERTISEMENT_CLOSE_ERROR = "ADVERTISEMENT_CLOSE_ERROR";

const ADVERTISEMENT_IMPORT_LIST_REQUEST = "ADVERTISEMENT_IMPORT_LIST_REQUEST";
const ADVERTISEMENT_IMPORT_LIST_SUCCESS = "ADVERTISEMENT_IMPORT_LIST_SUCCESS";
const ADVERTISEMENT_IMPORT_LIST_ERROR = "ADVERTISEMENT_IMPORT_LIST_ERROR";

const ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST = "ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST";
const ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS = "ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS";
const ADVERTISEMENT_CONTRACTORS_COUNT_ERROR = "ADVERTISEMENT_CONTRACTORS_COUNT_ERROR";


const GET_ADVERTISEMENT_VIEW_PAGE_REQUEST = "GET_ADVERTISEMENT_VIEW_PAGE_REQUEST";
const GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS = "GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS";
const GET_ADVERTISEMENT_VIEW_PAGE_ERROR = "GET_ADVERTISEMENT_VIEW_PAGE_ERROR";

const ADVERTISEMENT_ORDER_INVITE_REQUEST = "ADVERTISEMENT_ORDER_INVITE_REQUEST";
const ADVERTISEMENT_ORDER_INVITE_SUCCESS = "ADVERTISEMENT_ORDER_INVITE_SUCCESS";
const ADVERTISEMENT_ORDER_INVITE_ERROR = "ADVERTISEMENT_ORDER_INVITE_ERROR";

const GET_ADVERTISEMENT_WITH_ORDERS_PAGE_REQUEST = "GET_ADVERTISEMENT_WITH_ORDERS_PAGE_REQUEST";
const GET_ADVERTISEMENT_WITH_ORDERS_PAGE_SUCCESS = "GET_ADVERTISEMENT_WITH_ORDERS_PAGE_SUCCESS";
const GET_ADVERTISEMENT_WITH_ORDERS_PAGE_ERROR = "GET_ADVERTISEMENT_WITH_ORDERS_PAGE_ERROR";

const ADVERTISEMENT_FUNNEL_PAGE_REQUEST = "ADVERTISEMENT_FUNNEL_PAGE_REQUEST";
const ADVERTISEMENT_FUNNEL_PAGE_SUCCESS = "ADVERTISEMENT_FUNNEL_PAGE_SUCCESS";
const ADVERTISEMENT_FUNNEL_PAGE_ERROR = "ADVERTISEMENT_FUNNEL_PAGE_ERROR";

const CLEAR_FIELDS_ADVERTISEMENT_STORE = "CLEAR_FIELDS_ADVERTISEMENT_STORE";

const initial = {
    list: [],
    card: {
        baseModel: {},
    },
    contractorList: [],
    progress: false,
    progressAction: false,
    progressAdd: false,
    progressContractorList: false,
    address: {},
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    contractorsPageData: {
        pageSize: 25,
        pageNum: 1,
    },
    funnels: {
        list: [],
        pageData: {},
        progress: false,
        countMap: {},
        totalCount: 0,
    },
    contractorsTotalCount: 0,
    mapInfo: {},
    listWithOrders: [],
    pageDataWithOrders: {},
    totalCountWithOrders: 0,
    progressWithOrders: false,

};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ADVERTISEMENT_FUNNEL_PAGE_REQUEST:
            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    pageData: payload,
                    progress: true,
                },
            };
        case ADVERTISEMENT_FUNNEL_PAGE_SUCCESS: {
            const {
                contractors,
                totalCount,
                applicationCountMap,
            } = payload;

            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    totalCount,
                    list: contractors,
                    countMap: applicationCountMap,
                    progress: false,
                },
            };
        }
        case ADVERTISEMENT_FUNNEL_PAGE_ERROR: {
            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    progress: false,
                },
            };
        }
        case UPDATE_ADVERTISEMENT_ERROR:
        case UPDATE_ADVERTISEMENT_SUCCESS:
        case ADD_ADVERTISEMENT_PUBLISH_ERROR:
        case ADD_ADVERTISEMENT_PUBLISH_SUCCESS:
        case ADD_ADVERTISEMENT_DRAFT_SUCCESS:
        case ADD_ADVERTISEMENT_DRAFT_ERROR:
            return {
                ...state,
                progressAdd: false,
            };
        case UPDATE_ADVERTISEMENT_REQUEST:
        case ADD_ADVERTISEMENT_PUBLISH_REQUEST:
        case ADD_ADVERTISEMENT_DRAFT_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case DOWNLOAD_CONTRACTORS_GEO_INFO_REQUEST:
        case GET_MAP_INFO_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_ADVERTISEMENT_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_ADVERTISEMENT_WITH_ORDERS_PAGE_REQUEST:
            return {
                ...state,
                progressWithOrders: true,
                pageDataWithOrders: payload,
            };
        case ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ADVERTISEMENT_ORDER_INVITE_REQUEST:
            return {
                ...state,
                progressAction: true,
                actionPageData: payload,
            };
        case ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST:
        case ADVERTISEMENT_CLOSE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case GET_ADVERTISEMENT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_ADVERTISEMENT_VIEW_PAGE_REQUEST:
            return {
                ...state,
                progressContractorList: true,
            };
        case DOWNLOAD_CONTRACTORS_GEO_INFO_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case GET_MAP_INFO_SUCCESS:
            return {
                ...state,
                progress: false,
                mapInfo: payload,
            };
        case GET_ADVERTISEMENT_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case GET_ADVERTISEMENT_WITH_ORDERS_PAGE_SUCCESS:
            const {
                results: listWithOrders,
                totalCount: totalCountWithOrders,
            } = payload;

            return {
                ...state,
                progressWithOrders: false,
                listWithOrders,
                totalCountWithOrders,
            };
        case ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS:
        case ADVERTISEMENT_CLOSE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case GET_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS:
            const {
                results: contractorList,
                totalCount: contractorsTotalCount,
            } = payload;
            return {
                ...state,
                contractorList,
                contractorsTotalCount,
                progressContractorList: false,
            };
        case CLEAR_FIELDS_ADVERTISEMENT_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_ADVERTISEMENT_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case GET_ADVERTISEMENT_WITH_ORDERS_PAGE_ERROR:
            return {
                ...state,
                progressWithOrders: false,
                error: payload,
            };
        case ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR:
            return {
                ...state,
                progress: false,
            };
        case ADVERTISEMENT_ORDER_INVITE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case ADVERTISEMENT_ORDER_INVITE_SUCCESS:
        case ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR:
        case ADVERTISEMENT_CLOSE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case DOWNLOAD_CONTRACTORS_GEO_INFO_ERROR:
        case GET_ADVERTISEMENT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case GET_ADVERTISEMENT_VIEW_PAGE_ERROR:
            return {
                ...state,
                progressContractorList: false,
            };
        default:
            return state;
    }
};

export function getContractorsGeoInfoDoc(payload) {
    return {
        type: DOWNLOAD_CONTRACTORS_GEO_INFO_REQUEST,
        payload,
    };
}

export function getAdvertisementPage(payload) {
    return {
        type: GET_ADVERTISEMENT_PAGE_REQUEST,
        payload,
    };
}

export function addAdvertisementDraft(payload) {
    return {
        type: ADD_ADVERTISEMENT_DRAFT_REQUEST,
        payload,
    };
}

export function updateAdvertisement(payload) {
    return {
        type: UPDATE_ADVERTISEMENT_REQUEST,
        payload,
    };
}

export function addAdvertisementPublish(payload) {
    return {
        type: ADD_ADVERTISEMENT_PUBLISH_REQUEST,
        payload,
    };
}

export function getAdvertisement(payload) {
    return {
        type: GET_ADVERTISEMENT_REQUEST,
        payload,
    };
}

export function importAdvertisementList(payload) {
    return {
        type: ADVERTISEMENT_IMPORT_LIST_REQUEST,
        payload,
    };
}

export function clearFieldsAdvertisementStore(payload) {
    return {
        type: CLEAR_FIELDS_ADVERTISEMENT_STORE,
        payload,
    };
}

export function getAdvertisementContractorsCount(payload) {
    return {
        type: ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST,
        payload,
    };
}

export function advertisementPublishMultipleCheck(payload) {
    return {
        type: ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST,
        payload,
    };
}

export function advertisementPublishMultiple(payload) {
    return {
        type: ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST,
        payload,
    };
}

export function advertisementClose(payload) {
    return {
        type: ADVERTISEMENT_CLOSE_REQUEST,
        payload,
    };
}

export function getAdvertisementViewPage(payload) {
    return {
        type: GET_ADVERTISEMENT_VIEW_PAGE_REQUEST,
        payload,
    };
}

export function advertisementOrderInvite(payload) {
    return {
        type: ADVERTISEMENT_ORDER_INVITE_REQUEST,
        payload,
    };
}

export function getAdvertisementFunnelPage(payload) {
    return {
        type: ADVERTISEMENT_FUNNEL_PAGE_REQUEST,
        payload,
    };
}

export function getAdvertisementWithOrdersPage(payload) {
    return {
        type: GET_ADVERTISEMENT_WITH_ORDERS_PAGE_REQUEST,
        payload,
    };
}

export function getMapInfo(payload) {
    return {
        type: GET_MAP_INFO_REQUEST,
        payload,
    };
}


export const advertisementSelector = state => state.advertisement;

export const getAdvertisementTotalPagesSelector = createSelector(advertisementSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const getAdvertisementTotalCountSelector = createSelector(advertisementSelector, ({totalCount}) => totalCount);
export const getAdvertisementListSelector = createSelector(advertisementSelector, ({list}) => list);
export const getAdvertisementListFormattedToTaskIdsSelector = createSelector(advertisementSelector, ({list}) =>
    list.map(value => `${value.baseModel.clientId}:${value.baseModel.advertId}`),
);
export const getAdvertisementCardSelector = createSelector(advertisementSelector, ({card}) => card);
export const getAdvertisementContractorsTotalPagesSelector = createSelector(advertisementSelector,
    ({contractorsTotalCount, contractorsPageData: {pageSize = 0}}) =>
        getTotalPages(contractorsTotalCount, pageSize),
);
export const getAdvertisementContractorsTotalCountSelector = createSelector(advertisementSelector, ({contractorsTotalCount}) => contractorsTotalCount);
export const getAdvertisementContractorListSelector = createSelector(advertisementSelector, ({contractorList}) => contractorList);
export const getAdvertisementProgressSelector = createSelector(advertisementSelector, ({progress, progressAction, progressContractorList}) => ({
    progress,
    progressAction,
    progressContractorList,
}));

const funnelsSelector = (state) => state.advertisement.funnels;
export const advertisementFunnelsListSelector = createSelector(
    funnelsSelector,
    ({list}) => {
        return list.map(item => {
            const {
                workAddressInfo,
                departureAddressesInfo,
            } = item;

            return {
                ...item,
                workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
            };
        });
    },
);
export const advertisementFunnelsContractorIdsSelector = createSelector(
    funnelsSelector,
    ({list}) => {
        return [...new Set(list.map(item => item.contractorId))];
    },
);
export const advertisementFunnelsTotalCountSelector = createSelector(
    funnelsSelector,
    ({totalCount}) => totalCount,
);

export const advertisementFunnelsProgressSelector = createSelector(
    funnelsSelector,
    ({progress}) => progress,
);
export const advertisementFunnelsCountMapSelector = createSelector(
    funnelsSelector,
    ({countMap}) => countMap,
);
export const advertisementFunnelsPageDataSelector = createSelector(
    funnelsSelector,
    ({pageData}) => pageData,
);

export const getAdvertisementMarkersProgressSelector = createSelector(advertisementSelector, ({progress}) => (progress));

export const getAdvertisementWithOrdersTotalPagesSelector = createSelector(advertisementSelector, ({
    totalCountWithOrders,
    pageDataWithOrders: {pageSize = 0},
}) => getTotalPages(totalCountWithOrders, pageSize));
export const getAdvertisementWithOrdersTotalCountSelector = createSelector(advertisementSelector, ({totalCountWithOrders}) => totalCountWithOrders);
export const getAdvertisementWithOrdersListSelector = createSelector(advertisementSelector, ({listWithOrders}) => listWithOrders);
export const progressWithOrdersListSelector = createSelector(advertisementSelector, ({progressWithOrders}) => progressWithOrders);

export const mapInfoSelector = createSelector(advertisementSelector, ({mapInfo}) => mapInfo);

export const advertisementErrorSelector = createSelector(advertisementSelector, ({error}) => error);
export const advertisementActionPageDataSelector = createSelector(advertisementSelector, ({actionPageData}) => actionPageData);

//POST /api/advertisement/add/publish
const addAdvertisementPublishSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;
        const result = yield request.post(`${controller}/add/publish`, formData, {...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ADD_ADVERTISEMENT_PUBLISH_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.PUBLISH.SUCCESS);
        onSuccess();

        yield put({
            type: ADD_ADVERTISEMENT_PUBLISH_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ADD_ADVERTISEMENT_PUBLISH_ERROR,
        });
    }
};
//GET/api/advertisement/get/rich возвращает обогащенное объявление по идентификатору
const getAdvertisementSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...params
            },
        } = action;
        const result = yield request.get(`${controller}/get/rich`, {params});

        getResult && getResult(result);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: GET_ADVERTISEMENT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ADVERTISEMENT_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: GET_ADVERTISEMENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/add/draft сохранить черновик объявления
const addAdvertisementDraftSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;
        const result = yield request.post(`${controller}/add/draft`, formData, {...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ADD_ADVERTISEMENT_DRAFT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.ADD_DRAFT.SUCCESS);
        onSuccess();

        yield put({
            type: ADD_ADVERTISEMENT_DRAFT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ADD_ADVERTISEMENT_DRAFT_ERROR,
        });
    }
};

const getAdvertisementPageSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/page/rich`, data);

        getResult && getResult();

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ADVERTISEMENT_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: GET_ADVERTISEMENT_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/before/publish/multiple
const advertisementPublishMultipleCheckSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                requestData,
            },
        } = action;
        const result = yield request.post(`${controller}/before/publish/multiple`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/publish/multiple
const advertisementPublishMultipleSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess = () => {
                },
                requestData,
                isShowToastSuccess = false,
            },
        } = action;
        const result = yield request.post(`${controller}/publish/multiple`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        onSuccess();
        isShowToastSuccess && toastSuccess("Задача на публикацию объявлений передана в обработку");

        yield put({
            type: ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/advertisement/close
const advertisementCloseSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            requestData,
            toastSuccess: toastSuccessMsg,
        } = payload;
        const result = yield request.post(`${controller}/close`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADVERTISEMENT_CLOSE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        onSuccess();
        toastSuccessMsg && toastSuccess(toastSuccessMsg);

        yield put({
            type: ADVERTISEMENT_CLOSE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ADVERTISEMENT_CLOSE_ERROR,
            payload: error,
        });
    }
};

///api/advertisement/import/{clientId}
const importAdvertisementListSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
                getResult,
                formData,
            },
        } = action;
        const result = yield request.post(`${controller}/import/${clientId}`, formData, {...getMultipartHeaders()});

        getResult && getResult(result);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: ADVERTISEMENT_IMPORT_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: ADVERTISEMENT_IMPORT_LIST_ERROR,
            payload: error,
        });
    }
};

//POST
// /api/advertisement/count/contractors
// возвращает количество исполнителей подходящих под условия сегментации объявления

const getAdvertisementContractorsCountSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                onSuccess,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/count/contractors`, data);

        getResult && getResult(result);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: ADVERTISEMENT_CONTRACTORS_COUNT_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/view/page/rich
const getAdvertisementViewPageSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/view/page/rich`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ADVERTISEMENT_VIEW_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult && getResult();

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({baseModel: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {

    }
};

//POST /api/advertisement/order/add
const advertisementOrderInviteSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess = () => {},
                onError = () => {},
                isMassOperation,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/order/addAll`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ADVERTISEMENT_ORDER_INVITE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Выбранные исполнители успешно приглашены");
        onSuccess();

        yield put({
            type: ADVERTISEMENT_ORDER_INVITE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ADVERTISEMENT_ORDER_INVITE_ERROR,
            payload: error,
        });
    }
};

const getContractorsGeoInfoDocSaga = function* ({payload}) {
    try {
        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };
        const result = yield request.getFile(`${window.location.origin}/api/job/export/getContractorOrderAdvertExport`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                yield put({
                    type: DOWNLOAD_CONTRACTORS_GEO_INFO_ERROR,
                });

                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        yield downloadBlob(blob, headers);

        yield put({type: DOWNLOAD_CONTRACTORS_GEO_INFO_SUCCESS, payload: result});

    } catch (error) {
        yield put({
            type: DOWNLOAD_CONTRACTORS_GEO_INFO_ERROR,
        });
    }
};

export let mapMarkersCancelToken = axios.CancelToken.source();

const getMapInfoSaga = function* ({payload}) {
    try {
        const {
            onRequest,
            ...reqData
        } = payload;


        if (onRequest) {
            onRequest();
        }

        mapMarkersCancelToken = axios.CancelToken.source();

        const result = yield request.axios({
            method: "POST",
            url: `${controller}/getMapAdvertOrderContractor`,
            data: reqData,
            cancelToken: mapMarkersCancelToken.token,
        });

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: GET_MAP_INFO_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_MAP_INFO_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: GET_MAP_INFO_ERROR,
        });
    }
};

//POST /api/advertisement/update - редактировать объявление
const updateAdvertisementSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;
        const result = yield request.post(`${controller}/update`, formData, {...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: UPDATE_ADVERTISEMENT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.UPDATE.SUCCESS);
        onSuccess();

        yield put({
            type: UPDATE_ADVERTISEMENT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: UPDATE_ADVERTISEMENT_ERROR,
        });
    }
};

//POST /api/advertisement/pageWithOrders
const getAdvertisementWithOrdersPageSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${controller}/pageWithOrders`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ADVERTISEMENT_WITH_ORDERS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ADVERTISEMENT_WITH_ORDERS_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: GET_ADVERTISEMENT_WITH_ORDERS_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /api/advertisement/funnel
const getAdvertisementFunnelPageSaga = function* ({payload}) {
    const {
        isSearch,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(`${controller}/funnel`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADVERTISEMENT_FUNNEL_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ADVERTISEMENT_FUNNEL_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: ADVERTISEMENT_FUNNEL_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ADVERTISEMENT_PAGE_REQUEST, getAdvertisementPageSaga),
        takeEvery(ADD_ADVERTISEMENT_PUBLISH_REQUEST, addAdvertisementPublishSaga),
        takeEvery(ADD_ADVERTISEMENT_DRAFT_REQUEST, addAdvertisementDraftSaga),
        takeEvery(GET_ADVERTISEMENT_REQUEST, getAdvertisementSaga),
        takeEvery(ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST, advertisementPublishMultipleCheckSaga),
        takeEvery(ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST, advertisementPublishMultipleSaga),
        takeEvery(ADVERTISEMENT_CLOSE_REQUEST, advertisementCloseSaga),
        takeEvery(ADVERTISEMENT_IMPORT_LIST_REQUEST, importAdvertisementListSaga),
        takeEvery(UPDATE_ADVERTISEMENT_REQUEST, updateAdvertisementSaga),
        takeEvery(GET_MAP_INFO_REQUEST, getMapInfoSaga),
        takeEvery(DOWNLOAD_CONTRACTORS_GEO_INFO_REQUEST, getContractorsGeoInfoDocSaga),
        takeEvery(ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST, getAdvertisementContractorsCountSaga),
        takeEvery(GET_ADVERTISEMENT_VIEW_PAGE_REQUEST, getAdvertisementViewPageSaga),
        takeEvery(ADVERTISEMENT_ORDER_INVITE_REQUEST, advertisementOrderInviteSaga),
        takeEvery(GET_ADVERTISEMENT_WITH_ORDERS_PAGE_REQUEST, getAdvertisementWithOrdersPageSaga),
        takeEvery(ADVERTISEMENT_FUNNEL_PAGE_REQUEST, getAdvertisementFunnelPageSaga),
    ]);
}