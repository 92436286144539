import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {RecruitmentCandidateAboutYourself} from "../components/AboutYourself";
import {RecruitmentCandidateAnyInformation} from "../components/Any";
import {RecruitmentCandidateDriverLicences} from "../components/DriverLicences";
import {RecruitmentCandidateEducation} from "../components/Education";
import {RecruitmentCandidateExperience} from "../components/Experience";
import {RecruitmentCandidateFiles} from "../components/Files";
import {RecruitmentCandidateForeignLanguages} from "../components/ForeignLanguages";
import {RecruitmentCandidateHasCar} from "../components/HasCar";
import {RecruitmentCandidateReadingMoving} from "../components/ReadingMoving";
import {RecruitmentCandidateReadingTrips} from "../components/ReadingTrips";
import {RecruitmentCandidateResumes} from "../components/Resumes";
import {RecruitmentCandidateSalary} from "../components/Salary";
import {RecruitmentCandidateSchedules} from "../components/Schedules";
import {RecruitmentCandidateWorkStage} from "../components/WorkStage";
import {useRecruitmentCandidateInformationDicts} from "./useDicts";

import {isVisibleRecruitmentActions} from "../../../../utils/access";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {getRecruitmentCandidateInformationValues} from "../utils/getAdditionalValues";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

export const useRecruitmentCandidateInformationValues = (params) => {
    const {
        card,
        fields,
        isVisibleResumes,
    } = params;

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const isAccessActions = isVisibleRecruitmentActions()
        && isAccessByRestrictions({
            currentUserRestrictions,
            checkList: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.manageCandidatesAdmin,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
            ],
        });

    const {
        driverLicenseDict,
        languageDict,
        educationLevelDict,
        schedulesDict,
        workExperienceDict,
    } = useRecruitmentCandidateInformationDicts();

    const getAdditionalInformation = () => {
        if (isEmpty(card.anyInformation)) {
            return [];
        }

        return card.anyInformation.map((information, index) => {
            return {
                content: <RecruitmentCandidateAnyInformation
                    values={card.anyInformation}
                    index={index}
                    information={information}
                    isAccessActions={isAccessActions}
                />,
            };
        });
    };

    const values = useMemo(() => {
        if (fields) {
            return fields;
        }

        const {
            education,
            workExperience,
            driverLicences,
            hasCar,
            languages,
            schedules,
            readyForTrip,
            readyForMoving,
            salary,
        } = getRecruitmentCandidateInformationValues(card, {
            educationLevelDict,
            workExperienceDict,
            driverLicenseDict,
            languageDict,
            schedulesDict,
        });

        return [
            {
                label: "Опыт работы",
                content: <RecruitmentCandidateExperience
                    value={isEmpty(card.experiences) ? "-" : card.experiences}
                    isAccessActions={isAccessActions}
                />,
            },
            isVisibleResumes && {
                label: "Резюме",
                content: <RecruitmentCandidateResumes
                    jobBoardsResumes={card.jobBoardsResumes}
                    otherResumes={card.otherResumes}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "О себе",
                content: <RecruitmentCandidateAboutYourself
                    aboutYourself={card.aboutYourself}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Стаж работы",
                content: <RecruitmentCandidateWorkStage
                    value={card.workExperience}
                    content={workExperience}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Образование",
                content: <RecruitmentCandidateEducation
                    value={card.education}
                    content={education}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Зарплата",
                content: <RecruitmentCandidateSalary
                    value={card.salary}
                    content={salary}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Прикрепленные файлы",
                content: <RecruitmentCandidateFiles
                    files={card.files}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Категории водительских прав",
                content: <RecruitmentCandidateDriverLicences
                    value={card.driverLicences}
                    content={driverLicences}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Есть свой автомобиль",
                content: <RecruitmentCandidateHasCar
                    value={card.hasCar}
                    content={hasCar}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Иностранные языки",
                content: <RecruitmentCandidateForeignLanguages
                    value={card.languages}
                    content={languages}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Подходящий график работы",
                content: <RecruitmentCandidateSchedules
                    value={card.schedules}
                    content={schedules}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Готовность к командировкам",
                content: <RecruitmentCandidateReadingTrips
                    value={card.readyForTrip}
                    content={readyForTrip}
                    isAccessActions={isAccessActions}
                />,
            },
            {
                label: "Возможность переезда",
                content: <RecruitmentCandidateReadingMoving
                    value={card.readyForMoving}
                    content={readyForMoving}
                    isAccessActions={isAccessActions}
                />,
            },
            ...getAdditionalInformation(),
        ].filter(Boolean);
    }, [
        card,
        languageDict,
        driverLicenseDict,
        educationLevelDict,
    ]);

    return {
        values,
    };
};