export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_ERROR";

export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_ERROR";

export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_ERROR";

export const UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENT_USERS_STORE = "UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENT_USERS_STORE";