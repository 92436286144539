import {
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE,
} from "./actions";

const initialState = {
    list: [],
    pageData: {},
    card: {},
    progress: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR:
            return {
                ...state,
                progressAdd: false,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS:
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};