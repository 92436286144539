import {
    ADD_FILE_TO_MODEL_REQUEST,
    ADD_ML_MODEL_REQUEST,
    DELETE_ML_MODEL_REQUEST,
    DELETE_MODEL_FILE_REQUEST,
    GET_ML_MODEL_FILE_LIST_REQUEST,
    GET_ML_MODEL_LIST_REQUEST,
    ML_MODELS_PREDICT_AMOUNT_REQUEST,
    ML_MODELS_STORE_CLEAR,
    SET_MAIN_MODEL_REQUEST,
    UPDATE_ML_MODEL_REQUEST,
    UPDATE_MODEL_FILE_REQUEST,
} from "./actions";

export const getMlModelList = payload => {
    return {
        type: GET_ML_MODEL_LIST_REQUEST,
        payload,
    };
};

export const addMlModel = payload => {
    return {
        type: ADD_ML_MODEL_REQUEST,
        payload,
    };
};

export const updateMlModel = payload => {
    return {
        type: UPDATE_ML_MODEL_REQUEST,
        payload,
    };
};

export const setMainModel = payload => {
    return {
        type: SET_MAIN_MODEL_REQUEST,
        payload,
    };
};

export const deleteMLModelFile = payload => {
    return {
        type: DELETE_MODEL_FILE_REQUEST,
        payload,
    };
};

export const deleteMlModel = payload => {
    return {
        type: DELETE_ML_MODEL_REQUEST,
        payload,
    };
};

export const addFileToMlModel = payload => {
    return {
        type: ADD_FILE_TO_MODEL_REQUEST,
        payload,
    };
};

export const getMlModelFileList = payload => {
    return {
        type: GET_ML_MODEL_FILE_LIST_REQUEST,
        payload,
    };
};

export const updateModelFile = payload => {
    return {
        type: UPDATE_MODEL_FILE_REQUEST,
        payload,
    };
};

export function predictMlModelsAmount(payload) {
    return {
        type: ML_MODELS_PREDICT_AMOUNT_REQUEST,
        payload,
    };
}

export function updateMlModelsStore(payload) {
    return {
        type: ML_MODELS_STORE_CLEAR,
        payload,
    };
}