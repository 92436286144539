import {getUserRole} from "../../../../utils/access";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

export const getSettingsDirectoriesAccessActions = ({roles, currentUserRestrictions}) => {
    const role = getUserRole();

    return (
        roles.includes(role)
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingDirectory)
    );
};