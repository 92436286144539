export const BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST = "BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST";
export const BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS = "BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS";
export const BFF_SETTINGS_EMPLOYEES_GET_PAGE_ERROR = "BFF_SETTINGS_EMPLOYEES_GET_PAGE_ERROR";

export const BFF_SETTINGS_EMPLOYEES_ADD_REQUEST = "BFF_SETTINGS_EMPLOYEES_ADD_REQUEST";
export const BFF_SETTINGS_EMPLOYEES_ADD_SUCCESS = "BFF_SETTINGS_EMPLOYEES_ADD_SUCCESS";
export const BFF_SETTINGS_EMPLOYEES_ADD_ERROR = "BFF_SETTINGS_EMPLOYEES_ADD_ERROR";

export const BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST = "BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST";
export const BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS = "BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS";
export const BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR = "BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR";

export const BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST = "BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST";
export const BFF_SETTINGS_EMPLOYEES_ARCHIVE_SUCCESS = "BFF_SETTINGS_EMPLOYEES_ARCHIVE_SUCCESS";
export const BFF_SETTINGS_EMPLOYEES_ARCHIVE_ERROR = "BFF_SETTINGS_EMPLOYEES_ARCHIVE_ERROR";

export const BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST = "BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST";
export const BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS = "BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS";
export const BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR = "BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR";

export const UPDATE_SETTINGS_EMPLOYEES_STORE = "UPDATE_SETTINGS_EMPLOYEES_STORE";