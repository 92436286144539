import {
    BFF_CLIENTS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS,
    BFF_CLIENTS_INSTRUCTIONS_KEDO_UPDATE_STORE,
} from "./actions";

export const getClientKedoInstructionsInitialState = () => {
    return {
        list: [],
        progress: false,
        progressAction: false,
    };
};

const initialState = getClientKedoInstructionsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_CLIENTS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS: {
            return {
                ...state,
                list: payload,
            };
        }
        case BFF_CLIENTS_INSTRUCTIONS_KEDO_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default: return state;
    }
};