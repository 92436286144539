import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsClientGroupsCardContractorsSelector = state => state.bff.settingsClientGroupsCardContractors;
export const settingsClientGroupsCardContractorsTotalPagesSelector = createSelector(settingsClientGroupsCardContractorsSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const settingsClientGroupsCardContractorsTotalCountSelector = createSelector(settingsClientGroupsCardContractorsSelector, ({totalCount}) => totalCount);
export const settingsClientGroupsCardContractorsListSelector = createSelector(settingsClientGroupsCardContractorsSelector, ({list}) => list);
export const settingsClientGroupsCardContractorsProgressSelector = createSelector(settingsClientGroupsCardContractorsSelector, ({progress}) => progress);
export const settingsClientGroupsCardContractorsProgressActionSelector = createSelector(settingsClientGroupsCardContractorsSelector, ({progressAction}) => progressAction);
