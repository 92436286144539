import React from "react";
import {useSelector} from "react-redux";
import {withRouter} from "react-router";

import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Tabs from "../../../../components/ActualComponents/Tabs";
import Text from "../../../../components/ActualComponents/Text";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import HoReCaIndicator from "../../../../components/HoReCaIndicator";
import MassConfirm from "../../../../components/MassConfirm";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as HelpResourceIcon} from "../../../../images/help-resource-indicator.svg";
import TicketFilter from "../filter";
import TicketSettings from "../settings";
import TransferTicketModal from "../transfer-modal";

import useTicketListAction, {initTicketFilter} from "./hooks/useTicketListAction";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getFormattedFullName, getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CONTRACTOR_CLIENT_GROUP_TYPE} from "../../../../constants/clientGroup";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../constants/color";
import {SUB_PAGE_CRM_TICKET_CARD, TICKET_STATUS_DICT} from "../../../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_CRM_TICKET_CARD,
    LINK_CRM_TICKET_LIST,
} from "../../../../constants/links";
import {ADMIN, NM_COORDINATOR, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";
import {
    hasContractorTicketsInWorkSelector,
    ticketCallTypeDictSelector,
    ticketCategoryDictSelector,
    ticketCountsSelector,
    ticketListSelector,
    ticketProgressActionSelector,
    ticketProgressChangeOperatorSelector,
    ticketProgressListSelector,
    ticketTotalCountSelector,
    ticketTotalPagesSelector,
    ticketTypeDictSelector,
} from "../../../../ducks/crm/ticket";
import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";

import "./style.sass";

import {SUB_PAGE_CRM_TICKET} from "../../../../constants/link-params";

const CrmTicketList = (props) => {
    const {
        subpage,
        clientId,
        contractorId,
    } = props.match.params;
    const role = ls(USER_ROLE) || "";

    const isChatsSubpage = [
        SUB_PAGE_CRM_TICKET.CHATS.LINK,
        SUB_PAGE_CRM_TICKET.KEDO.LINK,
    ].includes(subpage);

    const isEditable = [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role);

    const list = useSelector(ticketListSelector);
    const progress = useSelector(ticketProgressListSelector);
    const totalCount = useSelector(ticketTotalCountSelector);
    const totalPages = useSelector(ticketTotalPagesSelector);
    const ticketCategoryDict = useSelector(ticketCategoryDictSelector);
    const ticketCallTypeDict = useSelector(ticketCallTypeDictSelector);
    const ticketTypeDict = useSelector(ticketTypeDictSelector);
    const ticketCounts = useSelector(ticketCountsSelector);
    const progressChangeOperator = useSelector(ticketProgressChangeOperatorSelector);
    const progressAction = useSelector(ticketProgressActionSelector);
    const hasContractorTicketsInWork = useSelector(hasContractorTicketsInWorkSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        closeTickets,
        getStatusFilter,
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
        onChangeFilter,
        filter,
        setFilter,
        setFilterDto,
        isSearch,
        setIsSearch,
        confirmData,
        cancelConfirm,
        isOpenFilter,
        setOpenFilter,
        changeOperator,
        isOpenTransferTicketModal,
        setOpenTransferTicketModal,
        addTicket,
    } = useTicketListAction({
        subpage,
        clientId,
        contractorId,
    });

    const showCheckBox = isEditable
        && getStatusFilter() !== SUB_PAGE_CRM_TICKET.CLOSED.STATUS
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.changeTickets);

    function getListLink() {
        if (clientId) {
            return LINK_CLIENT_CARD_CRM_TICKET_LIST.replace(":clientId", clientId);
        }

        if (contractorId) {
            return LINK_CONTRACTOR_CARD_CRM_TICKET_LIST.replace(":contractorId", contractorId);
        }

        return LINK_CRM_TICKET_LIST;
    }

    function getTabs() {
        const listLink = getListLink();

        const settingsTab = !clientId && !contractorId ?
            [
                {
                    name: SUB_PAGE_CRM_TICKET.SETTINGS.TEXT,
                    link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.SETTINGS.LINK),
                    active: subpage === SUB_PAGE_CRM_TICKET.SETTINGS.LINK,
                },
            ] : [];

        return [
            {
                name: SUB_PAGE_CRM_TICKET.CHATS.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.CHATS.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.CHATS.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.CHATS.COUNT_NAME],
            },
            {
                name: SUB_PAGE_CRM_TICKET.IN_WORK.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.IN_WORK.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.IN_WORK.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.IN_WORK.STATUS],
            },
            {
                name: SUB_PAGE_CRM_TICKET.RECALL.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.RECALL.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.RECALL.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.RECALL.STATUS],
            },
            {
                name: SUB_PAGE_CRM_TICKET.MISSED.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.MISSED.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.MISSED.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.MISSED.STATUS],
            },
            {
                name: SUB_PAGE_CRM_TICKET.IN_PROCESS.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.IN_PROCESS.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.IN_PROCESS.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.IN_PROCESS.STATUS],
            },
            {
                name: SUB_PAGE_CRM_TICKET.KEDO.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.KEDO.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.KEDO.LINK,
                count: ticketCounts.categoriesCount?.[SUB_PAGE_CRM_TICKET.KEDO.COUNT_NAME],
                isVisible: !contractorId,
            },
            {
                name: SUB_PAGE_CRM_TICKET.CLOSED.TEXT,
                link: listLink.replace(":subpage", SUB_PAGE_CRM_TICKET.CLOSED.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET.CLOSED.LINK,
            },
            ...settingsTab,
        ];
    }

    function getCardLink() {
        if (clientId) {
            return LINK_CLIENT_CARD_CRM_TICKET_CARD.replace(":clientId", clientId);
        }
        if (contractorId) {
            return LINK_CONTRACTOR_CRM_TICKET_CARD.replace(":contractorId", contractorId);
        }

        return LINK_CRM_TICKET_CARD;
    }

    function toCard(content, ticketId) {
        const linkCard = getCardLink()
            .replace(":subpage", subpage)
            .replace(":ticketId", ticketId)
            .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK);

        return (
            <ExtLink
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={linkCard}
            >
                {content}
            </ExtLink>
        );
    }

    function renderLabelByModels(item) {
        const {
            clientUserModel,
            contractorModel,
            kedoStaffModel,
            clientModel,
        } = item;

        if (kedoStaffModel) {
            const {
                lastName,
                firstName,
                patronymic,
                clientId,
                kedoStaffId,
            } = kedoStaffModel;

            const {
                name,
            } = clientModel;

            const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);
            const staffLink = LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId);

            const _fio = getFormattedFullName(getFullName(lastName, firstName, patronymic));

            return (
                <>
                    <Text
                        level="3"
                        isCursorPointer={true}
                        title="Перейти в карточку компании"
                        className="me-2"
                        color={COLOR.PASSIVE_100}
                        onClick={() => {
                            history.push(
                                clientLink,
                            );
                        }}
                    >
                        {name}
                    </Text>
                    <Text
                        level="3"
                        isCursorPointer={true}
                        title="Перейти в карточку сотрудника"
                        color={COLOR.PASSIVE_100}
                        onClick={() => {
                            history.push(
                                staffLink,
                                {
                                    isViewMode: true,
                                    kedoStaffId,
                                },
                            );
                        }}
                    >
                        {_fio}
                    </Text>
                </>
            );
        }

        if (contractorModel) {
            const {
                lastName,
                firstName,
                patronymic,
                fio,
                contractorId,
            } = contractorModel || {};

            const _fio = lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : fio;
            const linkCard = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

            return (
                <div className="flex align-items-center">
                    <ExtLink
                        pageData={{
                            pageSize,
                            pageNum,
                            isSearch,
                        }}
                        filterData={filter}
                        historyEnabled
                        to={linkCard}
                    >
                        <div className="ticket-list__label">
                            {_fio}
                        </div>
                    </ExtLink>
                    {
                        !isChatsSubpage &&
                        contractorModel.contractorClientGroupType === CONTRACTOR_CLIENT_GROUP_TYPE.HELP_RESOURCES &&
                        <NmAdvancedTooltip
                            className="ms-1 me-1"
                            children='Исполнитель в закрытом контуре "HelpResource"'
                            hover
                            position="bottom-left"
                            trigger={
                                <HelpResourceIcon
                                    width={16}
                                    height={16}
                                />
                            }
                        />
                    }
                    {
                        !isChatsSubpage &&
                        getIndicator({
                            contractorModel,
                            className: "ms-1 me-1",
                        })
                    }
                </div>
            );
        }

        if (clientUserModel && clientModel) {
            const {
                lastName,
                firstName,
                patronymic,
                fio,
            } = clientUserModel || {};
            const {name, clientId} = clientModel;

            const _fio = lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : fio;
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);
            return <div>
                <ExtLink
                    pageData={{
                        pageSize,
                        pageNum,
                        isSearch,
                    }}
                    filterData={filter}
                    historyEnabled
                    to={linkCard}
                    className="d-flex flex-wrap"
                >
                    <div
                        className="ticket-list__label"
                        title={name}
                    >
                        {name}
                    </div>
                    <div className="ticket-list__fio">
                        {_fio}
                    </div>
                </ExtLink>
            </div>;
        }

        return "Не определено";
    }

    const getLabelText = (item) => {
        const {
            baseModel,
            contractorModel,
            kedoStaffModel,
        } = item;

        const {
            locatedOutsideRussia,
        } = contractorModel || {};

        const phone = kedoStaffModel ? kedoStaffModel.phone : baseModel.fromNumber;
        const _locatedOutsideRussia = kedoStaffModel ? kedoStaffModel.locatedOutsideRussia : locatedOutsideRussia;

        return (
            <div className="flex align-items-center">
                {renderLabelByModels(item)}
                <div className="ms-1">
                    {phoneFormat(phone, _locatedOutsideRussia)}
                </div>
            </div>
        );
    };

    const getIndicator = (data) => {
        const {
            contractorModel,
            className,
        } = data;

        if (contractorModel?.contractorClientGroupType === CONTRACTOR_CLIENT_GROUP_TYPE.HO_RE_CA) {
            return <HoReCaIndicator className={className} />;
        }

        return null;
    };

    function getRows() {
        return list.map(item => {
            const {
                baseModel: {
                    createDate,
                    orderNumber,
                    type,
                    category,
                    callType,
                    ticketId,
                    status,
                    contractorId,
                    unreadCountMessages,
                },
                managerModel,
                contractorModel,
            } = item;
            const {
                lastName,
                firstName,
                patronymic,
            } = managerModel || {};

            const {isSelected = false} = selectedList.find(item => (item.ticketId === ticketId)) || {};
            const fillNameManager = getFormattedFullName(getFullName(lastName, firstName, patronymic));
            const ticketCallTypeCards = !isChatsSubpage ? [
                {
                    title: "Источник",
                    className: "col-16 col-lg-3 col-xl-3 col-xxl-2",
                    value: ticketCallTypeDict[callType] || "-",
                    overflowEllipseText: true,
                },
            ] : [];

            return {
                ...item,
                ticketId,
                showCheckBox: true,
                isSelected,
                isWithSubHeader: true,
                avatar: (
                    isChatsSubpage &&
                    <Avatar
                        isKedo={subpage === SUB_PAGE_CRM_TICKET.KEDO.LINK}
                        size="md"
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                        counter={unreadCountMessages}
                        showCheckBox={showCheckBox}
                        contractorClientGroupType={contractorModel?.contractorClientGroupType}
                        indicator={getIndicator({contractorModel})}
                    />
                ),
                contentRow: (
                    <NmListCard
                        checkbox
                        avatar={isChatsSubpage}
                        primaryHeaderLink
                        secondaryHeaderStatus={
                            isChatsSubpage &&
                            <NmBadge
                                noWrap
                                mod={TICKET_STATUS_DICT[status]?.MOD}
                                text={TICKET_STATUS_DICT[status]?.TEXT}
                            />
                        }
                        secondaryHeader={`Обращение от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={toCard(`Обращение №${orderNumber} - ${type ? ticketTypeDict[type] : "Не определено"}`, ticketId)}
                        cards={[
                            {
                                title: "Категория обращения",
                                className: `col-16 col-lg-3 col-xl-3 ${isOpenFilter ? "col-xxl-3" : "col-xxl-2"}`,
                                value: ticketCategoryDict[category] || "-",
                                overflowEllipseText: true,
                            },
                            ...ticketCallTypeCards,
                            {
                                title: "Ответственный",
                                className: "col-16 col-lg-3 col-xl-3 col-xxl-2",
                                value: lastName ? fillNameManager : "-",
                                overflowEllipseText: true,
                                titleText: lastName ? fillNameManager : undefined,
                            },
                        ]}
                        classNameMainContent={`col-16 col-xl-7 col-lg-7 ${isOpenFilter ? "col-xxl-9" : "col-xxl-10"}`}
                        cardsContainerClassName={`col-16 col-xl-9 col-lg-9 ${isOpenFilter ? "col-xxl-7" : "col-xxl-6"} mt-md-4 mt-xxl-0`}
                        labels={[
                            {
                                text: getLabelText(item),
                                className: "flex-aligned-end col-md-14 col-xl-14 col-xxl-14",
                                noWrap: false,
                                flexWrap: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    const submitFilter = (filter, isSearch) => {
        setFilterDto(filter);
        setPagination({pageSize, pageNum: 1});
        setIsSearch(isSearch);
    };

    return (
        <NmPage
            noPadding={Boolean(clientId || contractorId)}
            openFilter={!(clientId || contractorId) && isOpenFilter}
            widthByFilter
            typeFilter="vertical"
            subHeader={
                <Tabs panes={getTabs()} />
            }
            filtersBase={
                <TicketFilter
                    isShowFieldClientNameAndPhone={!(clientId || contractorId)}
                    isShowCallTypeFilter={!isChatsSubpage}
                    subpage={subpage}
                    role={role}
                    onChangeFilter={onChangeFilter}
                    submitFilter={submitFilter}
                    setFilter={setFilter}
                    initFilter={initTicketFilter}
                    filter={filter}
                />
            }
            onOpenFilter={() => setOpenFilter(!isOpenFilter)}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            header={
                <NmPageHeader
                    text={clientId || contractorId ? "Обращения" : "Список обращений"}
                    totalCount={!contractorId && (ticketCounts.totalCount || totalCount)}
                />
            }
            mediaControls={{
                renderCount: {
                    desktop: 1,
                    tablet: 1,
                    mobile: 1,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            disabled: progressAction || hasContractorTicketsInWork,
                            onClick: addTicket,
                            children: "Создать обращение",
                            color: "green",
                            icon: <AddIcon />,
                        },
                        visible: Boolean(contractorId)
                            && subpage === SUB_PAGE_CRM_TICKET.CHATS.LINK
                            && ![NM_COORDINATOR].includes(role),
                    },
                ],
            }}
            totalCount={totalCount}
            isLoaded={progress}
        >
            <TransferTicketModal
                loading={progressChangeOperator}
                onSubmit={changeOperator}
                isOpened={isOpenTransferTicketModal}
                onClose={() => setOpenTransferTicketModal(false)}
            />
            {
                subpage !== SUB_PAGE_CRM_TICKET.SETTINGS.LINK &&
                <>
                    {
                        confirmData.content &&
                    <MassConfirm
                        publicationCount={confirmData.publicationCount}
                        list={confirmData.content}
                        impossibleCount={confirmData.impossibleCount}
                        title="Закрытие обращений"
                        text="Вы действительно хотите закрыть представленные обращения?"
                        successLabel="К закрытию"
                        confirmButton="Подтвердить"
                        cancelButton="Отменить"
                        unablePublishTooltip='Обращения, которые были
                     выбраны в списке, но не могут быть закрыты
                     по причине незаполненных обязательных полей, наличия непрочитанных сообщений или несоответствия вашей учетной записи и ответственного в обращении в статусе
                      "Обрабатывается"'
                        onConfirm={confirmData.confirm}
                        onCancel={cancelConfirm}
                    />
                    }
                    {
                        list.length === 0 &&
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        title="Информация отсутствует"
                        fetchProgress={progress}
                    />
                    }
                    {
                        list.length > 0 &&
                    <CheckboxList
                        count={countSelected}
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 2,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        disabled: !countSelected,
                                        onClick: () => setOpenTransferTicketModal(true),
                                        children: "Перевести",
                                        color: "green",
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        disabled: !countSelected,
                                        onClick: closeTickets,
                                        children: "Закрыть обращения",
                                        color: "grey",
                                    },
                                    visible: !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.closeRequestCrm),
                                },
                            ],
                        }}
                        onSelectedRows={showCheckBox && handleSelectedRows}
                        rows={getRows()}
                    />
                    }
                </>
            }
            {subpage === SUB_PAGE_CRM_TICKET.SETTINGS.LINK && <TicketSettings />}
        </NmPage>
    );
};

export default withRouter(CrmTicketList);