import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {mapMetricForChart} from "../../../../../utils/objectHelper";

export const foremenTimesheetsSelector = state => state.bff.foremenTimesheets;

export const foremenTimesheetTotalPagesSelector = createSelector(
    foremenTimesheetsSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const foremenTimesheetListSelector = createSelector(
    foremenTimesheetsSelector,
    ({list}) => list,
);
export const foremenTimesheetMetricSelector = createSelector(
    foremenTimesheetsSelector,
    ({metrics}) => mapMetricForChart(metrics),
);
export const foremenTimesheetTotalCountSelector = createSelector(
    foremenTimesheetsSelector,
    ({totalCount}) => totalCount,
);
export const foremenTimesheetProgressSelector = createSelector(
    foremenTimesheetsSelector,
    ({progress}) => progress,
);
