import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientCategoriesPage} from "./actionCreators";
import {
    ADD_CLIENT_CATEGORIES_ERROR,
    ADD_CLIENT_CATEGORIES_REQUEST,
    ADD_CLIENT_CATEGORIES_SUCCESS,
    DELETE_CLIENT_CATEGORIES_ERROR,
    DELETE_CLIENT_CATEGORIES_REQUEST,
    DELETE_CLIENT_CATEGORIES_SUCCESS,
    GET_PAGE_CLIENT_CATEGORIES_ERROR,
    GET_PAGE_CLIENT_CATEGORIES_REQUEST,
    GET_PAGE_CLIENT_CATEGORIES_SUCCESS,
    UPDATE_CLIENT_CATEGORIES_ERROR,
    UPDATE_CLIENT_CATEGORIES_REQUEST,
    UPDATE_CLIENT_CATEGORIES_SUCCESS,
} from "./actions";
import {getSettingsDirectoriesNaimixClientCategoriesPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/order-categories";

const fetchList = function* () {
    const state = yield select();
    const pageData = getSettingsDirectoriesNaimixClientCategoriesPageDataSelector(state);

    yield put(getClientCategoriesPage(pageData));
};

const getClientCategoriesPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_CLIENT_CATEGORIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_CLIENT_CATEGORIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_CLIENT_CATEGORIES_ERROR,
            payload: error,
        });
    }
};

const addClientCategoriesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_CATEGORIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Категория успешно создана");

        yield fetchList();

        yield put({
            type: ADD_CLIENT_CATEGORIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_CLIENT_CATEGORIES_ERROR,
            payload: error,
        });
    }
};

const updateClientCategoriesSaga = function* ({payload}) {
    try {
        const {
            orderCategoryUuid,
            orderCategory,
        } = payload;

        const result = yield request.bff.patch(`${controller}/${orderCategoryUuid}`, orderCategory);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_CATEGORIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        yield fetchList();

        yield put({
            type: UPDATE_CLIENT_CATEGORIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_CATEGORIES_ERROR,
            payload: error,
        });
    }
};

const deleteClientCategoriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            orderCategoryUuid,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${orderCategoryUuid}`);
        const {errorMessage, results} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError && onError(results);

            yield put({
                type: DELETE_CLIENT_CATEGORIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Категория успешно удалена");

        yield fetchList();

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_CLIENT_CATEGORIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CLIENT_CATEGORIES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_CLIENT_CATEGORIES_REQUEST, getClientCategoriesPageSaga),
        takeEvery(UPDATE_CLIENT_CATEGORIES_REQUEST, updateClientCategoriesSaga),
        takeEvery(DELETE_CLIENT_CATEGORIES_REQUEST, deleteClientCategoriesSaga),
        takeEvery(ADD_CLIENT_CATEGORIES_REQUEST, addClientCategoriesSaga),
    ]);
}