import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_TASK_STATS_ERROR,
    GET_TASK_STATS_REQUEST,
    GET_TASK_STATS_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";

const controller = "/adm/settings/service/task-stats";

const getTaskStatsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_TASK_STATS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_TASK_STATS_SUCCESS, payload: result || []});
    } catch (error) {
        yield put({type: GET_TASK_STATS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_TASK_STATS_REQUEST, getTaskStatsSaga),
    ]);
}