import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {InfoMark} from "../../../../../components/ActualComponents/InfoMark";
import {MediaButtons} from "../../../../../components/ActualComponents/MediaControls";
import NmDivider from "../../../../../components/ActualComponents/NmDivider";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";
import Steps from "../../../../../components/Steps";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../../images/mode.svg";
import {SmartLinkClientEdit} from "../client-settings-edit";
import {SmartLinkCount} from "../count";

import {useModal} from "../../../../../hooks/useModal";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../../constants/color";
import {SMART_LINK_EDIT_STEP, SMART_LINK_EDIT_STEPS} from "../../constants";

import {
    createSmartLink,
    deleteSmartLinkAllCachedClients,
    deleteSmartLinkCachedClient,
    getSmartLinkCachedClients,
    updateSmartLink,
    updateSmartLinkStore,
} from "../../../../../ducks/smart-link/actionCreators";
import {
    smartLinkCachedClientIdsSelector,
    smartLinkCachedClientsSelector,
    smartLinkProgressActionSelector,
    smartLinkProgressCardSelector,
    smartLinkProgressClearAllSelector,
    smartLinkProgressClientIdSelector,
} from "../../../../../ducks/smart-link/selectors";

export const SmartLinkClientSettings = (props) => {
    const {
        onClose,
        goBack,
        fetchList,
        setIsHiddenModal,
        isEdit,
        isDuplicate,
    } = props;

    const smartLinkId = props.values.smartLinkId;

    const dispatch = useDispatch();

    const progressAction = useSelector(smartLinkProgressActionSelector);
    const clients = useSelector(smartLinkCachedClientsSelector);
    const clientIds = useSelector(smartLinkCachedClientIdsSelector);
    const progressCard = useSelector(smartLinkProgressCardSelector);
    const progressClientId = useSelector(smartLinkProgressClientIdSelector);
    const progressClearAll = useSelector(smartLinkProgressClearAllSelector);

    const localModals = useModal();

    useEffect(() => {
        if (!smartLinkId || !isEmpty(clients)) {
            return;
        }

        dispatch(getSmartLinkCachedClients({
            smartLinkId,
        }));

        return () => {
            dispatch(updateSmartLinkStore({
                progressSearch: false,
                progressClientSettings: false,
                progressClearAll: false,
                progressClientId: "",
            }));
        };
    }, []);

    const onCloseModal = () => {
        localModals.onCloseModal();

        setIsHiddenModal(false);
    };

    const {
        handleSubmit,
        values,
    } = useFormik({
        onSubmit,
        initialValues: {
            clientIds,
        },
        enableReinitialize: true,
        validateOnBlur: false,
    });

    function onSubmit() {
        if (isEdit && !isDuplicate) {
            dispatch(updateSmartLink({
                smartLinkId,
                clientIds,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(createSmartLink({
            smartLinkId,
            clientIds,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        }));
    }

    if (localModals.modalData?.isOpenAddClient) {
        return (
            <SmartLinkClientEdit
                isEdit={localModals.modalData.isEdit}
                smartLinkId={smartLinkId}
                clientId={localModals.modalData.clientId}
                clientName={localModals.modalData.clientName}
                onClose={onCloseModal}
            />
        );
    }

    const getClients = () => {
        return clients.map((item) => {
            const {
                clientName,
                clientId,
            } = item;

            return (
                <div
                    key={clientId}
                    className="d-flex align-items-center justify-content-between"
                >
                    <Text
                        color={COLOR.SECONDARY_100}
                        level="3"
                        noWrap={true}
                        className="me-2 me-md-5"
                    >
                        {`Заказчик: ${clientName}`}
                    </Text>
                    <MediaButtons
                        inline={true}
                        config={{
                            renderCount: {
                                mobile: 0,
                                tablet: 2,
                                desktop: 2,
                            },
                            className: "ms-1",
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        onClick: () => {
                                            localModals.onOpenModal({
                                                isOpenAddClient: true,
                                                isEdit: true,
                                                clientId: item.clientId,
                                                clientName: item.clientName,
                                            });
                                        },
                                        icon: <ModeIcon />,
                                        color: "grey",
                                        onlyIcon: true,
                                    },
                                    asset: {
                                        mobile: {
                                            children: "Редактировать",
                                        },
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        onClick: () => {
                                            dispatch(deleteSmartLinkCachedClient({
                                                clientId,
                                                smartLinkId,
                                                onSuccess: () => {
                                                    dispatch(getSmartLinkCachedClients({
                                                        smartLinkId,
                                                    }));
                                                },
                                            }));
                                        },
                                        disabled: progressClientId === clientId,
                                        color: "grey",
                                        onlyIcon: true,
                                        icon: <DeleteIcon />,
                                    },
                                    asset: {
                                        mobile: {
                                            children: "Удалить",
                                        },
                                    },
                                },
                            ],
                        }}
                    />
                </div>
            );
        });
    };

    return (
        <NmModal
            size="md"
            isShowBackButton={true}
            isBackIconAlwaysVisible={true}
            goBack={goBack}
            header={
                <NmTitle size="lg">
                    Создание ссылки
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    additionalBtnContent="Назад"
                    onClickAdditional={goBack}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                    disabled={progressAction}
                />
            }
            loading={progressCard}
            onClose={onClose}
            classNameContent="flex-column"
        >
            <NmForm addMargin>
                <Steps
                    withAdditionalParams={true}
                    items={SMART_LINK_EDIT_STEPS}
                    activeItems={[
                        SMART_LINK_EDIT_STEP.GENERAL_FILTERS,
                        SMART_LINK_EDIT_STEP.CLIENT_SETTINGS,
                    ]}
                />
                <InfoMark
                    isOutline={true}
                    text="Здесь можно настроить заказчиков и их объекты. Допустимо выбрать максимум 100 заказчиков"
                />
                <div className="flex-column flex-md-row d-flex mb-3 mb-md-4">
                    <NmButton
                        size="lg"
                        color="light-green"
                        icon={<AddIcon />}
                        disabled={clients.length === 100}
                        onClick={() => {
                            localModals.onOpenModal({
                                isOpenAddClient: true,
                            });

                            setIsHiddenModal(true);
                        }}
                    >
                        Добавить заказчика
                    </NmButton>
                    {
                        !isEmpty(values.clientIds) &&
                        <NmButton
                            size="lg"
                            color="grey"
                            className="mt-2 mt-md-0 ms-md-2"
                            disabled={progressClearAll}
                            onClick={() => {
                                dispatch(deleteSmartLinkAllCachedClients({
                                    smartLinkId,
                                    clientIds,
                                    onSuccess: () => {
                                        dispatch(getSmartLinkCachedClients({
                                            smartLinkId,
                                        }));
                                    },
                                }));
                            }}
                        >
                            Очистить все
                        </NmButton>
                    }
                </div>
                {getClients()}
                {
                    !isEmpty(clients) &&
                    <NmDivider
                        className="my-2 my-md-3"
                        color={COLOR.SECONDARY_10}
                    />
                }
                <SmartLinkCount
                    values={{
                        ...props.values,
                        clientIds: values.clientIds,
                    }}
                />
            </NmForm>
        </NmModal>
    );
};