export const  SETTINGS_NAIMIX_INFO_LOAD_REQUEST = "SETTINGS_NAIMIX_INFO_LOAD_REQUEST";
export const  SETTINGS_NAIMIX_INFO_LOAD_SUCCESS = "SETTINGS_NAIMIX_INFO_LOAD_SUCCESS";
export const  SETTINGS_NAIMIX_INFO_LOAD_ERROR = "SETTINGS_NAIMIX_INFO_LOAD_ERROR";

export const  SETTINGS_NAIMIX_INFO_SAVE_REQUEST = "SETTINGS_NAIMIX_INFO_SAVE_REQUEST";
export const  SETTINGS_NAIMIX_INFO_SAVE_SUCCESS = "SETTINGS_NAIMIX_INFO_SAVE_SUCCESS";
export const  SETTINGS_NAIMIX_INFO_SAVE_ERROR = "SETTINGS_NAIMIX_INFO_SAVE_ERROR";

export const  SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST = "SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST";
export const  SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS = "SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS";
export const  SETTINGS_NAIMIX_INFO_GET_FILE_ERROR = "SETTINGS_NAIMIX_INFO_GET_FILE_ERROR";

export const  SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST = "SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST";
export const  SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS = "SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS";
export const  SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR = "SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR";

export const UPDATE_SETTINGS_NAIMIX_INFO_STORE = "UPDATE_SETTINGS_NAIMIX_INFO_STORE";