export const SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST = "SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST";
export const SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS = "SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR = "SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR";

export const SETTINGS_CLIENT_GROUP_ADD_REQUEST = "SETTINGS_CLIENT_GROUP_ADD_REQUEST";
export const SETTINGS_CLIENT_GROUP_ADD_SUCCESS = "SETTINGS_CLIENT_GROUP_ADD_SUCCESS";
export const SETTINGS_CLIENT_GROUP_ADD_ERROR = "SETTINGS_CLIENT_GROUP_ADD_ERROR";

export const SETTINGS_CLIENT_GROUP_UPDATE_REQUEST = "SETTINGS_CLIENT_GROUP_UPDATE_REQUEST";
export const SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS = "SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_UPDATE_ERROR = "SETTINGS_CLIENT_GROUP_UPDATE_ERROR";

export const SETTINGS_CLIENT_GROUP_DELETE_REQUEST = "SETTINGS_CLIENT_GROUP_DELETE_REQUEST";
export const SETTINGS_CLIENT_GROUP_DELETE_SUCCESS = "SETTINGS_CLIENT_GROUP_DELETE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_DELETE_ERROR = "SETTINGS_CLIENT_GROUP_DELETE_ERROR";

export const SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST = "SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST";
export const SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS = "SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS";
export const SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR = "SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR";

export const SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST = "SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST";
export const SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS = "SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS";
export const SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR = "SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR";

export const UPDATE_SETTINGS_CLIENT_GROUPS_STORE = "UPDATE_SETTINGS_CLIENT_GROUPS_STORE";