import {
    CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS:
            const {
                pagedResult,
                totalResults,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount: totalResults,
                list: pagedResult,
            };
        case GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST:
        case EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS:
        case DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR:
        case EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS:
        case EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER:
            return initial;
        default:
            return state;
    }
};