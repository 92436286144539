import {
    BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANCEL_ERROR,
    BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CLOSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_EXPORT_ERROR,
    BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST,
    BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_HIDE_ERROR,
    BFF_RECRUITMENT_VACANCY_HIDE_REQUEST,
    BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR,
    BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    card: {},
    progress: false,
    progressAction: false,
    candidatesPageData: {},
    candidatesList: [],
    candidatesTotalCount: 0,
    candidatesCard: {},
    candidatesProgress: false,
    candidatesProgressAction: false,
    counters: {},
    progressCard: false,
    progressCheck: false,
    jobBoardsList: [],
    jobBoardsProgress: false,
    error: null,
    tabFilterData: {
        filterData: {},
        isSearch: false,
    },
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    feedList: [],
    feedProgress: false,
    jobBoards: [],
    recruitersWithAccess: {
        list: [],
        totalCount: 0,
    },
    availableRecruitersList: [],
    progressLogo: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST:
            return {
                ...state,
                progressLogo: true,
            };
        case BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR:
            return {
                ...state,
                progressLogo: false,
            };
        case BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS:
            return {
                ...state,
                availableRecruitersList: payload.results,
            };
        case BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST: {
            return {
                ...state,
                jobBoardsProgress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR: {
            return {
                ...state,
                jobBoardsProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS: {
            return {
                ...state,
                jobBoards: payload.result,
                jobBoardsProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST: {
            return {
                ...state,
                candidatesPageData: payload,
                candidatesProgress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS: {
            return {
                ...state,
                candidatesList: payload.isLoadMore ? state.candidatesList.concat(payload.results) : payload.results,
                candidatesTotalCount: payload.totalCount,
                candidatesProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR: {
            return {
                ...state,
                candidatesProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS: {
            return {
                ...state,
                counters: payload,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST: {
            return {
                ...state,
                progressCard: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS: {
            return {
                ...state,
                card: payload.result,
                progressCard: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR: {
            return {
                ...state,
                progressCard: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_ADD_REQUEST:
        case BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST:
        case BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST:
        case BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST:
        case BFF_RECRUITMENT_VACANCY_HIDE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST:
        case BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST:
        case BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST:
        case BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST:
        case BFF_RECRUITMENT_VACANCY_DELETE_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR:
        case BFF_RECRUITMENT_VACANCY_ADD_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_ADD_ERROR:
        case BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_UPDATE_ERROR:
        case BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR:
        case BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR:
        case BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CLOSE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANCEL_ERROR:
        case BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR:
        case BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR:
        case BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_HIDE_ERROR:
        case BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR:
        case BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR:
        case BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR:
        case BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR:
        case BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_EXPORT_ERROR:
        case BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR:
        case BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR:
        case BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_DELETE_ERROR: {
            return {
                ...state,
                progressAction: false,
                progressLogo: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST: {
            return {
                ...state,
                progressCheck: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR: {
            return {
                ...state,
                progressCheck: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST: {
            return {
                ...state,
                logPageData: payload,
                logProgress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS: {
            return {
                ...state,
                logList: payload.results,
                logTotalCount: payload.totalCount,
                logProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR: {
            return {
                ...state,
                logProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST: {
            return {
                ...state,
                feedProgress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS: {
            return {
                ...state,
                feedList: payload || [],
                feedProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR: {
            return {
                ...state,
                feedProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST: {
            return {
                ...state,
                progress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS: {
            return {
                ...state,
                recruitersWithAccess: {
                    list: payload.results,
                    totalCount: payload.totalCount,
                },
                progress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        default:
            return state;
    }
};