import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDadataInput from "../../../../../components/ActualComponents/NmDadataInput";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRangeInput from "../../../../../components/ActualComponents/NmRangeInput";
import StepList from "../../../../../components/ActualComponents/StepList";
import ApplyButtons from "../../../../../components/ApplyButtons";
import {ContractorsCountRefreshButton} from "../../../../../components/ContractorsCountRefreshButton";
import NmTitle from "../../../../../components/NmTitle";
import {AvailableRecruitersDropdown} from "../../../../../components/Recruitment/AvailableRecruitersDropdown";
import {
    bffRecruitmentDirectoryFunnelListSelector,
    bffRecruitmentDirectoryFunnelOptionsSelector,
    bffRecruitmentDirectoryJobRequirementOptionsSelector,
} from "../../../../../ducks/bff/recruitment/directory/selectors";
import {
    bffRecruitmentVacancyProgressActionSelector,
    bffRecruitmentVacancyProgressCheckSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {RecruitmentVacancyDescriptionEditor} from "../description";

import useRecruitmentVacancyEditForm from "./hooks/useForm";

import {dadataFromCityToApartmentFilter} from "../../../../../utils/dadata";

import {COLOR} from "../../../../../constants/color";
import {GENDER_OPTIONS_WITH_ALL_OPTION} from "../../../../../constants/contractorInfo";
import {RECRUITMENT_VACANCY_STATUS} from "../../constants";

import {getCitizenshipOptionsSelector} from "../../../../../ducks/contractor";
import {specialitiesAllV2OptionsSelector} from "../../../../../ducks/speciality";

const RecruitmentVacancyEditForm = (props) => {
    const {
        onClose = () => {
        },
        fetchData,
        editData,
        clientId,
    } = props;

    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const jobRequirementOptions = useSelector(bffRecruitmentDirectoryJobRequirementOptionsSelector);
    const funnelOption = useSelector(bffRecruitmentDirectoryFunnelOptionsSelector);
    const funnelList = useSelector(bffRecruitmentDirectoryFunnelListSelector);
    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);
    const progressCheck = useSelector(bffRecruitmentVacancyProgressCheckSelector);

    const isEdit = !isEmpty(editData) && !editData.isDuplicate;

    const {
        status,
        vacancyId,
    } = editData;

    const {
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        confirmData,
        onCloseConfirm,
        handleDownload,
        onSubmit,
        openPublishConfirm,
        checkCandidatesCount,
        onChangeDropzone,
    } = useRecruitmentVacancyEditForm({
        onClose,
        fetchData,
        isEdit,
        editData,
        clientId,
    });

    const funnelTooltipStatuses = (funnelList.find(item => item.id === values.funnelId) || {}).statusList?.map(({statusModel}) => statusModel.name);

    const getDraftButton = () => {
        if (!isEdit || status === RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE) {
            return "Сохранить черновик";
        }

        return null;
    };

    const getSubmitButton = () => {
        if (status === RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE || !isEdit) {
            return {
                submitBtnContent: "Взять в работу",
                submit: openPublishConfirm,
            };
        }

        if (isEdit && [RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE].includes(status)) {
            return {
                submitBtnContent: "Сохранить",
                submit: () => onSubmit(editData.status),
            };
        }

        return {
            submitBtnContent: null,
        };
    };

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование вакансии" : "Добавление вакансии"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    disabled={progressAction}
                    onClickAdditional={onClose}
                    additionalBtnContent="Отменить"
                    isHiddenAdditionalOnMobile
                    formId="formAdvert"
                    isSubmitCancelButton
                    onClose={() => onSubmit(RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE)}
                    cancelBtnContent={getDraftButton()}
                    {...getSubmitButton()}
                />
            }
        >
            {renderConfirmWindow()}
            <NmForm addMargin>
                <AvailableRecruitersDropdown
                    required={true}
                    name="recruiterId"
                    clientId={clientId}
                    vacancyId={vacancyId}
                    value={values.recruiterId}
                    error={touched.recruiterId && errors.recruiterId}
                    setFieldValue={setFieldValue}
                />
                <NmInputV2
                    required
                    size="xl"
                    label="Название вакансии"
                    placeholder="Введите наименование"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    maxLength={50}
                    error={touched.title && errors.title}
                />
                <RecruitmentVacancyDescriptionEditor
                    isVisibleTooltip={true}
                    value={values.description}
                    onChange={(_event, {value}) => {
                        setFieldValue("description", value);
                    }}
                    error={touched.description && errors.description}
                />
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDateRangePickerV2
                            isClearable
                            size="xl"
                            label="Период работ"
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            startFieldName="dateFrom"
                            endFieldName="dateTo"
                            minDate={{
                                start: new Date(),
                                end: values.dateFrom ? new Date(values.dateFrom) : new Date(),
                            }}
                            value={{
                                dateFrom: values.dateFrom,
                                dateTo: values.dateTo,
                            }}
                            error={
                                (touched.dateFrom || touched.dateTo) &&
                                (errors.dateFrom || errors.dateTo)
                            }
                            tooltip={
                                <HelpTooltip
                                    hover
                                    info
                                    width={20}
                                    height={20}
                                >
                                    Период работ по вакансии, который будет отображаться исполнителям на площадке
                                </HelpTooltip>
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDateRangePickerV2
                            isClearable
                            required
                            size="xl"
                            label="Период размещения"
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            startFieldName="publishDateFrom"
                            endFieldName="publishDateTo"
                            minDate={{
                                start: new Date(),
                                end: values.publishDateFrom ? new Date(values.publishDateFrom) : new Date(),
                            }}
                            value={{
                                publishDateFrom: values.publishDateFrom,
                                publishDateTo: values.publishDateTo,
                            }}
                            error={
                                (touched.publishDateFrom || touched.publishDateTo) &&
                                (errors.publishDateFrom || errors.publishDateTo)
                            }
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmTitle size="lg">
                            Сегментация
                        </NmTitle>
                    </div>
                    <ContractorsCountRefreshButton
                        className="col-16 col-md-8"
                        count={values.totalCandidatesCount}
                        onClick={checkCandidatesCount}
                        loading={progressCheck}
                    />
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDropdownV2
                            required
                            search
                            size="xl"
                            options={specialityOptions}
                            name="specialityId"
                            value={values.specialityId}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            label="Вид деятельности"
                            placeholder="Выберите вид деятельности"
                            error={touched.specialityId && errors.specialityId}
                        />
                    </div>
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmRangeInput
                            isAmountInput={true}
                            label="Зарплата, ₽"
                            nameStart="salaryFrom"
                            nameEnd="salaryTo"
                            valueStart={values.salaryFrom}
                            valueEnd={values.salaryTo}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            placeholderStart="от"
                            placeholderEnd="до"
                            isVisibleTooltip={true}
                            tooltip={
                                <HelpTooltip
                                    hover
                                    info
                                    className="ms-1"
                                    children={
                                        "Если в поле \"От\" и \"До\" вписать одинаковую сумму, то на Head Hunter " +
                                        "будет указано это значение с приставкой \"До\", а на Авито и Наймикс " +
                                        "будет указана точная сумма."
                                    }
                                />
                            }
                            error={
                                (touched.salaryFrom && errors.salaryFrom) ||
                                (touched.salaryTo && errors.salaryTo)
                            }
                        />
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDadataInput
                            required
                            fromBound="city"
                            label="Город публикации вакансии"
                            placeholder="Введите регион или город"
                            filter={dadataFromCityToApartmentFilter}
                            query={values.workAddressInfo}
                            onChange={(value) => {
                                setFieldValue("workAddressInfo", value.value);

                                const workAddressFiasId = value.data && value.data.fias_id ? value.data.fias_id : null;

                                setFieldValue("workAddressFiasId", workAddressFiasId);
                            }}
                            size="xl"
                            error={touched.workAddressFiasId && errors.workAddressFiasId}
                        />
                    </div>
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDropdownV2
                            label="Гражданство"
                            placeholder="Выберите гражданство"
                            name="citizenships"
                            options={citizenshipOptions}
                            value={values.citizenships}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            search
                            multiple
                        />
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmRangeInput
                            className="fluid"
                            label="Возраст"
                            nameStart="ageFrom"
                            nameEnd="ageTo"
                            maskStart="999"
                            maskEnd="999"
                            valueStart={values.ageFrom}
                            valueEnd={values.ageTo}
                            onChange={handleChange}
                            placeholderStart="от"
                            placeholderEnd="до"
                            onBlur={handleBlur}
                            error={
                                touched && (touched.ageFrom || touched.ageTo) ?
                                    errors.ageFrom || errors.ageTo :
                                    undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDropdownV2
                            label="Пол"
                            name="gender"
                            options={GENDER_OPTIONS_WITH_ALL_OPTION}
                            value={values.gender}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                        />
                    </div>
                </div>
                <NmDropdownV2
                    size="xl"
                    options={jobRequirementOptions}
                    name="requirementIds"
                    value={values.requirementIds}
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    label="Дополнительные требования"
                    placeholder="Выберите из списка"
                    multiple
                />
                <NmDropdownV2
                    required
                    size="xl"
                    options={funnelOption}
                    name="funnelId"
                    value={values.funnelId}
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    label="Воронка статусов"
                    placeholder="Выберите из списка"
                    isVisibleTooltip={true}
                    error={touched.funnelId && errors.funnelId}
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            width={20}
                            height={20}
                            children={
                                values.funnelId ?
                                    <StepList
                                        list={funnelTooltipStatuses}
                                        iconColor={COLOR.WHITE}
                                    /> :
                                    "Выберите воронку статусов"
                            }
                        />
                    }
                />
                <DropzoneV2
                    multiple={false}
                    onChange={onChangeDropzone}
                    onDownload={handleDownload}
                    uploadedFileLabel="Файл для объявления:"
                    accept=".pdf, .doc, .docx, .xlsx"
                    format="*.pdf, *.doc, *.docx, *.xlsx"
                    maxSize={10}
                    isDownload
                    files={values.files}
                    error={touched && touched.files ?
                        errors && errors.files :
                        undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};

export default RecruitmentVacancyEditForm;