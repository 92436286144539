import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractors/dicts";

const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_REQUEST = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_REQUEST";
const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_SUCCESS = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_SUCCESS";
const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_ERROR = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_ERROR";

const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_REQUEST = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_REQUEST";
const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_SUCCESS = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_SUCCESS";
const CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_ERROR = "CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_ERROR";

const CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_REQUEST = "CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_REQUEST";
const CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_SUCCESS = "CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_SUCCESS";
const CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_ERROR = "CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_ERROR";

const CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_REQUEST = "CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_REQUEST";
const CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_SUCCESS = "CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_SUCCESS";
const CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_ERROR = "CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_ERROR";

const CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_REQUEST = "CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_REQUEST";
const CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_SUCCESS = "CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_SUCCESS";
const CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_ERROR = "CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_ERROR";

const CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_REQUEST = "CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_REQUEST";
const CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_SUCCESS = "CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_SUCCESS";
const CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_ERROR = "CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_ERROR";

const CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_REQUEST = "CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_REQUEST";
const CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_SUCCESS = "CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_SUCCESS";
const CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_ERROR = "CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_ERROR";

const initial = {
    additionalDocumentFileTypeDict: {},
    additionalDocumentStatusDict: {},
    additionalDocumentTypeDict: {},
    recruitmentCurrencyDict: {},
    recruitmentWorkExperienceDict: {},
    recruitmentSocialMediaDict: {},
    recruitmentSchedules: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_SUCCESS:
            return {
                ...state,
                recruitmentSchedules: payload,
            };
        case CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                recruitmentSocialMediaDict: payload,
            };
        case CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_SUCCESS:
            return {
                ...state,
                recruitmentWorkExperienceDict: payload,
            };
        case CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_SUCCESS:
            return {
                ...state,
                recruitmentCurrencyDict: payload,
            };
        case CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_SUCCESS:
            return {
                ...state,
                additionalDocumentFileTypeDict: payload,
            };
        case CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_SUCCESS:
            return {
                ...state,
                additionalDocumentStatusDict: payload,
            };
        case CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                additionalDocumentTypeDict: payload,
            };
        default: return state;
    }
};

export const getContractorsAdditionalDocumentFileTypeDict = () => {
    return {
        type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_REQUEST,
    };
};

export const getContractorsAdditionalDocumentTypeDict = () => {
    return {
        type: CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_REQUEST,
    };
};

export const getContractorsAdditionalDocumentStatusDict = () => {
    return {
        type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_REQUEST,
    };
};

export const getContractorsRecruitmentCurrencyDict = () => {
    return {
        type: CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_REQUEST,
    };
};

export const getContractorsRecruitmentWorkExperienceDict = () => {
    return {
        type: CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_REQUEST,
    };
};

export const getContractorsRecruitmentSocialMediaDict = () => {
    return {
        type: CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_REQUEST,
    };
};

export const getContractorsRecruitmentSchedules = () => {
    return {
        type: CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_REQUEST,
    };
};

const contractorDictsSelector = state => state.contractorsDicts;
export const contractorDictsAdditionalDocumentFileTypeDictSelector = createSelector(contractorDictsSelector, ({additionalDocumentFileTypeDict}) => additionalDocumentFileTypeDict);
export const contractorDictsAdditionalDocumentStatusDictOptionsSelector = createSelector(contractorDictsSelector, ({additionalDocumentStatusDict}) => {
    return dictionaryToOptions(additionalDocumentStatusDict);
});
export const contractorDictsAdditionalDocumentTypeDictOptionsSelector = createSelector(contractorDictsSelector, ({additionalDocumentTypeDict}) => {
    return dictionaryToOptions(additionalDocumentTypeDict);
});
export const contractorDictsAdditionalDocumentTypeDictSelector = createSelector(contractorDictsSelector, ({additionalDocumentTypeDict}) => additionalDocumentTypeDict);
export const contractorDictsRecruitmentSchedulesSelector = createSelector(contractorDictsSelector, ({recruitmentSchedules}) => recruitmentSchedules);
export const contractorDictsRecruitmentCurrencyDictOptionsSelector = createSelector(contractorDictsSelector, ({recruitmentCurrencyDict}) => {
    return dictionaryToOptions(recruitmentCurrencyDict);
});
export const contractorDictsRecruitmentWorkExperienceDictSelector = createSelector(contractorDictsSelector, ({recruitmentWorkExperienceDict}) => recruitmentWorkExperienceDict);
export const contractorDictsRecruitmentSocialMediaDictSelector = createSelector(contractorDictsSelector, ({recruitmentSocialMediaDict}) => recruitmentSocialMediaDict);

//GET /api/contractors/dicts/additional-document/type
// Словарь типа дополнительного документа исполнителя
export const getContractorsAdditionalDocumentTypeDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/additional-document/type`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/additional-document/file/type
// Словарь типа загружаемого файла дополнительного документа исполнителя
export const getContractorsAdditionalDocumentFileTypeDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/additional-document/file/type`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/additional-document/status
// Словарь статуса дополнительного документа исполнителя
export const getContractorsAdditionalDocumentStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/additional-document/status`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/recruitmentCurrency
// Словарь валют в рекрутменте
export const getContractorsRecruitmentCurrencyDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/recruitmentCurrency`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/recruitmentWorkExperience
// Словарь стажа работы в рекрутменте
export const getContractorsRecruitmentWorkExperienceDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/recruitmentWorkExperience`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/recruitmentSocialMedia
// Словарь соц. сетей в рекрутменте
export const getContractorsRecruitmentSocialMediaDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/recruitmentSocialMedia`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/recruitmentSchedules
// Словарь графиков работы в рекрутменте
export const getContractorsRecruitmentSchedulesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/recruitmentSchedules`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTORS_DICTS_ADDITIONAL_DOCUMENT_TYPE_REQUEST, getContractorsAdditionalDocumentTypeDictSaga),
        takeEvery(CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_FILE_TYPE_REQUEST, getContractorsAdditionalDocumentFileTypeDictSaga),
        takeEvery(CONTRACTOR_DICTS_ADDITIONAL_DOCUMENT_STATUS_REQUEST, getContractorsAdditionalDocumentStatusDictSaga),
        takeEvery(CONTRACTORS_DICTS_RECRUITMENT_CURRENCY_REQUEST, getContractorsRecruitmentCurrencyDictSaga),
        takeEvery(CONTRACTORS_DICTS_RECRUITMENT_WORK_EXPERIENCE_REQUEST, getContractorsRecruitmentWorkExperienceDictSaga),
        takeEvery(CONTRACTORS_DICTS_RECRUITMENT_SOCIAL_MEDIA_REQUEST, getContractorsRecruitmentSocialMediaDictSaga),
        takeEvery(CONTRACTORS_DICTS_RECRUITMENT_SCHEDULES_REQUEST, getContractorsRecruitmentSchedulesSaga),
    ]);
}