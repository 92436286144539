import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ApplyButtons from "../../components/ApplyButtons";
import NmButton from "../../components/NmButton";
import NmButtonContact from "../../components/NmButtonContact";
import NmIcon from "../../components/NmIcon";
import NmLoader from "../../components/NmLoader";
import NmPopup from "../../components/NmPopup";
import NmTitle from "../../components/NmTitle";
import InviteContractorToOrderForm
    from "../../containers/contractor/nm-contractor-list/invite-contractor-to-order-form";
import {ReactComponent as ArchiveIcon} from "../../images/archive.svg";
import {ReactComponent as BookmarkIcon} from "../../images/bookmark_24.svg";
import {ReactComponent as MailIcon} from "../../images/mail_24.svg";
import {ReactComponent as PhoneIcon} from "../../images/phone_24.svg";
import ContextMenu from "../ActualComponents/ContextMenu";
import MediaQueriesRenderer from "../ActualComponents/MediaQueriesRenderer";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import Text from "../ActualComponents/Text";

import bem from "../../utils/bem";
import {formatLocalDate, getDateObject} from "../../utils/dateFormat";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {dictionaryToOptions} from "../../utils/objectHelper";
import {toastSuccess} from "../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {CONTRACTOR_TYPE_BLOCKED, CONTRACTOR_TYPE_BLOCKED_DICT} from "../../constants/contractor";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../constants/roles";
import {requiredMessage} from "../../constants/validationRules";

import {
    archiveContractorCard,
    getInfoContractorCard,
    updateContractorBlocked,
} from "../../ducks/bff/contractor-сard/actionCreators";
import {getContractorProfileResumeFile} from "../../ducks/bff/contractor-сard/profile/actionCreators";
import {
    contractorCardInfoSelector,
    contractorCardProgressActionSelector,
} from "../../ducks/bff/contractor-сard/selectors";
import {currentUserRestrictionsSelector} from "../../ducks/clientUserRestrictions";
import {
    addContractorToBlackList,
    addFavoriteContractors,
    deleteContractorFromBlackList,
    deleteFavoriteContractors,
    getActiveOrderNumbers,
} from "../../ducks/contractor";
import {
    complaintByEmail,
} from "../../ducks/email";

import PropTypes from "prop-types";

import "./style.sass";

const CLAIM_RADIO_OPTIONS = [
    {value: "FAKE_CONTRACTOR_USER", text: "Профиль выглядит фальшивым (обман в описании)"},
    {value: "OFFERS_OTHER_PEOPLES_SERVICE", text: "Исполнитель в профиле предлагает услуги других людей"},
    {value: "REQUEST_PAYMENT_OUTSIDE", text: "Исполнитель запрашивает платеж за пределами Наймикс"},
    {value: "OTHER", text: "Другое"},
];

const INITIAL_CLAIM_CODE = CLAIM_RADIO_OPTIONS[0].value;

const ACTION_TYPE = {
    CLAIM: "CLAIM",
    BLACK_IN: "BLACK_IN",
    BLACK_OUT: "BLACK_OUT",
    BLOCK: "BLOCK",
    UNLOCK: "UNLOCK",
    ARCHIVED: "ARCHIVED",
    PHONE: "PHONE",
    EMAIL: "EMAIL",
    DOWNLOAD_RESUME: "DOWNLOAD_RESUME",
    ADD_NOTE_FOR_CLIENT: "ADD_NOTE_FOR_CLIENT",
    ORDER_INVITE: "ORDER_INVITE",
};

const ContractorHeader = (props) => {
    const {
        name,
        phone,
        email,
        isOnline,
        lastLoginDate,
        onClickContacts,
        isUserBlocked,
        lastOrderDate,
        contractorId,
        clientId,
        clientUserId,
        isFavorite,
        isInBlackList,
        blackListReason,
        className,
        archived,
        noteForClient,
        openNoteForClientEditModal,
        contractorInvitationProhibited,
    } = props;

    const _lastOrderDate = lastOrderDate ? formatLocalDate(lastOrderDate) : null;
    const isShowLastOrderDate = getDateObject(lastOrderDate)?.getTime() > new Date().getTime();

    useEffect(() => {
        const {
            blockedCause,
        } = card;

        if (blockedCause) {
            setTypeBlocked(blockedCause);
        }
    }, []);


    const [block, element] = bem("contractor-header", className);

    const [isInvitePopupShown, setInvitePopupShown] = useState(false);
    const [isProgress, setProgress] = useState(false);
    const [isBlackListReasonPopupShown, setBlackListReasonPopupShown] = useState(false);
    const [isFavoritePopupShown, setFavoritePopupShown] = useState(false);
    const [isClaimPopupShown, setClaimPopupShown] = useState(false);
    const [archivePopup, setArchivePopup] = useState({});
    const [blackListReasonValue, setBlackListReasonValue] = useState("");
    const [isEmptyReasonError, setEmptyReasonError] = useState(false);
    const [claimCode, setClaimCode] = useState(INITIAL_CLAIM_CODE);
    const [claimMessage, setClaimMessage] = useState("");
    const [isClaimError, setClaimError] = useState(false);
    const [isBlockReasonPopupShown, setBlockReasonPopupShown] = useState(false);
    const [isBankOperationBlock, setBankOperationBlock] = useState(false);
    const [blockReasonValue, setBlockReasonValue] = useState("");
    const [typeBlocked, setTypeBlocked] = useState(CONTRACTOR_TYPE_BLOCKED.SUSPICIOUS_ACTIVITY);

    const dispatch = useDispatch();
    const onlineText = isOnline ? "В сети" : `Последний визит ${lastLoginDate}`;
    const card = useSelector(contractorCardInfoSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const progressAction = useSelector(contractorCardProgressActionSelector);

    const contractorBlockedOptions = dictionaryToOptions(CONTRACTOR_TYPE_BLOCKED_DICT);

    const role = ls(USER_ROLE);
    const isCustomer = [
        CLIENT_ADMIN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
        CLIENT_ACCOUNTANT,
    ].includes(role);

    const getActualWarningMessage = () => {
        if (typeBlocked === CONTRACTOR_TYPE_BLOCKED.SUSPICIOUS_ACTIVITY) {
            return isBankOperationBlock ? "Заблокированный исполнитель не сможет продолжить работу по текущим заказам и не сможет работать по новым. Также заблокированный пользователь не сможет выводить свободные денежные средства, которые ранее были зарезервированы на налог"
                : "Заблокированный исполнитель не сможет продолжить работу по текущим заказам и не сможет работать по новым";
        }

        if (typeBlocked === CONTRACTOR_TYPE_BLOCKED.DOCUMENTS_EXPIRED) {
            return !isBankOperationBlock ? "Заблокированный исполнитель сможет продолжить работу по текущим заказам, но не будет возможности откликаться на новые заказы"
                : "Заблокированный исполнитель сможет продолжить работу по текущим заказам, но не будет возможности откликаться на новые заказы. Также заблокированный пользователь не сможет выводить свободные денежные средства, которые ранее были зарезервированы на налог";
        }
    };

    const getOptions = ({mobile, desktop}) => {
        const options = {
            CLAIM: {
                key: ACTION_TYPE.CLAIM,
                text: "Пожаловаться",
                value: ACTION_TYPE.CLAIM,
            },
            BLACK_IN: {
                key: ACTION_TYPE.BLACK_IN,
                text: "В черный список",
                value: ACTION_TYPE.BLACK_IN,
            },
            BLACK_OUT: {
                key: ACTION_TYPE.BLACK_OUT,
                text: "Из черного списка",
                value: ACTION_TYPE.BLACK_OUT,
            },
            BLOCK: {
                key: ACTION_TYPE.BLOCK,
                text: "Заблокировать",
                value: ACTION_TYPE.BLOCK,
            },
            UNLOCK: {
                key: ACTION_TYPE.UNLOCK,
                text: "Разблокировать",
                value: ACTION_TYPE.UNLOCK,
            },
            ARCHIVED: {
                key: ACTION_TYPE.ARCHIVED,
                text: "Архивировать",
                value: ACTION_TYPE.ARCHIVED,
            },
            PHONE: {
                key: ACTION_TYPE.PHONE,
                text: "Скопировать телефон",
                value: ACTION_TYPE.PHONE,
            },
            EMAIL: {
                key: ACTION_TYPE.EMAIL,
                text: "Скопировать почту",
                value: ACTION_TYPE.EMAIL,
            },
            DOWNLOAD_RESUME: {
                key: ACTION_TYPE.DOWNLOAD_RESUME,
                text: "Скачать резюме",
                value: ACTION_TYPE.DOWNLOAD_RESUME,
            },
            ADD_NOTE_FOR_CLIENT: {
                key: ACTION_TYPE.ADD_NOTE_FOR_CLIENT,
                text: "Добавить примечание",
                value: ACTION_TYPE.ADD_NOTE_FOR_CLIENT,
            },
            ORDER_INVITE: {
                key: ACTION_TYPE.ORDER_INVITE,
                text: "Пригласить на заказ",
                value: ACTION_TYPE.ORDER_INVITE,
            },
        };

        const resultOptions = [];

        if (
            !desktop
            && [ADMIN, NM_MANAGER].includes(role)
            && !noteForClient
        ) {
            resultOptions.push(options.ADD_NOTE_FOR_CLIENT);
        }

        if (
            !contractorInvitationProhibited
            && isCustomer
            && mobile
        ) {
            resultOptions.push(options.ORDER_INVITE);
        }

        if (isCustomer) {
            resultOptions.push(options.CLAIM);
            resultOptions.push(isInBlackList ? options.BLACK_OUT : options.BLACK_IN);
        }

        if (
            [ADMIN, NM_MANAGER].includes(role)
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.blockContractors)
        ) {
            resultOptions.push(isUserBlocked ? options.UNLOCK : options.BLOCK);
        }

        if ([ADMIN].includes(role) && !archived) {
            resultOptions.push(options.ARCHIVED);
        }

        if (!desktop && phone) {
            resultOptions.push(options.PHONE);
        }

        if (!desktop && email) {
            resultOptions.push(options.EMAIL);
        }

        if (mobile) {
            resultOptions.push(options.DOWNLOAD_RESUME);
        }

        return resultOptions;
    };

    const handleClick = (value, type) => {
        onClickContacts(value, type);
    };

    const getActionByActionType = (actionType) => {
        switch (actionType) {
            case ACTION_TYPE.BLACK_IN:
            case ACTION_TYPE.BLACK_OUT: {
                setBlackListReasonPopupShown(true);

                return;
            }
            case ACTION_TYPE.CLAIM: {
                setClaimPopupShown(true);

                return;
            }
            case ACTION_TYPE.BLOCK: {
                handleShowBlocking();

                return;
            }
            case ACTION_TYPE.UNLOCK: {
                handleShowBlocking();

                return;
            }
            case ACTION_TYPE.ARCHIVED: {
                openArchiveConfirm();

                return;
            }
            case ACTION_TYPE.PHONE: {
                handleClick(phone, "phone");

                return;
            }
            case ACTION_TYPE.EMAIL: {
                handleClick(email, "email");

                return;
            }
            case ACTION_TYPE.DOWNLOAD_RESUME: {
                downloadResume();

                return;
            }
            case ACTION_TYPE.ADD_NOTE_FOR_CLIENT: {
                openNoteForClientEditModal && openNoteForClientEditModal();

                return;
            }
            case ACTION_TYPE.ORDER_INVITE: {
                setInvitePopupShown(true);

                return;
            }
            // no default
        }
    };

    const handleCloseBlackListPopup = () => {
        setBlackListReasonPopupShown(false);
        setBlackListReasonValue("");
        setEmptyReasonError(false);
    };

    const handleCloseClaimPopup = () => {
        setClaimMessage("");
        setClaimError(false);
        setClaimPopupShown(false);
        setClaimCode(INITIAL_CLAIM_CODE);
    };

    const handleFavoriteToggle = () => {
        setProgress(true);

        const payload = {
            data: {
                contractorId,
                clientId,
            },
            onSuccess: () => {
                dispatch(getInfoContractorCard(contractorId));
                setProgress(false);
                setFavoritePopupShown(false);
            },
            onError: () => {
                setProgress(false);
                setFavoritePopupShown(false);
            },
        };

        dispatch(isFavorite ? deleteFavoriteContractors(payload) : addFavoriteContractors(payload));
    };

    const handleToggleToBlackList = () => {
        if (!isInBlackList && blackListReasonValue.length === 0) {
            setEmptyReasonError(true);
            return;
        }

        setEmptyReasonError(false);

        setProgress(true);

        const payload = {
            data: {
                contractorId,
                clientId,
                reason: isInBlackList ? "delete" : blackListReasonValue,
            },
            onSuccess: () => {
                dispatch(getInfoContractorCard(contractorId));
                setProgress(false);
                setBlackListReasonPopupShown(false);
                setBlackListReasonValue("");
            },
            onError: () => {
                setProgress(false);
                setBlackListReasonValue("");
                setBlackListReasonPopupShown(false);
            },
        };

        dispatch(isInBlackList ? deleteContractorFromBlackList(payload) : addContractorToBlackList(payload));
    };

    const handleSubmitClaim = () => {
        if (claimCode === "OTHER" && claimMessage.length < 5) {
            setClaimError(true);
            return;
        }

        setClaimError(false);

        setProgress(true);

        const payload = {
            data: {
                contractorId,
                clientId,
                clientUserId,
                complaintCause: claimCode,
                cause: claimCode === "OTHER" ? claimMessage : null,
            },
            onSuccess: () => {
                setProgress(false);
                setClaimPopupShown(false);
                setClaimMessage("");
                setClaimCode(INITIAL_CLAIM_CODE);

            },
            onError: () => {
                setProgress(false);
                setClaimPopupShown(false);
                setClaimMessage("");
                setClaimCode(INITIAL_CLAIM_CODE);
            },
        };

        dispatch(complaintByEmail(payload));
    };

    const handleShowBlocking = () => {
        setBlockReasonPopupShown(true);
    };

    const handleChangeTypeBlocked = (e, {value}) => {
        setTypeBlocked(value);

        if (value === CONTRACTOR_TYPE_BLOCKED.TERRORISM_AND_EXTREMIST) {
            setBankOperationBlock(true);
            setBlockReasonValue("Пользователь может находиться в перечне физических лиц в отношении которых имеются сведения об их причастности к экстремистской деятельности или терроризму");

            return;
        }

        setBankOperationBlock(false);
        setBlockReasonValue("");
    };

    const handleClosedBlocking = () => {
        setBlockReasonValue("");
        setBankOperationBlock(false);
        setEmptyReasonError(false);
        setBlockReasonPopupShown(false);
    };

    const handleToggleBlockSubmit = () => {
        if (!isUserBlocked && blockReasonValue.length === 0) {
            setEmptyReasonError(true);
            return;
        }

        const getResult = (errorMessage) => {
            if (!errorMessage) {
                dispatch(getInfoContractorCard(contractorId));
            }
        };

        const blockOnData = {
            contractorId,
            blocked: true,
            blockedBankOperation: isBankOperationBlock,
            blockedReason: blockReasonValue,
            blockedCause: typeBlocked,
            getResult,
        };

        const blockOffData = {
            contractorId,
            blocked: false,
            blockedBankOperation: false,
            blockedReason: "",
            blockedCause: null,
            getResult,
        };

        const payload = isUserBlocked ? blockOffData : blockOnData;

        dispatch(updateContractorBlocked(payload));

        handleClosedBlocking();
    };

    const archive = () => {
        dispatch(archiveContractorCard({
            contractorId,
            onSuccess: () => {
                toastSuccess("Учётка успешно архивирована");
                setArchivePopup({});
                dispatch(getInfoContractorCard(contractorId));
            },
        }));
    };

    const checkActiveOrderNumbers = () => {
        dispatch(getActiveOrderNumbers({
            contractorId,
            getResult: (result = []) => {
                if (result.length) {
                    setArchivePopup({
                        isShown: true,
                        content: `Исполнитель находится на заказах: ${result.join(", ")}. При архивировании он будет снят с этих заказов. Продолжить?`,
                        onConfirm: archive,
                        confirmButton: "Ок",
                        cancelButton: "Отмена",
                    });

                    return;
                }

                archive();
            },
        }));
    };

    const openArchiveConfirm = () => {
        setArchivePopup({
            isShown: true,
            content: "Вы действительно хотите архивировать пользователя?",
            onConfirm: checkActiveOrderNumbers,
            confirmButton: "Да",
            cancelButton: "Отмена",
        });
    };

    const downloadResume = () => {
        dispatch(getContractorProfileResumeFile({
            contractorId,
            fileName: `Резюме_${name}.pdf`,
        }));
    };

    const renderConfirmArchiveModal = () => {
        const {
            isShown,
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = archivePopup;

        return (
            isShown &&
            <NmConfirmV2
                content={content}
                onCancel={() => {
                    setArchivePopup({});
                }}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />
        );
    };

    const renderFavoritePopup = () => {
        const title = isFavorite ? (
            `Вы действительно хотите удалить исполнителя ${name} из избранных?`
        ) : (
            "Вы действительно хотите добавить исполнителя в избранные?"
        );

        return (
            <NmModal
                className={element("archive-modal")}
                onClose={() => setFavoritePopupShown(false)}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent="Да"
                        cancelBtnContent="Нет"
                        onClose={() => {
                            setFavoritePopupShown(false);
                        }}
                        submit={handleFavoriteToggle}
                    />
                }
                children={null}
            />
        );
    };

    const renderBlackListReasonPopup = () => {
        const title = isInBlackList ? (
            `Вы действительно хотите удалить исполнителя ${name} из черного списка?`
        ) : (
            "Добавление исполнителя в черный список"
        );
        const subHeader = isInBlackList ? null : "Укажите причину добавления исполнителя в черный список";
        const submitBtnContent = isInBlackList ? "Да" : "Подтвердить";
        const cancelBtnContent = isInBlackList ? "Нет" : "Отменить";

        return (
            <NmModal
                className={element("blacklist-reason-popup")}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        className={element("buttons-apply")}
                        submitBtnContent={submitBtnContent}
                        cancelBtnContent={cancelBtnContent}
                        onClose={handleCloseBlackListPopup}
                        submit={handleToggleToBlackList}
                    />
                }
                onClose={handleCloseBlackListPopup}
            >
                <NmForm
                    addMargin
                    className={element("blacklist-reason-form")}
                >
                    {
                        subHeader &&
                        <div>
                            {subHeader}
                        </div>}
                    {
                        !isInBlackList &&
                        <div className={element("textarea-wrapper")}>
                            <NmTextareaV2
                                className={element("textarea")}
                                value={blackListReasonValue}
                                onChange={(event, {value}) => {
                                    setBlackListReasonValue(value);
                                }}
                                error={isEmptyReasonError ? "Обязательное поле" : null}
                            />
                        </div>
                    }
                </NmForm>
            </NmModal>
        );
    };

    const renderClaimPopup = () => {
        return (
            <NmModal
                className={element("claim-reason-popup")}
                header={
                    <NmTitle size="lg">
                        Укажите причину жалобы
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        className={element("buttons-apply")}
                        submitBtnContent="Отправить жалобу"
                        cancelBtnContent="Отменить"
                        onClose={handleCloseClaimPopup}
                        submit={handleSubmitClaim}
                    />
                }
                onClose={handleCloseClaimPopup}
            >
                <NmForm
                    addMargin
                    className={element("claim-reason-form")}
                >
                    {
                        CLAIM_RADIO_OPTIONS.map(({value, text}) => (
                            <div>
                                <NmRadioV2
                                    key={value}
                                    className={element("claim-reason-radio", {checked: claimCode === value})}
                                    label={text}
                                    checked={claimCode === value}
                                    onChange={() => {
                                        setClaimCode(value);
                                    }}
                                />
                            </div>
                        ))
                    }
                    {
                        claimCode === "OTHER" &&
                        <div className={`${element("textarea-wrapper")} ${element("textarea-claim")}`}>
                            <NmTextareaV2
                                size="xl"
                                className={element("textarea")}
                                value={claimMessage}
                                minRows={3}
                                maxlength={255}
                                placeholder="Расскажите подробнее. Исполнитель это не увидит."
                                onChange={(event, {value}) => {
                                    setClaimMessage(value);
                                }}
                                error={isClaimError ? "Минимальная длина строки 5 символов" : null}
                            />
                        </div>
                    }
                </NmForm>
            </NmModal>
        );
    };

    const renderBlockedPopup = () => {
        if (isUserBlocked) {
            return (
                <NmConfirmV2
                    content={`Вы действительно хотите снять блокировку ${name}?`}
                    onCancel={handleClosedBlocking}
                    onConfirm={handleToggleBlockSubmit}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                />
            );
        }

        return (
            <NmModal
                className={element("modal")}
                size="md"
                header={
                    <NmTitle size="lg">
                        Параметры блокировки
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        className={element("buttons-apply")}
                        submitBtnContent="Подтвердить"
                        cancelBtnContent="Отменить"
                        onClose={handleClosedBlocking}
                        submit={handleToggleBlockSubmit}
                    />
                }
                onClose={handleClosedBlocking}
            >
                <NmForm addMargin>
                    <NmDropdownV2
                        options={contractorBlockedOptions}
                        value={typeBlocked}
                        onChange={handleChangeTypeBlocked}
                        label="Причина блокировки"
                    />
                    <NmCheckboxV2
                        checked={isBankOperationBlock}
                        label="Блокировать операции с денежными средствами"
                        onChange={() => {
                            setBankOperationBlock(!isBankOperationBlock);
                        }}
                    />
                    <NmTextareaV2
                        size="xl"
                        autoSize={false}
                        value={blockReasonValue}
                        maxLength={255}
                        onChange={(event, {value}) => {
                            setBlockReasonValue(value);
                        }}
                        error={isEmptyReasonError ? requiredMessage : null}
                    />
                    <div className={element("blocked-warning-msg")}>
                        {getActualWarningMessage()}
                    </div>
                </NmForm>
            </NmModal>
        );
    };

    const renderContextMenuHeader = ({mobile}) => {
        return (
            <ContextMenu
                size="xl"
                inline={false}
                className={element("controls-item")}
                options={getOptions({mobile})}
                onClickItem={({value: actionType}) => {
                    getActionByActionType(actionType);
                }}
            />
        );
    };

    const renderInviteButton = () => {
        return (
            isCustomer && !isUserBlocked &&
            <NmButton
                className={element("controls-item")}
                size="xl"
                onClick={() => {
                    setInvitePopupShown(true);
                }}
            >
                Пригласить на заказ
            </NmButton>
        );
    };

    const renderControls = () => {
        return (
            <div className={element("controls")}>
                <MediaQueriesRenderer
                    config={{
                        desktop: {
                            render: () => (
                                <>
                                    {
                                        [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                                        !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.blockContractors) &&
                                        <div className={element("blocked-control-wrapper")}>
                                            <div className={element("blocked-status")}>
                                                Заблокировать
                                            </div>
                                            <NmCheckboxV2
                                                className={element("control")}
                                                checked={isUserBlocked}
                                                onChange={handleShowBlocking}
                                                error={isUserBlocked}
                                                disabled={![ADMIN, NM_MANAGER].includes(role) || progressAction}
                                                toggle
                                            />
                                        </div>
                                    }
                                    {
                                        ADMIN === role &&
                                        !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.archivingContractor) &&
                                        !archived &&
                                        <NmButton
                                            onlyIcon
                                            size="xl"
                                            color="grey"
                                            icon={<ArchiveIcon />}
                                            className={element("controls-item")}
                                            onClick={openArchiveConfirm}
                                        />
                                    }
                                    {
                                        isCustomer &&
                                        !isInBlackList &&
                                        <NmButton
                                            className={element("controls-item")}
                                            color="grey"
                                            size="xl"
                                            onlyIcon
                                            icon={<BookmarkIcon />}
                                            onClick={() => {
                                                setFavoritePopupShown(true);
                                            }}
                                        />
                                    }
                                    {
                                        phone &&
                                        <NmButtonContact
                                            className={element("controls-item")}
                                            icon={<PhoneIcon />}
                                            popup={phone}
                                            onClick={() => {
                                                handleClick(phone, "phone");
                                            }}
                                        />
                                    }
                                    {
                                        email &&
                                        <NmButtonContact
                                            className={element("controls-item")}
                                            icon={<MailIcon />}
                                            popup={email}
                                            onClick={() => {
                                                handleClick(email, "email");
                                            }}
                                        />
                                    }
                                    <NmButton
                                        className={element("controls-item")}
                                        size="xl"
                                        color="grey"
                                        onClick={downloadResume}
                                    >
                                        Скачать резюме
                                    </NmButton>
                                    {
                                        [ADMIN, NM_MANAGER].includes(role) && !noteForClient &&
                                        <NmButton
                                            className={element("controls-item")}
                                            size="xl"
                                            color="grey"
                                            onClick={openNoteForClientEditModal}
                                        >
                                            Добавить примечание
                                        </NmButton>
                                    }
                                    {
                                        isCustomer &&
                                        <>
                                            {
                                                !isUserBlocked &&
                                                !contractorInvitationProhibited &&
                                                <NmButton
                                                    className={element("controls-item")}
                                                    size="xl"
                                                    onClick={() => {
                                                        setInvitePopupShown(true);
                                                    }}
                                                >
                                                    Пригласить на заказ
                                                </NmButton>
                                            }
                                            <ContextMenu
                                                size="xl"
                                                className={element("controls-item")}
                                                options={getOptions({desktop: true})}
                                                onClickItem={({value: actionType}) => {
                                                    getActionByActionType(actionType);
                                                }}
                                            />
                                        </>
                                    }
                                </>
                            ),
                        },
                        tablet: {
                            render: () => (
                                <div className="flex">
                                    {renderInviteButton()}
                                    <NmButton
                                        className={element("controls-item")}
                                        size="xl"
                                        color="grey"
                                        onClick={downloadResume}
                                    >
                                        Скачать резюме
                                    </NmButton>
                                    {renderContextMenuHeader({})}
                                </div>
                            ),
                        },
                        mobile: {
                            render: () => renderContextMenuHeader({mobile: true}),
                        },
                    }}
                    mediaQueries={{
                        mobile: {maxWidth: 767},
                        tablet: {minWidth: 768, maxWidth: 1199},
                        desktop: {minWidth: 1200},
                    }}
                />
            </div>);
    };

    return (
        <div className={block()}>
            {
                isProgress &&
                <NmLoader active />
            }
            <div className={element("name-wrapper")}>
                <div className={element("name-wrapper-col")}>
                    <div className={element("statuses-wrapper")}>
                        <NmTitle className={element("name")}>
                            {name}
                        </NmTitle>
                        {
                            archived && <ArchiveIcon />
                        }
                        {
                            isInBlackList &&
                            <div className={element("blacklist-status-wrapper")}>
                                <NmIcon
                                    className={element("icon-blacklist")}
                                    name="blacklisted"
                                />
                                {blackListReason && <NmPopup className={element("blacklist-reason")}>
                                    {blackListReason}
                                </NmPopup>}
                            </div>
                        }
                    </div>
                    {
                        (isOnline || lastLoginDate) &&
                        <div className={element("online-indicator-wrapper")}>
                            <Text
                                level="2"
                                className={element("online-indicator", {online: isOnline})}
                            >
                                {onlineText}
                            </Text>
                        </div>
                    }
                </div>
                {
                    _lastOrderDate && isShowLastOrderDate &&
                    <div className={element("last-order-date")}>
                        {`На заказе до ${_lastOrderDate}`}
                    </div>
                }
            </div>
            {renderControls()}
            {
                isInvitePopupShown &&
                <InviteContractorToOrderForm
                    close={() => {
                        setInvitePopupShown(false);
                    }}
                    contractorIds={[contractorId]}
                    clientId={clientId}
                    isContractorPage={true}
                />
            }
            {renderConfirmArchiveModal()}
            {
                isBlackListReasonPopupShown &&
                renderBlackListReasonPopup()
            }
            {
                isFavoritePopupShown &&
                renderFavoritePopup()
            }
            {
                isClaimPopupShown &&
                renderClaimPopup()
            }
            {
                isBlockReasonPopupShown &&
                renderBlockedPopup()
            }
        </div>
    );
};

ContractorHeader.propTypes = {
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    isOnline: PropTypes.bool.isRequired,
    lastLoginDate: PropTypes.string,
    onClickContacts: PropTypes.func,
    isUserBlocked: PropTypes.bool,
    className: PropTypes.string,
};

export default ContractorHeader;