import {
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST,
} from "./actions";

export const getClientForemenForemanContractorsRichPage = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST,
        payload,
    };
};
export const deleteClientForemenForemanContractors = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST,
        payload,
    };
};
export const addClientForemenForemanContractors = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST,
        payload,
    };
};

export const clearClientForemenForemanContractorsStore = () => {
    return {
        type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE,
    };
};

