import {
    BFF_SETTINGS_EMPLOYEES_ADD_REQUEST,
    BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST,
    UPDATE_SETTINGS_EMPLOYEES_STORE,
} from "./actions";

export const getPageSettingsEmployees = (payload) => {
    return {
        type: BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST,
        payload,
    };
};

export const addSettingsEmployees = (payload) => {
    return {
        type: BFF_SETTINGS_EMPLOYEES_ADD_REQUEST,
        payload,
    };
};

export const updateSettingsEmployees = (payload) => {
    return {
        type: BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST,
        payload,
    };
};

export const archiveSettingsEmployees = (payload) => {
    return {
        type: BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST,
        payload,
    };
};

export const generatePasswordSettingsEmployees = (payload) => {
    return {
        type: BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST,
        payload,
    };
};

export const updateStoreSettingsEmployees = (payload) => {
    return {
        type: UPDATE_SETTINGS_EMPLOYEES_STORE,
        payload,
    };
};