import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import LogoThumbnail from "../../../../components/LogoThumbnail";
import NmBadge from "../../../../components/NmBadge";
import NmCheck from "../../../../components/NmCheck";
import NmReadonlyRating from "../../../../components/NmReadonlyRating";
import {ReactComponent as LogoIcon} from "../../../../images/company-logo.svg";
import {ReactComponent as OrdersIcon} from "../../../../images/library_books_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import ReviewReply from "../review-reply";

import bem from "../../../../utils/bem";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

import {COLOR} from "../../../../constants/color";
import {
    LINK_CLIENT_INFO,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../constants/links";
import {
    REVIEW_REPLY_STATUS,
    REVIEW_REPLY_STATUS_BADGE_DICT,
    REVIEW_STATUS,
} from "../../../../constants/reviews";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, RNKO} from "../../../../constants/roles";

import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";

import "./style.sass";

function ReviewsList(props) {
    const {
        list,
        getMediaActions = () => {
        },
        isClientPage = false,
        isContractorPage = false,
        role,
        onClickEdit = () => {
        },
        fetchList,
    } = props;

    const [, element] = bem("reviews-list");

    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    function getClientFullName({brand, name}) {
        return brand ? `${name} (${brand})` : name;
    }

    function getPrimaryHeader({contractorScore, client, contractor}) {
        if (role === RNKO) {
            return getClientFullName(client);
        }

        if (contractorScore || isContractorPage) {
            const {clientId} = client;
            const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

            return (
                <ExtLink
                    historyEnabled
                    to={clientLink}
                    noWrap
                >
                    {getClientFullName(client)}
                </ExtLink>
            );
        }

        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractor.contractorId);

        return (
            <ExtLink
                className={element("primary-header")}
                historyEnabled
                to={contractorLink}
            >
                {
                    contractor.lastName
                        ? getFullName(contractor.lastName, contractor.firstName, contractor.patronymic)
                        : contractor.fullName
                }
            </ExtLink>
        );
    }

    function renderAvatar({contractorScore, client, contractor}) {
        const {base64Logo} = client;
        const {contractorId} = contractor;

        if (contractorScore || isContractorPage) {
            return (
                base64Logo ?
                    <LogoThumbnail
                        src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
                        className="reviews-list__image"
                    /> :
                    <LogoIcon
                        width={48}
                        height={48}
                    />
            );
        }

        return (
            <Avatar
                type="list"
                contractorId={contractorId}
                base64={userAvatarDict[contractorId]}
            />
        );
    }

    function renderWhomLabel({contractorScore, client, contractor}) {
        const {clientId} = client;
        const {
            contractorId,
            lastName,
            firstName,
            patronymic,
            fullName,
        } = contractor;

        const link = contractorScore
            ? LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId)
            : LINK_CLIENT_INFO.replace(":clientId", clientId);
        const contractorFullName = lastName
            ? getFullName(lastName, firstName, patronymic)
            : fullName;
        const text = contractorScore
            ? contractorFullName 
            : getClientFullName(client);

        return (
            <ExtLink
                historyEnabled
                to={link}
                noWrap={true}
            >
                {text}
            </ExtLink>
        );
    }

    function renderReviewTextBlock(item) {
        const {
            baseModel: {
                reviewText,
                status,
            },
        } = item;

        if (
            isContractorPage || isClientPage || status !== REVIEW_STATUS.DRAFT.VALUE ||
            [NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(role)
        ) {
            return reviewText;
        }

        return (
            reviewText &&
            <div className="flex flex-aligned-center">
                {reviewText}
                <ModeIcon
                    className={element("mode-icon")}
                    color={COLOR.SECONDARY_45}
                    onClick={() => onClickEdit(item)}
                />
            </div>
        );
    }

    function getRows() {
        return list.map(item => {
            const {
                contractorScore,
                baseModel: {
                    id,
                    score,
                    workQualityScore,
                    dateTime,
                    status,
                    reply,
                },
                specialityName,
                totalCountOrder,
                totalCountOrderOfClient,
                clientUser,
            } = item;

            const isBadgeVisible = !isClientPage && !isContractorPage;

            return {
                ...item,
                key: id,
                avatar: renderAvatar(item),
                isWithSubHeader: isBadgeVisible,
                contentRow: (
                    <NmListCard
                        avatar
                        alignItems="flex-start"
                        secondaryHeaderStatus={
                            isBadgeVisible &&
                            <div className="flex flex-aligned-center">
                                <NmBadge
                                    noWrap
                                    mod={REVIEW_STATUS[status]?.MOD}
                                    text={REVIEW_STATUS[status]?.TEXT}
                                />
                                {
                                    !isEmpty((reply)) &&
                                    <NmCheck
                                        blockClassName="ms-2"
                                        isWarning={reply?.status === REVIEW_REPLY_STATUS.DRAFT}
                                        isCheck={reply?.status === REVIEW_REPLY_STATUS.PUBLISHED}
                                        label={REVIEW_REPLY_STATUS_BADGE_DICT[reply?.status]}
                                        labelProps={{
                                            noWrap: true,
                                        }}
                                    />
                                }
                            </div>
                        }
                        secondaryHeader={`Дата отзыва ${formatLocalDate(dateTime, "dd.MM.yyyy")}`}
                        primaryHeader={getPrimaryHeader(item)}
                        labels={[
                            isClientPage && {
                                text: specialityName,
                            },
                            isClientPage && {
                                text: <div className="flex flex-aligned-center">
                                    <OrdersIcon
                                        className="mr-2"
                                        width="15px"
                                        height="15px"
                                        color="gray"
                                    />
                                    Выполнено заказов
                                    {" "}
                                    {totalCountOrder || "0"}
                                    /
                                    {totalCountOrderOfClient || "0"}
                                </div>,
                            },
                            isContractorPage && {
                                text: <div className="flex flex-aligned-center">
                                    <div>
                                        {getFullName(clientUser?.lastName, clientUser?.firstName, clientUser?.patronymic)}
                                    </div>
                                    <div className="nm-selected-list__delimiter" />
                                    <div>
                                        {clientUser?.positionName}
                                    </div>
                                </div>,
                            },
                            !isClientPage && !isContractorPage && {
                                label: "Кому",
                                text: renderWhomLabel(item),
                                noWrap: false,
                            },
                            {
                                text: (
                                    <NmReadonlyRating
                                        classNameValue={element("readonly-rating-value")}
                                        rating={(contractorScore || isContractorPage ? workQualityScore : score) || 0}
                                        maxRating={5}
                                    />
                                ),
                            },
                            {
                                text: renderReviewTextBlock(item),
                                classNameText: element("comment"),
                                noWrap: false,
                            },
                            reply?.text && {
                                text: <ReviewReply
                                    review={item}
                                    isClientPage={isClientPage}
                                    isContractorPage={isContractorPage}
                                    contractorScore={contractorScore}
                                    reply={reply}
                                    fetchList={fetchList}
                                    isAccessEdit={
                                        !isClientPage
                                        && !isContractorPage
                                        && reply.status === REVIEW_REPLY_STATUS.DRAFT
                                    }
                                />,
                                classNameText: element("comment"),
                                noWrap: false,
                            },
                        ]}
                        noDivider
                        classNameMainContent="col-16 col-md-14 col-xxl-11"
                        actionsClassName="col-1 col-md-2 col-xl-1 col-xxl-1 justify-content-end px-0"
                        mediaControls={getMediaActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    }

    return (
        <CheckboxList
            withCheckbox={false}
            rows={getRows()}
        />
    );
}

export default ReviewsList;