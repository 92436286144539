import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffFinancesNpdSummarySelector = state => state.bff.financesNpdSummary;

export const bffFinancesNpdSummaryListSelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({reports}) => reports,
);
export const bffFinancesNpdSummaryProgressSelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({progress}) => progress,
);
export const bffFinancesNpdSummaryProgressReportsSelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({progressReports}) => progressReports,
);
export const bffFinancesNpdSummaryTotalSummarySelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({totalSummary}) => totalSummary,
);
export const bffFinancesNpdSummaryTotalCountSelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({totalCount}) => totalCount,
);
export const bffFinancesNpdSummaryTotalPagesSelector = createSelector(
    bffFinancesNpdSummarySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);