import {
    ADD_CLIENT_REF_PROGRAM_REQUEST,
    CHECK_CLIENT_PROMOCODE_REQUEST,
    CLEAR_FIELDS_CLIENT_CARD_INFO_STORE,
    GET_CLIENT_INFO_REQUEST,
    GET_CLIENT_PROPERTIES_REQUEST,
    GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST,
    GET_CLIENT_REF_PROGRAM_REQUEST,
    GET_CLIENT_SPECIALITIES_REQUEST,
    GET_CURRENT_CLIENT_INFO_REQUEST,
    UPDATE_CLIENT_INFO_REQUEST,
    UPDATE_CLIENT_PROPERTIES_REQUEST,
    UPDATE_CLIENT_REQUISITES_REQUEST,
    UPDATE_FIELDS_CLIENT_CARD_INFO_STORE,
} from "./actions";

export function getClientCardInfo(payload) {
    return {
        type: GET_CLIENT_INFO_REQUEST,
        payload,
    };
}

export function updateClientCardInfo(payload) {
    return {
        type: UPDATE_CLIENT_INFO_REQUEST,
        payload,
    };
}

export function getCurrentClientCardInfo(payload) {
    return {
        type: GET_CURRENT_CLIENT_INFO_REQUEST,
        payload,
    };
}

export function getClientCardProperties(payload) {
    return {
        type: GET_CLIENT_PROPERTIES_REQUEST,
        payload,
    };
}

export function updateClientCardProperties(payload) {
    return {
        type: UPDATE_CLIENT_PROPERTIES_REQUEST,
        payload,
    };
}

export function updateClientCardRequisites(payload) {
    return {
        type: UPDATE_CLIENT_REQUISITES_REQUEST,
        payload,
    };
}

export function updateFieldsClientCardInfo(payload) {
    return {
        type: UPDATE_FIELDS_CLIENT_CARD_INFO_STORE,
        payload,
    };
}

export function getClientCardRefProgram(payload) {
    return {
        type: GET_CLIENT_REF_PROGRAM_REQUEST,
        payload,
    };
}

export function addClientCardRefProgram(payload) {
    return {
        type: ADD_CLIENT_REF_PROGRAM_REQUEST,
        payload,
    };
}

export function getClientCardRefProgramHistory(payload) {
    return {
        type: GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST,
        payload,
    };
}

export function checkClientPromocode(payload) {
    return {
        type: CHECK_CLIENT_PROMOCODE_REQUEST,
        payload,
    };
}

export function getClientSpecialities(payload) {
    return {
        type: GET_CLIENT_SPECIALITIES_REQUEST,
        payload,
    };
}

export function clearFieldsClientCardInfo() {
    return {
        type: CLEAR_FIELDS_CLIENT_CARD_INFO_STORE,
    };
}