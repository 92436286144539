import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const clientForemenForemanContractorsSelector = state => state.bff.clientForemenForemanContractors;

export const clientForemenForemanContractorsPageSelector = createSelector(
    clientForemenForemanContractorsSelector,
    ({foremanContractors}) => foremanContractors,
);
export const clientForemenForemanContractorsTotalPagesSelector = createSelector(
    clientForemenForemanContractorsSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const clientForemenForemanContractorsTotalCountSelector = createSelector(
    clientForemenForemanContractorsSelector,
    ({totalCount}) => totalCount,
);
export const clientForemenForemanContractorsProgressSelector = createSelector(
    clientForemenForemanContractorsSelector,
    ({progress}) => progress,
);
export const clientForemenForemanContractorsProgressActionsSelector = createSelector(
    clientForemenForemanContractorsSelector,
    ({progressAction}) => progressAction,
);


