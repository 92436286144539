import React from "react";
import {useSelector} from "react-redux";

import Filter, {FILTER} from "../../components/ActualComponents/Filter";
import HelpTooltip from "../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../components/ActualComponents/ImportFromFilePatternV2";
import {MediaButtons} from "../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import Tabs from "../../components/ActualComponents/Tabs";
import Text from "../../components/ActualComponents/Text";
import CheckboxList from "../../components/CheckboxList";
import DepositValuesAmountInfo from "../../components/DepositValuesAmountInfo";
import ExtLink from "../../components/ExtLink";
import MassConfirm from "../../components/MassConfirm";
import NmBadge from "../../components/NmBadge";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import OrderTemplateInfo from "../order/template-info";
import ObjectLogDepositHistoryModal from "../projects/object-log-deposit-history-modal";
import ObjectsAddDeposit from "./components/add-deposit";
import ObjectsEdit from "./components/edit";
import ObjectsReturnDeposit from "./components/return-deposit";

import {useClientBrandsFilter} from "../../hooks/useClientBrands";
import {useFilter} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {useSelectedList} from "../../hooks/useSelectedList";
import {useObjectsAction} from "./hooks/useAction";
import {useObjectsFetch} from "./hooks/useFetch";
import {useObjectsSort} from "./hooks/useSort";

import {getUserRole} from "../../utils/access";
import {formatAmount, formatNumber} from "../../utils/stringFormat";
import getIsShowDetails from "./utils/getIsShowDetails";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../constants/color";
import {SimpleWithEmptyDropdownOptions} from "../../constants/contractorList";
import {EMPTY_OPTION_KEY} from "../../constants/dropdown";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../constants/links";
import {
    OBJECT_STATUS_DICT,
    OBJECT_STATUS_FILTER_DICT,
} from "../../constants/objects";
import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_OPERATOR, 
    RECRUITER,
} from "../../constants/roles";
import {OBJECTS_FILTERS} from "./constants";

import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";
import {currentUserRestrictionsSelector} from "../../ducks/clientUserRestrictions";
import {progressDepositImportSelector} from "../../ducks/deposit";
import {documentCustomTemplateAggregationShortTemplatesSelector} from "../../ducks/documentsCustomTemplate";
import {
    objectsListSelector,
    objectsProgressListSelector,
    objectsTotalCountSelector,
    objectsTotalPagesSelector,
} from "../../ducks/objects";

const initFilter = {
    speciality: "",
    city: "",
    smzFrameContractEnabled: EMPTY_OPTION_KEY,
    civilFrameContractEnabled: EMPTY_OPTION_KEY,
    individualFrameContractEnabled: EMPTY_OPTION_KEY,
    brandsIds: [],
};

const Objects = (props) => {
    const {
        match: {
            params: {
                clientId,
                projectId,
            },
        },
        hideDetailsProjectCard,
    } = props;

    const role = getUserRole();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("objects");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        depositDistributedByObjects,
        civilRegistryPaymentsAvailable,
    } = useSelector(clientCardPropertiesSelector);
    const totalPages = useSelector(objectsTotalPagesSelector);
    const list = useSelector(objectsListSelector);
    const progressList = useSelector(objectsProgressListSelector);
    const totalCount = useSelector(objectsTotalCountSelector);
    const progressDepositImport = useSelector(progressDepositImportSelector);
    const allOptionsInfo = useSelector(documentCustomTemplateAggregationShortTemplatesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    
    const {
        clientBrandsFilter,
        withoutBrandFilter,
    } = useClientBrandsFilter({clientId});

    const {
        sortType,
        sortName,
        options,
        onClickSort,
    } = useObjectsSort();

    const {
        onOpenEdit,
        editData,
        isOpenConfirm,
        confirmData,
        headerMediaControls,
        onOpenAddDeposit,
        onOpenReturnDeposit,
        changeDepositData,
        onCloseConfirm,
        getListMediaControls,
        hideDetails,
        getStatusFilterTabs,
        statusFilter,
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
        openImportModal,
        setOpenImportModal,
        submitImportDepositForm,
        archived,
        onOpenConfirm,
        multipleArchiveObject,
    } = useObjectsAction({
        clientId,
        projectId,
        depositDistributedByObjects,
        setPagination,
        pageSize,
        selectedList,
        clearSelectedRows,
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
    } = useFilter({
        initFilter,
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useObjectsFetch({
        filterData,
        pageNum,
        pageSize,
        clientId,
        projectId,
        sortType,
        sortName,
        statusFilter,
        archived,
    });

    const isVisibleMassActions = ![
        NM_CHIEF_ACCOUNTANT,
        NM_OPERATOR,
        NM_COORDINATOR,
        FOREMAN,
        RECRUITER,
    ].includes(role)
        && statusFilter !== OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE
        &&!archived;

    const getObjectLink = (item, key) => {
        if (!item[key]) {
            return "-";
        }

        const to = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":objectId", item.objectId)
            .replace(":clientId", item.clientId)
            .replace(":projectId", item.projectId);

        return (
            <ExtLink
                historyEnabled
                to={to}
            >
                {item[key]}
            </ExtLink>
        );
    };

    const getDepositAmount = (value) => {
        if (!depositDistributedByObjects) {
            return "Не установлен";
        }

        return formatAmount(formatNumber(value, 2)) || "-";
    };

    const getProjectLink = (item) => {
        if (!item.projectName) {
            return "-";
        }

        const to = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", item.clientId)
            .replace(":projectId", item.projectId);

        return (
            <ExtLink
                historyEnabled
                to={to}
            >
                {item.projectName}
            </ExtLink>
        );
    };

    const getTemplateLabelText = (id, link) => {
        return (
            <div className="flex">
                {id ? "Да (" : "Нет"}
                {
                    id &&
                    <>
                        <OrderTemplateInfo
                            id={id}
                            linkName="скачать шаблон"
                            useLinkName={false}
                            link={link}
                        />
                        )
                    </>
                }
            </div>
        );
    };

    const getRows = () => {
        const isShowDetails = getIsShowDetails({
            projectId,
            hideDetailsProjectCard,
            hideDetails,
        });

        return list.map(item => {
            const depositInfoCards = ![FOREMAN].includes(role) ? [
                {
                    title: "Депозит НПД, ₽",
                    value: getDepositAmount(item.smzDepositAmount),
                    className: "col-16 col-md-6 col-xxl-5 mt-md-4 mt-xxl-0",
                },
                {
                    title: "Депозит НДФЛ, ₽",
                    value: getDepositAmount(item.civilDepositAmount),
                    className: "col-16 col-md-6 col-xxl-5 mt-md-4 mt-xxl-0",
                },
            ] : [];

            const {
                otherDocumentTemplateId,
                otherDocumentTemplatePdfDownloadLink,
                smzFrameContractTemplateId,
                smzFrameContractTemplatePdfDownloadLink,
                civilFrameContractTemplateId,
                civilFrameContractTemplatePdfDownloadLink,
                individualFrameContractTemplateId,
                individualFrameContractTemplatePdfDownloadLink,
                brandName,
                objectId,
                hasAccessToArchive,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.objectId === objectId)) || {};

            return {
                ...item,
                key: objectId,
                isSelected,
                showCheckBox: item.status === OBJECT_STATUS_DICT.CLOSE.VALUE && hasAccessToArchive,
                contentRow: (
                    <NmListCard
                        primaryHeader={getObjectLink(item, "name")}
                        classNameMainContent="col-16 col-xxl-9"
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={OBJECT_STATUS_DICT[item.status]?.BADGE_MOD}
                                text={OBJECT_STATUS_DICT[item.status]?.TEXT}
                            />
                        }
                        labels={[
                            (!projectId && !hideDetails) && {label: "Проект", text: getProjectLink(item)},
                            isShowDetails && {label: "Адрес", text: item.address || "-"},
                            item.metroInfo && isShowDetails && {label: "Ближайшие станции метро", text: item.metroInfo},
                            isShowDetails && {
                                label: "Заключать отдельный договор НПД на объекте",
                                columnOnMobile: true,
                                text: getTemplateLabelText(smzFrameContractTemplateId, smzFrameContractTemplatePdfDownloadLink),
                            },
                            isShowDetails && {
                                label: "Заключать отдельный договор НДФЛ на объекте",
                                columnOnMobile: true,
                                text: getTemplateLabelText(civilFrameContractTemplateId, civilFrameContractTemplatePdfDownloadLink),
                            },
                            isShowDetails && {
                                label: "Заключать отдельный договор ИП на объекте",
                                columnOnMobile: true,
                                text: getTemplateLabelText(individualFrameContractTemplateId, individualFrameContractTemplatePdfDownloadLink),
                            },
                            isShowDetails && {
                                label: "Запрашивать подписание иного документа на объекте",
                                columnOnMobile: true,
                                text: getTemplateLabelText(otherDocumentTemplateId, otherDocumentTemplatePdfDownloadLink),
                            },
                            brandName && {
                                label: "Бренд",
                                text: brandName,
                            },
                        ]}
                        cards={[
                            ...depositInfoCards,
                            {
                                title: "ID объекта",
                                value: getObjectLink(item, "objectExtId"),
                                className: "col-16 col-md-4 col-xxl-5 mt-md-4 mt-xxl-0",
                                overflowEllipseText: true,
                                titleText: item.objectExtId,
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-6 mt-md-4 mt-xxl-0 align-items-end"
                        actionsClassName="col-1 flex-aligned-end justify-content-end"
                        mediaControls={getListMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderEditObjectWindow = () => {
        const {
            projectUnlimited,
            projectId,
            editObject,
            isOpenEditObjectWindow,
        } = editData;

        if (!isOpenEditObjectWindow) {
            return null;
        }

        return (
            <ObjectsEdit
                handleCancel={() => {
                    onOpenEdit({open: false, object: null});
                }}
                editObject={editObject}
                currentProjectId={projectId}
                selectedClientId={clientId}
                fetchList={fetchList}
                unlimitedProject={projectUnlimited}
            />
        );
    };

    const renderAddDeposit = () => {
        const {
            isOpenAddDeposit,
            projectId,
            objectId,
            name,
        } = changeDepositData;

        if (!isOpenAddDeposit) {
            return null;
        }

        return (
            <ObjectsAddDeposit
                name={name}
                clientId={clientId}
                projectId={projectId}
                objectId={objectId}
                fetchData={fetchList}
                onClose={() => {
                    onOpenAddDeposit({});
                }}
            />
        );
    };

    const renderReturnDeposit = () => {
        const {
            isOpenReturnDeposit,
            projectId,
            objectId,
            name,
        } = changeDepositData;

        if (!isOpenReturnDeposit) {
            return null;
        }

        return (
            <ObjectsReturnDeposit
                name={name}
                clientId={clientId}
                projectId={projectId}
                objectId={objectId}
                fetchData={fetchList}
                onClose={() => {
                    onOpenReturnDeposit({});
                }}
            />
        );
    };

    const renderConfirmWindow = () => {
        const {
            isMassConfirm,
            confirmTitle,
            confirmText,
            confirmWarning,
            func,
            massList,
        } = confirmData || {};

        if (!isOpenConfirm) {
            return null;
        }

        if (isMassConfirm) {
            return (
                <MassConfirm
                    publicationCount={countSelected}
                    list={massList}
                    title="Архивирование объектов"
                    text={"Вы действительно хотите добавить нижепредставленные объекты в статусе \"закрыт\" в архив?"}
                    successLabel="К архивированию"
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    onConfirm={func}
                    onCancel={onCloseConfirm}
                    isShowIconItems={false}
                    isShowDelimiter={true}
                />
            );
        }

        return (
            <NmConfirmV2
                onCancel={onCloseConfirm}
                onConfirm={func}
                confirmButton="Да"
                cancelButton="Нет"
                isOnlyConfirm={true}
                title={confirmTitle}
                content={confirmText}
                warning={confirmWarning}
                size="sm"
            />
        );
    };

    const renderImportForm = () => {
        return (
            openImportModal &&
            <ImportFromFilePatternV2
                patternLink="/files/Список объектов для пополнения депозита.xlsx"
                onSubmit={submitImportDepositForm}
                onClose={() => setOpenImportModal(false)}
                progress={progressDepositImport}
            />
        );
    };

    const getFilters = () => {
        return [
            ...OBJECTS_FILTERS,
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "smzFrameContractEnabled",
                        label: "Заключать отдельный договор НПД на объекте",
                        options: SimpleWithEmptyDropdownOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "smzFrameContractTemplateId",
                        label: "Шаблон РД НПД",
                        placeholder: "Не выбран",
                        options: allOptionsInfo.smzFrameContractTemplateId,
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "civilFrameContractEnabled",
                        label: "Заключать отдельный договор НДФЛ на объекте",
                        options: SimpleWithEmptyDropdownOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "civilFrameContractTemplateId",
                        label: "Шаблон РД НДФЛ",
                        placeholder: "Не выбран",
                        options: allOptionsInfo.civilFrameContractTemplateId,
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "individualFrameContractEnabled",
                        label: "Заключать отдельный договор ИП на объекте",
                        options: SimpleWithEmptyDropdownOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "individualFrameContractTemplateId",
                        label: "Шаблон РД ИП",
                        placeholder: "Не выбран",
                        options: allOptionsInfo.individualFrameContractTemplateId,
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    clientBrandsFilter,
                ],
            },
            {
                row: [
                    withoutBrandFilter,
                ],
            },
        ];
    };

    return (
        <NmPage
            noPadding
            className="objects"
            header={
                <NmTitle
                    size="xl"
                    count={!projectId && totalCount}
                >
                    Объекты
                </NmTitle>
            }
            controls={
                <MediaButtons
                    size="xl"
                    config={headerMediaControls}
                />
            }
            filtersBase={
                <Filter
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    filters={getFilters()}
                    initState={initFilter}
                    isInitStateEqualEmpty={true}
                />
            }
            typeFilter="vertical"
            subHeader={
                !projectId && ![FOREMAN].includes(role) &&
                <>
                    <div className="registry-list__details-item">
                        <Text
                            className="mb-2"
                            medium
                            level="2"
                            color={COLOR.SECONDARY_70}
                            noWrap
                        >
                            Детализация по счету для выплат исполнителям с типом налогообложения НПД
                        </Text>
                        <DepositValuesAmountInfo
                            hideDetails={hideDetails}
                            clientId={clientId}
                        />
                    </div>
                    {
                        civilRegistryPaymentsAvailable &&
                        <div className="registry-list__details-item">
                            <Text
                                className="mb-2"
                                medium
                                level="2"
                                color={COLOR.SECONDARY_70}
                                noWrap
                            >
                                Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                            </Text>
                            <DepositValuesAmountInfo
                                hideDetails={hideDetails}
                                clientId={clientId}
                                isCivil
                            />
                        </div>
                    }
                </>
            }
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressList}
        >
            {
                isOpenLogDepositHistoryModal &&
                <ObjectLogDepositHistoryModal
                    onClose={() => toggleLogDepositHistoryModal()}
                    logData={logData}
                />
            }
            {renderImportForm()}
            {renderConfirmWindow()}
            {renderEditObjectWindow()}
            {renderAddDeposit()}
            {renderReturnDeposit()}
            {
                (projectId && !archived) &&
                <Tabs
                    secondary
                    className="mb-4"
                    panes={getStatusFilterTabs()}
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progressList}
                    /> :
                    <CheckboxList
                        sort={true}
                        sortOptions={options}
                        onClickSort={onClickSort}
                        rows={getRows()}
                        count={countSelected}
                        mediaControls={
                            isVisibleMassActions && {
                                renderCount: {
                                    desktop: 2,
                                    tablet: 2,
                                    mobile: 0,
                                },
                                buttons: [
                                    {
                                        component: COMPONENT.BUTTON_WITH_TOOLTIP,
                                        props: {
                                            size: "lg",
                                            disabled: !countSelected,
                                            onClick: () => {
                                                onOpenConfirm({
                                                    isMassConfirm: true,
                                                    func: multipleArchiveObject,
                                                    massList: selectedList.filter(item => item.isSelected),
                                                });
                                            },
                                            children: "В архив",
                                            color: "grey",
                                            tooltip: (
                                                statusFilter !== OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE &&
                                                <HelpTooltip
                                                    children="В архив можно добавить только объекты в статусе “Закрыт”"
                                                    position="bottom-left"
                                                />
                                            ),
                                        },
                                    },
                                ],
                            }}
                        onSelectedRows={isVisibleMassActions && handleSelectedRows}
                    />
            }
        </NmPage>
    );
};

export default Objects;