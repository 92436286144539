import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffPromotionChannelsCardSelector = state => state.bff.promotionChannelsCard;
export const bffPromotionChannelsCardInfoSelector  = createSelector(bffPromotionChannelsCardSelector, ({card}) => card);
export const bffPromotionChannelsCardListSelector  = createSelector(bffPromotionChannelsCardSelector, ({list}) => list);
export const bffPromotionChannelsCardTotalCountSelector  = createSelector(bffPromotionChannelsCardSelector, ({totalCount}) => totalCount);
export const bffPromotionChannelsCardTotalPagesSelector  = createSelector(bffPromotionChannelsCardSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffPromotionChannelsCardProgressCardActionSelector  = createSelector(bffPromotionChannelsCardSelector, ({progressCard}) => progressCard);
export const bffPromotionChannelsCardProgressListActionSelector  = createSelector(bffPromotionChannelsCardSelector, ({progressList}) => progressList);
export const bffPromotionChannelsCardProgressActionSelector  = createSelector(bffPromotionChannelsCardSelector, ({progressAction}) => progressAction);