import {createSelector} from "reselect";

import {SOURCE_TYPE_FILTER} from "../../../../containers/task/list";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions, phoneCodeListToOptions} from "../../../../utils/objectHelper";
import {getClientUsagesOrderTypesSortOptions} from "../../../../utils/selectors";
import {getFormattedFullName, getFullName} from "../../../../utils/stringFormat";

import {COMMON_ALL_OPTION} from "../../../../constants/common";
import {TASK_TYPE} from "../../../../constants/task";

//*  SELECTORS  *//
export const bffCommonDictsSelector = state => state.bff.common.dicts;
export const bffCommonOnBoardingScenarioFrameContractTypeDictOptionsSelector = createSelector(bffCommonDictsSelector, ({onBoardingScenarioFrameContractTypeDict}) => {
    return [
        COMMON_ALL_OPTION,
        ...dictionaryToOptions(onBoardingScenarioFrameContractTypeDict),
    ];
});
export const bffCommonDictsOnBoardingContractorSourceSelector = createSelector(bffCommonDictsSelector, ({onBoardingContractorSourceDict}) => {
    return [
        COMMON_ALL_OPTION,
        ...dictionaryToOptions(onBoardingContractorSourceDict),
    ];
});
export const bffCommonDictsLeadRegistrationStatusDictOptionsSelector = createSelector(bffCommonDictsSelector, ({onBoardingLeadRegistrationStatusDict}) => {
    return dictionaryToOptions(onBoardingLeadRegistrationStatusDict);
});
export const bffCommonDictsMenuDesignDictSelector = createSelector(bffCommonDictsSelector, ({menuDesignDict}) => menuDesignDict);
export const bffCommonDictsMenuDesignDictOptionsSelector = createSelector(bffCommonDictsSelector, ({menuDesignDict}) => dictionaryToOptions(menuDesignDict));
export const bffCommonDictsPhoneCodeListSelector = createSelector(bffCommonDictsSelector, ({phoneCodeList}) => phoneCodeList);
export const bffCommonDictsPhoneCodeOptionsSelector = createSelector(bffCommonDictsSelector, ({phoneCodeList}) => phoneCodeListToOptions(phoneCodeList));
export const bffCommonDictsFlashCallListSelector = createSelector(bffCommonDictsSelector, ({flashCallList}) => flashCallList);
export const bffCommonDictsFlashCallTotalCountSelector = createSelector(bffCommonDictsSelector, ({flashCallTotalCount}) => flashCallTotalCount);
export const bffCommonDictsFlashCallProgressSelector = createSelector(bffCommonDictsSelector, ({flashCallProgress}) => flashCallProgress);
export const bffCommonDictsFlashCallProgressActionSelector = createSelector(bffCommonDictsSelector, ({flashCallProgressAction}) => flashCallProgressAction);
export const bffCommonDictsFlashCallTotalPagesSelector = createSelector(bffCommonDictsSelector, ({flashCallTotalCount, flashCallPageData = {}}) => {
    return getTotalPages(flashCallTotalCount, flashCallPageData.pageSize);
});
export const bffCommonDictsClientWorkTypeDictSelector = createSelector(
    bffCommonDictsSelector,
    ({clientWorkTypeDict}) => clientWorkTypeDict,
);
export const bffCommonDictsClientWorkTypeDictOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({clientWorkTypeDict}) => dictionaryToOptions(clientWorkTypeDict),
);
export const bffCommonDictsKedoRoleDictSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoRoleDict}) => kedoRoleDict,
);

export const bffCommonDictsKedoRoleDictOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoRoleDict}) => dictionaryToOptions(kedoRoleDict),
);

export const bffCommonDictsKedoSignatureTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoSignaturesTypes}) => dictionaryToOptions(kedoSignaturesTypes),
);

export const bffCommonDictsKedoOfficeTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoOfficeTypes}) => dictionaryToOptions(kedoOfficeTypes),
);

export const bffCommonDictsKedoDocumentsCreatedViaTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoDocumentsCreatedViaTypes}) => dictionaryToOptions(kedoDocumentsCreatedViaTypes),
);

export const bffCommonDictsKedoSignatureTransactionsActionTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoSignatureTransactionsActionTypes}) => dictionaryToOptions(kedoSignatureTransactionsActionTypes),
);

export const bffCommonDictsKedoSignatureTransactionsDecideTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoSignatureTransactionsDecideTypes}) => dictionaryToOptions(kedoSignatureTransactionsDecideTypes),
);

export const bffCommonDictsPositionsOptionsSelector =
    createSelector(bffCommonDictsSelector, ({positions}) => {
        return positions?.map(item => {
            return {
                key: item.positionId,
                value: item.positionId,
                text: item.name,
            };
        });
    });
export const bffCommonDictsSubdivisionsOptionsSelector =
    createSelector(bffCommonDictsSelector, ({subdivisions}) => {
        return subdivisions?.map(item => {
            return {
                key: item.subdivisionId,
                value: item.subdivisionId,
                text: item.name,
            };
        });
    });

export const bffCommonDictsKedoDocumentStatesSelector =
    createSelector(bffCommonDictsSelector, ({kedoDocumentStatesDict}) => kedoDocumentStatesDict);
export const bffCommonDictsKedoDocumentFileSourceTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoDocumentFileSourceTypesDict}) => dictionaryToOptions(kedoDocumentFileSourceTypesDict),
    );
export const bffCommonDictsKedoParticipantStatesSelector =
    createSelector(bffCommonDictsSelector, ({kedoParticipantStatesDict}) => kedoParticipantStatesDict);

export const bffCommonDictsDocumentHistoryEventTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoDocumentHistoryEventTypes}) => dictionaryToOptions(kedoDocumentHistoryEventTypes),
    );

const taskTypesSelector = (state, sourceTypeFilter) => {
    const {
        taskTypes,
    } = state.bff.common.dicts;

    return Object.keys(taskTypes)
        .map(value => ({
            key: value,
            value,
            text: taskTypes[value]?.description,
        }))
        .filter(item => {
            if (sourceTypeFilter === SOURCE_TYPE_FILTER.KEDO) {
                return item.key === TASK_TYPE.KEDO_STAFF_IMPORT.VALUE;
            }

            return true;
        });
};
export const bffCommonDictsTaskTypesOptionsSelector =
    createSelector(
        taskTypesSelector,
        (taskTypes) => taskTypes,
    );
export const bffCommonDictsKedoExportStateTypesSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoExportStateTypes}) => kedoExportStateTypes,
    );
export const bffCommonDictsKedoExportTypesSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoExportTypes}) => kedoExportTypes,
    );
export const bffCommonDictsCustomDocumentTemplateLogEventTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({customDocumentTemplateLogEventTypes}) => dictionaryToOptions(customDocumentTemplateLogEventTypes),
    );
export const bffCommonDictsClientNamesSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientNames}) => clientNames.map(item => {
            return {
                key: item.clientId,
                value: item.clientId,
                text: item.brand ? `${item.name} (${item.brand})` : item.name,
            };
        }),
    );
export const bffCommonDictsClientUsersFioOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientUsersFio}) => clientUsersFio.map(item => {
            return {
                key: item.clientUserId,
                value: item.clientUserId,
                text: getFormattedFullName(
                    getFullName(
                        item.lastName,
                        item.firstName,
                        item.patronymic,
                    ),
                ),
            };
        }),
    );
export const bffCommonDictsCustomDocumentTemplateNamesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({customDocumentTemplateNames}) => customDocumentTemplateNames.map(item => {
            return {
                key: item.templateId,
                value: item.templateId,
                text: item.name,
            };
        }),
    );
export const bffCommonDictsClientUsagesLogTypesSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientUsagesLogTypes}) => clientUsagesLogTypes,
    );
export const bffCommonDictsClientUsagesOrderTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientUsagesOrderTypes}) => getClientUsagesOrderTypesSortOptions(clientUsagesOrderTypes),
    );
export const bffCommonDictsClientBrandsOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientBrands}) => {
            return clientBrands.map(item => {
                return {
                    key: item.brandId,
                    value: item.brandId,
                    text: item.brandName,
                };
            });
        },
    );
export const bffCommonDictsClientCategoriesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientCategoriesDict}) => dictionaryToOptions(clientCategoriesDict),
    );
export const bffCommonDictsClientCategoriesDictSelector =
    createSelector(
        bffCommonDictsSelector,
        ({clientCategoriesDict}) => clientCategoriesDict,
    );
export const bffCommonDictsContractorApplicationTypesSelector =
    createSelector(
        bffCommonDictsSelector,
        ({contractorApplicationTypes}) => contractorApplicationTypes,
    );
export const bffCommonDictsContractorApplicationTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({contractorApplicationTypes}) => {
            return [
                COMMON_ALL_OPTION,
                ...dictionaryToOptions(contractorApplicationTypes),
            ];
        },
    );
export const bffCommonDictsKedoDocumentCodesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoAllDocumentCodes}) => {
            return Object.keys(kedoAllDocumentCodes).map(code => {
                return {
                    key: code,
                    value: code,
                    text: `${code} - ${kedoAllDocumentCodes[code]}`,
                };
            });
        },
    );
export const bffCommonDictsKedoDepartmentsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoDepartmentNames}) => kedoDepartmentNames,
    );
export const bffCommonDictsKedoDepartmentNamesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoDepartmentNames}) => {
            return kedoDepartmentNames.map((item) => {
                return {
                    key: item.kedoDepartmentId,
                    value: item.kedoDepartmentId,
                    text: item.name,
                    isRootDepartment: item.isRootDepartment,
                };
            });
        },
    );
export const bffCommonDictsTaskTypesDictSelector = createSelector(
    bffCommonDictsSelector,
    ({serviceTaskTypes}) => serviceTaskTypes,
);
export const bffCommonDictsTaskStatusesDictSelector = createSelector(
    bffCommonDictsSelector,
    ({taskStatuses}) => taskStatuses,
);

const formatKedoStaff = (item) => {
    return {
        key: item.kedoStaffId,
        value: item.kedoStaffId,
        text: getFormattedFullName(
            getFullName(
                item.lastName,
                item.firstName,
                item.patronymic,
            ),
        ),
    };
};

const getKedoStaffList = (list, filterId) => {
    return list
        .filter(item => filterId ?  item.kedoStaffId !== filterId : true)
        .map(formatKedoStaff);
};

export const bffCommonDictsKedoStaffDepartmentDeputyHeadListOptionsSelector =
    createSelector(
        [
            bffCommonDictsSelector,
            (_state, filterId) => filterId,
        ],
        ({kedoStaffDepartmentDeputyHeadList}, filterId) => {
            return getKedoStaffList(kedoStaffDepartmentDeputyHeadList, filterId);
        },
    );

export const bffCommonDictsKedoStaffDepartmentHeadOptionsSelector =
    createSelector(
        [
            bffCommonDictsSelector,
            (_state, filterId) => filterId,
        ],
        ({kedoStaffDepartmentHeadList}, filterId) => {
            return getKedoStaffList(kedoStaffDepartmentHeadList, filterId);
        },
    );

export const bffCommonDictsKedoStaffFioOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoStaffFioList}) => {
        return kedoStaffFioList.map(formatKedoStaff);
    },
);

export const bffCommonDictsKedoDocumentRouteOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoDocumentRouteList}) => {
        return kedoDocumentRouteList.map(item => {
            return {
                key: item.routeId,
                value: item.routeId,
                text: item.name,
            };
        });
    },
);
export const bffCommonDictsKedoDocumentRouteForClientOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoDocumentRouteList}) => {
        return kedoDocumentRouteList.map(item => {
            return {
                key: item.routeId,
                value: item.routeId,
                text: item.nameForClient,
            };
        });
    },
);

export const bffCommonDictsKedoStaffRegistryPositionsDictSelector = createSelector(
    bffCommonDictsSelector, ({kedoStaffPositionsList}) => {
        return kedoStaffPositionsList.map(item => {
            return {
                key: item.positionId,
                value: item.positionId,
                text: item.name,
            };
        });
    },
);

export const bffCommonDictsContractorEducationDictSelector = createSelector(bffCommonDictsSelector, ({educationDict}) => educationDict);
export const bffCommonDictsContractorEducationDictOptionsSelector = createSelector(bffCommonDictsSelector, ({educationDict}) => dictionaryToOptions(educationDict));
export const bffCommonDictsContractorLanguageLevelsDictSelector = createSelector(bffCommonDictsSelector, ({contractorLanguageLevelsDict}) => contractorLanguageLevelsDict);
export const bffCommonDictsContractorLanguageLevelsDictOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({contractorLanguageLevelsDict}) => dictionaryToOptions(contractorLanguageLevelsDict),
);
export const bffCommonDictsKedoAllDocumentTypesOptionsSelector =
    createSelector(
        bffCommonDictsSelector,
        ({kedoAllDocumentTypes}) => {
            return kedoAllDocumentTypes.map((item) => {
                return {
                    key: item.documentTypeId,
                    value: item.documentTypeId,
                    text: item.name,
                };
            });
        },
    );
export const bffCommonDictsKedoDepartmentLogTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoDepartmentLogTypes}) => dictionaryToOptions(kedoDepartmentLogTypes),
);
export const bffCommonDictsKedoDepartmentLogMergeTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({kedoDepartmentLogMergeTypes}) => dictionaryToOptions(kedoDepartmentLogMergeTypes),
);
export const kedoDirectoriesUploadedDocumentTypesSelector = createSelector(
    bffCommonDictsSelector,
    ({uploadedDocumentTypes}) => {
        const {
            totalCount = 0,
            list = [],
            pageData = {},
            progress,
        } = uploadedDocumentTypes;

        return {
            pageData,
            progress,
            totalCount,
            list,
            totalPages: getTotalPages(totalCount, pageData.pageSize),
        };
    },
);
export const kedoDirectoriesUploadedDocumentTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({uploadedDocumentTypes}) => {
        const {list = []} = uploadedDocumentTypes;

        return list.map(item => {
            return {
                key: item.documentTypeId,
                value: item.documentTypeId,
                text: item.name,
            };
        });
    },
);
export const kedoDirectoriesTemplateDocumentTypesSelector = createSelector(
    bffCommonDictsSelector,
    ({templateDocumentTypes}) => {
        const {
            totalCount = 0,
            list = [],
            pageData = {},
            progress,
        } = templateDocumentTypes;

        return {
            pageData,
            progress,
            totalCount,
            list,
            totalPages: getTotalPages(totalCount, pageData.pageSize),
        };
    },
);
export const kedoDirectoriesTemplateDocumentTypesOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({templateDocumentTypes}) => {
        const {list = []} = templateDocumentTypes;

        return list.map(item => {
            return {
                key: item.documentTypeId,
                value: item.documentTypeId,
                text: item.name,
            };
        });
    },
);