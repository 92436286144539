import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {transformDictListForSelectedList} from "../../../../components/NmSelectedList/utils/transformDictList";
import {getTotalPages} from "../../../../utils/mathHelper";

export const vacancyCandidatesSelector = state => state.bff.recruitment.vacancyCandidates;
export const bffRecruitmentVacancyCandidatesProgressSelector = createSelector(vacancyCandidatesSelector, ({progress}) => progress);
export const bffRecruitmentVacancyCandidatesProgressActionSelector = createSelector(vacancyCandidatesSelector, ({progressAction}) => progressAction);
export const bffRecruitmentVacancyCandidatesErrorSelector = createSelector(vacancyCandidatesSelector, ({error}) => error);
export const bffRecruitmentVacancyCandidatesListSelector = createSelector(vacancyCandidatesSelector, ({list}) => {
    return list.map(item => {
        return {
            ...item,
            specialities: transformDictListForSelectedList(item.specialities),
            cities: transformDictListForSelectedList(item.cities),
        };
    });
});
export const bffRecruitmentVacancyCandidatesTotalCountSelector = createSelector(vacancyCandidatesSelector, ({totalCount}) => totalCount);
export const bffRecruitmentVacancyCandidateIdsSelector = createSelector(vacancyCandidatesSelector, ({list}) => {
    return list.map(item => item.candidateId).filter(Boolean);
});
export const bffRecruitmentVacancyCandidatesTotalPagesSelector = createSelector(
    vacancyCandidatesSelector,
    ({totalCount, pageData: {pageSize = 25}}) => getTotalPages(totalCount, pageSize),
);
export const bffRecruitmentVacancyCandidatesProgressCardSelector = createSelector(vacancyCandidatesSelector, ({progressCard}) => progressCard);
export const bffRecruitmentVacancyCandidatesCardSelector = createSelector(vacancyCandidatesSelector, ({card}) => {
    return {
        ...card,
        jobBoardsResumes: !isEmpty(card.resumes) ? card.resumes.filter(item => item.type) : [],
        otherResumes: !isEmpty(card.resumes) ? card.resumes.filter(item => !item.type) : [],
        specialities: transformDictListForSelectedList(card.specialities),
        cities: transformDictListForSelectedList(card.cities),
        specialityIds: !isEmpty(card.specialities) ? card.specialities.map(item => item.id) : [],
        addressFiasIds: !isEmpty(card.cities) ? card.cities.map(item => item.id) : [],
        citiesOptions: !isEmpty(card.cities) ? card.cities.map(item => {
            return {
                key: item.id,
                value: item.id,
                text: item.value,
            };
        }) : [],
    };
});
export const bffRecruitmentVacancyCandidatesCardErrorSelector = createSelector(vacancyCandidatesSelector, ({cardError}) => cardError);
export const bffRecruitmentVacancyCandidatesIsEmptyCardSelector = createSelector(vacancyCandidatesSelector, ({card}) => isEmpty(card));
export const bffRecruitmentVacancyCandidatesPageDataSelector = createSelector(vacancyCandidatesSelector, ({pageData}) => pageData);
export const bffRecruitmentVacancyCandidatesForResponsePageDataSelector = createSelector(vacancyCandidatesSelector, ({candidatesForResponsePageData}) => candidatesForResponsePageData);
export const bffRecruitmentVacancyCandidatesForResponseProgressSelector = createSelector(vacancyCandidatesSelector, ({candidatesForResponseProgress}) => candidatesForResponseProgress);
export const bffRecruitmentVacancyCandidatesForResponseListSelector = createSelector(vacancyCandidatesSelector, ({candidatesForResponseList}) => candidatesForResponseList);
export const bffRecruitmentVacancyCandidatesForResponseTotalCountSelector = createSelector(vacancyCandidatesSelector, ({candidatesForResponseTotalCount}) => candidatesForResponseTotalCount);
export const bffRecruitmentVacancyCandidatesForResponseTotalPagesSelector = createSelector(
    vacancyCandidatesSelector,
    ({candidatesForResponseTotalCount, candidatesForResponsePageData: {pageSize = 0}}) => getTotalPages(candidatesForResponseTotalCount, pageSize),
);
export const bffRecruitmentCandidatesFeedListSelector = createSelector(vacancyCandidatesSelector, ({feedList}) => feedList);
export const bffRecruitmentCandidatesFeedProgressSelector = createSelector(vacancyCandidatesSelector, ({feedProgress}) => feedProgress);
export const bffRecruitmentCandidatesMessageTemplatesSelector = createSelector(
    vacancyCandidatesSelector,
    ({messageTemplates}) => messageTemplates.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.name,
            templateText: item.value,
        };
    }),
);
export const bffRecruitmentCandidatesProgressMessageTemplatesSelector = createSelector(
    vacancyCandidatesSelector,
    ({progressMessageTemplates}) => progressMessageTemplates,
);



