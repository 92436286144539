import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getKedoDepartmentsStaff} from "./actionCreators";
import {
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS,
    KEDO_DEPARTMENTS_STAFF_MOVE_ERROR,
    KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST,
    KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS,
} from "./actions";
import {kedoDepartmentsStaffPageDataSelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const controllers = {
    client: "/client-adm/kedo/organization-structure/department/staff",
    admin: "/adm/companies/company/kedo/organization-structure/department/staff",
};

// POST /bff/adm/companies/company/kedo/organization-structure/department/staff/get-page
// POST /bff/client-adm/kedo/organization-structure/department/staff/get-page
// Получение страницы сотрудников отдела
const getKedoDepartmentsStaffPageSaga = function* ({payload}) {
    const {
        ...reqData
    } = payload;
    const action = "/get-page";
    const url = getBffUrl({
        isClientCard: true,
        clientRolesUrl: `${controllers.client}${action}`,
        adminRolesUrl: `${controllers.admin}${action}`,
    });

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/companies/company/kedo/organization-structure/department/staff/move
// POST /bff/client-adm/kedo/organization-structure/department/staff/move
// Перемещение сотрудников в другой отдел
const moveKedoDepartmentStaffSaga = function* ({payload}) {
    const {
        ...reqData
    } = payload;
    const action = "/move";
    const url = getBffUrl({
        isClientCard: true,
        clientRolesUrl: `${controllers.client}${action}`,
        adminRolesUrl: `${controllers.admin}${action}`,
    });

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_DEPARTMENTS_STAFF_MOVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = kedoDepartmentsStaffPageDataSelector(state);

        yield put(getKedoDepartmentsStaff(pageData));
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_STAFF_MOVE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST, getKedoDepartmentsStaffPageSaga),
        takeEvery(KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST, moveKedoDepartmentStaffSaga),
    ]);
}