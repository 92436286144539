import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getKedoTemplateDocumentTypesPage,
    updateCommonPaymentDicts,
} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    kedoDirectoriesTemplateDocumentTypesOptionsSelector,
} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoTemplateDocumentTypesDropdown = (params) => {
    const {
        clientId,
        routeId,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(kedoDirectoriesTemplateDocumentTypesOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                templateDocumentTypes: {},
            }));
        };
    }, []);

    const fetchList = (nameFilter) => {
        dispatch(getKedoTemplateDocumentTypesPage({
            clientId,
            routeId,
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            nameFilter: handleFormString(nameFilter),
        }));
    };

    const onSearchChange = (nameFilter) => {
        fetchList(nameFilter);
    };

    return {
        options,
        label: "Типы шаблонных документов",
        placeholder: "Выберите типы шаблонных документов",
        onSearchChange,
        search: true,
    };
};