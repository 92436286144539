import {
    ADD_COLLECTION_BANK_DETAILS_ERROR,
    ADD_COLLECTION_BANK_DETAILS_REQUEST,
    ADD_COLLECTION_BANK_DETAILS_SUCCESS,
    DELETE_COLLECTION_BANK_DETAILS_ERROR,
    DELETE_COLLECTION_BANK_DETAILS_REQUEST,
    DELETE_COLLECTION_BANK_DETAILS_SUCCESS,
    GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
    GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST,
    GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS,
    UPDATE_COLLECTION_BANK_DETAILS_ERROR,
    UPDATE_COLLECTION_BANK_DETAILS_REQUEST,
    UPDATE_COLLECTION_BANK_DETAILS_SUCCESS,
    UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE,
} from "./actions";

const initial = {
    progress: null,
    errorMessage: "",
    progressGetPage: false,
    pageData: {},
    totalCount: null,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progressGetPage: true,
            };
        }
        case ADD_COLLECTION_BANK_DETAILS_REQUEST:
        case UPDATE_COLLECTION_BANK_DETAILS_REQUEST:
        case DELETE_COLLECTION_BANK_DETAILS_REQUEST:{
            return {
                ...state,
                progress: true,
            };
        }
        case GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS: {
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                progressGetPage: false,
            };
        }
        case ADD_COLLECTION_BANK_DETAILS_SUCCESS:
        case UPDATE_COLLECTION_BANK_DETAILS_SUCCESS:
        case DELETE_COLLECTION_BANK_DETAILS_SUCCESS:{
            return {
                ...state,
                progress: false,
            };
        }
        case GET_PAGE_COLLECTION_BANK_DETAILS_ERROR: {
            return {
                ...state,
                progressGetPage: false,
                errorMessage: payload,
            };
        }
        case ADD_COLLECTION_BANK_DETAILS_ERROR:
        case UPDATE_COLLECTION_BANK_DETAILS_ERROR:
        case DELETE_COLLECTION_BANK_DETAILS_ERROR:{
            return {
                ...state,
                progress: false,
            };
        }
        case UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};