import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/access";
//*  TYPES  *//

const GET_AVAILABLE_CLIENT_USERS_REQUEST = "GET_AVAILABLE_CLIENT_USERS_REQUEST";
const GET_AVAILABLE_CLIENT_USERS_SUCCESS = "GET_AVAILABLE_CLIENT_USERS_SUCCESS";
const GET_AVAILABLE_CLIENT_USERS_ERROR = "GET_AVAILABLE_CLIENT_USERS_ERROR";

const UPDATE_AVAILABLE_CLIENT_USERS_REQUEST = "UPDATE_AVAILABLE_CLIENT_USERS_REQUEST";
const UPDATE_AVAILABLE_CLIENT_USERS_SUCCESS = "UPDATE_AVAILABLE_CLIENT_USERS_SUCCESS";
const UPDATE_AVAILABLE_CLIENT_USERS_ERROR = "UPDATE_AVAILABLE_CLIENT_USERS_ERROR";

const AVAILABLE_CLIENT_USERS_UPDATE_STORE = "AVAILABLE_CLIENT_USERS_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_AVAILABLE_CLIENT_USERS_REQUEST:
        case UPDATE_AVAILABLE_CLIENT_USERS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_AVAILABLE_CLIENT_USERS_SUCCESS:
            return {
                ...state,
                list: payload,
                progress: false,
            };
        case UPDATE_AVAILABLE_CLIENT_USERS_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case GET_AVAILABLE_CLIENT_USERS_ERROR:
        case UPDATE_AVAILABLE_CLIENT_USERS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case AVAILABLE_CLIENT_USERS_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};

//*  ACTION CREATORS  */

export function getAvailableClientUsers(payload) {
    return {
        type: GET_AVAILABLE_CLIENT_USERS_REQUEST,
        payload,
    };
}

export function updateAvailableClientUsers(payload) {
    return {
        type: UPDATE_AVAILABLE_CLIENT_USERS_REQUEST,
        payload,
    };
}

export function updateAvailableClientUsersStore(payload) {
    return {
        type: AVAILABLE_CLIENT_USERS_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//

export const availableClientUsersAccessSelector = ({availableClientUsersAccess}) => availableClientUsersAccess;
export const availableClientUsersIdsSelector = createSelector(availableClientUsersAccessSelector, ({list}) => {
    return list.map(({clientIUserId}) => clientIUserId);
});
export const availableClientUsersProgressSelector = createSelector(availableClientUsersAccessSelector, ({progress}) => progress);

//*  SAGA  *//

//GET /api/access/client/{clientId}/availableUsers
export const getAvailableClientUsersSaga = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.get(`${controller}/client/${clientId}/availableUsers`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_AVAILABLE_CLIENT_USERS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_AVAILABLE_CLIENT_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_AVAILABLE_CLIENT_USERS_ERROR,
            payload: error,
        });
    }
};

//PUT /api/access/availableClients
export const updateAvailableClientUsersSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.put(`${controller}/availableClients`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_AVAILABLE_CLIENT_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: UPDATE_AVAILABLE_CLIENT_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_AVAILABLE_CLIENT_USERS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_AVAILABLE_CLIENT_USERS_REQUEST, getAvailableClientUsersSaga),
        takeEvery(UPDATE_AVAILABLE_CLIENT_USERS_REQUEST, updateAvailableClientUsersSaga),
    ]);
}
