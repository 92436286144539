import {
    CONTRACTOR_RESOURCES_EXPORT_ERROR,
    CONTRACTOR_RESOURCES_EXPORT_REQUEST,
    CONTRACTOR_RESOURCES_EXPORT_SUCCESS,
    CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST, CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS,
    CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS,
    CONTRACTOR_RESOURCES_GET_PAGE_ERROR,
    CONTRACTOR_RESOURCES_GET_PAGE_REQUEST,
    CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS,
    CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS,
    CONTRACTOR_RESOURCES_START_JOB_ERROR,
    CONTRACTOR_RESOURCES_START_JOB_REQUEST,
    CONTRACTOR_RESOURCES_START_JOB_SUCCESS,
    UPDATE_CONTRACTOR_RESOURCES_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    resourcesProjectsNameIdMap: [],
    resourcesObjectsNameIdMap: [],
    resourcesClientNameIdMap: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_RESOURCES_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS:
            const {
                contractors = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: contractors,
                totalCount,
            };
        case CONTRACTOR_RESOURCES_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case CONTRACTOR_RESOURCES_EXPORT_REQUEST:
        case CONTRACTOR_RESOURCES_START_JOB_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CONTRACTOR_RESOURCES_EXPORT_SUCCESS:
        case CONTRACTOR_RESOURCES_EXPORT_ERROR:
        case CONTRACTOR_RESOURCES_START_JOB_SUCCESS:
        case CONTRACTOR_RESOURCES_START_JOB_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS:
            return {
                ...state,
                resourcesClientNameIdMap: payload,
            };
        case CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS:
            return {
                ...state,
                resourcesProjectsNameIdMap: payload,
            };
        case CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS:
            return {
                ...state,
                resourcesObjectsNameIdMap: payload,
            };
        case UPDATE_CONTRACTOR_RESOURCES_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};