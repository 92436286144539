import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../components/ActualComponents/DropzoneV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import validationTerminateContract from "./validation-rule";

import {addDays, dateWithoutTimezone} from "../../../../utils/dateFormat";

import {clientProgressSelector} from "../../../../ducks/client";

import PropTypes from "prop-types";

const TerminateContractorForm = (props) => {
    const {
        submit,
        clientId,
    } = props;

    const progress = useSelector(clientProgressSelector);

    const [isShowConfirm, setShowConfirm] = useState(false);
    const getTerminationDateTime = (date) => {
        return addDays(date, 10);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            appDateTime: new Date() ,
            terminationDateTime: getTerminationDateTime(new Date()),
            notices : [],
        },
        onSubmit: () => setShowConfirm(true),
        validationSchema: validationTerminateContract(),
    });

    useEffect(() => {
        if (!values.appDateTime) {
            return;
        }

        const terminationDateTime = getTerminationDateTime(new Date(values.appDateTime));

        if (terminationDateTime < new Date()) {
            setFieldValue("terminationDateTime", new Date());

            return;
        }

        setFieldValue("terminationDateTime", terminationDateTime);
    },[values.appDateTime]);

    const onDropFile = (files) => {
        setFieldValue("notices", files);
        setFieldTouched("notices", true);
    };

    const submitForm = () => {
        const getRequestFormData = () => {
            const formData = new FormData();

            formData.append("appDateTime", dateWithoutTimezone(values.appDateTime));
            formData.append("terminationDateTime", dateWithoutTimezone(values.terminationDateTime));
            formData.append("clientId", clientId);

            if (!isEmpty(values.notices) && values.notices[0].size) {
                values.notices.forEach(file => {
                    formData.append("notices", (file));
                });
            }

            return formData;
        };
        const pushData = getRequestFormData();

        submit(pushData);
    };

    return (
        <NmForm
            id="TerminateContractorForm"
            noValidate
            addMargin
            className="terminate-contract-form"
            onSubmit={handleSubmit}
        >
            <NmDatePicker
                required
                placeholderText="ДД.ММ.ГГ"
                dateFormat="dd.MM.yy"
                name="appDateTime"
                selected={values.appDateTime}
                onChange={(e, {name, value}) => {
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                }}
                error={touched && touched.appDateTime ?
                    errors && errors.appDateTime : false
                }
                isClearable
                label="Дата получения уведомления о расторжении"
            />
            <NmDatePicker
                required
                placeholderText="ДД.ММ.ГГ"
                dateFormat="dd.MM.yy"
                disabled
                name="terminationDateTime"
                selected={values.terminationDateTime}
                onChange={(e, {name, value}) => {
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                }}
                error={touched && touched.terminationDateTime ?
                    errors && errors.terminationDateTime : false
                }
                isClearable
                label="Дата расторжения договора"
            />
            <DropzoneV2
                files={values.notices}
                isEqualCheck
                multiple
                onChange={onDropFile}
                accept=".odt, .doc, .docx, .pdf, .jpg, .png"
                format="*.odt, *.doc, *.docx, *.pdf, *.jpg, *.png."
                maxSize={10}
                error={touched.notices ?
                    errors && errors.notices :
                    false}
            />
            {isShowConfirm &&
                <NmConfirmV2
                    mobile="column"
                    disabled={progress}
                    isNeedClosing={false}
                    content="Обратите внимание, процесс расторжения агентского договора является необратимым"
                    onCancel={() => setShowConfirm(false)}
                    onConfirm={submitForm}
                    isHiddenCancelOnMobile
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                />
            }
        </NmForm>
    );
};

TerminateContractorForm.propTypes = {
    submit: PropTypes.func,
};

export default TerminateContractorForm;