import {ORDER_FIELDS_NAME} from "../../../../constants/clientList";
import {ORDER_KIND} from "../../../../constants/order";
import {directoriesReg} from "../../../../constants/validationRules";

const requiredMessage = "Обязательное поле";

export const ORDER_EDIT_TYPE_OPTIONS = [
    {
        key: ORDER_KIND.AMOUNT_BASED_ORDER,
        value: ORDER_KIND.AMOUNT_BASED_ORDER,
        text: "Тип заказа 1",
    },
    {
        key: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
        value: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
        text: "Тип заказа 2",
    },
];

export const ORDER_CREATE_STEPS = {
    PRE_CONDITIONS: {
        NAME: "Предусловия",
        VALUE: "PRE_CONDITIONS",
    }, // 1-й шаг Предусловия
    FIRST_STEP: {
        NAME: "Основная информация",
        VALUE: "GENERAL_INFORMATION",
    }, // 2-й шаг - Основная информация
    SECOND_STEP: {
        NAME: "Дополнительные условия",
        VALUE: "ADDITIONAL_TERMS",
    }, // 3-й шаг -
    PUBLISH_STEP: {
        NAME: "Публикация",
        VALUE: "PUBLISHING",
    },
    ACT_TEMPLATE: {
        NAME: "Шаблон формирования акта",
        VALUE: "ACT_TEMPLATE",
    },
};

export const TABS = [
    {
        value: ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE,
        name: "Предусловия заказа",
    },
    {
        value: ORDER_CREATE_STEPS.FIRST_STEP.VALUE,
        name: "Основная информация",
    },
    {
        value: ORDER_CREATE_STEPS.SECOND_STEP.VALUE,
        name: "Дополнительные условия",
    },
    {
        value: ORDER_CREATE_STEPS.PUBLISH_STEP.VALUE,
        name: "Публикация",
    },
];

export const TEMPLATE_TABS = [
    {
        value: ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE,
        name: "Шаблоны документов",
    },
    {
        value: ORDER_CREATE_STEPS.FIRST_STEP.VALUE,
        name: "Основная информация",
    },
    {
        value: ORDER_CREATE_STEPS.SECOND_STEP.VALUE,
        name: "Дополнительные условия",
    },
    {
        value: ORDER_CREATE_STEPS.ACT_TEMPLATE.VALUE,
        name: "Шаблон формирования акта",
    },
];

export const ORDER_EDIT_DRAFT_VALIDATION_RULE = {
    speciality: {
        match: {
            value: directoriesReg,
            message: "Поле содержит некорректные символы",
        },
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
        minLength: {
            value: 2,
            message: "Минимальная длина строки 2 символов",
        },
    },
    [ORDER_FIELDS_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Не более 100 символов",
        },
    },
    [ORDER_FIELDS_NAME.OBJECT_ID]: {
        required: requiredMessage,
    },
};

export const ORDER_EDIT_PRE_CONDITIONS_STEP_VALIDATION_RULE = {
    orderManagerId: {
        required: requiredMessage,
    },
    objectId: {
        required: requiredMessage,
    },
};

export const ORDER_EDIT_TEMPLATE_PRE_CONDITIONS_STEP_VALIDATION_RULE = {
    templateProjectIds: {
        required: requiredMessage,
    },
    templateObjectIds: {
        required: requiredMessage,
    },
};

const orderGeneralFields = {
    name: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Не более 100 символов",
        },
    },
    [ORDER_FIELDS_NAME.DESCRIPTION]: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Не более 5000 символов",
        },
    },
    [ORDER_FIELDS_NAME.WORK_END_DATE]: {
        required: requiredMessage,
    },
    // Удалено по требованию аналитика
    // [ORDER_FIELDS_NAME.ADDRESS]: {
    //     required: requiredMessage,
    //     minLength: {
    //         value: 5,
    //         message: "Минимальная длина 5 символов"
    //     },
    //     maxLength: {
    //         value: 250,
    //         message: "Не более 250 символов"
    //     }
    // },
    [ORDER_FIELDS_NAME.KEYWORDS]: {
        maxLength: {
            value: 30,
            message: "Максимальное количество ключевых слов для одного заказа - 30",
        },
    },
};

export const contractorNeedCountRule = {
    required: requiredMessage,
    minValue: {
        value: 0,
        message: "Значение должно быть больше 0",
    },
};

export const ORDER_EDIT_AMOUNT_BASED_TYPE_VALIDATION_RULE = {
    ...orderGeneralFields,
    contractorsNeededCount: contractorNeedCountRule,
    [ORDER_FIELDS_NAME.AMOUNT]: {
        required: requiredMessage,
        minValue: {
            value: 9,
            message: "Сумма заказа на одного исполнителя не может быть менее 10 рублей",
        },
    },
};

export const ORDER_EDIT_VOLUME_OF_FORK_BASED_TYPE_VALIDATION_RULE = {
    ...orderGeneralFields,
    contractorsNeededCountSecondType: contractorNeedCountRule,
    [ORDER_FIELDS_NAME.WORK_UNIT]: {
        required: requiredMessage,
    },
    [ORDER_FIELDS_NAME.WORK_UNIT_PRICE]: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: "Значение должно быть больше 0",
        },
    },
    [ORDER_FIELDS_NAME.VOLUME_OF_WORK]: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: "Значение должно быть больше 0",
        },
    },
};

export const ORDER_EDIT_TEMPLATE_AMOUNT_BASED_TYPE_VALIDATION_RULE = {
    templateName: {
        required: requiredMessage,
    },
    [ORDER_FIELDS_NAME.DESCRIPTION]: {
        minLength: {
            value: 5,
            message: "Минимальная длина 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Не более 5000 символов",
        },
    },
    [ORDER_FIELDS_NAME.KEYWORDS]: {
        maxLength: {
            value: 30,
            message: "Максимальное количество ключевых слов для одного заказа - 30",
        },
    },
    templateProjectIds: {
        required: requiredMessage,
    },
    [ORDER_FIELDS_NAME.AMOUNT]: {
        minValue: {
            value: 9,
            message: "Сумма заказа на одного исполнителя не может быть менее 10 рублей",
        },
    },
};

export const ORDER_EDIT_TEMPLATE_VOLUME_OF_WORK_BASED_TYPE_VALIDATION_RULE = {
    templateName: {
        required: requiredMessage,
    },
    [ORDER_FIELDS_NAME.DESCRIPTION]: {
        minLength: {
            value: 5,
            message: "Минимальная длина 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Не более 5000 символов",
        },
    },
    [ORDER_FIELDS_NAME.KEYWORDS]: {
        maxLength: {
            value: 30,
            message: "Максимальное количество ключевых слов для одного заказа - 30",
        },
    },
    templateProjectIds: {
        required: requiredMessage,
    },
    contractorsNeededCountSecondType: {
        minValue: {
            value: 0,
            message: "Значение должно быть больше 0",
        },
    },
    [ORDER_FIELDS_NAME.WORK_UNIT_PRICE]: {
        minValue: {
            value: 0,
            message: "Значение должно быть больше 0",
        },
    },
    [ORDER_FIELDS_NAME.VOLUME_OF_WORK]: {
        minValue: {
            value: 0,
            message: "Значение должно быть больше 0",
        },
    },
};

export const ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS = [
    {
        textBlock: "Заказ типа 1 - заказ при создании которого необходимо задать сумму заказа",
    },
    {
        textBlock: "Заказ типа 2 - заказ при создании которого необходимо задать ТС и общий объем работ",
    },
];