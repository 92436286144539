import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_BITRIX_LEAD_BY_ID_ERROR,
    GET_BITRIX_LEAD_BY_ID_REQUEST,
    GET_BITRIX_LEAD_BY_ID_SUCCESS,
    SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR,
    SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST,
    SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS,
    SETTINGS_JOINING_REQUESTS_DECLINE_ERROR,
    SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST,
    SETTINGS_JOINING_REQUESTS_DECLINE_SUCCESS,
    SETTINGS_JOINING_REQUESTS_EXPORT_ERROR,
    SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST,
    SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_ERROR,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT} from "../../../../constants/messages";

const controller = "/adm/settings/joining-requests";

const getPageSettingsJoiningRequests = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const confirmSettingsJoiningRequests = function* ({payload}) {
    try {
        const {
            data,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${controller}/confirm`, data);
        const {
            guid,
            errorMessage,
        } = result;

        if (!guid) {
            toastError(errorMessage ? errorMessage : CLIENT.CLIENT_ADD_ERROR_TEXT);

            yield put({
                type: SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Заявка подтверждена");

        if (getResult) {
            getResult(guid);
        }

        yield put({
            type: SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR,
            payload: error,
        });
    }
};

const declineSettingsJoiningRequests = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${controller}/decline`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_JOINING_REQUESTS_DECLINE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_JOINING_REQUESTS_DECLINE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_JOINING_REQUESTS_DECLINE_ERROR,
            payload: error,
        });
    }
};

const exportSettingsJoiningRequests = function* ({payload}) {
    try {
        const {onSuccess} = payload;

        const result = yield request.bff.post(`${controller}/export`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_JOINING_REQUESTS_EXPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_JOINING_REQUESTS_EXPORT_ERROR,
            payload: error,
        });
    }
};

const getStatusesByIdsSettingsJoiningRequests = function* ({payload}) {
    try {
        const {ids} = payload;
        const result = yield request.bff.post(`${controller}/get-statuses`, ids);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_ERROR,
            payload: error,
        });
    }
};

const getBitrixLeadById = function* ({payload}) {
    try {
        const result = yield request.bff.get("/adm/settings/client-applications/bitrix/lead/getById", {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_BITRIX_LEAD_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_BITRIX_LEAD_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_BITRIX_LEAD_BY_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST, getPageSettingsJoiningRequests),
        takeEvery(SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST, confirmSettingsJoiningRequests),
        takeEvery(SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST, declineSettingsJoiningRequests),
        takeEvery(SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST, getStatusesByIdsSettingsJoiningRequests),
        takeEvery(SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST, exportSettingsJoiningRequests),
        takeEvery(GET_BITRIX_LEAD_BY_ID_REQUEST, getBitrixLeadById),
    ]);
}