import {isEmpty} from "lodash";

export const getRecruitmentCandidateExperienceValues = (editData) => {
    if (isEmpty(editData)) {
        return {
            monthStart: "",
            yearStart: "",
            monthEnd: "",
            yearEnd: "",
            organisation: "",
            position: "",
            description: "",
        };
    }

    const [yearStart, monthStart] = editData.periodFrom ? editData.periodFrom.split("-") : "";
    const [yearEnd, monthEnd] = editData.periodTo ? editData.periodTo.split("-") : "";

    return {
        monthStart,
        yearStart,
        monthEnd,
        yearEnd,
        organisation: editData.organisation,
        position: editData.position,
        description: editData.description,
    };
};