import {all, put, takeEvery} from "@redux-saga/core/effects";

import {BRIGADE_TOAST_MESSAGE} from "../../../../../containers/brigade/foreman-requests/foremanRequests";
import {getNonDecisionCount} from "../../../../clientSpecialityOffer";
import {getForemenNewRequestsAndPaymentsCount} from "./actionCreators";
import {
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS,
} from "./actions";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

import {ORDER_ERROR_CODE} from "../../../../../constants/order";
import {ADMIN, CLIENT_ADMIN, FOREMAN, NM_MANAGER, OBJECT_MANAGER, PROJECT_MANAGER} from "../../../../../constants/roles";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/foremen/requests",
        client: "/client-adm/client/foremen/requests",
    });
};

// POST /bff/adm/clients/client-card/foremen/requests/getRichPage
// POST /bff/client-adm/client/foremen/requests/getRichPage
// Получение страницы заявок с объектов
function* getClientForemenPaymentsRichPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR,
            payload: error,
        });
    }
}

// POST /bff/adm/clients/client-card/foremen/requests/update
// POST /bff/client-adm/client/foremen/requests/update
// Подтверждение/отклонение заявки
function* updateClientForemenRequestsSaga({payload}) {
    const {
        onSuccess = () => {},
        close,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/update`, reqData);
        const {errorMessage, errorCode} = result;

        if (close
            && ![
                ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL,
                ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR,
            ].includes(errorCode)) {
            close();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(BRIGADE_TOAST_MESSAGE.SUCCESS_UPDATE);

        onSuccess();

        yield put({
            type: BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS,
            payload: result,
        });

        const userRole = ls(USER_ROLE);

        if ([
            CLIENT_ADMIN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            FOREMAN,
        ].includes(userRole)) {
            yield put(getForemenNewRequestsAndPaymentsCount());
        }

        const {offeredSpecialityName} = reqData;

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }

    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR,
            payload: error,
        });
    }
}

// POST /bff/client-adm/client/foremen/requests/getNewRequestsAndPaymentsCount
// Получение количества заявок в статусе Новая (включая заявки на оплаты)
function* getClientForemenNewRequestsAndPaymentsCountSaga() {
    try {
        const result = yield request.bff.post(`${getController()}/getNewRequestsAndPaymentsCount`);

        yield put({
            type: BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST, getClientForemenPaymentsRichPageSaga),
        takeEvery(BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST, updateClientForemenRequestsSaga),
        takeEvery(BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST, getClientForemenNewRequestsAndPaymentsCountSaga),
    ]);
}