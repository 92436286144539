import {useDispatch} from "react-redux";

import {getLinkExportDocuments} from "../../../ducks/bff/export/documents/actionCreators";

function useDownloadDocument() {
    const dispatch = useDispatch();

    const downloadDocument = (item) => {
        const {
            exportId,
            clientId,
            adminExportName,
        } = item;

        dispatch(getLinkExportDocuments({
            exportId,
            clientId,
            adminExportName,
        }));
    };

    return {
        downloadDocument,
    };
}

export default useDownloadDocument;