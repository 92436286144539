import {CHROME_EXTENSION_MESSAGE_TYPE} from "../../constants/chromeExtension";

interface AuthorizeExtension {
    accessToken?: string,
    refreshToken?: string,
    role?: string,
    clientId?: string,
}

export const authorizeExtension = (data: AuthorizeExtension) => {
    const {
        accessToken,
        refreshToken,
        role,
        clientId,
    } = data;

    // const extensionId = "blbdceahjjajbkjgalmhokbflilghidg";
    //
    // // eslint-disable-next-line no-undef
    // chrome.runtime.sendMessage(
    //     extensionId,
    //     {
    //         type: "AUTH",
    //         accessToken,
    //         refreshToken,
    //     },
    // );

    const message = {
        type: CHROME_EXTENSION_MESSAGE_TYPE.AUTH,
        accessToken,
        refreshToken,
        clientId,
        role,
    };

    window.postMessage(message, document.location.origin);
};