import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import BrigadePayments from "./brigadePayments";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";

import {
    clearClientForemenPaymentsStore,
    getForemenPaymentsForemanContractorsRichList,
    getForemenPaymentsRichPage,
} from "../../../ducks/bff/clients/foremen/payments/actionCreators";
import {getClientUserRestrictionsList} from "../../../ducks/clientUserRestrictions";

import "./style.sass";

class BrigadePaymentsContainer extends Component {
    constructor(props) {
        super(props);

        const {match: {params: {clientId}}} = props;

        this.clientId = clientId;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            foremanContractorIdFilter: undefined,
        };
    }
    componentDidMount() {
        const {
            getClientUserRestrictionsList,
        } = this.props;

        getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.confirmPaymentForemans],
        });

        this.fetchForemanPayments();
        this.fetchForemanContractorList();
    }

    componentWillUnmount() {
        const {clearClientForemenPaymentsStore} = this.props;

        clearClientForemenPaymentsStore();
    };

    get translate() {
        const {t} = this.props;

        return {
            buttonConfirm: t("button.confirm"),
            buttonReject: t("button.reject"),
            buttonNo: t("button.no"),
            buttonYes: t("button.yes"),
            textResult: t("brigade-payments.textResult"),
            confirmAllBrigadeText: t("brigade-payments.confirmAllBrigadeText"),
            confirmedText: t("brigade-payments.confirmed"),
            rejectedText: t("brigade-payments.rejected"),
        };
    }

    fetchForemanContractorList = () => {
        const {getForemenPaymentsForemanContractorsRichList} = this.props;

        getForemenPaymentsForemanContractorsRichList({clientId: this.clientId});
    };

    fetchForemanPayments = () => {
        const {getForemenPaymentsRichPage}  = this.props;
        const {
            pageNum,
            pageSize,
            foremanContractorIdFilter,
        } = this.state;

        getForemenPaymentsRichPage({
            clientId: this.clientId,
            pageNum,
            pageSize,
            foremanContractorIdFilter,
        });
    };

    submitFilter = (foremanContractorIdFilter) => {
        this.setState({
            pageNum: 1,
            foremanContractorIdFilter,
        }, this.fetchForemanPayments);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchForemanPayments);
    };

    handleChangePageSize = pageSize => {
        this.setState({
            pageNum: 1,
            pageSize,
        }, this.fetchForemanPayments);
    };

    render() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <BrigadePayments
                pageData={{pageNum, pageSize}}
                translate={this.translate}
                handlePaginationChange={this.handlePaginationChange}
                handleChangePageSize={this.handleChangePageSize}
                submitFilter={this.submitFilter}
                clearFilter={this.clearFilter}
                clientId={this.clientId}
            />
        );
    }
}

export default connect(
    null,
    {
        getForemenPaymentsRichPage,
        getForemenPaymentsForemanContractorsRichList,
        getClientUserRestrictionsList,
        clearClientForemenPaymentsStore,
    },
)(withTranslation()(BrigadePaymentsContainer));