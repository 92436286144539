import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../constants/dicts";

export const getDocumentRouteTypesFilter = (baseRouteType) => {
    if (baseRouteType) {
        return [KEDO_DOCUMENTS_ROUTE_TYPE.BASE_ROUTE];
    }

    return [
        KEDO_DOCUMENTS_ROUTE_TYPE.USER_ROUTE,
        KEDO_DOCUMENTS_ROUTE_TYPE.FLEX_ROUTE,
    ];
};