import React from "react";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";

import {toastError} from "../../../../../utils/toastHelper";
import {getCardMode} from "../../utils/statusSegment";

import {FULL_REGISTRATION_STEP} from "../../../../../components/FullRegistration/constants";
import {COLOR} from "../../../../../constants/color";
import {
    CONTRACTOR_STATUS,
} from "../../../../../constants/contractor";
import {CONTRACTOR_SEGMENT_INFO_TYPE} from "../../../../../constants/contractorInfo";

const MainDocumentInfoCard = (props) => {
    const {
        contractor,
        className,
        isEditable,
        segmentInfoType,
        registrationStep,
        mediaControlsRenderCount,
        title,
        isHeaderDivider,
        labels,
        mediaControlsButtons,
        editButtonAssets,
        scans,
        isIndividualEntrepreneurStatusBlock = false,
    } = props;

    const {
        status,
        mode,
        updateDateTime,
        dataPresent,
    } = getCardMode({
        infoList: contractor.personalDataSegmentsInfo,
        segment: segmentInfoType,
        isIndividualEntrepreneurStatusBlock,
        contractor,
    });

    const onClickEdit = () => {
        if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(contractor.registrationStatus)) {
            toastError("Редактирование данных исполнителя недоступно при наличии заявки на внесение/изменение персональных данных");

            return;
        }

        props.onOpenFullRegistration({
            step: registrationStep,
        });
    };

    function getIsVisibleEmptyText() {
        if (isIndividualEntrepreneurStatusBlock) {
            return false;
        }

        if (registrationStep === FULL_REGISTRATION_STEP.CITIZENSHIP_AND_MIGRATION_STATUS) {
            return (!contractor.citizenship && !contractor.migrationStatus);
        }

        return !dataPresent;
    }

    return (
        <NmMiniInfoCard
            className={className}
            title={title}
            titleLevel="4"
            isHeaderDivider={isHeaderDivider}
            onClickEdit={!isIndividualEntrepreneurStatusBlock && onClickEdit}
            accessEdit={isEditable}
            mode={segmentInfoType !== CONTRACTOR_SEGMENT_INFO_TYPE.CITIZENSHIP_AND_MIGRATION_STATUS && mode}
            mediaControlsButtons={mediaControlsButtons}
            mediaControlsRenderCount={mediaControlsRenderCount}
            lastUpdate={updateDateTime}
            editButtonAssets={editButtonAssets}
        >
            {
                getIsVisibleEmptyText() ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        labels={labels}
                        scans={scans}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default MainDocumentInfoCard;