import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {uploadFileRecruitmentVacancyCandidatesComment} from "../../../../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";

import {useCounter} from "../../../../../hooks/useCounter";

import {toastError} from "../../../../../utils/toastHelper";

import {FILE_ERROR} from "../../../../../constants/messages";
import {maxSize25MB} from "../../../../../constants/validationRules";

const useUploadCommentFiles = (props) => {
    const {
        clientId,
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const [uploadFilesList, setUploadFilesList] = useState([]);

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: uploadFilesList});

    useEffect(() => {
        if (isCounterFinish) {
            fetchList();
            setUploadFilesList([]);
        }
    }, [isCounterFinish]);

    const uploadFiles = (params) => {
        const {
            files,
            commentId,
            createdBy,
        } = params;

        if (!files || !files.length) {
            return;
        }

        const _files = [...files];

        if (_files.length > 5) {
            toastError("Максимальное возможное количество файлов для загрузки - 5");
            _files.slice(0, 5);
        }

        setUploadFilesList(_files);

        _files.forEach(file => {
            if (file.size > maxSize25MB) {
                toastError(FILE_ERROR.SIZE_25MB);

                return;
            }

            const formData = new FormData();

            formData.append("file", file);
            formData.append("clientId", clientId);
            formData.append("createdBy", createdBy);
            formData.append("fileName", file.name);

            dispatch(uploadFileRecruitmentVacancyCandidatesComment({
                id: commentId,
                formData,
                onSuccess: () => {
                    increaseCounter();
                },
            }));
        });
    };

    return {
        uploadFiles,
    };
};

export default useUploadCommentFiles;