import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";


export const settingsDocumentsCorrectionSelector = state => state.bff.settingsDocumentsCorrection;
export const settingsDocumentsCorrectionPageDataSelector = createSelector(settingsDocumentsCorrectionSelector, ({pageData}) => pageData);
export const settingsDocumentsCorrectionTotalPagesSelector = createSelector(settingsDocumentsCorrectionSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const settingsDocumentsCorrectionTotalCountSelector = createSelector(settingsDocumentsCorrectionSelector, ({totalCount}) => totalCount);
export const settingsDocumentsCorrectionListSelector = createSelector(settingsDocumentsCorrectionSelector, ({list}) => list);
export const settingsDocumentsCorrectionProgressSelector = createSelector(settingsDocumentsCorrectionSelector, ({progress}) => progress);
export const settingsDocumentsCorrectionProgressActionSelector = createSelector(settingsDocumentsCorrectionSelector, ({progressAction}) => progressAction);
export const settingsDocumentsCorrectionDictFieldNameSelector = createSelector(settingsDocumentsCorrectionSelector, ({dictFieldName}) => dictFieldName);
export const settingsDocumentsCorrectionFieldNamesOptionsSelector = createSelector(settingsDocumentsCorrectionSelector, ({dictFieldName}) => {
    return dictFieldName.map((item) => {
        return {
            key: item.fieldName,
            value: item.fieldName,
            text: item.fieldNameRus,
        };
    });
});