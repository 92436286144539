import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";
import {
    batchDeleteRecruitmentVacancy,
    batchPublishRecruitmentVacancy,
    batchPublishValidateRecruitmentVacancy,
} from "../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import useRecruitmentVacancyCardAction from "../card/hooks/useAction";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {dadataValueUniqCitySettlementFilter} from "../../../../utils/dadata";
import {toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {LINK_CLIENT_RECRUITMENT_VACANCIES} from "../../../../constants/links";
import {RECRUITMENT_VACANCY_STATUS_OPTIONS} from "../constants";

import {history} from "../../../../store/configureStore";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

export const OPEN_MODAL_TYPE = {
    EXPORT: "EXPORT",
    IMPORT: "IMPORT",
};
const useRecruitmentVacanciesAction = (props) => {
    const {
        clientId,
        setArchived,
        archived,
        selectedList,
        fetchList,
        isAccessAction,
        clearSelectedRows,
        setPagination,
        pageSize,
    } = props;

    const {
        confirmData,
        onCloseConfirm,
        isOpenLog,
        onCloseLog,
        getMediaControls: getListMediaControls,
        onOpenEdit,
        isOpenEdit,
        editData,
        logData,
        onCloseEdit,
        vacancyCloseActions,
        modals,
    } = useRecruitmentVacancyCardAction({
        isVisibleArchiveButton: true,
        renderCount: {
            mobile: 0,
            tablet: 0,
            desktop: 0,
        },
        clientId,
        fetchCallback: fetchList,
    });

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [massConfirmData, setMassConfirmData] = useState({});
    const [openModalType, setOpenModalType] = useState(null);

    const archive = getArchiveButton(t, archived, {mobile: true});

    const getMediaControls = () => {
        return {
            renderCount: {
                desktop: 3,
                tablet: 1,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Добавить вакансию",
                        icon: <AddIcon />,
                        onClick: () => {
                            onOpenEdit({});
                        },
                    },
                    visible: !archived
                        && isAccessAction
                        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.addVacanciesAdmin),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Загрузить список",
                        onClick: () => setOpenModalType(OPEN_MODAL_TYPE.IMPORT),
                    },
                    visible: !archived
                        && isAccessAction
                        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.addVacanciesAdmin),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: () => {
                            history.push(LINK_CLIENT_RECRUITMENT_VACANCIES.replace(":clientId", clientId));

                            setArchived(!archived);
                            setPagination({
                                pageNum: 1,
                                pageSize,
                            });
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Выгрузить вакансии из джоб-бордов",
                        onClick: () => setOpenModalType(OPEN_MODAL_TYPE.EXPORT),
                    },
                    visible: !archived && isAccessAction,
                },
            ],
        };
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "title",
                        label: "Название",
                        placeholder: "Введите название",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "vacancyNumber",
                        label: "Номер",
                        placeholder: "Введите номер",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "createDateFrom",
                        endFieldName: "createDateTo",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "lastModifiedDateFrom",
                        endFieldName: "lastModifiedDateTo",
                        isClearable: true,
                        label: "Дата последнего изменения",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус вакансии",
                        placeholder: "Не выбран",
                        name: "statuses",
                        multiple: true,
                        isClearable: true,
                        search: true,
                        options: RECRUITMENT_VACANCY_STATUS_OPTIONS,
                    },
                ],
            },
            // {
            //     row: [
            //         {
            //             component: FILTER.DROPDOWN,
            //             size: "lg",
            //             label: 'Этап подбора',
            //             placeholder: 'Не выбран',
            //             name: "statuses",
            //             multiple: true,
            //             isClearable: true,
            //             search: true,
            //             options: [],
            //         }
            //     ],
            // },
            // {
            //     row: [
            //         {
            //             component: FILTER.DROPDOWN,
            //             size: "lg",
            //             label: 'Должность',
            //             placeholder: 'Не выбран',
            //             name: "statuses",
            //             multiple: true,
            //             isClearable: true,
            //             search: true,
            //             options: [],
            //         }
            //     ],
            // },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        multiple: true,
                        name: "workAddressFiasIds",
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        placeholder: "Не выбран",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "recruiterName",
                        label: "Рекрутер",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            // {
            //     row: [
            //         {
            //             component: FILTER.CUSTOMER,
            //             label: "Заказчик",
            //             placeholder: "Не выбран",
            //             name: "clientIdFilter",
            //             isClearable: true,
            //             isBff: true,
            //             search: true,
            //         }
            //     ],
            // },
        ];
    }, []);

    const onCloseModal = () => {
        setOpenModalType(null);
    };

    const onCloseMassConfirm = () => {
        setMassConfirmData({});
        clearSelectedRows();
    };

    const onConfirmMassDelete = () => {
        const vacancyIds = selectedList.filter(({isSelected}) => isSelected).map(({vacancyId}) => vacancyId);

        dispatch(batchDeleteRecruitmentVacancy({
            vacancyIds,
            onSuccess: () => {
                onCloseMassConfirm();
                toastSuccess("Задача на удаление черновиков вакансий передана в обработку");
            },
        }));
    };

    const onConfirmMassPublish = (checkedListSuccess) => {
        if (!checkedListSuccess.length) {
            onCloseMassConfirm();

            return;
        }

        const vacancyIds = checkedListSuccess.map(({vacancyId}) => vacancyId);

        dispatch(batchPublishRecruitmentVacancy({
            vacancyIds,
            onSuccess: () => {
                onCloseMassConfirm();
                toastSuccess("Вакансии переведены в статус \"В работе\"");
                setPagination({pageNum: 1, pageSize});
            },
        }));
    };

    const openMassDeleteConfirm = () => {
        setMassConfirmData({
            title: "Удаление вакансий",
            text: "Вы действительно хотите удалить ниже представленные вакансии (в статусе «Черновик»)?",
            onConfirm: onConfirmMassDelete,
            confirmButton: "Подтвердить",
            list: selectedList.filter(({isSelected}) => isSelected).map(({title}) => {
                return {
                    name: title,
                };
            }),
            isShowCounters: false,
            isShowIconItems: false,
        });
    };

    const checkPublishPossible = () => {
        const vacancyIds = selectedList.filter(({isSelected}) => isSelected).map(({vacancyId}) => vacancyId);

        dispatch(batchPublishValidateRecruitmentVacancy({
            vacancyIds,
            getResult: (result) => {
                const checkedListSuccess = result.filter(({success}) => success);
                const publicationCount = checkedListSuccess.length;
                const isNoPossible = publicationCount === 0;

                setMassConfirmData({
                    title: "Массовый перевод вакансий в работу",
                    successLabel: "В работу",
                    text: isNoPossible ?
                        "У всех выбранных объявлений заполнены не все обязательные поля для публикации" :
                        "Вы действительно хотите перевести в статус \"В работе\" нижепредставленные вакансии? Доступные вакансии отмечены галочками",
                    onConfirm: () => {
                        onConfirmMassPublish(checkedListSuccess);
                    },
                    confirmButton: isNoPossible ? "Oк" : "Подтвердить",
                    list: result.map(({vacancyId, success}) => {
                        const vacancy = selectedList.find((item) => item.vacancyId === vacancyId);

                        return {
                            name: vacancy.title,
                            publishPossible: success,
                        };
                    }),
                    warningLabel: "Невозможно перевести в статус \"В работе\"",
                    unablePublishTooltip: "Вакансии, которые были выбраны в списке, но не могут быть переведены в статус \"В работе\" по причине незаполненных или некорректно заполненных обязательных полей",
                    publicationCount,
                    impossibleCount: result.length - publicationCount,
                });
            },
        }));
    };

    return {
        mediaControls: getMediaControls(),
        filters,
        massConfirmData,
        openModalType,
        openMassDeleteConfirm,
        onCloseModal,
        checkPublishPossible,
        onCloseMassConfirm,
        isOpenEdit,
        editData,
        onCloseEdit,
        getListMediaControls,
        confirmData,
        onCloseConfirm,
        isOpenLog,
        onCloseLog,
        logData,
        vacancyCloseActions,
        modals,
    };
};

export default useRecruitmentVacanciesAction;
