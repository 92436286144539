export const UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST = "UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST";
export const UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_SUCCESS = "UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_SUCCESS";
export const UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_ERROR = "UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_ERROR";

export const ARCHIVE_CONTRACTOR_CARD_REQUEST = "ARCHIVE_CONTRACTOR_CARD_REQUEST";
export const ARCHIVE_CONTRACTOR_CARD_SUCCESS = "ARCHIVE_CONTRACTOR_CARD_SUCCESS";
export const ARCHIVE_CONTRACTOR_CARD_ERROR = "ARCHIVE_CONTRACTOR_CARD_ERROR";

export const ADD_NOTE_CONTRACTOR_CARD_REQUEST = "ADD_NOTE_CONTRACTOR_CARD_REQUEST";
export const ADD_NOTE_CONTRACTOR_CARD_SUCCESS = "ADD_NOTE_CONTRACTOR_CARD_SUCCESS";
export const ADD_NOTE_CONTRACTOR_CARD_ERROR = "ADD_NOTE_CONTRACTOR_CARD_ERROR";

export const UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST = "UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST";
export const UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_SUCCESS = "UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_SUCCESS";
export const UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_ERROR = "UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_ERROR";

export const UPDATE_NOTE_CONTRACTOR_CARD_REQUEST = "UPDATE_NOTE_CONTRACTOR_CARD_REQUEST";
export const UPDATE_NOTE_CONTRACTOR_CARD_SUCCESS = "UPDATE_NOTE_CONTRACTOR_CARD_SUCCESS";
export const UPDATE_NOTE_CONTRACTOR_CARD_ERROR = "UPDATE_NOTE_CONTRACTOR_CARD_ERROR";

export const GET_RATING_CONTRACTOR_CARD_REQUEST = "GET_RATING_CONTRACTOR_CARD_REQUEST";
export const GET_RATING_CONTRACTOR_CARD_SUCCESS = "GET_RATING_CONTRACTOR_CARD_SUCCESS";
export const GET_RATING_CONTRACTOR_CARD_ERROR = "GET_RATING_CONTRACTOR_CARD_ERROR";

export const GET_NOTE_CONTRACTOR_CARD_REQUEST = "GET_NOTE_CONTRACTOR_CARD_REQUEST";
export const GET_NOTE_CONTRACTOR_CARD_SUCCESS = "GET_NOTE_CONTRACTOR_CARD_SUCCESS";
export const GET_NOTE_CONTRACTOR_CARD_ERROR = "GET_NOTE_CONTRACTOR_CARD_ERROR";

export const GET_INFO_CONTRACTOR_CARD_REQUEST = "GET_INFO_CONTRACTOR_CARD_REQUEST";
export const GET_INFO_CONTRACTOR_CARD_SUCCESS = "GET_INFO_CONTRACTOR_CARD_SUCCESS";
export const GET_INFO_CONTRACTOR_CARD_ERROR = "GET_INFO_CONTRACTOR_CARD_ERROR";

export const GET_PROMOCODE_CONTRACTOR_CARD_REQUEST = "GET_PROMOCODE_CONTRACTOR_CARD_REQUEST";
export const GET_PROMOCODE_CONTRACTOR_CARD_SUCCESS = "GET_PROMOCODE_CONTRACTOR_CARD_SUCCESS";
export const GET_PROMOCODE_CONTRACTOR_CARD_ERROR = "GET_PROMOCODE_CONTRACTOR_CARD_ERROR";

export const CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST = "CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST";
export const CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS = "CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS";
export const CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR = "CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR";

export const REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST = "REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST";
export const REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS = "REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS";
export const REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR = "REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR";

export const UPDATE_CONTRACTOR_BLOCKED_REQUEST = "UPDATE_CONTRACTOR_BLOCKED_REQUEST";
export const UPDATE_CONTRACTOR_BLOCKED_SUCCESS = "UPDATE_CONTRACTOR_BLOCKED_SUCCESS";
export const UPDATE_CONTRACTOR_BLOCKED_ERROR = "UPDATE_CONTRACTOR_BLOCKED_ERROR";

export const CONTRACTOR_CARD_UPDATE_STORE = "CONTRACTOR_CARD_UPDATE_STORE";