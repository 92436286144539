import {useDispatch} from "react-redux";

import {openLinkByUrl} from "../../../../utils/downloadBlob";

import {getContractorProfileResumeFile} from "../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {downloadDocument} from "../../../../ducks/documents";

export const useRecruitmentVacancyDownloadResume = () => {
    const dispatch = useDispatch();

    const openResume = (params) => {
        const {
            isNaimixResume,
            contractorId,
            responseId,
            fileName,
            isDownload = false,
        } = params;

        if (isNaimixResume) {
            dispatch(getContractorProfileResumeFile({
                contractorId,
                isDownload,
                onSuccess: (blob) => {
                    const url = URL.createObjectURL(blob);

                    openLinkByUrl(url);
                },
            }));

            return;
        }

        dispatch(downloadDocument({
            isDownload,
            fileName,
            downloadLink: `/api/filestore/recruitment/response/files/jobBoard/resume/get?responseId=${responseId}&fileName=${fileName}`,
        }));
    };

    return {
        openResume,
    };
};