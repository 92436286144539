import {
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    progressCard: false,
    progress: false,
    totalCount: 0,
    list: [],
    filterList: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS:
            const {
                results,
                totalCount,
                nameField = "list",
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                [nameField]: results,
            };
        case GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST:
        case UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST:
        case DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS:
        case UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS:
        case DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS:
        case ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR:
        case UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR:
        case DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES:
            return initial;
        default:
            return state;
    }
};