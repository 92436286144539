import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesBanksSelector = state => state.bff.settingsDirectoriesNaimixBanks;
export const settingsDirectoriesBanksListSelector = createSelector(settingsDirectoriesBanksSelector, ({list}) => {
    const paymentInfo = {
        CARD_LOCAL: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
        SBP: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
        BANK_REQUISITES: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
    };

    return list.map(item => {
        return {
            ...item,
            paymentsPathsInfo: item.paymentPaths.reduce((result, value) => {
                return {
                    ...result,
                    [value.paymentType]: {
                        ...result[value.paymentType],
                        [value.paymentMethod]: {
                            ...result[value.paymentType][value.paymentMethod],
                            [value.paymentPathType]: true,
                        },
                    },
                };
            }, {
                SMZ: paymentInfo,
                CIVIL: paymentInfo,
                INDIVIDUAL: paymentInfo,
            }),
        };
    });
});
export const settingsDirectoriesSbpBanksListSelector = createSelector(settingsDirectoriesBanksSelector, ({sbpList}) => sbpList);
export const settingsDirectoriesBanksTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({totalCount}) => totalCount);
export const settingsDirectoriesSbpBanksTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({sbpTotalCount}) => sbpTotalCount);
export const settingsDirectoriesBanksProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({progress}) => progress);
export const settingsDirectoriesBanksProgressActionSelector = createSelector(settingsDirectoriesBanksSelector, ({progressAction}) => progressAction);
export const contractorWalletWithdrawAvailableSelector = createSelector(settingsDirectoriesBanksSelector, ({contractorWalletWithdrawAvailable}) => contractorWalletWithdrawAvailable);
export const contractorCommissionWithdrawalSelector = createSelector(settingsDirectoriesBanksSelector, ({contractorCommissionWithdrawal}) => contractorCommissionWithdrawal);
export const settingsDirectoriesBanksTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesSbpBanksTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    sbpTotalCount,
    sbpPageData: {pageSize = 0},
}) => getTotalPages(sbpTotalCount, pageSize));
export const settingsDirectoriesBanksLogListSelector = createSelector(settingsDirectoriesBanksSelector, ({logList}) => logList);
export const settingsDirectoriesBanksLogTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({logTotalCount}) => logTotalCount);
export const settingsDirectoriesBanksLogProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({logProgress}) => logProgress);
export const settingsDirectoriesBanksLogTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    logTotalCount,
    logPageData: {pageSize = 0},
}) => getTotalPages(logTotalCount, pageSize));
export const settingsDirectoriesBanksBalancesAccessPageSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {accessPage}}) => accessPage);
export const settingsDirectoriesBanksBalancesListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {list}}) => list);
export const settingsDirectoriesBanksBalancesTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {totalCount}}) => totalCount);
export const settingsDirectoriesBanksBalancesProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {progress}}) => progress);
export const settingsDirectoriesBanksBalancesProgressActionSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {progressAction}}) => progressAction);
export const settingsDirectoriesBanksBalancesTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    balancesPage: {
        totalCount,
        pageData: {pageSize = 0},
    },
}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesBanksBalancesLogListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logList}}) => logList);
export const settingsDirectoriesBanksBalancesLogTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logTotalCount}}) => logTotalCount);
export const settingsDirectoriesBanksBalancesLogProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logProgress}}) => logProgress);
export const settingsDirectoriesBanksBalancesLogTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    balancesPage: {
        logTotalCount,
        logPageData: {pageSize = 0},
    },
}) => getTotalPages(logTotalCount, pageSize));
export const settingsDirectoriesBanksBalancesBankListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {bankList}}) => bankList);
export const settingsDirectoriesBanksBalancesBanksOptionsSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {bankList}}) => {
    return bankList.map(({bankBalanceId, bankName}) => {
        return {
            key: bankBalanceId,
            text: bankName,
            value: bankBalanceId,
        };
    },
    );
});