import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateForeignLanguages, {defaultForeignLanguagesForm} from "./hooks/useUpdateForeignLanguages";

import {
    bffCommonDictsContractorLanguageLevelsDictOptionsSelector,
} from "../../../../../../ducks/bff/common/dicts/selectors";
import {deleteContractorForeignLanguage} from "../../../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {
    contractorLanguagesDictOptionsSelector,
} from "../../../../../../ducks/bff/contractor-сard/profile/selectors";

export default function ContractorForeignLanguagesEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultForeignLanguagesForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const contractorLanguagesDictOptions = useSelector(contractorLanguagesDictOptionsSelector);
    const contractorLanguageLevelsDictOptions = useSelector(bffCommonDictsContractorLanguageLevelsDictOptionsSelector);

    const {
        onChangeForm,
        submit,
        form,
        setForm,
        error,
    } = useUpdateForeignLanguages({contractorId, onClose, getProfile});

    useEffect(() => {
        if (!isEmpty(editData)) {
            setForm({...editData});
        }
    }, [editData]);

    const {
        language,
        languageLevel,
        foreignLanguagePresent,
    } = form;

    const deleteElem = () => {
        dispatch(deleteContractorForeignLanguage({
            contractorId,
            language: editData.language,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                    additionalBtnContent={editData.language ? "Удалить" : null}
                    additionalBtnColor={editData.language ? "red" : null}
                    onClickAdditional={editData.language ? deleteElem : null}
                />
            }
            header={
                <NmTitle size="lg">
                    Иностранный язык
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    checked={foreignLanguagePresent}
                    name="foreignLanguagePresent"
                    onChange={onChangeForm}
                    label="Не владею иностранным языком"
                />
                {!foreignLanguagePresent && <>
                    <div className="nm-line" />
                    <NmDropdownV2
                        required
                        size="xl"
                        label="Язык"
                        name="language"
                        onChange={onChangeForm}
                        value={language}
                        error={error.language}
                        options={contractorLanguagesDictOptions}
                    />
                    <NmDropdownV2
                        required
                        size="xl"
                        label="Уровень"
                        error={error.languageLevel}
                        name="languageLevel"
                        onChange={onChangeForm}
                        value={languageLevel}
                        options={contractorLanguageLevelsDictOptions}
                    />
                </>}
            </NmForm>
        </NmModal>
    );
}