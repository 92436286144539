import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getRequestDataFromMapFilterData} from "../utils/dto";

import {getDriverLicenseDictionary} from "../../../ducks/bff/contractor-сard/profile/actionCreators";
import {getMapClusters, updateStoreMap} from "../../../ducks/bff/map/actionCreators";
import {mapMarkersCancelToken} from "../../../ducks/bff/map/sagas";
import {bffMapPageDataSelector} from "../../../ducks/bff/map/selectors";
import {getContractorStatusDict} from "../../../ducks/contractor";

export const useMapFetch = ({setFilterData, showMap}) => {
    const mapPageData = useSelector(bffMapPageDataSelector);

    const dispatch = useDispatch();

    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        fetchContractorStatusDict();

        dispatch(getDriverLicenseDictionary());

        return () => {
            mapMarkersCancelToken.cancel();
            dispatch(updateStoreMap({
                mapPageData: {},
                clusters: [],
            }));
        };
    }, []);

    const onSubmitFilter = (filterData) => {
        setIsSearch(true);

        setFilterData(filterData);

        fetchMarkers({
            ...filterData,
            distanceFilter: {
                ...mapPageData.distanceFilter,
                ...filterData.distanceFilter,
            },
        });
    };

    const fetchMarkers = (filterData) => {
        if (!showMap) {
            return;
        }

        const requestData= getRequestDataFromMapFilterData(filterData);

        dispatch(getMapClusters({
            ...requestData,
            onRequest: () => {
                mapMarkersCancelToken.cancel();
            },
        }));
    };

    const fetchContractorStatusDict = () => {
        dispatch(getContractorStatusDict());
    };

    return {
        onSubmitFilter,
        fetchMarkers,
        isSearch,
    };
};