import {useMemo} from "react";
import {useSelector} from "react-redux";

import {bffCommonDictsKedoDocumentCodesOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoDocumentCodes = () => {
    const allDocumentCodesOptions = useSelector(bffCommonDictsKedoDocumentCodesOptionsSelector);

    return useMemo(() => {
        return {
            options: allDocumentCodesOptions,
            search: true,
            label: "Код документа",
            placeholder: "Выберите код",
            name: "documentCode",
        };
    }, [allDocumentCodesOptions]);
};