import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import copy from "copy-to-clipboard";

import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import NmButton from "../../../components/NmButton";
import NmPageCard from "../../../components/NmPageCard";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import ProjectEditModal from "../projects-edit-modal";
import ProjectsRoute from "../projects-route";
import {ProjectsTabs} from "../projects-tabs";

import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {formatNumberAmount} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";

import {COLOR} from "../../../constants/color";
import {HIDE_DEPOSIT_DETAILS_PAGE} from "../../../constants/deposit";
import {LINK_CLIENT_PROJECTS_LIST} from "../../../constants/links";
import {
    PROJECT_ACTION_DICT,
    PROJECT_STATUS_DICT,
} from "../../../constants/projects";
import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR, NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {bffCrowdTasksTotalCountSelector} from "../../../ducks/bff/crowd/tasks/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {
    getOptimizedObjects,
    objectsTotalCountSelector,
} from "../../../ducks/objects";
import {
    orderTotalCountSelector,
    toggleOrdersArchived,
} from "../../../ducks/order";
import {
    changeProjectArchived,
    getProjectById,
    getProjectSelector,
    projectProgressCardSelector,
    updateFieldsStoreProject,
} from "../../../ducks/projects";

import "./style.sass";

class ProjectsCard extends Component {
    constructor(props) {
        super(props);
        const {
            match: {
                params: {
                    clientId,
                    projectId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.role = ls(USER_ROLE);
        this.projectId = projectId;

        this.initialRequestData = {
            pageNum: 1,
            pageSize: 25,
            clientId: this.clientId,
            projectId: this.projectId,
            archivedFilter: false,
        };

        this.state = {
            historyData: {},
            isShowDescription: false,
            hideDetails: false,
        };
    }

    componentDidMount() {
        const {
            getOptimizedObjects,
            getHideDepositDetails,
        } = this.props;

        this.getProject();
        this.saveHistoryData();

        getOptimizedObjects(this.initialRequestData);

        getHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.PROJECT,
            getResult: (hideDetails) => {
                this.setState({
                    hideDetails,
                });
            },
        });
    }

    componentWillUnmount() {
        const {updateFieldsStoreProject} = this.props;

        updateFieldsStoreProject({
            project: {},
        });
    }

    get localizationData() {
        const {
            t, project: {
                name,
            },
        } = this.props;

        return {
            editBtn: t("button.update"),
            archiveBtn: t("button.archive"),
            confirmText: t(PROJECT_ACTION_DICT.ARCHIVE_PROJECT.CONFIRM_KEY, {name}),
        };
    }

    getProject = () => {
        const {getProjectById} = this.props;

        getProjectById({
            clientId: this.clientId,
            projectId: this.projectId,
        });
    };

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, filterData} = state;

            this.setState({
                historyData: {prevPath, pageData, filterData},
            });
        }
    };

    handleOnClickBack = () => {
        const {historyData} = this.state;
        const {prevPath} = historyData;

        if (prevPath) {
            const {filterData, pageData} = historyData;

            history.push(prevPath, {pageData, filterData});

            return;
        }

        const linkToList = LINK_CLIENT_PROJECTS_LIST.replace(":clientId", this.clientId);

        history.push(linkToList);
    };

    toggleDesc = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowDescription: !prevState.isShowDescription,
        }));
    };

    get getContentInfo() {
        const {
            project: {
                amount,
                balanceAmount,
                balanceCommissionAmount,
                orderReserveAmount,
                orderReserveCommissionAmount,
                paidAmount,
                paidCommissionAmount,
                unlimited,
            },
        } = this.props;

        return [
            {
                label: "Бюджет",
                value: unlimited && amount === 0 ? null : amount,
                infinity: unlimited,
                subValue: "С учетом комиссии, ₽",
            },
            {
                label: "Фактические выплаты",
                value: paidAmount,
                subValue: `+${formatNumberAmount(paidCommissionAmount)}`,
            },
            {
                label: "Резерв по заказам",
                value: orderReserveAmount,
                subValue: `+${formatNumberAmount(orderReserveCommissionAmount)}`,
            },
            {
                label: "Остаток",
                value: unlimited && balanceAmount === 0 ? null : balanceAmount,
                infinity: unlimited,
                subValue: `+${formatNumberAmount(balanceCommissionAmount)}`,
            },
        ];
    }

    openEditModal = () => {
        this.setState({
            isOpenEditModal: true,
        });
    };

    closeEditModal = () => {
        this.setState({
            isOpenEditModal: false,
        });
    };

    updateHideDepositDetails = () => {
        const {setHideDepositDetails} = this.props;
        const {hideDetails} = this.state;

        setHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.PROJECT,
            value: hideDetails,
        });
    };

    handleChangeHideDetails = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                hideDetails: !prevState.hideDetails,
            };
        }, this.updateHideDepositDetails);
    };

    renderEditButton() {
        const {editBtn} = this.localizationData;

        return (
            <NmButton
                size="xl"
                icon={
                    <ModeIcon />
                }
                onClick={this.openEditModal}
            >
                {editBtn}
            </NmButton>
        );
    }

    handleRefreshCounters = () => {
        this.props.toggleOrdersArchived(false);
    };

    render() {
        const {
            isOpenEditModal,
            hideDetails,
        } = this.state;

        const {
            t,
            project,
            progressCard,
            pathname,
            client: {
                archived: isClientArchived,
            },
            clientProperties: {
                civilRegistryPaymentsAvailable,
                crowdTasksAvailable,
            },
            hideDepositDetailsProgress,
            orderTotalCount,
            objectTotalCount,
            taskTotalCount,
        } = this.props;

        const {
            status,
            name,
            projectUserCount,
            orderCount,
            objectCount,
            description,
            optimumProjectId,
            crowdTasksCount,
        } = project;

        return (
            <NmPageCard
                header={
                    <NmPageCardHeader
                        size="xl"
                        to={LINK_CLIENT_PROJECTS_LIST.replace(":clientId", this.clientId)}
                        content={name && `Проект "${name}"`}
                        status={status}
                        statusDict={PROJECT_STATUS_DICT}
                        controls={
                            <>
                                <NmHorizontalToggleV2
                                    className="me-5"
                                    disabled={hideDepositDetailsProgress}
                                    noWrapLabel
                                    leftLabel="Скрыть детали"
                                    single
                                    onChange={this.handleChangeHideDetails}
                                    checked={hideDetails}
                                    duplex={false}
                                />
                                {
                                    !isClientArchived && ![FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role) &&
                                    this.renderEditButton()
                                }
                            </>
                        }
                    />
                }
                description={
                    <>
                        {
                            description &&
                            <NmShowMoreText
                                lines={1}
                                anchor="green"
                                more="Подробнее"
                                less="Скрыть"
                                className="col-16 col-xxl-9 projects-card__description"
                                expanded={false}
                            >
                                {description}
                            </NmShowMoreText>
                        }
                        {
                            crowdTasksAvailable &&
                            <NmLabelText
                                className="mt-2"
                                type="page"
                                label="Идентификатор проекта в системе Оптимум"
                                text={optimumProjectId || "-"}
                                onClickText={() => {
                                    if (!optimumProjectId) {
                                        return;
                                    }

                                    copy(optimumProjectId);
                                    toastSuccess("Идентификатор скопирован");
                                }}
                                color={optimumProjectId && "blue"}
                            />
                        }
                    </>
                }
                info={
                    ![FOREMAN].includes(this.role) &&
                    <>
                        <div className="registry-list__details-item">
                            <Text
                                className="mb-2"
                                medium
                                level="2"
                                color={COLOR.SECONDARY_70}
                                noWrap
                            >
                                Детализация по счету для выплат исполнителям с типом налогообложения НПД
                            </Text>
                            <DepositValuesAmountInfo
                                hideDetails={hideDetails}
                                clientId={this.clientId}
                                projectId={this.projectId}
                            />
                        </div>
                        {
                            civilRegistryPaymentsAvailable &&
                            <div className="registry-list__details-item">
                                <Text
                                    className="mb-2"
                                    medium
                                    level="2"
                                    color={COLOR.SECONDARY_70}
                                    noWrap
                                >
                                    Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                                </Text>
                                <DepositValuesAmountInfo
                                    hideDetails={hideDetails}
                                    clientId={this.clientId}
                                    projectId={this.projectId}
                                    isCivil
                                />
                            </div>
                        }
                    </>
                }
                isLoaded={progressCard}
            >
                {
                    isOpenEditModal &&
                    <ProjectEditModal
                        pathname={pathname}
                        isGetCard
                        isEdit
                        projectData={{
                            projectId: this.projectId,
                            clientId: this.clientId,
                        }}
                        onClose={this.closeEditModal}
                    />
                }
                <Tabs
                    className="base-tabset projects-card__tabs"
                    panes={ProjectsTabs({
                        clientId: this.clientId,
                        projectId: this.projectId,
                        pathname,
                        orderCount: pathname.includes("orders") ? orderTotalCount : orderCount,
                        projectUserCount,
                        objectCount: pathname.includes("objects") ? objectTotalCount : objectCount,
                        taskCount: pathname.includes("tasks") ? taskTotalCount : crowdTasksCount,
                    })}
                    handleRefreshCounters={this.handleRefreshCounters}
                />
                <ProjectsRoute
                    fetchCard={this.getProject}
                    hideDetails={hideDetails}
                />
            </NmPageCard>
        );
    }
}

export default connect(
    state => ({
        project: getProjectSelector(state),
        progressCard: projectProgressCardSelector(state),
        pathname: state.router.location.pathname,
        client: clientCardInfoSelector(state),
        hideDepositDetailsProgress: hideDepositDetailsProgressSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        orderTotalCount: orderTotalCountSelector(state),
        objectTotalCount: objectsTotalCountSelector(state),
        taskTotalCount: bffCrowdTasksTotalCountSelector(state),
    }),
    {
        getProjectById,
        changeProjectArchived,
        toggleOrdersArchived,
        getOptimizedObjects,
        updateFieldsStoreProject,
        getHideDepositDetails,
        setHideDepositDetails,
    },
)((withTranslation()(ProjectsCard)));
