import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CONTRACTOR_RESOURCES_EXPORT_ERROR,
    CONTRACTOR_RESOURCES_EXPORT_REQUEST,
    CONTRACTOR_RESOURCES_EXPORT_SUCCESS,
    CONTRACTOR_RESOURCES_GET_CLIENTS_ERROR,
    CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST, CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS,
    CONTRACTOR_RESOURCES_GET_OBJECTS_ERROR,
    CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST,
    CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS,
    CONTRACTOR_RESOURCES_GET_PAGE_ERROR,
    CONTRACTOR_RESOURCES_GET_PAGE_REQUEST,
    CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS,
    CONTRACTOR_RESOURCES_GET_PROJECTS_ERROR,
    CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST,
    CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS,
    CONTRACTOR_RESOURCES_START_JOB_ERROR,
    CONTRACTOR_RESOURCES_START_JOB_REQUEST,
    CONTRACTOR_RESOURCES_START_JOB_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {LINK_CONTRACTORS_RESOURCES} from "../../../../constants/links";

const adminPageController = "/adm/contractors/contractors-resources";

const getController = () => {
    return (
        getBffUrl({
            [LINK_CONTRACTORS_RESOURCES]: adminPageController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/contractors/resources",
            adminRolesUrl: "/adm/clients/client-card/contractors/resources",
        }, adminPageController)
    );
};

const getPageContractorsResources = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONTRACTOR_RESOURCES_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const exportContractorsResources = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/addRetailExport`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RESOURCES_EXPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: CONTRACTOR_RESOURCES_EXPORT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_EXPORT_ERROR,
        });
    }
};

const startJobContractorsResources = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/startJob`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RESOURCES_START_JOB_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Анализ запущен");

        yield put({
            type: CONTRACTOR_RESOURCES_START_JOB_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_START_JOB_ERROR,
            payload: error,
        });
    }
};

const getClientsContractorsResources = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/retailClientIdNameMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RESOURCES_GET_CLIENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_GET_CLIENTS_ERROR,
            payload: error,
        });
    }
};

const getProjectsContractorsResources = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getProjects`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RESOURCES_GET_PROJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_GET_PROJECTS_ERROR,
            payload: error,
        });
    }
};

const getObjectsContractorsResources = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getObjects`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RESOURCES_GET_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RESOURCES_GET_OBJECTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_RESOURCES_GET_PAGE_REQUEST, getPageContractorsResources),
        takeEvery(CONTRACTOR_RESOURCES_EXPORT_REQUEST, exportContractorsResources),
        takeEvery(CONTRACTOR_RESOURCES_START_JOB_REQUEST, startJobContractorsResources),
        takeEvery(CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST, getClientsContractorsResources),
        takeEvery(CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST, getProjectsContractorsResources),
        takeEvery(CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST, getObjectsContractorsResources),
    ]);
}