import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsClientGroupsCardClientUsersSelector = state => state.bff.settingsClientGroupsCardClientUsers;
export const settingsClientGroupsCardClientsPageDataSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({pageData}) => pageData);
export const settingsClientGroupsCardClientsTotalPagesSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const settingsClientGroupsCardClientsTotalCountSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({totalCount}) => totalCount);
export const settingsClientGroupsCardClientsListSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({list}) => list);
export const settingsClientGroupsCardClientsProgressSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({progress}) => progress);
export const settingsClientGroupsCardClientsProgressActionSelector = createSelector(settingsClientGroupsCardClientUsersSelector, ({progressAction}) => progressAction);