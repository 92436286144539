import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RECRUITMENT_FUNNEL_STATUS_FILTER} from "../../../../../constants/recruitment";
import InviteContractorToOrderForm
    from "../../../../../containers/contractor/nm-contractor-list/invite-contractor-to-order-form";
import {
    bffRecruitmentAccessControlCanSetCandidateStatusSelector,
} from "../../../../../ducks/bff/recruitment/access-control/selectors";
import {
    bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    deleteRecruitmentVacancyCandidate,
    setRecruitmentVacancyCandidateStatus,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    setRecruitmentVacancyResponseHeadHunterStatus,
    setRecruitmentVacancyResponseStatus,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {MediaButtons} from "../../../../ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../ActualComponents/NmConfirmV2";
import {HH_POLITENESS_INDEX} from "../../../HhPolitenessIndex";
import RecruitmentLinkingResponseToCandidate from "../../../LinkingResponseToCandidate";
import {
    RecruitmentVacancyRejectCandidate,
} from "../RejectCandidate";

import {
    useRecruitmentFunnelFilters,
} from "../../../../../containers/recruitment/vacancies/card/description/hooks/useFunnelFilters";
import {useModal} from "../../../../../hooks/useModal";

import {
    isVisibleRecruitmentInviteOrder,
} from "../../../../../containers/recruitment/vacancies/card/сandidate-list/utils/isVisibleInviteOrder";
import {
    getUserRole,
    isVisibleRecruitmentActions,
} from "../../../../../utils/access";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO,
} from "../../../../../constants/links";
import {RECRUITMENT_OBSERVER} from "../../../../../constants/roles";
import {
    RECRUITMENT_SOURCE_JOB_BOARD,
} from "../../../../../containers/recruitment/vacancies/constants";
import {COMPONENT} from "../../../../ActualComponents/MediaControls/constants";

import {history} from "../../../../../store/configureStore";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";

import "./style.sass";

export const MODAL_TYPE = {
    CONFIRM: "CONFIRM",
    LINK_CANDIDATE: "LINK_CANDIDATE",
    INVITE_ORDER: "INVITE_ORDER",
    REJECT_CANDIDATE: "REJECT_CANDIDATE",
};

export const FUNNELS_BUTTON_CLASSNAMES = {
    MAIN: "recruitment-candidate-card-header__invite-button",
    TEXT_WITH_ICON_CONTAINER: "recruitment-candidate-card-header__text-with-icon",
};

export const RecruitmentCandidatesCardHeaderContextMenu = (props) => {
    const {
        candidateId,
        clientId,
        vacancyId,
        responseId,
        fetchList,
        card,
        activeFunnelStatusFilter,
        isVacancyCandidate,
        reject,
        fetchCard,
        buttonFunnelMoveName,
        isNewResponses,
        cardLink,
        progressAction,
    } = props;

    const funnelStatusOptions = useSelector(bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector);
    const {recruitmentHHPolitenessIndex} = useSelector(clientCardPropertiesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const canSetCandidateStatus = useSelector(bffRecruitmentAccessControlCanSetCandidateStatusSelector);

    const dispatch = useDispatch();

    const role = getUserRole();

    const isAccessAction = isVisibleRecruitmentActions();

    const isFunnels = activeFunnelStatusFilter
        && activeFunnelStatusFilter !== RECRUITMENT_FUNNEL_STATUS_FILTER.ALL_CANDIDATES;

    const {
        isOpen: isOpenConfirm,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        onOpenModal,
        onCloseModal,
        isOpen: isOpenModal,
        modalData,
    } = useModal();

    const {
        fetchFunnelFilters,
    } = useRecruitmentFunnelFilters({
        vacancyId,
        clientId,
    });

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                disabled={progressAction}
                content="Вы действительно хотите удалить кандидата? Внимание! В случае удаления кандидата все привязанные к нему отклики потеряют с ним связь"
                onCancel={onCloseConfirm}
                onConfirm={() => {
                    dispatch(deleteRecruitmentVacancyCandidate({
                        id: candidateId,
                        onSuccess: () => {
                            toastSuccess("Кандидат успешно удален");

                            fetchList();
                            fetchCard();

                            onCloseConfirm();
                        },
                    }));
                }}
                isNeedClosing={false}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const renderModalWindow = () => {
        if (!isOpenModal) {
            return null;
        }

        const {
            type,
            ...otherModalData
        } = modalData;

        if (type === MODAL_TYPE.REJECT_CANDIDATE) {
            return (
                <RecruitmentVacancyRejectCandidate
                    onClose={onCloseModal}
                    progressAction={progressAction}
                    clientId={clientId}
                    reject={(values) => {
                        const {
                            rejectionReasonId,
                            rejectionComment,
                        } = values;

                        reject({
                            responseId,
                            candidateId,
                            vacancyId,
                            clientId,
                            rejectionComment,
                            rejectionReasonId,
                            onSuccess: () => {
                                onCloseModal();
                                successCallback({
                                    toastText: "Исполнителю отправлен отказ",
                                });
                            },
                        });
                    }}
                />
            );
        }

        if (type === MODAL_TYPE.INVITE_ORDER) {
            return (
                <InviteContractorToOrderForm
                    {...otherModalData}
                    close={onCloseModal}
                />
            );
        }

        if (type === MODAL_TYPE.LINK_CANDIDATE) {
            return (
                <RecruitmentLinkingResponseToCandidate
                    title="Привязка отклика к кандидату"
                    onClose={onCloseModal}
                    responseId={responseId}
                    vacancyId={vacancyId}
                    clientId={clientId}
                    currentCandidateId={candidateId}
                    card={card}
                    onSuccess={successCallback}
                />
            );
        }

        if (type === MODAL_TYPE.CONFIRM) {
            const {
                content,
                onConfirm,
            } = modalData;

            return (
                <NmConfirmV2
                    content={content}
                    onCancel={onCloseModal}
                    onConfirm={onConfirm}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    loading={progressAction}
                />
            );
        }
    };

    const updateOpenedCard = ({results}) => {
        const id = responseId ? results[0]?.responseId : results[0]?.candidateId;

        if (!id) {
            return;
        }

        if (cardLink) {
            const link = cardLink
                .replace(":clientId", clientId)
                .replace(":vacancyId", vacancyId)
                .replace(":id", id);

            history.replace(link);

            return;
        }


        const link = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO
            .replace(":clientId", clientId)
            .replace(":vacancyId", vacancyId)
            .replace(":id", id)
            .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter);

        history.replace(link);
    };

    const successCallback = ({toastText} = {}) => {
        fetchFunnelFilters();

        if (toastText) {
            toastSuccess(toastText);
        }

        // Везде, кроме воронок и новых откликов обновляем карточку (вкладки "Все отклики", "Все кандидаты")
        if (
            !isFunnels &&
            !isNewResponses
        ) {
            fetchCard();

            return;
        }

        fetchList({
            onSuccess: updateOpenedCard,
        });
    };

    const _funnelStatusOptions = useMemo(() => {
        return funnelStatusOptions.map(item => {
            const statusId = item.value;

            return {
                ...item,
                onClick: () => {
                    // Для откликов
                    if (responseId) {
                        dispatch(setRecruitmentVacancyResponseStatus({
                            responseId,
                            statusId,
                            clientId,
                            vacancyId,
                            onSuccess: () => {
                                successCallback({
                                    toastText: "Статус успешно изменен",
                                });
                            },
                        }));

                        return;
                    }

                    dispatch(setRecruitmentVacancyCandidateStatus({
                        candidateId,
                        clientId,
                        statusId,
                        vacancyId,
                        onSuccess: () => {
                            successCallback({
                                toastText: "Статус успешно изменен",
                            });
                        },
                    }));
                },
                disabled: statusId === activeFunnelStatusFilter
                    || statusId === card.statusId,
            };
        });
    }, [
        funnelStatusOptions,
        responseId,
        candidateId,
        vacancyId,
        clientId,
        activeFunnelStatusFilter,
        card,
    ]);

    const getButtons = () => {
        const isVacancyCard = Boolean(
            (candidateId && isVacancyCandidate) ||
            responseId,
        );

        const isObserverCanSetCandidateStatus = [RECRUITMENT_OBSERVER].includes(role)
            && canSetCandidateStatus;

        const isVisibleFunnelButton = (isAccessAction || isObserverCanSetCandidateStatus)
            && isVacancyCard;

        const buttonFunnel = {
            component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
            props: {
                buttonProps: {
                    size: "xl",
                    color: "green",
                    children: buttonFunnelMoveName,
                    disabled: progressAction,
                    isIconPositionEnd: true,
                    isContentFullWidth: true,
                    className: FUNNELS_BUTTON_CLASSNAMES.MAIN,
                    classNameTextWithIconContainer: FUNNELS_BUTTON_CLASSNAMES.TEXT_WITH_ICON_CONTAINER,
                },
                children: buttonFunnelMoveName,
                options: _funnelStatusOptions,
            },
            visible: isVisibleFunnelButton,
        };
        const buttonReject = {
            component: COMPONENT.BUTTON,
            props: {
                color: "red",
                onClick: () => {
                    onOpenModal({
                        type: MODAL_TYPE.REJECT_CANDIDATE,
                    });
                },
                children: "Отказать",
            },
            visible: isVisibleFunnelButton,
        };
        const buttonInviteToOrder = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        type: MODAL_TYPE.INVITE_ORDER,
                        clientId,
                        vacancyId,
                        responseId,
                        contractorIds: [card.contractorId],
                    });
                },
                children: "Пригласить на заказ",
            },
            visible: !card.responseId && Boolean(card.contractorId) && isVisibleRecruitmentInviteOrder(),
        };

        if (responseId) {
            return [
                buttonFunnel,
                buttonReject,
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                type: MODAL_TYPE.LINK_CANDIDATE,
                            });
                        },
                        children: "Редактировать связь",
                    },
                    visible: card.candidateId && isAccessAction,
                },
                buttonInviteToOrder,
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            dispatch(setRecruitmentVacancyResponseHeadHunterStatus({
                                responseId: card.responseId,
                                onSuccess: () => {
                                    fetchCard();

                                    toastSuccess("Отклик отправлен");
                                },
                            }));
                        },
                        children: "Отправить приглашение повторно",
                    },
                    visible: isAccessAction
                        && recruitmentHHPolitenessIndex
                        && card.candidateId
                        && card.source?.responseSource === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER
                        && [
                            HH_POLITENESS_INDEX.NOT_SENT,
                            HH_POLITENESS_INDEX.NOT_SENT_ERROR,
                        ].includes(card.hhPolitenessIndex),
                },
            ];
        }

        return [
            buttonFunnel,
            buttonReject,
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenConfirm({});
                    },
                    children: "Удалить кандидата",
                },
                visible: !isVacancyCandidate
                    && isAccessAction
                    && Boolean(candidateId)
                    && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.manageCandidatesAdmin),
            },
            buttonInviteToOrder,
        ];
    };

    return (
        <>
            {renderModalWindow()}
            {getConfirm()}
            <MediaButtons
                config={{
                    renderCount: {
                        desktop: 2,
                        preDesktop: 1,
                        tablet: 0,
                        mobile: 0,
                    },
                    size: "xl",
                    buttons: getButtons(),
                }}
            />
        </>
    );
};