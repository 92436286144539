import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../components/ActualComponents/NmRadioV2";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import PromocodeClientsInput from "../../../components/PromocodeClientsInput";

import dateFormat from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getNumberFromFormattedAmount} from "../../../utils/stringFormat";
import {getClientNameWithoutBrand, isNullOrWhitespace} from "../../../utils/stringHelper";
import {
    toastError,
    toastSuccess,
    toastWarning,
} from "../../../utils/toastHelper";
import validate from "../../../utils/validate";

import {PROMOCODE_CHANNEL_FIELD_NAME} from "../../../constants/clientList";
import {
    POSTFIX_PROMOTION,
    PROMOCODE_TYPE,
    TYPE_PROMOTION,
} from "../../../constants/promocode/dict";
import {
    PROMOCODE_CHANNEL_RULE,
    PROMOCODE_CHANNEL_TYPE_CLIENTS_RULES,
    PROMOCODE_CHANNEL_TYPE_CONTRACTORS_RULES,
    requiredMessage,
} from "../../../constants/validationRules";

import {
    addPromotionChannel,
    checkHasPromotionPromotionChannels,
} from "../../../ducks/bff/promotion-channels/actionCreators";
import {
    getPagePromotionChannelsCard,
    updatePromotionChannelCard,
} from "../../../ducks/bff/promotion-channels/card/actionCreators";
import {
    bffPromotionChannelsCardInfoSelector,
    bffPromotionChannelsCardProgressActionSelector,
} from "../../../ducks/bff/promotion-channels/card/selectors";
import {
    bffPromotionChannelsClientWithPartnersOptionsSelector,
    bffPromotionChannelsProgressActionSelector,
} from "../../../ducks/bff/promotion-channels/selectors";
import {
    clientListOptimizedOptionsSelector,
    getOptimizedClientList,
    promocodeClientOptionsSelector,
    promocodePartnersOptionsSelector,
} from "../../../ducks/client";
import {
    clientGroupsListOptionsSelector,
    getClientGroups,
} from "../../../ducks/clientGroups";
import {
    promocodeGetPageProgressSelector,
    promocodeIsExistChannelSelector,
    updateFieldPromocodeStore,
} from "../../../ducks/promocode";

import PropTypes from "prop-types";

import "./style.sass";

const initForm = {
    [PROMOCODE_CHANNEL_FIELD_NAME.NAME]: "",
    [PROMOCODE_CHANNEL_FIELD_NAME.WORD]: "",
    [PROMOCODE_CHANNEL_FIELD_NAME.COUNT]: "1",
    [PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]: "",
    [PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION]: POSTFIX_PROMOTION.NOT_POSTFIX.VALUE,
    [PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION]: TYPE_PROMOTION.SINGLE.VALUE,
    [PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]: new Date(),
    [PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]: new Date(),
    [PROMOCODE_CHANNEL_FIELD_NAME.TYPE]: PROMOCODE_TYPE.CONTRACTORS,
    [PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_ID]: "",
    [PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]: "1",
    [PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES]: "",
};

class PromoCodeChannelNew extends Component {
    static propTypes = {
        close: PropTypes.func,
        addDeposit: PropTypes.func,
    };

    static defaultProps = {
        close: () => {
        },
        addDeposit: () => {
        },
        dependentData: PropTypes.object,
        channelEdit: {},
    };

    constructor(props) {
        super(props);

        const {
            channelEdit: {
                promotionId,
            },

        } = props;

        this.isEdit = Boolean(promotionId);
        this.promotionId = promotionId;
        this.role = ls(USER_ROLE);

        this.state = {
            errorForm: {},
            channelState: {},
            channel: {
                ...initForm,
            },
            isOpenConfirm: false,
        };
    }

    componentDidMount() {
        if (!this.isEdit) {
            return;
        }

        this.fetchCard();

        if (this.props.channelCard.signContractFrameClientIds) {
            this.fetchClientList({
                clientIdsFilter: this.props.channelCard.signContractFrameClientIds,
            });
        }

        if (this.props.channelCard.clientGroupName) {
            this.fetchClientGroups(this.props.channelCard.clientGroupName);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            isExistsChannel,
            isExistsProgress,
        } = this.props;
        const {
            channel: {
                clientId,
            },
        } = this.state;

        // Проверка на совпадение промокодов в списке выполняется после выбора из дропдауна, т.е. к этому моменту в state уже хранится
        // clientId выбранной компании, в случае если совпадений не найдено сохраняем имя компании в state, которое
        // используется как searchQuery в дропдауне. Также необходимо для корректной работы логики при onBlur
        if (isExistsProgress !== prevProps.isExistsProgress && !isExistsProgress && !isExistsChannel) {
            const {clientAndPartnersOptions} = this.props;

            const {text} = clientAndPartnersOptions.find(({value}) => (value === clientId)) || {};

            if (text) {
                this.setState(prevState => ({
                    ...prevState,
                    channel: {
                        ...prevState.channel,
                        name: text,
                    },
                }));
            }
        }

        if (isExistsChannel && isExistsChannel !== prevProps.isExistsChannel) {
            const {updateFieldPromocodeStore, partnersOptions} = this.props;

            const isPartner = (partnersOptions.map(item => (item.value))).includes(clientId);

            if (isPartner) {
                return;
            }

            const toastMsg = "Для компании уже существует канал привлечения";

            toastError(toastMsg);

            this.onChange(null, {name: "clientId", value: ""});
            this.onChange(null, {name: "name", value: ""});

            this.setState({
                queryNameFilter: "",
                isNeedClearShowedText: true,
            }, () => {
                this.setState({
                    isNeedClearShowedText: false,
                });
            });

            updateFieldPromocodeStore({existingPromocodeChannels: {totalCount: 0}});
        }

        if (!isEmpty(this.props.channelCard)) {
            if (
                this.props.channelCard.signContractFrameClientIds &&
                this.props.channelCard.signContractFrameClientIds !== prevProps.channelCard.signContractFrameClientIds
            ) {
                this.fetchClientList({
                    clientIdsFilter: this.props.channelCard.signContractFrameClientIds,
                });
            }

            if (this.props.channelCard.clientGroupName && (this.props.channelCard.clientGroupName !== prevProps.channelCard.clientGroupName)) {
                this.fetchClientGroups(this.props.channelCard.clientGroupName);
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {channelCard} = props;
        const {channelState} = state;

        if (channelCard.promotionId && JSON.stringify(channelState) !== JSON.stringify(channelCard)) {
            const channel = {
                ...channelCard,
                [PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]: channelCard[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE] === null ? null : new Date(channelCard[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]),
                [PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]: channelCard[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE] === null ? null : new Date(channelCard[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]),
            };

            return {
                ...state,
                channel,
                channelState: channelCard,
            };
        }

        return null;
    }

    fetchCard = () => {
        const {getPagePromotionChannelsCard} = this.props;

        getPagePromotionChannelsCard({
            promotionId: this.promotionId,
        });
    };

    fetchExistingPromocodeChannel = (nameFilter) => {
        const {checkHasPromotionPromotionChannels} = this.props;
        const {
            queryNameFilter,
        } = this.state;

        if (queryNameFilter === nameFilter) {
            return;
        }

        this.setState({
            queryNameFilter: nameFilter,
        });

        checkHasPromotionPromotionChannels({
            getResult: (result) => {
                if (result === true) {
                    toastError("Для компании уже существует канал привлечения");
                }
            },
            clientName: nameFilter,
        });
    };

    submitConfirm = () => {
        const {
            checked,
        } = this.state;

        this.setState(prevState => ({
            ...prevState,
            channel: {
                ...initForm,
                type: checked ? PROMOCODE_TYPE.CLIENTS : PROMOCODE_TYPE.CONTRACTORS,
            },
            errorForm: {},
            isOpenConfirm: false,
            queryNameFilter: "",
            isNeedClearShowedText: true,
        }), () => {
            this.setState({
                isNeedClearShowedText: false,
            });
        });
    };

    onChange = (e, {value, name, checked, selectedByDropdown = false}) => {
        if (name === PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_ID && value.length) {
            const {clientAndPartnersOptions} = this.props;
            const {text} = clientAndPartnersOptions.find(({value: clientId}) => (clientId === value)) || {};

            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [name]: value,
                    name: text,
                },
            }), () => {
                if (selectedByDropdown) {
                    this.fetchExistingPromocodeChannel(text);
                }
            });
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER) {
            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [name]: checked,
                    [PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]: checked ? prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID] : undefined,
                },
                errorForm: {
                    ...prevState.errorForm,
                    [PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]: checked ? prevState.errorForm[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID] : undefined,
                },
            }));

            return;
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER) {
            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [name]: checked,
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER]: checked ? false : prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER],
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]: !checked && !prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER] ? undefined : prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS],
                },
                errorForm: {
                    ...prevState.errorForm,
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]: checked ? undefined : prevState.errorForm[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS],
                },
            }));

            return;
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER) {
            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [name]: checked,
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER]: checked ? false : prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER],
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]: !checked && !prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER] ? undefined : prevState.channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS],
                },
                errorForm: {
                    ...prevState.errorForm,
                    [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]: checked ? prevState.errorForm[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS] : undefined,
                },
            }));

            return;
            //!channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER] && !channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER]
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.TYPE) {
            this.setState({
                isOpenConfirm: true,
                checked,
            });

            return;
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION && value === TYPE_PROMOTION.SINGLE.VALUE) {
            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]: "",
                    [PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION]: value,
                },
                errorForm: {
                    ...prevState.errorForm,
                    [PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]: undefined,
                },
            }));

            return;
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION && value === POSTFIX_PROMOTION.NOT_POSTFIX.VALUE) {
            this.setState(prevState => ({
                ...prevState,
                channel: {
                    ...prevState.channel,
                    [PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION]: value,
                    [PROMOCODE_CHANNEL_FIELD_NAME.COUNT]: "1",
                },
            }));

            return;
        }

        if (name === PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS && value.length > 10) {
            toastWarning("Возможен выбор не более 10 компаний");

            return;
        }

        this.setState(prevState => ({
            ...prevState,
            channel: {
                ...prevState.channel,
                [name]: checked !== undefined ? checked : value,
            },
        }));
    };

    submitChannel = () => {
        const {
            close,
            addPromotionChannel,
            updatePromotionChannelCard,
            partnersOptions,
        } = this.props;

        const {channel} = this.state;

        const isValid = this.validateForm(channel);

        if (!isValid) {
            return;
        }

        if (this.isEdit) {
            updatePromotionChannelCard({
                ...channel,
                [PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]: getNumberFromFormattedAmount(channel[PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]),
                onSuccess: () => {
                    toastSuccess("Канал успешно обновлен");
                    close();
                },
            });

            return;
        }

        const {
            clientId,
        } = channel;

        const clientPartnerId = (partnersOptions.map(item => (item.value))).includes(clientId);

        const requestData = {
            ...channel,
            clientId: clientId && !clientPartnerId ? clientId : undefined,
            clientPartnerId: clientPartnerId ? clientId : undefined,
            [PROMOCODE_CHANNEL_FIELD_NAME.WORD]: String(channel[PROMOCODE_CHANNEL_FIELD_NAME.WORD]).trim().toUpperCase(),
            [PROMOCODE_CHANNEL_FIELD_NAME.COUNT]: String(channel[PROMOCODE_CHANNEL_FIELD_NAME.COUNT]).trim(),
            [PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]: String(channel[PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]).trim(),
            [PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]: getNumberFromFormattedAmount(channel[PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]),
            [PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]:
                channel[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE] === null || channel[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE] === undefined
                    ? undefined : dateFormat(channel[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE], "yyyy-MM-dd"),
            [PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]:
                channel[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE] === null || channel[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE] === undefined
                    ? undefined : dateFormat(channel[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE], "yyyy-MM-dd"),
        };

        addPromotionChannel({
            ...requestData,
            onError: ({errorCode, errorMessage}) => {
                if (errorCode === "INVALID_WORD") {
                    this.setState(prevState => ({
                        ...prevState,
                        errorForm: {
                            ...prevState.errorForm,
                            [PROMOCODE_CHANNEL_FIELD_NAME.WORD]: errorMessage,
                        },
                    }));

                    return;
                }

                toastError(errorMessage);
            },
            onSuccess: close,
        });
    };

    validateForm(channel) {
        const {
            type,
            clientId,
            name,
        } = channel;

        const validatedData = {
            ...channel,
            clientId: !clientId ? name : clientId,
        };

        const rule = type === PROMOCODE_TYPE.CONTRACTORS ? {
            ...PROMOCODE_CHANNEL_RULE[channel[PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION]],
            ...PROMOCODE_CHANNEL_TYPE_CONTRACTORS_RULES,
        } : {
            ...PROMOCODE_CHANNEL_RULE[channel[PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION]],
            ...PROMOCODE_CHANNEL_TYPE_CLIENTS_RULES,
        };

        let errorForm = validate(validatedData, rule, "");

        if ((channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER] || channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER]) && isEmpty(channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS])) {
            errorForm = {
                ...errorForm,
                [PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]: requiredMessage,
            };
        }

        if (channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER] && isNullOrWhitespace(channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID])) {
            errorForm = {
                ...errorForm,
                [PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]: requiredMessage,
            };
        }

        this.setState({errorForm: {...errorForm}});

        return Object.values(errorForm).length === 0;
    }

    onBlurFilterClients = (e) => {
        const {
            clientAndPartnersOptions,
        } = this.props;
        const {
            channel: {
                name,
                clientId,
            },
        } = this.state;

        const {value: clientIdInList} = clientAndPartnersOptions.find(({clientName, text}) => ([clientName, text].includes(name))) || {};

        if (clientIdInList) {
            this.fetchExistingPromocodeChannel(name);

            this.onChange(e, {name: "clientId", value: clientIdInList});
        }

        if (!clientIdInList && clientId) {
            this.onChange(e, {name: "clientId", value: ""});
        }
    };

    renderActions() {
        const {
            close,
            t,
        } = this.props;

        return (
            <ApplyButtons
                submitBtnContent={this.isEdit ? t("button.save") : t("button.add")}
                cancelBtnContent={t("button.cancel")}
                onClose={close}
                submit={this.submitChannel}
            />
        );
    }

    getTitle() {
        const {
            t,
            channelEdit: {name},
        } = this.props;
        const {
            channel: {type},
        } = this.state;

        return (
            <div className="row gx-0 gx-xl-4">
                <div className="col-16 col-xl-6 mb-3 mb-xl-0">
                    <NmTitle size="lg">
                        {
                            this.isEdit ?
                                `${t("promocode-channel-new.promocode-channel-edit-title")} - ${name}` :
                                t("promocode-channel-new.promocode-channel-new-title")
                        }
                    </NmTitle>
                </div>
                <div className="col-16 col-xl-10 mb-3 mb-xl-0">
                    <NmHorizontalToggleV2
                        className="promocode-channel-new__toggle"
                        leftLabel="Для исполнителей"
                        rightLabel="Для компаний"
                        suggestion="Укажите, кто будет использовать промо-код в дальнейшем"
                        onChange={this.onChange}
                        tooltipIconHeight={20}
                        tooltipIconWidth={20}
                        name={PROMOCODE_CHANNEL_FIELD_NAME.TYPE}
                        checked={type !== PROMOCODE_TYPE.CONTRACTORS}
                        disabled={this.isEdit}
                    />
                </div>
            </div>
        );
    }

    cancelConfirm = () => {
        this.setState({
            isOpenConfirm: false,
        });
    };

    fetchClientGroups = (groupNameFilter) => {
        const {
            getClientGroups,
        } = this.props;

        getClientGroups({
            pageNum: 1,
            pageSize: 25,
            groupNameFilter: isNullOrWhitespace(groupNameFilter) ? null : groupNameFilter.trim(),
        });
    };

    fetchClientList = ({clientNameFilter = "", clientIdsFilter = []}) => {
        const {
            getOptimizedClientList,
        } = this.props;

        getOptimizedClientList({
            pageNum: 1,
            pageSize: 25,
            archivedFilter: false,
            nameSubstringFilter: isNullOrWhitespace(clientNameFilter) ? null : getClientNameWithoutBrand(clientNameFilter),
            clientIdsFilter: isEmpty(clientIdsFilter) ? null : clientIdsFilter,
        });
    };

    onSearchChangeClient = (clientNameFilter) => {
        this.fetchClientList({clientNameFilter});
    };

    onSearchChangeClientGroup = (groupNameFilter) => {
        this.fetchClientGroups(groupNameFilter);
    };

    renderConfirm() {
        const {t} = this.props;
        const {isOpenConfirm} = this.state;

        return isOpenConfirm &&
            <NmConfirmV2
                content="Все введенные значения будут удалены. Вы действительно хотите продолжить?"
                onCancel={this.cancelConfirm}
                onConfirm={this.submitConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />;
    }

    render() {
        const {
            errorForm,
            channel,
            channel: {
                fromDate,
                toDate,
                type,
                name,
                applicationCount,
            },
            isNeedClearShowedText,
        } = this.state;

        const {
            close,
            t,
            allClientListOptions,
            clientGroupsListOptions,
            progressAdd,
            progressEdit,
        } = this.props;

        const isAccessEdit = !applicationCount;

        return (
            <NmModal
                size="md"
                onClose={close}
                className="promocode-channel-new"
                footer={this.renderActions()}
                header={this.getTitle()}
                loading={progressAdd || progressEdit}
            >
                {this.renderConfirm()}
                <NmForm>
                    <div className="promocode-channel-new__row">
                        <PromocodeClientsInput
                            minSearchLength={2}
                            disabled={this.isEdit}
                            label={t("promocode-channel-new.channel-name")}
                            placeholder={t("promocode-channel-new.enter-channel-name")}
                            size="xl"
                            name="clientId"
                            onlyPartners={type !== PROMOCODE_TYPE.CONTRACTORS}
                            nameSearchQuery="name"
                            searchQuery={name}
                            value={channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_ID]}
                            error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.NAME]}
                            onChangeInput={this.onChange}
                            onChange={(event, {name, value}) => {
                                this.onChange(event, {name, value, selectedByDropdown: true});
                            }}
                            allValuesActive
                            onBlur={this.onBlurFilterClients}
                            isNeedClearShowedText={isNeedClearShowedText}
                        />
                    </div>
                    <div className="promocode-channel-new__row">
                        <NmInputV2
                            disabled={this.isEdit}
                            size="xl"
                            required
                            name={PROMOCODE_CHANNEL_FIELD_NAME.WORD}
                            error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.WORD]}
                            label={t("promocode-channel-new.fixed-word")}
                            placeholder={t("promocode-channel-new.enter-fixed-word")}
                            onChange={this.onChange}
                            value={channel[PROMOCODE_CHANNEL_FIELD_NAME.WORD]}
                        />
                    </div>
                    <div className="flex-column">
                        <div className="promocode-channel-new__row col-16">
                            <NmRadioV2
                                disabled={this.isEdit}
                                name={PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION}
                                label={t("promocode-channel-new.no-postfix")}
                                checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION] === POSTFIX_PROMOTION.NOT_POSTFIX.VALUE}
                                value={POSTFIX_PROMOTION.NOT_POSTFIX.VALUE}
                                className="promocode-channel-new__field"
                                onChange={this.onChange}
                                info={t("promocode-channel-new.generated-promocode")}
                            />
                            <HelpTooltip
                                className="promocode-channel-new__tooltip"
                                info
                                hover
                                width={20}
                                height={20}
                                text={t("promocode-channel-new.generated-promocode")}
                            />
                        </div>
                        <div className="promocode-channel-new__row col-16">
                            <NmRadioV2
                                disabled={this.isEdit}
                                name={PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION}
                                checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION] === POSTFIX_PROMOTION.POSTFIX.VALUE}
                                value={POSTFIX_PROMOTION.POSTFIX.VALUE}
                                className="promocode-channel-new__field"
                                label={t("promocode-channel-new.with-postfix")}
                                onChange={this.onChange}
                            />
                            <HelpTooltip
                                className="promocode-channel-new__tooltip"
                                info
                                hover
                                width={20}
                                height={20}
                                text={t("promocode-channel-new.generated-promocodes-consistently")}
                            />
                        </div>
                        <div className="promocode-channel-new__row col-16">
                            <NmRadioV2
                                disabled={this.isEdit}
                                name={PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION}
                                checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION] === POSTFIX_PROMOTION.RANDOM_POSTFIX.VALUE}
                                value={POSTFIX_PROMOTION.RANDOM_POSTFIX.VALUE}
                                className="promocode-channel-new__field"
                                label={t("promocode-channel-new.with-arbitrary-postfix")}
                                onChange={this.onChange}
                            />
                            <HelpTooltip
                                className="promocode-channel-new__tooltip"
                                info
                                hover
                                width={20}
                                height={20}
                                text={t("promocode-channel-new.generated-promocodes-arbitrarily")}
                            />
                        </div>
                    </div>
                    <div className="promocode-channel-new__sub-title">
                        {t("promocode-channel-new.use-promocode")}
                    </div>
                    <div className="promocode-channel-new__type-use">
                        <NmRadioV2
                            name={PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION}
                            checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION] === TYPE_PROMOTION.SINGLE.VALUE}
                            value={TYPE_PROMOTION.SINGLE.VALUE}
                            label={t("promocode-channel-new.disposable")}
                            className="promocode-channel-new__field promocode-channel-new__one-time"
                            onChange={this.onChange}
                        />
                        <NmRadioV2
                            name={PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION}
                            checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION] === TYPE_PROMOTION.MULTIPLE.VALUE}
                            value={TYPE_PROMOTION.MULTIPLE.VALUE}
                            className="promocode-channel-new__field"
                            label={t("promocode-channel-new.reusable")}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="promocode-channel-new__container">
                        <div className="promocode-channel-new__row row gx-0 gx-xl-4">
                            <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                                <NmInputV2
                                    label={t("promocode-channel-new.max-uses")}
                                    maskChar={null}
                                    mask="9999999"
                                    size="xl"
                                    error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]}
                                    alwaysShowMask={false}
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT}
                                    disabled={channel[PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION] === TYPE_PROMOTION.SINGLE.VALUE}
                                    onChange={this.onChange}
                                    value={channel[PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]}
                                />
                            </div>
                            <div className="col-16 col-xl-8">
                                <NmInputV2
                                    label={t("promocode-channel-new.number-promocodes")}
                                    maskChar={null}
                                    mask="9999999"
                                    size="xl"
                                    error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.COUNT]}
                                    disabled={channel[PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION] === POSTFIX_PROMOTION.NOT_POSTFIX.VALUE}
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.COUNT}
                                    onChange={this.onChange}
                                    value={channel[PROMOCODE_CHANNEL_FIELD_NAME.COUNT]}
                                />
                            </div>
                        </div>
                        <div className="promocode-channel-new__row row">
                            <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                                <NmDateRangePickerV2
                                    label={t("promocode-channel-new.activity-period")}
                                    size="xl"
                                    startFieldName={PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE}
                                    endFieldName={PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE}
                                    required
                                    value={{
                                        fromDate,
                                        toDate,
                                    }}
                                    minDate={{
                                        start: new Date(),
                                        end: new Date(channel[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]) || new Date(),
                                    }}
                                    error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE] || errorForm[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]}
                                    maxDate={{
                                        start: new Date(channel[PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]) || new Date(),
                                        end: null,
                                    }}
                                    onChange={this.onChange}
                                />
                            </div>
                            {
                                channel.type === PROMOCODE_TYPE.CONTRACTORS &&
                                <div className="col-16 col-xl-8">
                                    <AmountInput
                                        label={t("promocode-channel-new.partner-remuneration")}
                                        newInput
                                        size="xl"
                                        maxValue={100}
                                        error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]}
                                        placeholder="0,00"
                                        name={PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE}
                                        className="col-16 col-xl-8 mb-3 mb-xl-0"
                                        onChange={this.onChange}
                                        value={channel[PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]}
                                    />
                                </div>
                            }
                        </div>
                        <div className="promocode-channel-new__row">
                            <NmInputV2
                                required
                                label={t("promocode-channel-new.details-of-the-contract")}
                                size="xl"
                                error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES]}
                                name={PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES}
                                onChange={this.onChange}
                                value={channel[PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES]}
                            />
                        </div>
                        {type === PROMOCODE_TYPE.CONTRACTORS && <>
                            <div className="promocode-channel-new__row">
                                <NmCheckboxV2
                                    label="Подписание РД НПД после полной регистрации в мобильном приложении"
                                    checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER]}
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER}
                                    disabled={!isAccessEdit}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="promocode-channel-new__row">
                                <NmCheckboxV2
                                    label="Подписание РД НПД после проверки данных администрацией Наймикс"
                                    checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER]}
                                    disabled={!isAccessEdit}
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="promocode-channel-new__row">
                                <NmDropdownV2
                                    isAllSelectionShown={false}
                                    tooltip={
                                        <HelpTooltip
                                            hover
                                            info
                                            text="Пользователю будут предложены РД с компаниями, выбранными Вами в данном списке"
                                            position="top"
                                        />
                                    }
                                    multiple
                                    isMountLoad={isEmpty(channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS])}
                                    disabled={(!channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER] && !channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER]) || !isAccessEdit}
                                    label="Наименование компании"
                                    noResultsMessage={t("no-search-result.content")}
                                    onSearchChange={this.onSearchChangeClient}
                                    onChange={this.onChange}
                                    placeholder="Введите наименование компании"
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS}
                                    search
                                    required={channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_ADMIN_CHECK_FILTER] || channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_AFTER_MOBILE_FILTER]}
                                    selection
                                    value={channel[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]}
                                    options={allClientListOptions}
                                    error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.SIGN_CONTRACT_FRAME_CLIENT_IDS]}
                                />
                            </div>
                            <div className="promocode-channel-new__row">
                                <NmCheckboxV2
                                    label="Замкнутый контур"
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER}
                                    checked={channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER]}
                                    onChange={this.onChange}
                                    disabled={!isAccessEdit}
                                />
                            </div>
                            <div className="promocode-channel-new__row">
                                <NmDropdownV2
                                    tooltip={
                                        <HelpTooltip
                                            hover
                                            info
                                            text="Пользователь будет добавлен в выбранный Вами замкнутый контур"
                                            position="top"
                                        />
                                    }
                                    isMountLoad={!channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]}
                                    disabled={!channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER] || !isAccessEdit}
                                    label="Наименование контура"
                                    noResultsMessage={t("no-search-result.content")}
                                    onSearchChange={this.onSearchChangeClientGroup}
                                    onChange={this.onChange}
                                    placeholder="Введите наименование контура"
                                    name={PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID}
                                    search
                                    required={channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_FILTER]}
                                    selection
                                    value={channel[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]}
                                    options={clientGroupsListOptions}
                                    error={errorForm[PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_GROUP_ID]}
                                />
                            </div>
                        </>}
                    </div>
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        channelCard: bffPromotionChannelsCardInfoSelector(state),
        isExistsChannel: promocodeIsExistChannelSelector(state),
        clientAndPartnersOptions: bffPromotionChannelsClientWithPartnersOptionsSelector(state),
        clientOptions: promocodeClientOptionsSelector(state),
        partnersOptions: promocodePartnersOptionsSelector(state),
        isExistsProgress: promocodeGetPageProgressSelector(state),
        allClientListOptions: clientListOptimizedOptionsSelector(state),
        clientGroupsListOptions: clientGroupsListOptionsSelector(state),
        progressAdd: bffPromotionChannelsProgressActionSelector(state),
        progressEdit: bffPromotionChannelsCardProgressActionSelector(state),
    }),
    {
        addPromotionChannel,
        updatePromotionChannelCard,
        getPagePromotionChannelsCard,
        updateFieldPromocodeStore,
        getOptimizedClientList,
        getClientGroups,
        checkHasPromotionPromotionChannels,
    },
)(withTranslation()(PromoCodeChannelNew));
