import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesNaimixTariffGridSelector = state => state.bff.settingsDirectoriesNaimixTariffGrid;
export const tariffScaleCardSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({card}) => card);
export const tariffScaleProgressCardSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({progressCard}) => progressCard);
export const tariffScaleListSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({list}) => list);
export const tariffScaleTotalCountSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({totalCount}) => totalCount);
export const tariffScaleProgressSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({progress}) => progress);
export const tariffScaleTotalPagesSelector = createSelector(settingsDirectoriesNaimixTariffGridSelector, ({totalCount, pageSize = 0}) => getTotalPages(totalCount, pageSize));