import {
    KEDO_DEPARTMENTS_STAFF_CLEAR_STORE,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS,
    KEDO_DEPARTMENTS_STAFF_MOVE_ERROR,
    KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST,
    KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS,
    UPDATE_KEDO_DEPARTMENTS_STAFF_STORE,
} from "./actions";

const initialState = {
    list: [],
    totalCount: 0,
    pageData: {},
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case KEDO_DEPARTMENTS_STAFF_CLEAR_STORE:
            return initialState;
        case KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case KEDO_DEPARTMENTS_STAFF_MOVE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case UPDATE_KEDO_DEPARTMENTS_STAFF_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};