import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANCEL_ERROR,
    BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CLOSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_EXPORT_ERROR,
    BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST,
    BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_HIDE_ERROR,
    BFF_RECRUITMENT_VACANCY_HIDE_REQUEST,
    BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR,
    BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/recruitment/vacancy";

//POST /bff/adm/recruitment/vacancy/getPage
// Получить список вакансий
const getPageRecruitmentVacancy = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/
// Создать вакансию
const addRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess = () => {},
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_ERROR,
        });
    }
};

//PUT /bff/adm/recruitment/vacancy/{vacancyId}
// Редактировать вакансию
const updateRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${controller}/${vacancyId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/publish
// Опубликовать вакансию
const publishRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/publish`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/archive
// Перенос вакансии в архив
const archiveRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/archive`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/unarchive
// Восстановление вакансии из архива
const unarchiveRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/unarchive`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{clientId}/{id}/close
// Закрытие вакансии на площадке
const closeRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            clientId,
            vacancyId,
            jobBoardTypes,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${controller}/${clientId}/${vacancyId}/close`, {jobBoardTypes});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CLOSE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CLOSE_ERROR,
        });
    }
};

//DELETE /bff/adm/recruitment/vacancy/{id}
// Удаление вакансии
const deleteRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/${vacancyId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/cancel
// Отмена публикации вакансии на площадке
const cancelRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/cancel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANCEL_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANCEL_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/batchDelete
// Массовое удаление вакансий
const batchDeleteRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyIds,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/batchDelete`, vacancyIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/batchPublish
// Массовая публикация вакансий
const batchPublishRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyIds,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/batchPublish`, vacancyIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/batchPublish/validate
// Валидация перед массовой публикацией вакансий
const batchPublishValidateRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyIds,
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/batchPublish/validate`, vacancyIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/totalCandidates
// Подсчет количества исполнителей при создании вакансии
const getTotalCandidatesRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/totalCandidates`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR,
        });
    }
};

//GET /bff/adm/recruitment/vacancy/{vacancyId}
// Получить вакансию по идентификатору
const getByIdRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${vacancyId}/card`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/import/fromBoards
// Импортировать вакансии с джоб бордов
const importFromBoardsRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/import/fromBoards`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR,
        });
    }
};

//GET /bff/adm/recruitment/vacancy/{id}/candidateResponseCounters
// Получить фильтры статусов для кандидатов по вакансии
const getVacancyCandidateResponseCountersSaga = function* ({payload}) {
    try {
        const {
            vacancyId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/candidateResponseCounters`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/candidates
// Получить кандидатов по вакансии
const getPageRecruitmentVacancyCandidates = function* ({payload}) {
    try {
        const {
            isLoadMore,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/${reqData.vacancyId}/candidates`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/responses/hide
// Скрыть вакансию для откликов
const hideRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            value,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/responses/hide?value=${value}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_HIDE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_HIDE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/updateFromJobBoard
// Обновить параметры вакансии с джоб борда
const updateFromJobBoardRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            jobBoardType,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/updateFromJobBoard?jobBoardType=${jobBoardType}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/createAtJobBoard
// Создать вакансию на джоб-борде
const createAtJobBoardRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            jobBoardParams: {
                jobBoardType,
            },
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/createAtJobBoard?jobBoardType=${jobBoardType}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/updateAtJobBoard
// Обновить вакансию на джоб-борде
const updateAtJobBoardRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            jobBoardParams: {
                jobBoardType,
            },
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/updateAtJobBoard?jobBoardType=${jobBoardType}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/import/responses
// Импортировать отклики в вакансию с джоб борда
const importResponsesFromJobBoardRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            jobBoardType,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/import/responses?jobBoardType=${jobBoardType}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/export
// Выгрузить отклики и кандидатов в файл
const exportRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/export`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_EXPORT_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/{id}/addCandidate?candidateId=123
// Добавить кандидата в вакансию
const addCandidateRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.bff.post(`${controller}/${vacancyId}/addCandidate`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/getLogs
// Получить список логов по вакансии
const getLogPageRecruitmentVacancy = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getLogs`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR,
        });
    }
};

//GET /bff/adm/recruitment/vacancy/{vacancyId}/getFeed
// Получить ленту событий по вакансии
const getFeedListRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            vacancyId,
            ...params
        } = payload;

        const result = yield request.bff.get(`${controller}/${vacancyId}/getFeed`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/vacancy/{id}/jobBoards
// Получить джоб борды по вакансии
const getVacancyJobBoards = function* ({payload}) {
    const {
        id,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${id}/jobBoards`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancy/addResponseByResumeUrl
// Добавление отклика в вакансию через ссылку на резюме (холодный поиск)
const addResponseByResumeUrlRecruitmentVacancy = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/addResponseByResumeUrl`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage || "Ошибка. Попробуйте позже");

            yield put({
                type: BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancy/{id}/update-job-board-account
// Обновить аккаунт джоб-борда для вакансии
const updateVacancyJobBoardAccount = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.bff.post(
            `${controller}/${requestData.vacancyId}/update-job-board-account`,
            requestData,
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancy/{id}/recruiters-with-access
// Получить список рекрутеров, имеющих доступ к вакансии
const getRecruitersWithAccessVacancy = function* ({payload}) {
    const {
        vacancyId,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${vacancyId}/recruiters-with-access`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancy/get-available-recruiters
// Получить список пользователей для выбора ответственного рекрутера
const getAvailableVacancyRecruiters = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get-available-recruiters`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancy/addLogoVacancyAvito
// Добавить логотип для Авито
const addAvitoVacancyLogo = function* ({payload}) {
    const {
        formData,
        handleResponse,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/addLogoVacancyAvito`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_SUCCESS,
            payload: result,
        });

        handleResponse();
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST, getPageRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_ADD_REQUEST, addRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST, updateRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST, publishRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST, archiveRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST, unarchiveRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST, closeRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_DELETE_REQUEST, deleteRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST, cancelRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST, batchDeleteRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST, batchPublishRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST, batchPublishValidateRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST, getTotalCandidatesRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST, getByIdRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST, importFromBoardsRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST, getPageRecruitmentVacancyCandidates),
        takeEvery(BFF_RECRUITMENT_VACANCY_HIDE_REQUEST, hideRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST, updateFromJobBoardRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST, createAtJobBoardRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST, updateAtJobBoardRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST, importResponsesFromJobBoardRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST, exportRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST, addCandidateRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST, getLogPageRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST, getVacancyCandidateResponseCountersSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST, getFeedListRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST, addResponseByResumeUrlRecruitmentVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST, getVacancyJobBoards),
        takeEvery(BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST, updateVacancyJobBoardAccount),
        takeEvery(BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST, getRecruitersWithAccessVacancy),
        takeEvery(BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST, getAvailableVacancyRecruiters),
        takeEvery(BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST, addAvitoVacancyLogo),
    ]);
}