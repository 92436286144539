import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getAdditionalAgreements} from "../actionCreators";
import {bffAdditionalAgreementsPageDataSelector} from "../selectors";
import {
    getRegistryOfAdditionalAgreement,
    getRegistryOfAdditionalAgreementItemsPage,
    resubmitAdditionalAgreement,
    submitRegistryOfAdditionalAgreement,
} from "./actionCreators";
import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS,
} from "./actions";
import {
    bffAdditionalAgreementsRegistryPageDataSelector,
} from "./selectors";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";

const controller = "/adm/clients/client-card/documents/additional-agreement-registries/registry";

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/archive
// Архивировать реестр
const archiveRegistryOfAdditionalAgreement = function* ({payload}) {
    const {
        id,
        archived,
    } = payload;

    const action = archived ? "restore-from-archive" : "archive";

    try {
        const result = yield request.bff.put(`${controller}/${id}/${action}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = bffAdditionalAgreementsPageDataSelector(state);

        yield put(getAdditionalAgreements(pageData));
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/get
// Получить инфо о реестре
const getRegistryOfAdditionalAgreementSaga = function* ({payload}) {
    const {
        id,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${id}/get`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/status/dict
// Словарь статуса строки реестра
const getRegistryOfAdditionalAgreementStatusDict = function* () {
    try {
        const result = yield request.bff.get(`${controller}/status/dict`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/items/page
// Получить страницу записей реестра
const getRegistryOfAdditionalAgreementItemsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/items/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR,
        });
    }
};

const handleResponse = function* (data) {
    const {
        isFetchCard,
        isFetchCardList = isFetchCard,
        isFetchList,
        id,
    } = data;

    if (isFetchCard) {
        yield put(getRegistryOfAdditionalAgreement({id}));
    }

    if (isFetchCardList) {
        const state = yield select();
        const pageData = bffAdditionalAgreementsRegistryPageDataSelector(state);

        yield put(getRegistryOfAdditionalAgreementItemsPage(pageData));

        return {
            done: true,
        };
    }

    if (isFetchList) {
        const state = yield select();
        const pageData = bffAdditionalAgreementsPageDataSelector(state);

        yield put(getAdditionalAgreements(pageData));
    }
};

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/submit
// Передать на подписание
const submitRegistryOfAdditionalAgreementSaga = function* ({payload}) {
    const {
        id,
        isFetchCard,
        isFetchList,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${id}/submit`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchList,
            isFetchCard,
            id,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/cancel
// Отменить невыполненные
const cancelAllAdditionalAgreements = function* ({payload}) {
    const {
        id,
        isFetchCard,
        isFetchList,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${id}/cancel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchList,
            isFetchCard,
            id,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/items/{id}/cancel
// Отменить строку реестра
const cancelAdditionalAgreement = function* ({payload}) {
    const {
        id,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/items/${id}/cancel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchCardList: true,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/items/delete
// Удалить строки реестра (список)
const deleteAdditionalAgreements = function* ({payload}) {
    const {
        id,
        selectedIds,
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/items/delete`, {data: selectedIds});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            id,
            isFetchCard: true,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/submit/warnings
// Передать на подписание (набор предупреждений перед передачей)
const getAdditionalAgreementWarnings = function* ({payload}) {
    const {
        id,
        isSubmit,
        isResubmit,
        isFetchCard,
        isFetchList,
        isSubmitRegistry,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${id}/submit/warnings`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS,
            payload: result,
        });

        if (!result.result) {
            return {
                done: true,
            };
        }

        const {
            noItemsToSubmit,
            ...flags
        } = result.result;

        if (noItemsToSubmit) {
            if (isSubmitRegistry) {
                toastError("В реестре отсутствуют записи");
            } else {
                toastError( "В реестре отсутствуют невыполненные строки реестра");
            }

            return {
                done: true,
            };
        }

        const isStopSigning = Object.values(flags).some(flag => flag);

        if (isStopSigning) {
            return {
                done: true,
            };
        }

        if (isResubmit) {
            yield put(resubmitAdditionalAgreement({
                id,
                isFetchCard,
                isFetchList,
            }));

            return {
                done: true,
            };
        }

        if (isSubmit) {
            yield put(submitRegistryOfAdditionalAgreement({
                id,
                isFetchCard,
                isFetchList,
            }));
        }
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/items/add
// Добавить исполнителя в реестр
const addItemToAdditionalAgreementRegistry = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/items/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchCardList: true,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/contractor-candidates-page
// Список РД исполнителя для добавления
const getAdditionalAgreementContractorCandidates = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/contractor-candidates-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/frame-contract-candidates-list
// Список исполнителей для добавления
const getAdditionalAgreementFrameContractorCandidates = function* ({payload}) {
    const {
        id,
        contractorId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${id}/frame-contract-candidates-list`, {
            params: {
                id,
                contractorId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/registry/{id}/resubmit
// Повторить невыполненные
const resubmitAdditionalAgreementSaga = function* ({payload}) {
    const {
        id,
        isFetchCard,
        isFetchList,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${id}/resubmit`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchList,
            isFetchCard,
            id,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST, archiveRegistryOfAdditionalAgreement),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST, getRegistryOfAdditionalAgreementSaga),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST, getRegistryOfAdditionalAgreementStatusDict),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST, getRegistryOfAdditionalAgreementItemsPageSaga),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST, submitRegistryOfAdditionalAgreementSaga),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST, cancelAllAdditionalAgreements),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST, cancelAdditionalAgreement),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST, deleteAdditionalAgreements),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST, getAdditionalAgreementWarnings),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST, addItemToAdditionalAgreementRegistry),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST, getAdditionalAgreementContractorCandidates),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_REQUEST, getAdditionalAgreementFrameContractorCandidates),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST, resubmitAdditionalAgreementSaga),
    ]);
}