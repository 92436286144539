import React from "react";

import AmountInput from "../../../../AmountInput";
import {ClientWorkTypeDropdown} from "../../../../ClientWorkTypeDropdown";
import NmLoader from "../../../../NmLoader";
import HelpTooltip from "../../../HelpTooltip";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import NmInputV2 from "../../../NmInputV2";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsAdditionalModulesResourcesAccess} from "../ResourcesAccess";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";

import {useClientSettingsAdditionalModulesForm} from "./hooks/useForm";

import {CLIENT_WORK_TYPE} from "../../../../../constants/clientSettings";
import {ADMIN} from "../../../../../constants/roles";

export const ClientSettingsAdditionalModules = (props) => {
    const {
        isAccess,
        role,
        clientId,
        refFooter,
        onClose,
    } = props;

    const {
        values,
        errors,
        touched,
        onChange,
        onSubmit,
        isLoading,
    } = useClientSettingsAdditionalModulesForm({
        clientId,
        onClose,
    });

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            {
                isLoading &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            <div className="row align-items-start gx-4 mb-3 mb-md-4">
                <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                    <NmCheckboxV2
                        disabled={!isAccess}
                        className="mt-0 mt-xl-5"
                        name="edmAvailable"
                        onChange={onChange}
                        checked={values.edmAvailable}
                        label="Электронный документооборот"
                    />
                </div>
                <div className="col-16 col-xl-8">
                    <NmInputV2
                        size="xl"
                        label="Тариф, ₽"
                        required={values.edmAvailable}
                        disabled={!isAccess || !values.edmAvailable}
                        name="edmTariff"
                        value={values.edmTariff}
                        onChange={onChange}
                        error={
                            touched?.edmTariff &&
                            errors?.edmTariff
                        }
                    />
                </div>
            </div>
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={role !== ADMIN || !values.edmAvailable}
                name="withoutContract"
                onChange={onChange}
                checked={values.withoutContract}
                label="Без договора"
            />
            <div className="row align-items-start gx-4 mb-3 mb-md-4">
                <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                    <NmCheckboxV2
                        disabled={!isAccess || !values.edmAvailable}
                        name="migrantLicensePaymentEnabled"
                        onChange={onChange}
                        className="mt-0 mt-xl-5"
                        checked={values.migrantLicensePaymentEnabled}
                        label="Оплата патентов мигрантов"
                        isVisibleTooltip={true}
                        tooltip={
                            <HelpTooltip
                                info
                                hover
                                text="Модуль подключается совместно с модулем ЭДО"
                                position="bottom"
                            />
                        }
                    />
                </div>
                <div className="col-16 col-xl-8">
                    <AmountInput
                        newInput
                        size="xl"
                        label="Комиссия, ₽"
                        required={values.migrantLicensePaymentEnabled}
                        disabled={!isAccess || !values.migrantLicensePaymentEnabled}
                        name="migrantLicensePaymentCommission"
                        value={values.migrantLicensePaymentCommission}
                        onChange={onChange}
                        error={
                            touched?.migrantLicensePaymentCommission &&
                            errors?.migrantLicensePaymentCommission
                        }
                    />
                </div>
            </div>
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled
                name="insuranceAvailable"
                onChange={onChange}
                checked={values.insuranceAvailable}
                label="Страхование"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.withoutContract}
                name="smzRegistryPaymentsAvailable"
                onChange={onChange}
                checked={values.smzRegistryPaymentsAvailable}
                label="Оплаты реестрами (НПД)"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="civilRegistryPaymentsAvailable"
                onChange={onChange}
                checked={values.civilRegistryPaymentsAvailable}
                label="Оплаты реестрами (НДФЛ)"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="individualRegistryPaymentsAvailable"
                onChange={onChange}
                checked={values.individualRegistryPaymentsAvailable}
                label="Оплаты реестрами (ИП)"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.withoutContract}
                name="publishOrdersOnWebsite"
                onChange={onChange}
                checked={values.publishOrdersOnWebsite}
                label="Публиковать заказы на сайте"
            />
            <NmCheckboxV2
                disabled={!isAccess}
                name="marketplaceClient"
                onChange={onChange}
                className="mb-3 mb-md-4"
                checked={values.marketplaceClient}
                label="Маркетплейсовый заказчик"
                isVisibleTooltip={true}
                tooltip={
                    <HelpTooltip
                        info
                        hover
                        text={"При включении настройки \"Маркетплейсовый заказчик\" заказы данной компании будут находиться выше в поиске в МП и на сайте"}
                        position="bottom"
                    />
                }
            />
            <NmCheckboxV2
                disabled={!isAccess}
                name="contractorPushNotificationsAllowed"
                onChange={onChange}
                className="mb-3 mb-md-4"
                checked={values.contractorPushNotificationsAllowed}
                label="Рассылка уведомлений исполнителям от компании"
                isVisibleTooltip={true}
                tooltip={
                    <HelpTooltip
                        info
                        hover
                        text="Компании будет доступна рассылка уведомлений для исполнителей"
                        position="bottom"
                    />
                }
            />
            <ClientSettingsAdditionalModulesResourcesAccess
                values={values}
                onChange={onChange}
                isAccess={isAccess}
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.withoutContract}
                name="isRecruitmentAvailable"
                onChange={onChange}
                checked={values.isRecruitmentAvailable}
                label="Рекрутмент"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || !values.isRecruitmentAvailable || values.withoutContract}
                name="recruitmentHHPolitenessIndex"
                onChange={onChange}
                checked={values.recruitmentHHPolitenessIndex}
                label="Индекс вежливости hh"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!values.isRecruitmentAvailable}
                name="restrictUpdateFunnelToRecruiters"
                onChange={onChange}
                checked={values.restrictUpdateFunnelToRecruiters}
                label="Ограничить изменение и добавление воронок рекрутерами"
                tooltipOutline={false}
                tooltipText="При активации настройки рекрутеры не смогут управлять статусами и воронками статусов"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.withoutContract}
                name="crowdTasksAvailable"
                onChange={onChange}
                checked={values.crowdTasksAvailable}
                label="Задания"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.withoutContract}
                name="registryPaymentsClientInnCheckRequired"
                onChange={onChange}
                checked={values.registryPaymentsClientInnCheckRequired}
                label="Проверка ИНН компании в реестрах на оплату"
            />
            <div className="row align-items-start gx-4 mb-3 mb-md-4">
                <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                    <NmCheckboxV2
                        disabled={!isAccess}
                        className="mt-0 mt-xl-5"
                        name="monitoringRisksFnsAvailable"
                        onChange={onChange}
                        checked={values.monitoringRisksFnsAvailable}
                        label="Мониторинг рисков"
                    />
                </div>
                <div className="col-16 col-xl-8">
                    <NmInputV2
                        size="xl"
                        label="Тариф, ₽"
                        required={values.monitoringRisksFnsAvailable}
                        disabled={!isAccess || !values.monitoringRisksFnsAvailable}
                        name="monitoringRisksFnsTariff"
                        value={values.monitoringRisksFnsTariff}
                        onChange={onChange}
                        error={
                            touched?.monitoringRisksFnsTariff &&
                            errors?.monitoringRisksFnsTariff
                        }
                    />
                </div>
            </div>
            <div className="row align-items-start gx-4 mb-3 mb-md-4">
                <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                    <ClientWorkTypeDropdown
                        value={values.clientWorkType}
                        onChange={onChange}
                        error={
                            touched?.clientWorkType && errors?.clientWorkType ?
                                errors.clientWorkType :
                                undefined
                        }
                    />
                </div>
                <div className="col-16 col-xl-8">
                    <NmInputV2
                        size="xl"
                        label="Тариф КЭДО, ₽"
                        required={
                            [
                                CLIENT_WORK_TYPE.ONLY_KEDO,
                                CLIENT_WORK_TYPE.NAIMIX_KEDO,
                            ].includes(values.clientWorkType)
                        }
                        disabled={!isAccess}
                        name="clientWorkTypeTariff"
                        value={values.clientWorkTypeTariff}
                        onChange={onChange}
                        error={
                            touched?.clientWorkTypeTariff &&
                            errors?.clientWorkTypeTariff
                        }
                    />
                </div>
            </div>
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
