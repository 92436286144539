import {
    REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST, REGISTRY_PAYMENT_LOG_UPDATE_STORE,
} from "./actions";

export const getPageRegistryPaymentLog = (payload) => {
    return {
        type: REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateRegistryPaymentLogStore = (payload) => {
    return {
        type: REGISTRY_PAYMENT_LOG_UPDATE_STORE,
        payload,
    };
};