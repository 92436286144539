import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import ExtLink from "../../../components/ExtLink";
import NmTitle from "../../../components/NmTitle";
import Receipt from "../../../components/Receipt";
import TableDiv from "../../../components/TableDiv";
import InsuranceHistoryFilter from "./insurance-history-filter";
import InsuranceHistoryInfo from "./insurance-history-info";
import {
    Dimmer,
    Loader,
} from "semantic-ui-react";

import {formatLocalDate} from "../../../utils/dateFormat";
import {
    formatAmount,
    formatNumber,
} from "../../../utils/stringFormat";
import {
    handleDateFieldFilter,
    handleFieldFilter,
    isNullOrWhitespace,
    minutesToTextHourAndMinutes,
} from "../../../utils/stringHelper";

import {headersInsuranceList} from "../../../constants/headersTable";
import {LINK_ORDER_CARD} from "../../../constants/links";

import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {clientCardInfoProgressSelector, clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    clientProgressSelector,
} from "../../../ducks/client";
import {downloadDocument} from "../../../ducks/documents";
import {
    getInsuranceByClientId,
    getInsuranceHistoryListSelector,
    getInsuranceHistoryProgressSelector,
    getInsuranceHistoryTotalCountSelector,
    getInsuranceHistoryTotalPagesSelector,
    getInsuranceProgressSelector,
    getPageHistoryInsurance,     getReserveAmountSelector,
} from "../../../ducks/insurance";

import "./style.sass";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../constants/link-params";

class InsuranceHistory extends Component {
    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];

        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.clientId = clientId;

        this.state = {
            pageSize: 25,
            pageNum: 1,
            filter: {...this.defaultFilter},
        };
    }

    get defaultFilter() {
        return {
            fromDate: undefined,
            toDate: undefined,
            orderNum: "",
            contractorFullName: "",
            orderNameFilter: "",
        };
    }

    componentDidMount() {
        const {
            getInsuranceByClientId,
            getClientCardProperties,
        } = this.props;

        this.getPageHistoryInsurance();

        getInsuranceByClientId({
            clientId: this.clientId,
        });

        getClientCardProperties({clientId: this.clientId});
    }

    getPageHistoryInsurance = () => {
        const {
            getPageHistoryInsurance,
            client: {
                clientId,
            },
        } = this.props;

        const {
            pageSize,
            pageNum,
            filter: {
                fromDate,
                toDate,
                orderNum,
                contractorFullName,
                orderNameFilter,
            },
        } = this.state;

        getPageHistoryInsurance({
            pageSize,
            pageNum,
            clientId: clientId || this.clientId,
            fromDate: handleDateFieldFilter(fromDate),
            toDate: handleDateFieldFilter(toDate),
            orderNum: handleFieldFilter(orderNum),
            orderName: handleFieldFilter(orderNameFilter),
            fio: handleFieldFilter(contractorFullName),
        });
    };

    formatAmount = (amount) => {
        return isNullOrWhitespace(amount) ? "" : formatAmount(formatNumber(amount, 2));
    };

    renderOrderInfo({
        orderId,
        clientId,
        orderNum,
        orderName,
    }) {
        const linkToOrderCard = LINK_ORDER_CARD
            .replace(":orderId", orderId)
            .replace(":clientId", clientId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                to={linkToOrderCard}
                historyEnabled
            >
                {orderNum} 
                {" "}
                {orderName}
            </ExtLink>
        );
    }

    renderInsurancePolicy({link: downloadLink}) {
        const {t} = this.props;

        return (
            <a
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Receipt
                    text={t("client-insurance.document-type-policy")}
                />
            </a>
        );
    }

    renderPeriod({fromDate, toDate}) {
        const patternFormatDate = "dd.MM.yyyy";
        const patternFormatTime = "HH:mm";

        if (!fromDate && !toDate) {
            return null;
        }

        if (fromDate && !toDate) {
            return `${formatLocalDate(fromDate, patternFormatDate)} c ${formatLocalDate(fromDate, patternFormatTime)} по —`;
        }

        if (!fromDate && toDate) {
            return `${formatLocalDate(toDate, patternFormatDate)} c — по ${formatLocalDate(toDate, patternFormatTime)}`;
        }

        return `${formatLocalDate(fromDate, patternFormatDate)} c ${formatLocalDate(fromDate, patternFormatTime)} по ${formatLocalDate(toDate, patternFormatTime)}`;
    }

    mapTableData() {
        const {insuranceHistoryList = []} = this.props;

        const rows = insuranceHistoryList.map(item => ({
            ...item,
            period: this.renderPeriod(item),
            totalHours: minutesToTextHourAndMinutes(item.minutes),
            amount: this.formatAmount(item.amount),
            reserveAmount: this.formatAmount(item.reserveAmount),
            orderInfo: this.renderOrderInfo(item),
            insurancePolicy: this.renderInsurancePolicy(item),
        }));

        return {
            rows,
            headers: headersInsuranceList,
        };
    }

    renderMessage() {
        const {
            t,
            clientProperties: {
                insuranceAvailable,
            },
            progressClientProperties,
        } = this.props;

        return  !insuranceAvailable && !progressClientProperties ?
            <div className="insurance__header-message">
                <div style={{marginTop: "15px"}}>
                    <span className="insurance__history-message">
                        {t("client-insurance.history-message")}
                    </span>
                </div>
            </div> :
            null;
    }

    handleOnChangeFilter = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    };

    clearFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageNum: 1,
            filter: {...this.defaultFilter},
        }), this.getPageHistoryInsurance);
    };

    sendFilter = () => {
        this.setState(
            {
                pageNum: 1,
            },
            this.getPageHistoryInsurance,
        );
    };

    renderContent() {
        const {
            t,
            insuranceProgress,
            insuranceHistoryProgress,
            clientProgress,
            progressClientProperties,
        } = this.props;

        if (insuranceProgress || insuranceHistoryProgress || clientProgress || progressClientProperties) {
            return (
                <Dimmer
                    active
                    inverted
                >
                    <Loader content={t("loader.content")} />
                </Dimmer>
            );
        }

        return (
            <div className="insurance__history-table">
                <TableDiv
                    className="tr-clickable"
                    onSelectedRows={this.onSelectedRows}
                    onClickRow={this.handleClickRow}
                    tableData={this.mapTableData()}
                />
            </div>
        );
    }

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.getPageHistoryInsurance);
    };

    handleChangePageSize = pageSize => {
        this.setState({
            pageSize,
            pageNum: 1,
        },
        this.getPageHistoryInsurance,
        );
    };

    renderPagination() {
        const {
            pageSize,
            pageNum,
        } = this.state;

        const {
            totalPages,
            totalCount,
        } = this.props;

        return (
            <NmPagination
                className="insurance__pagination"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={this.handleChangePageSize}
                onChangePagination={this.handlePaginationChange}
            />
        );
    }

    render() {
        const {
            filter,
        } = this.state;

        const {
            reserveAmount,
            t,
            insuranceHistoryList,
        } = this.props;


        return (
            <div className="insurance">
                <div className="insurance__header">
                    <InsuranceHistoryInfo
                        formatAmount={this.formatAmount}
                        reserveAmount={reserveAmount}
                    />
                    {this.renderMessage()}
                </div>
                <div className="insurance__history-header">
                    <NmTitle
                        size="xl"
                    >
                        {t("client-insurance.history-title")}
                    </NmTitle>
                </div>
                <InsuranceHistoryFilter
                    onChange={this.handleOnChangeFilter}
                    clearFilter={this.clearFilter}
                    filter={filter}
                    sendFilter={this.sendFilter}
                />
                {
                    insuranceHistoryList?.length
                        ? this.renderContent()
                        : <NmEmptyPageV2 />
                }
                {this.renderPagination()}
            </div>
        );
    }
}


export default connect(
    state => ({
        client: clientCardInfoSelector(state),
        insuranceHistoryList: getInsuranceHistoryListSelector(state),
        reserveAmount: getReserveAmountSelector(state),
        insuranceProgress: getInsuranceProgressSelector(state),
        insuranceHistoryProgress: getInsuranceHistoryProgressSelector(state),
        clientProgress: clientProgressSelector(state),
        totalPages: getInsuranceHistoryTotalPagesSelector(state),
        totalCount: getInsuranceHistoryTotalCountSelector(state),
        progressClientProperties: clientCardInfoProgressSelector(state),
    }),
    {
        getPageHistoryInsurance,
        downloadDocument,
        getInsuranceByClientId,
        getClientCardProperties,
    },
)(withTranslation()(InsuranceHistory));