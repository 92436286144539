import {isEmpty} from "lodash";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS,
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS,
} from "../../../../constants/contractor";

export const getCardMode = (params) => {
    const {
        infoList = [],
        segment,
        isIndividualEntrepreneurStatusBlock,
        contractor,
    } = params;

    if (isIndividualEntrepreneurStatusBlock) {
        return {
            mode: getIndividualEntrepreneurCardMode(contractor.individualEntrepreneurStatus),
        };
    }

    const info = infoList.find(item => (item.segment === segment)) || {};
    if (!isEmpty(info)) {
        return {
            status: info.status,
            mode: getCardModeByStatusSegment(info.status),
            updateDateTime: info.updateDateTime,
            dataPresent: info.dataPresent,
        };
    }

    return {};
};

export const getCardModeByStatusSegment = (status) => {
    switch (status) {
        case CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT:
            return "gray";
        case CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK:
            return "yellow";
        case CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.DECLINED:
            return "red";
        case CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK_PASSED:
            return "green";
        default:
            return null;
    }
};

export const getIndividualEntrepreneurCardMode = (status) => {
    switch (status) {
        case CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED:
            return "gray";
        case CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID:
            return "red";
        case CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED:
            return "green";
        default:
            return null;
    }
};