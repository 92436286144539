import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {
    clearStoreContractorEventLog,
    getPageContractorEventLog,
} from "../../../../../ducks/bff/contractor-сard/event-log/actionCreators";

export function useContractorEventLogFetchList({filter = {}, pageSize, pageNum, contractorIdFilter}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearStoreContractorEventLog());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        dispatch(getPageContractorEventLog({
            pageNum,
            pageSize,
            contractorIdFilter,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}