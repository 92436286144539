import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    KEDO_DOCUMENTS_DELETE_DRAFT_ERROR,
    KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST,
    KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS,
    KEDO_DOCUMENTS_EXPORT_ERROR,
    KEDO_DOCUMENTS_EXPORT_REQUEST,
    KEDO_DOCUMENTS_EXPORT_SUCCESS,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS,
    KEDO_DOCUMENTS_GET_PAGE_ERROR,
    KEDO_DOCUMENTS_GET_PAGE_REQUEST,
    KEDO_DOCUMENTS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

// POST /bff/adm/clients/client-card/kedo/documents/getPage
// Получение страницы документов
const getKedoDocumentsPageSaga = function* ({payload}) {
    const {
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_DOCUMENTS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DOCUMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DOCUMENTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/documents/getHistoryPage
// Получение истории документа
const getKedoDocumentsHistoryPageSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/documents/export
// Инициализация экспорта
const exportKedoDocumentsSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DOCUMENTS_EXPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"КЭДО → Экспорт документов\"");

        yield put({
            type: KEDO_DOCUMENTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DOCUMENTS_EXPORT_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/kedo/documents/deleteDraft
// Удаление черновика
const deleteKedoDocumentsDraftSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.delete(url, {data: requestData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DOCUMENTS_DELETE_DRAFT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DOCUMENTS_DELETE_DRAFT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_DOCUMENTS_GET_PAGE_REQUEST, getKedoDocumentsPageSaga),
        takeEvery(KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST, getKedoDocumentsHistoryPageSaga),
        takeEvery(KEDO_DOCUMENTS_EXPORT_REQUEST, exportKedoDocumentsSaga),
        takeEvery(KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST, deleteKedoDocumentsDraftSaga),
    ]);
}