import {useState} from "react";
import {useDispatch} from "react-redux";

import useConfirm, {closeConfirmAction, onConfirmAction, openConfirmAction} from "../../../../../hooks/useConfirm";

import {deleteSpecialty} from "../../../../../ducks/bff/settings/directories/naimix/specialities/actionCreators";

export const useSpecialitiesAction = () => {
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const dispatch = useDispatch();

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const onOpenEdit = (editData) => {
        if (editData) {
            setEditData(editData);
        }

        setOpenEdit(true);
    };

    const onDelete = (id, onClose) => {
        dispatch(deleteSpecialty({
            id,
            onSuccess: () => {
                onClose();
            },
        }));
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({id}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы действительно хотите удалить данный вид деятельности из справочника?",
                ({onClose}) => onDelete(id, onClose),
            ),
        );
    };

    const onCancelConfirm = () => {
        dispatchConfirm(closeConfirmAction());
    };

    return {
        isOpenEdit,
        editData,
        isOpenConfirm,
        contentConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    };
};