import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {
    getRecruitmentVacancyCandidates,
    updateRecruitmentVacancyCandidatesStore,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {getRecruitmentVacancyCandidatesInitialState} from "../../../../../ducks/bff/recruitment/vacancyCandidates/reducer";
import {bffRecruitmentVacancyCandidateIdsSelector} from "../../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {
    fileStoreRecruitmentPageDataSelector,
    getFileStoreRecruitmentInitialState,
    getRecruitmentCandidateSmallThumbnails,
    updateFileStoreRecruitmentCandidateStore,
} from "../../../../../ducks/fileStoreRecruitment";

import {
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";

import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";

export const useRecruitmentCandidatesFetch = (params) => {
    const {
        clientId,
        $contentRef,
        setPageData,
        pageData,
    } = params;

    const dispatch = useDispatch();

    const candidateIds = useSelector(bffRecruitmentVacancyCandidateIdsSelector);
    const fileStorePageData = useSelector(fileStoreRecruitmentPageDataSelector);

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.manageCandidatesAdmin,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
            ],
        }));

        return () => {
            const fileStoreInitialState = getFileStoreRecruitmentInitialState();

            dispatch(updateFileStoreRecruitmentCandidateStore(fileStoreInitialState));

            const initialState = getRecruitmentVacancyCandidatesInitialState();

            dispatch(updateRecruitmentVacancyCandidatesStore(initialState));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(candidateIds) || isEqual(fileStorePageData.ids, candidateIds)) {
            return;
        }

        dispatch(getRecruitmentCandidateSmallThumbnails({
            ids: candidateIds,
        }));
    }, [candidateIds]);

    const fetchList = (filterData = {}) => {
        const {
            pageNum = 1,
            isLoadMore = true,
        } = filterData;

        if (!isLoadMore && pageData.pageNum !== 1) {
            setPageData({pageNum: 1});
        }

        dispatch(getRecruitmentVacancyCandidates({
            clientId,
            ...filterData,
            isLoadMore,
            email: filterData.email ? filterData.email : undefined,
            name: filterData.name ? filterData.name : undefined,
            phone: filterData.phone ? removePhoneMask(filterData.phone) : undefined,
            ageFrom: filterData.ageFrom ? +filterData.ageFrom : undefined,
            ageTo: filterData.ageTo ? +filterData.ageTo : undefined,
            dateFrom: getStartFilterDateWithTimeInUtc(filterData.dateFrom),
            dateTo: getEndFilterDateWithTimeInUtc(filterData.dateTo),
            pageNum,
            pageSize: 25,
            onSuccess: () => {
                if (!isLoadMore && $contentRef?.current) {
                    $contentRef.current.scrollTop = 0;
                }
            },
        }));
    };

    return {
        fetchList,
    };
};