import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const clientForemenRequestSelector = state => state.bff.clientForemenRequests;

export const clientForemenRequestsListSelector = createSelector(
    clientForemenRequestSelector,
    ({list}) => list,
);
export const clientForemenRequestsProgressListSelector = createSelector(
    clientForemenRequestSelector,
    ({progressList}) => progressList,
);
export const clientForemenRequestsTotalCountSelector = createSelector(
    clientForemenRequestSelector,
    ({totalCount}) => totalCount,
);
export const clientForemenRequestsTotalPagesSelector = createSelector(
    clientForemenRequestSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const clientForemenRequestsProgressUpdateSelector = createSelector(
    clientForemenRequestSelector,
    ({progressUpdate}) => progressUpdate,
);
export const clientForemenNewRequestsAndPaymentsCountSelector = createSelector(
    clientForemenRequestSelector,
    ({newRequestsAndPaymentsCount}) => newRequestsAndPaymentsCount,
);
