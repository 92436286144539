import {
    BFF_EXPORT_DOCUMENTS_ADD_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST,
    UPDATE_EXPORT_DOCUMENTS_STORE,
} from "./actions";

export const getPageExportDocuments = (payload) => {
    return {
        type: BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addExportDocuments = (payload) => {
    return {
        type: BFF_EXPORT_DOCUMENTS_ADD_REQUEST,
        payload,
    };
};

export const getLinkExportDocuments = (payload) => {
    return {
        type: BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST,
        payload,
    };
};

export const getProgressExportDocuments = (payload) => {
    return {
        type: BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST,
        payload,
    };
};

export const getDictExportDocumentType = (payload) => {
    return {
        type: BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST,
        payload,
    };
};

export const updateStoreExportDocuments = (payload) => {
    return {
        type: UPDATE_EXPORT_DOCUMENTS_STORE,
        payload,
    };
};