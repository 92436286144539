import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEqual} from "lodash";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";

import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";
import {
    crmSettingsTicketSelector,
    getCrmSettingTicket,
    setAllCrmSetting,
    setCrmTickedEnabledSetting,
} from "../../../../../ducks/crmSettings";

export const useCrmSettingsAction = ({}) => {
    const dispatch = useDispatch();

    const ticketSettings = useSelector(crmSettingsTicketSelector);

    const initialValues = useMemo(() => {
        return {
            ...ticketSettings,
        };
    }, [ticketSettings]);

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.activateSystemTickets,
                CLIENT_USER_RESTRICTIONS_VARIABLE.editParamSystemTickets,
            ],
        }));

        fetchSettings();
    }, []);

    const onChangeSystemTickets = (event, {checked}) => {
        dispatch(setCrmTickedEnabledSetting({
            value: checked,
            onSuccess: () => {
                setFieldValue("systemTicketsEnabled", checked);
            },
        }));
    };

    const fetchSettings = () => {
        dispatch(getCrmSettingTicket());
    };

    const onSubmit = () => {
        const {
            systemTicketsEnabled,
            ...reqData
        } = values;

        dispatch(setAllCrmSetting({
            ...reqData,
            onSuccess: () => {
                toastSuccess("Изменения сохранены");
                fetchSettings();
            },
        }));
    };

    const {
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
    });

    return {
        onChangeSystemTickets,
        values,
        setFieldValue,
        onSubmit,
        isChanges: !isEqual(ticketSettings, values),
    };
};