import {
    DIRECTORIES_BANKS_GET_SETTINGS_ERROR,
    DIRECTORIES_BANKS_GET_SETTINGS_REQUEST,
    DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS,
    DIRECTORIES_BANKS_SET_SETTINGS_ERROR,
    DIRECTORIES_BANKS_SET_SETTINGS_REQUEST,
    DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS,
    REFRESH_DIRECTORIES_SBP_BANKS_ERROR,
    REFRESH_DIRECTORIES_SBP_BANKS_REQUEST,
    REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS,
    SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR,
    SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST,
    SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS,
} from "./actions";

export const initialSettingsBanksBalancesPage = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    bankList: [],
    accessPage: false,
    error: null,
};

const initialState = {
    pageData: {},
    sbpPageData: {},
    list: [],
    sbpList: [],
    totalCount: 0,
    sbpTotalCount: 0,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    card: {},
    progress: false,
    progressAction: false,
    contractorWalletWithdrawAvailable: false,
    contractorCommissionWithdrawal: {},
    balancesPage: initialSettingsBanksBalancesPage,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST:
            return {
                ...state,
                sbpPageData: payload,
                progress: true,
            };
        case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST:
            return {
                ...state,
                logPageData: payload,
                logProgress: true,
            };
        case SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS:
            const {totalCount, results} = payload;

            return {
                ...state,
                totalCount,
                list: results,
                progress: false,
            };
        case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS:
            return {
                ...state,
                sbpTotalCount: payload.totalCount,
                sbpList: payload.results,
                progress: false,
            };
        case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS:
            return {
                ...state,
                logTotalCount: payload.totalCount,
                logList: payload.results,
                logProgress: false,
            };
        case SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR:
        case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST:
        case DIRECTORIES_BANKS_GET_SETTINGS_REQUEST:
        case DIRECTORIES_BANKS_SET_SETTINGS_REQUEST:
        case REFRESH_DIRECTORIES_SBP_BANKS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS:
        case DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS:
        case REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS:
        case SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR:
        case DIRECTORIES_BANKS_GET_SETTINGS_ERROR:
        case DIRECTORIES_BANKS_SET_SETTINGS_ERROR:
        case REFRESH_DIRECTORIES_SBP_BANKS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS:
            return {
                ...state,
                progressAction: false,
                [payload.fieldName]: payload.value,
            };
        case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR:
            return {
                ...state,
                logProgress: false,
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    accessPage: payload,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    pageData: payload,
                    progress: true,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    progress: false,
                    list: payload.results || [],
                    totalCount: payload.totalCount || 0,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    progress: false,
                    error: payload,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    logPageData: payload,
                    logProgress: true,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    logProgress: false,
                    logList: payload.results || [],
                    logTotalCount: payload.totalCount || 0,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    logProgress: false,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    bankList: payload || [],
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST:
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    progressAction: true,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS:
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR:
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS:
        case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR:
            return {
                ...state,
                balancesPage: {
                    ...state.balancesPage,
                    progressAction: false,
                },
            };
        case SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};