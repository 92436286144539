import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import Task from "../../../../../components/NmTask";
import NmTitle from "../../../../../components/NmTitle";
import TableDiv from "../../../../../components/TableDiv";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";

import {usePagination} from "../../../../../hooks/usePagination";
import {useImportTasks} from "../hooks/useImportTasks";
import {useClientsActions} from "./hooks/useClientsActions";
import {useClientsFilter} from "./hooks/useClientsFilter";
import {useGetListClients} from "./hooks/useGetListClients";

import bem from "../../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {groupClientsListHeaders} from "../../../../../constants/headersTable";
import {LINK_CLIENT_INFO} from "../../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../../constants/roles";

import {
    settingsClientGroupsCardClientsListSelector,
    settingsClientGroupsCardClientsProgressSelector,
    settingsClientGroupsCardClientsTotalCountSelector,
    settingsClientGroupsCardClientsTotalPagesSelector,
} from "../../../../../ducks/bff/settings/client-groups/card/clients/selectors";
import {settingsClientGroupsCardInfoSelector} from "../../../../../ducks/bff/settings/client-groups/card/selectors";
import {tasksImportClientGroupSelector} from "../../../../../ducks/job";

import PropTypes from "prop-types";

import "./style.sass";

const ClientsList = (props) => {
    const {
        groupId,
        isAccessEdit,
    } = props;

    const [block, element] = bem("clients");

    const list = useSelector(settingsClientGroupsCardClientsListSelector);
    const totalPage = useSelector(settingsClientGroupsCardClientsTotalPagesSelector);
    const totalCount = useSelector(settingsClientGroupsCardClientsTotalCountSelector);
    const loading = useSelector(settingsClientGroupsCardClientsProgressSelector);
    const tasksImportClientGroup = useSelector(tasksImportClientGroupSelector);
    const {groupName} = useSelector(settingsClientGroupsCardInfoSelector);
    const {importProgress} = useImportTasks(tasksImportClientGroup, groupId);
    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {filterDto, setFilterDto} = useClientsFilter();

    const {
        fetchList,
    } = useGetListClients({filter: filterDto, pageSize, pageNum, groupId, importProgress});

    const {
        dataModal,
        dataConfirm,
        onCloseModal,
        onClickAddClient,
        onClickDeleteClient,
    } = useClientsActions({
        groupId,
        fetchList,
    });

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = (filter) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const renderModalWindow = () => {
        const {
            isShow,
            title,
            content,
            submit,
            formId,
        } = dataModal;

        if (!isShow) {
            return null;
        }

        return (
            <NmModal
                onClose={onCloseModal}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        formId={formId}
                        submit={!formId && submit}
                        submitBtnContent="Подтвердить"
                        onClose={onCloseModal}
                        cancelBtnContent="Отменить"
                    />
                }
            >
                {content}
            </NmModal>
        );
    };

    const renderConfirmWindow = () => {
        const {
            isShow,
            ...otherProps
        } = dataConfirm;

        if (!isShow) {
            return null;
        }

        return (
            <NmConfirmV2
                {...otherProps}
            />
        );
    };

    const renderActions = (item) => {
        if (!isAccessEdit || [NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        return (
            <div className={element("table-action")}>
                <CloseIcon
                    color="grey"
                    className={element("table-action-icon")}
                    onClick={() => onClickDeleteClient(item, groupName)}
                />
            </div>
        );
    };

    const filter = [
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите наименование заказчика",
                    label: "Заказчик",
                    name: "clientNameFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите ИНН заказчика",
                    maskChar: null,
                    mask: "999999999999999999999999999999999999999999999999",
                    label: "ИНН",
                    name: "innFilter",
                },
            ],
        },

    ];

    const mapTableData = () => {
        const headers = groupClientsListHeaders;

        const rows = list.map(item => {
            const {clientId, clientName} = item;
            const linkClient = LINK_CLIENT_INFO.replace(":clientId", clientId);
            return {
                ...item,
                clientName: <Link to={linkClient}>
                    {clientName}
                </Link>,
                delete: renderActions(item),
            };
        });

        return {
            rows,
            headers,
        };
    };

    return (
        <NmPage
            headerClassName={!isAccessEdit && "justify-content-end"}
            controls={
                isAccessEdit &&
                ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={onClickAddClient}
                >
                    Добавить заказчика
                </NmButton>
            }
            onChangePageSize={onChangePageSize}
            className={block()}
            onPaginationChange={onPaginationChange}
            totalPages={totalPage}
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            isLoaded={loading}
            filtersBase={
                <Filter
                    onSubmit={submitFilter}
                    clearFilter={clearFilter}
                    filters={filter}
                    isInitStateEqualEmpty={false}
                />
            }
            typeFilter="vertical"
        >
            <Task />
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                    />
                    :
                    <TableDiv
                        className={element("table")}
                        tableData={mapTableData()}
                    />
            }
            {renderModalWindow()}
            {renderConfirmWindow()}
        </NmPage>
    );
};

ClientsList.propTypes = {
    groupId: PropTypes.string,
};

export default ClientsList;
