import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import NmBadge from "../../../../components/NmBadge";
import ContractorWorkTypeEditModal from "./components/contractor-work-type-edit-modal";

import {updateContractorAdditionalSpecialities} from "../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {contractorAdditionalSpecialitySelector} from "../../../../ducks/bff/contractor-сard/profile/selectors";
import {specialitiesAllListV2Selector} from "../../../../ducks/speciality";

export const ADDITIONAL_SPECIALITIES_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const OPTIONS = [
    {
        key: ADDITIONAL_SPECIALITIES_OPTION.EDIT,
        text: "Редактировать",
        value: ADDITIONAL_SPECIALITIES_OPTION.EDIT,
    },
    {
        key: ADDITIONAL_SPECIALITIES_OPTION.REMOVE,
        text: "Удалить",
        value: ADDITIONAL_SPECIALITIES_OPTION.REMOVE,
    },
];

export default function ContractorWorkType(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const {
        additionalSpecialityPresent,
        additionalSpecialities,
    } = useSelector(contractorAdditionalSpecialitySelector);
    const specialitiesList = useSelector(specialitiesAllListV2Selector);

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [confirmData, setConfirmData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const onClickAction = ({value}) => {
        if (value === ADDITIONAL_SPECIALITIES_OPTION.EDIT) {
            toggleEditModal({
                specialityIds: specialitiesList.filter(it => additionalSpecialities.includes(it.value)).map(it => it.id),
                hasAdditionalSpecialities: !additionalSpecialityPresent,
            });
        }

        if (value === ADDITIONAL_SPECIALITIES_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: handleDelete,
                confirmContent: "Вы уверены что хотите удалить сведения?",
            });
        }
    };

    const handleDelete = () => {
        dispatch(updateContractorAdditionalSpecialities({
            contractorId,
            specialityIds: [],
            hasAdditionalSpecialities: true,
            getResult: () => {
                getProfile();
                handleCloseConfirm();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    function mapContent() {
        if (!additionalSpecialities?.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return additionalSpecialities.map(item => {
            return (
                <NmBadge
                    key={item}
                    maxContent
                    className="ms-xl-1 mt-xl-1 mb-2"
                    mod="gray"
                    text={item}
                />
            );
        });
    }

    return (
        <>
            {isShowEditModal && <ContractorWorkTypeEditModal
                editData={editData}
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                contractorId={contractorId}
            />}
            {!isEmpty(confirmData) && <NmConfirmV2
                onCancel={handleCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Продолжить"
                cancelButton="Отменить"
                content={confirmContent}
                size="sm"
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Дополнительные виды деятельности"
                onClickAdd={!additionalSpecialities?.length ? () => onClickAction({value: ADDITIONAL_SPECIALITIES_OPTION.EDIT}) : null}
                options={additionalSpecialities?.length ? OPTIONS : null}
                onClickItem={onClickAction}
            >
                <div className="d-flex flex-wrap">
                    {mapContent()}
                </div>
            </NmMiniInfoCard>
        </>
    );
}