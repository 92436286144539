import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getTariffScaleList,
    updateTariffScaleStore,
} from "../../../../../ducks/bff/settings/directories/naimix/tariff-grid/actionCreators";

export const useTariffGridFetch = ({filterData, pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(updateTariffScaleStore({list: []}));
        };
    }, []);

    const fetchList = () => {
        dispatch(getTariffScaleList({
            ...filterData,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};