import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const GET_HH_AREAS_REQUEST = "GET_HH_AREAS_REQUEST";
const GET_HH_AREAS_SUCCESS = "GET_HH_AREAS_SUCCESS";
const GET_HH_AREAS_ERROR = "GET_HH_AREAS_ERROR";

const GET_HH_DICTIONARIES_REQUEST = "GET_HH_DICTIONARIES_REQUEST";
const GET_HH_DICTIONARIES_SUCCESS = "GET_HH_DICTIONARIES_SUCCESS";
const GET_HH_DICTIONARIES_ERROR = "GET_HH_DICTIONARIES_ERROR";

const GET_SUPER_JOB_SPECIALTIES_REQUEST = "GET_SUPER_JOB_SPECIALTIES_REQUEST";
const GET_SUPER_JOB_SPECIALTIES_SUCCESS = "GET_SUPER_JOB_SPECIALTIES_SUCCESS";
const GET_SUPER_JOB_SPECIALTIES_ERROR = "GET_SUPER_JOB_SPECIALTIES_ERROR";

const GET_SUPER_JOB_TOWNS_REQUEST = "GET_SUPER_JOB_TOWNS_REQUEST";
const GET_SUPER_JOB_TOWNS_SUCCESS = "GET_SUPER_JOB_TOWNS_SUCCESS";
const GET_SUPER_JOB_TOWNS_ERROR = "GET_SUPER_JOB_TOWNS_ERROR";

const GET_SUPER_JOB_REFERENCES_REQUEST = "GET_SUPER_JOB_REFERENCES_REQUEST";
const GET_SUPER_JOB_REFERENCES_SUCCESS = "GET_SUPER_JOB_REFERENCES_SUCCESS";
const GET_SUPER_JOB_REFERENCES_ERROR = "GET_SUPER_JOB_REFERENCES_ERROR";

const UPDATE_EXTERNAL_JOB_BOARDS_STORE = "UPDATE_EXTERNAL_JOB_BOARDS_STORE";

//*  INITIAL STATE  *//

export const getExternalJobBoardsInitialState = () => {
    return {
        hhAreas: [],
        hhExperienceDict: [],
        hhScheduleDict: [],
        hhBillingTypeDict: [],
        hhVacancyTypeDict: [],
        superJobSpecialties: [],
        superJobTowns: [],
        superJobExperienceDict: {},
        superJobTypeOfWorkDict: {},
    };
};

export const initialState = getExternalJobBoardsInitialState();

//*  REDUCER  *//

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_HH_AREAS_SUCCESS: {
            return {
                ...state,
                hhAreas: payload.areas,
            };
        }
        case GET_HH_DICTIONARIES_SUCCESS: {
            return {
                ...state,
                hhExperienceDict: payload.experience,
                hhScheduleDict: payload.schedule,
                hhBillingTypeDict: payload.vacancy_billing_type,
                hhVacancyTypeDict: payload.vacancy_type,
            };
        }
        case GET_SUPER_JOB_SPECIALTIES_SUCCESS: {
            return {
                ...state,
                superJobSpecialties: payload,
            };
        }
        case GET_SUPER_JOB_TOWNS_SUCCESS: {
            return {
                ...state,
                superJobTowns: payload.objects,
            };
        }
        case GET_SUPER_JOB_REFERENCES_SUCCESS: {
            return {
                ...state,
                superJobExperienceDict: payload.experience,
                superJobTypeOfWorkDict: payload.type_of_work,
            };
        }
        case UPDATE_EXTERNAL_JOB_BOARDS_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getHeadHunterAreas() {
    return {
        type: GET_HH_AREAS_REQUEST,
    };
}

export function getHeadHunterDictionaries() {
    return {
        type: GET_HH_DICTIONARIES_REQUEST,
    };
}

export function getSuperJobSpecialties() {
    return {
        type: GET_SUPER_JOB_SPECIALTIES_REQUEST,
    };
}

export function getSuperJobTowns() {
    return {
        type: GET_SUPER_JOB_TOWNS_REQUEST,
    };
}

export function getSuperJobReferences() {
    return {
        type: GET_SUPER_JOB_REFERENCES_REQUEST,
    };
}

export function updateExternalJobBoardsStore(payload) {
    return {
        type: UPDATE_EXTERNAL_JOB_BOARDS_STORE,
        payload,
    };
}

//*  SELECTORS  *//

export const externalJobBoardsSelector = ({externalJobBoards}) => externalJobBoards;
export const externalJobBoardsHhAreasOptionsSelector = createSelector(externalJobBoardsSelector, ({hhAreas}) => {
    let _hhAreas = [];

    hhAreas.forEach((item) => {
        if (item.areas.length) {
            _hhAreas = _hhAreas.concat(item.areas);

            return;
        }

        _hhAreas.push(item);
    });

    return _hhAreas.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
});
export const externalJobBoardsHhExperienceDictSelector = createSelector(externalJobBoardsSelector, ({hhExperienceDict}) => hhExperienceDict);
export const externalJobBoardsHhScheduleDictSelector = createSelector(externalJobBoardsSelector, ({hhScheduleDict}) => hhScheduleDict);
export const externalJobBoardsHhBillingTypeDictSelector = createSelector(externalJobBoardsSelector, ({hhBillingTypeDict}) => hhBillingTypeDict);
export const externalJobBoardsHhVacancyTypeDictSelector = createSelector(externalJobBoardsSelector, ({hhVacancyTypeDict}) => hhVacancyTypeDict);
export const externalJobBoardsSuperJobSpecialtiesOptionsSelector = createSelector(externalJobBoardsSelector, ({superJobSpecialties}) => {
    const _superJobSpecialties = superJobSpecialties.map(({positions = []}) => positions).flat();

    return _superJobSpecialties.map(item => ({
        key: item.key,
        value: item.key,
        text: item.title_rus,
    }));
});
export const externalJobBoardsSuperJobTownsOptionsSelector = createSelector(externalJobBoardsSelector, ({superJobTowns}) => {
    return superJobTowns.map(item => ({
        key: item.id,
        value: item.id,
        text: item.title,
    }));
});
export const externalJobBoardsSuperJobExperienceDictSelector = createSelector(externalJobBoardsSelector, ({superJobExperienceDict}) => superJobExperienceDict);
export const externalJobBoardsSuperJobTypeOfWorkSelector = createSelector(externalJobBoardsSelector, ({superJobTypeOfWorkDict}) => superJobTypeOfWorkDict);

//*  SAGA  *//

export const getHeadHunterAreasSaga = function* () {
    try {
        const result = yield request.get("https://api.hh.ru/areas/113");
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HH_AREAS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_HH_AREAS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_HH_AREAS_ERROR,
            payload: error,
        });
    }
};

export const getHeadHunterDictionariesSaga = function* () {
    try {
        const result = yield request.get("https://api.hh.ru/dictionaries");
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HH_DICTIONARIES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_HH_DICTIONARIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_HH_DICTIONARIES_ERROR,
            payload: error,
        });
    }
};

export const getSuperJobSpecialtiesSaga = function* () {
    try {
        const result = yield request.get("https://api.superjob.ru/2.0/catalogues/");
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUPER_JOB_SPECIALTIES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_SUPER_JOB_SPECIALTIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SUPER_JOB_SPECIALTIES_ERROR,
            payload: error,
        });
    }
};

export const getSuperJobTownsSaga = function* () {
    try {
        const result = yield request.get("https://api.superjob.ru/2.0/towns/?all=1");
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUPER_JOB_TOWNS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_SUPER_JOB_TOWNS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SUPER_JOB_TOWNS_ERROR,
            payload: error,
        });
    }
};

export const getSuperJobReferencesSaga = function* () {
    try {
        const result = yield request.get("https://api.superjob.ru/2.0/references/");
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUPER_JOB_REFERENCES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_SUPER_JOB_REFERENCES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SUPER_JOB_REFERENCES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_HH_AREAS_REQUEST, getHeadHunterAreasSaga),
        takeEvery(GET_HH_DICTIONARIES_REQUEST, getHeadHunterDictionariesSaga),
        takeEvery(GET_SUPER_JOB_SPECIALTIES_REQUEST, getSuperJobSpecialtiesSaga),
        takeEvery(GET_SUPER_JOB_TOWNS_REQUEST, getSuperJobTownsSaga),
        takeEvery(GET_SUPER_JOB_REFERENCES_REQUEST, getSuperJobReferencesSaga),
    ]);
}