import {useState} from "react";
import {useDispatch} from "react-redux";

import {toastError} from "../../../../../../../utils/toastHelper";

import {addContractorPortfolioFile} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export default function useCreateOrUpdatePortfolio({contractorId, onClose, getProfile}) {
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();


    const submit = () => {
        const formData = new FormData();

        formData.append("file", files[0]);
        formData.append("contractorId", contractorId);

        dispatch(addContractorPortfolioFile({
            formData,
            getResult: ({errorMessage}) => {
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }
                getProfile();
                onClose();
            },
        }));
    };

    return {
        submit,
        files,
        setFiles,
    };
}