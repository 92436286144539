import {useEffect, useReducer} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {COMPANY_SAMPLE_TYPE, UserEditModalTypeFormErrorType, UserType} from "../edit-modal";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";
import mapUserFormToRequestData from "../utils/mapUserFormToRequestData";

import {CLIENT_MEMBER_FIELD_NAME} from "../../../../constants/clientList";
import {NM_ADMIN_INFO} from "../../../../constants/contractorInfo";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER, NM_OPERATOR, NM_PARTNER} from "../../../../constants/roles";
import {requiredMessage, settingsMemberRule, settingsMemberWithPassRule} from "../../../../constants/validationRules";

import {
    addSettingsEmployees,
    generatePasswordSettingsEmployees,
    updateSettingsEmployees,
} from "../../../../ducks/bff/settings/employees/actionCreators";
import {getPromotionForPartner} from "../../../../ducks/promocode";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../../constants/link-params";

export type PasswordFormType = {
    password: string,
    repeatPassword: string
}

type State = {
    formError: UserEditModalTypeFormErrorType,
    form: UserType,
    isVisibleForm: boolean,
    type: string,
    passwordForm: PasswordFormType,
    isEditPassword: boolean
}

export const initialUserForm = {
    clientId: "",
    lastName: "",
    firstName: "",
    patronymic: "",
    password: "",
    position: "",
    phone: "",
    login: "",
    role: "",
    roleId: "",
    email: "",
    fullName: "",
    inn: "",
    clientUserId: "",
    partnerPromotionClientIdList: undefined,
    partnerPromotionContractorIdList: undefined,
    sendPassword: false,
};

const initialState = {
    formError: {},
    form: initialUserForm,
    isVisibleForm: false,
    type: "",
    passwordForm: {
        password: "",
        repeatPassword: "",
    },
    isEditPassword: true,
};
type FormChangeType = { fieldFormName: string, value: string | string[] | number | Date | boolean }

enum ActionType {
    changeForm = "changeForm",
    setFormError = "setFormError",
    changeVisibleForm = "changeVisibleForm",
    resetForm = "resetForm",
    submit = "submit",
    changePasswordForm = "changePasswordForm",
    resetPasswordForm = "resetPasswordForm",
    validatePassword = "validatePassword",
    setFullForm = "setFullForm",
    editPassword = "editPassword",
    resetEditPassword = "resetEditPassword"
}

type ChangeFormActionType = {
    type: ActionType.changeForm,
    form: FormChangeType
}

type EditPasswordActionType = {
    type: ActionType.editPassword,
}

type SetFullFormActionType = {
    type: ActionType.setFullForm,
    userForm: UserType
}

type SetFormErrorActionType = {
    type: ActionType.setFormError,
    formError: UserEditModalTypeFormErrorType
}

type changeVisibleActionType = {
    type: ActionType.changeVisibleForm,
}

type resetPasswordFormActionType = {
    type: ActionType.resetPasswordForm,
}

type validateActionType = {
    type: ActionType.validatePassword,
}

type resetActionType = {
    type: ActionType.resetForm,
}

type submitFormActionType = {
    type: ActionType.submit
}

type PasswordFormActionType = {
    type: ActionType.changePasswordForm,
    form: FormChangeType
}

type ResetEditPasswordActionType = {
    type: ActionType.resetEditPassword,
}

type Action = ChangeFormActionType |
    SetFormErrorActionType |
    changeVisibleActionType |
    resetActionType |
    submitFormActionType |
    PasswordFormActionType |
    validateActionType |
    resetPasswordFormActionType |
    SetFullFormActionType |
    EditPasswordActionType |
    ResetEditPasswordActionType;

export function changeFormAction(form: FormChangeType): ChangeFormActionType {
    return {
        type: ActionType.changeForm,
        form,
    };
}

export function toggleEditPasswordAction(): EditPasswordActionType {
    return {
        type: ActionType.editPassword,
    };
}

export function setFullFormAction(userForm: UserType): SetFullFormActionType {
    return {
        type: ActionType.setFullForm,
        userForm,
    };
}


export function resetPasswordFormAction(): resetPasswordFormActionType {
    return {
        type: ActionType.resetPasswordForm,
    };
}

export function changePasswordFormAction(form: FormChangeType): PasswordFormActionType {
    return {
        type: ActionType.changePasswordForm,
        form,
    };
}

export function submitFormAction(): submitFormActionType {
    return {
        type: ActionType.submit,
    };
}

export function setFormErrorAction(formError: UserEditModalTypeFormErrorType): SetFormErrorActionType {
    return {
        type: ActionType.setFormError,
        formError,
    };
}

export function changeVisibleForm(): changeVisibleActionType {
    return {
        type: ActionType.changeVisibleForm,
    };
}

export function resetForm(): resetActionType {
    return {
        type: ActionType.resetForm,
    };
}

export function validatePassword(): validateActionType {
    return {
        type: ActionType.validatePassword,
    };
}

export function resetEditPasswordAction(): ResetEditPasswordActionType {
    return {
        type: ActionType.resetEditPassword,
    };
}

function reducerUserForm(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.changeForm:
            return {
                ...state,
                type: action.type,
                form: {
                    ...state.form,
                    [action.form.fieldFormName]: action.form.value,
                },
            };
        case ActionType.setFormError:
            return {
                ...state,
                formError: {
                    ...action.formError,
                },
            };
        case ActionType.changeVisibleForm:
            return {
                ...state,
                type: action.type,
                isVisibleForm: !state.isVisibleForm,
            };
        case ActionType.submit:
            return {
                ...state,
                type: action.type,
            };
        case ActionType.editPassword:
            return {
                ...state,
                type: action.type,
                isEditPassword: !state.isEditPassword,
            };
        case ActionType.resetEditPassword:
            return {
                ...state,
                type: action.type,
                isEditPassword: true,
            };
        case ActionType.setFullForm:
            return {
                ...state,
                type: action.type,
                form: action.userForm,
            };
        case ActionType.changePasswordForm:
            return {
                ...state,
                type: action.type,
                passwordForm: {
                    ...state.passwordForm,
                    [action.form.fieldFormName]: action.form.value,
                },
            };
        case ActionType.resetPasswordForm:
            return {
                ...state,
                type: action.type,
                passwordForm: {
                    password: "",
                    repeatPassword: "",
                },
            };
        case ActionType.validatePassword:
            return {
                ...state,
                type: action.type,
            };
        case ActionType.resetForm:
            return {
                ...state,
                type: action.type,
                form: initialUserForm,
            };
        default:
            return state;

    }
}

enum PartnerTypeEnum {
    CONTRACTORS = "CONTRACTORS",
    CLIENTS = "CLIENTS"
}

export default function useUserFormEdit(subpage: string, fetchList: Function, clientId: string = NM_ADMIN_INFO.ID): [UserType, UserEditModalTypeFormErrorType, Function, boolean, PasswordFormType, boolean] {
    const [state, dispatch] = useReducer(reducerUserForm, initialState);
    const {
        form,
        formError,
        isVisibleForm,
        type,
        passwordForm,
        isEditPassword,
    } = state;

    const dispatchRedux = useDispatch();

    useEffect(() => {
        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(ls(USER_ROLE) ?? "")) {
            return;
        }
        getPromotion(PartnerTypeEnum.CLIENTS);
        getPromotion(PartnerTypeEnum.CONTRACTORS);
    }, []);

    useEffect(() => {
        if (!clientId || !isVisibleForm) {
            return;
        }
        const payload: FormChangeType = {
            fieldFormName: CLIENT_MEMBER_FIELD_NAME.CLIENT_ID,
            value: clientId,
        };

        dispatch(changeFormAction(payload));
    }, [isVisibleForm, clientId]);

    useEffect(() => {
        if (!isVisibleForm) {
            dispatch(resetPasswordFormAction());
            dispatch(resetForm());
            dispatch(setFormErrorAction({}));
            form.clientUserId && dispatch(toggleEditPasswordAction());
            dispatch(resetEditPasswordAction());
        }

    }, [isVisibleForm]);

    useEffect(() => {
        if (isVisibleForm && form.clientUserId) {
            dispatch(toggleEditPasswordAction());
        }
    }, [form.clientUserId, isVisibleForm]);

    useEffect(() => {
        if (form.clientUserId && !isEditPassword) {
            dispatch(resetPasswordFormAction());
        }
    }, [isEditPassword, form.clientUserId]);

    useEffect(() => {
        if (type === ActionType.submit) {
            submitForm();
            return;
        }


        if (type === ActionType.validatePassword) {
            dispatch(setFormErrorAction({...formError, ...validationRepeatPassword()}));
            return;
        }

    }, [type]);


    const submitForm = () => {
        if (!validationForm()) {
            return;
        }

        const requestData = mapUserFormToRequestData({
            ...form,
            availableClients: [NM_MANAGER, NM_COORDINATOR].includes(form.role) && form.sampleType === COMPANY_SAMPLE_TYPE.MULTIPLE ? form.availableClients : undefined,
            canManageAllClients: [NM_MANAGER, NM_COORDINATOR].includes(form.role) && form.sampleType === COMPANY_SAMPLE_TYPE.ALL,
            password: isNullOrWhitespace(passwordForm.password) ? undefined : passwordForm.password,
            role: subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK ? NM_PARTNER : form.role,
            sampleType: undefined,
        });

        const method = form.clientUserId ? updateSettingsEmployees : addSettingsEmployees;

        if (form.clientUserId && form.sendPassword) {
            dispatchRedux(generatePasswordSettingsEmployees({
                clientId,
                clientUserId: form.clientUserId,
            }));
        }

        dispatchRedux(method(
            {
                ...requestData,
                onSuccess: () => {
                    dispatch(changeVisibleForm());
                    dispatch(resetForm());
                    fetchList();
                },
            },
        ));
    };

    function validationForm() {
        const {password} = passwordForm;

        let rule: any = settingsMemberWithPassRule;

        if ((form.clientUserId && (password === "" || password === undefined)) || form.sendPassword) {
            rule = settingsMemberRule;
        }

        if (subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK) {
            rule = {
                ...rule,
                position: undefined,
                role: undefined,
            };
        }

        const validatedForm = {
            ...form,
            phone: removePhoneMask(form.phone),
            password: passwordForm.password,
        };

        let _formError = validate(validatedForm, rule, "");

        if (subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK && isEmpty(form.partnerPromotionClientIdList) && isEmpty(form.partnerPromotionContractorIdList)) {
            const textError = "Необходимо указать хотя бы один канал привлечения для компаний или исполнителей";
            _formError = {
                ..._formError,
                partnerPromotionClientIdList: textError,
                partnerPromotionContractorIdList: textError,
            };
        }

        if (form.role === NM_OPERATOR) {
            if(isNullOrWhitespace(form.sipNumber)){
                _formError = {
                    ..._formError,
                    sipNumber: requiredMessage,
                };
            }
            if(isNullOrWhitespace(form.extensionNumber)){
                _formError = {
                    ..._formError,
                    extensionNumber: requiredMessage,
                };
            }
        }


        const formErrorPasswords = validationRepeatPassword();

        dispatch(setFormErrorAction({..._formError, ...formErrorPasswords}));

        return Object.values(_formError).length === 0 && !formErrorPasswords.repeatPassword;
    }

    function validationRepeatPassword(): { repeatPassword?: string } {
        const {password, repeatPassword} = passwordForm;

        if (password === null) {
            return {};
        }

        if ((form.clientUserId && (password === "" || password === undefined)) || form.sendPassword) {
            return {};
        }

        if (isNullOrWhitespace(repeatPassword)) {
            return {repeatPassword: "Обязательное поле"};
        }

        if (password !== repeatPassword) {
            return {
                repeatPassword: "Пароли не совпадают",
            };
        }

        return {
            repeatPassword: undefined,
        };
    }

    const getPromotion = (type: string) => {
        dispatchRedux(getPromotionForPartner({
            type,
        }));
    };

    return [
        form,
        formError,
        dispatch,
        isVisibleForm,
        passwordForm,
        isEditPassword,
    ];
}