import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/crm/settings";

const CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_REQUEST = "CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_REQUEST";
const CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_SUCCESS = "CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_SUCCESS";
const CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_ERROR = "CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_ERROR";

const CRM_SETTINGS_GET_TICKET_REQUEST = "CRM_SETTINGS_GET_TICKET_REQUEST";
const CRM_SETTINGS_GET_TICKET_SUCCESS = "CRM_SETTINGS_GET_TICKET_SUCCESS";
const CRM_SETTINGS_GET_TICKET_ERROR = "CRM_SETTINGS_GET_TICKET_ERROR";

const CRM_SETTINGS_SET_ALL_REQUEST = "CRM_SETTINGS_SET_ALL_REQUEST";
const CRM_SETTINGS_SET_ALL_SUCCESS = "CRM_SETTINGS_SET_ALL_SUCCESS";
const CRM_SETTINGS_SET_ALL_ERROR = "CRM_SETTINGS_SET_ALL_ERROR";

const initialState = {
    progress: false,
    ticketSettings: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_REQUEST:
        case CRM_SETTINGS_SET_ALL_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_SUCCESS:
        case CRM_SETTINGS_SET_ALL_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_ERROR:
        case CRM_SETTINGS_SET_ALL_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CRM_SETTINGS_GET_TICKET_SUCCESS:
            return {
                ...state,
                ticketSettings: payload,
            };
        default: return state;
    }
};

export const setCrmTickedEnabledSetting = (payload) => ({
    type: CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_REQUEST,
    payload,
});

export const getCrmSettingTicket = () => ({
    type: CRM_SETTINGS_GET_TICKET_REQUEST,
});

export const setAllCrmSetting = (payload) => ({
    type: CRM_SETTINGS_SET_ALL_REQUEST,
    payload,
});

export const crmSettingsSelector = state => state.crmSettings;
export const crmSettingsTicketSelector = createSelector(crmSettingsSelector, ({ticketSettings}) => ticketSettings);
export const crmSettingsProgressSelector = createSelector(crmSettingsSelector, ({progress}) => progress);

const setAllCrmSettingSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/set-all`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CRM_SETTINGS_SET_ALL_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CRM_SETTINGS_SET_ALL_SUCCESS});
    } catch (error) {
        yield put({type: CRM_SETTINGS_SET_ALL_ERROR, payload: error});
    }
};

const getCrmSettingTickedSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/get/system-ticket`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CRM_SETTINGS_GET_TICKET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: CRM_SETTINGS_GET_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CRM_SETTINGS_GET_TICKET_ERROR, payload: error});
    }
};

const setCrmTickedEnabledSettingSaga = function* ({payload}) {
    try {
        const {
            value,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${controller}/set/system-ticket-enabled?value=${value}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CRM_SETTINGS_SET_SYSTEM_TICKET_ENABLED_REQUEST, setCrmTickedEnabledSettingSaga),
        takeEvery(CRM_SETTINGS_GET_TICKET_REQUEST, getCrmSettingTickedSaga),
        takeEvery(CRM_SETTINGS_SET_ALL_REQUEST, setAllCrmSettingSaga),
    ]);
}