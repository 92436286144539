import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import DocumentList from "../../documents/document-list";
import SettingsDocumentsCorrectionEdit from "./edit";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";

import {
    clearFieldsDocumentsCorrection,
    getDocumentsCorrectionPage,
    performDemoDocumentsCorrection,
    preformDocumentsCorrection,
} from "../../../ducks/bff/settings/documents-correction/actionCreators";
import {
    settingsDocumentsCorrectionListSelector,
    settingsDocumentsCorrectionPageDataSelector,
    settingsDocumentsCorrectionProgressActionSelector,
    settingsDocumentsCorrectionProgressSelector,
    settingsDocumentsCorrectionTotalCountSelector,
    settingsDocumentsCorrectionTotalPagesSelector,
} from "../../../ducks/bff/settings/documents-correction/selectors";
import {downloadDocument} from "../../../ducks/documents";

const SettingsDocumentsCorrection = (props) => {
    const [isEdit, setEdit] = useState(false);
    const [isConfirm, setConfirm] = useState(false);
    const [modalData, setModalData] = useState({
        documentId: "",
    });
    const [confirmData, setConfirmData] = useState({});
    const [progress, setProgress] = useState(false);

    const {
        documentId,
    } = modalData;

    const dispatch = useDispatch();

    const pageData = useSelector(settingsDocumentsCorrectionPageDataSelector);
    const progressAction = useSelector(settingsDocumentsCorrectionProgressActionSelector);
    const list = useSelector(settingsDocumentsCorrectionListSelector);
    const totalCount = useSelector(settingsDocumentsCorrectionTotalCountSelector);
    const totalPages = useSelector(settingsDocumentsCorrectionTotalPagesSelector);
    const progressList = useSelector(settingsDocumentsCorrectionProgressSelector);

    useEffect(() => {
        return () => {
            dispatch(clearFieldsDocumentsCorrection());
        };
    }, []);

    const onEdit = (document) => {
        const {
            documentsId: documentId,
        } = document;

        setEdit(true);
        setModalData({
            documentId,
        });
    };

    const onCloseEdit = () => {
        setEdit(false);
        setModalData({
            documentId: "",
        });
    };

    const onSubmitEdit = (form) => {
        setProgress(true);

        dispatch(performDemoDocumentsCorrection({
            ...form,
            documentId,
            onSuccess: (requestId) => {
                dispatch(downloadDocument({
                    isDownload: false,
                    downloadLink: `/bff/adm/settings/documents-correction/get/demo?requestId=${requestId}`,
                    onSuccess: () => {
                        setProgress(false);
                        setConfirmData(form);
                        setConfirm(true);
                    },
                    onError: () => {
                        setProgress(false);
                    },
                }));
            },
            onError: () => {
                setProgress(false);
            },
        }));
    };

    const fetchList = (reqData) => {
        dispatch(getDocumentsCorrectionPage(reqData));
    };

    const onCloseConfirm = () => {
        setConfirm(false);
    };

    const onSubmitConfirm = () => {
        dispatch(preformDocumentsCorrection({
            ...confirmData,
            documentId,
            onSuccess: () => {
                onCloseEdit();
                fetchList(pageData);
                setConfirm(false);
                setConfirmData({});
            },
            onError: () => {
                onCloseEdit();
                setConfirm(false);
                setConfirmData({});
            },
        }));
    };

    return (
        <>
            {
                isEdit &&
                    <SettingsDocumentsCorrectionEdit
                        onClose={onCloseEdit}
                        documentId={documentId}
                        onSubmit={onSubmitEdit}
                        isHiddenModal={isConfirm}
                        progress={progress}
                    />
            }
            {
                isConfirm &&
                    <NmModal
                        header="Вы действительно хотите обновить параметр документа?"
                        onClose={onCloseConfirm}
                        footer={
                            <ApplyButtons
                                onClose={onCloseConfirm}
                                disabled={progressAction}
                                submitBtnContent="Подтвердить"
                                cancelBtnContent="Отменить"
                                submit={onSubmitConfirm}
                                align="right"
                            />
                        }
                    />
            }
            <DocumentList
                header={
                    <NmTitle size="xl">
                        Исправление документов
                    </NmTitle>
                }
                getMediaControls={(document) => ({
                    renderCount: {
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                children: "Редактировать",
                                onClick: () => onEdit(document),
                            },
                        },
                    ],
                })}
                isSettings
                noPadding
                fetchList={fetchList}
                documentList={list}
                progressList={progressList}
                totalCount={totalCount}
                totalPages={totalPages}
                {...props}
            />
        </>
    );
};

export default SettingsDocumentsCorrection;