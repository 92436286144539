import {
    KEDO_DOCUMENT_ROUTE_ACTION_TYPE,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE,
    KEDO_DOCUMENT_ROUTE_SING_TYPE,
} from "../../../../../../../constants/kedo/document-route";

export const getKedoDocumentRouteInitialSignType = (values) => {
    const {
        actionType,
        matchParticipantType,
    } = values;

    if (
        actionType === KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN
        && matchParticipantType === KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.ROOT_DEPARTMENT_HEAD_OR_MCHD_USER
    ) {
        return KEDO_DOCUMENT_ROUTE_SING_TYPE.UKEP;
    }

    if (actionType === KEDO_DOCUMENT_ROUTE_ACTION_TYPE.APPROVE) {
        return KEDO_DOCUMENT_ROUTE_SING_TYPE.PEP;
    }

    return null;
};