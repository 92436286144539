import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR,
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST,
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS,
    GET_LIST_CONTRACTOR_BANKS_ERROR,
    GET_LIST_CONTRACTOR_BANKS_REQUEST,
    GET_LIST_CONTRACTOR_BANKS_SUCCESS,
    REFRESH_CONTRACTOR_BANK_STATUS_ERROR,
    REFRESH_CONTRACTOR_BANK_STATUS_REQUEST,
    REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS,
    REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR,
    REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST,
    REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS,
    SENDING_CONTRACTOR_TO_BANK_ERROR,
    SENDING_CONTRACTOR_TO_BANK_REQUEST,
    SENDING_CONTRACTOR_TO_BANK_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/contractors/contractors-registry/contractor-card/banks";

const getListContractorBanks = function* ({payload} ) {
    try {
        const result = yield request.bff.get(`${controller}/get-list`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_LIST_CONTRACTOR_BANKS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_LIST_CONTRACTOR_BANKS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_LIST_CONTRACTOR_BANKS_ERROR, payload: error});
    }
};

const sendingContractorToBank = function* ({payload} ) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/sending-contractor-to-bank`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SENDING_CONTRACTOR_TO_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SENDING_CONTRACTOR_TO_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SENDING_CONTRACTOR_TO_BANK_ERROR, payload: error});
    }
};

const refreshContractorBankStatus = function* ({payload} ) {
    try {
        const {
            contractorId,
            bankType,
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/refresh-contractor-status?contractorId=${contractorId}&bankType=${bankType}`);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_CONTRACTOR_BANK_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_CONTRACTOR_BANK_STATUS_ERROR, payload: error});
    }
};

const refreshContractorIdentificationStatus = function* ({payload} ) {
    try {
        const {
            contractorId,
            bankType,
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/refresh-identification-status?contractorId=${contractorId}&bankType=${bankType}`);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR, payload: error});
    }
};

const sendToContractorBankForcibly = function* ({payload} ) {
    const {
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/sending-contractor-to-bank-forcibly`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_LIST_CONTRACTOR_BANKS_REQUEST, getListContractorBanks),
        takeEvery(SENDING_CONTRACTOR_TO_BANK_REQUEST, sendingContractorToBank),
        takeEvery(REFRESH_CONTRACTOR_BANK_STATUS_REQUEST, refreshContractorBankStatus),
        takeEvery(REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST, refreshContractorIdentificationStatus),
        takeEvery(CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST, sendToContractorBankForcibly),
    ]);
}