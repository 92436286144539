export const GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST = "GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST";
export const GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS = "GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_SUCCESS";
export const GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR = "GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_ERROR";

export const DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST = "DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST";
export const DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS = "DECISION_DIRECTORIES_SPECIALTY_OFFER_SUCCESS";
export const DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR = "DECISION_DIRECTORIES_SPECIALTY_OFFER_ERROR =";

export const EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST = "EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST";
export const EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS = "EDIT_DIRECTORIES_SPECIALTY_OFFER_SUCCESS";
export const EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR = "EDIT_DIRECTORIES_SPECIALTY_OFFER_ERROR";

export const CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER = "CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER";