import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";

import {STATEMENT_ACTION_TYPE} from "../../loading-form/hooks/useLoadingForm";

import {mapParamToAdditionalParams} from "../../../../../order/order-templates/utils/mapOrderTemplateFilter";
import {STATEMENT_ACTION} from "../utils/getOptions";
import mapItemListToForm from "../utils/mapItemListToForm";

import {archiveStatement, getStatementsSourceFile, STATEMENT_FILE_TYPE} from "../../../../../../ducks/edmStatements";

export function useStatementItem({
    archived,
    fetchListCallback = () => {
    },
    clientId,
    setVisibleEditForm,
    setForm,
    setAction,
    openRejectForm = () => {
    },
    setAdditionalParams,
}) {
    const dispatch = useDispatch();

    const downloadFile = ({type, statementId}) => {
        dispatch(getStatementsSourceFile({
            statementId,
            clientId,
            fileType: STATEMENT_FILE_TYPE[type],
        }));
    };

    const onClickActionItem = useCallback(({value}, item) => {
        const {id} = item;

        switch (value) {
            case STATEMENT_ACTION.IN_ARCHIVE.VALUE:
            case STATEMENT_ACTION.FROM_ARCHIVE.VALUE: {
                dispatch(archiveStatement({
                    archived: !archived,
                    statementId: id,
                    getResult: fetchListCallback,
                }));
                break;
            }
            case STATEMENT_ACTION.EDIT.VALUE: {
                setForm(mapItemListToForm(item));
                setAdditionalParams(mapParamToAdditionalParams(item.param));
                setVisibleEditForm(true);
                setAction(STATEMENT_ACTION_TYPE.EDIT);
                break;
            }
            case STATEMENT_ACTION.ATTACHMENT_FILE.VALUE:
            case STATEMENT_ACTION.SOURCE_FILE.VALUE:
            case STATEMENT_ACTION.SIGNED_FILE.VALUE: {
                downloadFile({
                    statementId: id,
                    type: value,
                });
                break;
            }
            case STATEMENT_ACTION.TO_APPROVE.VALUE: {
                setForm(mapItemListToForm(item));
                setAdditionalParams(mapParamToAdditionalParams(item.param));
                setVisibleEditForm(true);
                setAction(STATEMENT_ACTION_TYPE.REVIEW);
                break;
            }
            case STATEMENT_ACTION.REJECT.VALUE: {
                openRejectForm([id]);
                break;
            }
            default:
                break;
        }
    }, [archived, dispatch, downloadFile, fetchListCallback, openRejectForm, setAction, setForm, setVisibleEditForm]);

    return {
        onClickActionItem,
        downloadFile,
    };
}