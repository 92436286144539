import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import Filter from "../../../components/ActualComponents/Filter";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../components/ActualComponents/NmPageInfoCardsAccordion";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import ViewsCount from "../../../components/ViewsCount";
import CrowdTaskContractorInfo from "./components/contractor-info";
import CrowdDepositValuesAmountInfo from "./components/deposit-values-amount-info";
import CrowdTaskEditForm from "./components/edit-form";
import CrowdTaskExtendModal from "./components/extend-modal";
import CrowdTaskLogList from "./components/log-modal";
import TaskPublishSuccessModal from "./components/task-publish-success-modal";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import useCrowdTaskRegistryAction from "./hooks/useAction";
import useCrowdTaskRegistryFetch from "./hooks/useFetch";
import useCrowdTaskRegistryFilter from "./hooks/useFilter";

import bem from "../../../utils/bem";
import dateFormat, {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getTimeFromMins} from "../../../utils/mathHelper";
import {formatAmount, formatAmountWithNullChecking, formatNumber} from "../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {CROWD_TASK_STATUS} from "../../../constants/crowd/task";
import {
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_SEARCH_PAGE,
} from "../../../constants/links";
import {
    NM_CHIEF_ACCOUNTANT,
    NM_OPERATOR,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {bffCrowdTaskGroupCardSelector} from "../../../ducks/bff/crowd/taskGroup/selectors";
import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";

import "./style.sass";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../constants/link-params";

const CrowdTaskRegistry = (props) => {
    const {
        match: {
            params: {
                clientId,
                taskGroupId,
                projectId,
                objectId,
            },
            path,
        },
    } = props;

    const taskGroupCard = useSelector(bffCrowdTaskGroupCardSelector);
    const {depositDistributedByObjects} = useSelector(clientCardPropertiesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    
    const [archivedFilter, setArchivedFilter] = useState(false);

    const [block, element] = bem("crowd-task-registry");

    const isGroupCardPage = Boolean(taskGroupId);
    const isGroupCardSearchPage = path.includes(LINK_SEARCH_PAGE);

    const role = ls(USER_ROLE);
    const isAccessActionCrowdTasksList = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTasksList);
    const isAccessActionCrowdTasksOneGroup = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTaskOneGroup);
    const isAccessMassAction = ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role) && isAccessActionCrowdTasksList;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
        filters,
        setFilter,
    } = useCrowdTaskRegistryFilter({
        clientId,
        pageSize,
        setPagination,
        projectId,
        objectId,
    });

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        fetchList,
        list,
        totalCount,
        totalPages,
        progress,
        progressAction,
        totalTasksPaymentAmount,
    } = useCrowdTaskRegistryFetch({
        clientId,
        pageNum,
        pageSize,
        archivedFilter,
        filter: filterData,
        isGroupCardPage,
        isGroupCardSearchPage,
        taskGroupId,
        projectId,
        objectId,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        getMediaControls,
        getMassActionContextMenuOptions,
        editFormData,
        setEditFormData,
        isOpenImportModal,
        setOpenImportModal,
        importGroupTasks,
        getListMediaControls,
        addTasksToGroup,
        successPublishModalData,
        setSuccessPublishModalData,
        logFormData,
        setLogFormData,
        extendModalData,
        setExtendModalData,
    } = useCrowdTaskRegistryAction({
        clientId,
        fetchList,
        progress,
        archivedFilter,
        setArchivedFilter,
        selectedList,
        clearSelectedRows,
        onOpenConfirm,
        onCloseConfirm,
        isGroupCardPage,
        isGroupCardSearchPage,
        taskGroupId,
        taskGroupCard,
        setPagination,
        isAccessActionCrowdTasksList,
        isAccessActionCrowdTasksOneGroup,
    });

    const isEmptyList = !totalCount;
    const isShowControls = !isEmptyList || isSearch;

    useEffect(() => {
        clearSelectedRows();
    }, [isGroupCardSearchPage]);

    const getProjectLink = ({clientId, projectData}) => {
        const {
            id,
            value,
        } = projectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", id);

        return (
            <ExtLink
                title={value}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const getObjectLink = ({clientId, projectData, objectData}) => {
        const {
            id,
            value,
        } = objectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectData?.id)
            .replace(":objectId", id);

        return (
            <ExtLink
                title={value}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const onClickTaskLink = (item) => {
        const {
            taskId,
            status,
        } = item;

        if (status === CROWD_TASK_STATUS.DRAFT.VALUE) {
            setEditFormData({
                isOpen: true,
                editData: item,
            });

            return;
        }
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", taskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        history.push(link);
    };

    const openTaskGroupsPage = () => {
        const link = LINK_CLIENT_CROWD_TASK_GROUPS.replace(":clientId", clientId);

        history.push(link);
    };

    const openTaskGroup = () => {
        const link = LINK_CLIENT_CROWD_TASK_GROUPS_CARD
            .replace(":clientId", clientId)
            .replace(":taskGroupId", taskGroupId);

        history.push(link);
    };

    const getMassActionContextMenu = () => {
        return (
            <ContextMenu
                size="lg"
                inline={false}
                options={getMassActionContextMenuOptions()}
            />
        );
    };

    const getMassActions = () => {
        if (!countSelected) {
            return (
                <NmAdvancedTooltip
                    type="light"
                    position="bottom-right"
                    trigger={getMassActionContextMenu()}
                    children="Нет доступных действий, т.к. вы не выбрали задание"
                />
            );
        }

        return getMassActionContextMenu();
    };

    const getWorkingContractor = ({workingContractorData}) => {
        return (
            <CrowdTaskContractorInfo
                contractor={workingContractorData}
                isShowLink={true}
                isMiniRatingInfo={true}
            />
        );
    };

    const getEmptyPageData = () => {
        const actionProps = {
            textAction: "Создать задание",
            onClickAction: () => setEditFormData({isOpen: true}),
        };

        if (isGroupCardSearchPage) {
            return {
                title: "У вас пока нет ни одного задания",
                description: "Для начала работы просто создайте задания и объедините их в группу",
                isSearch,
                isShowAction: isAccessActionCrowdTasksOneGroup,
                ...actionProps,
            };
        }

        if (isGroupCardPage) {
            return {
                title: "Данные отсутствуют",
                isSearch,
            };
        }

        return {
            title: !archivedFilter ?
                "Создавайте, размещайте и управляйте вашими заданиями на площадке Наймикс" :
                "Данные отсутствуют",
            description: !archivedFilter &&
                "Для начала работы просто создайте свое первое задание и сохраните его как черновик или опубликуйте его на нашей площадке для поиска заинтересованных исполнителей",
            isSearch,
            isShowAction: !archivedFilter && isAccessActionCrowdTasksList,
            ...actionProps,
        };
    };

    const getHeaderText = () => {
        if (isGroupCardSearchPage) {
            return `Поиск заданий для ${taskGroupCard.name}`;
        }

        if (isGroupCardPage) {
            return taskGroupCard.name || "";
        }

        if (projectId || objectId) {
            return "Задания";
        }

        return "Реестр заданий";
    };

    const getHeaderBackFunction = () => {
        if (isGroupCardSearchPage) {
            return openTaskGroup;
        }

        if (isGroupCardPage) {
            return openTaskGroupsPage;
        }

        return null;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                taskId,
                creationDateTime,
                status,
                name,
                number,
                specialityData,
                timeForExecutionMinutes,
                workingContractorData,
                paymentAmount,
                workStartDate,
                workEndDate,
                invitedContractorsCount,
                inQueueContractorsCount,
                workingContractorsCount,
                viewsCount,
            } = item;

            const {isSelected = false} = selectedList.find(_item => (_item.taskId === taskId)) || {};

            return {
                ...item,
                key: taskId,
                showCheckBox: !taskGroupCard.archived && isAccessMassAction,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={CROWD_TASK_STATUS[status]?.TEXT || status}
                                mod={CROWD_TASK_STATUS[status]?.MOD || "gray"}
                            />
                        }
                        secondaryHeader={
                            <div className="flex flex-aligned-center">
                                <div className="me-2">
                                    {
                                        creationDateTime &&
                                        `Задание от: ${formatLocalDate(creationDateTime, "dd.MM.yyyy HH:mm")}`
                                    }
                                </div>
                                <ViewsCount count={viewsCount} />
                            </div>
                        }
                        primaryHeader={`№${number} - ${name}`}
                        primaryHeaderLink={true}
                        onClickLink={() => onClickTaskLink(item)}
                        classNameMainContent="col-16 col-xxl-7"
                        labels={[
                            {
                                label: "Проект",
                                text: getProjectLink(item),
                            },
                            {
                                label: "Объект",
                                text: getObjectLink(item),
                            },
                            {
                                label: "Вид деятельности",
                                text: specialityData?.value || "-",
                                noWrap: false,
                            },
                            {
                                label: "Время выполнения",
                                text: timeForExecutionMinutes ? getTimeFromMins(timeForExecutionMinutes) : "-",
                            },
                            workingContractorData?.contractorId && {
                                label: "Исполнитель",
                                text: getWorkingContractor(item),
                                textOverflowUnset: true,
                            },
                        ]}
                        cards={
                            !isGroupCardSearchPage && [
                                {
                                    title: "Стоимость задания, ₽",
                                    value: paymentAmount ? formatAmount(formatNumber(paymentAmount, 2)) : "-",
                                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                                },
                                {
                                    title: "Период выполнения задания",
                                    value: `${dateFormat(workStartDate, "dd.MM.yyyy")} - ${dateFormat(workEndDate, "dd.MM.yyyy")}`,
                                    className: "col-16 col-md-4 col-xxl-6 mt-md-4 mt-xxl-0",
                                },
                                status === CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE && {
                                    title: "Приглашенные / В очереди / В работе",
                                    value: `${invitedContractorsCount || "-"} / ${inQueueContractorsCount || "-"} / ${workingContractorsCount || "-"}`,
                                    className: "col-16 col-md-4 col-xxl-6 mt-md-4 mt-xxl-0",
                                },
                            ]
                        }
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-8 align-items-end"
                        mediaControls={getListMediaControls(item)}
                        actionsClassName="col-1 justify-content-end"
                        isFixedActions={isGroupCardSearchPage}
                    />
                ),
            };
        });
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            isOnlyConfirm,
            confirmButton,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                isOnlyConfirm={isOnlyConfirm}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton || "Да"}
                cancelButton="Нет"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderPublishSuccessModal = () => {
        return (
            !isEmpty(successPublishModalData) &&
            <TaskPublishSuccessModal
                data={successPublishModalData}
                onClose={() => setSuccessPublishModalData({})}
            />
        );
    };

    const renderEditForm = () => {
        return (
            editFormData.isOpen &&
            <CrowdTaskEditForm
                clientId={clientId}
                onClose={() => setEditFormData({})}
                fetchList={fetchList}
                editData={editFormData.editData}
                openSuccessPublishModalData={(data) => setSuccessPublishModalData({
                    clientId,
                    ...data,
                })}
            />
        );
    };

    const renderImportForm = () => {
        return (
            isOpenImportModal &&
            <ImportFromFilePatternV2
                headerTitle="Загрузка заданий"
                patternLink="/files/Шаблон_Задание.xlsx"
                onSubmit={importGroupTasks}
                onClose={() => setOpenImportModal(false)}
                progress={progressAction}
            />
        );
    };

    const renderLogModal = () => {
        return (
            !isEmpty(logFormData) &&
            <CrowdTaskLogList
                taskData={logFormData}
                onClose={() => setLogFormData({})}
            />
        );
    };

    const renderExtendModal = () => {
        return (
            !isEmpty(extendModalData) &&
            <CrowdTaskExtendModal
                data={extendModalData}
                onClose={() => setExtendModalData({})}
                progress={progressAction}
            />
        );
    };

    const renderSubHeader = () => {
        if (isGroupCardPage) {
            return (
                taskGroupCard.comment &&
                <div className="col-16 col-xl-6 mb-2">
                    <Text level="3">
                        {taskGroupCard.comment}
                    </Text>
                </div>
            );
        }

        if (!isEmptyList && (projectId || objectId)) {
            return (
                <NmPageInfoCardsAccordion
                    bootstrap={true}
                    cards={[
                        {
                            title: "Сумма опубликованных заданий",
                            value: `${formatAmountWithNullChecking(totalTasksPaymentAmount)} ₽`,
                            className: "col-xl-4 col-xxl-2",
                            helpTooltip: {
                                text: depositDistributedByObjects ?
                                    "Сумма опубликованных и неоплаченных заданий по объекту с учётом комиссии" :
                                    "Сумма опубликованных и неоплаченных заданий с учётом комиссии",
                                type: "light",
                                position: "bottom-left",
                            },
                        },
                    ]}
                    desktopViewFrom="xxl"
                />
            );
        }

        return (
            !isEmptyList &&
            <div className="mb-4 mb-md-5">
                <Text
                    className="mb-2"
                    medium
                    level="2"
                    color={COLOR.SECONDARY_70}
                    noWrap
                >
                    Детализация по счету для выплат исполнителям с типом налогообложения НПД
                </Text>
                <CrowdDepositValuesAmountInfo
                    clientId={clientId}
                    className="mb-6"
                />
            </div>
        );
    };

    return (
        <NmPage
            className={block()}
            header={
                <NmPageHeader
                    text={getHeaderText()}
                    totalCount={
                        isShowControls &&
                        !projectId &&
                        !objectId &&
                        !isGroupCardPage ?
                            totalCount :
                            undefined
                    }
                    handleOnClickBack={getHeaderBackFunction()}
                />
            }
            subHeader={renderSubHeader()}
            mediaControls={getMediaControls(isShowControls)}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    updateFilter={(filter) => setFilter(filter)}
                />
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderExtendModal()}
            {renderLogModal()}
            {renderEditForm()}
            {renderImportForm()}
            {renderConfirm()}
            {renderPublishSuccessModal()}
            {
                !isEmptyList ?
                    <CheckboxList
                        header={
                            isAccessMassAction &&
                            !taskGroupCard.archived &&
                            !archivedFilter &&
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent={isGroupCardSearchPage && "Добавить в группу"}
                                onClick={() => addTasksToGroup({isMassOperation: true})}
                                otherActions={
                                    !isGroupCardSearchPage &&
                                    <div className={element("context-menu-container", {fluid: true})}>
                                        {getMassActions()}
                                    </div>
                                }
                            />
                        }
                        rows={getRows()}
                        onSelectedRows={
                            !taskGroupCard.archived &&
                            !archivedFilter &&
                            isAccessMassAction &&
                            handleSelectedRows
                        }
                        actionOptions={getMassActionContextMenuOptions()}
                    /> :
                    <NmEmptyPageV2
                        fetchProgress={progress}
                        {...getEmptyPageData()}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskRegistry;