import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/registries/invitations/contractors";
//*  TYPES  *//

const GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST = "GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST";
const GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS = "GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS";
const GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR = "GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR";

const ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST = "ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST";
const ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS = "ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS";
const ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR = "ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR";

const DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST = "DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST";
const DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS = "DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS";
const DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR = "DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR";

const UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST = "UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST";
const UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS = "UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS";
const UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR = "UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR";

const IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST = "IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST";
const IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS = "IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS";
const IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR = "IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR";

const RUN_REGISTRY_INVITATIONS_ITEM_REQUEST = "RUN_REGISTRY_INVITATIONS_ITEM_REQUEST";
const RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS = "RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS";
const RUN_REGISTRY_INVITATIONS_ITEM_ERROR = "RUN_REGISTRY_INVITATIONS_ITEM_ERROR";

const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_REQUEST = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_REQUEST";
const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_SUCCESS = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_SUCCESS";
const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_ERROR = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_ERROR";

const GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_REQUEST = "GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_REQUEST";
const GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_SUCCESS = "GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_SUCCESS";
const GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_ERROR = "GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    pageData: {},
    progress: false,
    actionProgress: false,
    totalCount: 0,
    statusDict: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST:
        case DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST:
        case UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST:
        case IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS:
        case DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS:
        case UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS:
        case IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
            };
        case GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR:
        case DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR:
        case UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR:
        case IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getRegistryInvitationsContractorsPage(payload) {
    return {
        type: GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST,
        payload,
    };
}

export function addContractorToRegistryInvitations(payload) {
    return {
        type: ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function deleteContractorsFromRegistryInvitations(payload) {
    return {
        type: DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function updateRegistryInvitationsContractor(payload) {
    return {
        type: UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST,
        payload,
    };
}

export function importRegistryInvitationsContractors(payload) {
    return {
        type: IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST,
        payload,
    };
}
export function runRegistryInvitationsItem(payload) {
    return {
        type: RUN_REGISTRY_INVITATIONS_ITEM_REQUEST,
        payload,
    };
}

export function checkValidResidentRegistryInvitationsItem(payload) {
    return {
        type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_REQUEST,
        payload,
    };
}

export function getRegistryInvitationsItemStatusDict() {
    return {
        type: GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_REQUEST,
    };
}

//*  SELECTORS  *//
export const registryInvitationsContractorsSelector = state => state.registryInvitationsContractors;
export const getRegistryInvitationsContractorsTotalPagesSelector = createSelector(registryInvitationsContractorsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getRegistryInvitationsContractorsTotalCountSelector = createSelector(registryInvitationsContractorsSelector, ({totalCount}) => totalCount);
export const getRegistryInvitationsContractorsListSelector = createSelector(registryInvitationsContractorsSelector, ({list}) => list);
export const getRegistryInvitationsContractorsProgressSelector = createSelector(registryInvitationsContractorsSelector, ({progress}) => progress);
export const getRegistryInvitationsContractorsActionProgressSelector = createSelector(registryInvitationsContractorsSelector, ({actionProgress}) => actionProgress);
export const getRegistryInvitationsContractorsStatusDictSelector = createSelector(registryInvitationsContractorsSelector, ({statusDict}) => statusDict);

//*  SAGA  *//

//POST /api/registries/invitations/contractors/getRichPage
export const getRegistryInvitationsContractorsPageSaga = function* ({payload} ) {
    try {
        const result = yield request.post(`${controller}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({baseModel: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/add
export const addContractorToRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/contractors/deleteByIds
export const deleteContractorsFromRegistryInvitationsSaga = function* ({payload} ) {
    try {
        const {
            requestData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteByIds`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/update
export const updateRegistryInvitationsContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/contractors/import/{clientId}/{registryInvitationId}
const importRegistryInvitationsContractorsSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            registryInvitationId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/import/${clientId}/${registryInvitationId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
            payload: error,
        });
    }
};

//POST /api/registries/invitations/contractors/run
export const runRegistryInvitationsItemSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/run`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RUN_REGISTRY_INVITATIONS_ITEM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Строка передана в работу");
        onSuccess();
        yield put({type: RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: RUN_REGISTRY_INVITATIONS_ITEM_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/contractors/isValidNonResidentFrameSigned
export const checkValidResidentRegistryInvitationsItemSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.post(`${controller}/isValidNonResidentFrameSigned`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_ERROR, payload: error});
    }
};

export const getRegistryInvitationsItemStatusDictSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/getShortStatusDictionary`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_ERROR,
            payload: e,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST, getRegistryInvitationsContractorsPageSaga),
        takeEvery(ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST, addContractorToRegistryInvitationsSaga),
        takeEvery(DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST, deleteContractorsFromRegistryInvitationsSaga),
        takeEvery(UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST, updateRegistryInvitationsContractorSaga),
        takeEvery(IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST, importRegistryInvitationsContractorsSaga),
        takeEvery(RUN_REGISTRY_INVITATIONS_ITEM_REQUEST, runRegistryInvitationsItemSaga),
        takeEvery(CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ITEM_REQUEST, checkValidResidentRegistryInvitationsItemSaga),
        takeEvery(GET_REGISTRY_INVITATIONS_ITEM_STATUS_DICT_REQUEST, getRegistryInvitationsItemStatusDictSaga),
    ]);
}
