import {
    REGISTRY_PAYMENT_ARCHIVE_REQUEST,
    REGISTRY_PAYMENT_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_PAY_REQUEST,
    REGISTRY_PAYMENT_UPDATE_STORE,
} from "./actions";

export const getPageRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const archiveRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_ARCHIVE_REQUEST,
        payload,
    };
};

export const payRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_PAY_REQUEST,
        payload,
    };
};

export const updateRegistryPaymentStore = (payload) => {
    return {
        type: REGISTRY_PAYMENT_UPDATE_STORE,
        payload,
    };
};