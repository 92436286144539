import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffSettingsContractorSupportSelector = state => state.bff.settingsContractorSupport;
export const bffSettingsContractorSupportListSelector = createSelector(bffSettingsContractorSupportSelector, ({list}) => list);
export const bffSettingsContractorSupportCardSelector = createSelector(bffSettingsContractorSupportSelector, ({card}) => card);
export const bffSettingsContractorSupportTotalCountSelector = createSelector(bffSettingsContractorSupportSelector, ({totalCount}) => totalCount);
export const bffSettingsContractorSupportTotalPagesSelector = createSelector(bffSettingsContractorSupportSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffSettingsContractorSupportProgressSelector = createSelector(bffSettingsContractorSupportSelector, ({progress}) => progress);
export const bffSettingsContractorSupportProgressCardSelector = createSelector(bffSettingsContractorSupportSelector, ({progressCard}) => progressCard);
export const bffSettingsContractorSupportProgressActionSelector = createSelector(bffSettingsContractorSupportSelector, ({progressAction}) => progressAction);