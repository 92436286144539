import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";
import {PATTERN_NUMBER} from "../../../../deposit/client-list/hooks/useGetlDepositData";

import {dadataValueUniqCitySettlementFilter} from "../../../../../utils/dadata";

import {GENDER_OPTIONS} from "../../../../../constants/contractorInfo";

import {getCitizenshipWithFlagsOptionsSelector} from "../../../../../ducks/contractor";

export const useRecruitmentCandidatesFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
    } = useFilter({
        initFilter: {},
        pageSize,
        setPagination,
    });

    const citizenshipOptions = useSelector(getCitizenshipWithFlagsOptionsSelector);

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        label: "Дата создания",
                        name: "createDate",
                        startFieldName: "dateFrom",
                        endFieldName: "dateTo",
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "name",
                        label: "ФИО",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "phone",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "email",
                        label: "E-mail",
                        placeholder: "Введите e-mail",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: GENDER_OPTIONS,
                        label: "Пол",
                        name: "gender",
                        placeholder: "Все",
                    },
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "ageFrom",
                        nameEnd: "ageTo",
                        label: "Возраст",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "citizenships",
                        label: "Гражданство",
                        placeholder: "Выберите гражданство",
                        search: true,
                        multiple: true,
                        options: citizenshipOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        name: "addressFiasIds",
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        placeholder: "Выберите город",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        name: "specialityIds",
                        label: "Вид деятельности",
                        placeholder: "Показать все",
                    },
                ],
            },
        ];
    }, []);

    return {
        filtersBase,
        onClear,
        onSearch,
        filter,
        isSearch,
        filterData,
    };
};