import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";
import {LogsModal} from "../../../../../components/ActualComponents/LogsModal";

import {useKedoDepartments} from "../../../../../components/KedoDepartmentsDropdown/hooks/useDepartments";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../../utils/objectHelper";
import {getFullName} from "../../../../../utils/stringFormat";

import {
    getKedoDepartmentLogMergeTypes,
    updateCommonPaymentDicts,
} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoDepartmentLogMergeTypesOptionsSelector,
} from "../../../../../ducks/bff/common/dicts/selectors";
import {clearKedoDepartmentsLogs, getKedoDepartmentsLogs} from "../../../../../ducks/kedo/departments/actionCreators";
import {
    kedoDepartmentsLogsListSelector,
    kedoDepartmentsLogsProgressSelector,
    kedoDepartmentsLogsTotalCountSelector,
    kedoDepartmentsLogsTotalPagesSelector,
} from "../../../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructureLogs = (props) => {
    const {
        onClose,
        clientId,
        kedoDepartmentId,
    } = props;

    const dispatch = useDispatch();

    const totalCount = useSelector(kedoDepartmentsLogsTotalCountSelector);
    const totalPages = useSelector(kedoDepartmentsLogsTotalPagesSelector);
    const list = useSelector(kedoDepartmentsLogsListSelector);
    const progress = useSelector(kedoDepartmentsLogsProgressSelector);
    const eventTypeOptions = useSelector(bffCommonDictsKedoDepartmentLogMergeTypesOptionsSelector);

    useEffect(() => {
        dispatch(getKedoDepartmentLogMergeTypes());

        return () => {
            dispatch(clearKedoDepartmentsLogs());
            dispatch(updateCommonPaymentDicts({
                kedoDepartmentLogMergeTypes: {},
            }));
        };
    }, []);

    const fetchList = (params) => {
        dispatch(getKedoDepartmentsLogs({
            clientId,
            ...params,
        }));
    };

    const {
        options,
        placeholder,
        onSearchChange,
    } = useKedoDepartments({
        clientId,
        parentDepartmentId: kedoDepartmentId,
    });

    const filters = [
        {
            component: FILTER.DROPDOWN,
            size: "lg",
            multiple: true,
            name: "kedoDepartmentsIds",
            placeholder,
            options,
            onSearchChange,
        },
        {
            component: FILTER.INPUT,
            size: "lg",
            name: "initiatorFio",
            placeholder: "ФИО инициатора",
        },
    ];

    return (
        <LogsModal
            title="Лог организационной структуры"
            onClose={onClose}
            totalCount={totalCount}
            totalPages={totalPages}
            list={list}
            progress={progress}
            fetchList={fetchList}
            dateFieldName="createdAt"
            getInitiatorNameText={(item) => {
                return getFullName(
                    item.initiatorLastName,
                    item.initiatorFirstName,
                    item.initiatorPatronymic,
                );
            }}
            eventTypeOptions={eventTypeOptions}
            classNameFilterField="col-16 col-md-8"
            date={{
                startDate: "createdFrom",
                endDate: "createdTo",
            }}
            eventType={{
                name: "mergeTypes",
                multiple: true,
            }}
            getLabels={(item) => {
                return [
                    {
                        label: "Должность",
                        text: item.initiatorPositionName,
                    },
                ];
            }}
            filters={filters}
            mapFilterDto={(filter) => {
                return filterEmptyValues({
                    ...filter,
                    createdFrom: getStartFilterDateWithTimeInUtc(filter.createdFrom),
                    createdTo: getEndFilterDateWithTimeInUtc(filter.createdTo),
                });
            }}
        />
    );
};