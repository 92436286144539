import {useDispatch} from "react-redux";

import {useModal} from "../../../../hooks/useModal";

import {isNMUsers} from "../../../../utils/access";

import {
    generateClientsMonitoringRisksReport,
    updateClientsMonitoringRisksData,
} from "../../../../ducks/bff/clients/monitoring-risk/actionCreators";

export const useClientMonitoringRiskAction = (props) => {
    const {
        clientId,
        fetchList,
    } = props;

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const dispatch = useDispatch();

    const onGenerateReport = () => {
        dispatch(generateClientsMonitoringRisksReport({
            onSuccess: () => {
                onOpenModal({
                    isOpenInformationModal: true,
                    title: "Информация о формировании отчета",
                    content: isNMUsers() ?
                        "Отчет будет сформирован на следующий день. Отслеживайте статус выгрузки в разделе Экспорт > Экспорт отчетов" :
                        "Отчет будет сформирован на следующий день. Отслеживайте статус выгрузки в разделе Отчеты > Экспорт отчетов",
                });
            },
            onError: ({errorMessage}) => {
                onOpenModal({
                    isOpenInformationModal: true,
                    title: "Формирование отчета недоступно",
                    content: errorMessage,
                });
            },
        }));
    };

    const onUpdateData = () => {
        dispatch(updateClientsMonitoringRisksData({
            clientId,
            onSuccess: () => {
                fetchList();
            }},
        ));
    };

    return {
        onGenerateReport,
        onUpdateData,
        onOpenModal,
        onCloseModal,
        modalData,
    };
};