export const GET_PAGE_SPECIALITIES_REQUEST = "GET_PAGE_SPECIALITIES_REQUEST";
export const GET_PAGE_SPECIALITIES_SUCCESS = "GET_PAGE_SPECIALITIES_SUCCESS";
export const GET_PAGE_SPECIALITIES_ERROR = "GET_PAGE_SPECIALITIES_ERROR";

export const ADD_SPECIALTY_REQUEST = "ADD_SPECIALTY_REQUEST";
export const ADD_SPECIALTY_SUCCESS = "ADD_SPECIALTY_SUCCESS";
export const ADD_SPECIALTY_ERROR = "ADD_SPECIALTY_ERROR";

export const UPDATE_SPECIALTY_REQUEST = "UPDATE_SPECIALTY_REQUEST";
export const UPDATE_SPECIALTY_SUCCESS = "UPDATE_SPECIALTY_SUCCESS";
export const UPDATE_SPECIALTY_ERROR = "UPDATE_SPECIALTY_ERROR";

export const DELETE_SPECIALTY_REQUEST = "DELETE_SPECIALTY_REQUEST";
export const DELETE_SPECIALTY_SUCCESS = "DELETE_SPECIALTY_SUCCESS";
export const DELETE_SPECIALTY_ERROR = "DELETE_SPECIALTY_ERROR";

export const CLEAR_STORE_SPECIALITIES = "CLEAR_STORE_SPECIALITIES";