import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_EXPORT_REPORTS_ADD_ERROR,
    BFF_EXPORT_REPORTS_ADD_REQUEST,
    BFF_EXPORT_REPORTS_ADD_SUCCESS,
    BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_ERROR,
    BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST,
    BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS,
    BFF_EXPORT_REPORTS_GET_PAGE_ERROR,
    BFF_EXPORT_REPORTS_GET_PAGE_REQUEST,
    BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {LINK_FINANCE_EXPORT_FULL} from "../../../../constants/links";

const controllers = {
    client: "/client-adm/reports/export-reports",
    adminClientCard: "/adm/clients/client-card/reports/export-reports",
    adminExport: "/adm/export/report-export",
};

const getPageExportReports = function* ({payload}) {
    try {
        const {clientId} = payload;

        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT_FULL]: `${controllers.adminExport}/finance-page`,
                isClientCard: Boolean(clientId),
                clientRolesUrl: `${controllers.client}/get-finances-export-page`,
                adminRolesUrl: `${controllers.adminClientCard}/getFinancesExportPage`,
            },
            `${controllers.adminExport}/finance-page`,
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_EXPORT_REPORTS_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_EXPORT_REPORTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addExportReport = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT_FULL]: `${controllers.adminExport}/add-export`,
                isClientCard: Boolean(reqData.clientId),
                clientRolesUrl: `${controllers.client}/add-export`,
                adminRolesUrl: `${controllers.adminClientCard}/addExport`,
            },
            `${controllers.adminExport}/finance-page`,
        );

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_EXPORT_REPORTS_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Сформированная выгрузка доступна в разделе Экспорт отчетов");

        yield put({
            type: BFF_EXPORT_REPORTS_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_EXPORT_REPORTS_ADD_ERROR,
        });
    }
};

const getEdoAccessListLinkExportReport = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT_FULL]: `${controllers.adminExport}/edocuments/access/self`,
                isClientCard: Boolean(payload.clientId),
                clientRolesUrl: `${controllers.client}/edocuments/self`,
                adminRolesUrl: `${controllers.adminClientCard}/edocuments/self`,
            },
            `${controllers.adminExport}/finance-page`,
        );


        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_EXPORT_REPORTS_GET_PAGE_REQUEST, getPageExportReports),
        takeEvery(BFF_EXPORT_REPORTS_ADD_REQUEST, addExportReport),
        takeEvery(BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST, getEdoAccessListLinkExportReport),
    ]);
}