import {
    ADD_CLIENT_CATEGORIES_REQUEST,
    CLEAR_FIELDS_CLIENT_CATEGORIES,
    DELETE_CLIENT_CATEGORIES_REQUEST,
    GET_PAGE_CLIENT_CATEGORIES_REQUEST,
    UPDATE_CLIENT_CATEGORIES_REQUEST,
} from "./actions";

export function getClientCategoriesPage(payload) {
    return {
        type: GET_PAGE_CLIENT_CATEGORIES_REQUEST,
        payload,
    };
}

export function updateClientCategories(payload) {
    return {
        type: UPDATE_CLIENT_CATEGORIES_REQUEST,
        payload,
    };
}

export function addClientCategories(payload) {
    return {
        type: ADD_CLIENT_CATEGORIES_REQUEST,
        payload,
    };
}

export function deleteClientCategories(payload) {
    return {
        type: DELETE_CLIENT_CATEGORIES_REQUEST,
        payload,
    };
}

export function clearFieldsClientCategories() {
    return {
        type: CLEAR_FIELDS_CLIENT_CATEGORIES,
    };
}