import React, {FC, LegacyRef, useState} from "react";
import {WithTranslation} from "react-i18next";

import {ReactComponent as ShareIcon} from "../../../images/share_24.svg";
import NmButton from "../../NmButton";
import ContextMenuContent from "../ContextMenu/Content";

import {useClickOutside} from "../../../hooks/useClickOutside";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";

import "./style.sass";

interface IProps extends WithTranslation {
    clientId: string;
    orderId?: string;
    taskId?: string;
    options: Array<OptionType>
}

const ShareButton: FC<IProps> = (props) => {
    const {
        clientId,
        taskId,
        orderId,
        t,
        options,
        ...otherProps
    } = props;

    const [isShowSharePopUp, setIsShowSharePopUp] = useState(false);

    const ref = useClickOutside(() => {
        setIsShowSharePopUp(false);
    }, isShowSharePopUp);

    return (
        <div
            ref={ref as LegacyRef<HTMLDivElement>}
            className="container"
        >
            <NmButton
                {...otherProps}
                icon={<ShareIcon />}
                color="white"
                isIconPositionEnd
                onClick={() => setIsShowSharePopUp(true)}
            />
            {
                isShowSharePopUp &&
                <ContextMenuContent
                    position="bottom"
                    inline
                    options={options}
                    onClickItem={() => setIsShowSharePopUp(false)}
                />
            }
        </div>
    );
};

export default ShareButton;