import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNestedObjectValues, useFormik} from "formik";
import * as yup from "yup";

import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import ContractorEditPaymentInfo from "../../../../../../components/ContractorEditPaymentInfo";
import NmRatingFieldsForm from "../../../../../../components/NmRatingFieldsForm";
import NmTitle from "../../../../../../components/NmTitle";

import {
    useOrderActRegistryEditPaymentFetch,
} from "../../../../../order/act-registry/card/components/edit-payment/hooks/useFetch";

import {getEndDate, getStartDate} from "../../../../../../utils/dateFormat";
import {formatAmountWithNullChecking} from "../../../../../../utils/stringFormat";
import {convertStringToDate, handleFormString} from "../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../constants/validationRules";

import {clientCardPropertiesSelector} from "../../../../../../ducks/bff/clients/info/selectors";
import {contractorCardInfoSelector} from "../../../../../../ducks/bff/contractor-сard/selectors";
import {
    createCrowdActRegistryEntry,
    editCrowdActRegistryEntry,
} from "../../../../../../ducks/bff/crowd/actRegistryEntry/actionCreators";
import {
    bffCrowdActRegistryEntryProgressActionSelector,
} from "../../../../../../ducks/bff/crowd/actRegistryEntry/selectors";

const CrowdActRegistryEntryEditModal = (props) => {
    const {
        onClose = () => {},
        fetchList = () => {},
        clientId,
        registryId,
        editData = {},
    } = props;

    const dispatch = useDispatch();

    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);
    const progress = useSelector(bffCrowdActRegistryEntryProgressActionSelector);
    const contractorCard = useSelector(contractorCardInfoSelector);

    const isEdit = Boolean(editData.entryId);
    const {
        task = {},
        contractor = {},
        contractorFinishedDate,
    } = editData;

    const _contractor = isEdit ? {
        ...contractorCard,
        contractorId: editData.contractorId,
        fullName: editData.contractorName,
        phone: editData.contractorPhone,
        inn: editData.contractorInn,
    } : contractor;
    const _task = isEdit ? {
        taskId: editData.taskId,
        number: editData.taskNumber,
        name: editData.taskName,
        paymentAmount: editData.sum,
    } : task;
    const {
        number,
        name,
        paymentAmount,
    } = _task || {};

    const initialValues = useMemo(() => {
        const actFormingDate = isEdit ? editData.actFormingDate : contractorFinishedDate;
        const workStartDate = isEdit ? editData.workStartDate : task.workStartDate;
        const taskEndDate = contractorFinishedDate ? contractorFinishedDate : task.workEndDate;
        const workEndDate = isEdit ? editData.workEndDate : taskEndDate;

        return {
            workActDescription: editData.workActDescription || "",
            workFNSDescription: editData.workFNSDescription || "",
            comment: editData.comment || "",
            workStartDate: convertStringToDate(workStartDate, true) || null,
            workEndDate: convertStringToDate(workEndDate, true) || null,
            actFormingDate: convertStringToDate(actFormingDate, true) || null,
            qualityRating: editData.qualityRating || null,
            timingRating: editData.timingRating || null,
        };
    }, [editData]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            workActDescription,
            workFNSDescription,
            comment,
            workStartDate,
            workEndDate,
            actFormingDate,
            qualityRating,
            timingRating,
        } = values;

        const reqData = {
            clientId,
            registryId,
            taskId: _task.taskId,
            contractorId: _contractor.contractorId,
            paymentId: _task.contractorId,
            sum: _task.paymentAmount,
            workActDescription: handleFormString(workActDescription),
            workFNSDescription: handleFormString(workFNSDescription),
            comment: handleFormString(comment),
            workStartDate: getStartDate(workStartDate),
            workEndDate: getEndDate(workEndDate),
            actFormingDate: getStartDate(actFormingDate),
            qualityRating,
            timingRating,
            onSuccess: () => {
                onClose();
                fetchList();
            },
        };

        if (isEdit) {
            dispatch(editCrowdActRegistryEntry({
                entryId: editData.entryId,
                ...reqData,
            }));

            return;
        }

        dispatch(createCrowdActRegistryEntry(reqData));
    };

    const {
        values,
        isValid,
        handleSubmit,
        touched,
        errors,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues,
        initialTouched: setNestedObjectValues(initialValues, false),
        validationSchema: yup.object().shape({
            workActDescription: yup.string()
                .nullable()
                .required(requiredMessage)
                .min(5, "Минимальная длина строки 5 символов"),
            workFNSDescription: yup.string()
                .nullable()
                .required(requiredMessage)
                .min(5, "Минимальная длина строки 5 символов"),
            workStartDate: yup.string()
                .nullable()
                .required(requiredMessage),
            workEndDate: yup.string()
                .nullable()
                .required(requiredMessage),
            actFormingDate: yup.string()
                .nullable()
                .required(requiredMessage)
                .test("check", "Дата акта должна входить в промежуток Периода работ по заданию", function (value) {
                    if (!value || (!this.parent.workStartDate && !this.parent.workEndDate)) {
                        return true;
                    }

                    const firstCondition = getStartDate(value) >= getStartDate(this.parent.workStartDate);
                    const secondCondition = getStartDate(value) <= getStartDate(this.parent.workEndDate);

                    if (this.parent.workStartDate && !this.parent.workEndDate) {
                        return firstCondition;
                    }

                    if (!this.parent.workStartDate && this.parent.workEndDate) {
                        return secondCondition;
                    }

                    return firstCondition && secondCondition;
                }),
            qualityRating: yup.string()
                .nullable()
                .required(requiredMessage),
            timingRating: yup.string()
                .nullable()
                .required(requiredMessage),
        }),
        validateOnBlur: false,
        enableReinitialize: true,
    });

    const handleChange = (event, {name, value, checked}) => {
        if (name === "workActDescription") {
            setFieldValue("workFNSDescription", value.slice(0, 160));
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const {
        contractorsPossibility,
    } = useOrderActRegistryEditPaymentFetch({
        contractorId: _contractor.contractorId,
        contractor: _contractor,
    });

    const estimates = [
        {
            rating: values.qualityRating,
            name: "qualityRating",
            label: "Оценка качества выполненных работ:",
            newError: touched.qualityRating && errors.qualityRating,
        },
        {
            rating: values.timingRating,
            name: "timingRating",
            label: "Оценка по срокам выполненных работ:",
            newError: touched.timingRating && errors.timingRating,
        },
    ];

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование" : "Добавление"}
                    {" "}
задания для оплаты исполнителю
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    disabled={progress}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                <Text type="title">
                    №
                    {number}
                    {" "}
- 
                    {" "}
                    {name}
                </Text>
                <ContractorEditPaymentInfo
                    contractor={_contractor}
                    contractorId={_contractor.contractorId}
                    contractorsPossibility={contractorsPossibility}
                    isVisiblePhone={canViewContractorContacts}
                    isVisibleInn={false}
                    isVisibleBankIndicator={false}
                    isVisibleSelfEmployedIndicator={true}
                />
                <div>
                    <Text>
                        Сумма, ₽
                    </Text>
                    <Text bold={true}>
                        {formatAmountWithNullChecking(paymentAmount)}
                    </Text>
                </div>
                <NmTextareaV2
                    required={true}
                    maxRows={3}
                    minRows={2}
                    maxLength={5000}
                    label="Описание выполненных работ для акта"
                    placeholder="Введите описание выполненных работ для акта"
                    error={touched.workActDescription && errors.workActDescription}
                    name="workActDescription"
                    onChange={handleChange}
                    value={values.workActDescription}
                />
                <NmTextareaV2
                    required={true}
                    size="xl"
                    maxRows={3}
                    minRows={2}
                    maxLength={160}
                    label="Описание выполненных работ для чека ФНС России"
                    placeholder="Введите описание выполненных работ для чека ФНС России"
                    error={touched.workFNSDescription && errors.workFNSDescription}
                    name="workFNSDescription"
                    onChange={handleChange}
                    value={values.workFNSDescription}
                />
                <NmTextareaV2
                    maxRows={3}
                    minRows={2}
                    maxLength={5000}
                    label="Комментарий"
                    placeholder="Комментарий"
                    name="comment"
                    error={touched.comment && errors.comment}
                    onChange={handleChange}
                    value={values.comment}
                />
                <NmDateRangePickerV2
                    required={true}
                    size="xl"
                    label="Период работ"
                    minDate={{
                        start: new Date(),
                        end: values.workStartDate || new Date(),
                    }}
                    error={
                        (touched.workStartDate && errors.workStartDate) ||
                        (touched.workEndDate && errors.workEndDate)
                    }
                    startFieldName="workStartDate"
                    endFieldName="workEndDate"
                    value={{
                        workStartDate: values.workStartDate,
                        workEndDate: values.workEndDate,
                    }}
                    onChange={handleChange}
                    returnString={false}
                    isClearable={true}
                />
                <NmDatePicker
                    required={true}
                    readOnlyInput={false}
                    dateFormat="dd.MM.yy"
                    name="actFormingDate"
                    error={touched.actFormingDate && errors.actFormingDate}
                    selected={values.actFormingDate}
                    onChange={handleChange}
                    isClearable={true}
                    label="Дата акта"
                />
                <div className="fluid">
                    <NmRatingFieldsForm
                        handleOnRate={handleChange}
                    >
                        {estimates}
                    </NmRatingFieldsForm>
                </div>
            </NmForm>
        </NmModal>
    );
};

export default CrowdActRegistryEntryEditModal;
