import {
    REVIEWS_CONTRACTOR_ADD_REQUEST,
    REVIEWS_CONTRACTOR_CLEAR_STORE,
    REVIEWS_CONTRACTOR_CONFIRM_REQUEST,
    REVIEWS_CONTRACTOR_DECLINE_REQUEST,
    REVIEWS_CONTRACTOR_DELETE_REQUEST,
    REVIEWS_CONTRACTOR_GET_COUNT_REQUEST,
    REVIEWS_CONTRACTOR_GET_PAGE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_UPDATE_REQUEST,
} from "./actions";

export const getPageReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCountReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_GET_COUNT_REQUEST,
        payload,
    };
};

export const declineReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_DECLINE_REQUEST,
        payload,
    };
};

export const confirmReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_CONFIRM_REQUEST,
        payload,
    };
};

export const updateReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_UPDATE_REQUEST,
        payload,
    };
};


export const deleteReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_DELETE_REQUEST,
        payload,
    };
};

export const addReviewsContractor = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_ADD_REQUEST,
        payload,
    };
};

export const updateReviewsContractorReply = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST,
        payload,
    };
};

export const updateStatusReviewsContractorReply = (payload) => {
    return {
        type: REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST,
        payload,
    };
};

export const clearReviewsContractorStore = () => {
    return {
        type: REVIEWS_CONTRACTOR_CLEAR_STORE,
    };
};