import {
    CONTRACTOR_RESOURCES_EXPORT_REQUEST,
    CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST,
    CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST,
    CONTRACTOR_RESOURCES_GET_PAGE_REQUEST,
    CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST,
    CONTRACTOR_RESOURCES_START_JOB_REQUEST,
    UPDATE_CONTRACTOR_RESOURCES_STORE,
} from "./actions";

export const getPageContractorsResources = (payload) => {
    return {
        type: CONTRACTOR_RESOURCES_GET_PAGE_REQUEST,
        payload,
    };
};

export const exportContractorsResources = (payload) => {
    return {
        type: CONTRACTOR_RESOURCES_EXPORT_REQUEST,
        payload,
    };
};

export const startJobContractorsResources = () => {
    return {
        type: CONTRACTOR_RESOURCES_START_JOB_REQUEST,
    };
};

export const getClientsContractorsResources = () => {
    return {
        type: CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST,
    };
};

export const getProjectsContractorsResources = () => {
    return {
        type: CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST,
    };
};

export const getObjectsContractorsResources = () => {
    return {
        type: CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST,
    };
};

export const updateStoreContractorsResources = (payload) => {
    return {
        type: UPDATE_CONTRACTOR_RESOURCES_STORE,
        payload,
    };
};