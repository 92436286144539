import React from "react";
import {useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import Tabs from "../../components/ActualComponents/Tabs";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import ReviewEditForm from "../contractor/reviews/components/add-form";
import ReviewsFilter from "./components/filter";
import ReviewsList from "./components/reviews-list";

import {useFilter} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {useReviewsAction} from "./hooks/useAction";
import {useReviewsFetchList} from "./hooks/useFetchList";
import {useReviewsFilterDto} from "./hooks/useFilterDto";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {LINK_REVIEWS_LIST} from "../../constants/links";
import {REVIEW_REPLY_STATUS, REVIEW_STATUS} from "../../constants/reviews";
import {ADMIN, NM_MANAGER} from "../../constants/roles";

import {currentUserRestrictionsSelector} from "../../ducks/clientUserRestrictions";

import {
    SUBPAGE_CLIENTS_REVIEWS,
    SUBPAGE_CONTRACTORS_REVIEWS,
} from "../../constants/link-params";

const initFilter = {
    statusesFilter: [],
    clientIdsFilter: [],
    reviewTextFilter: "",
    fioFilter: "",
    phoneFilter: "",
};

function ReviewsPage(props) {
    const {
        match: {
            params: {
                subpage,
            },
        },
    } = props;

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useReviewsFilterDto();

    const {
        fetchList,
        totalCountContractor,
        totalCountClient,
        list,
        totalCount,
        totalPages,
        progressPage,
        progressAction,
    } = useReviewsFetchList({
        pageNum,
        pageSize,
        filter: filterDto,
        subpage,
    });

    const {
        confirmAction,
        setConfirmAction,
        editData,
        closeConfirmModal,
        onConfirmScore,
        onDeclineScore,
        onClickEditReview,
        closeEditModal,
        updateScore,
        changeStatusScoreReply,
    } = useReviewsAction({fetchList, subpage});

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilter});
        setFilterDto({...initFilter});
        setIsSearch(false);
    };

    const getMediaActions = (item) => {
        if (
            ![ADMIN, NM_MANAGER].includes(role)
            || currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsReviews)
        ) {
            return null;
        }

        const {
            contractorScore,
            baseModel: {
                id,
                status,
                reply,
            },
        } = item;

        return {
            renderCount: {
                desktop: 5,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Подтвердить отзыв",
                        color: "light-green",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите опубликовать отзыв?",
                                onConfirm: () => {
                                    onConfirmScore(contractorScore, id);
                                },
                            });
                        },
                    },
                    visible: status === REVIEW_STATUS.DRAFT.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отклонить отзыв",
                        color: "grey",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите отклонить публикацию отзыва?",
                                onConfirm: () => {
                                    onDeclineScore(contractorScore, id);
                                },
                            });
                        },
                    },
                    visible: status === REVIEW_STATUS.DRAFT.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Подтвердить ответ",
                        color: "light-green",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите опубликовать ответ на отзыв?",
                                onConfirm: () => {
                                    changeStatusScoreReply(contractorScore, id, REVIEW_REPLY_STATUS.PUBLISHED);
                                },
                                confirmButton: "Опубликовать",
                            });
                        },
                    },
                    visible: reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отклонить ответ",
                        color: "grey",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите отклонить публикацию ответа на отзыв?",
                                onConfirm: () => {
                                    changeStatusScoreReply(contractorScore, id, REVIEW_REPLY_STATUS.DECLINED);
                                },
                            });
                        },
                    },
                    visible: reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
                    props: {
                        buttonProps: {
                            children: "Изменить статус",
                            color: "grey",
                            isIconPositionEnd: true,
                            isContentFullWidth: true,
                        },
                        children: "Изменить статус",
                        options: [
                            {
                                key: "review",
                                value: "review",
                                text: "Отзыва",
                                onClick: () => {
                                    setConfirmAction({
                                        isOpen: true,
                                        content: status === REVIEW_STATUS.PUBLISHED.VALUE
                                            ? "Вы уверены, что хотите изменить статус отзыва и отклонить публикацию?"
                                            : "Вы уверены, что хотите изменить статус отзыва и опубликовать?",
                                        onConfirm: () => {
                                            if (status === REVIEW_STATUS.PUBLISHED.VALUE) {
                                                onDeclineScore(contractorScore, id);

                                                return;
                                            }

                                            onConfirmScore(contractorScore, id);
                                        },
                                    });
                                },
                                visible: [REVIEW_STATUS.PUBLISHED.VALUE, REVIEW_STATUS.DECLINED.VALUE].includes(status),
                            },
                            {
                                key: "reply",
                                value: "reply",
                                text: "Ответа",
                                onClick: () => {
                                    setConfirmAction({
                                        isOpen: true,
                                        content: reply?.status === REVIEW_REPLY_STATUS.PUBLISHED
                                            ? "Вы уверены, что хотите изменить статус ответа на отзыв и отклонить публикацию?"
                                            : "Вы уверены, что хотите изменить статус ответа на отзыв и опубликовать?",
                                        onConfirm: () => {
                                            const status = reply?.status === REVIEW_REPLY_STATUS.PUBLISHED
                                                ? REVIEW_REPLY_STATUS.DECLINED
                                                : REVIEW_REPLY_STATUS.PUBLISHED;

                                            changeStatusScoreReply(contractorScore, id, status);
                                        },
                                    });
                                },
                                visible: [REVIEW_REPLY_STATUS.PUBLISHED, REVIEW_REPLY_STATUS.DECLINED].includes(reply?.status),
                            },
                        ],
                    },
                    visible: [REVIEW_STATUS.PUBLISHED.VALUE, REVIEW_STATUS.DECLINED.VALUE].includes(status)
                        || [REVIEW_REPLY_STATUS.PUBLISHED, REVIEW_REPLY_STATUS.DECLINED].includes(reply?.status),
                },
            ],
        };
    };

    const renderConfirmModal = () => {
        const {
            isOpen,
            content,
            onConfirm,
            confirmButton,
        } = confirmAction;

        return (
            isOpen &&
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                content={content}
                onCancel={closeConfirmModal}
                onConfirm={onConfirm}
                confirmButton={confirmButton || "Продолжить"}
                cancelButton="Отменить"
                isOnlyConfirm
                loading={progressAction}

            />
        );
    };

    const getTabs = () => {
        const linkClientReviews = LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CLIENTS_REVIEWS);
        const linkContractorReviews = LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CONTRACTORS_REVIEWS);

        return ([
            {
                name: "Отзывы на заказчиков",
                count: totalCountClient,
                link: linkClientReviews,
                active: subpage === SUBPAGE_CLIENTS_REVIEWS,
                onClick: () => {
                    if (!isEqual(filter, initFilter)) {
                        clearFilter();
                    }
                },
            },
            {
                name: "Отзывы на исполнителей",
                count: totalCountContractor,
                link: linkContractorReviews,
                active: subpage === SUBPAGE_CONTRACTORS_REVIEWS,
                onClick: () => {
                    if (!isEqual(filter, initFilter)) {
                        clearFilter();
                    }
                },
            },
        ]);
    };

    const renderEditForm = () => {
        return (
            !isEmpty(editData) &&
            <ReviewEditForm
                editData={editData}
                fetchList={fetchList}
                onClose={closeEditModal}
                submit={updateScore}
                progress={progressAction}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCountContractor + totalCountClient}
                    size="xl"
                >
                    Отзывы
                </NmTitle>
            }
            typeFilter="vertical"
            filtersBase={
                <ReviewsFilter
                    filter={filter}
                    subpage={subpage}
                    submitFilter={submitFilter}
                    onClear={clearFilter}
                    onChangeFilter={onChangeFilter}
                />
            }
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progressPage}
        >
            <Tabs
                panes={getTabs()}
                className="mb-3 mb-md-4"
            />
            {renderConfirmModal()}
            {renderEditForm()}
            {
                list?.length ?
                    <ReviewsList
                        role={role}
                        list={list}
                        getMediaActions={(item) => getMediaActions(item)}
                        onClickEdit={onClickEditReview}
                        fetchList={fetchList}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progressPage}
                    />
            }
        </NmPage>
    );
}

export default ReviewsPage;