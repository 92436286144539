import {all, put, takeEvery} from "@redux-saga/core/effects";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractors/personaldata/access";

const CONTRACTORS_PERSONAL_DATA_ACCESS_REQUEST = "CONTRACTORS_PERSONAL_DATA_ACCESS_REQUEST";
const CONTRACTORS_PERSONAL_DATA_ACCESS_SUCCESS = "CONTRACTORS_PERSONAL_DATA_ACCESS_SUCCESS";
const CONTRACTORS_PERSONAL_DATA_ACCESS_ERROR = "CONTRACTORS_PERSONAL_DATA_ACCESS_ERROR";

const initial = {
    progress: false,
};

export default (state = initial, {type}) => {
    switch (type) {
        case CONTRACTORS_PERSONAL_DATA_ACCESS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CONTRACTORS_PERSONAL_DATA_ACCESS_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case CONTRACTORS_PERSONAL_DATA_ACCESS_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

export const requestAccessToPD = payload => {
    return {
        type: CONTRACTORS_PERSONAL_DATA_ACCESS_REQUEST,
        payload,
    };
};

const requestAccessToPDSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;
        const {errorMessage, success} = yield request.post(`${controller}/request`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTORS_PERSONAL_DATA_ACCESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess({success});

        yield put({type: CONTRACTORS_PERSONAL_DATA_ACCESS_SUCCESS});
    } catch (error) {
        yield put({type: CONTRACTORS_PERSONAL_DATA_ACCESS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTORS_PERSONAL_DATA_ACCESS_REQUEST, requestAccessToPDSaga),
    ]);
}