import {
    KEDO_DIRECTORIES_ADD_POSITION_REQUEST,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_DELETE_POSITION_REQUEST,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_STORE,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST,
} from "./actions";

export const updateKedoDirectoryPosition = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST,
        payload,
    };
};

export const getKedoDirectoryPositionsPage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST,
        payload,
    };
};

export const addKedoDirectoryPosition = (payload) => {
    return {
        type: KEDO_DIRECTORIES_ADD_POSITION_REQUEST,
        payload,
    };
};

export const deleteKedoDirectoryPosition = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DELETE_POSITION_REQUEST,
        payload,
    };
};

export const updateKedoDirectorySubdivision = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST,
        payload,
    };
};

export const getKedoDirectorySubdivisionsPage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST,
        payload,
    };
};

export const addKedoDirectorySubdivision = (payload) => {
    return {
        type: KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST,
        payload,
    };
};

export const deleteKedoDirectorySubdivision = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST,
        payload,
    };
};

export const getKedoDirectoryPageCount = (payload) => {
    return {
        type: KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST,
        payload,
    };
};

export const updateKedoUploadDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST,
        payload,
    };
};

export const getKedoUploadDocumentTypesPage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST,
        payload,
    };
};

export const addKedoUploadDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST,
        payload,
    };
};

export const deleteKedoUploadDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST,
        payload,
    };
};

export const updateKedoTemplateDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
        payload,
    };
};

export const updateKedoTemplateDocumentTypeClientUsage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
        payload,
    };
};

export const getKedoTemplateDocumentTypesPage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST,
        payload,
    };
};

export const getKedoTemplateDocumentTypeClientUsagePage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST,
        payload,
    };
};

export const addKedoTemplateDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST,
        payload,
    };
};

export const deleteKedoTemplateDocumentType = (payload) => {
    return {
        type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST,
        payload,
    };
};

export const getKedoDocumentRoutesPage = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST,
        payload,
    };
};

export const deleteKedoDocumentRoutes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST,
        payload,
    };
};

export const addKedoDocumentRoutes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST,
        payload,
    };
};

export const addFlexKedoDocumentRoutes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST,
        payload,
    };
};

export const updateKedoDocumentRoutes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST,
        payload,
    };
};

export const updateFlexKedoDocumentRoutes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST,
        payload,
    };
};

export const getKedoDocumentRoutesFullModel = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST,
        payload,
    };
};

export const getKedoDocumentRoutesFlexFullModel = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST,
        payload,
    };
};

export const getKedoDocumentRoutesAllTypes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST,
        payload,
    };
};

export const updateKedoDocumentRoutesAllTypes = (payload) => {
    return {
        type: KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST,
        payload,
    };
};

export const updateKedoDirectoriesStore = (payload) => {
    return {
        type: KEDO_DIRECTORIES_UPDATE_STORE,
        payload,
    };
};