export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR";

export const BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST = "BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS = "BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR = "BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR";

export const BFF_CONTRACTOR_REGISTRY_ADD_REQUEST = "BFF_CONTRACTOR_REGISTRY_ADD_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS = "BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_ADD_ERROR = "BFF_CONTRACTOR_REGISTRY_ADD_ERROR";

export const UPDATE_CONTRACTOR_REGISTRY_STORE = "UPDATE_CONTRACTOR_REGISTRY_STORE";