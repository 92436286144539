export const RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE = {
    CHANGED_STATUS: "CHANGED_STATUS",
    INVITED_FOR_ORDER: "INVITED_FOR_ORDER",
    NEW_COMMENT: "NEW_COMMENT",
    CANDIDATE_CREATED: "CANDIDATE_CREATED",
};

export const RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE_TRANSLATE = {
    CHANGED_STATUS: "Смена статуса",
    INVITED_FOR_ORDER: "Приглашение на заказ",
    NEW_COMMENT: "Оставлен комментарий",
    CANDIDATE_CREATED: "Добавлен кандидат",
};

export const RECRUITMENT_CANDIDATE_CREATED_FEED_SOURCE = {
    FROM_RESPONSE: "FROM_RESPONSE",
    MANUAL: "MANUAL",
    COLD_SEARCH: "COLD_SEARCH",
    PLUGIN_SEARCH: "PLUGIN_SEARCH",
};