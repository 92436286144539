import React from "react";
import {useSelector} from "react-redux";

import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {ReactComponent as DoubleCheck} from "../../../images/double-check.svg";
import {ReactComponent as NaimixLogo} from "../../../images/nm-logo-rounded.svg";
import {ReactComponent as AttachedLogo} from "../../../images/push_pin_24.svg";
import {Counter} from "../../ActualComponents/Counter";
import NmLabelText from "../../ActualComponents/NmLabelText";
import Text from "../../ActualComponents/Text";
import Avatar from "../../Avatar";

import bem from "../../../utils/bem";
import {formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls} from "../../../utils/localstorage";

import {COLOR} from "../../../constants/color";

import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";

import "./style.sass";

export default function Dialog(props) {
    const {
        isNamemixChat,
        className,
        isActive,
        contractorId,
        name,
        date,
        lastMessage,
        unreadMessages,
        onClickBlock,
        attached,
        chat,
    } = props;

    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    const isOutgoingLastMessage = lastMessage.userId === currentUserId;

    const [block, element] = bem("dialog", className);

    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const renderAvatar = () => {
        if (isNamemixChat) {
            return (
                <NaimixLogo
                    className={element("avatar")}
                />
            );
        }
        return (
            <Avatar
                className={element("avatar")}
                size="md"
                contractorId={contractorId}
                base64={userAvatarDict[contractorId]}
            />
        );
    };

    function renderMsgCheck() {
        if (lastMessage.read) {
            return <DoubleCheck
                className="mr-2"
                color={COLOR.PRIMARY_100}
            />;
        }

        return <DoneIcon
            className="mr-2"
            color={COLOR.PRIMARY_100}
        />;
    }

    return (
        <div
            className={block({
                active: isActive,
            })}
            onClick={onClickBlock}
        >
            <div>
                {renderAvatar()}
                {
                    Boolean(unreadMessages?.unreadMessageCount) &&
                    <Counter
                        count={unreadMessages?.unreadMessageCount}
                        avatarCounter={true}
                        background={
                            !unreadMessages?.isCurrentUserOrderManager
                            && COLOR.SECONDARY_45
                        }
                    />
                }
            </div>
            <div className={element("content")}>
                <div className={element("primary-name")}>
                    <div className="d-flex align-items-center flex-content-end">
                        <Text.Title
                            level="3"
                            medium
                            className={element("name")}
                        >
                            {name}
                        </Text.Title>
                        {attached && <AttachedLogo className={element("attached")} />}
                    </div>
                    {
                        lastMessage &&
                        <Text
                            level="2"
                            className={element("date")}
                        >
                            {formatLocalDate(date, "dd.MM.yyyy HH:mm")}
                        </Text>
                    }
                </div>
                <div className={element("additional-info")}>

                </div>
                {
                    chat.orderName &&
                    <NmLabelText
                        type="list"
                        className={element("chat-info")}
                        label="Заказ"
                        text={`${chat.orderNum} - ${chat.orderName}`}
                    />
                }
                <Text
                    level="3"
                    className={element("last-message")}
                >
                    {isOutgoingLastMessage && renderMsgCheck()}
                    {`${isOutgoingLastMessage ? "Вы: " : ""}${lastMessage.content || ""}`}
                </Text>
            </div>
        </div>
    );
}