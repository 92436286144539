import {
    getTaskListPage,
    setInvisibleTask,
} from "../../../task/actionCreators";

import {getBffUrl} from "../../../../utils/url";

import {
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
} from "../../../../constants/links";

const controllers = {
    naimixAllTasks: "/adm/tasks/naimix/all-tasks",
    naimixMyTasks: "/adm/tasks/naimix/my-tasks",
    kedoAllTasks: "/adm/tasks/kedo/all-tasks",
    kedoMyTasks: "/adm/tasks/kedo/my-tasks",
};

export const getAdmTaskListPage = (payload) => {
    const controller = getBffUrl(
        {
            [LINK_TASK_LIST_NAIMIX_ALL]: controllers.naimixAllTasks,
            [LINK_TASK_LIST_NAIMIX_MY]: controllers.naimixMyTasks,
            [LINK_TASK_LIST_KEDO_ALL]: controllers.kedoAllTasks,
            [LINK_TASK_LIST_KEDO_MY]: controllers.kedoMyTasks,
        },
        controllers.naimixAllTasks,
    );

    return getTaskListPage({
        url: `${controller}/page`,
        ...payload,
    });
};


export const setInvisibleTaskAdm = (payload) => {
    const controller = getBffUrl(
        {
            [LINK_TASK_LIST_NAIMIX_MY]: controllers.naimixMyTasks,
            [LINK_TASK_LIST_KEDO_MY]: controllers.kedoMyTasks,
        },
        controllers.naimixAllTasks,
    );

    return setInvisibleTask({
        url: `${controller}/set-invisible`,
        ...payload,
    });
};