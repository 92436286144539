import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import OrderListStatus from "../../order-list/order-list-status";
import OrdersFilter from "../../order-list/orders-filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import useAddOrderToGroupAction from "./hooks/useAction";
import {useAddOrdersToGroupFetchData} from "./hooks/useFetchData";
import {useAddOrdersToGroupFilterDto} from "./hooks/useFilterDto";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ORDER_STATUS} from "../../../../constants/clientList";
import {
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {orderClientAdminStatuses} from "../../../../constants/status";

import {history} from "../../../../store/configureStore";

import {
    getOrdersOrderGroupCardSelector,
    getOrdersOrderGroupsActionProgressSelector,
    getOrdersOrderGroupsProgressSelector,
} from "../../../../ducks/bff/orders/order-groups/selectors";
import {
    getOrderListProgressSelector,
    orderListSelector,
    orderTotalCountSelector,
    orderTotalPagesSelector,
} from "../../../../ducks/order";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../constants/link-params";

const initFilterForm = {
    objectIdsFilter: [],
    projectIdsFilter: [],
    nameSubstringFilter: undefined,
    orderNumFilter: undefined,
    statusFilter: "ALL",
    orderContractPaymentTypeFilter: "ALL",
    toDateCreateFilter: undefined,
    fromDateCreateFilter: undefined,
    dateWorkStartFilter: undefined,
    dateWorkEndFilter: undefined,
    specialityIds: [],
    regionsFilter: [],
    visibleToContractor: "all",
    addressFiasIds: [],
};

const ACTION_MASS_OPTION = {
    ADD_TO_GROUP: "ADD_TO_GROUP",
};

function AddOrdersToGroupPage(props) {
    const {
        match: {
            params: {
                clientId,
                orderGroupId,
            },
        },
    } = props;

    const groupCard = useSelector(getOrdersOrderGroupCardSelector);
    const cardProgress = useSelector(getOrdersOrderGroupsProgressSelector);
    const actionProgress = useSelector(getOrdersOrderGroupsActionProgressSelector);
    const orders = useSelector(orderListSelector);
    const listProgress = useSelector(getOrderListProgressSelector);
    const totalCount = useSelector(orderTotalCountSelector);
    const totalPages = useSelector(orderTotalPagesSelector);

    const {
        orderGroupNumber,
    } = groupCard;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {t} = useTranslation();

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useAddOrdersToGroupFilterDto();

    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});

    const {
        fetchList,
    } = useAddOrdersToGroupFetchData({
        clientId,
        orderGroupId,
        orderGroupNumber,
        pageSize,
        pageNum,
        filter: filterDto,
    });

    const {
        addOrdersToGroupAction,
    } = useAddOrderToGroupAction({clientId, orderGroupId, fetchList, clearSelectedRows});

    const clearFilter = () => {
        setFilter({...initFilterForm});
        setFilterDto({...initFilterForm});
        setIsSearch(false);
    };

    const submitFilter = () => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const renderLink = (content, {orderId, clientId, status, archived}) => {
        if (status === ORDER_STATUS.DRAFT) {
            return (
                <span title={archived ? t("order-list.link-order") : ""}>
                    {content}
                </span>
            );
        }

        const link = LINK_ORDER_CARD.replace(":clientId", clientId);

        const linkOrder = link.replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={linkOrder}
            >
                {content}
            </ExtLink>
        );
    };

    const getProjectLink = ({clientId, projectId, projectName}) => {
        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <ExtLink
                title={projectName}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {projectName}
            </ExtLink>
        );
    };

    const getObjectLink = ({clientId, projectId, objectId, objectName}) => {
        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                title={objectName}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {objectName}
            </ExtLink>
        );
    };

    const addSelectedOrderToGroup = () => {
        const orderList = selectedList.filter(item => item.isSelected).map(({orderId}) => orderId);

        addOrdersToGroupAction(orderList);
    };

    const handleOnClickBack = () => {
        history.push(LINK_CLIENT_ORDER_GROUPS_CARD
            .replace(":clientId", clientId)
            .replace(":orderGroupId", orderGroupId),
        );
    };

    const getMobileDropdownMassActionOptions = () => {
        return countSelected ? [
            {
                key: ACTION_MASS_OPTION.ADD_TO_GROUP,
                text: "Добавить в группу",
                value: ACTION_MASS_OPTION.ADD_TO_GROUP,
            },
        ] : [];
    };

    const onClickMobileDropdownItem = ({value: action}) => {
        switch (action) {
            case ACTION_MASS_OPTION.ADD_TO_GROUP:
                return addSelectedOrderToGroup();
            default:
                return;


        }
    };

    const getRows = () => {
        return orders.map(order => {
            const {
                orderId,
                status,
                createDate,
                orderNum,
                name,
                projectName,
                objectId,
                objectName,
                projectId,
                registryId,
                clientId,
            } = order;
            const orderSelected = selectedList.find(item => (item.orderId === orderId)) || {};
            const {isSelected = false} = orderSelected;

            return {
                key: orderId,
                status,
                orderId,
                name,
                orderNum,
                clientId,
                registryId,
                showCheckBox: true,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-start"
                        secondaryHeaderStatus={
                            <OrderListStatus
                                status={status}
                            />
                        }
                        secondaryHeader={`Заказ от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={renderLink(`№${orderNum} - ${name}`, order)}
                        primaryHeaderLink
                        primaryHeaderTwoLines
                        labels={[
                            {
                                label: "Проект",
                                text: getProjectLink({clientId, projectId, projectName}),
                            },
                            {
                                label: "Объект",
                                text: getObjectLink({projectId, objectId, objectName, clientId}),
                            },
                        ]}
                        classNameMainContent="col-16 col-xxl-6"
                        actionsClassName="col-1 col-md-2 col-xl-1 col-xxl-1 justify-content-end"
                        mediaControls={{
                            renderCount: {
                                mobile: 0,
                                tablet: 1,
                                desktop: 1,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "light-green",
                                        children: "Добавить в группу",
                                        onClick:  () => addOrdersToGroupAction([orderId]),
                                    },
                                    asset: {
                                        mobile: {children: "Добавить в группу"},
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    size="xl"
                    handleOnClickBack={handleOnClickBack}
                    text={`Поиск заказов для группы заказов №${orderGroupNumber}`}
                />
            }
            typeFilter="vertical"
            filtersBase={
                <div className="orders__sub-header">
                    <OrdersFilter
                        isProjectForm={false}
                        clientId={clientId}
                        pageData={{
                            pageNum,
                            pageSize,
                            isSearch,
                        }}
                        clearFilter={clearFilter}
                        handleChange={onChangeFilter}
                        submitFilter={submitFilter}
                        statusOptions={dictionaryToOptions(orderClientAdminStatuses)}
                        searchData={filter}
                    />
                </div>
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            totalPages={totalPages}
            isLoaded={cardProgress || listProgress || actionProgress}
        >
            <CheckboxList
                header={
                    <SelectionCountWithAction
                        adaptiveLogic
                        count={countSelected}
                        onClick={addSelectedOrderToGroup}
                        buttonColor="green"
                        buttonContent="Добавить в группу"
                    />
                }
                className="order-list-table"
                rows={getRows()}
                onSelectedRows={handleSelectedRows}
                actionOptions={getMobileDropdownMassActionOptions()}
                onClickActionItem={onClickMobileDropdownItem}
            />
        </NmPage>
    );
}

export default AddOrdersToGroupPage;