import React, {FC} from "react";

import Avatar from "../../../../../Avatar";

import bem from "../../../../../../utils/bem";

import "./style.sass";

interface IProps {
    contractorId: string;
    addedToMyClientGroup?: boolean;
    avatarPresent?: boolean;
}

export const NmListCardAvatar: FC<IProps> = (props) => {
    const {
        contractorId,
        addedToMyClientGroup,
        avatarPresent,
    } = props;

    const [block] = bem("nm-list-card-avatar");

    return (
        <Avatar
            contractorId={contractorId}
            addedToMyClientGroup={addedToMyClientGroup}
            avatarPresent={avatarPresent}
            imageClassName={block()}
        />
    );
};
