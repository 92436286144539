import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";

import {
    getCrowdTaskContractorsCompleted,
    getCrowdTaskContractorsWorking,
    updateStoreCrowdTaskCard,
} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {getClientUserRestrictionsList} from "../../../../../../ducks/clientUserRestrictions";

const useCrowdTaskCardInWorkPageFetch = (props) => {
    const {
        clientId,
        taskId,
        isCompletedPage,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.addCrowdPayments,
            ],
        }));
        
        return () => {
            dispatch(updateStoreCrowdTaskCard({
                workingCard: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchInfo();
    }, [isCompletedPage]);

    const fetchInfo = () => {
        if (isCompletedPage) {
            dispatch(getCrowdTaskContractorsCompleted({
                clientId,
                taskId,
            }));

            return;
        }
        dispatch(getCrowdTaskContractorsWorking({
            clientId,
            taskId,
        }));
    };

    return {
        fetchInfo,
    };
};

export default useCrowdTaskCardInWorkPageFetch;