import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEqual} from "lodash";

import validationSchema from "../validation";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../utils/stringHelper";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES} from "../../../../../constants/clientList";
import {COLOR, COLOR_HO_RE_CA} from "../../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../../constants/finance";
import {LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD} from "../../../../../constants/links";
import {MENU_DESIGN_TYPE} from "../../../../../constants/menu";
import {
    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP,
    ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE,
    ONBOARDING_SCENARIO_UPDATE_ERROR_CODE} from "../../../../../constants/onboarding";

import {history} from "../../../../../store/configureStore";

import {getCitizenshipSelector} from "../../../../../ducks/contractor";
import {downloadDocument} from "../../../../../ducks/documents";
import {
    addOnboardingScenario,
    checkClientBelongsToAnyClientGroup,
    getOnboardingAdditionalDocumentsTypeDict,
    getOnboardingBindingVariants,
    getOnboardingObjectVariants,
    getOnboardingProjectParamsVariants,
    getOnboardingPromoCodeVariants,
    getOnboardingScenarioCard,
    getOnboardingScenarioDefaultWelcomeString,
    onboardingBindingVariantsSelector,
    onBoardingWelcomeStringSelector,
    updateOnboardingScenario,
    updateOnboardingStore,
} from "../../../../../ducks/onboarding";

export const useOnboardingScenarioEditFormData = (props) => {
    const {
        card,
        clientId,
        isEdit,
        fetchList,
        onClose,
        isHasContractors,
    } = props;

    const [openCheckDesignModal, setOpenCheckDesignModal] = useState(false);
    const [confirmData, setConfirmData] = useState(false);

    const _citizenshipsDict = useSelector(getCitizenshipSelector);
    const bindingVariants = useSelector(onboardingBindingVariantsSelector);
    const welcomeString = useSelector(onBoardingWelcomeStringSelector);

    const dispatch = useDispatch();

    const initialDesignParams = {
        customDesignEnabled: false,
        primaryColor: COLOR.OPEN_NAIMIX_PRIMARY_100,
        secondaryColor: COLOR.OPEN_NAIMIX_SECONDARY_100,
        primaryTextColor: COLOR.WHITE,
        secondaryTextColor: COLOR.WHITE,
        welcomeString: "",
        files: [],
        supportPhone: "+7 (800) 500 56 54",
    };

    const initialValues = {
        ...initialDesignParams,
        valueObjectFilter: "",
        name: "",
        objectId: null,
        contractType: null,
        offerContractToSign: false,
        signingAllowedWithoutFullRegistration: false,
        signingAllowedOnlyWithSmzTaxOffer: false,
        signingAllowedWithoutOkTaxStatus: false,
        offerOtherDocumentToSign: false,
        otherDocumentRequired: false,
        clientGroupApplicationMode: null,
        applyProjectParams: false,
        applyPromoCode: false,
        projectParams: null,
        promoCode: null,
        applyClientGroup: false,
        removeFromClientGroupAfterContractTermination: false,
        removeFromClientGroupAfterContractTerminationDaysOffset: "",
        citizenships: [],
        additionalDocumentParams: {
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.SNILS]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.EMPLOYMENT_HISTORY]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.FINGERPRINTING]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_CERTIFICATION_REPORT]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.COVID_19_VACCINATION_CERTIFICATE]: {
                tracked: false,
                required: false,
            },
            [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO]: {
                tracked: false,
                required: false,
            },
        },
        ...getCardInfo(),
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    useEffect(() => {
        if (!welcomeString) {
            return;
        }

        setFieldValue("welcomeString", welcomeString);
    }, [welcomeString]);

    const prevCitizenships = useGetPrevValue(values.citizenships);

    const initialCustomColors = {
        primaryColor: COLOR.BLACK_50,
        secondaryColor: COLOR.BLACK_80,
        primaryTextColor: COLOR.WHITE,
        secondaryTextColor: COLOR.WHITE,
    };

    function getCardInfo() {
        if (!isEdit) {
            return {};
        }

        const {
            documentParams,
            invitationParams,
            designParams,
            contractorParams: {
                citizenshipRule,
                additionalDocumentParams,
                registrationAllowedWithoutInn,
            },
        } = card;

        let citizenships = citizenshipRule?.exactCitizenshipList || [];

        if (citizenshipRule?.citizenshipGroup === ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) {
            citizenships = [
                ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS,
                citizenshipsDict.RU.value,
            ];
        }

        if (citizenshipRule?.citizenshipGroup === ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) {
            citizenships = [
                ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS,
                ...Object.keys(_citizenshipsDict).filter(item => item !== citizenshipsDict.RU.value),
            ];
        }

        return {
            ...card,
            boundScenarios: card.boundScenarios ? card.boundScenarios.map(item => item.scenarioId) : [],
            additionalDocumentParams,
            registrationAllowedWithoutInn,
            ...documentParams,
            ...invitationParams,
            ...designParams,
            citizenships,
            valueObjectFilter: card.objectName || "",
        };
    }

    useEffect(() => {
        dispatch(getOnboardingScenarioDefaultWelcomeString({
            clientId,
        }));
        dispatch(getOnboardingProjectParamsVariants({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize: 100,
        }));
        dispatch(getOnboardingPromoCodeVariants({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize: 100,
        }));
        dispatch(getOnboardingBindingVariants({
            clientId,
            pageNum: 1,
            pageSize: 100,
        }));
        dispatch(checkClientBelongsToAnyClientGroup({
            clientId,
        }));

        if (card.scenarioId) {
            dispatch(getOnboardingScenarioCard({
                clientId,
                scenarioId: card.scenarioId,
            }));
        }

        return () => {
            dispatch(updateOnboardingStore({
                promoCodeVariants: [],
                projectParamsVariants: [],
                additionalDocTypeDict: {},
                welcomeString: "",
            }));
        };
    }, []);

    const onCloseConfirmModal = () => {
        setConfirmData({});
    };

    const handleChange = (e, {name, value, checked}) => {
        if (name === "customDesignEnabled") {
            resetDesignParams(checked);

            return;
        }

        if (name === "offerContractToSign" && !checked) {
            setValues({
                ...values,
                contractType: null,
                signingAllowedWithoutFullRegistration: false,
                signingAllowedOnlyWithSmzTaxOffer: false,
                signingAllowedWithoutOkTaxStatus: false,
                registrationAllowedWithoutInn: false,
                offerContractToSign: checked,
            });

            return;
        }

        if (name === "offerOtherDocumentToSign" && !checked) {
            setValues({
                ...values,
                otherDocumentRequired: false,
                offerOtherDocumentToSign: checked,
            });

            return;
        }

        if (name === "applyProjectParams" && !checked) {
            setValues({
                ...values,
                projectParams: null,
                applyProjectParams: checked,
            });

            return;
        }

        if (name === "applyPromoCode" && !checked) {
            setValues({
                ...values,
                promoCode: null,
                applyPromoCode: checked,
            });

            return;
        }

        if (name === "contractType" && value === ORDER_WORK_REPORT_TYPE.CIVIL) {
            setValues({
                ...values,
                signingAllowedOnlyWithSmzTaxOffer: false,
                signingAllowedWithoutOkTaxStatus: false,
                contractType: value,
            });

            return;
        }

        if (name === "contractType" && value === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            setValues({
                ...values,
                signingAllowedOnlyWithSmzTaxOffer: false,
                signingAllowedWithoutOkTaxStatus: false,
                signingAllowedWithoutFullRegistration: false,
                registrationAllowedWithoutInn: false,
                contractType: value,
            });

            return;
        }

        if (name === "applyClientGroup" && !checked) {
            setValues({
                ...values,
                clientGroupApplicationMode: null,
                applyClientGroup: checked,
                removeFromClientGroupAfterContractTerminationDaysOffset: "",
                removeFromClientGroupAfterContractTermination: false,
            });

            return;
        }

        if (name === "applyClientGroup") {
            setValues({
                ...values,
                applyClientGroup: checked,
                clientGroupApplicationMode: ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.INSTANTLY,
            });

            return;
        }

        if (name === "removeFromClientGroupAfterContractTermination" && !checked) {
            setValues({
                ...values,
                removeFromClientGroupAfterContractTerminationDaysOffset: "",
                removeFromClientGroupAfterContractTermination: checked,
            });

            return;
        }

        if (name === "objectId") {
            setValues({
                ...values,
                objectId: value,
                offerOtherDocumentToSign: false,
                otherDocumentRequired: false,
            });

            return;
        }

        setFieldValue(name,typeof checked === "boolean" ? checked : value);
    };

    const handleChangeAdditionalDocumentParams = (block, name, checked) => {
        let _values = {};

        if (name === "tracked" && !checked) {
            _values = {required: false};
        }

        setFieldValue("additionalDocumentParams", {
            ...values.additionalDocumentParams,
            [block]: {
                ...values.additionalDocumentParams[block],
                [name]: checked,
                ..._values,
            },
        });
    };

    const handleChangeCitizenshipRule = (e, {value}) => {
        // При отключении чекбоксов "Только гражданство РФ" и "Только иностранные граждане"
        if (
            (
                !value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) &&
                values.citizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS)
            ) ||
            (
                !value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) &&
                values.citizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS)
            )
        ) {
            setFieldValue("citizenships", []);

            return;
        }

        // Ситуация, когда было выбрано "Только граждане РФ" + "Россия" и снимаем чекбокс "Россия"
        if (
            value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) &&
            value.length === 1 &&
            prevCitizenships &&
            prevCitizenships.length > 1
        ) {
            setFieldValue("citizenships", []);

            return;
        }

        // Необходимо отключить чекбокс "Только граждане РФ" если он активен и кликаем по иностранному гражданству,
        if (
            prevCitizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) &&
            value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS)
        ) {
            const isNoResidentExist = value.some(value => {
                return ![
                    citizenshipsDict.RU.value,
                    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS,
                ].includes(value);
            });

            if (isNoResidentExist) {
                const citizenshipsWithoutResidentsCheckbox = value.filter(value => {
                    return ![
                        ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS,
                    ].includes(value);
                });

                setFieldValue("citizenships", citizenshipsWithoutResidentsCheckbox);

                return;
            }
        }

        // Необходимо отключить чекбокс "Только иностранные гражданства" если он активен и кликаем по российскому гражданству,
        if (
            prevCitizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) &&
            value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) &&
            (
                prevCitizenships.length > value.length ||
                value.includes(citizenshipsDict.RU.value)
            )
        ) {
            const _values = value.filter(value => {
                return value !== ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS;
            });
            setFieldValue("citizenships", _values);

            return;
        }

        // Если выбираем чекбокс "Только граждане РФ" и до этого он не был выбран,
        // Проставляем также гражданство "Россия"
        if (
            value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) &&
            !prevCitizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS)
        ) {
            setFieldValue("citizenships", [
                ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS,
                citizenshipsDict.RU.value,
            ]);

            return;
        }

        // Если выбираем чекбокс "Только иностранные граждане" и до этого он не был выбран,
        // Проставляем также всех иностранных граждан
        if (
            value.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) &&
            !prevCitizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS)
        ) {
            setFieldValue("citizenships", [
                ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS,
                ...Object.keys(_citizenshipsDict).filter(item => item !== citizenshipsDict.RU.value),
            ]);

            return;
        }

        setFieldValue("citizenships", value);
    };

    const resetDesignParams = (checked) => {
        if (checked) {
            setValues({
                ...values,
                customDesignEnabled: true,
                ...initialCustomColors,
            });

            return;
        }

        setValues({
            ...values,
            ...initialDesignParams,
        });
    };

    const getCitizenshipsGroup = () => {
        if (values.citizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS)) {
            return ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS;
        }

        if (values.citizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS)) {
            return ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS;
        }

        return null;
    };

    function onSubmit() {
        if (!isValid) {
            return;
        }

        const formData = new FormData();

        if (values.files?.length) {
            const [file] = values.files;

            formData.append("customLogoFile", file);
        }

        const {
            name,
            objectId,
            additionalDocumentParams,
            applyProjectParams,
            projectParams,
            applyPromoCode,
            promoCode,
            applyClientGroup,
            clientGroupApplicationMode,
            removeFromClientGroupAfterContractTermination,
            removeFromClientGroupAfterContractTerminationDaysOffset,
            customDesignEnabled,
            primaryColor,
            secondaryColor,
            primaryTextColor,
            secondaryTextColor,
            welcomeString,
            supportPhone,
            offerContractToSign,
            contractType,
            signingAllowedWithoutFullRegistration,
            signingAllowedWithoutOkTaxStatus,
            signingAllowedOnlyWithSmzTaxOffer,
            offerOtherDocumentToSign,
            otherDocumentRequired,
            boundScenarios,
            citizenships,
            registrationAllowedWithoutInn,
        } = values;

        const citizenshipGroup = getCitizenshipsGroup();

        const designParams = {
            customDesignEnabled,
            primaryColor,
            secondaryColor,
            primaryTextColor,
            secondaryTextColor,
            welcomeString,
            supportPhone: removePhoneMask(supportPhone),
        };
        const citizenshipRule = {
            citizenshipGroup,
            exactCitizenshipList: Boolean(citizenshipGroup) || !citizenships.length ? null : citizenships,
        };

        const model = {
            clientId,
            name,
            objectId,
            boundScenarios: boundScenarios ? boundScenarios.map(item => {
                const {clientId} = bindingVariants.find(_item => _item.scenarioId === item) || {};
                return {
                    clientId,
                    scenarioId: item,
                };
            }) : [],
            documentParams: {
                offerContractToSign,
                contractType,
                signingAllowedWithoutFullRegistration,
                signingAllowedWithoutOkTaxStatus,
                signingAllowedOnlyWithSmzTaxOffer,
                offerOtherDocumentToSign,
                otherDocumentRequired,
            },
            contractorParams: {
                additionalDocumentParams,
                registrationAllowedWithoutInn,
                citizenshipRule,
            },
            invitationParams: {
                applyProjectParams,
                projectParams,
                applyPromoCode,
                promoCode,
                applyClientGroup,
                clientGroupApplicationMode,
                removeFromClientGroupAfterContractTermination,
                removeFromClientGroupAfterContractTerminationDaysOffset,
            },
            designParams,
        };

        const hasContractorModel = {
            contractorParams: {
                ...card.contractorParams,
                citizenshipRule,
            },
            designParams,
        };

        const _model = isHasContractors ? hasContractorModel : model;

        formData.append("model", new Blob([JSON.stringify({
            ...card,
            ..._model,
        })], {
            type: "application/json",
        }));

        if (isEdit) {
            dispatch(updateOnboardingScenario({
                formData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
                onError: (code) => {
                    if (code === ONBOARDING_SCENARIO_UPDATE_ERROR_CODE.UPDATED_ONBOARDING_SCENARIO_ALREADY_IN_USE_BY_CONTRACTORS) {
                        setConfirmData({
                            submit: onCloseConfirmModal,
                            content: "По сценарию был выполнен переход, редактирование недоступно, измененные данные не сохранены",
                        });
                    }
                },
            }));

            return;
        }

        dispatch(addOnboardingScenario({
            formData,
            onSuccess: ({scenarioId}) => {
                onClose();

                const link = LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD
                    .replace(":clientId", clientId)
                    .replace(":scenarioId", scenarioId);

                history.push(link);
            },
        }));
    }

    const fetchObjects = () => {
        const {
            valueObjectFilter,
        } = values;

        dispatch(getOnboardingObjectVariants({
            clientId,
            nameFilter: handleFormString(valueObjectFilter),
            pageNum: 1,
            pageSize: 100,
        }));
    };

    const onSearchChangeObject = (valueObjectFilter) => {
        setFieldValue("valueObjectFilter", valueObjectFilter);
    };

    useEffect(() => {
        const citizenshipGroup = getCitizenshipsGroup();

        dispatch(getOnboardingAdditionalDocumentsTypeDict({
            citizenshipGroup,
            exactCitizenshipList: (Boolean(citizenshipGroup) || !values.citizenships.length) ? null : values.citizenships,
        }));

        if (isEqual(values.citizenships.filter(item => ![ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS].includes(item)), [citizenshipsDict.RU.value])) {
            setFieldValue("additionalDocumentParams", {
                ...values.additionalDocumentParams,
                [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.FINGERPRINTING]: {
                    tracked: false,
                    required: false,
                },
                [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_CERTIFICATION_REPORT]: {
                    tracked: false,
                    required: false,
                },
            });
        }

        if (!values.citizenships.includes(citizenshipsDict.RU.value)) {
            handleChangeAdditionalDocumentParams(CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO, "tracked", false);
        }
    }, [values.citizenships]);

    useEffect(() => {
        fetchObjects();
    }, [values.valueObjectFilter]);

    useEffect(() => {
        if (values.customLogoFileLink) {
            dispatch(downloadDocument({
                downloadLink: values.customLogoFileLink,
                fileName: values.customLogoFileName,
                isDownload: false,
                getResultFile: (result) => {
                    setFieldValue("files", [result]);
                },
            }));
        }
    }, [values.customLogoFileLink]);

    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleChangeAdditionalDocumentParams,
        handleChangeCitizenshipRule,
        handleSubmit,
        touched,
        openCheckDesignModal,
        setOpenCheckDesignModal,
        onCloseConfirmModal,
        confirmData,
        onSearchChangeObject,
    };
};