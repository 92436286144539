import {
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_SUCCESS,
    CLEAR_CONTRACTOR_TASK_HISTORY_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.tasks,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case CLEAR_CONTRACTOR_TASK_HISTORY_STORE: {
            return initial;
        }
        default:
            return state;
    }
};