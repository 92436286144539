import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmBadge from "../../../../../components/NmBadge";

import dateFormat, {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {getClientNameWithBrand} from "../../../../../utils/stringHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {FC_REGISTRY_ITEM_STATUS} from "../../../../../constants/fcRegistries";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../../constants/finance";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_REGISTRY_FC_CARD,
} from "../../../../../constants/links";

import {history} from "../../../../../store/configureStore";

import {getRegistryFrameContractShortItemsSelector} from "../../../../../ducks/bff/contractor-сard/documents/selectors";
import {
    getDocumentTypeDictsSelector,
} from "../../../../../ducks/documents";

import "./style.sass";

const ContractorDocumentsRegistry = (props) => {
    const {
        location,
    } = props;
    
    const {t} = useTranslation();
    const list = useSelector(getRegistryFrameContractShortItemsSelector);

    const typeDict = useSelector(getDocumentTypeDictsSelector);

    const getStatus = (status) => {
        switch (status) {
            case FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE: return {
                text: t("contractor-documents.registryShortList-awaitingSignature"),
                color: "light-green",
            };
            case FC_REGISTRY_ITEM_STATUS.SIGNED: return {
                text: t("contractor-documents.registryShortList-signed"),
                color: "green",
            };
            case FC_REGISTRY_ITEM_STATUS.DECLINED: return {
                text: t("contractor-documents.registryShortList-declined"),
                color: "red",
            };
            case FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR: return {
                text: t("contractor-documents.registryShortList-signaturePreparationError"),
                color: "red",
            };
            case FC_REGISTRY_ITEM_STATUS.CREATED: return {
                text: t("contractor-documents.registryShortList-created"),
                color: "gray",
            };
            case FC_REGISTRY_ITEM_STATUS.SIGNATURE_ERROR: return {
                text: "Ошибка при подписании",
                color: "red",
            };
            default: return {
                text: "",
                color: null,
            };
        }
    };

    const showFcRegistry = ({clientId, registryId}) => {
        const link = LINK_CLIENT_REGISTRY_FC_CARD.replace(":clientId", clientId).replace(":registryId", registryId);

        return () => {
            const state = {
                prevPath: location.pathname,
            };
            history.push(link, state);
        };
    };

    const getRows = () => {
        return list.map((doc) => {
            const {
                clientId,
                clientName,
                clientBrand,
                status,
                declinedReason,
                frameContractType,
                contractType,
                createdDateTime,
                objectId,
                projectId,
                objectName,
            } = doc;

            const linkClient = LINK_CLIENT_INFO.replace(":clientId", clientId);
            const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
                .replace(":objectId", objectId)
                .replace(":clientId", clientId)
                .replace(":projectId", projectId);

            const client = {
                label: t("contractor-documents.registryShortList-client"), text: (
                    <Link to={linkClient}>
                        {getClientNameWithBrand(clientName, clientBrand)}
                    </Link>
                ),
            };

            const object = objectId && {
                label: "Объект", text: (
                    <Link to={objectLink}>
                        {objectName}
                    </Link>
                ),
            };

            const type = frameContractType
                ? typeDict[frameContractType]
                : ORDER_WORK_REPORT_TYPE_TRANSLATE[contractType];


            return {
                ...doc,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={`${t("contractor-documents.registryShortList-dateTime")} ${dateFormat(convertUtcToLocal(createdDateTime))}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={getStatus(status).color || "gray"}
                                text={getStatus(status).text || status}
                                tooltipPosition="right-top"
                                rightTooltipText={declinedReason}
                            />
                        }
                        labels={[
                            {label: "Тип договора", text: type || ""},
                            client,
                            object,
                        ]}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 1,
                                tablet: 1,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: showFcRegistry(doc),
                                        children: "Перейти в реестр",
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    return (
        <CheckboxList rows={getRows()} />
    );
};

export default ContractorDocumentsRegistry;