import React, {useContext} from "react";
import {useDispatch} from "react-redux";

import {ReactComponent as FileDownloadIcon} from "../../../../../images/file_download.svg";
import {TemplatesContext} from "../../../contexts/context";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, isClientUser, NM_MANAGER} from "../../../../../constants/roles";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../constants";

import {downloadDocument} from "../../../../../ducks/documents";
import {
    deleteDocumentCustomTemplate,
    setDefaultDocumentsCustomTemplateById,
} from "../../../../../ducks/documentsCustomTemplate";

export default function useTemplateContractsAction(params) {
    const {
        clientId,
        fetchList,
        openVersionsTemplateModal,
        openModalClientListTemplateModal,
        onOpenConfirm,
        documentTypeFilter,
    } = params;
    const {
        onOpenEdit,
    } = useContext(TemplatesContext);
    const dispatch = useDispatch();
    const role = ls(USER_ROLE);

    const downloadFile = ({downloadLink, fileName, extension}) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
            fileName,
            extension,
        }));
    };

    const setDefaultTemplate = ({id}) => {
        dispatch(setDefaultDocumentsCustomTemplateById({
            id,
            clientId,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    const getListMediaControls = (item) => {
        const {
            htmlDownloadLink,
            pdfDownloadLink,
            inUse,
            id,
            name,
            orderContractPaymentType,
            orderKind,
            naimixTemplate,
            defaultTemplate,
            documentType,
        } = item;

        if (isClientUser(role)) {
            return {
                renderCount: {
                    desktop: 1,
                    tablet: 0,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            icon: (
                                <FileDownloadIcon />
                            ),
                            size: "lg",
                            color: "light-green",
                            children: "PDF",
                            onClick: () => {
                                downloadFile({
                                    downloadLink: pdfDownloadLink,
                                    fileName: `${name}.pdf`,
                                });
                            },
                        },
                        asset: {
                            mobile: {
                                children: "Скачать PDF",
                            },
                            tablet: {
                                children: "Скачать PDF",
                            },
                        },
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: "Сделать шаблоном по умолчанию",
                            onClick: () => {
                                setDefaultTemplate({
                                    id,
                                });
                            },
                        },
                        visible: !defaultTemplate,
                    },
                ],
            };
        }

        if (![ADMIN, NM_MANAGER].includes(role)) {
            return null;
        }

        const isHiddenEditActions = documentTypeFilter === TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION
            && !orderContractPaymentType;

        return {
            renderCount: {
                desktop: 2,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: (
                            <FileDownloadIcon />
                        ),
                        size: "lg",
                        color: "light-green",
                        children: "PDF",
                        onClick: () => {
                            downloadFile({
                                downloadLink: pdfDownloadLink,
                                fileName: `${name}.pdf`,
                            });
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Скачать PDF",
                        },
                        tablet: {
                            children: "Скачать PDF",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: (
                            <FileDownloadIcon />
                        ),
                        size: "lg",
                        color: "light-green",
                        children: "HTML",
                        onClick: () => {
                            downloadFile({
                                downloadLink: htmlDownloadLink,
                                fileName: `${name}.html`,
                            });
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Скачать HTML",
                        },
                        tablet: {
                            children: "Скачать HTML",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать шаблон",
                        onClick: () => {
                            onOpenEdit({
                                id,
                                name,
                                documentType,
                                orderContractPaymentType,
                                orderKind,
                                htmlDownloadLink,
                            });
                        },
                    },
                    visible: !naimixTemplate && !clientId && !isHiddenEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать список компаний",
                        onClick: () => {
                            openModalClientListTemplateModal({customDocumentTemplateIdFilter: id});
                        },
                    },
                    visible: !naimixTemplate && !isHiddenEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Версии шаблона",
                        onClick: () => {
                            openVersionsTemplateModal({templateIdFilter: id});
                        },
                    },
                    visible: !isHiddenEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Удалить",
                        onClick: () => onOpenConfirm({
                            content: `Вы действительно хотите удалить шаблон документа "${name}"?`,
                            onConfirm: () => {
                                dispatch(deleteDocumentCustomTemplate({
                                    id,
                                    onSuccess: fetchList,
                                }));
                            },
                        }),
                    },
                    visible: !inUse && !isHiddenEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Сделать шаблоном по умолчанию",
                        onClick: () => {
                            setDefaultTemplate({
                                id,
                            });
                        },
                    },
                    visible: Boolean(clientId) && !defaultTemplate && !isHiddenEditActions,
                },
            ],
        };
    };


    return {
        getListMediaControls,
    };
}