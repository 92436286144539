import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {simpleContractorValidationScheme} from "../validation";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {toastSuccess} from "../../../../utils/toastHelper";

import {LINK_CONTRACTOR_PERSONAL_INFO} from "../../../../constants/links";
import {CONTRACTOR_MESSAGE} from "../../../../constants/messages";
import {RUSSIA_PHONE_CODE_ID} from "../../../../constants/phone";

import {history} from "../../../../store/configureStore";

import {addContractorsRegistry} from "../../../../ducks/bff/contractors/registry/actionCreators";

const initialValues = {
    lastName: "",
    firstName: "",
    patronymic: "",
    phone: "",
    email: "",
    mainSpecialityId: "",
    workAddressFiasId: "",
    remoteWork: false,
    additionalSpecialityIds: [],
    departureAddressesFiasIds: [],
    promocode: "",
    locatedOutsideRussia: false,
    phoneCodeId: RUSSIA_PHONE_CODE_ID,
};

const useContractorNewForm = () => {
    const dispatch = useDispatch();

    const {
        values,
        errors,
        isValid,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: simpleContractorValidationScheme,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true,
    });

    function onSubmit(values) {
        if (!isValid) {
            return;
        }

        dispatch(addContractorsRegistry({
            ...values,
            phone: removePhoneMask(values.phone).trim(),
            getResult: ({guid}) => {
                toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_ADD_SUCCESS_TEXT);
                history.push(LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", guid));
            },
        }));
    }

    function handleEnterPressed(event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    function handleChange(e, {checked, name: field, value}) {
        setFieldValue(field, typeof checked === "boolean" ? checked : value);
    }

    return {
        values,
        setFieldValue,
        errors,
        handleSubmit,
        handleChange,
        handleEnterPressed,
    };
};

export default useContractorNewForm;