export const TARIFF_SCALE_GET_PAGE_REQUEST = "TARIFF_SCALE_GET_PAGE_REQUEST";
export const TARIFF_SCALE_GET_PAGE_SUCCESS = "TARIFF_SCALE_GET_PAGE_SUCCESS";
export const TARIFF_SCALE_GET_PAGE_ERROR = "TARIFF_SCALE_GET_PAGE_ERROR";

export const TARIFF_SCALE_ADD_REQUEST = "TARIFF_SCALE_ADD_REQUEST";
export const TARIFF_SCALE_ADD_SUCCESS = "TARIFF_SCALE_ADD_SUCCESS";
export const TARIFF_SCALE_ADD_ERROR = "TARIFF_SCALE_ADD_ERROR";

export const TARIFF_SCALE_DELETE_REQUEST = "TARIFF_SCALE_DELETE_REQUEST";
export const TARIFF_SCALE_DELETE_SUCCESS = "TARIFF_SCALE_DELETE_SUCCESS";
export const TARIFF_SCALE_DELETE_ERROR = "TARIFF_SCALE_DELETE_ERROR";

export const TARIFF_SCALE_UPDATE_REQUEST = "TARIFF_SCALE_UPDATE_REQUEST";
export const TARIFF_SCALE_UPDATE_SUCCESS = "TARIFF_SCALE_UPDATE_SUCCESS";
export const TARIFF_SCALE_UPDATE_ERROR = "TARIFF_SCALE_UPDATE_ERROR";

export const TARIFF_SCALE_CHECK_EXIST_REQUEST = "TARIFF_SCALE_CHECK_EXIST_REQUEST";
export const TARIFF_SCALE_CHECK_EXIST_SUCCESS = "TARIFF_SCALE_CHECK_EXIST_SUCCESS";
export const TARIFF_SCALE_CHECK_EXIST_ERROR = "TARIFF_SCALE_CHECK_EXIST_ERROR";

export const TARIFF_SCALE_GET_BY_ID_REQUEST = "TARIFF_SCALE_GET_BY_ID_REQUEST";
export const TARIFF_SCALE_GET_BY_ID_SUCCESS = "TARIFF_SCALE_GET_BY_ID_SUCCESS";
export const TARIFF_SCALE_GET_BY_ID_ERROR = "TARIFF_SCALE_GET_BY_ID_ERROR";

export const TARIFF_SCALE_IMPORT_REQUEST = "TARIFF_SCALE_IMPORT_REQUEST";
export const TARIFF_SCALE_IMPORT_SUCCESS = "TARIFF_SCALE_IMPORT_SUCCESS";
export const TARIFF_SCALE_IMPORT_ERROR = "TARIFF_SCALE_IMPORT_ERROR";

export const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST";
export const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS";
export const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR";

export const TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST = "TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST";
export const TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS = "TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS";
export const TARIFF_SCALE_CHECK_DOWNLOAD_ERROR = "TARIFF_SCALE_CHECK_DOWNLOAD_ERROR";

export const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST";
export const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS";
export const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR";

export const TARIFF_SCALE_UPDATE_STORE = "TARIFF_SCALE_UPDATE_STORE";