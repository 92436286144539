import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";

import {usePagination} from "../../../../../hooks/usePagination";
import useFinanceCrowdPaymentsLogModalFetch from "./hooks/useFetch";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {
    bffCrowdPaymentLogListSelector,
    bffCrowdPaymentLogProgressSelector,
    bffCrowdPaymentLogTotalCountSelector,
    bffCrowdPaymentLogTotalPagesSelector,
} from "../../../../../ducks/bff/crowd/payment/selectors";

const FinanceCrowdPaymentsLogModal = (props) => {
    const {
        paymentData,
        onClose = () => {},
    } = props;

    const list = useSelector(bffCrowdPaymentLogListSelector);
    const totalCount = useSelector(bffCrowdPaymentLogTotalCountSelector);
    const totalPages = useSelector(bffCrowdPaymentLogTotalPagesSelector);
    const progress = useSelector(bffCrowdPaymentLogProgressSelector);

    const {
        clientId,
        paymentId,
        contractorId,
        taskNumber,
        taskName,
        paymentNumber,
        workActDescription,
        clientName,
    } = paymentData;

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    useFinanceCrowdPaymentsLogModalFetch({
        pageNum,
        pageSize,
        clientId,
        paymentId,
        contractorId,
    });

    const getRows = () => {
        return list.map(item => {
            const {
                eventId,
                createDate,
                type,
                initiatorFIO,
            } = item;

            return {
                ...item,
                key: eventId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Дата",
                                text: formatLocalDate(createDate, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Событие",
                                text: type?.text || "-",
                                noWrap: false,
                                inline: true,
                            },
                            {
                                label: "Инициатор",
                                text: initiatorFIO,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог оплаты по заданию #
                    {taskNumber}
                    {" "}
- 
                    {" "}
                    {taskName}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                header={
                    <div className="col-16">
                        <NmLabelText
                            type="page"
                            label="Компания"
                            text={clientName || "-"}
                        />
                        <NmLabelText
                            type="page"
                            label={`Задание №${taskNumber}`}
                            text={taskName || "-"}
                            noWrap={false}
                            inline={true}
                        />
                        <NmLabelText
                            type="page"
                            label="Оплата по заданию"
                            text={paymentNumber || "-"}
                        />
                        <NmLabelText
                            type="page"
                            label="Идентификатор оплаты по заданию"
                            text={paymentId || "-"}
                        />
                        <NmLabelText
                            type="page"
                            label="Описание работы"
                            text={workActDescription || "-"}
                            noWrap={false}
                            inline={true}
                        />
                    </div>
                }
                overflowUnset
                noPadding
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </NmPage>
        </NmModal>
    );
};

export default FinanceCrowdPaymentsLogModal;