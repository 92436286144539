import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import CreateEditRegistryPatent from "../create-edit-registry-patent";
import PatentsListFilter from "./filter";
import PatentsListLog from "./log";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {isNMUsers} from "../../../utils/access";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    formatAmount,
    formatNumber,
} from "../../../utils/stringFormat";
import {
    handleFormString,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../../constants/links";
import {
    PATENTS_REGISTRY_STATUS,
    PATENTS_REGISTRY_STATUS_COLOR_TRANSCRIPT,
    PATENTS_REGISTRY_STATUS_DICT,
} from "../../../constants/patentsRegistry";
import {
    ADMIN, CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER, NM_OPERATOR,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {clientObjectListSelector} from "../../../ducks/clientObject";
import {getBalanceDepositPatents, patentsDepositBalanceSelector} from "../../../ducks/patentsDeposits";
import {
    addPatentsRegistry,
    archivePatentsRegistry,
    checkPatentRegistryPaymentsDuplicates,
    createDuplicatePatentRegistry,
    deleteFailedPatentRegistryPayments,
    getPatentsRegistries,
    patentsRegistriesActionRegistryProgressSelector,
    patentsRegistriesListSelector,
    patentsRegistriesListTotalPagesSelector,
    patentsRegistriesProgressListSelector,
    patentsRegistriesTotalCountSelector,
    patentsRegistryArchivedParamSelector,
    payPatentRegistry,
    payUnpaidPatentRegistry,
    restorePatentsRegistry,
    updatePatentsRegistry} from "../../../ducks/patentsRegistry";

import "./style.sass";

class PatentsList extends Component {
    constructor(props) {

        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
            prevCardArchived,
        } = props;

        this.state = {
            archived: prevCardArchived || false,
            pageNum: 1,
            pageSize: 25,
            registryObj: {},
            error: {},
            payModel: [],
            confirmData: {},
            statusFilter: "ALL",
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId;
        this.isClientCard = !isNullOrWhitespace(this.clientId);
    }

    componentDidMount() {
        const {
            getBalanceDepositPatents,
        } = this.props;

        getBalanceDepositPatents({
            clientId: this.clientId,
        });

        this.fetchRegistryList();
    }

    get isClientArchived() {
        const {
            client: {
                archived,
            },
        } = this.props;

        return archived;
    }

    fetchRegistryList = () => {
        const {getPatentsRegistries} = this.props;

        const {
            pageNum,
            pageSize,
            registryNameFilter,
            statusFilter,
            fromDateFilter,
            toDateFilter,
            archived,
            creatorFioFilter,
            projectIds,
            objectIds,
        } = this.state;

        getPatentsRegistries({
            pageNum,
            pageSize,
            clientIdFilter: this.clientId,
            archivedFilter: archived,
            registryNameFilter,
            statusFilter: statusFilter === "ALL" ? undefined : statusFilter,
            fromDateFilter,
            toDateFilter,
            creatorNameFilter: handleFormString(creatorFioFilter),
            projectIds: !isEmpty(projectIds) ? projectIds : undefined,
            objectIds: !isEmpty(objectIds) ? objectIds : undefined,
        });
    };

    renderRegistryLink = ({patentRegistryId, registryName}) => {
        const link = LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD.replace(":clientId", this.clientId).replace(":patentRegistryId", patentRegistryId);

        return (
            <ExtLink
                className="fc-registry-list__registry-link"
                to={link}
            >
                {registryName}
            </ExtLink>
        );
    };

    showConfirmWindow = ({confirmMethod, confirmText, confirmTitle}) => {
        return () => {
            this.setState({
                openConfirmWindow: true,
                confirmMethod,
                confirmData: {
                    confirmText,
                    confirmTitle,
                },
            });
        };
    };

    handleCancelConfirm = () => {
        this.setState({
            openConfirmWindow: false,
            confirmText: "",
            confirmMethod: () => {
            },
        });
    };

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            confirmData: {
                confirmText,
            },
            confirmMethod,
        } = this.state;

        const {t} = this.props;

        return openConfirmWindow &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={confirmMethod}
                confirmButton={t("button.confirm")}
                cancelButton={t("button.cancel")}
                content={confirmText}
                size="sm"
            />;
    }

    payRegistryAction = (patentRegistryId) => {
        const {payPatentRegistry} = this.props;

        payPatentRegistry({
            clientId: this.clientId,
            patentRegistryId,
            onSuccess: () => {
                this.fetchRegistryList();
                this.handleCancelConfirm();
            },
        });
    };

    payUnpaidRegistry = (patentRegistryId) => {
        const {payUnpaidPatentRegistry} = this.props;

        payUnpaidPatentRegistry({
            clientId: this.clientId,
            patentRegistryId,
            onSuccess: () => {
                this.fetchRegistryList();
                this.handleCancelConfirm();
            },
        });
    };

    checkDuplicatesPayments = (patentRegistryId) => {
        return () => {
            const {checkPatentRegistryPaymentsDuplicates} = this.props;

            checkPatentRegistryPaymentsDuplicates({
                data: {
                    clientId: this.clientId,
                    patentRegistryId,
                },
                onSuccess: ({result}) => {
                    if (result) {
                        this.showConfirmWindow({
                            confirmMethod: () => this.payRegistryAction(patentRegistryId),
                            confirmText: "Внимание! В реестре найдены дубли строк по оплате патента одному и тому же исполнителю. Для передачи реестра в оплату их необходимо удалить. Вы подтверждаете операцию удаления дублей и передачи реестра в оплату?",
                        })();

                        return;
                    }

                    this.payRegistryAction(patentRegistryId);
                },
            });
        };
    };

    createDuplicateRegistryAction = (patentRegistryId) => {
        return () => {
            const {createDuplicatePatentRegistry} = this.props;

            createDuplicatePatentRegistry({
                clientId: this.clientId,
                patentRegistryId,
            });
            this.handleCancelConfirm();
        };
    };

    deleteFailedPatentRegistryPaymentsAction = (patentRegistryId) => {
        return () => {
            const {deleteFailedPatentRegistryPayments} = this.props;

            deleteFailedPatentRegistryPayments({
                clientId: this.clientId,
                patentRegistryId,
                onSuccess: this.fetchRegistryList,
            });
            this.handleCancelConfirm();
        };
    };

    archiveRegistryAction = (patentRegistryId) => {
        return () => {
            const {archivePatentsRegistry} = this.props;

            archivePatentsRegistry({
                clientId: this.clientId,
                patentRegistryId,
            });
            this.handleCancelConfirm();
        };
    };

    restoreRegistryAction = (patentRegistryId) => {
        return () => {
            const {restorePatentsRegistry} = this.props;

            restorePatentsRegistry({
                clientId: this.clientId,
                patentRegistryId,
            });
            this.handleCancelConfirm();
        };
    };

    setDataLog = (dataLog = {}) => {
        this.setState({dataLog});
    };

    getOptions = (item) => {
        const {
            patentRegistryId,
            status,
            registryName,
            registryComment,
            clientId,
            totalCount,
            declinedCount,
            objectId,
        } = item;

        const {t} = this.props;

        const {archived} = this.state;

        const options = {
            LOG: {
                key: "LOG",
                text: "Лог",
                action: () => this.setDataLog(item),
            },
            ARCHIVE_ACTION: {
                key: "ARCHIVE_ACTION",
                text: t("registries.action-archive"),
                action: this.showConfirmWindow({
                    confirmMethod: this.archiveRegistryAction(patentRegistryId),
                    confirmText: t("registries.action-archive-confirm", {name: registryName}),
                }),
            },
            UNARCHIVE_ACTION: {
                key: "UNARCHIVE_ACTION",
                text: t("registries.action-unarchive"),
                action: this.showConfirmWindow({
                    confirmMethod: this.restoreRegistryAction(patentRegistryId),
                    confirmText: t("registries.action-unarchive-confirm", {name: registryName}),
                }),
            },
            PAY_ACTION: {
                key: "PAY_ACTION",
                text: t("registries.action-pay"),
                action: this.showConfirmWindow({
                    confirmMethod: this.checkDuplicatesPayments(patentRegistryId),
                    confirmText: t("registries.action-pay-confirm"),
                }),
            },
            PAY_DECLINED_ACTION: {
                key: "PAY_DECLINED_ACTION",
                text: t("registries.action-pay-outstanding"),
                action: this.showConfirmWindow({
                    confirmMethod: () => this.payUnpaidRegistry(patentRegistryId),
                    confirmText: t("registries.action-pay-outstanding-confirm"),
                }),
            },
            EDIT_REGISTRY_ACTION: {
                key: "EDIT_REGISTRY_ACTION",
                text: t("registries.action-edit"),
                action: () => {
                    this.showAddRegistryForm(true, {
                        clientId,
                        patentRegistryId,
                        registryComment,
                        registryName,
                        status,
                        objectId,
                    });
                },
            },
            CREATE_DUPLICATE_REGISTRY_ACTION: {
                key: "CREATE_DUPLICATE_REGISTRY_ACTION",
                text: t("registries.action-create-duplicate"),
                action: this.showConfirmWindow({
                    confirmMethod: this.createDuplicateRegistryAction(patentRegistryId),
                    confirmText: t("registries.action-create-duplicate-confirm", {name: registryName}),
                }),
            },
            DELETE_DECLINED_ACTION: {
                key: "DELETE_DECLINED_ACTION",
                text: t("registries.action-cancel-outstanding"),
                action: this.showConfirmWindow({
                    confirmMethod: this.deleteFailedPatentRegistryPaymentsAction(patentRegistryId),
                    confirmText: t("registries.action-cancel-outstanding-confirm", {name: registryName}),
                }),
            },
        };

        const resultOptions = [];
        const isAccessEdit = [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role);
        const isAccessPay = [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role);

        if (!isAccessEdit) {
            return resultOptions;
        }

        if (archived && !this.isClientArchived) {
            resultOptions.push(options.UNARCHIVE_ACTION);

            return isNMUsers() ? [
                ...resultOptions,
                options.LOG,
            ] : resultOptions;
        }

        if ([PATENTS_REGISTRY_STATUS.FORMED].includes(status) && totalCount && isAccessPay && !this.isClientArchived) {
            resultOptions.push(options.PAY_ACTION);
        }
        if (!this.isClientArchived) {
            resultOptions.push(options.EDIT_REGISTRY_ACTION);
        }

        if (isAccessEdit && !this.isClientArchived) {
            resultOptions.push(options.CREATE_DUPLICATE_REGISTRY_ACTION);
        }

        if ([PATENTS_REGISTRY_STATUS.PROCESSING, PATENTS_REGISTRY_STATUS.NOT_DEPOSITED, PATENTS_REGISTRY_STATUS.DEPOSITED_PARTIALLY].includes(status) && declinedCount && isAccessPay && !this.isClientArchived) {
            resultOptions.push(options.PAY_DECLINED_ACTION);
            resultOptions.push(options.DELETE_DECLINED_ACTION);
        }

        if ([PATENTS_REGISTRY_STATUS.FORMED, PATENTS_REGISTRY_STATUS.NOT_DEPOSITED, PATENTS_REGISTRY_STATUS.DEPOSITED_TOTALLY].includes(status) && !this.isClientArchived) {
            resultOptions.push(options.ARCHIVE_ACTION);
        }

        if (isNMUsers()) {
            resultOptions.push(options.LOG);
        }

        return resultOptions;
    };

    onClickRegistryActionItem = (option) => {
        option.action();
    };

    renderAction = (item) => {
        if ([FOREMAN, NM_COORDINATOR].includes(this.role)) {
            return null;
        }

        const options = this.getOptions(item);

        if (isEmpty(options)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.onClickRegistryActionItem(option);
                }}
                dropDownType="right"
            />
        );
    };

    renderStatus = status => {
        return (
            <NmBadge
                text={PATENTS_REGISTRY_STATUS_DICT[status]}
                mod={PATENTS_REGISTRY_STATUS_COLOR_TRANSCRIPT[status]}
            />
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchRegistryList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchRegistryList,
        );
    };

    showAddRegistryForm = (showAddRegistryForm, registryObj = {}) => {
        this.setState(prevState => ({
            ...prevState,
            registryObj,
            showAddRegistryForm,
        }));
    };

    handleChange = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    toggleArchived = () => {
        this.setState(({archived}) => ({
            pageNum: 1,
            archived: !archived,
        }), this.fetchRegistryList);
    };

    searchFilter = () => {
        this.setState({
            pageNum: 1,
            isSearch: true,
        }, this.fetchRegistryList);
    };

    clearFilter = () => {
        this.setState({
            pageNum: 1,
            registryNameFilter: undefined,
            statusFilter: "ALL",
            fromDateFilter: undefined,
            toDateFilter: undefined,
            creatorFioFilter: undefined,
            isSearch: false,
            projectIds: [],
            objectIds: [],
        }, this.fetchRegistryList);
    };

    renderBaseFilter() {
        const {
            numberFilter,
            registryNameFilter,
            clientFilter,
            statusFilter,
            fromDateFilter,
            toDateFilter,
            creatorFioFilter,
            createActsTypeFilter,
            projectIds,
            objectIds,
        } = this.state;

        return (
            <PatentsListFilter
                numberFilter={numberFilter}
                nameFilter={registryNameFilter}
                clientFilter={clientFilter}
                statusFilter={statusFilter}
                fromDateFilter={fromDateFilter}
                toDateFilter={toDateFilter}
                creatorFioFilter={creatorFioFilter}
                createActsTypeFilter={createActsTypeFilter}
                projectIds={projectIds}
                objectIds={objectIds}
                clientId={this.clientId}
                clear={this.clearFilter}
                search={this.searchFilter}
                handleChange={this.handleChange}
            />
        );
    }

    getMediaControls = (isShowControls) => {
        const {t} = this.props;
        const {archived} = this.state;

        const archive = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: this.toggleArchived,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => {
                            this.showAddRegistryForm(true);
                        },
                        children: t("registries.add-registry"),
                        icon: <AddIcon />,
                    },
                    settings: {
                        exception: true,
                    },
                    visible: ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role) && !this.isClientArchived && isShowControls,
                },
            ],
        };
    };

    renderProjectInfo = ({projectName, projectId, clientId}) => {
        if (!projectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId || this.clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    getRows() {
        const {list} = this.props;

        return list.map(item => {
            const {
                totalCount,
                createDate,
                registryComment,
                creatorName,
                status,
                depositedCount,
                processedCount,
                declinedCount,
                registerSum,
                requiredDeposit,
            } = item;

            // Всего
            const paymentsTotalCount = totalCount || 0;
            // Выплачено
            const paidCount = depositedCount || 0;
            // В процессе
            const inProgressCount = processedCount || 0;
            // Отклонено
            const rejectCount = declinedCount || 0;

            const comment = registryComment ? [{text: registryComment}] : [];

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-7"
                        primaryHeader={this.renderRegistryLink(item)}
                        secondaryHeaderStatus={this.renderStatus(status)}
                        secondaryHeader={`Реестр от ${dateFormat(convertUtcToLocal(createDate))}`}
                        labels={[
                            ...comment,
                            {label: "Ответственный", text: creatorName},
                            {label: "Проект", text: this.renderProjectInfo(item)},
                            {label: "Объект", text: this.renderObjectInfo(item)},
                        ]}
                        cards={[
                            {
                                title: "Всего/Выплачено/В процессе/Отклонено",
                                values: [
                                    {text: paymentsTotalCount, bold: true},
                                    {text: paidCount},
                                    {text: inProgressCount},
                                    {text: rejectCount},
                                ],
                                className: "col-16 col-md-6 col-xxl-3 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Сумма реестра, ₽",
                                value: formatAmount(formatNumber(registerSum || 0, 2)),
                                className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Необходимый депозит, ₽",
                                value: formatAmount(formatNumber(requiredDeposit, 2)),
                                className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        actions={this.renderAction(item)}
                    />
                ),
            };
        });
    }

    getDepositInfo() {
        const {
            clientProperties: {
                depositDistributedByObjects,
            },
        } = this.props;

        return (
            !depositDistributedByObjects &&
            <DepositValuesAmountInfo
                clientId={this.clientId}
                isPatents
                isCivil
            />
        );
    }

    renderList() {
        const rows = this.getRows();

        return (
            <CheckboxList
                rows={rows}
            />
        );
    };

    renderAddRegistryForm() {
        const {
            showAddRegistryForm,
            registryObj,
        } = this.state;

        const {
            addPatentsRegistry,
            updatePatentsRegistry,
            objectList,
        } = this.props;

        if (!showAddRegistryForm) {
            return null;
        }

        const {
            patentRegistryId,
            status,
            registryName,
            registryComment,
            clientId,
            objectId,
        } = registryObj;

        return (
            <CreateEditRegistryPatent
                data={{
                    clientId: this.isClientCard ? this.clientId : clientId,
                    registryName,
                    registryComment,
                    patentRegistryId,
                    status,
                    objectId,
                }}
                onClose={() => {
                    this.showAddRegistryForm(false);
                }}
                onSubmit={patentRegistryId ? updatePatentsRegistry : addPatentsRegistry}
                getPatentsRegistries={this.fetchRegistryList}
                objectList={objectList}
            />
        );
    }

    render() {
        const {
            totalCount,
            progressList,
            actionRegistryProgress,
            totalPages,
            t,
            client: {
                archived,
            },
        } = this.props;

        const {
            pageNum,
            pageSize,
            isSearch,
            archived: archivedList,
            dataLog,
        } = this.state;

        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                typeFilter="vertical"
                className="fluid-flex-grow"
                isLoaded={progressList || actionRegistryProgress}
                header={
                    <NmTitle
                        isShowCount={typeof archived === "boolean" && !archived}
                        size="xl"
                        count={totalCount}
                    >
                        {t("patents.payments")}
                    </NmTitle>
                }
                subHeader={this.getDepositInfo()}
                mediaControls={this.getMediaControls(isShowControls)}
                filtersBase={this.renderBaseFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                {this.renderAddRegistryForm()}
                {this.renderConfirmWindow()}
                {
                    !isEmpty(dataLog) &&
                    <PatentsListLog
                        handleClose={() => this.setDataLog()}
                        {...dataLog}
                        name={dataLog.registryName}
                    />
                }
                {
                    !(totalCount || progressList) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction="Добавить реестр"
                            isShowAction={typeof archived === "boolean" && !archived && !archivedList && this.role !== NM_OPERATOR}
                            onClickAction={() => {
                                this.showAddRegistryForm(true);
                            }}
                            isSearch={isSearch}
                        /> :
                        this.renderList()
                }
            </NmPage>
        );
    };
}

export default withPageData(connect(
    state => ({
        progressList: patentsRegistriesProgressListSelector(state),
        actionRegistryProgress: patentsRegistriesActionRegistryProgressSelector(state),
        totalCount: patentsRegistriesTotalCountSelector(state),
        totalPages: patentsRegistriesListTotalPagesSelector(state),
        list: patentsRegistriesListSelector(state),
        depositBalance: patentsDepositBalanceSelector(state),
        prevCardArchived: patentsRegistryArchivedParamSelector(state),
        client: clientCardInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        objectList: clientObjectListSelector(state),
    }),
    {
        getPatentsRegistries,
        archivePatentsRegistry,
        restorePatentsRegistry,
        payPatentRegistry,
        getBalanceDepositPatents,
        addPatentsRegistry,
        updatePatentsRegistry,
        createDuplicatePatentRegistry,
        deleteFailedPatentRegistryPayments,
        checkPatentRegistryPaymentsDuplicates,
        payUnpaidPatentRegistry,
    },
)(withTranslation()(PatentsList)));