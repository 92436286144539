import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmPage from "../../../components/NmPage";
import TableDiv from "../../../components/TableDiv";
import BrigadeTimesheetChart from "./brigade-timesheet-chart";

import dateFormat from "../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    getFormattedFullName,
    getFullName,
} from "../../../utils/stringFormat";

import {headersTimesheetList as headers} from "../../../constants/headersTable";

import {
    clearTimesheetsStore,
    getForemenTimesheetPage,
} from "../../../ducks/bff/clients/foremen/timesheets/actionCreators";
import {
    foremenTimesheetListSelector,
    foremenTimesheetProgressSelector,
    foremenTimesheetTotalCountSelector,
    foremenTimesheetTotalPagesSelector,
} from "../../../ducks/bff/clients/foremen/timesheets/selectors";
import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";

class BrigadeTimesheet extends Component {
    constructor(props) {
        super(props);

        this.role = ls(USER_ROLE);

        this.state = {
            pageSize: 25,
            pageNum: 1,
        };
    }

    get clientId() {
        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = this.props;

        return clientId;
    }

    componentDidMount() {
        this.fetchList();
    }

    componentWillUnmount() {
        const {clearTimesheetsStore} = this.props;

        clearTimesheetsStore();
    };

    fetchList = () => {
        const {getForemenTimesheetPage} = this.props;
        const {
            pageSize,
            pageNum,
        } = this.state;

        getForemenTimesheetPage({
            pageSize,
            pageNum,
            clientIdFilter: this.clientId,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
                isOpenChart: false,
            },
            this.fetchList,
        );
    };

    handleOnCloseChart = () => {
        this.setState({
            isOpenChart: false,
            selectedItem: {},
        });
    };

    handleOpenChart = (item) => {
        const {
            timeSheetId,
        } = item;

        this.setState({
            isOpenChart: true,
            selectedItem: {
                timeSheetId,
            },
        });
    };

    renderAction(item) {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        if (isClientArchived) {
            return null;
        }

        return (
            <div
                className="icon-chart"
                onClick={() => {
                    this.handleOpenChart(item);
                }}
            >
                <i className="material-icons notranslate">
                    insert_chart_outlined
                </i>
            </div>
        );
    }

    mapTableData() {
        const {list = []} = this.props;

        const rows = list.map(item => {
            const {
                foremanLastName,
                foremanFirstName,
                foremanPatronymic,
                orderNum,
                orderName,
                objectName,
                objectAddress,
            } = item;

            return {
                ...item,
                fullName: getFormattedFullName(getFullName(foremanLastName, foremanFirstName, foremanPatronymic)),
                object: `${objectName} - ${objectAddress}`,
                operationDate: dateFormat(item.operationDate, "dd.MM.yyyy"),
                orderInfo: `${orderNum} - ${orderName}`,
                action: this.renderAction(item),
            };
        });

        return {
            rows,
            headers,
        };
    }

    render() {
        const {
            isOpenChart,
            selectedItem,
            pageSize,
            pageNum,
        } = this.state;

        const {
            totalPages,
            totalCount,
            progress,
        } = this.props;

        return (
            <NmPage
                className="container-app"
                currentPageSize={pageSize}
                totalPages={totalPages}
                onPaginationChange={this.handlePaginationChange}
                currentPageNum={pageNum}
                onChangePageSize={this.handleChangePageSize}
                totalCount={totalCount}
                showHeaderBlock={false}
                isLoaded={progress}
            >
                {
                    isOpenChart &&
                    <BrigadeTimesheetChart
                        data={selectedItem}
                        onClose={this.handleOnCloseChart}
                    />
                }
                {
                    !(totalCount || progress) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                        /> :
                        <TableDiv
                            tableData={this.mapTableData()}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: foremenTimesheetListSelector(state),
        totalPages: foremenTimesheetTotalPagesSelector(state),
        totalCount: foremenTimesheetTotalCountSelector(state),
        progress: foremenTimesheetProgressSelector(state),
        client: clientCardInfoSelector(state),

    }),
    {
        getForemenTimesheetPage,
        clearTimesheetsStore,
    },
)(withTranslation()(BrigadeTimesheet));
