import {getPageCrowdTasks} from "../../crowd/tasks/actionCreators";

import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/registry",
        client: "/client-adm/crowd-tasks/registry",
    });
};

export const getPageAdmCrowdTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController()}/page`,
        ...payload,
    });
};

export const getPageAdmCrowdProjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController()}/by-project/page`,
        ...payload,
    });
};

export const getPageAdmCrowdObjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController()}/by-object/page`,
        ...payload,
    });
};