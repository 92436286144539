import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {TAB} from "../constants";

import {
    getKedoTemplateDocumentTypesPage,
    getKedoUploadedDocumentTypesPage,
} from "../../../../../../../ducks/bff/common/dicts/actionCreators";

export const useKedoDocumentRouteLinkedTypesModalFetch = (props) => {
    const {
        routeId,
        tab,
        pageSize,
        pageNum,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        routeId,
        pageNum,
        pageSize,
        tab,
    ]);

    const fetchList = () => {
        const reqData = {
            routeId,
            pageSize,
            pageNum,
        };

        if (tab === TAB.TEMPLATE_DOCUMENTS) {
            dispatch(getKedoTemplateDocumentTypesPage(reqData));

            return;
        }

        dispatch(getKedoUploadedDocumentTypesPage(reqData));
    };

    return {
        fetchList,
    };
};