import {
    GET_TASK_STATS_ERROR,
    GET_TASK_STATS_REQUEST,
    GET_TASK_STATS_SUCCESS,
    UPDATE_SETTINGS_SERVICE_TASK_STATS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    edoAccessList: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_TASK_STATS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_TASK_STATS_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case GET_TASK_STATS_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case UPDATE_SETTINGS_SERVICE_TASK_STATS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};