import {all, put, takeEvery} from "redux-saga/effects";

import {
    BFF_FINANCES_PATENTS_SUMMARY_LIST_ERROR,
    BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST,
    BFF_FINANCES_PATENTS_SUMMARY_LIST_SUCCESS,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_ERROR,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/patent-reports";

export const getPatentsSummarySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PATENTS_SUMMARY_LIST_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PATENTS_SUMMARY_LIST_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PATENTS_SUMMARY_LIST_ERROR,
            payload: error,
        });
    }
};

export const getPatentsTotalSummarySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/total-summary-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PATENTS_TOTAL_SUMMARY_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PATENTS_TOTAL_SUMMARY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PATENTS_TOTAL_SUMMARY_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST, getPatentsSummarySaga),
        takeEvery(BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST, getPatentsTotalSummarySaga),
    ]);
}