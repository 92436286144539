import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../components/ActualComponents/DropzoneV2";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDivider from "../../../../components/ActualComponents/NmDivider";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import {TemplatesContext} from "../../contexts/context";

import {isLastElementInArray} from "../../../../utils/objectHelper";
import {toastError} from "../../../../utils/toastHelper";
import validate from "../../../../utils/validate";
import {getTemplatesCreateRequestData} from "./utils/dto";

import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../constants/finance";
import {ORDER_KIND} from "../../../../constants/order";
import {templateOtherDocumentDataRule} from "../../../../constants/validationRules";
import {
    TEMPLATES_SUB_TAB,
    TEMPLATES_TAB,
    TEMPLATES_TYPE_DOCUMENTS,
    TEMPLATES_TYPE_DOCUMENTS_OPTIONS,
} from "../../constants";

import {
    addDocumentCustomTemplate,
    documentCustomTemplateProgressActionSelector,
    documentCustomTemplateProgressFileSelector,
    generateDocumentCustomTemplatePdf,
    getDocumentCustomTemplateFileById,
    updateDocumentCustomTemplate,
    validateDocumentCustomTemplateFields,
} from "../../../../ducks/documentsCustomTemplate";

import PropTypes from "prop-types";

import "./style.sass";

const TemplatesEdit = (props) => {
    const {
        pathname,
        fetchList,
        onClose,
    } = props;
    const {
        editData,
    } = useContext(TemplatesContext);
    const progressFile = useSelector(documentCustomTemplateProgressFileSelector);
    const progress = useSelector(documentCustomTemplateProgressActionSelector);
    const dispatch = useDispatch();
    const [confirmData, setConfirmData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState(() => {
        const isCrowdTaskTemplates = pathname.includes(TEMPLATES_SUB_TAB.TASKS.LINK);

        if (pathname.includes(TEMPLATES_TAB.CONTRACTS.LINK)) {
            return {
                orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
                documentType: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
            };
        }

        if (pathname.includes(TEMPLATES_TAB.APPLICATIONS.LINK)) {
            return {
                orderKind: ORDER_KIND.AMOUNT_BASED_ORDER,
                orderContractPaymentType: !isCrowdTaskTemplates ?
                    ORDER_WORK_REPORT_TYPE.SMZ :
                    undefined,
                documentType: isCrowdTaskTemplates ?
                    TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION :
                    TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
            };
        }

        if (pathname.includes(TEMPLATES_TAB.OTHER_DOCUMENTS.LINK)) {
            return {
                documentType: TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS,
                name: editData?.name || "",
            };
        }

        if (pathname.includes(TEMPLATES_TAB.ADDITIONAL_AGREEMENT.LINK)) {
            return {
                orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
                documentType: TEMPLATES_TYPE_DOCUMENTS.ADDITIONAL_AGREEMENT,
            };
        }

        return {
            orderKind: ORDER_KIND.AMOUNT_BASED_ORDER,
            orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
            documentType: isCrowdTaskTemplates ?
                TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK :
                TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
        };
    });
    const {
        documentType,
        templateFile = [],
        orderContractPaymentType,
        orderKind,
        externalDocument,
    } = form;

    const isVisibleIndividualFrameContract = ![
        TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK,
        TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION,
    ].includes(documentType);
    const orderContractPaymentTypeList = [
        ORDER_WORK_REPORT_TYPE.SMZ,
        ORDER_WORK_REPORT_TYPE.CIVIL,
        isVisibleIndividualFrameContract && ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
    ].filter(item => Boolean(item));

    useEffect(() => {
        if (editData.id) {
            setForm(prevForm => ({
                ...prevForm,
                documentType: editData.documentType,
                orderContractPaymentType: editData.orderContractPaymentType,
                orderKind: editData.orderKind,
            }));

            if (!editData.htmlDownloadLink) {
                return;
            }

            const url = editData.htmlDownloadLink.split("api")[1];

            dispatch(getDocumentCustomTemplateFileById({
                id: editData.id,
                name: `${editData.name}.html`,
                onSuccess: (templateFile) => {
                    setForm(prevForm => ({
                        ...prevForm,
                        templateFile: [templateFile],
                    }));
                },
                downloadLink: `/api${url}`,
            }));
        }
    }, []);

    const onChange = (_, {value, checked, name}) => {
        if (
            name === "documentType"
            && value === TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK
        ) {
            setForm({
                ...form,
                documentType: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK,
                orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
            });

            return;
        }

        setForm({
            ...form,
            [name]: typeof checked === "boolean" ? checked : value,
        });
    };

    const onErrorUnsupportedFields = (result) => {
        const {
            unsupportedFields,
            errorCode,
            errorMessage,
        } = result;

        if (!["UNSUPPORTED_FIELDS_FOUND"].includes(errorCode)) {
            toastError(errorMessage);
        }

        if (unsupportedFields) {
            setConfirmData(unsupportedFields);
        }
    };

    const getValidationRule = () => {
        if (form.documentType === TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS) {
            return templateOtherDocumentDataRule;
        }

        return {};
    };

    const isValid = () => {
        const error = validate(form, getValidationRule(), "");

        setErrors({...error});

        return Object.values(error).length === 0;
    };

    const onSubmit = () => {
        if (!isValid()) {
            return;
        }

        const requestData = getTemplatesCreateRequestData({form: {...form, id: editData.id}});

        if (editData.id) {
            dispatch(updateDocumentCustomTemplate({
                requestData,
                onError: onErrorUnsupportedFields,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(addDocumentCustomTemplate({
            requestData,
            onError: onErrorUnsupportedFields,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        }));
    };

    const handleClose = () => {
        onClose();
    };

    const onChangeFile = ({name, files}) => {
        onChange(undefined, {name, value: files});
    };

    const onClickPreview = async () => {
        const requestData = getTemplatesCreateRequestData({form});

        setLoading(true);

        dispatch(validateDocumentCustomTemplateFields({
            requestData,
            onError: (result) => {
                setLoading(false);

                onErrorUnsupportedFields(result);
            },
            onSuccess: () => {
                setLoading(false);

                dispatch(generateDocumentCustomTemplatePdf({
                    requestData,
                    onError: () => {
                        setLoading(false);
                    },
                }));
            },
        }));
    };

    const getDocumentType = (params = {}) => {
        const {
            subHeader = "Тип договора",
        } = params;

        return (
            <div>
                <Text.Sub
                    level="1"
                    medium
                    className="mb-2"
                >
                    {subHeader}
                </Text.Sub>
                <div className="col-16">
                    {
                        orderContractPaymentTypeList.map((item, index) => {
                            const isLast = isLastElementInArray(index, orderContractPaymentTypeList);

                            return (
                                <NmRadioV2
                                    key={index}
                                    className={!isLast && "mb-2 mb-md-3"}
                                    name="orderContractPaymentType"
                                    checked={orderContractPaymentType === item}
                                    value={item}
                                    label={ORDER_WORK_REPORT_TYPE_TRANSLATE[item]}
                                    onChange={onChange}
                                    disabled={
                                        documentType === TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK
                                        && item !== ORDER_WORK_REPORT_TYPE.SMZ
                                    }
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    const getOrderType = () => {
        return (
            orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ &&
            <div>
                <Text.Sub
                    level="1"
                    medium
                    className="mb-2"
                >
                    Тип заказа
                </Text.Sub>
                <NmHorizontalToggleV2
                    noWrapLabel
                    rightLabel="Тип заказ 2"
                    leftLabel="Тип заказ 1"
                    onChange={(e, {name, checked}) => onChange(e, {
                        name,
                        value: checked ? ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER : ORDER_KIND.AMOUNT_BASED_ORDER,
                    })}
                    name="orderKind"
                    checked={orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER}
                    duplex={false}
                />
            </div>
        );
    };

    const getTemplateForm = () => {
        switch (documentType) {
            case TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT:
                return (
                    <>
                        {getDocumentType()}
                        <NmCheckboxV2
                            disabled={Boolean(editData.id)}
                            label="Внешний"
                            name="externalDocument"
                            checked={externalDocument}
                            onChange={onChange}
                        />
                    </>
                );
            case TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION:
                return (
                    <>
                        {getDocumentType({subHeader: "Тип заявки"})}
                        {getOrderType()}
                    </>
                );
            case TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION:
            case TEMPLATES_TYPE_DOCUMENTS.ADDITIONAL_AGREEMENT:
                return null;
            case TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK:
                return (
                    <>
                        {getDocumentType({subHeader: "Тип акта"})}
                        {getOrderType()}
                    </>
                );
            case TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK:
                return getDocumentType({subHeader: "Тип акта"});
            case TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS:
                return (
                    <NmInputV2
                        required
                        size="xl"
                        label="Название документа"
                        name="name"
                        value={form.name}
                        placeholder="Введите название"
                        onChange={onChange}
                        error={errors.name}
                    />
                );
            default:
                return getDocumentType();
        }
    };

    const getContent = () => {
        const message = "В файле на основании которого формируется шаблон документа содержатся атрибуты, не принадлежащие выбранной модели:\n\n";
        const fields = confirmData.map(value => `- ${value}; \n`).join("");

        return `${message}${fields}`;
    };

    const getForm = () => {
        return (
            <>
                <NmDropdownV2
                    required
                    placeholder="Выберите из списка"
                    label="Тип документа"
                    name="documentType"
                    onChange={onChange}
                    value={form.documentType}
                    options={TEMPLATES_TYPE_DOCUMENTS_OPTIONS}
                />
                <NmDivider className="my-3 my-md-4" />
                {getTemplateForm()}
            </>
        );
    };

    const isButtonsDisabled = loading || progress || isEmpty(templateFile);

    return (
        <NmModal
            size="md"
            onClose={handleClose}
            header={
                <NmTitle
                    size="lg"
                >
                    {editData.id ? "Редактирование шаблона" : "Создание шаблона"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    additionalBtnContent="Предпросмотр"
                    additionalBtnDisabled={isButtonsDisabled}
                    isHiddenCancelOnMobile
                    additionalBtnColor="light-green"
                    submit={onSubmit}
                    disabled={isButtonsDisabled}
                    onClose={handleClose}
                    onClickAdditional={onClickPreview}
                />
            }
        >
            {
                confirmData &&
                <NmConfirmV2
                    contentAlign="left"
                    title="Ошибка формирования шаблона"
                    content={getContent()}
                    className="templates-edit__confirm"
                    onCancel={() => {
                        setConfirmData(null);
                    }}
                    onConfirm={() => {
                        setConfirmData(null);
                    }}
                    confirmButton="Ок"
                />
            }
            <NmForm addMargin>
                {
                    !editData.id &&
                    getForm()
                }
                <DropzoneV2
                    isVisibleLabel={true}
                    isDownload
                    disabled={progressFile}
                    files={templateFile}
                    onChange={(files) => onChangeFile({name: "templateFile", files})}
                    maxSize={10}
                    format="*.html"
                    accept=".html"
                    innerLabel="Выберите или перетащите файл"
                />
            </NmForm>
        </NmModal>
    );
};

TemplatesEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TemplatesEdit;
