import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getKeywordsPage} from "./actionCreators";
import {
    ADD_KEYWORD_ERROR,
    ADD_KEYWORD_REQUEST,
    ADD_KEYWORD_SUCCESS,
    DELETE_KEYWORD_ERROR,
    DELETE_KEYWORD_REQUEST,
    DELETE_KEYWORD_SUCCESS,
    GET_PAGE_KEYWORDS_ERROR,
    GET_PAGE_KEYWORDS_REQUEST,
    GET_PAGE_KEYWORDS_SUCCESS,
    UPDATE_KEYWORD_ERROR,
    UPDATE_KEYWORD_REQUEST,
    UPDATE_KEYWORD_SUCCESS,
} from "./actions";
import {settingsDirectoriesNaimixKeywordsPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/keywords";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsDirectoriesNaimixKeywordsPageDataSelector(state);

    yield put(getKeywordsPage(pageData));
};

const getKeywordsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_KEYWORDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_KEYWORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_KEYWORDS_ERROR,
            payload: error,
        });
    }
};

const addKeywordSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_KEYWORD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {keyword} = payload;
        toastSuccess(`Ключевое слово "${keyword}" успешно добавлено`);

        yield fetchList();

        yield put({
            type: ADD_KEYWORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_KEYWORD_ERROR,
            payload: error,
        });
    }
};

const updateKeywordSaga = function* ({payload}) {
    try {
        const {
            keywordUuid,
            keyword,
        } = payload;

        const body = {
            keyword,
        };

        const result = yield request.bff.patch(`${controller}/${keywordUuid}`, body);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_KEYWORD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        yield fetchList();

        yield put({
            type: UPDATE_KEYWORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_KEYWORD_ERROR,
            payload: error,
        });
    }
};

const deleteKeywordSaga = function* ({payload}) {
    try {
        const {
            keywordUuid,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${keywordUuid}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_KEYWORD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Ключевое слово успешно удалено");

        yield fetchList();

        yield put({
            type: DELETE_KEYWORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_KEYWORD_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_KEYWORDS_REQUEST, getKeywordsPageSaga),
        takeEvery(UPDATE_KEYWORD_REQUEST, updateKeywordSaga),
        takeEvery(DELETE_KEYWORD_REQUEST, deleteKeywordSaga),
        takeEvery(ADD_KEYWORD_REQUEST, addKeywordSaga),
    ]);
}