import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";

import {usePagination} from "../../../../hooks/usePagination";
import {useSettingsServiceTaskStatsFetch} from "./hooks/useFetch";
import {useSettingsServiceTaskStatsFilter} from "./hooks/useFilter";
import {useSettingsServiceTaskStatsSort} from "./hooks/useSort";

import dateFormat, {convertSecToMinutes} from "../../../../utils/dateFormat";

import {
    bffSettingsServiceTaskStatsListSelector,
    bffSettingsServiceTaskStatsProgressSelector,
    bffSettingsServiceTaskStatsTotalCountSelector,
    bffSettingsServiceTaskStatsTotalPagesSelector,
} from "../../../../ducks/bff/settings/service/task-stats/selectors";

const SettingsServiceTaskStats = () => {
    const totalPages = useSelector(bffSettingsServiceTaskStatsTotalPagesSelector);
    const list = useSelector(bffSettingsServiceTaskStatsListSelector);
    const progressList = useSelector(bffSettingsServiceTaskStatsProgressSelector);
    const totalCount = useSelector(bffSettingsServiceTaskStatsTotalCountSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        onClear,
        onSearch,
        filterData,
        filters,
    } = useSettingsServiceTaskStatsFilter({pageSize, setPagination});

    const {
        sortOptions,
        usageCountSort,
        onClickSort,
    } = useSettingsServiceTaskStatsSort();

    useSettingsServiceTaskStatsFetch({
        usageCountSort,
        pageNum,
        pageSize,
        filterData,
    });

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.key,
                contentRow: (
                    <NmListCard
                        labels={[
                            {
                                label: "Продолжительность",
                                text: convertSecToMinutes(item.duration),
                            },
                            {
                                label: "Дата и время начала",
                                text: dateFormat(item.startDateTime),
                            },
                            {
                                label: "Дата и время окончания",
                                text: dateFormat(item.endDateTime),
                            },
                            {
                                label: "Статус",
                                text: item.status,
                            },
                            {
                                label: "Тип задачи",
                                text: item.taskType,
                            },

                        ]}
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                    />
                ),

            };
        });
    });

    return (
        <NmPage
            noPadding
            isLoaded={progressList}
            header={
                <NmPageHeader text="График исполнения джобов" />
            }
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
        >
            {
                list.length === 0 ? (
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                    />
                ) : (
                    <CheckboxList
                        sort
                        onClickSort={onClickSort}
                        sortOptions={sortOptions}
                        rows={rows}
                    />
                )
            }
        </NmPage>

    );
};

export default SettingsServiceTaskStats;