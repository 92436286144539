import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/client/foremen/payments",
        client: "/client-adm/client/foremen/payments",
    });
};

// POST /bff/adm/clients/client-card/client/foremen/payments/getRichPage
// POST /bff/client-adm/client/foremen/payments/getRichPage
// Получение страницы выплат бригадирам
function* getClientForemenPaymentsRichPageSaga({payload}) {
    const {
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/getRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR,
            payload: error,
        });
    }
}

// GET /bff/adm/clients/client-card/client/foremen/payments/getForemanContractorsRichList
// GET /bff/client-adm/client/foremen/payments/getForemanContractorsRichList
// Список с ФИО бригадиров, от которых есть заявки на выплаты
function* getClientForemenPaymentsForemanContractorsRichListSaga({payload}) {
    const {
        isClientCard = true,
        ...params
    } = payload;

    try {
        const result = yield request.bff.get(`${getController()}/getForemanContractorsRichList`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR,
            payload: error,
        });
    }
}

// POST /bff/adm/clients/client-card/client/foremen/payments/paymentReject
// POST /bff/client-adm/client/foremen/payments/paymentReject
// Отклонить выплату
function* foremenPaymentsPaymentRejectSaga({payload}) {
    const {
        onSuccess = () => {},
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/paymentReject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR,
            payload: error,
        });
    }
}

// POST /bff/adm/clients/client-card/client/foremen/payments/paymentConfirm
// POST /bff/client-adm/client/foremen/payments/paymentConfirm
// Подтвердить выплату
function* foremenPaymentsPaymentConfirmSaga({payload}) {
    const {
        onSuccess = () => {},
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/paymentConfirm`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST, getClientForemenPaymentsRichPageSaga),
        takeEvery(BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST, foremenPaymentsPaymentRejectSaga),
        takeEvery(BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST, foremenPaymentsPaymentConfirmSaga),
        takeEvery(BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST, getClientForemenPaymentsForemanContractorsRichListSaga),
    ]);
}