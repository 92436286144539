import React, {FC, useMemo} from "react";
import {uniqWith} from "lodash";

import NmDropdownV2, {INmDropdownV2} from "../ActualComponents/NmDropdownV2";

import {usePositions} from "./hooks/usePositions";

import {
    OptionType,
} from "../../containers/document-management/document-management-statement/list/item/utils/getOptions";

interface PositionsDropdownProps extends INmDropdownV2 {
    clientId: string,
    initialOption?: OptionType,
}

export const PositionsDropdown: FC<PositionsDropdownProps> = (props) => {
    const {
        clientId,
        searchQuery,
        initialOption,
        ...otherProps
    } = props;

    const positions = usePositions({
        clientId,
        searchQuery,
    });

    //TODO: избавиться от данного кода, логика initialOption реализована с NmDropdownV2
    const options = useMemo(() => {
        if (initialOption) {
            return uniqWith(
                [
                    ...positions.options,
                    initialOption,
                ],
                (arrVal, othVal) => {
                    return arrVal.key === othVal.key;
                });
        }

        return positions.options;
    }, [
        positions.options,
        initialOption,
    ]);

    return (
        <NmDropdownV2
            {...otherProps}
            {...positions}
            options={options}
        />
    );
};
