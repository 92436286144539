import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect/lib/index";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

import {ORDER_WORK} from "../constants/messages";

const controller = "/orderwork";
//*  TYPES  *//

const ORDER_WORK_LIST_REQUEST = "ORDER_WORK_LIST_REQUEST";
const ORDER_WORK_LIST_SUCCESS = "ORDER_WORK_LIST_SUCCESS";
const ORDER_WORK_LIST_ERROR = "ORDER_WORK_LIST_ERROR";

const ORDER_WORK_ADD_REQUEST = "ORDER_WORK_ADD_REQUEST";
const ORDER_WORK_ADD_SUCCESS = "ORDER_WORK_ADD_SUCCESS";
const ORDER_WORK_ADD_ERROR = "ORDER_WORK_ADD_SUCCESS";

const ORDER_WORK_DELETE_REQUEST = "ORDER_WORK_DELETE_REQUEST";
const ORDER_WORK_DELETE_SUCCESS = "ORDER_WORK_DELETE_SUCCESS";
const ORDER_WORK_DELETE_ERROR = "ORDER_WORK_DELETE_ERROR";

const ORDER_WORK_UPDATE_REQUEST = "ORDER_WORK_UPDATE_REQUEST";
const ORDER_WORK_UPDATE_SUCCESS = "ORDER_WORK_UPDATE_SUCCESS";
const ORDER_WORK_UPDATE_ERROR = "ORDER_WORK_UPDATE_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    error: {},
    pageData: {},
    list: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDER_WORK_LIST_REQUEST:
            return {
                ...state,
                list: [],
                pageData: payload,
                progress: true,
            };
        case ORDER_WORK_DELETE_REQUEST:
        case ORDER_WORK_UPDATE_REQUEST:
        case ORDER_WORK_ADD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_WORK_LIST_SUCCESS:
            const {
                "orders": list,
                totalCount,
            } = payload;

            return {
                ...state,
                totalCount,
                list,
                progress: false,
            };
        case ORDER_WORK_DELETE_SUCCESS:
        case ORDER_WORK_UPDATE_SUCCESS:
        case ORDER_WORK_ADD_SUCCESS:
            return {
                ...state,
                ...payload,
            };
        case ORDER_WORK_DELETE_ERROR:
        case ORDER_WORK_UPDATE_ERROR:
        case ORDER_WORK_ADD_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function addOrderWork(payload) {
    return {
        type: ORDER_WORK_ADD_REQUEST,
        payload,
    };
}

export function getOrderWorkList(payload) {
    return {
        type: ORDER_WORK_LIST_REQUEST,
        payload,
    };
}

export function deleteOrderWork(payload) {
    return {
        type: ORDER_WORK_DELETE_REQUEST,
        payload,
    };
}

export function updateOrderWork(payload) {
    return {
        type: ORDER_WORK_UPDATE_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const orderWorkSelector = state => state.orderWork;
export const getOrderWorkListSelector = createSelector(orderWorkSelector, ({list}) => list);

//*  SAGA  *//

export const getOrderWorkListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_WORK_LIST_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: ORDER_WORK_LIST_ERROR, payload: error});
    }
};

export const deleteOrderWorkSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/delete`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_WORK_DELETE_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: ORDER_WORK_DELETE_ERROR, payload: error});
    }
};

export const updateOrderWorkSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_WORK_UPDATE_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: ORDER_WORK_UPDATE_ERROR, payload: error});
    }
};

export const addOrderWorkSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (!errorMessage) {
            yield put({type: ORDER_WORK_ADD_SUCCESS, payload: result});
        } else {
            toastError(ORDER_WORK.ORDER_WORK_ADD_ERROR_TEXT);
            yield put({type: ORDER_WORK_ADD_ERROR, payload: {errorMessage}});
        }
    } catch (error) {
        toastError(ORDER_WORK.ORDER_WORK_ADD_ERROR_TEXT);
        yield put({type: ORDER_WORK_ADD_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_WORK_LIST_REQUEST, getOrderWorkListSaga),
        takeEvery(ORDER_WORK_ADD_REQUEST, addOrderWorkSaga),
        takeEvery(ORDER_WORK_DELETE_REQUEST, deleteOrderWorkSaga),
        takeEvery(ORDER_WORK_UPDATE_REQUEST, updateOrderWorkSaga),
    ]);
}
