import axios from "axios";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {toastError} from "../utils/toastHelper";

const controller = "/api/clientUsers";

const RECOVERY_PASSWORD_REQUEST = "RECOVERY_PASSWORD_REQUEST";
const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
const RECOVERY_PASSWORD_ERROR = "RECOVERY_PASSWORD_ERROR";

const RECOVERY_PASSWORD_SAVE_REQUEST = "RECOVERY_PASSWORD_SAVE_REQUEST";
const RECOVERY_PASSWORD_SAVE_SUCCESS = "RECOVERY_PASSWORD_SAVE_SUCCESS";
const RECOVERY_PASSWORD_SAVE_ERROR = "RECOVERY_PASSWORD_SAVE_ERROR";

const RECOVERY_PASSWORD_STORE_FIELD_CLEAR = "RECOVERY_PASSWORD_STORE_FIELD_CLEAR";

const initial = {
    progressRecovery: false,
    isCompleteRecovery: false,
    isCompleteSave: false,
    progressSave: false,
    error: "",
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case RECOVERY_PASSWORD_SAVE_REQUEST:
            return {
                ...state,
                progressSave: true,
            };
        case RECOVERY_PASSWORD_REQUEST: {
            return {
                ...state,
                progressRecovery: true,
            };
        }
        case RECOVERY_PASSWORD_SAVE_SUCCESS:
            return {
                ...state,
                progressSave: false,
                isCompleteSave: true,
            };
        case RECOVERY_PASSWORD_SUCCESS: {
            return {
                ...state,
                progressRecovery: false,
                isCompleteRecovery: true,
            };
        }
        case RECOVERY_PASSWORD_STORE_FIELD_CLEAR: {
            return {
                ...state,
                ...payload,
            };
        }
        case RECOVERY_PASSWORD_SAVE_ERROR:
        case RECOVERY_PASSWORD_ERROR: {
            return {
                ...state,
                error: payload,
                progressRecovery: false,
                progressSave: false,
            };
        }
        default:
            return state;
    }
};

export function requestRecovery(payload) {
    return {
        type: RECOVERY_PASSWORD_REQUEST,
        payload,
    };
}

export function savePassword(payload) {
    return {
        type: RECOVERY_PASSWORD_SAVE_REQUEST,
        payload,
    };
}

export function clearFieldStore(payload) {
    return {
        type: RECOVERY_PASSWORD_STORE_FIELD_CLEAR,
        payload,
    };
}

export const passwordRecoverySelector = state => state.passwordRecovery;
export const progressPasswordRecoverySelector = createSelector(passwordRecoverySelector, ({progressRecovery}) => progressRecovery);
export const progressSavePasswordRecoverySelector = createSelector(passwordRecoverySelector, ({progressSave}) => progressSave);
export const endRecoverySelector = createSelector(passwordRecoverySelector, ({isCompleteRecovery}) => isCompleteRecovery);
export const endSaveSelector = createSelector(passwordRecoverySelector, ({isCompleteSave}) => isCompleteSave);
export const passwordRecoveryErrorSelector = createSelector(passwordRecoverySelector, ({error}) => error);

const handleError = function* (error, actionType) {
    if (!error?.message) {
        yield put({
            type: actionType,
            payload: "Возникла неизвестная ошибка. Попробуйте позже",
        });

        return;
    }

    toastError(error.message);

    yield put({
        type: actionType,
        payload: error.message,
    });
};

export const passwordRecoveryInit = function* (action) {
    try {
        const {email} = action.payload;

        const response = yield call(axios, {
            url: `${controller}/passwordRecovery/requestRecoveryToken`,
            method: "post",
            data: {
                email,
            },
        });

        const {data = {}} = response;

        const {errorMessage} = data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RECOVERY_PASSWORD_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: RECOVERY_PASSWORD_SUCCESS,
        });

    } catch (error) {
        yield handleError(error, RECOVERY_PASSWORD_ERROR);
    }
};

export const passwordRecoverySave = function* (action) {
    try {
        const response = yield call(axios, {
            url: `${controller}/passwordRecovery/executeWithRecoveryToken`,
            method: "post",
            data: action.payload,
        });

        const {data = {}} = response;

        const {errorMessage} = data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RECOVERY_PASSWORD_SAVE_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: RECOVERY_PASSWORD_SAVE_SUCCESS,
        });

    } catch (error) {
        yield handleError(error, RECOVERY_PASSWORD_SAVE_ERROR);
    }
};

export function* saga() {
    yield all([
        takeEvery(RECOVERY_PASSWORD_REQUEST, passwordRecoveryInit),
        takeEvery(RECOVERY_PASSWORD_SAVE_REQUEST, passwordRecoverySave),
    ]);
}

