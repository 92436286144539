import {
    SETTINGS_CLIENT_GROUP_ADD_ERROR,
    SETTINGS_CLIENT_GROUP_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_ADD_SUCCESS,
    SETTINGS_CLIENT_GROUP_DELETE_ERROR,
    SETTINGS_CLIENT_GROUP_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_DELETE_SUCCESS,
    SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR,
    SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST,
    SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS,
    SETTINGS_CLIENT_GROUP_UPDATE_ERROR,
    SETTINGS_CLIENT_GROUP_UPDATE_REQUEST,
    SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS,
    SSETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_STORE,
} from "./actions";

const initial = {
    progressAction: false,
    progress: false,
    error: null,
    pageData: {},
    totalCount: null,
    list: [],
    groupMemberRelatedUsersList: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_CLIENT_GROUP_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case SETTINGS_CLIENT_GROUP_GET_PAGE_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                progress: false,
            };
        case SETTINGS_CLIENT_GROUP_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SETTINGS_CLIENT_GROUP_ADD_REQUEST:
        case SETTINGS_CLIENT_GROUP_UPDATE_REQUEST:
        case SETTINGS_CLIENT_GROUP_DELETE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_CLIENT_GROUP_ADD_SUCCESS:
        case SETTINGS_CLIENT_GROUP_UPDATE_SUCCESS:
        case SETTINGS_CLIENT_GROUP_DELETE_SUCCESS:
        case SETTINGS_CLIENT_GROUP_ADD_ERROR:
        case SETTINGS_CLIENT_GROUP_UPDATE_ERROR:
        case SETTINGS_CLIENT_GROUP_DELETE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_REQUEST:
        case SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_REQUEST:
            return {
                ...state,
                progressRelatedUsers: true,
            };
        case SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_SUCCESS:
        case SETTINGS_CLIENT_GROUP_SET_RELATED_USERS_ERROR:
        case SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_ERROR:
            return {
                ...state,
                progressRelatedUsers: false,
            };
        case SETTINGS_CLIENT_GROUP_GET_RELATED_USERS_SUCCESS:
            return {
                ...state,
                groupMemberRelatedUsersList: payload.list,
                progressRelatedUsers: false,
            };
        case UPDATE_SETTINGS_CLIENT_GROUPS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};