import {
    ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST,
} from "./actions";

export const getPageOrderContractorWorkLog = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const addOrderContractorWorkInfoClientTime = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST,
        payload,
    };
};

export const updateOrderContractorWorkInfoClientTime = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST,
        payload,
    };
};

export const addOrderContractorWorkInfo = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST,
        payload,
    };
};

export const deleteOrderContractorWorkInfo = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST,
        payload,
    };
};

export const deleteOrderContractorWorkInfoClientTime = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST,
        payload,
    };
};

export const updateOrderContractorWorkLogCommonInfo = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST,
        payload,
    };
};

export const removeOrderContractorWorkLogCommonInfoPayTime = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST,
        payload,
    };
};

export const clearOrderContractorWorkLogStore = (payload) => {
    return {
        type: ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE,
        payload,
    };
};