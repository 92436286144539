import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Tabs from "../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import TaskDetailsModal from "../details-modal";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";

import {getUserRole} from "../../../utils/access";
import {isVisibleClientWorkTypeTabs} from "../../../utils/clientWorkType";
import {
    formatLocalDate,
    getEndDate,
    getStartDate,
    getUtcDateFilter,
} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_ID, ls} from "../../../utils/localstorage";
import {dictionaryToOptions, filterEmptyValues} from "../../../utils/objectHelper";
import {getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    LINK_FORBIDDEN_PAGE,
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
} from "../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isClientUser, isUserFromNm, NM_MANAGER} from "../../../constants/roles";
import {TASK_STATUS, TASK_STATUS_FILTER_DICT, TASK_TYPE} from "../../../constants/task";
import {MAP_INVITE_MATCHING_TYPE} from "../../map/components/invite-modal/constants";

import {getAdmTaskListPage, setInvisibleTaskAdm} from "../../../ducks/bff/adm/task-list/services";
import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {getClientsTaskListPage} from "../../../ducks/bff/clients/task-list/services";
import {getTaskTypes} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsTaskTypesOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../ducks/clientUserRestrictions";
import {downloadDocument} from "../../../ducks/documents";
import {
    cancelTaskById,
    setInvisible,
} from "../../../ducks/job";
import {updateTaskStore} from "../../../ducks/task/actionCreators";
import {getTaskInitialState} from "../../../ducks/task/reducer";
import {
    taskListSelector,
    taskProgressSelector,
    taskTotalCountSelector,
    taskTotalPagesSelector,
} from "../../../ducks/task/selectors";

import "./style.sass";

export const SOURCE_TYPE_FILTER = {
    NAIMIX: "NAIMIX",
    KEDO: "KEDO",
};

const SOURCE_TYPE_NAME = {
    NAIMIX: "Наймикс",
    KEDO: "КЭДО",
};

export default function TaskList(props) {
    const {
        sourceTypeFilter,
        onlyMyTasks,
    } = props;

    const tasks = useSelector(taskListSelector);
    const progress = useSelector(taskProgressSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const totalCount = useSelector(taskTotalCountSelector);
    const totalPages = useSelector(taskTotalPagesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const dispatch = useDispatch();

    const [loaderId, setLoaderId] = useState("");
    const [selectedDetails, setSelectedDetails] = useState({});

    const taskTypesOptions = useSelector((state) => bffCommonDictsTaskTypesOptionsSelector(state, sourceTypeFilter));

    const role = getUserRole();
    const isClientRole = isClientUser(role);
    const isVisibleSourceTypeTabs = isVisibleClientWorkTypeTabs(clientProperties.clientWorkType);
    const accessAdmAllTaskList = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmAllTaskList);

    useEffect(() => {
        if ([CLIENT_ADMIN].includes(role)) {
            dispatch(getClientCardProperties({
                clientId: ls(CURRENT_CLIENT_ID),
            }));
        }

        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmAllTaskList,
            ],
        }));
    }, []);

    function mapFilterDto(filter) {
        return {
            ...filter,
            clientIdsFilter: filter.clientIdsFilter ? [filter.clientIdsFilter] : undefined,
            fromCreatedAtDateTimeFilter: getUtcDateFilter(getStartDate(filter.fromCreatedAtDateTimeFilter)),
            toCreatedAtDateTimeFilter: getUtcDateFilter(getEndDate(filter.toCreatedAtDateTimeFilter)),
        };
    }

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            visibleCreatorTaskTypesFilter: [],
            creatorTaskStatusesFilter: [],
        },
        mapFilterDto,
        setPagination,
        pageSize,
    });

    const fetchList = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        const requestData = filterEmptyValues({
            ...filterData,
            sourceTypeFilter,
            clientId,
            clientUserId,
            onlyMyTasks,
            pageNum,
            pageSize,
            creatorFioFilter: sourceTypeFilter === SOURCE_TYPE_FILTER.KEDO && filterData.creatorFioFilter ?
                filterData.creatorFioFilter :
                null,
            emailFilter: sourceTypeFilter === SOURCE_TYPE_FILTER.NAIMIX && filterData.emailFilter ?
                filterData.emailFilter :
                null,
        });

        if (isClientRole) {
            dispatch(getClientsTaskListPage(requestData));

            return;
        }

        dispatch(getAdmTaskListPage(requestData));
    };

    useEffect(() => {
        if (isEmpty(taskTypesOptions)) {
            dispatch(getTaskTypes());
        }

        return () => {
            const initialState = getTaskInitialState();

            dispatch(updateTaskStore(initialState));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageSize,
        pageNum,
        onlyMyTasks,
        filterData,
        sourceTypeFilter,
    ]);

    const toggleTaskDetailsModal = (e, details = {}) => {
        setSelectedDetails(details);
    };

    function deleteTask(task) {
        const {id: taskId} = task;
        setLoaderId(taskId);

        const reqData = {
            data: {
                taskId,
            },
            handleResponse: () => {
                fetchList();
                setLoaderId(taskId);
            },
        };

        if (isClientRole) {
            dispatch(setInvisible(reqData));

            return;
        }

        dispatch(setInvisibleTaskAdm(reqData));
    }

    function cancelTask({id: taskId}) {
        setLoaderId(taskId);

        dispatch(cancelTaskById({
            taskId,
            onSuccess: () => {
                fetchList();
                setLoaderId("");
            },
        }));
    }

    function downloadDocumentDetails(downloadLink, type) {
        const parse = require("url-parse");

        const {query, pathname} = parse(downloadLink);

        dispatch(downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            downloadLink: `${pathname}${query}`,
            fileName: `${TASK_TYPE[type].TEXT}.xlsx`,
        }));
    }

    const getDetailsText = (item) => {
        const {
            type,
            detailsInfo,
        } = item;

        if (
            [
                TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE,
                TASK_TYPE.PRE_VALIDATION_CONTRACTOR_BANK_CHECK.VALUE,
                TASK_TYPE.KEDO_STAFF_IMPORT.VALUE,
            ].includes(type)
        ) {
            return "Скачать детали";
        }

        return detailsInfo?.reportDownloadLink ? "Скачать прикрепленный файл" : "Посмотреть детали";
    };

    const getRows = () => {
        return tasks.map((item) => {
            const {
                progressObject,
                infoList,
                type,
                typeInfo: {
                    description,
                    successCountMessage,
                    failedCountMessage,
                },
                detailsInfo,
                creatorClientName,
                creatorClientUserFio,
                additionalInfo,
            } = item;

            const {
                totalCount = 0,
                succeededCount = 0,
                failedCount = 0,
            } = progressObject || {};

            const details = {
                infoList,
                type,
                detailsInfo,
                additionalInfo,
            };

            const leftCount = totalCount - succeededCount - failedCount;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeaderStatus={
                            <NmBadge
                                rightIcon={
                                    item.status === TASK_STATUS.ERROR.VALUE && item.errorMessage &&
                                    <HelpTooltip
                                        info
                                        classNameIcon="task-list__help-tooltip-icon"
                                        children={item.errorMessage}
                                        position="bottom-left"
                                    />
                                }
                                mod={TASK_STATUS[item.status]?.MOD || "gray"}
                                text={TASK_STATUS[item.status] ? TASK_STATUS[item.status].TEXT : "Не определен"}
                            />
                        }
                        primaryHeader={description || "Не определен"}
                        otherContent={
                            <div className="task-list__content col-16 col-md-5">
                                <div className="task-list__row">
                                    <NmLabelText
                                        label="Компания"
                                        className="task-list__label-text"
                                        text={creatorClientName || "-"}
                                    />
                                    <NmLabelText
                                        label="Пользователь"
                                        className="task-list__label-text"
                                        text={creatorClientUserFio || "-"}
                                    />
                                </div>
                                <div className="task-list__row">
                                    <NmLabelText
                                        label="Дата запуска"
                                        className="task-list__label-text"
                                        text={formatLocalDate(item.createdAtDateTime, "dd.MM.yyyy HH:mm:ss")}
                                    />
                                    <NmLabelText
                                        label="Дата завершения"
                                        className="task-list__label-text"
                                        text={formatLocalDate(item.completionDateTime, "dd.MM.yyyy HH:mm:ss") || "-"}
                                    />
                                </div>
                                {
                                    item.type === TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE &&
                                    additionalInfo?.matchingType &&
                                    [
                                        MAP_INVITE_MATCHING_TYPE.CONTRACTOR_ON_ORDERS,
                                        MAP_INVITE_MATCHING_TYPE.CONTRACTOR_ON_CROWD_TASKS,
                                    ].includes(additionalInfo.matchingType) &&
                                    <NmLabelText
                                        label="ФИО исполнителя"
                                        className="task-list__label-text"
                                        text={
                                            additionalInfo.lastName ?
                                                getFullName(additionalInfo.lastName, additionalInfo.firstName, additionalInfo.patronymic) :
                                                additionalInfo.fullName
                                        }
                                    />
                                }
                                {
                                    item.type === TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE &&
                                    additionalInfo?.matchingType === MAP_INVITE_MATCHING_TYPE.CROWD_TASK_FOR_CONTRACTORS &&
                                    <NmLabelText
                                        label="Номер и название задания"
                                        className="task-list__label-text"
                                        text={`№${additionalInfo.crowdTaskNum} - ${additionalInfo.crowdTaskName}`}
                                    />
                                }
                                {
                                    item.type === TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE &&
                                    additionalInfo?.matchingType === MAP_INVITE_MATCHING_TYPE.ORDER_FOR_CONTRACTORS &&
                                    <NmLabelText
                                        label="Номер и название заказа"
                                        className="task-list__label-text"
                                        text={`№${additionalInfo.orderNum} - ${additionalInfo.orderName}`}
                                    />
                                }
                                {
                                    ![TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE].includes(type) &&
                                    <div className="task-list__row">
                                        <NmLabelText
                                            label={successCountMessage}
                                            className="task-list__label-text"
                                            text={
                                                <div className="task-list__count-wrapper task-list__count-wrapper_success">
                                                    <div className="task-list__count">
                                                        {succeededCount}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <NmLabelText
                                            label={failedCountMessage}
                                            className="task-list__label-text"
                                            text={
                                                <div className="task-list__count-wrapper task-list__count-wrapper_failed">
                                                    <div className="task-list__count">
                                                        {failedCount}
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <NmLabelText
                                            label="Осталось обработать"
                                            text={
                                                <div className="task-list__count-wrapper task-list__count-wrapper_left">
                                                    <div className="task-list__count">
                                                        {leftCount < 0 ? 0 : leftCount}
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                <div className="task-list__row">
                                    {
                                        (
                                            !isEmpty(infoList) ||
                                            detailsInfo?.reportDownloadLink ||
                                            (
                                                [
                                                    TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE,
                                                    TASK_TYPE.PRE_VALIDATION_CONTRACTOR_BANK_CHECK.VALUE,
                                                    TASK_TYPE.KEDO_STAFF_IMPORT.VALUE,
                                                ].includes(type) &&
                                                detailsInfo.errorMessage
                                            ) ||
                                            item.initialFileLink
                                        ) &&
                                        <NmLabelText
                                            label="Детали"
                                            className="task-list__details"
                                            text={
                                                <div
                                                    className="task-list__details-value"
                                                    onClick={() => {
                                                        if (
                                                            [
                                                                TASK_TYPE.IMPORT_PREFILLED_CONTRACTORS.VALUE,
                                                                TASK_TYPE.IMPORT_ONBOARDING_LEADS.VALUE,
                                                            ].includes(type)
                                                        ) {
                                                            downloadDocumentDetails(
                                                                detailsInfo?.reportDownloadLink,
                                                                type,
                                                            );

                                                            return;
                                                        }

                                                        if (
                                                            [
                                                                TASK_TYPE.KEDO_STAFF_IMPORT.VALUE,
                                                            ].includes(type)
                                                        ) {
                                                            toggleTaskDetailsModal(null, {
                                                                initialFileLink: item.initialFileLink,
                                                                reportFileLink: item.reportFileLink,
                                                                type,
                                                            });

                                                            return;
                                                        }

                                                        toggleTaskDetailsModal(null, details);
                                                    }}
                                                >
                                                    {getDetailsText(item)}
                                                </div>
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        }
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 2,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        children: "Удалить",
                                        size: "md",
                                        color: "grey",
                                        loading: loaderId === item.id,
                                        disabled: loaderId === item.id,
                                        onClick: () => {
                                            deleteTask(item);
                                        },
                                    },
                                    visible: onlyMyTasks,
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        children: "Остановить",
                                        size: "md",
                                        color: "grey",
                                        loading: loaderId === item.id,
                                        disabled: loaderId === item.id,
                                        onClick: () => {
                                            cancelTask(item);
                                        },
                                    },
                                    visible: [TASK_STATUS.IN_PROGRESS.VALUE, TASK_STATUS.CONTINUATION.VALUE].includes(item.status) && [TASK_TYPE.IMPORT_PREFILLED_CONTRACTORS.VALUE].includes(type),
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    const customerFilter = isUserFromNm(role) ? [
        {
            row: [
                {
                    component: FILTER.CUSTOMER,
                    label: "Компания",
                    placeholder: "Введите название компании",
                    name: "clientIdsFilter",
                    isClearable: true,
                },
            ],
        },
    ] : [];

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "fromCreatedAtDateTimeFilter",
                        endFieldName: "toCreatedAtDateTimeFilter",
                        label: "Дата запуска задачи",
                        isClearable: true,
                        value: {
                            fromDate: null,
                            toDate: null,
                        },
                    },
                ],
            },
            ...customerFilter,
            sourceTypeFilter === SOURCE_TYPE_FILTER.KEDO && {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Инициатор",
                        name: "creatorFioFilter",
                        placeholder: "Введите ФИО",
                        isClearable: true,
                    },
                ],
            },
            sourceTypeFilter === SOURCE_TYPE_FILTER.NAIMIX && {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Email пользователя",
                        name: "emailFilter",
                        placeholder: "Введите email",
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Тип задачи",
                        placeholder: "Выберите тип задачи",
                        name: "visibleCreatorTaskTypesFilter",
                        options: taskTypesOptions,
                        defaultValue: [],
                        multiple: true,
                        isClearable: true,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус задачи",
                        placeholder: "Выберите статус задачи",
                        name: "creatorTaskStatusesFilter",
                        options: dictionaryToOptions(TASK_STATUS_FILTER_DICT),
                        defaultValue: [],
                        multiple: true,
                        isClearable: true,
                    },
                ],
            },
        ].filter(Boolean);
    }, [
        taskTypesOptions,
        sourceTypeFilter,
    ]);

    const sourceTypeFilterTabs = useMemo(() => {
        return [
            {
                name: SOURCE_TYPE_NAME.NAIMIX,
                link: LINK_TASK_LIST_NAIMIX_MY,
                active: [
                    LINK_TASK_LIST_NAIMIX_MY,
                    LINK_TASK_LIST_NAIMIX_ALL,
                ],
            },
            {
                name: SOURCE_TYPE_NAME.KEDO,
                link: LINK_TASK_LIST_KEDO_MY,
                active: [
                    LINK_TASK_LIST_KEDO_MY,
                    LINK_TASK_LIST_KEDO_ALL,
                ],
            },
        ];
    }, [
        sourceTypeFilter,
    ]);

    const getTabs = () => {
        const linkMyTasks = sourceTypeFilter === SOURCE_TYPE_FILTER.NAIMIX
            ? LINK_TASK_LIST_NAIMIX_MY
            : LINK_TASK_LIST_KEDO_MY;
        const linkAllTasks = sourceTypeFilter === SOURCE_TYPE_FILTER.NAIMIX
            ? LINK_TASK_LIST_NAIMIX_ALL
            : LINK_TASK_LIST_KEDO_ALL;

        return [
            {
                name: "Мои задачи",
                link: linkMyTasks,
                active: linkMyTasks,
            },
            {
                name: "Все задачи",
                link: linkAllTasks,
                active: linkAllTasks,
                isVisible: accessAdmAllTaskList,
            },
        ];
    };

    const getHeader = (isSourceType) => {
        if (isSourceType) {
            return <NmPageHeader text={SOURCE_TYPE_NAME[sourceTypeFilter]} />;
        }

        return <NmPageHeader text="Список задач" />;
    };

    const getFilter = () => {
        return (
            <Filter
                initState={filterData}
                filters={filtersBase}
                onSubmit={onSearch}
                clearFilter={onClear}
            />
        );
    };

    const page = (
        <NmPage
            header={getHeader(!isClientRole)}
            subHeader={
                [ADMIN, NM_MANAGER].includes(role) &&
                <Tabs
                    panes={getTabs()}
                    secondary={true}
                />
            }
            noPadding={isVisibleSourceTypeTabs}
            typeFilter={
                isVisibleSourceTypeTabs
                    ? ""
                    : "vertical"
            }
            filtersBase={
                isVisibleSourceTypeTabs
                    ? null
                    : getFilter()
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progress}
        >
            {
                !isEmpty(selectedDetails) && <TaskDetailsModal
                    details={selectedDetails}
                    close={toggleTaskDetailsModal}
                />
            }
            {
                totalCount > 0 ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );

    if (!accessAdmAllTaskList && !onlyMyTasks) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    if (!isVisibleSourceTypeTabs) {
        return page;
    }

    return (
        <NmPage
            header={getHeader()}
            subHeader={
                isVisibleSourceTypeTabs &&
                <Tabs panes={sourceTypeFilterTabs} />
            }
            typeFilter="vertical"
            filtersBase={getFilter()}
        >
            {page}
        </NmPage>
    );
}