import {
    BFF_EXPORT_REPORTS_ADD_REQUEST,
    BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST,
    BFF_EXPORT_REPORTS_GET_PAGE_REQUEST,
    UPDATE_EXPORT_REPORTS_STORE,
} from "./actions";

export const getPageExportReports = (payload) => {
    return {
        type: BFF_EXPORT_REPORTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addExportReport = (payload) => {
    return {
        type: BFF_EXPORT_REPORTS_ADD_REQUEST,
        payload,
    };
};

export const getEdoAccessListLinkExportReport = (payload) => {
    return {
        type: BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST,
        payload,
    };
};

export const updateStoreExportReports = (payload) => {
    return {
        type: UPDATE_EXPORT_REPORTS_STORE,
        payload,
    };
};