import {
    TARIFF_SCALE_ADD_ERROR,
    TARIFF_SCALE_ADD_REQUEST,
    TARIFF_SCALE_ADD_SUCCESS,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS,
    TARIFF_SCALE_CHECK_EXIST_ERROR,
    TARIFF_SCALE_CHECK_EXIST_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_SUCCESS,
    TARIFF_SCALE_DELETE_ERROR,
    TARIFF_SCALE_DELETE_REQUEST,
    TARIFF_SCALE_DELETE_SUCCESS,
    TARIFF_SCALE_GET_BY_ID_ERROR,
    TARIFF_SCALE_GET_BY_ID_REQUEST,
    TARIFF_SCALE_GET_BY_ID_SUCCESS,
    TARIFF_SCALE_GET_PAGE_REQUEST,
    TARIFF_SCALE_GET_PAGE_SUCCESS,
    TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS,
    TARIFF_SCALE_IMPORT_ERROR,
    TARIFF_SCALE_IMPORT_REQUEST,
    TARIFF_SCALE_IMPORT_SUCCESS,
    TARIFF_SCALE_UPDATE_ERROR,
    TARIFF_SCALE_UPDATE_REQUEST,
    TARIFF_SCALE_UPDATE_STORE,
    TARIFF_SCALE_UPDATE_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    card: {},
    progress: false,
    progressCard: false,
    totalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS:
            return {
                ...state,
                scale: payload,
            };
        case TARIFF_SCALE_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case TARIFF_SCALE_GET_BY_ID_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case TARIFF_SCALE_GET_BY_ID_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case TARIFF_SCALE_GET_PAGE_REQUEST:
            return {
                ...payload,
                ...state,
            };
        case TARIFF_SCALE_GET_PAGE_SUCCESS:
            const {
                totalCount,
                results,
            } = payload;

            return {
                ...state,
                totalCount,
                list: results,
            };
        case TARIFF_SCALE_IMPORT_REQUEST:
        case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST:
        case TARIFF_SCALE_CHECK_EXIST_REQUEST:
        case TARIFF_SCALE_UPDATE_REQUEST:
        case TARIFF_SCALE_DELETE_REQUEST:
        case TARIFF_SCALE_ADD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case TARIFF_SCALE_IMPORT_SUCCESS:
        case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS:
        case TARIFF_SCALE_CHECK_EXIST_SUCCESS:
        case TARIFF_SCALE_UPDATE_SUCCESS:
        case TARIFF_SCALE_DELETE_SUCCESS:
        case TARIFF_SCALE_ADD_SUCCESS:
        case TARIFF_SCALE_IMPORT_ERROR:
        case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR:
        case TARIFF_SCALE_CHECK_EXIST_ERROR:
        case TARIFF_SCALE_ADD_ERROR:
        case TARIFF_SCALE_DELETE_ERROR:
        case TARIFF_SCALE_UPDATE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case TARIFF_SCALE_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};