import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CLIENT_BRANDS_ADD_ERROR,
    CLIENT_BRANDS_ADD_LOGO_ERROR,
    CLIENT_BRANDS_ADD_LOGO_REQUEST,
    CLIENT_BRANDS_ADD_LOGO_SUCCESS,
    CLIENT_BRANDS_ADD_REQUEST,
    CLIENT_BRANDS_ADD_SUCCESS,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR,     CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_DELETE_LOGO_ERROR,
    CLIENT_BRANDS_DELETE_LOGO_REQUEST,
    CLIENT_BRANDS_DELETE_LOGO_SUCCESS,
    CLIENT_BRANDS_GET_LOGS_ERROR,
    CLIENT_BRANDS_GET_LOGS_REQUEST,
    CLIENT_BRANDS_GET_LOGS_SUCCESS,
    CLIENT_BRANDS_GET_PAGE_ERROR,
    CLIENT_BRANDS_GET_PAGE_REQUEST,
    CLIENT_BRANDS_GET_PAGE_SUCCESS,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS,
    CLIENT_BRANDS_UPDATE_ERROR,
    CLIENT_BRANDS_UPDATE_REQUEST,
    CLIENT_BRANDS_UPDATE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

// POST /bff/adm/clients/company/brands/update
// POST /bff/adm/clients/client-card/client/brands/update
// Редактирование бренда
const updateClientBrand = function* ({payload}) {
    const {
        url,
        onSuccess,
        checkError,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            checkError && checkError(result);

            yield put({
                type: CLIENT_BRANDS_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CLIENT_BRANDS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/updateArchiveStatus
// POST /bff/adm/clients/company/brands/updateArchiveStatus
// Изменение архивного статуса бренда
const updateClientBrandArchiveStatus = function* ({payload}) {
    const {
        url,
        onSuccess,
        checkError,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            toastError(errorMessage);
            checkError && checkError(result);

            yield put({
                type: CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/getPage
// POST /bff/adm/clients/company/brands/getPage
// Получение страницы бренды компании
const getClientBrands = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_BRANDS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/deleteLogo
// POST /bff/adm/clients/company/brands/deleteLogo
// Удалить лого бренда объекта
const deleteClientBrandLogo = function* ({payload}) {
    const {
        url,
        onSuccess,
        onError,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            onError();

            yield put({
                type: CLIENT_BRANDS_DELETE_LOGO_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CLIENT_BRANDS_DELETE_LOGO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        onError();

        yield put({
            type: CLIENT_BRANDS_DELETE_LOGO_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/add
// POST /bff/adm/clients/company/brands/add
// Добавление бренда
const addClientBrand = function* ({payload}) {
    const {
        url,
        onSuccess,
        checkError,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            checkError && checkError(result);

            yield put({
                type: CLIENT_BRANDS_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: CLIENT_BRANDS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/addLogo
// POST /bff/adm/clients/company/brands/addLogo
// Добавить лого бренда объекта
const addClientBrandLogo = function* ({payload}) {
    const {
        url,
        onSuccess,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(url, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_ADD_LOGO_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_BRANDS_ADD_LOGO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_ADD_LOGO_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/assignBrandsProjectsPage
// POST /bff/adm/clients/company/brands/assignBrandsProjectsPage
// Получение настроек: Присвоение брендов объектам - страница проектов
const getAssignBrandsProjectsPage = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/assignBrandsObjectsPage
// POST /bff/adm/clients/company/brands/assignBrandsObjectsPage
// Получение настроек: Присвоение брендов объектам - страница объектов
const getAssignBrandsObjectsPage = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS,payload: {
                ...result,
                projectId: data.projectId,
            },
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/saveAssignBrandsObjects
// POST /bff/adm/clients/company/brands/saveAssignBrandsObjects
// Сохранение настроек: Присвоение брендов объектам - страница проектов
const saveAssignBrandsObjects = function* ({payload}) {
    const {
        url,
        onSuccess,
        checkError,
        isRemoving,
        brandName,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage, errorCode} = result;

        const isSuccessErrorCodes = [
            // Изменен статус объекта, бренд не будет сохранен для закрытых объектов
            "BRAND_NOT_APP_OBJECTS",
        ].includes(errorCode);

        if (isSuccessErrorCodes && errorMessage) {
            toastSuccess(errorMessage);
        }

        if (errorMessage && !isSuccessErrorCodes) {
            toastError(errorMessage);
            checkError && checkError(result);

            yield put({
                type: CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (isRemoving) {
            toastSuccess("В выбранных объектах успешно удалены бренды");
        }

        if (!isRemoving && !isSuccessErrorCodes) {
            toastSuccess(`Бренд "${brandName}" применен к выбранным объектам`);
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/getLogsBrandChange
// POST /bff/adm/clients/company/brands/getLogsBrandChange
// Лог изменений бренда
const getLogsBrandChange = function* ({payload}) {
    const {
        url,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_GET_LOGS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_BRANDS_GET_LOGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_GET_LOGS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/brands/assignBrandsProjectsObjectsList
// POST /bff/adm/clients/company/brands/assignBrandsProjectsObjectsList
// Получение настроек: Присвоение брендов объектам - список проектов с объектами
const getAssignBrandsProjectsObjectsList = function* ({payload}) {
    const {
        url,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(url, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_BRANDS_UPDATE_REQUEST, updateClientBrand),
        takeEvery(CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST, updateClientBrandArchiveStatus),
        takeEvery(CLIENT_BRANDS_GET_PAGE_REQUEST, getClientBrands),
        takeEvery(CLIENT_BRANDS_DELETE_LOGO_REQUEST, deleteClientBrandLogo),
        takeEvery(CLIENT_BRANDS_ADD_REQUEST, addClientBrand),
        takeEvery(CLIENT_BRANDS_ADD_LOGO_REQUEST, addClientBrandLogo),
        takeEvery(CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST, getAssignBrandsProjectsPage),
        takeEvery(CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST, getAssignBrandsObjectsPage),
        takeEvery(CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST, saveAssignBrandsObjects),
        takeEvery(CLIENT_BRANDS_GET_LOGS_REQUEST, getLogsBrandChange),
        takeEvery(CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST, getAssignBrandsProjectsObjectsList),
    ]);
}