import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {
    formatAmountWithNullChecking,
    getNumberFromFormattedAmount,
} from "../../../../../utils/stringFormat";
import {transformYupFormattedAmountToNumber} from "../../../../../utils/yupTransforms";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {
    getSettingsDirectoriesBank,
    setSettingsDirectoriesBank,
} from "../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";

const useSettingsDirectoriesWithdrawalCommissionForm = ({data}) => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const initialValues = useMemo(() => {
        return {
            minWithdrawalAmount: formatAmountWithNullChecking(data.minWithdrawalAmount),
            withdrawalAmountWithoutCommission: formatAmountWithNullChecking(data.withdrawalAmountWithoutCommission),
            contractorCommissionAmount: formatAmountWithNullChecking(data.contractorCommissionAmount),
        };
    }, [data]);

    useEffect(() => {
        fetchSettings();
    }, []);

    const toggleCard = () => {
        if (!isEdit) {
            setIsEdit(true);

            return;
        }

        handleSubmit();
    };

    const cancelEditMode = () => {
        setIsEdit(false);
    };

    const fetchSettings = () => {
        dispatch(getSettingsDirectoriesBank({
            key: "contractorCommissionWithdrawal",
        }));
    };

    const onSubmit = () => {
        if (!isValid) {
            return false;
        }

        dispatch(setSettingsDirectoriesBank({
            key: "contractorCommissionWithdrawal",
            value: JSON.stringify({
                minWithdrawalAmount: getNumberFromFormattedAmount(values.minWithdrawalAmount),
                withdrawalAmountWithoutCommission: getNumberFromFormattedAmount(values.withdrawalAmountWithoutCommission),
                contractorCommissionAmount: getNumberFromFormattedAmount(values.contractorCommissionAmount),
            }),
            onSuccess: () => {
                fetchSettings();
                setIsEdit(false);
            },
        }));
    };

    const {
        values,
        handleSubmit,
        errors,
        touched,
        isValid,
        handleChange,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            minWithdrawalAmount: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .transform(transformYupFormattedAmountToNumber)
                .min(0, "Минимальное значение - 0")
                .max( 5000, "Максимальное значение - 5000"),
            withdrawalAmountWithoutCommission: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .transform(transformYupFormattedAmountToNumber)
                .min(0, "Минимальное значение - 0")
                .max( 10000, "Максимальное значение - 10000"),
            contractorCommissionAmount: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .transform(transformYupFormattedAmountToNumber)
                .min(0, "Минимальное значение - 0")
                .max( 5000, "Максимальное значение - 5000"),
        }),
    });

    return {
        isEdit,
        setIsEdit,
        values,
        handleSubmit,
        errors,
        touched,
        handleChange,
        toggleCard,
        cancelEditMode,
    };
};

export default useSettingsDirectoriesWithdrawalCommissionForm;