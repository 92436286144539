import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../../constants/page";

import {getKedoStaffFioPage, getKedoStaffPositionsPage} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoStaffFioOptionsSelector,
    bffCommonDictsKedoStaffRegistryPositionsDictSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoOrganizationStructureStaffFilters = (params) => {
    const {
        pageSize,
        setPagination,
        clientId,
    } = params;

    const dispatch = useDispatch();

    const staffPositionsFilterOptions = useSelector(bffCommonDictsKedoStaffRegistryPositionsDictSelector);
    const staffFioFiltersOptions = useSelector(bffCommonDictsKedoStaffFioOptionsSelector);

    const mapFilterDto = (filter) => {
        const {
            fioFilter,
            positionIdsFilter,
        } = filter;

        return {
            fioFilter,
            positionIdsFilter: isNullOrWhitespace(positionIdsFilter) ? undefined : [positionIdsFilter],
        };
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: {
            positionIdsFilter: [],
            fioFilter: "",
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        search: true,
                        onSearchChange: (fioFilter) => {
                            dispatch(getKedoStaffFioPage({
                                pageNum: 1,
                                pageSize: PAGE_DEFAULT_SIZE,
                                fioFilter: handleFormString(fioFilter),
                                clientIdFilter: clientId,
                                archiveFilter: false,
                            }));
                        },
                        options: staffFioFiltersOptions,
                        name: "fioFilter",
                        label: "ФИО сотрудника",
                        placeholder: "Выберите сотрудника",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        search: true,
                        onSearchChange: (nameFilter) => {
                            dispatch(getKedoStaffPositionsPage({
                                pageNum: 1,
                                pageSize: PAGE_DEFAULT_SIZE,
                                nameFilter,
                                clientIdFilter: clientId,
                            }));
                        },
                        options: staffPositionsFilterOptions,
                        name: "positionIdsFilter",
                        label: "Должность сотрудника",
                        placeholder: "Выберите должность",
                    },
                ],
            },
        ];
    }, [
        staffPositionsFilterOptions,
        staffFioFiltersOptions,
    ]);

    return {
        filters,
        filterData,
        filter,
        isSearch,
        onClear,
        onSearch,
    };
};