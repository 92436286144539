import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {useKedoDepartments} from "../../../../../components/KedoDepartmentsDropdown/hooks/useDepartments";
import {useKedoDepartmentDeputyHead} from "../../hooks/useDepartmentDeputyHead";
import {useKedoDepartmentHead} from "../../hooks/useDepartmentHead";

import {getFullName} from "../../../../../utils/stringFormat";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {
    addKedoDepartment,
    getRichKedoDepartmentCard, updateKedoDepartment,
    updateKedoDepartmentsStore,
} from "../../../../../ducks/kedo/departments/actionCreators";
import {
    kedoDepartmentsProgressActionSelector,
    kedoDepartmentsProgressPreUpdateCardSelector,
    kedoDepartmentsRichCardSelector,
} from "../../../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructureEdit = (props) => {
    const {
        kedoDepartmentId,
        clientId,
        onClose,
        isFetchList,
        isFetchCard,
        isRootDepartment,
    } = props;

    const dispatch = useDispatch();

    const card = useSelector(kedoDepartmentsRichCardSelector);
    const progressAction = useSelector(kedoDepartmentsProgressActionSelector);
    const progressCard = useSelector(kedoDepartmentsProgressPreUpdateCardSelector);

    const departmentsDropdownProps = useKedoDepartments({
        clientId,
        parentDepartmentId: kedoDepartmentId,
    });

    useEffect(() => {
        if (kedoDepartmentId) {
            dispatch(getRichKedoDepartmentCard({
                kedoDepartmentId,
            }));
        }

        return () => {
            dispatch(updateKedoDepartmentsStore({
                richCard: {},
            }));
        };
    }, []);

    const {
        handleSubmit,
        values,
        touched,
        setFieldValue,
        errors,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            name: card.name || "",
            parentDepartmentId: card.parentDepartmentId || "",
            headStaffId: card.headStaffId || "",
            deputyHeadStaffId: card.deputyHeadStaffId || "",
        },
        validationSchema: yup.object().shape({
            name: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            parentDepartmentId: isRootDepartment
                ? null
                : yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            headStaffId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        validateOnBlur: false,
    });

    const headDropdownProps = useKedoDepartmentHead({
        errors,
        touched,
        clientId,
        filterId: values.deputyHeadStaffId,
        isFetchAll: true,
    });
    const deputyHeadDropdownProps = useKedoDepartmentDeputyHead({
        errors,
        touched,
        clientId,
        filterId: values.headStaffId,
        isFetchAll: true,
    });

    function onSubmit() {
        if (kedoDepartmentId) {
            dispatch(updateKedoDepartment({
                isFetchList,
                isFetchCard,
                kedoDepartmentId,
                clientId,
                ...values,
            }));

            return;
        }

        dispatch(addKedoDepartment({
            isFetchList,
            clientId,
            ...values,
        }));
    }

    const onChange = (_event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {kedoDepartmentId ? "Редактирование отдела" : "Добавление отдела"}
                </NmTitle>
            }
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    submitBtnContent="Сохранить"
                />
            }
            loading={progressAction || progressCard}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    size="xl"
                    required={true}
                    name="name"
                    value={values.name}
                    label="Наименование"
                    placeholder="Введите наименование"
                    maxLength={255}
                    onChange={onChange}
                    error={
                        touched?.name &&
                        errors?.name
                    }
                />
                {
                    !isRootDepartment &&
                    <NmDropdownV2
                        {...departmentsDropdownProps}
                        name="parentDepartmentId"
                        search={true}
                        required={true}
                        onChange={onChange}
                        label="Родительский отдел"
                        placeholder="Выберите отдел"
                        value={values.parentDepartmentId}
                        error={
                            touched?.parentDepartmentId &&
                            errors?.parentDepartmentId
                        }
                        initialOption={
                            card.parentDepartmentId
                                ? {
                                    key: card.parentDepartmentId,
                                    value: card.parentDepartmentId,
                                    text: card.name,
                                }
                                : undefined
                        }
                    />
                }
                <NmDropdownV2
                    {...headDropdownProps}
                    required={true}
                    onChange={onChange}
                    value={values.headStaffId}
                    initialOption={
                        card.headStaffId
                            ? {
                                value: card.headStaffId,
                                text: getFullName(
                                    card.headStaffLastName,
                                    card.headStaffFirstName,
                                    card.headStaffPatronymic,
                                ),
                            }
                            : undefined
                    }
                />
                <NmDropdownV2
                    {...deputyHeadDropdownProps}
                    onChange={onChange}
                    value={values.deputyHeadStaffId}
                    initialOption={
                        card.deputyHeadStaffId
                            ? {
                                key: card.deputyHeadStaffId,
                                value: card.deputyHeadStaffId,
                                text: getFullName(
                                    card.deputyHeadStaffLastName,
                                    card.deputyHeadStaffFirstName,
                                    card.deputyHeadStaffPatronymic,
                                ),
                            }
                            : undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};