import {
    BFF_CONTRACTOR_ORDER_HISTORY_GET_PAGE_REQUEST,
    CLEAR_CONTRACTOR_ORDER_HISTORY_STORE,
} from "./actions";

export const getPageContractorOrderHistory = (payload) => {
    return {
        type: BFF_CONTRACTOR_ORDER_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorOrderHistory = () => {
    return {
        type: CLEAR_CONTRACTOR_ORDER_HISTORY_STORE,
    };
};