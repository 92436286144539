import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import HelpTooltip from "../../../../../../../components/ActualComponents/HelpTooltip";
import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import {OrderEditContext} from "../../../../context-provider";

import {ORDER_STATUS} from "../../../../../../../constants/clientList";
import {ORDER_CREATION_MODE} from "../../../../../../../constants/order";
import {ORDER_EDIT_TYPE_OPTIONS, ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS} from "../../../../constants";
import {ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES} from "../../../../context-provider/constants";

import {clientCardPropertiesSelector} from "../../../../../../../ducks/bff/clients/info/selectors";
import {getDocumentCustomTemplateShortDefault} from "../../../../../../../ducks/documentsCustomTemplate";
import {orderCreationDataSelector} from "../../../../../../../ducks/order";

const OrderEditFormPreConditionsOrderType = (props) => {
    const {
        creatingMode,
        formData,
        formError,
        isDuplicateOrder,
        isPromptOn,
    } = props;
    const {
        status,
    } = useSelector(orderCreationDataSelector);
    const {
        clientId,
        editOrderId,
        setForm,
    } = useContext(OrderEditContext);
    const {
        foremanEnabled,
        canCreateOnlyVolumeOfWorkBasedOrders = true,
    } = useSelector(clientCardPropertiesSelector);
    const dispatch = useDispatch();

    const onChange = (event, {name, value}) => {
        props.onChange(event, {
            name,
            value,
            // Обновление дефолтных значений шаблонов в списках при изменении типа заказа при редактировании
            onChangeCallback: () => {
                if (!editOrderId) {
                    return;
                }

                ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES.forEach(item => {
                    const {
                        documentTypeFilter,
                        fieldName,
                    } = item;

                    // Получение дефолтного шаблона
                    dispatch(getDocumentCustomTemplateShortDefault({
                        clientIdFilter: clientId,
                        orderContractPaymentTypeFilter: formData.orderContractPaymentType,
                        documentTypeFilter,
                        orderKindFilter: value,
                        onSuccess: ({id}) => {
                            setForm({
                                [fieldName]: id,
                            });
                        },
                    }));
                });
            },
        });
    };

    return (
        <NmDropdownV2
            search
            disabled={
                // Создание заказов только с заполнением ТС и объемов работ = выкл
                canCreateOnlyVolumeOfWorkBasedOrders ||
                // Бригадир = вкл
                foremanEnabled ||
                (
                    !isDuplicateOrder &&
                    [
                        ORDER_STATUS.CONTRACTOR_SEARCH,
                        ORDER_STATUS.PUBLISHED,
                    ].includes(status)
                ) ||
                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
            }
            placeholder="Выберите тип заказа"
            label="Тип заказа"
            name="orderKind"
            onChange={onChange}
            value={formData.orderKind}
            options={ORDER_EDIT_TYPE_OPTIONS}
            error={formError.orderKind}
            isVisibleTooltip={isPromptOn}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    compound
                    body={ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS}
                    position="bottom-left"
                />
            }
        />
    );
};

export default OrderEditFormPreConditionsOrderType;