import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import ContractorMedicalBookInfoEditForm from "./components/edit-form";

import {useContractorAdditionalDocuments} from "../../hooks/useAdditionalDocuments";
import {useMedicalBookInfoCardAction} from "./hooks/useAction";

import {getCardModeByStatusSegment} from "../../utils/statusSegment";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../../constants/contractor";

import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";

const MedicalBookInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const card = useSelector(contractorCardInfoSelector);

    const {
        contractorId,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useMedicalBookInfoCardAction({});

    const {
        requestDocumentButton,
        documentInfo,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
    } = useContractorAdditionalDocuments({
        contractorId,
        documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK,
    });

    const data = documentInfo.data || {};

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <ContractorMedicalBookInfoEditForm
                contractorId={contractorId}
                data={data}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Медицинская книжка (доп. документ)"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            onClickDelete={toggleShowDeleteConfirm}
            isVisibleDeleteButton={isEditable && documentInfo.canBeDeleted}
            accessEdit={isEditable}
            mode={getCardModeByStatusSegment(documentInfo.status)}
            lastUpdate={documentInfo.updateDateTime}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 3,
                desktop: 3,
            }}
            mediaControlsButtons={[requestDocumentButton]}
        >
            {renderDeleteConfirm()}
            {renderEditForm()}
            {
                documentInfo.status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        labels={[
                            {label: "Типографский номер", text: data.medicalBookNumber},
                        ]}
                        scans={[
                            {
                                label: "Фотография разворота главной страницы медицинской книжки",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN,
                            },
                            {
                                label: "Фотография QR-кода для проверки медицинской книжки",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN,
                            },
                        ]}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default MedicalBookInfoCard;