import React from "react";
import {useDispatch, useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import CardApp from "../../../../components/CardApp";
import {ProjectsObjectsCheckboxList} from "../../../../components/ProjectsObjectsCheckboxList";

import {useModal} from "../../../../hooks/useModal";

import {getClientCardProperties} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {
    getClientSettingsObjectsTree,
    getClientSettingsProjectsTree,
    getClientSettingsTreeList,
    getClientsSettingsHideOrderProjectsObjectsList,
    getClientsSettingsHideOrderProjectsPage,
    getClientsSettingsHideOrdersObjectsPage,
    hideClientsSettingsOrders,
    saveClientSettingsTree,
    updateStoreClientsSetting,
} from "../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsErrorSelector,
    bffClientsSettingsHidingOrdersObjectsSelector,
    bffClientsSettingsHidingOrdersProjectsAndObjectsSelector,
    bffClientsSettingsHidingOrdersProjectsSelector,
    bffClientsSettingsHidingOrdersProjectsTreeNodesSelector,
    bffClientsSettingsPaymentsProgressActionSelector,
    bffClientsSettingsPaymentsProgressSelector,
} from "../../../../ducks/bff/clients/settings/selectors";

const ClientTreeSettings = (props) => {
    const {
        title,
        isEditable,
        clientId,
        settingsType,
        settingsName,
        modalTitle,
        checkboxLabel,
    } = props;

    const dispatch = useDispatch();

    const clientProperties = useSelector(clientCardPropertiesSelector);
    const hidingOrders = useSelector(bffClientsSettingsHidingOrdersProjectsTreeNodesSelector);
    const objects = useSelector(bffClientsSettingsHidingOrdersObjectsSelector);
    const projects = useSelector(bffClientsSettingsHidingOrdersProjectsSelector);
    const progressAction = useSelector(bffClientsSettingsPaymentsProgressActionSelector);
    const projectsAndObjects = useSelector(bffClientsSettingsHidingOrdersProjectsAndObjectsSelector);
    const progressSearch = useSelector(bffClientsSettingsPaymentsProgressSelector);
    const error = useSelector(bffClientsSettingsErrorSelector);

    const {
        onOpenModal,
        onCloseModal,
        isOpen,
    } = useModal();

    const toggleCard = () => {
        onOpenModal({});
    };

    const updateObjectsCallback = () => {
        dispatch(updateStoreClientsSetting({
            hidingOrders: {
                ...hidingOrders,
                objects: {
                    lists: {},
                    totalCount: 0,
                    pageData: {},
                },
            },
            error: null,
        }));
    };

    const clearNodesFromStore = () => {
        dispatch(updateStoreClientsSetting({
            hidingOrders: {
                projects: {
                    clientInfo: {},
                    list: [],
                    totalCount: 0,
                    pageData: {},
                },
                objects: {
                    lists: {},
                    totalCount: 0,
                    pageData: {},
                },
                projectsAndObjects: {},
            },
            error: null,
        }));
    };

    const fetchObjects = ({projectId, pageNum, onSuccess, pageSize}) => {
        const data = {
            pageNum,
            clientId,
            projectIdsFilter: [projectId],
            pageSize,
            onSuccess,
        };

        if (settingsType) {
            dispatch(getClientSettingsObjectsTree({
                ...data,
                settingsType,
            }));

            return;
        }

        dispatch(getClientsSettingsHideOrdersObjectsPage(data));
    };

    const fetchProjectsAndObjects = (projectObjectNameFilter) => {
        const data = {
            clientId,
            projectObjectNameFilter,
        };

        if (settingsType) {
            dispatch(getClientSettingsTreeList({
                ...data,
                settingsType,
            }));

            return;
        }

        dispatch(getClientsSettingsHideOrderProjectsObjectsList(data));
    };

    const onSubmitForm = ({requestData, onSuccess}) => {
        const data = {
            ...requestData,
            onSuccess: () => {
                onSuccess();

                dispatch(getClientCardProperties({clientId}));
            },
        };

        if (settingsType) {
            dispatch(saveClientSettingsTree({
                ...data,
                settingsType,
            }));

            return;
        }

        dispatch(hideClientsSettingsOrders(data));
    };

    const fetchProjects = ({pageNum, isSearchCleared, onSuccess}) => {
        const data = {
            pageNum,
            isSearchCleared,
            pageSize: 10,
            clientId,
            onSuccess,
        };

        if (settingsType) {
            dispatch(getClientSettingsProjectsTree({
                ...data,
                settingsType,
            }));

            return;
        }

        dispatch(getClientsSettingsHideOrderProjectsPage(data));
    };

    return (
        <CardApp
            title={title}
            isEditable={isEditable}
            onClickIcon={toggleCard}
        >
            {
                isOpen &&
                <ProjectsObjectsCheckboxList
                    settingsName={settingsName}
                    title={modalTitle}
                    onClose={onCloseModal}
                    updateObjectsCallback={updateObjectsCallback}
                    clearNodesFromStore={clearNodesFromStore}
                    fetchObjects={fetchObjects}
                    fetchProjectsAndObjects={fetchProjectsAndObjects}
                    onSubmitForm={onSubmitForm}
                    fetchProjects={fetchProjects}
                    clientId={clientId}
                    objects={objects}
                    projects={projects}
                    progressAction={progressAction}
                    clientProperties={clientProperties}
                    projectsAndObjects={projectsAndObjects}
                    progressSearch={progressSearch}
                    error={error}
                    checkboxLabel={checkboxLabel}
                />
            }
            <NmCheckboxV2
                onChange={() => {}}
                disabled={true}
                label={checkboxLabel}
                checked={clientProperties[settingsName]}
            />
        </CardApp>
    );
};

export default ClientTreeSettings;