import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const contractorCardDocumentsSelector = state => state.bff.contractorCardDocuments;
export const contractorCardDocumentsProgressSelector  = createSelector(contractorCardDocumentsSelector, ({progress}) => progress);
export const contractorCardDocumentsProgressActionSelector  = createSelector(contractorCardDocumentsSelector, ({progressAction}) => progressAction);

// Агентский договор с Наймикс
export const documentAgencyContractListSelector = createSelector(contractorCardDocumentsSelector, ({agencyContract: {list}}) => list);
export const documentAgencyContractPageDataSelector = createSelector(contractorCardDocumentsSelector, ({agencyContract}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = agencyContract;

    return getTotalPages(totalCount, pageSize);
});

export const documentAgencyContractTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({agencyContract}) => {
    const {totalCount} = agencyContract;

    return totalCount;
});

// Согласие на обработку ПД
export const documentConsentToPDListSelector = createSelector(contractorCardDocumentsSelector, ({consentToPersonalData: {list}}) => list);
export const documentConsentToPDPageDataSelector = createSelector(contractorCardDocumentsSelector, ({consentToPersonalData}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = consentToPersonalData;

    return getTotalPages(totalCount, pageSize);
});
export const documentConsentToPDTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({consentToPersonalData}) => {
    const {totalCount} = consentToPersonalData;

    return totalCount;
});

// Договоры с заказчиками
export const getFrameContractDocumentListSelector = createSelector(contractorCardDocumentsSelector, ({frameContract: {list}}) => list);
export const getFrameContractDocumentTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({frameContract}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = frameContract;

    return getTotalPages(totalCount, pageSize);
});

export const getFrameContractDocumentTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({frameContract}) => {
    const {totalCount} = frameContract;

    return totalCount;
});

// Заявки на выполнение работ (оказание услуг)
export const documentsOrderApplicationListSelector = createSelector(contractorCardDocumentsSelector, ({orderApplication: {list}}) => list);
export const documentsOrderApplicationTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({orderApplication}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = orderApplication;

    return getTotalPages(totalCount, pageSize);
});

export const documentsOrderApplicationTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({orderApplication}) => {
    const {totalCount} = orderApplication;

    return totalCount;
});

// Акты выполненных работ
export const documentsActOfAcceptanceOfWorkListSelector = createSelector(contractorCardDocumentsSelector, ({actOfAcceptanceOfWork: {list}}) => list);
export const documentsActOfAcceptanceOfWorkTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({actOfAcceptanceOfWork}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = actOfAcceptanceOfWork;

    return getTotalPages(totalCount, pageSize);
});

export const documentsActOfAcceptanceOfWorkTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({actOfAcceptanceOfWork}) => {
    const {totalCount} = actOfAcceptanceOfWork;

    return totalCount;
});

// Страховые полисы
export const documentsInsurancePolicyListSelector = createSelector(contractorCardDocumentsSelector, ({insurancePolicy: {list}}) => list);
export const documentsInsurancePolicyTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({insurancePolicy}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = insurancePolicy;

    return getTotalPages(totalCount, pageSize);
});

export const documentsInsurancePolicyTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({insurancePolicy}) => {
    const {totalCount} = insurancePolicy;

    return totalCount;
});

// Квитанции РНКО
export const documentsReceiptsRNKOListSelector = createSelector(contractorCardDocumentsSelector, ({receiptRNKO: {list}}) => list);
export const documentsReceiptsRNKOTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({receiptRNKO}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = receiptRNKO;

    return getTotalPages(totalCount, pageSize);
});

export const documentsReceiptsRNKOTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({receiptRNKO}) => {
    const {totalCount} = receiptRNKO;

    return totalCount;
});

export const getRegistryFrameContractShortItemsSelector = createSelector(contractorCardDocumentsSelector, ({registryFrameContractShortItems}) => registryFrameContractShortItems);
export const getRegistryFrameContractShortTotalCountSelector = createSelector(contractorCardDocumentsSelector, ({registryFrameContractShortTotalCount}) => registryFrameContractShortTotalCount);
export const getRegistryFrameContractShortTotalPagesSelector = createSelector(contractorCardDocumentsSelector, ({registryFrameContractShortTotalCount = 0, registryFrameContractShortPageSize}) => getTotalPages(registryFrameContractShortTotalCount, registryFrameContractShortPageSize));

export const documentIdentificationSheetListSelector = createSelector(contractorCardDocumentsSelector, ({identificationSheetList}) => {
    return identificationSheetList.filter(item => Boolean(item.fileLink));
});
export const contractorDocumentCountsSelector = createSelector(contractorCardDocumentsSelector, ({contractorDocumentCounts}) => contractorDocumentCounts);
export const contractorPendingContractsListSelector = createSelector(contractorCardDocumentsSelector, ({pendingContracts}) => pendingContracts.list);