import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {ReactComponent as SuccessIcon} from "../../images/check-bg.svg";
import {ReactComponent as CheckIcon} from "../../images/checkV2.svg";
import {ReactComponent as DeleteIcon} from "../../images/close_24.svg";
import {ReactComponent as EditIcon} from "../../images/create.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmButton from "../NmButton";
import NmTitle from "../NmTitle";

import {isNullOrWhitespace} from "../../utils/stringHelper";
import {toastSuccess} from "../../utils/toastHelper";

import {ADMIN, NM_MANAGER} from "../../constants/roles";

import {
    getInfoContractorCard,
    updateProjectParamsContractorCard,
} from "../../ducks/bff/contractor-сard/actionCreators";
import {contractorCardInfoSelector} from "../../ducks/bff/contractor-сard/selectors";
import {
    updateLeadsCompleted,
    updateLeadsCompletedProgressSelector,
} from "../../ducks/contractor";

import "./style.sass";

export default function ContractorEditParams(props) {
    const {
        close,
        projectParams = [],
        leadsCompleted = [],
        role,
    } = props;

    const dispatch = useDispatch();
    const contractorCard = useSelector(contractorCardInfoSelector);
    const updateLeadsCompletedProgress = useSelector(updateLeadsCompletedProgressSelector);

    const [params, setParams] = useState([]);
    const [isAddParam, setIsAddParam] = useState(false);
    const [newParam, setNewParam] = useState("");
    const [editParam, setEditParam] = useState("");
    const [keyEditParam, setKeyEditParam] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmData, setConfirmData] = useState({});

    useEffect(() => {
        if (!isEmpty(projectParams)) {
            setParams([...projectParams]);
        }
    }, [projectParams]);

    const onChangeNewParam = (e, {value}) => {
        setNewParam(value);
    };

    const onChangeEditParam = (e, {value}) => {
        setEditParam(value);
    };

    const save = () => {
        dispatch(updateProjectParamsContractorCard({
            contractorId: contractorCard.contractorId,
            projectParams: params,
            onSuccess: () => {
                toastSuccess("ProjectParams успешно изменены");
                dispatch(getInfoContractorCard(contractorCard.contractorId));
                close();
            },
        }));
    };

    const deleteMark = (value) => {
        dispatch(updateLeadsCompleted({
            contractorId: contractorCard.contractorId,
            leadCompleted: leadsCompleted.filter((item) => item !== value),
            onSuccess: () => {
                toastSuccess("Признак завершения лида успешно удален");
                setConfirmData({});
            },
        }));
    };

    function renderItem(item) {
        return (
            <>
                <div className="col-md-6">
                    {item}
                </div>
                <div className="col-md-10 flex flex-aligned-center">
                    <EditIcon
                        className="contractor-edit-params-project-params-block__edit-icon col-md-2"
                        onClick={() => {
                            setErrorMessage("");
                            setKeyEditParam(item);
                            setEditParam(item);
                        }}
                    />
                    <DeleteIcon
                        onClick={() => {
                            deleteParam(item);
                        }}
                        className="contractor-edit-params-project-params-block__delete-icon col-md-2"
                    />
                    {
                        leadsCompleted.includes(item) && [ADMIN, NM_MANAGER].includes(role) &&
                        <div className="flex flex-aligned-center">
                            <NmAdvancedTooltip
                                className="col-md-4"
                                hover
                                position="bottom-left"
                                trigger={<SuccessIcon
                                    width={24}
                                    height={24}
                                />}
                                children="Лид с данным параметром является завершенным (находится в коллекции leadsCompleted)"
                            />
                            <NmButton
                                disabled={updateLeadsCompletedProgress}
                                className="ms-2"
                                size="sm"
                                color="gray"
                                onClick={() => setConfirmData({
                                    content: "Вы действительно хотите удалить метку?",
                                    onConfirm: () => deleteMark(item),
                                    loading: updateLeadsCompletedProgress,
                                })}
                                children="Удалить метку"
                            />
                        </div>
                    }
                </div>
            </>
        );
    }

    function renderEditItem(item) {
        return (
            <div className="flex">
                <NmInputV2
                    error={errorMessage}
                    label={`Редактирование параметра "${item}"`}
                    value={editParam}
                    name="editParam"
                    onChange={onChangeEditParam}
                />
                <CheckIcon
                    className="contractor-edit-params-project-params-block__submit-icon"
                    onClick={() => editNewParam(item)}
                />
                <DeleteIcon
                    onClick={() => {
                        setKeyEditParam("");
                        setEditParam("");
                    }}
                    className="contractor-edit-params-project-params-block__delete-icon contractor-edit-params-project-params-block__delete-icon_mt"
                />
            </div>
        );
    }

    function mapParams() {
        const _params = params.map(item => {
            return (
                <div className="contractor-edit-params-project-params-block">
                    {keyEditParam && keyEditParam === item ? renderEditItem(item) : renderItem(item)}
                </div>
            );
        });

        _params.unshift(renderNewElement());

        return _params;
    }


    const addNewParam = () => {
        setErrorMessage("");

        if (isNullOrWhitespace(newParam)) {
            setErrorMessage("Заполните значение");
            return;
        }

        if (params.includes(newParam.trim())) {
            setErrorMessage("Значение уже существует");
            return;
        }

        setParams(prevState => [...prevState, newParam.trim()]);
        setIsAddParam(false);
        setNewParam("");
    };

    const handleEditParam = (oldParams, oldItem) => {
        return oldParams.map(p => p === oldItem ? editParam.trim() : p);
    };

    const editNewParam = (oldItem) => {
        setErrorMessage("");

        if (isNullOrWhitespace(editParam)) {
            setErrorMessage("Заполните значение");
            return;
        }

        if (params.includes(editParam.trim())) {
            setErrorMessage("Значение уже существует");
            return;
        }

        setParams(prevState => handleEditParam(prevState, oldItem));
        setKeyEditParam("");
    };

    const deleteParam = (param) => {
        setParams(prevState => prevState.filter(item => item !== param));
    };

    function renderNewElement() {
        return <>
            {isAddParam &&
            <div className="contractor-edit-params-project-params-block-new-block">
                <NmInputV2
                    label="Новый параметр"
                    value={newParam}
                    name="newParam"
                    onChange={onChangeNewParam}
                    error={errorMessage}
                />
                <CheckIcon
                    className="contractor-edit-params-project-params-block__submit-icon"
                    onClick={addNewParam}
                />

            </div>
            }
            {!isAddParam && <NmButton
                color="white"
                onClick={() => {
                    setErrorMessage("");
                    setIsAddParam(true);
                }}
            >
                Добавить новое значение
            </NmButton>}
        </>;
    }

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
            loading,
        } = confirmData;

        return (
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                loading={loading}
            />
        );
    };

    return (
        !isEmpty(confirmData) ?
            renderConfirmModal() :
            <NmModal
                onClose={close}
                size="sm"
                header={
                    <NmTitle size="lg">
                        Редактирование ProjectParams
                    </NmTitle>
                }
                classNameContent="contractor-edit-params-content"
                footer={<ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="colum"
                    onClose={close}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={save}
                />}
            >
                {mapParams()}
            </NmModal>
    );
}