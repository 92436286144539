export const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST";
export const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS";
export const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR";

export const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST";
export const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS";
export const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR";

export const SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST = "SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST";
export const SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS = "SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS";
export const SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR = "SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR";

export const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST";
export const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS";
export const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR";

export const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST";
export const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS";
export const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR";

export const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST";
export const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS";
export const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR";

export const CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE = "CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE";