import {
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_ERROR,
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST,
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_SUCCESS,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_ERROR,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_SUCCESS,
    UPDATE_FINANCES_RECEIPTS_CANCELLED_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    data: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_SUCCESS: {
            const {
                reports,
                totalCount,
            } = payload;

            return {
                ...state,
                list: reports,
                totalCount: totalCount,
                progress: false,
            };
        }
        case BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_SUCCESS:
        case BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case UPDATE_FINANCES_RECEIPTS_CANCELLED_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};