import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const kedoDepartments = state => state.kedoDepartments;
export const kedoDepartmentsListSelector = createSelector(
    kedoDepartments,
    ({list}) => list,
);
export const kedoDepartmentsOptionsListSelector = createSelector(
    kedoDepartments,
    ({list}) => {
        return list.map(item => {
            return {
                key: item.kedoDepartmentId,
                value: item.kedoDepartmentId,
                text: item.name,
            };
        });
    },
);
export const kedoDepartmentsProgressSelector = createSelector(
    kedoDepartments,
    ({progress}) => progress,
);
export const kedoDepartmentsProgressActionSelector = createSelector(
    kedoDepartments,
    ({progressAction}) => progressAction,
);
export const kedoDepartmentsCardSelector = createSelector(
    kedoDepartments,
    ({card}) => card,
);
export const kedoDepartmentsProgressCardSelector = createSelector(
    kedoDepartments,
    ({progressCard}) => progressCard,
);
export const kedoDepartmentsProgressPreUpdateCardSelector = createSelector(
    kedoDepartments,
    ({progressPreUpdateCard}) => progressPreUpdateCard,
);
export const kedoDepartmentsRichCardSelector = createSelector(
    kedoDepartments,
    ({richCard}) => richCard,
);
export const kedoDepartmentsTotalCountSelector = createSelector(
    kedoDepartments,
    ({totalCount}) => totalCount,
);
export const kedoDepartmentsTotalPagesSelector = createSelector(
    kedoDepartments,
    ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize),
);
export const kedoDepartmentsRootSelector = createSelector(
    kedoDepartments,
    ({root}) => root,
);
export const kedoDepartmentsErrorSelector = createSelector(
    kedoDepartments,
    ({error}) => error,
);
const kedoDepartmentsLogsSelector = state => state.kedoDepartments.logs;
export const kedoDepartmentsLogsListSelector = createSelector(
    kedoDepartmentsLogsSelector,
    ({list}) => list,
);
export const kedoDepartmentsLogsTotalCountSelector = createSelector(
    kedoDepartmentsLogsSelector,
    ({totalCount}) => totalCount,
);
export const kedoDepartmentsLogsTotalPagesSelector = createSelector(
    kedoDepartmentsLogsSelector,
    ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize),
);
export const kedoDepartmentsLogsProgressSelector = createSelector(
    kedoDepartmentsLogsSelector,
    ({progress}) => progress,
);