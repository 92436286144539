import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";
import Text from "../../../../components/ActualComponents/Text";
import NmHint from "../../../../components/NmHint";

import {PATTERN_NUMBER} from "../../../deposit/client-list/hooks/useGetlDepositData";

import bem from "../../../../utils/bem";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {COLOR} from "../../../../constants/color";
import {GENDER_OPTIONS} from "../../../../constants/contractorInfo";
import {SimpleDropdownOptions} from "../../../../constants/contractorList";
import {OBJECT_STATUS_DICT} from "../../../../constants/objects";
import {PROJECT_STATUS_DICT} from "../../../../constants/projects";
import {mapOrderStatuses} from "../../../../constants/status";
import {MARKER_TYPE} from "../../constants";

import {contractorDrivingLicenseDictOptionsSelector} from "../../../../ducks/bff/contractor-сard/profile/selectors";
import {getCitizenshipOptionsSelector} from "../../../../ducks/contractor";
import {
    objectsForOrdersOptionsSelector,
    objectsForTasksOptionsSelector,
} from "../../../../ducks/objects";
import {
    getProjectsForOrdersOptionsSelector,
    getProjectsForTasksOptionsSelector,
} from "../../../../ducks/projects";
import {specialitiesAllV2OptionsSelector} from "../../../../ducks/speciality";

import "./style.sass";

const MapFilter = (props) => {
    const {
        onSubmitFilter,
        onChangeFilter,
        onClearFilter,
        filter,
    } = props;

    const [block, element] = bem("map-filter");

    const {t} = useTranslation();
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const driverLicenseOptions = useSelector(contractorDrivingLicenseDictOptionsSelector);
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const projectsForOrdersOptions = useSelector(getProjectsForOrdersOptionsSelector);
    const projectsForTasksOptions = useSelector(getProjectsForTasksOptionsSelector);
    const objectsForOrdersOptions = useSelector(objectsForOrdersOptionsSelector);
    const objectsForTasksOptions = useSelector(objectsForTasksOptionsSelector);
    const orderStatusOptions = dictionaryToOptions(mapOrderStatuses);

    const [errors, setErrors] = useState({});

    const {
        ordersFilters = {},
        crowdTasksFilters = {},
        contractorsFilters = {},
        advertsFilters = {},
    } = filter;

    const onKeyPress = ({key}) => {
        if (key === "Enter") {
            onSearch();
        }
    };

    const onSearch = () => {
        // if (ageFromFilter && ageToFilter && Number(ageFromFilter) > Number(ageToFilter)) {
        //     setErrors({
        //         ageFilter: `Значение "От" должно быть меньше или равно значению "До"`
        //     })
        //
        //     return
        // }

        setErrors({});
        onSubmitFilter(filter);
    };

    const onClear = () => {
        setErrors({});
        onClearFilter();
        props.onClearFilter();
    };

    const renderFilterBlock = ({title, blockName, filters}) => {
        return (
            <NmHint
                reverse={true}
                buttonClassName="mt-2"
                buttonColor="gray"
                customButton={
                    <Text.Title
                        level="4"
                        color={COLOR.SECONDARY_90}
                    >
                        {title}
                    </Text.Title>
                }
                chevronSize={20}
                fullWidth={true}
            >
                <NmForm
                    addMargin
                    onKeyPress={onKeyPress}
                    className="map-filter__form"
                >
                    {filters}
                </NmForm>
            </NmHint>
        );
    };

    const renderOrdersFilter = () => {
        return renderFilterBlock({
            title: "Заказы",
            blockName: "ordersFilters",
            filters: <>
                <NmDateRangePickerV2
                    size="lg"
                    label="Период работ"
                    minDate={{
                        end: ordersFilters.workDateFrom,
                    }}
                    startFieldName="ordersFilters.workDateFrom"
                    endFieldName="ordersFilters.workDateTo"
                    value={{
                        "ordersFilters.workDateFrom": ordersFilters.workDateFrom,
                        "ordersFilters.workDateTo": ordersFilters.workDateTo,
                    }}
                    onChange={onChangeFilter}
                    isClearable={true}
                />
                <FilterCustomer
                    size="lg"
                    name="ordersFilters.clientIdsFilter"
                    value={ordersFilters.clientIdsFilter}
                    onChange={onChangeFilter}
                    onSubmit={onSearch}
                    isMountLoad={false}
                    multiple
                />
                <FilterProject
                    size="lg"
                    onChange={onChangeFilter}
                    projectIdsFilter={ordersFilters.projectIdsFilter}
                    clientIdsFilter={ordersFilters.clientIdsFilter || []}
                    isOnlyWithClientIdsFilter={true}
                    name="ordersFilters.projectIdsFilter"
                    onSubmit={onSearch}
                    placeholder="Показать все"
                    searchEmptyByValueFilter={false}
                    statusFilter={PROJECT_STATUS_DICT.IN_WORK.VALUE}
                    listFieldName="projectsForOrders"
                    options={projectsForOrdersOptions}
                />
                <FilterObject
                    size="lg"
                    name="ordersFilters.objectIdsFilter"
                    onChange={onChangeFilter}
                    objectIdsFilter={ordersFilters.objectIdsFilter}
                    projectIdsFilter={ordersFilters.projectIdsFilter}
                    clientIdsFilter={ordersFilters.clientIdsFilter || []}
                    isOnlyWithClientIdsFilter={true}
                    onSubmit={onSearch}
                    placeholder="Показать все"
                    statusFilter={OBJECT_STATUS_DICT.IN_WORK.VALUE}
                    listFieldName="objectsForOrders"
                    options={objectsForOrdersOptions}
                />
                <NmDropdownV2
                    size="lg"
                    placeholder="Показать все"
                    label="Вид деятельности на заказе"
                    name="ordersFilters.specialityIdsFilter"
                    onSubmit={onSearch}
                    options={specialityOptions}
                    value={ordersFilters.specialityIdsFilter}
                    onChange={onChangeFilter}
                    multiple={true}
                    search={true}
                />
                <NmDropdownV2
                    size="lg"
                    label="Статус заказа"
                    options={orderStatusOptions}
                    name="ordersFilters.statusFilter"
                    onChange={onChangeFilter}
                    value={ordersFilters.statusFilter}
                    placeholder="Все"
                    isClearable={false}
                />
                <NmCheckboxV2
                    size="lg"
                    name="ordersFilters.contractorRecruitmentRequiredFilter"
                    checked={ordersFilters.contractorRecruitmentRequiredFilter}
                    onChange={onChangeFilter}
                    label="Требуется подбор"
                />
            </>,
        });
    };

    const renderTasksFilter = () => {
        return (
            renderFilterBlock({
                title: "Задания",
                blockName: "crowdTasksFilters",
                filters: <>
                    <NmDateRangePickerV2
                        size="lg"
                        label="Период работ"
                        minDate={{
                            end: crowdTasksFilters.workStartDateFromFilter,
                        }}
                        startFieldName="crowdTasksFilters.workStartDateFromFilter"
                        endFieldName="crowdTasksFilters.workEndDateToFilter"
                        value={{
                            "crowdTasksFilters.workStartDateFromFilter": crowdTasksFilters.workStartDateFromFilter,
                            "crowdTasksFilters.workEndDateToFilter": crowdTasksFilters.workEndDateToFilter,
                        }}
                        onChange={onChangeFilter}
                        isClearable={true}
                    />
                    <FilterCustomer
                        size="lg"
                        name="crowdTasksFilters.clientIdsFilter"
                        value={crowdTasksFilters.clientIdsFilter}
                        onChange={onChangeFilter}
                        onSubmit={onSearch}
                        isMountLoad={false}
                        multiple
                    />
                    <FilterProject
                        size="lg"
                        onChange={onChangeFilter}
                        projectIdsFilter={crowdTasksFilters.projectIdsFilter}
                        clientIdsFilter={crowdTasksFilters.clientIdsFilter || []}
                        isOnlyWithClientIdsFilter={true}
                        name="crowdTasksFilters.projectIdsFilter"
                        onSubmit={onSearch}
                        placeholder="Показать все"
                        searchEmptyByValueFilter={false}
                        statusFilter={PROJECT_STATUS_DICT.IN_WORK.VALUE}
                        listFieldName="projectsForTasks"
                        options={projectsForTasksOptions}
                    />
                    <FilterObject
                        size="lg"
                        name="crowdTasksFilters.objectIdsFilter"
                        onChange={onChangeFilter}
                        objectIdsFilter={crowdTasksFilters.objectIdsFilter}
                        projectIdsFilter={crowdTasksFilters.projectIdsFilter}
                        clientIdsFilter={crowdTasksFilters.clientIdsFilter || []}
                        isOnlyWithClientIdsFilter={true}
                        onSubmit={onSearch}
                        placeholder="Показать все"
                        statusFilter={OBJECT_STATUS_DICT.IN_WORK.VALUE}
                        listFieldName="objectsForTasks"
                        options={objectsForTasksOptions}
                    />
                    <NmDropdownV2
                        size="lg"
                        placeholder="Показать все"
                        label="Вид деятельности на задании"
                        name="crowdTasksFilters.specialityIdsFilter"
                        onSubmit={onSearch}
                        options={specialityOptions}
                        value={crowdTasksFilters.specialityIdsFilter}
                        onChange={onChangeFilter}
                        multiple={true}
                        search={true}
                    />
                </>,
            })
        );
    };

    const renderContractorFilter = () => {
        return (
            renderFilterBlock({
                title: "Исполнители",
                blockName: "contractorsFilters",
                filters: <>
                    <NmDatePicker
                        size="lg"
                        dateFormatMask="99.99.9999"
                        label="Дата последнего визита"
                        name="contractorsFilters.lastLoginDateFromFilter"
                        selected={contractorsFilters.lastLoginDateFromFilter}
                        onChange={onChangeFilter}
                        isClearable={true}
                    />
                    <NmDropdownV2
                        size="lg"
                        placeholder="Показать все"
                        label="Вид деятельности"
                        name="contractorsFilters.specialityIdsFilter"
                        onSubmit={onSearch}
                        options={specialityOptions}
                        value={contractorsFilters.specialityIdsFilter}
                        onChange={onChangeFilter}
                        multiple={true}
                        search={true}
                    />
                    <NmDropdownV2
                        size="lg"
                        label="Гражданство"
                        name="contractorsFilters.citizenshipFilter"
                        className="fluid"
                        onChange={onChangeFilter}
                        onSubmit={onSearch}
                        options={citizenshipOptions}
                        value={contractorsFilters.citizenshipFilter}
                        placeholder="Показать все"
                        multiple={true}
                        search={true}
                    />
                    <div className="row gx-4">
                        <div className="col-md-8">
                            <NmDropdownV2
                                placeholder="Все"
                                emptyOptionText="Все"
                                label="Пол"
                                name="contractorsFilters.genderFilter"
                                onChange={onChangeFilter}
                                value={contractorsFilters.genderFilter}
                                options={GENDER_OPTIONS}
                                size="lg"
                            />
                        </div>
                        <div className="col-md-8">
                            <NmRangeInput
                                label="Возраст"
                                placeholderStart={t("component-labels.from")}
                                placeholderEnd={t("component-labels.to")}
                                valueStart={contractorsFilters.ageFromFilter}
                                valueEnd={contractorsFilters.ageToFilter}
                                onChange={onChangeFilter}
                                pattern={PATTERN_NUMBER}
                                nameStart="contractorsFilters.ageFromFilter"
                                nameEnd="contractorsFilters.ageToFilter"
                                size="lg"
                                error={errors.ageFilter}
                            />
                        </div>
                    </div>
                    <div className="row gx-4">
                        <div className="col-md-8">
                            <NmDropdownV2
                                placeholder="Все"
                                emptyOptionText="Все"
                                label="Мед книжка"
                                name="contractorsFilters.medicalBookFilter"
                                onChange={onChangeFilter}
                                value={contractorsFilters.medicalBookFilter}
                                options={SimpleDropdownOptions}
                                size="lg"
                            />
                        </div>
                        <div className="col-md-8">
                            <NmDropdownV2
                                placeholder="Все"
                                label="Водительские права"
                                name="contractorsFilters.driverLicenseFilter"
                                onSubmit={onSearch}
                                onChange={onChangeFilter}
                                value={contractorsFilters.driverLicenseFilter}
                                options={driverLicenseOptions}
                                size="lg"
                                multiple
                            />
                        </div>
                    </div>
                </>,
            })
        );
    };

    const renderAdvertFilter = () => {
        return (
            renderFilterBlock({
                title: "Объявления",
                blockName: "advertsFilters",
                filters: <>
                    <FilterCustomer
                        size="lg"
                        name="advertsFilters.clientIdsFilter"
                        value={advertsFilters.clientIdsFilter}
                        onChange={onChangeFilter}
                        onSubmit={onSearch}
                        isMountLoad={false}
                        multiple
                    />
                    <NmDropdownV2
                        size="lg"
                        placeholder="Показать все"
                        label="Вид деятельности"
                        name="advertsFilters.specialityIdsFilter"
                        onSubmit={onSearch}
                        options={specialityOptions}
                        value={advertsFilters.specialityIdsFilter}
                        onChange={onChangeFilter}
                        multiple={true}
                        search={true}
                    />
                    <NmDropdownV2
                        size="lg"
                        label="Гражданство"
                        name="advertsFilters.citizenshipFilter"
                        className="fluid"
                        onChange={onChangeFilter}
                        onSubmit={onSearch}
                        options={citizenshipOptions}
                        value={advertsFilters.citizenshipFilter}
                        placeholder="Показать все"
                        multiple={true}
                        search={true}
                    />
                    <div className="row gx-4">
                        <div className="col-md-8">
                            <NmDropdownV2
                                size="lg"
                                placeholder="Все"
                                emptyOptionText="Все"
                                label={t("contractor-list.gender")}
                                name="advertsFilters.genderFilter"
                                onChange={onChangeFilter}
                                value={advertsFilters.genderFilter}
                                options={GENDER_OPTIONS}
                            />
                        </div>
                        <div className="col-md-8">
                            <NmRangeInput
                                label="Возраст"
                                placeholderStart={t("component-labels.from")}
                                placeholderEnd={t("component-labels.to")}
                                valueStart={advertsFilters.ageFromFilter}
                                valueEnd={advertsFilters.ageToFilter}
                                onChange={onChangeFilter}
                                pattern={PATTERN_NUMBER}
                                nameStart="advertsFilters.ageFromFilter"
                                nameEnd="advertsFilters.ageToFilter"
                                size="lg"
                                error={errors.ageFilter}

                            />
                        </div>
                    </div>
                    <NmDateRangePickerV2
                        size="lg"
                        label="Период публикации"
                        minDate={{
                            end: advertsFilters.publicationFromFilter,
                        }}
                        startFieldName="advertsFilters.publicationFromFilter"
                        endFieldName="advertsFilters.publicationToFilter"
                        value={{
                            "advertsFilters.publicationFromFilter": advertsFilters.publicationFromFilter,
                            "advertsFilters.publicationToFilter": advertsFilters.publicationToFilter,
                        }}
                        onChange={onChangeFilter}
                        isClearable={true}
                    />
                </>,
            })
        );
    };

    const renderEmptyFilter = () => {
        return (
            <div className={element("info")}>
                <Text level="3">
                    Для фильтрации и показа элементов на карте вам необходимо их выбрать в блоке "Показывать только"
                </Text>
            </div>
        );
    };

    return (
        <div className={block()}>
            {
                isEmpty(filter.types) ?
                    renderEmptyFilter() :
                    <>
                        {
                            filter.types.includes(MARKER_TYPE.ORDER) &&
                            renderOrdersFilter()
                        }
                        {
                            filter.types.includes(MARKER_TYPE.CROWD_TASK) &&
                            renderTasksFilter()
                        }
                        {
                            filter.types.includes(MARKER_TYPE.CONTRACTOR) &&
                            renderContractorFilter()
                        }
                        {
                            filter.types.includes(MARKER_TYPE.ADVERT) &&
                            renderAdvertFilter()
                        }
                        <FilterButtonsV2
                            className="map-filter__buttons"
                            onSearch={onSearch}
                            onClear={onClear}
                        />
                    </>
            }
        </div>
    );
};

export default MapFilter;