import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getInfoContractorCard} from "../../../ducks/bff/contractor-сard/actionCreators";

export const useFullRegistrationFetchContractor = (params) => {
    const {
        contractorId,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInfoContractorCard(contractorId));
    }, []);

    return {
    };
};