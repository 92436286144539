import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {removePhoneMask} from "../../../../utils/stringFormat";
import validate from "../../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {clientCardRule} from "../../../../constants/validationRules";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardInfoSelector, getSignatoryInfoSelector} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class SignatoryCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor() {
        super();

        this.state = {
            errorForm: {},
            editSignatoryInfo: {},
            isEdit: false,
        };
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            signatoryInfo: _signatoryInfo,
            isEdit,
        } = state;
        const {signatoryInfo} = newProps;

        if (
            JSON.stringify(signatoryInfo) !== JSON.stringify(_signatoryInfo) &&
            !isEdit
        ) {
            return {
                ...state,
                editSignatoryInfo: {
                    ...signatoryInfo,
                },
                signatoryInfo,
            };
        }

        return null;
    }

    handleChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            editSignatoryInfo: {
                ...prevState.editSignatoryInfo,
                [name]: value,
            },
        }));
    };

    isValidEditClient(editClient) {
        const rule = clientCardRule.signatoryInfo;

        const errorForm = validate(editClient, rule, "");

        this.setState(prevState => ({
            ...prevState,
            errorForm,
        }));

        return Object.values(errorForm).length === 0;
    }

    submitSignatoryInfo = () => {
        const {updateClientCardInfo, signatoryInfo, client} = this.props;

        const {editSignatoryInfo} = this.state;

        if (JSON.stringify(editSignatoryInfo) === JSON.stringify(signatoryInfo)) {
            return;
        }

        const requestData = {
            ...client,
            ...editSignatoryInfo,
        };

        updateClientCardInfo(requestData);
    };

    cancelEdit = () => {
        const {editSignatoryInfoCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            editSignatoryInfo: editSignatoryInfoCopy,
        }));
    };

    toggleCard = () => {
        const {isEdit, editSignatoryInfo} = this.state;

        if (isEdit) {
            const validationData = {
                ...editSignatoryInfo,
                [CLIENT_FIELD_NAME.MANAGER_PHONE]: removePhoneMask(
                    editSignatoryInfo[CLIENT_FIELD_NAME.MANAGER_PHONE],
                ),
            };

            if (!this.isValidEditClient(validationData)) {
                return;
            }
        } else {
            this.setState({
                editSignatoryInfoCopy: editSignatoryInfo,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {this.submitSignatoryInfo();}
            },
        );
    };

    renderCardReadOnly(client) {
        const {t} = this.props;

        return (
            <>
                <LabelTextApp
                    label={t("client-signatory-info.position")}
                    className="client-card-block__label mt-15"
                    text={
                        client[CLIENT_FIELD_NAME.SIGNATORY] ||
                        t("is-not-specified.content")
                    }
                />
                <LabelTextApp
                    label={t("client-signatory-info.decryption")}
                    className="client-card-block__label"
                    text={
                        client[CLIENT_FIELD_NAME.SIGNATORY_DECODING] ||
                        t("is-not-specified.content")
                    }
                />
            </>
        );
    }

    renderCardEditMode(client) {
        const {t} = this.props;
        const {errorForm} = this.state;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-signatory-info.position")}
                            required
                            placeholder={t("client-signatory-info.position")}
                            name={CLIENT_FIELD_NAME.SIGNATORY}
                            value={client[CLIENT_FIELD_NAME.SIGNATORY] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.SIGNATORY]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-signatory-info.decryption")}
                            required
                            placeholder={t("client-signatory-info.decryption")}
                            name={CLIENT_FIELD_NAME.SIGNATORY_DECODING}
                            value={client[CLIENT_FIELD_NAME.SIGNATORY_DECODING] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.SIGNATORY_DECODING]}
                        />
                    </div>
                </div>
            </NmForm>
        );
    }

    render() {
        const {isEdit, editSignatoryInfo} = this.state;
        const {
            isEditable,
            t,
        } = this.props;

        return (
            <CardApp
                title={t("client-signatory-info.client-signatory-title")}
                className="client-card-block"
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEdit}
                isEdit={isEdit}
            >
                {!isEdit ?
                    this.renderCardReadOnly(editSignatoryInfo) :
                    this.renderCardEditMode(editSignatoryInfo)}
            </CardApp>
        );

    }
}

export default connect(state => ({
    signatoryInfo: getSignatoryInfoSelector(state),
    client: clientCardInfoSelector(state),
}), {
    updateClientCardInfo,
})(withTranslation()(SignatoryCardBlock));
