import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {endMyCheckOnContractorPersonalDataStatus} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";

export const useContractorVerificationEndCheckOnPersonalStatus = (contractorId) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            endCheck();
        };
    }, []);

    const endCheck = (params = {}) => {
        const {
            onSuccess,
        } = params;

        dispatch(endMyCheckOnContractorPersonalDataStatus({
            contractorId,
            onSuccess,
        }));
    };

    return {
        endCheck,
    };
};