import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import ClientMemberCard from "../../client/client-members-card";
import FnsService from "../../fns-service";
import MLModelsList from "../../machine-learning/list";
import TechsupportCard from "../../techsupport/techsupport-card";
import TechsupportList from "../../techsupport/techsupport-list";
import CollectionBankDetails from "../collection-bank-details";
import SettingsDirectories from "../directories";
import SettingsDocumentsCorrection from "../documents-correction";
import {SettingsInstructions} from "../instructions";
import SettingsService from "../service";
import SettingsClientGroup from "../settings-client-group";
import SettingsInfo from "../settings-info";
import SettingsJoiningRequests from "../settings-joining-requests";
import SettingsLogCommon from "../settings-logs";
import SettingsUsersContainer from "../settings-users";
import SettingsUsersRoles from "../users-roles";

import {ls, USER_ROLE} from "../../../utils/localstorage";

import {
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DOCUMENTS_CORRECTION,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_INSTRUCTION, LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_LOG,
    LINK_SETTINGS_MEMBERS_CARD,
    LINK_SETTINGS_MEMBERS_LIST, LINK_SETTINGS_ML,
    LINK_SETTINGS_SERVICE,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_USERS_ROLES_LIST,
    LinkNavigator,
} from "../../../constants/links";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT, NM_COORDINATOR,
    NM_MANAGER, NM_OPERATOR,
} from "../../../constants/roles";

export function SettingsRoute() {
    const role = ls(USER_ROLE);

    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={LINK_SETTINGS_INFO}
                component={SettingsInfo}
            />
            <CheckRoleRoute
                exact
                path={LINK_SETTINGS_TECHSUPPORT_LIST}
                component={TechsupportList}
            />
            <CheckRoleRoute
                exact
                path={LINK_SETTINGS_JOINING_REQUESTS}
                component={SettingsJoiningRequests}
            />
            <CheckRoleRoute
                exact
                path={LINK_SETTINGS_TECHSUPPORT_CARD}
                component={TechsupportCard}
            />
            <CheckRoleRoute
                path={LINK_SETTINGS_INSTRUCTION}
                component={SettingsInstructions}
            />
            <CheckRoleRoute
                path={LINK_SETTINGS_INSTRUCTION_KEDO}
                component={SettingsInstructions}
            />
            {role === ADMIN && <CheckRoleRoute
                path={LINK_SETTINGS_MEMBERS_LIST}
                component={SettingsUsersContainer}
            />}
            {role === ADMIN && <CheckRoleRoute
                exact
                path={LINK_SETTINGS_MEMBERS_CARD}
                component={(props) => (
                    <ClientMemberCard
                        isSettingsAdmin
                        {...props}
                    />
                )}
            />}
            <CheckRoleRoute
                path={LINK_SETTINGS_DIRECTORIES}
                component={SettingsDirectories}
            />
            {
                role === ADMIN &&
                <Route
                    path={LINK_SETTINGS_LOG}
                    component={SettingsLogCommon}
                />
            }
            {
                [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) &&
                <CheckRoleRoute
                    exact
                    path={LINK_SETTINGS_FNS_SERVICE}
                    component={FnsService}
                />
            }
            <CheckRoleRoute
                path={LINK_SETTINGS_CLIENT_GROUP}
                component={SettingsClientGroup}
            />
            {
                [ADMIN, NM_MANAGER, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <CheckRoleRoute
                    path={LINK_SETTINGS_SERVICE}
                    component={SettingsService}
                />
            }
            {
                [ADMIN, NM_MANAGER].includes(role) &&
                <CheckRoleRoute
                    path={LINK_SETTINGS_DOCUMENTS_CORRECTION}
                    component={SettingsDocumentsCorrection}
                />
            }
            {
                [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <CheckRoleRoute
                    path={LINK_SETTINGS_COLLECTION_BANK_DETAILS}
                    component={CollectionBankDetails}
                />
            }
            {
                [ADMIN, NM_COORDINATOR].includes(role) &&
                <CheckRoleRoute
                    path={LINK_SETTINGS_ML}
                    component={MLModelsList}
                />
            }
            {
                [ADMIN].includes(role) &&
                <CheckRoleRoute
                    path={LINK_SETTINGS_USERS_ROLES_LIST}
                    component={SettingsUsersRoles}
                />
            }
        </Switch>
    );
}