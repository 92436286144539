import {guid} from "../../../../../../../utils/stringHelper";

import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../../../../constants/dicts";
import {
    KEDO_DOCUMENT_ROUTE_STEP_ACTION_TYPE_DICT,
    KEDO_DOCUMENT_ROUTE_STEP_INITIAL_VALUES,
} from "../constants";

export const getKedoDocumentRouteEditModalInitialValuesStepsItem = () => {
    return {
        temporaryId: guid(),
        ...KEDO_DOCUMENT_ROUTE_STEP_INITIAL_VALUES,
    };
};

const getInitialStepTemplatesItem = (item) => {
    const {
        actionType,
        matchParticipantParams,
        ...otherValues
    } = item;

    const actionTypes = KEDO_DOCUMENT_ROUTE_STEP_ACTION_TYPE_DICT[actionType] || {
        actionType: null,
        signType: null,
    };

    return {
        temporaryId: guid(),
        ...otherValues,
        ...matchParticipantParams,
        ...actionTypes,
    };
};

export const getKedoDocumentRouteEditModalInitialValues = (data) => {
    const {
        stepTemplates,
        type,
        actionType,
        isClientPage,
        ...otherValues
    } = data;

    return {
        isClientPage,
        flexRouteType: type
            ? type === KEDO_DOCUMENTS_ROUTE_TYPE.FLEX_ROUTE
            : !isClientPage,
        clientId: null,
        name: "",
        nameForClient: "",
        files: [],
        stepTemplates: stepTemplates
            ? stepTemplates.map(item => {
                return getInitialStepTemplatesItem(item);
            })
            : [
                getKedoDocumentRouteEditModalInitialValuesStepsItem(),
                getKedoDocumentRouteEditModalInitialValuesStepsItem(),
            ],
        ...otherValues,
    };
};