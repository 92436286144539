import {
    BFF_FINANCES_PATENTS_SUMMARY_LIST_ERROR,
    BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST,
    BFF_FINANCES_PATENTS_SUMMARY_LIST_SUCCESS,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_ERROR,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_SUCCESS,
} from "./actions";

const initialState = {
    payments: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    paymentStatusDict: {},
    summaryData: [],
    summaryTotalData: {},
    summaryPageData: {},
    summaryDataProgress: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST:
            return {
                ...state,
                summaryPageData: payload,
                summaryDataProgress: true,
            };
        case BFF_FINANCES_PATENTS_SUMMARY_LIST_SUCCESS:
            return {
                ...state,
                summaryData: payload.payments,
                summaryDataProgress: false,
                summaryTotalCount: payload.totalCount,
            };
        case BFF_FINANCES_PATENTS_SUMMARY_LIST_ERROR:
            return {
                ...state,
                error: payload,
                summaryDataProgress: false,
            };
        case BFF_FINANCES_PATENTS_TOTAL_SUMMARY_SUCCESS:
            return {
                ...state,
                summaryTotalProgress: false,
                summaryTotalData: payload,
            };
        case BFF_FINANCES_PATENTS_TOTAL_SUMMARY_ERROR:
            return {
                ...state,
                error: payload,
                summaryTotalProgress: false,
            };
        case BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST:
            return {
                ...state,
                summaryTotalProgress: true,
            };
        default:
            return state;
    }
};
