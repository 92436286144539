import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as InfoIcon} from "../../../../../images/info_24.svg";

import {usePagination} from "../../../../../hooks/usePagination";

import {COLOR} from "../../../../../constants/color";

import {
    getPageErrorsCrowdActRegistryEntry,
    updateStoreCrowdActRegistryEntry,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/actionCreators";
import {
    bffCrowdActRegistryEntryErrorsListSelector,
    bffCrowdActRegistryEntryErrorsProgressSelector,
    bffCrowdActRegistryEntryErrorsTotalCountSelector,
    bffCrowdActRegistryEntryErrorsTotalPagesSelector,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/selectors";

const CrowdTaskActRegistryErrorsEntryModal = (props) => {
    const {
        onClose = () => {
        },
        registryId,
    } = props;

    const list = useSelector(bffCrowdActRegistryEntryErrorsListSelector);
    const totalPages = useSelector(bffCrowdActRegistryEntryErrorsTotalPagesSelector);
    const totalCount = useSelector(bffCrowdActRegistryEntryErrorsTotalCountSelector);
    const progress = useSelector(bffCrowdActRegistryEntryErrorsProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("bg-modal", 10);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdActRegistryEntry({
                errorsList: [],
                errorsTotalCount: 0,
                errorsPageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum]);

    const fetchList = () => {
        dispatch(getPageErrorsCrowdActRegistryEntry({
            pageSize: 10,
            pageNum,
            registryId,
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                rowNumber,
                validationResult,
            } = item;

            return {
                ...item,
                key: rowNumber,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Номера строки",
                                text: rowNumber,
                            },
                            {
                                label: "Причина ошибки",
                                colorLabel: "red",
                                text: validationResult?.validationResults?.map(({errorMessage}) => errorMessage).join(", ") || "-",
                                noWrap: false,
                                inline: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Ошибка запуска реестра
                </NmTitle>
            }
            loading={progress}
            footer={
                <ApplyButtons
                    submitBtnContent="Понятно"
                    submit={onClose}
                />
            }
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                <div className="flex align-items-start">
                    <InfoIcon
                        className="me-2"
                        color={COLOR.NEGATIVE_100}
                        width={24}
                        height={24}
                    />
                    <Text>
                        Найдены ошибки в некоторых строках реестра. Исправьте их, чтобы запустить реестр
                    </Text>
                </div>
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </NmPage>
        </NmModal>
    );
};

export default CrowdTaskActRegistryErrorsEntryModal;