import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/actregistrypayments";

//*  TYPES  *//
const GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST = "GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST";
const GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS = "GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS";
const GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR = "GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR";

const GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST = "GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST";
const GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS = "GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS";
const GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR = "GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR";

const ADD_ACT_REGISTRY_PAYMENT_REQUEST = "ADD_ACT_REGISTRY_PAYMENT_REQUEST";
const ADD_ACT_REGISTRY_PAYMENT_SUCCESS = "ADD_ACT_REGISTRY_PAYMENT_SUCCESS";
const ADD_ACT_REGISTRY_PAYMENT_ERROR = "ADD_ACT_REGISTRY_PAYMENT_ERROR";

const UPDATE_ACT_REGISTRY_PAYMENT_REQUEST = "UPDATE_ACT_REGISTRY_PAYMENT_REQUEST";
const UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS = "UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS";
const UPDATE_ACT_REGISTRY_PAYMENT_ERROR = "UPDATE_ACT_REGISTRY_PAYMENT_ERROR";

const IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST = "IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST";
const IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS = "IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS";
const IMPORT_ACT_REGISTRY_PAYMENTS_ERROR = "IMPORT_ACT_REGISTRY_PAYMENTS_ERROR";

const PAY_ACT_REGISTRY_PAYMENTS_REQUEST = "PAY_ACT_REGISTRY_PAYMENTS_REQUEST";
const PAY_ACT_REGISTRY_PAYMENTS_SUCCESS = "PAY_ACT_REGISTRY_PAYMENTS_SUCCESS";
const PAY_ACT_REGISTRY_PAYMENTS_ERROR = "PAY_ACT_REGISTRY_PAYMENTS_ERROR";

const RETRY_PAY_ACT_REGISTRY_PAYMENTS_REQUEST = "RETRY_PAY_ACT_REGISTRY_PAYMENTS_REQUEST";
const RETRY_PAY_ACT_REGISTRY_PAYMENTS_SUCCESS = "RETRY_PAY_ACT_REGISTRY_PAYMENTS_SUCCESS";
const RETRY_PAY_ACT_REGISTRY_PAYMENTS_ERROR = "RETRY_PAY_ACT_REGISTRY_PAYMENTS_ERROR";

const DELETE_ACT_REGISTRY_PAYMENTS_REQUEST = "DELETE_ACT_REGISTRY_PAYMENTS_REQUEST";
const DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS = "DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS";
const DELETE_ACT_REGISTRY_PAYMENTS_ERROR = "DELETE_ACT_REGISTRY_PAYMENTS_ERROR";

const UPDATE_ACT_REGISTRY_PAYMENTS_STORE = "UPDATE_ACT_REGISTRY_PAYMENTS_STORE";

//*  INITIAL STATE  *//
const initial = {
    pageData: {},
    list: [],
    card: {},
    totalCount: 0,
    progress: false,
    progressAction: false,
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ADD_ACT_REGISTRY_PAYMENT_REQUEST:
        case UPDATE_ACT_REGISTRY_PAYMENT_REQUEST:
        case IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST:
        case PAY_ACT_REGISTRY_PAYMENTS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS:
            const {
                registryPayments: list,
                totalCount,
            } = payload;

            return {
                ...state,
                list,
                totalCount,
                progress: false,
            };
        case GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case ADD_ACT_REGISTRY_PAYMENT_SUCCESS:
        case UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS:
        case IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS:
        case PAY_ACT_REGISTRY_PAYMENTS_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case ADD_ACT_REGISTRY_PAYMENT_ERROR:
        case UPDATE_ACT_REGISTRY_PAYMENT_ERROR:
        case IMPORT_ACT_REGISTRY_PAYMENTS_ERROR:
        case PAY_ACT_REGISTRY_PAYMENTS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_ACT_REGISTRY_PAYMENTS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export function getPageActRegistryPayments(payload) {
    return {
        type: GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function getByIdActRegistryPayment(payload) {
    return {
        type: GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
}

export function addActRegistryPayment(payload) {
    return {
        type: ADD_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
}

export function updateActRegistryPayment(payload) {
    return {
        type: UPDATE_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
}

export function importActRegistryPayments(payload) {
    return {
        type: IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function payActRegistryPayments(payload) {
    return {
        type: PAY_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function retryPayActRegistryPayments(payload) {
    return {
        type: RETRY_PAY_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function deleteActRegistryPayments(payload) {
    return {
        type: DELETE_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function updateActRegistryPaymentsStore(payload) {
    return {
        type: UPDATE_ACT_REGISTRY_PAYMENTS_STORE,
        payload,
    };
}

//*  SELECTORS  *//
export const actRegistryPaymentSelector = state => state.actRegistryPayment;
export const actRegistryPaymentListSelector = createSelector(actRegistryPaymentSelector, ({list}) => list);
export const actRegistryPaymentTotalPagesSelector = createSelector(actRegistryPaymentSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const actRegistryPaymentTotalCountSelector = createSelector(actRegistryPaymentSelector, ({totalCount}) => totalCount);
export const actRegistryPaymentCardSelector = createSelector(actRegistryPaymentSelector, ({card}) => card);
export const actRegistryPaymentProgressSelector = createSelector(actRegistryPaymentSelector, ({progress}) => progress);
export const actRegistryPaymentProgressActionSelector = createSelector(actRegistryPaymentSelector, ({progressAction}) => progressAction);

//*  SAGA  *//
//POST /api/actregistrypayments/getPage
export const getPageActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {registryPayments} = result;

        if (registryPayments?.length) {
            const contractorIds = registryPayments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/getById
export const getByIdActRegistryPaymentSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getById`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/add
export const addActRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: ADD_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/update
export const updateActRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/import/{clientId}/{registryId}/{definingRegistryParameterType}
export const importActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            clientId,
            registryId,
            definingRegistryParameterType,
            formData,
        } = payload;

        const result = yield request
            .post(
                `job/registryPayments/import/acts/${clientId}/${registryId}/${definingRegistryParameterType}`,
                formData,
                {...getMultipartHeaders()},
            );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IMPORT_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IMPORT_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/{clientId}/{registryId}/{registrySeqNum}/pay
export const payActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            registrySeqNum,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/${registrySeqNum}/pay`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PAY_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: PAY_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAY_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/{clientId}/{registryId}/{registrySeqNum}/retrypay
export const retryPayActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            registrySeqNum,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/${registrySeqNum}/retrypay`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RETRY_PAY_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: RETRY_PAY_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: RETRY_PAY_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistrypayments/deleteAll
export const deleteActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteAll`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST, getPageActRegistryPaymentsSaga),
        takeEvery(GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST, getByIdActRegistryPaymentSaga),
        takeEvery(ADD_ACT_REGISTRY_PAYMENT_REQUEST, addActRegistryPaymentSaga),
        takeEvery(UPDATE_ACT_REGISTRY_PAYMENT_REQUEST, updateActRegistryPaymentSaga),
        takeEvery(IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST, importActRegistryPaymentsSaga),
        takeEvery(PAY_ACT_REGISTRY_PAYMENTS_REQUEST, payActRegistryPaymentsSaga),
        takeEvery(RETRY_PAY_ACT_REGISTRY_PAYMENTS_REQUEST, retryPayActRegistryPaymentsSaga),
        takeEvery(DELETE_ACT_REGISTRY_PAYMENTS_REQUEST, deleteActRegistryPaymentsSaga),
    ]);
}
