import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffSettingsSettingsEmployeesSelector = state => state.bff.settingsEmployees;
export const bffSettingsSettingsEmployeesListSelector = createSelector(bffSettingsSettingsEmployeesSelector, ({nmUsers}) => {
    return nmUsers;
});
export const bffSettingsSettingsEmployeesTotalCountDataSelector = createSelector(bffSettingsSettingsEmployeesSelector, ({nmUsersTotalCountData}) => {
    return nmUsersTotalCountData;
});
export const bffSettingsSettingsEmployeesTotalPagesDataSelector = createSelector(bffSettingsSettingsEmployeesSelector, ({nmUsersTotalCountData, nmUsersPageData, totalCount}) => {
    return {
        totalCount: nmUsersTotalCountData.totalCount,
        otherTotalPages: getTotalPages(nmUsersTotalCountData.otherCount, nmUsersPageData.pageSize ?? 0),
        partnerTotalPages: getTotalPages(nmUsersTotalCountData.partnerCount, nmUsersPageData.pageSize ?? 0),
    };
});
export const bffSettingsSettingsEmployeesProgressUpdateSelector = createSelector(bffSettingsSettingsEmployeesSelector, ({progressUpdate}) => progressUpdate);
export const bffSettingsSettingsEmployeesClientsForSelectionOptionsSelector = createSelector(bffSettingsSettingsEmployeesSelector, ({clientsForSelection}) => {
    return clientsForSelection.map(({clientId, name}) => {
        return {
            key: clientId,
            text: name,
            value: clientId,
        };
    });
});
