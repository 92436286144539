import {createSelector} from "reselect";

import {getTotalPages} from "../../utils/mathHelper";

export const registryPaymentSelector = state => state.registryPayment;
export const registryPaymentListSelector = createSelector(
    registryPaymentSelector,
    ({list}) => list,
);
export const registryPaymentTotalCountSelector = createSelector(
    registryPaymentSelector,
    ({totalCount}) => totalCount,
);
export const registryPaymentTotalPagesSelector = createSelector(
    registryPaymentSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);
export const registryPaymentProgressListSelector = createSelector(
    registryPaymentSelector,
    ({progressList}) => progressList,
);
export const registryPaymentProgressActionSelector = createSelector(
    registryPaymentSelector,
    ({progressAction}) => progressAction,
);