import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT} from "../constants/messages";
import {ORDER_TEMPLATES_UPDATE_PARAMETERS} from "../constants/order";

const controller = "/clientProperties";

const UPDATE_FOR_CLIENT_REQUEST = "UPDATE_FOR_CLIENT_REQUEST";
const UPDATE_FOR_CLIENT_SUCCESS = "UPDATE_FOR_CLIENT_SUCCESS";
const UPDATE_FOR_CLIENT_ERROR = "UPDATE_FOR_CLIENT_ERROR";

const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST";
const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR";

const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST";
const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR";

const initial = {
    card: {},
    error: "",
    progressAction: false,
    progressClientProperties: false,
    progressEditCommission: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST:
        case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST:
            return {
                ...state,
                progressEditCommission: true,
            };
        case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS:
        case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS:
        case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR:
        case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR:
            return {
                ...state,
                progressEditCommission: false,
            };
        case UPDATE_FOR_CLIENT_REQUEST:
            return {
                ...state,
                progressUpdate: true,
            };
        case UPDATE_FOR_CLIENT_SUCCESS:
        case UPDATE_FOR_CLIENT_ERROR:
            return {
                ...state,
                progressUpdate: false,
            };
        default:
            return state;
    }
};

export function updateForClient(payload) {
    return {
        type: UPDATE_FOR_CLIENT_REQUEST,
        payload,
    };
}

export function changeClientPropertiesSmzCommissions(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST,
        payload,
    };
}

export function changeClientPropertiesGphCommissions(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST,
        payload,
    };
}

export const clientPropertiesSelector = state => state.clientProperties;
export const clientPropertiesProgressEditCommissionSelector = createSelector(clientPropertiesSelector, ({progressEditCommission}) => progressEditCommission);

export const updateForClientSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            data,
            onSuccess = () => {
            },
            onError = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/updateForClient`, data);

        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorCode === ORDER_TEMPLATES_UPDATE_PARAMETERS.TEMPLATE_NOT_FOUND_IN_OBJECTS) {
            const {failedIds} = result;

            onError(failedIds);

            yield put({
                type: UPDATE_FOR_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_FOR_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: UPDATE_FOR_CLIENT_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT.CLIENT_UPDATE_ERROR_TEXT);

        yield put({
            type: UPDATE_FOR_CLIENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientProperties/changeSmzCommissions
export const changeClientPropertiesSmzCommissionsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/npd-deposit/changeCommissions", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Процентные ставки по компании успешно изменены");
        onSuccess();

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        toastError("Процентные ставки по компании не были изменены, попробуйте позднее.");

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientProperties/changeGphCommissions
export const changeClientPropertiesGphCommissionsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/ndfl-deposit/changeCommissions", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Процентные ставки по компании успешно изменены");
        onSuccess();

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(UPDATE_FOR_CLIENT_REQUEST, updateForClientSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST, changeClientPropertiesSmzCommissionsSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST, changeClientPropertiesGphCommissionsSaga),
    ]);
}