import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearStoreContractorPersonalDataEventsLog,
    getPageContractorPersonalDataEventsLog,
} from "../../../../../ducks/bff/contractor-сard/event-log/personal-data-events/actionCreators";

export function useContractorOrderHistoryFetchList(props) {
    const {
        pageSize,
        pageNum,
        contractorIdFilter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearStoreContractorPersonalDataEventsLog());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getPageContractorPersonalDataEventsLog({
            pageNum,
            pageSize,
            contractorIdFilter,
        }));
    };

    return {
        fetchList,
    };
}