import {
    DELETE_EMPTY_RECORD_ERROR,
    DELETE_EMPTY_RECORD_REQUEST,
    DELETE_EMPTY_RECORD_SUCCESS,
    GET_EMPTY_RECORD_ERROR,
    GET_EMPTY_RECORD_REQUEST,
    GET_EMPTY_RECORD_SUCCESS,
    MAINTENANCE_NOTICE_PUBLISHED_SUCCESS,
    NDFL_DEPOSIT_CORRECT_ERROR,
    NDFL_DEPOSIT_CORRECT_REQUEST,
    NDFL_DEPOSIT_CORRECT_SUCCESS,
    NPD_DEPOSIT_CORRECT_ERROR,
    NPD_DEPOSIT_CORRECT_REQUEST,
    NPD_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS,
    SYSTEM_SETTING_SET_ERROR,
    SYSTEM_SETTING_SET_REQUEST,
    SYSTEM_SETTING_SET_SUCCESS,
    UPDATE_SETTINGS_SERVICE_STORE,
} from "./actions";

const initial = {
    progress: false,
    isMaintenanceNoticePublished: false,
    emptyRecordProgress: false,
    progressCorrection: false,
    ticketSettings: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SYSTEM_SETTING_SET_REQUEST:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case NPD_DEPOSIT_CORRECT_REQUEST:
        case NDFL_DEPOSIT_CORRECT_REQUEST:
            return {
                ...state,
                progressCorrection: true,
            };
        case SYSTEM_SETTING_SET_SUCCESS:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case NPD_DEPOSIT_CORRECT_SUCCESS:
        case NDFL_DEPOSIT_CORRECT_SUCCESS:
            return {
                ...state,
                progressCorrection: false,
            };
        case SYSTEM_SETTING_SET_ERROR:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR:
            return {
                ...state,
                progress: false,
            };
        case NPD_DEPOSIT_CORRECT_ERROR:
        case NDFL_DEPOSIT_CORRECT_ERROR:
            return {
                ...state,
                progressCorrection: false,
                error: payload,
            };
        case MAINTENANCE_NOTICE_PUBLISHED_SUCCESS:
            return {
                ...state,
                isMaintenanceNoticePublished: payload.result,
            };
        case GET_EMPTY_RECORD_REQUEST:
        case DELETE_EMPTY_RECORD_REQUEST:
            return {
                ...state,
                emptyRecordProgress: true,
            };
        case GET_EMPTY_RECORD_SUCCESS:
        case DELETE_EMPTY_RECORD_SUCCESS:
            return {
                ...state,
                emptyRecordProgress: false,
            };
        case GET_EMPTY_RECORD_ERROR:
        case DELETE_EMPTY_RECORD_ERROR:
            return {
                ...state,
                emptyRecordProgress: false,
            };
        case UPDATE_SETTINGS_SERVICE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};