import React from "react";
import {
    useDispatch,
    useSelector,
} from "react-redux";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";
import {useKedoOrganizationStructureStaffRegistryMoveForm} from "../../hooks/useStaffMoveForm";

import {
    kedoDepartmentsOptionsListSelector,
} from "../../../../../ducks/kedo/departments/selectors";
import {updateKedoDepartmentsStaff} from "../../../../../ducks/kedo/departments/staff/actionCreators";
import {
    kedoDepartmentsErrorSelector,
    kedoDepartmentsProgressActionSelector,
} from "../../../../../ducks/kedo/departments/staff/selectors";

export const KedoOrganizationStructureStaffRegistryMoveForm = (props) => {
    const {
        kedoStaffIds,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const departmentOptionsList = useSelector(kedoDepartmentsOptionsListSelector);
    const progressAction = useSelector(kedoDepartmentsProgressActionSelector);
    const error = useSelector(kedoDepartmentsErrorSelector);

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateKedoDepartmentsStaff({
                    error: null,
                }));
            }

            onClose();
        },
        progressAction,
        error,
    });

    const {
        values,
        errors,
        handleSubmit,
        onChange,
    } = useKedoOrganizationStructureStaffRegistryMoveForm({
        kedoStaffIds,
    });

    if (error?.errorCode === "ERROR_DEPARTMENT_CONTAINS_HEAD_STAFFS") {
        return (
            <NmConfirmV2
                size="sm"
                title="Выбранных сотрудников нельзя переместить"
                content={
                    <Text
                        level="4"
                        style={{textAlign: "center"}}
                    >
                        Среди выбранных сотрудников есть руководящие должности.
                        Назначьте новых руководителей отдела и повторите попытку перемещения
                    </Text>
                }
                isOnlyConfirm={true}
                confirmButton="Понятно"
                onCancel={() => {
                    dispatch(updateKedoDepartmentsStaff({
                        error: null,
                    }));
                }}
            />
        );
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle>
                    Вы действительно хотите переместить выбранных сотрудников?
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Переместить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
        >
            <NmForm addMargin={true}>
                <NmDropdownV2
                    label="Новый отдел"
                    required={true}
                    options={departmentOptionsList}
                    value={values.kedoDepartmentId}
                    error={errors.kedoDepartmentId}
                    placeholder="Введите название"
                    name="kedoDepartmentId"
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
};