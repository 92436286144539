import {
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
    UPDATE_FINANCES_FINANCES_STORE,
} from "./actions";

export const getPageFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const confirmFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
        payload,
    };
};

export const rejectFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
        payload,
    };
};

export const createReceiptFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST,
        payload,
    };
};

export const cancelReceiptFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
        payload,
    };
};

export const getReceiptFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST,
        payload,
    };
};

export const updateStoreFinancesPayments = (payload) => {
    return {
        type: UPDATE_FINANCES_FINANCES_STORE,
        payload,
    };
};