import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import {BrigadeRoute} from "./bigade-route";
import {BrigadeTabs} from "./brigade-tabs";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {NM_COORDINATOR} from "../../constants/roles";

import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";

import "./style.sass";

class Brigade extends Component {
    get params() {
        const {match} = this.props;
        const {
            params: {
                page: tab,
                clientId,
            },
        } = match;

        return {
            tab,
            clientId,
        };
    }

    get role(){
        return ls(USER_ROLE);
    }

    render() {
        const {
            client: {
                foremanFunctionalityAvailable,
            },
            t,
        } = this.props;

        if (!foremanFunctionalityAvailable) {
            return (
                <div className="access-message">
                    {t("brigade.section-not-available-for-company")}
                </div>
            );
        }

        if ([NM_COORDINATOR].includes(this.role)) {
            return (
                <div className="access-message">
                    {t("brigade.section-not-available-for-user")}
                </div>
            );
        }

        return (
            <NmPage
                className="brigade"
                header={
                    <NmPageHeader
                        text={t("brigade.brigade-title")}
                    />
                }
            >
                <div className="brigade__tabs nm-tabs-custom">
                    <BrigadeTabs
                        {...this.params}
                    />
                    <div className="brigade__content">
                        <BrigadeRoute />
                    </div>
                </div>
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        client: clientCardInfoSelector(state),
    }),
    {},
)(withTranslation()(Brigade));
