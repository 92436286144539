export const MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST = "MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST";
export const MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS = "MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS";
export const MARKETING_NOTIFICATIONS_GET_PAGE_ERROR = "MARKETING_NOTIFICATIONS_GET_PAGE_ERROR";

export const MARKETING_NOTIFICATIONS_ADD_REQUEST = "MARKETING_NOTIFICATIONS_ADD_REQUEST";
export const MARKETING_NOTIFICATIONS_ADD_SUCCESS = "MARKETING_NOTIFICATIONS_ADD_SUCCESS";
export const MARKETING_NOTIFICATIONS_ADD_ERROR = "MARKETING_NOTIFICATIONS_ADD_ERROR";

export const MARKETING_NOTIFICATIONS_CANCEL_REQUEST = "MARKETING_NOTIFICATIONS_CANCEL_REQUEST";
export const MARKETING_NOTIFICATIONS_CANCEL_SUCCESS = "MARKETING_NOTIFICATIONS_CANCEL_SUCCESS";
export const MARKETING_NOTIFICATIONS_CANCEL_ERROR = "MARKETING_NOTIFICATIONS_CANCEL_ERROR";

export const MARKETING_NOTIFICATIONS_RETRY_REQUEST = "MARKETING_NOTIFICATIONS_RETRY_REQUEST";
export const MARKETING_NOTIFICATIONS_RETRY_SUCCESS = "MARKETING_NOTIFICATIONS_RETRY_SUCCESS";
export const MARKETING_NOTIFICATIONS_RETRY_ERROR = "MARKETING_NOTIFICATIONS_RETRY_ERROR";

export const MARKETING_NOTIFICATIONS_GET_STATS_REQUEST = "MARKETING_NOTIFICATIONS_GET_STATS_REQUEST";
export const MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS = "MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS";
export const MARKETING_NOTIFICATIONS_GET_STATS_ERROR = "MARKETING_NOTIFICATIONS_GET_STATS_ERROR";

export const MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST = "MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST";
export const MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_SUCCESS = "MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_SUCCESS";
export const MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_ERROR = "MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_ERROR";


export const CLEAR_MARKETING_NOTIFICATIONS_STORE = "CLEAR_MARKETING_NOTIFICATIONS_STORE";

export const UPDATE_MARKETING_NOTIFICATIONS_STORE = "UPDATE_MARKETING_NOTIFICATIONS_STORE";