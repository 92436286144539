import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/contractors/promotion";

const PROMOTION_TYPE = {
    CONTRACTORS: "contractors",
    CLIENTS: "clients",
};
//*  TYPES  *//

const PROMOCODE_CHECK_ACTIVE_REQUEST = "PROMOCODE_CHECK_ACTIVE_REQUEST";
const PROMOCODE_CHECK_ACTIVE_SUCCESS = "PROMOCODE_CHECK_ACTIVE_SUCCESS";
const PROMOCODE_CHECK_ACTIVE_ERROR = "PROMOCODE_CHECK_ACTIVE_ERROR";

const PROMOCODE_CONTRACOR_LIST_REQUEST = " PROMOCODE_CONTRACOR_LIST_REQUEST";
const PROMOCODE_CONTRACOR_LIST_SUCCESS = "PROMOCODE_CONTRACOR_LIST_SUCCESS";
const PROMOCODE_CONTRACOR_LIST_ERROR = "PROMOCODE_CONTRACOR_LIST_ERROR";

const PROMOCODE_CLIENTS_LIST_REQUEST = " PROMOCODE_CLIENTS_LIST_REQUEST";
const PROMOCODE_CLIENTS_LIST_SUCCESS = "PROMOCODE_CLIENTS_LIST_SUCCESS";
const PROMOCODE_CLIENTS_LIST_ERROR = "PROMOCODE_CLIENTS_LIST_ERROR";

const PROMOCODE_REGISTRATION_REQUEST = "PROMOCODE_REGISTRATION_REQUEST";
const PROMOCODE_REGISTRATION_SUCCESS = "PROMOCODE_REGISTRATION_SUCCESS";
const PROMOCODE_REGISTRATION_ERROR = "PROMOCODE_REGISTRATION_ERROR";

const PROMOCODE_BY_CLIENT_ID_REQUEST = "PROMOCODE_BY_CLIENT_ID_REQUEST";
const PROMOCODE_BY_CLIENT_ID_SUCCESS = "PROMOCODE_BY_CLIENT_ID_SUCCESS";
const PROMOCODE_BY_CLIENT_ID_ERROR = "PROMOCODE_BY_CLIENT_ID_ERROR";

const GET_PROMOCODE_FOR_PARTNER_REQUEST = "GET_PROMOCODE_FOR_PARTNER_REQUEST";
const GET_PROMOCODE_FOR_PARTNER_SUCCESS = "GET_PROMOCODE_FOR_PARTNER_SUCCESS";

const UPDATE_FIELD_PROMOCODE_STORE = "UPDATE_FIELD_PROMOCODE_STORE";


//*  INITIAL STATE  *//

const initial = {
    contractorPromotions: {
        totalCount: 0,
        list: [],
        pageData: {},
    },
    existingPromocodeChannels: {
        totalCount: 0,
    },
    channelCard: {},
    contractorPageData: {},
    clientPageData: {},
    channelTotalCount: 0,
    promocodeList: [],
    promocodePageData: {},
    promocodeTotalCount: 0,
    isExistActive: null,
    contractorList: [],
    contractorTotalCount: 0,
    checkProgress: false,
    clientPromocode: undefined,
    isCheckSuccess: false,
    clientList: [],
    clientTotalCount: 0,
    progressCard: false,
    progress: false,
    progressAdd: false,
    promotionsForPartner: {
        [PROMOTION_TYPE.CONTRACTORS]: [],
        [PROMOTION_TYPE.CLIENTS]: [],
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case PROMOCODE_CONTRACOR_LIST_REQUEST:
            return {
                ...state,
                contractorPageData: payload,
                progress: true,
            };
        case PROMOCODE_CLIENTS_LIST_REQUEST:
            return {
                ...state,
                clientPageData: payload,
                progress: true,
            };
        case GET_PROMOCODE_FOR_PARTNER_SUCCESS:
            const {type, list} = payload;
            return {
                ...state,
                promotionsForPartner: {
                    ...state.promotionsForPartner,
                    [PROMOTION_TYPE[type]]: list,
                },
            };
        case PROMOCODE_CHECK_ACTIVE_SUCCESS:
            return {
                ...state,
                isExistActive: payload,
            };

        case PROMOCODE_CONTRACOR_LIST_SUCCESS:
            const {
                contractorModels: contractorList,
                totalCount: contractorTotalCount,
            } = payload;

            return {
                ...state,
                contractorList,
                contractorTotalCount,
                progress: false,
            };
        case PROMOCODE_CLIENTS_LIST_SUCCESS:
            const {
                clientModels: clientList,
                totalCount: clientTotalCount,
            } = payload;

            return {
                ...state,
                clientList,
                clientTotalCount,
                progress: false,
            };
        case PROMOCODE_BY_CLIENT_ID_SUCCESS:
            return {
                ...state,
                clientPromocode: payload,
            };
        case UPDATE_FIELD_PROMOCODE_STORE:
            return {
                ...state,
                ...payload,
            };
        case PROMOCODE_CONTRACOR_LIST_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case PROMOCODE_CHECK_ACTIVE_ERROR:
            return {
                ...state,
                error: payload,
                isExistActive: null,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function updateFieldPromocodeStore(payload) {
    return {
        type: UPDATE_FIELD_PROMOCODE_STORE,
        payload,
    };
}

export function getCheckNoActivePromocode(payload) {
    return {
        type: PROMOCODE_CHECK_ACTIVE_REQUEST,
        payload,
    };
}

export function getClientsByPromocode(payload) {
    return {
        type: PROMOCODE_CLIENTS_LIST_REQUEST,
        payload,
    };
}

export function getContractorsByPromocode(payload) {
    return {
        type: PROMOCODE_CONTRACOR_LIST_REQUEST,
        payload,
    };
}

export function registrationPromocode(payload) {
    return {
        type: PROMOCODE_REGISTRATION_REQUEST,
        payload,
    };
}

export function getPromocodeByClientId(payload) {
    return {
        type: PROMOCODE_BY_CLIENT_ID_REQUEST,
        payload,
    };
}

export function getPromotionForPartner(payload) {
    return {
        type: GET_PROMOCODE_FOR_PARTNER_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const promocodeSelector = state => state.promocode;

export const promocodeGetPageProgressSelector = createSelector(promocodeSelector, ({progress}) => progress);
export const promocodeIsExistChannelSelector = createSelector(promocodeSelector, ({existingPromocodeChannels: {totalCount}}) => totalCount > 0);
export const getPromocodeContractorListSelector = createSelector(promocodeSelector, ({contractorList}) => contractorList);
export const promocodeContractorTotalPagesSelector = createSelector(promocodeSelector, ({contractorPageData: {pageSize}, contractorTotalCount}) => getTotalPages(contractorTotalCount, pageSize));
export const promocodeContractorTotalCountSelector = createSelector(promocodeSelector, ({contractorTotalCount}) => contractorTotalCount);
export const promocodeClientTotalPagesSelector = createSelector(promocodeSelector, ({clientPageData: {pageSize}, clientTotalCount}) => getTotalPages(clientTotalCount, pageSize));
export const promocodeClientTotalCountSelector = createSelector(promocodeSelector, ({clientTotalCount}) => clientTotalCount);
export const getPromocodeisExistActiveSelector = createSelector(promocodeSelector, ({isExistActive}) => isExistActive);
export const getClientListByPromocodeSelector = createSelector(promocodeSelector, ({clientList}) => clientList);
export const getClientListByPromocodeOptionSelector = createSelector(promocodeSelector, ({clientList}) => clientList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));
export const promocodePartnerClientsOptionsSelector = createSelector(promocodeSelector, ({promotionsForPartner}) => promotionsForPartner[PROMOTION_TYPE.CLIENTS].map(({promotionId: value, name: text, requisites}) => ({
    key: value, value, text: `${text} (${requisites})`,
})));
export const promocodePartnerContractorsOptionsSelector = createSelector(promocodeSelector, ({promotionsForPartner}) => promotionsForPartner[PROMOTION_TYPE.CONTRACTORS].map(({promotionId: value, name: text, requisites}) => ({
    key: value, value, text: `${text} (${requisites})`,
})));

//*  SAGA  *//

export const getCheckNoActivePromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                promotionId,
            },
        } = action;

        const result = yield request.get(`${controller}/getCheckNoActivePromocode`, {
            params: {
                promotionId,
            },
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CHECK_ACTIVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        //
        // if (result === false) {
        //     yield put(downloadDocument({
        //         downloadLink: `/api/documents/getPromotionPromocodesFile?promotionId=${promotionId}&active=${result}`,
        //         documentType: name,
        //         extension: "xlsx"
        //     }));
        // }

        yield put({
            type: PROMOCODE_CHECK_ACTIVE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CHECK_ACTIVE_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getContractorsPage
export const getContractorsByPromocodeSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getContractorsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CONTRACOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_CONTRACOR_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CONTRACOR_LIST_ERROR, payload: error});
    }
};

//POST /api/contractors/promotion/registration
export const registrationPromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                isFetchClientPromocode,
                data,
                onSuccess,
                onError = () => {
                },
            },
        } = action;

        const result = yield request.post(`${controller}/registration`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            // toastError(errorMessage);
            onError(errorMessage);
            yield put({type: PROMOCODE_REGISTRATION_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Промо-код успешно применен");

        yield put({
            type: PROMOCODE_REGISTRATION_SUCCESS,
            payload: result,
        });

        if (isFetchClientPromocode) {
            yield put(getPromocodeByClientId({
                data: {
                    clientId: data.clientId,
                },
            }));
        }

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        yield put({type: PROMOCODE_REGISTRATION_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getPromocodeByClientId

export const getPromocodeByClientIdSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
            },
        } = action;

        const result = yield request.get(`${controller}/getPromocodeByClientId`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_BY_CLIENT_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_BY_CLIENT_ID_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_BY_CLIENT_ID_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getClientsPage
export const getClientsByPromocodeSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getClientsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CLIENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({
            type: PROMOCODE_CLIENTS_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CLIENTS_LIST_ERROR, payload: error});
    }
};
//GET /api/contractors/promotion/getPromotionForPartner
export const getPromotionForPartnerSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            getResult,
            type,
        } = payload;

        const result = yield request.get(`${controller}/getPromotionForPartner`, {params: {type}});
        const {errorMessage} = result;

        getResult && getResult(result);


        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CLIENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({
            type: GET_PROMOCODE_FOR_PARTNER_SUCCESS,
            payload: {
                type,
                list: result,
            },
        });

    } catch (error) {
        yield put({type: PROMOCODE_CLIENTS_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(PROMOCODE_CHECK_ACTIVE_REQUEST, getCheckNoActivePromocodeSaga),
        takeEvery(PROMOCODE_CONTRACOR_LIST_REQUEST, getContractorsByPromocodeSaga),
        takeEvery(PROMOCODE_REGISTRATION_REQUEST, registrationPromocodeSaga),
        takeEvery(PROMOCODE_BY_CLIENT_ID_REQUEST, getPromocodeByClientIdSaga),
        takeEvery(PROMOCODE_CLIENTS_LIST_REQUEST, getClientsByPromocodeSaga),
        takeEvery(GET_PROMOCODE_FOR_PARTNER_REQUEST, getPromotionForPartnerSaga),
    ]);
}
