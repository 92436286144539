import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import Text from "../../../../../components/ActualComponents/Text";
import NmButton from "../../../../../components/NmButton";
import {ReactComponent as InfoIcon} from "../../../../../images/info_24.svg";
import {Loader} from "semantic-ui-react";

import {useSystemTransactionsAction} from "../../../../../components/SystemTransactions/hooks/useAction";

import dateFormat, {monthOption} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../../constants/color";
import {ADMIN, NM_MANAGER} from "../../../../../constants/roles";

import {getContractorFinanceBalanceProgressActionSelector} from "../../../../../ducks/bff/contractor-сard/finance/balance/selectors";
import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";

import "../style.sass";

export const useContractorIncomeCustomerPaymentsAmountData = (props) => {
    const {
        contractorId,
        filter = {},
        fetchData,
        clearStore,
        balanceSelector,
        isHiddenTax = false,
    } = props;

    const [processOperationModalData, setProcessOperationModalData] = useState({});
    const contractorBalance = useSelector(balanceSelector);
    const contractor = useSelector(contractorCardInfoSelector);
    const progressAction = useSelector(getContractorFinanceBalanceProgressActionSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const {
        balance = {},
        taxPaymentInfo = {},
        incomePastMonths = [],
        incomeCurrentMonth = {},
    } = contractorBalance;

    const {
        taxBalance = {},
        crowdBalance = {},
    } = balance;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const role = ls(USER_ROLE);
    const isAccessEditTaxOffer = [ADMIN, NM_MANAGER].includes(role)
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.setContractorSmzTaxOffer);
    
    useEffect(() => {
        return () => {
            dispatch(clearStore({
                contractorBalance: {},
            }));
        };
    }, []);

    useEffect(() => {
        getContractorBalance();
    }, [filter]);

    const getContractorBalance = () => {
        dispatch(fetchData({
            contractorId,
            fromDateFilter: filter.fromDateOperationFilter,
            toDateFilter: filter.toDateOperationFilter,
        }));
    };

    const {
        confirmData,
        onOpenConfirm,
        onCloseConfirm,
        onCancelAutoTax,
        onChangeAutoTax,
        onChangeOpenWithdrawalMoneyOfWalletInBank,
        onDownload,
    } = useSystemTransactionsAction({
        contractorId,
        taxPaymentInfo,
        withdrawalMoneyOfWalletInBank: taxPaymentInfo.withdrawalMoneyOfWalletInBank,
        role,
        fetchInfo: getContractorBalance,
    });

    const getBalanceAmount = (amount, withoutFormat = false) => {
        if (isEmpty(contractorBalance) || contractorBalance.isError) {
            return <Loader
                active
                inline
                size="tiny"
            />;
        }

        if (withoutFormat) {
            return amount;
        }

        return `${formatAmountWithNullChecking(amount)} ₽`;
    };

    const getProcessLabel = () => {
        return (
            <div className="flex align-items-center">
                <InfoIcon
                    className="me-1"
                    color={COLOR.INERT_100}
                    width={18}
                    height={18}
                />
                В процессе
            </div>
        );
    };

    const cardInfoTax = {
        defaultOpen: true,
        isHiddenToggleButton: true,
        title: !taxPaymentInfo.smzTaxOfferEnabledMonth && "Уплата налогов в ФНС России",
        classNameValue: "col-16",
        classNameTitle: "col-16",
        value: taxPaymentInfo.smzTaxOfferEnabledMonth &&
            <div className="col-16 flex flex-column flex-xxl-row contractor-finance-balance__tax-offer-info-value">
                <Text
                    level="2"
                    className="col-16 col-xxl-11"
                >
                    {
                        taxPaymentInfo.smzTaxOffer ?
                            t("contractor-income-page.cancel-auto-tax-from-next-month") :
                            t("contractor-income-page.set-auto-tax-from-next-month")
                    }
                </Text>
                <NmButton
                    color="grey"
                    size="sm"
                    className="mt-2 mt-xxl-0 ms-xxl-2"
                    onClick={onCancelAutoTax}
                >
                    {t("button.cancel")}
                </NmButton>
            </div>
        ,
        className: "col-16",
        labels: [
            {
                text: <NmCheckboxV2
                    disabled={!isAccessEditTaxOffer}
                    className="mb-2"
                    name="smzTaxOffer"
                    onChange={() => onOpenConfirm({
                        content: `${taxPaymentInfo.smzTaxOffer ? t("contractor-income-page.cancel-auto-tax-confirm") : t("contractor-income-page.add-auto-tax-confirm")} ${contractor.lastName} ${contractor.firstName} ${contractor.patronymic || ""}?`,
                        onConfirm: () => onChangeAutoTax(!taxPaymentInfo.smzTaxOffer),
                    })}
                    checked={taxPaymentInfo.smzTaxOffer}
                    label={t("contractor-income-page.auto-tax-payming")}
                    isVisibleTooltip
                    tooltip={<HelpTooltip
                        text={t("contractor-income-page.auto-tax-help-text")}
                    />}
                />,
                noWrap: false,
                className: "justify-content-between",
            },
            {
                text: <NmCheckboxV2
                    disabled={!isAccessEditTaxOffer || progressAction}
                    className="mb-2"
                    name="smzTaxOffer"
                    onChange={() => onOpenConfirm({
                        content: `Вы действительно хотите ${taxPaymentInfo.withdrawalMoneyOfWalletInBank ? "заблокировать" : "разблокировать"} исполнителю возможность ручного вывода средств с банковского кошелька на период с 9 по 20 число месяца?${!taxPaymentInfo.withdrawalMoneyOfWalletInBank ? "\n Внимание! При подтверждении операции данная возможность будет предоставлена только до конца текущего месяца, после чего будет заблокирована." : ""}`,
                        onConfirm: onChangeOpenWithdrawalMoneyOfWalletInBank,
                    })}
                    checked={taxPaymentInfo.withdrawalMoneyOfWalletInBank}
                    label="Вывод средств с 9 по 20 число"
                />,
                noWrap: false,
                className: "justify-content-between",
            },
        ],
    };

    const cardBalance = {
        defaultOpen: true,
        isHiddenToggleButton: true,
        title: "Общий баланс",
        titleAdditionalContent:
            (Boolean(taxBalance.processPaymentsAmount) || Boolean(crowdBalance.processPaymentsAmount)) &&
            <Text
                color={COLOR.PASSIVE_100}
                isCursorPointer={true}
                onClick={() => setProcessOperationModalData({
                    crowdPayments: crowdBalance.processPayments || [],
                    taxPayments: taxBalance.processPayments || [],
                })}
            >
                Все операции «В процессе»
            </Text>,
        value: contractorBalance.isError ? "Банк недоступен" : getBalanceAmount(balance.totalBalance),
        isLoading: isEmpty(contractorBalance),
        className: "col-16 col-xxl-8",
        labels: [
            {
                label: "Баланс на счету Наймикс / Баланс на кошельке W1",
                classNameLabel: "col-9",
                text: getBalanceAmount(`${getBalanceAmount(taxBalance.balance)} / ${getBalanceAmount(taxBalance.balanceW1)}`, true),
                boldLabel: true,
                boldText: true,
                noWrap: false,
                noWrapLabel: false,
                className: "justify-content-between mb-2",
            },
            {
                label: "Резерв для уплаты налога",
                text: getBalanceAmount(taxBalance.reserveBalance),
                noWrap: false,
                className: "justify-content-between",
            },
            {
                label: "Доступно к выводу по налогам",
                text: getBalanceAmount(taxBalance.withdrawalBalance),
                noWrap: false,
                className: "justify-content-between",
            },
            Boolean(taxBalance.processPaymentsAmount) && {
                label: getProcessLabel(),
                text: getBalanceAmount(taxBalance.processPaymentsAmount),
                noWrap: false,
                className: "justify-content-between",
            },
            {
                label: "Баланс по заданиям",
                text: getBalanceAmount(crowdBalance.balance),
                boldLabel: true,
                boldText: true,
                noWrap: false,
                className: "justify-content-between mt-4 mb-2",
            },
            {
                label: "Доступно к выводу по заданиям",
                text: getBalanceAmount(crowdBalance.withdrawalBalance),
                noWrap: false,
                className: "justify-content-between",
            },
            Boolean(crowdBalance.processPaymentsAmount) && {
                label: getProcessLabel(),
                text: getBalanceAmount(crowdBalance.processPaymentsAmount),
                noWrap: false,
                className: "justify-content-between",
            },
        ],
    };

    const getCardIncome = ({title, income, commission, tax, className}) => {
        return {
            title,
            value: `${formatAmountWithNullChecking(income)} ₽`,
            className,
            labels: [
                {
                    label: t("contractor-income-page.naimix-commission"),
                    text: `${formatAmountWithNullChecking(commission)} ₽`,
                    noWrap: false,
                    className: "justify-content-between",
                },
                !isHiddenTax && {
                    label: t("contractor-income-page.tax"),
                    text: `${formatAmountWithNullChecking(tax)} ₽`,
                    noWrap: false,
                    className: "justify-content-between",
                },
            ],
        };
    };

    const cardsIncomePastMonths = incomePastMonths.map(item => {
        return getCardIncome({
            ...item,
            title: `За ${monthOption[String(item.month).length !== 2 ? `0${item.month}` : item.month].toLowerCase()}`,
            className: "contractor-finance-balance__card-info",
        });
    });

    const getLabelPeriodDate = (date) => {
        return date ? dateFormat(date, "dd.MM.yyyy") : "Не выбрано";
    };

    const currentMonthNumber = new Date().getMonth() + 1;

    const cardIncomeCurrentMonth = getCardIncome({
        ...incomeCurrentMonth,
        title: filter.fromDateOperationFilter || filter.toDateOperationFilter ?
            `${getLabelPeriodDate(filter.fromDateOperationFilter)} - ${getLabelPeriodDate(filter.toDateOperationFilter)}` :
            `За ${monthOption[String(currentMonthNumber).length !== 2 ? `0${currentMonthNumber}` : currentMonthNumber].toLowerCase()}`,
        className: "contractor-finance-balance__card-info",
    });

    return {
        confirmData,
        onCloseConfirm,
        cards: [
            ...cardsIncomePastMonths,
            cardIncomeCurrentMonth,
        ],
        cardInfoTax,
        cardBalance,
        onDownload,
        processOperationModalData,
        setProcessOperationModalData,
    };
};