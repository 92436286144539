import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_ERROR,
    GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST,
    GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS,
    GET_PAGE_DOCUMENTS_CORRECTION_ERROR,
    GET_PAGE_DOCUMENTS_CORRECTION_REQUEST,
    GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS,
    PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST,
    PERFORM_DOCUMENTS_CORRECTION_REQUEST,
    PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR,
    PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS,
    PREFORM_DOCUMENTS_CORRECTION_ERROR,
    PREFORM_DOCUMENTS_CORRECTION_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "/adm/settings/documents-correction";

const getDocumentsCorrectionPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DOCUMENTS_CORRECTION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DOCUMENTS_CORRECTION_ERROR,
            payload: error,
        });
    }
};

const getFieldNamesDictDocumentsCorrection = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/field-names/get`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({
            type: GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_ERROR,
        });
    }
};

const preformDocumentsCorrection = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/perform`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: PREFORM_DOCUMENTS_CORRECTION_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Имя параметра успешно изменено");
        onSuccess();

        yield put({
            type: PREFORM_DOCUMENTS_CORRECTION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        payload.onError();
        toastError(error.message);
        yield put({
            type: PREFORM_DOCUMENTS_CORRECTION_ERROR,
        });
    }
};

const performDemoDocumentsCorrection = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/perform/demo`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR,
            });

            return {
                done: true,
            };
        }

        const {guid} = result;

        onSuccess(guid);

        yield put({
            type: PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS,
        });
    } catch (error) {
        payload.onError();
        toastError(error.message);
        yield put({
            type: PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DOCUMENTS_CORRECTION_REQUEST, getDocumentsCorrectionPageSaga),
        takeEvery(GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST, getFieldNamesDictDocumentsCorrection),
        takeEvery(PERFORM_DOCUMENTS_CORRECTION_REQUEST, preformDocumentsCorrection),
        takeEvery(PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST, performDemoDocumentsCorrection),
    ]);
}