import {useDispatch, useSelector} from "react-redux";

import {useModal} from "../../../../hooks/useModal";
import {useSuccessActionCallback} from "../../../../hooks/useSuccessAction";

import {deleteKedoDepartment, updateKedoDepartmentsStore} from "../../../../ducks/kedo/departments/actionCreators";
import {
    kedoDepartmentsErrorSelector,
    kedoDepartmentsProgressActionSelector,
} from "../../../../ducks/kedo/departments/selectors";

export const useKedoOrganizationStructureAction = () => {
    const dispatch = useDispatch();

    const progressAction = useSelector(kedoDepartmentsProgressActionSelector);
    const error = useSelector(kedoDepartmentsErrorSelector);

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateKedoDepartmentsStore({
                    error: null,
                }));
            }

            onCloseModal();
        },
        progressAction,
        error,
    });

    const {
        onOpenModal,
        modalData,
        onCloseModal,
    } = useModal();

    const onClickEdit = (item) => {
        onOpenModal({
            isOpenEdit: true,
            item,
        });
    };

    const onClickDelete = (item) => {
        onOpenModal({
            confirmProps: {
                content: "Вы действительно хотите удалить отдел?",
                confirmButton: "Удалить",
                onConfirm: () => {
                    dispatch(deleteKedoDepartment({
                        kedoDepartmentId: item.kedoDepartmentId,
                    }));
                },
            },
        });
    };

    return {
        onOpenModal,
        onClickEdit,
        onClickDelete,
        modalData,
        onCloseModal,
    };
};