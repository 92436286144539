import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractors/personaldata/pending/";
//*  TYPES  *//

const VALID_INN_AND_PASSPORT_DATA_REQUEST = "VALID_INN_AND_PASSPORT_DATA_REQUEST";
const VALID_INN_AND_PASSPORT_DATA_SUCCESS = "VALID_INN_AND_PASSPORT_DATA_SUCCESS";
const VALID_INN_AND_PASSPORT_DATA_ERROR = "VALID_INN_AND_PASSPORT_DATA_ERROR";

const CONTRACTORS_PENDING_PERSONAL_DATA_UPDATE_STORE = "CONTRACTORS_PENDING_PERSONAL_DATA_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    currentRole: "",
    verificationSteps: [],
    progressVerificationSteps: false,
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case VALID_INN_AND_PASSPORT_DATA_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case VALID_INN_AND_PASSPORT_DATA_SUCCESS:
            return {
                ...state,
                ...payload,
                progress: false,
            };
        case VALID_INN_AND_PASSPORT_DATA_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case CONTRACTORS_PENDING_PERSONAL_DATA_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function updateContractorsPendingPersonalDataStore(payload) {
    return {
        type: CONTRACTORS_PENDING_PERSONAL_DATA_UPDATE_STORE,
        payload,
    };
}

export function refreshInnAndPassportDataValidStatus(payload) {
    return {
        type: VALID_INN_AND_PASSPORT_DATA_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorPendingPersonalDataSelector = state => state.contractorPendingPersonalData;
export const contractorPendingPersonalDataProgressSelector = createSelector(contractorPendingPersonalDataSelector, ({progress}) => progress);

//*  SAGA  *//

//POST
// /api/contractors/personaldata/pending/refreshInnAndPassportDataValidStatus
// Проверка соотношения паспортных данные и ИНН
const refreshInnAndPassportDataValidStatusSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}refreshInnAndPassportDataValidStatus`, data);

        yield getResult(result);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: VALID_INN_AND_PASSPORT_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: VALID_INN_AND_PASSPORT_DATA_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: VALID_INN_AND_PASSPORT_DATA_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([takeEvery(VALID_INN_AND_PASSPORT_DATA_REQUEST, refreshInnAndPassportDataValidStatusSaga)]);
}
