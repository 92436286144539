import * as yup from "yup";

import {getFullRegistrationValidations} from "./getValidations";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {
    CONTRACTOR_MIGRATION_STATUS,
    EDM_PASSPORT_TYPE,
    EDM_PASSPORT_TYPE_OPTIONS,
} from "../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../constants/contractorInfo";
import {einReg} from "../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_COMPONENT_TYPE, FULL_REGISTRATION_STEP} from "../constants";

const GENERAL_FIELDS = [
    "lastName",
    "firstName",
    "patronymic",
    "birthDate",
    "gender",
];

export const getPersonalDataStep = (params) => {
    const {
        genderOptions,
        migrationStatus,
        citizenship,
        isRequiredFields,
        passportTypeOptions,
        isCheckPersonalData,
    } = params;

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.REFUGEE) {
        const fieldNames = {
            num: "refugeeCertificateNum",
            issuedBy: "refugeeCertificateIssuedBy",
            personalFileNum: "refugeeCertificatePersonalFileNum",
            issuedDate: "refugeeCertificateIssuedDate",
            validToDate: "refugeeCertificateValidToDate",
            birthPlace: "refugeeCertificateBirthPlace",
        };

        if (isCheckPersonalData) {
            return fieldNames;
        }

        return getPersonalDataByMigrationStatus({
            isRequiredFields,
            passportTypeOptions,
            genderOptions,
            scans: [
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                    name: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD,
                    label: "Фотография разворота удостоверения с персональной информацией",
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                    name: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK,
                    label: "Фотография разворота удостоверения с отметкой о продлении срока действия",
                },
            ],
            fieldNames,
        });
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE) {
        const fieldNames = {
            num: "temporaryRefugeeCertificateNum",
            issuedDate: "temporaryRefugeeCertificateIssuedDate",
            validToDate: "temporaryRefugeeCertificateValidToDate",
            personalFileNum: "temporaryRefugeeCertificatePersonalFileNum",
            birthPlace: "temporaryRefugeeCertificateBirthPlace",
            issuedBy: "temporaryRefugeeCertificateIssuedBy",
        };

        if (isCheckPersonalData) {
            return fieldNames;
        }

        return getPersonalDataByMigrationStatus({
            isRequiredFields,
            passportTypeOptions,
            genderOptions,
            scans: [
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                    name: CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA,
                    label: "Фотография разворота свидетельства о предоставлении временного убежища с персональной информацией",
                },
            ],
            fieldNames,
        });
    }

    if (citizenship === citizenshipsDict.NOT_SPECIFIED.value) {
        const fieldNames = {
            num: "statelessPersonTemporaryIdNum",
            issuedDate: "statelessPersonTemporaryIdIssuedDate",
            validToDate: "statelessPersonTemporaryIdValidToDate",
            birthPlace: "statelessPersonTemporaryIdBirthPlace",
            issueReason: "statelessPersonTemporaryIdIssueReason",
            issuedBy: "statelessPersonTemporaryIdIssuedBy",
        };

        if (isCheckPersonalData) {
            return fieldNames;
        }

        return getPersonalDataByMigrationStatus({
            isRequiredFields,
            passportTypeOptions,
            genderOptions,
            citizenship,
            scans: [
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                    name: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD,
                    label: "Фотография разворота удостоверения личности лица без гражданства с персональной информацией",
                },
            ],
            fieldNames,
        });
    }

    if (isCheckPersonalData) {
        return {
            num: "idDocNumber",
            issuedDate: "idDocIssuedDate",
            validToDate: "idDocValidToDate",
            issuedBy: "idDocIssuedBy",
            birthPlace: "birthPlace",
        };
    }

    return getPersonalDataByCitizenShip({
        citizenship,
        isRequiredFields,
        passportTypeOptions,
        genderOptions,
    });
};

const getPersonalDataByMigrationStatus = (params) => {
    const {
        isRequiredFields,
        scans,
        fieldNames,
        passportTypeOptions,
        genderOptions,
        citizenship,
    } = params;

    const scanValidations = getScanValidations({
        isRequiredFields,
        scans,
    });

    return {
        value: FULL_REGISTRATION_STEP.PERSONAL_DATA,
        modalTitle: "Персональные данные",
        validation: () => {
            const fieldValidations = getFullRegistrationValidations({
                isRequiredFields,
                fields: [
                    ...GENERAL_FIELDS,
                    [fieldNames.num],
                    [fieldNames.issuedBy],
                    [fieldNames.personalFileNum],
                    [fieldNames.issuedDate],
                    [fieldNames.validToDate],
                    [fieldNames.birthPlace],
                    [fieldNames.issueReason],
                ],
            });

            const resultFieldValidations = getFieldValidations({
                fieldNames: [
                    ...GENERAL_FIELDS,
                    ...Object.values(fieldNames),
                ],
                fieldValidations,
            });

            return yup.object().shape({
                ...resultFieldValidations,
                ...scanValidations,
            });
        },
        fields: () => {
            const generalFields = getFields({
                passportTypeOptions,
                genderOptions,
                citizenship,
                fieldNames: GENERAL_FIELDS,
            });

            return [
                ...scans,
                ...generalFields,
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    name: fieldNames.num,
                    label: citizenship === citizenshipsDict.NOT_SPECIFIED.value ? "Номер" : "Серия и номер",
                    required: true,
                    placeholder: "Введите серию и номер",
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    name: fieldNames.issuedBy,
                    label: "Кем выдано",
                    required: true,
                    placeholder: "Введите кем выдан документ",
                },
                fieldNames.personalFileNum &&
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    name: fieldNames.personalFileNum,
                    label: "Номер личного дела",
                    required: true,
                    placeholder: "Введите номер личного дела",
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                    label: "Дата выдачи",
                    maxDate: new Date(),
                    required: true,
                    name: fieldNames.issuedDate,
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                    label: "Дата окончания срока действия",
                    minDate: new Date(),
                    required: true,
                    name: fieldNames.validToDate,
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    label: "Место рождения",
                    required: true,
                    placeholder: "Введите место рождения",
                    name: fieldNames.birthPlace,
                },
                fieldNames.issueReason &&
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    label: "Причина выдачи",
                    placeholder: "Введите причину выдачи",
                    name: fieldNames.issueReason,
                },
            ].filter(value => value);
        },
    };
};

const getPersonalDataByCitizenShip = (params) =>  {
    const {
        isRequiredFields,
        citizenship,
        genderOptions,
        passportTypeOptions,
    } = params;

    switch (citizenship) {
        case citizenshipsDict.RU.value: {
            return getStep({
                isRequiredFields,
                scans: [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: "Фотография разворота паспорта",
                        name: CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO,
                    },
                ],
                citizenship,
                genderOptions,
                fieldNames: [
                    ...GENERAL_FIELDS,
                    "idDocNumber",
                    "birthPlace",
                    "idDocIssuedBy",
                    "idDocIssuedDate",
                    "idDocIssuedByDepartmentCode",
                ],
            });
        }
        case citizenshipsDict.BY.value: {
            return getStep({
                isRequiredFields,
                passportTypeOptions,
                genderOptions,
                citizenship,
                fieldValidations: {
                    ein: yup.string()
                        .length(14, "Допустимое количество символов 14")
                        .matches(einReg, "Ввод цифр и латинских букв"),
                },
                scans: (values) => {
                    if (values.passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
                        return [
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография лицевой стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
                            },
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография обратной стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
                                required: false,
                            },
                        ];
                    }

                    return [
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            label: "Фотография разворота главной страницы заграничного паспорта",
                            name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                        },
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            label: "Фотография разворота страницы заграничного паспорта с местом рождения",
                            name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
                            noteText: "Необходимо загрузить фотографию разворота страниц 30-31.",
                        },
                    ];
                },
                fieldNames: (values) => {
                    return [
                        !values.locatedOutsideRussia && "passportType",
                        ...GENERAL_FIELDS,
                        "idDocNumber",
                        "ein",
                        "birthPlace",
                        "idDocIssuedBy",
                        "idDocIssuedDate",
                        "idDocValidToDate",
                    ];
                },
            });
        }
        case citizenshipsDict.AM.value:
        case citizenshipsDict.UZ.value: {
            return getStep({
                isRequiredFields,
                passportTypeOptions,
                genderOptions,
                citizenship,
                scans: [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: "Фотография разворота главной страницы заграничного паспорта",
                        name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                    },
                ],
                fieldNames: [
                    ...GENERAL_FIELDS,
                    "idDocNumber",
                    "birthPlace",
                    "idDocIssuedBy",
                    "idDocIssuedDate",
                    "idDocValidToDate",
                ],
            });
        }
        case citizenshipsDict.KZ.value:
        case citizenshipsDict.KG.value: {
            const getEinValidation = () => {
                if (citizenship === citizenshipsDict.KG.value) {
                    return yup.string()
                        .min(12, "Допустимое количество символов от 12 до 14")
                        .max(14, "Допустимое количество символов от 12 до 14")
                        .matches(einReg, "Ввод цифр и латинских букв");
                }

                return yup.string()
                    .length(12, "Допустимое количество символов 12");
            };

            return getStep({
                isRequiredFields,
                passportTypeOptions,
                genderOptions,
                citizenship,
                fieldValidations: {
                    ein: getEinValidation(),
                },
                scans: (values) => {
                    if (values.passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
                        return [
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография лицевой стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
                            },
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография обратной стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
                                required: false,
                            },
                        ];
                    }

                    return [
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            label: "Фотография разворота главной страницы заграничного паспорта",
                            name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                        },
                    ];
                },
                fieldNames: (values) => {
                    return [
                        !values.locatedOutsideRussia && "passportType",
                        ...GENERAL_FIELDS,
                        "idDocNumber",
                        "ein",
                        "birthPlace",
                        "idDocIssuedBy",
                        "idDocIssuedDate",
                        "idDocValidToDate",
                    ];
                },
            });
        }
        case citizenshipsDict.TJ.value:
        case citizenshipsDict.UA.value: {
            return getStep({
                isRequiredFields,
                edmPassportTypeOptions: EDM_PASSPORT_TYPE_OPTIONS,
                genderOptions,
                citizenship,
                fieldValidations: {
                    ein: yup.string()
                        .length(13, "Допустимое количество символов 13")
                        .matches(einReg, "Ввод цифр и латинских букв"),
                },
                scans: (values) => {
                    if (values.edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE) {
                        return [
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография лицевой стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
                            },
                            {
                                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                                label: "Фотография обратной стороны паспорта",
                                name: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
                                required: false,
                            },
                        ];
                    }

                    return [
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            label: "Фотография разворота главной страницы заграничного паспорта",
                            name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                        },
                    ];
                },
                fieldNames: (values) => {
                    return [
                        !values.locatedOutsideRussia && "edmPassportType",
                        ...GENERAL_FIELDS,
                        "idDocNumber",
                        (
                            values.citizenship === citizenshipsDict.UA.value ||
                            values.edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE
                        ) && "ein",
                        "birthPlace",
                        "idDocIssuedBy",
                        "idDocIssuedDate",
                        "idDocValidToDate",
                    ];
                },
            });
        }
        case citizenshipsDict.DN.value:
        case citizenshipsDict.LG.value: {
            return getStep({
                isRequiredFields,
                passportTypeOptions,
                genderOptions,
                citizenship,
                fields: {
                    idDocIssuedByDepartmentCode: {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Код подразделения",
                        placeholder: "Введите код",
                    },
                },
                fieldValidations: {
                    idDocIssuedByDepartmentCode: yup.string().max(50, "Допустимое количество символов 50"),
                },
                scans: [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: "Фотография разворота главной страницы паспорта",
                        name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                    },
                ],
                fieldNames: citizenship === citizenshipsDict.DN.value ? [
                    ...GENERAL_FIELDS,
                    "idDocNumber",
                    "birthPlace",
                    "idDocIssuedBy",
                    "idDocIssuedDate",
                    "idDocIssuedByDepartmentCode",
                ] : [
                    ...GENERAL_FIELDS,
                    "idDocNumber",
                    "birthPlace",
                    "idDocIssuedBy",
                    "idDocIssuedDate",
                ],
            });
        }
        default: {
        // остальные гражданства
            return getStep({
                isRequiredFields,
                passportTypeOptions,
                genderOptions,
                citizenship,
                fieldValidations: {
                    ein: yup.string()
                        .max(20, "Допустимое количество символов 20")
                        .matches(einReg, "Ввод цифр и латинских букв"),
                },
                scans: [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: [
                            citizenshipsDict.DN.value,
                            citizenshipsDict.LG.value,
                        ].includes(citizenship) ?
                            "Фотография разворота главной страницы паспорта" :
                            "Фотография разворота главной страницы заграничного паспорта",
                        name: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                    },
                ],
                fieldNames: [
                    ...GENERAL_FIELDS,
                    "idDocNumber",
                    "ein",
                    "birthPlace",
                    "idDocIssuedBy",
                    "idDocIssuedDate",
                    "idDocValidToDate",
                ],
            });
        }
    }
};

const getStep = (params) => {
    const {
        genderOptions,
        fieldNames,
        isRequiredFields,
        passportTypeOptions,
        edmPassportTypeOptions,
        fieldValidations = {},
        fields = {},
        citizenship,
        scans,
    } = params;

    const getFieldNames = (values) => {
        if (typeof fieldNames === "function") {
            return fieldNames(values);
        }

        return fieldNames;
    };

    return {
        value: FULL_REGISTRATION_STEP.PERSONAL_DATA,
        modalTitle: "Персональные данные",
        validation: (values) => {
            return getValidations({
                scans: typeof scans === "function" ?
                    scans(values) :
                    scans,
                fieldNames: getFieldNames(values),
                isRequiredFields,
                fieldValidations,
            });
        },
        fields: (values) => {
            const _fields = getFields({
                passportTypeOptions,
                edmPassportTypeOptions,
                genderOptions,
                fieldNames: getFieldNames(values),
                fields,
                citizenship,
            });

            const scansList = typeof scans === "function" ?
                scans(values) :
                scans;

            return [
                ...scansList,
                ..._fields,
            ];
        },
    };
};

const getValidations = (params) => {
    const {
        scans,
        fieldNames,
        isRequiredFields,
    } = params;

    const scanValidations = getScanValidations({
        isRequiredFields,
        scans,
    });

    const validations = getFullRegistrationValidations({
        isRequiredFields,
        fields: [
            ...GENERAL_FIELDS,
            "birthPlace",
            "idDocNumber",
            "idDocIssuedBy",
            "idDocIssuedDate",
            "idDocValidToDate",
            "idDocIssuedByDepartmentCode",
        ],
    });

    const fieldValidations = {
        ...validations,
        ...params.fieldValidations,
    };

    const resultFieldValidations = getFieldValidations({
        fieldNames,
        fieldValidations,
    });

    return yup.object().shape({
        ...resultFieldValidations,
        ...scanValidations,
    });
};

const getFields = (params) => {
    const {
        passportTypeOptions,
        edmPassportTypeOptions,
        fieldNames,
        genderOptions,
        fields,
        citizenship,
    } = params;

    const allFields = {
        [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
            label: "Фотография разворота паспорта",
        },
        lastName: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Фамилия",
            required: true,
            placeholder: "Введите фамилию",
        },
        firstName: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Имя",
            required: true,
            placeholder: "Введите имя",
        },
        idDocValidToDate: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата окончания срока действия",
            required: true,
            minDate: new Date(),
        },
        patronymic: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Отчество",
            placeholder: "Введите отчество",
        },
        birthDate: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата рождения",
            maxDate: new Date(),
            required: true,
        },
        ein: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Идентификационный номер",
            placeholder: "Введите идентификационный номер",
        },
        gender: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
            label: "Пол",
            options: genderOptions,
            placeholder: "Выберите пол",
            required: true,
        },
        idDocNumber: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: [citizenshipsDict.RU.value].includes(citizenship) ? "Серия и номер" : "Номер паспорта",
            maskChar: null,
            mask: [citizenshipsDict.RU.value].includes(citizenship) ? "9999 999999" : null,
            placeholder: [citizenshipsDict.RU.value].includes(citizenship) ? "Введите серию и номер" : "Введите номер паспорта",
            required: true,
        },
        birthPlace: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Место рождения",
            placeholder: "Введите место рождения",
            required: true,
        },
        idDocIssuedBy: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Кем выдан",
            placeholder: "Введите кем выдан документ",
            required: true,
        },
        passportType: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
            label: "Тип паспорта",
            options: passportTypeOptions,
            required: true,
        },
        edmPassportType: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
            label: "Тип паспорта",
            options: edmPassportTypeOptions,
            required: true,
        },
        idDocIssuedDate: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            maxDate: new Date(),
            label: "Дата выдачи документа",
            placeholder: "Введите дату выдачи",
            required: true,
        },
        idDocIssuedByDepartmentCode: {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Код подразделения",
            maskChar: null,
            mask: "999-999",
            placeholder: "Введите код",
            required: true,
        },
        ...fields,
    };

    return fieldNames.map((name) => {
        return {
            name,
            ...allFields[name],
        };
    });
};

const getScanValidations = (params) => {
    const {
        isRequiredFields,
        scans,
    } = params;

    return scans.reduce((result, value) => {
        return {
            ...result,
            [value.name]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
        };
    }, {});
};

const getFieldValidations = (params) => {
    const {
        fieldNames,
        fieldValidations,
    } = params;

    return fieldNames.reduce((result, fieldName) => {
        return {
            ...result,
            [fieldName]: fieldValidations[fieldName],
        };
    }, {});
};