import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {edmSubdivisionsToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/subdivisions";
//*  TYPES  *//

const EDM_SUBDIVISION_LIST_REQUEST = "EDM_SUBDIVISION_LIST_REQUEST";
const EDM_SUBDIVISION_LIST_SUCCESS = "EDM_SUBDIVISION_LIST_SUCCESS";
const EDM_SUBDIVISION_LIST_ERROR = "EDM_SUBDIVISION_LIST_ERROR";

const EDM_SUBDIVISION_UPDATE_REQUEST = "EDM_SUBDIVISION_UPDATE_REQUEST";
const EDM_SUBDIVISION_UPDATE_SUCCESS = "EDM_SUBDIVISION_UPDATE_SUCCESS";
const EDM_SUBDIVISION_UPDATE_ERROR = "EDM_SUBDIVISION_UPDATE_ERROR";

const EDM_SUBDIVISION_DELETE_REQUEST = "EDM_SUBDIVISION_DELETE_REQUEST";
const EDM_SUBDIVISION_DELETE_SUCCESS = "EDM_SUBDIVISION_DELETE_SUCCESS";
const EDM_SUBDIVISION_DELETE_ERROR = "EDM_SUBDIVISION_DELETE_ERROR";

const EDM_SUBDIVISION_ADD_REQUEST = "EDM_SUBDIVISION_ADD_REQUEST";
const EDM_SUBDIVISION_ADD_SUCCESS = "EDM_SUBDIVISION_ADD_SUCCESS";
const EDM_SUBDIVISION_ADD_ERROR = "EDM_SUBDIVISION_ADD_ERROR";

const EDM_SUBDIVISION_COMMON_COUNT_REQUEST = "EDM_SUBDIVISION_COMMON_COUNT_REQUEST";
const EDM_SUBDIVISION_COMMON_COUNT_SUCCESS = "EDM_SUBDIVISION_COMMON_COUNT_SUCCESS";
const EDM_SUBDIVISION_COMMON_COUNT_ERROR = "EDM_SUBDIVISION_COMMON_COUNT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    commonCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EDM_SUBDIVISION_LIST_REQUEST:
            const {getResult, ...pageData} = payload;
            return {
                ...state,
                pageData,
            };
        case EDM_SUBDIVISION_LIST_SUCCESS:
            const {
                results = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                list: results,
                totalCount,
            };
        case EDM_SUBDIVISION_COMMON_COUNT_SUCCESS:
            return {
                ...state,
                commonCount: payload,
            };
        case EDM_SUBDIVISION_COMMON_COUNT_ERROR:
        case EDM_SUBDIVISION_LIST_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getEdmSubdivisionsList(payload) {
    return {
        type: EDM_SUBDIVISION_LIST_REQUEST,
        payload,
    };
}

export function updateEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_UPDATE_REQUEST,
        payload,
    };
}

export function deleteEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_DELETE_REQUEST,
        payload,
    };
}

export function addEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_ADD_REQUEST,
        payload,
    };
}

export function getEdmSubdivisionCommonCount(payload) {
    return {
        type: EDM_SUBDIVISION_COMMON_COUNT_REQUEST,
        payload,
    };
}


//*  SELECTORS  *//

export const edmSubdivisionsSelector = state => state.edmSubdivisions;

export const edmSubdivisionsTotalCountSelector = createSelector(edmSubdivisionsSelector, ({totalCount}) => totalCount);
export const edmSubdivisionsCommonCountSelector = createSelector(edmSubdivisionsSelector, ({commonCount}) => commonCount);

export const edmSubdivisionsPageDataSelector = createSelector(edmSubdivisionsSelector, ({pageData}) => pageData);

export const edmSubdivisionsListSelector = createSelector(edmSubdivisionsSelector, ({list}) => list);

export const edmSubdivisionsOptionsSelector = createSelector(edmSubdivisionsSelector, ({list}) => edmSubdivisionsToOptions(list));


export const edmSubdivisionsTotalPagesSelector = createSelector(edmSubdivisionsSelector, ({
    totalCount, pageData: {
        pageSize = 0,
    },
}) => getTotalPages(totalCount, pageSize));


//*  SAGA  *//

export const getEdmSubdivisionsListSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                ...data
            },
        } = action;

        const result = yield request.post(`${controller}/page`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            console.error(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: EDM_SUBDIVISION_LIST_ERROR,
            payload: error,
        });
    }
};

export const updateEdmSubdivisionSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            onSuccess,
            clientId,
            subdivisionId,
            value,
        } = payload;


        const result = yield request.patch(`${controller}/${clientId}/${subdivisionId}`, {
            clientId,
            subdivisionId,
            value,
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_UPDATE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно изменено");

    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const getEdmSubdivisionCommonCountSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            clientId,
        } = payload;

        const state = yield select();
        const pageData = yield edmSubdivisionsPageDataSelector(state);

        const result = yield request.post(`${controller}/count`, {clientId, ...pageData});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_COMMON_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_COMMON_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_COMMON_COUNT_ERROR,
            payload: error,
        });
    }
};

export const addEdmSubdivisionSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_ADD_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно добавлено");
        yield put(getEdmSubdivisionCommonCount({clientId: data.clientId}));

    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_ADD_ERROR,
            payload: error,
        });
    }
};

export const deleteEdmSubdivisionSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess,
                clientId,
                subdivisionId,
            },
        } = action;

        const result = yield request.delete(`${controller}/${clientId}/${subdivisionId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_DELETE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно удалено");
        yield put(getEdmSubdivisionCommonCount({clientId}));
    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_DELETE_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(EDM_SUBDIVISION_LIST_REQUEST, getEdmSubdivisionsListSaga),
        takeEvery(EDM_SUBDIVISION_UPDATE_REQUEST, updateEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_DELETE_REQUEST, deleteEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_ADD_REQUEST, addEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_COMMON_COUNT_REQUEST, getEdmSubdivisionCommonCountSaga),
    ]);
}
