import {
    CLEAR_CONTRACTOR_EVENT_LOG_STORE,
    CONTRACTOR_EVENT_LOG_GET_PAGE_REQUEST,
} from "./actions";

export const getPageContractorEventLog = (payload) => {
    return {
        type: CONTRACTOR_EVENT_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorEventLog = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_EVENT_LOG_STORE,
        payload,
    };
};