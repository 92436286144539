import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEqual} from "lodash";

import NmForm from "../../../../components/ActualComponents/NmForm";
import StrParamEditor from "../../../../components/ActualComponents/StrParamEditor";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

const ProjectParamsBlock = props => {
    const {
        isEditable,
    } = props;

    const dispatch = useDispatch();

    const [isEdit, setEditable] = useState(false);

    const [form, setForm] = useState({});

    const [copiedForm, setCopiedForm] = useState({});

    const clientCardData = useSelector(clientCardInfoSelector);

    useEffect(() => {
        const {
            projectParams,
        } = clientCardData;

        const data = {projectParams};

        if (!isEqual(copiedForm, data)) {
            setForm(data);
            setCopiedForm(data);
        }
    }, [clientCardData]);

    const submitClientData = () => {
        dispatch(
            updateClientCardInfo({
                ...clientCardData,
                ...form,
                projectParams: form.projectParams,
            }),
        );
    };

    const cancelEditMode = () => {
        setEditable(false);
        setForm(copiedForm);
    };

    const toggleCard = () => {
        const {
            projectParams,
        } = clientCardData;

        if (isEdit) {
            submitClientData();
        } else {
            setForm({projectParams});
        }
        setEditable(!isEdit);
    };

    const renderReadOnlyCard = () => {
        const {
            projectParams = [],
        } = clientCardData;

        return (
            <LabelTextApp
                label="Параметры проекта"
                className="client-card-block__label"
                text={projectParams.join(", ")}
            />
        );
    };

    const handleChange = (e, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const renderEditModeCard = () => {
        return (
            <NmForm addMargin>
                <StrParamEditor
                    name="projectParams"
                    params={form.projectParams}
                    onChange={handleChange}
                />
            </NmForm>
        );
    };


    return (
        <CardApp
            title="Параметры проекта"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEditMode}
            isEdit={isEdit}
        >
            {!isEdit ?
                renderReadOnlyCard() :
                renderEditModeCard()}
        </CardApp>
    );
};

ProjectParamsBlock.propTypes = {
    isEditable: PropTypes.bool,
};

export default (ProjectParamsBlock);