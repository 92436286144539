export const BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST = "BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR = "BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST = "BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_CREATE_ERROR = "BFF_CROWD_ACT_REGISTRY_CREATE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST = "BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS = "BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_EDIT_ERROR = "BFF_CROWD_ACT_REGISTRY_EDIT_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR = "BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST = "BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR = "BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST = "BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS = "BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR = "BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR";

export const BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST = "BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_DELETE_ERROR = "BFF_CROWD_ACT_REGISTRY_DELETE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_RUN_REQUEST = "BFF_CROWD_ACT_REGISTRY_RUN_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS = "BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_RUN_ERROR = "BFF_CROWD_ACT_REGISTRY_RUN_ERROR";

export const BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST = "BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR = "BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR";

export const UPDATE_CROWD_ACT_REGISTRY_STORE = "UPDATE_CROWD_ACT_REGISTRY_STORE";