import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDivider from "../../../../../../../components/ActualComponents/NmDivider";
import NmLabelText from "../../../../../../../components/ActualComponents/NmLabelText";
import Text from "../../../../../../../components/ActualComponents/Text";
import NmButton from "../../../../../../../components/NmButton";
import NmSelectedList from "../../../../../../../components/NmSelectedList";
import {ReactComponent as InfoIcon} from "../../../../../../../images/info_24.svg";
import {ReactComponent as PlaceIcon} from "../../../../../../../images/place_24.svg";
import {ReactComponent as WatchIcon} from "../../../../../../../images/watch_later_24.svg";
import OrderEditFormPublishClientInfo from "../../../../../../order/order-edit/form/publish/components/client-info";
import OrderEditFormPublishClientInfoRating from "../../../../../../order/order-edit/form/publish/components/client-info-rating";
import OrderEditFormPublishLabelText from "../../../../../../order/order-edit/form/publish/components/label-text";
import {CrowdTaskEditContext} from "../context-provider";

import bem from "../../../../../../../utils/bem";
import dateFormat from "../../../../../../../utils/dateFormat";
import {
    formatAmount,
    formatNumber,
    getNumberFromFormattedAmount,
} from "../../../../../../../utils/stringFormat";

import {COLOR} from "../../../../../../../constants/color";

import {clientCardInfoSelector} from "../../../../../../../ducks/bff/clients/info/selectors";
import {clientObjectListSelector} from "../../../../../../../ducks/clientObject";
import {downloadDocument} from "../../../../../../../ducks/documents";
import {specialitiesAllV2DictSelector} from "../../../../../../../ducks/speciality";

import "./style.sass";

const CrowdTaskEditPublishingForm = () => {
    const {
        clientId,
        values,
        calculatorData,
    } = useContext(CrowdTaskEditContext);

    const dispatch = useDispatch();

    const [brandLogo, setBrandLogo] = useState(null);

    const [block, element] = bem("crowd-task-edit-publishing-form");

    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const objectList = useSelector(clientObjectListSelector);
    const clientInfo = useSelector(clientCardInfoSelector);
    const {
        name,
        brand,
        base64Logo,
    } = clientInfo;
    const {
        contractorTaxAmount,
        amountToCard,
    } = calculatorData;

    const {
        workStartDate,
        workEndDate,
        medicalBookRequired,
        driverLicenseRequiredCategories,
        personalVehicleRequired,
        driverLicenseRequired,
        objectId,
    } = values;

    const periodText = `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`;
    const timeText = `${values.timeHours}ч ${values.timeMinutes}мин`;
    const objectInfo = objectList.find(item => item.objectId === objectId) || {};

    useEffect(() => {
        if (objectInfo.brandId) {
            dispatch(downloadDocument({
                isDownload: false,
                downloadLink: `/bff/adm/download-client-files/getClientBrandLogoThumbnailById?brandId=${objectInfo.brandId}`,
                isShowFileNotFoundErrorMessage: false,
                getResultFile: (file) => {
                    const objectBrandLogo = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    },
                    );

                    setBrandLogo(objectBrandLogo);
                },
            }));
        }
    }, [objectInfo]);

    const getThingsLabelText = () => {
        if (
            !medicalBookRequired &&
            !personalVehicleRequired &&
            !driverLicenseRequired &&
            driverLicenseRequiredCategories.length === 0
        ) {
            return;
        }

        const things = [];

        if (medicalBookRequired) {
            things.push("Медицинская книжка");
        }

        if (driverLicenseRequired) {
            const types = driverLicenseRequiredCategories.join(", ");
            const strTypes = types ? ` ${types}` : "";

            things.push(`Водительские права${strTypes}`);
        }

        if (personalVehicleRequired) {
            things.push("Личный автомобиль");
        }

        return (
            <OrderEditFormPublishLabelText
                className="mb-2"
                label="Должны быть"
                text={things.join(", ")}
            />
        );
    };

    const renderButton = () => {
        return (
            <NmButton
                className="col-16 mt-2"
                disabled={true}
                onClick={() => {
                }}
                size="xl"
            >
                Откликнуться
            </NmButton>
        );
    };

    return (
        <div className={block()}>
            <div className={element("header")}>
                Проверьте параметры задания перед публикацией
            </div>
            <div className={element("content")}>
                <div className={element("column")}>
                    <div className={element("description")}>
                        <div className={element("description-text")}>
                            Так ваше задание будет отображаться в списке поиска для исполнителей
                        </div>
                    </div>
                    <div className={element("card-app", {row: true})}>
                        <div className={element("card-app-line")}>
                        </div>
                        <div className={element("card-app-content")}>
                            <div className="flex flex-content-spaced mb-2">
                                <Text
                                    level="4"
                                    bold={true}
                                >
                                    № 000: 
                                    {" "}
                                    {values.name}
                                </Text>
                                <Text
                                    level="4"
                                    medium={true}
                                    color={COLOR.PRIMARY_100}
                                >
                                    {formatAmount(getNumberFromFormattedAmount(values.paymentAmount), true)}
                                </Text>
                            </div>
                            <div className="flex">
                                <PlaceIcon
                                    className="me-2"
                                    color={COLOR.PASSIVE_50}
                                    width={14}
                                    height={14}
                                />
                                <Text level="3">
                                    700 м от вас
                                </Text>
                            </div>
                            <div className="flex mb-2">
                                <WatchIcon
                                    className="me-2"
                                    color={COLOR.PASSIVE_50}
                                    width={14}
                                    height={14}
                                />
                                <Text level="3">
                                    {periodText}
                                    {" "}
(~
                                    {timeText}
)
                                </Text>
                            </div>
                            <NmLabelText
                                type="page"
                                label="Вид деятельности"
                                text={specialityDict.get(values.specialityId)}
                                boldText={true}
                            />
                            <NmLabelText
                                type="page"
                                text={values.address}
                            />
                            {renderButton()}
                        </div>
                    </div>
                </div>
                <div className={element("column")}>
                    <div className={element("description")}>
                        <div className={element("description-text")}>
                            И так при просмотре подробной информации перед отправкой отклика
                        </div>
                    </div>
                    <div className={element("card-app", {padding: true})}>
                        <Text
                            level="5"
                            bold={true}
                        >
                            №000: 
                            {" "}
                            {values.name}
                        </Text>
                        <div className="flex align-items-center flex-content-spaced mt-2 mb-2">
                            <Text
                                medium={true}
                                color={COLOR.PRIMARY_100}
                                className={element("amount")}
                            >
                                {formatAmount(getNumberFromFormattedAmount(values.paymentAmount), true)}
                            </Text>
                            <div className="flex flex-column flex-aligned-end">
                                <Text
                                    level="1"
                                    color={COLOR.SECONDARY_70}
                                >
                                    На баланс: 
                                    {" "}
                                    {formatAmount(formatNumber(amountToCard || 0, 2), true)}
                                </Text>
                                <Text
                                    level="1"
                                    color={COLOR.SECONDARY_70}
                                >
                                    Налог: 
                                    {" "}
                                    {formatAmount(formatNumber(contractorTaxAmount || 0, 2), true)}
                                </Text>
                                <div className="flex align-items-center">
                                    <Text
                                        level="1"
                                        color={COLOR.SECONDARY_70}
                                    >
                                        Комиссия
                                    </Text>
                                    <InfoIcon
                                        className="ms-1"
                                        color={COLOR.INERT_100}
                                        width={16}
                                        height={16}
                                    />
                                </div>
                            </div>
                        </div>
                        <NmDivider
                            className="mb-4"
                            color={COLOR.SECONDARY_5}
                        />
                        <OrderEditFormPublishClientInfoRating
                            label="Заказчик"
                            className="mb-4"
                            clientId={clientId}
                        />
                        <OrderEditFormPublishClientInfo
                            className="mb-4"
                            base64Logo={base64Logo}
                            brand={brand}
                            clientName={name}
                            objectInfo={objectInfo}
                            objectBrandLogo={brandLogo}
                        />
                        <NmDivider
                            className="mb-4"
                            color={COLOR.SECONDARY_5}
                        />
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Период работ"
                            text={periodText}
                        />
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Примерное время выполнения"
                            text={timeText}
                        />
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Описание работ"
                            text={values.servicesDescription}
                        />
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Место выполнения работ"
                            text={
                                <>
                                    {values.address}
                                    <Text
                                        className="mt-2"
                                        color={COLOR.PASSIVE_100}
                                        underline={true}
                                    >
                                        Показать на карте
                                    </Text>
                                </>
                            }
                        />
                        {getThingsLabelText()}
                        {
                            values.productCategory &&
                            <OrderEditFormPublishLabelText
                                className="mb-2"
                                label="Категория продукции"
                                text={values.productCategory}
                            />
                        }
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Вид деятельности"
                            text={specialityDict.get(values.specialityId)}
                        />
                        {
                            Boolean(values.keywords.length) &&
                            <NmSelectedList
                                showedItemsCount={5}
                                isShowDetailModal={false}
                                showListWithoutValue={true}
                                list={values.keywords.map(item => ({text: item.content}))}
                            />
                        }
                        {renderButton()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrowdTaskEditPublishingForm;