import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

export const bffExportDocumentsSelector = state => state.bff.exportDocuments;
export const bffExportDocumentsListSelector  = createSelector(bffExportDocumentsSelector, ({list}) => list);
export const bffExportDocumentsTotalCountSelector  = createSelector(bffExportDocumentsSelector, ({totalCount}) => totalCount);
export const bffExportDocumentsTotalPagesSelector  = createSelector(bffExportDocumentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffExportDocumentsProgressSelector  = createSelector(bffExportDocumentsSelector, ({progress}) => progress);
export const bffExportDocumentsActionSelector  = createSelector(bffExportDocumentsSelector, ({progressAction}) => progressAction);
export const bffExportDocumentTypeDictSelector = createSelector(bffExportDocumentsSelector, ({exportDocumentTypeDict}) => dictionaryToOptions(exportDocumentTypeDict));