import {
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_SUCCESS,
    UPDATE_SETTINGS_CLIENT_GROUPS_CARD_STORE,
} from "./actions";

const initial = {
    progressAction: false,
    progress: false,
    error: null,
    card: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case SETTINGS_CLIENT_GROUP_CARD_GET_INFO_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case SETTINGS_CLIENT_GROUP_CARD_GET_INFO_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_CLIENT_GROUP_CARD_IMPORT_SUCCESS:
        case SETTINGS_CLIENT_GROUP_CARD_IMPORT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_SETTINGS_CLIENT_GROUPS_CARD_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};