import React from "react";
import {Switch, withRouter} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import FinanceExport from "../../../finance/finance-export";
import OrderAnalytics from "../../../order/analytics";
import {ClientReportsMonitoringRisk} from "../../../reports/monitoring-risk";
import ClientFinanceUpload from "../../client-finance-upload";

import {
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
} from "../../../../constants/links";

export const ClientCardReportsRoute = () => {
    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_FINANCE_EXPORT_FULL}
                component={FinanceExport}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_REPORTS_RISK_MONITORING}
                component={ClientReportsMonitoringRisk}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_FINANCE_UPLOAD}
                component={ClientFinanceUpload}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDERS_ANALYTICS}
                component={OrderAnalytics}
            />
        </Switch>
    );
};

export default withRouter(ClientCardReportsRoute);