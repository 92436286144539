import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageRecruitmentVacancy,
} from "../../../../ducks/bff/recruitment/vacancy/actionCreators";

import {useFilter} from "../../../../hooks/useFilter";

import {getVacanciesRequestData} from "../utils/dto";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

const useRecruitmentVacanciesFetch = (props) => {
    const {
        clientId,
        setPagination,
        archived,
        pageNum,
        pageSize,
    } = props;

    const dispatch = useDispatch();

    const initFilter = {
        dateFrom: null,
        dateTo: null,
        createDateFrom: null,
        createDateTo: null,
        statuses: [],
        title: "",
        recruiterName: "",
        vacancyNumber: "",
        workAddressFiasId: null,
        lastModifiedDateFrom: null,
        lastModifiedDateTo: null,
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
    });

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.addVacanciesAdmin,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
            ],
        }));
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        archived,
    ]);

    const fetchList = () => {
        const filters = getVacanciesRequestData(filterData);

        dispatch(getPageRecruitmentVacancy({
            clientId,
            archived,
            pageSize,
            pageNum,
            ...filters,
        }));
    };

    return {
        fetchList,
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
    };
};

export default useRecruitmentVacanciesFetch;