import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {DEPOSIT_SUB_PAGE} from "../../containers/deposit/client-list";
import NmDepositHistoryWindow from "../../containers/finance/nm-deposit-history-window";
import Filter from "../ActualComponents/Filter";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import ExtLink from "../ExtLink";
import NmPage from "../NmPage";
import {NmPageHeader} from "../NmPageHeader";
import TableDiv from "../TableDiv";

import {usePagination} from "../../hooks/usePagination";
import useSortList, {sortAction} from "../../hooks/useSortList";
import {useFinancePivotFilter} from "./hooks/useFilter";

import {formatAmount, formatNumber} from "../../utils/stringFormat";

import {LINK_CLIENT_NDFL_DEPOSIT_LIST} from "../../constants/links";

import "./style.sass";

export default function FinancePivot(props) {
    const {
        header,
        defaultDateSortType,
        list,
        summary,
        totalCount,
        totalPages,
        isInitLoad = true,
        progressDepositHistory,
        progressReports,
    } = props;
    const {t} = useTranslation();
    const {
        clientId,
        pageNumber,
        from,
        to,
    } = props.match.params;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page", props.match.params.pageSize, pageNumber);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useFinancePivotFilter({
        pageSize,
        setPagination,
        from,
        to,
        clientId,
    });

    const [isMounted, setMounted] = useState(false);
    const [, orderType, headers, sortDispatch] = useSortList(props.headers);
    const [depositReplenishmentHistory, setDepositReplenishmentHistory] = useState([]);


    useEffect(() => {
        if (!isInitLoad && !isMounted) {
            setMounted(true);

            return;
        }

        fetchList();
    }, [pageNum, pageSize, orderType, filterData]);

    const fetchList = () => {
        const {clientIdFilter, fromDateFilter, toDateFilter} = filterData;

        props.fetchList({
            clientId: clientIdFilter,
            fromDate: fromDateFilter,
            toDate: toDateFilter,
            pageNum,
            pageSize,
            orderType: orderType ? orderType.toUpperCase() : defaultDateSortType,
        });
    };

    function renderSummary(financeTotalData) {
        return {
            ...financeTotalData,
            clientName: t("finance-pivot-table.total"),
            pendingPayments: formatAmount(formatNumber(financeTotalData.pendingPayments, 2)),
            pendingPaymentsCustomerCommission: formatAmount(formatNumber(financeTotalData.pendingPaymentsCustomerCommission, 2)),
            comingAmount:
                <div className="hidden-ellipsis-nowrap">
                    {formatAmount(formatNumber(financeTotalData.comingAmount))}
                </div>,
        };
    }

    const onClickHistory = (clientId) => {
        setDepositReplenishmentHistory([]);
        props.onClickHistory({
            clientId,
            fromDate: filterData.fromDateFilter,
            toDate: filterData.toDateFilter,
            getResult: (result) => {
                setDepositReplenishmentHistory(result);
            },
        });
    };

    const getDepositReplenishment = (item, index) => {
        if (item.comingAmount === 0) {
            return null;
        }

        return (
            <div className="finance-pivot__replenishment">
                <span>
                    {`${formatAmount(formatNumber(item.comingAmount, 2))} `}
                </span>
                <NmDepositHistoryWindow
                    currentPosition={index + 1}
                    listLength={list.length}
                    data={depositReplenishmentHistory}
                    progress={progressDepositHistory}
                    arrivalFieldName="amount"
                    dateFieldName="dateTime"
                    onClick={() => onClickHistory(item.clientId)}
                />
            </div>
        );
    };

    function mapTableData() {
        const rows = list.map((item, index) => {
            const clientDepositLink = LINK_CLIENT_NDFL_DEPOSIT_LIST
                .replace(":clientId", item.clientId)
                .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);

            return {
                ...item,
                comingAmount: getDepositReplenishment(item, index),
                pendingPayments: formatAmount(formatNumber(item.pendingPayments, 2)),
                pendingPaymentsCustomerCommission: formatAmount(formatNumber(item.pendingPaymentsCustomerCommission, 2)),
                clientName: (
                    <ExtLink
                        pageData={{pageNum, pageSize}}
                        // filterData={{clientIdFilter, fromDateFilter, toDateFilter}}
                        to={clientDepositLink}
                        historyEnabled={true}
                        className="finance-pivot__client-name"
                    >
                        {item.clientName}
                        {item.brand && `(${item.brand})`}
                    </ExtLink>
                ),
            };
        });

        rows.unshift(renderSummary(summary));

        return {
            headers,
            rows,
        };
    }

    const handleChangeSearch = (orderField, sortType) => {
        if (sortType !== "asc" && sortType !== "desc") {
            return;
        }

        sortDispatch(sortAction([orderField, sortType]));
    };

    function renderContent() {
        return (
            <div className="finance-pivot__content">
                {
                    list.length === 0 ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                            fetchProgress={progressReports}
                        /> :
                        <div className="finance-pivot__overflow-container">
                            <TableDiv
                                className="finance-pivot__table"
                                handleChangeSearch={handleChangeSearch}
                                tableData={mapTableData()}
                            />
                        </div>
                }
            </div>
        );
    }

    return (
        <NmPage
            header={
                <NmPageHeader
                    text={header}
                />
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            typeFilter="vertical"
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            className="finance-pivot"
            tabIndex="0"
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressReports}
            controlsClassName="finance-pivot__controls"
            paginationClassName="finance-pivot__pagination"
        >
            {renderContent()}
        </NmPage>
    );
}