import {
    SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR,
    SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST,
    SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS,
    SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS,
    SETTINGS_NAIMIX_INFO_LOAD_ERROR,
    SETTINGS_NAIMIX_INFO_LOAD_REQUEST,
    SETTINGS_NAIMIX_INFO_LOAD_SUCCESS,
    SETTINGS_NAIMIX_INFO_SAVE_ERROR,
    SETTINGS_NAIMIX_INFO_SAVE_REQUEST,
    SETTINGS_NAIMIX_INFO_SAVE_SUCCESS,
    UPDATE_SETTINGS_NAIMIX_INFO_STORE,
} from "./actions";

const initial = {
    card: {},
    progress: false,
    progressAction: false,
    files: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_NAIMIX_INFO_LOAD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case SETTINGS_NAIMIX_INFO_LOAD_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload.naimixInfo,
            };
        case SETTINGS_NAIMIX_INFO_LOAD_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SETTINGS_NAIMIX_INFO_SAVE_REQUEST:
        case SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_NAIMIX_INFO_SAVE_SUCCESS:
        case SETTINGS_NAIMIX_INFO_SAVE_ERROR:
        case SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS:
        case SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.fieldName]: payload.result,
                },
            };
        case UPDATE_SETTINGS_NAIMIX_INFO_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};