import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getPageOrderContractors} from "./order";
import {
    getDataPaidSumSelector,
    getPaidSumList,
} from "./orderWorkReport";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {ORDER_WORK_REPORT} from "../constants/messages";

const controller = "/orderWorkReport";
//*  TYPES  *//

const FINANCE_REPORT_PAYMENT_LIST_REQUEST = "FINANCE_REPORT_PAYMENT_LIST_REQUEST";
const FINANCE_REPORT_PAYMENT_LIST_SUCCESS = "FINANCE_REPORT_PAYMENT_LIST_SUCCESS";
const FINANCE_REPORT_PAYMENT_LIST_ERROR = "FINANCE_REPORT_PAYMENT_LIST_ERROR";

const PAYMENT_TOTAL_AMOUNT_REQUEST = "PAYMENT_TOTAL_AMOUNT_REQUEST";
const PAYMENT_TOTAL_AMOUNT_SUCCESS = "PAYMENT_TOTAL_AMOUNT_SUCCESS";
const PAYMENT_TOTAL_AMOUNT_ERROR = "PAYMENT_TOTAL_AMOUNT_ERROR";

const FINANCE_REPORT_CONTRACTOR_LIST_REQUEST = "FINANCE_REPORT_CONTRACTOR_LIST_REQUEST";
const FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS = "FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS";
const FINANCE_REPORT_CONTRACTOR_LIST_ERROR = "FINANCE_REPORT_CONTRACTOR_LIST_ERROR";

const FINANCE_REPORT_PAYMENT_INFO_REQUEST = "FINANCE_REPORT_PAYMENT_INFO_REQUEST";
const FINANCE_REPORT_PAYMENT_INFO_SUCCESS = "FINANCE_REPORT_PAYMENT_INFO_SUCCESS";
const FINANCE_REPORT_PAYMENT_INFO_ERROR = "FINANCE_REPORT_PAYMENT_INFO_ERROR";

const FINANCE_REPORT_SUMMARY_DATA_REQUEST = "FINANCE_REPORT_SUMMARY_DATA_REQUEST";
const FINANCE_REPORT_SUMMARY_DATA_SUCCESS = "FINANCE_REPORT_SUMMARY_DATA_SUCCESS";
const FINANCE_REPORT_SUMMARY_DATA_ERROR = "FINANCE_REPORT_SUMMARY_DATA_ERROR";

const FINANCE_REPORT_TAX_LIST_REQUEST = "FINANCE_REPORT_TAX_LIST_REQUEST";
const FINANCE_REPORT_TAX_LIST_SUCCESS = "FINANCE_REPORT_TAX_LIST_SUCCESS";
const FINANCE_REPORT_TAX_LIST_ERROR = "FINANCE_REPORT_TAX_LIST_ERROR";

const UPDATE_FINANCE_REPORT_STORE = "UPDATE_FINANCE_REPORT_STORE";

const FINANCE_INIT_PAYMENT_REQUEST = "FINANCE_INIT_PAYMENT_REQUEST";
const FINANCE_INIT_PAYMENT_SUCCESS = "FINANCE_INIT_PAYMENT_SUCCESS";
const FINANCE_INIT_PAYMENT_ERROR = "FINANCE_INIT_PAYMENT_ERROR";

const CONFIRM_ORDER_PAYMENTS_REQUEST = "CONFIRM_ORDER_PAYMENTS_REQUEST";
const CONFIRM_ORDER_PAYMENTS_SUCCESS = "CONFIRM_ORDER_PAYMENTS_SUCCESS";
const CONFIRM_ORDER_PAYMENTS_ERROR = "CONFIRM_ORDER_PAYMENTS_ERROR";

const PAY_ORDER_PAYMENTS_REQUEST = "PAY_ORDER_PAYMENTS_REQUEST";

const FINANCE_REPORT_GET_OPTIMIZED_PAGE_REQUEST = "FINANCE_REPORT_GET_OPTIMIZED_PAGE_REQUEST";
const FINANCE_REPORT_GET_OPTIMIZED_PAGE_SUCCESS = "FINANCE_REPORT_GET_OPTIMIZED_PAGE_SUCCESS";
const FINANCE_REPORT_GET_OPTIMIZED_PAGE_ERROR = "FINANCE_REPORT_GET_OPTIMIZED_PAGE_ERROR";
const FINANCE_REPORT_GET_OPTIMIZED_PAGE_CLEAR = "FINANCE_REPORT_GET_OPTIMIZED_PAGE_CLEAR";

const CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_REQUEST = "CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_REQUEST";
const CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_SUCCESS = "CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_SUCCESS";
const CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_ERROR = "CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_ERROR";

const CONTRACTOR_INCOME_TOTAL_REQUEST = "CONTRACTOR_INCOME_TOTAL_REQUEST";
const CONTRACTOR_INCOME_TOTAL_SUCCESS = "CONTRACTOR_INCOME_TOTAL_SUCCESS";
const CONTRACTOR_INCOME_TOTAL_ERROR = "CONTRACTOR_INCOME_TOTAL_ERROR";

//*  INITIAL STATE  *//

const initial = {
    paymentList: [],
    paymentListPageData: {
        data: {},
    },
    paymentListTotalCount: 0,
    contractorListForFilter: [],
    contractorListByIds: [],
    summaryDataLoading: false,
    summaryData: {},
    contractortTaxList: [],
    paymentInfo: {},
    paymentInfoLoading: false,
    duplicatedPaymentsInfo: {
        paymentsNumberDuplicated: [],
        sumOfMoney: 0,
    },
    processDuplicatedPayments: false,
    optimizedList: [],
    paymentsTotalAmount: 0,
    loadingDataAmount: false,
    loadingData: false,
    contractorIncomeList: [],
    contractorIncomeTotal: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case PAYMENT_TOTAL_AMOUNT_REQUEST:
            return {
                ...state,
                loadingDataAmount: true,
            };
        case PAYMENT_TOTAL_AMOUNT_SUCCESS:
            return {
                ...state,
                paymentsTotalAmount: payload,
                loadingDataAmount: false,
            };
        case PAYMENT_TOTAL_AMOUNT_ERROR:
            return {
                ...state,
                loadingDataAmount: false,
            };
        case FINANCE_REPORT_GET_OPTIMIZED_PAGE_REQUEST:
            return {
                ...state,
                loadingData: true,
                paymentListPageData: {...payload},
            };
        case FINANCE_REPORT_GET_OPTIMIZED_PAGE_SUCCESS: {
            const {
                results: optimizedList,
                totalCount: paymentListTotalCount,
            } = payload;
            return {
                ...state,
                optimizedList,
                paymentListTotalCount,
                loadingData: false,
            };
        }
        case FINANCE_REPORT_GET_OPTIMIZED_PAGE_CLEAR:
            return {
                ...state,
                optimizedList: [],
            };
        case FINANCE_REPORT_GET_OPTIMIZED_PAGE_ERROR:
            return {
                ...state,
                loadingData: false,
            };
        case UPDATE_FINANCE_REPORT_STORE:
            return {
                ...state,
                ...payload,
            };
        case FINANCE_INIT_PAYMENT_REQUEST:
            return {
                ...state,
                processDuplicatedPayments: true,
            };
        case FINANCE_INIT_PAYMENT_SUCCESS:
            const {paymentsNumberDuplicated, warnings, sumOfMoney, errors} = payload;

            return {
                ...state,
                processDuplicatedPayments: false,
                duplicatedPaymentsInfo: {
                    warnings,
                    paymentsNumberDuplicated,
                    errors,
                    sumOfMoney,
                },
            };
        case FINANCE_INIT_PAYMENT_ERROR:
            return {
                ...state,
                processDuplicatedPayments: false,
            };
        case FINANCE_REPORT_SUMMARY_DATA_REQUEST:
            return {
                ...state,
                summaryDataLoading: true,
            };
        case FINANCE_REPORT_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                loadingData: true,
                paymentListPageData: {...payload},
            };
        case FINANCE_REPORT_TAX_LIST_REQUEST:
            return {
                ...state,
                loadingData: true,
                paymentListPageData: {...payload},
            };
        case FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS:
            const {contractors: contractorListForFilter} = payload;
            return {
                ...state,
                loadingData: false,
                contractorListForFilter,
            };
        case FINANCE_REPORT_PAYMENT_INFO_REQUEST:
            return {
                ...state,
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_PAYMENT_INFO_SUCCESS:
            return {
                ...state,
                paymentInfo: {...payload},
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_PAYMENT_INFO_ERROR:
            return {
                ...state,
                paymentInfo: {},
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                summaryData: {...payload},
                summaryDataLoading: false,
            };
        case FINANCE_REPORT_TAX_LIST_SUCCESS:
            return {
                ...state,
                contractortTaxList: payload,
                loadingData: false,
            };
        case FINANCE_REPORT_PAYMENT_LIST_SUCCESS:
            const {
                reports: paymentList,
                totalCount: paymentListTotalCount,
            } = payload;
            return {
                ...state,
                paymentList,
                paymentListTotalCount,
                loadingData: false,
            };
        case CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_SUCCESS:
            return {
                ...state,
                contractorIncomeList: payload,
            };
        case CONTRACTOR_INCOME_TOTAL_SUCCESS:
            return {
                ...state,
                contractorIncomeTotal: payload,
            };
        case FINANCE_REPORT_PAYMENT_LIST_ERROR:
        case FINANCE_REPORT_TAX_LIST_ERROR:
            return {
                ...state,
                loadingData: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//


export function getPaymentsTotalAmount(payload) {
    return {
        type: PAYMENT_TOTAL_AMOUNT_REQUEST,
        payload,
    };
}

export function getPaymentList(payload) {
    return {
        type: FINANCE_REPORT_PAYMENT_LIST_REQUEST,
        payload,
    };
}

export function getPaymentInfo(payload) {
    return {
        type: FINANCE_REPORT_PAYMENT_INFO_REQUEST,
        payload,
    };
}

export function getContractorListForFilter(payload) {
    return {
        type: FINANCE_REPORT_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}

export function clearOptimizedList() {
    return {
        type: FINANCE_REPORT_GET_OPTIMIZED_PAGE_CLEAR,
    };
}

export function getContractorIncomeSummaryData(payload) {
    return {
        type: FINANCE_REPORT_SUMMARY_DATA_REQUEST,
        payload,
    };
}

export function initPayment(payload) {
    return {
        type: FINANCE_INIT_PAYMENT_REQUEST,
        payload,
    };
}

export function confirmOrderPayments(payload) {
    return {
        type: CONFIRM_ORDER_PAYMENTS_REQUEST,
        payload,
    };
}

export function payOrderPayments(payload) {
    return {
        type: PAY_ORDER_PAYMENTS_REQUEST,
        payload,
    };
}

export function updateFinanceReportStore(payload) {
    return {
        type: UPDATE_FINANCE_REPORT_STORE,
        payload,
    };
}

// оптимизированный список
export function getOptimizedPaymentList(payload) {
    return {
        type: FINANCE_REPORT_GET_OPTIMIZED_PAGE_REQUEST,
        payload,
    };
}

export function getContractorIncomeListForLastThreeMonths(payload) {
    return {
        type: CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_REQUEST,
        payload,
    };
}

export function getContractorIncomeTotal(payload) {
    return {
        type: CONTRACTOR_INCOME_TOTAL_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const financeReportSelector = state => state.financeReport;

export const paymentsTotalAmountSelector = createSelector(financeReportSelector, ({paymentsTotalAmount}) => paymentsTotalAmount);

export const getPaymentListTotalCountSelector = createSelector(financeReportSelector, ({paymentListTotalCount}) => paymentListTotalCount);

export const paymentInfoSelector = createSelector(financeReportSelector, ({paymentInfo}) => paymentInfo);

export const getPaymentPageDataSelector = createSelector(financeReportSelector, ({paymentListPageData}) => paymentListPageData);

export const contractorIncomeSummaryDataSelector = createSelector(financeReportSelector, ({summaryData}) => summaryData);

export const getPaymentListTotalPagesSelector = createSelector(financeReportSelector, ({paymentListTotalCount, paymentListPageData}) => {
    const {
        data: {
            pageSize,
        },
    } = paymentListPageData;
    return pageSize ? Math.ceil(paymentListTotalCount / pageSize) : 0;
});
export const getContractorListForFilterSelector = createSelector(financeReportSelector, ({contractorListForFilter}) => contractorListForFilter.map(({contractorId: value, firstName, lastName, patronymic}) => ({
    key: value, value, text: `${lastName} ${firstName} ${patronymic}`,
})));

export const getLoadingDataSelector = createSelector(financeReportSelector, ({loadingData}) => loadingData);
export const getLoadingDataAmountSelector = createSelector(financeReportSelector, ({loadingDataAmount}) => loadingDataAmount);

export const getPaymentEntityList = createSelector(financeReportSelector, ({paymentList}) => paymentList);

export const financeReportDuplicatedPaymentsSelector = createSelector(financeReportSelector, ({duplicatedPaymentsInfo}) => duplicatedPaymentsInfo);
export const financeReportProcessDuplicatedPaymentsSelector = createSelector(financeReportSelector, ({processDuplicatedPayments}) => processDuplicatedPayments);

export const getOrderWorkReportOptimizedListSelector = createSelector(financeReportSelector, ({optimizedList}) => optimizedList);
export const getOrderWorkReportOptimizedListTaskIdsSelector = createSelector(financeReportSelector, ({optimizedList}) =>
    optimizedList.map(value => `${value.clientId}:${value.orderId}:${value.paymentNumber}`),
);
export const contractorIncomeListSelector = createSelector(financeReportSelector, ({contractorIncomeList}) => contractorIncomeList);
export const contractorIncomeTotalSelector = createSelector(financeReportSelector, ({contractorIncomeTotal}) => contractorIncomeTotal);

//*  SAGA  *//

export const getPaymentsTotalAmountSaga = function* ({payload: {data}}) {
    try {
        const result = yield request.post(`${controller}/getPaymentsTotalAmount`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: PAYMENT_TOTAL_AMOUNT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PAYMENT_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAYMENT_TOTAL_AMOUNT_ERROR,
            payload: error,
        });
    }
};

export const getPaymentListSaga = function* ({payload: {data}}) {
    try {
        const result = yield request.post(`${controller}/getPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_PAYMENT_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_PAYMENT_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_PAYMENT_LIST_ERROR,
            payload: error,
        });
    }
};

export const getContractorListSaga = function* (action) {
    try {
        const {payload: {data}} = action;
        const result = yield request.post("contractors/getPage", data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_REPORT_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_CONTRACTOR_LIST_ERROR,
            payload: error,
        });
    }
};

export const getContractorTaxListSaga = function* (action) {
    try {
        const {payload: {data}} = action;
        const result = yield request.post(`${controller}/getContractorTaxList`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_TAX_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_TAX_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_TAX_LIST_ERROR,
            payload: error,
        });
    }
};

export const getSummaryDataSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getContractorMoneyStats`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_SUMMARY_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_SUMMARY_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_SUMMARY_DATA_ERROR,
            payload: error,
        });
    }
};

// POST /api/orderWorkReport/addV2
// Инициализация выплаты V2
export const initPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/addV2`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_INIT_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_INIT_PAYMENT_SUCCESS,
            payload: result,
        });

        const {paymentsNumberDuplicated, warnings, errors} = result;

        if (
            warnings && warnings.length ||
            paymentsNumberDuplicated && paymentsNumberDuplicated.length ||
            errors
        ) {
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess(ORDER_WORK_REPORT.ADD_SUCCESS_TEXT);

        const state = yield select();

        yield put(getPageOrderContractors(state.order.orderContractorPageData));

        yield put(getPaidSumList(getDataPaidSumSelector(state)));
    } catch (error) {
        yield put({
            type: FINANCE_INIT_PAYMENT_ERROR,
            payload: error,
        });
    }
};

export const getPaymentInfoSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getOrderPaymentInfo`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_PAYMENT_INFO_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_PAYMENT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(ORDER_WORK_REPORT.PAYMENT_CONFIRM_ERROR);
        yield put({
            type: FINANCE_REPORT_PAYMENT_INFO_ERROR,
        });
    }
};

//POST /api/job/orderpayments/confirmOrderPaymentsV2
//Подтверждение платежей
export const confirmOrderPaymentsSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.post("/job/orderpayments/confirmOrderPaymentsV2", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONFIRM_ORDER_PAYMENTS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONFIRM_ORDER_PAYMENTS_SUCCESS,
            payload: result,
        });

        onSuccess(result);
    } catch (error) {
        toastError(ORDER_WORK_REPORT.PAYMENT_CONFIRM_ERROR);
        yield put({
            type: CONFIRM_ORDER_PAYMENTS_ERROR,
        });
    }
};

//POST /api/job/orderpayments/payOrderPaymentsV2
//Оплата платежей
export const payOrderPaymentsSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;
        const result = yield request.post("/job/orderpayments/payOrderPaymentsV2", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONFIRM_ORDER_PAYMENTS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONFIRM_ORDER_PAYMENTS_SUCCESS,
            payload: result,
        });

        onSuccess(result);
    } catch (error) {
        toastError(ORDER_WORK_REPORT.PAYMENT_TASK.ERROR);
        yield put({
            type: CONFIRM_ORDER_PAYMENTS_ERROR,
        });
    }
};

export const getOrderWorkReportOptimizedListSaga = function* ({payload}) {
    try {
        const {
            isNdlf = false,
            isClientPage = false,
            data,
        } = payload;
        const result = yield request.bff.post(`/adm${isClientPage ? "/clients" : ""}/${isNdlf ? "ndfl" : "npd"}-payments/page`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_REPORT_GET_OPTIMIZED_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: FINANCE_REPORT_GET_OPTIMIZED_PAGE_SUCCESS, payload: result});

    } catch (error) {
        toastError(error);

        yield put({type: FINANCE_REPORT_GET_OPTIMIZED_PAGE_ERROR, payload: error});
    }
};

//GET /api/orderWorkReport/getContractorIncomeListForLastThreeMonths
export const getContractorIncomeListForLastThreeMonthsSaga = function* ({payload: params}) {
    try {
        const result = yield request.get(`${controller}/getContractorIncomeListForLastThreeMonths`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_SUCCESS, payload: result});

    } catch (error) {
        toastError(error);

        yield put({type: CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_ERROR, payload: error});
    }
};

//GET /api/orderWorkReport/getContractorIncomeTotal
export const getContractorIncomeTotalSaga = function* ({payload: params}) {
    try {
        const result = yield request.get(`${controller}/getContractorIncomeTotal`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_INCOME_TOTAL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_INCOME_TOTAL_SUCCESS, payload: result});

    } catch (error) {
        toastError(error);

        yield put({type: CONTRACTOR_INCOME_TOTAL_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(FINANCE_REPORT_PAYMENT_LIST_REQUEST, getPaymentListSaga),
        takeEvery(FINANCE_REPORT_CONTRACTOR_LIST_REQUEST, getContractorListSaga),
        takeEvery(FINANCE_REPORT_SUMMARY_DATA_REQUEST, getSummaryDataSaga),
        takeEvery(FINANCE_REPORT_TAX_LIST_REQUEST, getContractorTaxListSaga),
        takeEvery(FINANCE_INIT_PAYMENT_REQUEST, initPaymentSaga),
        takeEvery(CONFIRM_ORDER_PAYMENTS_REQUEST, confirmOrderPaymentsSaga),
        takeEvery(FINANCE_REPORT_PAYMENT_INFO_REQUEST, getPaymentInfoSaga),
        takeEvery(PAY_ORDER_PAYMENTS_REQUEST, payOrderPaymentsSaga),
        takeEvery(FINANCE_REPORT_GET_OPTIMIZED_PAGE_REQUEST, getOrderWorkReportOptimizedListSaga),
        takeEvery(PAYMENT_TOTAL_AMOUNT_REQUEST, getPaymentsTotalAmountSaga),
        takeEvery(CONTRACTOR_INCOME_LIST_FOR_LAST_THREE_MONTHS_REQUEST, getContractorIncomeListForLastThreeMonthsSaga),
        takeEvery(CONTRACTOR_INCOME_TOTAL_REQUEST, getContractorIncomeTotalSaga),
    ]);
}
