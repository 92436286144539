import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {handleFormString} from "../../../../../utils/stringHelper";

import {
    clearFieldKeyword,
    getKeywordsPage,
} from "../../../../../ducks/bff/settings/directories/naimix/keywords/actionCreators";

export const useSettingsDirectoriesKeywordsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        usageCountSort,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        usageCountSort,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearFieldKeyword());
        };
    }, []);

    const fetchList = () => {
        dispatch(getKeywordsPage({
            pageSize,
            pageNum,
            valueFilter: handleFormString(filterData.valueFilter),
            usageCountSort,
        }));
    };

    return {
        fetchList,
    };
};