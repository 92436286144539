import React, {FC} from "react";

import bem from "../../../../../../utils/bem";

import "./style.sass";

interface IProps {
    notification?: string;
}

export const NmListCardNotification: FC<IProps> = (props) => {
    const {
        notification,
    } = props;

    const [block, element] = bem("nm-list-card-notification");

    return (
        <div className={block()}>
            <div className={element("message")}>
                {notification}
            </div>
        </div>
    );
};