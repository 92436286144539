import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_EXPORT_DOCUMENTS_ADD_ERROR,
    BFF_EXPORT_DOCUMENTS_ADD_REQUEST,
    BFF_EXPORT_DOCUMENTS_ADD_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_ERROR,
    BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_LINK_ERROR,
    BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_LINK_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_PROGRESS_ERROR,
    BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_PROGRESS_SUCCESS,
} from "./actions";

import {downloadByUrl} from "../../../../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../../../../utils/localstorage";
import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_FINANCE_EXPORT_DOCUMENTS,
} from "../../../../constants/links";

const controllers = {
    client: "/client-adm/documents/document-export",
    adminClientCard: "/adm/clients/client-card/documents/document-export",
    adminExport: "/adm/export/document-export",
};

const getPageExportDocuments = function* ({payload}) {
    try {
        const {clientId} = payload;

        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT_DOCUMENTS]: `${controllers.adminExport}/export/partitioned/page/rich`,
                isClientCard: Boolean(clientId),
                clientRolesUrl: `${controllers.client}/getPage`,
                adminRolesUrl: `${controllers.adminClientCard}/getPage`,
            },
            `${controllers.adminExport}/finance-page`,
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addExportDocuments = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT_DOCUMENTS]: `${controllers.adminExport}/export/partitioned/add`,
                [LINK_CLIENT_FINANCE_EXPORT_FULL.replace(":clientId", reqData.clientId)]: `${controllers.client}/addExport`,
            },
            `${controllers.adminExport}/export/partitioned/add`,
        );

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_EXPORT_DOCUMENTS_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_EXPORT_DOCUMENTS_ADD_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_EXPORT_DOCUMENTS_ADD_ERROR,
        });
    }
};

const getLinkExportDocuments = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };
        const result = yield request.getFile(`${window.location.origin}/bff/adm/export/document-export/filestore/export/partitioned/get-link`, requestHeaders);

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                return {
                    done: true,
                };
            }
        }

        const url = yield result.text();

        yield downloadByUrl(url, data.adminExportName);

        yield put({type: BFF_EXPORT_DOCUMENTS_GET_LINK_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: BFF_EXPORT_DOCUMENTS_GET_LINK_ERROR,
        });
    }
};

const getProgressExportDocuments = function* ({payload}) {
    try {
        const {
            getResult,
            list,
        } = payload;

        const result = yield request.bff.post(`${controllers.adminExport}/progress/list`, list);

        if (getResult) {
            getResult(result);
        }

        yield put({type: BFF_EXPORT_DOCUMENTS_GET_PROGRESS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: BFF_EXPORT_DOCUMENTS_GET_PROGRESS_ERROR});
    }
};

const getDictExportDocumentType = function* () {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}/documentType`,
                adminRolesUrl: `${controllers.adminClientCard}/documentType`,
            },
            `${controllers.adminClientCard}/documentType`,
        );

        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST, getPageExportDocuments),
        takeEvery(BFF_EXPORT_DOCUMENTS_ADD_REQUEST, addExportDocuments),
        takeEvery(BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST, getLinkExportDocuments),
        takeEvery(BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST, getProgressExportDocuments),
        takeEvery(BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST, getDictExportDocumentType),
    ]);
}