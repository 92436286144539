import React from "react";

import DepositClientList from "../client-list";

import {getPatentsDepositLinks} from "../../patents/patents-deposits-list/utils/getTabLinks";

const DepositPatentClientList = (props) => {
    const {
        match: {params: {clientId}},
    } = props;

    return (
        <DepositClientList
            isCivil={true}
            isPatentsPage={true}
            links={getPatentsDepositLinks(clientId)}
            header="Депозит по патентам"
            {...props}
        />
    );
};

export default DepositPatentClientList;