import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";

const controller = "/adm/recruitment/access-control/job-boards/account";

// POST /bff/adm/recruitment/access-control/job-boards/account/{id}/archive
// Архивировать аккаунт
const archiveRecruitmentAccessControlJobBoardAccount = function* ({payload}) {
    const {
        jobBoardAccountId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${jobBoardAccountId}/archive`);
        const {errorMessage, successMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (successMessage) {
            toastSuccess(successMessage);
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/job-boards/account/search
// Получить список аккаунтов джоб-бордов
const searchRecruitmentAccessControlJobBoardAccounts = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST, archiveRecruitmentAccessControlJobBoardAccount),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST, searchRecruitmentAccessControlJobBoardAccounts),
    ]);
}