import {
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS,
    BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR,
    BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS,
    BFF_CROWD_TASK_LOG_GET_PAGE_ERROR,
    BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS,
    UPDATE_CROWD_TASK_CARD_STORE,
} from "./actions";

const initial = {
    card: {},
    pageData: {},
    list: [],
    totalCount: 0,
    workingCard: {},
    workingCardProgress: false,
    progress: false,
    progressAction: false,
    progressList: false,
    error: null,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    contractorsCounts: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST:
        case BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST: {
            return {
                ...state,
                progress: true,
            };
        }
        case BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS: {
            return {
                ...state,
                card: payload.result,
                progress: false,
            };
        }
        case BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS:
        case BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR:
        case BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progressList: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR: {
            return {
                ...state,
                progressList: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST: {
            return {
                ...state,
                workingCardProgress: true,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS: {
            return {
                ...state,
                workingCardProgress: false,
                workingCard: payload.result || {},
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR: {
            return {
                ...state,
                workingCardProgress: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST: {
            return {
                ...state,
                progressAction: true,
                actionPageData: payload,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS:
        case BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST: {
            return {
                ...state,
                logPageData: payload,
                logProgress: true,
            };
        }
        case BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS: {
            return {
                ...state,
                logList: payload.results,
                logTotalCount: payload.totalCount,
                logProgress: false,
            };
        }
        case BFF_CROWD_TASK_LOG_GET_PAGE_ERROR: {
            return {
                ...state,
                logProgress: false,
            };
        }
        case BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS: {
            return {
                ...state,
                contractorsCounts: payload,
            };
        }
        case UPDATE_CROWD_TASK_CARD_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};