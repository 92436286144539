import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";

import {ReactComponent as ChatIcon} from "../../../../../../images/chat_24.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../images/phone_24.svg";

import {getStartDate} from "../../../../../../utils/dateFormat";
import {openLinkByUrl} from "../../../../../../utils/downloadBlob";
import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {phoneFormat, phoneFormatWithoutSevenNumber} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";
import {CROWD_TASK_STATUS, CROWD_TASK_WORKING_CONTRACTOR_STATUS} from "../../../../../../constants/crowd/task";
import {LINK_CLIENT_CROWD_TASK_CHATS} from "../../../../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../../../ducks/bff/clients/info/selectors";
import {rejectCrowdPayment, repeatCrowdPayment} from "../../../../../../ducks/bff/crowd/payment/actionCreators";
import {
    approveResultCrowdTaskContractorsWorking,
    forceFinishCrowdTaskContractorsWorking,
    rejectResultCrowdTaskContractorsWorking,
    scheduleOptimumIntegrationCrowdTaskContractors,
} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {
    bffCrowdTasksCardRichSelector,
    bffCrowdTasksCardWorkingSelector,
} from "../../../../../../ducks/bff/crowd/taskCard/selectors";
import {currentUserRestrictionsSelector} from "../../../../../../ducks/clientUserRestrictions";
import {downloadDocument} from "../../../../../../ducks/documents";

import {STATUS_TASK_PAYMENT} from "../../../../../../constants/crowd/task-payment";

const useCrowdTaskCardInWorkPageAction = (props) => {
    const {
        clientId,
        taskId,
        status,
        contractor,
        onOpenConfirm,
        onCloseConfirm,
        fetchInfo,
        isCompletedPage,
    } = props;

    const dispatch = useDispatch();

    const [contractorRejectModalData, setContractorRejectModalData] = useState({});
    const [createActModalData, setCreateActModalData] = useState({});
    const [rejectReasonModalData, setRejectReasonModal] = useState({});

    const card = useSelector(bffCrowdTasksCardWorkingSelector);
    const taskCard = useSelector(bffCrowdTasksCardRichSelector);
    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);

    const isAccessPaymentAction = [
        ADMIN,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
    ].includes(role)
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.addCrowdPayments);
    const isAccessActionsCrowdTasksCard = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTasksCard);

    const isAccessPaymentDocumentView = [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
        NM_OPERATOR,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
    ].includes(role);

    const isTaskNotCompleted = taskCard.status === CROWD_TASK_STATUS.NOT_COMPLETED.VALUE;

    const handleCopyContact = () => {
        copy(phoneFormatWithoutSevenNumber(contractor.phone));

        toastSuccess("Номер телефона скопирован.");
    };

    const getContractorMediaControls = () => {
        const {
            contractorId,
            phone,
            chatUnreadMessagesCount,
        } = contractor;

        return {
            renderCount: {
                desktop: 4,
                tablet: 4,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        onClick: handleCopyContact,
                        icon: <PhoneIcon />,
                        color: "grey",
                        onlyIcon: true,
                        size: "lg",
                        popup: phoneFormat(phone),
                    },
                    asset: {
                        mobile: {
                            children: "Скопировать телефон",
                        },
                    },
                    visible: canViewContractorContacts && Boolean(phone),
                },
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        isLink: true,
                        href: LINK_CLIENT_CROWD_TASK_CHATS
                            .replace(":clientId", clientId)
                            .replace(":contractorId?", contractor.contractorId),
                        onlyIcon: true,
                        icon: <ChatIcon />,
                        color: "grey",
                        size: "lg",
                        popup: "Перейти в чат",
                        showIndicator: Boolean(chatUnreadMessagesCount),
                    },
                    asset: {
                        mobile: {
                            children: "Перейти в чат",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "xl",
                        onClick: () => setContractorRejectModalData({
                            clientId,
                            taskId,
                            contractorId,
                        }),
                        children: "Отказать",
                    },
                    visible: isAccessActionsCrowdTasksCard
                        && [
                            CROWD_TASK_WORKING_CONTRACTOR_STATUS.SIGNING_CONTRACT.VALUE,
                            CROWD_TASK_WORKING_CONTRACTOR_STATUS.HIRED.VALUE,
                        ].includes(status),
                },
            ],
        };
    };

    const getTaskMediaControls = () => {
        return {
            renderCount: {
                desktop: 4,
                tablet: 4,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        size: "lg",
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите отправить задание на проверку?",
                            onConfirm: forceFinish,
                            confirmButton: "Подтвердить",
                            cancelButton: "Отменить",
                        }),
                        children: "Отправить на проверку",
                    },
                    visible: status === CROWD_TASK_WORKING_CONTRACTOR_STATUS.AT_WORK.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => onOpenConfirm({
                            content: "Подтвердите, что работа исполнителя принята? Задание будет снято с публикации, работа исполнителя оплачена",
                            onConfirm: () => approveResult(),
                            confirmButton: "Подтвердить",
                            cancelButton: "Закрыть",
                        }),
                        children: "Принять работу",
                    },
                    visible: isAccessActionsCrowdTasksCard
                        && status === CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "lg",
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите отклонить работу исполнителя?",
                            onConfirm: onConfirmReject,
                            confirmButton: "Подтвердить",
                            cancelButton: "Закрыть",
                        }),
                        children: "Отклонить работу",
                    },
                    visible: isAccessActionsCrowdTasksCard
                        && status === CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: openActCreationModal,
                        children: "Сформировать акт",
                    },
                    visible: !isTaskNotCompleted
                        && status === CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE
                        && !card.payments?.length
                        && isAccessPaymentAction,
                },
            ],
        };
    };

    const getOptimumMediaControls = (sfaOptimumIntegrationData) => {
        const {
            contractorIntegrated,
            taskContractorIntegrated,
        } = sfaOptimumIntegrationData || {};
        return {
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: scheduleOptimumIntegration,
                        children: "Назначить исполнителя на анкету Оптимум",
                    },
                    visible: !taskContractorIntegrated,
                },
            ],
        };
    };

    const getPaymentMediaControls = (payment) => {
        const {
            paymentNumber,
            linkFNS,
            applicationDocumentFileLink,
            status,
            paymentId,
        } = payment;

        return {
            renderCount: {
                desktop: 3,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: openActCreationModal,
                        children: "Отправить акт повторно",
                    },
                    visible: !isTaskNotCompleted
                        && [
                            STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CANCELLED.VALUE,
                        ].includes(status)
                        && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => repeatPayment(payment),
                        children: "Повторить оплату",
                    },
                    visible: !isTaskNotCompleted
                        && status === STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE
                        && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "lg",
                        onClick: () => setRejectReasonModal({
                            isOpen: true,
                            paymentId,
                        }),
                        children: "Отклонить оплату",
                    },
                    visible: !isTaskNotCompleted && [
                        STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                        STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                        STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                    ].includes(status) && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        onClick: () => downloadFile(applicationDocumentFileLink),
                        children: "Заявка",
                    },
                    visible: Boolean(applicationDocumentFileLink) && isAccessPaymentDocumentView,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        onClick: () => downloadFile(`/bff/adm/clients/client-card/crowd-tasks/registry/task/files/act-document?paymentNumber=${paymentNumber}`),
                        children: "Акт",
                    },
                    visible: isCompletedPage && isAccessPaymentDocumentView,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        onClick: () => openLinkByUrl(linkFNS),
                        children: "Чек ФНС России",
                    },
                    visible: isCompletedPage && Boolean(linkFNS) && isAccessPaymentDocumentView,
                },
            ],
        };
    };

    const openActCreationModal = () => {
        setCreateActModalData({
            contractorId: contractor.contractorId,
            contractorName: contractor.name,
            contractSigningDateTime: card.contractSigningDateTime,
            applicationSigningDateTime: card.applicationSigningDateTime,
            taskWorkFinishedDateTime: card.taskWorkFinishedDateTime,
        });
    };

    const approveResult = () => {
        dispatch(approveResultCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId: contractor.contractorId,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                openActCreationModal();
            },
        }));
    };

    const rejectResult = ({completeTask, successText}) => {
        dispatch(rejectResultCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId: contractor.contractorId,
            completeTask,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                toastSuccess(successText);
            },
        }));
    };

    const forceFinish = () => {
        dispatch(forceFinishCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId: contractor.contractorId,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                toastSuccess("Задание отправлено на проверку");
            },
        }));
    };

    const scheduleOptimumIntegration = () => {
        dispatch(scheduleOptimumIntegrationCrowdTaskContractors({
            clientId,
            taskId,
            contractorId: contractor.contractorId,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
            },
        }));
    };

    const onConfirmReject = () => {
        const leftPartEquation = getStartDate(new Date(taskCard.workEndDate));
        const rightPartEquation = getStartDate(new Date());

        if (leftPartEquation.getTime() < rightPartEquation.getTime()) {
            rejectResult({
                completeTask: true,
                successText: "Период выполнения задания закончился. Задание завершено как невыполненное",
            });

            return;
        }

        onOpenConfirm({
            content: "Оставить задание в работе и назначить нового исполнителя из очереди?",
            onConfirm: () => rejectResult({
                completeTask: false,
                successText: "Работа отклонена. На задание будет назначен новой исполнитель из очереди.",
            }),
            onCancel: () => rejectResult({
                completeTask: true,
                successText: "Задание завершено как невыполненное",
            }),
            confirmButton: "Продолжить",
            cancelButton: "Завершить",
        });
    };

    const repeatPayment = ({paymentId, paymentNumber}) => {
        dispatch(repeatCrowdPayment({
            paymentId,
            onSuccess: () => {
                toastSuccess(`Запущена задача по повтору оплаты по заданию ${paymentNumber}`);
                fetchInfo();
            },
        }));
    };

    const rejectPayment = ({paymentId, rejectReason}) => {
        dispatch(rejectCrowdPayment({
            paymentId,
            rejectReason: handleFormString(rejectReason),
            onSuccess: () => {
                fetchInfo();
                setRejectReasonModal({});
            },
        }));
    };

    const downloadFile = (downloadLink) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
        }));
    };

    return {
        getContractorMediaControls,
        getTaskMediaControls,
        getOptimumMediaControls,
        getPaymentMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
        createActModalData,
        setCreateActModalData,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
    };
};

export default useCrowdTaskCardInWorkPageAction;