import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import Icon from "../../../../node_modules/semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../components/ActualComponents/Text";
import ApplyButtons from "../../../components/ApplyButtons";
import DefaultDropzone from "../../../components/DefaultDropzone";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";
import {ReactComponent as ClockOutlineIcon} from "../../../images/clock-outline_24.svg";
import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {ReactComponent as DoubleCheck} from "../../../images/double-check.svg";
import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import {ReactComponent as NaimixLogo} from "../../../images/naimix-logo.svg";
import {ReactComponent as UserIcon} from "../../../images/user_24.svg";
import DisputesNew from "../../disputes/disputes-new";
import ChatHeader from "./header";
import {Loader} from "semantic-ui-react";

import {getFilesWithPreview} from "../../../components/ActualComponents/DropzoneV2/utils/getFilesWithPreview";
import {isNMUsers} from "../../../utils/access";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {getDocumentIconName, getFileExtension} from "../../../utils/file";
import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {guid, isNullOrWhitespace} from "../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {CHAT_LINK_PARAMS, DISPUTE_FIELD} from "../../../constants/disputes";
import {
    ADMIN,
    CLIENT_ADMIN,
    FOREMAN,
    NM_COORDINATOR,
    NM_MANAGER, NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";
import {STATUS} from "../../../constants/status";
import {maxSizeScanFile, requiredMessage} from "../../../constants/validationRules";

import {
    addToChat,
    chatAddMessage,
    chatAddToChatSuccessSelector,
    chatLoadingSelector,
    chatMessageListNeedScrollSelector,
    chatMessageListSelector,
    chatMessageListTotalCountSelector,
    chatScrollToLastElementSelector,
    chatTotalPagesSelector,
    getDisputeMessageList,
    getMessageList,
    getNewMessageList,
    sessionMessageUploadingStatusesSelector,
    updateChatMessages,
    updateFieldChat,
} from "../../../ducks/chat";
import {chatSelector, getChatById, lockUserChat} from "../../../ducks/chatList";
import {
    clientCurrentMemberSelector,
    clientMemberOptionsSelector,
    getClientMemberList,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";
import {
    causesDisputeDictsOptionsSelector,
    disputeChangeStatusSuccessSelector,
    disputeLoadingSelector,
    disputeSelector,
    getDisputeById,
    getDisputeCausesDict,
    updateFieldDisputeStore,
    updateStatusDispute,
} from "../../../ducks/dispute";
import {downloadDocument} from "../../../ducks/documents";
import {
    addDocumentToChat,
    fileStoreDisputeImageSelector,
    fileStoreImagesSelector,
    fileStoreIsSuccessAddSelector,
    getChatFile,
    updateFieldFilesStore,
} from "../../../ducks/fileStore";

import PropTypes from "prop-types";

import "./style.sass";

class Chat extends Component {
    static propTypes = {
        disputeId: PropTypes.string,
        orderId: PropTypes.string,
        chatId: PropTypes.string,
        orderStatus: PropTypes.string,
        orderManagerId: PropTypes.string,
    };

    static defaultProps = {
        orderManagerId: "",
        messageList: [],
    };

    static IMAGE_EXTENSIONS = ["raw", "bmp", "gif", "png", "jpeg", "jpg"];

    constructor() {
        super();

        this.state = {
            msg: "",
            pageNum: 1,
            pageSize: 25,
            errorObj: {},
            files: [],
        };

        this.currentPage = 1;
        this.currentUserId = ls(CURRENT_CLIENT_USER_ID);
    }

    componentDidMount() {
        const {
            getDisputeById,
            disputeId,
            getDisputeCausesDict,
            chatId,
            updateFieldChat,
        } = this.props;

        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
        this.currentClientId = ls(CURRENT_CLIENT_ID);
        this.role = ls(USER_ROLE);
        this.isSimpleChat = !isNullOrWhitespace(chatId);

        updateFieldChat({currentChat: {disputeId}});

        this.fetchMessageList();
        if (this.isSimpleChat) {
            this.fetchChatById();
        } else {
            getDisputeById(disputeId);
            getDisputeCausesDict();
        }
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        const {
            disputeId,
            getDisputeById,
            imageProperties,
            needScroll,
            dispute,
            isAddToChatSuccess,
            changeStatusSuccess,
            updateFieldDisputeStore,
            updateFieldChat,
            isDisputeLoading,
            scrollToLastElement,
            chatId,
            messageList,
        } = this.props;
        const {
            dispute: oldDispute,
            messageList: _messageList,
            imageProperties: _imageProperties,
        } = prevProps;

        if (Object.keys(imageProperties).length && JSON.stringify(imageProperties) !== JSON.stringify(_imageProperties)) {
            const {getChatFile} = this.props;

            getChatFile({
                fileName: imageProperties.fileName,
                fileUuid: imageProperties.fileUuid,
                contentUrl: imageProperties.contentUrl,
            });
        }

        if (messageList.length && JSON.stringify(messageList) !== JSON.stringify(_messageList)) {
            this.getFiles(messageList);
            this.scrollToBottom();
        }

        if (scrollToLastElement) {
            updateFieldChat({
                scrollToLastElement: false,
            });

            setTimeout(() => {
                this.scrollToLastElement();
            }, 50);
        }

        if (prevProps.disputeId !== disputeId || prevProps.chatId !== chatId) {
            const {updateFieldFilesStore} = this.props;

            updateFieldFilesStore({images: []});

            this.fetchMessageList();
            this.currentPage = 1;

            if (prevProps.disputeId !== disputeId) {
                getDisputeById(disputeId);
            }

            if (prevProps.chatId !== chatId) {
                this.fetchChatById(disputeId);
            }
        }

        if (needScroll && !isDisputeLoading) {
            updateFieldChat({needScroll: false});
            this.scrollToBottom();
        }

        if (isAddToChatSuccess) {
            this.setState({
                isShowAddBlock: false,
            }, () => {
                updateFieldChat({isAddToChatSuccess: false});
            });
        }

        if (!isNullOrWhitespace(dispute) && dispute[DISPUTE_FIELD.DISPUTE_ID] && JSON.stringify(dispute) !== JSON.stringify(oldDispute)) {
            this.fetchClientAdminsList();
        }
        if (changeStatusSuccess) {
            updateFieldDisputeStore("isDisputeChangeStatusSuccess", false);
            this.setState({
                isShowApprovedBlock: false,
                isOpenDispute: false,
            }, () => {
                getDisputeById(disputeId);
                this.fetchMessageList();
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldChat} = this.props;
        updateFieldChat({
            messageList: [],
            sessionMessageUploadingStatuses: [],
            currentChat: {},
        });
    }

    getFiles = list => {
        const {
            getChatFile,
            images,
        } = this.props;

        list.forEach(({messageId, files}) => {
            if (!files || files.length === 0) {
                return;
            }

            files.forEach(({fileName, fileUuid, contentUrl}) => {
                const file = images.find(value => (value.fileUuid === fileUuid)) || {};

                if (!isEmpty(file)) {
                    return;
                }

                getChatFile({
                    messageId,
                    fileName,
                    fileUuid,
                    contentUrl,
                }, "multiSave");
            });
        });
    };

    fetchChatById = () => {
        const {getChatById, chatId} = this.props;

        getChatById({chatId});
    };

    fetchMessageList = () => {
        const {
            pageNum,
            pageSize,
        } = this.state;
        const {
            getMessageList,
            getDisputeMessageList,
            disputeId,
            chatId,
        } = this.props;

        if (this.isSimpleChat) {
            getMessageList({chatId, pageNum, pageSize});

            return;
        }

        getDisputeMessageList({disputeId, pageNum, pageSize});
    };

    sendMsg = (files = []) => {
        const {
            chatAddMessage,
            disputeId,
            chatId,
            updateChatMessages,
        } = this.props;
        const {msg} = this.state;

        if (!isNullOrWhitespace(msg) || files.length) {
            const newMessage = {
                content: msg,
                admin: isNMUsers(),
                clientUserId: this.currentUserId,
                chatId,
                files,
                disputeId,
                temporaryMessageId: guid(),
            };

            updateChatMessages({
                ...newMessage,
                dateTimeCreate: new Date(),
            });

            chatAddMessage({
                ...newMessage,
                simpleChat: this.isSimpleChat,
            });

            this.setState(prevState => ({
                ...prevState,
                msg: "",
                files: [],
            }));
        }
    };

    onSubmitMessage = () => {
        const {files} = this.state;

        if (!files.length) {
            this.sendMsg();

            return;
        }

        this.sendImages(files);
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.onSubmitMessage();
        }
    };

    handleOnChangeMessage = (e, {value, name}) => {
        const {isChatLoading} = this.props;

        if (!isChatLoading) {
            this.setState({
                [name]: value,
            });
        }
    };

    uploadFiles = (newFiles) => {
        if (!newFiles || !newFiles.length) {
            return;
        }

        const isSizeError = newFiles.some(file => file.size > maxSizeScanFile);

        if (isSizeError) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            files: getFilesWithPreview(newFiles, "image/*"),
        }));
    };

    removeImage = () => {
        this.setState(prevState => ({
            ...prevState,
            files: [],
        }));
    };

    renderPreviewFiles() {
        const {files} = this.state;

        if (!files.length) {
            return null;
        }

        const [file] = files;
        const fileExtension = getFileExtension(file.name);

        return (
            <div className="chat__images">
                <div className="chat__images-cont">
                    <div className="chat__images-preview">
                        {Chat.IMAGE_EXTENSIONS.includes(fileExtension) ?
                            <>
                                <CloseIcon
                                    width={16}
                                    height={16}
                                    className="chat__images-remove"
                                    onClick={() => {
                                        this.removeImage();
                                    }}
                                />
                                <div className="chat__images-preview">
                                    <img
                                        alt={file.name}
                                        className="chat__images-preview-image"
                                        src={file.preview}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <CloseIcon
                                    width={16}
                                    height={16}
                                    className="chat__images-remove"
                                    onClick={() => {
                                        this.removeImage();
                                    }}
                                />
                                <div>
                                    <Icon
                                        className="chat__images-preview-file-icon"
                                        name={getDocumentIconName(fileExtension)}
                                    />
                                    <Text
                                        title={file.name}
                                        level="3"
                                        noWrap
                                        className="chat__images-preview-filename"
                                    >
                                        {file.name}
                                    </Text>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }

    sendImages = (files) => {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append("file", file);
        });


        const {
            addDocumentToChat,
            disputeId,
            chatId,
        } = this.props;

        addDocumentToChat({
            disputeId,
            chatId,
            isFromChat: true,
            file: formData,
            isSimpleChat: this.isSimpleChat,
            getResult: (result) => {
                const uploadFiles = [result];

                this.sendMsg(uploadFiles);
            },
        });
    };

    onSearchChangeClientAdmin = (clientAdminNameFilter) => {
        this.setState({clientAdminNameFilter}, this.fetchClientAdminsList);
    };

    fetchClientAdminsList = () => {
        const {getClientMemberList, dispute} = this.props;
        const {clientAdminNameFilter} = this.state;

        if (!dispute.clientId) {
            return;
        }

        getClientMemberList({
            clientId: dispute.clientId,
            pageNum: 1,
            pageSize: 25,
            roleFilter: CLIENT_ADMIN,
            archiveFilter: false,
            nameSubstringFilter: isNullOrWhitespace(clientAdminNameFilter) ? "  " : clientAdminNameFilter.trim(),
            fieldName: "adminMembers",
        });
    };

    handleOnChangeDisputeChat = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }),
        );
    };

    onCloseModalAddToChat = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowAddBlock: false,
            clientAdmin: null,
            errorObj: {
                ...prevState.errorObj,
                clientAdmin: undefined,
            },
        }),
        );
    };

    scrollToBottom = () => {
        if (!isNullOrWhitespace(this.scroller)) {
            this.scroller.scrollTo(0, this.scroller.scrollHeight);
            this.firstScroll = true;
        }
    };

    scrollToLastElement = () => {
        if (!isNullOrWhitespace(this.lastScrollElement)) {
            this.lastScrollElement.scrollIntoView();
            setTimeout(() => {
                this.isNeedScroll = false;
            }, 50);

        }
    };

    addToChat = () => {
        const {clientAdmin} = this.state;
        const {addToChat, disputeId, dispute} = this.props;

        if (isNullOrWhitespace(clientAdmin)) {
            this.setState(prevState => ({
                ...prevState,
                errorObj: {
                    ...prevState.errorObj,
                    clientAdmin: "Обязательное поле",
                },
            }),
            );

            return;
        }

        addToChat({
            clientUserId: clientAdmin,
            clientId: dispute[DISPUTE_FIELD.CLIENT_ID],
            disputeId,
            onSuccess: () => {
                this.fetchMessageList();
            },
        });

        this.setState(prevState => ({
            ...prevState,
            errorObj: {
                ...prevState.errorObj,
                clientAdmin: undefined,
            },
        }),
        );
    };

    showApprovedBlock = (isShowApprovedBlock) => {
        this.setState({
            isShowApprovedBlock,
            errorObj: {},
        });
    };

    updateStatusDispute = () => {
        const {approvDescription, errorObj} = this.state;
        const {updateStatusDispute, dispute} = this.props;
        const _errorObj = {...errorObj};

        if (isNullOrWhitespace(approvDescription)) {
            _errorObj.approvDescription = requiredMessage;
            this.setState({errorObj: _errorObj});
            return;
        }
        if (approvDescription.length > 255) {
            _errorObj.approvDescription = "Максимальная длина сообщения 255 символов";
            this.setState({errorObj: _errorObj});
            return;
        }
        updateStatusDispute({
            disputeId: dispute[DISPUTE_FIELD.DISPUTE_ID],
            description: approvDescription,
            disputeStatus: CHAT_LINK_PARAMS.CLOSED.toUpperCase(),
            status: "APPROVED",
        });

        this.setState({errorObj: {}});
    };

    onChangeApprDescription = (e, {value: approvDescription}) => {
        this.setState({approvDescription});
    };

    handleImageFullScreen = ({src}) => {
        return () => {
            this.setState({imageFullScreenSrc: src});
        };
    };

    closeImageFullScreen = () => {
        this.setState({imageFullScreenSrc: null});
    };

    showDisputeInfo = () => {
        this.setState({isOpenDispute: true});
    };

    handleCloseDispute = () => {
        const {updateFieldClientMemberStore} = this.props;

        this.setState({isOpenDispute: false});
        this.fetchMessageList();

        updateFieldClientMemberStore({card: {}});
    };

    onScroll = (e) => {
        const {totalPages, getNewMessageList, disputeId, isChatLoading} = this.props;
        const {pageSize} = this.state;
        const {currentTarget} = e;

        if (!this.isNeedScroll && !isChatLoading && this.firstScroll && currentTarget.scrollTop < 100 && this.currentPage < totalPages) {
            this.currentPage++;
            this.isNeedScroll = true;
            getNewMessageList({disputeId, pageNum: this.currentPage, pageSize});
        }
    };

    renderImageFullScreen() {
        const {imageFullScreenSrc} = this.state;

        return (
            imageFullScreenSrc &&
            <div className="chat__layer">
                <img
                    alt="Изображение"
                    className="chat__image-full-screen"
                    onClick={this.closeImageFullScreen}
                    src={imageFullScreenSrc}
                />
            </div>
        );
    }

    sendLockUserChat = (locked) => {
        const {lockUserChat, chatId, chat} = this.props;

        return () => {
            lockUserChat({chatId, contractorId: chat.contractorId, locked});
        };
    };

    renderChatDocument = (files) => {
        if (!files || !files.length) {
            return null;
        }

        const {images, t} = this.props;

        return files.map(({contentUrl, fileName, fileUuid}, index) => {
            const fileExtension = getFileExtension(fileName);
            const onlyName = fileName ? fileName.split(".")[0] : "";
            const image = images.find(value => (value.fileUuid === fileUuid)) || {};

            if (Chat.IMAGE_EXTENSIONS.includes(fileExtension)) {
                return (
                    <div
                        key={index}
                        className="chat__message-img"
                        onClick={this.handleImageFullScreen(image)}
                    >
                        <Loader
                            className="chat__message-img-loader"
                            active={!image.src}
                            inline="centered"
                            content={t("loader.content")}
                        />
                        {
                            image.src ?
                                <img
                                    alt={fileName}
                                    src={image.src}
                                /> :
                                null
                        }
                    </div>
                );
            }

            return (
                <div
                    key={index}
                    className="chat__message-document"
                >
                    <Icon name={getDocumentIconName(fileExtension)} />
                    <div
                        onClick={() => {
                            this.downloadDocument(contentUrl, fileExtension, onlyName);
                        }}
                        className="chat__images-preview-filename"
                    >
                        {fileName}
                    </div>
                </div>
            );
        });
    };

    downloadDocument = (contentUrl, fileExtension, onlyName) => {
        const {downloadDocument} = this.props;

        const parse = require("url-parse");

        const {query, pathname} = parse(contentUrl);

        downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            extension: fileExtension,
            fileName: onlyName,
            downloadLink: `${pathname}${query}`,
        });
    };

    renderMsgCheck = (isCurrentUserMsg, msg) => {
        if (!isCurrentUserMsg) {
            return null;
        }

        const {
            sessionMessageUploadingStatuses,
        } = this.props;
        const {
            messageId,
            temporaryMessageId,
            read,
        } = msg;

        if (!messageId && !sessionMessageUploadingStatuses[!temporaryMessageId]) {
            return (
                <ClockOutlineIcon
                    color={COLOR.PRIMARY_100}
                    width={20}
                    height={20}
                />
            );
        }

        if (!messageId && sessionMessageUploadingStatuses[temporaryMessageId]?.isError) {
            return (
                <InfoIcon
                    color={COLOR.NEGATIVE_100}
                    width={20}
                    height={20}
                />
            );
        }

        return read ?
            <DoubleCheck color={COLOR.PRIMARY_100} /> :
            <DoneIcon color={COLOR.PRIMARY_100} />;
    };

    renderSystemMsg = (item) => {
        return (
            <div className="chat__system-message-container">
                <div className="chat__system-message">
                    <NaimixLogo
                        className="chat__system-message-logo"
                    />
                    <span>
                        {item.content}
                    </span>
                </div>
            </div>
        );
    };

    renderMsgBody = (item) => {
        const isCurrentUserMsg = item.clientUserId === this.currentClientUserId;

        return (
            <div className={isCurrentUserMsg ? "chat__message chat__message_my" : "chat__message"}>
                <div className="chat__message-header">
                    <div className="chat__message-header-name-container">
                        {
                            item.admin ?
                                <NaimixLogo
                                    width={20}
                                    height={20}
                                    className="me-2"
                                /> :
                                <UserIcon
                                    width={20}
                                    height={20}
                                    className="me-2"
                                />
                        }
                    </div>
                    <div className="chat__message-header-name">
                        {item.lastName ? `${item.lastName} ${item.firstName}` : item.fullName}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="chat__message-header-date">
                            {
                                dateFormat(
                                    item.temporaryMessageId ?
                                        item.dateTimeCreate :
                                        convertUtcToLocal(item.dateTimeCreate),
                                )
                            }
                        </div>
                        {this.renderMsgCheck(isCurrentUserMsg, item)}
                    </div>
                </div>
                {this.renderChatDocument(item.files)}
                {
                    item.content ?
                        <div className="chat__message-text">
                            {item.content}
                        </div> :
                        null
                }
            </div>
        );
    };

    mapMessageList = () => {
        const {
            messageList,
            chat,
        } = this.props;

        if (!messageList.length && this.currentUserId === chat.orderManagerId) {
            return (
                <div className="chat__body_empty">
                    <Text
                        level="5"
                        color={COLOR.SECONDARY_45}
                    >
Начните чат с исполнителем
                    </Text>
                </div>
            );
        }

        return messageList.map((item, idx) => {
            return (
                <div
                    className="chat__body-row"
                    key={item.messageId}
                >
                    {
                        idx === 18 ?
                            <div
                                ref={(el) => {
                                    this.lastScrollElement = el;
                                }}
                            >
                            </div> :
                            null
                    }
                    {
                        isNullOrWhitespace(item.system) ?
                            this.renderMsgBody(item) :
                            this.renderSystemMsg(item)
                    }
                </div>
            );
        });
    };

    renderSendMsgBlock() {
        const {msg} = this.state;
        const {t} = this.props;

        return (
            <div className="chat__footer">
                <div className="chat__footer-container">
                    <DefaultDropzone
                        multiple={false}
                        accept=".raw, .tiff, .tif, .bmp, .gif, .png, .jpeg, .jpg, .odt, .doc, .docx, .pdf"
                        onDropFiles={this.uploadFiles}
                    >
                        <NmButton
                            size="xl"
                            onlyIcon
                            color="grey"
                            icon={
                                <AddIcon
                                    width={16}
                                    height={16}
                                />
                            }
                        />
                    </DefaultDropzone>
                    <NmTextareaV2
                        minRows={2}
                        maxRows={4}
                        className="chat__footer-textarea"
                        placeholder={t("chat.enter-message")}
                        name="msg"
                        maxLength="500"
                        value={msg}
                        onChange={this.handleOnChangeMessage}
                        onKeyDown={this.handleKeyDown}
                    />
                    <NmButton
                        size="xl"
                        color="grey"
                        onlyIcon
                        onClick={this.onSubmitMessage}
                        icon={
                            <IconChevron
                                width={24}
                                height={24}
                                className="chat__footer-send"
                            />
                        }
                    />
                </div>
            </div>
        );
    }

    renderModalAddToChatBlock() {
        const {
            clientAdmin,
            errorObj,
        } = this.state;
        const {clientUserList, t} = this.props;

        return (
            <NmModal
                size="sm"
                header={
                    <NmTitle size="lg">
                        {t("chat.add-member")}
                    </NmTitle>
                }
                onClose={this.onCloseModalAddToChat}
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        onClose={this.onCloseModalAddToChat}
                        submit={this.addToChat}
                        cancelBtnContent={t("button.cancel")}
                        submitBtnContent={t("chat.invite-button")}
                    />
                }
            >
                <NmForm addMargin>
                    <NmDropdownV2
                        size="xl"
                        noResultsMessage={t("no-search-result.content")}
                        onSearchChange={this.onSearchChangeClientAdmin}
                        name="clientAdmin"
                        label={t("chat.client-admin")}
                        onChange={this.handleOnChangeDisputeChat}
                        className="new-form_block_content_dropdown"
                        placeholder={t("chat.enter-admin-name")}
                        search
                        value={clientAdmin}
                        options={clientUserList}
                        error={errorObj?.clientAdmin}
                    />
                    <Text level="3">
                        {t("chat.admin-notification")}
                    </Text>
                </NmForm>
            </NmModal>
        );
    }

    renderModalApproveBlock() {
        const {approvDescription, errorObj} = this.state;
        const {t} = this.props;

        return (
            <NmModal
                size="sm"
                onClose={() => {
                    this.showApprovedBlock(false);
                }}
                header={
                    <NmTitle size="lg">
                        Закрытие спора
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        submitBtnContent={t("button.confirm")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={() => {
                            this.showApprovedBlock(false);
                        }}
                        submit={this.updateStatusDispute}
                    />
                }
            >
                <NmForm>
                    <NmTextareaV2
                        minRows={2}
                        placeholder={t("disputes-new.indicate-result-dispute")}
                        onChange={this.onChangeApprDescription}
                        value={approvDescription}
                        error={errorObj.approvDescription}
                    />
                </NmForm>
            </NmModal>
        );
    }

    get isShowMsgBlock() {
        const {
            dispute,
            chat,
            orderStatus,
            orderManagerId,
        } = this.props;

        const {
            locked,
            contractorStatus,
        } = chat;

        if (this.isSimpleChat) {
            if (
                !locked &&
                ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) &&
                    orderManagerId === this.currentClientUserId) &&
                orderStatus !== ORDER_STATUS.CLOSED &&
                contractorStatus !== STATUS.COMPLETED
            ) {
                return true;
            }
        } else {
            if ((isNullOrWhitespace(dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT])
                && ![NM_COORDINATOR].includes(this.role)
                && (dispute[DISPUTE_FIELD.ACTIVE] || [ADMIN, NM_MANAGER, NM_OPERATOR].includes(this.role)))) {
                return true;
            }
        }

        return false;
    }

    render() {
        const {
            isOpenDispute,
            isShowAddBlock,
            isShowApprovedBlock,
        } = this.state;
        const {
            disputeId,
            orderId,
            chatId,
            onClickBackToChatList,
            fullName,
        } = this.props;

        return (
            <>
                {
                    isOpenDispute ?
                        <DisputesNew
                            disputeId={disputeId}
                            isFromOrderCard={this.isOrderCard}
                            isFromChat={true}
                            orderId={orderId}
                            handleClose={this.handleCloseDispute}
                        /> :
                        null
                }
                {this.renderImageFullScreen()}
                <div className="chat">
                    <ChatHeader
                        fullName={fullName}
                        onClickBackToChatList={onClickBackToChatList}
                        role={this.role}
                        isOpenChat={Boolean(disputeId || chatId)}
                        currentClientUserId={this.currentClientUserId}
                        showApprovedBlock={this.showApprovedBlock}
                        handleOnChangeDisputeChat={this.handleOnChangeDisputeChat}
                        showDisputeInfo={this.showDisputeInfo}
                        sendLockUserChat={this.sendLockUserChat}
                        isSimpleChat={this.isSimpleChat}
                        isShowAddBlock={this.state.isShowAddBlock}
                    />
                    {
                        isShowAddBlock ?
                            this.renderModalAddToChatBlock() :
                            null
                    }
                    <div
                        className="chat__body"
                        onScroll={this.onScroll}
                        ref={(el) => {
                            this.scroller = el;
                        }}
                    >
                        {this.mapMessageList()}
                    </div>
                    {this.renderPreviewFiles()}
                    {
                        isShowApprovedBlock ?
                            this.renderModalApproveBlock() :
                            null
                    }
                    {
                        this.isShowMsgBlock ?
                            this.renderSendMsgBlock() :
                            null
                    }
                </div>
            </>
        );
    }
}


export default connect(
    state => ({
        messageList: chatMessageListSelector(state),
        dispute: disputeSelector(state),
        disputeCauses: causesDisputeDictsOptionsSelector(state),
        isImageSuccessAdded: fileStoreIsSuccessAddSelector(state),
        imageProperties: fileStoreDisputeImageSelector(state),
        needScroll: chatMessageListNeedScrollSelector(state),
        clientUserList: clientMemberOptionsSelector(state),
        isAddToChatSuccess: chatAddToChatSuccessSelector(state),
        changeStatusSuccess: disputeChangeStatusSuccessSelector(state),
        isDisputeLoading: disputeLoadingSelector(state),
        totalCount: chatMessageListTotalCountSelector(state),
        totalPages: chatTotalPagesSelector(state),
        scrollToLastElement: chatScrollToLastElementSelector(state),
        isChatLoading: chatLoadingSelector(state),
        chat: chatSelector(state),
        images: fileStoreImagesSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        sessionMessageUploadingStatuses: sessionMessageUploadingStatusesSelector(state),
    }

    ),
    {
        getMessageList,
        getDisputeMessageList,
        chatAddMessage,
        getDisputeById,
        getDisputeCausesDict,
        addDocumentToChat,
        downloadDocument,
        updateFieldFilesStore,
        updateFieldChat,
        addToChat,
        getClientMemberList,
        updateStatusDispute,
        updateFieldDisputeStore,
        getNewMessageList,
        updateFieldClientMemberStore,
        getChatFile,
        getChatById,
        lockUserChat,
        updateChatMessages,
    },
)
(withTranslation()(Chat));
