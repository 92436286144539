import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getClientCardInfo} from "./bff/clients/info/actionCreators";
import {currentChatSelector} from "./chat";

import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT_FILE_TYPES} from "../constants/clientList";
import {CLIENT} from "../constants/messages";

const controller = "/filestore";
//*  TYPES  *//

const FILE_STORE_OBJECT_FILES_READ_ERROR = "FILE_STORE_OBJECT_FILES_READ_ERROR";

const FILE_STORE_CLIENT_FILE_ADD_REQUEST = "FILE_STORE_CLIENT_FILE_ADD_REQUEST";
const FILE_STORE_CLIENT_FILE_ADD_ERROR = "FILE_STORE_CLIENT_FILE_ADD_ERROR";

const FILE_STORE_CLEAR_REDUX_STORE = "FILE_STORE_CLEAR_REDUX_STORE";

const FILE_STORE_CLIENT_FILES_READ_STAMP_REQUEST = "FILE_STORE_CLIENT_FILES_READ_STAMP_REQUEST";
const FILE_STORE_CLIENT_FILES_READ_STAMP_SUCCESS = "FILE_STORE_CLIENT_FILES_READ_STAMP_SUCCESS";
const FILE_STORE_CLIENT_FILES_READ_STAMP_ERROR = "FILE_STORE_CLIENT_FILES_READ_STAMP_ERROR";

const FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST = "FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST";
const FILE_STORE_CONTRACTOR_FILES_READ_SCAN_SUCCESS = "FILE_STORE_CONTRACTOR_FILES_READ_SCAN_SUCCESS";
const FILE_STORE_CONTRACTOR_FILES_READ_SCAN_ERROR = "FILE_STORE_CONTRACTOR_FILES_READ_SCAN_ERROR";

const FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_REQUEST = "FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_REQUEST";
const FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_SUCCESS = "FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_SUCCESS";
const FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_ERROR = "FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_ERROR";

const FILE_STORE_CLIENT_FILES_READ_SIGNATURE_REQUEST = "FILE_STORE_CLIENT_FILES_READ_SIGNATURE_REQUEST";
const FILE_STORE_CLIENT_FILES_READ_SIGNATURE_SUCCESS = "FILE_STORE_CLIENT_FILES_READ_SIGNATURE_SUCCESS";
const FILE_STORE_CLIENT_FILES_READ_SIGNATURE_ERROR = "FILE_STORE_CLIENT_FILES_READ_SIGNATURE_ERROR";

const FILE_STORE_CLIENT_FILES_ADD_SCAN_REQUEST = "FILE_STORE_CLIENT_FILES_ADD_SCAN_REQUEST";

const FILE_STORE_CLIENT_FILES_READ_LIST_SUCCESS = "FILE_STORE_CLIENT_FILES_READ_LIST_SUCCESS";
const FILE_STORE_CLIENT_FILES_READ_LIST_ERROR = "FILE_STORE_CLIENT_FILES_READ_LIST_ERROR";
const FILE_STORE_CLIENT_FILES_READ_LIST_REQUEST = "FILE_STORE_CLIENT_FILES_READ_LIST_REQUEST";

const FILE_STORE_DISPUTE_UPlOAD_IMAGE_REQUEST = "FILE_STORE_DISPUTE_UPlOAD_IMAGE_REQUEST";
const FILE_STORE_DISPUTE_UPlOAD_IMAGE_SUCCESS = "FILE_STORE_DISPUTE_UPlOAD_IMAGE_SUCCESS";
const FILE_STORE_DISPUTE_UPlOAD_IMAGE_ERROR = "FILE_STORE_DISPUTE_UPlOAD_IMAGE_ERROR";

const BFF_CROWD_CHAT_FILE_SAVE_REQUEST = "BFF_CROWD_CHAT_FILE_SAVE_REQUEST";
const BFF_CROWD_CHAT_FILE_SAVE_SUCCESS = "BFF_CROWD_CHAT_FILE_SAVE_SUCCESS";
const BFF_CROWD_CHAT_FILE_SAVE_ERROR = "BFF_CROWD_CHAT_FILE_SAVE_ERROR";

const FILE_STORE_DISPUTE_GET_FILES_REQUEST = "FILE_STORE_DISPUTE_GET_FILES_REQUEST";
const FILE_STORE_DISPUTE_GET_FILES_SUCCESS = "FILE_STORE_DISPUTE_GET_FILES_SUCCESS";
const FILE_STORE_DISPUTE_GET_FILES_ERROR = "FILE_STORE_DISPUTE_GET_FILES_ERROR";

const FILE_STORE_UPDATE_FIELD_STORE = "FILE_STORE_UPDATE_FIELD_STORE";

const FILE_STORE_DISPUTE_DELETE_FILE_REQUEST = "FILE_STORE_DISPUTE_DELETE_FILE_REQUEST";
const FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS = "FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS";
const FILE_STORE_DISPUTE_DELETE_FILE_ERROR = "FILE_STORE_DISPUTE_DELETE_FILE_ERROR";

const FILE_STORE_CLIENT_DELETE_FILE_REQUEST = "FILE_STORE_CLIENT_DELETE_FILE_REQUEST";
const FILE_STORE_CLIENT_DELETE_FILE_SUCCESS = "FILE_STORE_CLIENT_DELETE_FILE_SUCCESS";
const FILE_STORE_CLIENT_DELETE_FILE_ERROR = "FILE_STORE_CLIENT_DELETE_FILE_ERROR";

const FILE_STORE_UPDATE_CONTRACTOR_SCAN_FIELD_STORE = "FILE_STORE_UPDATE_CONTRACTOR_SCAN_FIELD_STORE";

const FILE_STORE_GET_USER_INSTRUCTION_FILE_REQUEST = "FILE_STORE_GET_USER_INSTRUCTION_FILE_REQUEST";
const FILE_STORE_GET_USER_INSTRUCTION_FILE_SUCCESS = "FILE_STORE_GET_USER_INSTRUCTION_FILE_SUCCESS";
const FILE_STORE_GET_USER_INSTRUCTION_FILE_ERROR = "FILE_STORE_GET_USER_INSTRUCTION_FILE_ERROR";

const FILE_STORE_GET_CHAT_FILE_REQUEST = "FILE_STORE_GET_CHAT_FILE_REQUEST";
const FILE_STORE_GET_CHAT_FILE_SUCCESS = "FILE_STORE_GET_CHAT_FILE_SUCCESS";
const FILE_STORE_GET_CHAT_FILE_ERROR = "FILE_STORE_GET_CHAT_FILE_ERROR";

const FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_REQUEST = "FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_REQUEST";
const FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_SUCCESS = "FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_SUCCESS";
const FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_ERROR = "FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_ERROR";

const FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_REQUEST = "FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_REQUEST";
const FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_ERROR = "FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_ERROR";

const FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_REQUEST = "FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_REQUEST";
const FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_SUCCESS = "FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_SUCCESS";
const FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_ERROR = "FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_ERROR";

const FILE_STORE_CLIENT_FILES_READ_LOGO_REQUEST = "FILE_STORE_CLIENT_FILES_READ_LOGO_REQUEST";
const FILE_STORE_CLIENT_FILES_READ_LOGO_SUCCESS = "FILE_STORE_CLIENT_FILES_READ_LOGO_SUCCESS";
const FILE_STORE_CLIENT_FILES_READ_LOGO_ERROR = "FILE_STORE_CLIENT_FILES_READ_LOGO_ERROR";

const FILE_STORE_GET_FILE_REQUEST = "FILE_STORE_GET_FILE_REQUEST";
const FILE_STORE_GET_FILE_SUCCESS = "FILE_STORE_GET_FILE_SUCCESS";
const FILE_STORE_GET_FILE_ERROR = "FILE_STORE_GET_FILE_ERROR";

const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST";
const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS";
const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR";

const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_REQUEST = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_REQUEST";
const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_SUCCESS = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_SUCCESS";
const ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_ERROR = "ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_ERROR";

const GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST = "GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST";
const GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS = "GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS";
const GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR = "GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR";

const CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_REQUEST = "CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_REQUEST";
const CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_SUCCESS = "CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_SUCCESS";
const CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_ERROR = "CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_ERROR";

const USER_AVATAR_ADD_REQUEST = "USER_AVATAR_ADD_REQUEST";
const USER_AVATAR_ADD_SUCCESS = "USER_AVATAR_ADD_SUCCESS";
const USER_AVATAR_ADD_ERROR = "USER_AVATAR_ADD_ERROR";

const USER_AVATAR_DELETE_REQUEST = "USER_AVATAR_DELETE_REQUEST";
const USER_AVATAR_DELETE_SUCCESS = "USER_AVATAR_DELETE_SUCCESS";
const USER_AVATAR_DELETE_ERROR = "USER_AVATAR_DELETE_ERROR";

const USER_AVATAR_GET_REQUEST = "USER_AVATAR_GET_REQUEST";
const USER_AVATAR_GET_SUCCESS = "USER_AVATAR_GET_SUCCESS";
const USER_AVATAR_GET_ERROR = "USER_AVATAR_GET_ERROR";

const USER_AVATAR_GET_THUMBNAIL_REQUEST = "USER_AVATAR_GET_THUMBNAIL_REQUEST";
const USER_AVATAR_GET_THUMBNAIL_SUCCESS = "USER_AVATAR_GET_THUMBNAIL_SUCCESS";
const USER_AVATAR_GET_THUMBNAIL_ERROR = "USER_AVATAR_GET_THUMBNAIL_ERROR";

const USER_AVATAR_GET_LIST_THUMBNAILS_REQUEST = "USER_AVATAR_GET_LIST_THUMBNAILS_REQUEST";
const USER_AVATAR_GET_LIST_THUMBNAILS_SUCCESS = "USER_AVATAR_GET_LIST_THUMBNAILS_SUCCESS";
const USER_AVATAR_GET_LIST_THUMBNAILS_ERROR = "USER_AVATAR_GET_LIST_THUMBNAILS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    file: {},
    error: null,
    progress: false,
    newObjectId: false,
    progressAdd: false,
    isSuccessAdd: false,
    fileUrl: "",
    clientSignature: {},
    clientStamp: {},
    clientLogoByClientId: {},
    contractorScanPersonalInfo: {},
    contractorScanRegistration: {},
    contractorScanMigrationCard: {},
    contractorScanTemporaryHousingFirst: {},
    contractorScanTemporaryHousingSecond: {},
    documentScanList: [],
    documentScanTotalCount: [],
    pageDataScan: {},
    progressScanPersonalInfo: false,
    disputeImages: [],
    isSuccessDelete: false,
    imageProperties: {},
    images: [],
    image: {},
    contractorScanObj: {},
    contractorOriginalPreviousScanObj: {},
    progressOrderDocument: false,
    contractorOriginalScanObj: {},
    eventLogContractorOriginalScanObj: {},
    getAvatarProgress: false,
    avatarActionProgress: false,
    avatarBase64ImagesList: {},
    avatarBase64Image: "",
    avatarBase64ImageOriginal: "",
    progressChatFilesIds: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_REQUEST:
            return {
                ...state,
                progressOrderDocument: true,
            };
        case FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_ERROR:
        case FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_SUCCESS:
            return {
                ...state,
                progressOrderDocument: false,
            };
        case FILE_STORE_GET_CHAT_FILE_REQUEST:
            const fileId = payload.data?.fileId || payload.data?.fileUuid;

            return {
                ...state,
                progressChatFilesIds: [
                    ...state.progressChatFilesIds,
                    fileId,
                ],
            };
        case FILE_STORE_GET_CHAT_FILE_SUCCESS:
            const {fileInfo, nameField} = payload;
            const loadedFileId = fileInfo.fileId || fileInfo.fileUuid;

            return {
                ...state,
                [nameField]: nameField === "image" ? fileInfo : [...state.images, fileInfo],
                progressChatFilesIds: state.progressChatFilesIds.filter(item => item !== loadedFileId),
            };
        case FILE_STORE_UPDATE_CONTRACTOR_SCAN_FIELD_STORE:
            const {contractorScanObj: oldContractorScanObj} = state;

            const {
                fileType,
                base64,
            } = payload;

            return {
                ...state,
                contractorScanObj: {
                    ...oldContractorScanObj,
                    [fileType]: base64,
                },
            };
        case FILE_STORE_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_REQUEST:
        case FILE_STORE_CLIENT_FILES_READ_STAMP_REQUEST:
        case FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST:
        case GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case FILE_STORE_CLIENT_FILES_READ_SIGNATURE_REQUEST:
        case FILE_STORE_DISPUTE_GET_FILES_REQUEST:
        case FILE_STORE_DISPUTE_DELETE_FILE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case FILE_STORE_CLIENT_FILES_READ_LIST_REQUEST:
            return {
                ...state,
                pageDataScan: payload,
            };
        case FILE_STORE_CLEAR_REDUX_STORE:
            return {
                ...initial,
                avatarBase64Image: state.avatarBase64Image,
            };
        case FILE_STORE_CLIENT_FILES_READ_LIST_SUCCESS:
            const {
                documents: documentScanList,
                totalCount: documentScanTotalCount,
            } = payload;
            return {
                ...state,
                documentScanList,
                documentScanTotalCount,
            };
        case FILE_STORE_CLIENT_FILE_ADD_REQUEST:
        case FILE_STORE_DISPUTE_UPlOAD_IMAGE_REQUEST:
        case BFF_CROWD_CHAT_FILE_SAVE_REQUEST:
        case FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case USER_AVATAR_GET_REQUEST:
        case USER_AVATAR_GET_THUMBNAIL_REQUEST:
        case USER_AVATAR_GET_LIST_THUMBNAILS_REQUEST:
            return {
                ...state,
                getAvatarProgress: true,
            };
        case USER_AVATAR_ADD_REQUEST:
        case USER_AVATAR_DELETE_REQUEST:
            return {
                ...state,
                avatarActionProgress: true,
            };
        case FILE_STORE_CLIENT_FILES_READ_SIGNATURE_SUCCESS:
            return {
                ...state,
                clientSignature: payload,
            };
        case FILE_STORE_CLIENT_FILES_READ_STAMP_SUCCESS:
            return {
                ...state,
                clientStamp: payload,
            };
        case FILE_STORE_CLIENT_FILES_READ_LOGO_SUCCESS:
            return {
                ...state,
                clientLogoByClientId: {
                    ...state.clientLogoByClientId,
                    ...payload,
                },
            };
        case FILE_STORE_DISPUTE_GET_FILES_SUCCESS:
            return {
                ...state,
                disputeImages: payload,
            };
        case CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_SUCCESS:
        case FILE_STORE_CONTRACTOR_FILES_READ_SCAN_SUCCESS:
        case GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS:
            const {contractorFileType, result, field = "contractorScanObj"} = payload;

            return {
                ...state,
                [field]: {
                    ...state[field],
                    [contractorFileType]: result,
                },
                progress: false,
            };
        case FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS:
            return {
                ...state,
                isSuccessDelete: true,
            };
        case FILE_STORE_DISPUTE_UPlOAD_IMAGE_SUCCESS:
        case BFF_CROWD_CHAT_FILE_SAVE_SUCCESS:
            return {
                ...state,
                progressAdd: false,
                isSuccessAdd: true,
                imageProperties: payload,
            };
        case FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_SUCCESS:
            return {
                ...state,
                progressAdd: false,
                progress: false,
                isSuccessAdd: true,
            };
        case USER_AVATAR_GET_SUCCESS:
            return {
                ...state,
                avatarBase64ImageOriginal: payload.base64str,
                getAvatarProgress: false,
            };
        case USER_AVATAR_GET_THUMBNAIL_SUCCESS:
            return {
                ...state,
                avatarBase64Image: payload.base64str,
                getAvatarProgress: false,
            };
        case USER_AVATAR_GET_LIST_THUMBNAILS_SUCCESS:
            return {
                ...state,
                avatarBase64ImagesList: {
                    ...state.avatarBase64ImagesList,
                    ...payload,
                },
                getAvatarProgress: false,
            };
        case USER_AVATAR_ADD_SUCCESS:
        case USER_AVATAR_DELETE_SUCCESS:
            return {
                ...state,
                avatarActionProgress: false,
            };
        case FILE_STORE_CLIENT_FILE_ADD_ERROR:
        case FILE_STORE_DISPUTE_UPlOAD_IMAGE_ERROR:
        case BFF_CROWD_CHAT_FILE_SAVE_ERROR:
        case FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_ERROR:
            return {
                ...state,
                error: payload,
                progressAdd: false,
                isSuccessAdd: false,
                progress: false,
            };
        case CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_ERROR:
        case FILE_STORE_CLIENT_FILES_READ_SIGNATURE_ERROR:
        case FILE_STORE_OBJECT_FILES_READ_ERROR:
        case FILE_STORE_DISPUTE_GET_FILES_ERROR:
        case FILE_STORE_DISPUTE_DELETE_FILE_ERROR:
        case FILE_STORE_CONTRACTOR_FILES_READ_SCAN_ERROR:
        case GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case FILE_STORE_CLIENT_FILES_READ_STAMP_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case USER_AVATAR_GET_ERROR:
        case USER_AVATAR_GET_THUMBNAIL_ERROR:
        case USER_AVATAR_GET_LIST_THUMBNAILS_ERROR:
            return {
                ...state,
                getAvatarProgress: false,
            };
        case USER_AVATAR_ADD_ERROR:
        case USER_AVATAR_DELETE_ERROR:
            return {
                ...state,
                avatarActionProgress: false,
            };
        case ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_SUCCESS:
        case ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_ERROR:
            return {
                ...state,
                progressAdd: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export function addDocumentToChat(payload) {
    return {
        type: FILE_STORE_DISPUTE_UPlOAD_IMAGE_REQUEST,
        payload,
    };
}

export function addDocumentToCrowdChat(payload) {
    return {
        type: BFF_CROWD_CHAT_FILE_SAVE_REQUEST,
        payload,
    };
}

export function deleteDisputeFile(payload) {
    return {
        type: FILE_STORE_DISPUTE_DELETE_FILE_REQUEST,
        payload,
    };
}

export function getDisputeFiles(payload) {
    return {
        type: FILE_STORE_DISPUTE_GET_FILES_REQUEST,
        payload,
    };
}

export function addClientFileToFileStore(payload) {
    return {
        type: FILE_STORE_CLIENT_FILE_ADD_REQUEST,
        payload,
    };
}

export function addContractorFileScanFileToFileStore(payload) {
    return {
        type: FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_REQUEST,
        payload,
    };
}

export function getFileClientStamp(payload) {
    return {
        type: FILE_STORE_CLIENT_FILES_READ_STAMP_REQUEST,
        payload,
    };
}

export function getFileClientSignature(payload) {
    return {
        type: FILE_STORE_CLIENT_FILES_READ_SIGNATURE_REQUEST,
        payload,
    };
}

export function getFileClientLogo(payload) {
    return {
        type: FILE_STORE_CLIENT_FILES_READ_LOGO_REQUEST,
        payload,
    };
}

export function getFileContractorScan(payload) {
    return {
        type: FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST,
        payload,
    };
}

export function clearFileStoreReduxStore() {
    return {
        type: FILE_STORE_CLEAR_REDUX_STORE,
    };
}

export function getClientAgencyContractScanPage(payload) {
    return {
        type: FILE_STORE_CLIENT_FILES_READ_LIST_REQUEST,
        payload,
    };
}

export function updateFieldFilesStore(payload) {
    return {
        type: FILE_STORE_UPDATE_FIELD_STORE,
        payload,
    };
}

export function updateContractorScanFieldFilesStore(fileType, base64) {
    return {
        type: FILE_STORE_UPDATE_CONTRACTOR_SCAN_FIELD_STORE,
        payload: {
            fileType,
            base64,
        },
    };
}


export function deleteClientFile(payload) {
    return {
        type: FILE_STORE_CLIENT_DELETE_FILE_REQUEST,
        payload,
    };
}

export function getChatFile(data, saveType) {
    return {
        type: FILE_STORE_GET_CHAT_FILE_REQUEST,
        payload: {
            data,
            saveType,
        },
    };
}

export function getUserInstructionFile(payload) {
    return {
        type: FILE_STORE_GET_USER_INSTRUCTION_FILE_REQUEST,
        payload,
    };
}

export function getOrderAttachmentsFile(payload) {
    return {
        type: FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_REQUEST,
        payload,
    };
}

export function saveOrderAttachmentsFile(payload) {
    return {
        type: FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_REQUEST,
        payload,
    };
}

export function deleteOrderAttachmentsFile(payload) {
    return {
        type: FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_REQUEST,
        payload,
    };
}

export function getFile(payload) {
    return {
        type: FILE_STORE_GET_FILE_REQUEST,
        payload,
    };
}

export function addFileContractorAdditionalScan(payload) {
    return {
        type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST,
        payload,
    };
}

export function getContractorAdditionalDocumentFile(payload) {
    return {
        type: CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_REQUEST,
        payload,
    };
}

export function addFileContractorAdditionalScanV2(payload) {
    return {
        type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_REQUEST,
        payload,
    };
}

export function getFileContractorAdditionalScan(payload) {
    return {
        type: GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST,
        payload,
    };
}

export function addUserAvatar(payload) {
    return {
        type: USER_AVATAR_ADD_REQUEST,
        payload,
    };
}

export function deleteUserAvatar(payload) {
    return {
        type: USER_AVATAR_DELETE_REQUEST,
        payload,
    };
}

export function getUserAvatar(payload) {
    return {
        type: USER_AVATAR_GET_REQUEST,
        payload,
    };
}

export function getUserAvatarThumbnail(payload) {
    return {
        type: USER_AVATAR_GET_THUMBNAIL_REQUEST,
        payload,
    };
}

export function getUserAvatarListThumbnails(payload) {
    return {
        type: USER_AVATAR_GET_LIST_THUMBNAILS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const fileStoreObjectsFileSelector = state => state.fileStore;

export const fileClientLogoSelector = createSelector(fileStoreObjectsFileSelector, ({clientLogoByClientId}) => clientLogoByClientId);

export const fileContractorScanObjSelector = createSelector(fileStoreObjectsFileSelector, ({contractorScanObj}) => contractorScanObj);

export const fileContractorOriginalScanObjSelector = createSelector(fileStoreObjectsFileSelector, ({contractorOriginalScanObj}) => contractorOriginalScanObj);
export const fileContractorOriginalScanBase64ObjSelector = createSelector(fileStoreObjectsFileSelector, ({contractorOriginalScanObj}) => {
    return Object.keys(contractorOriginalScanObj).reduce((result, fileType) => {
        return {
            ...result,
            [fileType]: [
                {
                    base64str: contractorOriginalScanObj[fileType].base64str,
                    type: "image/*",
                },
            ],
        };
    }, {});
});
export const fileContractorOriginalPreviousScanObjSelector = createSelector(fileStoreObjectsFileSelector, ({contractorOriginalPreviousScanObj}) => contractorOriginalPreviousScanObj);

export const fileContractorScanProgressSelector = createSelector(fileStoreObjectsFileSelector, ({progress}) => progress);
export const fileContractorScanProgressAddSelector = createSelector(fileStoreObjectsFileSelector, ({progressAdd}) => progressAdd);
export const fileStoreDisputeImageListSelector = createSelector(fileStoreObjectsFileSelector, ({disputeImages}) => {
    return disputeImages.map(item => ({
        ...item,
        isRemote: true,
        type: "image/*",
        name: item.fileName,
    }));
});

export const fileStoreIsSuccessAddSelector = createSelector(fileStoreObjectsFileSelector, ({isSuccessAdd}) => isSuccessAdd);

export const fileStoreIsSuccessDeleteSelector = createSelector(fileStoreObjectsFileSelector, ({isSuccessDelete}) => isSuccessDelete);


export const fileStoreDisputeImageSelector = createSelector(fileStoreObjectsFileSelector, ({imageProperties}) => imageProperties);

export const fileStoreImagesSelector = createSelector(fileStoreObjectsFileSelector, ({images}) => images);
export const fileStoreOrderDocumentProgressSelector = createSelector(fileStoreObjectsFileSelector, ({progressOrderDocument}) => progressOrderDocument);

export const getAvatarProgressSelector = createSelector(fileStoreObjectsFileSelector, ({getAvatarProgress}) => getAvatarProgress);
export const avatarActionProgressSelector = createSelector(fileStoreObjectsFileSelector, ({avatarActionProgress}) => avatarActionProgress);
export const avatarBase64ImageSelector = createSelector(fileStoreObjectsFileSelector, ({avatarBase64Image}) => avatarBase64Image);
export const avatarBase64ImageOriginalSelector = createSelector(fileStoreObjectsFileSelector, ({avatarBase64ImageOriginal}) => avatarBase64ImageOriginal);
export const avatarBase64ImagesListSelector = createSelector(fileStoreObjectsFileSelector, ({avatarBase64ImagesList}) => avatarBase64ImagesList);
export const progressChatFilesIdsSelector = createSelector(fileStoreObjectsFileSelector, ({progressChatFilesIds}) => progressChatFilesIds);

//*  SAGA  *//
export const addDocumentToChatStoreSaga = function* ({payload}) {
    try {
        const {
            disputeId,
            chatId,
            file: data,
            isFromChat,
            isSimpleChat,
            getResult = () => {
            },
        } = payload;

        const state = yield select();
        const currentChat = currentChatSelector(state);

        const url = `${controller}/${isSimpleChat ? "chatFiles" : "disputeFiles"}${isFromChat && !isSimpleChat ? "/chat" : ""}/add`;
        const crmUrl = `${controller}/crm/chat/add`;
        const result = yield request.put(currentChat.isNamemixChat ? crmUrl : url, data, {
            params: {
                chatId: chatId || currentChat.chatId,
                disputeId,
            },
            ...getMultipartHeaders(),
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_DISPUTE_UPlOAD_IMAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: FILE_STORE_DISPUTE_UPlOAD_IMAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_DISPUTE_UPlOAD_IMAGE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/messages/file/save
// Сохранить файл в крауд чате
export const addDocumentToCrowdChatSaga = function* ({payload}) {
    try {
        const {
            chatId,
            clientId,
            contractorId,
            fileName,
            file: data,
            getResult = () => {
            },
        } = payload;

        const state = yield select();
        const currentChat = currentChatSelector(state);

        const result = yield request.bff.post("/adm/clients/client-card/crowd-tasks/chats/messages/file/save", data, {
            params: {
                chatId: chatId || currentChat.chatId,
                clientId,
                contractorId,
            },
            ...getMultipartHeaders(),
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_CHAT_FILE_SAVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const fileInfo = {
            ...result,
            fileName,
        };

        getResult(fileInfo);

        yield put({
            type: BFF_CROWD_CHAT_FILE_SAVE_SUCCESS,
            payload: fileInfo,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_CHAT_FILE_SAVE_ERROR,
            payload: error,
        });
    }
};

//curl -X POST --header 'Content-Type: multipart/form-data'api/filestore/clientFiles/add?clientId=ac073d44-48a9-4c85-add8-1e0bdda418f5&clientFileType=SIGNATURE'
export const addFileClientToFileStoreSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            clientId,
            clientFileType,
            isHiddenToaster,
            file: data,
            onSuccess,
        } = payload;

        const {errorMessage} = yield request.post(`${controller}/clientFiles/add`, data, {
            params: {
                clientId,
                clientFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        !isHiddenToaster && toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        if (onSuccess) {
            onSuccess();

            return;
        }

        switch (clientFileType) {
            case CLIENT_FILE_TYPES.STAMP:
                yield put(getFileClientStamp(clientId));
                break;
            case CLIENT_FILE_TYPES.SIGNATURE:
                yield put(getFileClientSignature(clientId));
                break;
            case CLIENT_FILE_TYPES.LOGO_THUMBNAIL:
                yield put(getFileClientLogo({
                    clientId,
                    clientFileType: CLIENT_FILE_TYPES.LOGO_THUMBNAIL,
                }));
                yield put(getClientCardInfo({clientId}));
                break;
            //no default
        }

    } catch (error) {
    }
};

export const addFileContractorFileStoreSaga = function* ({payload}) {
    const {
        contractorId,
        contractorFileType,
        "file": data,
        actualData,
        onSuccess = () => {
        },
        onError,
        isGetAfter = true,
    } = payload;

    try {
        const {errorMessage} = yield request.post(`${controller}/contractorFiles/add`, data, {
            params: {
                contractorId,
                actualData,
                contractorFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError();
            }

            return {
                done: true,
            };
        }

        onSuccess();


        if (isGetAfter) {
            yield put({
                type: FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST,
                payload,
            });
        }
    } catch (error) {
        if (onError) {
            onError();
        }
    }
};

//POST /api/filestore/documentFiles/addClientAgencyContractScan
export const addClientAgencyContractScanSaga = function* (action) {
    try {
        const {payload} = action;
        const {clientId, contractorFileType, "file": data} = payload;

        const {errorMessage} = yield request.post(`${controller}/documentFiles/addClientAgencyContractScan`, data, {
            params: {
                clientId,
                contractorFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put(getClientAgencyContractScanPage({
            clientId,
            latestOnly: true,
            pageNum: 1,
            pageSize: 25,
            versionFilter: [],
        }));
    } catch (error) {
    }
};


export const deleteDisputeFileSaga = function* (action) {
    try {
        const {payload} = action;

        const {disputeId, name} = payload;

        const {errorMessage} = yield request.delete(`${controller}/disputeFiles/delete`, {
            params: {
                disputeId,
                name,
            },
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_DISPUTE_DELETE_FILE_ERROR,
            payload: error,
        });
    }
};

export const getDisputeFilesSaga = function* (action) {
    try {
        const {payload: disputeId} = action;

        const result = yield request.get(`${controller}/disputeFiles/getFiles`, {
            params: {
                disputeId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_DISPUTE_GET_FILES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_DISPUTE_GET_FILES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_DISPUTE_GET_FILES_ERROR,
            payload: error,
        });
    }
};

export const getFileClientStampSaga = function* (action) {
    try {
        const {payload: clientId} = action;

        const result = yield request.get(`${controller}/clientFiles/getBase64`, {
            params: {
                clientId,
                clientFileType: "STAMP",
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_CLIENT_FILES_READ_STAMP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_STAMP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_STAMP_ERROR,
            payload: error,
        });
    }
};

export const getFileContractorScanSaga = function* ({payload}) {
    const {
        contractorId,
        contractorFileType,
        fileId,
        thumbnail = true,
        fileFieldName = contractorFileType,
        field,
        isNeedDownload,
        isPending = false,
        includingPending = false,
        onSuccess = () => {
        },
        onError = () => {
        },
    } = payload;

    const url = isPending ? "/contractorFiles/actual/getBase64" : "/contractorFiles/getBase64";

    const params = {
        contractorId,
        contractorFileType: contractorFileType,
        fileId,
        thumbnail,
    };

    try {
        const result = yield request.get(`${controller}${url}`, {
            params: includingPending ? {...params, includingPending} : {...params},
        });
        const {errorMessage, errorCode, base64str} = result;

        if (errorMessage) {
            errorCode !== "FILE_NOT_FOUND" && toastError(errorMessage);

            yield onError();

            yield put({
                type: FILE_STORE_CONTRACTOR_FILES_READ_SCAN_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (isNeedDownload) {
            const tempLink = document.createElement("a");

            tempLink.setAttribute("href", "data:image/jpeg;base64," + base64str);
            tempLink.setAttribute("download", contractorFileType + ".jpg");
            document.body.appendChild(tempLink);

            tempLink.click();
            tempLink.remove();
        }

        const resultObj = {field, contractorFileType: fileFieldName, result};

        onSuccess(resultObj);

        yield put({
            type: FILE_STORE_CONTRACTOR_FILES_READ_SCAN_SUCCESS,
            payload: resultObj,
        });
    } catch (error) {
        const resultObj = {};

        onError();

        yield put({
            type: FILE_STORE_CONTRACTOR_FILES_READ_SCAN_ERROR,
            payload: resultObj,
        });
    }
};

export const getFileClientSignatureSaga = function* (action) {
    try {
        const {payload: clientId} = action;

        const result = yield request.get(`${controller}/clientFiles/getBase64`, {
            params: {
                clientId,
                clientFileType: "SIGNATURE",
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_CLIENT_FILES_READ_SIGNATURE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_SIGNATURE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_SIGNATURE_ERROR,
            payload: error,
        });
    }
};

export const getFileClientLogoSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientFileType,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/clientFiles/getBase64`, {
            params: {
                clientId,
                clientFileType,
            },
        });
        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage && errorCode !== "FILE_NOT_FOUND") {
            yield put({
                type: FILE_STORE_CLIENT_FILES_READ_LOGO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        if (clientFileType === CLIENT_FILE_TYPES.LOGO_THUMBNAIL) {
            yield put({
                type: FILE_STORE_CLIENT_FILES_READ_LOGO_SUCCESS,
                payload: {
                    [clientId]: result,
                },
            });
        }
    } catch (error) {
        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_LOGO_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/documentFiles/getClientAgencyContractScanPage
export const getClientAgencyContractScanPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/documentFiles/getClientAgencyContractScanPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_CLIENT_FILES_READ_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_CLIENT_FILES_READ_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/clientFiles/delete
export const deleteClientFileSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
                clientFileType,
                isShowToast = true,
            },
        } = action;
        const result = yield request.post(`${controller}/clientFiles/delete?clientId=${clientId}&clientFileType=${clientFileType}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_CLIENT_DELETE_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        switch (clientFileType) {
            case CLIENT_FILE_TYPES.STAMP:
                yield put(getFileClientStamp(clientId));
                break;
            case CLIENT_FILE_TYPES.SIGNATURE:
                yield put(getFileClientSignature(clientId));
                break;
            case CLIENT_FILE_TYPES.LOGO_THUMBNAIL:
                yield put(getFileClientLogo({
                    clientId,
                    clientFileType: CLIENT_FILE_TYPES.LOGO_THUMBNAIL,
                }));
                yield put(getClientCardInfo({clientId}));
                break;
            //no default
        }

        if (isShowToast) {
            toastSuccess("Файл успешно удален");
        }

        yield put({
            type: FILE_STORE_CLIENT_DELETE_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: FILE_STORE_CLIENT_DELETE_FILE_ERROR,
            payload: error,
        });
    }
};

export const getChatFileSaga = function* ({payload}) {
    try {
        const {data, saveType} = payload;
        const {
            fileUuid,
            fileName,
            contentUrl,
        } = data;

        const result = yield request.getFile(contentUrl);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_GET_CHAT_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const blob = yield result.blob();
        const nameField = saveType === "multiSave" ? "images" : "image";
        const src = URL.createObjectURL(blob);
        const fileInfo = {
            fileUuid,
            fileName,
            src,
        };

        yield put({
            type: FILE_STORE_GET_CHAT_FILE_SUCCESS,
            payload: {
                fileInfo,
                nameField,
            },
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_GET_CHAT_FILE_ERROR,
            payload: error,
        });
    }
};

export const getUserInstructionFileSaga = function* (action) {
    try {
        const {
            payload: {
                instructionType,
                fileName,
            },
        } = action;

        const url = `${new URL(window.location.href).origin}/api${controller}/userInstructions/getFile?instructionType=${instructionType}`;
        const response = yield request.getFile(url);

        const blob = yield response.blob();

        const href = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", href);
        tempLink.setAttribute("download", fileName);

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

        yield put({type: FILE_STORE_GET_USER_INSTRUCTION_FILE_SUCCESS});

    } catch (error) {
        console.error(error.message);
        toastError("Не удалось скачать файл");
        yield put({type: FILE_STORE_GET_USER_INSTRUCTION_FILE_ERROR, payload: error});
    }
};

export const saveOrderAttachmentsFileSaga = function* ({payload}) {
    try {
        const {clientId, orderId, file} = payload;

        const result = yield request.post(`${controller}/orderAttachments/saveFile`, file, {
            params: {
                clientId,
                orderId,
            },
            ...getMultipartHeaders(),
        },
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }
    } catch (error) {
        toastError("Не удалось прикрепить файл к заказу");
        yield put({
            type: FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_ERROR,
            payload: error.message,
        });
    }
};

export const getOrderAttachmentsFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            orderId,
            setFile,
            fileName,
        } = payload;

        const url = `${new URL(window.location.href).origin}/api${controller}/orderAttachments/getFile?clientId=${clientId}&orderId=${orderId}`;
        const response = yield request.getFile(url, {params: {clientId, orderId}});

        const blob = yield response.blob();

        const documentFile = new File([blob], fileName, {type: blob.type});

        const documentFileUrl = URL.createObjectURL(documentFile);

        setFile({documentFileUrl, documentFile});

        yield put({type: FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_SUCCESS});
    } catch (error) {
        toastError("Не удалось получить файл");
        yield put({type: FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_ERROR, payload: error});
    }
};

export const removeOrderAttachmentsFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            orderId,
        } = payload;

        const result = yield request.post(`${controller}/orderAttachments/deleteFile?clientId=${clientId}&orderId=${orderId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_SUCCESS});

    } catch (error) {
        toastError("Не удалось удалить файл из заказа");
        yield put({type: FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_ERROR, payload: error});
    }
};

export const getAdvertisementFileSaga = function* ({payload}) {
    try {
        const {
            filePath,
            setFile,
            fileName,
        } = payload;

        const response = yield request.getFile(`${new URL(window.location.href).origin}${filePath}`);

        const blob = yield response.blob();

        const file = new File([blob], fileName, {type: blob.type});

        const fileUrl = URL.createObjectURL(file);

        setFile({filePath: fileUrl, file});

        yield put({type: FILE_STORE_GET_FILE_SUCCESS});
    } catch (error) {
        toastError("Не удалось получить файл");
        yield put({type: FILE_STORE_GET_FILE_ERROR});
    }
};

//POST /api/filestore/contractorFiles/additional/add
export const addFileContractorAdditionalScanSaga = function* ({payload}) {
    const {
        contractorId,
        contractorFileType,
        file: data,
        actualData,
        onSuccess = () => {
        },
        onError,
    } = payload;

    try {
        const {errorMessage} = yield request.post(`${controller}/contractorFiles/additional/add`, data, {
            params: {
                contractorId,
                actualData,
                contractorAdditionalFileType: contractorFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError();
            }

            yield put({
                type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS,
        });
    } catch (error) {
        if (onError) {
            onError();
        }

        yield put({
            type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/contractorFiles/additionalDocuments/add
export const addFileContractorAdditionalScanV2Saga = function* ({payload}) {
    try {
        const {
            contractorId,
            contractorFileType,
            file: data,
            onSuccess = () => {
            },
        } = payload;

        const {errorMessage} = yield request.post(`${controller}/contractorFiles/additionalDocuments/add`, data, {
            params: {
                contractorId,
                type: contractorFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_ERROR,
            payload: error,
        });
    }
};

export const getFileContractorAdditionalScanSaga = function* ({payload}) {
    const {
        contractorId,
        contractorFileType,
        thumbnail = true,
        field,
        fileFieldName = contractorFileType,
        isNeedDownload,
        includingPending = false,
        onSuccess = () => {
        },
        onError = () => {
        },
    } = payload;

    const url = thumbnail ? "/contractorFiles/additional/thumbnail/getBase64" : "/contractorFiles/additional/getBase64";

    const params = {
        contractorId,
        contractorAdditionalFileType: contractorFileType,
    };

    try {
        const result = yield request.get(`${controller}${url}`, {
            params: includingPending ? {...params, includingPending} : {...params},
        });
        const {errorMessage, errorCode, base64str} = result;

        if (errorMessage) {
            errorCode !== "FILE_NOT_FOUND" && toastError(errorMessage);

            yield onError();

            yield put({
                type: GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (isNeedDownload) {
            const tempLink = document.createElement("a");

            tempLink.setAttribute("href", "data:image/jpeg;base64," + base64str);
            tempLink.setAttribute("download", contractorFileType + ".jpg");
            document.body.appendChild(tempLink);

            tempLink.click();
            tempLink.remove();
        }

        const resultObj = {field, contractorFileType: fileFieldName, result};

        onSuccess(resultObj);

        yield put({
            type: GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_SUCCESS,
            payload: resultObj,
        });
    } catch (error) {
        const resultObj = {};

        onError();

        yield put({
            type: GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_ERROR,
            payload: resultObj,
        });
    }
};

//POST /api/filestore/contractorFiles/avatar/add
export const addUserAvatarSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            file: data,
            onSuccess = () => {
            },
        } = payload;

        const {errorMessage} = yield request.post(`${controller}/contractorFiles/avatar/add`, data, {
            params: {
                contractorId,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: USER_AVATAR_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: USER_AVATAR_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: USER_AVATAR_ADD_ERROR,
            payload: error,
        });
    }
};

//DELETE /api/filestore/contractorFiles/avatar/delete
export const deleteUserAvatarSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const {errorMessage} = yield request.delete(`${controller}/contractorFiles/avatar/delete`, {params: payload});

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: USER_AVATAR_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: USER_AVATAR_DELETE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: USER_AVATAR_DELETE_ERROR,
            payload: error,
        });
    }
};

//GET /api/filestore/contractorFiles/avatar/get/base64
export const getUserAvatarSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/contractorFiles/avatar/get/base64`, {params: payload});
        const {errorMessage, errorCode} = result;

        if (errorMessage && errorCode !== "CONTRACTOR_AVATAR_FILE_NOT_FOUND") {
            yield put({
                type: USER_AVATAR_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: USER_AVATAR_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: USER_AVATAR_GET_ERROR,
            payload: error,
        });
    }
};

//GET /api/filestore/contractorFiles/avatar/thumbnail/get/base64
export const getUserAvatarThumbnailSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/contractorFiles/avatar/thumbnail/get/base64`, {params: payload});
        const {errorMessage, errorCode} = result;

        if (errorMessage && errorCode !== "CONTRACTOR_AVATAR_FILE_NOT_FOUND") {
            yield put({
                type: USER_AVATAR_GET_THUMBNAIL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: USER_AVATAR_GET_THUMBNAIL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: USER_AVATAR_GET_THUMBNAIL_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/contractorFiles/avatar/thumbnail/small/list/get/base64
export const getUserAvatarListThumbnailsSaga = function* ({payload}) {
    try {
        const {
            contractorIds,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/contractorFiles/avatar/thumbnail/small/list/get/base64`, contractorIds);
        const {errorMessage} = result;


        if (errorMessage) {
            yield put({
                type: USER_AVATAR_GET_LIST_THUMBNAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: USER_AVATAR_GET_LIST_THUMBNAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: USER_AVATAR_GET_LIST_THUMBNAILS_ERROR,
            payload: error,
        });
    }
};

export const getContractorAdditionalDocumentFileSaga = function* ({payload}) {
    const {
        field,
        thumbnail,
        type,
        contractorId,
        id,
        fileFieldName = type,
        isNeedDownload,
        onSuccess = () => {
        },
        onError = () => {
        },
    } = payload;
    const url = thumbnail ? "/contractorFiles/additionalDocuments/thumbnail/get/base64" : "/contractorFiles/additionalDocuments/get/base64";

    const params = {
        contractorId,
        id,
        type,
    };

    try {
        const result = yield request.get(`${controller}${url}`, {params});

        const {errorMessage, errorCode, base64str} = result;

        if (errorMessage) {
            !["FILE_NOT_FOUND", "ADDITIONAL_DOCUMENT_FILE_NOT_FOUND"].includes(errorCode) && toastError(errorMessage);

            yield onError();

            yield put({
                type: CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (isNeedDownload) {
            const tempLink = document.createElement("a");

            tempLink.setAttribute("href", "data:image/jpeg;base64," + base64str);
            tempLink.setAttribute("download", type + ".jpg");
            document.body.appendChild(tempLink);

            tempLink.click();
            tempLink.remove();
        }

        const resultObj = {
            field,
            contractorFileType: fileFieldName,
            result,
        };

        onSuccess(resultObj);

        yield put({
            type: CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_SUCCESS,
            payload: resultObj,
        });
    } catch (error) {
        onError();

        yield put({
            type: CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FILE_STORE_DISPUTE_DELETE_FILE_REQUEST, deleteDisputeFileSaga),
        takeEvery(FILE_STORE_DISPUTE_UPlOAD_IMAGE_REQUEST, addDocumentToChatStoreSaga),
        takeEvery(BFF_CROWD_CHAT_FILE_SAVE_REQUEST, addDocumentToCrowdChatSaga),
        takeEvery(FILE_STORE_DISPUTE_GET_FILES_REQUEST, getDisputeFilesSaga),
        takeEvery(FILE_STORE_CLIENT_FILE_ADD_REQUEST, addFileClientToFileStoreSaga),
        takeEvery(FILE_STORE_CLIENT_FILES_READ_STAMP_REQUEST, getFileClientStampSaga),
        takeEvery(FILE_STORE_CONTRACTOR_FILES_READ_SCAN_REQUEST, getFileContractorScanSaga),
        takeEvery(FILE_STORE_CLIENT_FILES_READ_SIGNATURE_REQUEST, getFileClientSignatureSaga),
        takeEvery(FILE_STORE_CONTRACTOR_FILES_ADD_SCAN_REQUEST, addFileContractorFileStoreSaga),
        takeEvery(FILE_STORE_CLIENT_FILES_ADD_SCAN_REQUEST, addClientAgencyContractScanSaga),
        takeEvery(FILE_STORE_CLIENT_FILES_READ_LIST_REQUEST, getClientAgencyContractScanPageSaga),
        takeEvery(FILE_STORE_CLIENT_DELETE_FILE_REQUEST, deleteClientFileSaga),
        takeEvery(FILE_STORE_GET_CHAT_FILE_REQUEST, getChatFileSaga),
        takeEvery(FILE_STORE_GET_USER_INSTRUCTION_FILE_REQUEST, getUserInstructionFileSaga),
        takeEvery(FILE_STORE_ORDER_ATTACHMENTS_SAVE_FILE_REQUEST, saveOrderAttachmentsFileSaga),
        takeEvery(FILE_STORE_ORDER_ATTACHMENTS_GET_FILE_REQUEST, getOrderAttachmentsFileSaga),
        takeEvery(FILE_STORE_ORDER_ATTACHMENTS_DELETE_FILE_REQUEST, removeOrderAttachmentsFileSaga),
        takeEvery(FILE_STORE_CLIENT_FILES_READ_LOGO_REQUEST, getFileClientLogoSaga),
        takeEvery(FILE_STORE_GET_FILE_REQUEST, getAdvertisementFileSaga),
        takeEvery(ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST, addFileContractorAdditionalScanSaga),
        takeEvery(ADD_CONTRACTOR_FILES_ADDITIONAL_SCAN_V2_REQUEST, addFileContractorAdditionalScanV2Saga),
        takeEvery(GET_CONTRACTOR_FILES_ADDITIONAL_SCAN_REQUEST, getFileContractorAdditionalScanSaga),
        takeEvery(USER_AVATAR_ADD_REQUEST, addUserAvatarSaga),
        takeEvery(USER_AVATAR_DELETE_REQUEST, deleteUserAvatarSaga),
        takeEvery(USER_AVATAR_GET_REQUEST, getUserAvatarSaga),
        takeEvery(USER_AVATAR_GET_THUMBNAIL_REQUEST, getUserAvatarThumbnailSaga),
        takeEvery(USER_AVATAR_GET_LIST_THUMBNAILS_REQUEST, getUserAvatarListThumbnailsSaga),
        takeEvery(CONTRACTOR_FILES_ADDITIONAL_DOCUMENT_REQUEST, getContractorAdditionalDocumentFileSaga),
    ]);
}