import {
    CLEAR_FIELDS_FINANCES_ORDERS,
    GET_HISTORY_FINANCES_ORDERS_REQUEST,
    GET_PAGE_FINANCES_ORDERS_REQUEST,
    UPDATE_FIELDS_FINANCES_ORDERS,
} from "./actions";

export function getFinancesOrdersPage(payload) {
    return {
        type: GET_PAGE_FINANCES_ORDERS_REQUEST,
        payload,
    };
}

export function getFinancesOrdersHistory(payload) {
    return {
        type: GET_HISTORY_FINANCES_ORDERS_REQUEST,
        payload,
    };
}

export function updateFieldFinancesOrder() {
    return {
        type: UPDATE_FIELDS_FINANCES_ORDERS,
    };
}

export function clearFieldFinancesOrder() {
    return {
        type: CLEAR_FIELDS_FINANCES_ORDERS,
    };
}