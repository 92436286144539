import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../../components/ActualComponents/NmPageCardHeader";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../../components/ActualComponents/Tabs";
import Text from "../../../../components/ActualComponents/Text";
import ExtLink from "../../../../components/ExtLink";
import NmHint from "../../../../components/NmHint";
import NmPageCard from "../../../../components/NmPageCard";
import NmSelectedList from "../../../../components/NmSelectedList";
import {
    onboardingRecruitersListIsSearchSelector,
    onboardingRecruitersListTotalCountSelector,
} from "../../../../ducks/onboardingRecruiters";
import OnboardingScenarioEditForm from "../edit-form";
import OnboardingScenarioContractorList from "./components/contractor-list";
import OnboardingScenarioLeadList from "./components/lead-list";
import OnboardingScenarioRecruiterList from "./components/recruiter-list";

import {useActionOnboardingScenarioCard} from "./hooks/useAction";
import {useFetchOnboardingScenarioCard} from "./hooks/useFetch";

import bem from "../../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {getHistoryBackLink} from "../../../../utils/historyNavigationhelper";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getFullName} from "../../../../utils/stringFormat";
import {getSimpleText} from "../../../../utils/stringHelper";
import {getOnboardingFrameContractType} from "../../utils/getFrameContractType";
import {getOnBoardingCardTabsCounter} from "./utils/getTabCounter";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTORS_TABS_TYPES} from "../../../../constants/contractor";
import {
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
} from "../../../../constants/links";
import {
    ONBOARDING_SCENARIO_CARD_TAB,
    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP,
    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT,
    ONBOARDING_SCENARIO_STATUS,
    ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE_DICT,
} from "../../../../constants/onboarding";
import {
    CLIENT_ACCOUNTANT,
    FOREMAN,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    RECRUITER,
} from "../../../../constants/roles";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS,
} from "../../../contractor/contractor-info-new/components/additional-documents-verification/constants";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {getCitizenshipOptionsSelector, getCitizenshipSelector} from "../../../../ducks/contractor";
import {
    onboardingScenarioCardSelector,
    onboardingScenarioLeadsSelector,
    onboardingScenarioProgressActionSelector,
    onboardingScenarioProgressSelector,
} from "../../../../ducks/onboarding";
import {
    onboardingContractorsListIsSearchSelector,
    onboardingContractorsListTotalCountSelector,
} from "../../../../ducks/onboardingContractors";

import "./style.sass";

const OnboardingScenarioCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                scenarioId,
            },
        },
    } = props;

    const [, element] = bem("onboarding-scenario-card");
    const [isOpenEditForm, setIsOpenEditForm] = useState(false);

    const card = useSelector(onboardingScenarioCardSelector);
    const progress = useSelector(onboardingScenarioProgressSelector);
    const progressAction = useSelector(onboardingScenarioProgressActionSelector);
    const citizenshipDict = useSelector(getCitizenshipSelector);
    const contractorsTotalCount = useSelector(onboardingContractorsListTotalCountSelector);
    const recruitersTotalCount = useSelector(onboardingRecruitersListTotalCountSelector);
    const leads = useSelector(onboardingScenarioLeadsSelector);
    const recruiterIsSearch = useSelector(onboardingRecruitersListIsSearchSelector);
    const contractorIsSearch = useSelector(onboardingContractorsListIsSearchSelector);
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const location = useSelector(state => state.router.location);
    const {archived: isClientArchived} = useSelector(clientCardInfoSelector);

    const [tab, setTab] = useState(ONBOARDING_SCENARIO_CARD_TAB.CONTRACTORS);
    const [hideDetails, setHideDetails] = useState(true);

    const role = ls(USER_ROLE);

    const {
        status,
        name,
        objectName,
        projectId,
        objectId,
        responsibleUserLastName,
        responsibleUserFirstName,
        responsibleUserPatronymic,
        number,
        creationDateTime,
        frameContractTemplateInfo = {},
        otherDocumentTemplateInfo = {},
        documentParams = {},
        contractorParams = {},
        invitationParams = {},
        contractorsCount,
        boundScenarios,
        frameContractType,
    } = card;

    const isActive = status === ONBOARDING_SCENARIO_STATUS.ACTIVE.VALUE;

    useEffect(() => {
        setTab(ONBOARDING_SCENARIO_CARD_TAB.CONTRACTORS);
    }, [scenarioId]);

    const {
        fetchCard,
    } = useFetchOnboardingScenarioCard({
        clientId,
        scenarioId,
    });

    const {
        updateStatus,
        downloadFile,
    } = useActionOnboardingScenarioCard({
        clientId,
        scenarioId,
        fetchCard,
    });

    const renderObjectInfo = () => {
        if (!objectId) {
            return "-";
        }

        if ([HR_MANAGER].includes(role)) {
            return objectName;
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    const getCitizenshipInfo = () => {
        const {
            citizenshipGroup,
            exactCitizenshipList,
        } = contractorParams.citizenshipRule || {};

        if (!citizenshipGroup && !exactCitizenshipList) {
            return "-";
        }

        if (citizenshipGroup === ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS) {
            return ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT[citizenshipGroup];
        }

        if (citizenshipGroup === ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS) {
            const notResidentsCitizenshipList = Object.keys(citizenshipDict)
                .filter(item => item !== citizenshipsDict.RU.value)
                .map((item) => ({text: citizenshipDict[item]}));

            return (
                <div className="flex">
                    <Text>
                        {ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT[citizenshipGroup]}
                    </Text>
                    (
                    <NmSelectedList
                        className={element("selected-list")}
                        modalTitle="Гражданство"
                        isShowDetailModal
                        itemClassName={element("selected-list-item")}
                        showedItemsCount={3}
                        showListWithoutValue
                        list={notResidentsCitizenshipList}
                        dropdownWidth={250}
                    />
                    )
                </div>
            );
        }

        const isAll = exactCitizenshipList?.length === citizenshipOptions.length;

        return (
            <div className="flex">
                {
                    isAll &&
                    <Text>
                        Все(
                    </Text>
                }
                <NmSelectedList
                    className={element("selected-list")}
                    modalTitle="Гражданство"
                    isShowDetailModal
                    itemClassName={element("selected-list-item")}
                    showedItemsCount={3}
                    showListWithoutValue={true}
                    list={exactCitizenshipList.map((item) => ({text: citizenshipDict[item]}))}
                    dropdownWidth={250}
                />
                {isAll && ")"}
            </div>
        );
    };

    const renderBoundScenarios = (boundScenarios) => {
        return (
            <div className="flex flex-wrap align-items-center">
                {
                    boundScenarios.map((value, index) => {
                        return (
                            <div
                                key={value.scenarioId}
                                className="flex align-items-center"
                            >
                                <Text noWrap>
                                    {value.name}
                                </Text>
                                {
                                    index !== boundScenarios.length - 1 &&
                                    <div className="nm-selected-list__delimiter" />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const getAdditionalDocumentInfo = () => {
        const additionalDocumentParams = contractorParams.additionalDocumentParams || {};
        const additionalDocumentList = Object.keys(additionalDocumentParams).filter(item => additionalDocumentParams[item]?.tracked);
        const resultList = additionalDocumentList.map(item => `${CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[item]?.name}${additionalDocumentParams[item]?.required ? "(Обязательно)" : ""}`);

        return resultList.join(", ");
    };

    const renderContent = () => {
        switch (tab) {
            case ONBOARDING_SCENARIO_CARD_TAB.CONTRACTORS: {
                return (
                    <OnboardingScenarioContractorList
                        clientId={clientId}
                        scenarioId={scenarioId}
                        isBoundScenarios={Boolean(boundScenarios?.length)}
                    />
                );
            }
            case ONBOARDING_SCENARIO_CARD_TAB.RECRUITERS: {
                return (
                    <OnboardingScenarioRecruiterList
                        clientId={clientId}
                        scenarioId={scenarioId}
                    />
                );
            }
            case ONBOARDING_SCENARIO_CARD_TAB.LEADS: {
                return (
                    <OnboardingScenarioLeadList
                        clientId={clientId}
                        scenarioId={scenarioId}
                    />
                );
            }
            default:
                return null;
        }
    };

    const getMediaControls = () => {
        return (
            {
                renderCount: {
                    desktop: 2,
                    tablet: 2,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.HORIZONTAL_TOGGLE,
                        props: {
                            disabled: progressAction,
                            noWrapLabel: true,
                            leftLabel: "Сценарий активен",
                            onChange: () => {
                                updateStatus({
                                    status: isActive ?
                                        ONBOARDING_SCENARIO_STATUS.INACTIVE.VALUE :
                                        ONBOARDING_SCENARIO_STATUS.ACTIVE.VALUE,
                                });
                            },
                            checked: isActive,
                            single: true,
                            duplex: false,
                        },
                        visible: !isClientArchived && ![RECRUITER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, FOREMAN, CLIENT_ACCOUNTANT, NM_OPERATOR].includes(role),
                        asset: {
                            mobile: {children: isActive ? "Деактивировать сценарий" : "Активировать сценарий"},
                        },
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            color: "grey",
                            size: "xl",
                            onClick: () => setIsOpenEditForm(true),
                            children: "Редактировать",
                            disabled: progressAction,
                        },
                        visible: !isClientArchived && ![RECRUITER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, FOREMAN, CLIENT_ACCOUNTANT, NM_OPERATOR].includes(role),
                    },
                ],
            }
        );
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <OnboardingScenarioEditForm
                clientId={clientId}
                card={card}
                onClose={() => setIsOpenEditForm(false)}
                fetchList={fetchCard}
            />
        );
    };

    return (
        <NmPageCard
            header={
                <NmPageCardHeader
                    to={
                        getHistoryBackLink(location) ||
                        LINK_CLIENT_CONTRACTORS_LIST
                            .replace(":type", CONTRACTORS_TABS_TYPES.ONBOARDING)
                            .replace(":clientId", clientId)
                    }
                    status={status}
                    statusDict={ONBOARDING_SCENARIO_STATUS}
                    content={name}
                    controls={<MediaButtons config={getMediaControls()} />}
                />
            }
            description={
                <div className="col-16 col-xxl-9">
                    <NmLabelText
                        className="onboarding-scenario-card__label-text"
                        type="page"
                        label="Дата создания"
                        text={formatDate(convertUtcToLocal(creationDateTime), "dd.MM.yyyy HH:mm")}
                        noWrap={false}
                    />
                    <NmLabelText
                        className="onboarding-scenario-card__label-text"
                        type="page"
                        label="Номер сценария"
                        text={number}
                        noWrap={false}
                    />
                    <NmLabelText
                        className="onboarding-scenario-card__label-text"
                        type="page"
                        label="Ответственный"
                        text={getFullName(responsibleUserLastName, responsibleUserFirstName, responsibleUserPatronymic)}
                        noWrap={false}
                    />
                    <NmLabelText
                        className="onboarding-scenario-card__label-text"
                        type="page"
                        label="Объект"
                        text={renderObjectInfo()}
                        noWrap={false}
                    />
                    <NmLabelText
                        className="onboarding-scenario-card__label-text"
                        type="page"
                        label="Тип договора"
                        text={getOnboardingFrameContractType(frameContractType)}
                        noWrap={false}
                    />
                    <NmHint
                        onClick={() => setHideDetails(prevState => !prevState)}
                        isShown={!hideDetails}
                        closeButtonText="Cкрыть детали"
                        openButtonText="Показать детали"
                        buttonClassName={element("hint-text")}
                    >
                        <>
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                cursorPointer
                                type="page"
                                label="Шаблон договора"
                                text={frameContractTemplateInfo.name}
                                color="blue"
                                noWrap={false}
                                inline={true}
                                onClickText={() => {
                                    downloadFile(frameContractTemplateInfo.name, frameContractTemplateInfo.downloadLink);
                                }}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Разрешить подписывать РД до проверки персональных данных Администрацией Наймикс"
                                text={getSimpleText(documentParams.signingAllowedWithoutFullRegistration)}
                                noWrapLabelOnMobile={true}
                                columnOnMobile={true}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс"
                                text={getSimpleText(documentParams.signingAllowedWithoutOkTaxStatus)}
                                noWrapLabelOnMobile={true}
                                columnOnMobile={true}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Разрешить подписывать РД только при наличии подключенной автоуплаты налога"
                                text={getSimpleText(documentParams.signingAllowedOnlyWithSmzTaxOffer)}
                                noWrapLabelOnMobile={true}
                                columnOnMobile={true}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                cursorPointer={documentParams.offerOtherDocumentToSign}
                                type="page"
                                label="Документ на подписание"
                                text={documentParams.offerOtherDocumentToSign && otherDocumentTemplateInfo.name ? otherDocumentTemplateInfo.name : "-"}
                                color="blue"
                                noWrap={false}
                                onClickText={() => {
                                    if (documentParams.offerOtherDocumentToSign && otherDocumentTemplateInfo.name) {
                                        downloadFile(otherDocumentTemplateInfo.name, otherDocumentTemplateInfo.downloadLink);
                                    }
                                }}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Документ обязателен к подписанию"
                                text={getSimpleText(documentParams.otherDocumentRequired)}
                                noWrap={false}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Гражданство"
                                text={getCitizenshipInfo()}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Дополнительные документы"
                                text={<NmShowMoreText
                                    lines={1}
                                    title="Дополнительные документы"
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {getAdditionalDocumentInfo() || "-"}
                                </NmShowMoreText>}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Разрешить регистрацию без ИНН"
                                text={getSimpleText(contractorParams.registrationAllowedWithoutInn)}
                                noWrap={false}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="ProjectParams"
                                text={invitationParams.projectParams || "-"}
                                noWrap={false}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Промокод"
                                text={invitationParams.promoCode || "-"}
                                noWrap={false}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Связанные сценарии"
                                text={boundScenarios?.length ? renderBoundScenarios(boundScenarios) : "-"}
                                noWrap={false}
                            />
                            <NmLabelText
                                className="onboarding-scenario-card__label-text"
                                type="page"
                                label="Добавить в Закрытый контур"
                                text={`${getSimpleText(invitationParams.applyClientGroup)}${
                                    invitationParams.applyClientGroup && invitationParams.clientGroupApplicationMode ?
                                        ` (${ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE_DICT[invitationParams.clientGroupApplicationMode]})` : ""}
                                        `}
                                noWrap={false}
                            />
                            {
                                invitationParams.removeFromClientGroupAfterContractTermination &&
                                <NmLabelText
                                    type="page"
                                    label="Выводить с закрытого контура после расторжении РД через"
                                    text={invitationParams.removeFromClientGroupAfterContractTerminationDaysOffset}
                                    noWrap={false}
                                />
                            }
                        </>
                    </NmHint>
                </div>
            }
            isLoaded={progress}
        >
            {renderEditForm()}
            <Tabs
                className="mb-4"
                panes={[
                    {
                        count: getOnBoardingCardTabsCounter(contractorIsSearch, card.contractorsCount, contractorsTotalCount),
                        name: "Исполнители",
                        active: tab === ONBOARDING_SCENARIO_CARD_TAB.CONTRACTORS,
                        onClick: () => setTab(ONBOARDING_SCENARIO_CARD_TAB.CONTRACTORS),
                    },
                    {
                        count: getOnBoardingCardTabsCounter(leads.isSearch, card.leadsCount, leads.totalCount),
                        name: "Лиды",
                        active: tab === ONBOARDING_SCENARIO_CARD_TAB.LEADS,
                        onClick: () => setTab(ONBOARDING_SCENARIO_CARD_TAB.LEADS),
                    },
                    {
                        count: getOnBoardingCardTabsCounter(recruiterIsSearch, card.recruitersCount, recruitersTotalCount),
                        name: "Рекрутеры",
                        active: tab === ONBOARDING_SCENARIO_CARD_TAB.RECRUITERS,
                        onClick: () => setTab(ONBOARDING_SCENARIO_CARD_TAB.RECRUITERS),
                    },
                ]}
            />
            {renderContent()}
        </NmPageCard>
    );
};

export default OnboardingScenarioCard;