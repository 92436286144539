import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useModal} from "../../../../../hooks/useModal";

import {
    getUserRole,
    isNMUsers,
} from "../../../../../utils/access";
import {handleFormString} from "../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {formatFileDict} from "../../../../../constants/financeExport";
import {FINANCE_EXPORT_MESSAGES} from "../../../../../constants/messages";
import {
    NM_CHIEF_ACCOUNTANT,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
} from "../../../../../constants/roles";

import {runCrowdActRegistry} from "../../../../../ducks/bff/crowd/actRegistry/actionCreators";
import {bffCrowdActRegistryCardSelector} from "../../../../../ducks/bff/crowd/actRegistry/selectors";
import {
    checkDuplicateCrowdActRegistryEntry,
    deleteCrowdActRegistryEntry,
    exportCrowdActRegistry,
    importCrowdActRegistry,
    massDeleteCrowdActRegistryEntry,
    repeatCrowdActRegistryEntry,
    validationCrowdActRegistryEntry,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/actionCreators";
import {bffCrowdActRegistryEntryProgressActionSelector} from "../../../../../ducks/bff/crowd/actRegistryEntry/selectors";
import {rejectCrowdPayment} from "../../../../../ducks/bff/crowd/payment/actionCreators";
import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";
import {downloadDocument} from "../../../../../ducks/documents";
import {getAllTasks} from "../../../../../ducks/job";

import {CROWD_ACT_REGISTRY_ENTRY_STATUS, CROWD_ACT_REGISTRY_STATUS} from "../../../../../constants/crowd/act-registry";
import {STATUS_TASK_PAYMENT} from "../../../../../constants/crowd/task-payment";

export const useCrowdTaskActRegistryCardAction = (props) => {
    const {
        clientId,
        registryId,
        fetchCard,
        fetchList,
        selectedList,
        clearSelectedRows,
        isAccessAction,
    } = props;

    const dispatch = useDispatch();

    const card = useSelector(bffCrowdActRegistryCardSelector);
    const progressAction = useSelector(bffCrowdActRegistryEntryProgressActionSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const [rejectReasonModalData, setRejectReasonModal] = useState({});

    const role = getUserRole();
    const isAccessPayCrowdRegistry = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.payCrowdRegistry);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const getMediaControls = () => {
        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Запустить реестр",
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            content: "Вы подтверждаете проверку выполненных работ и запуск оплат по заданиям?",
                            confirmButton: "Подтверждаю",
                            onConfirm: checkValid,
                        }),
                    },
                    visible: ![
                        NM_MANAGER,
                        NM_CHIEF_ACCOUNTANT,
                        NM_OPERATOR,
                        OBJECT_MANAGER,
                    ].includes(role)
                        && card.status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE
                        && isAccessPayCrowdRegistry,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Выгрузить реестр",
                        onClick: exportRegistry,
                        disabled: progressAction,
                    },
                    visible: isAccessAction,
                },
            ],
        };
    };

    const getListItemMediaControls = (item) => {
        const {
            entryId,
            status,
            paymentStatus,
            contractDocumentFileLink,
            applicationDocumentFileLink,
            paymentNumber,
            paymentId,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать",
                        onClick: () => onOpenModal({
                            isOpenEditModal: true,
                            data: item,
                        }),
                    },
                    visible: isAccessAction
                        && card.status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE
                        || (
                            card.status === CROWD_ACT_REGISTRY_STATUS.PROCESSING.VALUE
                            && status === CROWD_ACT_REGISTRY_ENTRY_STATUS.ERROR.VALUE
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Удалить",
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            content: "Вы подтверждаете удаление строки?",
                            confirmButton: "Подтверждаю",
                            onConfirm: () => deleteEntry(entryId),
                        }),
                    },
                    visible: isAccessAction
                        && card.status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE
                        || (
                            card.status === CROWD_ACT_REGISTRY_STATUS.PROCESSING.VALUE
                            && status === CROWD_ACT_REGISTRY_ENTRY_STATUS.ERROR.VALUE
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отклонить оплату",
                        onClick: () => setRejectReasonModal({
                            isOpen: true,
                            paymentId,
                        }),
                    },
                    visible: status === CROWD_ACT_REGISTRY_ENTRY_STATUS.PROCESSING.VALUE
                        && [
                            STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                            STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                            STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                        ].includes(paymentStatus),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Повторить оплату",
                        onClick: () => repeatPayment(item),
                    },
                    visible: status === CROWD_ACT_REGISTRY_ENTRY_STATUS.ERROR.VALUE
                        && !(
                            [
                                STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                                STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                            ].includes(paymentStatus)
                            && !isNMUsers()
                        )
                        && isAccessPayCrowdRegistry,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Договор",
                        onClick: () => downloadFile(contractDocumentFileLink),
                    },
                    visible: Boolean(contractDocumentFileLink),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Заявка по заданию",
                        onClick: () => downloadFile(applicationDocumentFileLink),
                    },
                    visible: Boolean(applicationDocumentFileLink),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Акт по заданию",
                        onClick: () => downloadFile(`/bff/adm/clients/client-card/crowd-tasks/registry/task/files/act-document?paymentNumber=${paymentNumber}`),
                    },
                    visible: Boolean(paymentNumber),
                },
            ],
        };
    };

    const downloadFile = (downloadLink) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
        }));
    };

    const repeatPayment = ({entryId, paymentNumber}) => {
        dispatch(repeatCrowdActRegistryEntry({
            clientId,
            registryId,
            entryId,
            onSuccess: () => {
                toastSuccess(`Запущена задача по повтору оплаты по заданию ${paymentNumber}`);
                fetchList();
            },
        }));
    };

    const rejectPayment = ({paymentId, rejectReason}) => {
        dispatch(rejectCrowdPayment({
            paymentId,
            rejectReason: handleFormString(rejectReason),
            onSuccess: () => {
                fetchList();
                setRejectReasonModal({});
            },
        }));
    };

    const importEntries = ({file}) => {
        const formData = new FormData();
        formData.append("mFile", file);

        dispatch(importCrowdActRegistry({
            registryId,
            formData,
            onSuccess: () => {
                onCloseModal();
                toastSuccess("Запущена задача по импорту заданий. Состояние задачи можно посмотреть в разделе \"Список задач\"");
                fetchTasks();
            },
        }));
    };

    const fetchTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    const exportRegistry = () => {
        dispatch(exportCrowdActRegistry({
            registryId,
            formatType: formatFileDict.XLSX,
            onSuccess: () => {
                toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_REGISTRY_SUCCESS);
            },
        }));
    };

    const checkValid = () => {
        dispatch(validationCrowdActRegistryEntry({
            registryId,
            getResult: (result) => {
                if (!result) {
                    onOpenModal({
                        isOpenErrorsEntryModal: true,
                        onClose: () => {
                            onCloseModal();
                            fetchCard();
                            fetchList();
                        },
                    });

                    return;
                }

                onCloseModal();
                checkDuplicates();
            },
        }));
    };

    const checkDuplicates = () => {
        dispatch(checkDuplicateCrowdActRegistryEntry({
            registryId,
            getResult: (result) => {
                const {
                    duplicateEntries = [],
                    nonActualEntries = [],
                } = result;

                if (duplicateEntries.length) {
                    onOpenModal({
                        isOpenDuplicatesEntryModal: true,
                        duplicateEntries,
                        nonActualEntries,
                    });

                    return;
                }

                if (nonActualEntries.length) {
                    onOpenModal({
                        isOpenNonActualEntryModal: true,
                        nonActualEntries,
                    });

                    return;
                }

                runRegistry();
            },
        }));
    };

    const runRegistry = () => {
        dispatch(runCrowdActRegistry({
            registryId,
            onSuccess: () => {
                onCloseModal();
                fetchCard();
                fetchList();
            },
        }));
    };

    const deleteEntry = (entryId) => {
        dispatch(deleteCrowdActRegistryEntry({
            entryId,
            onSuccess: () => {
                clearSelectedRows();
                fetchList();
                onCloseModal();
            },
        }));
    };

    const massDeleteEntries = () => {
        const entryIds = selectedList.filter(({isSelected}) => isSelected).map(({entryId}) => entryId);

        dispatch(massDeleteCrowdActRegistryEntry({
            clientId,
            registryId,
            entryIds,
            onSuccess: () => {
                clearSelectedRows();
                fetchList();
                fetchCard();
                onCloseModal();
            },
        }));
    };

    const onSubmitDuplicateEntryModal = (entryIds) => {
        dispatch(massDeleteCrowdActRegistryEntry({
            clientId,
            registryId,
            entryIds,
            onSuccess: () => {
                if (card.hasNonActualEntries) {
                    fetchList();
                    fetchCard();

                    onOpenModal({
                        ...modalData,
                        isOpenDuplicatesEntryModal: false,
                        isOpenNonActualEntryModal: true,
                        duplicateEntries: undefined,
                    });

                    return;
                }

                onCloseModal();
                runRegistry();
            },
        }));
    };

    const onSubmitNonActualEntryModal = () => {
        dispatch(massDeleteCrowdActRegistryEntry({
            clientId,
            registryId,
            entryIds: modalData.nonActualEntries.map(({entryId}) => entryId),
            onSuccess: () => {
                runRegistry();
                onCloseModal();
            },
        }));
    };

    return {
        getMediaControls,
        getListItemMediaControls,
        importEntries,
        massDeleteEntries,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
        modalData,
        onCloseModal,
        onOpenModal,
        onSubmitDuplicateEntryModal,
        onSubmitNonActualEntryModal,
    };
};