import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    getContractorEducationLevelDict,
    getContractorLanguagesDict,
    getDriverLicenseDictionary,
} from "../ducks/bff/contractor-сard/profile/actionCreators";
import {
    contractorDrivingLicenseDictKeyDictSelector,
    contractorEducationLevelDictSelector,
    contractorLanguagesDictSelector,
} from "../ducks/bff/contractor-сard/profile/selectors";

export const useFetchDicts = () => {
    const dispatch = useDispatch();

    const educationLevelDict = useSelector(contractorEducationLevelDictSelector);
    const driverLicenseDict = useSelector(contractorDrivingLicenseDictKeyDictSelector);
    const languageDict = useSelector(contractorLanguagesDictSelector);

    useEffect(() => {
        if (isEmpty(driverLicenseDict)) {
            dispatch(getDriverLicenseDictionary());
        }
        if (isEmpty(languageDict)) {
            dispatch(getContractorLanguagesDict());
        }
        if (isEmpty(educationLevelDict)) {
            dispatch(getContractorEducationLevelDict());

        }
    }, []);

    return {
        educationLevelDict,
        driverLicenseDict,
        languageDict,
    };
};