import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";

const controller = "/notification";
//*  TYPES  *//

const NOTIFICATION_GET_PAGE_REQUEST = "NOTIFICATION_GET_PAGE_REQUEST";
const NOTIFICATION_GET_PAGE_SUCCESS = "NOTIFICATION_GET_PAGE_SUCCESS";
const NOTIFICATION_GET_PAGE_ERROR = "NOTIFICATION_GET_PAGE_ERROR";

const NOTIFICATION_ADD_VIEW_REQUEST = "NOTIFICATION_ADD_VIEW_REQUEST";
const NOTIFICATION_ADD_VIEW_SUCCESS = "NOTIFICATION_ADD_VIEW_SUCCESS";
const NOTIFICATION_ADD_VIEW_ERROR = "NOTIFICATION_ADD_VIEW_ERROR";

const NOTIFICATION_ADD_VIEWS_REQUEST = "NOTIFICATION_ADD_VIEWS_REQUEST";
const NOTIFICATION_ADD_VIEWS_SUCCESS = "NOTIFICATION_ADD_VIEWS_SUCCESS";
const NOTIFICATION_ADD_VIEWS_ERROR = "NOTIFICATION_ADD_VIEWS_ERROR";

const NOTIFICATION_GET_UNREAD_COUNT_REQUEST = "NOTIFICATION_GET_UNREAD_COUNT_REQUEST";
const NOTIFICATION_GET_UNREAD_COUNT_SUCCESS = "NOTIFICATION_GET_UNREAD_COUNT_SUCCESS";
const NOTIFICATION_GET_UNREAD_COUNT_ERROR = "NOTIFICATION_GET_UNREAD_COUNT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    notificationList: [],
    notificationPageData: {},
    notificationTotalCount: 0,
    unreadCount: 0,
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case NOTIFICATION_GET_PAGE_REQUEST:
            const {getSuccess, ...notificationPageData} = payload;

            return {
                ...state,
                progress: true,
                notificationPageData,
            };
        case NOTIFICATION_GET_PAGE_SUCCESS:
            const {
                notificationList,
                totalCount: notificationTotalCount,
            } = payload;

            return {
                ...state,
                notificationList,
                progress: false,
                notificationTotalCount,
            };
        case NOTIFICATION_GET_UNREAD_COUNT_SUCCESS:
            return {
                ...state,
                unreadCount: payload,
            };
        case NOTIFICATION_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getPageNotification(payload) {
    return {
        type: NOTIFICATION_GET_PAGE_REQUEST,
        payload,
    };
}

export function getNotificationUnreadCount(payload) {
    return {
        type: NOTIFICATION_GET_UNREAD_COUNT_REQUEST,
        payload,
    };
}

export function addViewNotification(payload) {
    return {
        type: NOTIFICATION_ADD_VIEW_REQUEST,
        payload,
    };
}

export function addViewsNotification(payload) {
    return {
        type: NOTIFICATION_ADD_VIEWS_REQUEST,
        payload,
    };
}


//*  SELECTORS  *//

export const notificationSelector = ({notification}) => notification;
export const notificationTotalPagesSelector = createSelector(notificationSelector, ({notificationTotalCount, notificationPageData: {pageSize = 0}}) => getTotalPages(notificationTotalCount, pageSize));
export const notificationListSelector = createSelector(notificationSelector, ({notificationList}) => notificationList);
export const notificationUnreadCountSelector = createSelector(notificationSelector, ({unreadCount}) => unreadCount);
export const notificationTotalCountSelector = createSelector(notificationSelector, ({notificationTotalCount}) => notificationTotalCount);
export const notificationProgressSelector = createSelector(notificationSelector, ({progress}) => progress);

//*  SAGA  *//

//POST /api/notification/clients/getPage
export const getPageNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                getSuccess,
                ...data
            },
        } = action;

        const result = yield request.post(`${controller}/clients/page`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: NOTIFICATION_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: NOTIFICATION_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: NOTIFICATION_GET_PAGE_ERROR, payload: error});
    }
};

//GET /api/notification/clients/addView
export const addViewNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                getSuccess,
                ...params
            },
        } = action;

        const result = yield request.get(`${controller}/clients/addView`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: NOTIFICATION_ADD_VIEW_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: NOTIFICATION_ADD_VIEW_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: NOTIFICATION_ADD_VIEW_ERROR, payload: error});
    }
};

//POST /api/notification/clients/addViews
export const addViewsNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                getSuccess,
                ...reqData
            },
        } = action;

        const result = yield request.post(`${controller}/clients/addViews`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: NOTIFICATION_ADD_VIEWS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: NOTIFICATION_ADD_VIEWS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: NOTIFICATION_ADD_VIEWS_ERROR, payload: error});
    }
};

//GET /api/notification/clients/unreadCount
export const getNotificationUnreadCountSaga = function* ({payload: params}) {
    try {
        const result = yield request.get(`${controller}/clients/unreadCount`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: NOTIFICATION_GET_UNREAD_COUNT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({type: NOTIFICATION_GET_UNREAD_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: NOTIFICATION_GET_UNREAD_COUNT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(NOTIFICATION_GET_PAGE_REQUEST, getPageNotificationSaga),
        takeEvery(NOTIFICATION_ADD_VIEW_REQUEST, addViewNotificationSaga),
        takeEvery(NOTIFICATION_ADD_VIEWS_REQUEST, addViewsNotificationSaga),
        takeEvery(NOTIFICATION_GET_UNREAD_COUNT_REQUEST, getNotificationUnreadCountSaga),
    ]);
}
