import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdPaymentSelector = state => state.bff.crowd.payment;
export const bffCrowdPaymentListSelector  = createSelector(bffCrowdPaymentSelector, ({list}) => list);
export const bffCrowdPaymentTotalCountSelector  = createSelector(bffCrowdPaymentSelector, ({totalCount}) => totalCount);
export const bffCrowdPaymentTotalPagesSelector  = createSelector(bffCrowdPaymentSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdPaymentProgressSelector  = createSelector(bffCrowdPaymentSelector, ({progress}) => progress);
export const bffCrowdPaymentProgressActionSelector  = createSelector(bffCrowdPaymentSelector, ({progressAction}) => progressAction);
export const bffCrowdPaymentTotalAmountSelector  = createSelector(bffCrowdPaymentSelector, ({totalAmount}) => totalAmount);
export const bffCrowdPaymentCardSelector  = createSelector(bffCrowdPaymentSelector, ({card}) => card);
export const bffCrowdPaymentLogListSelector  = createSelector(bffCrowdPaymentSelector, ({logList}) => logList);
export const bffCrowdPaymentLogTotalCountSelector  = createSelector(bffCrowdPaymentSelector, ({logTotalCount}) => logTotalCount);
export const bffCrowdPaymentLogTotalPagesSelector  = createSelector(bffCrowdPaymentSelector, ({logTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logTotalCount, pageSize));
export const bffCrowdPaymentLogProgressSelector  = createSelector(bffCrowdPaymentSelector, ({logProgress}) => logProgress);
export const bffCrowdPaymentsExistsSelector  = createSelector(bffCrowdPaymentSelector, ({paymentsExists}) => paymentsExists);
