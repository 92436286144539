import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {KedoOrganizationStructure} from "../../index";

import {updateKedoDepartmentsStore} from "../../../../../ducks/kedo/departments/actionCreators";

export const OrganizationStructureDepartmentCard = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateKedoDepartmentsStore({
                card: {},
            }));
        };
    }, []);

    return (
        <KedoOrganizationStructure
            isRootDepartment={false}
            {...props}
        />
    );
};