import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "adm/recruitment/jobBoards";

// GET /bff/adm/recruitment/jobBoards
// Получить список джоб-бордов клиента
const getActiveRecruitmentJobBoards = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/active`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/jobBoards/connectUrl
// Получить url для авторизации на джоб-борд
const getRecruitmentJobBoardsConnectUrl = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/connectUrl`, {params: requestData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR,
        });
    }
};

// /bff/adm/recruitment/jobBoards/authorize
// Авторизовать пользователя по коду авторизации от джоб-борда
const authorizeRecruitmentJobBoards = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/authorize`, requestData);
        const {errorMessage, successMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (successMessage) {
            toastSuccess(successMessage);
        }

        onSuccess(result);

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST, getActiveRecruitmentJobBoards),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST, getRecruitmentJobBoardsConnectUrl),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST, authorizeRecruitmentJobBoards),
    ]);
}