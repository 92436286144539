import {
    BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_ERROR,
    BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_REQUEST,
    BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_SUCCESS,
    BFF_FINANCES_NPD_SUMMARY_REPORTS_ERROR,
    BFF_FINANCES_NPD_SUMMARY_REPORTS_REQUEST,
    BFF_FINANCES_NPD_SUMMARY_REPORTS_SUCCESS,
    BFF_FINANCES_NPD_SUMMARY_UPDATE_STORE,
} from "./actions";

const initialState = {
    reports: [],
    progressReports: false,
    progress: false,
    totalSummary: {},
    totalCount: 0,
    pageData: {},
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_SUCCESS:
        case BFF_FINANCES_NPD_SUMMARY_DEPOSIT_HISTORY_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_FINANCES_NPD_SUMMARY_REPORTS_REQUEST:
            const {pageNum, pageSize} = payload;
            return {
                ...state,
                pageData: {
                    pageNum,
                    pageSize,
                },
                progressReports: true,
            };
        case BFF_FINANCES_NPD_SUMMARY_REPORTS_SUCCESS:
            const {
                results: reports,
                totalCount,
                totals: totalSummary,
            } = payload;

            return {
                ...state,
                progressReports: false,
                reports,
                totalSummary,
                totalCount,
            };
        case BFF_FINANCES_NPD_SUMMARY_REPORTS_ERROR:
            return {
                ...state,
                progressReports: false,
            };
        case BFF_FINANCES_NPD_SUMMARY_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
