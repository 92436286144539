export const BFF_EXPORT_REPORTS_GET_PAGE_REQUEST = "BFF_EXPORT_REPORTS_GET_PAGE_REQUEST";
export const BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS = "BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS";
export const BFF_EXPORT_REPORTS_GET_PAGE_ERROR = "BFF_EXPORT_REPORTS_GET_PAGE_ERROR";

export const BFF_EXPORT_REPORTS_ADD_REQUEST = "BFF_EXPORT_REPORTS_ADD_REQUEST";
export const BFF_EXPORT_REPORTS_ADD_SUCCESS = "BFF_EXPORT_REPORTS_ADD_SUCCESS";
export const BFF_EXPORT_REPORTS_ADD_ERROR = "BFF_EXPORT_REPORTS_ADD_ERROR";

export const BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST = "BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_REQUEST";
export const BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS = "BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS";
export const BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_ERROR = "BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_ERROR";

export const UPDATE_EXPORT_REPORTS_STORE = "UPDATE_EXPORT_REPORTS_STORE";