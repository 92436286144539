export const REGISTRY_PAYMENT_GET_PAGE_REQUEST = "REGISTRY_PAYMENT_GET_PAGE_REQUEST";
export const REGISTRY_PAYMENT_GET_PAGE_SUCCESS = "REGISTRY_PAYMENT_GET_PAGE_SUCCESS";
export const REGISTRY_PAYMENT_GET_PAGE_ERROR = "REGISTRY_PAYMENT_GET_PAGE_ERROR";

export const REGISTRY_PAYMENT_ARCHIVE_REQUEST = "REGISTRY_PAYMENT_ARCHIVE_REQUEST";
export const REGISTRY_PAYMENT_ARCHIVE_SUCCESS = "REGISTRY_PAYMENT_ARCHIVE_SUCCESS";
export const REGISTRY_PAYMENT_ARCHIVE_ERROR = "REGISTRY_PAYMENT_ARCHIVE_ERROR";

export const REGISTRY_PAYMENT_PAY_REQUEST = "REGISTRY_PAYMENT_PAY_REQUEST";
export const REGISTRY_PAYMENT_PAY_SUCCESS = "REGISTRY_PAYMENT_PAY_SUCCESS";
export const REGISTRY_PAYMENT_PAY_ERROR = "REGISTRY_PAYMENT_PAY_ERROR";

export const REGISTRY_PAYMENT_UPDATE_STORE = "REGISTRY_PAYMENT_UPDATE_STORE";