import {
    ADD_SPECIALTY_REQUEST,
    CLEAR_STORE_SPECIALITIES,
    DELETE_SPECIALTY_REQUEST,
    GET_PAGE_SPECIALITIES_REQUEST,
    UPDATE_SPECIALTY_REQUEST,
} from "./actions";

export function getSpecialitiesPage(payload) {
    return {
        type: GET_PAGE_SPECIALITIES_REQUEST,
        payload,
    };
}

export function updateSpecialty(payload) {
    return {
        type: UPDATE_SPECIALTY_REQUEST,
        payload,
    };
}

export function addSpecialty(payload) {
    return {
        type: ADD_SPECIALTY_REQUEST,
        payload,
    };
}

export function deleteSpecialty(payload) {
    return {
        type: DELETE_SPECIALTY_REQUEST,
        payload,
    };
}

export function clearStoreSpecialities() {
    return {
        type: CLEAR_STORE_SPECIALITIES,
    };
}