import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNestedObjectValues, useFormik} from "formik";
import * as yup from "yup";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import dateFormat from "../../../../../utils/dateFormat";
import {removeOgrnipMask} from "../../../../../utils/stringFormat";
import {convertStringToDate, handleFormString} from "../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../utils/toastHelper";
import {validateOgrnipWithYup} from "../../../../../utils/validate";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {
    updateContractorIndividualEntrepreneurLegalInfo,
} from "../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {contractorPersonalDataProgressActionSelector} from "../../../../../ducks/bff/contractor-сard/personal-data/selectors";

const ContractorIndividualEntrepreneurDataEditModal = (props) => {
    const {
        onClose,
        contractorId,
        card,
        fetchContractorInfo,
    } = props;

    const progress = useSelector(contractorPersonalDataProgressActionSelector);

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        const {
            registrationIssuedDateTime,
            ...otherValues
        } = card?.individualEntrepreneurLegalInfo || {};

        return {
            ogrnip: "",
            registrationIssuedBy: "",
            registrationIssuedDateTime: convertStringToDate(registrationIssuedDateTime) || null,
            ...otherValues,
        };
    }, [
        card,
    ]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(updateContractorIndividualEntrepreneurLegalInfo({
            contractorId,
            ogrnip: handleFormString(removeOgrnipMask(values.ogrnip)),
            registrationIssuedDateTime: dateFormat(values.registrationIssuedDateTime, "yyyy-MM-dd"),
            registrationIssuedBy: handleFormString(values.registrationIssuedBy),
            onSuccess: () => {
                toastSuccess("Данные ИП обновлены");

                if (fetchContractorInfo) {
                    fetchContractorInfo();
                }

                if (onClose) {
                    onClose();
                }
            },
        }));
    };

    const {
        handleSubmit,
        values,
        handleChange,
        setFieldValue,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        initialTouched: setNestedObjectValues(initialValues, false),
        validationSchema: yup.object().shape({
            ogrnip: yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .transform(removeOgrnipMask)
                .length(15, "Доступен ввод 15 цифр")
                .test("ogrnip", "Невалидное значение", validateOgrnipWithYup)
            ,
            registrationIssuedBy: yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .min(3, "Минимум 3 символа")
                .max(1000 , "Не более 1000 символов")
            ,
            registrationIssuedDateTime: yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        validateOnBlur: false,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle
                    size="lg"
                >
                    Редактирование данных ИП
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    size="xl"
                    className="col-md-16"
                    label="ИНН"
                    placeholder="ИНН"
                    value={card.inn}
                    onChange={handleChange}
                    disabled={true}
                />
                <NmInputV2
                    size="xl"
                    label="ОГРНИП"
                    required={true}
                    placeholder="Введите ОГРНИП"
                    name="ogrnip"
                    mask="9-99-99-999999999-9"
                    maskChar={null}
                    value={values.ogrnip}
                    onChange={handleChange}
                    error={touched.ogrnip && errors.ogrnip}
                />
                <NmDatePicker
                    size="xl"
                    placeholder="Введите дату выдачи ИП"
                    dateFormatMask="99.99.9999"
                    label="Дата выдачи"
                    required={true}
                    name="registrationIssuedDateTime"
                    selected={values.registrationIssuedDateTime}
                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                    error={touched.registrationIssuedDateTime && errors.registrationIssuedDateTime}
                />
                <NmInputV2
                    size="xl"
                    label="Орган выдачи"
                    required={true}
                    placeholder="Введите орган выдачи ИП"
                    name="registrationIssuedBy"
                    value={values.registrationIssuedBy}
                    onChange={handleChange}
                    error={touched.registrationIssuedBy && errors.registrationIssuedBy}
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorIndividualEntrepreneurDataEditModal;