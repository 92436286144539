import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useConfirm, {closeConfirmAction, onConfirmAction, openConfirmAction} from "../../../../../hooks/useConfirm";

import {deleteDirectoriesCategorySpecialities} from "../../../../../ducks/bff/settings/directories/naimix/category-specialities/actionCreators";
import {settingsDirectoriesNaimixCategorySpecialitiesProgressActionSelector} from "../../../../../ducks/bff/settings/directories/naimix/category-specialities/selectors";

export const useCategorySpecialitiesAction = () => {
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const dispatch = useDispatch();
    const progress = useSelector(settingsDirectoriesNaimixCategorySpecialitiesProgressActionSelector);

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const onOpenEdit = (editData) => {
        if (editData) {
            setEditData(editData);
        }

        setOpenEdit(true);
    };

    const onDelete = (id, onClose) => {
        dispatch(deleteDirectoriesCategorySpecialities({
            id,
            onSuccess: onClose,
        }));
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({id}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы уверены что хотите удалить запись справочника?",
                ({onClose}) => onDelete(id, onClose),
            ),
        );
    };

    const onCancelConfirm = () => {
        dispatchConfirm(closeConfirmAction());
    };

    return {
        isOpenEdit,
        progress,
        editData,
        isOpenConfirm,
        contentConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    };
};