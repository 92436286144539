import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import useDataForm from "./hooks/useDataForm";
import useReadFormWarning from "./hooks/useReadFormWarning";

import {formatDateWithoutTime} from "../../../../utils/dateFormat";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {CONTRACTOR_CARD_FIELDS} from "../../../../constants/contractorInfo";

import {pendingPersonalDataSelector} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";

import "./style.sass";

function EmploymentPatent2(props) {
    const {
        isEditable,
        className,
        card,
    } = props;

    const {data} = useSelector(pendingPersonalDataSelector);
    const {pendingPersonalDataFields = []} = data || {};

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isEdit,
        getError,
        toggleCard,
        cancelEdit,
    } = useDataForm(card);

    const {
        errorsReadOnlyCard,
    } = useReadFormWarning(values, card);

    const renderText = (value) => {
        return value || "Не указан";
    };

    const hasBorder = () => CONTRACTOR_CARD_FIELDS.EMPLOYMENT_PATENT_INFO.some(field => pendingPersonalDataFields.includes(field));

    const renderCardReadOnly = () => {
        return (
            <>
                <LabelTextApp
                    className="col-16"
                    label="Серия"
                    text={renderText(values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES])}
                />
                <LabelTextApp
                    className="col-16 mt-3 mt-md-4"
                    label="Номер"
                    text={renderText(values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM])}
                />
                <LabelTextApp
                    className="col-16 mt-3 mt-md-4"
                    label="Регистрационный номер"
                    text={renderText(values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER])}
                />
                <LabelTextApp
                    className="col-16 mt-3 mt-md-4"
                    label="Дата выдачи"
                    text={renderText(formatDateWithoutTime(values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]))}
                />
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm
                addMargin
                className="fluid"
            >
                <NmInputV2
                    size="lg"
                    required
                    className="employment-patent__input"
                    label="Серия"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES}
                    value={values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]}
                    error={getError(CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES)}
                />
                <NmInputV2
                    size="lg"
                    required
                    className="employment-patent__input"
                    name={CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM}
                    label="Номер"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]}
                    error={getError(CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM)}
                />
                <NmInputV2
                    size="lg"
                    required
                    className="employment-patent__input"
                    name={CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER}
                    label="Регистрационный номер"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]}
                    error={getError(CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER)}
                />
                <NmDatePicker
                    size="lg"
                    label="Дата выдачи"
                    dateFormatMask="99.99.9999"
                    maxDate={new Date()}
                    className="employment-patent__input"
                    name={CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE}
                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE, true)}
                    selected={values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]}
                    isClearable
                    error={getError(CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE)}
                />
            </NmForm>
        );
    };

    return (
        <CardApp
            className={className}
            title="Патент на осуществление трудовой деятельности в России"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
            border={hasBorder()}
            error={errorsReadOnlyCard}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
}

export default EmploymentPatent2;