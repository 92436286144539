import {
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
    CLEAR_CONTRACTOR_TASK_HISTORY_STORE,
} from "./actions";

export const getPageContractorTaskHistory = (payload) => {
    return {
        type: BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorTaskHistory = () => {
    return {
        type: CLEAR_CONTRACTOR_TASK_HISTORY_STORE,
    };
};