import {
    GET_BITRIX_LEAD_BY_ID_REQUEST,
    SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST,
    SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST,
    SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST,
    UPDATE_SETTINGS_JOINING_REQUESTS_STORE,
} from "./actions";

export function getStatusesByIdsSettingsJoiningRequests(payload) {
    return {
        type: SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST,
        payload,
    };
}

export function getPageSettingsJoiningRequests(payload) {
    return {
        type: SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST,
        payload,
    };
}

export function confirmSettingsJoiningRequests(payload) {
    return {
        type: SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST,
        payload,
    };
}

export function declineSettingsJoiningRequests(payload) {
    return {
        type: SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST,
        payload,
    };
}

export function getBitrixLeadById(payload) {
    return {
        type: GET_BITRIX_LEAD_BY_ID_REQUEST,
        payload,
    };
}

export function exportSettingsJoiningRequests(payload) {
    return {
        type: SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST,
        payload,
    };
}

export const updateStoreSettingsJoiningRequests = (payload) => {
    return {
        type: UPDATE_SETTINGS_JOINING_REQUESTS_STORE,
        payload,
    };
};