import {getTaskListPage} from "../../../task/actionCreators";

import {getBffUrl} from "../../../../utils/url";

import {
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
} from "../../../../constants/links";

const controllers = {
    naimix: "/client-adm/tasks/naimix/my-tasks",
    kedo: "/client-adm/kedo/naimix/my-tasks",
};

export const getClientsTaskListPage = (payload) => {
    const controller = getBffUrl(
        {
            [LINK_TASK_LIST_NAIMIX_MY]: controllers.naimix,
            [LINK_TASK_LIST_KEDO_MY]: controllers.kedo,
        },
        controllers.naimix,
    );

    return getTaskListPage({
        url: `${controller}/page`,
        ...payload,
    });
};