import {
    ADD_COLLECTION_BANK_DETAILS_REQUEST,
    DELETE_COLLECTION_BANK_DETAILS_REQUEST,
    GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST,
    UPDATE_COLLECTION_BANK_DETAILS_REQUEST,
    UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE,
} from "./actions";

export function getPageCollectionBankDetails(payload) {
    return {
        type: GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function addCollectionBankDetails(payload) {
    return {
        type: ADD_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function updateCollectionBankDetails(payload) {
    return {
        type: UPDATE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function deleteCollectionBankDetails(payload) {
    return {
        type: DELETE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export const updateStoreSettingsCollectionBankDetails = (payload) => {
    return {
        type: UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE,
        payload,
    };
};