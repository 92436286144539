import {
    GET_BITRIX_LEAD_BY_ID_SUCCESS,
    SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR,
    SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST,
    SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS,
    SETTINGS_JOINING_REQUESTS_EXPORT_ERROR,
    SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST,
    SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST,
    SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS,
    UPDATE_SETTINGS_JOINING_REQUESTS_STORE} from "./actions";

const initial = {
    progress: null,
    errorMessage: "",
    progressGetPage: false,
    pageData: {},
    totalCount: null,
    list: [],
    clientApplicationStatuses: [],
    bitrixLeads: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS:
            return {
                ...state,
                clientApplicationStatuses: payload,
            };
        case SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progressGetPage: true,
            };
        }
        case SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS: {
            const {applications, totalCount} = payload;

            return {
                ...state,
                list: applications,
                totalCount,
                progressGetPage: false,
            };
        }
        case SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR: {
            return {
                ...state,
                progressGetPage: false,
                errorMessage: payload,
            };
        }
        case SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST:
        case SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST: {
            return {
                ...state,
                progress: true,
            };
        }
        case SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS:
        case SETTINGS_JOINING_REQUESTS_EXPORT_ERROR:
        case SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS:
        case SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case UPDATE_SETTINGS_JOINING_REQUESTS_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_BITRIX_LEAD_BY_ID_SUCCESS: {
            return {
                ...state,
                bitrixLeads: [
                    ...state.bitrixLeads,
                    payload.result,
                ],
            };
        }
        default:
            return state;
    }
};