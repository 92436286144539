import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {getUserRole} from "../../../../utils/access";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {getPageCrowdPaymentAdm} from "../../../../ducks/bff/adm/crowd-payments/services";
import {getPageCrowdPaymentClient} from "../../../../ducks/bff/clients/crowd-payments/services";
import {
    checkExistsCrowdPayments,
    updateStoreCrowdPayment,
} from "../../../../ducks/bff/crowd/payment/actionCreators";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

import {
    STATUS_TASK_PAYMENT,
    TASK_PAYMENTS_SORT_TYPE,
} from "../../../../constants/crowd/task-payment";

const useFinanceCrowdPaymentsFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
        clientId,
        paymentNumberFilter,
    } = props;

    const dispatch = useDispatch();

    const role = getUserRole();

    useEffect(() => {
        if (
            Boolean(clientId)
            && [
                ADMIN,
                CLIENT_ADMIN,
                CLIENT_ACCOUNTANT,
                PROJECT_MANAGER,
            ].includes(role)
        ) {
            dispatch(checkExistsCrowdPayments({
                clientIds: [clientId],
                statuses: [STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE],
            }));

            dispatch(getClientUserRestrictionsList({
                restrictions: [
                    CLIENT_USER_RESTRICTIONS_VARIABLE.repeatFailedCrowdPayments,
                ],
            }));
        }

        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdPayments,
            ],
        }));

        return () => {
            dispatch(updateStoreCrowdPayment({
                pageData: {},
                list: [],
                totalCount: 0,
                paymentsExists: false,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter, clientId, paymentNumberFilter]);

    const fetchList = () => {
        const clientIdsFilter = filter.clientId ? [filter.clientId] : undefined;

        const reqData = {
            pageNum,
            pageSize,
            sortingType: clientId ? TASK_PAYMENTS_SORT_TYPE.CONTRACTOR_NPD_PAYMENTS : undefined,
            clientIds: clientId ? [clientId] : clientIdsFilter,
            paymentNumber: !isEmpty(filter) ? filter.paymentNumber : paymentNumberFilter,
            ...filter,
        };

        if (clientId) {
            dispatch(getPageCrowdPaymentClient(reqData));

            return;
        }

        dispatch(getPageCrowdPaymentAdm(reqData));
    };

    return {
        fetchList,
    };
};

export default useFinanceCrowdPaymentsFetch;