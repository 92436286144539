import {useFilter} from "../../../../hooks/useFilter";

import {filterEmptyValues} from "../../../../utils/objectHelper";

export const useKedoOrganizationStructureFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        mapFilterDto: filterEmptyValues,
        pageSize,
        setPagination,
    });

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
    };
};