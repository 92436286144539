import {
    ADD_DIRECTORIES_STOP_WORDS_REQUEST,
    CLEAR_FIELDS_DIRECTORIES_STOP_WORDS,
    DELETE_DIRECTORIES_STOP_WORDS_REQUEST,
    GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST,
    UPDATE_DIRECTORIES_STOP_WORDS_REQUEST,
} from "./actions";

export function getDirectoriesStopWordsPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST,
        payload,
    };
}

export function updateDirectoriesStopWords(payload) {
    return {
        type: UPDATE_DIRECTORIES_STOP_WORDS_REQUEST,
        payload,
    };
}

export function addDirectoriesStopWords(payload) {
    return {
        type: ADD_DIRECTORIES_STOP_WORDS_REQUEST,
        payload,
    };
}

export function deleteDirectoriesStopWords(payload) {
    return {
        type: DELETE_DIRECTORIES_STOP_WORDS_REQUEST,
        payload,
    };
}

export function clearFieldsDirectoriesStopWords() {
    return {
        type: CLEAR_FIELDS_DIRECTORIES_STOP_WORDS,
    };
}