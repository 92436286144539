import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import dateFormat from "../../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../../utils/objectHelper";
import {getFullName, removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {ORDER_WORK_REPORT_TYPE} from "../../../../../constants/finance";
import {FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE} from "../../../../../constants/frameContractExternal";

import {getClientObjectList} from "../../../../../ducks/clientObject";
import {
    addClientFrameContractExternal,
    searchContractorByInnOrPhone,
} from "../../../../../ducks/companyDocumentsRegistry";

export const useFrameContractExternalEditFormData = ({clientId, onClose, fetchList}) => {
    const [searchBlockInfo, setSearchBlockInfo] = useState({
        isSearch: false,
        fio: "",
        error: "",
    });

    const dispatch = useDispatch();

    const initialValues = {
        contractType: ORDER_WORK_REPORT_TYPE.SMZ,
        contractNumber: "",
        contractSignDate: null,
        phone: "",
        inn: "",
        objectId: null,
        searchType: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
    };

    const fetchObjects = () => {
        const {
            valueObjectFilter,
            contractType,
        } = values;

        const isNdlf = contractType === ORDER_WORK_REPORT_TYPE.CIVIL;

        dispatch(getClientObjectList({
            clientId,
            nameSubstringFilter: handleFormString(valueObjectFilter),
            smzFrameContractEnabled: !isNdlf ? true : undefined,
            civilFrameContractEnabled: isNdlf ? true : undefined,
            pageNum: 1,
            pageSize: 100,
        }));
    };

    const onSearchChangeObject = (valueObjectFilter) => {
        setFieldValue("valueObjectFilter", valueObjectFilter);
    };

    const handleChange = (e, {name, value, checked}) => {
        if (name === "searchType") {
            setValues({
                ...values,
                phone: "",
                inn: "",
            });
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    function onSubmit() {
        if (!isValid) {
            return;
        }

        const {
            contractorId,
            objectId,
            useObjectId,
            contractType,
            contractNumber,
            contractSignDate,
            signingBeforeCheckPersonalData,
            signingWithoutTaxpayerAndPartner,
            signingOnlyWithSmzTaxOffer,
        } = values;

        dispatch(addClientFrameContractExternal({
            clientId,
            contractorId,
            objectId: useObjectId ? objectId : undefined,
            contractType,
            contractNumber: handleFormString(contractNumber),
            contractSignDate: dateFormat(contractSignDate, "yyyy-MM-dd"),
            signingBeforeCheckPersonalData,
            signingWithoutTaxpayerAndPartner: contractType === ORDER_WORK_REPORT_TYPE.SMZ ?
                signingWithoutTaxpayerAndPartner :
                null,
            signingOnlyWithSmzTaxOffer: contractType === ORDER_WORK_REPORT_TYPE.SMZ ?
                signingOnlyWithSmzTaxOffer :
                null,
            onSuccess: () => {
                toastSuccess("Реестр на подписание доп. соглашения \nс исполнителем успешно создан. Исполнителю будет " +
                    "отправлено \nдоп. соглашение на подписание. \nПосле подписания доп. соглашение \nк внешнему РД станет " +
                    "доступно в разделе \n\"Документы → Реестр документов\"");

                fetchList();
                onClose();
            },
        }));
    }

    function searchContractor() {
        const {
            inn,
            phone,
        } = values;

        setTouched({
            ...touched,
            inn: true,
            phone: true,
        });

        if (
            (!phone && !inn) ||
            (phone && errors.phone) ||
            (inn && errors.inn)
        ) {
            return;
        }

        dispatch(searchContractorByInnOrPhone(filterEmptyValues({
            inn,
            phone: removePhoneMask(phone),
            getResult: ({result, errorMessage}) => {
                if (result?.contractorId) {
                    const {
                        contractorId,
                        firstName,
                        lastName,
                        patronymic,
                        fullName,
                    } = result;

                    setFieldValue("contractorId", contractorId);
                    setSearchBlockInfo({
                        isSearch: true,
                        fio: lastName ? getFullName(lastName, firstName, patronymic) : fullName,
                        error: "",
                    });

                    return;
                }

                if (values.contractorId) {
                    setFieldValue("contractorId", null);
                }

                setSearchBlockInfo({
                    fio: "",
                    isSearch: true,
                    error: errorMessage || "Исполнитель не найден на площадке",
                });
            },
        })));
    }

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        validateField,
        setFieldTouched,
        setTouched,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    useEffect(() => {
        fetchObjects();
    }, [values.valueObjectFilter, values.contractType]);

    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        touched,
        onSearchChangeObject,
        searchContractor,
        searchBlockInfo,
        validateField,
        setFieldTouched,
    };
};