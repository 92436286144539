import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import validate from "../../../../../../utils/validate";

import {ORDER_CATEGORY_FIELD} from "../../../../../../constants/orderCategory";
import {ADMIN} from "../../../../../../constants/roles";
import {categoryRule} from "../../../../../../constants/validationRules";

import {
    addClientCategories,
    updateClientCategories,
} from "../../../../../../ducks/bff/settings/directories/naimix/client-categories/actionCreators";
import {
    getSettingsDirectoriesNaimixClientCategoriesProgressActionSelector,
} from "../../../../../../ducks/bff/settings/directories/naimix/client-categories/selectors";

const defaultItem = {
    id: null,
    value: null,
    [ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT]: 100000,
    [ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT]: 100000,
    [ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT]: 200000,
};

const SettingsDirectoriesOrderCategoryListEdit = (props) => {
    const {
        onClose,
        editData,
    } = props;
    const progress = useSelector(getSettingsDirectoriesNaimixClientCategoriesProgressActionSelector);
    const [form, setForm] = useState({
        ...defaultItem,
        ...editData,
    });
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const isEdit = !isNullOrWhitespace(editData.id);

    const {t} = useTranslation();
    const role = ls(USER_ROLE);

    const createCategory = () => {
        const {
            value,
            limitPerTransaction,
            limitPerDay,
            limitPerMonth,
        } = form;

        if (validateCategoryFields({
            value,
            limitPerTransaction,
            limitPerDay,
            limitPerMonth,
        })) {
            dispatch(addClientCategories({
                value,
                limitPerTransaction,
                limitPerDay,
                limitPerMonth,
            }));
        }
    };

    const updateCategory = () => {
        const {
            id,
            value,
            limitPerTransaction,
            limitPerDay,
            limitPerMonth,
        } = form;

        if (validateCategoryFields({
            value,
            limitPerTransaction,
            limitPerDay,
            limitPerMonth,
        })) {
            dispatch(updateClientCategories({
                orderCategoryUuid: id,
                orderCategory: {
                    value,
                    limitPerTransaction,
                    limitPerDay,
                    limitPerMonth,
                },
            }));
        }
    };

    const validateCategoryFields = (category) => {
        const errorMessage = validate(category, categoryRule, "");

        setError({...errorMessage});

        return Object.values(errorMessage).length === 0;
    };

    const onChange = (event, {value, name}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? t("order-categories.edit-category") : t("order-categories.new-category")}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    align="right"
                    onClose={onClose}
                    disabled={progress}
                    submitBtnContent={isEdit ? t("button.save") : t("button.add")}
                    submit={isEdit ? updateCategory : createCategory}
                    cancelBtnContent={t("button.cancel")}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    label={t("order-categories.category")}
                    error={error[ORDER_CATEGORY_FIELD.NAME]}
                    name={ORDER_CATEGORY_FIELD.NAME}
                    value={form[ORDER_CATEGORY_FIELD.NAME]}
                    onChange={onChange}
                    placeholder="Введите категорию"
                />
                <NmInputV2
                    size="xl"
                    label={t("order-categories.transaction-limit")}
                    error={error[ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT]}
                    name={ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT}
                    mask="999999999999"
                    disabled={role !== ADMIN}
                    maskChar={null}
                    value={form[ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT]}
                    onChange={onChange}
                />
                <NmInputV2
                    size="xl"
                    label={t("order-categories.day-transaction-limit")}
                    error={error[ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT]}
                    name={ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT}
                    mask="999999999999"
                    disabled={role !== ADMIN}
                    maskChar={null}
                    value={form[ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT]}
                    onChange={onChange}
                />
                <NmInputV2
                    size="xl"
                    label={t("order-categories.month-transaction-limit")}
                    error={error[ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT]}
                    mask="999999999999"
                    disabled={role !== ADMIN}
                    maskChar={null}
                    name={ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT}
                    value={form[ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT]}
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
};

export default SettingsDirectoriesOrderCategoryListEdit;