import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import RejectionReason from "../../../components/RejectionReason";
import {ReactComponent as HistoryIcon} from "../../../images/history_24.svg";
import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import {ReactComponent as ResetIcon} from "../../../images/reset_24.svg";
import CrowdTaskActCreation from "../task-registry/card/components/act-creation";
import CrowdTaskCardContractorRejectModal from "../task-registry/card/components/contractor-reject-modal";
import CrowdTaskContractorInfo from "../task-registry/components/contractor-info";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import useCrowdTaskRegistryFilter from "../task-registry/hooks/useFilter";
import useCrowdTaskAnalyticsAction from "./hooks/useAction";
import useCrowdTaskAnalyticsFetch from "./hooks/useFetch";

import {addDays, convertUtcToLocal, formatLocalDate, getStartDate} from "../../../utils/dateFormat";
import {getTimeFromMins} from "../../../utils/mathHelper";
import {formatAmount} from "../../../utils/stringFormat";
import {getTimeColor} from "../task-registry/utils/getTimeColor";

import {COLOR} from "../../../constants/color";
import {
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
} from "../../../constants/links";

import {history} from "../../../store/configureStore";

import {bffCrowdTasksCardProgressActionSelector} from "../../../ducks/bff/crowd/taskCard/selectors";
import {
    bffCrowdTasksAnalyticsCountAggregationSelector,
    bffCrowdTasksAnalyticsCountersProgressSelector,
    bffCrowdTasksAnalyticsListSelector,
    bffCrowdTasksAnalyticsProgressSelector,
    bffCrowdTasksAnalyticsTotalCountSelector,
    bffCrowdTasksAnalyticsTotalPagesSelector,
} from "../../../ducks/bff/crowd/tasksAnalytics/selectors";

import {STATUS_TASK_PAYMENT} from "../../../constants/crowd/task-payment";
import {
    SUB_PAGE_CROWD_TASK_ANALYTICS,
    SUB_PAGE_CROWD_TASK_CARD,
} from "../../../constants/link-params";

const CrowdTaskAnalytics = (props) => {
    const {
        match: {
            params: {
                subpage,
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffCrowdTasksAnalyticsListSelector);
    const totalCount = useSelector(bffCrowdTasksAnalyticsTotalCountSelector);
    const totalPages = useSelector(bffCrowdTasksAnalyticsTotalPagesSelector);
    const progress = useSelector(bffCrowdTasksAnalyticsProgressSelector);
    const countersProgress = useSelector(bffCrowdTasksAnalyticsCountersProgressSelector);
    const progressAction = useSelector(bffCrowdTasksCardProgressActionSelector);
    const countAggregation = useSelector(bffCrowdTasksAnalyticsCountAggregationSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
        filters,
        setFilter,
    } = useCrowdTaskRegistryFilter({
        clientId,
        pageSize,
        setPagination,
    });

    const {
        fetchInfo,
        sortType,
        setSortType,
    } = useCrowdTaskAnalyticsFetch({
        clientId,
        subpage,
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        sortOptions,
        onClickSort,
        getMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
        createActModalData,
        setCreateActModalData,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
    } = useCrowdTaskAnalyticsAction({
        clientId,
        fetchInfo,
        sortType,
        setSortType,
        subpage,
        onOpenConfirm,
        onCloseConfirm,
    });

    const getProjectLink = ({clientId, projectData}) => {
        const {
            id,
            value,
        } = projectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_TASK_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", id);

        return (
            <ExtLink
                title={value}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const getObjectLink = ({clientId, projectData, objectData}) => {
        const {
            id,
            value,
        } = objectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectData?.id)
            .replace(":objectId", id);

        return (
            <ExtLink
                title={value}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const onClickTaskLink = (item) => {
        const {taskId} = item;

        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", taskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        history.push(link);
    };

    const getSecondaryHeader = (item) => {
        const {
            creationDateTime,
            workStartDate,
            workEndDate,
        } = item;

        const labels = [
            {
                label: "Проект",
                text: getProjectLink(item),
                visible: subpage !== SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK,
            },
            {
                label: "Объект",
                text: getObjectLink(item),
                visible: subpage !== SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK,
            },
            {
                label: "Задание от",
                text: formatLocalDate(creationDateTime, "dd.MM.yyyy HH:mm"),
                visible: subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK,
            },
            {
                label: "Период выполнения работ",
                text: `${formatLocalDate(workStartDate, "dd.MM.yyyy")} - ${formatLocalDate(workEndDate, "dd.MM.yyyy")}`,
                visible: true,
            },
        ].filter(({visible}) => visible);

        return (
            <div className="flex">
                {
                    labels.map(({label, text, visible}) => {
                        return (
                            <NmLabelText
                                className="me-2"
                                type="list"
                                label={label}
                                text={text}
                            />
                        );
                    })
                }
            </div>
        );
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                taskId,
                name,
                number,
                timeForExecutionMinutes,
                paymentAmount,
                contractorData,
                contractSigningProposedAtDateTime,
                workStartDate,
                taskWorkStartedDateTime,
                taskWorkFinishedDateTime,
                paymentData,
            } = item;

            const isContractSigningMoreDay = getStartDate(addDays(new Date(contractSigningProposedAtDateTime), 1)).getTime() < getStartDate(new Date()).getTime();
            const isTodayWorkStartDate = getStartDate(workStartDate) < new Date();
            const workTimeNowMinutes = Math.floor((new Date() - convertUtcToLocal(new Date(taskWorkStartedDateTime))) / 60000);
            const isExecutionTimeWork = workTimeNowMinutes > timeForExecutionMinutes;
            const timeColor = getTimeColor(workTimeNowMinutes, taskWorkFinishedDateTime, timeForExecutionMinutes);

            const noResponsesLabels = subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK ?
                [
                    {
                        label: "Проект",
                        text: getProjectLink(item),
                    },
                    {
                        label: "Объект",
                        text: getObjectLink(item),
                    },
                    timeForExecutionMinutes && {
                        label: "Время выполнения",
                        text: getTimeFromMins(timeForExecutionMinutes),
                    },
                    paymentAmount && {
                        label: "Сумма задания",
                        text: formatAmount(paymentAmount, true),
                    },
                ] :
                [];

            const inPaymentLabels = subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK && !isEmpty(paymentData) ?
                [
                    {
                        label: "Оплата по заданию",
                        text: paymentData?.paymentNumber,
                    },
                    paymentData?.createDate && {
                        label: "Дата отправки акта",
                        text: `${formatLocalDate(paymentData.createDate, "dd.MM.yyyy HH:mm")} по МСК`,
                    },
                    paymentData?.status && {
                        label: "Статус",
                        text: `${STATUS_TASK_PAYMENT[paymentData.status]?.TEXT} ${
                            ![STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE].includes(paymentData.status) ?
                                formatLocalDate(paymentData.updateDate, "dd.MM.yyyy HH:mm") :
                                ""
                        }`,
                        color: STATUS_TASK_PAYMENT[paymentData.status]?.COLOR,
                    },
                ] :
                [];

            return {
                ...item,
                key: taskId,
                contentRow: (
                    <NmListCard
                        noDivider
                        className="align-items-start"
                        secondaryHeader={getSecondaryHeader(item)}
                        primaryHeader={`№${number} - ${name}`}
                        primaryHeaderLink={true}
                        onClickLink={() => onClickTaskLink(item)}
                        classNameMainContent="col-16 col-xxl-9"
                        labels={[
                            ...noResponsesLabels,
                            subpage !== SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK &&
                            contractorData && {
                                label: "Исполнитель",
                                text: <CrowdTaskContractorInfo
                                    contractor={contractorData}
                                    isShowLink={true}
                                    isMiniRatingInfo={true}
                                />,
                                textOverflowUnset: true,
                            },
                            subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.SIGN_CONTRACT.LINK &&
                            contractSigningProposedAtDateTime && {
                                text: <div className="flex align-items-center mt-2">
                                    <Text
                                        level="2"
                                        color={
                                            isContractSigningMoreDay ?
                                                COLOR.NEGATIVE_100 :
                                                COLOR.PRIMARY_100
                                        }
                                    >
                                        Подписывает договор
                                        с 
                                        {" "}
                                        {formatLocalDate(new Date(contractSigningProposedAtDateTime), "dd.MM.yyyy HH:mm")}
                                    </Text>
                                    {
                                        isContractSigningMoreDay &&
                                        <NmAdvancedTooltip
                                            className="ms-1"
                                            trigger={
                                                <InfoIcon
                                                    color={COLOR.NEGATIVE_100}
                                                    height={16}
                                                    width={16}
                                                />
                                            }
                                            position="bottom-left"
                                            hover
                                        >
                                            С момента отправки договора прошло более суток
                                        </NmAdvancedTooltip>
                                    }
                                </div>,
                                textOverflowUnset: true,
                            },
                            subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.HIRED.LINK && {
                                text: <Text
                                    className="mt-2"
                                    level="2"
                                    color={isTodayWorkStartDate && COLOR.PRIMARY_100}
                                >
                                    {
                                        isTodayWorkStartDate ?
                                            "Задание ждет выполнения" :
                                            `Начнет выполнение с ${formatLocalDate(workStartDate, "dd.MM.yyyy")}`
                                    }
                                </Text>,
                                textOverflowUnset: true,
                            },
                            subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.IN_WORK.LINK && {
                                text: <NmAdvancedTooltip
                                    children={
                                        isExecutionTimeWork &&
                                        "С момента начала выполнения прошло больше времени, чем указано в задании"
                                    }
                                    classNameTooltip="avatar-my-contractor-indicator__tooltip"
                                    hover
                                    position="bottom-left"
                                    trigger={
                                        <Text
                                            className="mt-2"
                                            level="2"
                                            color={timeColor}
                                        >
                                            Выполняет 
                                            {" "}
                                            {getTimeFromMins(workTimeNowMinutes)}
                                            <HistoryIcon
                                                className="ms-1"
                                                width={16}
                                                height={16}
                                                color={timeColor}
                                            />
                                        </Text>
                                    }
                                />,
                                textOverflowUnset: true,
                            },
                            subpage === SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK && {
                                label: "Выполнил",
                                text: `${formatLocalDate(taskWorkFinishedDateTime, "dd.MM.yyyy HH:mm")} по МСК`,
                            },
                            ...inPaymentLabels,
                        ]}
                        actionsClassName="col-1 col-xxl-7 justify-content-end align-items-start"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const getTabs = () => {
        return Object.values(SUB_PAGE_CROWD_TASK_ANALYTICS)
            .map((item) => {
                const {
                    TEXT,
                    LINK,
                    COUNT_NAME,
                } = item;

                return {
                    name: TEXT,
                    link: LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", LINK),
                    active: subpage === LINK,
                    count: countAggregation[COUNT_NAME] || 0,
                };
            });
    };

    const renderCardContractorRejectModal = () => {
        return (
            !isEmpty(contractorRejectModalData) &&
            <CrowdTaskCardContractorRejectModal
                onClose={() => setContractorRejectModalData({})}
                fetchInfo={fetchInfo}
                {...contractorRejectModalData}
            />
        );
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            onCancel,
            confirmButton = "Подтвердить",
            cancelButton = "Отменить",
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onClose={onCloseConfirm}
                onCancel={onCancel ? onCancel : onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderActCreationModal = () => {
        return (
            !isEmpty(createActModalData) &&
            <CrowdTaskActCreation
                clientId={createActModalData.clientId}
                taskId={createActModalData.taskId}
                taskCard={createActModalData}
                onClose={() => setCreateActModalData({})}
                fetchInfo={fetchInfo}
                {...createActModalData}
            />
        );
    };

    const rejectReasonModal = () => {
        return (
            rejectReasonModalData.isOpen &&
            <RejectionReason
                title="Причина отклонения"
                placeholder="Введите причину отклонения оплаты"
                close={() => setRejectReasonModal({})}
                submit={(rejectReason) => {
                    rejectPayment({
                        paymentId: rejectReasonModalData.paymentId,
                        rejectReason,
                    });
                }}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                >
                    Аналитика по заданиям
                </NmTitle>
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    updateFilter={(filter) => setFilter(filter)}
                />
            }
            controls={
                <NmButton
                    onlyIcon={true}
                    icon={<ResetIcon />}
                    onClick={fetchInfo}
                    disabled={progress}
                    size="xl"
                    color="grey"
                />
            }
            subHeader={
                <Tabs
                    {...props}
                    panes={getTabs()}
                />
            }
            isLoaded={progress || countersProgress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderCardContractorRejectModal()}
            {renderConfirm()}
            {renderActCreationModal()}
            {rejectReasonModal()}
            {
                !list.length ?
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        title="Данные отсутствуют"
                        fetchProgress={progress}
                    /> :
                    <CheckboxList
                        sort
                        sortOptions={sortOptions}
                        onClickSort={onClickSort}
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskAnalytics;