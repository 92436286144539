import {
    BFF_GET_TIMESHEET_METRICS_REQUEST,
    BFF_GET_TIMESHEET_PAGE_REQUEST,
    BFF_TIMESHEETS_CLEAR_STORE,
} from "./actions";

export const getForemenTimesheetPage = (payload) => {
    return {
        type: BFF_GET_TIMESHEET_PAGE_REQUEST,
        payload,
    };
};

export const getForemenTimesheetMetrics = (payload) => {
    return {
        type: BFF_GET_TIMESHEET_METRICS_REQUEST,
        payload,
    };
};

export const clearTimesheetsStore = () => {
    return {
        type: BFF_TIMESHEETS_CLEAR_STORE,
    };
};