import {
    CLEAR_CONTRACTOR_DOCUMENT_SIGNING_STORE,
    CONTRACTOR_DOCUMENT_SIGNING_GET_PAGE_REQUEST,
} from "./actions";

export const getPageContractorDocumentSigning = (payload) => {
    return {
        type: CONTRACTOR_DOCUMENT_SIGNING_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorDocumentSigning = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_DOCUMENT_SIGNING_STORE,
        payload,
    };
};