import React, {useEffect, useRef} from "react";
import {useMedia} from "react-media";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import {ReactComponent as IconAlert} from "../../images/alert_24.svg";
import {ReactComponent as IconCancel} from "../../images/cancel_24.svg";
import {ChevronIcon} from "../ActualComponents/ChevronIcon";
import {InputWithButtonsRow} from "../ActualComponents/InputWithButtonsRow";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmInputV2 from "../ActualComponents/NmInputV2";
import Text from "../ActualComponents/Text";
import NmBadge from "../NmBadge";
import NmButton from "../NmButton";
import {SmartLinkViewAllNumbers} from "./components/all-numbers";
import {SmartLinkImportOrderNumbersFromXlsx} from "./components/import-from-xlsx";
import {SmartLinkNotLoadedNumbers} from "./components/not-loaded-numbers";

import {useModal} from "../../hooks/useModal";

import {removePhoneMask} from "../../utils/stringFormat";

import {COLOR} from "../../constants/color";

export const ImportMultipleValuesForm = (props) => {
    const {
        onChange,
        inputLabel,
        inputMask,
        addMask,
        errorNumbers,
        nums,
        checkExists,
        fieldName = "nums",
        maxShowedCount = 12,
        showDeleteAllCount = 2,
        clearSuccessNumbers,
        inputPlaceholder,
        warningCount = 250,
        progress,
        headerAllNumbers,
        importFromFile,
        patternLink,
        progressImport,
        error,
        updateError,
        successNumbers,
        onChangeFileInput,
        getValidation,
        deletingConfirmText,
        requiredMessage,
        setIsHiddenModal,
        duplicateErrorMessage = "Номер уже добавлен",
        confirmTextDeleteAll,
    } = props;

    const fileInputRef = useRef(null);
    const isMobile = useMedia({query: {maxWidth: 767}});

    const isVisibleDeleteAll = nums.length >= showDeleteAllCount;

    useEffect(() => {
        if (!isEmpty(successNumbers)) {
            onChange(null, {name: fieldName, value: [...new Set([...nums, ...successNumbers])]});
        }
    }, [successNumbers]);

    const modal = useModal();

    const {
        modalData,
    } = modal;

    const onOpenModal = (data) => {
        modal.onOpenModal(data);

        if (setIsHiddenModal) {
            setIsHiddenModal(true);
        }
    };

    const onCloseModal = () => {
        modal.onCloseModal();

        if (setIsHiddenModal) {
            setIsHiddenModal(false);
        }
    };

    const baseValidation = yup.string().required(requiredMessage);

    const {
        handleSubmit,
        values,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues: {
            number: "",
        },
        validationSchema: yup.object().shape({
            number: getValidation
                ? getValidation(
                    baseValidation,
                )
                : baseValidation,
        }),
        enableReinitialize: true,
    });

    useEffect(() => {
        if (error) {
            updateError({
                error: null,
            });

            setError(error);
        }
    }, [error]);

    const setError = async (error) => {
        await setFieldTouched("number", true);

        setFieldError("number", error);
    };

    const clearError = async () => {
        await setFieldTouched("number", false);

        setFieldError("number", undefined);
    };

    function onSubmit({number}) {
        if (nums.map(item => removePhoneMask(item)).includes(removePhoneMask(number))) {
            setFieldError("number", duplicateErrorMessage);

            return;
        }

        checkExists({
            number,
            saveNumber: async ({success}) => {
                if (success) {
                    onChange(null, {
                        name: fieldName,
                        value: [
                            ...nums,
                            addMask ? addMask(values.number) : values.number,
                        ],
                    });

                    await setFieldTouched("number", false);
                    await setFieldValue("number", "", false);
                }
            },
        });
    }

    const onClickDelete = (index, num) => {
        const deleteNumber = () => {
            const _nums = [...nums];

            _nums.splice(index, 1);

            onChange(null, {name: fieldName, value: _nums, isDeleting: true});
        };

        if (deletingConfirmText) {
            onOpenModal({
                otherModalsData: modalData,
                isOpenConfirm: true,
                confirmProps: {
                    content: deletingConfirmText.replace(":num", num),
                    onConfirm: () => {
                        deleteNumber(index);
                    },
                },
            });

            return;
        }

        deleteNumber();
    };

    const _clearNumbers = () => {
        if (clearSuccessNumbers) {
            clearSuccessNumbers();
        }
        // dispatch(updateSmartLinkStore({
        //     numbers: [],
        // }));
        onChange(null, {name: fieldName, value: []});
    };

    const getNums = () => {
        return nums.slice(0, maxShowedCount)
            .map((num, index) => {
                return (
                    <NmBadge
                        key={num}
                        className="mt-2 me-2"
                        text={num}
                        mod="gray"
                        rightIcon={
                            <IconCancel
                                cursor="pointer"
                                title="Удалить"
                                height={16}
                                width={16}
                                color={COLOR.SECONDARY_45}
                                onClick={() => {
                                    onClickDelete(index, num);
                                }}
                            />
                        }
                    />
                );
            });
    };

    const getViewAll = () => {
        return nums.length > maxShowedCount ?
            <div className="d-flex align-items-center mt-2 me-2">
                <Text
                    isCursorPointer={true}
                    level="2"
                    color={COLOR.PASSIVE_100}
                    onClick={() => {
                        onOpenModal({
                            isOpenViewAllOrderNumbers: true,
                        });

                        if (setIsHiddenModal) {
                            setIsHiddenModal(true);
                        }
                    }}
                >
                    Показать все
                </Text>
            </div> :
            null;
    };

    if (modalData?.isOpenConfirm) {
        return (
            <NmConfirmV2
                content={modalData.confirmProps.content}
                isNeedClosing={modalData.confirmProps.isNeedClosing}
                onCancel={() => {
                    const {
                        otherModalsData,
                    } = modalData;

                    if (isEmpty(otherModalsData)) {
                        onCloseModal();

                        return;
                    }

                    onOpenModal(otherModalsData);
                }}
                onConfirm={modalData.confirmProps.onConfirm}
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    }

    const onClickDeleteAll = (params = {}) => {
        const {
            isNeedClosing,
        } = params;

        const onConfirm = async (isNeedClosing) => {
            _clearNumbers();

            if (isNeedClosing) {
                onCloseModal();
            }

            await clearError();
        };

        if (confirmTextDeleteAll) {
            onOpenModal({
                otherModalsData: modalData,
                isOpenConfirm: true,
                confirmProps: {
                    isNeedClosing: false,
                    content: confirmTextDeleteAll,
                    onConfirm: async () => {
                        await onConfirm(true);
                    },
                },
            });

            return;
        }

        onConfirm(isNeedClosing);
    };

    if (modalData?.isOpenViewAllOrderNumbers) {
        return (
            <SmartLinkViewAllNumbers
                onClose={onCloseModal}
                clearError={clearError}
                nums={nums}
                headerAllNumbers={headerAllNumbers}
                onClickDelete={onClickDelete}
                onClickDeleteAll={() => {
                    onClickDeleteAll({
                        isNeedClosing: true,
                    });
                }}
            />
        );
    }

    if (modalData?.isOpenNotLoadedNumbers) {
        return (
            <SmartLinkNotLoadedNumbers
                errorNumbers={errorNumbers}
                onClose={onCloseModal}
                label={inputLabel}
            />
        );
    }

    if (modalData?.isOpenImport) {
        return (
            <SmartLinkImportOrderNumbersFromXlsx
                importFromFile={importFromFile}
                progress={progressImport}
                onClose={onCloseModal}
                patternLink={patternLink}
            />
        );
    }

    const getDeleteAll = () => {
        return isVisibleDeleteAll &&
            <NmButton
                className="col-16 col-md-auto mt-3 mt-md-0"
                color="grey"
                onClick={() => {
                    onClickDeleteAll({
                        isNeedClosing: false,
                    });
                }}
            >
                Удалить все номера
            </NmButton>;
    };

    const getErrorNumbersMessage = () => {
        return !isEmpty(errorNumbers) &&
            <div className="col-16 d-flex justify-content-between justify-content-md-start align-items-center">
                <div className="d-flex align-items-center ms-md-4 me-2">
                    <IconAlert
                        color={COLOR.INERT_100}
                        width={20}
                        height={20}
                    />
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_90}
                        className="ms-1"
                    >
                        Есть незагруженные номера
                    </Text>
                </div>
                {
                    isMobile ?
                        <ChevronIcon
                            width={20}
                            height={20}
                            color={COLOR.SECONDARY_45}
                            rotate={270}
                            className="ms-1"
                            onClick={() => {
                                onOpenModal({
                                    isOpenNotLoadedNumbers: true,
                                });

                                if (setIsHiddenModal) {
                                    setIsHiddenModal(true);
                                }
                            }}
                        /> :
                        <Text
                            isCursorPointer={true}
                            level="2"
                            color={COLOR.PASSIVE_100}
                            onClick={() => {
                                onOpenModal({
                                    isOpenNotLoadedNumbers: true,
                                });

                                if (setIsHiddenModal) {
                                    setIsHiddenModal(true);
                                }
                            }}
                        >
                            Посмотреть
                        </Text>
                }
            </div>;
    };

    return (
        <div>
            <InputWithButtonsRow
                className="mb-1"
                input={
                    <NmInputV2
                        required={true}
                        size="xl"
                        mask={inputMask}
                        maskChar={null}
                        label={inputLabel}
                        value={values.number}
                        placeholder={inputPlaceholder}
                        onChange={(event, {value}) => {
                            setFieldValue("number", value);
                        }}
                        error={errors.number && touched.number ? errors.number : undefined}
                    />
                }
                buttons={
                    <div className="d-flex flex-column flex-md-row flex-grow-1">
                        <NmButton
                            size="xl"
                            color="light-green"
                            className="mb-2 mb-md-0 me-md-2 mx-md-2"
                            type="button"
                            onClick={handleSubmit}
                            disabled={progress || nums.length === warningCount}
                        >
                            Добавить
                        </NmButton>
                        <NmButton
                            size="xl"
                            color="grey"
                            onClick={() => {
                                if (onChangeFileInput) {
                                    fileInputRef.current.click();

                                    return;
                                }

                                onOpenModal({
                                    isOpenImport: true,
                                });

                                if (setIsHiddenModal) {
                                    setIsHiddenModal(true);
                                }
                            }}
                        >
                            Загрузить XLSX
                        </NmButton>
                    </div>
                }
            >
                {
                    onChangeFileInput &&
                    <input
                        ref={fileInputRef}
                        type="file"
                        hidden
                        accept=".xls, .xlsx"
                        onChange={onChangeFileInput}
                    />
                }
            </InputWithButtonsRow>
            {
                !isEmpty(nums) &&
                <div className="d-flex flex-wrap">
                    {getNums()}
                    {getViewAll()}
                </div>
            }
            {
                (!isEmpty(errorNumbers) || isVisibleDeleteAll) &&
                <>
                    <div className="d-flex flex-column-reverse flex-md-row col-16 align-items-center mt-2">
                        {getDeleteAll()}
                        {getErrorNumbersMessage()}
                    </div>
                </>
            }
        </div>
    );
};