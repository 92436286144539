import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getUtcDateFilter} from "../../../../../utils/dateFormat";

import {
    getDictionariesTaskStatuses,
    getDictionariesTaskTypes,
    updateCommonPaymentDicts,
} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {
    getSettingsServiceTaskStats,
    updateStoreSettingsServiceTaskStats,
} from "../../../../../ducks/bff/settings/service/task-stats/actionCreators";

export const useSettingsServiceTaskStatsFetch = ({
    filterData,
    pageNum,
    pageSize,
    usageCountSort,
}) => {

    const {
        creationDateTimeFrom,
        creationDateTimeTo,
        dateTimeGrouping,
        taskStatuses,
        taskTypes,
    } = filterData;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        usageCountSort,
    ]);

    useEffect(() => {
        getDictionaries();

        return () => {
            dispatch(updateStoreSettingsServiceTaskStats({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
            dispatch(updateCommonPaymentDicts({
                taskStatuses: [],
                serviceTaskTypes: [],
            }));
        };
    }, []);

    const getDictionaries = () => {
        dispatch(getDictionariesTaskStatuses());
        dispatch(getDictionariesTaskTypes());
    };

    const fetchList = () => {
        dispatch(getSettingsServiceTaskStats({
            dateTimeFrom: getUtcDateFilter(creationDateTimeFrom),
            dateTimeGrouping,
            dateTimeTo: getUtcDateFilter(creationDateTimeTo),
            orderType: usageCountSort,
            pageNum,
            pageSize,
            taskStatuses,
            taskTypes,
        }));
    };

    return {
        fetchList,
    };
};