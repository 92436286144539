import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import {KedoTemplateDocumentTypesDropdown} from "../../../../../../components/KedoTemplateDocumentTypesDropdown";
import {KedoUploadedDocumentTypesDropdown} from "../../../../../../components/KedoUploadedDocumentTypesDropdown";
import NmTitle from "../../../../../../components/NmTitle";

import {COLOR} from "../../../../../../constants/color";

import {
    getKedoDocumentRoutesAllTypes,
    updateKedoDocumentRoutesAllTypes,
} from "../../../../../../ducks/kedo/directories/actionCreators";
import {
    kedoDirectoriesDocumentRouteTypesSelector,
    kedoDirectoriesProgressActionSelector,
} from "../../../../../../ducks/kedo/directories/selectors";

export const KedoDocumentRouteEditTypesModal = (props) => {
    const {
        routeId,
        onClose,
        routeName,
    } = props;

    const dispatch = useDispatch();
    const {
        info,
        progress,
    } = useSelector(kedoDirectoriesDocumentRouteTypesSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const initial = useMemo(() => {
        return {
            values: {
                uploadedDocumentTypeIds: info?.uploadedDocumentTypeIds?.map(item => item.documentTypeId) || [],
                templateDocumentTypeIds: info?.templateDocumentTypeIds?.map(item => item.documentTypeId) || [],
            },
            options: {
                uploaded: info?.uploadedDocumentTypeIds?.map(item => {
                    return {
                        key: item.documentTypeId,
                        value: item.documentTypeId,
                        text: item.name,
                    };
                }),
                template: info?.templateDocumentTypeIds?.map(item => {
                    return {
                        key: item.documentTypeId,
                        value: item.documentTypeId,
                        text: item.name,
                    };
                }),
            },
        };
    }, [info]);

    useEffect(() => {
        dispatch(getKedoDocumentRoutesAllTypes({routeId}));
    }, [routeId]);

    const onSubmit = () => {
        dispatch(updateKedoDocumentRoutesAllTypes({
            routeId,
            uploadedDocumentTypeIds: values.uploadedDocumentTypeIds,
            templateDocumentTypeIds: values.templateDocumentTypeIds,
        }));
    };

    const onChange = (_event, {name, value}) => {
        setFieldValue(name, value);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initial.values,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Редактирование типов документов
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            loading={progress}
            classNameContent="flex-column"
        >
            <Text.Title
                color={COLOR.SECONDARY_100}
                level="4"
                className="mb-6"
            >
                {routeName}
            </Text.Title>
            <NmForm addMargin={true}>
                <KedoUploadedDocumentTypesDropdown
                    multiple={true}
                    name="uploadedDocumentTypeIds"
                    value={values.uploadedDocumentTypeIds}
                    onChange={onChange}
                    initialOptions={initial.options.uploaded}
                />
                <KedoTemplateDocumentTypesDropdown
                    multiple={true}
                    name="templateDocumentTypeIds"
                    value={values.templateDocumentTypeIds}
                    onChange={onChange}
                    initialOptions={initial.options.template}
                />
            </NmForm>
        </NmModal>
    );
};