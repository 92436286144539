import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import {useModal} from "../../../../hooks/useModal";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {getUserRole} from "../../../../utils/access";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {NM_CHIEF_ACCOUNTANT, NM_OPERATOR} from "../../../../constants/roles";

import {
    archiveCrowdActRegistry,
    deleteCrowdActRegistry,
    unarchiveCrowdActRegistry,
} from "../../../../ducks/bff/crowd/actRegistry/actionCreators";
import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

import {CROWD_ACT_REGISTRY_STATUS} from "../../../../constants/crowd/act-registry";

export const useCrowdTaskActRegistryAction = (props) => {
    const {
        clientId,
        fetchList,
        archivedFilter,
        setArchivedFilter,
        progress,
        setPagination,
        pageSize,
    } = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const role = getUserRole();
    const isAccessAction = ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)
        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdActRegistries);

    const getMediaControls = () => {
        const archive = getArchiveButton(t, archivedFilter, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: () => onOpenModal({
                            isOpenEdit: true,
                            isEdit: false,
                        }),
                        icon: <AddIcon />,
                        children: "Добавить реестр",
                    },
                    visible: isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progress,
                        onClick: () => {
                            setArchivedFilter(value => !value);
                            setPagination({
                                pageNum: 1,
                                pageSize,
                            });
                        },
                    },
                },
            ],
        };
    };

    const getListItemMediaControls = (item) => {
        const {
            registryId,
            archived,
            status,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenEdit: true,
                            isEdit: true,
                            data: item,
                        }),
                        children: "Редактировать",
                    },
                    visible: isAccessAction
                        && status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            content: "Вы подтверждаете, что хотите удалить реестр актов по заданиям?",
                            confirmButton: "Подтверждаю",
                            cancelButton: "Отмена",
                            onConfirm: () => deleteRegistry(registryId),
                        }),
                        children: "Удалить",
                    },
                    visible: isAccessAction
                        && status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            content: archived ?
                                "Вы уверены, что хотите перенести реестр актов по заданиям из архива?" :
                                "Вы уверены, что хотите перенести реестр актов по заданиям в архив?",
                            confirmButton: "Подтверждаю",
                            cancelButton: "Отмена",
                            onConfirm: () => archiveRegistry(item),
                        }),
                        children: archived ? "Из архива" : "В архив",
                    },
                    visible: isAccessAction
                        && (
                            status === CROWD_ACT_REGISTRY_STATUS.DONE.VALUE
                            || archived
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenLogModal: true,
                            clientId,
                            registryId: item.registryId,
                            number: item.registryNumber,
                            name: item.name,
                        }),
                        children: "История изменений",
                    },
                    visible: isAccessAction,
                },
            ],
        };
    };

    const deleteRegistry = (registryId) => {
        dispatch(deleteCrowdActRegistry({
            registryId,
            onSuccess: () => {
                fetchList();
                onCloseModal();
            },
        }));
    };

    const archiveRegistry = ({registryId, archived}) => {
        if (archived) {
            dispatch(unarchiveCrowdActRegistry({
                registryId,
                onSuccess: () => {
                    fetchList();
                    onCloseModal();
                },
            }));

            return;
        }

        dispatch(archiveCrowdActRegistry({
            registryId,
            onSuccess: () => {
                fetchList();
                onCloseModal();
            },
        }));
    };

    return {
        getMediaControls,
        getListItemMediaControls,
        modalData,
        onOpenModal,
        onCloseModal,
    };
};