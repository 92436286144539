import {
    BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST,
    BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST,
} from "./actions";

export const getPatentsSummary = (payload) => {
    return {
        type: BFF_FINANCES_PATENTS_SUMMARY_LIST_REQUEST,
        payload,
    };
};

export function getPatentsTotalSummary(payload) {
    return {
        type: BFF_FINANCES_PATENTS_TOTAL_SUMMARY_REQUEST,
        payload,
    };
}
