import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

import {strapiSalesManagersSelector} from "../../../strapi";

export const bffSettingsSettingsJoiningRequestsSelector = state => state.bff.settingsJoiningRequests;
export const bffSettingsSettingsJoiningRequestsProgressSelector = createSelector(bffSettingsSettingsJoiningRequestsSelector, ({progress}) => progress);
export const bffSettingsSettingsJoiningRequestsTotalPagesSelector = createSelector(bffSettingsSettingsJoiningRequestsSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const bffSettingsSettingsJoiningRequestsTotalCountSelector = createSelector(bffSettingsSettingsJoiningRequestsSelector, ({totalCount}) => totalCount);
export const bffSettingsSettingsJoiningRequestsPageSelector = createSelector(
    [
        strapiSalesManagersSelector,
        bffSettingsSettingsJoiningRequestsSelector,
    ],
    (salesManagers, settingsJoiningRequests) => {
        const {
            bitrixLeads,
            list,
            clientApplicationStatuses,
        } = settingsJoiningRequests;

        return list.map((item) => {
            const {
                applicationId,
                bitrixLeadId,
            } = item;

            const {
                ASSIGNED_BY_ID,
            } = bitrixLeads
                .filter(item => Boolean(item))
                .find(item => (item.id === bitrixLeadId)) || {};

            const managerInfo = ASSIGNED_BY_ID
                ? salesManagers.find(item => (item.attributes.bitrix_user_id === ASSIGNED_BY_ID)) || {}
                : {};

            const {
                name: managerName,
            } = managerInfo;

            const applicationStatusInfo = clientApplicationStatuses.find(item => (item.applicationId === applicationId)) || {};

            return {
                ...item,
                ...applicationStatusInfo,
                managerName,
            };
        });
    },
);
export const bffSettingsSettingsJoiningRequestsPageProgressSelector = createSelector(bffSettingsSettingsJoiningRequestsSelector, ({progressGetPage}) => progressGetPage);
export const bitrixLeadsSelector = createSelector(bffSettingsSettingsJoiningRequestsSelector, ({bitrixLeads}) => bitrixLeads);