import {
    BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE,
    BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE,
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR,
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST,
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_GET_MONITORING_RISKS_ERROR,
    BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS, BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR,
    BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST,
    BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    listLoading: false,
    identificationRisk: false,
    lastExportDateTime: null,
    contractorsList: [],
    contractorsListLoading: false,
    contractorsListTotalCount: 0,
    contractorsListPageData: {},
    startedExport: false,
    generateReportLoading: false,
    updateDataLoading: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST:
            return {
                ...state,
                listLoading: true,
            };
        case BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                list: payload.risks,
                identificationRisk: payload.identificationRisk,
                lastExportDateTime: payload.lastExportDateTime,
                startedExport: payload.startedExport,
            };
        case BFF_CLIENTS_GET_MONITORING_RISKS_ERROR:
            return {
                ...state,
                listLoading: false,
            };
        case BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST:
            return {
                ...state,
                contractorsListLoading: true,
                contractorsListPageData: payload,
            };
        case BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS:
            return {
                ...state,
                contractorsListLoading: false,
                contractorsList: payload.results,
                contractorsListTotalCount: payload.totalCount,
            };
        case BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR:
            return {
                ...state,
                contractorsListLoading: false,
            };
        case BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST:
            return {
                ...state,
                generateReportLoading: true,
            };
        case BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST:
            return {
                ...state,
                updateDataLoading: true,
            };
        case BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS:
        case BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR:
            return {
                ...state,
                updateDataLoading: false,
            };
        case BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS:
        case BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR:
            return {
                ...state,
                generateReportLoading: false,
            };
        case BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE:
            return {
                ...state,
                contractorsListLoading: false,
                contractorsList: [],
                contractorsListPageData: {},
                contractorsListTotalCount: 0,
            };
        case BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE:
            return initialState;
        default: return state;
    }
};