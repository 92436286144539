import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDatePicker from "../../components/ActualComponents/NmDatePicker";
import NmForm from "../../components/ActualComponents/NmForm";
import CardApp from "../../components/CardApp";
import NmButton from "../../components/NmButton";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import {
    Divider,
} from "semantic-ui-react";

import dateFormat from "../../utils/dateFormat";
import {toastSuccess} from "../../utils/toastHelper";
import validate from "../../utils/validate";

import {formatFileDict, UPLOAD_TYPE} from "../../constants/financeExport";
import {financeUploadRule} from "../../constants/validationRules";

import {addExportOneC} from "../../ducks/bff/one-c/actionCreators";
import {bffClientCardReportsOneCActionSelector} from "../../ducks/bff/one-c/selectors";

import "./style.sass";

class FinanceUpload extends Component {
    constructor() {
        super();

        this.state = {
            startPeriodDate: null,
            endPeriodDate: null,
            formError: {},
        };
    }

    submit = () => {
        const {addExportOneC} = this.props;

        const {startPeriodDate, endPeriodDate} = this.state;

        const isValid = this.validationForm({startPeriodDate, endPeriodDate}, financeUploadRule);

        if (!isValid) {
            return;
        }

        addExportOneC({
            uploadType: UPLOAD_TYPE.ATOL_RECEIPTS_EXPORT.value,
            toDate: dateFormat(endPeriodDate, "yyyy-MM-dd"),
            fromDate:dateFormat(startPeriodDate, "yyyy-MM-dd"),
            formatType: formatFileDict.XML,
            getResult: () => {
                toastSuccess("Экспорт чеков Атол запущен. Скачать файл по готовности можно в разделе \"Экспорт\"");
            },
        });
    };

    validationForm(formData, rule) {
        const errorMessage = validate(formData, rule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };


    handleOnChangeDate = (event, {name, value}) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const {
            formError,
            endPeriodDate,
            startPeriodDate,
        } = this.state;
        const {
            progress,
            t,
        } = this.props;

        return (
            <NmPage
                header={
                    <NmTitle size="xl">
                        {t("finance-upload.finance-upload-title")}
                    </NmTitle>
                }
            >
                <div className="finance-upload__container row">
                    <div className="col-md-5">
                        <CardApp
                            title=""
                            className="contractor-card"
                            onClickIcon={this.togglePassportInfoEdit}
                        >
                            <NmForm
                                addMargin
                                className="container fluid"
                            >
                                <NmDatePicker
                                    size="lg"
                                    label={t("finance-upload.date-from")}
                                    maxDate={endPeriodDate || new Date()}
                                    required
                                    placeholderText={t("finance-upload.date-from")}
                                    selected={startPeriodDate}
                                    error={formError.startPeriodDate}
                                    name="startPeriodDate"
                                    dateFormatMask="99.99.9999"
                                    onChange={this.handleOnChangeDate}
                                />
                                <NmDatePicker
                                    size="lg"
                                    label={t("finance-upload.date-to")}
                                    minDate={startPeriodDate}
                                    maxDate={new Date()}
                                    required
                                    placeholderText={t("finance-upload.date-to")}
                                    error={formError.endPeriodDate}
                                    placeholder={t("finance-upload.date-to")}
                                    selected={endPeriodDate}
                                    name="endPeriodDate"
                                    dateFormatMask="99.99.9999"
                                    onChange={this.handleOnChangeDate}
                                />
                                <Divider className="divider-fluid" />
                                <div className="flex flex-content-end">
                                    <NmButton
                                        size="xl"
                                        onClick={this.submit}
                                        disabled={progress}
                                    >
                                        {t("finance-upload.unload-button")}
                                    </NmButton>
                                </div>
                            </NmForm>
                        </CardApp>
                    </div>
                </div>
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        progress: bffClientCardReportsOneCActionSelector(state),
    }),
    {
        addExportOneC,
    },
)(withTranslation()(FinanceUpload));
