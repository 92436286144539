import {
    ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    ADD_CONTRACTOR_EDUCATION_REQUEST,
    ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
    ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    CLEAR_CONTRACTOR_PROFILE_STORE,
    DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    DELETE_CONTRACTOR_EDUCATION_REQUEST,
    DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
    DELETE_CONTRACTOR_PORTFOLIO_REQUEST,
    DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
    GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST,
    GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
    GET_CONTRACTOR_PROFILE_REQUEST,
    GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST,
    GET_DRIVER_LICENSE_DICTIONARY_REQUEST,
    GET_EDUCATION_LEVEL_DICT_REQUEST,
    MERGE_CONTRACTOR_PROF_SKILLS_REQUEST,
    UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST,
    UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST,
    UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST,
    UPDATE_CONTRACTOR_EDUCATION_REQUEST,
    UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST,
    UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
    UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST,
    UPDATE_CONTRACTOR_MAIN_WORK_REQUEST,
    UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST,
    UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
} from "./actions";

export function clearContractorProfileStore() {
    return {
        type: CLEAR_CONTRACTOR_PROFILE_STORE,
    };
}

export function getContractorProfile(payload) {
    return {
        type: GET_CONTRACTOR_PROFILE_REQUEST,
        payload,
    };
}

export function getContractorLanguagesDict() {
    return {
        type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST,
    };
}

export function addContractorWorkExperience(payload) {
    return {
        type: ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function updateContractorWorkExperience(payload) {
    return {
        type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function deleteAllContractorWorkExperience(payload) {
    return {
        type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function deleteContractorWorkExperience(payload) {
    return {
        type: DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function getContractorEducationLevelDict() {
    return {
        type: GET_EDUCATION_LEVEL_DICT_REQUEST,
    };
}

export function updateContractorEducation(payload) {
    return {
        type: UPDATE_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function addContractorEducation(payload) {
    return {
        type: ADD_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function deleteContractorEducation(payload) {
    return {
        type: DELETE_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function getDriverLicenseDictionary() {
    return {
        type: GET_DRIVER_LICENSE_DICTIONARY_REQUEST,
    };
}

export function updateContractorDriverLicense(payload) {
    return {
        type: UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST,
        payload,
    };
}

export function updateContractorForeignLanguage(payload) {
    return {
        type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
        payload,
    };
}

export function deleteContractorForeignLanguage(payload) {
    return {
        type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
        payload,
    };
}

export function addContractorCourseCertificate(payload) {
    return {
        type: ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function updateContractorCourseCertificate(payload) {
    return {
        type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function addContractorCourseCertificateFile(payload) {
    return {
        type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
        payload,
    };
}

export function deleteContractorCourseCertificate(payload) {
    return {
        type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function getContractorCourseCertificateFile(payload) {
    return {
        type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
        payload,
    };
}

export function updateContractorFamilyStatus(payload) {
    return {
        type: UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST,
        payload,
    };
}

export function mergeContractorProfessionalSkills(payload) {
    return {
        type: MERGE_CONTRACTOR_PROF_SKILLS_REQUEST,
        payload,
    };
}

export function addContractorPortfolioFile(payload) {
    return {
        type: ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
        payload,
    };
}

export function getContractorPortfolioFile(payload) {
    return {
        type: GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
        payload,
    };
}

export function deleteContractorPortfolio(payload) {
    return {
        type: DELETE_CONTRACTOR_PORTFOLIO_REQUEST,
        payload,
    };
}

export function updateContractorWorkContraindication(payload) {
    return {
        type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST,
        payload,
    };
}

export function updateContractorAdditionalSpecialities(payload) {
    return {
        type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST,
        payload,
    };
}

export function updateContractorMainSpeciality(payload) {
    return {
        type: UPDATE_CONTRACTOR_MAIN_WORK_REQUEST,
        payload,
    };
}

export function updateDepartureAddresses(payload) {
    return {
        type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST,
        payload,
    };
}

export function updateContractorMainWorkAddress(payload) {
    return {
        type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST,
        payload,
    };
}

export function getContractorProfileResumeFile(payload) {
    return {
        type: GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST,
        payload,
    };
}