export const KEDO_DEPARTMENTS_GET_PAGE_REQUEST = "KEDO_DEPARTMENTS_GET_PAGE_REQUEST";
export const KEDO_DEPARTMENTS_GET_PAGE_SUCCESS = "KEDO_DEPARTMENTS_GET_PAGE_SUCCESS";
export const KEDO_DEPARTMENTS_GET_PAGE_ERROR = "KEDO_DEPARTMENTS_GET_PAGE_ERROR";

export const KEDO_DEPARTMENTS_GET_ROOT_REQUEST = "KEDO_DEPARTMENTS_GET_ROOT_REQUEST";
export const KEDO_DEPARTMENTS_GET_ROOT_SUCCESS = "KEDO_DEPARTMENTS_GET_ROOT_SUCCESS";
export const KEDO_DEPARTMENTS_GET_ROOT_ERROR = "KEDO_DEPARTMENTS_GET_ROOT_ERROR";

export const KEDO_DEPARTMENTS_GET_CARD_REQUEST = "KEDO_DEPARTMENTS_GET_CARD_REQUEST";
export const KEDO_DEPARTMENTS_GET_CARD_SUCCESS = "KEDO_DEPARTMENTS_GET_CARD_SUCCESS";
export const KEDO_DEPARTMENTS_GET_CARD_ERROR = "KEDO_DEPARTMENTS_GET_CARD_ERROR";

export const KEDO_DEPARTMENTS_DELETE_REQUEST = "KEDO_DEPARTMENTS_DELETE_REQUEST";
export const KEDO_DEPARTMENTS_DELETE_SUCCESS = "KEDO_DEPARTMENTS_DELETE_SUCCESS";
export const KEDO_DEPARTMENTS_DELETE_ERROR = "KEDO_DEPARTMENTS_DELETE_ERROR";

export const KEDO_DEPARTMENTS_IMPORT_REQUEST = "KEDO_DEPARTMENTS_IMPORT_REQUEST";
export const KEDO_DEPARTMENTS_IMPORT_SUCCESS = "KEDO_DEPARTMENTS_IMPORT_SUCCESS";
export const KEDO_DEPARTMENTS_IMPORT_ERROR = "KEDO_DEPARTMENTS_IMPORT_ERROR";

export const KEDO_DEPARTMENTS_ADD_REQUEST = "KEDO_DEPARTMENTS_ADD_REQUEST";
export const KEDO_DEPARTMENTS_ADD_SUCCESS = "KEDO_DEPARTMENTS_ADD_SUCCESS";
export const KEDO_DEPARTMENTS_ADD_ERROR = "KEDO_DEPARTMENTS_ADD_ERROR";

export const KEDO_DEPARTMENTS_UPDATE_REQUEST = "KEDO_DEPARTMENTS_UPDATE_REQUEST";
export const KEDO_DEPARTMENTS_UPDATE_SUCCESS = "KEDO_DEPARTMENTS_UPDATE_SUCCESS";
export const KEDO_DEPARTMENTS_UPDATE_ERROR = "KEDO_DEPARTMENTS_UPDATE_ERROR";

export const KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST = "KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST";
export const KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS = "KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS";
export const KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR = "KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR";

export const KEDO_DEPARTMENTS_LOGS_REQUEST = "KEDO_DEPARTMENTS_LOGS_REQUEST";
export const KEDO_DEPARTMENTS_LOGS_SUCCESS = "KEDO_DEPARTMENTS_LOGS_SUCCESS";
export const KEDO_DEPARTMENTS_LOGS_ERROR = "KEDO_DEPARTMENTS_LOGS_ERROR";

export const KEDO_DEPARTMENTS_CLEAR_LOGS = "KEDO_DEPARTMENTS_CLEAR_LOGS";

export const KEDO_DEPARTMENTS_CLEAR_STORE = "KEDO_DEPARTMENTS_CLEAR_STORE";

export const KEDO_DEPARTMENTS_UPDATE_STORE = "KEDO_DEPARTMENTS_UPDATE_STORE";