import {
    BFF_CROWD_TASK_GROUP_ADD_ERROR,
    BFF_CROWD_TASK_GROUP_ADD_REQUEST,
    BFF_CROWD_TASK_GROUP_ADD_SUCCESS,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_EXPORT_ERROR,
    BFF_CROWD_TASK_GROUP_EXPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_SUCCESS,
    BFF_CROWD_TASK_GROUP_IMPORT_ERROR,
    BFF_CROWD_TASK_GROUP_IMPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_REMOVE_ERROR,
    BFF_CROWD_TASK_GROUP_REMOVE_REQUEST,
    BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS,
    UPDATE_CROWD_TASK_GROUP_FILED_STORE,
} from "./actions";

const initial = {
    card: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressCard: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CROWD_TASK_GROUP_GET_REQUEST: {
            return {
                ...state,
                progressCard: true,
            };
        }
        case BFF_CROWD_TASK_GROUP_GET_SUCCESS: {
            return {
                ...state,
                card: payload.result,
                progressCard: false,
            };
        }
        case BFF_CROWD_TASK_GROUP_GET_ERROR: {
            return {
                ...state,
                progressCard: false,
            };
        }
        case BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST:
        case BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS:
        case BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR:
        case BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_CROWD_TASK_GROUP_IMPORT_REQUEST:
        case BFF_CROWD_TASK_GROUP_EXPORT_REQUEST:
        case BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST:
        case BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST:
        case BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST:
        case BFF_CROWD_TASK_GROUP_ADD_REQUEST:
        case BFF_CROWD_TASK_GROUP_REMOVE_REQUEST:
        case BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS:
        case BFF_CROWD_TASK_GROUP_IMPORT_ERROR:
        case BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS:
        case BFF_CROWD_TASK_GROUP_EXPORT_ERROR:
        case BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS:
        case BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR:
        case BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS:
        case BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR:
        case BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS:
        case BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR:
        case BFF_CROWD_TASK_GROUP_ADD_SUCCESS:
        case BFF_CROWD_TASK_GROUP_ADD_ERROR:
        case BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS:
        case BFF_CROWD_TASK_GROUP_REMOVE_ERROR:
        case BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS:
        case BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case UPDATE_CROWD_TASK_GROUP_FILED_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};