import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../components/ActualComponents/NmDadata";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import SelectPhoneCodeForm from "../../../components/SelectPhoneCodeForm";

import useContractorNewForm from "./hooks/useContractorNewForm";

import bem from "../../../utils/bem";
import {
    dadataValueCityFormatter,
    getOptionsUniqCity,
} from "../../../utils/dadata";
import {getPhoneMask} from "../../../utils/stringFormat";

import {bffCommonDictsPhoneCodeListSelector} from "../../../ducks/bff/common/dicts/selectors";
import {bffContractorsRegistryActionSelector} from "../../../ducks/bff/contractors/registry/selectors";
import {specialitiesAllV2OptionsSelector} from "../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

const ContractorNew = (props) => {
    const {
        handleCancel,
        className,
    } = props;

    const [block] = bem("new-contractor-window", className);

    const progressAdd = useSelector(bffContractorsRegistryActionSelector);
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const phoneCodeList = useSelector(bffCommonDictsPhoneCodeListSelector);

    const {t} = useTranslation();

    const {
        values,
        errors,
        handleChange,
        handleEnterPressed,
        handleSubmit,
        setFieldValue,
    } = useContractorNewForm();

    return (
        <NmModal
            size="md"
            onClose={handleCancel}
            header={
                <NmTitle size="lg">
                    {t("new-contractor-form.header")}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submit={handleSubmit}
                    onClose={handleCancel}
                    loading={progressAdd}
                    mobile="column"
                    disabled={progressAdd}
                    submitBtnContent={t("button.add")}
                    cancelBtnContent={t("button.cancel")}
                />
            }
        >
            <NmForm
                addMargin
                className={block()}
            >
                <NmInputV2
                    size="xl"
                    required
                    name="lastName"
                    placeholder="Введите фамилию"
                    error={errors.lastName}
                    value={values.lastName}
                    label={t("contractor-full-personal-data.last-name")}
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                />
                <NmInputV2
                    size="xl"
                    required
                    placeholder="Введите имя"
                    name="firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    label={t("contractor-full-personal-data.first-name")}
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                />
                <NmInputV2
                    size="xl"
                    placeholder="Введите отчество"
                    name="patronymic"
                    value={values.patronymic}
                    error={errors.patronymic}
                    label={t("contractor-full-personal-data.patronymic")}
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                />
                <SelectPhoneCodeForm
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                />
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            disabled={values.locatedOutsideRussia && !values.phoneCodeId}
                            mask={getPhoneMask(values.phoneCodeId, values.locatedOutsideRussia, phoneCodeList)}
                            maskChar={null}
                            required
                            placeholder="Введите телефон контактного лица"
                            name="phone"
                            error={errors.phone}
                            value={values.phone}
                            label={t("contractor-full-personal-data.phone-number")}
                            onChange={handleChange}
                            onKeyPress={handleEnterPressed}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-2 mt-md-0">
                        <NmInputV2
                            size="xl"
                            placeholder="Введите E-mail"
                            name="email"
                            error={errors.email}
                            label="E-mail"
                            onChange={handleChange}
                            value={values.email}
                            onKeyPress={handleEnterPressed}
                        />
                    </div>
                </div>
                <NmDropdownV2
                    size="xl"
                    name="mainSpecialityId"
                    value={values.mainSpecialityId}
                    error={errors.mainSpecialityId}
                    label="Основной вид деятельности"
                    search
                    options={specialityOptions}
                    placeholder="Выберите вид деятельности"
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                    required
                />
                <NmDadata
                    isClearSearchOnBlur
                    name="workAddressFiasId"
                    label="Город ведения деятельности"
                    error={errors.workAddressFiasId}
                    onChange={handleChange}
                    placeholder="Выберите город"
                    formatter={dadataValueCityFormatter}
                    optionsFormatter={getOptionsUniqCity}
                    isAllSelectionShown={false}
                    required
                    fromBound="city"
                    toBound="settlement"
                />
                <NmCheckboxV2
                    label="Работа удалённо"
                    checked={values.remoteWork}
                    name="remoteWork"
                    onChange={handleChange}
                />
                <NmDropdownV2
                    size="xl"
                    name="additionalSpecialityIds"
                    value={values.additionalSpecialityIds}
                    label="Дополнительные виды деятельности"
                    search
                    options={specialityOptions}
                    placeholder="Не выбрано"
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                    multiple
                />
                <NmDadata
                    isClearSearchOnBlur
                    name="departureAddressesFiasIds"
                    label="Города для выезда"
                    onChange={handleChange}
                    placeholder="Выберите города"
                    formatter={dadataValueCityFormatter}
                    optionsFormatter={getOptionsUniqCity}
                    isAllSelectionShown={false}
                    fromBound="city"
                    toBound="settlement"
                    multiple
                />
                <NmInputV2
                    label="Промокод"
                    size="xl"
                    name="promocode"
                    onChange={handleChange}
                    value={values.promocode}
                    placeholder={t("promo-code-channel-card.enter-promocode")}
                />
            </NmForm>
        </NmModal>
    );
};

ContractorNew.defaultProps = {
    industryOptions: [],
    handleCancel: () => {
    },
};

ContractorNew.propTypes = {
    industryOptions: PropTypes.array,
    handleCancel: PropTypes.func,
};

export default ContractorNew;
