import {useMemo, useState} from "react";

export const useKedoDirectoriesSort = (props) => {
    const {
        isDocumentRoutePage,
        initialSortType = "BY_CREATE_UPDATE_AT_DESC",
    } = props || {};

    const [sortType, setSortType] = useState(initialSortType);

    const sortOptions = useMemo(() => {
        if (isDocumentRoutePage) {
            return [
                {
                    key: "name",
                    value: "name",
                    sortType,
                    text: "По наименованию",
                    asc: "NAME_ASC",
                    desc: "NAME_DESC",
                },
                {
                    key: "date",
                    value: "date",
                    sortType,
                    isDefaultSort: true,
                    text: "По дате создания",
                    asc: "CREATED_ASC",
                    desc: "CREATED_DESC",
                },
            ];
        }

        return [
            {
                key: "name",
                value: "name",
                sortType,
                text: "По наименованию",
                asc: "BY_NAME_ASC",
                desc: "BY_NAME_DESC",
            },
            {
                key: "date",
                value: "date",
                sortType,
                isDefaultSort: true,
                text: "По дате обновления",
                asc: "BY_CREATE_UPDATE_AT_ASC",
                desc: "BY_CREATE_UPDATE_AT_DESC",
            },
        ];
    }, [
        isDocumentRoutePage,
        sortType,
    ]);

    const onClickSort = ({sortType}) => {
        setSortType(sortType);
    };

    return {
        onClickSort,
        sortType,
        sortOptions,
    };
};