import {DEPOSIT_SUB_PAGE} from "../../client-list";

import {LINK_CLIENT_NDFL_DEPOSIT_LIST} from "../../../../constants/links";

export const getCivilDepositLinks = (clientId) => {
    const REPLENISHMENT_AND_REFUNDS = LINK_CLIENT_NDFL_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);
    const COMPLETED_PAYMENTS = LINK_CLIENT_NDFL_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS);
    const PAYMENTS_IN_PROCESS = LINK_CLIENT_NDFL_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS);
    const PAYMENTS_PENDING = LINK_CLIENT_NDFL_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING);

    return {
        REPLENISHMENT_AND_REFUNDS,
        COMPLETED_PAYMENTS,
        PAYMENTS_IN_PROCESS,
        PAYMENTS_PENDING,
    };
};