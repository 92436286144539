import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_DIRECTORIES_DOCUMENTS_ERROR,
    ADD_DIRECTORIES_DOCUMENTS_REQUEST,
    ADD_DIRECTORIES_DOCUMENTS_SUCCESS,
    DELETE_DIRECTORIES_DOCUMENTS_ERROR,
    DELETE_DIRECTORIES_DOCUMENTS_REQUEST,
    DELETE_DIRECTORIES_DOCUMENTS_SUCCESS,
    GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR,
    GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST,
    GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS,
    UPDATE_DIRECTORIES_DOCUMENTS_ERROR,
    UPDATE_DIRECTORIES_DOCUMENTS_REQUEST,
    UPDATE_DIRECTORIES_DOCUMENTS_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

import {EDO_DOCUMENT_TOAST_SUCCESS} from "../../../../../../constants/settings";

const controller = "/adm/settings/directories/naimix/documents";

const getDirectoriesDocumentsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR,
            payload: error,
        });
    }
};

const addDirectoriesDocumentsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DIRECTORIES_DOCUMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }

        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.ADD.replace(":name", payload.value));

        yield put({
            type: ADD_DIRECTORIES_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DIRECTORIES_DOCUMENTS_ERROR,
            payload: error,
        });
    }
};

const updateDirectoriesDocumentsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
            value,
        } = payload;

        const result = yield request.bff.patch(`${controller}/${id}`, {
            id,
            value,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DIRECTORIES_DOCUMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }

        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.EDIT);

        yield put({
            type: UPDATE_DIRECTORIES_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DIRECTORIES_DOCUMENTS_ERROR,
            payload: error,
        });
    }
};

const deleteDirectoriesDocumentsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DIRECTORIES_DOCUMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }
        
        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.DELETE);

        yield put({
            type: DELETE_DIRECTORIES_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DIRECTORIES_DOCUMENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST, getDirectoriesDocumentsPageSaga),
        takeEvery(UPDATE_DIRECTORIES_DOCUMENTS_REQUEST, updateDirectoriesDocumentsSaga),
        takeEvery(DELETE_DIRECTORIES_DOCUMENTS_REQUEST, deleteDirectoriesDocumentsSaga),
        takeEvery(ADD_DIRECTORIES_DOCUMENTS_REQUEST, addDirectoriesDocumentsSaga),
    ]);
}