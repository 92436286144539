import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {
    STOP_LIST_ADD_ERROR_TYPE,
    STOP_LIST_EXPORT_ERROR_TYPE,
    STOP_LIST_IMPORT_ERROR_TYPE,
} from "../constants/stopList";

const controller = "/clients/inn";

//*  TYPES  *//

const STOP_LIST_READ_REQUEST = "STOP_LIST_READ_REQUEST";
const STOP_LIST_READ_SUCCESS = "STOP_LIST_READ_SUCCESS";
const STOP_LIST_READ_ERROR = "STOP_LIST_READ_ERROR";

const STOP_LIST_ADD_INN_REQUEST = "STOP_LIST_ADD_INN_REQUEST";
const STOP_LIST_ADD_INN_SUCCESS = "STOP_LIST_ADD_INN_SUCCESS";
const STOP_LIST_ADD_INN_ERROR = "STOP_LIST_ADD_INN_ERROR";

const STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST = "STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST";
const STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS = "STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS";
const STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR = "STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR";

const STOP_LIST_DELETE_INNS_REQUEST = "STOP_LIST_DELETE_INNS_REQUEST";
const STOP_LIST_DELETE_INNS_SUCCESS = "STOP_LIST_DELETE_INNS_SUCCESS";
const STOP_LIST_DELETE_INNS_ERROR = "STOP_LIST_DELETE_INNS_ERROR";

const IMPORT_STOP_LIST_REQUEST = "IMPORT_STOP_LIST_REQUEST";
const IMPORT_STOP_LIST_SUCCESS = "IMPORT_STOP_LIST_SUCCESS";
const IMPORT_STOP_LIST_ERROR = "IMPORT_STOP_LIST_ERROR";

const CREATE_EXPORT_STOP_LIST_REQUEST = "CREATE_EXPORT_STOP_LIST_REQUEST";
const CREATE_EXPORT_STOP_LIST_SUCCESS = "CREATE_EXPORT_STOP_LIST_SUCCESS";
const CREATE_EXPORT_STOP_LIST_ERROR = "CREATE_EXPORT_STOP_LIST_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    currentClient: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case STOP_LIST_READ_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case STOP_LIST_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getStopList(payload) {
    return {
        type: STOP_LIST_READ_REQUEST,
        payload,
    };
}

export function addInnForbidden(payload) {
    return {
        type: STOP_LIST_ADD_INN_REQUEST,
        payload,
    };
}

export function deleteAllInnClient(payload) {
    return {
        type: STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST,
        payload,
    };
}

export function deleteInnsForbidden(payload) {
    return {
        type: STOP_LIST_DELETE_INNS_REQUEST,
        payload,
    };
}

export function importInnsFromFile(payload) {
    return {
        type: IMPORT_STOP_LIST_REQUEST,
        payload,
    };
}

export function exportStopList(payload) {
    return {
        type: CREATE_EXPORT_STOP_LIST_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const stopListSelector = state => state.stopList;
export const stopListProgressSelector = createSelector(stopListSelector, ({progress}) => progress);
export const getStopListSelector = createSelector(stopListSelector, ({list}) => list.forbiddenModels);
export const getTotalPagesListSelector = createSelector(stopListSelector, ({list: {totalCount}, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getTotalCountStopListSelector = createSelector(stopListSelector, ({list: {totalCount}}) => totalCount);
export const stopListPageDataSelector = createSelector(stopListSelector, ({pageData}) => pageData);

//*  SAGA  *//

//POST /api/clients/inn/getPage
export const getStopListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: STOP_LIST_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: STOP_LIST_READ_ERROR, payload: error});
    }
};

//GET /api/clients/inn/addInnForbidden
export const addInnForbiddenSaga = function* ({payload}) {
    try {
        const {clientId, inn} = payload;
        const result = yield request.get(`${controller}/addInnForbidden`, {params: {clientId, inn}});
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_ADD_ERROR_TYPE.INN_ALREADY_ADDED) {
                toastError("Данный ИНН уже добавлен в стоп-лист компании");
            } else {
                toastError(errorMessage);
            }

            yield put({
                type: STOP_LIST_ADD_INN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: STOP_LIST_ADD_INN_SUCCESS, payload: result});

        const state = yield select();
        yield put(getStopList(stopListPageDataSelector(state)));
    } catch (error) {
        yield put({type: STOP_LIST_ADD_INN_ERROR, payload: error});
    }
};

//GET /api/clients/inn/deleteAllInnClient
export const deleteAllInnClientSaga = function* ({payload}) {
    try {
        const {clientId} = payload;
        const result = yield request.get(`${controller}/deleteAllInnClient`, {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Стоп-лист компании успешно очищен");

        yield put({type: STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS, payload: result});

        const state = yield select();
        yield put(getStopList(stopListPageDataSelector(state)));
    } catch (error) {
        yield put({type: STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR, payload: error});
    }
};

//POST /api/clients/inn/deleteInnsForbidden
export const deleteInnsForbiddenSaga = function* ({payload}) {
    try {
        const {listInns} = payload;
        const result = yield request.post(`${controller}/deleteInnsForbidden`, listInns);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_DELETE_INNS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Выбранные ИНН успешно удалены");

        yield put({type: STOP_LIST_DELETE_INNS_SUCCESS, payload: result});

        const state = yield select();
        yield put(getStopList(stopListPageDataSelector(state)));
    } catch (error) {
        yield put({type: STOP_LIST_DELETE_INNS_ERROR, payload: error});
    }
};

//POST /api/job/clientForbidden/importForbiddenInnClient
export const importInnsFromFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`/job/clientForbidden/importForbiddenInnClient?clientId=${clientId}`, formData, {...getMultipartHeaders()});
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_IMPORT_ERROR_TYPE.NO_DATA_FILE_TO_IMPORT) {
                toastError("В выбранном файле отсутствуют записи для загрузки");
            } else {
                toastError(errorMessage);
            }

            yield put({
                type: IMPORT_STOP_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Загрузка файла успешно завершена. Список будет обновлен через некоторое время");

        yield put({
            type: IMPORT_STOP_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: IMPORT_STOP_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/job/export/addExportClientForbidden
export const exportStopListSaga = function* ({payload}) {
    try {
        const result = yield request.post("/job/export/addExportClientForbidden", payload);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_EXPORT_ERROR_TYPE.NO_INN_CLIENT_FOUND_SPECIFIED_FILTERS) {
                toastError("Записи в стоп-листе компании отсутствуют. Операция невозможна");
            } else {
                toastError(errorMessage);
            }


            yield put({
                type: CREATE_EXPORT_STOP_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт\"");

        yield put({
            type: CREATE_EXPORT_STOP_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CREATE_EXPORT_STOP_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(STOP_LIST_READ_REQUEST, getStopListSaga),
        takeEvery(STOP_LIST_ADD_INN_REQUEST, addInnForbiddenSaga),
        takeEvery(STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST, deleteAllInnClientSaga),
        takeEvery(STOP_LIST_DELETE_INNS_REQUEST, deleteInnsForbiddenSaga),
        takeEvery(IMPORT_STOP_LIST_REQUEST, importInnsFromFileSaga),
        takeEvery(CREATE_EXPORT_STOP_LIST_REQUEST, exportStopListSaga),
    ]);
}
