import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesNaimixFederalMigrationSelector = state => state.bff.settingsDirectoriesNaimixFederalMigration;
export const settingsDirectoriesNaimixFederalMigrationPageDataSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({pageData}) => pageData);
export const settingsDirectoriesNaimixFederalMigrationTotalPagesSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesNaimixFederalMigrationTotalCountSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({totalCount}) => totalCount);
export const settingsDirectoriesNaimixFederalMigrationListSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({list}) => list);
export const settingsDirectoriesNaimixFederalMigrationProgressSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({progress}) => progress);
export const settingsDirectoriesNaimixFederalMigrationProgressActionSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({progressAction}) => progressAction);
export const settingsDirectoriesNaimixFederalMigrationCardSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({card}) => card);
export const settingsDirectoriesNaimixFederalMigrationProgressCardSelector = createSelector(settingsDirectoriesNaimixFederalMigrationSelector, ({progressCard}) => progressCard);