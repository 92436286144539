import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {dictionaryToOptions, formatEnumToFilterOptions} from "../../../../../utils/objectHelper";

import {TIME_GROUPING_OPTIONS} from "../constants";

import {
    bffCommonDictsTaskStatusesDictSelector,
    bffCommonDictsTaskTypesDictSelector,
} from "../../../../../ducks/bff/common/dicts/selectors";

export const useSettingsServiceTaskStatsFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const taskStatuses = useSelector(bffCommonDictsTaskStatusesDictSelector);
    const taskTypes = useSelector(bffCommonDictsTaskTypesDictSelector);

    const {
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            creationDateTimeFrom: null,
            creationDateTimeTo: null,
            taskStatuses: [],
            taskTypes: [],
            dateTimeGrouping: "",
        },
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFrom",
                        endFieldName: "creationDateTimeTo",
                        isClearable: true,
                        label: "Диапазон дат",
                        showTimeSelect: true,
                        datePickerDateFormat: "dd.MM.yy HH:mm",
                        dateFormatMask: "99.99.99 99:99",
                        placeholderStart: "дд.мм.гг чч:мм",
                        placeholderEnd: "дд.мм.гг чч:мм",
                        returnString: false,
                        isRelative: false,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус задачи",
                        placeholder: "Выберите статус",
                        name: "taskStatuses",
                        multiple: true,
                        options: formatEnumToFilterOptions(taskStatuses),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Тип задачи",
                        placeholder: "Выберите тип",
                        name: "taskTypes",
                        multiple: true,
                        options: formatEnumToFilterOptions(taskTypes),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Период группировки",
                        placeholder: "Выберите период группировки",
                        name: "dateTimeGrouping",
                        options: dictionaryToOptions(TIME_GROUPING_OPTIONS),
                    },
                ],
            },
        ];
    });

    return {
        onClear,
        onSearch,
        filterData,
        filters,
    };
};