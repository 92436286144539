import {all, put, takeEvery} from "redux-saga/effects";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/clients/dadata";

const CLIENTS_DADATA_GET_REQUISITES_BY_INN_REQUEST = "CLIENTS_DADATA_GET_REQUISITES_BY_INN_REQUEST";
const CLIENTS_DADATA_GET_REQUISITES_BY_INN_SUCCESS = "CLIENTS_DADATA_GET_REQUISITES_BY_INN_SUCCESS";
const CLIENTS_DADATA_GET_REQUISITES_BY_INN_ERROR = "CLIENTS_DADATA_GET_REQUISITES_BY_INN_ERROR";

const initialState = {
    progress: false,
};

export default (state = initialState, {type}) => {
    switch (type) {
        case CLIENTS_DADATA_GET_REQUISITES_BY_INN_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENTS_DADATA_GET_REQUISITES_BY_INN_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case CLIENTS_DADATA_GET_REQUISITES_BY_INN_ERROR:
            return {
                ...state,
                progress: false,
            };
        default: return state;
    }
};

export const getDadataRequisitesByInn = (payload) => ({
    type: CLIENTS_DADATA_GET_REQUISITES_BY_INN_REQUEST,
    payload,
});

export const getDadataRequisitesByInnSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;
        const result = yield request.get(`${controller}/getRequisitesByInn`, {params});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: CLIENTS_DADATA_GET_REQUISITES_BY_INN_ERROR,
                error: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: CLIENTS_DADATA_GET_REQUISITES_BY_INN_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CLIENTS_DADATA_GET_REQUISITES_BY_INN_ERROR,
            error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENTS_DADATA_GET_REQUISITES_BY_INN_REQUEST, getDadataRequisitesByInnSaga),
    ]);
}