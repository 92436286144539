import {
    ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    info: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS:
            const {
                workLog,
                totalCount,
                ...info
            } = payload;

            return {
                ...state,
                list: workLog || [],
                totalCount,
                info,
                progress: false,
            };
        case ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST:
        case ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS:
        case ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR:
        case ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case ORDER_CONTRACTOR_WORK_LOG_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};