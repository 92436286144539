import {
    KEDO_TEMPLATES_ADD_ERROR,
    KEDO_TEMPLATES_ADD_REQUEST,
    KEDO_TEMPLATES_ADD_SUCCESS,
    KEDO_TEMPLATES_DELETE_ERROR,
    KEDO_TEMPLATES_GET_PAGE_ERROR,
    KEDO_TEMPLATES_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_GET_PAGE_SUCCESS,
    KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR,
    KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS,
    KEDO_TEMPLATES_UPDATE_ERROR,
    KEDO_TEMPLATES_UPDATE_REQUEST,
    KEDO_TEMPLATES_UPDATE_STORE,
    KEDO_TEMPLATES_UPDATE_SUCCESS,
    KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR,
    KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST,
    KEDO_TEMPLATES_VALIDATE_PDF_ERROR,
    KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS,
} from "./actions";

export const getKedoTemplatesInitialState = () => {
    return {
        list: [],
        logsList: [],
        pageData: {},
        totalCount: 0,
        usersFieldNames: [],
        progress: false,
        progressAction: false,
        progressPreview: false,
        progressUsersFieldNames: false,
        error: null,
    };
};

const initialState = getKedoTemplatesInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS:
            return {
                ...state,
                logsList: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR:
        case KEDO_TEMPLATES_VALIDATE_PDF_ERROR:
            return {
                ...state,
                error: payload,
                progressPreview: false,
            };
        case KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                progressPreview: false,
            };
        case KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST:
            return {
                ...state,
                isSuccess: false,
                progressPreview: true,
            };
        case KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST:
            return {
                ...state,
                progressUsersFieldNames: true,
            };
        case KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS:
            return {
                ...state,
                progressUsersFieldNames: false,
                usersFieldNames: payload.results,
            };
        case KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR:
            return {
                ...state,
                progressUsersFieldNames: false,
            };
        case KEDO_TEMPLATES_UPDATE_REQUEST:
        case KEDO_TEMPLATES_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case KEDO_TEMPLATES_DELETE_ERROR:
        case KEDO_TEMPLATES_ADD_ERROR:
        case KEDO_TEMPLATES_UPDATE_ERROR:
            return {
                ...state,
                error: payload,
                progressAction: false,
            };
        case KEDO_TEMPLATES_UPDATE_SUCCESS:
        case KEDO_TEMPLATES_ADD_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST:
        case KEDO_TEMPLATES_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case KEDO_TEMPLATES_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR:
        case KEDO_TEMPLATES_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case KEDO_TEMPLATES_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default: return state;
    }
};