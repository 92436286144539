import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_PROMOTION_CHANNELS_ADD_ERROR,
    BFF_PROMOTION_CHANNELS_ADD_REQUEST,
    BFF_PROMOTION_CHANNELS_ADD_SUCCESS,
    BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_ERROR,
    BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST,
    BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_SUCCESS,
    BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_ERROR,
    BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS,
    BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR,
    BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";

const controller = "/adm/promotion-channels";

const getPagePromotionChannels = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addPromotionChannel = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            onError && onError(result);

            yield put({
                type: BFF_PROMOTION_CHANNELS_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_ADD_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_ADD_ERROR,
        });
    }
};

const getPagePromotionChannelClientsWithPartners = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get-page-with-partners`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const checkHasPromotionPromotionChannels = function* ({payload}) {
    try {
        const {
            getResult,
            clientName,
        } = payload;

        const result = yield request.bff.get(`${controller}/has-promotion`, {params: {clientName}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST, getPagePromotionChannels),
        takeEvery(BFF_PROMOTION_CHANNELS_ADD_REQUEST, addPromotionChannel),
        takeEvery(BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST, getPagePromotionChannelClientsWithPartners),
        takeEvery(BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST, checkHasPromotionPromotionChannels),
    ]);
}