import React from "react";
import {Route, Switch} from "react-router";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import Tabs from "../../../components/ActualComponents/Tabs";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import ContractorOnboardingLeads from "../onboarding-leads";
import ClientRemoteContractorsList from "./list";

import {getUserRole} from "../../../utils/access";

import {
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
} from "../../../constants/links";
import {ADMIN, NM_COORDINATOR, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";

const ClientRemoteContractors = () => {
    const role = getUserRole();

    const isAccessPage = [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_OPERATOR].includes(role);

    if (!isAccessPage) {
        return (
            <NmPage
                header={
                    <NmPageHeader
                        text="Исполнители заказчиков"
                    />
                }
            >
                <NmEmptyPageV2
                    isBlocked={true}
                    className="fluid"
                    title="Раздел недоступен для данного пользователя"
                />
            </NmPage>
        );
    }

    return (
        <NmPage
            header={
                <NmPageHeader text="Исполнители заказчиков" />
            }
        >
            <Tabs
                className="mb-4"
                panes={[
                    {
                        link: LINK_CLIENT_REMOTE_CONTRACTORS,
                        name: "Лиды API",
                    },
                    {
                        link: LINK_CONTRACTOR_ONBOARDING_LEADS,
                        name: "Лиды для онбординга",
                    },
                ]}
            />
            <Switch>
                <Route
                    exact
                    path={LINK_CLIENT_REMOTE_CONTRACTORS}
                    component={ClientRemoteContractorsList}
                />
                <Route
                    exact
                    path={LINK_CONTRACTOR_ONBOARDING_LEADS}
                    component={ContractorOnboardingLeads}
                />
            </Switch>
        </NmPage>
    );
};

export default ClientRemoteContractors;