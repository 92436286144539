import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_DIRECTORIES_STATEMENTS_ERROR,
    ADD_DIRECTORIES_STATEMENTS_REQUEST,
    ADD_DIRECTORIES_STATEMENTS_SUCCESS,
    DELETE_DIRECTORIES_STATEMENTS_ERROR,
    DELETE_DIRECTORIES_STATEMENTS_REQUEST,
    DELETE_DIRECTORIES_STATEMENTS_SUCCESS,
    GET_PAGE_DIRECTORIES_STATEMENTS_ERROR,
    GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST,
    GET_PAGE_DIRECTORIES_STATEMENTS_SUCCESS,
    UPDATE_DIRECTORIES_STATEMENTS_ERROR,
    UPDATE_DIRECTORIES_STATEMENTS_REQUEST,
    UPDATE_DIRECTORIES_STATEMENTS_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

import {EDO_STATEMENT_TOAST_SUCCESS} from "../../../../../../constants/settings";

const controller = "/adm/settings/directories/naimix/statements";

const getDirectoriesStatementsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/types/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_STATEMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_STATEMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_STATEMENTS_ERROR,
            payload: error,
        });
    }
};

const addDirectoriesStatementsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DIRECTORIES_STATEMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.ADD.replace(":name", payload.value));

        yield put({
            type: ADD_DIRECTORIES_STATEMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DIRECTORIES_STATEMENTS_ERROR,
            payload: error,
        });
    }
};

const updateDirectoriesStatementsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
            value,
        } = payload;

        const result = yield request.bff.patch(`${controller}/${id}`, {
            id,
            value,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DIRECTORIES_STATEMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.EDIT);

        yield put({
            type: UPDATE_DIRECTORIES_STATEMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DIRECTORIES_STATEMENTS_ERROR,
            payload: error,
        });
    }
};

const deleteDirectoriesStatementsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DIRECTORIES_STATEMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack();
        }

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.DELETE);

        yield put({
            type: DELETE_DIRECTORIES_STATEMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DIRECTORIES_STATEMENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST, getDirectoriesStatementsPageSaga),
        takeEvery(UPDATE_DIRECTORIES_STATEMENTS_REQUEST, updateDirectoriesStatementsSaga),
        takeEvery(DELETE_DIRECTORIES_STATEMENTS_REQUEST, deleteDirectoriesStatementsSaga),
        takeEvery(ADD_DIRECTORIES_STATEMENTS_REQUEST, addDirectoriesStatementsSaga),
    ]);
}