import {useState} from "react";
import {useDispatch} from "react-redux";

import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls} from "../../../../../utils/localstorage";

import {importSettingsClientGroupsCard} from "../../../../../ducks/bff/settings/client-groups/card/actionCreators";
import {getAllTasks} from "../../../../../ducks/job";

export function useActionsCardGroup(groupId, tab) {
    const dispatch = useDispatch();

    const [isOpenImport, setOpenImport] = useState(false);

    const onCloseModal = () => {
        setOpenImport(false);
    };

    const onSubmitImportList = ({file}) => {
        const formData = new FormData();

        formData.append("file", file);

        const requestData = {
            file: formData,
            groupId,
            tab,
        };

        dispatch(importSettingsClientGroupsCard({
            requestData,
            onSuccess: () => {
                onCloseModal();

                dispatch(getAllTasks({
                    clientId: ls(CURRENT_CLIENT_ID),
                    clientUserId: ls(CURRENT_CLIENT_USER_ID),
                }));
            },
        }));
    };


    const onClickImportList = () => {
        setOpenImport(true);
    };

    return {
        isOpenImport,
        onCloseModal,
        onSubmitImportList,
        onClickImportList,
    };
}