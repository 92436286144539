import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import TableDiv from "../../../../../components/TableDiv";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as SettingsIcon} from "../../../../../images/settings_24.svg";
import AddGroupMemberForm from "./components/add-form";

import {usePagination} from "../../../../../hooks/usePagination";
import {useFetchMembers} from "./hooks/useFetchMembers";
import {useMembersActions} from "./hooks/useMembersActions";
import {useMembersFilter} from "./hooks/useMembersFilter";

import {getUserRole} from "../../../../../utils/access";
import bem from "../../../../../utils/bem";
import {getFullName, phoneFormat} from "../../../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {groupMembersListHeaders} from "../../../../../constants/headersTable";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../../constants/roles";

import {
    settingsClientGroupsCardClientsListSelector,
    settingsClientGroupsCardClientsProgressActionSelector,
    settingsClientGroupsCardClientsProgressSelector,
    settingsClientGroupsCardClientsTotalCountSelector,
    settingsClientGroupsCardClientsTotalPagesSelector,
} from "../../../../../ducks/bff/settings/client-groups/card/client-users/selectors";
import {settingsClientGroupsCardInfoSelector} from "../../../../../ducks/bff/settings/client-groups/card/selectors";
import {
    settingsClientGroupsGroupMemberRelatedUsersListOptionsSelector,
    settingsClientGroupsProgressRelatedUsersSelector,
} from "../../../../../ducks/bff/settings/client-groups/selectors";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";

import "./style.sass";

const GroupMembersList = (props) => {
    const {
        groupId,
        isAccessEdit,
    } = props;

    const [block, element] = bem("group-members-list");

    const list = useSelector(settingsClientGroupsCardClientsListSelector);
    const totalPage = useSelector(settingsClientGroupsCardClientsTotalPagesSelector);
    const totalCount = useSelector(settingsClientGroupsCardClientsTotalCountSelector);
    const progress = useSelector(settingsClientGroupsCardClientsProgressSelector);
    const progressAction = useSelector(settingsClientGroupsCardClientsProgressActionSelector);
    const progressSetRelatedUsers = useSelector(settingsClientGroupsProgressRelatedUsersSelector);
    const groupMemberRelatedUsersOptions = useSelector(settingsClientGroupsGroupMemberRelatedUsersListOptionsSelector);
    const {countClients} = useSelector(settingsClientGroupsCardInfoSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = getUserRole();

    const {
        onClickAddMember,
        onClickDeleteMember,
        onClickSettingsMember,
        onCloseModal,
        dataModal,
        enabledRelatedUsersList,
        setEnabledRelatedUsersList,
        submitRelatedUsers,
    } = useMembersActions(groupId);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        filterDto,
        isSearch,
        setIsSearch,
        setFilterDto,
    } = useMembersFilter({clientGroupId: groupId});
    useFetchMembers({filter: filterDto, pageSize, pageNum});

    const submitFilter = (filter) => {
        setIsSearch(true);
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = (filter) => {
        setIsSearch(false);
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const filter = [
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите ФИО сотрудника",
                    label: "ФИО сотрудника",
                    name: "fioFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите телефон сотрудника",
                    label: "Телефон сотрудника",
                    name: "phoneFilter",
                    mask: "+7 (999) 999 9999",
                    maskChar: null,
                },
            ],
        },
        {
            row: [
                {
                    component: "input",
                    placeholder: "Введите e-mail сотрудника",
                    label: "E-mail сотрудника",
                    name: "emailFilter",
                },
            ],
        },
    ];

    const renderActions = (item) => {
        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        return (
            <div className={element("table-action")}>
                {
                    !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editClientGroupUsersSetting) &&
                    <NmButton
                        className={element("table-action-icon")}
                        onlyIcon
                        icon={<SettingsIcon />}
                        color="grey"
                        onClick={() => onClickSettingsMember(item)}
                    />
                }
                {
                    isAccessEdit &&
                    <CloseIcon
                        color="grey"
                        className={element("table-action-icon")}
                        onClick={() => onClickDeleteMember(item)}
                    />
                }
            </div>
        );
    };

    const mapTableData = () => {
        const rows = list.map(item => {
            const {
                phone,
                lastName,
                firstName,
                patronymic,
            } = item;

            return {
                ...item,
                fio: getFullName(lastName, firstName, patronymic),
                phone: phoneFormat(phone),
                action: renderActions(item),
            };
        });

        return {
            rows,
            headers: groupMembersListHeaders,
        };
    };

    const renderModalWindow = () => {
        const {
            isShow,
            isAddForm,
            isSettingsUserForm,
            title,
            submit,
        } = dataModal;

        if (!isShow) {
            return null;
        }

        if (isAddForm) {
            return (
                <AddGroupMemberForm
                    groupId={groupId}
                    submit={submit}
                    onClose={onCloseModal}
                />
            );
        }

        if (isSettingsUserForm) {
            return (
                <NmModal
                    size="md"
                    onClose={onCloseModal}
                    header={
                        <NmTitle size="lg">
                            Настройка доступов к компаниям
                        </NmTitle>
                    }
                    footer={
                        <ApplyButtons
                            className="col-16"
                            classNameMainButtons="col-16 justify-content-between"
                            submit={submitRelatedUsers}
                            onClose={onCloseModal}
                            submitBtnContent="Сохранить"
                            cancelBtnContent="Отменить"
                            mobile="column"
                            isHiddenCancelOnMobile
                            disabled={progress}
                        />
                    }
                    loading={progressSetRelatedUsers}
                >
                    <NmForm addMargin>
                        <NmInputV2
                            size="xl"
                            disabled
                            value={getFullName(dataModal.lastName, dataModal.firstName, dataModal.patronymic)}
                            label="Сотрудник"
                        />
                        <NmDropdownV2
                            required
                            multiple
                            size="xl"
                            label="Список компаний"
                            onChange={(event, {value}) => {
                                setEnabledRelatedUsersList(value);
                            }}
                            value={enabledRelatedUsersList}
                            options={groupMemberRelatedUsersOptions}
                        />
                    </NmForm>
                </NmModal>
            );
        }

        return (
            <NmConfirmV2
                content={title}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={submit}
                onCancel={onCloseModal}
            />
        );
    };

    return (
        <NmPage
            className={block()}
            headerClassName={!isAccessEdit && "justify-content-end"}
            controls={
                isAccessEdit &&
                (![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) && countClients !== 0) &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={onClickAddMember}
                >
                    Добавить сотрудника
                </NmButton>
            }
            filtersBase={
                <Filter
                    onSubmit={submitFilter}
                    clearFilter={clearFilter}
                    filters={filter}
                    isInitStateEqualEmpty={false}
                />
            }
            typeFilter="vertical"
            isLoaded={progress || progressAction}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPage}
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
        >
            {renderModalWindow()}
            {
                !(totalCount || progress) ?
                    <NmEmptyPageV2
                        isSearch={isSearch}
                    /> :
                    <TableDiv
                        className={element("table")}
                        tableData={mapTableData()}
                    />
            }
        </NmPage>
    );
};

export default GroupMembersList;
