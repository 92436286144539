import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/client-groups/card/client";

const getPageSettingsClientGroupsCardClients = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addSettingsClientGroupsCardClients = function* ({payload}) {
    try {
        const {
            onSuccess,
            groupId,
            clientIds,
        } = payload;

        const result = yield request.bff.post(`${controller}/add?groupId=${groupId}`, clientIds);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteSettingsClientGroupsCardClients = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.delete(`${controller}/deleteClientGroup`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENTS_GET_PAGE_REQUEST, getPageSettingsClientGroupsCardClients),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENTS_ADD_REQUEST, addSettingsClientGroupsCardClients),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_CLIENTS_DELETE_REQUEST, deleteSettingsClientGroupsCardClients),
    ]);
}