export const GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST = "GET_PAGE_DIRECTORIES_STATEMENTS_REQUEST";
export const GET_PAGE_DIRECTORIES_STATEMENTS_SUCCESS = "GET_PAGE_DIRECTORIES_STATEMENTS_SUCCESS";
export const GET_PAGE_DIRECTORIES_STATEMENTS_ERROR = "GET_PAGE_DIRECTORIES_STATEMENTS_ERROR";

export const ADD_DIRECTORIES_STATEMENTS_REQUEST = "ADD_DIRECTORIES_STATEMENTS_REQUEST";
export const ADD_DIRECTORIES_STATEMENTS_SUCCESS = "ADD_DIRECTORIES_STATEMENTS_SUCCESS";
export const ADD_DIRECTORIES_STATEMENTS_ERROR = "ADD_DIRECTORIES_STATEMENTS_ERROR";

export const UPDATE_DIRECTORIES_STATEMENTS_REQUEST = "UPDATE_DIRECTORIES_STATEMENTS_REQUEST";
export const UPDATE_DIRECTORIES_STATEMENTS_SUCCESS = "UPDATE_DIRECTORIES_STATEMENTS_SUCCESS";
export const UPDATE_DIRECTORIES_STATEMENTS_ERROR = "UPDATE_DIRECTORIES_STATEMENTS_ERROR";

export const DELETE_DIRECTORIES_STATEMENTS_REQUEST = "DELETE_DIRECTORIES_STATEMENTS_REQUEST";
export const DELETE_DIRECTORIES_STATEMENTS_SUCCESS = "DELETE_DIRECTORIES_STATEMENTS_SUCCESS";
export const DELETE_DIRECTORIES_STATEMENTS_ERROR = "DELETE_DIRECTORIES_STATEMENTS_ERROR";

export const CLEAR_FIELD_DIRECTORIES_STATEMENTS = "CLEAR_FIELD_DIRECTORIES_STATEMENTS";