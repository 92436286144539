import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    REGISTRY_PAYMENT_ARCHIVE_ERROR,
    REGISTRY_PAYMENT_ARCHIVE_REQUEST,
    REGISTRY_PAYMENT_ARCHIVE_SUCCESS,
    REGISTRY_PAYMENT_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_GET_PAGE_SUCCESS,
    REGISTRY_PAYMENT_PAY_ERROR,
    REGISTRY_PAYMENT_PAY_REQUEST,
    REGISTRY_PAYMENT_PAY_SUCCESS,
} from "./actions";

import request from "../../utils/postman";
import {toastError} from "../../utils/toastHelper";
import {getBffUrl} from "../../utils/url";

import {LINK_FINANCE_REGISTRY_PAYMENTS} from "../../constants/links";

const getController = () => {
    const adminPageController = "/adm/finances/registry-payments";

    return (
        getBffUrl({
            [LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", false)]: adminPageController,
            [LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", true)]: adminPageController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/registry-payments/registry",
            adminRolesUrl: "/adm/clients/client-card/finances/registry-payments/registry",
        })
    );
};

//POST /bff/adm/finances/registry-payments/page
// Получение страницы реестровых выплат
const getPageRegistryPayment = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/finances/registry-payments/{clientId}/{registryId}/archived/{archived}
// Архивирование реестра
const archiveRegistryPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            registryId,
            archived,
        } = payload;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryId}/archived/${archived}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ARCHIVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REGISTRY_PAYMENT_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/finances/registry-payments/pay
// Оплата реестра
const payRegistryPayment = function* ({payload}) {
    try {
        const {
            data,
            handleResponse,
            handleError,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/pay`, data);
        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (payModel) {
            handleResponse(result);

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REGISTRY_PAYMENT_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_PAY_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_GET_PAGE_REQUEST, getPageRegistryPayment),
        takeEvery(REGISTRY_PAYMENT_ARCHIVE_REQUEST, archiveRegistryPayment),
        takeEvery(REGISTRY_PAYMENT_PAY_REQUEST, payRegistryPayment),
    ]);
}