import {isNullOrWhitespace} from "./stringHelper";

import {TASK_STATUS, TASK_TYPE} from "../constants/task";

export function getTasksByType(tasks, types = []) {
    try {
        return tasks.filter(({type: taskType, details}) => types.includes(taskType) && !isNullOrWhitespace(details))
            .map(({details, ...rest}) => {
                const info = isNullOrWhitespace(details) ? {} : JSON.parse(details).info;
                return {
                    keys: Object.keys(info),
                    ...rest,
                };
            });
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getIdsFromTaskInfo(tasks) {
    const result = [];
    tasks.map(item => {
        const details = JSON.parse(item.details);

        return details ? details.info : {};
    }).filter(item => Object.entries(item).find(([, value]) => {
        if (value === null) {
            return true;
        }
        return value.processed === false;
    })).forEach(item => {
        Object.keys(item).forEach(_item => {
            result.push(_item);
        });
    });


    return Array.from(new Set(result));
}

// Для тасков, которые не отображаются в МО со всеми тасками
// в таких ситуациях нужно получить id для обеспечения нужной логики на страницах
export function getIdFromHiddenTask(item) {
    const details = JSON.parse(item.details);
    const result = details ? details.info : {};
    const [value] = Object.keys(result);

    return value;
}

export function getIdFromRegistryTask(item) {
    const details = JSON.parse(item.details);
    const result = details ? details.info : {};
    const {clientId, registryId} = result;

    return `${clientId}:${registryId}`;
}

export const getPaymentNumber = (key) => {
    const [, , paymentNumber] = key.toString().split(":");
    return paymentNumber;
};

// Получаем незавершенные таски в процессе
export function getTasksInProgress(tasks, checkingTaskTypes) {
    try {
        const result = Array.from(new Set(
            tasks.filter(item =>
                checkingTaskTypes.includes(item.type)
                && ![
                    TASK_STATUS.COMPLETED.VALUE,
                    TASK_STATUS.ERROR.VALUE,
                ].includes(item.status),
            ).map(item => {
                const id = getIdFromHiddenTask(item);

                return id.split(":").filter(value => value !== "unknown").join(":");
            }),
        ));

        return result.length === 0 ? undefined : [...new Set(result)];
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getTasksRegistryPaymentsImport(tasks) {
    try {
        return tasks.filter(({type, status}) =>
            [TASK_TYPE.IMPORT_REGISTRY_PAYMENTS.VALUE].includes(type) && ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(status),
        );
    } catch (e) {
        console.error(e);
        return [];
    }
}

export const handleTaskList = (tasks) => {
    const allTasks = tasks.map((item, index) => {
        const progressObject = isNullOrWhitespace(item.progressMessage) ? null : JSON.parse(item.progressMessage);

        if (
            [
                TASK_TYPE.RECRUITMENT_CHECK_JOB_BOARD_PUBLISH_STATUS.VALUE,
            ].includes(item.type)
        ) {
            const detailsInfo = isNullOrWhitespace(item.details) ? {} : JSON.parse(item.details).info;

            return {
                ...item,
                infoList: [detailsInfo],
                progressObject,
            };
        }

        const info = isNullOrWhitespace(item.details) ? {} : JSON.parse(item.details).info;
        const _info = item.type === TASK_TYPE.DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE.VALUE && info.deposits ? info.deposits : info;
        const infoList =
            [TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE, TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE].includes(item.type) ?
                _info.importRows || [] :
                Object.entries(_info).map(([key, value]) => {
                    if (item.type === TASK_TYPE.IMPORT_PREFILLED_CONTRACTORS.VALUE) {
                        return [];
                    }

                    return {
                        ...value,
                        rowNumber: item.type === TASK_TYPE.DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE.VALUE ? value?.rowIndex : value?.rowNumber,
                        paymentNumber: [
                            TASK_TYPE.ORDER_PAYMENTS_PAYMENT.VALUE,
                            TASK_TYPE.ORDER_PAYMENTS_CONFIRMATION.VALUE,
                            TASK_TYPE.ORDER_REJECTED_PAYMENT.VALUE,
                        ].includes(item.type) ? getPaymentNumber(key) : null,
                    };
                });

        if (
            [
                TASK_TYPE.ORDERS_CLOSING.VALUE,
                TASK_TYPE.ORDERS_ARCHIVING.VALUE,
                TASK_TYPE.ORDERS_DRAFT_DELETING.VALUE,
                TASK_TYPE.ORDERS_DRAFT_POSTING.VALUE,
            ].includes(item.type)
        ) {
            return {
                ...item,
                detailsInfo: info,
                progressObject,
                infoList: infoList.filter(item => !item.success),
            };
        }

        return {
            ...item,
            detailsInfo: info,
            progressObject,
            infoList: ![
                TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE,
                TASK_TYPE.PRE_VALIDATION_CONTRACTOR_BANK_CHECK.VALUE,
            ].includes(item.type) ? infoList : [],
        };
    });

    const invitationTasks = tasks.filter((item) => item.type === TASK_TYPE.ORDER_CONTRACTOR_INVITATION.VALUE).map((item, index) => {
        const progressMessage = isNullOrWhitespace(item.progressMessage) ? {} : JSON.parse(item.progressMessage);

        const {
            totalCount,
            succeededCount,
            failedCount,
            orderProgress,
        } = progressMessage;

        const infoList = Object.entries(orderProgress).map(([key, value]) => {
            return {
                ...value,
                invitationInfo: Object.entries(value.contractorProgress).map(([, contractorData]) => contractorData),
            };
        });

        return {
            ...item,
            progressObject: {
                totalCount,
                succeededCount,
                failedCount,
            },
            infoList,
        };
    });
    return allTasks.concat(invitationTasks);
};

export function getTasksRegistryDiscardPayments(tasks) {
    try {
        const result = Array.from(new Set(
            tasks.filter(item =>
                [TASK_TYPE.DISCARD_REGISTRY_PAYMENT_ERRORS.VALUE].includes(item.type) && item.status !== TASK_STATUS.COMPLETED.VALUE,
            ).map(item => {
                return getIdFromRegistryTask(item);
            }),
        ));

        return result.length === 0 ? undefined : result;
    } catch (e) {
        console.error(e);
        return [];
    }
}

export const filterTaskByTypes = (tasks, types) => {
    try {
        const result = getIdsFromTaskInfo(tasks.filter(item => types.includes(item.type)));

        return result.length === 0 ? undefined : result;
    } catch (e) {
        console.error(e);
        return [];
    }
};

export function getTasksAdvertisement(tasks) {
    try {
        const result = tasks.filter(item =>
            [TASK_TYPE.CLOSE_ADVERT.VALUE, TASK_TYPE.PUBLISH_ADVERT.VALUE].includes(item.type) &&
            item.status !== TASK_STATUS.COMPLETED.VALUE,
        ).map(item => {
            return getIdFromHiddenTask(item);
        });

        return result.length === 0 ? undefined : result;
    } catch (e) {
        console.error(e);
        return [];
    }
}
