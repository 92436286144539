import * as React from "react";
import {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter, {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useKedoDepartments} from "../../../../../components/KedoDepartmentsDropdown/hooks/useDepartments";
import {useKedoDepartmentDeputyHead} from "../../hooks/useDepartmentDeputyHead";
import {useKedoDepartmentHead} from "../../hooks/useDepartmentHead";

import {bffCommonDictsKedoDepartmentNamesOptionsSelector} from "../../../../../ducks/bff/common/dicts/selectors";
import {kedoDepartmentsRootSelector} from "../../../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructureDepartmentsFilter = (props) => {
    const {
        clientId,
        kedoDepartmentId,
        filterData,
        onClear,
        onSearch,
    } = props;

    const departmentNamesFiltersOptions = useSelector(bffCommonDictsKedoDepartmentNamesOptionsSelector);
    const {rootDepartmentId} = useSelector(kedoDepartmentsRootSelector);

    const headDropdownProps = useKedoDepartmentHead({
        clientId,
    });
    const deputyHeadDropdownProps = useKedoDepartmentDeputyHead({
        clientId,
    });
    const departmentsDropdownProps = useKedoDepartments({
        clientId,
        parentDepartmentId: kedoDepartmentId || rootDepartmentId,
        isRootDepartment: true,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        search: true,
                        multiple: true,
                        name: "kedoDepartmentIds",
                        isMountLoad: false,
                        ...departmentsDropdownProps,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...headDropdownProps,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...deputyHeadDropdownProps,
                    },
                ],
            },
        ];
    }, [
        headDropdownProps.options,
        deputyHeadDropdownProps.options,
        departmentNamesFiltersOptions,
    ]);

    return (
        <Filter
            initState={filterData}
            filters={filters}
            clearFilter={onClear}
            onSubmit={onSearch}
        />
    );
};