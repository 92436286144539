import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions, retailObjectsToOptions} from "../../../../utils/objectHelper";

export const contractorsResourcesSelector = state => state.bff.contractorsResources;
export const contractorsResourcesListSelector  = createSelector(contractorsResourcesSelector, ({list}) => list);
export const contractorsResourcesTotalCountSelector  = createSelector(contractorsResourcesSelector, ({totalCount}) => totalCount);
export const contractorsResourcesTotalPagesSelector  = createSelector(contractorsResourcesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const contractorsResourcesProgressSelector  = createSelector(contractorsResourcesSelector, ({progress}) => progress);
export const contractorsResourcesActionSelector  = createSelector(contractorsResourcesSelector, ({progressAction}) => progressAction);
export const contractorsResourcesClientOptionsSelector = createSelector(contractorsResourcesSelector, ({resourcesClientNameIdMap}) => dictionaryToOptions(resourcesClientNameIdMap));
export const contractorsResourcesProjectOptionsSelector = createSelector(contractorsResourcesSelector, ({resourcesProjectsNameIdMap}) => retailObjectsToOptions(resourcesProjectsNameIdMap));
export const contractorsResourcesObjectOptionsSelector = createSelector(contractorsResourcesSelector, ({resourcesObjectsNameIdMap}) => retailObjectsToOptions(resourcesObjectsNameIdMap));