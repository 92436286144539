import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {getInitialTouched} from "../../../../../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../../../../../utils/stringFormat";

import {RUSSIA_PHONE_CODE_ID} from "../../../../../../../../constants/phone";

import {getPendingPersonalData} from "../../../../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {contractorCardInfoSelector} from "../../../../../../../../ducks/bff/contractor-сard/selectors";
import {
    updateContractor,
} from "../../../../../../../../ducks/contractor";

export const useDataContractorContactInfoEditForm = ({contractorId, onClose}) => {
    const card = useSelector(contractorCardInfoSelector);

    const initialValues = {
        phone: card.phone|| "",
        email: card.email || "",
        locatedOutsideRussia: card.locatedOutsideRussia || false,
        phoneCodeId: card.phoneCodeId || RUSSIA_PHONE_CODE_ID,
    };

    const dispatch = useDispatch();

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const requestData = {
            ...card,
            ...values,
            phone: removePhoneMask(values.phone),
            phoneCodeId: values.locatedOutsideRussia ? values.phoneCodeId : RUSSIA_PHONE_CODE_ID,
            onSuccess: () => {
                fetchPersonalPendingData();
                onClose();
            },
        };

        dispatch(updateContractor(requestData));
    };

    const fetchPersonalPendingData = () => {
        dispatch(getPendingPersonalData({
            contractorId,
        }));
    };

    function handleChange(e, {checked, name: field, value}) {
        setFieldValue(field, typeof checked === "boolean" ? checked : value);
    }

    const {
        handleSubmit,
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
        setFieldValue,
    };
};