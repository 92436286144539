import {useState} from "react";
import {useDispatch} from "react-redux";

import {isNullOrWhitespace} from "../../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {updateContractorMainWorkAddress} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultMainWorkCityForm = {
    workAddressFiasId: "",
    query: "",
    remoteWork: false,
};

export default function useUpdateMainWorkCity({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultMainWorkCityForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        workAddressFiasId,
        remoteWork,
    } = form;

    const validateForm = () => {
        if (isNullOrWhitespace(workAddressFiasId)) {
            setError({workAddressFiasId: requiredMessage});
            return false;
        }
        setError({});

        return true;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        dispatch(updateContractorMainWorkAddress({
            contractorId,
            workAddressFiasId,
            remoteWork,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    const handleChangeAddressMain = (value) => {
        setForm(prevState => ({
            ...prevState,
            workAddressFiasId: value.data && value.data.fias_id ? value.data.fias_id : "",
            query: value.value,
        }));
    };

    const handleChangeRemoteWork = (e, {checked}) => {
        setForm(prevState => ({
            ...prevState,
            remoteWork: checked,
        }));
    };

    return {
        form,
        submit,
        setForm,
        handleChangeAddressMain,
        handleChangeRemoteWork,
        error,
    };
}