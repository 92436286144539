export const GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST = "GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST";
export const GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS = "GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS";
export const GET_PAGE_COLLECTION_BANK_DETAILS_ERROR = "GET_PAGE_COLLECTION_BANK_DETAILS_ERROR";

export const ADD_COLLECTION_BANK_DETAILS_REQUEST = "ADD_COLLECTION_BANK_DETAILS_REQUEST";
export const ADD_COLLECTION_BANK_DETAILS_SUCCESS = "ADD_COLLECTION_BANK_DETAILS_SUCCESS";
export const ADD_COLLECTION_BANK_DETAILS_ERROR = "ADD_COLLECTION_BANK_DETAILS_ERROR";

export const UPDATE_COLLECTION_BANK_DETAILS_REQUEST = "UPDATE_COLLECTION_BANK_DETAILS_REQUEST";
export const UPDATE_COLLECTION_BANK_DETAILS_SUCCESS = "UPDATE_COLLECTION_BANK_DETAILS_SUCCESS";
export const UPDATE_COLLECTION_BANK_DETAILS_ERROR = "UPDATE_COLLECTION_BANK_DETAILS_ERROR";

export const DELETE_COLLECTION_BANK_DETAILS_REQUEST = "DELETE_COLLECTION_BANK_DETAILS_REQUEST";
export const DELETE_COLLECTION_BANK_DETAILS_SUCCESS = "DELETE_COLLECTION_BANK_DETAILS_SUCCESS";
export const DELETE_COLLECTION_BANK_DETAILS_ERROR = "DELETE_COLLECTION_BANK_DETAILS_ERROR";

export const UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE = "UPDATE_SETTINGS_COLLECTION_BANK_DETAILS_STORE";