import {
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
    UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE,
} from "./actions";

export const getPageCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST,
        payload,
    };
};

export const createCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST,
        payload,
    };
};

export const editCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST,
        payload,
    };
};

export const getByIdCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST,
        payload,
    };
};

export const deleteCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST,
        payload,
    };
};

export const massDeleteCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST,
        payload,
    };
};

export const checkDuplicateCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST,
        payload,
    };
};

export const getPageErrorsCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST,
        payload,
    };
};

export const validationCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST,
        payload,
    };
};

export const repeatCrowdActRegistryEntry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST,
        payload,
    };
};

export const importCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
        payload,
    };
};

export const exportCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
        payload,
    };
};

export const updateStoreCrowdActRegistryEntry = (payload) => {
    return {
        type: UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE,
        payload,
    };
};