import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getKedoDepartmentNames,
    updateCommonPaymentDicts,
} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsKedoDepartmentNamesOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoDepartments = (params) => {
    const {
        clientId,
        parentDepartmentId,
        isRootDepartment,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(bffCommonDictsKedoDepartmentNamesOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                kedoDepartmentNames: [],
            }));
        };
    }, []);

    useEffect(() => {
        if (isRootDepartment && parentDepartmentId) {
            fetchList();
        }
    }, [
        parentDepartmentId,
    ]);

    const fetchList = (nameFilter) => {
        dispatch(getKedoDepartmentNames({
            clientId,
            parentDepartmentId,
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            nameFilter: handleFormString(nameFilter),
        }));
    };

    const onSearchChange = (nameFilter) => {
        fetchList(nameFilter);
    };

    return {
        options,
        label: "Управленченский отдел",
        placeholder: "Выберите отдел",
        onSearchChange,
    };
};