import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";

import {getInfoContractorCard} from "../../../../../ducks/bff/contractor-сard/actionCreators";
import {changeContractorBlockedBankOperation} from "../../../../../ducks/bff/contractor-сard/finance/balance/actionCreators";
import {getContractorFinanceBalanceProgressActionSelector} from "../../../../../ducks/bff/contractor-сard/finance/balance/selectors";
import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";
import {
    contractorProgressSelector,
} from "../../../../../ducks/contractor";

export const useContractorFinanceBalancePageActions = (params) => {
    const {
        contractorId,
    } = params;

    const dispatch = useDispatch();

    const contractor = useSelector(contractorCardInfoSelector);
    const contractorProgress = useSelector(contractorProgressSelector);
    const progressAction = useSelector(getContractorFinanceBalanceProgressActionSelector);

    const leftLabel = contractor.blockedBankOperation
        ? "Разблокировать вывод денежных средств"
        : "Заблокировать вывод денежных средств";

    const pageActions = useMemo(() => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.HORIZONTAL_TOGGLE,
                    props: {
                        leftLabel,
                        single: true,
                        onChange: () => {
                            dispatch(changeContractorBlockedBankOperation({
                                contractorId,
                                blockedBankOperation: !contractor.blockedBankOperation,
                                onSuccess: () => {
                                    dispatch(getInfoContractorCard(contractorId));
                                },
                            }));
                        },
                        checked: contractor.blockedBankOperation,
                        disabled: progressAction || contractorProgress,
                        duplex: false,
                    },
                    asset: {
                        mobile: {
                            children: leftLabel,
                        },
                        tablet: {
                            children: leftLabel,
                        },
                    },
                },
            ],
        };
    }, [
        contractor.blockedBankOperation,
        contractorProgress,
        progressAction,
    ]);

    return {
        pageActions,
    };
};