import {
    TARIFF_SCALE_ADD_REQUEST,
    TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_REQUEST,
    TARIFF_SCALE_DELETE_REQUEST,
    TARIFF_SCALE_GET_BY_ID_REQUEST,
    TARIFF_SCALE_GET_PAGE_REQUEST,
    TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST,
    TARIFF_SCALE_IMPORT_REQUEST,
    TARIFF_SCALE_UPDATE_REQUEST,
    TARIFF_SCALE_UPDATE_STORE,
} from "./actions";

export const getTariffScaleList = (payload) => ({
    type: TARIFF_SCALE_GET_PAGE_REQUEST,
    payload,
});

export const addTariffScale = (payload) => ({
    type: TARIFF_SCALE_ADD_REQUEST,
    payload,
});

export const deleteTariffScale = (payload) => ({
    type: TARIFF_SCALE_DELETE_REQUEST,
    payload,
});

export const updateTariffScale = (payload) => ({
    type: TARIFF_SCALE_UPDATE_REQUEST,
    payload,
});

export const checkExistTariffScale = (payload) => ({
    type: TARIFF_SCALE_CHECK_EXIST_REQUEST,
    payload,
});

export const getByIdTariffScale = (payload) => ({
    type: TARIFF_SCALE_GET_BY_ID_REQUEST,
    payload,
});

export const importTariffScale = (payload) => ({
    type: TARIFF_SCALE_IMPORT_REQUEST,
    payload,
});

export const checkExistByUpdateTariffScale = (payload) => ({
    type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST,
    payload,
});

export const updateTariffScaleStore = (payload) => ({
    type: TARIFF_SCALE_UPDATE_STORE,
    payload,
});

export const downloadTariffScale = () => ({
    type: TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST,
});