import {useEffect, useReducer} from "react";

export type ActionReducerType = {
    payload: any,
    type: string
};

export type InitialSortStateType = {
    fieldName: string,
    sortData: string,
    headers: any[]

}

const initialSortState = {
    fieldName: "",
    sortData: "",
    headers: [],
};

export function sortAction(payload: Array<string | any[]>): ActionReducerType {
    const [fieldName, sortData] = payload;
    return {
        type: "sort",
        payload: {
            fieldName,
            sortData,
        },
    };
}

export function resetSortAction(): ActionReducerType {
    return {
        type: "sort",
        payload: {},
    };
}

export function setHeadersAction(headers: any): ActionReducerType {
    return {
        type: "setHeaders",
        payload: {
            headers,
        },
    };
}

function updateHeaders(tableHeaders: any[], orderField: string, data: string): any[] {
    const _tableHeaders = [...tableHeaders];

    const column = _tableHeaders.find(value => value.key === orderField) || {};
    column.typeSort = data;

    return _tableHeaders;
}

function reducerSort(state: InitialSortStateType, action: ActionReducerType) {
    switch (action.type) {
        case "sort":
            return {
                ...state,
                ...action.payload,
                headers: updateHeaders(state.headers, action.payload.fieldName, action.payload.sortData),
            };
        case "setHeaders":
            return {
                ...state,
                headers: action.payload.headers,
            };
        case "reset":
            return initialSortState;
        default:
            return state;
    }
}

export default function useSortList(headers: any[]): [string, string, any[], Function] {
    const [state, dispatch] = useReducer(reducerSort, initialSortState);

    useEffect(() => {
        dispatch(setHeadersAction(headers));
        return () => {
            dispatch(resetSortAction());
        };
    }, [headers]);

    return [
        state.fieldName,
        state.sortData,
        state.headers,
        dispatch,
    ];
}