export const GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST = "GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST";
export const GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS = "GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS";
export const GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR = "GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR";

export const GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST = "GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST";
export const GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS = "GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS";
export const GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR = "GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR";

export const ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST = "ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST";
export const ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS = "ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS";
export const ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR = "ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR";

export const UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST = "UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST";
export const UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS = "UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS";
export const UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR = "UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR";

export const DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST = "DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST";
export const DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS = "DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS";
export const DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR = "DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR";

export const UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES = "UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES";

export const CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES = "CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES";