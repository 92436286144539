import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../../../components/ActualComponents/Text";
import Avatar from "../../../../../components/Avatar";
import LinkMarkdown from "../../../../../components/LinkMarkdown";
import {ReactComponent as ClockOutlineIcon} from "../../../../../images/clock-outline_24.svg";
import {ReactComponent as DoneIcon} from "../../../../../images/done_24.svg";
import {ReactComponent as DoubleCheck} from "../../../../../images/double-check.svg";
import {ReactComponent as AudioFileIcon} from "../../../../../images/file-audio.svg";
import {ReactComponent as InfoIcon} from "../../../../../images/info_24.svg";
import {ReactComponent as NaimixLogo} from "../../../../../images/nm-logo-rounded.svg";
import {Loader} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import bem from "../../../../../utils/bem";
import dateFormat from "../../../../../utils/dateFormat";
import {downloadLocalFile} from "../../../../../utils/downloadBlob";
import {getDocumentIconName, getFileExtension} from "../../../../../utils/file";

import {AUDIO_EXTENSIONS, IMAGE_EXTENSIONS} from "../../../../../constants/chats";
import {COLOR} from "../../../../../constants/color";

import {clientCardInfoSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {downloadDocument} from "../../../../../ducks/documents";
import {
    avatarBase64ImagesListSelector,
    fileStoreImagesSelector,
} from "../../../../../ducks/fileStore";

import "./style.sass";

function ChatMessage(props) {
    const {
        contractorId,
        outgoing = false,
        isRenderAvatar,
        message,
        className = "",
        dateTime,
        read,
        userFirstName,
        isNaimixUser,
        isClientUser,
        files = [],
        isLoading = false,
        isError = false,
        isNotificationMessage = false,
    } = props;

    const time = dateFormat(dateTime, "HH:mm");

    const images = useSelector(fileStoreImagesSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const clientCard = useSelector(clientCardInfoSelector);

    const [imageFullScreenSrc, setImageFullScreenSrc] = useState(null);

    const [block, element] = bem("chat-client-message", className);

    const dispatch = useDispatch();

    function renderMsgIndicator() {
        if (isLoading) {
            return (
                <ClockOutlineIcon
                    color={COLOR.PRIMARY_100}
                    width={20}
                    height={20}
                />
            );
        }

        if (isError) {
            return (
                <InfoIcon
                    color={COLOR.NEGATIVE_100}
                    width={20}
                    height={20}
                />
            );
        }

        if (read) {
            return (
                <DoubleCheck
                    color={COLOR.PRIMARY_100}
                    width={20}
                    height={20}
                />
            );
        }

        return (
            <DoneIcon
                color={COLOR.PRIMARY_100}
                width={20}
                height={20}
            />
        );
    }

    const downloadFile = ({contentUrl, fileExtension, fileLink, fileName}) => {
        const parse = require("url-parse");
        const {query, pathname} = parse(contentUrl);

        const reqData = fileLink ? {
            fileName,
            downloadLink: fileLink,
        } : {
            isDownload: true,
            isFillOrigin: true,
            extension: fileExtension,
            fileName,
            downloadLink: `${pathname}${query}`,
        };

        dispatch(downloadDocument(reqData));
    };

    function handleImageFullScreen({src}) {
        setImageFullScreenSrc(src);
    }

    function renderImageFullScreen() {
        return (
            imageFullScreenSrc &&
            <div className={element("layer")}>
                <img
                    alt="Изображение"
                    className={element("image-full-screen")}
                    onClick={() => setImageFullScreenSrc(null)}
                    src={imageFullScreenSrc}
                />
            </div>
        );
    }

    function renderChatDocument(files) {
        return files.map(({contentUrl, fileName, fileUuid, fileLink, file}, index) => {
            const fileExtension = getFileExtension(fileName);
            const image = images.find(value => fileUuid && value.fileUuid === fileUuid) || {};

            if (IMAGE_EXTENSIONS.includes(fileExtension)) {
                return (
                    <div
                        key={index}
                        className={element("message-img")}
                        onClick={() => handleImageFullScreen(image)}
                    >
                        <Loader
                            className={element("message-img-loader")}
                            active={!image.src}
                            inline="centered"
                            content="Загрузка"
                        />
                        {
                            image.src ?
                                <img
                                    alt={fileName}
                                    src={image.src}
                                /> :
                                null
                        }
                    </div>
                );
            }

            return (
                <div
                    key={index}
                    className={element("message-document")}
                >
                    {
                        AUDIO_EXTENSIONS.includes(fileExtension)
                            ? <AudioFileIcon
                                width={24}
                                height={24}
                            />
                            : <Icon name={getDocumentIconName(fileExtension)} />
                    }
                    <div
                        onClick={() => {
                            if (file && !fileLink) {
                                downloadLocalFile(file, fileName);

                                return;
                            }

                            downloadFile({
                                contentUrl,
                                fileExtension,
                                fileName,
                                fileLink,
                            });
                        }}
                        className={element("file-preview-name")}
                    >
                        {fileName}
                    </div>
                </div>
            );
        });
    };

    const renderAvatar = () => {
        if (isNotificationMessage) {
            return null;
        }

        if (isNaimixUser) {
            return (
                <NaimixLogo
                    height={20}
                    width={20}
                    className={element("avatar")}
                />
            );
        }

        return (
            <Avatar
                className={element("avatar")}
                contractorId={!isClientUser && contractorId}
                base64={isClientUser ? clientCard.base64Logo : userAvatarDict[contractorId]}
                isAccessOpenOriginal={!isClientUser}
            />
        );
    };

    return (
        <div className={block({outgoing})}>
            {renderImageFullScreen()}
            {!outgoing && isRenderAvatar && <div className={element("user-info")}>
                {renderAvatar()}
                <div className={element("user-name")}>
                    {
                        isNotificationMessage ?
                            <div className="flex align-items-center">
                                <InfoIcon
                                    className="me-2"
                                    color={COLOR.PASSIVE_50}
                                    width={20}
                                    height={20}
                                />
                                <Text color={COLOR.PASSIVE_50}>
                                    Это сообщение системы для исполнителя
                                </Text>
                            </div> :
                            userFirstName
                    }
                </div>
            </div>}
            <div className="flex flex-aligned-center">
                <div
                    className={element("content", {
                        outgoing,
                        notification: isNotificationMessage,
                    })}
                >
                    <div className={element("content-files")}>
                        {renderChatDocument(files)}
                    </div>
                    <div className={element("content-message")}>
                        <LinkMarkdown>
                            {message}
                        </LinkMarkdown>
                        <span className={element("info")}>
                            <span className={element("info-hidden")}>
                                {time}
                            </span>

                            <span className={element("info-container")}>
                                <div className={element("time")}>
                                    {time}
                                </div>
                                {outgoing && renderMsgIndicator()}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>);
}

export default ChatMessage;