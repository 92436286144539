import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import CardApp from "../../../../components/CardApp";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";
import {toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT_PROPERTIES_FIELD} from "../../../../constants/clientList";

import {getClientCardProperties} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {setProhibitionContractingNonResidents} from "../../../../ducks/client";
import {
    updateForClient,
} from "../../../../ducks/clientProperties";

import PropTypes from "prop-types";

import "./style.sass";

class ClientSettingsBlock extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isEdit: false,
            isShowErrorConfirm: false,
            form: {},
            errorForm: {},
            objectsFailedIds: [],
        };

        this.role = ls(USER_ROLE);
    }

    componentDidUpdate(prevProps) {
        const {card} = this.props;
        const {card: prevCard} = prevProps;
        const {
            form: {
                [CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]: formProhResidentsValue,
            },
        } = this.state;

        if (card[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS] !== undefined && (!isEqual(card, prevCard) || formProhResidentsValue === undefined)) {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]: card[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS],
                    [CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED]: card[CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED],
                    [CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED]: card[CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED],
                },
            }));
        }
    }

    get cardProps() {
        return {
            title: "Параметры компании",
            className: "client-card-block",
        };
    }

    setLoading = (loading = true) => {
        this.setState({loading});
    };

    getSuccess = () => {
        this.setLoading(false);

        toastSuccess("Параметры компании успешно изменены");

        const {
            getClientCardProperties,
            card: {
                clientId,
            },
        } = this.props;

        getClientCardProperties({clientId});

        this.cancelEdit();
    };

    submit = () => {
        const {updateForClient, clientId, card} = this.props;
        const {form} = this.state;

        const parametersCard = {
            [CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]: card[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS],
            [CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED]: card[CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED],
            [CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED]: card[CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED],
        };

        if (isEqual(parametersCard, form)) {
            this.cancelEdit();
            return;
        }

        this.setLoading();

        updateForClient({
            data: {
                [CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED]: form[CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED],
                [CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]: form[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS],
                [CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED]: form[CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED],
                clientId,
            },
            onSuccess: this.getSuccess,
        });
    };

    toggleEdit = () => {
        const {isEdit} = this.state;

        if (isEdit) {
            this.submit();
            return;
        }

        this.setState(
            prevState => ({
                ...prevState,
                isEdit: !prevState.isEdit,
            }),
        );
    };

    handleChange = (e, {value, checked, name}) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: checked !== undefined ? checked : value,
            },
        }));
    };

    cancelEdit = () => {
        const {card} = this.props;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            form: {
                ...prevState.form,
                [CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]: card[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS],
                [CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED] : card[CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED],
                [CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED] : card[CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED],
            },
        }));
    };

    get isEdit() {
        const {isEdit} = this.state;
        const {isEditable} = this.props;

        if (!isEditable) {
            return false;
        }

        return isEdit;
    }

    renderFormEdit() {
        const {form} = this.state;

        return (
            <NmForm>
                <NmCheckboxV2
                    disabled={!this.isEdit}
                    className="nmx-row fluid"
                    classNameLabel="client-settings-block__checkbox-label"
                    name={CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS}
                    onChange={this.handleChange}
                    checked={form[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]}
                    label="Запрет на заключение договоров с нерезидентами"
                />
                <NmCheckboxV2
                    disabled={!this.isEdit}
                    className="nmx-row fluid"
                    classNameLabel="client-settings-block__checkbox-label"
                    name={CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED}
                    onChange={this.handleChange}
                    checked={form[CLIENT_PROPERTIES_FIELD.OBJECT_ID_REQUIRED]}
                    label="Обязательность ввода ID для объектов"
                />
                <NmCheckboxV2
                    disabled={!this.isEdit}
                    className="nmx-row fluid"
                    classNameLabel="client-settings-block__checkbox-label"
                    name={CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED}
                    onChange={this.handleChange}
                    checked={form[CLIENT_PROPERTIES_FIELD.WORK_REPORT_PERIOD_VALIDATION_ENABLED]}
                    label="Запрет на формирование актов в рамках одного заказа с пересекающимся периодами выполнения работ"
                />
            </NmForm>
        );
    }

    render() {
        const {
            isEdit,
            loading,
        } = this.state;
        const {isEditable} = this.props;

        const {
            title,
            className,
        } = this.cardProps;

        return (
            <CardApp
                title={title}
                className={className}
                isEditable={isEditable}
                onClickIcon={this.toggleEdit}
                onClickCancelIcon={this.cancelEdit}
                isEdit={isEdit}
                loading={loading}
            >
                {this.renderFormEdit()}
            </CardApp>
        );
    }
}

export default connect(state => ({
    card: clientCardPropertiesSelector(state),
}),
{
    setProhibitionContractingNonResidents,
    getClientCardProperties,
    updateForClient,

})(withTranslation()(ClientSettingsBlock));
