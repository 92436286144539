import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useKedoUploadedDocumentTypesDropdown} from "./hooks/useKedoUploadedDocumentTypes";

export const KedoUploadedDocumentTypesDropdown = (props) => {
    const {
        clientId,
        routeId,
        ...otherProps
    } = props;

    const defaultComponentProps = useKedoUploadedDocumentTypesDropdown({
        clientId,
        routeId,
    });

    return (
        <NmDropdownV2
            {...defaultComponentProps}
            {...otherProps}
        />
    );
};