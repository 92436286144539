import {all, put, takeEvery} from "redux-saga/effects";

import {
    BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_ERROR,
    BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_REQUEST,
    BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_SUCCESS,
    BFF_FINANCES_NDFL_SUMMARY_REPORTS_ERROR,
    BFF_FINANCES_NDFL_SUMMARY_REPORTS_REQUEST,
    BFF_FINANCES_NDFL_SUMMARY_REPORTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/finances/deposit-report-ndfl/summary";

// POST /bff/adm/finances/deposit-report-ndfl/summary
// Метод получения данных для сводной ведомости
export const getNdflSummaryReportsSaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(controller, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            
            yield put({
                type: BFF_FINANCES_NDFL_SUMMARY_REPORTS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_NDFL_SUMMARY_REPORTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NDFL_SUMMARY_REPORTS_ERROR,
        });
    }
};

// POST /bff/adm/finances/deposit-report-ndfl/summary/deposit-history
// Метод получения истории пополнений для сводной
export const getNdflSummaryDepositHistorySaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/deposit-history`, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_SUCCESS,
            payload: result,
        });

        getResult(result);
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_NDFL_SUMMARY_REPORTS_REQUEST, getNdflSummaryReportsSaga),
        takeEvery(BFF_FINANCES_NDFL_SUMMARY_DEPOSIT_HISTORY_REQUEST, getNdflSummaryDepositHistorySaga),
    ]);
}