import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";
import DepositCommentModal from "../../../disputes/deposit-comment-modal";
import ClientListDepositOfFundEdit from "../components/deposit-fund-edit";
import ClientListDepositReturnEdit from "../components/deposit-return-edit";
import {DEPOSIT_SUB_PAGE} from "../index";

import {isNMUsers} from "../../../../utils/access";
import {dadataValueUniqCitySettlementFilter} from "../../../../utils/dadata";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {formatAmount, formatNumber} from "../../../../utils/stringFormat";
import {handleFormString, handleNumber, isNullOrWhitespace} from "../../../../utils/stringHelper";
import {getDepositRegistryStatusFilters} from "../utils/getRegistryStatusFilter";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {CROWD_TASK_STATUS} from "../../../../constants/crowd/task";
import {DEPOSIT_OPERATION, DEPOSIT_OPERATION_TRANSCRIPTION} from "../../../../constants/deposit";
import {ORDER_WORK_REPORT_TYPE, SOURCE_TYPE_OPTIONS} from "../../../../constants/finance";
import {
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LinkNavigator,
} from "../../../../constants/links";
import {
    DEPOSIT_FINANCE_PATENTS_PAYMENTS_STATUS_FILTER_OPTIONS,
    PATENTS_PAYMENTS_STATUS_CODE,
} from "../../../../constants/patentsPayments";
import {REG_EXP} from "../../../../constants/regExp";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    isClientUser,
    isUserFromNm,
    PROJECT_MANAGER,
} from "../../../../constants/roles";
import {
    CLIENT_STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS,
    STATUS_OF_TRANSFER_TRANSCRIPTION,
    STATUS_PAYMENT,
    STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER,
    STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT,
    STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS,
} from "../../../../constants/status";
import {regFullName} from "../../../../constants/validationRules";

import {
    editClientDepositReplenishmentAndRefunds,
    getClientDepositReplenishmentAndRefundsPage,
} from "../../../../ducks/bff/clients/finances/deposit/replenishment-and-refunds/actionCreators";
import {
    clientDepositReplenishmentAndRefundsListSelector,
    clientDepositReplenishmentAndRefundsProgressSelector,
    clientDepositReplenishmentAndRefundsTotalAddedAmountelector,
    clientDepositReplenishmentAndRefundsTotalCountSelector,
    clientDepositReplenishmentAndRefundsTotalPagesSelector,
    clientDepositReplenishmentAndRefundsTotalReturnedAmountelector,
} from "../../../../ducks/bff/clients/finances/deposit/replenishment-and-refunds/selectors";
import {getPageClientFinancesDepositTasks} from "../../../../ducks/bff/clients/finances/npd-deposit/crowd/services";
import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {getTotalAmountCrowdPayment} from "../../../../ducks/bff/crowd/payment/actionCreators";
import {
    bffCrowdPaymentTotalAmountSelector,
} from "../../../../ducks/bff/crowd/payment/selectors";
import {getTotalAmountCrowdTasks} from "../../../../ducks/bff/crowd/tasks/actionCreators";
import {
    bffCrowdTasksListSelector,
    bffCrowdTasksProgressSelector,
    bffCrowdTasksTotalAmountSelector,
    bffCrowdTasksTotalCountSelector,
    bffCrowdTasksTotalPagesSelector,
} from "../../../../ducks/bff/crowd/tasks/selectors";
import {
    clientMemberOptionsSelector,
    getClientMemberList,
} from "../../../../ducks/clientMember";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";
import {
    getPaidApiListSelector,
    getPaidApiPage,
    getPaidApiProgressSelector,
    getPaidApiTotalAmountSelector,
    getPaidApiTotalCountSelector,
    getPaidApiTotalPagesSelector,
} from "../../../../ducks/deposit";
import {edoAccessListSelector} from "../../../../ducks/edocuments";
import {getPageFinancesPayments} from "../../../../ducks/financePayments/actionCreators";
import {
    bffFinancesPaymentsListSelector,
    bffFinancesPaymentsProgressSelector,
    bffFinancesPaymentsTotalCountSelector,
    bffFinancesPaymentsTotalPagesSelector,
} from "../../../../ducks/financePayments/selectors";
import {
    clearOptimizedList,
    getLoadingDataAmountSelector,
    getPaymentsTotalAmount,
    paymentsTotalAmountSelector,
} from "../../../../ducks/financeReport";
import {getOptimizedObjects} from "../../../../ducks/objects";
import {
    financeTotalCountSelector,
    financeTotalPageSelector,
    getFinancePage,
    getOrderFinance,
    getProgressFinanceSelector,
    getRegistryOrders,
    getRegistryOrdersAmount,
    listFinanceSelector,
    orderClearFieldStore,
    orderFinanceSelector,
    orderProgressSelector,
    orderRegistryOrdersAmountProgressSelector,
    orderRegistryOrdersAmountSelector,
    ordersRegistryProgressSelector,
    ordersRegistrySelector,
    ordersRegistryTotalCountSelector,
    ordersRegistryTotalPagesSelector,
} from "../../../../ducks/order";
import {
    getPatentPaymentsDepositPage,
    getPatentPaymentsTotalAmount,
    patentPaymentsTotalAmountSelector,
    patentsPaymentsListSelector,
    patentsPaymentsListTotalPagesSelector,
    patentsPaymentsProgressListSelector,
    patentsPaymentsTotalCountSelector,
} from "../../../../ducks/patentsPayments";
import {generateInvoiceOfDepositReplenishmentByCivil} from "../../../../ducks/print";

import {STATUS_TASK_PAYMENT, STATUS_TASK_PAYMENT_OPTIONS} from "../../../../constants/crowd/task-payment";
import {EDO_ACCESS_RIGHTS} from "../../../../constants/document-management/document-list";

export const PATTERN_NUMBER = /^\d*(,\d{0,2})?$/;

export default function useGetDepositData(data) {
    const {
        clientId,
        filter,
        filterData,
        pageSize,
        pageNum,
        isCivil,
        isPatentsPage,
        subPage,
        subTab,
    } = data;

    const {
        projectIdsFilter,
    } = filterData;

    const {
        addressFiasIds,
    } = filter;

    const {
        REPLENISHMENT_AND_REFUNDS,
        PAYMENTS_PENDING,
        PAYMENTS_IN_PROCESS,
        COMPLETED_PAYMENTS,
        SPENT_LIMIT,
        PAID_APIS,
    } = DEPOSIT_SUB_PAGE;

    const initialDataModal = {
        isOpen: false,
        content: null,
    };

    const [dataModal, setDataModal] = useState(initialDataModal);

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    // депозит НДФЛ реестровые заказы
    const listRegistryOrders = useSelector(ordersRegistrySelector);
    const totalCountRegistryOrders = useSelector(ordersRegistryTotalCountSelector);
    const totalPagesRegistryOrders = useSelector(ordersRegistryTotalPagesSelector);
    const progressRegistryOrders = useSelector(ordersRegistryProgressSelector);

    const listPatentPayments = useSelector(patentsPaymentsListSelector);
    const totalCountPatentPayments = useSelector(patentsPaymentsTotalCountSelector);
    const totalPagesPatentPayments = useSelector(patentsPaymentsListTotalPagesSelector);
    const progressPatentPayments = useSelector(patentsPaymentsProgressListSelector);

    const listPayments = useSelector(bffFinancesPaymentsListSelector);
    const totalPagesPayments = useSelector(bffFinancesPaymentsTotalPagesSelector);
    const totalCountPayments = useSelector(bffFinancesPaymentsTotalCountSelector);
    const progressListPayments = useSelector(bffFinancesPaymentsProgressSelector);
    const progressListPaymentsAmount = useSelector(getLoadingDataAmountSelector);

    const listDepositHistory = useSelector(clientDepositReplenishmentAndRefundsListSelector);
    const totalPageDepositHistory = useSelector(clientDepositReplenishmentAndRefundsTotalPagesSelector);
    const totalCountDepositHistory = useSelector(clientDepositReplenishmentAndRefundsTotalCountSelector);
    const progressDepositHistory = useSelector(clientDepositReplenishmentAndRefundsProgressSelector);

    const listOrderFinance = useSelector(listFinanceSelector);
    const totalPageOrderFinance = useSelector(financeTotalPageSelector);
    const totalCountOrderFinance = useSelector(financeTotalCountSelector);
    const progressListOrderFinance = useSelector(orderProgressSelector);
    const progressFinanceFinance = useSelector(getProgressFinanceSelector);

    const listPaidApis = useSelector(getPaidApiListSelector);
    const totalCountPaidApis = useSelector(getPaidApiTotalCountSelector);
    const totalAmountPaidApis = useSelector(getPaidApiTotalAmountSelector);
    const totalPagesPaidApis = useSelector(getPaidApiTotalPagesSelector);
    const progressPaidApis = useSelector(getPaidApiProgressSelector);

    const listTasksPaymentsTotalAmount = useSelector(bffCrowdPaymentTotalAmountSelector);

    const listTasks = useSelector(bffCrowdTasksListSelector);
    const totalCountTasks = useSelector(bffCrowdTasksTotalCountSelector);
    const totalPagesTasks = useSelector(bffCrowdTasksTotalPagesSelector);
    const progressTasks = useSelector(bffCrowdTasksProgressSelector);
    const listTasksTotalAmount = useSelector(bffCrowdTasksTotalAmountSelector);

    const totalAddedAmount = useSelector(clientDepositReplenishmentAndRefundsTotalAddedAmountelector);
    const totalReturnedAmount = useSelector(clientDepositReplenishmentAndRefundsTotalReturnedAmountelector);
    const {
        totalLimitSpentAmount,
        totalLimitSpentCommissionAmount,
        totalOrdersAmount,
        totalOrdersCommissionAmount,
        totalOrdersPaidAmount,
        totalOrdersPaidCommissionAmount,
    } = useSelector(orderFinanceSelector);

    const paymentsTotalAmount = useSelector(paymentsTotalAmountSelector);
    // Суммка заказов (вкладка В процессе оплаты/Реестровые и созданные заказы из API)
    const {amount, commission} = useSelector(orderRegistryOrdersAmountSelector);
    const orderSumProgress = useSelector(orderRegistryOrdersAmountProgressSelector);
    const patentPaymentsTotalAmount = useSelector(patentPaymentsTotalAmountSelector);

    const {
        name,
        civilAccountBank,
    } = useSelector(clientCardInfoSelector);
    const {
        crowdTasksAvailable,
        individualRegistryPaymentsAvailable,
    } = useSelector(clientCardPropertiesSelector);

    const responsibleOptions = useSelector(clientMemberOptionsSelector);
    const accessList = useSelector(edoAccessListSelector);
    const bankStatusOptions = dictionaryToOptions(STATUS_OF_TRANSFER_TRANSCRIPTION);

    useEffect(() => {
        fetchPaymentList();
        getListByTab();
    }, [filter, pageSize, pageNum, subPage, subTab]);

    useEffect(() => {
        if (!isPatentsPage && subPage === REPLENISHMENT_AND_REFUNDS) {
            const editDepositRestriction = isCivil
                ? CLIENT_USER_RESTRICTIONS_VARIABLE.editCivilClientDepositHistory
                : CLIENT_USER_RESTRICTIONS_VARIABLE.editClientDepositHistory;

            dispatch(getClientUserRestrictionsList({
                restrictions: [
                    editDepositRestriction,
                ],
            }));
        }

        dispatch(getClientMemberList({
            clientId,
            archiveFilter: false,
            pageNum: 1,
            pageSize: 300,
        }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(orderClearFieldStore({
                registryOrdersAmount: {
                    commission: 0,
                    amount: 0,
                },
                registryOrders: [],
            }));
        };
    }, []);

    function fetchObjects() {
        dispatch(getOptimizedObjects({
            clientId,
            pageNum: 1,
            pageSize: 10,
            orderType: "DESC",
            sortName: "name",
        }));
    }

    const onEditDeposit = (data) => {
        dispatch(editClientDepositReplenishmentAndRefunds({
            ...data,
            isCivil,
            onSuccess: () => {
                onClickCloseModal();
                fetchPaymentList();
            },
        }));
    };

    function getContentModalByAction(row, isEditModalAction) {
        const {
            action,
            clientDepositHistoryId,
            clientId,
            comment,
            amount,
            bankType,
        } = row;

        if (isEditModalAction) {
            return <DepositCommentModal
                isCivil={isCivil}
                onClose={onClickCloseModal}
                commentData={{
                    comment,
                    clientDepositHistoryId,
                }}
                onSuccess={() => {
                    fetchPaymentList();
                }}
            />;
        }

        const dependentData = {
            clientDepositHistoryId,
            clientId,
            clientName: name,
            amount,
            bankType,
        };

        if (DEPOSIT_OPERATION.ADD_DEPOSIT === action) {
            return <ClientListDepositOfFundEdit
                close={onClickCloseModal}
                dependentData={dependentData}
                onEditDeposit={onEditDeposit}
            />;
        }

        if (DEPOSIT_OPERATION.RETURN_DEPOSIT === action) {
            return <ClientListDepositReturnEdit
                close={onClickCloseModal}
                dependentData={{
                    ...dependentData,
                    comment,
                }}
                onEditDeposit={onEditDeposit}
                isEdit
            />;
        }
    }

    const onClickOpenModal = (row, isEditModalAction = false) => {
        setDataModal(prevState => ({
            ...prevState,
            isOpen: true,
            content: getContentModalByAction(row, isEditModalAction),
        }));
    };

    const onClickCloseModal = () => {
        setDataModal(initialDataModal);
    };

    const defaultFilter = [
        {
            row: [
                {
                    component: FILTER.PROJECT,
                    name: "projectIdsFilter",
                    clientId: clientId,
                    isActiveStyle: false,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.OBJECT,
                    name: "objectIdsFilter",
                    clientId: clientId,
                    isActiveStyle: false,
                    projectIdsFilter: projectIdsFilter,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DADATA,
                    name: "addressFiasIds",
                    multiple: true,
                    fromBound: "region",
                    toBound: "settlement",
                    filter: dadataValueUniqCitySettlementFilter,
                    isAllSelectionShown: false,
                    isMountLoad: false,
                    label: "Город",
                    initialAddressIds: addressFiasIds,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    name: "contractorFioFilter",
                    label: "ФИО исполнителя",
                    pattern: regFullName,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.RANGE_INPUT,
                    nameStart: "fromSumMoneyFilter",
                    nameEnd: "toSumMoneyFilter",
                    valueStart: "",
                    valueEnd: "",
                    label: "Сумма операции",
                    placeholderStart: "От",
                    placeholderEnd: "До",
                    pattern: PATTERN_NUMBER,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "fromDateFilter",
                    endFieldName: "toDateFilter",
                    label: "Дата операции",
                    isClearable: true,
                    value: {
                        creationDateFromFilter: null,
                        creationDateToFilter: null,
                    },
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    name: "paymentNumberFilter",
                    label: "Номер оплаты",
                    mask: "999999999999",
                    maskChar: null,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    name: "orderNumFilter",
                    label: "Номер заказа",
                    mask: "999999999999",
                    maskChar: null,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    name: "orderNameFilter",
                    label: "Название",
                    pattern: regFullName,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.RANGE_INPUT,
                    nameStart: "fromClientCommissionAmountFilter",
                    nameEnd: "toClientCommissionAmountFilter",
                    valueStart: "",
                    valueEnd: "",
                    label: "Комиссия заказчика",
                    placeholderStart: "От",
                    placeholderEnd: "До",
                    pattern: PATTERN_NUMBER,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "sourceTypeFilter",
                    label: "Тип оплаты",
                    options: SOURCE_TYPE_OPTIONS,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "clientUserIdFilter",
                    label: "Ответственный",
                    options: responsibleOptions,
                },
            ],
        },
    ];

    const statusTaskPaymentOptions = useMemo(() => {
        if (subPage === PAYMENTS_IN_PROCESS) {
            return STATUS_TASK_PAYMENT_OPTIONS.filter(item =>
                [
                    STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                    STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
                    STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE,
                    STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                    STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                ].includes(item.value),
            );
        }

        if (subPage === PAYMENTS_PENDING) {
            return STATUS_TASK_PAYMENT_OPTIONS.filter(item =>
                [
                    STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                    STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                ].includes(item.value),
            );
        }

        return STATUS_TASK_PAYMENT_OPTIONS;
    }, [subPage, subTab]);

    const patentStatusesFilters = isNMUsers() && subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS ? [
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "paymentStatuses",
                    label: "Статус Наймикс",
                    options: DEPOSIT_FINANCE_PATENTS_PAYMENTS_STATUS_FILTER_OPTIONS,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "plutoniumStatuses",
                    label: "Банковский статус",
                    options: bankStatusOptions,
                },
            ],
        },
    ] : [];

    const filters = {
        [PAID_APIS]: [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "fromDate",
                        endFieldName: "toDate",
                        label: "Дата вызова",
                        isClearable: true,
                        value: {
                            fromDate: null,
                            toDate: null,
                        },
                    },
                ],
            },
        ],
        [DEPOSIT_SUB_PAGE.REGISTRY_ORDERS]: [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "fromDateCreateFilter",
                        endFieldName: "toDateCreateFilter",
                        label: "Дата создания",
                        isClearable: true,
                        value: {
                            creationDateFromFilter: null,
                            creationDateToFilter: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "registryNumFilter",
                        label: "Номер реестра",
                        mask: "999999999999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "registryNameFilter",
                        label: "Название реестра",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "orderNumFilter",
                        label: "Номер заказа",
                        mask: "999999999999",
                        maskChar: null,
                    },
                ],
            },
        ],
        [DEPOSIT_SUB_PAGE.PATENTS]: [
            {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIds",
                        isActiveStyle: false,
                        clientId,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIds",
                        isActiveStyle: false,
                        projectIdsFilter: filterData.projectIds,
                        clientId,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorFio",
                        label: "ФИО исполнителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "paymentSumFrom",
                        nameEnd: "paymentSumTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Сумма операции",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "statusDateFrom",
                        endFieldName: "statusDateTo",
                        label: "Дата операции",
                        isClearable: true,
                        value: {
                            statusDateFrom: null,
                            statusDateTo: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumber",
                        label: "Номер оплаты",
                        mask: "999999999999",
                        maskChar: null,
                    },
                ],
            },
            ...patentStatusesFilters,
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "paymentCommissionFrom",
                        nameEnd: "paymentCommissionTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Комиссия заказчика",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
        ],
        [REPLENISHMENT_AND_REFUNDS]: [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "fromDate",
                        endFieldName: "toDate",
                        label: "Дата операции",
                        isClearable: true,
                        value: {
                            fromDate: null,
                            toDate: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "action",
                        label: "Операция",
                        options: dictionaryToOptions(DEPOSIT_OPERATION_TRANSCRIPTION),
                        isClearable: true,
                    },
                ],
            },
        ],
        [COMPLETED_PAYMENTS]: [
            ...defaultFilter,
        ],
        [PAYMENTS_IN_PROCESS]: [
            ...defaultFilter,
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "reportStatusFilter",
                        label: "Статус Наймикс",
                        options: dictionaryToOptions(
                            isClientUser(role) ?
                                CLIENT_STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS :
                                STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS,
                        ),
                        compact: true,
                        clearable: true,
                        selectOnBlur: false,
                    },
                ],
            },
        ],
        [PAYMENTS_PENDING]: [
            ...defaultFilter,
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "reportStatusFilter",
                        label: "Статус Наймикс",
                        options: dictionaryToOptions(STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT),
                        compact: true,
                        clearable: true,
                        selectOnBlur: false,
                    },
                ],
            },
        ],
        [SPENT_LIMIT]: [
            {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIdsFilter",
                        isActiveStyle: false,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIdsFilter",
                        isActiveStyle: false,
                        projectIdsFilter: projectIdsFilter,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        name: "addressFiasIds",
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        initialAddressIds: addressFiasIds,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "dateWorkStartFilter",
                        endFieldName: "dateWorkEndFilter",
                        label: "Период работ",
                        isClearable: true,
                        value: {
                            creationDateFromFilter: null,
                            creationDateToFilter: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        clientId: clientId,
                        name: "specialityIds",
                        label: "Вид деятельности",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "orderNumFilter",
                        label: "Номер заказа",
                        mask: "999999999999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameSubstringFilter",
                        label: "Название",
                        pattern: regFullName,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "fromDateCreateFilter",
                        endFieldName: "toDateCreateFilter",
                        label: "Дата создания",
                        isClearable: true,
                        value: {
                            creationDateFromFilter: null,
                            creationDateToFilter: null,
                        },
                    },
                ],
            },
        ],
        [DEPOSIT_SUB_PAGE.TASKS]: [
            {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIds",
                        clientId: clientId,
                        isActiveStyle: false,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIds",
                        clientId: clientId,
                        isActiveStyle: false,
                        projectIdsFilter: filterData.projectIds,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        name: "addressFiasIds",
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        initialAddressIds: addressFiasIds,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorFIO",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО исполнителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "amountFrom",
                        nameEnd: "amountTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Сумма оплаты по заданию",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "operationDateFrom",
                        endFieldName: "operationDateTo",
                        isClearable: true,
                        label: "Дата операции",
                        value: {
                            operationDateFrom: null,
                            operationDateTo: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumber",
                        label: "Номер оплаты по заданию",
                        placeholder: "Введите номер оплаты",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNumber",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskName",
                        label: "Наименование задания",
                        placeholder: "Введите наименование задания",
                    },
                ],
            },
            ![
                SPENT_LIMIT,
                COMPLETED_PAYMENTS,
            ].includes(subPage) && {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "statuses",
                        label: "Статус оплаты по заданию",
                        placeholder: "Не выбран",
                        options: statusTaskPaymentOptions,
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "commissionFrom",
                        nameEnd: "commissionTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Комиссия заказчика",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
        ].filter(item => Boolean(item)),
    };

    const getAmount = (_amount) => formatAmount(formatNumber(_amount, 2), true);

    const getCardsData = () => {
        if (subTab === DEPOSIT_SUB_PAGE.TASKS) {
            return {
                [COMPLETED_PAYMENTS]: listTasksPaymentsTotalAmount === 0 ? [] : [
                    {
                        title: "Сумма завершенных оплат по заданиям",
                        value: getAmount(listTasksPaymentsTotalAmount),
                        className: "col-16 col-xl-4 col-xxl-3",
                    },
                ],
                [PAYMENTS_IN_PROCESS]: listTasksPaymentsTotalAmount === 0 ? [] : [
                    {
                        title: "В процессе оплаты по заданиям",
                        value: getAmount(listTasksPaymentsTotalAmount),
                        className: "col-16 col-xl-4 col-xxl-3",
                    },
                ],
                [PAYMENTS_PENDING]: listTasksPaymentsTotalAmount === 0 ? [] : [
                    {
                        title: "Сумма выплат на рассмотрении по заданиям",
                        value: getAmount(listTasksPaymentsTotalAmount),
                        className: "col-16 col-xl-4 col-xxl-3",
                    },

                ],
                [SPENT_LIMIT]: [
                    {
                        title: "Сумма по заданиям без оплат",
                        value: getAmount(listTasksTotalAmount?.amount),
                        subValue: getAmount(listTasksTotalAmount?.commission),
                        className: "col-16 col-xl-4 col-xxl-3",
                    },
                ],
            };
        }

        return {
            [REPLENISHMENT_AND_REFUNDS]: [
                {
                    title: "Сумма пополнений",
                    value: getAmount(totalAddedAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
                {
                    title: "Сумма возвратов",
                    value: getAmount(totalReturnedAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ],
            [COMPLETED_PAYMENTS]: paymentsTotalAmount === 0 ? [] : [
                {
                    title: "Сумма завершенных выплат",
                    value: getAmount(paymentsTotalAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ],
            [PAYMENTS_IN_PROCESS]: paymentsTotalAmount === 0 ? [] : [
                {
                    title: "В процессе оплаты",
                    value: getAmount(paymentsTotalAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ],
            [PAYMENTS_PENDING]: paymentsTotalAmount === 0 ? [] : [
                {
                    title: "На рассмотрении",
                    value: getAmount(paymentsTotalAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },

            ],
            [SPENT_LIMIT]: [
                {
                    title: "Сумма заказов, ₽",
                    value: getAmount(totalOrdersAmount),
                    subValue: getAmount(totalOrdersCommissionAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
                {
                    title: "Выплачено по заказам, ₽",
                    value: getAmount(totalOrdersPaidAmount),
                    subValue: getAmount(totalOrdersPaidCommissionAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
                {
                    title: "Израсходовано лимита, ₽",
                    value: getAmount(totalLimitSpentAmount),
                    subValue: getAmount(totalLimitSpentCommissionAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ],
        };
    };

    function mapFilterPaymentList(dataFilter) {
        const {
            fromSumMoneyFilter,
            toSumMoneyFilter,
            paymentNumberFilter,
            orderNumFilter,
            orderNameFilter,
            contractorFioFilter,
            fromClientCommissionAmountFilter,
            toClientCommissionAmountFilter,
            fromPlatformAmountFilter,
            toPlatformAmountFilter,
            sourceTypeFilter,
            objectIdsFilter = [],
            projectIdsFilter = [],
            addressFiasIds = [],
            clientUserIdFilter,
            fromDateFilter,
            toDateFilter,
            statusOfTheTransferFilter,
            checked,
        } = dataFilter;

        const {
            reportStatusFilter,
            registryStatusFilter,
        } = getDepositRegistryStatusFilters({
            subPage,
            registryStatusFilter: dataFilter.registryStatusFilter,
            reportStatusFilter: dataFilter.reportStatusFilter,
        });

        return ({
            statusOfTheTransferFilter,
            checked,
            dateFilter: {
                from: fromDateFilter,
                to: toDateFilter,
            },
            objectIdsFilter: objectIdsFilter.length ? objectIdsFilter : undefined,
            projectIdsFilter: projectIdsFilter.length ? projectIdsFilter : undefined,
            addressFiasIds: addressFiasIds.length ? addressFiasIds : undefined,
            sumOfMoneyFilter: {
                from: handleNumber(fromSumMoneyFilter),
                to: handleNumber(toSumMoneyFilter),
            },
            clientId,
            paymentNumberFilter: handleFormString(paymentNumberFilter),
            orderNumFilter: handleFormString(orderNumFilter),
            orderNameFilter: handleFormString(orderNameFilter),
            contractorFioFilter: handleFormString(contractorFioFilter),
            clientCommissionAmountFilter: {
                from: handleNumber(fromClientCommissionAmountFilter),
                to: handleNumber(toClientCommissionAmountFilter),
            },
            platformAmountFilter: {
                from: handleNumber(fromPlatformAmountFilter),
                to: handleNumber(toPlatformAmountFilter),
            },
            pageSize,
            pageNum,
            orderContractPaymentTypeFilter: isCivil ? ORDER_WORK_REPORT_TYPE.CIVIL : ORDER_WORK_REPORT_TYPE.SMZ,
            sourceTypeFilter: isNullOrWhitespace(sourceTypeFilter) || sourceTypeFilter === "ALL" ? undefined : sourceTypeFilter,
            reportStatusFilter,
            registryStatusFilter,
            needToEnrichByProjectAndObjects: true,
            clientUserIdFilter: handleFormString(clientUserIdFilter),
        });
    }

    function getFetchMethodByTab(params) {
        const {
            orderContractPaymentTypeFilter,
            ...reqData
        } = params;
        const requestData = {data: params};

        const requestDataPayments = mapFilterPaymentList(params);

        if (subTab === DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR) {
            dispatch(getPageFinancesPayments(requestDataPayments));
            dispatch(getPaymentsTotalAmount({
                data: {
                    ...requestDataPayments,
                    orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                },
            }));

            return;
        }

        switch (subPage) {
            case REPLENISHMENT_AND_REFUNDS:
                return dispatch(getClientDepositReplenishmentAndRefundsPage({
                    ...reqData,
                    isCivil,
                }));
            case PAYMENTS_IN_PROCESS:
                dispatch(getPageFinancesPayments(requestDataPayments));
                dispatch(getPaymentsTotalAmount({
                    data: {
                        ...reqData,
                        orderContractPaymentTypeFilter,
                    },
                }));

                return;
            case PAYMENTS_PENDING:
            case COMPLETED_PAYMENTS: {
                const requestData = {
                    data: {
                        ...reqData,
                        orderContractPaymentTypeFilter,
                    },
                };

                dispatch(getPageFinancesPayments(requestDataPayments));
                dispatch(getPaymentsTotalAmount(requestData));

                return;
            }
            case SPENT_LIMIT:
                dispatch(getFinancePage(requestData));
                dispatch(getOrderFinance(requestData));

                return;
            default:
                return;
        }
    }

    const clearList = () => {
        dispatch(clearOptimizedList());
    };

    const getInitialFilterByTab = () => {
        switch (subPage) {
            case DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS:
                return {
                    clientId,
                };

            case DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS:
                return {
                    needToEnrichByProjectAndObjects: true,
                    clientId,
                    reportStatusFilter: [STATUS_PAYMENT.PAYMENT_COMPLETED],
                    sortByStatuses: false,

                };
            case DEPOSIT_SUB_PAGE.PAYMENTS_PENDING:
                return {
                    needToEnrichByProjectAndObjects: true,
                    clientId,
                    reportStatusFilter: Object.keys(STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT),
                    sortByStatuses: false,
                };
            case DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS:
                return {
                    needToEnrichByProjectAndObjects: true,
                    clientId,
                    reportStatusFilter: STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER.REPORT_STATUS_FILTER,
                    registryStatusFilter: STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER.REGISTRY_STATUS_FILTER,
                    sortByStatuses: false,
                };
            case DEPOSIT_SUB_PAGE.SPENT_LIMIT:
                return {
                    archivedFilter: false,
                    clientId,
                    isSearch: false,
                    statusFilter: ["DRAFT", "CLOSED", "PUBLISHED", "CONTRACTOR_SEARCH"],
                    orderSourceTypes: ["TYPE_1", "TYPE_2"],
                    sortByStatuses: false,
                };
            case DEPOSIT_SUB_PAGE.PAID_APIS:
                return {
                    clientId,
                };
            default:
                return null;

        }
    };

    const fetchPaymentList = () => {
        const orderContractPaymentTypeFilter = isCivil ? ORDER_WORK_REPORT_TYPE.CIVIL : ORDER_WORK_REPORT_TYPE.SMZ;

        if (subTab === DEPOSIT_SUB_PAGE.REGISTRY_ORDERS) {
            const requestData = {
                clientId,
                pageSize,
                pageNum,
                orderContractPaymentTypeFilter: isCivil
                    ? [ORDER_WORK_REPORT_TYPE.CIVIL]
                    : [
                        ORDER_WORK_REPORT_TYPE.SMZ,
                        ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                    ],
                ...filter,
            };

            dispatch(getRegistryOrders(requestData));
            dispatch(getRegistryOrdersAmount(requestData));

            return;
        }

        if (
            subTab === DEPOSIT_SUB_PAGE.TASKS &&
            [
                DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS,
                DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS,
                DEPOSIT_SUB_PAGE.PAYMENTS_PENDING,
            ].includes(subPage)
        ) {
            const getStatuses = () => {
                if (filter.statuses?.length) {
                    return filter.statuses;
                }

                if (subPage === DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS) {
                    return [
                        STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE,
                    ];
                }
                if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS) {
                    return [
                        STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                        STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
                        STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE,
                        STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                        STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                    ];
                }

                if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_PENDING) {
                    return [
                        STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                        STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                    ];
                }

                return [];
            };

            const requestData = {
                clientId,
                clientIds: [clientId],
                statuses: getStatuses(),
                includingClientCommission: true,
                ...filter,
            };

            dispatch(getPageFinancesPayments({
                ...requestData,
                pageSize,
                pageNum,
            }));
            dispatch(getTotalAmountCrowdPayment(requestData));

            return;
        }

        if (subTab === DEPOSIT_SUB_PAGE.TASKS && subPage === DEPOSIT_SUB_PAGE.SPENT_LIMIT) {
            const requestData = {
                clientIdFilter: clientId,
                statusFilter: CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE,
                ...filter,
            };

            dispatch(getPageClientFinancesDepositTasks({
                pageSize,
                pageNum,
                ...requestData,
            }));
            dispatch(getTotalAmountCrowdTasks(requestData));

            return;
        }

        if (subTab === DEPOSIT_SUB_PAGE.PATENTS) {
            const paymentStatuses =
                subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS ?
                    [PATENTS_PAYMENTS_STATUS_CODE.PROCESSING, PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR]
                    : [PATENTS_PAYMENTS_STATUS_CODE.DEPOSITED];

            const requestData = {
                clientId,
                ...filter,
                paymentSumFrom: handleNumber(filter.paymentSumFrom),
                paymentSumTo: handleNumber(filter.paymentSumTo),
                paymentCommissionFrom: handleNumber(filter.paymentCommissionFrom),
                paymentCommissionTo: handleNumber(filter.paymentCommissionTo),
                paymentStatuses: filter.paymentStatuses ? [filter.paymentStatuses] : paymentStatuses,
                plutoniumStatuses: filter.plutoniumStatuses ? [filter.plutoniumStatuses] : undefined,
            };

            dispatch(getPatentPaymentsDepositPage({
                ...requestData,
                pageSize,
                pageNum,
            }));
            dispatch(getPatentPaymentsTotalAmount(requestData));

            return;
        }

        if (subPage === DEPOSIT_SUB_PAGE.PAID_APIS) {
            dispatch(getPaidApiPage({
                clientId,
                ...filter,
                pageSize,
                pageNum,
            }));

            return;
        }

        const initialFilter = getInitialFilterByTab();

        const {reportStatusFilter} = filter;
        const {reportStatusFilter: initialReportStatusFilter} = initialFilter;

        const requestData = {
            clientId,
            pageSize,
            pageNum,
            ...initialFilter,
            ...filter,
            reportStatusFilter: reportStatusFilter || initialReportStatusFilter,
            orderContractPaymentTypeFilter,
        };

        getFetchMethodByTab(requestData);
    };

    function getCardByTab() {
        if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS && subTab === DEPOSIT_SUB_PAGE.REGISTRY_ORDERS) {
            return amount === 0 ? [] : [
                {
                    title: "Сумма заказов",
                    value: getAmount(amount),
                    subValue: getAmount(commission),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ];
        }

        if (subTab === DEPOSIT_SUB_PAGE.PATENTS) {
            return patentPaymentsTotalAmount === 0 ? [] : [
                {
                    title: subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS ? "В процессе оплаты" : "Сумма завершенных выплат",
                    value: getAmount(patentPaymentsTotalAmount),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ];
        }

        if (subPage === DEPOSIT_SUB_PAGE.PAID_APIS) {
            return [
                {
                    title: "Сумма списаний",
                    value: getAmount(totalAmountPaidApis),
                    className: "col-16 col-xl-3 col-xxl-2",
                },
                {
                    title: "Количество вызовов",
                    value: totalCountPaidApis,
                    className: "col-16 col-xl-3 col-xxl-2",
                },
            ];
        }

        return getCardsData()[subPage];
    }

    function getGeneralRules(subPage) {
        const _link = isCivil ? LinkNavigator.client.finance.civilDepositRegistryOrders : LinkNavigator.client.finance.depositRegistryOrders;
        const _paymentsLink = isCivil ? LINK_CLIENT_NDFL_DEPOSIT_LIST : LINK_CLIENT_DEPOSIT_LIST;
        const _patentLink = isPatentsPage ? LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS : _link;

        const link = _link
            .replace(":clientId", clientId)
            .replace(":subPage", subPage)
            .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS);
        const taskLink = _link
            .replace(":clientId", clientId)
            .replace(":subPage", subPage)
            .replace(":subTab", DEPOSIT_SUB_PAGE.TASKS);
        const individualEntrepreneurLink = _link
            .replace(":clientId", clientId)
            .replace(":subPage", subPage)
            .replace(":subTab", DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR);
        const patentLink = _patentLink
            .replace(":clientId", clientId)
            .replace(":subPage", subPage)
            .replace(":subTab", DEPOSIT_SUB_PAGE.PATENTS);
        const paymentsLink = _paymentsLink
            .replace(":clientId", clientId)
            .replace(":subPage", subPage);

        const registryAndApiTab = subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS ? [
            {
                active: link,
                link,
                name: "Реестровые и созданные из API заказы",
            },
        ] : [];

        const patentTab = isCivil && (isUserFromNm(role) || ([CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER].includes(role) && accessList.includes(EDO_ACCESS_RIGHTS.EDM_GENERAL))) ? [
            {
                active: patentLink,
                link: patentLink,
                name: "Оплаты патентов",
            },
        ] : [];

        const isVisibleTaskTab = !isCivil && crowdTasksAvailable && [
            DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS,
            DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS,
            DEPOSIT_SUB_PAGE.PAYMENTS_PENDING,
            DEPOSIT_SUB_PAGE.SPENT_LIMIT,
        ].includes(subPage);
        const isVisibleIndividualEntrepreneurTab = !isCivil && individualRegistryPaymentsAvailable && [
            DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS,
            DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS,
            DEPOSIT_SUB_PAGE.PAYMENTS_PENDING,
        ].includes(subPage);

        const tasksTab = isVisibleTaskTab ? [
            {
                active: taskLink,
                link: taskLink,
                name: "По заданиям",
            },
        ] : [];

        const individualEntrepreneurTab = isVisibleIndividualEntrepreneurTab ? [
            {
                active: individualEntrepreneurLink,
                link: individualEntrepreneurLink,
                name: "По ИП",
            },
        ] : [];

        if (isPatentsPage) {
            return {
                tabs: [...patentTab],
            };
        }

        return {
            tabs: [
                {
                    active: paymentsLink,
                    link: paymentsLink,
                    exceptions: [
                        link,
                        patentLink,
                        taskLink,
                        individualEntrepreneurLink,
                    ],
                    name: isVisibleTaskTab ? "По заказам" : "Оплаты исполнителям",
                },
                ...registryAndApiTab,
                ...tasksTab,
                ...individualEntrepreneurTab,
                ...patentTab,
            ],
        };
    }

    function getListByTab() {
        if ([PAYMENTS_IN_PROCESS, PAYMENTS_PENDING, COMPLETED_PAYMENTS].includes(subPage)) {
            if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS && subTab === DEPOSIT_SUB_PAGE.REGISTRY_ORDERS) {
                return {
                    list: listRegistryOrders,
                    totalCount: totalCountRegistryOrders,
                    totalPage: totalPagesRegistryOrders,
                    progress: progressRegistryOrders || orderSumProgress,
                    ...getGeneralRules(DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                };
            }

            if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS && subTab === DEPOSIT_SUB_PAGE.PATENTS) {
                return {
                    list: listPatentPayments,
                    totalCount: totalCountPatentPayments,
                    totalPage: totalPagesPatentPayments,
                    progress: progressPatentPayments || orderSumProgress,
                    ...getGeneralRules(DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                };
            }

            if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS) {
                return {
                    list: listPayments,
                    totalCount: totalCountPayments,
                    totalPage: totalPagesPayments,
                    progress: progressListPayments || progressListPaymentsAmount,
                    ...getGeneralRules(DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                };
            }

            if (isCivil && subPage === DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS && subTab === DEPOSIT_SUB_PAGE.PATENTS) {
                return {
                    list: listPatentPayments,
                    totalCount: totalCountPatentPayments,
                    totalPage: totalPagesPatentPayments,
                    progress: progressPatentPayments || orderSumProgress,
                    ...getGeneralRules(DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                };
            }

            if (isCivil && subPage === DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS) {
                return {
                    list: listPayments,
                    totalCount: totalCountPayments,
                    totalPage: totalPagesPayments,
                    progress: progressListPayments || progressListPaymentsAmount,
                    ...getGeneralRules(DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                };
            }

            return {
                list: listPayments,
                totalCount: totalCountPayments,
                totalPage: totalPagesPayments,
                progress: progressListPayments || progressListPaymentsAmount,
                ...getGeneralRules(subPage),
            };
        }

        if ([REPLENISHMENT_AND_REFUNDS].includes(subPage)) {
            return {
                list: listDepositHistory,
                totalCount: totalCountDepositHistory,
                totalPage: totalPageDepositHistory,
                progress: progressDepositHistory,
            };
        }

        if (SPENT_LIMIT === subPage) {
            if (subTab === DEPOSIT_SUB_PAGE.TASKS) {
                return {
                    list: listTasks,
                    totalCount: totalCountTasks,
                    totalPage: totalPagesTasks,
                    progress: progressTasks,
                    ...getGeneralRules(subPage),
                };
            }

            return {
                list: listOrderFinance,
                totalCount: totalCountOrderFinance,
                totalPage: totalPageOrderFinance,
                progress: progressListOrderFinance || progressFinanceFinance,
                ...getGeneralRules(subPage),
            };
        }

        if (PAID_APIS === subPage) {
            return {
                list: listPaidApis,
                totalCount: totalCountPaidApis,
                totalPage: totalPagesPaidApis,
                progress: progressPaidApis,
            };
        }

        return {
            list: [],
            totalCount: null,
            totalPage: null,
            progress: false,
        };
    }

    const getFilters = () => {
        if (subTab && filters[subTab]) {
            return filters[subTab];
        }

        return filters[subPage];
    };

    const {
        list,
        tabs,
        totalCount,
        totalPage,
        progress,
    } = getListByTab();

    const cards = getCardByTab();

    const onSubmitCivil = (data) => {
        dispatch(generateInvoiceOfDepositReplenishmentByCivil(data));
    };

    return {
        list,
        tabs,
        civilAccountBank,
        totalPage,
        totalCount,
        progress,
        filters: getFilters(),
        cards,
        onClickOpenModal,
        dataModal,
        clearList,
        fetchObjects,
        onSubmitCivil,
    };
}