import React from "react";
import {useSelector} from "react-redux";

import Text from "../../../../../components/ActualComponents/Text";
import NmButton from "../../../../../components/NmButton";
import ContractorInfoBlock from "../block";

import {toastError} from "../../../../../utils/toastHelper";

import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_STATUS} from "../../../../../constants/contractor";
import {
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
} from "../../../../../constants/links";

import {history} from "../../../../../store/configureStore";

import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";

const MESSAGE = `Проверка доп. документов исполнителя доступна только при наличии подтвержденных персональных 
данных исполнителя (статус регистрации "Проверка пройдена"). 
Проверьте доп. документы после проверки перс. данных исполнителя`;

const ContractorInfoAdditionalDocumentsChecking = (props) => {
    const {
        className,
    } = props;

    const contractor = useSelector(contractorCardInfoSelector);
    const {
        registrationStatus,
        contractorId,
    } = contractor;

    const onClickCheckDocuments = () => {
        if (![CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(registrationStatus))  {
            toastError(MESSAGE);

            return;
        }

        history.push(LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS
            .replace(":contractorId", contractorId));
    };

    return (
        <ContractorInfoBlock className={className}>
            <div className="flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between">
                <Text
                    color={COLOR.SECONDARY_100}
                    level="4"
                >
                Внесение/изменение дополнительных документов
                </Text>
                <NmButton
                    className="mt-2 mt-xl-0"
                    size="sm"
                    color="light-green"
                    onClick={onClickCheckDocuments}
                >
                    Проверить документы
                </NmButton>
            </div>
        </ContractorInfoBlock>
    );
};

export default ContractorInfoAdditionalDocumentsChecking;