import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {
    debounce,
    isEmpty,
} from "lodash";

import {
    newClientValidationScheme,
} from "../validation";

import {getKeyFromMigrationDictByDadata} from "../../../../../utils/objectHelper";
import getNewClientInitFormValues from "../utils/getNewClientInitFormValues";
import getNewClientRequestData from "../utils/getNewClientRequestData";

import {CLIENT_FILE_TYPES} from "../../../../../constants/clientList";
import {LINK_CLIENT_INFO} from "../../../../../constants/links";
import {NM_MANAGER} from "../../../../../constants/roles";

import {history} from "../../../../../store/configureStore";

import {refreshTokenAction} from "../../../../../ducks/auth";
import {confirmSettingsJoiningRequests} from "../../../../../ducks/bff/settings/joining-requests/actionCreators";
import {bffAddClient} from "../../../../../ducks/client";
import {getCoordsByAddress} from "../../../../../ducks/dadata";
import {getListMigrations} from "../../../../../ducks/federalMigration";
import {addClientFileToFileStore} from "../../../../../ducks/fileStore";
import {getOrderCategoriesAll} from "../../../../../ducks/orderCategories";

function useNewClientForm(props) {
    const {
        nearestOVM,
        role,
        federalRegionDict,
        currentApplication,
        dadataGeoData,
        categoryOptions,
        categoryList,
        fetchList,
        onClose,
    } = props;

    const dispatch = useDispatch();
    const [isOpenImageCrop, setIsOpenImageCrop] = useState(false);

    const onUploadLogo = (clientId) => {
        const {
            fileObj: {
                "IMPORT_LOGO": importedFile,
            },
            fileObjThumbnail,
        } = values;

        const [file = {}] = importedFile || [];

        const formDataFull = new FormData();
        formDataFull.append("file", file);

        dispatch(addClientFileToFileStore({
            clientId,
            clientFileType: CLIENT_FILE_TYPES.LOGO,
            file: formDataFull,
            isHiddenToaster: true,
        }));

        const formDataCropped = new FormData();
        formDataCropped.append("file", fileObjThumbnail[0]);

        dispatch(addClientFileToFileStore({
            clientId,
            clientFileType: CLIENT_FILE_TYPES.LOGO_THUMBNAIL,
            file: formDataCropped,
            isHiddenToaster: true,
        }));
    };

    const onUpdateListMigrations = () => {
        const {
            longitudeFilter,
            latitudeFilter,
            region,
        } = values;

        dispatch(getListMigrations({
            pageNum: 1,
            pageSize: 200,
            longitudeFilter,
            latitudeFilter,
            region,
        }));
    };

    const onChangeClient = (e, {checked, value, name}) => {
        if (name === "migrantLicensePaymentEnabled" && !checked) {
            setValues({
                ...values,
                migrantLicensePaymentCommission: "",
                migrantLicensePaymentEnabled: checked,
            });

            return;
        }

        if (name === "withoutContract" && checked) {
            setValues({
                ...values,
                withoutContract: checked,
                registryPaymentsAvailable: false,
            });

            return;
        }

        if (name === "edmAvailable" && !checked) {
            setValues({
                ...values,
                migrantLicensePaymentEnabled: false,
                migrantLicensePaymentCommission: "",
                withoutContract: false,
                registryPaymentsAvailable: false,
                edmAvailable: checked,
            });

            return;
        }

        setFieldValue(name, value || checked);
    };

    const onChangeAddress = (data) => {
        const value = data.unrestricted_value ? data.unrestricted_value : data.value;

        setFieldValue("registeredAddress", value);
    };

    const onChangeCategory = (e, {value}) => {
        //Больше ключей нет, чтобы определить вид категории. Пока делаем такое условие
        const category = categoryOptions.find((item) => {
            return item.value === value && item.text === "Строительство и ремонт";
        });

        setValues({
            ...values,
            categoryId: value,
            foremanFunctionalityAvailable:
                category ?
                    values.foremanFunctionalityAvailable
                    : undefined,
            isShowForemanFunctionalityAvailable: !!category,
        });
    };

    const onDropFile = (block) => {
        return (files) => {
            if (isEmpty(files)) {
                setValues({
                    ...values,
                    fileObj: {
                        ...values.fileObj,
                        [block]: files,
                    },
                    fileObjThumbnail: [],
                });

                return;
            }

            const _files = files.map((item) => {
                return Object.assign(item, {
                    preview: URL.createObjectURL(item),
                });
            },
            );

            setFieldValue("fileObj", {
                ...values.fileObj,
                [block]: _files,
            });

            setIsOpenImageCrop(true);
        };
    };

    const onCloseImageCropForm = () => {
        setIsOpenImageCrop(false);

        setValues({
            ...values,
            fileObj: {},
            fileObjThumbnail: [],
        });
    };

    const onSaveImageCropForm = (file) => {
        const fileObjThumbnail = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });

        setIsOpenImageCrop(false);
        setFieldValue("fileObjThumbnail", [fileObjThumbnail]);
    };

    const onUpdateForm = (data) => {
        const {
            registeredAddress,
            ...otherData
        } = data;

        setValues({
            ...values,
            ...otherData,
            registeredAddress,
        }, false);
    };

    const onSubmitAdd = () => {
        if (!isValid) {
            return;
        }

        const {
            fileObj,
        } = values;

        const requestData = getNewClientRequestData({
            formFields: values,
            nearestOVM,
            categoryList,
        });

        if (requestData.applicationId) {
            dispatch(confirmSettingsJoiningRequests({
                data: requestData,
                getResult: (clientId) => {
                    if ([NM_MANAGER].includes(role)) {
                        dispatch(refreshTokenAction());
                    }

                    if (!isEmpty(fileObj)) {
                        onUploadLogo(clientId);
                    }

                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(bffAddClient({
            data: requestData,
            onSuccess: (clientId) => {
                if ([NM_MANAGER].includes(role)) {
                    dispatch(refreshTokenAction());
                }

                if (!isEmpty(fileObj)) {
                    onUploadLogo(clientId);
                }

                history.push(LINK_CLIENT_INFO.replace(":clientId", clientId));
            },
        }));
    };

    const getCoords = () => {
        const {
            address,
        } = values;

        dispatch(getCoordsByAddress([address]));
    };

    const debounceAddress = debounce(getCoords, 350);
    const {
        values,
        errors,
        touched,
        isValid,
        handleSubmit,
        setTouched,
        setFieldValue,
        setValues,
        handleBlur,
    } = useFormik({
        initialValues: getNewClientInitFormValues(currentApplication),
        validationSchema: newClientValidationScheme,
        onSubmit: onSubmitAdd,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        validateOnMount: false,
    });

    useEffect(() => {
        dispatch(getOrderCategoriesAll());
    }, []);

    useEffect(() => {
        if (values.address) {
            debounceAddress();
        }
    }, [values.address]);

    useEffect(() => {
        if (!isEmpty(dadataGeoData) && touched.registeredAddress) {
            const [{
                region,
                geo_lat: latitude,
                geo_lon: longitude,
            }] = dadataGeoData;

            if (!region || !latitude || !longitude) {
                setFieldValue("addressSearchError", "Не найден адрес, повторите ввод");

                return;
            }

            const regionId = getKeyFromMigrationDictByDadata(region, federalRegionDict);

            setValues({
                ...values,
                latitudeFilter: latitude,
                longitudeFilter: longitude,
                region: regionId,
                addressSearchError: null,
            });

            onUpdateListMigrations();
        }
    }, [dadataGeoData]);

    return {
        values,
        errors,
        isOpenImageCrop,
        handleSubmit,
        onDropFile,
        onChangeCategory,
        onChangeAddress,
        onUpdateForm,
        onSaveImageCropForm,
        onCloseImageCropForm,
        onChangeClient,
        setTouched,
        handleBlur,
    };
}

export default useNewClientForm;