import React from "react";
import {Route, Switch} from "react-router";

import {OrganizationStructureDepartmentCard} from "./components/card";
import {KedoOrganizationStructure} from "./index";

import {
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
} from "../../../constants/links";


export const KedoOrganizationStructureRoute = () => {
    return (
        <Switch>
            <Route
                path={LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD}
                component={OrganizationStructureDepartmentCard}
            />
            <Route
                path={LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE}
                component={KedoOrganizationStructure}
            />
        </Switch>
    );
};