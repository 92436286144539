export const BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST = "BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST";
export const BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS = "BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS";
export const BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR = "BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR";

export const BFF_EXPORT_DOCUMENTS_ADD_REQUEST = "BFF_EXPORT_DOCUMENTS_ADD_REQUEST";
export const BFF_EXPORT_DOCUMENTS_ADD_SUCCESS = "BFF_EXPORT_DOCUMENTS_ADD_SUCCESS";
export const BFF_EXPORT_DOCUMENTS_ADD_ERROR = "BFF_EXPORT_DOCUMENTS_ADD_ERROR";

export const BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST = "BFF_EXPORT_DOCUMENTS_GET_LINK_REQUEST";
export const BFF_EXPORT_DOCUMENTS_GET_LINK_SUCCESS = "BFF_EXPORT_DOCUMENTS_GET_LINK_SUCCESS";
export const BFF_EXPORT_DOCUMENTS_GET_LINK_ERROR = "BFF_EXPORT_DOCUMENTS_GET_LINK_ERROR";

export const BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST = "BFF_EXPORT_DOCUMENTS_GET_PROGRESS_REQUEST";
export const BFF_EXPORT_DOCUMENTS_GET_PROGRESS_SUCCESS = "BFF_EXPORT_DOCUMENTS_GET_PROGRESS_SUCCESS";
export const BFF_EXPORT_DOCUMENTS_GET_PROGRESS_ERROR = "BFF_EXPORT_DOCUMENTS_GET_PROGRESS_ERROR";

export const BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST = "BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_REQUEST";
export const BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS = "BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS";
export const BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_ERROR = "BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_ERROR";

export const UPDATE_EXPORT_DOCUMENTS_STORE = "UPDATE_EXPORT_DOCUMENTS_STORE";