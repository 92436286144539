import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useKedoDocumentRoute} from "./hooks/useKedoDocumentRoute";

export const KedoDocumentRouteDropdown = (props) => {
    const {
        clientId,
        typesFilter,
        ...otherProps
    } = props;

    const defaultComponentProps = useKedoDocumentRoute({
        clientId,
        typesFilter,
    });

    return (
        <NmDropdownV2
            {...defaultComponentProps}
            {...otherProps}
        />
    );
};