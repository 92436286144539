import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import {BaseDirectoryControls} from "../../../../../components/BasicDirectory/components/Controls";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import {KedoOrganizationStructureDepartmentsFilter} from "../departments-filter";
import {KedoOrganizationStructureEdit} from "../edit";

import {usePagination} from "../../../../../hooks/usePagination";
import {useKedoOrganizationStructureAction} from "../../hooks/useAction";
import {useKedoDepartmentsFetch} from "../../hooks/useFetch";
import {useKedoOrganizationStructureFilters} from "../../hooks/useFilters";

import {getUserRole} from "../../../../../utils/access";
import {getFullName} from "../../../../../utils/stringFormat";

import {
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD_DEPARTMENTS,
} from "../../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER} from "../../../../../constants/roles";

import {
    kedoDepartmentsListSelector,
    kedoDepartmentsProgressSelector,
    kedoDepartmentsTotalCountSelector,
    kedoDepartmentsTotalPagesSelector,
} from "../../../../../ducks/kedo/departments/selectors";

export const KedoOrganizationStructureDepartments = (props) => {
    const {
        match: {
            params: {
                clientId,
                kedoDepartmentId,
            },
        },
    } = props;

    const totalCount = useSelector(kedoDepartmentsTotalCountSelector);
    const list = useSelector(kedoDepartmentsListSelector);
    const totalPages = useSelector(kedoDepartmentsTotalPagesSelector);
    const progress = useSelector(kedoDepartmentsProgressSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useKedoOrganizationStructureFilters({
        pageSize,
        setPagination,
        clientId,
        kedoDepartmentId,
    });

    useKedoDepartmentsFetch({
        pageNum,
        pageSize,
        filterData,
        clientId,
        kedoDepartmentId,
    });

    const role = getUserRole();

    const {
        onClickEdit,
        onClickDelete,
        modalData,
        onCloseModal,
    } = useKedoOrganizationStructureAction({
        clientId,
    });

    const getConfirmWindow = () => {
        if (!modalData?.confirmProps) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                onCancel={onCloseModal}
                cancelButton="Отменить"
                {...modalData.confirmProps}
            />
        );
    };

    const getPrimaryHeader = ({name, kedoDepartmentId}) => {
        const link = LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD_DEPARTMENTS
            .replace(":clientId", clientId)
            .replace(":kedoDepartmentId", kedoDepartmentId);

        return (
            <ExtLink to={link}>
                {name}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.kedoDepartmentId,
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        classNameMainContent="col-16 col-xxl-14"
                        primaryHeaderStatusTooltip={
                            item.isExternal &&
                            <HelpTooltip
                                hover
                                info
                                children="Отдел для внештатных сотрудников"
                            />
                        }
                        primaryHeader={getPrimaryHeader(item)}
                        labels={[
                            {
                                label: "Руководитель отдела",
                                text: getFullName(
                                    item.headStaffLastName,
                                    item.headStaffFirstName,
                                    item.headStaffPatronymic,
                                ),
                                columnOnMobile: true,
                                noWrap: false,
                            },
                            {
                                label: "Заместитель руководителя отдела",
                                text: item.deputyHeadStaffId
                                    ? getFullName(
                                        item.deputyHeadStaffLastName,
                                        item.deputyHeadStaffFirstName,
                                        item.deputyHeadStaffPatronymic,
                                    )
                                    : "-",
                                columnOnMobile: true,
                                noWrap: false,
                            },
                            {
                                label: "Сотрудники",
                                text: item.departmentsStaffCount,
                                noWrap: false,
                            },
                        ]}
                        isFixedActions={true}
                        actions={
                            <BaseDirectoryControls
                                onClickEdit={() => {
                                    onClickEdit(item);
                                }}
                                onClickDelete={() => {
                                    onClickDelete(item);
                                }}
                                isVisibleDelete={!item.isExternal}
                                isVisibleControls={[ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role)}
                            />
                        }
                    />
                ),
            };
        });
    };

    const getEdit = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <KedoOrganizationStructureEdit
                isFetchList={true}
                onClose={onCloseModal}
                clientId={clientId}
                kedoDepartmentId={modalData.item.kedoDepartmentId}
            />
        );
    };

    return (
        <NmPage
            noPadding={true}
            openFilter={true}
            widthByFilter={true}
            typeFilter="vertical"
            header={
                <>
                    <NmTitle
                        count={totalCount}
                        size="xl"
                    >
                        Отделы
                    </NmTitle>
                    {getEdit()}
                </>
            }
            filtersBase={
                <KedoOrganizationStructureDepartmentsFilter
                    clientId={clientId}
                    filterData={filterData}
                    onClear={onClear}
                    onSearch={onSearch}
                    kedoDepartmentId={kedoDepartmentId}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progress}
            isEmptyPage={isEmpty(list)}
            emptyPageProps={{
                isSearch,
            }}
        >
            {getConfirmWindow()}
            <CheckboxList rows={getRows()} />
        </NmPage>
    );
};