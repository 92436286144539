import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useKedoDepartments} from "./hooks/useDepartments";

export const KedoDepartmentsDropdown = (props) => {
    const {
        clientId,
        kedoDepartmentId,
        ...otherProps
    } = props;

    const kedoDepartments = useKedoDepartments({
        clientId,
        parentDepartmentId: kedoDepartmentId,
    });

    return (
        <NmDropdownV2
            {...otherProps}
            {...kedoDepartments}
        />
    );
};