import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmListCard from "../../ActualComponents/NmList/Card";
import Text from "../../ActualComponents/Text";
import ExtLink from "../../ExtLink";
import LinkMarkdown from "../../LinkMarkdown";
import MultiSelect from "../../MultiSelect";
import NmBadge from "../../NmBadge";
import ViewTextButton from "../../ViewTextButton";
import {MailingsListCardModal} from "../ListCardModal";

import bem from "../../../utils/bem";
import {formatLocalDate} from "../../../utils/dateFormat";
import {
    phoneFormat,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastWarning} from "../../../utils/toastHelper";

import {COLOR} from "../../../constants/color";
import {GENDER} from "../../../constants/contractorInfo";
import {LINK_CLIENT_INFO} from "../../../constants/links";
import {NOTIFICATION_SOURCE} from "../../../constants/notifications";

import {
    clientNameIdMapSelector,
    clientRoleMapSelector,
} from "../../../ducks/client";
import {getWorkRegionsOptionsSelector} from "../../../ducks/contractor";
import {downloadDocument} from "../../../ducks/documents";
import {
    getOrderCategoriesAll,
    getOrderCategoriesAllOptionsSelector,
} from "../../../ducks/orderCategories";
import {
    subCategoriesAllOptionsSelector,
} from "../../../ducks/orderSubcategories";
import {
    specialitiesAllV2OptionsSelector,
} from "../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

import {FILTER_SENDER, FILTER_TYPE, MAILINGS_STATUS} from "../../../constants/ mailings/segmentationType";

class MailingsListCard extends Component {
    static propTypes = {
        mailing: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpenCard: false,
        };

        const [block, element] = bem("mailings-list-card");

        this.block = block;
        this.element = element;
    }

    downloadReport = (reportUrl) => {
        const {downloadDocument, t} = this.props;

        if (!reportUrl) {
            toastWarning(t("mailings-list.no-report"));
            return;
        }

        const parse = require("url-parse");

        const {query, pathname} = parse(reportUrl);

        downloadDocument({
            extension: "xlsx",
            documentType: "Список получателей",
            isDownload: true,
            isFillOrigin: true,
            downloadLink: `${pathname}${query}`,
        });
    };

    renderRecipientsCount({filterSender, recipientsCount, reportUrl}) {
        const sender = filterSender === FILTER_SENDER.BY_CLIENT ? "Заказчик" : "Исполнитель";

        if (recipientsCount === 0) {
            return sender;
        }

        return (
            <div className={this.element("sender")}>
                <span
                    className="app-link"
                    onClick={() => {
                        this.downloadReport(reportUrl);
                    }}
                >
                    {` ${sender} ${recipientsCount ? `(${recipientsCount})` : ""}`}
                </span>
            </div>
        );
    }

    toggleCardModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenCard: !prevState.isOpenCard,
        }));
    };

    getMailingCards = () => {
        const {
            mailing,
            categoriesAllOptions,
            clientAllOptions,
            roleAllOptions,
            specialityOptions,
            workRegionsOptions,
            subCategoriesAllOptions,
        } = this.props;

        const {
            filterSender,
            filterType,
            categoryIds,
            clientIds,
            roles,
            workRegions,
            addressFiasIds,
            addressesInfo,
            specialityIds,
            subcategoryIds,
            gender,
        } = mailing;

        let cards = [];

        if (filterType === FILTER_TYPE.ALL) {
            cards.push({
                value: filterSender === FILTER_SENDER.BY_CLIENT ? "Все заказчики" : "Все исполнители",
                className: "col-16 col-md-4 col-xxl-5",
            });
        }

        if ((filterSender === FILTER_SENDER.BY_CONTRACTOR || isNullOrWhitespace(filterSender)) && filterType === FILTER_TYPE.BY_PARAMS) {
            const categoryCard = categoryIds.length ? [{
                title: "Категории/Подкатегории",
                values: [
                    {
                        text: <MultiSelect
                            readOnly
                            isShowContent={false}
                            options={categoriesAllOptions}
                            value={categoryIds}
                            classNameOption="mailings-list-card__multiselect mailings-list-card__multiselect_w92"
                            classNameContent="multiselect__content-without-padding"
                            classNameText="multiselect__text-without-padding"
                            classNameInfo="info_without-border"
                            classNameContainer="multiselect__container_small"
                        />,
                    },
                    {
                        text: <MultiSelect
                            readOnly
                            classNameOption="mailings-list-card__multiselect mailings-list-card__multiselect_w92"
                            isShowContent={false}
                            options={subCategoriesAllOptions}
                            value={subcategoryIds}
                            classNameText="multiselect__content-without-padding"
                            classNameInfo="info_without-border"
                            classNameContainer="multiselect__container_small"
                        />,
                    },
                ],
                className: "col-16 col-md-4 col-xxl-5",
            }] : [];

            const genderCard = gender ? [{
                title: "Пол",
                value: GENDER[gender],
                className: "col-16 col-md-4 col-xxl-5",
            }] : [];

            cards = [
                ...this.getMailingCardsCity(addressFiasIds, addressesInfo),
                ...this.getCardsWidthOptions({
                    options: workRegionsOptions,
                    value: workRegions,
                    nameOne: "Регион",
                    nameMore: "Регионы",
                }),
                ...this.getCardsWidthOptions({
                    options: specialityOptions,
                    value: specialityIds,
                    nameOne: "Вид деятельности",
                    nameMore: "Виды деятельности",
                }),
                ...this.getCardsWidthOptions({
                    options: roleAllOptions,
                    value: roles,
                    nameOne: "Роль",
                    nameMore: "Роли",
                }),
                ...categoryCard,
                ...genderCard,
            ];
        }

        if (filterSender === FILTER_SENDER.BY_CLIENT && filterType === FILTER_TYPE.BY_PARAMS) {
            cards = [
                ...this.getCardsWidthOptions({
                    options: categoriesAllOptions,
                    value: categoryIds,
                    nameOne: "Категория",
                    nameMore: "Категории",
                }),
                ...this.getCardsWidthOptions({
                    options: clientAllOptions,
                    value: clientIds,
                    nameOne: "Заказчик",
                    nameMore: "Заказчики",
                }),
                ...this.getCardsWidthOptions({
                    options: roleAllOptions,
                    value: roles,
                    nameOne: "Роль",
                    nameMore: "Роли",
                }),
            ];
        }

        if (filterType === FILTER_TYPE.BY_PHONE_NUMBERS) {
            cards = this.getMailingCardsPhones(mailing);
        }

        return cards;
    };

    getCardsWidthOptions = ({value = "", options, nameOne, nameMore}) => {
        if (!value.length) {
            return [];
        }

        const isOneValue = value.length === 1;

        return [{
            title: isOneValue ? nameOne : nameMore,
            value:
                !isOneValue ?
                    <MultiSelect
                        readOnly
                        isShowContent={false}
                        options={options}
                        value={value}
                        classNameText="multiselect__content-without-padding"
                        classNameInfo="info_without-border"
                        classNameContainer="multiselect__container_small"
                        className="mailings-list-card__multiselect"
                    /> : options.find(item => item.value && item.value.includes(value[0]))?.text,
            className: "col-16 col-md-4 col-xxl-5",
            overflowEllipseText: isOneValue,
        }];
    };

    getMailingCardsCity = (addressFiasIds, addressesInfo) => {
        if (!addressFiasIds.length) {
            return [];
        }

        const options = Object.values(addressesInfo).map((item) => {
            const {
                addressType,
                cityId,
                cityName,
                regionId,
                regionName,
                settlementId,
                settlementName,
                areaId,
                areaName,
            } = item;

            if (addressType === "AREA") {
                return {
                    key: areaId,
                    value: areaId,
                    text: areaName,
                };
            }

            const isCity = addressType === "CITY";

            return {
                key: (isCity ? cityId : settlementId) || regionId,
                value: (isCity ? cityId : settlementId) || regionId,
                text: (isCity ? cityName : settlementName) || regionName,
            };
        });

        return this.getCardsWidthOptions({
            nameOne: "Город",
            nameMore: "Города",
            options: options,
            value: addressFiasIds,
        });
    };

    getMailingCardsPhones = ({phones, archivedContractorFio}) => {
        if (archivedContractorFio) {
            return [{
                title: "ФИО",
                value: archivedContractorFio,
                className: "col-16 col-md-4 col-xxl-5",
                overflowEllipseText: true,
            }];
        }

        return [{
            title: "Номера",
            value: phones.length !== 1 ? phones.length : phoneFormat(phones[0]),
            className: "col-16 col-md-4 col-xxl-5",
        }];
    };

    downloadFile(file) {
        const {downloadDocument} = this.props;
        const {link, name: fileName} = file;

        downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            fileName,
            downloadLink: link,
        });
    }

    mapFiles = (selectedFiles) => {
        return selectedFiles.map((file, index) => {
            const {name} = file;

            return (
                <Text
                    key={index}
                    className={this.element("file-link")}
                    noWrap
                    size="sm"
                    color={COLOR.PASSIVE_100}
                    onClick={() => {
                        this.downloadFile(file);
                    }}
                >
                    {name}
                </Text>
            );
        });
    };

    render() {
        const {
            mailing,
            actions,
            clientId,
        } = this.props;
        const {
            isOpenCard,
        } = this.state;
        const {
            status,
            createDateTime,
            sendDateTime,
            title,
            text,
            author,
            filesNameLinkList,
            name,
            notificationSource,
            clientName,
        } = mailing;

        const isVisibleClient = notificationSource === NOTIFICATION_SOURCE.CLIENT_ADMIN_UI && !clientId;

        return (
            <>
                {isOpenCard &&
                    <MailingsListCardModal
                        onCloseModal={() => {
                            this.toggleCardModal();
                        }}
                        data={mailing}
                        clientId={clientId}
                    />
                }
                <NmListCard
                    secondaryHeaderStatus={
                        status &&
                        <NmBadge
                            text={MAILINGS_STATUS[status]?.TEXT}
                            mod={MAILINGS_STATUS[status]?.BADGE_MOD}
                        />
                    }
                    secondaryHeader={`Рассылка от ${formatLocalDate(createDateTime, "dd.MM.yyyy HH:mm")}`}
                    primaryHeader={title}
                    primaryHeaderLink
                    onClickLink={() => this.toggleCardModal()}
                    labels={[
                        {
                            label: "Содержание",
                            text: (
                                <ViewTextButton>
                                    <Text
                                        preWrap={true}
                                        level="2"
                                        color={COLOR.SECONDARY_100}
                                    >
                                        <LinkMarkdown>
                                            {text}
                                        </LinkMarkdown>
                                    </Text>
                                </ViewTextButton>
                            ),
                        },
                        {label: "Наименование", text: name || "Отсутствует"},
                        {label: "Автор", text: author},
                        isVisibleClient && {
                            label: "Получатель",
                            text: this.renderRecipientsCount(mailing),
                        },
                        sendDateTime && {label: "Отправлено", text: formatLocalDate(sendDateTime, "dd.MM.yyyy HH:mm")},
                        Boolean(filesNameLinkList?.length) && {
                            label: "Документы", text: <div className="flex flex-wrap">
                                {this.mapFiles(filesNameLinkList)}
                            </div>,
                        },
                        isVisibleClient && {
                            label: "Компания",
                            text: (
                                <ExtLink
                                    historyEnabled
                                    to={LINK_CLIENT_INFO.replace(":clientId", mailing.clientId)}
                                >
                                    {clientName}
                                </ExtLink>
                            ),
                        },
                    ]}
                    cards={this.getMailingCards()}
                    cardsWithContainer
                    classNameMainContent="col-16 col-xxl-8"
                    cardsContainerClassName="col-16 col-xxl-7 mt-md-4 mt-xxl-0 align-items-end"
                    actionsClassName="col-1 align-items-end justify-content-end"
                    actions={actions}
                />
            </>
        );
    }
}

export default connect(
    state => ({
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        workRegionsOptions: getWorkRegionsOptionsSelector(state),
        categoriesAllOptions: getOrderCategoriesAllOptionsSelector(state),
        subCategoriesAllOptions: subCategoriesAllOptionsSelector(state),
        clientAllOptions: clientNameIdMapSelector(state),
        roleAllOptions: clientRoleMapSelector(state),
    }),
    {
        downloadDocument,
        getOrderCategoriesAll,
    },
)(withTranslation()(MailingsListCard));
