import React from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderHistoryPopup from "../../../../order/order-history-popup";

import {
    getFinancesOrdersHistory,
    updateFieldFinancesOrder,
} from "../../../../../ducks/bff/finances/orders/actionCreators";
import {
    financesOrdersHistoryListSelector,
    financesOrdersHistoryProgressSelector,
    financesOrdersHistoryTotalCountSelector,
    financesOrdersHistoryTotalPagesSelector,
} from "../../../../../ducks/bff/finances/orders/selectors";

const AdmFinancesOrderHistoryPopup = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(financesOrdersHistoryListSelector);
    const totalCount = useSelector(financesOrdersHistoryTotalCountSelector);
    const totalPages = useSelector(financesOrdersHistoryTotalPagesSelector);
    const progress = useSelector(financesOrdersHistoryProgressSelector);

    return (
        <OrderHistoryPopup
            {...props}
            events={list}
            totalCount={totalCount}
            totalPages={totalPages}
            progress={progress}
            onFetchList={(data) => {
                dispatch(getFinancesOrdersHistory(data));
            }}
            onClearStore={() => {
                dispatch(updateFieldFinancesOrder({
                    historyPageData: {},
                    historyProgress: false,
                    historyTotalCount: 0,
                    historyList: [],
                }));
            }}
        />
    );
};

export default AdmFinancesOrderHistoryPopup;