import React from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {ReactComponent as ResetIcon} from "../../../images/reset_24.svg";
import {ClientReportsMonitoringRiskContractorsForm} from "./components";

import {useClientMonitoringRiskAction} from "./hooks/useAction";
import {useClientMonitoringRiskFetchList} from "./hooks/useFetchList";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {RISK_STATUS} from "../../../constants/monitoringRisk";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {
    bffClientMonitoringRiskGenerateReportLoadingSelector,
    bffClientMonitoringRiskIdentificationRiskSelector,
    bffClientMonitoringRiskLastExportDateTimeSelector,
    bffClientMonitoringRiskListLoadingSelector,
    bffClientMonitoringRiskListSelector,
    bffClientMonitoringRiskStartedExportSelector,
    bffClientMonitoringRiskUpdateDataLoadingSelector,
} from "../../../ducks/bff/clients/monitoring-risk/selectors";

export const ClientReportsMonitoringRisk = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const role = ls(USER_ROLE);

    const list = useSelector(bffClientMonitoringRiskListSelector);
    const listLoading = useSelector(bffClientMonitoringRiskListLoadingSelector);
    const identificationRisk = useSelector(bffClientMonitoringRiskIdentificationRiskSelector);
    const lastExportDateTime = useSelector(bffClientMonitoringRiskLastExportDateTimeSelector);
    const startedExport = useSelector(bffClientMonitoringRiskStartedExportSelector);
    const generateReportLoading = useSelector(bffClientMonitoringRiskGenerateReportLoadingSelector);
    const updateDataLoading = useSelector(bffClientMonitoringRiskUpdateDataLoadingSelector);

    const {fetchList} = useClientMonitoringRiskFetchList({clientId});

    const {
        onGenerateReport,
        onUpdateData,
        modalData,
        onCloseModal,
        onOpenModal,
    } = useClientMonitoringRiskAction({
        clientId,
        fetchList,
    });

    const mediaControls = {
        renderCount: {
            mobile: 0,
            tablet: 2,
            desktop: 2,
        },
        buttons: [
            {
                component: startedExport ? COMPONENT.BUTTON_WITH_TOOLTIP : COMPONENT.BUTTON,
                props: {
                    onClick: onUpdateData,
                    children: "Обновить данные",
                    color: "white",
                    disabled: startedExport || updateDataLoading,
                    loaderInverted: false,
                    loading: updateDataLoading,
                    tooltip: (
                        <HelpTooltip
                            width={24}
                            height={24}
                            children="Данные находятся в процессе обновления, это может занять от часа до 3-х дней.
                             После завершения вы сможете сформировать
                             обновленный отчет или запросить обновление снова"
                            position="bottom-right"
                            info
                            hover
                        />
                    ),
                },
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: onGenerateReport,
                    children: "Сформировать отчет",
                    loading: generateReportLoading,
                    disabled: generateReportLoading || startedExport,
                    loaderInverted: false,
                },
                visible: [PROJECT_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
            },
        ],
    };

    const getLastUpdateText = ({updatedAt, inProgress}) => {
        if (inProgress) {
            return (
                <>
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_100}
                        className="me-1"
                    >
                        В процессе
                    </Text>
                    <ResetIcon
                        width={18}
                        height={18}
                        color={COLOR.SECONDARY_45}
                    />
                </>
            );
        }

        return formatLocalDate(updatedAt, "dd.MM.yyyy HH:mm") || "-";
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.type?.description}
                        noDivider
                        secondaryHeaderStatus={
                            item.visibleOnlyNmAdmin &&
                            <NmBadge
                                mod="gray"
                                text="Видно только администратору"
                            />
                        }
                        labels={[
                            {
                                label: "Описание критерия",
                                text: item.description,
                                columnOnMobile: true,
                                noWrap: false,
                                inline: true,
                            },
                            {
                                label: "Количество исполнителей",
                                text: item.countContractor,
                                color: "blue",
                                onClickText: () => {
                                    onOpenModal({
                                        isOpenViewContractorsModal: true,
                                        modalHeader: item.type?.description,
                                        riskId: item.riskId,
                                    });
                                },
                                alignItems: "flex-start",
                                noWrap: false,
                                cursorPointer: true,
                            },
                            {
                                label: "Последнее обновление",
                                classNameText: "d-flex",
                                text: getLastUpdateText({
                                    updatedAt: item.updatedAt,
                                    inProgress: item.status.value === RISK_STATUS.IN_PROGRESS,
                                }),
                            },
                        ]}
                    />
                ),
            };
        });
    };

    const getBadge = () => {
        if (
            [ADMIN, NM_MANAGER, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(role) &&
            identificationRisk
        ) {
            return (
                <NmBadge
                    text="Есть риск"
                    mod="orange"
                    tooltipPosition="bottom-left"
                    leftTooltipProps={{
                        hover: true,
                    }}
                    leftTooltipText="Есть риск передачи сведений в межведомственную комиссию. Подробности в отчете
                             «Отчет оценки критериев ФНС для передачи данных в
                            межведомственную комиссию» в разделе Экспорт -> Экспорт Отчетов"
                />
            );
        }

        return null;
    };

    const getSubTitle = () => {
        if ([
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
        ].includes(role)) {
            return `Последний отчет: ${formatLocalDate(lastExportDateTime, "dd.MM.yyyy HH:mm") || "-"}`;
        }

        return null;
    };

    const renderViewContractorsModal = () => {
        if (modalData?.isOpenViewContractorsModal) {
            return (
                <ClientReportsMonitoringRiskContractorsForm
                    onClose={onCloseModal}
                    modalHeader={modalData.modalHeader}
                    riskId={modalData.riskId}
                    clientId={clientId}
                />
            );
        }
    };

    const renderInformationModal = () => {
        if (modalData?.isOpenInformationModal) {
            return (
                <NmConfirmV2
                    {...modalData}
                    onCancel={onCloseModal}
                    confirmButton="Понятно"
                />
            );
        }
    };

    return (
        <NmPage
            mediaControls={mediaControls}
            isLoaded={listLoading}
            titleOverflowVisible
            header={
                <>
                    {renderInformationModal()}
                    {renderViewContractorsModal()}
                    <NmPageHeader
                        text="Мониторинг рисков"
                        subTitle={getSubTitle()}
                        noWrap={false}
                        badge={getBadge()}
                        className="d-block d-md-flex"
                        classNameBadge="ms-0 ms-md-3 mt-3 mt-md-0"
                    />
                </>
            }
            isEmptyPage={!list.length}
        >
            <CheckboxList rows={getRows()} />
        </NmPage>
    );
};