import {
    ADD_DIRECTORIES_DOCUMENTS_REQUEST,
    CLEAR_FIELD_DIRECTORIES_DOCUMENTS,
    DELETE_DIRECTORIES_DOCUMENTS_REQUEST,
    GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST,
    UPDATE_DIRECTORIES_DOCUMENTS_REQUEST,
} from "./actions";

export function getDirectoriesDocumentsPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST,
        payload,
    };
}

export function updateDirectoriesDocuments(payload) {
    return {
        type: UPDATE_DIRECTORIES_DOCUMENTS_REQUEST,
        payload,
    };
}

export function addDirectoriesDocuments(payload) {
    return {
        type: ADD_DIRECTORIES_DOCUMENTS_REQUEST,
        payload,
    };
}

export function deleteDirectoriesDocuments(payload) {
    return {
        type: DELETE_DIRECTORIES_DOCUMENTS_REQUEST,
        payload,
    };
}

export function clearFieldsDirectoriesDocuments() {
    return {
        type: CLEAR_FIELD_DIRECTORIES_DOCUMENTS,
    };
}