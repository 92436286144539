import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const kedoDirectories = state => state.kedoDirectories;

const getDirectoryData = (directory, options) => {
    const {
        guidFieldName,
        isBaseDirectory,
    } = options;
    const {
        totalCount = 0,
        pageData = {},
        list = [],
    } = directory;

    return {
        ...directory,
        totalCount,
        list: isBaseDirectory ? list.map(item => {
            return {
                id: item[guidFieldName],
                clientId: item.clientId,
                clientName: item.clientName,
                value: item.name,
            };
        }) : list,
        totalPages: getTotalPages(totalCount, pageData.pageSize),
    };
};
export const kedoDirectoriesPositionsSelector = createSelector(
    kedoDirectories,
    ({positions}) => getDirectoryData(positions, {
        guidFieldName: "positionId",
        isBaseDirectory: true,
    }),
);
export const kedoDirectoriesSubdivisionsSelector = createSelector(
    kedoDirectories,
    ({subdivisions}) => getDirectoryData(subdivisions, {
        guidFieldName: "subdivisionId",
        isBaseDirectory: true,
    }),
);

export const kedoDirectoriesProgressActionSelector = createSelector(
    kedoDirectories,
    ({progressAction}) => progressAction,
);

export const kedoDirectoriesCountsSelector = createSelector(
    kedoDirectories,
    ({counts}) => counts,
);

export const kedoDirectoriesUploadDocumentTypesSelector = createSelector(
    kedoDirectories,
    ({uploadDocumentTypes}) => {
        const {
            totalCount = 0,
            list = [],
            pageData = {},
            progress,
        } = uploadDocumentTypes;

        return {
            progress,
            totalCount,
            list,
            totalPages: getTotalPages(totalCount, pageData.pageSize),
        };
    },
);

export const kedoDirectoriesTemplateDocumentTypesSelector = createSelector(
    kedoDirectories,
    ({templateDocumentTypes}) => {
        const {
            totalCount = 0,
            list = [],
            pageData = {},
            progress,
        } = templateDocumentTypes;

        return {
            progress,
            totalCount,
            list,
            totalPages: getTotalPages(totalCount, pageData.pageSize),
        };
    },
);

export const kedoDirectoriesTemplateClientUsageSelector = createSelector(
    kedoDirectories,
    ({clientUsage: {list = [], totalCount = 0, pageData: {pageSize} = {}}}) => {
        return {
            list,
            totalCount,
            totalPages: getTotalPages(totalCount, pageSize),
        };
    },
);

export const kedoDirectoriesDocumentRoutesSelector = createSelector(
    kedoDirectories,
    ({documentRoutes}) => {
        const {
            totalCount = 0,
            list = [],
            pageData = {},
            progress,
        } = documentRoutes;

        return {
            pageData,
            progress,
            totalCount,
            list,
            totalPages: getTotalPages(totalCount, pageData.pageSize),
        };
    },
);

export const kedoDirectoriesDocumentRouteDetailsSelector = createSelector(
    kedoDirectories,
    ({details, progressDetails}) => {
        return {
            details,
            progressDetails,
        };
    },
);

export const kedoDirectoriesDocumentRouteTypesSelector = createSelector(
    kedoDirectories,
    ({documentRouteTypesInfo, progressDocumentRouteTypes}) => {
        return {
            info: documentRouteTypesInfo,
            progress: progressDocumentRouteTypes,
        };
    },
);

export const kedoDirectoriesErrorSelector = createSelector(
    kedoDirectories,
    ({error}) => error,
);