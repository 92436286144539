import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";
import {Divider} from "semantic-ui-react";

import {clientPhoneMask} from "../../../../utils/clientHelper";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {phoneFormat, removeEinMask, removePhoneMask} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {CARD_TYPE, CARD_TYPE_FIELD, CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {BUSINESS_REGISTRATION_FORM_DICT} from "../../../../constants/dicts";
import {MASK_PHONE} from "../../../../constants/mask";
import {ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN, NM_MANAGER} from "../../../../constants/roles";
import {clientCardRule, merchandisingResponsiblePhoneRule} from "../../../../constants/validationRules";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector,
    clientCardPropertiesSelector,
    getClientInfoSelector,
    getClientTypeSelector,
} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class ClientCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor() {
        super();

        this.state = {
            clientInfo: {},
            editClientInfo: {},
            isEdit: false,
            errorForm: {},
        };

        this.role = ls(USER_ROLE);
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            clientInfo: _editClientInfo,
            isEdit,
        } = state;
        const {clientInfo} = newProps;

        if (
            JSON.stringify(clientInfo) !== JSON.stringify(_editClientInfo) &&
            !isEdit
        ) {
            return {
                ...state,
                editClientInfo: {
                    ...clientInfo,
                },
                clientInfo,
            };
        }

        return null;
    }

    handleOnChangeAddress(name) {
        return data =>
            this.handleChange(data.event, {
                value: data.unrestricted_value
                    ? data.unrestricted_value
                    : data.value,
                name,
            },
            );
    }

    cancelEdit = () => {
        const {editClientInfoCopy} = this.state;

        this.setState({
            isEdit: false,
            errorForm: {},
            editClientInfo: editClientInfoCopy,
        });
    };

    handleChange = (e, {value, mask, name}) => {
        if (mask && !mask.test(value) && value !== "") {return;}

        this.setState(prevState => ({
            ...prevState,
            editClientInfo: {
                ...prevState.editClientInfo,
                [name]: value,
            },
        }));
    };

    submitClientInfo = () => {
        const {updateClientCardInfo, clientInfo, client} = this.props;
        const {editClientInfo} = this.state;

        if (JSON.stringify(editClientInfo) === JSON.stringify(clientInfo)) {
            this.setState(prevState => ({
                ...prevState,
                isEdit: !prevState.isEdit,
            }));

            return;
        }

        const requestData = {
            ...client,
            ...editClientInfo,
            [CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE]: isNullOrWhitespace(
                editClientInfo[CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE],
            )
                ? null
                : editClientInfo[CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE],
            [CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]: removePhoneMask(
                editClientInfo[CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE],
            ),
            [CLIENT_FIELD_NAME.PHONE]: removePhoneMask(
                editClientInfo[CLIENT_FIELD_NAME.PHONE],
            ),
            [CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE]: removePhoneMask(
                editClientInfo[CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE],
            ),
            onSuccess: () => {
                this.setState(prevState => ({
                    ...prevState,
                    isEdit: !prevState.isEdit,
                }));
            },
        };

        updateClientCardInfo(requestData);
    };

    toggleCard = () => {
        const {
            clientType: {
                isForeignClient,
            },
        } = this.props;
        const {isEdit, editClientInfo} = this.state;

        if (isEdit) {
            const validationData = {
                ...editClientInfo,
                [CLIENT_FIELD_NAME.INN]: isForeignClient ? removeEinMask(editClientInfo[CLIENT_FIELD_NAME.INN]) :
                    editClientInfo[CLIENT_FIELD_NAME.INN],
                [CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]: removePhoneMask(
                    editClientInfo[CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE],
                ),
                [CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE]: removePhoneMask(
                    editClientInfo[CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE],
                ),
                [CLIENT_FIELD_NAME.PHONE]: removePhoneMask(
                    editClientInfo[CLIENT_FIELD_NAME.PHONE],
                ),
            };

            if (!this.isValidEditClient(validationData)) {
                return;
            }
        } else {
            this.setState({
                editClientInfoCopy: editClientInfo,
            });
        }

        if (isEdit) {
            this.submitClientInfo();

            return;
        }

        this.setState(prevState => ({
            isEdit: !prevState.isEdit,
        }));
    };

    get rule() {
        const {
            clientType: {
                isForeignClient,
                isIndividualClient,
            },
            clientProperties,
        } = this.props;

        const getRule = () => {
            if (isForeignClient) {
                return clientCardRule.clientInfoForeign;
            }

            if (isIndividualClient) {
                return clientCardRule.clientInfoIndividual;
            }

            return clientCardRule.clientInfo;
        };

        const rule = getRule();

        if (clientProperties.crowdTasksAvailable) {
            return {
                ...rule,
                [CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE]: merchandisingResponsiblePhoneRule,
            };
        }

        return rule;
    }

    isValidEditClient(editClient) {
        const errorForm = validate(editClient, this.rule, "");

        this.setState({
            errorForm,
        });

        return Object.values(errorForm).length === 0;
    }

    renderCardReadOnly(client) {
        const {
            clientTypeName,
            clientType: {
                isForeignClient,
                isIndividualClient,
                isRussianClient,
            },
            clientProperties,
            t,
        } = this.props;

        return (
            <>
                <LabelTextApp
                    label={isIndividualClient ? t("client-contract-info.full-name-ie") : t("client-contract-info.official-name-company")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.FULL_NAME] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={t("client-contract-info.company-name-short")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.NAME] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={t("client-contract-info.brand")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.BRAND] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={t("client-contract-info.business-registration-form")}
                    className="client-card-block__label"
                    text={BUSINESS_REGISTRATION_FORM_DICT[clientTypeName] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={t("client-contract-info.name-contact-person")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME] || t("is-not-specified.content")}
                />
                <div className="row">
                    <div className="col-md-8">
                        <LabelTextApp
                            label={t("client-contract-info.contact-phone-number")}
                            className="client-card-block__label"
                            text={
                                phoneFormat(
                                    client[CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE],
                                ) || t("is-not-specified.content")
                            }
                        />
                    </div>
                    <div className="col-md-8">
                        <LabelTextApp
                            label={t("client-contract-info.contact-email")}
                            className="client-card-block__label"
                            text={
                                client[CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL] ||
                                t("is-not-specified.content")
                            }
                        />
                    </div>
                </div>
                <Divider className="divider-fluid" />
                {!isIndividualClient ?
                    <LabelTextApp
                        label={t("client-contract-info.actual-address")}
                        className="client-card-block__label"
                        text={client[CLIENT_FIELD_NAME.ACTUAL_ADDRESS] || t("is-not-specified.content")}
                    /> :
                    null
                }
                <LabelTextApp
                    label={!isIndividualClient ? t("client-contract-info.registered-address") : t("client-contract-info.registration-address")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.REGISTERED_ADDRESS] || t("is-not-specified.content")}
                />
                <Divider className="divider-fluid" />
                <div className="row">
                    <div className="col-md-8">
                        <LabelTextApp
                            label={`${!isIndividualClient ? t("client-contract-info.client-phone-number") : t("client-contract-info.ie-phone-number")}`}
                            className="client-card-block__label"
                            text={
                                phoneFormat(client[CLIENT_FIELD_NAME.PHONE]) || t("is-not-specified.content")
                            }
                        />
                    </div>
                    <div className="col-md-8">
                        <LabelTextApp
                            label={`${!isIndividualClient ? t("client-contract-info.client-email") : t("client-contract-info.ie-email")}`}
                            className="client-card-block__label"
                            text={client[CLIENT_FIELD_NAME.EMAIL] || t("is-not-specified.content")}
                        />
                    </div>
                </div>
                {
                    clientProperties.crowdTasksAvailable &&
                    <div className="row">
                        <div className="col-md-8">
                            <LabelTextApp
                                label="Контакты ответственного за мерчандайзинг"
                                className="client-card-block__label"
                                text={client[CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE] || t("is-not-specified.content")}
                            />
                        </div>
                    </div>
                }
                <Divider className="divider-fluid" />
                <div className="row">
                    {!isForeignClient ?
                        <div className="col-md-8">
                            <LabelTextApp
                                label={!isIndividualClient ? "ОГРН" : "ОГРНИП"}
                                className="client-card-block__label"
                                text={client[CLIENT_FIELD_NAME.OGRN] || t("is-not-specified.content")}
                            />
                        </div> :
                        null
                    }
                    {isRussianClient ?
                        <div className="col-md-8">
                            <LabelTextApp
                                label="КПП"
                                className="client-card-block__label"
                                text={
                                    client[CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE] ||
                                    t("is-not-specified.content")
                                }
                            />
                        </div> :
                        null
                    }
                </div>
                <LabelTextApp
                    label={!isForeignClient ? "ИНН" : "Идентификационный номер работодателя (EIN)"}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.INN] || t("is-not-specified.content")}
                />
            </>
        );
    }

    renderCardEditMode(client) {
        const {errorForm} = this.state;

        const {
            clientType: {
                isForeignClient,
                isIndividualClient,
                isRussianClient,
            },
            clientTypeName,
            clientProperties,
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            required
                            size="lg"
                            label={isIndividualClient ? t("client-contract-info.full-name-ie") : t("client-contract-info.official-name-company")}
                            disabled={![ADMIN, NM_MANAGER].includes(this.role)}
                            className="client-form__control"
                            placeholder={isIndividualClient ? t("client-contract-info.full-name-ie") : t("client-contract-info.official-name-company")}
                            name={CLIENT_FIELD_NAME.FULL_NAME}
                            value={client[CLIENT_FIELD_NAME.FULL_NAME]}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.FULL_NAME]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            required
                            size="lg"
                            label={t("client-contract-info.company-name-short")}
                            disabled={![ADMIN, NM_MANAGER].includes(this.role)}
                            className="client-form__control"
                            placeholder={t("client-contract-info.company-name-short")}
                            name={CLIENT_FIELD_NAME.NAME}
                            value={client[CLIENT_FIELD_NAME.NAME]}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.NAME]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-contract-info.brand")}
                            disabled={![ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)}
                            className="client-form__control"
                            placeholder={t("client-contract-info.brand")}
                            name={CLIENT_FIELD_NAME.BRAND}
                            value={client[CLIENT_FIELD_NAME.BRAND]}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.BRAND]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            required
                            label={t("client-contract-info.business-registration-form")}
                            disabled
                            value={BUSINESS_REGISTRATION_FORM_DICT[clientTypeName] || ""}
                        />
                    </div>
                </div>
                <Divider className="divider-fluid" />
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-contract-info.name-contact-person")}
                            className="client-form__control"
                            placeholder={t("client-contract-info.name-contact-person")}
                            value={client[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME] || ""}
                            name={CLIENT_FIELD_NAME.REPRESENTATIVE_NAME}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-contract-info.contact-phone-number")}
                            className="client-form__control"
                            name={CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE}
                            mask={clientPhoneMask[clientTypeName]}
                            value={client[CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]}
                            placeholder={clientPhoneMask[clientTypeName]}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-contract-info.contact-email")}
                            name={CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL}
                            className="client-form__control"
                            iconPosition="left"
                            placeholder="Email"
                            value={client[CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL]}
                        />
                    </div>
                </div>
                <Divider className="divider-fluid" />
                {!isIndividualClient ?
                    <>
                        <div className="row">
                            <div className="col-md-16">
                                <NmDadataInput
                                    size="lg"
                                    label={t("client-contract-info.actual-address")}
                                    query={client[CLIENT_FIELD_NAME.ACTUAL_ADDRESS]}
                                    error={errorForm[CLIENT_FIELD_NAME.ACTUAL_ADDRESS]}
                                    name={CLIENT_FIELD_NAME.ACTUAL_ADDRESS}
                                    required
                                    onChange={this.handleOnChangeAddress(
                                        CLIENT_FIELD_NAME.ACTUAL_ADDRESS,
                                        CARD_TYPE.CLIENT_INFO,
                                        CARD_TYPE_FIELD.EDIT_CLIENT_INFO,
                                    )}
                                    placeholder={t("client-contract-info.actual-address")}
                                />
                            </div>
                        </div>
                    </> :
                    null
                }
                <div className="row">
                    <div className="col-md-16">
                        <NmDadataInput
                            size="lg"
                            label={!isIndividualClient ? t("client-contract-info.registered-address") : t("client-contract-info.registration-address")}
                            query={client[CLIENT_FIELD_NAME.REGISTERED_ADDRESS]}
                            error={errorForm[CLIENT_FIELD_NAME.REGISTERED_ADDRESS]}
                            name={CLIENT_FIELD_NAME.REGISTERED_ADDRESS}
                            onChange={this.handleOnChangeAddress(
                                CLIENT_FIELD_NAME.REGISTERED_ADDRESS,
                                CARD_TYPE.CLIENT_INFO,
                                CARD_TYPE_FIELD.EDIT_CLIENT_INFO,
                            )}
                            required
                            placeholder={t("client-contract-info.registered-address")}
                        />
                    </div>
                </div>
                <Divider className="divider-fluid" />
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={!isIndividualClient ? t("client-contract-info.client-phone-number") : t("client-contract-info.ie-phone-number")}
                            required
                            className="client-form__control"
                            name={CLIENT_FIELD_NAME.PHONE}
                            mask={clientPhoneMask[clientTypeName]}
                            value={client[CLIENT_FIELD_NAME.PHONE] || ""}
                            placeholder={clientPhoneMask[clientTypeName]}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.PHONE]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={!isIndividualClient ? t("client-contract-info.client-email") : t("client-contract-info.ie-email")}
                            required
                            name={CLIENT_FIELD_NAME.EMAIL}
                            className="client-form__control"
                            placeholder="Email"
                            value={client[CLIENT_FIELD_NAME.EMAIL] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.EMAIL]}
                        />
                    </div>
                </div>
                {
                    clientProperties.crowdTasksAvailable &&
                    <div className="row">
                        <div className="col-md-16">
                            <NmInputV2
                                size="lg"
                                required={true}
                                label="Контакты ответственного за мерчандайзинг"
                                name={CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE}
                                mask={MASK_PHONE}
                                placeholder={MASK_PHONE}
                                value={client[CLIENT_FIELD_NAME.MERCHANDISING_RESPONSIBLE_PHONE]}
                                onChange={this.handleChange}
                                error={errorForm.merchandisingResponsiblePhone}
                            />
                        </div>
                    </div>
                }
                <Divider className="divider-fluid" />
                {!isForeignClient ?
                    <>
                        <div className="row">
                            <div className="col-md-16">
                                <NmInputV2
                                    size="lg"
                                    label={!isIndividualClient ? "ОГРН" : "ОГРНИП"}
                                    required
                                    className="client-form__control"
                                    placeholder={!isIndividualClient ? "ОГРН" : "ОГРНИП"}
                                    name={CLIENT_FIELD_NAME.OGRN}
                                    value={client[CLIENT_FIELD_NAME.OGRN] || ""}
                                    onChange={this.handleChange}
                                    error={errorForm[CLIENT_FIELD_NAME.OGRN]}
                                />
                            </div>
                        </div>
                    </> :
                    null
                }
                {isRussianClient ?
                    <>
                        <div className="row">
                            <div className="col-md-16">
                                <NmInputV2
                                    size="lg"
                                    label="КПП"
                                    required
                                    className="client-form__control"
                                    name={CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE}
                                    placeholder="КПП"
                                    value={client[CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE] || ""}
                                    error={errorForm[CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE]}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </> :
                    null
                }
                <div className="row">
                    <div className="col-md-16">
                        {isForeignClient ?
                            <NmInputV2
                                size="lg"
                                label="Идентификационный номер работодателя (EIN)"
                                required
                                fieldClassName="client-new-form-row__input"
                                error={errorForm[CLIENT_FIELD_NAME.INN]}
                                type="text"
                                mask="99-9999999"
                                maskChar={null}
                                name={CLIENT_FIELD_NAME.INN}
                                placeholder="Введите EIN"
                                value={client[CLIENT_FIELD_NAME.INN] || ""}
                                onChange={this.handleChange}
                            /> :
                            <NmInputV2
                                size="lg"
                                label="ИНН"
                                required
                                disabled={this.role !== ADMIN}
                                className="client-form__control"
                                placeholder="ИНН"
                                value={client[CLIENT_FIELD_NAME.INN] || ""}
                                name={CLIENT_FIELD_NAME.INN}
                                onChange={this.handleChange}
                                error={errorForm[CLIENT_FIELD_NAME.INN]}
                            />
                        }
                    </div>
                </div>
            </NmForm>
        );
    }

    render() {
        const {editClientInfo, isEdit} = this.state;
        const {isEditable, t} = this.props;

        return (
            <CardApp
                title={t("client-contract-info.client-info-title")}
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEdit}
                isEdit={isEdit}
            >
                {isEdit ?
                    this.renderCardEditMode(editClientInfo) :
                    this.renderCardReadOnly(editClientInfo)}
            </CardApp>
        );
    }
}

export default connect(
    state => ({
        client: clientCardInfoSelector(state),
        clientInfo: getClientInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        clientTypeName: getClientTypeSelector(state),
    }),
    {
        updateClientCardInfo,
    },
)(withTranslation()(ClientCardBlock));