import {
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST,
    BFF_FOREMEN_REQUESTS_CLEAR_STORE,
} from "./actions";

export const getForemenRequestsRichPage = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST,
        payload,
    };
};

export const updateForemanRequest = (payload) => {
    return {
        type: BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST,
        payload,
    };
};

export const getForemenNewRequestsAndPaymentsCount = () => {
    return {
        type: BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_REQUEST,
    };
};

export const clearForemenRequests = () => {
    return {
        type: BFF_FOREMEN_REQUESTS_CLEAR_STORE,
    };
};