import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";

import {getPageContractorFinanceBalance} from "../../../../../ducks/bff/contractor-сard/finance/balance/actionCreators";
import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";

export function useContractorFinanceBalanceFetch({filter = {}, pageSize, pageNum, contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.setContractorSmzTaxOffer,
            ],
        }));
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getPageContractorFinanceBalance({
            pageNum,
            pageSize,
            contractorId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}