import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {ImportMultipleValuesForm} from "../../../../../components/ImportMultipleValuesForm";

import {
    checkSmartLinkOrderNumberExists,
    importSmartLinkOrderNumbers,
    updateSmartLinkStore,
} from "../../../../../ducks/smart-link/actionCreators";
import {
    smartLinkErrorNumberExistsSelector,
    smartLinkErrorNumbersSelector,
    smartLinkProgressActionSelector,
    smartLinkProgressCheckSelector,
    smartLinkSuccessNumbersListSelector,
} from "../../../../../ducks/smart-link/selectors";

export const SmartLinkCreateByNumbers = (props) => {
    const {
        onChange,
        setIsHiddenModal,
        type,
        nums,
        isTask,
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(smartLinkProgressCheckSelector);
    const error = useSelector(smartLinkErrorNumberExistsSelector);
    const successNumbersList = useSelector(smartLinkSuccessNumbersListSelector);
    const errorNumbers = useSelector(smartLinkErrorNumbersSelector);
    const progressImport = useSelector(smartLinkProgressActionSelector);

    return (
        <ImportMultipleValuesForm
            onChange={onChange}
            inputLabel={isTask ? "Номер задания" : "Номер заказа"}
            inputMask="999999999999"
            errorNumbers={errorNumbers}
            nums={nums}
            checkExists={(params) => {
                const {
                    number,
                    saveNumber,
                } = params;

                dispatch(checkSmartLinkOrderNumberExists({
                    number,
                    type,
                    saveNumber,
                }));
            }}
            clearSuccessNumbers={() => {
                dispatch(updateSmartLinkStore({
                    numbers: [],
                }));
            }}
            requiredMessage={`Укажите хотя бы 1 номер ${isTask ? "задания" : "заказа"}`}
            inputPlaceholder={`Введите номер ${isTask ? "задания" : "заказа"}`}
            progress={progress}
            patternLink="/files/Шаблон_создания_ссылки_по_номерам.xlsx"
            headerAllNumbers={`Номера ${isTask ? "заданий" : "заказов"}`}
            importFromFile={(params) => {
                const {
                    file,
                    onSuccess,
                } = params;

                const formData = new FormData();

                formData.append("file", file);
                formData.append("type", type);

                dispatch(importSmartLinkOrderNumbers({
                    formData,
                    type,
                    onSuccess,
                }));
            }}
            error={error}
            successNumbers={successNumbersList}
            progressImport={progressImport}
            updateError={({error}) => {
                dispatch(updateSmartLinkStore({
                    errorOrderExists: error,
                }));
            }}
            setIsHiddenModal={setIsHiddenModal}
        />
    );
};