import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";

import {
    getFullName,
    phoneFormat,
    removePhoneMask,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {
    toastError,
    toastSuccess,
} from "../../../utils/toastHelper";

import {PHONE_MASK} from "../../../constants/phone";

import {addClientForemenForemanContractors} from "../../../ducks/bff/clients/foremen/foreman-contractors/actionCreators";
import {
    clearContractorStore,
    contractorNotForemanListSelector,
    contractorProgressNotForemanListSelector,
    contractorTotalPagesNotForemanListSelector,
    getNotForemanContractorsPage,
} from "../../../ducks/contractor";

import PropTypes from "prop-types";

import "./style.sass";

class BrigadeSearch extends Component {
    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        clientId: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            phoneFilter: "",
            pageSize: 100,
            pageNum: 1,
            contractorIdProgress: "",
            action: {},
            isOpenConfirm: false,
        };
    }

    componentWillUnmount() {
        const {clearContractorStore} = this.props;

        clearContractorStore({
            list: [],
        });
    }

    fetchList = () => {
        const {getNotForemanContractorsPage, clientId} = this.props;
        const {
            pageNum,
            pageSize,
            phoneFilter,
        } = this.state;

        getNotForemanContractorsPage({
            clientId,
            pageSize,
            pageNum,
            phoneFilter: removePhoneMask(phoneFilter),
        });
    };

    handleChange = (e, {value: phoneFilter}) => {
        if (isNullOrWhitespace(phoneFilter)) {
            return;
        }

        this.setState({
            phoneFilter,
        },
        this.fetchList,
        );
    };

    setProgressAdd(contractorIdProgress = "") {
        this.setState({contractorIdProgress});
    }

    showInviteConfirm(item) {
        const {t} = this.props;
        const {
            lastName,
            firstName,
            patronymic,
            fullName,
        } = item;
        this.setState({
            isOpenConfirm: true,
            action: {
                onConfirm: () => {
                    this.inviteForeman(item);
                },
                textConfirm: `${t("brigade-search.confirm-contractor-add-to-order")} "${lastName ? getFullName(lastName, firstName, patronymic): fullName}"?`,
            },
        });
    }

    inviteForeman(item) {
        const {contractorId: foremanContractorId} = item;
        this.handleCancelConfirm();

        this.setProgressAdd(foremanContractorId);

        const {addClientForemenForemanContractors, clientId} = this.props;

        addClientForemenForemanContractors({
            handleResponse: this.handleResponse,
            onError: () => {
                this.setProgressAdd("");
            },
            clientId,
            foremanContractorId,
        });
    }

    handleResponse = (result) => {
        const {t} = this.props;
        const {errorMessage} = result;
        this.setProgressAdd("");

        if(errorMessage){
            toastError(errorMessage);
            return;
        }

        toastSuccess(t("brigade-search.contractor-recruited-from-post-foreman"));
        this.fetchList();
    };

    handleCancelConfirm = () => {
        this.setState({action: {}, isOpenConfirm: false});
    };

    renderList(list) {
        const {t} = this.props;
        const {contractorIdProgress} = this.state;

        return list.map(item => {
            const {
                lastName,
                firstName,
                patronymic,
                phone,
                fullName: contractorFullName,
                contractorId,
                blocked,
                blockedReason,
            } = item;
            const fullName = lastName ? getFullName(lastName, firstName, patronymic) : contractorFullName;

            return (
                <>
                    <div className="brigade-search_list brigade-search-row">
                        <div className="brigade-search__item flex flex-aligned-center column-35">
                            {fullName}
                            {
                                blocked &&
                                    <NmAdvancedTooltip
                                        trigger={
                                            <BlockedIcon
                                                color="red"
                                            />
                                        }
                                        hover
                                    >
                                        {blockedReason}
                                    </NmAdvancedTooltip>
                            }
                        </div>
                        <div className="brigade-search__item column-35">
                            {phoneFormat(phone)}
                        </div>
                        <div className="brigade-search__item column-25 ">
                            <NmButton
                                size="xl"
                                className="brigade-search__item-invite-btn"
                                onClick={() => {
                                    this.showInviteConfirm(item);
                                }}
                                loading={contractorId === contractorIdProgress}
                                disabled={blocked}
                            >
                                {t("button.invite")}
                            </NmButton>
                        </div>
                    </div>
                    <div className="brigade-search-line" />
                </>
            );
        },
        );
    }

    renderConfirmWindow() {
        const {t} = this.props;
        const {
            isOpenConfirm,
            action: {
                textConfirm,
                onConfirm,
            },
        } = this.state;

        return (
            isOpenConfirm &&
                <NmConfirmV2
                    onCancel={this.handleCancelConfirm}
                    onConfirm={onConfirm}
                    confirmButton={t("button.yes")}
                    cancelButton={t("button.no")}
                    content={textConfirm}
                    size="sm"
                />
        );
    }

    render() {
        const {phoneFilter} = this.state;
        const {
            handleClose,
            list,
            progress,
            t,
        } = this.props;

        return (
            <NmModal
                align="top"
                onClose={handleClose}
                className="brigade-search"
                loading={progress}
                loaderInverted
                header={
                    <NmTitle size="lg">
                        {t("brigade-search.search-contractors")}
                    </NmTitle>
                }
            >
                {this.renderConfirmWindow()}
                <NmForm>
                    <NmInputV2
                        className="brigade-search__input"
                        size="xl"
                        mask={PHONE_MASK.FOREIGN}
                        maskChar={null}
                        iconPosition="left"
                        placeholder="Номер телефона"
                        onChange={this.handleChange}
                        value={phoneFilter}
                    />
                    <div className="brigade-search_count">
                        {t("brigade-search.found")}
:
                        {list.length}
                    </div>
                    <div className="brigade-search-results">
                        {this.renderList(list)}
                    </div>
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        list: contractorNotForemanListSelector(state),
        progress: contractorProgressNotForemanListSelector(state),
        totalPages: contractorTotalPagesNotForemanListSelector(state),
    }),
    {
        getNotForemanContractorsPage,
        addClientForemenForemanContractors,
        clearContractorStore,
    },
)(withTranslation()(BrigadeSearch));
