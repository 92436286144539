export const GET_PAGE_FINANCES_ORDERS_REQUEST = "GET_PAGE_FINANCES_ORDERS_REQUEST";
export const GET_PAGE_FINANCES_ORDERS_SUCCESS = "GET_PAGE_FINANCES_ORDERS_SUCCESS";
export const GET_PAGE_FINANCES_ORDERS_ERROR = "GET_PAGE_FINANCES_ORDERS_ERROR";

export const GET_HISTORY_FINANCES_ORDERS_REQUEST = "GET_HISTORY_FINANCES_ORDERS_REQUEST";
export const GET_HISTORY_FINANCES_ORDERS_SUCCESS = "GET_HISTORY_FINANCES_ORDERS_SUCCESS";
export const GET_HISTORY_FINANCES_ORDERS_ERROR = "GET_HISTORY_FINANCES_ORDERS_ERROR";

export const UPDATE_FIELDS_FINANCES_ORDERS = "UPDATE_FIELDS_FINANCES_ORDERS";

export const CLEAR_FIELDS_FINANCES_ORDERS = "CLEAR_FIELDS_FINANCES_ORDERS";