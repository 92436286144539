import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import ChatClientList from "../../../chats/chat-client-list";
import CrowdTaskActRegistry from "../../../crowd/task-act-registry";
import CrowdTaskActRegistryCard from "../../../crowd/task-act-registry/card";
import CrowdTaskAnalytics from "../../../crowd/task-analytics";
import CrowdTaskGroups from "../../../crowd/task-groups";
import CrowdTaskRegistry from "../../../crowd/task-registry";
import CrowdTaskCard from "../../../crowd/task-registry/card";
import CrowdTaskCardInviteCandidatesList from "../../../crowd/task-registry/card/invite-candidates-list";

import {
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD_SEARCH_PAGE,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES,
} from "../../../../constants/links";

export const ClientCardCrowdRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_REGISTRY}
                component={CrowdTaskRegistry}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_GROUPS}
                component={CrowdTaskGroups}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_GROUPS_CARD}
                component={CrowdTaskRegistry}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_GROUPS_CARD_SEARCH_PAGE}
                component={CrowdTaskRegistry}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES}
                component={CrowdTaskCardInviteCandidatesList}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_REGISTRY_CARD}
                component={CrowdTaskCard}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_CHATS}
                component={ChatClientList}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_ACT_REGISTRY}
                component={CrowdTaskActRegistry}
            />
            <Route
                exact
                path={LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD}
                component={CrowdTaskActRegistryCard}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CROWD_TASK_ANALYTICS}
                component={CrowdTaskAnalytics}
            />
        </Switch>
    );
};