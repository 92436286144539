import React from "react";
import {Route, Switch, withRouter} from "react-router";

import DepositPatentClientList from "../../../deposit/patent-client-list";
import PatentsRegistryCard from "../../../patents/card";
import PatentExportList from "../../../patents/exports";
import PatentsList from "../../../patents/list";
import PatentsDocumentsList from "../../../patents/patentd-documents-list";

import {
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST,
} from "../../../../constants/links";


const PatentsRoute = (props) => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST}
                component={PatentsList}
            />
            <Route
                exact
                path={LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD}
                component={PatentsRegistryCard}
            />
            <Route
                exact
                path={LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS}
                component={DepositPatentClientList}
            />
            <Route
                exact
                path={LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST}
                component={DepositPatentClientList}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST}
                component={() => <PatentsDocumentsList {...props} />}
            />
            <Route
                exact
                path={LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST}
                component={() => <PatentExportList {...props} />}
            />
        </Switch>
    );
};

export default withRouter(PatentsRoute);