import {
    CLEAR_CONTRACTOR_DOCUMENTS_STORE,
    CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS,
    CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR,
    CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST,
    CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS,
    CONTRACTOR_DOCUMENTS_REFRESH_ERROR,
    CONTRACTOR_DOCUMENTS_REFRESH_REQUEST,
    CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS,
    GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS,
    PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR,
    PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST,
    PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS,
} from "./actions";

export const storeContractorDocumentInitState = {
    actOfAcceptanceOfWork: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    agencyContract: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    consentToPersonalData: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    frameContract: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    orderApplication: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    insurancePolicy: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    receiptRNKO: {
        totalCount: 0,
        pageData: {},
        list: [],
    },
    pendingContracts: {
        list: [],
    },
};

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    progressAction: false,
    contractorDocumentCounts: {},
    identificationSheetList: [],
    registryFrameContractShortItems: [],
    registryFrameContractShortTotalCount: 0,
    ...storeContractorDocumentInitState,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST:
            const {
                field,
                ...reqData
            } = payload;

            return {
                ...state,
                [field]: {
                    ...state[field],
                    pageData: reqData,
                },
                progress: true,
            };
        case CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                [payload.field]: {
                    ...state[payload.field],
                    list: payload.documents,
                    totalCount: payload.totalCount,
                },
            };
        case CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case CONTRACTOR_DOCUMENTS_REFRESH_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS:
        case CONTRACTOR_DOCUMENTS_REFRESH_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS:
            return {
                ...state,
                contractorDocumentCounts: payload,
            };
        case GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS:
            const {
                results,
            } = payload;

            return {
                ...state,
                registryFrameContractShortItems: results,
                registryFrameContractShortTotalCount: payload.totalCount,
            };
        case GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST:
            return {
                ...state,
                registryFrameContractShortPageSize: payload.pageSize,
            };
        case GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS:
            return {
                ...state,
                identificationSheetList: [payload],
            };
        case PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS:
            return {
                ...state,
                pendingContracts: {
                    list: payload || [],
                },
                progressList: false,
            };
        case PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR:
            return {
                ...state,
                pendingContracts: {
                    list: [],
                },
                progressList: false,
            };
        case CLEAR_CONTRACTOR_DOCUMENTS_STORE:
            return initial;
        default:
            return state;
    }
};