import React from "react";
import {useSelector} from "react-redux";

import ContractorVerificationDataForm from "./components/form";

import {useMigrationStatusDict} from "../../../hooks/useMigrationStatusDict";
import {useContractorVerificationLocalization} from "./hooks/useLocalization";

import {contractorCardInfoSelector} from "../../../ducks/bff/contractor-сard/selectors";

const ContractorVerificationData = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const contractor = useSelector(contractorCardInfoSelector);

    const {
        localizationData,
    } = useContractorVerificationLocalization();

    const {
    } = useMigrationStatusDict({
        citizenship: contractor.citizenship,
        nameField: "contractorMigrationStatusDict",
    });

    return (
        <ContractorVerificationDataForm
            contractorId={contractorId}
            migrationStatus={contractor.migrationStatus}
            localizationData={localizationData}
        />
    );
};

export default ContractorVerificationData;