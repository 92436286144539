import {
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR,
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST,
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS,
    INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS,
    INTERNAL_PAYMENTS_TKB_PAY_ERROR,
    INTERNAL_PAYMENTS_TKB_PAY_REQUEST,
    INTERNAL_PAYMENTS_TKB_PAY_SUCCESS,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS,
    INTERNAL_PAYMENTS_UPDATE_STORE,
} from "./actions";

export const getInternalPaymentsInitialState = () => {
    return {
        list: [],
        totalCount: 0,
        operationStatus: null,
        allowedEmails: [],
        progress: false,
        progressAction: false,
        pageData: {},
        statuses: [],
    };
};

const initialState = getInternalPaymentsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS:
            return {
                ...state,
                allowedEmails: payload,
            };
        case INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case INTERNAL_PAYMENTS_TKB_PAY_ERROR:
        case INTERNAL_PAYMENTS_TKB_PAY_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case INTERNAL_PAYMENTS_TKB_PAY_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case INTERNAL_PAYMENTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR:
            return {
                ...state,
                progress: false,
            };
        case INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS:
            return {
                ...state,
                statuses: payload,
                progress: false,
            };
        default:
            return state;
    }
};