import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";
import {SettingsSpecialitiesEdit} from "./components/edit";

import {usePagination} from "../../../../hooks/usePagination";
import {useSpecialitiesAction} from "./hooks/useAction";
import {useSpecialitiesFetch} from "./hooks/useFetch";
import {useSpecialitiesFilter} from "./hooks/useFilter";

import bem from "../../../../utils/bem";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    settingsDirectoriesNaimixSpecialitiesListSelector,
    settingsDirectoriesNaimixSpecialitiesProgressActionSelector,
    settingsDirectoriesNaimixSpecialitiesProgressSelector,
    settingsDirectoriesNaimixSpecialitiesTotalCountSelector,
    settingsDirectoriesNaimixSpecialitiesTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/specialities/selectors";
import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";

import "./style.sass";

const SettingsDirectoriesSpecialities = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const [block, element] = bem("settings-directories-specialty");

    const totalPages = useSelector(settingsDirectoriesNaimixSpecialitiesTotalPagesSelector);
    const list = useSelector(settingsDirectoriesNaimixSpecialitiesListSelector);
    const totalCount = useSelector(settingsDirectoriesNaimixSpecialitiesTotalCountSelector);
    const progressPage = useSelector(settingsDirectoriesNaimixSpecialitiesProgressSelector);
    const progressAction = useSelector(settingsDirectoriesNaimixSpecialitiesProgressActionSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER],
        currentUserRestrictions,
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    } = useSpecialitiesFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useSpecialitiesFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        contentConfirm,
        editData,
        isOpenEdit,
        isOpenConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    } = useSpecialitiesAction();

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit(item);
                        },
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenDelete(item),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        className="align-items-stretch"
                        primaryHeader={item.value}
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        actionsClassName="col-1 col-xxl-2 align-items-end justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            className={block()}
            controls={
                isAccessEdit &&
                <NmButton
                    size="xl"
                    onClick={() => {
                        onOpenEdit();
                    }}
                    icon={<AddIcon />}
                >
                    Добавить вид деятельности
                </NmButton>
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            typeFilter="vertical"
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressPage}
        >
            {
                isOpenEdit &&
                <SettingsSpecialitiesEdit
                    fetchList={fetchList}
                    onClose={onCloseEdit}
                    editData={editData}
                />
            }
            {
                isOpenConfirm &&
                <NmConfirmV2
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progressAction}
                    onCancel={onCancelConfirm}
                    onConfirm={onConfirm}
                    content={contentConfirm}
                    confirmButton="Да"
                    cancelButton="Нет"
                    isNeedClosing={false}
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default SettingsDirectoriesSpecialities;