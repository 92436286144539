import React, {useState} from "react";
import {isEmpty} from "lodash";

import NmPagination from "../../../../components/ActualComponents/NmPagination";
import NmIcon from "../../../../components/NmIcon";
import {Divider} from "semantic-ui-react";

import {convertUtcToLocal} from "../../../../utils/dateFormat";
import dateFormat from "../../../../utils/dateFormat";

import {REFERRAL_ACTION, REFERRAL_ACTION_TEXT} from "../../../../constants/refProgram";

import "./style.sass";

const RefHistoryBlock = (props) => {
    const {
        list,
        totalPages,
        pageNum,
        handlePaginationChange,
    } = props;

    const [isShowAll, setShowAll] = useState(false);

    const renderCollapseHistoryLink = () => {
        return (list.length > 1 &&
            <div
                className="promocode-card-block__collapse-history"
                onClick={() => {
                    setShowAll(!isShowAll);
                }}
            >
                {isShowAll ? "Свернуть" : "Показать ещё..."}
            </div>
        );
    };

    const renderUpdateReferralHistoryItem = ({promocode, referralRate, requisites}) => {
        return (
            <>
                <div>
                    {`Промо-код: ${promocode || ""}`}
                </div>
                <div>
                    {`Размер вознаграждения Партнёра, %: ${referralRate}`}
                </div>
                <div className="ref-history-block__requisites">
                    {`Реквизиты договора: ${requisites || ""}`}
                </div>
            </>
        );
    };

    const renderActionHistoryItem = (item) => {
        const {
            actionType,
        } = item;

        switch (actionType) {
            case REFERRAL_ACTION.ADD_TO_ARCHIVE:
                return (<div>
                    {REFERRAL_ACTION_TEXT[REFERRAL_ACTION.ADD_TO_ARCHIVE]}
                </div>);
            case REFERRAL_ACTION.RETURN_FROM_ARCHIVE:
                return (<div>
                    {REFERRAL_ACTION_TEXT[REFERRAL_ACTION.RETURN_FROM_ARCHIVE]}
                </div>);
            case REFERRAL_ACTION.UPDATE:
                return renderUpdateReferralHistoryItem(item);
            default:
                return null;

        }
    };

    return (!isEmpty(list) &&
        <div className="ref-history-block">
            <div className="flex">
                <div className="mr-15 mt-40">
                    <NmIcon
                        name="history-icon"
                    />
                </div>
                <div className="ml-15">
                    {list.map((item, index) => {
                        const {
                            clientUserFirstName,
                            clientUserLastName,
                            datetime,
                        } = item;

                        return ((index < 1 || isShowAll) &&
                            <div
                                key={index}
                                className="promocode-card-block__history-item"
                            >
                                <Divider className="divider-fluid" />
                                <div>
                                    {`${dateFormat(convertUtcToLocal(datetime))} ${clientUserLastName} ${clientUserFirstName}`}
                                </div>
                                {renderActionHistoryItem(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
            {renderCollapseHistoryLink()}
            {
                isShowAll &&
                <NmPagination
                    className="ref-history-block__pagination"
                    pageNum={pageNum}
                    totalPages={totalPages}
                    onChangePagination={handlePaginationChange}
                />
            }
        </div>
    );
};

export default RefHistoryBlock;