import React, {Component} from "react";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../components/CheckboxList";
import NmTitle from "../../../components/NmTitle";

import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls} from "../../../utils/localstorage";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";

import {ORDER_HISTORY_FIELD_NAME} from "../../../constants/order";
import {EVENT_TYPE} from "../../../constants/status";

import {contractorStatusSelector} from "../../../ducks/order";

import {orderEventType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class OrderHistoryPopup extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        orderInfo: PropTypes.object,
        events: PropTypes.arrayOf(orderEventType),
    };

    static defaultProps = {
        handleClose: () => {
        },
        orderInfo: {},
        events: [],
    };

    constructor(props) {
        super(props);
        const {clientId} = props;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            form: {},
            isSearch: false,
        };

        this.pageSizes = [25, 50, 100];
        this.clientId = clientId || ls("currentClientId");
    }

    componentDidMount() {
        this.fetchHistory();
    }

    componentWillUnmount() {
        const {onClearStore} = this.props;

        onClearStore();
    }

    fetchHistory = () => {
        const {
            onFetchList,
            orderInfo: {orderId},
        } = this.props;
        const {
            pageNum,
            pageSize,
            form,
        } = this.state;
        const {
            contractorFioFilter,
            contractorPhoneFilter,
            logEndDateFilter,
            logStartDateFilter,
        } = form;

        onFetchList({
            clientId: this.clientId,
            orderId,
            pageNum,
            pageSize,
            contractorFioFilter,
            contractorPhoneFilter: removePhoneMask(contractorPhoneFilter),
            logEndDateFilter: logEndDateFilter === undefined ? undefined : formatDate(logEndDateFilter, "yyyy-MM-dd"),
            logStartDateFilter: logStartDateFilter === undefined ? undefined : formatDate(logStartDateFilter, "yyyy-MM-dd"),
        });
    };

    submitFilter = () => {
        this.setState({pageNum: 1}, this.fetchHistory);
    };

    getCards = () => {
        const {events, contractorStatusDict} = this.props;
        const _events = events.filter(({eventType}) => !["ClientEvaluationRequired", "ClientEvaluationDone"].includes(eventType));

        return _events.map(item => {
            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={`${EVENT_TYPE[item.eventType]} ${contractorStatusDict[item.status] ? `- ${contractorStatusDict[item.status]}` : ""}`}
                        labels={[
                            {
                                label: "Дата",
                                text: formatDate(convertUtcToLocal(item.dateTime), "dd.MM.yyyy HH:mm:ss"),
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            {label: "Самозанятый", text: item.contractorFio, noWrap: false, columnOnMobile: true},
                            {
                                label: "Телефон",
                                text: phoneFormat(item.contractorPhone),
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            {
                                label: "Инициатор",
                                text: item.foremanFio ? item.foremanFio : item.contractorFio,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    renderEmpty = () => {
        const {isSearch} = this.state;

        if (!isSearch) {
            return <div>
                Нет записей в истории по заказу
            </div>;
        }

        return <NmEmptyPageV2 isSearch={isSearch} />;
    };

    handleChange = (event, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            isSearch: true,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };

    handleOnChangePageSize = pageSize => {
        this.setState(prevState => ({
            ...prevState,
            pageSize,
        }), this.fetchHistory);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageNum,
        }), this.fetchHistory);
    };

    clearForm = () => {
        this.setState({
            form: {},
            isSearch: false,
        }, this.fetchHistory);
    };

    renderFilter = () => {
        const {form} = this.state;

        return (
            <NmForm
                horizontal
                className="order-history__form"
            >
                <NmDateRangePickerV2
                    size="lg"
                    className="order-history__date"
                    startFieldName={ORDER_HISTORY_FIELD_NAME.LOG_START_DATE_FILTER}
                    endFieldName={ORDER_HISTORY_FIELD_NAME.LOG_END_DATE_FILTER}
                    value={{
                        [ORDER_HISTORY_FIELD_NAME.LOG_START_DATE_FILTER]: form[ORDER_HISTORY_FIELD_NAME.LOG_START_DATE_FILTER],
                        [ORDER_HISTORY_FIELD_NAME.LOG_END_DATE_FILTER]: form[ORDER_HISTORY_FIELD_NAME.LOG_END_DATE_FILTER],
                    }}
                    isClearable={true}
                    onChange={this.handleChange}
                    suffix="EditReport"
                />
                <NmInputV2
                    size="lg"
                    className="order-history__input"
                    name={ORDER_HISTORY_FIELD_NAME.CONTRACTOR_FIO_FILTER}
                    onChange={this.handleChange}
                    value={form[ORDER_HISTORY_FIELD_NAME.CONTRACTOR_FIO_FILTER] || ""}
                    placeholder="ФИО"
                />
                <NmInputV2
                    size="lg"
                    mask="+7 (999) 999 99 99"
                    className="order-history__input"
                    name={ORDER_HISTORY_FIELD_NAME.CONTRACTOR_PHONE_FILTER}
                    value={form[ORDER_HISTORY_FIELD_NAME.CONTRACTOR_PHONE_FILTER] || ""}
                    placeholder="Телефон"
                    onChange={this.handleChange}
                />
                <FilterButtonsV2
                    onSearch={this.submitFilter}
                    onClear={this.clearForm}
                />
            </NmForm>
        );
    };

    renderPagination = () => {
        const {totalPages, totalCount} = this.props;
        const {pageSize, pageNum} = this.state;

        return (
            <NmPagination
                className="order-history__pagination"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={this.handleOnChangePageSize}
                onChangePagination={this.handlePaginationChange}
            />
        );
    };

    render() {
        const {
            handleClose,
            events,
            orderInfo: {
                orderNum,
                name,
            },
        } = this.props;

        return (
            <NmModal
                className="order-history"
                onClose={handleClose}
                classNameContent="flex-column"
                header={
                    <NmTitle size="lg">
                        История по заказу #
                        {orderNum}
                        {" "}
                        -
                        {" "}
                        {name}
                    </NmTitle>
                }
            >
                <div className="order-history__content">
                    {this.renderFilter()}
                    {events.length !== 0 ?
                        <CheckboxList rows={this.getCards()} /> :
                        this.renderEmpty()
                    }
                    {this.renderPagination()}
                </div>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        contractorStatusDict: contractorStatusSelector(state),
    }), {})(OrderHistoryPopup);