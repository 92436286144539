import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

import {KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE} from "../../../../../../constants/kedo/document-route";

const kedoDocumentRouteStepTemplatesItemValidationSchema = () => {
    return yup.object().shape({
        matchParticipantType: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        kedoStaffId: yup.string()
            .nullable()
            .when("matchParticipantType", {
                is: KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.FIXED_USER,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        kedoDepartmentId: yup.string()
            .nullable()
            .when("matchParticipantType", {
                is: KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.DEPARTMENT_USER,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        departmentRole: yup.string()
            .nullable()
            .when("matchParticipantType", {
                is: KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.DEPARTMENT_USER,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        actionType: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        signType: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};

export const kedoDocumentRouteValidationSchema = () => {
    return yup.object().shape({
        clientId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        name: yup.string()
            .nullable()
            .when("isClientPage", {
                is: false,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        nameForClient: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        stepTemplates: yup.array()
            .nullable()
            .when("flexRouteType", {
                is: false,
                then: yup.array()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(20, "Не более 20 участников")
                    .of(kedoDocumentRouteStepTemplatesItemValidationSchema()),
            }),
        files: yup.array()
            .nullable()
            .when("flexRouteType", {
                is: true,
                then: yup.array()
                    .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};