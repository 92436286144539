import React from "react";

import bem from "../../../utils/bem";
import {getCount} from "../../../utils/mathHelper";

import "./style.sass";

export interface ICounter {
    count: number,
    background?: string,
    className?: string,
    avatarCounter?: boolean
}

export const Counter = (props: ICounter) => {
    const {
        count,
        background,
        avatarCounter,
        className,
    } = props;

    const num = String(count);

    const [block, element] = bem("counter", className);

    return (
        <div
            style={{background}}
            className={block({
                avatarCounter,
                big: num.length > 2,
            })}
        >
            {getCount(count)}
        </div>
    );
};