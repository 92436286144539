import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {
    getHoursFromMinutes,
    getMinutes,
} from "../../../../utils/dateFormat";
import {transformYupFormattedAmountToNumber} from "../../../../utils/yupTransforms";

import {updateOrderContractorWorkLogCommonInfo} from "../../../../ducks/bff/orders/contractor-work-log/actionCreators";

export const TimeSheetPayTimeEditModal = (props) => {
    const {
        onClose,
        isEdit,
        data,
        fetchInfo,
    } = props;

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        const {payTimeMinutes} = data;

        if (!payTimeMinutes) {
            return {
                timeHours: "",
                timeMinutes: "",
            };
        }

        return {
            ...getHoursFromMinutes(payTimeMinutes),
        };
    }, [data]);

    const {
        handleSubmit,
        values,
        handleChange,
        errors,
        touched,
        isValid,
    } = useFormik({
        initialValues,
        onSubmit: () => {
            if (!isValid) {
                return;
            }

            dispatch(updateOrderContractorWorkLogCommonInfo({
                clientId: data.clientId,
                orderId: data.orderId,
                contractorId: data.contractorId,
                payTimeMinutes: getMinutes(Number(values.timeHours), Number(values.timeMinutes)),
                onSuccess: () => {
                    if (fetchInfo) {
                        fetchInfo();
                    }

                    onClose();
                },
            }));
        },
        validationSchema: () => {
            return yup.object().shape({
                timeHours: yup.number()
                    .nullable()
                    .transform(transformYupFormattedAmountToNumber)
                    .max(9999999, "Максимальное допустимое значение равно 9999999")
                    .when("timeMinutes", {
                        is: value => !value,
                        then: yup.number()
                            .nullable()
                            .transform(transformYupFormattedAmountToNumber)
                            .required("Необходимо указать время"),
                    }),
                timeMinutes: yup.number()
                    .nullable()
                    .transform(transformYupFormattedAmountToNumber)
                    .max(59, "Нельзя ввести больше 59 минут"),
            });
        },
        validateOnBlur: false,
        validateOnMount: false,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        isEdit
                            ? "Редактирование времени для оплаты"
                            : "Добавление времени для оплаты"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    loading={false}
                    disabled={false}
                    submitBtnContent={
                        isEdit
                            ? "Сохранить"
                            : "Добавить"
                    }
                />
            }
            onClose={onClose}
        >
            <NmForm>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Часы"
                            placeholder="Введите часы"
                            name="timeHours"
                            mask="9999999"
                            maskChar={null}
                            value={values.timeHours}
                            onChange={handleChange}
                            error={touched.timeHours && errors.timeHours}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Минуты"
                            placeholder="Введите минуты"
                            name="timeMinutes"
                            mask="99"
                            maskChar={null}
                            value={values.timeMinutes}
                            onChange={handleChange}
                            error={touched.timeMinutes && errors.timeMinutes}
                        />
                    </div>
                </div>
            </NmForm>
        </NmModal>
    );
};