import {useState} from "react";
import {useDispatch} from "react-redux";

import {setSettingsDirectoriesBank} from "../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";

export const useSettingsDirectoriesBanksAction = ({fetchSettings}) => {
    const dispatch = useDispatch();

    const [isOpenLogModal, setOpenLogModal] = useState(false);

    const setSettings = (value) => {
        dispatch(setSettingsDirectoriesBank({
            key: "contractorWalletWithdrawAvailable",
            value,
            onSuccess: fetchSettings,
        }));
    };

    return {
        isOpenLogModal,
        setOpenLogModal,
        setSettings,
    };
};