import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getKedoDocumentRoutePage,
    updateCommonPaymentDicts,
} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoDocumentRouteForClientOptionsSelector,
    bffCommonDictsKedoDocumentRouteOptionsSelector,
} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoDocumentRoute = (props) => {
    const {
        clientId,
        typesFilter,
    } = props;

    const dispatch = useDispatch();

    const adminOptions = useSelector(bffCommonDictsKedoDocumentRouteOptionsSelector);
    const clientOptions = useSelector(bffCommonDictsKedoDocumentRouteForClientOptionsSelector);

    const options = useMemo(() => {
        if (clientId) {
            return clientOptions;
        }

        return adminOptions;
    }, [
        clientId,
        adminOptions,
        clientOptions,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                kedoDocumentRouteList: [],
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [typesFilter]);

    const fetchList = (nameFilter) => {
        dispatch(getKedoDocumentRoutePage({
            clientId,
            types: typesFilter,
            name: handleFormString(nameFilter),
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
        }));
    };

    const onSearchChange = (nameFilter) => {
        fetchList(nameFilter);
    };

    return {
        options,
        search: true,
        isClearSearchOnBlur: true,
        label: "Маршрут подписания",
        placeholder: "Выберите маршрут",
        name: "routeId",
        onSearchChange,
        isMountLoad: !typesFilter,
    };
};