import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";

const controller = "/adm/finances/registry-payments/log";

//POST /bff/adm/finances/registry-payments/log/page
// Получить страницу логов реестровых оплат
const getPageRegistryPaymentLog = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST, getPageRegistryPaymentLog),
    ]);
}