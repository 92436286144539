import {getPageCrowdPayment} from "../../crowd/payment/actionCreators";

import {getBffControllerClientCardPage} from "../../../../utils/url";

export const getPageCrowdPaymentClient = (payload) => {
    const controller = getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/finances/npd-payments/tasks",
        client: "/client-adm/finances/npd-payments/tasks",
    });

    return getPageCrowdPayment({
        url: `${controller}/page`,
        ...payload,
    });
};