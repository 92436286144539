import {
    CLEAR_FIELDS_DOCUMENTS_CORRECTION,
    GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST,
    GET_PAGE_DOCUMENTS_CORRECTION_REQUEST,
    PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST,
    PERFORM_DOCUMENTS_CORRECTION_REQUEST,
} from "./actions";

export function getDocumentsCorrectionPage(payload) {
    return {
        type: GET_PAGE_DOCUMENTS_CORRECTION_REQUEST,
        payload,
    };
}

export function preformDocumentsCorrection(payload) {
    return {
        type: PERFORM_DOCUMENTS_CORRECTION_REQUEST,
        payload,
    };
}

export function performDemoDocumentsCorrection(payload) {
    return {
        type: PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST,
        payload,
    };
}

export function getFieldNamesDictDocumentsCorrection(payload) {
    return {
        type: GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST,
        payload,
    };
}

export function clearFieldsDocumentsCorrection() {
    return {
        type: CLEAR_FIELDS_DOCUMENTS_CORRECTION,
    };
}