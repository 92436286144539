import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getFiltersData} from "../utils/getFiltersData";

import {KEDO_DOCUMENT_STATES_FILTER_OPTIONS} from "../constants";

import {getKedoAllDocumentTypes, updateCommonPaymentDicts} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoAllDocumentTypesOptionsSelector,
    bffCommonDictsKedoDocumentFileSourceTypesOptionsSelector,
    bffCommonDictsKedoDocumentsCreatedViaTypesOptionsSelector,
    bffCommonDictsKedoRoleDictOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoDocumentsFilters = (params) => {
    const {
        pageSize,
        setPagination,
        clientId,
    } = params;

    const roleOptions = useSelector(bffCommonDictsKedoRoleDictOptionsSelector);
    const fileSourceTypeOptions = useSelector(bffCommonDictsKedoDocumentFileSourceTypesOptionsSelector);
    const documentsCreatedViaTypesOptions = useSelector(bffCommonDictsKedoDocumentsCreatedViaTypesOptionsSelector);
    const documentTypesOptions = useSelector(bffCommonDictsKedoAllDocumentTypesOptionsSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getKedoAllDocumentTypes({
            clientId,
        }));

        return () => {
            dispatch(updateCommonPaymentDicts({
                kedoAllDocumentTypes: [],
            }));
        };
    }, []);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        mapFilterDto: getFiltersData,
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название или номер документа",
                        placeholder: "Введите название или номер документа",
                        name: "documentNameOrNumFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "createdFromFilter",
                        endFieldName: "createdToFilter",
                        isClearable: true,
                        label: "Дата создания документа",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "creatorFioFilter",
                        label: "ФИО создателя",
                        placeholder: "Введите ФИО создателя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: fileSourceTypeOptions,
                        name: "fileUploadSourceTypesFilter",
                        label: "Источник загрузки",
                        placeholder: "Выберите источник загрузки",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "currentParticipantFioFilter",
                        label: "Текущий подписант",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: documentsCreatedViaTypesOptions,
                        multiple: true,
                        search: true,
                        name: "fileCreatedViaTypesFilter",
                        label: "Тип загрузки документа",
                        placeholder: "Выберите тип загрузки",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: documentTypesOptions,
                        multiple: true,
                        search: true,
                        name: "documentTypeIdsFilter",
                        label: "Тип документа",
                        placeholder: "Выберите тип документа",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: KEDO_DOCUMENT_STATES_FILTER_OPTIONS,
                        name: "documentStatesFilter",
                        label: "Статус документа",
                        placeholder: "Выберите статус",
                    },
                ],
            },
        ];
    }, [
        roleOptions,
        documentTypesOptions,
        fileSourceTypeOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};