import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/documents/edo/registries/items";

const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST";
const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS";
const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR";

const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST";
const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS";
const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR";

const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

const DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE = "DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE";

const initialState = {
    list: [],
    totalCount: 0,
    pageData: {},
    progressList: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
                progressList: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                list: payload.results,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};

export const getDocumentsEdoRegistriesItemsPage = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST,
    payload,
});

export const getByIdDocumentsEdoRegistriesItems = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST,
    payload,
});

export const addDocumentsEdoRegistriesItems = (payload) => ({
    type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesItems = (payload) => ({
    type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const deleteDocumentsEdoRegistriesItems = (payload) => ({
    type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesStoreItems = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE,
    payload,
});

export const importDocumentsEdoRegistriesItems = (payload) => ({
    type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const rejectDocumentsEdoRegistriesItems = (payload) => ({
    type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const deleteNotCompletedDocumentsEdoRegistriesItems = (payload) => ({
    type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const checkImportTaskExistsDocumentsEdoRegistriesItems = (payload) => ({
    type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateReceiversDocumentsEdoRegistriesItems = (payload) => ({
    type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const documentsEdoRegistriesItemsSelector = state => state.documentsEdoRegistriesItems;
export const documentsEdoRegistriesItemsListSelector = createSelector(documentsEdoRegistriesItemsSelector, ({list}) => list);
export const documentsEdoRegistriesItemsTotalPagesSelector = createSelector(documentsEdoRegistriesItemsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const documentsEdoRegistriesItemsTotalCountSelector = createSelector(documentsEdoRegistriesItemsSelector, ({totalCount}) => totalCount);
export const documentsEdoRegistriesItemsProgressListSelector = createSelector(documentsEdoRegistriesItemsSelector, ({progressList}) => progressList);
export const documentsEdoRegistriesItemsProgressActionSelector = createSelector(documentsEdoRegistriesItemsSelector, ({progressAction}) => progressAction);

//POST /api/documents/edo/registries/items/richPage
export const getDocumentsEdoRegistriesItemsPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/richPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR, payload: error.message});
    }
};

//GET /api/documents/edo/registries/items/{clientId}/{registryId}/rich
export const getByIdDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.get(`${controller}/${clientId}/${registryId}/rich`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/items/add
export const addDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");
        onSuccess();
        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/items/update
export const updateDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/items/delete
export const deleteDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/delete`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/job/edoc/registries/items/import
export const importDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post("/job/edoc/registries/items/import", formData, {
            params: {
                clientId,
                registryId,
            },
            ...getMultipartHeaders(),
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Загрузка файла успешно завершена. Новые исполнители станут доступны через некоторое время.");
        onSuccess();
        yield put({
            type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/items/reject
export const rejectDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/reject`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/items/deleteNotCompletedItems
export const deleteNotCompletedDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteNotCompletedItems`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//GET /api/job/edoc/registries/items/import/task/exists/{clientId}/{registryId}
export const checkImportTaskExistsDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {},
        } = payload;

        const result = yield request.get(`/job/edoc/registries/items/import/task/exists/${clientId}/${registryId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

//POST /api/job/edoc/registries/items/updateReceiversData
export const updateReceiversDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post("/job/edoc/registries/items/updateReceiversData", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Задача по обновлению параметров исполнителей успешно запущена. Данные обновятся через некоторое время.");
        onSuccess();
        yield put({
            type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST, getDocumentsEdoRegistriesItemsPageSaga),
        takeEvery(DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST, getByIdDocumentsEdoRegistriesItemsSaga),
        takeEvery(ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, addDocumentsEdoRegistriesItemsSaga),
        takeEvery(UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, updateDocumentsEdoRegistriesItemsSaga),
        takeEvery(DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, deleteDocumentsEdoRegistriesItemsSaga),
        takeEvery(IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, importDocumentsEdoRegistriesItemsSaga),
        takeEvery(REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, rejectDocumentsEdoRegistriesItemsSaga),
        takeEvery(DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, deleteNotCompletedDocumentsEdoRegistriesItemsSaga),
        takeEvery(CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, checkImportTaskExistsDocumentsEdoRegistriesItemsSaga),
        takeEvery(UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, updateReceiversDocumentsEdoRegistriesItemsSaga),
    ]);
}