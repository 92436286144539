export const GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST = "GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST";
export const GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS = "GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS";
export const GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR = "GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR";

export const GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST = "GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST";
export const GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS = "GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS";
export const GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR = "GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR";

export const ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST = "ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST";
export const ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS = "ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS";
export const ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR = "ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR";

export const UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST = "UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST";
export const UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS = "UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS";
export const UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR = "UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR";

export const DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST = "DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST";
export const DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS = "DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS";
export const DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR = "DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR";

export const UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION = "UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION";

export const CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION = "CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION";