import {
    ADD_SPECIALTY_ERROR,
    ADD_SPECIALTY_REQUEST,
    ADD_SPECIALTY_SUCCESS,
    CLEAR_STORE_SPECIALITIES,
    DELETE_SPECIALTY_ERROR,
    DELETE_SPECIALTY_REQUEST,
    DELETE_SPECIALTY_SUCCESS,
    GET_PAGE_SPECIALITIES_ERROR,
    GET_PAGE_SPECIALITIES_REQUEST,
    GET_PAGE_SPECIALITIES_SUCCESS,
    UPDATE_SPECIALTY_ERROR,
    UPDATE_SPECIALTY_REQUEST,
    UPDATE_SPECIALTY_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    progress: false,
    totalCount: 0,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_SPECIALITIES_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_SPECIALITIES_SUCCESS:
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: results,
            };
        case GET_PAGE_SPECIALITIES_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ADD_SPECIALTY_REQUEST:
        case UPDATE_SPECIALTY_REQUEST:
        case DELETE_SPECIALTY_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_SPECIALTY_SUCCESS:
        case ADD_SPECIALTY_ERROR:
        case UPDATE_SPECIALTY_SUCCESS:
        case UPDATE_SPECIALTY_ERROR:
        case DELETE_SPECIALTY_SUCCESS:
        case DELETE_SPECIALTY_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CLEAR_STORE_SPECIALITIES:
            return initial;
        default:
            return state;
    }
};