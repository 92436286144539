import React, {FC, ReactNode} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

export interface ILabelText {
    label?: string,
    text?: string | ReactNode,
    className?: string,
    color?: "blue" | "primary" | "red",
    wrapped?: boolean,
    noWrap?: boolean,
    alignItems?: "baseline" | "center" | "flex-start" | "flex-end",
    columnOnMobile?: boolean,
    columnOnTablet?: boolean,
    flexWrap?: boolean,
    fluidText?: boolean,
    type?: "page" | "list",
    noWrapLabel?: boolean,
    tooltip?: ReactNode,
    textTooltip?: ReactNode,
    inline?: boolean,
    boldText?: boolean,
    boldLabel?: boolean,
    classNameLabel?: string,
    classNameText?: string,
    textTitle?: string,
    onClickText?: () => {},
    noWrapLabelOnMobile?: boolean,
    textMaxWidth?: boolean,
    labelCursorPointer?: string,
    labelColor?: string,
    textColor?: string,
    cursorPointer?: boolean
    textOverflowUnset?: boolean
}

const NmLabelText: FC<ILabelText> = (props) => {
    const {
        label,
        text,
        color,
        alignItems = "baseline",
        noWrap = true,
        className,
        type = "list",
        columnOnMobile,
        columnOnTablet = false,
        flexWrap = false,
        fluidText,
        noWrapLabel = true,
        noWrapLabelOnMobile,
        inline,
        textTooltip,
        tooltip,
        wrapped,
        boldText,
        boldLabel,
        textTitle,
        classNameLabel = "",
        classNameText = "",
        cursorPointer = false,
        textMaxWidth,
        textOverflowUnset,
        onClickText = () => {
        },
        labelCursorPointer,
        labelColor,
        textColor,
    } = props;

    const [block, element] = bem("nm-label-text", className);

    return (
        <div className={block({alignItems, columnOnMobile, columnOnTablet, flexWrap, type, inline})}>
            {
                label &&
                <div
                    style={{color: labelColor}}
                    className={`${element("label", {
                        noWrap: noWrapLabel,
                        noWrapOnMobile: noWrapLabelOnMobile,
                        cursorPointer: labelCursorPointer,
                        bold: boldLabel,
                        type,
                        inline,
                    })} ${classNameLabel}`}
                >
                    {label}
                    :
                    {
                        tooltip &&
                        <div className={element("tooltip")}>
                            {tooltip}
                        </div>
                    }
                </div>
            }
            <div
                style={{color: textColor}}
                onClick={onClickText}
                title={textTitle}
                className={`${element("text", {
                    cursorPointer,
                    color,
                    wrapped,
                    noWrap,
                    inline,
                    type,
                    fluid: fluidText,
                    bold: boldText,
                    maxWidth: textMaxWidth,
                    overflowUnset: textOverflowUnset,
                },
                )} ${classNameText}`}
            >
                {text}
                {
                    textTooltip &&
                    <div className={element("text-tooltip")}>
                        {textTooltip}
                    </div>
                }
            </div>
        </div>
    );
};

export default NmLabelText;