import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import {ReactComponent as ErrorIcon} from "../../../../images/fault-bg.svg";

import {useDepositValuesFetch} from "../../../../components/DepositValuesAmountInfo/hooks/useDepositValuesFetch";

import {getDepositValuesCards} from "../../../../components/DepositValuesAmountInfo/utils/getDepositValuesCards";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

const FinancePaymentListAmountValues = ({isClientPayments, isCivil, selectedSum, clientId, isShow}) => {
    const {depositDistributedByObjects} = useSelector(clientCardPropertiesSelector);

    const {
        depositValues,
        isLoading,
    } = useDepositValuesFetch({
        isCivil,
        clientId,
        isFetchValues: isClientPayments,
    });
    const {availableForPaymentsAmount = 0} = depositValues;

    const {t} = useTranslation();

    const getCards = () => {
        if (!isClientPayments) {
            return [
                {
                    title: t("finance-payment-list.amount-selected-payments"),
                    value: `${formatAmountWithNullChecking(selectedSum(false))} ₽`,
                    className: "col-16 col-xxl-2",
                },
            ];
        }

        const {
            deposit,
            awaitingConfirmationPayments,
            inProgressPayments,
            availableForPayments,
        } = getDepositValuesCards({
            isCivil,
            depositValues,
            depositDistributedByObjects,
            isLoading,
        });

        return [
            {
                ...deposit,
                className: "col-16 col-xxl-2",
            },
            {
                ...awaitingConfirmationPayments,
                className: "col-16 col-xxl-2",
            },
            {
                ...inProgressPayments,
                className: "col-16 col-xxl-2",
            },
            {
                ...availableForPayments,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("finance-payment-list.amount-selected-payments"),
                value: `${formatAmountWithNullChecking(selectedSum())} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("finance-payment-list.free-balance-after-payment"),
                value: depositDistributedByObjects ? "-" : `${formatAmountWithNullChecking(availableForPaymentsAmount - selectedSum())} ₽`,
                isLoading,
                icon: availableForPaymentsAmount - selectedSum() < 0 ? <ErrorIcon
                    width={16}
                    height={16}
                /> : null,
                className: "col-16 col-xxl-2",
            },
        ];
    };

    if (!isShow) {
        return null;
    }

    return (
        <NmPageInfoCardsAccordion
            cards={getCards()}
            desktopViewFrom="xxl"
        />
    );
};

export default FinancePaymentListAmountValues;