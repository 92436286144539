import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

import {moveKedoDepartmentStaff} from "../../../../ducks/kedo/departments/staff/actionCreators";

export const useKedoOrganizationStructureStaffRegistryMoveForm = (params) => {
    const {
        kedoStaffIds,
    } = params;

    const dispatch = useDispatch();

    const {
        values,
        setFieldValue,
        errors,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues: {
            kedoDepartmentId: "",
        },
        validationSchema: yup.object().shape({
            kedoDepartmentId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit() {
        dispatch(moveKedoDepartmentStaff({
            kedoDepartmentId: values.kedoDepartmentId,
            kedoStaffIds,
        }));
    }

    return {
        values,
        errors,
        handleSubmit,
        onChange,
    };
};