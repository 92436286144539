import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {SettingsDirectoriesBanksBalancesEditModal} from "./components/edit-modal";
import {SettingsDirectoriesBanksBalancesLogModal} from "./components/log-modal";

import {useModal} from "../../../../../hooks/useModal";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSettingsDirectoriesBanksBalancesFetch} from "./hooks/useFetch";

import {formatLocalDate} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {formatAmount} from "../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_CHIEF_ACCOUNTANT} from "../../../../../constants/roles";

import {
    settingsDirectoriesBanksBalancesListSelector,
    settingsDirectoriesBanksBalancesProgressActionSelector,
    settingsDirectoriesBanksBalancesProgressSelector,
    settingsDirectoriesBanksBalancesTotalCountSelector,
    settingsDirectoriesBanksBalancesTotalPagesSelector,
} from "../../../../../ducks/bff/settings/directories/naimix/banks/selectors";

export const SettingsDirectoriesBanksBalances = () => {
    const list = useSelector(settingsDirectoriesBanksBalancesListSelector);
    const totalCount = useSelector(settingsDirectoriesBanksBalancesTotalCountSelector);
    const totalPages = useSelector(settingsDirectoriesBanksBalancesTotalPagesSelector);
    const progress = useSelector(settingsDirectoriesBanksBalancesProgressSelector);
    const progressAction = useSelector(settingsDirectoriesBanksBalancesProgressActionSelector);

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {
        isOpen: isOpenEditModal,
        modalData: editModalData,
        onOpenModal: onOpenEditModal,
        onCloseModal: onCloseEditModal,
    } = useModal();

    const {
        isOpen: isOpenLogModal,
        modalData: logModalData,
        onOpenModal: onOpenLogModal,
        onCloseModal: onCloseLogModal,
    } = useModal();

    const {
        fetchList,
    } = useSettingsDirectoriesBanksBalancesFetch({
        pageNum,
        pageSize,
    });

    const getMediaControls = (item) => {
        if (![ADMIN, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenLogModal(item),
                        color: "grey",
                        children: "История изменений",
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                bankBalanceId,
                bankName,
                lastUpdateDatetime,
                balance,
            } = item;

            return {
                ...item,
                key: bankBalanceId,
                contentRow: (
                    <NmListCard
                        primaryHeader={bankName}
                        noDivider
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Последние изменения",
                                text: lastUpdateDatetime ?
                                    formatLocalDate(lastUpdateDatetime, "dd.MM.yyyy HH:mm") :
                                    "-",
                            },
                            {
                                label: "Баланс, ₽",
                                text: balance ? formatAmount(balance) : "-",
                            },
                        ]}
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const renderEditForm = () => {
        return (
            isOpenEditModal &&
            <SettingsDirectoriesBanksBalancesEditModal
                {...editModalData}
                onClose={onCloseEditModal}
                fetchList={fetchList}
            />
        );
    };

    const renderLogModal = () => {
        return (
            isOpenLogModal &&
            <SettingsDirectoriesBanksBalancesLogModal
                data={logModalData}
                onClose={onCloseLogModal}
            />
        );
    };

    return (
        <NmPage
            overflowUnset={true}
            header={
                <NmPageHeader
                    text="Баланс на р/с"
                />
            }
            mediaControls={{
                renderCount: {
                    desktop: 1,
                    tablet: 1,
                    mobile: 1,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            disabled: progressAction,
                            onClick: () => onOpenEditModal({isEdit: false}),
                            children: "Добавить значение баланса",
                            color: "green",
                            icon: <AddIcon />,
                        },
                    },
                ],
            }}
            noPadding={true}
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
        >
            {renderEditForm()}
            {renderLogModal()}
            {
                !isEmpty(list) ?
                    <CheckboxList rows={rows} /> :
                    <NmEmptyPageV2 fetchProgress={progress} />
            }
        </NmPage>
    );
};