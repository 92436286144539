import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR,
    SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST, SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS,
    SETTINGS_NAIMIX_INFO_GET_FILE_ERROR,
    SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST,
    SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS,
    SETTINGS_NAIMIX_INFO_LOAD_ERROR,
    SETTINGS_NAIMIX_INFO_LOAD_REQUEST,
    SETTINGS_NAIMIX_INFO_LOAD_SUCCESS,
    SETTINGS_NAIMIX_INFO_SAVE_ERROR,
    SETTINGS_NAIMIX_INFO_SAVE_REQUEST,
    SETTINGS_NAIMIX_INFO_SAVE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/settings/naimix-info";

const loadSettingsNaimixInfo = function* () {
    try {
        const result = yield request.bff.get(`${controller}/load`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_NAIMIX_INFO_LOAD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_NAIMIX_INFO_LOAD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_NAIMIX_INFO_LOAD_ERROR,
            payload: error,
        });
    }
};

const saveSettingsNaimixInfo = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/save`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_NAIMIX_INFO_SAVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_NAIMIX_INFO_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_NAIMIX_INFO_SAVE_ERROR,
            payload: error,
        });
    }
};

const getFileSettingsNaimixInfo = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/file`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_NAIMIX_INFO_GET_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_NAIMIX_INFO_GET_FILE_SUCCESS,
            payload: {
                fieldName: payload.clientFileType,
                result,
            },
        });
    } catch (error) {
        yield put({
            type: SETTINGS_NAIMIX_INFO_GET_FILE_ERROR,
            payload: error,
        });
    }
};

const addFileSettingsNaimixInfo = function* ({payload}) {
    try {
        const {
            clientId,
            clientFileType,
            file: data,
            onSuccess,
        } = payload;

        const {errorMessage} = yield request.bff.post(`${controller}/add`, data, {
            params: {
                clientId,
                clientFileType,
            },
            ...getMultipartHeaders(),
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();

            return;
        }

        yield put({
            type: SETTINGS_NAIMIX_INFO_ADD_FILE_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_NAIMIX_INFO_ADD_FILE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_NAIMIX_INFO_LOAD_REQUEST, loadSettingsNaimixInfo),
        takeEvery(SETTINGS_NAIMIX_INFO_SAVE_REQUEST, saveSettingsNaimixInfo),
        takeEvery(SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST, getFileSettingsNaimixInfo),
        takeEvery(SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST, addFileSettingsNaimixInfo),
    ]);
}