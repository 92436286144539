import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_TASK_GROUP_ADD_ERROR,
    BFF_CROWD_TASK_GROUP_ADD_REQUEST,
    BFF_CROWD_TASK_GROUP_ADD_SUCCESS,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_EXPORT_ERROR,
    BFF_CROWD_TASK_GROUP_EXPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS,
    BFF_CROWD_TASK_GROUP_GET_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_SUCCESS,
    BFF_CROWD_TASK_GROUP_IMPORT_ERROR,
    BFF_CROWD_TASK_GROUP_IMPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CROWD_TASK_GROUP_REMOVE_ERROR,
    BFF_CROWD_TASK_GROUP_REMOVE_REQUEST,
    BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/groups/group",
        client: "/client-adm/crowd-tasks/groups/group",
    });
};


const getCrowdTaskGroup = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_GET_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_ERROR,
        });
    }
};

const exportCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/export`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_EXPORT_ERROR,
        });
    }
};

const getCrowdTaskGroupInGroupPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/tasks/page/in-group`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR,
        });
    }
};

const getCrowdTaskGroupOutGroupPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/tasks/page/out-of-group`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR,
        });
    }
};

const importCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            taskGroupId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/import/${clientId}/${taskGroupId}`, formData, {
            params: {
                clientId,
                taskGroupId,
            },
            ...getMultipartHeaders(),
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_CROWD_TASK_GROUP_IMPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: BFF_CROWD_TASK_GROUP_IMPORT_ERROR, payload: error.message});
    }
};

const deleteMultipleCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/delete/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR,
        });
    }
};

const publishMultipleCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/publish/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR,
        });
    }
};

const archiveMultipleCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/archive/add/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR,
        });
    }
};

const closeMultipleCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/close/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR,
        });
    }
};

const addCrowdTasksToGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_ADD_ERROR,
        });
    }
};

const removeCrowdTaskFromGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/tasks/remove`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUP_REMOVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUP_REMOVE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASK_GROUP_GET_REQUEST, getCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_EXPORT_REQUEST, exportCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST, getCrowdTaskGroupInGroupPage),
        takeEvery(BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST, getCrowdTaskGroupOutGroupPage),
        takeEvery(BFF_CROWD_TASK_GROUP_IMPORT_REQUEST, importCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST, deleteMultipleCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST, publishMultipleCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST, archiveMultipleCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST, closeMultipleCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_ADD_REQUEST, addCrowdTasksToGroup),
        takeEvery(BFF_CROWD_TASK_GROUP_REMOVE_REQUEST, removeCrowdTaskFromGroup),
    ]);
}