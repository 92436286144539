import {
    REVIEWS_CONTRACTOR_ADD_ERROR,
    REVIEWS_CONTRACTOR_ADD_REQUEST,
    REVIEWS_CONTRACTOR_ADD_SUCCESS,
    REVIEWS_CONTRACTOR_CLEAR_STORE,
    REVIEWS_CONTRACTOR_CONFIRM_ERROR,
    REVIEWS_CONTRACTOR_CONFIRM_REQUEST,
    REVIEWS_CONTRACTOR_CONFIRM_SUCCESS,
    REVIEWS_CONTRACTOR_DECLINE_ERROR,
    REVIEWS_CONTRACTOR_DECLINE_REQUEST,
    REVIEWS_CONTRACTOR_DECLINE_SUCCESS,
    REVIEWS_CONTRACTOR_DELETE_ERROR,
    REVIEWS_CONTRACTOR_DELETE_REQUEST,
    REVIEWS_CONTRACTOR_DELETE_SUCCESS,
    REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS,
    REVIEWS_CONTRACTOR_GET_PAGE_ERROR,
    REVIEWS_CONTRACTOR_GET_PAGE_REQUEST,
    REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS,
    REVIEWS_CONTRACTOR_UPDATE_ERROR,
    REVIEWS_CONTRACTOR_UPDATE_REQUEST,
    REVIEWS_CONTRACTOR_UPDATE_SUCCESS,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case REVIEWS_CONTRACTOR_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case REVIEWS_CONTRACTOR_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                progress: false,
                totalCount: payload.totalCount,
            };
        case REVIEWS_CONTRACTOR_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case REVIEWS_CONTRACTOR_GET_COUNT_SUCCESS:
            return {
                ...state,
                totalCount: payload,
            };
        case REVIEWS_CONTRACTOR_UPDATE_REQUEST:
        case REVIEWS_CONTRACTOR_DELETE_REQUEST:
        case REVIEWS_CONTRACTOR_ADD_REQUEST:
        case REVIEWS_CONTRACTOR_DECLINE_REQUEST:
        case REVIEWS_CONTRACTOR_CONFIRM_REQUEST:
        case REVIEWS_CONTRACTOR_REPLY_UPDATE_REQUEST:
        case REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case REVIEWS_CONTRACTOR_UPDATE_SUCCESS:
        case REVIEWS_CONTRACTOR_UPDATE_ERROR:
        case REVIEWS_CONTRACTOR_DELETE_SUCCESS:
        case REVIEWS_CONTRACTOR_DELETE_ERROR:
        case REVIEWS_CONTRACTOR_ADD_SUCCESS:
        case REVIEWS_CONTRACTOR_ADD_ERROR:
        case REVIEWS_CONTRACTOR_DECLINE_SUCCESS:
        case REVIEWS_CONTRACTOR_DECLINE_ERROR:
        case REVIEWS_CONTRACTOR_CONFIRM_SUCCESS:
        case REVIEWS_CONTRACTOR_CONFIRM_ERROR:
        case REVIEWS_CONTRACTOR_REPLY_UPDATE_SUCCESS:
        case REVIEWS_CONTRACTOR_REPLY_UPDATE_ERROR:
        case REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_SUCCESS:
        case REVIEWS_CONTRACTOR_REPLY_STATUS_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case REVIEWS_CONTRACTOR_CLEAR_STORE: {
            return initialState;
        }
        default: return state;
    }
};