import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import {ReactComponent as DeleteIcon} from "../../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../../images/mode.svg";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_INFO} from "../../../../../constants/links";

import {deleteClientCategories} from "../../../../../ducks/bff/settings/directories/naimix/client-categories/actionCreators";
import {
    getOrderCategoriesActionSuccessSelector,
    updateFieldOrderCategory,
} from "../../../../../ducks/orderCategories";

export const useSettingsDirectoriesOrderCategoryListAction = (params) => {
    const {
        isAccessEdit,
        fetchList,
    } = params;
    const dispatch = useDispatch();

    const isActionSuccess = useSelector(getOrderCategoriesActionSuccessSelector);
    const [editData, setEditData] = useState(null);
    const [confirmData, setConfirmData] = useState(null);

    const {t} = useTranslation();

    useEffect(() => {
        if (isActionSuccess) {
            dispatch(updateFieldOrderCategory({
                isActionSuccess: false,
            }));
            onCloseConfirm();
            onCloseEditModal();
        }
    }, [isActionSuccess]);

    const onOpenConfirm = (editItem) => {
        setConfirmData({...editItem});
    };

    const onCloseConfirm = () => {
        setConfirmData(null);
    };

    const onOpenEditModal = (editItem) => {
        setEditData({...editItem});
    };

    const onCloseEditModal = () => {
        setEditData(null);
    };

    const deleteCategory = () => {
        const {id} = confirmData;

        dispatch(deleteClientCategories({
            orderCategoryUuid: id,
            onSuccess: fetchList,
            onError: (listCompany) => {
                if (isEmpty(listCompany)) {
                    return;
                }

                onOpenConfirm({
                    listCompany,
                });
            },
        }));
    };

    const getDataForConfirmWindow = () => {
        if (confirmData.listCompany && confirmData.listCompany.length > 0) {
            return {
                content: (
                    <>
                        {t("order-categories.not-delete")}
                        {confirmData.listCompany && confirmData.listCompany.map((company, index) => {
                            const link = LINK_CLIENT_INFO.replace(":clientId", company.clientId);

                            return (
                                <Link
                                    key={company.clientId}
                                    to={link}
                                >
                                    {` ${company.name}${index !== confirmData.listCompany.length - 1 ? "," : "."}`}
                                </Link>
                            );
                        })}
                    </>
                ),
                onConfirm: onCloseConfirm,
                onCancel: onCloseConfirm,
                confirmButton: t("button.ok"),
            };
        }

        return {
            content: t("order-categories.confirm-delete"),
            onConfirm: deleteCategory,
            onCancel: onCloseConfirm,
            confirmButton: t("button.yes"),
            cancelButton: t("button.no"),
        };
    };

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEditModal(item);
                        },
                        title: t("card-app.edit-title"),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({id: item.id});
                        },
                        color: "grey",
                        title: t("button.delete"),
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    return {
        onOpenEditModal,
        onCloseEditModal,
        getMediaControls,
        editData,
        confirmData,
        getDataForConfirmWindow,
    };
};