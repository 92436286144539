import {
    GET_TASK_STATS_REQUEST,
    UPDATE_SETTINGS_SERVICE_TASK_STATS_STORE,
} from "./actions";

export const getSettingsServiceTaskStats = (payload) => {
    return {
        type: GET_TASK_STATS_REQUEST,
        payload,
    };
};

export const updateStoreSettingsServiceTaskStats = (payload) => {
    return {
        type: UPDATE_SETTINGS_SERVICE_TASK_STATS_STORE,
        payload,
    };
};