export const BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST = "BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST";
export const BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS = "BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS";
export const BFF_PROMOTION_CHANNELS_CARD_GET_ERROR = "BFF_PROMOTION_CHANNELS_CARD_GET_ERROR";

export const BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST = "BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST";
export const BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS = "BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS";
export const BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR = "BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR";

export const BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST = "BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST";
export const BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS = "BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS";
export const BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR = "BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR";

export const UPDATE_PROMOTION_CHANNELS_CARD_STORE = "UPDATE_PROMOTION_CHANNELS_CARD_STORE";