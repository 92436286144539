import {
    BFF_PROMOTION_CHANNELS_CARD_GET_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS,
    UPDATE_PROMOTION_CHANNELS_CARD_STORE,
} from "./actions";

const initial = {
    card: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progressCard: false,
    progressList: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS:
            return {
                ...state,
                progressList: false,
                list: payload.contractorPromotionPromoCodes,
                totalCount: payload.totalCount,
            };
        case BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
                error: payload,
            };
        case BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case BFF_PROMOTION_CHANNELS_CARD_GET_ERROR:
            return {
                ...state,
                progressCard: false,
                error: payload,
            };
        case BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS:
        case BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_PROMOTION_CHANNELS_CARD_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};