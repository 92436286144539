import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {RecruitmentCandidateInformationCard} from "../../../../components/Recruitment/InformationCard";
import {
    deleteRecruitmentVacancyCandidatesFile,
    editRecruitmentVacancyCandidate,
    uploadRecruitmentVacancyCandidatesFile,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesCardSelector,
    bffRecruitmentVacancyCandidatesProgressActionSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";

import {useFetchCandidateCard} from "../../vacancies/hooks/useFetchCard";

export const RecruitmentVacancyCandidateCard = (props) => {
    const {
        match: {
            params: {
                vacancyId,
                id: candidateId,
                clientId,
            },
        },
    } = props;

    const card = useSelector(bffRecruitmentVacancyCandidatesCardSelector);
    const progressAction = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);

    const dispatch = useDispatch();

    const {
        fetchCard,
    } = useFetchCandidateCard({
        vacancyId,
        candidateId,
    });

    const updateInformation = (updateData) => {
        const {
            onSuccess,
            ...data
        } = updateData;

        dispatch(editRecruitmentVacancyCandidate({
            id: candidateId,
            vacancyId,
            clientId,
            ...card,
            ...data,
            onSuccess,
        }));
    };

    const uploadFile = (data) => {
        dispatch(uploadRecruitmentVacancyCandidatesFile({
            candidateId: card.candidateId,
            ...data,
        }));
    };

    const deleteFile = (data) => {
        dispatch(deleteRecruitmentVacancyCandidatesFile({
            candidateId: card.candidateId,
            ...data,
        }));
    };

    return (
        <RecruitmentCandidateInformationCard
            card={card}
            isVisibleAddButton={true}
            progressAction={progressAction}
            fetchInformation={fetchCard}
            updateInformation={updateInformation}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            header="Информация о кандидате"
            isVisibleResumes={true}
            {...props}
        />
    );
};