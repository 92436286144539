import React from "react";

import {ReactComponent as ReportProblemIcon} from "../../images/report_problem_24.svg";
import Text from "../ActualComponents/Text";

import bem from "../../utils/bem";

import "./style.sass";

const ForbiddenPage = () => {
    const [block, element] = bem("forbidden-page");

    return (
        <div className={block()}>
            <div className={element("content")}>
                <div className="flex align-items-center mb-4">
                    <ReportProblemIcon className="me-2" />
                    <Text.Title level="1">
                        Ошибка
                    </Text.Title>
                </div>
                <div>
                    <Text level="5">
                        К сожалению, данная страница недоступна. Перейдите в другой раздел в навигационном меню
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default ForbiddenPage;