import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import AmountInput from "../../../../components/AmountInput";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {clearSpace, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

import {CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_FILTER_DICT} from "../../../../constants/contractor";
import {SimpleDropdownOptions} from "../../../../constants/contractorList";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS,
    REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS,
} from "../../../../constants/registry";

import {
    registryFilterByTicketSelector,
    updateTicketStore,
} from "../../../../ducks/crm/ticket";
import {
    registryPaymentStatusesAllForFiltersOptionsSelector,
} from "../../../../ducks/registryPaymentStatuses";

import "./style.sass";

const isNum = value => /^[0-9]+$/.test(value);

const RegistryCardFilter = props => {
    const {
        orderContractPaymentType,
        submitFilter,
        clearFilter,
    } = props;

    const isCivil = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL;
    const isIndividualEntrepreneur = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

    const registryFilterByTicket = useSelector(registryFilterByTicketSelector);

    const [filter, setFilter] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(registryFilterByTicket)) {
            setFilter({
                ...filter,
                ...registryFilterByTicket,
            });
        }

        return () => {
            dispatch(updateTicketStore({
                ticketRegistryFilter: {},
            }));
        };
    }, [registryFilterByTicket]);

    const {
        fioFilter,
        phoneFilter,
        registryPaymentFilterStatuses,
        orderAmountSumFilter,
        paymentNumberFilter,
        civilContractAmountFilter,
        orderAmountForPaymentFilter,
        contractFrameStatusFilter,
        paymentAbility,
        externalFrameContractFilter,
        individualEntrepreneurStatusFilter,
    } = filter;

    const statusOptions = useSelector(registryPaymentStatusesAllForFiltersOptionsSelector);

    const onChange = (event, {name, value}) => {
        if (name === "paymentNumberFilter" && value.length && !isNum(value)) {
            return;
        }

        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const submit = () => {
        const reqData = {
            fioFilter,
            civilContractAmountFilter: civilContractAmountFilter ? handleNumber(clearSpace(civilContractAmountFilter)) : civilContractAmountFilter,
            orderAmountForPaymentFilter: orderAmountForPaymentFilter ? handleNumber(clearSpace(orderAmountForPaymentFilter)) : orderAmountForPaymentFilter,
            orderAmountSumFilter: orderAmountSumFilter ? handleNumber(clearSpace(orderAmountSumFilter)) : orderAmountSumFilter,
            registryPaymentFilterStatuses: registryPaymentFilterStatuses ? [registryPaymentFilterStatuses] : registryPaymentFilterStatuses,
            phoneFilter: phoneFilter ? removePhoneMask(phoneFilter) : phoneFilter,
            paymentNumberFilter: +paymentNumberFilter,
            contractFrameStatusFilter: contractFrameStatusFilter?.length ? contractFrameStatusFilter : undefined,
            paymentAbility: paymentAbility && paymentAbility !== "ALL" ? paymentAbility === "YES" : undefined,
            individualEntrepreneurStatusFilter: individualEntrepreneurStatusFilter ?
                [individualEntrepreneurStatusFilter] : 
                undefined,
            externalFrameContractFilter: typeof externalFrameContractFilter === "boolean" ?
                externalFrameContractFilter :
                undefined,
        };

        submitFilter(reqData);
    };

    const clear = () => {
        setFilter({});

        clearFilter();
    };

    return (
        <NmForm addMargin>
            <NmInputV2
                name="fioFilter"
                onChange={onChange}
                value={fioFilter || ""}
                label="ФИО"
                placeholder="Введите ФИО"
                size="lg"
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                name="registryPaymentFilterStatuses"
                options={statusOptions}
                onChange={onChange}
                value={registryPaymentFilterStatuses || ""}
                label="Статус оплаты"
                placeholder="Не выбрано"
                size="lg"
            />
            {
                isCivil ?
                    <>
                        <AmountInput
                            newInput
                            size="lg"
                            label="Сумма по договору"
                            placeholder="Введите сумму"
                            addZeroAfterDivider
                            value={civilContractAmountFilter || ""}
                            name="civilContractAmountFilter"
                            onChange={onChange}
                        />
                        <AmountInput
                            newInput
                            size="lg"
                            label="На руки исполнителю"
                            placeholder="Введите сумму"
                            addZeroAfterDivider
                            value={orderAmountForPaymentFilter || ""}
                            name="orderAmountForPaymentFilter"
                            onChange={onChange}
                        />
                    </> :
                    <AmountInput
                        newInput
                        size="lg"
                        label={
                            isIndividualEntrepreneur ?
                                "Сумма по договору" :
                                "Сумма на карту"
                        }
                        placeholder="Введите сумму"
                        addZeroAfterDivider
                        value={orderAmountSumFilter || ""}
                        name="orderAmountSumFilter"
                        onChange={onChange}
                    />
            }
            <NmInputV2
                name="paymentNumberFilter"
                onChange={onChange}
                value={paymentNumberFilter || ""}
                label="Номер оплаты"
                placeholder="Введите номер оплаты"
                size="lg"
            />
            {
                !isIndividualEntrepreneur &&
                <NmDropdownV2
                    name="paymentAbility"
                    options={REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS}
                    onChange={onChange}
                    value={paymentAbility || ""}
                    label="Проверено банком"
                    placeholder="Не выбрано"
                    size="lg"
                />
            }
            <NmDropdownV2
                name="contractFrameStatusFilter"
                options={REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS}
                onChange={onChange}
                value={contractFrameStatusFilter || ""}
                label="Рамочный договор"
                placeholder="Не выбрано"
                size="lg"
                multiple
            />
            {
                isIndividualEntrepreneur &&
                <NmDropdownV2
                    name="individualEntrepreneurStatusFilter"
                    options={dictionaryToOptions(CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_FILTER_DICT)}
                    onChange={onChange}
                    value={individualEntrepreneurStatusFilter}
                    label="Статус ИП"
                    placeholder="Не выбрано"
                    size="lg"
                />
            }
            {
                !isIndividualEntrepreneur &&
                <NmDropdownV2
                    emptyOptionText="Все"
                    name="externalFrameContractFilter"
                    options={SimpleDropdownOptions}
                    onChange={onChange}
                    value={externalFrameContractFilter}
                    label="Внешний РД"
                    placeholder="Не выбрано"
                    size="lg"
                />
            }
            <FilterButtonsV2
                className="registry-card-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
};

RegistryCardFilter.propTypes = {};

export default RegistryCardFilter;