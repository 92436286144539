import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as HistoryIcon} from "../../../images/history_24.svg";
import OrderListStatus from "../../order/order-list/order-list-status";
import OrdersFilter from "../../order/order-list/orders-filter";
import AdmFinancesOrderHistoryPopup from "./components/history-modal";

import formatDate, {formatLocalDate} from "../../../utils/dateFormat";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {formatAmountWithNullChecking} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {ORDER_STATUS} from "../../../constants/clientList";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {ORDER_SOURCE_DESCRIPTIONS, ORDER_SOURCE_TYPES} from "../../../constants/order";
import {filterOrderStatuses, orderClientAdminStatuses} from "../../../constants/status";

import {clearFieldFinancesOrder, getFinancesOrdersPage} from "../../../ducks/bff/finances/orders/actionCreators";
import {
    financesOrdersListSelector,
    financesOrdersProgressSelector,
    financesOrdersTotalCountSelector,
    financesOrdersTotalPagesSelector,
} from "../../../ducks/bff/finances/orders/selectors";
import {downloadDocument} from "../../../ducks/documents";
import {saveFinanceLinkBack} from "../../../ducks/financeExport";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

class FinanceOrders extends Component {
    constructor() {
        super();
        this.state = {
            pageData: {
                pageNum: 1,
                pageSize: 25,
            },
            form: {
                statusFilter: "ALL",
                orderContractPaymentTypeFilter: "ALL",
                visibleToContractor: "all",
            },
            clientIdsFilter: null,
        };

        this.statusOptions = dictionaryToOptions(filterOrderStatuses);
        this.pageSizes = [25, 50, 100];
    }

    componentDidMount() {
        this.fetchList();
    }

    componentWillUnmount() {
        const {clearFieldFinancesOrder} = this.props;

        clearFieldFinancesOrder();
    }


    fetchList = () => {
        const {getFinancesOrdersPage} = this.props;
        const {
            pageData,
            form,
            form: {
                statusFilter,
                dateWorkEndFilter,
                dateWorkStartFilter,
                fromDateCreateFilter,
                toDateCreateFilter,
                orderContractPaymentTypeFilter,
                visibleToContractor,
            },
            clientIdsFilter,
        } = this.state;

        getFinancesOrdersPage({
            ...pageData,
            ...form,
            visibleToContractor: visibleToContractor === "all" ? undefined : visibleToContractor === "yes",
            needToEnrichOrders: true,
            dateWorkEndFilter: dateWorkEndFilter ? convertUtcToLocal(dateWorkEndFilter) : undefined,
            dateWorkStartFilter: dateWorkStartFilter ? convertUtcToLocal(dateWorkStartFilter) : undefined,
            fromDateCreateFilter: fromDateCreateFilter ? convertUtcToLocal(fromDateCreateFilter) : undefined,
            toDateCreateFilter: toDateCreateFilter ? convertUtcToLocal(toDateCreateFilter) : undefined,
            statusFilter: statusFilter === "ALL" ? [ORDER_STATUS.CLOSED, ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH] : [statusFilter],
            orderContractPaymentTypeFilter: orderContractPaymentTypeFilter === "ALL" ? undefined : orderContractPaymentTypeFilter,
            clientIdsFilter: clientIdsFilter ? [clientIdsFilter] : undefined,
        });
    };

    unLoad = (clientId, orderId, oName, cName) => {
        const {downloadDocument} = this.props;

        downloadDocument({
            downloadLink: `/bff/adm/common/naimix/download-payment-files/get-receipt-file?clientId=${clientId}&orderId=${orderId}`,
            documentType: `${cName}_${oName}_${formatDate(new Date())}`,
            extension: "xlsx",
        });
    };

    onChangeFilter = (e, {value: clientIdsFilter}) => {
        this.setState(prevState => ({
            ...prevState,
            clientIdsFilter,
        }));
    };

    get formFields() {
        return {
            nameSubstringFilter: undefined,
            orderNumFilter: undefined,
            statusFilter: "ALL",
            orderContractPaymentTypeFilter: "ALL",
            toDateCreateFilter: undefined,
            fromDateCreateFilter: undefined,
            dateWorkStartFilter: undefined,
            dateWorkEndFilter: undefined,
        };
    }

    clearFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
            },
            form: this.formFields,
            clientIdsFilter: null,
        }), this.fetchList);
    };

    submitFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
            },
        }), this.fetchList);
    };

    handleChange = (event, {name, value, checked}) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: checked === undefined ? value : checked,
            },
        }));
    };

    handleOnChangePageSize = pageSize => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageSize,
                pageNum: 1,
            },
        }), this.fetchList);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum,
            },
        }), this.fetchList);
    };

    openOrderHistory = (orderId, orderNum, name, clientId) => {
        this.setState({
            isOpenOrderHistory: true,
            historyClientId: clientId,
            history: {
                orderId,
                orderNum,
                name,
            },
        });
    };

    closeOrderHistory = () => {
        this.setState({
            isOpenOrderHistory: false,
            historyClientId: "",
            history: {},
        });
    };

    handleClickLink = () => {
        const {saveFinanceLinkBack, pathname} = this.props;

        saveFinanceLinkBack(pathname);
    };

    renderLink(content, orderId, clientId) {
        const link = LINK_ORDER_CARD
            .replace(":orderId", orderId)
            .replace(":clientId", clientId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <Link
                to={link}
                onClick={this.handleClickLink}
            >
                {content}
            </Link>
        );
    }

    renderClientLink = (clientName, brand, clientId) => {
        const link = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return (
            <Link
                to={link}
                onClick={this.handleClickLink}
            >
                {brand ? `${clientName} (${brand})` : clientName}
            </Link>
        );
    };

    renderStatus = ({status, orderSource}) => {
        return (
            <div>
                {orderClientAdminStatuses[status]}
                <div className="finance-orders__status_published">
                    {ORDER_SOURCE_DESCRIPTIONS[orderSource]}
                </div>
            </div>
        );
    };

    renderRegistryLink = ({registryId, clientId, registryOrdinalNumber, registryName, registrySeqNum}) => {
        const {
            pageData,
            form,
        } = this.props;

        const registryCardLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", clientId)
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", registryId);

        if (!registryOrdinalNumber || !registryName || !registrySeqNum) {
            return "Реестр удален";
        }

        return (
            <ExtLink
                pageData={pageData}
                filterData={form}
                historyEnabled
                to={registryCardLink}
            >
                {`№${registryOrdinalNumber} - ${registryName} (строка ${registrySeqNum})`}
            </ExtLink>
        );
    };

    renderOrderName = (item) => {
        const {
            name,
            orderId,
            clientId,
            orderSource,
        } = item;

        const isRegistryOrder = orderSource === ORDER_SOURCE_TYPES.REGISTRY;

        return (
            <>
                <div className="finance-orders__table-name">
                    {this.renderLink(name, orderId, clientId)}
                </div>
                {isRegistryOrder && this.renderRegistryLink(item)}
            </>
        );
    };

    renderActions(item, client) {
        const {
            forPaymentReport,
            hasCompletedPayment,
            clientId,
            orderId,
            orderNum,
            name,
            clientName,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.unLoad(clientId, orderId, name, clientName);
                        },
                        color: "grey",
                        children: "Выгрузить чеки",
                    },
                    visible: forPaymentReport && hasCompletedPayment,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => this.openOrderHistory(orderId, orderNum, name, clientId),
                        color: "grey",
                        children: "История",
                        icon: <HistoryIcon className="finance-orders__action-history-icon" />,
                    },
                },
            ],
        };
    }

    getRows = () => {
        const {orders} = this.props;

        return orders.map(item => {
            const {
                name,
                clientId,
                orderId,
                orderNum,
                orderSource,
                createDate,
                paidSum,
                amount,
                workStartDate,
                workEndDate,
                hiredCount,
                contractorsNeededCount,
                awaitingCount,
                status,
            } = item;

            const isRegistryOrder = orderSource === ORDER_SOURCE_TYPES.REGISTRY;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        secondaryHeaderStatus={
                            <div className="flex flex-aligned-center">
                                <OrderListStatus
                                    status={status}
                                />
                                <div className="finance-orders__status_published">
                                    {ORDER_SOURCE_DESCRIPTIONS[orderSource]}
                                </div>
                            </div>
                        }
                        secondaryHeader={`Дата создания  ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={this.renderLink(name, orderId, clientId)}
                        primaryHeaderLink
                        labels={[
                            isRegistryOrder && {text: this.renderRegistryLink(item)},
                            {label: "№", text: this.renderLink(orderNum, orderId, clientId)},
                            {label: "Заказчик", text: this.renderClientLink(item.clientName, item.brand, item.clientId)},
                        ]}
                        cards={[
                            {
                                title: "Выплачено/Всего, ₽",
                                values: [{text: formatAmountWithNullChecking(paidSum)}, {text: formatAmountWithNullChecking(amount)}],
                                className: "col-16 col-md-6",
                                noWrap: false,
                            },
                            {
                                title: "Период работ",
                                value: `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`,
                                className: "col-16 col-md-5",
                                noWrap: false,
                            },
                            {
                                title: "Факт/План/Ожидают",
                                values: [{text: hiredCount || 0}, {text: contractorsNeededCount || 0}, {text: awaitingCount || 0}],
                                className: "col-16 col-md-4",
                                noWrap: false,
                            },
                        ]}
                        cardsWithContainer
                        classNameMainContent="col-16 col-xxl-6"
                        cardsContainerClassName="col-16 col-xxl-7 mt-md-4 mt-xxl-0 align-items-end"
                        actionsClassName="col-1 col-xxl-3 align-items-end"
                        mediaControls={this.renderActions(item)}
                    />
                ),
            };
        });
    };

    renderOrderHistory() {
        const {
            isOpenOrderHistory,
            historyClientId,
            history,
        } = this.state;

        return (
            isOpenOrderHistory &&
            <AdmFinancesOrderHistoryPopup
                clientId={historyClientId}
                orderInfo={history}
                handleClose={this.closeOrderHistory}
                isAdminFinancesPage={true}
            />
        );
    }

    render() {
        const {
            orders,
            totalCount,
            totalPages,
            progressGetList,
            t,
        } = this.props;
        const {
            pageData,
            form,
            clientIdsFilter,
        } = this.state;

        return (
            <NmPage
                className="finance-orders finance-orders_overflow finance-orders_fluid"
                header={
                    <NmTitle
                        size="xl"
                        className="finance-orders__header"
                        count={totalCount}
                    >
                        {t("client-orders.title")}
                    </NmTitle>
                }
                typeFilter="vertical"
                filtersBase={
                    <OrdersFilter
                        isFinanceOrders
                        className="finance-orders__filter"
                        statusOptions={this.statusOptions}
                        pageData={pageData}
                        searchData={form}
                        clientIdsFilter={clientIdsFilter}
                        clearFilter={this.clearFilter}
                        handleChange={this.handleChange}
                        submitFilter={this.submitFilter}
                        onChangeFilter={this.onChangeFilter}
                    />
                }
                currentPageSize={pageData.pageSize}
                currentPageNum={pageData.pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleOnChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                isLoaded={progressGetList}
            >
                {this.renderOrderHistory()}
                {
                    orders?.length
                        ? <CheckboxList
                            rows={this.getRows()}
                        />
                        : <NmEmptyPageV2
                            fetchProgress={progressGetList}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        orders: financesOrdersListSelector(state),
        totalCount: financesOrdersTotalCountSelector(state),
        totalPages: financesOrdersTotalPagesSelector(state),
        progressGetList: financesOrdersProgressSelector(state),
    }),
    {
        getFinancesOrdersPage,
        clearFieldFinancesOrder,
        saveFinanceLinkBack,
        downloadDocument,
    },
)(withTranslation()(FinanceOrders));