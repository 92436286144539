import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../components/ActualComponents/Text";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import BankIndicator from "../../../components/BankIndicator";
import ContractorIndividualEntrepreneurIndicator from "../../../components/ContractorIndividualEntrepreneurIndicator";
import NmRatingFieldsForm from "../../../components/NmRatingFieldsForm";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";
import RegistriesCivilCalculator from "../civil-calculator";

import {getDateObject} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {
    clearSpace,
    divideNumberIntoDigits,
    formatAmountWithNullChecking,
    getFullName,
    getNumberFromFormattedAmount,
    innFormat,
    phoneFormat,
    replaceSpace,
} from "../../../utils/stringFormat";
import {
    convertStringToDate,
    handleDateFieldFilter,
    handleFormString,
    handleNumber,
    handleNumberForInput,
} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";
import validate from "../../../utils/validate";
import {getRegistryEditPaymentValidation} from "./utils/validation";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {REGISTRY_PAYMENT_ERROR_CODE} from "../../../constants/registry";
import {isClientUser} from "../../../constants/roles";
import {
    amountRegexDelimited,
} from "../../../constants/validationRules";

import {
    clientCardPropertiesSelector,
    clientCurrentDataSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {
    contractorCardSelector,
    contractorProgressSelector,
    contractorTotalCountSelector,
    getContractorById,
    getContractorList,
} from "../../../ducks/contractor";
import {
    contractorRegistryPaymentPossibilityListSelector,
    contractorsPaymentPossibilityListSelector,
    getContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../ducks/contractorBankCheck";
import {registriesCardSelector} from "../../../ducks/registry";
import {
    addContractorToRegistryPayments,
    editContractorToRegistryPayments,
    registryPaymentsProgressValidation,
    registryProgressDataSelector,
    registryViolationsSelector,
    updateContractorToRegistryPayments,
} from "../../../ducks/registryPayments";

import PropTypes from "prop-types";

import "./style.sass";

const TIME_INTERVAL = 2000;

class RegistryEditContractorPayment extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        nextStep: PropTypes.func,
        isEdit: PropTypes.bool,
        paymentParams: PropTypes.object,
    };

    static defaultProps = {
        onClose: () => {
        },
        nextStep: () => {
        },
        isEdit: false,
        paymentParams: {},
        contractorsData: {},
    };

    constructor(props) {
        super(props);

        const isPromptOn = ls("promptActivateContractorPaymentParams") === null ? true : ls("promptActivateContractorPaymentParams") === "true";

        this.state = {
            isPromptOn,
            errorForm: {},
            orderWorkDescription: "",
            orderDescription: "",
            orderDescriptionFns: "",
            comment: "",
            commentForContractor: "",
            orderWorkStartDate: null,
            orderWorkEndDate: null,
            contractorEstimatedTimeScore: null,
            contractorWorkQualityScore: null,
            actDate: null,
            applicationDate: null,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            isEdit,
            paymentParams: {
                orderDescription,
                orderWorkDescription,
                orderDescriptionFns,
                comment,
                commentForContractor,
                orderWorkStartDate,
                orderWorkEndDate,
                workExecutionAddress,
                orderAmount,
                contractorEstimatedTimeScore,
                contractorWorkQualityScore,
                actDate,
                orderAmountForPayment,
                civilContractAmount,
                applicationDate,
            },
        } = this.props;

        if (isEdit) {
            this.setState({
                orderWorkDescription,
                orderDescription,
                orderDescriptionFns,
                workExecutionAddress,
                comment,
                commentForContractor,
                orderWorkStartDate: convertStringToDate(orderWorkStartDate),
                orderWorkEndDate: convertStringToDate(orderWorkEndDate),
                actDate: convertStringToDate(actDate),
                applicationDate: convertStringToDate(applicationDate),
                orderAmount: divideNumberIntoDigits(handleNumberForInput(orderAmount)),
                orderAmountForPayment: divideNumberIntoDigits(handleNumberForInput(orderAmountForPayment)),
                civilContractAmount: divideNumberIntoDigits(handleNumberForInput(civilContractAmount)),
                contractorEstimatedTimeScore,
                contractorWorkQualityScore,
            });
        }

        this.getContractor();

        !isClientUser(this.role) && this.getContractorsPaymentPossibility();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            progressData: {
                successSaveContractor,
                progressSaveContractor,
            },
            onClose,
        } = this.props;

        const {
            progressData: {
                progressSaveContractor: oldProgressSaveContractor,
            },
        } = prevProps;

        if (progressSaveContractor !== oldProgressSaveContractor && !progressSaveContractor && successSaveContractor) {
            onClose();
        }
    }

    componentWillUnmount() {
        const {updateFieldsContractorPaymentPossibilityState} = this.props;

        updateFieldsContractorPaymentPossibilityState({
            registryPaymentsPossibility: [],
        });

        clearTimeout(this.checkBankPossibleTimer);
    }

    getContractor = () => {
        const {
            getContractorById,
            contractorData: {
                contractorId,
            },
        } = this.props;

        getContractorById(contractorId);
    };

    getContractorsPaymentPossibility = () => {
        const {
            getContractorsPaymentPossibility,
            registryCard: {
                clientId,
            },
            contractorData: {
                contractorId,
            },
        } = this.props;

        getContractorsPaymentPossibility({
            handleResponse: this.handleResponsePaymentPossibility,
            clientId,
            contractorIds: [contractorId],
            nameField: "registryPaymentsPossibility",
        });
    };

    handleResponsePaymentPossibility = (result) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getContractorsPaymentPossibility, TIME_INTERVAL);
    };

    handleChange = (e, {value, name}) => {
        if (name === "orderAmount") {
            let _value = value;

            _value = clearSpace(_value);

            if (_value && !amountRegexDelimited.test(_value)) {
                return;
            }
            //Делим на разряды
            _value = divideNumberIntoDigits(_value);
            this.setState({
                [name]: _value,
            });
            return;
        }

        if (name === "orderDescription") {
            this.setState({
                orderDescriptionFns: replaceSpace(value.slice(0, 160).replace("\n", " ")),
            });
        }

        if (name === "orderDescriptionFns") {
            this.setState({
                [name]: replaceSpace(value.replace("\n", " ")),
            });
            return;
        }

        this.setState({
            [name]: value,
        });
    };

    isValidForm() {
        const {
            orderWorkDescription,
            orderDescription,
            orderDescriptionFns,
            comment,
            commentForContractor,
            orderAmount,
            civilContractAmount,
            orderAmountForPayment,
            orderWorkStartDate,
            orderWorkEndDate,
            contractorEstimatedTimeScore,
            contractorWorkQualityScore,
            actDate,
        } = this.state;

        const {
            registryCard,
            clientProperties,
        } = this.props;

        const rule = getRegistryEditPaymentValidation(registryCard, clientProperties);

        const error = validate(
            {
                orderWorkDescription,
                orderDescription,
                orderDescriptionFns,
                comment,
                commentForContractor,
                orderAmount: clearSpace(orderAmount),
                orderWorkStartDate,
                orderWorkEndDate,
                contractorEstimatedTimeScore,
                contractorWorkQualityScore,
                actDate,
                orderAmountForPayment: orderAmountForPayment ? getNumberFromFormattedAmount(orderAmountForPayment) : null,
                civilContractAmount: civilContractAmount ? getNumberFromFormattedAmount(civilContractAmount) : null,
            },
            rule,
            "",
        );

        this.setState({errorForm: {...error}});

        return Object.values(error).length === 0;
    }

    save = () => {
        const isValid = this.isValidForm();

        if (!isValid) {
            return;
        }

        const {
            addContractorToRegistryPayments,
            updateContractorToRegistryPayments,
            editContractorToRegistryPayments,
            registryCard: {
                clientId,
                registryId,
                orderContractPaymentType,
                documentTablesEnabled,
            },
            contractor: {
                contractorId,
                phone,
                inn,
            },
            paymentParams,
            isEdit,
            paymentPossibilityViolations,
            clientProperties,
        } = this.props;

        const {
            orderWorkStartDate,
            orderWorkEndDate,
            orderAmount,
            actDate,
            applicationDate,
            orderWorkDescription,
            workExecutionAddress,
            orderDescription,
            orderDescriptionFns,
            orderAmountForPayment,
            civilContractAmount,
            comment,
            commentForContractor,
            contractorEstimatedTimeScore,
            contractorWorkQualityScore,
        } = this.state;

        const generalParams = {
            contractorId,
            clientId,
            registryId,
            contractorInn: inn,
            contractorPhone: phone,
            orderDescription: handleFormString(orderDescription),
            orderDescriptionFns: handleFormString(orderDescriptionFns),
            orderWorkDescription: handleFormString(orderWorkDescription),
            commentForContractor: handleFormString(commentForContractor),
        };

        const updateParams = {
            ...generalParams,
            actDate: handleDateFieldFilter(actDate),
            applicationDate: handleDateFieldFilter(applicationDate),
            clientId,
            comment: handleFormString(comment),
            contractorEstimatedTimeScore,
            contractorWorkQualityScore,
            orderAmount: orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL ? undefined : handleNumber(clearSpace(orderAmount)),
            orderAmountForPayment: orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL ? getNumberFromFormattedAmount(orderAmountForPayment) : undefined,
            orderWorkEndDate: handleDateFieldFilter(orderWorkEndDate),
            orderWorkStartDate: handleDateFieldFilter(orderWorkStartDate),
            registryId,
            workExecutionAddress: handleFormString(workExecutionAddress),
            civilContractAmount: orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL ? getNumberFromFormattedAmount(civilContractAmount) : undefined,
        };

        const getResult = ({errorMessage, errorCode}) => {
            if (errorCode === REGISTRY_PAYMENT_ERROR_CODE.COMMENT_VALID_ERROR) {
                this.setState(prevState => ({
                    ...prevState,
                    errorForm: {
                        ...prevState.errorForm,
                        comment: "Не пройдена проверка на наличие ИНН компании",
                    },
                }));

                return;
            }

            if (errorMessage) {
                toastError(errorMessage);
            }
        };

        if (isEdit && documentTablesEnabled) {
            editContractorToRegistryPayments({
                ...generalParams,
                registrySeqNum: paymentParams.registrySeqNum,
                getResult,
            });

            return;
        }

        if (isEdit) {
            updateContractorToRegistryPayments({
                registrySeqNum: paymentParams.registrySeqNum,
                ...updateParams,
                getResult,
            });

            return;
        }

        const addParams = {
            contractorPaymentPossibility: !paymentPossibilityViolations.length,
            paymentPossibilityViolations,
        };

        addContractorToRegistryPayments({
            ...addParams,
            ...updateParams,
            getResult,
        });
    };

    get possibleDataSource() {
        const {
            registryPaymentPossibility,
            contractorsPaymentPossibilityList,
            isEditRealContractor,
        } = this.props;

        return isEditRealContractor ? contractorsPaymentPossibilityList : registryPaymentPossibility;
    }

    back = () => {
        const {nextStep, prevStep} = this.props;

        nextStep(prevStep);
    };

    renderActions() {
        const {
            t,
            onClose,
            progressData: {
                progressSaveContractor,
            },
            progressSearch,
            isEdit,
        } = this.props;

        return (
            <ApplyButtons
                align="right"
                mobile="column"
                isHiddenCancelOnMobile
                loading={progressSaveContractor}
                disabled={progressSaveContractor || progressSearch}
                onClose={onClose}
                submit={this.save}
                cancelBtnContent={t("button.cancel")}
                submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                additionalBtnContent={!isEdit && "Назад"}
                onClickAdditional={this.back}
            />
        );
    }

    handleOnChangeAddress(name) {
        return ({value}) => {
            this.handleChange(null, {value, name});
        };
    };

    onOpenPrompt = () => {
        const {isPromptOn} = this.state;

        ls("promptActivateContractorPaymentParams", !isPromptOn);

        this.setState(prevState => ({
            ...prevState,
            isShowMainPrompt: true,
            isPromptOn: !prevState.isPromptOn,
        }));
    };

    get jobEvaluationRatingContent() {
        const {
            contractorEstimatedTimeScore,
            contractorWorkQualityScore,
            errorForm,
        } = this.state;

        return [
            {
                rating: contractorWorkQualityScore,
                name: "contractorWorkQualityScore",
                label: "Оценка качества выполненных работ:",
                error: errorForm.contractorWorkQualityScore,
            },
            {
                rating: contractorEstimatedTimeScore,
                name: "contractorEstimatedTimeScore",
                label: "Оценка по срокам выполненных работ:",
                error: errorForm.contractorEstimatedTimeScore,
            },
        ];
    }

    getAmountFields() {
        const {
            registryCard: {
                orderContractPaymentType,
            },
            clientProperties: {
                contractorIndividualRegistryPaymentsCommission,
                minimalCommissionIndividualPaymentsAmount,
            },
        } = this.props;

        const {
            errorForm,
            civilContractAmount,
            orderAmountForPayment,
            orderAmount,
            isPromptOn,
        } = this.state;

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) {
            return (
                <>
                    {this.renderWorkPeriod()}
                    <NmInputV2
                        required={true}
                        size="xl"
                        error={errorForm.orderAmount}
                        name="orderAmount"
                        label="Сумма на карту, ₽"
                        placeholder="0,00"
                        value={orderAmount || ""}
                        onChange={this.handleChange}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                hover
                                info
                                children='Net ("на руки" исполнителю)'
                                position="bottom-left"
                            />
                        }
                    />
                </>
            );
        }

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            const amount= orderAmount ? getNumberFromFormattedAmount(orderAmount) : 0;
            const contractorCommission = amount * contractorIndividualRegistryPaymentsCommission;
            const clientCommission = amount * minimalCommissionIndividualPaymentsAmount;
            const rsAmount = amount - contractorCommission;

            return (
                <>
                    <NmInputV2
                        required={true}
                        size="xl"
                        error={errorForm.orderAmount}
                        name="orderAmount"
                        label="Сумма по договору, ₽"
                        placeholder="0,00"
                        value={orderAmount || ""}
                        onChange={this.handleChange}
                    />
                    <div className="mt-4 mb-4">
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="Сумма на р/с, ₽"
                            text={formatAmountWithNullChecking(rsAmount)}
                            classNameText="ms-auto"
                            textMaxWidth={true}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="Комиссия исполнителя, ₽"
                            text={formatAmountWithNullChecking(contractorCommission)}
                            classNameText="ms-auto"
                            textMaxWidth={true}
                        />
                        <NmLabelText
                            type="page"
                            label="Комиссия заказчика, ₽"
                            text={formatAmountWithNullChecking(clientCommission)}
                            classNameText="ms-auto"
                            textMaxWidth={true}
                        />
                    </div>
                    {this.renderWorkPeriod()}
                </>
            );
        }

        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <AmountInput
                            required={true}
                            newInput={true}
                            size="xl"
                            className="registry-edit-contractor-payment__form-item"
                            error={errorForm.civilContractAmount}
                            name="civilContractAmount"
                            label="Сумма по договору, ₽"
                            placeholder="0,00"
                            value={civilContractAmount || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <AmountInput
                            required={true}
                            newInput={true}
                            size="xl"
                            error={errorForm.orderAmountForPayment}
                            name="orderAmountForPayment"
                            label={"\"На руки\" исполнителю, ₽"}
                            placeholder="0,00"
                            value={orderAmountForPayment || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <RegistriesCivilCalculator orderAmountForPayment={orderAmountForPayment} />
                {this.renderWorkPeriod()}
            </>
        );
    }

    renderWorkPeriod() {
        const {
            currentClientData: {
                nmContractDateConclusion,
            },
        } = this.props;
        const {
            orderWorkEndDate,
            orderWorkStartDate,
            isPromptOn,
            errorForm,
        } = this.state;

        return (
            <NmDateRangePickerV2
                required={true}
                className="registry-edit-contractor-payment__form-item"
                size="xl"
                label="Период работ"
                minDate={{
                    start: getDateObject(nmContractDateConclusion),
                    end: orderWorkStartDate || getDateObject(nmContractDateConclusion),
                }}
                error={errorForm.orderWorkStartDate || errorForm.orderWorkEndDate}
                startFieldName="orderWorkStartDate"
                endFieldName="orderWorkEndDate"
                value={{orderWorkStartDate, orderWorkEndDate}}
                onChange={this.handleChange}
                returnString={false}
                isVisibleTooltip={isPromptOn}
                tooltip={
                    <HelpTooltip
                        hover
                        info
                        children="Укажите дату начала и окончания работ по заказу"
                        position="bottom-left"
                    />
                }
            />
        );
    }

    renderBlockedInfo = () => {
        const {
            contractor: {
                blocked,
                blockedReason,
            },
        } = this.props;
        return (
            blocked && <NmAdvancedTooltip
                className="d-flex align-items-center pointer ms-2"
                trigger={
                    <BlockedIcon
                        width={24}
                        height={24}
                        color="red"
                    />
                }
                hover
            >
                {blockedReason}
            </NmAdvancedTooltip>
        );
    };

    renderCommentForContractorField = () => {
        const {
            commentForContractor,
            errorForm,
        } = this.state;

        return (
            <NmTextareaV2
                maxRows={3}
                minRows={2}
                maxLength={1500}
                label="Уведомление для исполнителя"
                placeholder="Уведомление для исполнителя"
                name="commentForContractor"
                error={errorForm.commentForContractor}
                onChange={this.handleChange}
                value={commentForContractor}
                tooltip={
                    <HelpTooltip
                        hover
                        info
                        children="Это уведомление увидит исполнитель после успешного завершения выплаты"
                        position="bottom-left"
                    />
                }
            />
        );
    };

    render() {
        const {
            onClose,
            contractor: {
                lastName,
                firstName,
                inn,
                fullName,
                patronymic,
                phone,
                locatedOutsideRussia,
                individualEntrepreneurStatus,
            },
            registryCard: {
                orderContractPaymentType,
                documentTablesEnabled,
            },
            isEdit,
            contractorData: {
                contractorId,
            },
            clientProperties,
        } = this.props;

        const {
            orderWorkDescription,
            orderDescription,
            orderDescriptionFns,
            workExecutionAddress,
            comment,
            errorForm,
            actDate,
            applicationDate,
            isPromptOn,
        } = this.state;

        const isIndividualEntrepreneurRegistry = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

        return (
            <NmModal
                size="md"
                onClose={onClose}
                lsKeyEditPrompt="promptActivateContractorPaymentParams"
                onChangePrompt={this.onOpenPrompt}
                footer={this.renderActions()}
                header={
                    <NmTitle size="lg">
                        {`${isEdit ? "Редактирование" : "Добавление"} параметров оплаты для исполнителя`}
                    </NmTitle>
                }
            >
                <NmForm
                    addMargin
                    className="registry-edit-contractor-payment__form"
                >
                    <div className="registry-edit-contractor-payment__info-block">
                        <div className="registry-edit-contractor-payment__info-block-name">
                            <Text.Sub
                                className="registry-edit-contractor-payment__full-name"
                                level="2"
                                medium
                            >
                                {lastName ? getFullName(lastName, firstName, patronymic) : fullName}
                            </Text.Sub>
                            {this.renderBlockedInfo()}
                            {
                                !isIndividualEntrepreneurRegistry &&
                                !isClientUser(this.role) &&
                                <BankIndicator
                                    size="lg"
                                    showLabel
                                    className="registry-edit-contractor-payment__payment-status"
                                    contractorId={contractorId}
                                    contractorsPossibility={this.possibleDataSource}
                                />
                            }
                            {
                                isIndividualEntrepreneurRegistry &&
                                !isClientUser(this.role) &&
                                <ContractorIndividualEntrepreneurIndicator
                                    className="ms-4"
                                    status={individualEntrepreneurStatus}
                                />
                            }
                        </div>
                        <Text level="3">
                            {inn && `${innFormat(inn)},`}
                            {" "}
                            {phoneFormat(phone, locatedOutsideRussia)}
                        </Text>
                    </div>
                    <NmTextareaV2
                        required={true}
                        maxRows={3}
                        minRows={2}
                        maxLength={5000}
                        label="Описание работ"
                        placeholder="Введите описание работ"
                        error={errorForm.orderWorkDescription}
                        name="orderWorkDescription"
                        onChange={this.handleChange}
                        value={orderWorkDescription}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                hover
                                info
                                children="Описание будет отображаться в описании заказа и в заявке на выполнение работ (оказание услуг)"
                                position="bottom-left"
                            />
                        }
                    />
                    <NmTextareaV2
                        required={true}
                        maxRows={3}
                        minRows={2}
                        maxLength={5000}
                        label="Описание выполненных работ для акта"
                        placeholder="Введите описание выполненных работ для акта"
                        error={errorForm.orderDescription}
                        name="orderDescription"
                        onChange={this.handleChange}
                        value={orderDescription}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                hover
                                info
                                children="Описание будет отображаться в акте выполненных работ"
                                position="bottom-left"
                            />
                        }
                    />
                    {
                        orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ &&
                        <NmTextareaV2
                            required={true}
                            size="xl"
                            maxRows={3}
                            minRows={2}
                            maxLength={160}
                            label="Описание выполненных работ для чека ФНС России"
                            placeholder="Введите описание выполненных работ для чека ФНС России"
                            error={errorForm.orderDescriptionFns}
                            name="orderDescriptionFns"
                            onChange={this.handleChange}
                            value={orderDescriptionFns}
                            isVisibleTooltip={isPromptOn}
                            tooltip={
                                <HelpTooltip
                                    hover
                                    info
                                    children="Описание будет отображаться в чеке ФНС России"
                                    position="bottom-left"
                                />
                            }
                        />
                    }
                    {
                        documentTablesEnabled ?
                            this.renderCommentForContractorField() :
                            <>
                                <NmDadataInput
                                    size="xl"
                                    label="Адрес выполнения работ"
                                    query={workExecutionAddress}
                                    name="workExecutionAddress"
                                    onChange={this.handleOnChangeAddress("workExecutionAddress")}
                                    placeholder="Введите адрес"
                                    isVisibleTooltip={isPromptOn}
                                    tooltip={
                                        <HelpTooltip
                                            hover
                                            info
                                            children="Адрес выполнения работ будет отображаться в заявке на выполнение работ (оказание услуг) и в акте выполненных работ"
                                            position="bottom-left"
                                        />
                                    }
                                />
                                <NmTextareaV2
                                    maxRows={3}
                                    minRows={2}
                                    maxLength={5000}
                                    label="Комментарий"
                                    placeholder="Комментарий"
                                    name="comment"
                                    error={errorForm.comment}
                                    onChange={this.handleChange}
                                    value={comment}
                                />
                                {this.renderCommentForContractorField()}
                                {this.getAmountFields()}
                                <div className="row gx-4">
                                    <div className="col-16 col-md-8">
                                        <NmDatePicker
                                            className="registry-edit-contractor-payment__form-item"
                                            readOnlyInput={false}
                                            dateFormat="dd.MM.yy"
                                            name="applicationDate"
                                            selected={applicationDate}
                                            onChange={this.handleChange}
                                            isClearable
                                            label="Дата заявки"
                                        />
                                    </div>
                                    <div className="col-16 col-md-8">
                                        <NmDatePicker
                                            readOnlyInput={false}
                                            dateFormat="dd.MM.yy"
                                            name="actDate"
                                            error={errorForm.actDate}
                                            selected={actDate}
                                            onChange={this.handleChange}
                                            isClearable
                                            label="Дата акта"
                                        />
                                    </div>
                                </div>
                                <div className="fluid">
                                    <NmRatingFieldsForm
                                        handleOnRate={this.handleChange}
                                    >
                                        {this.jobEvaluationRatingContent}
                                    </NmRatingFieldsForm>
                                </div>
                            </>
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        progressSearch: contractorProgressSelector(state),
        totalCount: contractorTotalCountSelector(state),
        registryCard: registriesCardSelector(state),
        currentClientData: clientCurrentDataSelector(state),
        progressData: registryProgressDataSelector(state),
        paymentPossibilityViolations: registryViolationsSelector(state),
        progressValidation: registryPaymentsProgressValidation(state),
        registryPaymentPossibility: contractorRegistryPaymentPossibilityListSelector(state),
        contractorsPaymentPossibilityList: contractorsPaymentPossibilityListSelector(state),
        contractor: contractorCardSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
    }),
    {
        getContractorList,
        addContractorToRegistryPayments,
        updateContractorToRegistryPayments,
        editContractorToRegistryPayments,
        getContractorsPaymentPossibility,
        updateFieldsContractorPaymentPossibilityState,
        getContractorById,
    },
)(withTranslation()(RegistryEditContractorPayment));