import {all, put, takeEvery} from "redux-saga/effects";

import {downloadBlob, getBlobInParts} from "../../utils/downloadBlob";
import request from "../../utils/postman";
import {toastError, toastWarning} from "../../utils/toastHelper";

const controller = "/crm/recording";
//*  TYPES  *//

const GET_RECORDING_CONVERSATION_REQUEST = "GET_RECORDING_CONVERSATION_REQUEST";
const GET_RECORDING_CONVERSATION_SUCCESS = "GET_RECORDING_CONVERSATION_SUCCESS";
const GET_RECORDING_CONVERSATION_ERROR = "GET_RECORDING_CONVERSATION_ERROR";

//*  INITIAL STATE  *//

const initial = {};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getRecordingConversation(payload) {
    return {
        type: GET_RECORDING_CONVERSATION_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

//*  SAGA  *//


//GET
// /api/crm/recording/file/get
//получение записи разговора по callId
export const getRecordingConversationSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                callId,
            },
        } = action;

        const result = yield request.getFile(`${new URL(window.location.href).origin}/api${controller}/file/get?callId=${callId}`);

        const contentLength = +result.headers.get("Content-Length");
        const fileType = result.headers.get("Content-type");

        const toastId = toastWarning("Идет скачивание файла...", false);

        const blob = yield getBlobInParts(result.body, contentLength, toastId, fileType);
        yield downloadBlob(blob, result.headers);

        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RECORDING_CONVERSATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_RECORDING_CONVERSATION_SUCCESS, payload: result});
    } catch (error) {
        console.log("error", error);
        yield put({type: GET_RECORDING_CONVERSATION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_RECORDING_CONVERSATION_REQUEST, getRecordingConversationSaga),
    ]);
}
