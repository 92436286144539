export const GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST = "GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST";
export const GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS = "GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS";
export const GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR = "GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR";

export const ADD_DIRECTORIES_DOCUMENTS_REQUEST = "ADD_DIRECTORIES_DOCUMENTS_REQUEST";
export const ADD_DIRECTORIES_DOCUMENTS_SUCCESS = "ADD_DIRECTORIES_DOCUMENTS_SUCCESS";
export const ADD_DIRECTORIES_DOCUMENTS_ERROR = "ADD_DIRECTORIES_DOCUMENTS_ERROR";

export const UPDATE_DIRECTORIES_DOCUMENTS_REQUEST = "UPDATE_DIRECTORIES_DOCUMENTS_REQUEST";
export const UPDATE_DIRECTORIES_DOCUMENTS_SUCCESS = "UPDATE_DIRECTORIES_DOCUMENTS_SUCCESS";
export const UPDATE_DIRECTORIES_DOCUMENTS_ERROR = "UPDATE_DIRECTORIES_DOCUMENTS_ERROR";

export const DELETE_DIRECTORIES_DOCUMENTS_REQUEST = "DELETE_DIRECTORIES_DOCUMENTS_REQUEST";
export const DELETE_DIRECTORIES_DOCUMENTS_SUCCESS = "DELETE_DIRECTORIES_DOCUMENTS_SUCCESS";
export const DELETE_DIRECTORIES_DOCUMENTS_ERROR = "DELETE_DIRECTORIES_DOCUMENTS_ERROR";

export const CLEAR_FIELD_DIRECTORIES_DOCUMENTS = "CLEAR_FIELD_DIRECTORIES_DOCUMENTS";