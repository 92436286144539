import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPageSettingsClientGroupsCardClientUsers} from "../../../../../../ducks/bff/settings/client-groups/card/client-users/actionCreators";
import {
    getMembersPageForGroup,
} from "../../../../../../ducks/client";

export function useFetchMembers({filter, pageSize, pageNum}) {
    const dispatch = useDispatch();

    const {excludeClientGroupId} = filter;

    useEffect(() => {
        const requestData = {
            archiveFilter: false,
            pageSize,
            pageNum,
            existInGroup: !excludeClientGroupId,
            ...filter,

        };
        dispatch(
            excludeClientGroupId
                ? getMembersPageForGroup(requestData)
                : getPageSettingsClientGroupsCardClientUsers(requestData),
        );
    }, [filter, pageSize, pageNum]);

}