import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearStoreSpecialities,
    getSpecialitiesPage,
} from "../../../../../ducks/bff/settings/directories/naimix/specialities/actionCreators";

export const useSpecialitiesFetch = ({filterData, pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(clearStoreSpecialities());
        };
    }, []);

    const fetchList = () => {
        dispatch(getSpecialitiesPage({
            ...filterData,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};