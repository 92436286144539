import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

import {UPLOAD_STATUS} from "../../../../../constants/financeExport";

export const bffClientCardReportsOneCSelector = state => state.bff.clientCardReportsOneC;
export const bffClientCardReportsOneCListSelector = createSelector(bffClientCardReportsOneCSelector, ({list}) => list);
export const bffClientCardReportsOneCTotalCountSelector = createSelector(bffClientCardReportsOneCSelector, ({totalCount}) => totalCount);
export const bffClientCardReportsOneCTotalPagesSelector = createSelector(bffClientCardReportsOneCSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => {
    return getTotalPages(totalCount, pageSize);
});
export const bffClientCardReportsOneCProgressSelector = createSelector(bffClientCardReportsOneCSelector, ({progress}) => progress);
export const bffClientCardReportsOneCActionSelector = createSelector(bffClientCardReportsOneCSelector, ({progressAction}) => progressAction);
export const bffClientCardReportsOneCTaskIdsSelector = createSelector(bffClientCardReportsOneCSelector, ({list}) => {
    return list
        .filter(({uploadStatus}) => uploadStatus === UPLOAD_STATUS.INITIALIZATION.key)
        .map(({taskId}) => (taskId))
        .filter(Boolean);
});
export const bffClientCardReportsOneCProgressExportDataSelector = createSelector(bffClientCardReportsOneCSelector, ({progressExport}) => progressExport);