import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import CovidVaccinationInfoCardEditForm from "./components/edit-form";

import {useContractorAdditionalDocuments} from "../../hooks/useAdditionalDocuments";
import {useCovidVaccinationInfoCardAction} from "./hooks/useAction";

import {getCardModeByStatusSegment} from "../../utils/statusSegment";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../../constants/contractor";

import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";

const CovidVaccinationInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const card = useSelector(contractorCardInfoSelector);

    const {
        contractorId,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useCovidVaccinationInfoCardAction({});

    const {
        requestDocumentButton,
        documentInfo,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
    } = useContractorAdditionalDocuments({
        contractorId,
        documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.COVID_19_VACCINATION_CERTIFICATE,
    });

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <CovidVaccinationInfoCardEditForm
                contractorId={contractorId}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Сертификат о вакцинации COVID-19 (доп. документ)"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            onClickDelete={toggleShowDeleteConfirm}
            isVisibleDeleteButton={isEditable && documentInfo.canBeDeleted}
            accessEdit={isEditable}
            mode={getCardModeByStatusSegment(documentInfo.status)}
            lastUpdate={documentInfo.updateDateTime}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 3,
                desktop: 3,
            }}
            mediaControlsButtons={[requestDocumentButton]}
        >
            {renderDeleteConfirm()}
            {renderEditForm()}
            {
                documentInfo.status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        scans={[
                            {
                                label: "Сертификат о вакцинации (от COVID-19) или QR-код",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.COVID_19_VACCINATION_CERTIFICATE_SCAN,
                            },
                        ]}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default CovidVaccinationInfoCard;