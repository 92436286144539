import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useOrderTemplatesConfirm} from "./useConfirm";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {NM_OPERATOR} from "../../../../constants/roles";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {FIELD_NAME_ORDER_LIST_ENUM, updateOrderArchive} from "../../../../ducks/order";

function useOrderTemplateAction({archived}) {
    const role = ls(USER_ROLE);
    const [isShowArchiveErrorConfirm, setIsShowArchiveErrorConfirm] = useState(false);
    const [failedTemplateObjects, setFailedTemplateObjects] = useState([]);
    const [isOpenEditTemplate, setOpenEditTemplate] = useState(false);
    const [templateEditData, setTemplateEditData] = useState({});
    const [openFilter, setOpenFilter] = useState(false);
    const {
        archived: isClientArchived,
    } = useSelector(clientCardInfoSelector);

    const dispatch = useDispatch();

    const {
        confirmData,
        onCloseConfirm,
    } = useOrderTemplatesConfirm();

    useEffect(() => {
        if(!isShowArchiveErrorConfirm){
            setFailedTemplateObjects([]);
        }
    }, [isShowArchiveErrorConfirm]);

    const toggleArchiveErrorConfirm = () => {
        setIsShowArchiveErrorConfirm(!isShowArchiveErrorConfirm);
    };

    const onOpenFilter = () => {
        setOpenFilter(!openFilter);
    };
    
    const onOpenTemplateEdit = (data) => {
        if (data) {
            setTemplateEditData({...data});
        }
        
        setOpenEditTemplate(true);
    };

    const onCloseTemplateEdit = () => {
        setTemplateEditData({});
        setOpenEditTemplate(false);
    };

    const getMediaControls = (item) => {
        if (isClientArchived || role === NM_OPERATOR) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenTemplateEdit(item);
                        },
                        size: "lg",
                        children: "Редактировать шаблон",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            if (archived) {
                                orderArchive(false, item);

                                return;
                            }

                            orderArchive(true, item);
                        },
                        size: "lg",
                        children: archived ? "Из архива" : "В архив",
                    },
                },
            ],
        };
    };

    const orderArchive = (isArchive, item) => {
        const {
            clientId,
            orderId,
        } = item;

        dispatch(updateOrderArchive({
            clientId,
            orderId,
            isArchive,
            nameFieldPageData: FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.PAGE_DATA,
            getResult: (result) => {
                const {failedTemplateObjects} = result;

                if (failedTemplateObjects) {
                    setFailedTemplateObjects([...failedTemplateObjects]);
                    setIsShowArchiveErrorConfirm(true);
                    return;
                }
                setFailedTemplateObjects([]);
            },
        }));
    };

    return {
        isShowArchiveErrorConfirm,
        failedTemplateObjects,
        toggleArchiveErrorConfirm,
        isOpenEditTemplate,
        onCloseTemplateEdit,
        onOpenTemplateEdit,
        templateEditData,
        getMediaControls,
        openFilter,
        confirmData,
        onCloseConfirm,
        onOpenFilter,
    };
}

export default useOrderTemplateAction;