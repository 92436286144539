import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useUserRolesDropdown} from "./hooks/useUserRoles";

export const UserRolesDropdown = (props) => {
    const {
        type,
        ...otherProps
    } = props;

    const defaultComponentProps = useUserRolesDropdown({
        type,
    });

    return (
        <NmDropdownV2
            {...defaultComponentProps}
            {...otherProps}
        />
    );
};