import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../fileStore";
import {
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS,
    BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR,
    BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS,
    BFF_CROWD_TASK_LOG_GET_PAGE_ERROR,
    BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {LINK_FINANCE_CROWD_TASKS} from "../../../../constants/links";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/registry/task",
        client: "/client-adm/crowd-tasks/registry/task",
    });
};

const getCardCrowdTask = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR,
        });
    }
};

const getByNumberCardCrowdTask = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/get/by-number`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR,
        });
    }
};

const getPageCrowdTaskContractorsInvited = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/invited/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.filter(({hasAvatar, contractorId}) => hasAvatar && Boolean(contractorId)).map(({contractorId}) => contractorId);

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR,
        });
    }
};

const inviteCrowdTaskContractor = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            reqData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/contractors/invite`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR,
            payload: error,
        });
    }
};

const getPageCrowdTaskContractorsQueue = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/in-queue/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR,
        });
    }
};

const getCrowdTaskContractorsWorking = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/working/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR,
            });
            return {
                done: true,
            };
        }

        if (result.result?.workingContractor?.contractorId && result.result?.workingContractor?.hasAvatar) {
            yield put(getUserAvatarListThumbnails({
                contractorIds: [result.result.workingContractor.contractorId],
            }));
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR,
        });
    }
};

const rejectResultCrowdTaskContractorsWorking = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/task-work/result/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR,
        });
    }
};

const approveResultCrowdTaskContractorsWorking = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/task-work/result/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR,
        });
    }
};

const forceFinishCrowdTaskContractorsWorking = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/task-work/force-finish`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR,
        });
    }
};

const rejectCrowdTaskContractors = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/contractors/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR,
        });
    }
};

const inviteWithdrawCrowdTaskContractors = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/contractors/invite/withdraw`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR,
        });
    }
};

const getCrowdTaskContractorsCompleted = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/completed/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR,
            });
            return {
                done: true,
            };
        }

        if (result.result?.workingContractor?.contractorId && result.result?.workingContractor?.hasAvatar) {
            yield put(getUserAvatarListThumbnails({
                contractorIds: [result.result.workingContractor.contractorId],
            }));
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR,
        });
    }
};

const getPageCrowdTaskContractorsRejected = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/rejected/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.filter((workingContractor) => {
                const {
                    contractorId,
                    hasAvatar,
                } = workingContractor || {};

                return hasAvatar && Boolean(contractorId);
            }).map(({contractorId}) => contractorId);

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR,
        });
    }
};

const getPageCrowdTaskContractorsInviteCandidates = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/invite-candidates/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.filter(({avatarPresent, contractorId}) => avatarPresent && Boolean(contractorId)).map(({contractorId}) => contractorId);

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR,
        });
    }
};

const scheduleOptimumIntegrationCrowdTaskContractors = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/contractors/schedule-optimum-integration`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR,
        });
    }
};

const getPageCrowdTaskLog = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                [LINK_FINANCE_CROWD_TASKS]: "/adm/finances/tasks/event-logs/page",
            },
            `${getController()}/event-logs/page`,
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_LOG_GET_PAGE_ERROR,
        });
    }
};

const getCountsCrowdTaskContractors = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/contractors/counts`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST, getCardCrowdTask),
        takeEvery(BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST, getByNumberCardCrowdTask),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST, getPageCrowdTaskContractorsInvited),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST, inviteCrowdTaskContractor),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST, getPageCrowdTaskContractorsQueue),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST, getCrowdTaskContractorsWorking),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST, rejectResultCrowdTaskContractorsWorking),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST, approveResultCrowdTaskContractorsWorking),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST, forceFinishCrowdTaskContractorsWorking),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST, rejectCrowdTaskContractors),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST, inviteWithdrawCrowdTaskContractors),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST, getCrowdTaskContractorsCompleted),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST, getPageCrowdTaskContractorsRejected),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST, getPageCrowdTaskContractorsInviteCandidates),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST, scheduleOptimumIntegrationCrowdTaskContractors),
        takeEvery(BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST, getPageCrowdTaskLog),
        takeEvery(BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST, getCountsCrowdTaskContractors),
    ]);
}