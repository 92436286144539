import * as yup from "yup";

import {getUserRole} from "../../../../../../../utils/access";

import {REG_EXP} from "../../../../../../../constants/regExp";
import {NM_MANAGER} from "../../../../../../../constants/roles";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

export const getKedoDocumentTypesValidation = () => {
    const role = getUserRole();

    return yup.object().shape({
        clientId: role === NM_MANAGER && yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        name: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Максимальная длина - 255 символов"),
        documentCode: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        externalId: yup.string()
            .max(50, "Максимальная длина - 50 символов")
            .test("letters", "Может содержать только латиницу, цифры и нижний регистр", (value) => {
                return new RegExp(REG_EXP.DOCUMENT_EXTERNAL_ID).test(value);
            }),
        routeId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};