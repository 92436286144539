import {
    CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST,
    CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST,
    CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST,
    CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE,
    GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST,
    GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST,
    SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST,
} from "./actions";

export function getPageContractorFinanceBalance(payload) {
    return {
        type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST,
        payload,
    };
}

export function getSummaryContractorFinanceBalance(payload) {
    return {
        type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST,
        payload,
    };
}

export function setContractorSmzTaxOffer(payload) {
    return {
        type: SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST,
        payload,
    };
}

export function changeOpenWithdrawalMoneyOfWalletInBank(payload) {
    return {
        type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST,
        payload,
    };
}

export function cancelContractorSmzTaxOffer(payload) {
    return {
        type: CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST,
        payload,
    };
}

export function updateContractorFinanceBalanceStore(payload) {
    return {
        type: CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE,
        payload,
    };
}

export function changeContractorBlockedBankOperation(payload) {
    return {
        type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST,
        payload,
    };
}