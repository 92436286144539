import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {withPageData} from "../../../../components/withPageData";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as EditIcon} from "../../../../images/mode.svg";
import DocumentManagementNewPositionForm from "./document-management-new-position-form";
import DocumentManagementPositionsFilter from "./document-management-positions-filter";
import {Dimmer, Loader} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../constants/color";
import {EDM_POSITION_ACTION_TYPE} from "../../../../constants/edmPosition";
import {
    ADMIN,
    NM_MANAGER,
} from "../../../../constants/roles";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {
    deleteEdmPosition,
    edmPositionActionProgressSelector,
    edmPositionListProgressSelector,
    edmPositionListSelector,
    edmPositionListTotalPagesSelector,
    edmPositionTotalCountSelector,
    getEdmPositionList,
} from "../../../../ducks/edmPositions";
import {edoAccessListSelector} from "../../../../ducks/edocuments";

import "./style.sass";

import {EDO_ACCESS_RIGHTS} from "../../../../constants/document-management/document-list";

class DocumentManagementPositions extends Component {

    static defaultProps = {};

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
            accessList,
        } = props;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            positionForEdit: {},
            positionForDelete: {},
            filterData: {},
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId;

        this.isAccessAdd = ([ADMIN, NM_MANAGER].includes(this.role) || accessList.includes(EDO_ACCESS_RIGHTS.EDM_DICTIONARY_MANAGE));
    }

    componentDidMount() {
        this.fetchEdmPositionList();
    }

    componentDidUpdate(prevProps, {isLoading}) {
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        },
        this.fetchEdmPositionList,
        );
    };

    handleChangePageSize = pageSize => {
        const {pageSize: pageSizeOld} = this.state;

        if (pageSize === pageSizeOld) {
            return;
        }

        this.setState({
            pageSize,
            pageNum: 1,
        },
        this.fetchEdmPositionList,
        );
    };

    fetchEdmPositionList = () => {
        const {
            getEdmPositionList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        getEdmPositionList({
            clientId: this.clientId,
            pageNum,
            pageSize,
            ...filterData,
        });
    };

    getRows = () => {
        const {
            list,
        } = this.props;

        return list.map(position => {
            return {
                ...position,
                key: position.positionId,
                contentRow: (
                    <NmListCard
                        alignItems="stretch"
                        noDivider
                        onlyLabels
                        classNameMainContent="col-16 col-xxl-14 d-flex align-items-center"
                        labels={[{render: () => <Text
                            color={COLOR.SECONDARY_100}
                            level="5"
                        >
                            {position.value}
                        </Text>}]}
                        mediaControls={this.getMediaControls(position)}
                        actionsClassName="col-2 justify-content-end"
                    />
                ),
            };
        });
    };

    updateState = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    onDeleteConfirm = () => {
        const {
            deleteEdmPosition,
        } = this.props;

        const {
            positionForDelete: {
                positionId,
            },
        } = this.state;

        deleteEdmPosition({
            data: {
                clientId: this.clientId,
                positionId,
            },
            onSuccess: () => {
                this.setState({
                    positionForDelete: {},
                });
            },
        });
    };

    submitFilter = (filterData, isSearch) => {
        this.setState({
            filterData,
            isSearch,
        }, this.fetchEdmPositionList);
    };

    getMediaControls = (position) => {
        if (![ADMIN, NM_MANAGER].includes(this.role) && !this.props.accessList.includes(EDO_ACCESS_RIGHTS.EDM_DICTIONARY_MANAGE)) {
            return null;
        }

        if (this.isClientArchived) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        icon: <EditIcon />,
                        onClick: this.updateState({positionForEdit: position}),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        icon: <DeleteIcon />,
                        onClick: this.updateState({positionForDelete: position}),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    renderPreloader() {
        const {
            t,
            listProgress,
            actionProgress,
        } = this.props;

        return (
            <Dimmer
                active={listProgress || actionProgress}
                inverted
            >
                <Loader content={t("loader.content")} />
            </Dimmer>
        );
    };

    renderEditPositionForm = () => {
        const {
            positionForEdit,
        } = this.state;

        return (
            positionForEdit.positionId &&
            <DocumentManagementNewPositionForm
                onCancel={this.updateState({
                    positionForEdit: {},
                })}
                type={EDM_POSITION_ACTION_TYPE.EDIT}
                clientId={this.clientId}
                positionForEdit={positionForEdit}
            />
        );
    };

    renderNewPositionForm = () => {
        const {
            showPositionNewForm,
        } = this.state;

        return (
            showPositionNewForm &&
            <DocumentManagementNewPositionForm
                onCancel={this.updateState({showPositionNewForm: false})}
                type={EDM_POSITION_ACTION_TYPE.NEW}
                clientId={this.clientId}
            />
        );
    };

    renderDeleteConfirmWindow() {
        const {
            positionForDelete,
        } = this.state;

        return (
            positionForDelete.positionId &&
            <NmConfirmV2
                content="Вы действительно хотите удалить данную должность из справочника?"
                onCancel={this.updateState({
                    positionForDelete: {},
                })}
                onConfirm={this.onDeleteConfirm}
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    }

    getAddButton() {
        if (this.isClientArchived) {
            return null;
        }

        return (this.isAccessAdd) &&
            <NmButton
                size="xl"
                className="document-management-positions__button"
                onClick={this.updateState({showPositionNewForm: true})}
            >
                Добавить должность
            </NmButton>;
    }

    render() {
        const {
            pageSize,
            pageNum,
            isSearch,
        } = this.state;

        const {
            totalPages,
            totalCount,
            listProgress,
            t,
        } = this.props;

        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                header={
                    <NmTitle
                        size="lg"
                        children={t("client-positions.title-positions")}
                    />
                }
                isLoaded={listProgress}
                headerClassName="document-management-positions__header"
                controlsClassName="document-management-positions__controls"
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                typeFilter="vertical"
                controls={
                    isShowControls &&
                    this.getAddButton()
                }
                filtersBase={
                    <DocumentManagementPositionsFilter
                        submitFilter={this.submitFilter}
                    />
                }
                totalCount={totalCount}
            >
                {this.renderPreloader()}
                {this.renderNewPositionForm()}
                {this.renderEditPositionForm()}
                {this.renderDeleteConfirmWindow()}
                {
                    !(totalCount || listProgress) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction={(!this.isClientArchived && this.isAccessAdd) && "Добавить должность"}
                            onClickAction={!this.isClientArchived && this.updateState({showPositionNewForm: true})}
                            isSearch={isSearch}
                        /> :
                        <CheckboxList rows={this.getRows()} />
                }
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        list: edmPositionListSelector(state),
        totalPages: edmPositionListTotalPagesSelector(state),
        listProgress: edmPositionListProgressSelector(state),
        totalCount: edmPositionTotalCountSelector(state),
        actionProgress: edmPositionActionProgressSelector(state),
        accessList: edoAccessListSelector(state),
        client: clientCardInfoSelector(state),
    }),
    {
        getEdmPositionList,
        deleteEdmPosition,
    },
)(withTranslation()(DocumentManagementPositions)));
