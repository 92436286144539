import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_COLLECTION_BANK_DETAILS_ERROR,
    ADD_COLLECTION_BANK_DETAILS_REQUEST,
    ADD_COLLECTION_BANK_DETAILS_SUCCESS,
    DELETE_COLLECTION_BANK_DETAILS_ERROR,
    DELETE_COLLECTION_BANK_DETAILS_REQUEST,
    DELETE_COLLECTION_BANK_DETAILS_SUCCESS,
    GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
    GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST,
    GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS,
    UPDATE_COLLECTION_BANK_DETAILS_ERROR,
    UPDATE_COLLECTION_BANK_DETAILS_REQUEST,
    UPDATE_COLLECTION_BANK_DETAILS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "/adm/settings/bank-details";

const getPageCollectionBankDetails = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

const addCollectionBankDetails = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно добавлено");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

const updateCollectionBankDetails = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно обновлено");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

const deleteCollectionBankDetails = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${controller}/${id}/delete`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно удалено");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST, getPageCollectionBankDetails),
        takeEvery(ADD_COLLECTION_BANK_DETAILS_REQUEST, addCollectionBankDetails),
        takeEvery(UPDATE_COLLECTION_BANK_DETAILS_REQUEST, updateCollectionBankDetails),
        takeEvery(DELETE_COLLECTION_BANK_DETAILS_REQUEST, deleteCollectionBankDetails),
    ]);
}