import React, {useState} from "react";
import {isEmpty} from "lodash";

import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import FnsServiceSendModal from "./send-modal";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {FNS_METHOD_LIST, FNS_METHOD_LIST_NM_MANAGER} from "../../constants/fnsService";
import {NM_MANAGER} from "../../constants/roles";

import "./style.sass";

const FnsService = () => {
    const [fnsData, setFnsData] = useState({});
    const list = ls(USER_ROLE) === NM_MANAGER ? FNS_METHOD_LIST_NM_MANAGER : FNS_METHOD_LIST;

    const selectFnsData = (data) => {
        setFnsData(data);
    };

    const handleOnCloseModal = () => {
        setFnsData({});
    };

    const renderList = (list) => {
        return list.map(item => (
            <div
                className="fns-service-table-row"
                onClick={() => {
                    selectFnsData(item);
                }}
            >
                <div>
                    {item.title}
:
                    <span className="fns-service__method">
                        {item.method}
                    </span>
                </div>
            </div>
        ),
        );
    };

    return (
        <NmPage
            rowClassName="document-management-document-list-table-row"
            header={
                <NmPageHeader
                    text="ФНС России сервис"
                />
            }
            className="fns-service-list"
            totalCount={list.length}
        >
            {renderList(list)}
            {!isEmpty(fnsData) && <FnsServiceSendModal
                fnsData={fnsData}
                onClose={handleOnCloseModal}
            />}
        </NmPage>
    );
};

export default FnsService;