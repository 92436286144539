import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import ZingChart from "zingchart-react";

import NmModal from "../../../../components/ActualComponents/NmModal";
import NmTitle from "../../../../components/NmTitle";

import {fioInitialsFormat, getFormattedFullName, getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {getForemenTimesheetMetrics} from "../../../../ducks/bff/clients/foremen/timesheets/actionCreators";
import {foremenTimesheetMetricSelector} from "../../../../ducks/bff/clients/foremen/timesheets/selectors";

import PropTypes from "prop-types";

import "./style.sass";

import "zingchart/es6";


class BrigadeTimesheetChart extends Component {
    static propTypes = {
        data: PropTypes.shape({
            clientId: PropTypes.string.isRequired,
            foremanId: PropTypes.string.isRequired,
            objectId: PropTypes.string.isRequired,
        }),
        onClose: PropTypes.func.isRequired,
    };
    static defaultProps = {
        data: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            config: {
                type: "bar",
                series: [],
                "scale-x": {
                    labels: [],
                    label: {
                        text: "Дата",
                    },
                    "tick": {
                        "size": 10,
                    },
                },
                "scale-y": {
                    values: "0:30:10",
                    label: {
                        text: "Количество людей",
                    },
                },
            },
            openListContractors: false,
            indexListContractors: 0,
            titleListContractors: "",
        };
        this.chart = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {metric} = this.props;

        if (!isEmpty(metric.contractorsCounts) && isEmpty(this.state.config.series)) {
            this.setConfig(metric);
        }
    }

    componentDidMount() {
        const {
            data,
            getForemenTimesheetMetrics,
        } = this.props;

        getForemenTimesheetMetrics({...data});
    }

    componentWillUnmount() {
        this.chart = undefined;
    }

    setConfig(metric) {
        const {
            contractorsCounts: values,
            dates: labels,
        } = metric;

        this.setState(_prevState => ({
            ..._prevState,
            config: {
                ..._prevState.config,
                series: [
                    {
                        values,
                        "background-color": "#128A10",
                    },
                ],
                "scale-x": {
                    ..._prevState.config["scale-x"],
                    labels,
                },
                "scale-y": {
                    ..._prevState.config["scale-y"],
                    //Отступ на 10 позиции вверх по оси y
                    values: `0:${Math.max(...values) + 10}:10`,
                },
            },
        }));
    }

    handleClickData = (e) => {
        this.setState(prevState => ({
            ...prevState,
            openListContractors: true,
            indexListContractors: e.nodeindex,
            titleListContractors: e.scaletext,
        }));
    };

    renderListContractors = () => {
        const {
            titleListContractors,
            openListContractors,
            indexListContractors,
        } = this.state;

        if (!openListContractors) {
            return false;
        }

        const {metric: {
            metrics: {
                [indexListContractors]: {
                    contractors,
                },
            },
        }} = this.props;

        return (
            <div className="time-sheet-chart__satellite">
                <div className="time-sheet-chart__satellite-title">
                    {titleListContractors}
                </div>
                <div className="time-sheet-chart__satellite-content">
                    {contractors.map((item, index) => {
                        return (
                            <div
                                className="contractors-item"
                                key={`contractor${index}`}
                            >
                                <span className="contractors-item__fio">
                                    {fioInitialsFormat(item.fio)}
                                </span>
                                <br />
                                <span className="contractors-item__phone">
                                    {phoneFormat(item.phone)}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderTitle() {
        const {metric} = this.props;

        const {
            objectAddress,
            objectName,
            foremanLastName,
            foremanFirstName,
            foremanPatronymic,
        } = metric;
        const fullName = getFormattedFullName(getFullName(foremanLastName, foremanFirstName, foremanPatronymic));

        return `${objectAddress}, ${objectName}, ${fullName}`;
    }

    render() {
        const {
            onClose,
        } = this.props;

        const {
            config,
        } = this.state;

        return (
            <>
                <NmModal
                    onClose={onClose}
                    className="time-sheet-chart"
                    classNameLayer="time-sheet-chart__layer"
                    header={
                        <NmTitle size="lg">
                            {this.renderTitle()}
                        </NmTitle>
                    }
                    additionElement={this.renderListContractors()}
                >
                    <ZingChart
                        ref={this.chart}
                        data={config}
                        node_click={this.handleClickData}
                    />
                </NmModal>
            </>
        );
    }
}

export default connect(
    state => ({
        metric: foremenTimesheetMetricSelector(state),
    }),
    {
        getForemenTimesheetMetrics,
    },
)(withTranslation()(BrigadeTimesheetChart));

