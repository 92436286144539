import {
    CLEAR_CONTRACTOR_DEVICE_LOG_STORE,
    CONTRACTOR_DEVICE_LOG_GET_PAGE_REQUEST,
} from "./actions";

export const getPageContractorDevicesLog = (payload) => {
    return {
        type: CONTRACTOR_DEVICE_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorDevicesLog = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_DEVICE_LOG_STORE,
        payload,
    };
};