import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {getClientNamesFilter} from "../../../../../components/ActualComponents/Filter/utils/getClientsFilter";
import {getUserRole} from "../../../../../utils/access";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    KEDO_DOCUMENTS_ROUTE_TYPE,
    KEDO_DOCUMENTS_ROUTE_TYPE_ADMIN_DICT,
    KEDO_DOCUMENTS_ROUTE_TYPE_DICT,
} from "../../../../../constants/dicts";
import {ADMIN} from "../../../../../constants/roles";

export const useKedoDocumentRoutesFilter = (props) => {
    const {
        clientId,
        pageSize,
        setPagination,
    } = props;

    const role = getUserRole();

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            !clientId && {
                row: [
                    getClientNamesFilter({
                        name: "clientId",
                    }),
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: clientId ? "nameForClient" : "name",
                        label: "Наименование маршрута",
                        placeholder: "Введите наименование",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "type",
                        label: "Тип маршрута",
                        selection: true,
                        options: dictionaryToOptions(
                            role === ADMIN
                                ? KEDO_DOCUMENTS_ROUTE_TYPE_ADMIN_DICT
                                : KEDO_DOCUMENTS_ROUTE_TYPE_DICT,
                        ),
                        placeholder: "Не выбран",
                    },
                ],
            },
        ].filter(Boolean);
    }, [clientId]);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};