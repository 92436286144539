import React, {useContext} from "react";
import {useSelector} from "react-redux";

import Tabs from "../../components/ActualComponents/Tabs";
import NmButton from "../../components/NmButton";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import TemplatesRoute from "./components/route";
import {TemplatesContext, TemplatesProvider} from "./contexts/context";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_TEMPLATES_LIST,
    LINK_TEMPLATES_LIST_WITH_TAB,
} from "../../constants/links";
import {ADMIN, NM_MANAGER} from "../../constants/roles";
import {TEMPLATES_SUB_TAB, TEMPLATES_TAB} from "./constants";

import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";

const TemplatesLayout = (props) => {
    const {
        subpage,
        subTab,
        clientId,
    } = props.match.params;
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const {
        onOpenEdit,
    } = useContext(TemplatesContext);

    const role = ls(USER_ROLE) || "";

    const pathname = useSelector((state) => state.router.location.pathname);
    const isAccessEdit = [ADMIN, NM_MANAGER].includes(role) && !clientId;
    const isLogs = pathname.includes(TEMPLATES_TAB.LOGS.LINK);

    function getIsShowTab(subpage) {
        return [TEMPLATES_TAB.APPLICATIONS.LINK, TEMPLATES_TAB.ACTS.LINK].includes(subpage);
    }

    function getListLink(subpage) {
        const isShowTabs = getIsShowTab(subpage);

        if (clientId) {
            const link = isShowTabs ?
                LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB :
                LINK_CLIENT_CARD_TEMPLATES_LIST;

            return link.replace(":clientId", clientId);
        }

        return isShowTabs ? LINK_TEMPLATES_LIST_WITH_TAB : LINK_TEMPLATES_LIST;
    }

    function getTabs() {
        return Object.values(TEMPLATES_TAB)
            .filter(item => {
                // для клиентской части - не отображаем
                if (item.VALUE === TEMPLATES_TAB.LOGS.VALUE && clientId) {
                    return false;
                }

                // для КЧ - фильтруем вкладку исходя из настроек компании
                if (
                    clientId
                    && item.VALUE === TEMPLATES_TAB.ADDITIONAL_AGREEMENT.VALUE
                    && !clientProperties.createAdditionalAgreementOnContractorPDChange
                ) {
                    return false;
                }

                return true;
            })
            .map((item) => {
                const {TEXT, LINK} = item;
                const listLink = getListLink(LINK);
                const link = listLink
                    .replace(":subpage", LINK)
                    .replace(":subTab", [
                        TEMPLATES_TAB.APPLICATIONS.LINK,
                        TEMPLATES_TAB.ACTS.LINK,
                    ].includes(LINK) ? TEMPLATES_SUB_TAB.ORDERS.LINK :
                        "",
                    );

                return {
                    name: TEXT,
                    link,
                    active: subpage === LINK,
                };
            });
    }

    function getSubTabs() {
        const listLink = getListLink(subpage);
        const mainTabInfo = Object.values(TEMPLATES_TAB).find(item => item.LINK === subpage) || {};

        return Object.values(TEMPLATES_SUB_TAB)
            .map((item) => {
                const {TEXT, LINK} = item;
                const link = listLink
                    .replace(":subpage", subpage)
                    .replace(":subTab", LINK);

                return {
                    name: `${mainTabInfo.TEXT} ${TEXT}`,
                    link,
                    active: subTab === LINK,
                };
            });
    }

    return (
        <NmPage
            controls={
                isAccessEdit && !isLogs &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={onOpenEdit}
                >
                    Создать шаблон
                </NmButton>
            }
            header={
                <NmPageHeader
                    text="Шаблоны документов Наймикс"
                />
            }
            subHeader={<Tabs
                panes={getTabs()}
            />}
        >
            {
                getIsShowTab(subpage) &&
                <Tabs
                    className="mb-4 mb-xxl-5"
                    secondary={true}
                    panes={getSubTabs()}
                />
            }
            <TemplatesRoute />
        </NmPage>
    );
};

const Templates = (props) => {
    return (
        <TemplatesProvider>
            <TemplatesLayout {...props} />
        </TemplatesProvider>
    );
};

export default Templates;