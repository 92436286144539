import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import ButtonArchive from "../../../components/ButtonArchive";
import CheckboxList from "../../../components/CheckboxList";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage, {FILTER_OPTION} from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import ObjectsEdit from "../../objects/components/edit";
import ProjectEditModal from "../projects-edit-modal";
import ProjectsFilter from "../projects-filter";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {HIDE_DEPOSIT_DETAILS_PAGE} from "../../../constants/deposit";
import {headersProjectsForemanList, headersProjectsList} from "../../../constants/headersTable";
import {LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST, LINK_FORBIDDEN_PAGE} from "../../../constants/links";
import {
    PROJECT_ACTION_DICT,
    PROJECT_ACTION_OPTIONS,
    PROJECT_ARCHIVED_OPTIONS,
    PROJECT_FIELD_NAME,
    PROJECT_STATUS_DICT,
} from "../../../constants/projects";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER, NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {importDeposit, progressDepositImportSelector} from "../../../ducks/deposit";
import {closeObject, updateObjectStatus} from "../../../ducks/objects";
import {
    changeProjectArchived,
    changeProjectStatus,
    getProjectInfoForCardSelector,
    getProjectsFinances,
    getProjectsListSelector,
    getProjectsTotalCountSelector,
    getProjectsTotalPagesSelector,
    getRichProjectsList,
    importProjectsFromFile,
    projectProgressListSelector,
    projectProgressSelector,
    updateFieldsStoreProject,
} from "../../../ducks/projects";

import PropTypes from "prop-types";

import "./style.sass";

const HEADER_OPTIONS = {
    IMPORT_DEPOSIT: {
        key: "IMPORT_DEPOSIT",
        value: "IMPORT_DEPOSIT",
        text: "Пополнить депозит объектов из файла",
    },
    IMPORT_PROJECTS: {
        key: "IMPORT_PROJECTS",
        value: "IMPORT_PROJECTS",
        text: "Загрузить список",
    },
    HIDE_DETAILS: {
        value: "HIDE_DETAILS",
    },
    ARCHIVE: {
        value: "ARCHIVE",
    },
};

class ProjectsList extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
    };

    static defaultProps = {
        totalPages: 0,
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.role = ls(USER_ROLE);

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filter: {},
            projectData: {},
            func: () => {
            },
            projectIdEdit: undefined,
            archived: false,
            status: undefined,
            sortType: "desc",
            sortName: "datetime",
            headers: this.role === FOREMAN ? headersProjectsForemanList : headersProjectsList,
            isSearch: false,
            hideDetails: false,
            importModalData: {},
        };

        this.clientUserId = ls(CURRENT_CLIENT_USER_ID);
        this.clientId = clientId;
    }

    get localizationData() {
        const {t} = this.props;

        return {
            title: t("projects.list-title"),
            createBtn: t("projects.create-btn"),
            importBtn: t("button.upload-list"),
            notSet: t("projects.not-set"),
        };
    }

    componentDidMount() {
        const {
            getProjectsFinances,
            getHideDepositDetails,
        } = this.props;
        this.fetchList();

        getProjectsFinances({
            clientId: this.clientId,
        });

        getHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.LIST_PROJECTS,
            getResult: (hideDetails) => {
                this.setState({
                    hideDetails,
                });
            },
        });
    }

    componentWillUnmount() {
        const {updateFieldsStoreProject} = this.props;

        updateFieldsStoreProject({
            projectInfo: {},
            projectsList: [],
        });
    }

    fetchList = () => {
        const {getRichProjectsList} = this.props;

        const {
            pageNum,
            pageSize,
            archived,
            filter: {
                name,
                status,
                objectNameFilter,
            },
            sortType,
            sortName,
        } = this.state;

        getRichProjectsList({
            pageNum,
            pageSize,
            clientId: this.clientId,
            archived,
            clientUserId: [PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) ? this.clientUserId : undefined,
            status: archived ? PROJECT_STATUS_DICT.CLOSE.VALUE : status,
            nameSubstringFilter: handleFormString(name),
            objectNameFilter: handleFormString(objectNameFilter),
            sortType,
            sortName,
        });
    };

    changePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    openEditModal = (projectData = {}) => {
        this.setState({
            isOpenEditModal: true,
            projectData: {
                ...projectData,
                [PROJECT_FIELD_NAME.CLIENT_ID]: this.clientId,
            },
        });
    };

    openImportProjectsModal = () => {
        this.setState({
            importModalData: {
                patternLink: "/files/Шаблон_Проекты_Объекты.xlsx",
                onSubmit: this.submitImportProjectForm,
            },
        });
    };

    openImportDepositModal = () => {
        this.setState({
            importModalData: {
                patternLink: "/files/Список объектов для пополнения депозита.xlsx",
                onSubmit: this.submitImportDepositForm,
            },
        });
    };

    closeImportFileModal = () => {
        this.setState({
            importModalData: {},
        });
    };

    closeEditModal = () => {
        const {
            updateFieldsStoreProject,
        } = this.props;

        updateFieldsStoreProject({project: {}});

        this.setState({
            isOpenEditModal: false,
            projectData: {},
        });
    };

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState({
            formFilter: {},
        }, this.fetchList);
    };

    handleChangeSearch = (sortName, sortType) => {
        if (sortType === "asc" || sortType === "desc") {
            this.setState(prevState => {
                const headers = [...prevState.headers];

                const index = headers.findIndex(h => h.key === sortName);
                const header = {
                    ...headers[index],
                    typeSort: sortType,
                };

                headers.splice(index, 1, header);

                return {
                    ...prevState,
                    sortType,
                    headers,
                    sortName,
                };
            },
            () => setTimeout(this.fetchList, 500),
            );
        }
    };

    updateHideDepositDetails = () => {
        const {setHideDepositDetails} = this.props;
        const {hideDetails} = this.state;

        setHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.LIST_PROJECTS,
            value: hideDetails,
        });
    };

    handleChangeHideDetails = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                hideDetails: !prevState.hideDetails,
            };
        }, this.updateHideDepositDetails);
    };

    showConfirmWindow = ({func, confirmText, projectId: projectIdEdit, status, archivedEdit}) => {
        this.setState({
            openConfirmWindow: true,
            func,
            confirmText,
            projectIdEdit,
            status,
            archivedEdit,
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            openConfirmWindow: false,
            confirmText: "",
            func: () => {
            },
            projectIdEdit: "",
            status: undefined,
        });
    };

    changeProjectStatus = () => {
        const {changeProjectStatus} = this.props;
        const {
            status,
            projectIdEdit: projectId,
        } = this.state;

        changeProjectStatus({
            clientId: this.clientId,
            status,
            projectId,
        });

        this.handleCancelConfirm();
    };

    changeProjectArchived = () => {
        const {changeProjectArchived} = this.props;
        const {
            projectIdEdit: projectId,
            archivedEdit: archived,
        } = this.state;

        changeProjectArchived({
            clientId: this.clientId,
            archived,
            projectId,
        });

        this.handleCancelConfirm();
    };

    addObjectModalWindow = ({open, projectId, unlimited}) => {
        return () => {
            this.setState({
                isOpenAddObjectWindow: open,
                currentProjectId: projectId,
                unlimited,
            });
        };
    };

    submitImportProjectForm = ({file}) => {
        const {importProjectsFromFile} = this.props;

        const formData = new FormData();
        formData.append("file", file);

        importProjectsFromFile({
            clientId: this.clientId,
            data: formData,
            onSuccess: this.closeImportFileModal,
        });
    };

    submitImportDepositForm = ({file}) => {
        const {importDeposit} = this.props;
        const {
            importModalData: {
                projectId,
            },
        } = this.state;

        const formData = new FormData();
        formData.append("mFile", file);

        importDeposit({
            clientId: this.clientId,
            formData,
            onSuccess: this.closeImportFileModal,
        });
    };

    onClickActionItem = (option, item) => {
        const {
            clientProperties: {
                depositDistributedByObjects,
            },
            t,
        } = this.props;
        const {value: action} = option;

        switch (action) {
            case PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE: {
                this.showConfirmWindow({
                    status: PROJECT_STATUS_DICT.IN_WORK.VALUE,
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.OPEN_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    func: this.changeProjectStatus,
                });
                break;
            }
            case PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE: {
                this.openEditModal({
                    projectId: item.projectId,
                });
                break;
            }
            case PROJECT_ACTION_DICT.ADD_OBJECT.VALUE: {
                this.addObjectModalWindow({
                    open: true,
                    projectId: item.projectId,
                    unlimited: item.unlimited,
                })();
                break;
            }
            case PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.ARCHIVE_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    func: this.changeProjectArchived,
                    archivedEdit: !item.archived,
                });
                break;
            }
            case PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    archivedEdit: !item.archived,
                    func: this.changeProjectArchived,
                });
                break;
            }
            case PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    status: PROJECT_STATUS_DICT.CLOSE.VALUE,
                    openConfirmWindow: true,
                    confirmText: t(depositDistributedByObjects ? "projects.close-distributed-confirm-text" : "projects.close-confirm-text", {name: item.name}),
                    projectId: item.projectId,
                    func: this.changeProjectStatus,
                });

                break;
            }
            default:
                break;
        }
    };

    renderAddObjectWindow() {
        const {
            isOpenAddObjectWindow,
            currentProjectId,
            unlimited,
        } = this.state;

        const {
            projectsList,
        } = this.props;

        if (isOpenAddObjectWindow) {
            return (
                <ObjectsEdit
                    handleCancel={this.addObjectModalWindow({open: false, projectId: null})}
                    selectedClientId={this.clientId}
                    projectList={projectsList}
                    currentProjectId={currentProjectId}
                    fetchList={this.fetchList}
                    unlimitedProject={unlimited}
                />
            );
        }
        return null;
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            confirmText,
            func,
        } = this.state;
        const {t} = this.props;

        return (
            openConfirmWindow &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={func}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                content={confirmText}
                size="sm"
            />
        );
    }

    renderAction = (item) => {
        const {
            archived,
        } = this.state;

        const {role} = this;

        if ([FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        function getOptions() {
            if ([OBJECT_MANAGER, PROJECT_MANAGER].includes(role)) {
                return PROJECT_STATUS_DICT.IN_WORK.VALUE === item.status && !archived ?
                    [{
                        key: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
                        value: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
                        text: PROJECT_ACTION_DICT.ADD_OBJECT.TEXT,
                    }] : [];
            }

            if (archived) {
                return PROJECT_ARCHIVED_OPTIONS;
            }

            return PROJECT_ACTION_OPTIONS[item.status];
        }

        const options = [
            ...getOptions(),
        ];

        if (!options.length) {
            return null;
        }

        return (
            <div className="fluid flex flex-content-end">
                <ContextMenu
                    options={options}
                    onClickItem={(option) => {
                        this.onClickActionItem(option, item);
                    }}
                />
            </div>
        );
    };

    renderName(item) {
        const to = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", item.clientId)
            .replace(":projectId", item.projectId);

        return (
            <ExtLink
                historyEnabled
                to={to}
            >
                {item.name}
            </ExtLink>
        );
    }

    renderStatus = status => {
        return (
            <NmBadge
                text={PROJECT_STATUS_DICT[status]?.TEXT}
                mod={PROJECT_STATUS_DICT[status]?.MOD || "gray"}
            />
        );
    };

    getRows() {
        const {
            projectsList,
            client: {
                archived: isClientArchived,
            },
            clientProperties: {
                depositDistributedByObjects,
            },
        } = this.props;

        return projectsList.map(item => {
            const {
                status,
                smzDepositAmount,
                civilDepositAmount,
                objectCount,
            } = item;

            const depositInfoCards = ![FOREMAN].includes(this.role) ? [
                {
                    title: "Депозит НПД, ₽",
                    value: depositDistributedByObjects ? formatAmountWithNullChecking(smzDepositAmount) : "Не установлен",
                    className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                },
                {
                    title: "Депозит НДФЛ, ₽",
                    value: depositDistributedByObjects ? formatAmountWithNullChecking(civilDepositAmount) : "Не установлен",
                    className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                },
            ] : [];

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-9"
                        primaryHeader={this.renderName(item)}
                        primaryHeaderNoWrap
                        secondaryHeaderStatus={this.renderStatus(status)}
                        cards={[
                            ...depositInfoCards,
                            {
                                title: "Количество объектов",
                                value: objectCount,
                                className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        actions={isClientArchived || [NM_OPERATOR].includes(this.role) ? null : this.renderAction(item)}
                    />
                ),
            };
        });
    }

    toggleArchived = () => {
        this.setState(prevState => ({
            ...prevState,
            archived: !prevState.archived,
            pageNum: 1,
        }), this.fetchList);
    };

    renderArchiveButton() {
        const {progressList} = this.props;
        const {archived} = this.state;

        return (
            <ButtonArchive
                disabled={progressList}
                onClick={this.toggleArchived}
                archivedFilter={archived}
            />
        );
    }

    renderCreateButton() {
        const {createBtn} = this.localizationData;
        const {
            client: {
                archived: isClientArchived,
            },
            totalCount,
        } = this.props;

        const {
            archived,
            isSearch,
        } = this.state;

        const isCanCreateProjects = [CLIENT_ADMIN, ADMIN, NM_MANAGER, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived;
        const isShowControls = totalCount || isSearch;

        if (archived || !isShowControls || !isCanCreateProjects) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                icon={<AddIcon />}
                onClick={this.openEditModal}
            >
                {createBtn}
            </NmButton>
        );
    }

    renderImportProjectsButton() {
        const {importBtn} = this.localizationData;
        const {archived} = this.state;

        if (archived) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                onClick={this.openImportProjectsModal}
                className="projects-list__button ms-4"
            >
                {importBtn}
            </NmButton>
        );
    };

    renderImportForm() {
        const {
            progressImport,
            progressDepositImport,
        } = this.props;
        const {importModalData} = this.state;

        const {
            patternLink,
            onSubmit,
        } = importModalData;

        return (
            !isEmpty(importModalData) &&
            <ImportFromFilePatternV2
                patternLink={patternLink}
                onSubmit={onSubmit}
                onClose={this.closeImportFileModal}
                progress={progressImport || progressDepositImport}
            />
        );
    };

    sendFilter = (filter, isSearch) => {
        this.setState({
            filter,
            isSearch,
            pageNum: 1,
        }, this.fetchList);
    };

    getContextMenuOptions = () => {
        const {
            clientProperties: {
                depositDistributedByObjects,
            },
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        const {
            archived,
            hideDetails,
        } = this.state;

        const distributedByObjectsOptions = ([ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role) && depositDistributedByObjects) ? [HEADER_OPTIONS.IMPORT_DEPOSIT] : [];

        const archivedOption = {
            key: HEADER_OPTIONS.ARCHIVE.value,
            text: archived ? "Список" : "Архив",
            value: HEADER_OPTIONS.ARCHIVE.value,
        };

        const hideDetailsOption = {
            key: HEADER_OPTIONS.HIDE_DETAILS.value,
            text: hideDetails ? "Показать детали" : "Скрыть детали",
            value: HEADER_OPTIONS.HIDE_DETAILS.value,
        };

        const importProjectsOptions = ([PROJECT_MANAGER, OBJECT_MANAGER, NM_MANAGER, CLIENT_ADMIN, ADMIN, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived) ? [HEADER_OPTIONS.IMPORT_PROJECTS] : [];

        return {
            mobile: [
                hideDetailsOption,
                FILTER_OPTION,
                archivedOption,
                ...importProjectsOptions,
                ...distributedByObjectsOptions,
            ],
            tablet: [
                hideDetailsOption,
                archivedOption,
                ...importProjectsOptions,
                ...distributedByObjectsOptions,
            ],
            other: [
                ...distributedByObjectsOptions,
            ],
        };
    };


    onClickContextMenuItem = ({value}) => {
        switch (value) {
            case HEADER_OPTIONS.HIDE_DETAILS.value:
                this.handleChangeHideDetails();

                return;
            case HEADER_OPTIONS.ARCHIVE.value:
                this.toggleArchived();

                return;
            case HEADER_OPTIONS.IMPORT_PROJECTS.value:
                this.openImportProjectsModal();

                return;
            case HEADER_OPTIONS.IMPORT_DEPOSIT.value:
                this.openImportDepositModal();

                return;
            default:
                return;
        }
    };

    renderControls() {
        const {
            hideDetails,
        } = this.state;

        const {
            client: {
                archived: isClientArchived,
            },
            hideDepositDetailsProgress,
        } = this.props;

        return {
            other: {
                render: () =>
                    <>
                        <NmHorizontalToggleV2
                            className="me-9"
                            disabled={hideDepositDetailsProgress}
                            noWrapLabel
                            leftLabel="Скрыть детали"
                            single
                            onChange={this.handleChangeHideDetails}
                            checked={hideDetails}
                            duplex={false}
                        />
                        {this.renderCreateButton()}
                        {
                            ([PROJECT_MANAGER, OBJECT_MANAGER, NM_MANAGER, CLIENT_ADMIN, ADMIN, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived)
                                ? this.renderImportProjectsButton()
                                : null
                        }
                        {this.renderArchiveButton()}
                    </>,
            },
            tablet: {
                render: () => <>
                    {this.renderCreateButton()}
                </>,
            },
            mobile: {
                render: () => <>
                    {this.renderCreateButton()}
                </>,
            },
        };
    };

    render() {
        const {
            pageNum,
            pageSize,
            isOpenEditModal,
            projectData,
            archived,
            isSearch,
            hideDetails,
            filter,
        } = this.state;

        const {
            totalPages,
            totalCount,
            progressList,
            pathname,
            client: {
                archived: isClientArchived,
            },
            projectsList,
            clientProperties: {
                civilRegistryPaymentsAvailable,
            },
        } = this.props;

        const {
            title,
        } = this.localizationData;

        const isCanCreateProjects = [CLIENT_ADMIN, ADMIN, NM_MANAGER, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived;
        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                header={
                    <NmTitle
                        size="xl"
                        count={totalCount}
                    >
                        {title}
                    </NmTitle>
                }
                subHeader={
                    ![FOREMAN].includes(this.role) &&
                    <>
                        <div className="registry-list__details-item">
                            <Text
                                className="mb-2"
                                medium
                                level="2"
                                color={COLOR.SECONDARY_70}
                                noWrap
                            >
                                Детализация по счету для выплат исполнителям с типом налогообложения НПД
                            </Text>
                            <DepositValuesAmountInfo
                                hideDetails={hideDetails}
                                clientId={this.clientId}
                            />
                        </div>
                        {
                            civilRegistryPaymentsAvailable &&
                            <div className="registry-list__details-item">
                                <Text
                                    className="mb-2"
                                    medium
                                    level="2"
                                    color={COLOR.SECONDARY_70}
                                    noWrap
                                >
                                    Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                                </Text>
                                <DepositValuesAmountInfo
                                    hideDetails={hideDetails}
                                    clientId={this.clientId}
                                    isCivil
                                />
                            </div>
                        }
                    </>
                }
                mediaQueries={{mobile: {maxWidth: 767}, tablet: {minWidth: 768, maxWidth: 1200}}}
                onClickContextMenuItem={this.onClickContextMenuItem}
                contextMenuOptions={this.getContextMenuOptions()}
                controlsByMediaQueries={this.renderControls()}
                typeFilter="vertical"
                filtersBase={
                    isShowControls &&
                    <ProjectsFilter
                        filter={filter}
                        archived={archived}
                        sendFilter={this.sendFilter}
                    />
                }
                onPaginationChange={this.changePageNum}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                currentPageSize={pageSize}
                onChangePageSize={this.changePageSize}
                isLoaded={progressList}
            >
                {this.renderConfirmWindow()}
                {this.renderAddObjectWindow()}
                {this.renderImportForm()}
                {isOpenEditModal ? <ProjectEditModal
                    pathname={pathname}
                    isEdit={!!projectData.projectId}
                    projectData={projectData}
                    onClose={this.closeEditModal}
                /> : null}
                <div className="projects-list">
                    {
                        !projectsList?.length ?
                            <NmEmptyPageV2
                                isShowAction={isCanCreateProjects && !archived}
                                textAction="Создать проект"
                                onClickAction={this.openEditModal}
                                isSearch={isSearch}
                            /> :
                            <CheckboxList
                                rows={this.getRows()}
                            />
                    }
                </div>
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        projectsList: getProjectsListSelector(state),
        totalPages: getProjectsTotalPagesSelector(state),
        totalCount: getProjectsTotalCountSelector(state),
        progressList: projectProgressListSelector(state),
        projectInfo: getProjectInfoForCardSelector(state),
        progressImport: projectProgressSelector(state),
        client: clientCardInfoSelector(state),
        pathname: state.router.location.pathname,
        clientProperties: clientCardPropertiesSelector(state),
        progressDepositImport: progressDepositImportSelector(state),
        hideDepositDetailsProgress: hideDepositDetailsProgressSelector(state),
    }),
    {
        getRichProjectsList,
        changeProjectStatus,
        changeProjectArchived,
        updateFieldsStoreProject,
        closeObject,
        updateObjectStatus,
        getProjectsFinances,
        importProjectsFromFile,
        getHideDepositDetails,
        setHideDepositDetails,
        importDeposit,
    },
)(withTranslation()(ProjectsList)));
