import React from "react";

import {ReactComponent as NoOnIcon} from "../../../../images/no_on.svg";
import {ReactComponent as NotOnIcon} from "../../../../images/not_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../images/yes_on.svg";

import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {COLOR} from "../../../../constants/color";
import {KEDO_PARTICIPANT_DOCUMENT_STATUS} from "../constants";

interface GetDocumentStatusOfSignatory {
    state?: string,
    stateStr: string,
    signedAt?: string,
    rejectReason?: string,
    isSender?: boolean,
    errorMessage?: string,
    participantStatesDict: Record<string, string>,
    isShowOnSignGosKey?: boolean,
}

export interface GetDocumentStatusOfSignatoryReturn {
    icon: React.ReactNode,
    iconColor: string,
    titleColor: string,
    title: string,
    date?: string,
    text?: string,
}

export const getKedoParticipantDocumentStatus = (params: GetDocumentStatusOfSignatory): GetDocumentStatusOfSignatoryReturn | null => {
    const {
        state,
        stateStr,
        signedAt,
        rejectReason,
    } = params;

    if (!state) {
        return null;
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.ON_APPROVAL,
        ].includes(state)
    ) {
        return {
            icon: NotOnIcon,
            iconColor: COLOR.INERT_100,
            titleColor: COLOR.SECONDARY_100,
            title: stateStr,
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.ON_SIGNING,
        ].includes(state)
    ) {
        return {
            icon: NotOnIcon,
            iconColor: COLOR.INERT_100,
            titleColor: COLOR.SECONDARY_100,
            title: stateStr,
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.SIGNED,
        ].includes(state)
    ) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.PRIMARY_100,
            titleColor: COLOR.PRIMARY_100,
            title: stateStr,
            date: dateFormat(convertUtcToLocal(signedAt), "dd.MM.yyyy"),
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.APPROVED,
        ].includes(state)
    ) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.PRIMARY_100,
            titleColor: COLOR.PRIMARY_100,
            title: stateStr,
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.SIGN_REJECTED,
        ].includes(state)
    ) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            date: dateFormat(convertUtcToLocal(signedAt), "dd.MM.yyyy"),
            title: stateStr,
            text: rejectReason,
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.APPROVE_REJECTED,
        ].includes(state)
    ) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            date: dateFormat(convertUtcToLocal(signedAt), "dd.MM.yyyy"),
            title: stateStr,
            text: rejectReason,
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.GOS_KEY_SEND_ERROR,
        ].includes(state)
    ) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            title: stateStr,
            text: "Не удалось отправить документ в Госключ. Повторите попытку отправки с помощью функции \"Отправить в Госключ повторно\"",
        };
    }

    if (
        [
            KEDO_PARTICIPANT_DOCUMENT_STATUS.NOT_FOUND,
        ].includes(state)
    ) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            title: stateStr,
            text: "Не удалось найти участника. Отредактируйте маршрут подписания для данного типа документа",
        };
    }

    return null;
};