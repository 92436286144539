import {
    KEDO_DEPARTMENTS_ADD_ERROR,
    KEDO_DEPARTMENTS_ADD_REQUEST,
    KEDO_DEPARTMENTS_ADD_SUCCESS,
    KEDO_DEPARTMENTS_CLEAR_LOGS,
    KEDO_DEPARTMENTS_CLEAR_STORE,
    KEDO_DEPARTMENTS_DELETE_ERROR,
    KEDO_DEPARTMENTS_DELETE_REQUEST,
    KEDO_DEPARTMENTS_DELETE_SUCCESS,
    KEDO_DEPARTMENTS_GET_CARD_ERROR,
    KEDO_DEPARTMENTS_GET_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_CARD_SUCCESS,
    KEDO_DEPARTMENTS_GET_PAGE_ERROR,
    KEDO_DEPARTMENTS_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_GET_PAGE_SUCCESS,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS,
    KEDO_DEPARTMENTS_GET_ROOT_ERROR,
    KEDO_DEPARTMENTS_GET_ROOT_REQUEST,
    KEDO_DEPARTMENTS_GET_ROOT_SUCCESS,
    KEDO_DEPARTMENTS_IMPORT_ERROR,
    KEDO_DEPARTMENTS_IMPORT_REQUEST,
    KEDO_DEPARTMENTS_IMPORT_SUCCESS,
    KEDO_DEPARTMENTS_LOGS_ERROR,
    KEDO_DEPARTMENTS_LOGS_REQUEST,
    KEDO_DEPARTMENTS_LOGS_SUCCESS,
    KEDO_DEPARTMENTS_UPDATE_ERROR,
    KEDO_DEPARTMENTS_UPDATE_REQUEST,
    KEDO_DEPARTMENTS_UPDATE_STORE,
    KEDO_DEPARTMENTS_UPDATE_SUCCESS,
} from "./actions";

const logs = {
    list: [],
    pageData: {},
    totalCount: 0,
    progress: false,
};

const initialState = {
    list: [],
    card: {},
    root: {},
    richCard: {},
    pageData: {},
    staffList: [],
    progress: false,
    progressCard: true,
    progressPreUpdateCard: false,
    progressAction: false,
    logs,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_DEPARTMENTS_LOGS_REQUEST:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    pageData: payload,
                    progress: true,
                },
            };
        case KEDO_DEPARTMENTS_LOGS_SUCCESS:
            return {
                ...state,
                logs: {
                    ...state,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DEPARTMENTS_LOGS_ERROR:
            return {
                ...state,
                logs: {
                    ...state,
                    progress: false,
                },
            };
        case KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST:
            return {
                ...state,
                progressPreUpdateCard: true,
            };
        case KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS:
            return {
                ...state,
                richCard: payload,
                progressPreUpdateCard: false,
            };
        case KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR:
            return {
                ...state,
                progressPreUpdateCard: false,
            };
        case KEDO_DEPARTMENTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case KEDO_DEPARTMENTS_GET_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case KEDO_DEPARTMENTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case KEDO_DEPARTMENTS_GET_CARD_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case KEDO_DEPARTMENTS_GET_CARD_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case KEDO_DEPARTMENTS_GET_CARD_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case KEDO_DEPARTMENTS_GET_ROOT_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case KEDO_DEPARTMENTS_GET_ROOT_SUCCESS:
            return {
                ...state,
                root: payload,
            };
        case KEDO_DEPARTMENTS_GET_ROOT_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case KEDO_DEPARTMENTS_UPDATE_REQUEST:
        case KEDO_DEPARTMENTS_ADD_REQUEST:
        case KEDO_DEPARTMENTS_DELETE_REQUEST:
        case KEDO_DEPARTMENTS_IMPORT_REQUEST:
            return {
                ...state,
                error: null,
                progressAction: true,
            };
        case KEDO_DEPARTMENTS_UPDATE_SUCCESS:
        case KEDO_DEPARTMENTS_ADD_SUCCESS:
        case KEDO_DEPARTMENTS_IMPORT_SUCCESS:
        case KEDO_DEPARTMENTS_DELETE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case KEDO_DEPARTMENTS_UPDATE_ERROR:
        case KEDO_DEPARTMENTS_ADD_ERROR:
        case KEDO_DEPARTMENTS_IMPORT_ERROR:
        case KEDO_DEPARTMENTS_DELETE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case KEDO_DEPARTMENTS_CLEAR_LOGS:
            return {
                ...state,
                logs,
            };
        case KEDO_DEPARTMENTS_CLEAR_STORE:
            return initialState;
        case KEDO_DEPARTMENTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};