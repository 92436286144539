import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../../components/CheckboxList";
import NmTitle from "../../../../../components/NmTitle";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";

import dateFormat, {formatLocalDate} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {filterEmptyValues} from "../../../../../utils/objectHelper";
import {getFormattedFullName, getFullName} from "../../../../../utils/stringFormat";

import {isClientUser} from "../../../../../constants/roles";
import {
    DOCUMENT_HISTORY_EVENT_TYPE,
    DOCUMENT_HISTORY_EVENT_TYPE_TEXT,
} from "../../constants";

import {getClientsKedoDocumentsHistoryPage} from "../../../../../ducks/bff/clients/kedo/documents/services";
import {bffCommonDictsDocumentHistoryEventTypesOptionsSelector} from "../../../../../ducks/bff/common/dicts/selectors";
import {getCompaniesKedoDocumentsHistoryPage} from "../../../../../ducks/bff/companies/company/kedo/documents/services";
import {
    updateKedoDocumentsStore,
} from "../../../../../ducks/kedo/documents/actionCreators";
import {
    bffCompaniesCompanyKedoDocumentsHistorySelector,
} from "../../../../../ducks/kedo/documents/selectors";

export const KedoDocumentsHistory = (props) => {
    const {
        onClose,
        clientId,
        documentId,
    } = props;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const dispatch = useDispatch();

    const options = useSelector(bffCommonDictsDocumentHistoryEventTypesOptionsSelector);
    const {
        list,
        totalCount,
        totalPages,
        progress,
    } = useSelector(bffCompaniesCompanyKedoDocumentsHistorySelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        onClear,
        onChangeFilter,
        filter,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            dateTimeFrom: null,
            dateTimeTo: null,
            eventTypesFilter: [],
        },
    });

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateKedoDocumentsStore({
                history: {
                    list: [],
                    totalCount: 0,
                    pageData: {},
                    progress: false,
                },
            }));
        };
    }, []);

    const fetchList = () => {
        const requestData = filterEmptyValues({
            clientFilter: clientId,
            documentIdFilter: documentId,
            pageNum,
            pageSize,
            createdFromFilter: dateFormat(filterData.dateTimeFrom, "yyyy-MM-dd"),
            createdToFilter: dateFormat(filterData.dateTimeTo, "yyyy-MM-dd"),
            eventTypesFilter: !isEmpty(filterData.eventTypesFilter) ?
                filterData.eventTypesFilter :
                null,
        });

        if (isClientRole) {
            dispatch(getClientsKedoDocumentsHistoryPage(requestData));

            return;
        }

        dispatch(getCompaniesKedoDocumentsHistoryPage(requestData));
    };

    const getFilters = () => {
        return (
            <div className="row gx-4 mb-4 mb-md-6">
                <div className="col-16 col-md-8 mb-3">
                    <NmDateRangePickerV2
                        size="lg"
                        startFieldName="dateTimeFrom"
                        endFieldName="dateTimeTo"
                        value={{
                            dateTimeFrom: filter.dateTimeFrom,
                            dateTimeTo: filter.dateTimeTo,
                        }}
                        isClearable
                        label="Дата операции"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-8 mb-3">
                    <NmDropdownV2
                        size="lg"
                        label="Тип события"
                        multiple={true}
                        onChange={onChangeFilter}
                        name="eventTypesFilter"
                        value={filter.eventTypesFilter}
                        options={options}
                        placeholder="Выберите тип"
                    />
                </div>
                <div className="col-16">
                    <FilterButtonsV2
                        onSearch={() => {
                            onSearch(filter);
                        }}
                        onClear={onClear}
                    />
                </div>
            </div>
        );
    };

    const getCreator = (item) => {
        if (!item.creatorKedoStaffId) {
            return "Система";
        }

        return getFormattedFullName(getFullName(
            item.creatorLastName,
            item.creatorFirstName,
            item.creatorPatronymic,
        )) || "-";
    };

    const getRows = () => {
        return list.map(item => {
            return {
                key: item.documentHistoryId,
                contentRow: (
                    <NmListCard
                        noDivider
                        fluidPrimaryHeader
                        secondaryHeader={formatLocalDate(item.createdAt, "dd.MM.yyyy HH:mm")}
                        primaryHeader={item.eventTypeDescription}
                        primaryHeaderNoWrap={false}
                        labels={[
                            {
                                label: "Тип события",
                                text: DOCUMENT_HISTORY_EVENT_TYPE_TEXT[item.eventType] || "-",
                            },
                            item.signTypeStr && {
                                label: "Тип подписи",
                                text: item.signTypeStr,
                            },
                            {
                                label: "Инициатор",
                                text: getCreator(item),
                            },
                            item.gosKeyOrderNum && {
                                label: "Номер в Госключе",
                                text: item.gosKeyOrderNum || "-",
                            },
                            item.rejectReason && {
                                label: [
                                    DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_UNSENT_GOS_KEY,
                                ].includes(item.eventType)
                                    ? "Причина ошибки"
                                    : "Причина",
                                text: item.rejectReason || "-",
                            },
                            item.creatorPositionName && {
                                label: "Должность",
                                text: item.creatorPositionName,
                            },
                        ]}
                        isFixedActions
                    />
                ),
            };
        });
    };

    const getContent = () => {
        return (
            <CheckboxList
                rows={getRows()}
                className="relative"
                loading={progress}
                loaderContent=""
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    История документа
                </NmTitle>
            }
            footer={
                <NmPagination
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onChangePageSize={onChangePageSize}
                    onChangePagination={onPaginationChange}
                />
            }
            classNameContent="flex-column"
        >
            {getFilters()}
            {getContent()}
        </NmModal>
    );
};