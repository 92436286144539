import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsDirectoriesNaimixClientCategoriesSelector = state => state.bff.settingsDirectoriesNaimixClientCategories;
export const getSettingsDirectoriesNaimixClientCategoriesPageDataSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({pageData}) => pageData);
export const getSettingsDirectoriesNaimixClientCategoriesTotalPagesSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getSettingsDirectoriesNaimixClientCategoriesTotalCountSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({totalCount}) => totalCount);
export const getSettingsDirectoriesNaimixClientCategoriesListSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({list}) => list);
export const getSettingsDirectoriesNaimixClientCategoriesProgressSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({progress}) => progress);
export const getSettingsDirectoriesNaimixClientCategoriesProgressActionSelector = createSelector(settingsDirectoriesNaimixClientCategoriesSelector, ({progressAction}) => progressAction);