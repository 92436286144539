import React from "react";

import TaskList, {SOURCE_TYPE_FILTER} from "../list";

const KedoMyTaskList = () => {
    return (
        <TaskList
            sourceTypeFilter={SOURCE_TYPE_FILTER.KEDO}
            onlyMyTasks={true}
        />
    );
};

export default KedoMyTaskList;