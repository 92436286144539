import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearFieldsClientCategories,
    getClientCategoriesPage,
} from "../../../../../ducks/bff/settings/directories/naimix/client-categories/actionCreators";

export const useSettingsDirectoriesOrderCategoryListFetch = ({filterData, pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(clearFieldsClientCategories());
        };
    }, []);

    const fetchList = () => {
        dispatch(getClientCategoriesPage({
            ...filterData,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};