import {all, put, takeEvery} from "@redux-saga/core/effects";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractorworkschedules";

const WORK_SCHEDULES_BY_ID_REQUEST = "WORK_SCHEDULES_BY_ID_REQUEST";
const WORK_SCHEDULES_BY_ID_SUCCESS = "WORK_SCHEDULES_BY_ID_SUCCESS";
const WORK_SCHEDULES_BY_ID_ERROR = "WORK_SCHEDULES_BY_ID_ERROR";

const initial = {
    progress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case WORK_SCHEDULES_BY_ID_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case WORK_SCHEDULES_BY_ID_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case WORK_SCHEDULES_BY_ID_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const getWorkSchedulesByContractorId = payload => {
    return {
        type: WORK_SCHEDULES_BY_ID_REQUEST,
        payload,
    };
};

const getWorkSchedulesByContractorIdSaga = function* (action) {
    try {
        const {payload: params} = action;
        const {errorMessage} = yield request.get(`${controller}/getAllByContractorId`, {params});

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: WORK_SCHEDULES_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: WORK_SCHEDULES_BY_ID_SUCCESS});
    } catch (error) {
        yield put({type: WORK_SCHEDULES_BY_ID_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(WORK_SCHEDULES_BY_ID_REQUEST, getWorkSchedulesByContractorIdSaga),
    ]);
}