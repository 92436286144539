import React from "react";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import Text from "../../../../components/ActualComponents/Text";
import {UkepIndicator} from "../../../../components/ActualComponents/UkepIndicator";
import {CitizenshipWithFlag} from "../../../../components/CitizenshipWithFlag";
import ExtLink from "../../../../components/ExtLink";

import dateFormat from "../../../../utils/dateFormat";
import {getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {COLOR} from "../../../../constants/color";
import {GENDER_DICT} from "../../../../constants/contractorInfo";
import {
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD_DEPARTMENTS,
} from "../../../../constants/links";

export const getProfileValues = (params) => {
    const {
        card,
        roleDict,
    } = params;

    // TODO: необходимо переработать рендер элементов (которые собираем в данном хуке)
    //  на использование с NmLabelText, чтобы кастомно Jsx не создавать,
    //  а пользоваться возможностями компонента
    const renderManagementDepartmentLabel = () => {
        return (
            <div className="d-flex flex-column">
                <NmLabelText
                    classNameLabel="align-items-center"
                    label={
                        <Text
                            level="2"
                            color={COLOR.SECONDARY_75}
                        >
                        Управленческий отдел
                        </Text>
                    }
                    tooltip={
                        <HelpTooltip
                            hover
                            width={18}
                            height={18}
                            text="Отдел в Организационной структуре"
                        />
                    }
                />
            </div>
        );
    };

    const renderDepartmentInfo = (card) => {
        const {
            kedoDepartmentId,
            departmentName,
            clientId,
        } = card;

        if (!kedoDepartmentId) {
            return "-";
        }

        const kedoDepartmentLink = LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENT_CARD_DEPARTMENTS
            .replace(":clientId", clientId)
            .replace(":kedoDepartmentId", kedoDepartmentId);

        return (
            <ExtLink
                to={kedoDepartmentLink}
                historyEnabled={true}
            >
                {departmentName}
            </ExtLink>
        );
    };

    return [
        {
            title: "Персональные данные",
            labels: [
                {
                    label: "ФИО",
                    text: getFullName(
                        card.lastName,
                        card.firstName,
                        card.patronymic,
                    ),
                },
                {
                    label: "СНИЛС",
                    text: card.snils,
                },
                {
                    label: "ИНН",
                    text: card.inn,
                },
            ],
        },
        {
            title: "Контактные данные",
            labels: [
                {
                    label: "Номер телефона",
                    text: phoneFormat(card.phone),
                },
                {
                    label: "E-mail",
                    text: card.email,
                },
            ],
        },
        {
            title: "Должность и структурное подразделение",
            labels: [
                {
                    label: "Должность",
                    text: card.positionName,
                },
                {
                    label: "Структурное подразделение компании",
                    text: card.subdivisionName,
                },
                {
                    label: renderManagementDepartmentLabel(),
                    text: renderDepartmentInfo(card),
                },
            ],
        },
        {
            title: "Паспортные данные",
            labels: [
                {
                    label: "Фамилия",
                    text: card.lastName,
                },
                {
                    label: "Имя",
                    text: card.firstName,
                },
                {
                    label: "Отчество",
                    text: card.patronymic,
                },
                {
                    row: {
                        labels: [
                            {
                                label: "Пол",
                                text: GENDER_DICT[card.gender],
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Дата рождения",
                                text: card.birthDate ? dateFormat(card.birthDate, "dd.MM.yyyy") : "",
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                {
                    row: {
                        labels: [
                            {
                                label: "Место рождения",
                                text: card.birthPlace,
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Гражданство",
                                text: card.citizenship &&
                                    <CitizenshipWithFlag
                                        citizenship={card.citizenship}
                                        textLevel="3"
                                    />,
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                ![
                    citizenshipsDict.RU.value,
                    citizenshipsDict.NOT_SPECIFIED.value,
                ].includes(card.citizenship)
                && {
                    label: "Номер документа",
                    text: card.idDocNumber,
                },
                card.citizenship !== citizenshipsDict.NOT_SPECIFIED.value
                && {
                    label: "Кем выдан",
                    text: card.idDocIssuedBy,
                },
                {
                    row: {
                        labels: [
                            card.citizenship !== citizenshipsDict.NOT_SPECIFIED.value
                            && {
                                label: "Дата выдачи",
                                text: card.idDocIssuedDate ? dateFormat(card.idDocIssuedDate, "dd.MM.yyyy") : "",
                                className: "col-16 col-md-4",
                            },
                            card.citizenship === citizenshipsDict.RU.value
                            && {
                                label: "Код подразделения",
                                text: card.idDocIssuedByDepartmentCode,
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                {
                    row: {
                        labels: [
                            [
                                citizenshipsDict.RU.value,
                            ].includes(card.citizenship)
                            && {
                                label: "Серия и номер паспорта",
                                text: card.idDocNumber,
                                className: "col-16 col-md-4",
                            },
                            ![
                                citizenshipsDict.NOT_SPECIFIED.value,
                                citizenshipsDict.LG.value,
                                citizenshipsDict.DN.value,
                            ].includes(card.citizenship)
                            && {
                                label: "Дата окончания срока действия",
                                text: dateFormat(card.idDocValidToDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                {
                    label: "Адрес регистрации/места жительства",
                    text: card.address,
                },
            ],
        },
        {
            title: "Учетные данные",
            labels: [
                {
                    label: "Роль",
                    text: roleDict[card.role] && (
                        <>
                            {roleDict[card.role]}
                            {
                                card.ukepAvailable &&
                                <div className="ms-1">
                                    <UkepIndicator />
                                </div>
                            }
                        </>
                    ),
                },
                {
                    label: "Логин",
                    text: card.login,
                },
                {
                    label: "Добавлен",
                    text: card.createdAt ? dateFormat(card.createdAt, "dd.MM.yyyy") : "",
                },
            ],
        },
    ];
};