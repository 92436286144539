import {
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_RUN_REQUEST,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST,
    UPDATE_CROWD_ACT_REGISTRY_STORE,
} from "./actions";

export const getPageCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST,
        payload,
    };
};

export const createCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST,
        payload,
    };
};

export const editCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST,
        payload,
    };
};

export const archiveCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST,
        payload,
    };
};

export const unarchiveCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST,
        payload,
    };
};

export const getCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST,
        payload,
    };
};

export const deleteCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST,
        payload,
    };
};

export const runCrowdActRegistry = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_RUN_REQUEST,
        payload,
    };
};

export const getPageCrowdActRegistryLog = (payload) => {
    return {
        type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateStoreCrowdActRegistry = (payload) => {
    return {
        type: UPDATE_CROWD_ACT_REGISTRY_STORE,
        payload,
    };
};