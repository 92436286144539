import React, {ReactNode} from "react";
import {Link} from "react-router-dom";

import DropzoneV2 from "../../DropzoneV2";
import HelpTooltip from "../../HelpTooltip";
import NmForm from "../../NmForm";
import Text from "../../Text";

import "./style.sass";

interface ImportFromFilePatternFormProps {
    headerControls?: ReactNode,
    patternLink: string,
    templateText?: string,
    textTooltip?: string,
    maxSize: number,
    files: Array<File>,
    setFiles: (files: Array<File>) => void,

}
export const ImportFromFilePatternForm: React.FC<ImportFromFilePatternFormProps> = (props) => {
    const {
        headerControls,
        patternLink,
        templateText = "предложенному шаблону",
        maxSize,
        textTooltip,
        files,
        setFiles,
    } = props;

    return (
        <NmForm addMargin>
            {
                headerControls && headerControls
            }
            <div className="flex">
                <Text level="3">
                    Вы можете загрузить список из файла, заполненного по
                    <Link
                        download
                        className="import-from-file-pattern-form__link"
                        to={patternLink}
                        target="_blank"
                    >
                        {templateText}
                    </Link>
                </Text>
                {
                    textTooltip &&
                    <div
                        className="import-from-file-pattern-form__text-tooltip"
                    >
                        <HelpTooltip
                            info
                            hover
                            children={textTooltip}
                        />
                    </div>
                }
            </div>
            <DropzoneV2
                maxSize={maxSize}
                files={files}
                onChange={setFiles}
                multiple={false}
                format="*.XLSX"
                accept={[".xlsx"]}
            />
        </NmForm>
    );
};