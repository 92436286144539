import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import ClientNamesDropdown from "../../../../../components/ActualComponents/ClientNamesDropdown";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {ProjectsObjectsCheckboxList} from "../../../../../components/ProjectsObjectsCheckboxList";

import {updateClientBrandsStore} from "../../../../../ducks/client/brands/actionCreators";
import {
    getSmartLinkCachedClients,
    getSmartLinkClientObjects,
    getSmartLinkClientProjects,
    getSmartLinkObjectsProjectList,
    saveSmartLinkClientPart,
    updateSmartLinkStore,
} from "../../../../../ducks/smart-link/actionCreators";
import {
    smartLinkCachedClientIdsSelector,
    smartLinkErrorSelector,
    smartLinkObjectsSelector,
    smartLinkProgressActionSelector,
    smartLinkProgressSearchSelector,
    smartLinkProjectsAndObjectsSelector,
    smartLinkProjectsSelector,
} from "../../../../../ducks/smart-link/selectors";

export const SmartLinkClientEdit = (props) => {
    const {
        smartLinkId,
        clientName,
        clientId,
        nodes,
        onClose,
        isEdit,
    } = props;

    const dispatch = useDispatch();

    const objects = useSelector(smartLinkObjectsSelector);
    const projects = useSelector(smartLinkProjectsSelector);
    const projectsAndObjects = useSelector(smartLinkProjectsAndObjectsSelector);
    const progressSearch = useSelector(smartLinkProgressSearchSelector);
    const progressAction = useSelector(smartLinkProgressActionSelector);
    const error = useSelector(smartLinkErrorSelector);
    const clientIds = useSelector(smartLinkCachedClientIdsSelector);

    const {
        errors,
        values,
        setValues,
        setFieldValue,
        setFieldError,
    } = useFormik({
        initialValues: {
            clientId: clientId || "",
            clientName: clientName || "",
        },
        enableReinitialize: true,
        validateOnBlur: false,
    });

    function onSubmit(params) {
        const {
            requestData,
            isSubmittedByConfirm,
        } = params;
        const {
            projectsParams,
            objectsParams,
            clientPropertiesParamSelectorType,
        } = requestData;

        if (!isEdit && clientIds.includes(values.clientId)) {
            setFieldError("clientId", "Вы уже настроили этого заказчика. Выберите другого");

            return;
        }

        dispatch(saveSmartLinkClientPart({
            smartLinkId,
            clientSettings: {
                clientId: values.clientId,
                clientPropertiesParamSelectorType,
                projectsParams,
                objectsParams,
            },
            onSuccess: () => {
                if (!isSubmittedByConfirm) {
                    dispatch(getSmartLinkCachedClients({
                        smartLinkId,
                    }));
                }

                // управление закрытием формы
                if (params.onSuccess) {
                    params.onSuccess();

                    return;
                }

                onClose();
            },
        }));
    }

    const initialOption = useMemo(() => {
        if (clientId) {
            return {
                key: clientId,
                value: clientId,
                text: clientName,
            };
        }
    }, []);

    const updateObjectsCallback = () => {
        dispatch(updateClientBrandsStore({
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            error: null,
        }));
    };

    const clearNodesFromStore = () => {
        dispatch(updateSmartLinkStore({
            projects: {
                clientInfo: {},
                list: [],
                totalCount: 0,
                pageData: {},
            },
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            projectsAndObjects: {},
            error: null,
        }));
    };

    const fetchProjects = ({pageNum, isSearchCleared, onSuccess, pageSize}) => {
        dispatch(getSmartLinkClientProjects({
            smartLinkId,
            pageNum,
            isSearchCleared,
            pageSize,
            clientId: values.clientId,
            onSuccess,
        }));
    };

    const fetchObjects = ({projectId, pageNum, onSuccess, pageSize}) => {
        dispatch(getSmartLinkClientObjects({
            smartLinkId,
            pageNum,
            clientId: values.clientId,
            pageSize,
            projectIds: [projectId],
            onSuccess,
        }));
    };

    const fetchProjectsAndObjects = (projectObjectNameFilter) => {
        dispatch(getSmartLinkObjectsProjectList({
            smartLinkId,
            clientId: values.clientId,
            projectObjectNameFilter,
        }));
    };

    const onChangeClient = (event, data) => {
        const {value, text} = data;

        if (!isEmpty(projects.list)) {
            clearNodesFromStore();
        }

        setValues({
            clientId: value,
            clientName: text,
        });
    };

    return (
        <NmModal
            size="md"
            isShowBackButton={true}
            isBackIconAlwaysVisible={true}
            goBack={onClose}
            header={
                <NmTitle size="lg">
                    Добавление заказчика
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={() => {
                        clearNodesFromStore();
                        setFieldValue("clientId", "");
                    }}
                    submitBtnContent="Сохранить заказчика"
                    cancelBtnContent="Сбросить"
                    disabled={progressAction || projects.progress}
                    submit={() => {
                        if (!values.clientId) {
                            setFieldError("clientId", "Обязательное поле");
                        }
                    }}
                    formId="add-client"
                />
            }
            onClose={onClose}
            classNameContent="flex-column"
        >
            <ClientNamesDropdown
                required
                label="Название заказчика"
                placeholder="Не выбрано"
                className="mb-3 mb-md-4"
                onChange={onChangeClient}
                initialOption={initialOption}
                name="clientId"
                value={values.clientId}
                error={errors.clientId}
            />
            {
                values.clientId &&
                <ProjectsObjectsCheckboxList
                    onSubmitForm={onSubmit}
                    formId="add-client"
                    key={values.clientId}
                    isModal={false}
                    isCheckbox={false}
                    isClientPropertiesBoolParam={false}
                    nodes={nodes}
                    error={error}
                    updateObjectsCallback={updateObjectsCallback}
                    clearNodesFromStore={clearNodesFromStore}
                    fetchObjects={fetchObjects}
                    fetchProjectsAndObjects={fetchProjectsAndObjects}
                    fetchProjects={fetchProjects}
                    clientId={values.clientId}
                    objects={objects}
                    projects={projects}
                    projectsAndObjects={projectsAndObjects}
                    progressSearch={progressSearch}
                    progressAction={progressAction}
                    suggestion="Введите или выберите пункты из списка"
                />
            }
        </NmModal>
    );
};