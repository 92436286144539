import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Avatar from "../../../../../../components/Avatar";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmPage from "../../../../../../components/NmPage";
import CrowdTaskContractorCard from "../../components/contractor-card";
import CrowdTaskCardContractorRejectModal from "../contractor-reject-modal";

import {useModal} from "../../../../../../hooks/useModal";
import {usePagination} from "../../../../../../hooks/usePagination";
import useCrowdTaskCardContractorListAction from "./hooks/useAction";
import useCrowdTaskCardContractorListFetch from "./hooks/useFetch";

import {
    bffCrowdTasksCardContractorsListSelector,
    bffCrowdTasksCardContractorsTotalCountSelector,
    bffCrowdTasksCardContractorsTotalPagesSelector,
    bffCrowdTasksCardProgressActionSelector,
    bffCrowdTasksCardProgressListSelector,
} from "../../../../../../ducks/bff/crowd/taskCard/selectors";
import {avatarBase64ImagesListSelector} from "../../../../../../ducks/fileStore";

const CrowdTaskCardContractorList = (props) => {
    const {
        clientId,
        taskId,
        fetchMethod,
        isInvitedPage = false,
        isQueuePage = false,
        isRejectedPage = false,
    } = props;

    const list = useSelector(bffCrowdTasksCardContractorsListSelector);
    const totalPages = useSelector(bffCrowdTasksCardContractorsTotalPagesSelector);
    const totalCount = useSelector(bffCrowdTasksCardContractorsTotalCountSelector);
    const progress = useSelector(bffCrowdTasksCardProgressListSelector);
    const progressAction = useSelector(bffCrowdTasksCardProgressActionSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        fetchList,
    } = useCrowdTaskCardContractorListFetch({
        clientId,
        taskId,
        pageNum,
        pageSize,
        fetchMethod,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        getMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
    } = useCrowdTaskCardContractorListAction({
        clientId,
        taskId,
        fetchList,
        onOpenConfirm,
        onCloseConfirm,
        setPagination,
        isInvitedPage,
        isQueuePage,
    });

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                workingContractor,
                ...rejectedContractorData
            } = item;

            const contractor = isRejectedPage
                ? item.workingContractor
                : item;

            return {
                ...item,
                key: contractor?.contractorId,
                avatar: <Avatar
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                contentRow: (
                    <CrowdTaskContractorCard
                        contractor={contractor}
                        rejectedContractorData={rejectedContractorData}
                        mediaControls={getMediaControls(isRejectedPage ? item.workingContractor : item)}
                        isInvitedPage={isInvitedPage}
                        isQueuePage={isQueuePage}
                        isRejectedPage={isRejectedPage}
                    />
                ),
            };
        });
    }, [list]);

    const renderCardContractorRejectModal = () => {
        return (
            !isEmpty(contractorRejectModalData) &&
            <CrowdTaskCardContractorRejectModal
                onClose={() => setContractorRejectModalData({})}
                fetchInfo={fetchList}
                {...contractorRejectModalData}
            />
        );
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    return (
        <NmPage
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderCardContractorRejectModal()}
            {renderConfirm()}
            {
                list.length === 0 || progress ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        fetchProgress={progress}
                    /> :
                    <CheckboxList
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskCardContractorList;