import {useDispatch} from "react-redux";

import {useModal} from "../../../../../hooks/useModal";

import {
    archiveOrRestoreOrderGroup,
    deleteOrderGroup,
} from "../../../../../ducks/bff/orders/order-groups/actionCreators";

function useOrderGroupsAction({fetchList}) {
    const dispatch = useDispatch();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const archiveGroup = (item) => {
        const {
            archived,
            clientId,
            orderGroupId,
        } = item;

        onOpenModal({
            isOpenConfirm: true,
            content: archived ?
                "Вы уверены, что хотите восстановить группу заказов из архива? Статусы заказов при восстановлении из архива группы не изменятся." :
                "Вы уверены, что хотите перенести группу заказов в архив? Статусы заказов при архивировании группы не изменятся.",
            onConfirm: () => {
                dispatch(archiveOrRestoreOrderGroup({
                    clientId,
                    orderGroupId,
                    archived: !archived,
                    onSuccess: () => {
                        fetchList();
                        onCloseModal();
                    },
                }));
            },
        });
    };

    const deleteGroup = (item) => {
        const {
            clientId,
            orderGroupId,
        } = item;

        onOpenModal({
            isOpenConfirm: true,
            content: "Вы уверены, что хотите удалить группу заказов? Статусы заказов при удалении группы не изменятся.",
            onConfirm: () => {
                dispatch(deleteOrderGroup({
                    clientId,
                    orderGroupId,
                    onSuccess: () => {
                        fetchList();
                        onCloseModal();
                    },
                }));
            },
        });
    };

    return {
        onCloseModal,
        modalData,
        onOpenModal,
        archiveGroup,
        deleteGroup,
    };
}

export default useOrderGroupsAction;