import {
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS,
    UPDATE_CROWD_TASKS_ANALYTICS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    countAggregation: {},
    progress: false,
    countersProgress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST:
        case BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST:
        case BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST:
        case BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST:
        case BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST:
        case BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS:
        case BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR:
        case BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR:
        case BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR:
        case BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR:
        case BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR:
        case BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST: {
            return {
                ...state,
                countersProgress: true,
            };
        }
        case BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS: {
            return {
                ...state,
                countersProgress: false,
                countAggregation: payload.result,
            };
        }
        case BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR: {
            return {
                ...state,
                countersProgress: false,
            };
        }
        case UPDATE_CROWD_TASKS_ANALYTICS_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};