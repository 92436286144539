import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import {ReactComponent as CheckIcon} from "../../images/checkV2.svg";
import {ReactComponent as CloseIcon} from "../../images/close_24.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../images/file_download.svg";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import Text from "../ActualComponents/Text";
import {
    Loader,
} from "semantic-ui-react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

class CardApp extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.object, PropTypes.node]),
        className: PropTypes.string,
        title: PropTypes.string,
        onClickIcon: PropTypes.func,
        onClickCancelIcon: PropTypes.func,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        isEditable: PropTypes.bool,
        loading: PropTypes.bool,
        close: PropTypes.func,
        isShowCheck: PropTypes.bool,
        isEdit: PropTypes.bool,
        downloadEnabled: PropTypes.bool,
        onDownloadClick: PropTypes.func,
        border: PropTypes.bool,
        classNameTitle: PropTypes.string,
        classNameHeader: PropTypes.string,
        error: PropTypes.string,
        warning: PropTypes.string,
        isScanStyle: PropTypes.bool,
        controls: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.object, PropTypes.node]),
    };

    static defaultProps = {
        file: {
            base64str: null,
        },
        className: "",
        title: "",
        onClickIcon: () => {
        },
        onClickCancelIcon: () => {
        },
        onDownloadClick: () => {
        },
        icon: "pencil alternate",
        iconColor: "#B99736;",
        loading: false,
        buttons: null,
        isShowCheck: true,
        downloadEnabled: false,
        isEdit: false,
        border: false,
        classNameTitle: "",
        classNameHeader: "",
        error: "",
        warning: "",
        info: "",
        isScanStyle: false,
    };

    constructor(props) {
        super(props);
        const {className = ""} = props;

        const [block] = bem("card-app", className);

        this.block = block;
    }

    renderFileControls = () => {
        const {
            file,
            isEditable,
            handleDelete,
        } = this.props;

        const {
            base64str,
            fileName,
        } = file || {};

        return base64str !== null ? (
            <>
                {isEditable ? (
                    <DeleteIcon
                        width={20}
                        height={20}
                        className="card-app__control"
                        onClick={handleDelete}
                    />
                ) : null}
                <a
                    download={fileName || "Изображение"}
                    className="card-app__control"
                    href={` data:image/jpeg;Content-disposition:attachment;filename:1234;charset=utf-8;base64, ${base64str}`}
                >
                    <FileDownloadIcon
                        className="card-app__download-icon"
                        width={20}
                        height={20}
                    />
                </a>
            </>
        ) : null;
    };

    renderActions() {
        const {
            onClickIcon,
            iconColor,
            t,
            isEdit,
            isEditable,
            downloadEnabled,
            onDownloadClick,
            isShowCheck,
        } = this.props;

        if (!isEditable) {
            return null;
        }

        const checkIcon = isShowCheck ? <CheckIcon
            onClick={onClickIcon}
            width={18}
            height={18}
            className="card-app__control"
        /> : null;

        return (
            isEdit ?
                checkIcon :
                <>
                    {
                        downloadEnabled && (
                            <FileDownloadIcon
                                onClick={onDownloadClick}
                                className="card-app__control card-app__download-icon"
                                title={t("card-app.download")}
                                width={20}
                                height={20}
                            />
                        )
                    }
                    <ModeIcon
                        onClick={onClickIcon}
                        //color={iconColor}
                        className="card-app__control"
                        title={isEdit ? t("card-app.save-title") : t("card-app.edit-title")}
                        width={20}
                        height={20}
                    />
                </>
        );
    };

    renderClearIcon() {
        const {
            onClickCancelIcon,
            iconColor,
            t,
            isEdit,
        } = this.props;

        if (!isEdit) {
            return null;
        }

        return (
            <CloseIcon
                onClick={onClickCancelIcon}
                title={t("card-app.cancel")}
                className="card-app__control"
                //color={iconColor}
                width={22}
                height={22}
            >
                clear
            </CloseIcon>
        );
    }

    renderLoader() {
        const {
            children,
            loading,
            t,
        } = this.props;

        if (!loading) {
            return children;
        }

        return (
            <Loader
                className="card-app__loader"
                active
                inline
                content={t("loader.content")}
            />
        );
    }

    render() {
        const {
            title,
            close,
            isHeaderShown = true,
            border,
            classNameTitle,
            additionalActions,
            classNameHeader,
            error,
            warning,
            info,
            isScanStyle,
            controls,
        } = this.props;

        return (
            <div className={this.block({border, scans: isScanStyle})}>
                {
                    isHeaderShown &&
                    <div className={`card-app__header ${classNameHeader}`}>
                        <Text.Title
                            level={isScanStyle ? "4" : "3"}
                            className={classNameTitle}
                        >
                            <div className={"card-app__header-title-container"}>
                                {!isScanStyle && title}
                                {Boolean(info) && <HelpTooltip
                                    width={24}
                                    height={24}
                                    position="bottom-left"
                                    hover
                                    type="light"
                                    className={isScanStyle ? "card-app__header-title-tooltip" : "card-app__header-title-tooltip-revers"}
                                    text={info}
                                />}
                                {Boolean(warning) && <HelpTooltip
                                    width={24}
                                    height={24}
                                    position="bottom-left"
                                    hover
                                    type="warning"
                                    className={isScanStyle ? "card-app__header-title-tooltip" : "card-app__header-title-tooltip-revers"}
                                    text={warning}
                                />}
                                {Boolean(error) && <HelpTooltip
                                    width={24}
                                    height={24}
                                    position="bottom-left"
                                    hover
                                    type="error"
                                    className={isScanStyle ? "card-app__header-title-tooltip" : "card-app__header-title-tooltip-revers"}
                                >
                                    {error}
                                </HelpTooltip>}
                                {isScanStyle && title}
                            </div>
                            {additionalActions && <div>
                                {additionalActions}
                            </div>}
                        </Text.Title>
                        <div className="card-app__header-controls">
                            {controls}
                            {this.renderFileControls()}
                            {this.renderClearIcon()}
                            {this.renderActions()}
                        </div>
                        {close && <div
                            onClick={close}
                            className="order-edit__close"
                        >
✕
                        </div>}
                    </div>
                }
                {this.renderLoader()}
            </div>
        );
    }
}

export default withTranslation()(CardApp);
