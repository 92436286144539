import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {useObjectsChangeDeposit} from "./useChangeDeposit";
import {useObjectsEdit} from "./useEdit";
import {useLogHistory} from "./useLogHistory";

import {useModal} from "../../../hooks/useModal";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {HIDE_DEPOSIT_DETAILS_PAGE} from "../../../constants/deposit";
import {formatFileDict, UPLOAD_TYPE} from "../../../constants/financeExport";
import {OBJECT_ACTION_DICT, OBJECT_STATUS_DICT, OBJECT_STATUS_FILTER_DICT} from "../../../constants/objects";
import {PROJECT_STATUS_DICT} from "../../../constants/projects";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {importDeposit} from "../../../ducks/deposit";
import {addFinanceExportTask} from "../../../ducks/financeExport";
import {
    changeArchiveObject,
    closeObject,
    multipleChangeArchiveObject,
    updateObjectStatus,
} from "../../../ducks/objects";
import {getProjectSelector} from "../../../ducks/projects";

export const useObjectsAction = (props) => {
    const {
        clientId,
        projectId,
        depositDistributedByObjects,
        setPagination,
        pageSize,
        selectedList,
        clearSelectedRows,
    } = props;

    const [hideDetails, setHideDetails] = useState(false);
    const [statusFilter, setStatusFilter] = useState(OBJECT_STATUS_FILTER_DICT.ALL.VALUE);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [archived, setArchived] = useState(false);

    const role = ls(USER_ROLE);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        archived: isClientArchived,
    } = useSelector(clientCardInfoSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const hideDepositDetailsProgress = useSelector(hideDepositDetailsProgressSelector);
    const project = useSelector(getProjectSelector);

    const archive = getArchiveButton(t, archived, {mobile: true});

    useEffect(() => {
        dispatch(getHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.LIST_OBJECTS,
            getResult: (hideDetails) => setHideDetails(hideDetails),
        }));
    }, []);

    useEffect(() => {
        clearSelectedRows();
    }, [
        statusFilter,
        archived,
    ]);

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        onOpenAddDeposit,
        onOpenReturnDeposit,
        changeDepositData,
    } = useObjectsChangeDeposit();

    const {
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
    } = useLogHistory();

    const {
        editData,
        onOpenEdit,
    } = useObjectsEdit();

    const updateHideDetails = (value) => {
        setHideDetails(value);

        dispatch(setHideDepositDetails({
            page: HIDE_DEPOSIT_DETAILS_PAGE.LIST_OBJECTS,
            value,
        }));
    };

    const _updateObjectStatus = (params) => {
        const {
            clientId,
            objectId,
            projectId,
            status,
        } = params;

        dispatch(updateObjectStatus({
            clientId,
            objectId,
            projectId,
            status,
        }));

        onCloseConfirm();
    };

    const _closeObject = (params) => {
        const {
            clientId,
            projectId,
            objectId,
        } = params;

        dispatch(closeObject({
            clientId,
            projectId,
            objectId,
        }));

        onCloseConfirm();
    };

    const archiveObject = ({objectId, name}) => {
        dispatch(changeArchiveObject({
            clientId,
            objectId,
            archived: !archived,
            onSuccess: () => {
                toastSuccess(
                    archived
                        ? `Объект ${name} успешно восстановлен из архива`
                        : `Объект ${name} успешно перенесен в архив`,
                );
                onCloseConfirm();
            },
        }));
    };

    const multipleArchiveObject = () => {
        const objectIds = selectedList.filter(item => item.isSelected).map(({objectId}) => objectId);

        dispatch(multipleChangeArchiveObject({
            clientId,
            objectIds,
            onSuccess: () => {
                toastSuccess("Запущен процесс массовой архивации объектов. Результат можно посмотреть в разделе \"Список задач\"");
                clearSelectedRows();
                onCloseConfirm();
            },
        }));
    };

    const submitImportDepositForm = ({file}) => {
        const formData = new FormData();
        formData.append("mFile", file);

        dispatch(importDeposit({
            clientId,
            formData,
            onSuccess: () => setOpenImportModal(false),
        }));
    };

    const onClickExportObjects = () => {
        dispatch(addFinanceExportTask({
            fromClientId: clientId,
            projectId,
            uploadType: UPLOAD_TYPE.PROJECT_EMBEDDED_OBJECTS_EXPORT_FOR_OPTIMUM.value,
            formatType: formatFileDict.XLSX,
            getResult: () => {
                toastSuccess(`Запущен экспорт объектов Проекта "${project.name}". Скачать файл по готовности можно в разделе Отчеты → Экспорт отчетов`);
            },
        }));
    };

    const getListMediaControls = (item) => {
        const {
            name,
            objectId,
            clientId,
            projectId,
            projectUnlimited,
            hasAccessToArchive,
            status,
        } = item;

        const isAccessAction = ![FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit({
                                open: true,
                                object: item,
                                projectUnlimited,
                                projectId,
                            });
                        },
                        children: "Редактировать объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.IN_WORK.VALUE && !isClientArchived && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmText: t(depositDistributedByObjects ? "objects.close-distributed-confirm-text" : "objects.close-confirm-text", {name}),
                                func: () => {
                                    _closeObject({
                                        objectId,
                                        clientId,
                                        projectId,
                                    });
                                },
                            });
                        },
                        children: "Закрыть объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.IN_WORK.VALUE && !isClientArchived && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmText: t(OBJECT_ACTION_DICT.OPEN.CONFIRM_KEY, {name}),
                                func: () => {
                                    _updateObjectStatus({
                                        clientId,
                                        projectId,
                                        objectId,
                                        status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
                                    });
                                },
                            });
                        },
                        children: "Открыть объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.CLOSE.VALUE
                        && item.projectStatus === PROJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived
                        && isAccessAction
                        && !archived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenAddDeposit({name, objectId});
                        },
                        children: "Пополнить депозит объекта",
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role)
                        && clientProperties.depositDistributedByObjects
                        && item.status === PROJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenReturnDeposit({name, objectId});
                        },
                        children: "Вернуть депозит объекта",
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER].includes(role)
                        && clientProperties.depositDistributedByObjects
                        && item.status === PROJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            toggleLogDepositHistoryModal({
                                name,
                                objectId,
                                clientId,
                            });
                        },
                        children: OBJECT_ACTION_DICT.LOG_DEPOSIT_HISTORY.TEXT,
                    },
                    visible: clientProperties.depositDistributedByObjects,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmWarning: archived,
                                confirmTitle: archived &&
                                    `Вы действительно хотите восстановить объект ${name}из архива?`,
                                confirmText: archived
                                    ? "В списковой форме объект отобразится в статусе \"закрыт\""
                                    : `Вы действительно хотите перенести объект ${name} в архив?`,
                                func: () => {
                                    archiveObject({
                                        objectId,
                                        name,
                                    });
                                },
                            });
                        },
                        children: archived ? "Из архива" : "В архив",
                    },
                    visible: hasAccessToArchive
                        && status === OBJECT_STATUS_DICT.CLOSE.VALUE,
                },
            ],
        };
    };

    const headerMediaControls = {
        renderCount: {
            desktop: 3,
            tablet: 3,
            mobile: 0,
        },
        size: "xl",
        buttons: [
            {
                isAdditional: true,
                component: COMPONENT.HORIZONTAL_TOGGLE,
                props: {
                    disabled: hideDepositDetailsProgress,
                    noWrapLabel: true,
                    leftLabel: "Скрыть детали",
                    onChange: () => {
                        updateHideDetails(!hideDetails);
                    },
                    checked: hideDetails,
                    single: true,
                    duplex: false,
                },
                asset: {
                    mobile: {children: hideDetails ? "Показать детали" : "Скрыть детали"},
                },
                visible: !projectId,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Добавить объект",
                    onClick: () => {
                        onOpenEdit({open: true, object: {}, projectId});
                    },
                    color: "green",
                    size: "xl",
                    icon: <AddIcon />,
                },
                visible: ![FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR, RECRUITER].includes(role)
                    && !(projectId && project.status === PROJECT_STATUS_DICT.CLOSE.VALUE)
                    && !isClientArchived
                    && !archived,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    ...archive.props,
                    onClick: () => {
                        setArchived(!archived);
                        setPagination({
                            pageSize,
                            pageNum: 1,
                        });
                    },
                },
                visible: statusFilter !== OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Выгрузить объекты",
                    onClick: onClickExportObjects,
                    color: "grey",
                    size: "xl",
                },
                visible: Boolean(projectId) && clientProperties.crowdTasksAvailable && !archived,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Пополнить депозит объектов из файла",
                    onClick: () => setOpenImportModal(true),
                },
                visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role)
                    && !projectId
                    && !archived
                    && depositDistributedByObjects,
            },
        ],
    };

    const getStatusFilterTabs = () => {
        return ([
            {
                key: OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.IN_WORK.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
            },
            {
                key: OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.CLOSE.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE,
            },
            {
                key: OBJECT_STATUS_FILTER_DICT.ALL.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.ALL.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.ALL.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.ALL.VALUE,
            },
        ]);
    };

    return {
        onOpenAddDeposit,
        onOpenReturnDeposit,
        headerMediaControls,
        changeDepositData,
        isOpenConfirm,
        confirmData,
        onCloseConfirm,
        getListMediaControls,
        editData,
        onOpenEdit,
        hideDetails,
        getStatusFilterTabs,
        statusFilter,
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
        openImportModal,
        setOpenImportModal,
        submitImportDepositForm,
        archived,
        onOpenConfirm,
        multipleArchiveObject,
    };
};