import {
    CLEAR_CONTRACTOR_NOTIFICATION_LOG_STORE,
    CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_REQUEST,
} from "./actions";

export const getPageContractorNotificationLog = (payload) => {
    return {
        type: CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorNotificationLog = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_NOTIFICATION_LOG_STORE,
        payload,
    };
};