import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import OpenStreetMap from "../../../components/OpenStreetMap";

import {getAddress} from "../../../utils/map";
import {getKeyFromMigrationDictByDadata} from "../../../utils/objectHelper";
import validate from "../../../utils/validate";

import {FEDERAL_MIGRATION_FIELD} from "../../../constants/clientList";
import {federalMigrationRule} from "../../../constants/validationRules";

import {
    addDirectoriesFederalMigration,
    getDirectoriesFederalMigrationCard,
    updateDirectoriesFederalMigration,
    updateFieldsDirectoriesFederalMigration,
} from "../../../ducks/bff/settings/directories/naimix/federal-migration/actionCreators";
import {
    settingsDirectoriesNaimixFederalMigrationCardSelector,
    settingsDirectoriesNaimixFederalMigrationProgressActionSelector,
    settingsDirectoriesNaimixFederalMigrationProgressCardSelector,
} from "../../../ducks/bff/settings/directories/naimix/federal-migration/selectors";
import {
    dadataGeoDataSelector,
    getAddressByCoords,
    getCoordsByAddress,
    updateGeoDataStore,
} from "../../../ducks/dadata";
import {
    getFederalRegionDictSelector,
    getFederalRegionOptionsSelector,
} from "../../../ducks/federalMigration";

import PropTypes from "prop-types";

import "./style.sass";

class DepartmentFederalMigrationCard extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        federalId: PropTypes.string,
    };

    static defaultProps = {
        handleClose: () => {},
        federalId: "",
    };

    constructor(props) {
        super(props);
        const {federalId, t} = props;

        this.state = {
            formError: {},
            addressError: null,
            federalMigration: {
                region: "MSK",
            },
        };

        this.title = federalId ? t("department-federal-migration-new.edit-title") : t("department-federal-migration-new.new-title");
        this.nameActionBtn = federalId ? t("button.save") : t("button.add");
    }

    componentDidMount() {
        const {federalId} = this.props;

        if (federalId) {
            this.fetchMigration();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            dadataGeoData,
        } = this.props;
        const {
            dadataGeoData: _dadataGeoData,
        } = prevProps;

        if (!isEqual(dadataGeoData, _dadataGeoData) && !isEmpty(dadataGeoData)) {
            const [
                {
                    result: address,
                    region,
                    geo_lat: latitude,
                    geo_lon: longitude,
                },
            ] = dadataGeoData;

            if (!region || !latitude || !longitude) {
                return;
            }

            const regionId = getKeyFromMigrationDictByDadata(region, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                federalMigration: {
                    ...prevState.federalMigration,
                    latitude,
                    longitude,
                    address,
                    region: regionId,
                },
            }));
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {federalMigration} = state;
        const {card} = props;

        if (!federalMigration.latitude && card.longitude !== federalMigration.longitude) {
            return {
                ...state,
                federalMigration: {
                    ...state.federalMigration,
                    ...card,
                },
                mapState: {
                    ...state.mapState,
                    center: [card.latitude, card.longitude],
                },
            };
        }

        return null;
    }

    componentWillUnmount() {
        const {
            federalId,
            updateGeoDataStore,
            updateFieldsDirectoriesFederalMigration,
        } = this.props;

        if (federalId) {
            updateFieldsDirectoriesFederalMigration({
                card: {},
            });
        }
        updateGeoDataStore({
            dadataGeoData: [{}],
            nominatimGeoData: {},
        });
    }

    fetchAddressByCoords = ({lat, lng}) => {
        const {getAddressByCoords} = this.props;

        const onSuccess = (result) => {
            const {
                address: {
                    state,
                },
            } = result;

            const address = getAddress(result);
            const regionId = getKeyFromMigrationDictByDadata(state, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                federalMigration: {
                    ...prevState.federalMigration,
                    latitude: lat,
                    longitude: lng,
                    address,
                    region: regionId,
                },
            }));
        };

        getAddressByCoords({
            lat,
            lng,
            onSuccess,
        });
    };

    fetchCoordsByAddress = (address) => {
        const {getCoordsByAddress} = this.props;

        getCoordsByAddress([address]);
    };

    fetchMigration = () => {
        const {getDirectoriesFederalMigrationCard, federalId} = this.props;

        getDirectoriesFederalMigrationCard({
            federalId,
        });
    };

    updateForm = data => {
        this.setState(prevState => ({
            ...prevState,
            federalMigration: {
                ...prevState.federalMigration,
                ...data,
            },
        }));
    };

    handleOnChangeAddress(name) {
        return (data) => {
            if (data.data) {
                const {federalRegionDict} = this.props;
                const region = data.data.region;

                this.setState(prevState => ({
                    ...prevState,
                    federalMigration: {
                        ...prevState.federalMigration,
                        address: data.value,
                        region: getKeyFromMigrationDictByDadata(region, federalRegionDict),
                    },
                }));
            }
        };
    }

    handleChange = (event, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            federalMigration: {
                ...prevState.federalMigration,
                [name]: value,
            },
        }));
    };

    validationForm(formData, rule) {
        const errorMessage = validate(formData, rule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    isValidAddress = () => {
        const {federalMigration: {latitude}, addressError} = this.state;

        if (!latitude) {
            this.setState({
                addressError: "Введите существующий адрес",
            });

            return false;
        }

        if (addressError && latitude) {
            this.setState({
                addressError: null,
            });

            return true;
        }

        return true;
    };

    handleAdd = () => {
        const {
            addDirectoriesFederalMigration,
            updateDirectoriesFederalMigration,
            federalId,
            handleClose,
        } = this.props;

        const {federalMigration} = this.state;

        const isValidForm = this.validationForm(federalMigration, federalMigrationRule);

        if (!isValidForm || !this.isValidAddress()) {
            return;
        }

        if (federalId) {
            updateDirectoriesFederalMigration({...federalMigration, federalId});
        } else {
            addDirectoriesFederalMigration(federalMigration);
        }

        handleClose();
    };

    renderMap() {
        const {
            federalMigration: {
                latitude,
                longitude,
                address,
            },
        } = this.state;

        return (
            <OpenStreetMap
                className="department-federal-migration-new__map"
                center={[55.75, 37.57]}
                zoom={9}
                latitude={latitude}
                longitude={longitude}
                tooltipContent={address}
                fetchAddressByCoords={this.fetchAddressByCoords}
                updateForm={this.updateForm}
            />
        );
    }

    renderActions(t) {
        const {handleClose, progressAction} = this.props;

        return (
            <ApplyButtons
                onClose={handleClose}
                submitBtnContent={this.nameActionBtn}
                cancelBtnContent={t("button.cancel")}
                submit={this.handleAdd}
                loading={progressAction}
                disabled={progressAction}
                mobile="column"
                isHiddenCancelOnMobile
            />
        );
    }

    render() {
        const {addressError, formError, federalMigration} = this.state;
        const {
            handleClose,
            federalRegionsOptions,
            progressById,
            t,
            card,
        } = this.props;

        return (
            <NmModal
                className="department-federal-migration-new"
                onClose={handleClose}
                header={
                    <NmTitle size="lg">
                        {this.title}
                    </NmTitle>
                }
                footer={this.renderActions(t)}
                loading={progressById}
                loaderInverted
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6">
                            <NmForm addMargin>
                                <NmInputV2
                                    size="xl"
                                    label={t("department-federal-migration-new.name")}
                                    required
                                    error={formError[FEDERAL_MIGRATION_FIELD.NAME_DEPARTMENT]}
                                    name={FEDERAL_MIGRATION_FIELD.NAME_DEPARTMENT}
                                    value={federalMigration[FEDERAL_MIGRATION_FIELD.NAME_DEPARTMENT] || ""}
                                    className="fluid department-federal-migration-row flex-column"
                                    placeholder={t("department-federal-migration-new.name")}
                                    onChange={this.handleChange}
                                />
                                <NmDadataInput
                                    required
                                    label={t("department-federal-migration-new.address")}
                                    query={federalMigration[FEDERAL_MIGRATION_FIELD.ADDRESS] || ""}
                                    error={formError[FEDERAL_MIGRATION_FIELD.ADDRESS] || addressError}
                                    name={FEDERAL_MIGRATION_FIELD.ADDRESS}
                                    onChange={this.handleOnChangeAddress(FEDERAL_MIGRATION_FIELD.ADDRESS)}
                                    placeholder={t("department-federal-migration-new.address")}
                                    onBlur={(value) => {
                                        this.fetchCoordsByAddress(value);
                                    }}
                                />
                                <NmDropdownV2
                                    size="xl"
                                    label={t("department-federal-migration-new.region")}
                                    required
                                    options={federalRegionsOptions}
                                    name={FEDERAL_MIGRATION_FIELD.REGION}
                                    error={formError[FEDERAL_MIGRATION_FIELD.REGION]}
                                    value={federalMigration[FEDERAL_MIGRATION_FIELD.REGION]}
                                    className="department-federal-migration-new__select department-federal-migration-new-row"
                                    placeholder={t("department-federal-migration-new.region-placeholder")}
                                    onChange={this.handleChange}
                                />
                            </NmForm>
                        </div>
                        <div className="col-xl-10">
                            {this.renderMap()}
                        </div>
                    </div>
                </div>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        card: settingsDirectoriesNaimixFederalMigrationCardSelector(state),
        federalRegionsOptions: getFederalRegionOptionsSelector(state),
        federalRegionDict: getFederalRegionDictSelector(state),
        progressAction: settingsDirectoriesNaimixFederalMigrationProgressActionSelector(state),
        progressById: settingsDirectoriesNaimixFederalMigrationProgressCardSelector(state),
        dadataGeoData: dadataGeoDataSelector(state),
    }), {
        getDirectoriesFederalMigrationCard,
        updateFieldsDirectoriesFederalMigration,
        updateDirectoriesFederalMigration,
        addDirectoriesFederalMigration,
        getAddressByCoords,
        getCoordsByAddress,
        updateGeoDataStore,
    },
)(withTranslation()(DepartmentFederalMigrationCard));