import React, {useEffect, useState} from "react";
import Highlighter from "react-highlight-words";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {RECRUITMENT_TEXT_NO_PARAM} from "../../../constants/recruitment";
import {
    bffRecruitmentDirectoryMessageTemplateSearchWordsSelector,
} from "../../../ducks/bff/recruitment/directory/selectors";
import {
    getRecruitmentMessageTemplates,
    updateRecruitmentVacancyCandidatesStore,
} from "../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentCandidatesMessageTemplatesSelector,
    bffRecruitmentCandidatesProgressMessageTemplatesSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import HelpTooltip from "../../ActualComponents/HelpTooltip";
import {IconButton} from "../../ActualComponents/IconButton";
import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";
import NmForm from "../../ActualComponents/NmForm";
import NmModal from "../../ActualComponents/NmModal";
import NmTextareaV2 from "../../ActualComponents/NmTextareaV2";
import Text from "../../ActualComponents/Text";
import TextWithTooltip from "../../ActualComponents/TextWithTooltip";
import ApplyButtons from "../../ApplyButtons";
import NmTitle from "../../NmTitle";

import {openLinkByUrl} from "../../../utils/downloadBlob";

import {COLOR} from "../../../constants/color";

export const RecruitmentTemplateMessage = (props) => {
    const {
        name,
        fio,
        onClose,
        url,
        clientId,
        vacancyId,
        responseId,
        candidateId,
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(bffRecruitmentCandidatesMessageTemplatesSelector);
    const searchWords = useSelector(bffRecruitmentDirectoryMessageTemplateSearchWordsSelector);
    const progress = useSelector(bffRecruitmentCandidatesProgressMessageTemplatesSelector);

    const [isOpen, setOpen] = useState(false);

    const {
        setValues,
        values,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: {
            tempText: "",
            text: "",
            templateId: "",
        },
        onSubmit,
    });

    const isError = values.text.toLowerCase().includes(RECRUITMENT_TEXT_NO_PARAM);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyCandidatesStore({
                messageTemplates: [],
            }));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(options) || values.templateId) {
            return;
        }

        const [firstOption] = options;

        const {
            value: templateId,
            templateText,
        } = firstOption;

        setValues({
            ...values,
            templateId,
            tempText: templateText,
            text: templateText,
        });

    }, [options]);

    const onSearchChange = (name) => {
        dispatch(getRecruitmentMessageTemplates({
            clientId,
            vacancyId,
            responseId,
            candidateId,
            name,
            pageNum: 1,
            pageSize: 25,
        }));
    };

    function onSubmit() {
        copy(values.text);
        openLinkByUrl(url);
    }

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {`${name}: ${fio || "Нет данных"}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent={`Копировать и перейти в ${name}`}
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
            loading={progress}
        >
            <NmForm addMargin={true}>
                <NmDropdownV2
                    size="xl"
                    search={true}
                    label="Название шаблона"
                    name="templateId"
                    options={options}
                    onSearchChange={onSearchChange}
                    value={values.templateId}
                    onChange={(_event, {value, templateText}) => {
                        setValues({
                            tempText: templateText,
                            text: templateText,
                            templateId: value,
                        });
                    }}
                />
                <div className="d-flex align-items-center justify-content-between">
                    <TextWithTooltip
                        level="2"
                        type="sub"
                        medium={true}
                        children="Содержание"
                        tooltipProps={
                            isError
                                ? {
                                    text: "Не удалось получить значение параметра. Вы можете отредактировать сообщение",
                                }
                                : undefined
                        }
                    />
                    {
                        isOpen ?
                            <div className="d-flex">
                                <IconButton
                                    icon={CloseIcon}
                                    width={24}
                                    height={24}
                                    className="ms-2"
                                    onClick={() => {
                                        setOpen(false);
                                        setValues({
                                            ...values,
                                            tempText: values.text,
                                        });
                                    }}
                                />
                                <IconButton
                                    icon={DoneIcon}
                                    width={24}
                                    height={24}
                                    className="ms-2"
                                    onClick={() => {
                                        setOpen(false);
                                        setFieldValue("text", values.tempText);
                                    }}
                                />
                            </div> :
                            <IconButton
                                icon={ModeIcon}
                                width={24}
                                height={24}
                                className="ms-2"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            />
                    }
                </div>
                {
                    isOpen ?
                        <NmTextareaV2
                            placeholder="Введите обязанности"
                            minRows={3}
                            maxLength="4000"
                            name="description"
                            value={values.tempText}
                            onChange={(_event, {value}) => {
                                setFieldValue("tempText", value);
                            }}
                            tooltip={
                                <HelpTooltip
                                    hover={true}
                                    info={true}
                                    children="Не удалось получить значение параметра. Вы можете отредактировать сообщение."
                                />
                            }
                        /> :
                        <Text
                            color={COLOR.SECONDARY_90}
                            level="4"
                        >
                            <Highlighter
                                highlightStyle={{
                                    background: "unset",
                                    color: COLOR.NEGATIVE_100,
                                }}
                                searchWords={searchWords}
                                textToHighlight={values.text}
                            />
                        </Text>
                }
            </NmForm>
        </NmModal>
    );
};