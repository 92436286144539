export const CONTRACTOR_RESOURCES_GET_PAGE_REQUEST = "CONTRACTOR_RESOURCES_GET_PAGE_REQUEST";
export const CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS = "CONTRACTOR_RESOURCES_GET_PAGE_SUCCESS";
export const CONTRACTOR_RESOURCES_GET_PAGE_ERROR = "CONTRACTOR_RESOURCES_GET_PAGE_ERROR";

export const CONTRACTOR_RESOURCES_EXPORT_REQUEST = "CONTRACTOR_RESOURCES_EXPORT_REQUEST";
export const CONTRACTOR_RESOURCES_EXPORT_SUCCESS = "CONTRACTOR_RESOURCES_EXPORT_SUCCESS";
export const CONTRACTOR_RESOURCES_EXPORT_ERROR = "CONTRACTOR_RESOURCES_EXPORT_ERROR";

export const CONTRACTOR_RESOURCES_START_JOB_REQUEST = "CONTRACTOR_RESOURCES_START_JOB_REQUEST";
export const CONTRACTOR_RESOURCES_START_JOB_SUCCESS = "CONTRACTOR_RESOURCES_START_JOB_SUCCESS";
export const CONTRACTOR_RESOURCES_START_JOB_ERROR = "CONTRACTOR_RESOURCES_START_JOB_ERROR";

export const CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST = "CONTRACTOR_RESOURCES_GET_CLIENTS_REQUEST";
export const CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS = "CONTRACTOR_RESOURCES_GET_CLIENTS_SUCCESS";
export const CONTRACTOR_RESOURCES_GET_CLIENTS_ERROR = "CONTRACTOR_RESOURCES_GET_CLIENTS_ERROR";

export const CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST = "CONTRACTOR_RESOURCES_GET_PROJECTS_REQUEST";
export const CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS = "CONTRACTOR_RESOURCES_GET_PROJECTS_SUCCESS";
export const CONTRACTOR_RESOURCES_GET_PROJECTS_ERROR = "CONTRACTOR_RESOURCES_GET_PROJECTS_ERROR";

export const CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST = "CONTRACTOR_RESOURCES_GET_OBJECTS_REQUEST";
export const CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS = "CONTRACTOR_RESOURCES_GET_OBJECTS_SUCCESS";
export const CONTRACTOR_RESOURCES_GET_OBJECTS_ERROR = "CONTRACTOR_RESOURCES_GET_OBJECTS_ERROR";

export const UPDATE_CONTRACTOR_RESOURCES_STORE = "UPDATE_CONTRACTOR_RESOURCES_STORE";