import {
    CLEAR_CONTRACTOR_NOTIFICATION_LOG_STORE,
    CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_ERROR,
    CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_REQUEST,
    CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_SUCCESS,
} from "./actions";

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_SUCCESS:
            const {
                results = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case CONTRACTOR_NOTIFICATION_LOG_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case CLEAR_CONTRACTOR_NOTIFICATION_LOG_STORE:
            return initial;
        default:
            return state;
    }
};