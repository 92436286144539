export const GET_PAGE_KEYWORDS_REQUEST = "GET_PAGE_KEYWORDS_REQUEST";
export const GET_PAGE_KEYWORDS_SUCCESS = "GET_PAGE_KEYWORDS_SUCCESS";
export const GET_PAGE_KEYWORDS_ERROR = "GET_PAGE_KEYWORDS_ERROR";

export const ADD_KEYWORD_REQUEST = "ADD_KEYWORD_REQUEST";
export const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS";
export const ADD_KEYWORD_ERROR = "ADD_KEYWORD_ERROR";

export const UPDATE_KEYWORD_REQUEST = "UPDATE_KEYWORD_REQUEST";
export const UPDATE_KEYWORD_SUCCESS = "UPDATE_KEYWORD_SUCCESS";
export const UPDATE_KEYWORD_ERROR = "UPDATE_KEYWORD_ERROR";

export const DELETE_KEYWORD_REQUEST = "DELETE_KEYWORD_REQUEST";
export const DELETE_KEYWORD_SUCCESS = "DELETE_KEYWORD_SUCCESS";
export const DELETE_KEYWORD_ERROR = "DELETE_KEYWORD_ERROR";

export const CLEAR_FIELD_KEYWORDS = "CLEAR_FIELD_KEYWORDS";