import {useState} from "react";
import {useDispatch} from "react-redux";

import {updateContractorFamilyStatus} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultFamilyStatusForm = {
    status: "",
};

export default function useUpdateFamilyStatus({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultFamilyStatusForm});
    const dispatch = useDispatch();

    const {
        status,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const submit = () => {
        dispatch(updateContractorFamilyStatus({
            contractorId,
            status,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
    };
}