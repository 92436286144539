import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    REVIEWS_CLIENT_CONFIRM_ERROR,
    REVIEWS_CLIENT_CONFIRM_REQUEST,
    REVIEWS_CLIENT_CONFIRM_SUCCESS,
    REVIEWS_CLIENT_DECLINE_ERROR,
    REVIEWS_CLIENT_DECLINE_REQUEST,
    REVIEWS_CLIENT_DECLINE_SUCCESS,
    REVIEWS_CLIENT_DELETE_ERROR,
    REVIEWS_CLIENT_DELETE_REQUEST,
    REVIEWS_CLIENT_DELETE_SUCCESS,
    REVIEWS_CLIENT_GET_COUNT_ERROR,
    REVIEWS_CLIENT_GET_COUNT_REQUEST,
    REVIEWS_CLIENT_GET_COUNT_SUCCESS,
    REVIEWS_CLIENT_GET_PAGE_ERROR,
    REVIEWS_CLIENT_GET_PAGE_REQUEST,
    REVIEWS_CLIENT_GET_PAGE_SUCCESS,
    REVIEWS_CLIENT_REPLY_ADD_ERROR,
    REVIEWS_CLIENT_REPLY_ADD_REQUEST,
    REVIEWS_CLIENT_REPLY_ADD_SUCCESS,
    REVIEWS_CLIENT_REPLY_STATUS_UPDATE_ERROR,
    REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST,
    REVIEWS_CLIENT_REPLY_STATUS_UPDATE_SUCCESS,
    REVIEWS_CLIENT_REPLY_UPDATE_ERROR,
    REVIEWS_CLIENT_REPLY_UPDATE_REQUEST,
    REVIEWS_CLIENT_REPLY_UPDATE_SUCCESS,
    REVIEWS_CLIENT_SEND_COMPLAINT_ERROR,
    REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST,
    REVIEWS_CLIENT_SEND_COMPLAINT_SUCCESS,
    REVIEWS_CLIENT_UPDATE_ERROR,
    REVIEWS_CLIENT_UPDATE_REQUEST,
    REVIEWS_CLIENT_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {LINK_REVIEWS_LIST} from "../../../../constants/links";

import {SUBPAGE_CLIENTS_REVIEWS} from "../../../../constants/link-params";

const adminPageLink = LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CLIENTS_REVIEWS);
const adminPageController = "/adm/review/review-on-client";

const getController = () => {
    return (
        getBffUrl({
            [adminPageLink]: adminPageController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/reviews",
            adminRolesUrl: "/adm/clients/client-card/client/reviews",
        }, adminPageController)
    );
};

const getPageReviewsClientSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: REVIEWS_CLIENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};


const getCountReviewsClientSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${adminPageController}/count`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_GET_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: REVIEWS_CLIENT_GET_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_GET_COUNT_ERROR,
            payload: error,
        });
    }
};

const confirmReviewsClientSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/confirm/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_CONFIRM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_CONFIRM_ERROR,
            payload: error,
        });
    }
};

const declineReviewsClientSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/decline/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_DECLINE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_DECLINE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_DECLINE_ERROR,
            payload: error,
        });
    }
};

const deleteReviewsClientSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_DELETE_ERROR,
            payload: error,
        });
    }
};

const updateReviewsClientSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_UPDATE_ERROR,
            payload: error,
        });
    }
};

const updateStatusReviewsClientReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${reviewId}/reply/change-status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_REPLY_STATUS_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_REPLY_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_REPLY_STATUS_UPDATE_ERROR,
            payload: error,
        });
    }
};

const updateReviewsClientReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${reviewId}/reply/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_REPLY_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: REVIEWS_CLIENT_REPLY_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_REPLY_UPDATE_ERROR,
            payload: error,
        });
    }
};

const addReviewsClientReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${reviewId}/reply/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_REPLY_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({type: REVIEWS_CLIENT_REPLY_ADD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REVIEWS_CLIENT_REPLY_ADD_ERROR, payload: error});
    }
};

const sendComplaintReviewsClientSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/sendComplaintOnContractorReview/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REVIEWS_CLIENT_SEND_COMPLAINT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        toastSuccess("Жалоба успешно подана и будет рассмотрена в ближайшее время.");

        yield put({
            type: REVIEWS_CLIENT_SEND_COMPLAINT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REVIEWS_CLIENT_SEND_COMPLAINT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REVIEWS_CLIENT_GET_PAGE_REQUEST, getPageReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_GET_COUNT_REQUEST, getCountReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_CONFIRM_REQUEST, confirmReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_DECLINE_REQUEST, declineReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_DELETE_REQUEST, deleteReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_UPDATE_REQUEST, updateReviewsClientSaga),
        takeEvery(REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST, updateStatusReviewsClientReplySaga),
        takeEvery(REVIEWS_CLIENT_REPLY_UPDATE_REQUEST, updateReviewsClientReplySaga),
        takeEvery(REVIEWS_CLIENT_REPLY_ADD_REQUEST, addReviewsClientReplySaga),
        takeEvery(REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST, sendComplaintReviewsClientSaga),
    ]);
}