import {
    BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST,
    CLEAR_CLIENT_REPORTS_ONE_C_STORE,
} from "./actions";

export const getPageClientReportsOneC = (payload) => {
    return {
        type: BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST,
        payload,
    };
};

export const getTasksProgressClientReportsOneC = (payload) => {
    return {
        type: BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST,
        payload,
    };
};

export const addClientReportOneC = (payload) => {
    return {
        type: BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST,
        payload,
    };
};

export const cancelClientReportOneC = (payload) => {
    return {
        type: BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST,
        payload,
    };
};

export const clearStoreClientReportsOneC = (payload) => {
    return {
        type: CLEAR_CLIENT_REPORTS_ONE_C_STORE,
        payload,
    };
};