import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";

import bem from "../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import validate from "../../../../utils/validate";

import {isClientUser} from "../../../../constants/roles";
import {promocodeRule} from "../../../../constants/validationRules";

import {
    checkPromocodeContractorCard,
    getPromocodeByContractorId,
    registrationPromocodeContractorCard,
} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {
    contractorCardInfoSelector,
    contractorCardProgressActionSelector,
} from "../../../../ducks/bff/contractor-сard/selectors";

import PropTypes from "prop-types";

import "./style.sass";

const PromocodeInfo = (props) => {
    const {
        className,
    } = props;

    const role = ls(USER_ROLE);

    const [block] = bem("promocode-info", className);

    const actionProgress = useSelector(contractorCardProgressActionSelector);

    const [promocodeInitial, setPromocodeInitial] = useState("");
    const [promocode, setPromocode] = useState("");
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState(null);

    const {contractorId, archived} = useSelector(contractorCardInfoSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if(contractorId){
            fetchPromocode();
        }
    }, [contractorId]);

    const fetchPromocode = () => {
        dispatch(
            getPromocodeByContractorId({
                contractorId,
                handleResponse: result => {
                    setPromocode(result);
                    setPromocodeInitial(result);
                },
            }));
    };

    const isValidForm = () => {
        const error = validate({promocode}, promocodeRule, "");

        setError(error.promocode);

        return Object.values(error).length === 0;
    };

    const handleChange = (e, {value}) => {
        setPromocode(value);
    };

    const handleCancelEdit = () => {
        setEdit(false);
        setPromocode(promocodeInitial);
        setError(null);
    };

    const onRegPromocodeSuccess = () => {
        fetchPromocode();
        setEdit(false);
    };

    const registrationPromocode = () => {
        dispatch(
            registrationPromocodeContractorCard({
                contractorId,
                promocode,
                handleResponse: () => {
                    onRegPromocodeSuccess();
                },
            }),
        );
    };

    const handleCheck = () => {
        if (!isValidForm()) {
            return;
        }

        dispatch(
            checkPromocodeContractorCard({
                contractorId,
                promocode,
                handleResponse: (result) => {
                    const {errorMessage} = result;

                    setError(errorMessage || undefined);

                    if (errorMessage) {
                        return;
                    }

                    registrationPromocode();
                },
            }),
        );
    };

    const renderForm = () => (
        <NmForm>
            <NmInputV2
                size="xl"
                error={error}
                name="promocode"
                label="Если у Вас есть промо-код, то Вы можете ввести его в поле ниже"
                placeholder="Введите промо-код"
                onChange={handleChange}
                value={promocode}
            />
        </NmForm>
    );

    const edit = !isClientUser(role) && !archived ? (
        <ModeIcon
            onClick={() => {setEdit(true);}}
            className="promocode-info__edit-icon"
        />
    ) : null;

    return (
        <NmLabelText
            label="Промокод"
            type="page"
            className={block()}
            text={
                <div className="d-flex">
                    <div>
                        {promocode || "-"}
                    </div>
                    {edit}
                    {
                        isEdit &&
                        <NmModal
                            size="md"
                            header={
                                <NmTitle size="md">
                                    Промокод
                                </NmTitle>
                            }
                            onClose={handleCancelEdit}
                            footer={
                                <ApplyButtons
                                    mobile="column"
                                    cancelBtnContent="Отменить"
                                    onClose={handleCancelEdit}
                                    disabled={actionProgress}
                                    submitBtnContent="Активировать"
                                    submit={handleCheck}
                                />
                            }
                        >
                            {renderForm()}
                        </NmModal>
                    }
                </div>
            }
        />
    );
};

PromocodeInfo.propTypes = {
    className: PropTypes.string,
};

export default PromocodeInfo;
