import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageExportDocuments,
    updateStoreExportDocuments,
} from "../../../ducks/bff/export/documents/actionCreators";

export function useFetchList({pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreExportDocuments({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum]);

    const fetchList = () => {
        const requestData = {
            pageNum,
            pageSize,
        };

        dispatch(getPageExportDocuments(requestData));
    };

    return {
        fetchList,
    };
}