import {
    KEDO_DIRECTORIES_ADD_POSITION_ERROR,
    KEDO_DIRECTORIES_ADD_POSITION_REQUEST,
    KEDO_DIRECTORIES_ADD_POSITION_SUCCESS,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_POSITION_ERROR,
    KEDO_DIRECTORIES_DELETE_POSITION_REQUEST,
    KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_ERROR,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_SUCCESS,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST,
    KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_SUCCESS,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_POSITION_ERROR,
    KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_STORE,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS,
} from "./actions";

export const getKedoDocumentsInitialState = () => {
    return {
        uploadDocumentTypes: {},
        templateDocumentTypes: {},
        positions: {},
        subdivisions: {},
        documentRoutes: {},
        progressAction: false,
        counts: {},
        clientUsage: {},
        details: {},
        progressDetails: false,
        error: null,
    };
};

const initialState = getKedoDocumentsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST:
            return {
                ...state,
                clientUsage: {
                    ...state.clientUsage,
                    progress: false,
                    pageData: payload,
                },
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR:
            return {
                ...state,
                clientUsage: {
                    ...state.clientUsage,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS:
            return {
                ...state,
                clientUsage: {
                    ...state.clientUsage,
                    list: payload.results,
                    totalCount: payload.totalCount,
                },
            };
        case KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS:
            return {
                ...state,
                counts: payload,
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR:
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS:
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR:
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS:
        case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR:
        case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS:
        case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR:
        case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS:
        case KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR:
        case KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS:
        case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR:
        case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS:
        case KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR:
        case KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS:
        case KEDO_DIRECTORIES_DELETE_POSITION_ERROR:
        case KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS:
        case KEDO_DIRECTORIES_UPDATE_POSITION_ERROR:
        case KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS:
        case KEDO_DIRECTORIES_ADD_POSITION_ERROR:
        case KEDO_DIRECTORIES_ADD_POSITION_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_SUCCESS:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_ERROR:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_ERROR:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_ERROR:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ERROR:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_ERROR:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST:
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST:
        case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST:
        case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST:
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST:
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST:
        case KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST:
        case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST:
        case KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST:
        case KEDO_DIRECTORIES_DELETE_POSITION_REQUEST:
        case KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST:
        case KEDO_DIRECTORIES_ADD_POSITION_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST:
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST:
            return {
                ...state,
                positions: {
                    ...state.positions,
                    progress: true,
                    pageData: payload,
                },
            };
        case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS:
            return {
                ...state,
                positions: {
                    ...state.positions,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR:
            return {
                ...state,
                uploadDocumentTypes: {
                    ...state.uploadDocumentTypes,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR:
            return {
                ...state,
                positions: {
                    ...state.positions,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST:
            return {
                ...state,
                subdivisions: {
                    ...state.subdivisions,
                    progress: true,
                    pageData: payload,
                },
            };
        case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS:
            return {
                ...state,
                subdivisions: {
                    ...state.subdivisions,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR:
            return {
                ...state,
                subdivisions: {
                    ...state.subdivisions,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    progress: true,
                    pageData: payload,
                },
            };
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST:
            return {
                ...state,
                uploadDocumentTypes: {
                    ...state.uploadDocumentTypes,
                    progress: true,
                    pageData: payload,
                },
            };
        case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS:
            return {
                ...state,
                templateDocumentTypes: {
                    ...state.templateDocumentTypes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS:
            return {
                ...state,
                uploadDocumentTypes: {
                    ...state.uploadDocumentTypes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST:
            return {
                ...state,
                documentRoutes: {
                    ...state.documentRoutes,
                    pageData: payload,
                    progress: true,
                },
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_SUCCESS:
            return {
                ...state,
                documentRoutes: {
                    ...state.documentRoutes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_ERROR:
            return {
                ...state,
                documentRoutes: {
                    ...state.uploadDocumentTypes,
                    progress: false,
                    error: payload,
                },
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST:
            return {
                ...state,
                progressDetails: true,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_SUCCESS:
            return {
                ...state,
                progressDetails: false,
                details: payload.model,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_ERROR:
            return {
                ...state,
                progressDetails: false,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST:
            return {
                ...state,
                progressDetails: true,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_SUCCESS:
            return {
                ...state,
                progressDetails: false,
                details: payload.model,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_ERROR:
            return {
                ...state,
                progressDetails: false,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST:
            return {
                ...state,
                progressDocumentRouteTypes: true,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_SUCCESS:
            return {
                ...state,
                documentRouteTypesInfo: payload,
                progressDocumentRouteTypes: false,
            };
        case KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_ERROR:
            return {
                ...state,
                progressDocumentRouteTypes: false,
            };
        case KEDO_DIRECTORIES_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};