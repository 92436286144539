import {createSelector} from "reselect";

import {CHANGES_NEW_MAIL_ATTRIBUTE_NAME} from "../../../../containers/contractor/contractor-event-log/utils/getLabels";
import {getTotalPages} from "../../../../utils/mathHelper";

export const contractorCardEventLogSelector = state => state.bff.contractorCardEventLog;
export const contractorCardEventLogListSelector  = createSelector(contractorCardEventLogSelector, ({list}) => {
    return list.map(item => {
        if (item.changes) {
            return {
                ...item,
                isNewEmailEvent: item.changes.some(item => item.attribute.includes(CHANGES_NEW_MAIL_ATTRIBUTE_NAME)),
                changes: {
                    newValues: item.changes.map(item => {
                        return {
                            name: item.attribute,
                            value: item.newValue,
                        };
                    }),
                    oldValues: item.changes.map(item => {
                        return {
                            name: item.attribute,
                            value: item.oldValue,
                        };
                    }),
                },
            };
        }

        return {
            ...item,
            changes: null,
        };
    });
});
export const contractorCardEventLogTotalCountSelector  = createSelector(contractorCardEventLogSelector, ({totalCount}) => totalCount);
export const contractorCardEventLogTotalPagesSelector  = createSelector(contractorCardEventLogSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const contractorCardEventLogProgressSelector  = createSelector(contractorCardEventLogSelector, ({progress}) => progress);