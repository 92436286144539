export const SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR";

export const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST";
export const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS";
export const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR";

export const REFRESH_DIRECTORIES_SBP_BANKS_REQUEST = "REFRESH_DIRECTORIES_SBP_BANKS_REQUEST";
export const REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS = "REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS";
export const REFRESH_DIRECTORIES_SBP_BANKS_ERROR = "REFRESH_DIRECTORIES_SBP_BANKS_ERROR";

export const SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST = "SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST";
export const SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS = "SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS";
export const SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR = "SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR";

export const DIRECTORIES_BANKS_GET_SETTINGS_REQUEST = "DIRECTORIES_BANKS_GET_SETTINGS_REQUEST";
export const DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS = "DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS";
export const DIRECTORIES_BANKS_GET_SETTINGS_ERROR = "DIRECTORIES_BANKS_GET_SETTINGS_ERROR";

export const DIRECTORIES_BANKS_SET_SETTINGS_REQUEST = "DIRECTORIES_BANKS_SET_SETTINGS_REQUEST";
export const DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS = "DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS";
export const DIRECTORIES_BANKS_SET_SETTINGS_ERROR = "DIRECTORIES_BANKS_SET_SETTINGS_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS";
export const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR";

export const SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE = "SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE";