import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearClientsMonitoringRisksStore,
    getClientsMonitoringRisks,
} from "../../../../ducks/bff/clients/monitoring-risk/actionCreators";

export function useClientMonitoringRiskFetchList(props) {
    const {
        clientId,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();

        return () => {
            dispatch(clearClientsMonitoringRisksStore());
        };
    }, []);

    function fetchList() {
        dispatch(getClientsMonitoringRisks({clientId}));
    }

    return {
        fetchList,
    };
}