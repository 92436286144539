import React from "react";

import {ReactComponent as NoOnIcon} from "../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../images/yes_on.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text, {TTextLevel} from "../ActualComponents/Text";

import bem from "../../utils/bem";

import {COLOR} from "../../constants/color";

import "./style.sass";

interface IPaymentPossibleV2 {
    value: boolean,
    className?: string,
    textClassName?: string;
    size?: "md" | "lg",
    showLabel?: boolean,
    isIndividualEntrepreneur?: boolean,
    level?: TTextLevel,
    textMedium?: boolean,
}

const InstrumentPaymentIndicator = (props: IPaymentPossibleV2) => {
    const {
        value,
        className = "",
        size = "md",
        showLabel = true,
        level = "3",
        textClassName,
        isIndividualEntrepreneur = false,
        textMedium,
    } = props;

    const [block, element] = bem("instrument-payment-indicator", className);

    const getTooltipText = () => {
        if (value) {
            return (
                isIndividualEntrepreneur ?
                    "Исполнитель указа р/с ИП" :
                    "Исполнитель указал платежные данные"
            );
        }

        return "Исполнитель не указал платежные данные";
    };

    return (
        <div className={block()}>
            <NmAdvancedTooltip
                hover
                className={element("advanced-tooltip")}
                position="bottom-left"
                children={getTooltipText()}
                trigger={
                    value ?
                        <YesOnIcon
                            className={element("icon", {size})}
                            color={COLOR.PRIMARY_100}
                        /> :
                        <NoOnIcon
                            className={element("icon", {size})}
                            color={COLOR.NEGATIVE_100}
                        />
                }
            />
            {
                showLabel &&
                <Text
                    level={level}
                    className={`${element("text")} ${textClassName}`}
                    medium={textMedium}
                >
                    {
                        isIndividualEntrepreneur ?
                            "Платежные данные" :
                            "Средство платежа"
                    }
                </Text>
            }
        </div>
    );
};

export default InstrumentPaymentIndicator;