import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_TEXT,
} from "../../../../../constants/fcRegistries";

export const bffAdditionalAgreementsRegistrySelector = state => state.bff.documentsAdditionalAgreementsRegistry;
export const bffAdditionalAgreementsRegistryListSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({list}) => list,
);
export const bffAdditionalAgreementsRegistryPageDataSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({pageData}) => pageData,
);
export const bffAdditionalAgreementsRegistryTotalCountSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({totalCount}) => totalCount,
);
export const bffAdditionalAgreementsRegistryTotalPagesSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffAdditionalAgreementsRegistryProgressSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({progress}) => progress,
);
export const bffAdditionalAgreementsRegistryProgressActionSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({progressAction}) => progressAction,
);
export const bffAdditionalAgreementsRegistryStatusDictOptionsSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({statusDict}) => dictionaryToOptions(statusDict),
);
export const bffAdditionalAgreementsRegistryProgressCardSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({progressCard}) => progressCard,
);
export const bffAdditionalAgreementsRegistryCardSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({card}) => card,
);
export const bffAdditionalAgreementsRegistryInitialValuesSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({card}) => {
        return {
            documentTemplateId: card.documentTemplateId || "",
            name: card.name || "",
            commentary: card.commentary || "",
        };
    },
);
export const bffAdditionalAgreementsRegistryErrorSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({error}) => error,
);
export const bffAdditionalAgreementRegistryWarningsDataSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({warnings}) => {
        if (!warnings) {
            return;
        }

        const {
            itemDuplicatesPresent,
        } = warnings;

        if (itemDuplicatesPresent) {
            return {
                warning: true,
                content: FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_TEXT,
            };
        }
    },
);
export const bffAdditionalAgreementRegistryWarningsPageDataSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({warningsPageData}) => warningsPageData,
);
export const bffAdditionalAgreementRegistryContractorsSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({contractors}) => {
        return {
            ...contractors,
            totalPages: getTotalPages(contractors.totalCount, contractors.pageData.pageSize),
        };
    },
);
export const bffAdditionalAgreementRegistryFrameContractsOptionsSelector = createSelector(
    bffAdditionalAgreementsRegistrySelector,
    ({frameContracts}) => frameContracts.map(item => {
        return {
            key: item.documentId,
            value: item.documentId,
            text: item.name,
        };
    }),
);
