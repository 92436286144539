import {
    ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
} from "./actions";

export function getDirectoriesFederalMigrationPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
        payload,
    };
}

export function getDirectoriesFederalMigrationCard(payload) {
    return {
        type: GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
        payload,
    };
}

export function updateDirectoriesFederalMigration(payload) {
    return {
        type: UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
        payload,
    };
}

export function addDirectoriesFederalMigration(payload) {
    return {
        type: ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
        payload,
    };
}

export function deleteDirectoriesFederalMigration(payload) {
    return {
        type: DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
        payload,
    };
}

export function updateFieldsDirectoriesFederalMigration(payload) {
    return {
        type: UPDATE_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
        payload,
    };
}

export function clearFieldsDirectoriesFederalMigration() {
    return {
        type: CLEAR_FIELDS_DIRECTORIES_FEDERAL_MIGRATION,
    };
}