import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const settingsClientGroupsSelector = state => state.bff.settingsClientGroups;
export const settingsClientGroupsTotalPagesSelector = createSelector(settingsClientGroupsSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const settingsClientGroupsTotalCountSelector = createSelector(settingsClientGroupsSelector, ({totalCount}) => totalCount);
export const settingsClientGroupsListSelector = createSelector(settingsClientGroupsSelector, ({list}) => list);
export const settingsClientGroupsProgressSelector = createSelector(settingsClientGroupsSelector, ({progress}) => progress);
export const settingsClientGroupsProgressActionSelector = createSelector(settingsClientGroupsSelector, ({progressAction}) => progressAction);
export const settingsClientGroupsProgressRelatedUsersSelector = createSelector(settingsClientGroupsSelector, ({progressRelatedUsers}) => progressRelatedUsers);
export const settingsClientGroupsGroupMemberRelatedUsersListSelector = createSelector(settingsClientGroupsSelector, ({groupMemberRelatedUsersList}) => groupMemberRelatedUsersList);
export const settingsClientGroupsGroupMemberRelatedUsersListOptionsSelector = createSelector(settingsClientGroupsSelector, ({groupMemberRelatedUsersList}) => {
    return groupMemberRelatedUsersList.map((item) => {
        return {
            key: item.clientUserId,
            value: item.clientUserId,
            text: item.clientFullName,
        };
    });
});