import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmAdvancedTooltip from "../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../components/CheckboxList";
import ExtLink from "../../components/ExtLink";
import LogoThumbnail from "../../components/LogoThumbnail";
import NmBadge from "../../components/NmBadge";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import {ReactComponent as LogoIcon} from "../../images/company-logo.svg";
import ExportDocumentFilter from "./components/filter";

import {usePagination} from "../../hooks/usePagination";
import useAddExportDocument from "./hooks/useAddExportDocument";
import useDownloadDocument from "./hooks/useDownloadDocument";
import {useFetchList} from "./hooks/useFetchList";
import useProgressStatus from "./hooks/useProgressStatus";

import dateFormat, {convertUtcToLocal} from "../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../utils/localstorage";
import {phoneFormat} from "../../utils/stringFormat";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {DOCUMENT_EXPORT_STATUS} from "../../constants/documentsExport";
import {LINK_CLIENT_INFO} from "../../constants/links";
import {ADMIN} from "../../constants/roles";

import {
    bffExportDocumentsListSelector,
    bffExportDocumentsProgressSelector,
    bffExportDocumentsTotalCountSelector,
    bffExportDocumentsTotalPagesSelector,
} from "../../ducks/bff/export/documents/selectors";
import {cancelExportById} from "../../ducks/job";

import "./style.sass";

export const ADM_EXPORT_DOCUMENT_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    FINISHED: "FINISHED",
};

function ExportDocument() {
    const totalCount = useSelector(bffExportDocumentsTotalCountSelector);
    const totalPages = useSelector(bffExportDocumentsTotalPagesSelector);
    const list = useSelector(bffExportDocumentsListSelector);
    const loading = useSelector(bffExportDocumentsProgressSelector);

    const role = ls(USER_ROLE);
    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const [confirmData, setConfirmData] = useState({});

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {fetchList} = useFetchList({pageNum, pageSize});

    const {
        onChangeForm,
        addExportDocument,
        exportDocumentForm,
    } = useAddExportDocument(fetchList);

    const {
        progressList,
    } = useProgressStatus({
        list,
        fetchList,
    });

    const {downloadDocument} = useDownloadDocument();

    const renderDocuments = (item) => {
        const {
            exportedActsCount,
            exportedOrderApplicationsCount,
            exportedFrameContractsCount,
            exportedPaymentReportsCount,
            exportedOperatorReportsCount,
            exportedPartitionsCount,
            exportedChecksCount,
            exportedCivilFrameContractsCount,
            exportedPatentsCount,
            exportedReductionOnPersonalIncomeTaxesCount,
            exportedLoanContractsCount,
            exportedOvmStartsCount,
            exportedOvmEndsCount,
            exportedOtherDocumentsCount,
            exportedIndividualFrameContractsCount,
            exportedAdditionalAgreementsCount,
            exportedDocumentsTotalCount,
        } = item;

        return (
            <NmAdvancedTooltip
                type="light"
                position="bottom-left"
                trigger={<div className="export-document__label-count">
                    {exportedDocumentsTotalCount}
                </div>}
            >
                <div>
                    <div>
                        Договоров ИП:
                        {" "}
                        {exportedIndividualFrameContractsCount}
                    </div>
                    <div>
                        Договоров НПД:
                        {" "}
                        {exportedFrameContractsCount}
                    </div>
                    <div>
                        Договоров НДФЛ: 
                        {" "}
                        {exportedCivilFrameContractsCount}
                    </div>
                    <div>
                        Дополнительных соглашений:
                        {" "}
                        {exportedAdditionalAgreementsCount}
                    </div>
                    <div>
                        Заявок: 
                        {" "}
                        {exportedOrderApplicationsCount}
                    </div>
                    <div>
                        Актов: 
                        {" "}
                        {exportedActsCount}
                    </div>
                    <div>
                        Чеков: 
                        {" "}
                        {exportedChecksCount}
                    </div>
                    <div>
                        XML по оплатам: 
                        {" "}
                        {exportedPaymentReportsCount}
                    </div>
                    <div>
                        Отчетов оператора: 
                        {" "}
                        {exportedOperatorReportsCount}
                    </div>
                    <div>
                        Квитанций об оплате патентов: 
                        {" "}
                        {exportedPatentsCount}
                    </div>
                    <div>
                        Заявлений на уменьшение НДФЛ на сумму авансовых
                        платежей: 
                        {" "}
                        {exportedReductionOnPersonalIncomeTaxesCount}
                    </div>
                    <div>
                        Заявлений на выдачу досрочного аванса: 
                        {" "}
                        {exportedLoanContractsCount}
                    </div>
                    <div>
                        Уведомлений о начале работ: 
                        {" "}
                        {exportedOvmStartsCount}
                    </div>
                    <div>
                        Уведомлений о завершении работ: 
                        {" "}
                        {exportedOvmEndsCount}
                    </div>
                    <div>
                        Иных документов: 
                        {" "}
                        {exportedOtherDocumentsCount}
                    </div>
                    <div>
                        Итого: 
                        {" "}
                        {exportedDocumentsTotalCount}
                        {" "}
(
                        {exportedPartitionsCount}
                        {" "}
архивов)
                    </div>
                </div>
            </NmAdvancedTooltip>
        );
    };

    function renderStatus(item) {
        const {
            status,
            exportId,
        } = item;

        const {progressPercents} = progressList.find((item) => (item.exportId === exportId)) || {};

        const text = status === ADM_EXPORT_DOCUMENT_STATUS.IN_PROGRESS ?
            `${DOCUMENT_EXPORT_STATUS[status]?.TEXT} (${progressPercents || 0}%)` :
            DOCUMENT_EXPORT_STATUS[status]?.TEXT;

        return (
            <NmBadge
                noWrap
                mod={DOCUMENT_EXPORT_STATUS[status]?.MOD || "gray"}
                text={text}
            />
        );
    }

    const renderClientInfo = ({clientId, clientName, brand}) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        const brandValue = brand ? ` (${brand})` : "";

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {`${clientName}${brandValue}`}
            </ExtLink>
        );
    };

    const getMediaControls = (item) => {
        const {
            status,
            exportId,
            creatorClientUserId,
        } = item;

        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => downloadDocument(item),
                        color: "light-green",
                        children: "Скачать",
                    },
                    visible: status === ADM_EXPORT_DOCUMENT_STATUS.FINISHED,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () =>
                            setConfirmData({
                                content: "Вы уверены, что хотите отменить формирование документа?",
                                onConfirm: () => cancelExport(exportId),
                                confirmButton: "Да",
                                cancelButton: "Нет",
                            }),
                        color: "grey",
                        children: "Отменить",
                    },
                    visible: status === ADM_EXPORT_DOCUMENT_STATUS.IN_PROGRESS && ([ADMIN].includes(role) || creatorClientUserId === currentClientUserId),
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            const {
                exportId,
                periodStartDateTime,
                periodEndDateTime,
                createdDateTime,
                creatorClientUserName,
                base64Logo,
                contractorFio,
                contractorPhone,
                orderNum,
                orderName,
            } = item;

            return {
                ...item,
                key: exportId,
                avatar:
                    base64Logo ?
                        <LogoThumbnail
                            className="contractor-order-list__logo"
                            size="sm"
                            src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
                        /> :
                        <LogoIcon className="contractor-order-list__logo contractor-order-list__logo_empty" />
                ,
                contentRow: (
                    <NmListCard
                        clientLogo
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-15"
                        labels={[
                            {
                                label: "Инициатор",
                                text: creatorClientUserName,
                            },
                            {
                                label: "Период",
                                text: `${dateFormat(periodStartDateTime, "dd.MM.yyyy")} - ${dateFormat(periodEndDateTime, "dd.MM.yyyy")}`,
                            },
                            {
                                label: "Исполнитель",
                                text: contractorFio || contractorPhone ?
                                    `${contractorFio || ""} ${contractorPhone ? phoneFormat(contractorPhone) : ""}` :
                                    "-",
                            },
                            {
                                label: "Номер и наименование заказа",
                                text: orderNum || orderName ?
                                    `${orderNum ? `№ ${orderNum} - ` : ""}${orderName || ""}` :
                                    "-",
                            },
                            {
                                label: "Заказчик",
                                text: renderClientInfo(item),
                            },
                            {
                                label: "Документы",
                                text: renderDocuments(item),
                                classNameText: "document-export-list__label-documents",
                            },
                        ]}
                        secondaryHeader={`Дата создания ${dateFormat(convertUtcToLocal(createdDateTime))}`}
                        secondaryHeaderStatus={renderStatus(item)}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const cancelExport = (exportId) => {
        dispatch(cancelExportById({
            exportId,
            onSuccess: fetchList,
        }));
    };

    const submit = () => {
        addExportDocument();
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const renderModalWindow = () => {
        const {
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = confirmData;

        return !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    };

    return (
        <NmPage
            isLoaded={loading}
            header={
                <NmPageHeader
                    text="Экспорт документов"
                />
            }
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            filtersBase={
                <ExportDocumentFilter
                    filter={exportDocumentForm}
                    onChangeFilter={onChangeForm}
                    submit={submit}
                />
            }
        >
            {renderModalWindow()}
            {
                list?.length ?
                    <CheckboxList
                        withCheckbox={false}
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        fetchProgress={loading}
                    />
            }
        </NmPage>
    );
}

export default ExportDocument;