import {
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR,
    BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS,
    UPDATE_SETTINGS_EMPLOYEES_STORE,
} from "./actions";

const initial = {
    nmUsers: [],
    nmUsersTotalCountData: {
        otherCount: 0,
        partnerCount: 0,
        totalCount: 0,
    },
    nmUsersPageData: {},
    clientsForSelection: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST:
            return {
                ...state,
                nmUsersPageData: payload,
            };
        case BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS:
            return {
                ...state,
                nmUsers: payload.clientUsers,
                nmUsersTotalCountData: {
                    otherCount: payload.otherCount,
                    partnerCount: payload.partnerCount,
                    totalCount: payload.totalCount,
                },
                clientsForSelection: payload.clientsForSelection,
            };
        case BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST:
        case BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST:
            return {
                ...state,
                progressUpdate: true,
            };
        case BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS:
        case BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS:
        case BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR:
        case BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR:
            return {
                ...state,
                progressUpdate: false,
            };
        case UPDATE_SETTINGS_EMPLOYEES_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};