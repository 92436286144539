import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/contractors/contractors-registry";

const getPageContractorsRegistry = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const importContractorsRegistry = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;

        const result = yield request.bff.post(`${controller}/prefilled/import`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
        });
    }
};

const addContractorsRegistry = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST, getPageContractorsRegistry),
        takeEvery(BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST, importContractorsRegistry),
        takeEvery(BFF_CONTRACTOR_REGISTRY_ADD_REQUEST, addContractorsRegistry),
    ]);
}