import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {CURRENT_CLIENT_ID, ls} from "../../../../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {
    addNoteContractorCard,
    getNoteContractorCard,
    updateNoteContractorCard,
} from "../../../../../../ducks/bff/contractor-сard/actionCreators";

export const useNoteAction = ({contractorId, noteInitial = ""}) => {
    const [note, setNote] = useState(noteInitial);
    const [isEditNote, setEditNote] = useState(false);

    const currentClientId = ls(CURRENT_CLIENT_ID);

    const dispatch = useDispatch();

    useEffect(() => {
        setNote(noteInitial);
    }, [noteInitial]);

    const handleSubmitNote = () => {
        const isHasNote = !isNullOrWhitespace(noteInitial);
        const data = {
            contractorId,
            clientId: currentClientId,
            text: note,
            onSuccess: () => {
                setEditNote(false);
                getNote();
                toastSuccess(
                    isHasNote
                        ? "Заметка успешно изменена"
                        : "Заметка успешно добавлена",
                );
            },
        };

        isHasNote
            ? dispatch(updateNoteContractorCard(data))
            : dispatch(addNoteContractorCard(data));
    };

    const deleteNote = () => {
        setNote("");
        dispatch(updateNoteContractorCard({
            contractorId,
            clientId: currentClientId,
            text: "",
            onSuccess: () => {
                setEditNote(false);
                getNote();
                toastSuccess("Заметка успешно удалена");
            },
        }));
    };

    const getNote = () => {
        dispatch(getNoteContractorCard({
            contractorId,
            clientId: currentClientId,
        }));
    };

    return {
        note,
        setNote,
        isEditNote,
        setEditNote,
        handleSubmitNote,
        deleteNote,
    };
};