import React from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmTitle from "../../../../components/NmTitle";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useClientMonitoringRiskFetchContractorsList} from "../hooks/useFetchContractorsList";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {getFullName, phoneFormat, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {LINK_CONTRACTOR_PROFILE} from "../../../../constants/links";

import {
    bffClientMonitoringRiskContractorsListLoadingSelector,
    bffClientMonitoringRiskContractorsListSelector,
    bffClientMonitoringRiskContractorsListTotalCountSelector,
    bffClientMonitoringRiskContractorsListTotalPagesSelector,
} from "../../../../ducks/bff/clients/monitoring-risk/selectors";

export const ClientReportsMonitoringRiskContractorsForm = (props) => {
    const {
        onClose,
        modalHeader,
        clientId,
        riskId,
    } = props;

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card", 5);

    const {
        onClear,
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
            phone: "",
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    useClientMonitoringRiskFetchContractorsList({
        clientId,
        pageNum,
        pageSize,
        filterData,
        riskId,
    });

    const list = useSelector(bffClientMonitoringRiskContractorsListSelector);
    const listLoading = useSelector(bffClientMonitoringRiskContractorsListLoadingSelector);
    const listTotalCount = useSelector(bffClientMonitoringRiskContractorsListTotalCountSelector);
    const listTotalPages = useSelector(bffClientMonitoringRiskContractorsListTotalPagesSelector);

    function mapFilterDto(filter) {
        return {
            fioFilter: handleFormString(filter.name),
            phoneFilter: filter.phone ? removePhoneMask(filter.phone) : undefined,
        };
    }

    const renderFilters = () => {
        return (
            <div className="row gx-4 mb-4 align-items-end">
                <div className="col-16 col-md-5">
                    <NmInputV2
                        label="ФИО"
                        size="lg"
                        placeholder="Введите ФИО"
                        onChange={onChangeFilter}
                        name="name"
                        value={filter.name}
                    />
                </div>
                <div className="col-16 col-md-5 mt-2 mt-md-0">
                    <NmInputV2
                        size="lg"
                        mask="+7 (999) 999 99 99"
                        name="phone"
                        value={filter.phone}
                        placeholder="+7"
                        label="Номер телефона"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-6 mt-2 mt-md-0">
                    <FilterButtonsV2
                        className="col-16"
                        onSearch={() => {
                            onSearch(filter);
                        }}
                        onClear={onClear}
                    />
                </div>
            </div>
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.contractorId,
                contentRow: (
                    <NmListCard
                        primaryHeader={
                            <ExtLink
                                to={LINK_CONTRACTOR_PROFILE.replace(":contractorId", item.contractorId)}
                                historyEnabled={true}
                            >
                                {getFullName(item.lastName, item.firstName, item.patronymic)}
                            </ExtLink>
                        }
                        noDivider
                        labels={[
                            {
                                label: "Номер телефона",
                                text: phoneFormat(item.phone),
                            },
                            {
                                label: "Дата последней выплаты",
                                text: formatLocalDate(item.lastPaymentDate, "dd.MM.yyyy HH:mm") || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            loading={listLoading}
            header={
                <NmTitle size="lg">
                    {`Критерий "${modalHeader}"`}
                </NmTitle>
            }
            classNameContent="flex-column"
        >
            <NmLabelText
                label="Количество исполнителей"
                text={listTotalCount}
                className="mb-4 mb-md-6"
            />
            {renderFilters()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2 isSearch={isSearch} /> :
                    <CheckboxList rows={getRows()} />
            }
            <NmPagination
                className="mt-4 mt-md-5 mt-xxl-7"
                totalCount={listTotalCount}
                pageSizes={[5, 10, 15]}
                pageNum={pageNum}
                totalPages={listTotalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
        </NmModal>
    );
};