import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink";
import NmBadge from "../../../../../components/NmBadge";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import SelectionCountWithAction from "../../../../../components/SelectionCountWithAction";
import {KedoOrganizationStructureStaffRegistryMoveForm} from "../move-form";

import {useModal} from "../../../../../hooks/useModal";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../hooks/useSelectedList";
import {useKedoDepartmentsStaffFetch} from "../../hooks/useFetchStaff";
import {useKedoOrganizationStructureStaffFilters} from "../../hooks/useStaffFilters";

import {getFullName} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {LINK_CLIENT_KEDO_PROFILE} from "../../../../../constants/links";

import {kedoDepartmentsRootSelector} from "../../../../../ducks/kedo/departments/selectors";
import {
    kedoDepartmentsProgressSelector,
    kedoDepartmentsStaffListSelector,
    kedoDepartmentsTotalCountSelector,
    kedoDepartmentsTotalPagesSelector,
} from "../../../../../ducks/kedo/departments/staff/selectors";

export const KedoOrganizationStructureStaffRegistry = (props) => {
    const {
        match: {
            params: {
                clientId,
                kedoDepartmentId,
            },
        },
    } = props;

    const totalCount = useSelector(kedoDepartmentsTotalCountSelector);
    const totalPages = useSelector(kedoDepartmentsTotalPagesSelector);
    const list = useSelector(kedoDepartmentsStaffListSelector);
    const progress = useSelector(kedoDepartmentsProgressSelector);
    const rootDepartment = useSelector(kedoDepartmentsRootSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useKedoOrganizationStructureStaffFilters({
        pageSize,
        setPagination,
        clientId,
    });

    useKedoDepartmentsStaffFetch({
        pageSize,
        pageNum,
        clientId,
        filterData,
        rootDepartment,
        kedoDepartmentId,
    });

    const {
        selectedList,
        selectedIds,
        countSelected,
        handleSelectedRows,
    } = useSelectedList({
        list,
        idName: "kedoStaffId",
    });

    const {
        onOpenModal,
        modalData,
        onCloseModal,
    } = useModal();

    const renderLink = (fullName, kedoStaffId) => {
        if (isNullOrWhitespace(kedoStaffId)) {
            return fullName;
        }

        const link = LINK_CLIENT_KEDO_PROFILE
            .replace(":clientId", clientId)
            .replace(":kedoStaffId", kedoStaffId);

        return (
            <ExtLink to={link}>
                {fullName}
            </ExtLink>
        );
    };

    const getMoveModalWindow = () => {
        return (
            <KedoOrganizationStructureStaffRegistryMoveForm
                kedoStaffIds={selectedIds}
                onClose={onCloseModal}
            />
        );
    };

    const getRows = () => {
        return selectedList.map(item => {
            const {
                kedoStaffId,
                isDepartmentHeadStaff,
                isDepartmentDeputyHeadStaff,
                isSelected,
            } = item;

            return {
                ...item,
                key: kedoStaffId,
                showCheckBox: true,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox={true}
                        noDivider={true}
                        classNameMainContent="col-16 col-xxl-14"
                        primaryHeader={renderLink(getFullName(
                            item.lastName,
                            item.firstName,
                            item.patronymic,
                        ), item.kedoStaffId)}
                        secondaryHeaderStatus={
                            (isDepartmentHeadStaff || isDepartmentDeputyHeadStaff) &&
                                <NmBadge
                                    text={isDepartmentHeadStaff ? "Руководитель" : "Заместитель"}
                                    mod={isDepartmentHeadStaff ? "green" : "gray"}
                                />
                        }
                        labels={[
                            {
                                label: "Должность",
                                text: item.positionName || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    const handleOpenMoveModal = () => {
        onOpenModal({
            isOpenMoveModal: true,
        });
    };

    return (
        <NmPage
            noPadding={true}
            openFilter={true}
            widthByFilter={true}
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Сотрудники
                </NmTitle>
            }
            isLoaded={progress}
            isEmptyPage={isEmpty(list)}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            emptyPageProps={{
                isSearch,
            }}
        >
            {modalData?.isOpenMoveModal && getMoveModalWindow()}
            <CheckboxList
                header={
                    <SelectionCountWithAction
                        adaptiveLogic={true}
                        count={countSelected}
                        buttonColor="green"
                        buttonContent="Переместить"
                        onClick={handleOpenMoveModal}
                    />
                }
                onSelectedRows={handleSelectedRows}
                rows={getRows()}
            />
        </NmPage>
    );
};