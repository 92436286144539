import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getNaimixInstructions, saveNaimixInstruction} from "./actionCreators";
import {
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_SUCCESS,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";


const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/settings/instructions/naimix",
        client: "/client-adm/instructions/naimix",
    });
};

// POST bff/adm/settings/instructions/naimix/save
// Сохранить инструкцию
const saveNaimixInstructionSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/save`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Инструкция успешно сохранена");

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_SUCCESS,
            payload: result,
        });

        yield put(getNaimixInstructions());
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_ERROR,
        });
    }
};

// POST /bff/adm/settings/instructions/naimix/addFile
// Добавить файл инструкции
const addNaimixInstructionFileSaga = function* ({payload}) {
    try {
        const {instructionType, file: data, info} = payload;

        const result = yield request.bff.post(`${getController()}/add-file`, data, {
            params: {
                instructionType,
            },
            ...getMultipartHeaders(),
        },
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_SUCCESS,
        });

        yield put(saveNaimixInstruction(info));
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_ERROR,
        });
    }
};

// GET /bff/adm/settings/instructions/naimix/getList
// Получить список инструкций
const getNaimixInstructionsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/list`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST, saveNaimixInstructionSaga),
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST, addNaimixInstructionFileSaga),
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST, getNaimixInstructionsSaga),
    ]);
}