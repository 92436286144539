import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import {ParamPickerWithTextarea} from "../../../../../../components/ActualComponents/ParamPickerWithTextarea";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";
import {
    addRecruitmentDirectoryMessageTemplate,
    updateRecruitmentDirectoryMessageTemplate,
} from "../../../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    bffRecruitmentDirectoryMessageTemplateParamsOptionsSelector,
    bffRecruitmentDirectoryMessageTemplateParamsSelector,
    bffRecruitmentDirectoryProgressActionSelector,
} from "../../../../../../ducks/bff/recruitment/directory/selectors";

import {handleFormString, insertIntoString} from "../../../../../../utils/stringHelper";
import {getValidationMessage, getYupError} from "../../../../../../utils/validate";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

export const RecruitmentMessageTemplatesEdit = (props) => {
    const {
        onClose,
        card,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffRecruitmentDirectoryProgressActionSelector);
    const options = useSelector(bffRecruitmentDirectoryMessageTemplateParamsOptionsSelector);
    const sizes = useSelector(bffRecruitmentDirectoryMessageTemplateParamsSelector);

    const {
        handleSubmit,
        values,
        touched,
        setValues,
        setFieldValue,
        errors,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            name: card.name || "",
            value: card.templateText || "",
            param: "",
            cursorPosition: null,
        },
        validationSchema: yup.object().shape({
            name: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .max(100, getValidationMessage().maxLength(100)),
            value: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        validateOnBlur: false,
    });

    function onSubmit() {
        const reqData = {
            name: handleFormString(values.name),
            value: handleFormString(values.value),
        };

        if (card.id) {
            dispatch(updateRecruitmentDirectoryMessageTemplate({
                id: card.id,
                ...reqData,
            }));

            return;
        }

        dispatch(addRecruitmentDirectoryMessageTemplate(reqData));
    }

    const onChangeParam = (_event, {value}) => {
        const _value = values.value && values.cursorPosition
            ? insertIntoString(values.value, values.cursorPosition, `${value}`)
            : `${value}${values.value}`;

        setValues({
            ...values,
            param: "",
            value: _value,
            cursorPosition: null,
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {
                        card.id
                            ? "Редактирование шаблона сообщения"
                            : "Добавление шаблона сообщения"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                    onClose={onClose}
                />
            }
            loading={progressAction}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Название шаблона"
                    placeholder="Введите название"
                    onChange={(_event, {value}) => {
                        setFieldValue("name", value);
                    }}
                    value={values.name}
                    error={getYupError(touched, errors, "name")}
                />
                <ParamPickerWithTextarea
                    paramDict={sizes}
                    dropdownProps={{
                        options,
                        value: values.param,
                        onChange: onChangeParam,
                    }}
                    textareaProps={{
                        size: "xl",
                        required: true,
                        label: "Содержание",
                        value: values.value,
                        minRows: 3,
                        maxLength: 4000,
                        placeholder: "Введите сожержание",
                        onBlur: (event) => {
                            setFieldValue("cursorPosition", event.target.selectionStart);
                        },
                        onMouseUp: (event) => {
                            setFieldValue("cursorPosition", event.target.selectionStart);
                        },
                        onChange: (_event, {value}) => {
                            setFieldValue("value", value);
                        },
                        error: getYupError(touched, errors, "value"),
                    }}
                />
            </NmForm>
        </NmModal>
    );
};