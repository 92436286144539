import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getKedoDepartmentCard, getKedoDepartments} from "./actionCreators";
import {
    KEDO_DEPARTMENTS_ADD_ERROR,
    KEDO_DEPARTMENTS_ADD_REQUEST,
    KEDO_DEPARTMENTS_ADD_SUCCESS,
    KEDO_DEPARTMENTS_DELETE_ERROR,
    KEDO_DEPARTMENTS_DELETE_REQUEST,
    KEDO_DEPARTMENTS_DELETE_SUCCESS,
    KEDO_DEPARTMENTS_GET_CARD_ERROR,
    KEDO_DEPARTMENTS_GET_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_CARD_SUCCESS,
    KEDO_DEPARTMENTS_GET_PAGE_ERROR,
    KEDO_DEPARTMENTS_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_GET_PAGE_SUCCESS,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS,
    KEDO_DEPARTMENTS_GET_ROOT_ERROR,
    KEDO_DEPARTMENTS_GET_ROOT_REQUEST,
    KEDO_DEPARTMENTS_GET_ROOT_SUCCESS,
    KEDO_DEPARTMENTS_IMPORT_ERROR,
    KEDO_DEPARTMENTS_IMPORT_REQUEST,
    KEDO_DEPARTMENTS_IMPORT_SUCCESS,
    KEDO_DEPARTMENTS_LOGS_ERROR,
    KEDO_DEPARTMENTS_LOGS_REQUEST,
    KEDO_DEPARTMENTS_LOGS_SUCCESS,
    KEDO_DEPARTMENTS_UPDATE_ERROR,
    KEDO_DEPARTMENTS_UPDATE_REQUEST,
    KEDO_DEPARTMENTS_UPDATE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../utils/postman";
import {
    toastError,
    toastSuccess,
} from "../../../utils/toastHelper";
import {getBffUrl} from "../../../utils/url";

const controllers = {
    client: "/client-adm/kedo/organization-structure/department",
    admin: "/adm/clients/client-card/kedo/organization-structure/department",
};

const handleResponse = function* (data) {
    const {
        isFetchList,
        isFetchCard,
        kedoDepartmentId,
    } = data;

    if (isFetchCard) {
        yield put(getKedoDepartmentCard({kedoDepartmentId}));

        return {
            done: true,
        };
    }

    if (isFetchList) {
        const state = yield select();

        yield put(getKedoDepartments(state.kedoDepartments.pageData));
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/initialize-or-get-root
// POST /bff/client-adm/kedo/organization-structure/department/initialize-or-get-root
// Создание или получение идентификатора корневого отдела
const getRootKedoDepartmentSaga = function* ({payload}) {
    const action = "/initialize-or-get-root";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_GET_ROOT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_GET_ROOT_SUCCESS,
            payload: result,
        });

        const {
            rootDepartmentId,
        } = result;

        yield put(getKedoDepartmentCard({
            kedoDepartmentId: rootDepartmentId,
        }));
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_GET_ROOT_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/kedo/organization-structure/department/get-card
// GET /bff/client-adm/kedo/organization-structure/department/get-card
// Получение карточки отдела
const getKedoDepartmentCardSaga = function* ({payload}) {
    const {kedoDepartmentId} = payload;
    const action = "/get-card";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.get(url, {params: {kedoDepartmentId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_GET_CARD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_GET_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_GET_CARD_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/clients/client-card/kedo/organization-structure/department/delete
// DELETE /bff/client-adm/kedo/organization-structure/department/delete
// Удаление отдела
const deleteKedoDepartment = function* ({payload}) {
    const {kedoDepartmentId} = payload;
    const action = "/delete";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.delete(url, {params: {kedoDepartmentId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_DELETE_SUCCESS,
            payload: result,
        });

        const state = yield select();

        yield put(getKedoDepartments(state.kedoDepartments.pageData));
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/import
// POST /bff/client-adm/kedo/organization-structure/department/import
// Импорт организационной структуры
const importKedoDepartments = function* ({payload}) {
    const {
        isFetchList,
        file,
        clientId,
    } = payload;

    const action = "/import";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}/${clientId}`,
        },
    );

    try {
        const result = yield request.bff.post(
            url,
            file,
            {...getMultipartHeaders()},
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_IMPORT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_IMPORT_SUCCESS,
            payload: result,
        });

        toastSuccess("Задача по добавлению отдела из файла передана в обработку.  " +
            "Прогресс выполнения задачи доступен в разделе \"Список задач\"");

        yield handleResponse({
            clientId,
            isFetchList,
        });

    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_IMPORT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/get-page
// POST /bff/client-adm/kedo/organization-structure/department/get-page
// Получение страницы отделов
const getKedoDepartmentsSaga = function* ({payload}) {
    const action = "/get-page";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/add
// POST /bff/kedo/client/organization-structure/department/add
// Добавление отдела
const addKedoDepartment = function* ({payload}) {
    const {
        isFetchList,
        ...reqData
    } = payload;

    const action = "/add";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_ADD_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchList,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/update
// POST /bff/client-adm/kedo/organization-structure/department/update
// Обновление отдела
const updateKedoDepartment = function* ({payload}) {
    const {
        isFetchList,
        isFetchCard,
        ...reqData
    } = payload;

    const action = "/update";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_UPDATE_SUCCESS,
            payload: result,
        });

        yield handleResponse({
            isFetchList,
            isFetchCard,
            kedoDepartmentId: payload.kedoDepartmentId,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_UPDATE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/kedo/organization-structure/department/get-pre-update-card
// GET /bff/kedo/client/organization-structure/department/get-pre-update-card
// Получение полной модели отдела для обновления
const getRichKedoDepartmentCard = function* ({payload}) {
    const action = "/get-pre-update-card";
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${action}`,
            adminRolesUrl: `${controllers.admin}${action}`,
        },
    );

    try {
        const result = yield request.bff.get(url, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/kedo/organization-structure/department/log/get-page
// Получение логов
const getKedoDepartmentsLogs = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controllers.admin}/log/get-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DEPARTMENTS_LOGS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DEPARTMENTS_LOGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DEPARTMENTS_LOGS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_DEPARTMENTS_GET_PAGE_REQUEST, getKedoDepartmentsSaga),
        takeEvery(KEDO_DEPARTMENTS_GET_ROOT_REQUEST, getRootKedoDepartmentSaga),
        takeEvery(KEDO_DEPARTMENTS_GET_CARD_REQUEST, getKedoDepartmentCardSaga),
        takeEvery(KEDO_DEPARTMENTS_DELETE_REQUEST, deleteKedoDepartment),
        takeEvery(KEDO_DEPARTMENTS_IMPORT_REQUEST, importKedoDepartments),
        takeEvery(KEDO_DEPARTMENTS_ADD_REQUEST, addKedoDepartment),
        takeEvery(KEDO_DEPARTMENTS_UPDATE_REQUEST, updateKedoDepartment),
        takeEvery(KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST, getRichKedoDepartmentCard),
        takeEvery(KEDO_DEPARTMENTS_LOGS_REQUEST, getKedoDepartmentsLogs),
    ]);
}