import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getUserRole} from "../../../../../utils/access";

import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../../constants/dicts";
import {ADMIN} from "../../../../../constants/roles";

import {
    getKedoDocumentRoutesPage,
    updateKedoDirectoriesStore,
} from "../../../../../ducks/kedo/directories/actionCreators";

export const useKedoDocumentRoutesFetch = (props) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        sortType,
    } = props;
    
    const dispatch = useDispatch();

    const role = getUserRole();

    useEffect(() => {
        return () => {
            dispatch(updateKedoDirectoriesStore({
                documentRoutes: {},
            }));
        };
    }, []);


    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        sortType,
    ]);

    const fetchList = () => {
        dispatch(getKedoDocumentRoutesPage({
            clientIdFilter: clientId,
            types: !filterData.type
                ? [
                    role === ADMIN && KEDO_DOCUMENTS_ROUTE_TYPE.BASE_ROUTE,
                    KEDO_DOCUMENTS_ROUTE_TYPE.USER_ROUTE,
                    KEDO_DOCUMENTS_ROUTE_TYPE.FLEX_ROUTE,
                ].filter(Boolean)
                : undefined,
            ...filterData,
            orderType: sortType,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};