import {useSelector} from "react-redux";

import {TAB} from "../constants";

import {
    contractorPendingContractsListSelector,
    documentAgencyContractListSelector,
    documentAgencyContractPageDataSelector,
    documentAgencyContractTotalCountSelector,
    documentConsentToPDListSelector,
    documentConsentToPDPageDataSelector,
    documentConsentToPDTotalCountSelector,
    documentIdentificationSheetListSelector,
    documentsActOfAcceptanceOfWorkListSelector,
    documentsActOfAcceptanceOfWorkTotalCountSelector,
    documentsActOfAcceptanceOfWorkTotalPagesSelector,
    documentsInsurancePolicyListSelector,
    documentsInsurancePolicyTotalCountSelector,
    documentsInsurancePolicyTotalPagesSelector,
    documentsOrderApplicationListSelector,
    documentsOrderApplicationTotalCountSelector,
    documentsOrderApplicationTotalPagesSelector,
    documentsReceiptsRNKOListSelector,
    documentsReceiptsRNKOTotalCountSelector,
    documentsReceiptsRNKOTotalPagesSelector,
    getFrameContractDocumentListSelector,
    getFrameContractDocumentTotalCountSelector,
    getFrameContractDocumentTotalPagesSelector,
    getRegistryFrameContractShortItemsSelector,
    getRegistryFrameContractShortTotalCountSelector,
    getRegistryFrameContractShortTotalPagesSelector,
} from "../../../../ducks/bff/contractor-сard/documents/selectors";

export const useData = ({tab}) => {
    const data = {
        [TAB.AGENCY_CONTRACT]: {
            totalPages: useSelector(documentAgencyContractPageDataSelector),
            totalCount: useSelector(documentAgencyContractTotalCountSelector),
            list: useSelector(documentAgencyContractListSelector),
        },
        /*Согласие на обработку ПД*/
        [TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA]: {
            totalPages: useSelector(documentConsentToPDPageDataSelector),
            totalCount: useSelector(documentConsentToPDTotalCountSelector),
            list: useSelector(documentConsentToPDListSelector),
        },
        /*Договоры с заказчиками*/
        [TAB.FRAME_CONTRACT]: {
            totalPages: useSelector(getFrameContractDocumentTotalPagesSelector),
            totalCount: useSelector(getFrameContractDocumentTotalCountSelector),
            list: useSelector(getFrameContractDocumentListSelector),
        },
        /*Заявки на выполнение работ (оказание услуг)*/
        [TAB.ORDER_APPLICATION]: {
            totalCount: useSelector(documentsOrderApplicationTotalCountSelector),
            totalPages: useSelector(documentsOrderApplicationTotalPagesSelector),
            list: useSelector(documentsOrderApplicationListSelector),
        },
        /*Акты выполненных работ*/
        [TAB.ACT_OF_ACCEPTANCE_OF_WORK]: {
            totalPages: useSelector(documentsActOfAcceptanceOfWorkTotalPagesSelector),
            totalCount: useSelector(documentsActOfAcceptanceOfWorkTotalCountSelector),
            list: useSelector(documentsActOfAcceptanceOfWorkListSelector),
        },
        /*Страховые полисы*/
        [TAB.INSURANCE_POLICY]: {
            totalPages: useSelector(documentsInsurancePolicyTotalPagesSelector),
            totalCount: useSelector(documentsInsurancePolicyTotalCountSelector),
            list: useSelector(documentsInsurancePolicyListSelector),
        },
        /*Квитанции РНКО*/
        [TAB.RECEIPT_RNKO]: {
            totalPages: useSelector(documentsReceiptsRNKOTotalPagesSelector),
            totalCount: useSelector(documentsReceiptsRNKOTotalCountSelector),
            list: useSelector(documentsReceiptsRNKOListSelector),
        },
        /*Реестры на подписание рамочных договоров с исполнителем*/
        [TAB.DOCUMENTS_REGISTRY]: {
            totalCount: useSelector(getRegistryFrameContractShortTotalCountSelector),
            totalPages: useSelector(getRegistryFrameContractShortTotalPagesSelector),
            list: useSelector(getRegistryFrameContractShortItemsSelector),
        },
        /*Лист идентификации личности*/
        [TAB.IDENTIFICATION_SHEET]: {
            totalPages: 1,
            totalCount: 1,
            list: useSelector(documentIdentificationSheetListSelector),
        },
        /*Договоры на подписании*/
        [TAB.PENDING_CONTRACTS]: {
            totalPages: 1,
            totalCount: 1,
            list: useSelector(contractorPendingContractsListSelector),
        },
    };

    const {
        totalCount,
        totalPages,
        list,
    } = data[tab];

    return {
        totalPages,
        totalCount,
        list,
    };
};