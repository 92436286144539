import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getKedoDepartments} from "../../../../ducks/kedo/departments/actionCreators";
import {kedoDepartmentsRootSelector} from "../../../../ducks/kedo/departments/selectors";

export const useKedoDepartmentsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        kedoDepartmentId,
    } = params;

    const {rootDepartmentId} = useSelector(kedoDepartmentsRootSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!rootDepartmentId && !kedoDepartmentId) {
            return;
        }

        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        kedoDepartmentId,
        rootDepartmentId,
    ]);

    const fetchList = () => {
        dispatch(getKedoDepartments({
            clientId,
            pageNum,
            pageSize,
            parentDepartmentId: kedoDepartmentId || rootDepartmentId,
            ...filterData,
        }));
    };

    return null;
};