export const KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST = "KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST";
export const KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS = "KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS";
export const KEDO_DIRECTORIES_UPDATE_POSITION_ERROR = "KEDO_DIRECTORIES_UPDATE_POSITION_ERROR";

export const KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST = "KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST";
export const KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS = "KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR = "KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR";

export const KEDO_DIRECTORIES_ADD_POSITION_REQUEST = "KEDO_DIRECTORIES_ADD_POSITION_REQUEST";
export const KEDO_DIRECTORIES_ADD_POSITION_SUCCESS = "KEDO_DIRECTORIES_ADD_POSITION_SUCCESS";
export const KEDO_DIRECTORIES_ADD_POSITION_ERROR = "KEDO_DIRECTORIES_ADD_POSITION_ERROR";

export const KEDO_DIRECTORIES_DELETE_POSITION_REQUEST = "KEDO_DIRECTORIES_DELETE_POSITION_REQUEST";
export const KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS = "KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS";
export const KEDO_DIRECTORIES_DELETE_POSITION_ERROR = "KEDO_DIRECTORIES_DELETE_POSITION_ERROR";

export const KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST = "KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST";
export const KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS = "KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS";
export const KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR = "KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR";

export const KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST = "KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST";
export const KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS = "KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR = "KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR";

export const KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST = "KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST";
export const KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS = "KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS";
export const KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR = "KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR";

export const KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST = "KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST";
export const KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS = "KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS";
export const KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR = "KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR";

export const KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST = "KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST";
export const KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS = "KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS";
export const KEDO_DIRECTORIES_GET_PAGE_COUNT_ERROR = "KEDO_DIRECTORIES_GET_PAGE_COUNT_ERROR";

export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_REQUEST";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_SUCCESS";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ERROR";

export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR";

export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR";

export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS";
export const KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR = "KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR";

export const KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST = "KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST";
export const KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS = "KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS";
export const KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR = "KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR";

export const KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST = "KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST";
export const KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS = "KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS";
export const KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR = "KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR";

export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR";

export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR";

export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR";

export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS";
export const KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR = "KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_PAGE_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_DELETE_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_ADD_FLEX_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_FLEX_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_FULL_MODEL_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_FLEX_GET_FULL_MODEL_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_GET_ALL_TYPES_MODEL_ERROR";

export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_REQUEST";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_SUCCESS = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_SUCCESS";
export const KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_ERROR = "KEDO_DIRECTORIES_DOCUMENT_ROUTES_UPDATE_ALL_TYPES_MODEL_ERROR";

export const KEDO_DIRECTORIES_UPDATE_STORE = "KEDO_DIRECTORIES_UPDATE_STORE";