export const GET_ML_MODEL_LIST_REQUEST = "GET_ML_MODEL_LIST_REQUEST";
export const GET_ML_MODEL_LIST_SUCCESS = "GET_ML_MODEL_LIST_SUCCESS";
export const GET_ML_MODEL_LIST_ERROR = "GET_ML_MODEL_LIST_ERROR";

export const ADD_ML_MODEL_REQUEST = "ADD_ML_MODEL_REQUEST";
export const ADD_ML_MODEL_SUCCESS = "ADD_ML_MODEL_SUCCESS";
export const ADD_ML_MODEL_ERROR = "ADD_ML_MODEL_ERROR";

export const ADD_FILE_TO_MODEL_REQUEST = "ADD_FILE_TO_MODEL_REQUEST";
export const ADD_FILE_TO_MODEL_SUCCESS = "ADD_FILE_TO_MODEL_SUCCESS";
export const ADD_FILE_TO_MODEL_ERROR = "ADD_FILE_TO_MODEL_ERROR";

export const UPDATE_MODEL_FILE_REQUEST = "UPDATE_MODEL_FILE_REQUEST";
export const UPDATE_MODEL_FILE_SUCCESS = "UPDATE_MODEL_FILE_SUCCESS";
export const UPDATE_MODEL_FILE_ERROR = "UPDATE_MODEL_FILE_ERROR";

export const DELETE_ML_MODEL_REQUEST = "DELETE_ML_MODEL_REQUEST";
export const DELETE_ML_MODEL_SUCCESS = "DELETE_ML_MODEL_SUCCESS";
export const DELETE_ML_MODEL_ERROR = "DELETE_ML_MODEL_ERROR";

export const DELETE_MODEL_FILE_REQUEST = "DELETE_MODEL_FILE_REQUEST";
export const DELETE_MODEL_FILE_SUCCESS = "DELETE_MODEL_FILE_SUCCESS";
export const DELETE_MODEL_FILE_ERROR = "DELETE_MODEL_FILE_ERROR";

export const SET_MAIN_MODEL_REQUEST = "SET_MAIN_MODEL_REQUEST";
export const SET_MAIN_MODEL_SUCCESS = "SET_MAIN_MODEL_SUCCESS";
export const SET_MAIN_MODEL_ERROR = "SET_MAIN_MODEL_ERROR";

export const UPDATE_ML_MODEL_REQUEST = "UPDATE_ML_MODEL_REQUEST";
export const UPDATE_ML_MODEL_SUCCESS = "UPDATE_ML_MODEL_SUCCESS";
export const UPDATE_ML_MODEL_ERROR = "UPDATE_ML_MODEL_ERROR";

export const GET_ML_MODEL_FILE_LIST_REQUEST = "GET_ML_MODEL_FILE_LIST_REQUEST";
export const GET_ML_MODEL_FILE_LIST_SUCCESS = "GET_ML_MODEL_FILE_LIST_SUCCESS";
export const GET_ML_MODEL_FILE_LIST_ERROR = "GET_ML_MODEL_FILE_LIST_ERROR";

export const ML_MODELS_PREDICT_AMOUNT_REQUEST = "ML_MODELS_PREDICT_AMOUNT_REQUEST";
export const ML_MODELS_PREDICT_AMOUNT_SUCCESS = "ML_MODELS_PREDICT_AMOUNT_SUCCESS";
export const ML_MODELS_PREDICT_AMOUNT_ERROR = "ML_MODELS_PREDICT_AMOUNT_ERROR";

export const ML_MODELS_STORE_CLEAR = "ML_MODELS_STORE_CLEAR";