import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import MailingsList from "../../../components/MailingsList";

import {
    addMarketingNotification,
    cancelMarketingNotification,
    clearStoreMarketingNotifications,
    getContractorsCountMarketingNotification,
    getPageMarketingNotifications,
    retryMarketingNotification,
    updateStoreMarketingNotifications,
} from "../../../ducks/bff/marketing/notifications/actionCreators";
import {
    bffMarketingNotificationsListSelector,
    bffMarketingNotificationsMailingCardSelector,
    bffMarketingNotificationsProgressActionSelector,
    bffMarketingNotificationsProgressSelector,
    bffMarketingNotificationsTotalCountSelector,
    bffMarketingNotificationsTotalPagesSelector,
} from "../../../ducks/bff/marketing/notifications/selectors";
import {
    getContractorByPhone,
} from "../../../ducks/contractor";

export const AdminMailingsList = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(bffMarketingNotificationsListSelector);
    const totalPages = useSelector(bffMarketingNotificationsTotalPagesSelector);
    const totalCount = useSelector(bffMarketingNotificationsTotalCountSelector);
    const progress = useSelector(bffMarketingNotificationsProgressSelector);
    const notification = useSelector(bffMarketingNotificationsMailingCardSelector);
    const progressAction = useSelector(bffMarketingNotificationsProgressActionSelector);

    useEffect(() => {
        return () => {
            dispatch(clearStoreMarketingNotifications());
        };
    }, []);

    const retryNotification = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(retryMarketingNotification({
            ...data,
            handleResponse,
        }));
    };

    const cancelNotification = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(cancelMarketingNotification({
            ...data,
            handleResponse,
        }));
    };

    const fetchList = (params) => {
        const {
            pageNum,
            pageSize,
            ...filters
        } = params;

        dispatch(getPageMarketingNotifications({
            pageNum,
            pageSize,
            ...filters,
        }));
    };

    const getContractorCount = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(getContractorsCountMarketingNotification({
            data,
            handleResponse,
        }));
    };

    const addNotification = (params) => {
        const {
            formData,
            handleResponse,
        } = params;

        dispatch(addMarketingNotification({
            formData,
            handleResponse,
        }));
    };

    const addContractor = ({data, saveNumber}) => {
        dispatch(getContractorByPhone({
            data,
            handleResponse: (response) => {
                const isSuccess = Boolean(response.length);

                saveNumber({
                    isSuccess,
                    isNotFound: !isSuccess,
                });
            },
        }));
    };

    return (
        <MailingsList
            retryNotification={retryNotification}
            cancelNotification={cancelNotification}
            fetchList={fetchList}
            getContractorCount={getContractorCount}
            addNotification={addNotification}
            addContractor={addContractor}
            updateStoreMarketingNotifications={(data) => {
                dispatch(updateStoreMarketingNotifications(data));
            }}
            notification={notification}
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            progress={progress}
            progressAction={progressAction}
            {...props}
        />
    );
};