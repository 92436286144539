import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import NmLoader from "../../../../../components/NmLoader";
import ContractorInfoAdditionalDocumentsVerificationForm from "./components/form";

import {useContractorAdditionalDocumentsVerificationFetch} from "./hooks/useFetch";
import {useContractorAdditionalDocumentsVerificationNavigate} from "./hooks/useNavigate";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS,
} from "./constants";

import {
    contractorAdditionalDocumentsProgressAggregationSelector,
} from "../../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {contractorProgressSelector} from "../../../../../ducks/contractor";
import {
    contractorAdditionalDocumentsFileSelector,
} from "../../../../../ducks/contractorAdditionalDocuments";

const ContractorInfoAdditionalDocumentsVerification = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const progressAggregation = useSelector(contractorAdditionalDocumentsProgressAggregationSelector);
    const progressContractor = useSelector(contractorProgressSelector);
    const document = useSelector(contractorAdditionalDocumentsFileSelector);

    const {
        currentStep,
        updateDocumentStepForm,
    } = useContractorAdditionalDocumentsVerificationNavigate({
        contractorId,
    });

    useContractorAdditionalDocumentsVerificationFetch({
        contractorId,
    });

    const filtered = useMemo(() => {
        return Object.keys(CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS)
            .filter((fileType) => fileType === currentStep.fileType);
    }, [currentStep]);

    if (progressAggregation) {
        return (
            <NmLoader
                active={true}
                content=""
                inverted
            />
        );
    }

    return (
        filtered.map(fileType => {
            return (
                <ContractorInfoAdditionalDocumentsVerificationForm
                    key={fileType}
                    currentStep={currentStep}
                    checkedDocumentName={CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[fileType].name}
                    updateDocumentStepForm={updateDocumentStepForm}
                    pendingFileTypes={currentStep.pendingFileTypes}
                    savedFiles={currentStep.savedFiles}
                    initialValues={document.data}
                    {...props}
                />
            );
        })
    );
};

export default ContractorInfoAdditionalDocumentsVerification;