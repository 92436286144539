import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNestedObjectValues, useFormik} from "formik";

import validationSchema from "../validation";

import {removePhoneMask} from "../../../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../../../../constants/contractor";

import {getInfoContractorCard} from "../../../../../../../../ducks/bff/contractor-сard/actionCreators";
import {
    updateContractorBankRequisites,
    updateContractorIndividualEntrepreneurBankRequisites,
} from "../../../../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    contractorCardInfoSelector,
    contractorPaymentDataSelector,
} from "../../../../../../../../ducks/bff/contractor-сard/selectors";

export const usePaymentDataInfoEditForm = ({contractorId, onClose, isIndividualEntrepreneurForm, fetchContractorInfo}) => {
    const paymentData = useSelector(contractorPaymentDataSelector);
    const card = useSelector(contractorCardInfoSelector);

    const {
        bankRequisitesRequired,
        bankRequisites,
        individualEntrepreneurBankRequisitesModel,
    } = card;

    const initialValues = useMemo(() => {
        if (isIndividualEntrepreneurForm) {
            return {
                bankName: "",
                bankAddress: "",
                rs: "",
                ks: "",
                bic: "",
                inn: "",
                ...individualEntrepreneurBankRequisitesModel,
                bankRequisitesRequired: true,
            };
        }

        return {
            ...paymentData,
            ...bankRequisites,
            bankRequisitesRequired,
        };
    }, [
        card,
        isIndividualEntrepreneurForm,
    ]);

    const dispatch = useDispatch();

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const requisites = {
            bankName: values.bankName ? handleFormString(values.bankName) : null,
            bankAddress: values.bankAddress ? handleFormString(values.bankAddress) : null,
            rs: values.rs ? handleFormString(values.rs) : null,
            ks: values.ks ? handleFormString(values.ks) : null,
            bic: values.bic ? handleFormString(values.bic) : null,
            inn: values.inn ? handleFormString(values.inn) : null,
        };

        if (isIndividualEntrepreneurForm) {
            dispatch(updateContractorIndividualEntrepreneurBankRequisites({
                contractorId,
                ...requisites,
                onSuccess: () => {
                    fetchContractorInfo();
                    onClose();
                },
            }));

            return;
        }

        dispatch(updateContractorBankRequisites({
            ...values,
            ...requisites,
            contractorId,
            [CONTRACTOR_FIELD.BANK_CARD_NUMBER]: removePhoneMask(values[CONTRACTOR_FIELD.BANK_CARD_NUMBER]),
            onSuccess: () => {
                dispatch(getInfoContractorCard(contractorId));
                onClose();
            },
        }));
    };

    const handleChangeAddress = ({value}) => {
        setFieldValue(CONTRACTOR_FIELD.BANK_ADDRESS, value);
    };

    const handleChange = (e, {value, name, bankInfo}) => {
        if (name === "bankInfo") {
            setValues({
                ...values,
                ...bankInfo,
            });

            return;
        }

        if (name === CONTRACTOR_FIELD.BANK_NAME && value.length > 255) {
            value = value.slice(0, 255);
        }

        setValues({
            ...values,
            [name]: value,
        });
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        setFieldTouched,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        initialTouched: setNestedObjectValues(initialValues, false),
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
        handleChangeAddress,
    };
};