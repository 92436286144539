export const BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST = "BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_REQUEST";
export const BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS = "BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_SUCCESS";
export const BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR =  "BFF_CLIENT_FOREMEN_PAYMENTS_RICH_PAGE_ERROR";

export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST = "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_REQUEST";
export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS = "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_SUCCESS";
export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR =  "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_REJECT_ERROR";

export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST = "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_REQUEST";
export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS = "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_SUCCESS";
export const BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR =  "BFF_CLIENT_FOREMEN_PAYMENTS_PAYMENT_CONFIRM_ERROR";

export const BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST = "BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_REQUEST";
export const BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS = "BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_SUCCESS";
export const BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR = "BFF_CLIENT_FOREMEN_PAYMENTS_FOREMAN_CONTRACTOR_RICH_LIST_ERROR";

export const BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE = "BFF_CLIENT_FOREMEN_PAYMENTS_CLEAR_STORE";