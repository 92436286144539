import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getByIdRecruitmentVacancy,
    updateRecruitmentVacancyStore,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";

import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";

export const useRecruitmentVacanciesCardFetch = (params) => {
    const {
        vacancyId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
            ],
        }));

        fetchCard();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyStore({
                card: {},
            }));
        };
    }, []);

    const fetchCard = () => {
        dispatch(getByIdRecruitmentVacancy({
            vacancyId,
        }));
    };

    return {
        fetchCard,
    };
};