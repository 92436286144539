import React from "react";
import {useMedia} from "react-media";
import {useSelector} from "react-redux";

import NmPageInfoCardsAccordion from "../ActualComponents/NmPageInfoCardsAccordion";

import {useDepositValuesFetch} from "./hooks/useDepositValuesFetch";

import bem from "../../utils/bem";
import {getDepositValuesCards} from "./utils/getDepositValuesCards";

import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";

import "./style.sass";

const DepositValuesAmountInfo = (props) => {
    const {
        clientId,
        className = "",
        isOpenFilter,
        isRegistry = false,
        isPatents = false,
        // НДФЛ
        isCivil = false,
        addMarginBottom = false,
        hideDetails = false,
        projectId,
        objectId,
    } = props;

    const {depositDistributedByObjects} = useSelector(clientCardPropertiesSelector);

    const [block] = bem("deposit-values-amount-info", className);
    const isAccordion = useMedia({query: {maxWidth: 1920}});

    const {
        depositValues,
        isLoading,
    } = useDepositValuesFetch({
        isCivil,
        clientId,
        projectId,
        objectId,
    });

    const {
        deposit,
        nonDistributedDeposit,
        awaitingConfirmationPayments,
        inProgressPayments,
        availableForPayments,
        limit,
        limitSpent,
        availableForOrders,
    } = getDepositValuesCards({
        isCivil,
        depositValues,
        depositDistributedByObjects,
        projectId,
        objectId,
        isAccordion,
        isLoading,
    });

    const cards = [
        {
            ...nonDistributedDeposit,
            isVisible: depositDistributedByObjects && !hideDetails && !isPatents,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...deposit,
            isVisible: !hideDetails,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...awaitingConfirmationPayments,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...inProgressPayments,
            isVisible: !hideDetails,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...availableForPayments,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...limit,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...limitSpent,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...availableForOrders,
            isVisible: !isRegistry && !isPatents,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
    ];

    return (
        <NmPageInfoCardsAccordion
            bootstrap={true}
            className={block({mb: addMarginBottom})}
            cards={cards}
            desktopViewFrom="xxl"
        />
    );
};

export default DepositValuesAmountInfo;