import {
    ADD_CLIENT_MODULE_TARIFFS_ERROR,
    ADD_CLIENT_MODULE_TARIFFS_REQUEST,
    ADD_CLIENT_MODULE_TARIFFS_SUCCESS,
    CLIENT_MODULE_TARIFFS_UPDATE_STORE,
    DELETE_CLIENT_MODULE_TARIFFS_ERROR,
    DELETE_CLIENT_MODULE_TARIFFS_REQUEST,
    DELETE_CLIENT_MODULE_TARIFFS_SUCCESS,
    UPDATE_CLIENT_MODULE_TARIFFS_ERROR,
    UPDATE_CLIENT_MODULE_TARIFFS_REQUEST,
    UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS,
} from "./actions";

const initialState = {
    progressAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case ADD_CLIENT_MODULE_TARIFFS_REQUEST:
        case UPDATE_CLIENT_MODULE_TARIFFS_REQUEST:
        case DELETE_CLIENT_MODULE_TARIFFS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_CLIENT_MODULE_TARIFFS_SUCCESS:
        case ADD_CLIENT_MODULE_TARIFFS_ERROR:
        case UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS:
        case UPDATE_CLIENT_MODULE_TARIFFS_ERROR:
        case DELETE_CLIENT_MODULE_TARIFFS_SUCCESS:
        case DELETE_CLIENT_MODULE_TARIFFS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CLIENT_MODULE_TARIFFS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};