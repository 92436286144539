export const SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST = "SETTINGS_JOINING_REQUESTS_GET_PAGE_REQUEST";
export const SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS = "SETTINGS_JOINING_REQUESTS_GET_PAGE_SUCCESS";
export const SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR = "SETTINGS_JOINING_REQUESTS_GET_PAGE_ERROR";

export const SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST = "SETTINGS_JOINING_REQUESTS_CONFIRM_REQUEST";
export const SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS = "SETTINGS_JOINING_REQUESTS_CONFIRM_SUCCESS";
export const SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR = "SETTINGS_JOINING_REQUESTS_CONFIRM_ERROR";

export const SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST = "SETTINGS_JOINING_REQUESTS_DECLINE_REQUEST";
export const SETTINGS_JOINING_REQUESTS_DECLINE_SUCCESS = "SETTINGS_JOINING_REQUESTS_DECLINE_SUCCESS";
export const SETTINGS_JOINING_REQUESTS_DECLINE_ERROR = "SETTINGS_JOINING_REQUESTS_DECLINE_ERROR";

export const SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST = "SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_REQUEST";
export const SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS = "SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_SUCCESS";
export const SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_ERROR = "SETTINGS_JOINING_REQUESTS_GET_STATUSES_BY_IDS_ERROR";

export const SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST = "SETTINGS_JOINING_REQUESTS_EXPORT_REQUEST";
export const SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS = "SETTINGS_JOINING_REQUESTS_EXPORT_SUCCESS";
export const SETTINGS_JOINING_REQUESTS_EXPORT_ERROR = "SETTINGS_JOINING_REQUESTS_EXPORT_ERROR";

export const GET_BITRIX_LEAD_BY_ID_REQUEST = "GET_BITRIX_LEAD_BY_ID_REQUEST";
export const GET_BITRIX_LEAD_BY_ID_SUCCESS = "GET_BITRIX_LEAD_BY_ID_SUCCESS";
export const GET_BITRIX_LEAD_BY_ID_ERROR = "GET_BITRIX_LEAD_BY_ID_ERROR";

export const UPDATE_SETTINGS_JOINING_REQUESTS_STORE = "UPDATE_SETTINGS_JOINING_REQUESTS_STORE";