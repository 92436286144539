import React, {useState} from "react";
import {useDispatch} from "react-redux";

import AddGroupForm from "../components/add-group-form";

import {
    addSettingsClientGroups,
    deleteSettingsClientGroups,
    updateSettingsClientGroups,
} from "../../../../ducks/bff/settings/client-groups/actionCreators";


export const CLIENT_GROUP_ACTION = {
    EDIT: "EDIT",
    DELETE: "DELETE",
};

const INITIAL_STATE_MODAL_WINDOW = {
    isShow: false,
    title: "",
    formId: undefined,
    content: "",
    submit: undefined,
    submitContent: "Добавить",
};

export function useClientGroupActions(props) {
    const {
        fetchList,
        isAccessEdit,
    } = props;

    const dispatch = useDispatch();

    const [dataModal, setDataModal] = useState({
        ...INITIAL_STATE_MODAL_WINDOW,
    });

    const getOptions = () => {
        if (!isAccessEdit) {
            return null;
        }

        const option = {
            edit: {
                key: CLIENT_GROUP_ACTION.EDIT,
                text: "Редактировать",
                value: CLIENT_GROUP_ACTION.EDIT,
            },
            delete: {
                key: CLIENT_GROUP_ACTION.DELETE,
                text: "Удалить",
                value: CLIENT_GROUP_ACTION.DELETE,
            },
        };

        return [
            option.edit,
            option.delete,
        ];
    };

    const onCloseModal = () => {
        setDataModal(INITIAL_STATE_MODAL_WINDOW);
    };

    const onSubmitAddNewGroup = (data) => {
        dispatch(addSettingsClientGroups({
            ...data,
            onSuccess: () => {
                fetchList();
                onCloseModal();
            },
        }));
    };

    const onSubmitEditNewGroup = (data) => {
        dispatch(updateSettingsClientGroups({
            ...data,
            onSuccess: () => {
                fetchList();
                onCloseModal();
            },
        }));
    };

    const onClickAddNewGroup = () => {
        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            title: "Добавление группы компаний",
            formId: "AddGroupCompaniesForm",
            content: <AddGroupForm
                onSubmit={onSubmitAddNewGroup}
            />,
        }));
    };

    const onClickActionItem = (option, item) => {
        const {value} = option;
        const {groupId} = item;

        switch (value) {
            case CLIENT_GROUP_ACTION.EDIT:
                setDataModal(prevState => ({
                    ...prevState,
                    isShow: true,
                    submitContent: "Подтвердить",
                    title: "Редактирование группы компаний",
                    formId: "AddGroupCompaniesForm",
                    content: <AddGroupForm
                        data={item}
                        onSubmit={onSubmitEditNewGroup}
                    />,
                }));

                return;
            case CLIENT_GROUP_ACTION.DELETE:
                setDataModal(prevState => ({
                    ...prevState,
                    isShow: true,
                    isConfirm: true,
                    submitContent: "Подтвердить",
                    content: "Вы действительно хотите удалить группу компаний?",
                    submit: () => dispatch(deleteSettingsClientGroups({
                        groupId,
                        onSuccess: () => {
                            fetchList();
                            onCloseModal();
                        },
                    })),
                }));

                return;

            default:
                return;
        }
    };

    return {
        onClickActionItem,
        options: getOptions(),
        dataModal,
        onCloseModal,
        onClickAddNewGroup,
    };
}