import {all, put, takeEvery} from "@redux-saga/core/effects";
import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../constants/contractor";

const controller = "/contractor/additional-documents";

const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE = "CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE";

const initial = {
    document: {
        data: {},
    },
    progressDecide: false,
    progressRequest: false,
    progressUpdateData: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS:
            return {
                ...state,
                document: payload.result,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST: {
            return {
                ...state,
                progressDecide: true,
            };
        }
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS: {
            return {
                ...state,
                progressDecide: false,
            };
        }
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR: {
            return {
                ...state,
                progressDecide: false,
            };
        }
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default: return state;
    }
};

export const decideContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST,
        payload,
    };
};

export const updateContractorAdditionalDocumentStore = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE,
        payload,
    };
};

export const getContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST,
        payload,
    };
};

export const requestContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST,
        payload,
    };
};

export const deleteContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST,
        payload,
    };
};

const contractorAdditionalDocumentsSelector = ({contractorAdditionalDocuments}) => contractorAdditionalDocuments;
export const contractorAdditionalDocumentsProgressDecideSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressDecide}) => progressDecide);
export const contractorAdditionalDocumentsProgressRequestSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressRequest}) => progressRequest);
export const contractorAdditionalDocumentsFileSelector = createSelector(contractorAdditionalDocumentsSelector, ({document}) => document);

function* decideContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/decide`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError(result);

            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR,
            payload: error,
        });
    }
}

function* getContractorAdditionalDocumentsSaga({payload}) {
    try {
        const {
            contractorId,
            changeId,
        } = payload;
        const result = yield request.get(`${controller}/get`, {params: {contractorId, changeId}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR,
        });
    }
}

function* requestContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/request`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Запрос документа успешно выполнен");

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR,
            payload: error,
        });
    }
}

function* deleteContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/delete`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Документ успешно удален");

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST, decideContractorAdditionalDocumentSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST, getContractorAdditionalDocumentsSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST, requestContractorAdditionalDocumentSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST, deleteContractorAdditionalDocumentSaga),
    ]);
}