import {CHROME_EXTENSION_MESSAGE_TYPE} from "../../constants/chromeExtension";

export const logoutExtension = async () => {
    // const extensionId = "blbdceahjjajbkjgalmhokbflilghidg";
    //
    // await chrome.runtime.sendMessage(
    //     extensionId,
    //     {
    //         type: "LOGOUT",
    //     },
    // );

    const message = {
        type: CHROME_EXTENSION_MESSAGE_TYPE.LOGOUT,
    };

    window.postMessage(message, document.location.origin);
};