export const GET_CONTRACTOR_PROFILE_REQUEST = "GET_CONTRACTOR_PROFILE_REQUEST";
export const GET_CONTRACTOR_PROFILE_SUCCESS = "GET_CONTRACTOR_PROFILE_SUCCESS";
export const  GET_CONTRACTOR_PROFILE_ERROR = "GET_CONTRACTOR_PROFILE_ERROR";

export const ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
export const ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
export const ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR = "ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR";

export const UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
export const UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
export const UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

export const DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
export const DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
export const DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

export const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
export const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
export const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

export const GET_EDUCATION_LEVEL_DICT_REQUEST = "GET_EDUCATION_LEVEL_DICT_REQUEST";
export const GET_EDUCATION_LEVEL_DICT_SUCCESS = "GET_EDUCATION_LEVEL_DICT_SUCCESS";
export const GET_EDUCATION_LEVEL_DICT_ERROR = "GET_EDUCATION_LEVEL_DICT_ERROR";

export const ADD_CONTRACTOR_EDUCATION_REQUEST = "ADD_CONTRACTOR_EDUCATION_REQUEST";
export const ADD_CONTRACTOR_EDUCATION_SUCCESS = "ADD_CONTRACTOR_EDUCATION_SUCCESS";
export const ADD_CONTRACTOR_EDUCATION_ERROR = "ADD_CONTRACTOR_EDUCATION_ERROR";

export const UPDATE_CONTRACTOR_EDUCATION_REQUEST = "UPDATE_CONTRACTOR_EDUCATION_REQUEST";
export const UPDATE_CONTRACTOR_EDUCATION_SUCCESS = "UPDATE_CONTRACTOR_EDUCATION_SUCCESS";
export const UPDATE_CONTRACTOR_EDUCATION_ERROR = "UPDATE_CONTRACTOR_EDUCATION_ERROR";

export const DELETE_CONTRACTOR_EDUCATION_REQUEST = "DELETE_CONTRACTOR_EDUCATION_REQUEST";
export const DELETE_CONTRACTOR_EDUCATION_SUCCESS = "DELETE_CONTRACTOR_EDUCATION_SUCCESS";
export const DELETE_CONTRACTOR_EDUCATION_ERROR = "DELETE_CONTRACTOR_EDUCATION_ERROR";

export const GET_DRIVER_LICENSE_DICTIONARY_REQUEST = "GET_DRIVER_LICENSE_DICTIONARY_REQUEST";
export const GET_DRIVER_LICENSE_DICTIONARY_SUCCESS = "GET_DRIVER_LICENSE_DICTIONARY_SUCCESS";
export const GET_DRIVER_LICENSE_DICTIONARY_ERROR = "GET_DRIVER_LICENSE_DICTIONARY_ERROR";

export const UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST = "UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST";
export const UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS = "UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS";
export const UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR = "UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR";

export const GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST";
export const GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS";
export const GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR";

export const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST";
export const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS";
export const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR";

export const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST";
export const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS";
export const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR";

export const ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
export const ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
export const ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

export const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
export const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
export const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

export const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST";
export const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS";
export const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR";

export const DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
export const DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
export const DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

export const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST";
export const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS";
export const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR";

export const UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST = "UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST";
export const UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS = "UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS";
export const UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR = "UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR";

export const MERGE_CONTRACTOR_PROF_SKILLS_REQUEST = "MERGE_CONTRACTOR_PROF_SKILLS_REQUEST";
export const MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS = "MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS";
export const MERGE_CONTRACTOR_PROF_SKILLS_ERROR = "MERGE_CONTRACTOR_PROF_SKILLS_ERROR";

export const ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST = "ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST";
export const ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS = "ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS";
export const ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR = "ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR";

export const GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST = "GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST";
export const GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS = "GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS";
export const GET_CONTRACTOR_PORTFOLIO_FILE_ERROR = "GET_CONTRACTOR_PORTFOLIO_FILE_ERROR";

export const DELETE_CONTRACTOR_PORTFOLIO_REQUEST = "DELETE_CONTRACTOR_PORTFOLIO_REQUEST";
export const DELETE_CONTRACTOR_PORTFOLIO_SUCCESS = "DELETE_CONTRACTOR_PORTFOLIO_SUCCESS";
export const DELETE_CONTRACTOR_PORTFOLIO_ERROR = "DELETE_CONTRACTOR_PORTFOLIO_ERROR";

export const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST";
export const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS";
export const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR";

export const UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST";
export const UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS";
export const UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR";

export const UPDATE_CONTRACTOR_MAIN_WORK_REQUEST = "UPDATE_CONTRACTOR_MAIN_WORK_REQUEST";
export const UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS = "UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS";
export const UPDATE_CONTRACTOR_MAIN_WORK_ERROR = "UPDATE_CONTRACTOR_MAIN_WORK_ERROR";

export const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST";
export const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS";
export const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR";

export const UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST = "UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST";
export const UPDATE_CONTRACTOR_MAIN_WORK_CITY_SUCCESS = "UPDATE_CONTRACTOR_MAIN_WORK_ADDRESS_SUCCESS";
export const UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR = "UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR";

export const GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST = "GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST";
export const GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS = "GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS";
export const GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR = "GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR";

export const CLEAR_CONTRACTOR_PROFILE_STORE = "CLEAR_CONTRACTOR_PROFILE_STORE";