import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import {BaseDirectoryControls} from "../../../../components/BasicDirectory/components/Controls";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {getSettingsDirectoriesAccessActions} from "../../../settings/directories/helpers/getAccessActions";
import {KedoDirectoriesTabs} from "../tabs";
import {KedoDirectoriesDocumentTypesEdit} from "./components/edit";

import {usePagination} from "../../../../hooks/usePagination";
import {useKedoDirectoryFetchCount} from "../hooks/useFetchCount";
import {useKedoDirectoriesSort} from "../hooks/useSort";
import {useKedoDocumentTypesAction} from "./hooks/useAction";
import {useKedoDocumentTypesFetch} from "./hooks/useFetch";
import {useKedoDocumentTypesFilter} from "./hooks/useFilter";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {LINK_CLIENT_INFO} from "../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";
import {
    kedoDirectoriesProgressActionSelector,
    kedoDirectoriesUploadDocumentTypesSelector,
} from "../../../../ducks/kedo/directories/selectors";

export const KedoDocumentTypes = (props) => {
    const clientId = props.match.params.clientId;

    const {
        fetchCounts,
    } = useKedoDirectoryFetchCount({
        clientId,
    });

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {
        onClickSort,
        sortType,
        sortOptions,
    } = useKedoDirectoriesSort();

    const {
        totalPages,
        totalCount,
        list,
        progress,
    } = useSelector(kedoDirectoriesUploadDocumentTypesSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = ls(USER_ROLE);
    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER, CLIENT_ADMIN],
        currentUserRestrictions,
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    } = useKedoDocumentTypesFilter({
        setPagination,
        pageSize,
        clientId,
    });

    const {
        fetchList,
    } = useKedoDocumentTypesFetch({
        filterData,
        pageNum,
        pageSize,
        clientId,
        sortType,
    });

    const {
        contentConfirm,
        editData,
        isOpenEdit,
        isOpenConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    } = useKedoDocumentTypesAction({
        fetchList,
        fetchCounts,
    });

    const getCompanies = ({clientId, clientName}) => {
        if (!clientId) {
            return "Все";
        }

        return (
            <ExtLink
                title="Перейти в карточку компании"
                to={LINK_CLIENT_INFO.replace(":clientId", clientId)}
            >
                {clientName}
            </ExtLink>
        );
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.documentTypeId,
                contentRow: (
                    <NmListCard
                        className="align-items-stretch"
                        primaryHeader={item.name}
                        secondaryHeaderStatus={
                            !item.clientId &&
                            <NmBadge
                                text="Тип КЭДО"
                                mod="orange"
                            />
                        }
                        noDivider
                        labels={[
                            {label: "Внешний идентификатор", text: item.externalId || "-"},
                            {label: "Код документа", text: item.documentCode || "-"},
                            {label: "Маршрут подписания", text: item.routeName || "-"},
                            !clientId && {label: "Компания", text: getCompanies(item)},
                        ]}
                        classNameMainContent="col-16 col-xxl-14"
                        isFixedActions
                        actions={
                            <BaseDirectoryControls
                                onClickEdit={() => {
                                    onOpenEdit(item);
                                }}
                                onClickDelete={() => {
                                    onOpenDelete(item);
                                }}
                                isVisibleControls={
                                    isAccessEdit &&
                                    (
                                        (
                                            clientId
                                            && item.clientId
                                            && [CLIENT_ADMIN].includes(role)
                                        )
                                        || [ADMIN].includes(role)
                                        || (
                                            [NM_MANAGER].includes(role)
                                            && item.clientId
                                        )
                                    )
                                }
                            />
                        }
                    />
                ),
            };
        });
    }, [list]);

    return (
        <>
            <KedoDirectoriesTabs
                clientId={clientId}
                isSearch={isSearch}
                uploadedDocumentTypesCount={totalCount}
                {...props}
            />
            <NmPage
                noPadding
                header={
                    <NmPageHeader text="Типы загруженных документов" />
                }
                controls={
                    isAccessEdit &&
                    <NmButton
                        size="xl"
                        onClick={() => {
                            onOpenEdit();
                        }}
                        icon={<AddIcon />}
                    >
                        Добавить тип
                    </NmButton>
                }
                filtersBase={
                    <Filter
                        initState={filterData}
                        filters={filters}
                        onSubmit={onSearch}
                        clearFilter={onClear}
                    />
                }
                typeFilter="vertical"
                totalCount={totalCount}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                isLoaded={progress}
            >
                {
                    isOpenEdit &&
                    <KedoDirectoriesDocumentTypesEdit
                        clientId={clientId}
                        fetchCounts={fetchCounts}
                        fetchList={fetchList}
                        onClose={onCloseEdit}
                        editData={editData}
                    />
                }
                {
                    isOpenConfirm &&
                    <NmConfirmV2
                        mobile="column"
                        isHiddenCancelOnMobile
                        disabled={progressAction}
                        onCancel={onCancelConfirm}
                        onConfirm={onConfirm}
                        content={contentConfirm}
                        confirmButton="Да"
                        cancelButton="Нет"
                        isNeedClosing={false}
                    />
                }
                {
                    list.length === 0 ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        /> :
                        <CheckboxList
                            rows={rows}
                            sort
                            sortOptions={sortOptions}
                            onClickSort={onClickSort}
                        />
                }
            </NmPage>
        </>
    );
};