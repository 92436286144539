import React from "react";
import {Link} from "react-router-dom";

import ExtLink from "../ExtLink";
import LinkMarkdown from "../LinkMarkdown";

import {getMonthName} from "../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../utils/localstorage";
import {
    formatAmount,
    handleNewLineString,
    phoneFormat,
} from "../../utils/stringFormat";

import {
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_ORDER_CARD,
} from "../../constants/links";
import {NOTIFICATION_MESSAGE, NOTIFICATION_TYPE} from "../../constants/notifications";
import {
    ADMIN,
    NM_MANAGER,
} from "../../constants/roles";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../constants/link-params";

/**
 * При выводе в строке перечисления определяет запятую
 * @param list<array>
 * @param index
 * @returns {string}
 */
export const getComma = (list, index) => list.length > 1 && index !== list.length - 1 ? ", " : "";

const NotificationsStoredAtFrontAndFormatted = props => {
    const {
        notification: {
            notificationData,
            notificationType,
            message: _message,
        },
        classNameMessage = "",
    } = props;
    const role = ls(USER_ROLE);

    // сообщение из коснтанты по типу
    const message = NOTIFICATION_MESSAGE[notificationType];

    if (!message || !notificationData) {
        return (
            <div className={classNameMessage}>
                <LinkMarkdown>
                    {handleNewLineString(_message)}
                </LinkMarkdown>
            </div>
        );
    }

    const {
        orderNum,
        orderName,
        orderId,
        clientId,
        objectName,
        contractorFio,
        fio,
        descriptionDispute,
        causeDispute,
        foremanFio,
        disputeStatusResult,
        disputeResult,
        periodFrom,
        periodTo,
        accepted,
        offeredSpecialityName,
        clientOfferedSpecialityName,
        amount,
        contractorPhone,
        orders,
        orderWorkReportEmailModelList,
        contractorId,
    } = notificationData;

    const getMessageForCancelledChecks = (formattedMessage, startDate, endDate) => {
        const link = [ADMIN, NM_MANAGER].includes(role) ? LINK_FINANCE_CANCELED_CHECKS : LINK_CLIENT_CANCELLED_CHECKS;
        const checksLinkStr = `${link}?startPeriod=${startDate}&endPeriod=${endDate}`;

        const checksLink = (
            <Link to={checksLinkStr}>
                аннулированные чеки
            </Link>
        );

        const splitMessage = formattedMessage.split(":link");

        splitMessage.splice(1, 0 , checksLink);

        return splitMessage;
    };

    // по аннулированным чекам за месяц
    if (notificationType === NOTIFICATION_TYPE.CANCELED_CHECKS_MONTH) {
        let {
            endDate,
            startDate,
        } = notificationData;

        const formattedMessage = message.replace(":start", startDate).replace(":end", endDate);

        startDate = startDate ? startDate.split(".").reverse().join("-") : "";
        endDate = endDate ? endDate.split(".").reverse().join("-") : "";

        return getMessageForCancelledChecks(formattedMessage, startDate, endDate);
    }

    // по аннулированным чекам за квартал
    if (notificationType === NOTIFICATION_TYPE.CANCELED_CHECKS_QUARTER) {
        let {
            endDate,
            startDate,
        } = notificationData;

        startDate = startDate ? startDate.split(".").reverse().join("-") : "";
        endDate = endDate ? endDate.split(".").reverse().join("-") : "";

        const formattedMessage = message.replace(":start", getMonthName(startDate)).replace(":end", getMonthName(endDate));

        return getMessageForCancelledChecks(formattedMessage, startDate, endDate);
    }

    if (notificationType === NOTIFICATION_TYPE.PAYMENT_NOT_CONFIRMED && orderWorkReportEmailModelList && orderWorkReportEmailModelList.length !== 0) {
        const splitMessage = message.split(":d");

        const paymentNumbers = orderWorkReportEmailModelList.map((item, index) => {
            const {
                paymentNumber,
                clientId,
            } = item;

            const link = LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId);

            return (
                <>
                    <ExtLink
                        historyEnabled={true}
                        isLoadDataTarget={true}
                        filterData={{
                            paymentNumber,
                        }}
                        to={link}
                    >
                        {paymentNumber}
                    </ExtLink>
                    {getComma(orderWorkReportEmailModelList, index)}
                </>
            );
        });

        const orderNums = orderWorkReportEmailModelList.map((item, index) => {
            const {
                orderNum,
                clientId,
                orderId,
            } = item;

            const link = LINK_ORDER_CARD
                .replace(":orderId", orderId)
                .replace(":clientId", clientId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

            return (
                <>
                    <Link to={link}>
                        {orderNum}
                    </Link>
                    {getComma(orderWorkReportEmailModelList, index)}
                </>
            );
        });

        // Строка разделена на 3 части,
        // вставляем на 2е место ссылки на номера платежей,
        // вставляем на 4е место ссылки на заказы
        splitMessage.splice(1, 0 , paymentNumbers);
        splitMessage.splice(3, 0 , orderNums);

        return splitMessage;
    }

    if (notificationType === NOTIFICATION_TYPE.ORDERS_EXPIRED && orders && orders.length !== 0) {
        const formattedMessage = message.replace(":amount", formatAmount(amount));
        const splitMessage = formattedMessage.split(":order");

        const orderList = orders.map((item, index) => {
            const {
                orderNum,
                name,
                orderId,
                clientId,
            } = item;

            const orderInfo = `${orderNum} "${name}"`;
            const orderLink = LINK_ORDER_CARD
                .replace(":orderId", orderId)
                .replace(":clientId", clientId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

            // index + 1 - порядковый номер строк
            return (
                <div>
                    {`${index + 1}. `}
                    <Link to={orderLink}>
                        {orderInfo}
                    </Link>
                </div>
            );
        });

        // Строка разделена на 2 части по разделителю,
        // вставляем в середину массива список ссылок на номера заказа
        splitMessage.splice(1, 0 , orderList);

        return splitMessage;
    }

    if (
        [
            NOTIFICATION_TYPE.CONTRACTOR_PERSONAL_DATA_ACCESS_ABSENT,
            NOTIFICATION_TYPE.CONTRACTOR_PERSONAL_DATA_ACCESS_GRANTED,
        ].includes(notificationType)
    ) {
        const formattedMessage = message.replace(":contractorPhone", phoneFormat(contractorPhone));
        const splitMessage = formattedMessage.split(":contractor");

        const contractorLink = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId);

        const contractor = <Link to={contractorLink}>
            {contractorFio}
        </Link>;

        splitMessage.splice(1, 0 , contractor);

        return splitMessage;
    }

    if ([NOTIFICATION_TYPE.PUBLISHING_REVIEW].includes(notificationType)) {
        const splitMessage = message.split(":contractor");

        const contractorLink = LINK_CONTRACTOR_REVIEWS_LIST.replace(":contractorId", contractorId);

        const contractor = <Link to={contractorLink}>
            {contractorFio}
        </Link>;

        splitMessage.splice(1, 0 , contractor);

        return splitMessage;
    }

    // по публикации ответа на отзыв
    if ([
        NOTIFICATION_TYPE.PUBLISHING_SCORE_REPLY,
        NOTIFICATION_TYPE.PUBLISHING_SCORE_REPLY_WITH_CORRECTION,
    ].includes(notificationType)) {
        const splitMessage = message
            .replace(":contractorFio", contractorFio)
            .split(":reviews");

        const reviews = <Link
            to={LINK_CLIENT_FEEDBACKS.replace(":clientId", clientId)}
        >
            "Отзывы"
        </Link>;

        splitMessage.splice(1, 0 , reviews);

        return splitMessage;
    }

    const orderInfo = `${orderNum} "${orderName}"`;
    const period = `${periodFrom} - ${periodTo}`;
    // для уведомления типа CLIENT_SPECIALITY_OFFER_RESULT_DECISION по ВД
    const acceptedText = accepted ? "одобрению" : "отклонению";
    const speciality = accepted ? offeredSpecialityName : clientOfferedSpecialityName;

    const formattedMessage = message
        .replace(":object", objectName)
        .replace(":contractorFio", contractorFio)
        .replace(":contractorPhone", phoneFormat(contractorPhone))
        .replace(":fio", fio)
        .replace(":descriptionDispute", descriptionDispute)
        .replace(":foremanFio", foremanFio)
        .replace(":causeDispute", causeDispute)
        .replace(":disputeStatusResult", disputeStatusResult)
        .replace(":disputeResult", disputeResult)
        .replace(":period", period)
        .replace(":acceptedText", acceptedText)
        .replace(":speciality", speciality);

    const splitMessage = formattedMessage.split(":order");

    const orderLink = LINK_ORDER_CARD
        .replace(":orderId", orderId)
        .replace(":clientId", clientId)
        .replace(":page", SELF_CONTRACTOR)
        .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
    const order = (
        <Link to={orderLink}>
            {orderInfo}
        </Link>
    );

    // В уведомлениях кроме NOTIFICATION_TYPE.PAYMENT_NOT_CONFIRMED и NOTIFICATION_TYPE.ORDERS_EXPIRED
    // строка делится на 2 части по разделителю, в середину массива (вместо разделителя) вставляем ссылку на заказ
    splitMessage.splice(1, 0 , order);

    return splitMessage;
};

export default NotificationsStoredAtFrontAndFormatted;