import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositList from "../list";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {
    addDeposit,
    commissionHistoryModesSelector,
    commissionHistoryProgressSelector,
    commissionHistoryTotalCountSelector,
    commissionHistoryTotalPagesSelector,
    depositProgressActionSelector,
    depositProgressListSelector,
    getDepositListSelector,
    getDepositListV2,
    getDepositTotalCountSelector,
    getDepositTotalPagesSelector,
    getRichCommissionHistory,
    getRichContractorCommissionHistory,
    returnDeposit,
    updateFieldDepositStore,
} from "../../../ducks/deposit";

const DepositAdminList = (props) => {
    const dispatch = useDispatch();

    const progressDepositAction = useSelector(depositProgressActionSelector);
    const list = useSelector(getDepositListSelector);
    const totalPages = useSelector(getDepositTotalPagesSelector);
    const totalCount = useSelector(getDepositTotalCountSelector);
    const progressList = useSelector(depositProgressListSelector);

    // История изменения комиссиии
    const historyList = useSelector(commissionHistoryModesSelector);
    const historyTotalPages = useSelector(commissionHistoryTotalPagesSelector);
    const historyProgress = useSelector(commissionHistoryProgressSelector);
    const historyTotalCount = useSelector(commissionHistoryTotalCountSelector);

    const onFetchList = ({nameSubstringFilter, pageNum, pageSize}) => {
        dispatch(getDepositListV2({
            nameSubstringFilter,
            pageNum,
            pageSize,
        }));
    };

    const onFetchCommissionHistory = (reqData) => {
        const {commissionType} = reqData;

        if (!isNullOrWhitespace(commissionType)) {
            dispatch(getRichContractorCommissionHistory(reqData));

            return;
        }

        dispatch(getRichCommissionHistory(reqData));
    };

    const onUpdateStore = (data) => {
        dispatch(updateFieldDepositStore(data));
    };

    const onAddDeposit = (data) => {
        dispatch(addDeposit(data));
    };

    const onReturnDeposit = (data) => {
        dispatch(returnDeposit(data));
    };

    return (
        <DepositList
            {...props}
            header="Депозит (НПД)"
            commissionHistory={{
                list: historyList,
                totalPages: historyTotalPages,
                historyProgress: historyProgress,
                historyTotalCount: historyTotalCount,
                onFetchList: onFetchCommissionHistory,
                onUpdateStore,
            }}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            onAddDeposit={onAddDeposit}
            onReturnDeposit={onReturnDeposit}
            onFetchList={onFetchList}
            progressAction={progressDepositAction}
            progressList={progressList}
        />
    );
};

export default DepositAdminList;