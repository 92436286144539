import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useDataForm from "./hooks/useFormData";

import {getOrdersOrderGroupsActionProgressSelector} from "../../../../../../ducks/bff/orders/order-groups/selectors";

const initialForm = {
    orderGroupName: "",
    comment: "",
};

function OrderGroupEditForm(props) {
    const {
        className,
        isEdit,
        onClose,
        card,
        clientId,
        fetchList,
    } = props;

    const actionProgress = useSelector(getOrdersOrderGroupsActionProgressSelector);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useDataForm({card, clientId, isEdit, fetchList, onClose});

    const {
        orderGroupName,
        comment,
    } = values;

    return (
        <NmModal
            className={className}
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование группы заказов" : "Создание группы заказов"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Создать"}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={actionProgress}

        >
            <NmForm>
                <NmTextareaV2
                    required
                    value={orderGroupName}
                    name="orderGroupName"
                    label="Название"
                    placeholder="Введите название группы заказов"
                    maxLength={100}
                    onChange={handleChange}
                    className="mb-4"
                    error={errors?.orderGroupName}
                />
                <NmTextareaV2
                    value={comment}
                    name="comment"
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    maxLength={255}
                    onChange={handleChange}
                />
            </NmForm>
        </NmModal>
    );
}

export default OrderGroupEditForm;