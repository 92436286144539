import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {REVIEW_STATUS} from "../index";

import {isNMUsers} from "../../../../utils/access";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    clearReviewsContractorStore,
    getPageReviewsContractor,
} from "../../../../ducks/bff/reviews/contractor/actionCreators";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

export function useContractorReviewsFetchList({contractorId, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsReviews,
                CLIENT_USER_RESTRICTIONS_VARIABLE.deleteContractorScores,
            ],
        }));
        
        return () => {
            dispatch(clearReviewsContractorStore());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getPageReviewsContractor({
            contractorIdFilter: contractorId,
            pageNum,
            pageSize,
            statusesFilter: isNMUsers() ? [REVIEW_STATUS.PUBLISHED, REVIEW_STATUS.DRAFT] : [REVIEW_STATUS.PUBLISHED],
        }));
    };

    return {
        fetchList,
    };
}