import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {USER_KEDO_INSTRUCTION_TYPE_DICT} from "../../../../../constants/dicts";

export const settingsInstructionsKedoSelector = state => state.bff.settingsInstructionsKedo;

export const kedoInstructionsSelector = ({list}) => {
    if (isEmpty(list)) {
        return [];
    }

    const filterListByInstructionType = (instructionType) => list.find(item => item.instructionType === instructionType) || {};


    return [
        filterListByInstructionType(USER_KEDO_INSTRUCTION_TYPE_DICT.KEDO_HR_WORK_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_KEDO_INSTRUCTION_TYPE_DICT.KEDO_HR_CRYPTO_PRO_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_KEDO_INSTRUCTION_TYPE_DICT.KEDO_HR_1C_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_KEDO_INSTRUCTION_TYPE_DICT.KEDO_CLIENT_USER_WORK_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_KEDO_INSTRUCTION_TYPE_DICT.KEDO_CLIENT_USER_GOS_KEY_INSTRUCTION.VALUE),
    ];
};

export const settingsInstructionsInfoGroupSelector = createSelector(
    settingsInstructionsKedoSelector,
    kedoInstructionsSelector,
);