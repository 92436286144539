import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorPortfolioEditModal from "./components/сontractor-portfolio-edit-modal";

import {isNMUsers} from "../../../../utils/access";
import {downloadLocalFile} from "../../../../utils/downloadBlob";

import {
    deleteContractorPortfolio,
    getContractorPortfolioFile,
} from "../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {contractorPortfolioListSelector} from "../../../../ducks/bff/contractor-сard/profile/selectors";

const PORTFOLIO_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const OPTIONS = [
    {
        key: PORTFOLIO_OPTION.EDIT,
        text: "Редактировать",
        value: PORTFOLIO_OPTION.EDIT,
    },
    {
        key: PORTFOLIO_OPTION.REMOVE,
        text: "Удалить",
        value: PORTFOLIO_OPTION.REMOVE,
    },
];

export default function ContractorPortfolio(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const portfolioList = useSelector(contractorPortfolioListSelector);

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const downloadFile = ({portfolioId, fileName}) => {
        dispatch(getContractorPortfolioFile({
            contractorId,
            fileName,
            portfolioId,
            getResult: (file) => {
                if (file) {
                    downloadLocalFile(file, fileName);
                }
            },
        }));
    };

    const onClickAction = ({value}, item) => {
        if (value === PORTFOLIO_OPTION.EDIT) {
            toggleEditModal(item);
            return;
        }

        if (value === PORTFOLIO_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem(item);
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?" ,
            });
            setShowConfirm(true);
        }
    };

    const deleteElem = (item) => {
        dispatch(deleteContractorPortfolio({
            contractorId,
            portfolioId: item.portfolioId,
            getResult: () => {
                getProfile();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    function mapContent() {
        if (!portfolioList?.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return portfolioList.map((item, index) => {
            const {fileName} = item;
            return (
                <div
                    className="d-flex flex-column mb-2"
                    key={index}
                >
                    <div className="flex flex-aligned-center justify-content-between mb-2">
                        <NmLabelText
                            label="Файл"
                            text={fileName}
                            classNameText="app-link"
                            onClickText={() => downloadFile(item)}
                        />
                        {isNMUsers() && <ContextMenu
                            notBorder
                            horizontalIcon
                            transform={false}
                            disabled={false}
                            options={OPTIONS}
                            onClickItem={(data) => {
                                onClickAction(data, item);
                            }}
                        />}
                    </div>
                    {index !== portfolioList.length - 1 && <div className="nm-line" />}
                </div>
            );
        });
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal &&
            <ContractorPortfolioEditModal
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                contractorId={contractorId}
                editData={editData}
            />
            }
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Портфолио"
                onClickAdd={() => toggleEditModal()}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}