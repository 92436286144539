import {
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS,
} from "./actions";

const initialState = {
    foremanContractors: [],
    progress: false,
    progressAction: false,
    totalCount: 0,
    pageData: {},
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS:
            const {
                foremanContractors,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                foremanContractors,
                totalCount,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST:
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS:
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR:
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};