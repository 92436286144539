import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../../utils/url";

const getController = (isNdlf) => {
    if (isNdlf) {
        return getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/finances/ndfl-deposit/replenishment-and-refunds",
                adminRolesUrl: "/adm/clients/client-card/finances/ndfl-deposit/replenishment-and-refunds",
            },
        );
    }

    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/npd-deposit/replenishment-and-refunds",
            adminRolesUrl: "/adm/clients/client-card/finances/npd-deposit/replenishment-and-refunds",
        },
    );
};

const getClientDepositReplenishmentAndRefundsPageSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(isCivil)}/get-history-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

const updateCommentClientDepositReplenishmentAndRefundsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(isCivil)}/update-client-deposit-history-comment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");
        onSuccess && onSuccess();

        yield put({
            type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

const editClientDepositReplenishmentAndRefundsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(isCivil)}/edit-history`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");
        onSuccess && onSuccess();

        yield put({
            type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, getClientDepositReplenishmentAndRefundsPageSaga),
        takeEvery(UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, updateCommentClientDepositReplenishmentAndRefundsSaga),
        takeEvery(EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, editClientDepositReplenishmentAndRefundsSaga),
    ]);
}