import React from "react";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import {TNmTooltipPosition} from "../ActualComponents/NmTooltip";
import Text, {TTextLevel} from "../ActualComponents/Text";
import BankIndicatorIcon from "./components/icon";
import BankIndicatorRefresh from "./components/refresh";
import {Loader} from "semantic-ui-react";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";

import bem from "../../utils/bem";
import {checkContractorPaymentPossibility} from "./utils/checkContractor";

import "./style.sass";

export enum RESULT_STATUS {
    // Проверка пройдена
    CHECK_PASSED = "CHECK_PASSED",
    // С ошибками
    CHECK_FAILED = "CHECK_FAILED",
    // На проверке
    CHECK_IN_PROGRESS = "CHECK_IN_PROGRESS",
    // Данные еще не направлялись в банк
    CHECK_NOT_PERFORMED = "CHECK_NOT_PERFORMED",
}

export type TCheck = {
    contractorId: string,
    errorCode: string,
    errorMessage: string,
    result: RESULT_STATUS,
    checkPlanned: boolean,
    resultReasons: Array<string>
}

interface IPaymentPossibleV2 {
    iconClass?: string,
    showLabel?: boolean,
    className?: string,
    textClassName?: string,
    contractorId: string,
    contractorsPossibility: Array<TCheck>,
    hideLoaderContent?: boolean,
    loaderSize?: SemanticSIZES,
    // размер иконки 18px / 20px
    size?: "md" | "lg",
    // для рефреша признака
    refreshPaymentPossibility?: () => void,
    // для анимации кнопки рефрега
    isRefresh?: boolean,
    timeoutForCheckPossible?: number,
    level?: TTextLevel,
    textMedium?: boolean,
    position?: TNmTooltipPosition,
    refreshProgress?: boolean
}

const BankIndicator = (props: IPaymentPossibleV2) => {
    const {
        isRefresh = false,
        refreshPaymentPossibility,
        className = "",
        iconClass = "",
        contractorId,
        level = "3",
        refreshProgress,
        size = "md",
        showLabel,
        loaderSize = "tiny",
        contractorsPossibility,
        position = "bottom-left",
        timeoutForCheckPossible,
        textClassName,
        textMedium,
    } = props;
    const [block, element] = bem("bank-indicator", className);

    const getErrors = (checks: Array<string>, result?: RESULT_STATUS) => {
        if (!result) {
            return null;
        }

        switch (result) {
            case RESULT_STATUS.CHECK_PASSED:
                return "Исполнитель прошел проверку в банке";
            case RESULT_STATUS.CHECK_FAILED:
                return (
                    <ul className={element("ul")}>
                        {
                            checks.map((message, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={element("error")}
                                    >
                                        {message}
                                    </li>
                                );
                            })
                        }
                    </ul>
                );
            case RESULT_STATUS.CHECK_IN_PROGRESS:
                return "Данные на проверке банком";
            case RESULT_STATUS.CHECK_NOT_PERFORMED:
                return "Данные еще не направлялись в банк";
            default: return;
        }
    };

    const {
        result,
        isVisibleIndicator,
        resultReasons,
        checkPlanned,
    } = checkContractorPaymentPossibility({contractorId, contractorsPossibility});

    const getSuggestion = () => {
        return (
            <>
                {getErrors(resultReasons, result)}
                {
                    typeof  timeoutForCheckPossible === "number" && timeoutForCheckPossible > 0 &&
                    <div className={element("warning")}>
                        {`Следующая проверка будет доступна через: ${timeoutForCheckPossible} секунд`}
                    </div>
                }
            </>
        );
    };

    return (
        <div className={block()}>
            <div className={element("icon-container", {size})}>
                {
                    isVisibleIndicator &&
                    <NmAdvancedTooltip
                        hover
                        className={element("advanced-tooltip")}
                        position={position}
                        children={getSuggestion()}
                        trigger={
                            <BankIndicatorIcon
                                size={size}
                                className={iconClass}
                                result={result}
                            />
                        }
                    />
                }
            </div>
            {
                showLabel &&
                <Text
                    level={level}
                    className={`${element("text")} ${textClassName}`}
                    medium={textMedium}
                >
                    Кошелек
                </Text>
            }
            {
                refreshPaymentPossibility && RESULT_STATUS.CHECK_NOT_PERFORMED !== result &&
                <BankIndicatorRefresh
                    progress={checkPlanned}
                    timeoutForCheckPossible={timeoutForCheckPossible}
                    isRefresh={isRefresh}
                    refreshPaymentPossibility={refreshPaymentPossibility}
                />
            }
            {
                (checkPlanned || refreshProgress) ?
                    <Loader
                        active
                        inline
                        size={loaderSize}
                        className={element("loader")}
                    /> :
                    null
            }
        </div>
    );
};

export default BankIndicator;