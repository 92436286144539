import {useState} from "react";
import {useDispatch} from "react-redux";

import {handleFormString} from "../../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {updateContractorForeignLanguage} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultForeignLanguagesForm = {
    language: "",
    languageLevel: "",
    foreignLanguagePresent: false,
};

export default function useUpdateForeignLanguages({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultForeignLanguagesForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        language,
        languageLevel,
        foreignLanguagePresent,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "foreignLanguagePresent" && checked) {
            setForm({
                ...defaultForeignLanguagesForm,
                foreignLanguagePresent: checked,
            });
            setError({});
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const validateForm = () => {
        if (foreignLanguagePresent) {
            return true;
        }

        if ((!language || !languageLevel)) {
            !language && setError(prevState => ({
                ...prevState,
                language: requiredMessage,
            }));
            !languageLevel && setError(prevState => ({
                ...prevState,
                languageLevel: requiredMessage,
            }));
            return false;
        }

        setError({});
        return true;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }
        dispatch(updateContractorForeignLanguage({
            contractorId,
            language: handleFormString(language),
            languageLevel: handleFormString(languageLevel),
            hasLanguages: !foreignLanguagePresent,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        error,
    };
}