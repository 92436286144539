import React from "react";

import {RecruitmentCandidatesEditForm} from "../../../../containers/recruitment/candidates/edit-form";

import {LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID} from "../constants";

export const LinkingResponseToCandidateRecruitmentNew = (props) => {
    return (
        <RecruitmentCandidatesEditForm
            {...props}
            formId={LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID}
            isRedirectToCard={false}
            isVisibleFunnelStage={true}
        />
    );
};
