import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {ReactComponent as ResetIcon} from "../../../images/reset_24.svg";
import DropzoneV2 from "../../ActualComponents/DropzoneV2";
import HelpTooltip from "../../ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../ActualComponents/NmConfirmV2";
import NmDadata from "../../ActualComponents/NmDadata";
import NmDatePicker from "../../ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";
import NmForm from "../../ActualComponents/NmForm";
import NmInputV2 from "../../ActualComponents/NmInputV2";
import NmModal from "../../ActualComponents/NmModal";
import NmRadioV2 from "../../ActualComponents/NmRadioV2";
import Text from "../../ActualComponents/Text";
import ApplyButtons from "../../ApplyButtons";
import {ImportMultipleValuesForm} from "../../ImportMultipleValuesForm";
import InputEmoji from "../../InputEmoji";
import NmTitle from "../../NmTitle";
import SmartLinksDropdown from "../../SmartLinksDropdown";
import Steps from "../../Steps";
import {NotificationSpecialityDropdown} from "../SpecialityDropdown";
import {Divider, Message} from "semantic-ui-react";

import {isAccessToClosedGroups} from "../../../utils/access";
import {
    compareDatesWithoutTime,
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
    today,
} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {
    formatAmount,
    phoneFormat,
    removePhoneMask,
} from "../../../utils/stringFormat";
import {convertStringToDate, handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

import {COLOR} from "../../../constants/color";
import {GENDER_OPTIONS} from "../../../constants/contractorInfo";
import {PHONE_MASK} from "../../../constants/phone";

import {updateStoreMarketingNotifications} from "../../../ducks/bff/marketing/notifications/actionCreators";
import {
    clientNameIdMapSelector,
    clientRoleMapSelector,
    getCountOfClientsForPushNotifications,
} from "../../../ducks/client";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    getPhonesByXlsxFile,
    getWorkRegionsOptionsSelector,
} from "../../../ducks/contractor";
import {getOrderCategoriesAllOptionsSelector} from "../../../ducks/orderCategories";
import {subCategoriesAllSelector} from "../../../ducks/orderSubcategories";
import {getSpecialitiesAllV2} from "../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

import {FILTER_SENDER, FILTER_TYPE, SEND_TYPE} from "../../../constants/ mailings/segmentationType";

const MAILING_NEW_STEP = {
    FIRST: [0],
    SECOND: [0, 1],
};

const MAILING_NEW_STEPS = [
    {name: "Основная информация"},
    {name: "Сегментация"},
];

const REQUIRED_MESSAGE = "Введите номер телефона полностью";

class MailingsEditForm extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        notification: PropTypes.object,
    };

    static defaultProps = {
        notification: {},
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);
        const {notification} = props;

        const {
            title,
            text,
            filterSender,
            filterType,
            dateTime,
            filesNameLinkList,
            name,
        } = notification;

        this.state = {
            error: {},
            title: title || "",
            name: name || "",
            text: text || "",
            dateTime: dateTime || null,
            files: filesNameLinkList || [],
            fileNames: [],
            fileError: "",
            filterSender: filterSender || FILTER_SENDER.BY_CONTRACTOR,
            filterType: filterType || FILTER_TYPE.BY_PHONE_NUMBERS,
            sendType: SEND_TYPE.IMMEDIATE.VALUE,
            isShowModal: false,
            recipientsCount: 0,
            isDisabledFileInput: false,
            notification,
            ...this.defaultDataByParams,
            ...this.defaultDataByPhones,
            activeSteps: [0],
        };
        this.role = ls(USER_ROLE);
        this.fileInputRef = React.createRef();
        this.steps = [
            {
                name: "Основная информация",
            },
            {
                name: "Сегментация",
            },
        ];
    }

    componentDidMount() {
        const {updateStoreMarketingNotifications} = this.props;

        this.getRecipientsCount();

        if (updateStoreMarketingNotifications) {
            updateStoreMarketingNotifications({card: {}});
        }
    }

    get defaultDataByParams() {
        const {notification} = this.props;

        const {
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
            contractorOnly,
            excludeClientGroupContractors,
            specialityIds,
            workRegions,
            categoryIds,
            clientIds,
            roles,
            addressFiasIds,
        } = notification;

        return {
            gender: gender || null,
            lastLoginDateFrom: convertStringToDate(lastLoginDateFrom, true) || null,
            lastLoginDateTo: convertStringToDate(lastLoginDateTo, true) || null,
            contractorOnly: contractorOnly || false,
            excludeClientGroupContractors: !isNullOrWhitespace(excludeClientGroupContractors) ? excludeClientGroupContractors : true,
            specialityIds: specialityIds || [],
            workRegions: workRegions || [],
            categoryIds: categoryIds || [],
            clientIds: clientIds || [],
            roles: roles || [],
            addressFiasIds: addressFiasIds || [],
        };
    }

    get defaultDataByPhones() {
        const {notification} = this.props;

        const {
            phones,
            smartLinkIdFilterEnabled,
        } = notification;

        let phonesWithMask = [];

        if (phones) {
            phonesWithMask = phones.map(phone => phoneFormat(phone));
        }

        return {
            phoneNumberList: phonesWithMask,
            smartLinkIdFilterEnabled,
        };
    }

    handleCheckValue = (value) => {
        if (value === FILTER_TYPE.ALL) {
            this.setState(prevState => ({
                ...prevState,
                ...this.defaultDataByParams,
                ...this.defaultDataByPhones,
            }));
            return;
        }

        if (value === FILTER_TYPE.BY_PHONE_NUMBERS) {
            this.setState(prevState => ({
                ...prevState,
                ...this.defaultDataByParams,
            }));
        }
    };

    handleCheckName = (name, value) => {
        if (name === "filterSender") {
            this.setState(prevState => ({
                ...prevState,
                filterType: value === FILTER_SENDER.BY_CLIENT ?
                    FILTER_TYPE.BY_PARAMS :
                    FILTER_TYPE.BY_PHONE_NUMBERS,
                ...this.defaultDataByParams,
                ...this.defaultDataByPhones,
            }));
        }
    };

    handleChange = (e, {value, checked, name}) => {
        this.handleCheckValue(value);
        this.handleCheckName(name, value);

        const {clientId} = this.props;

        const _state = {
            [name]: checked !== undefined ? checked : value,
        };

        if (
            clientId
            && name === "filterType"
            && value === FILTER_TYPE.BY_PARAMS
        ) {
            const {
                getSpecialitiesAllV2,
                clientId,
            } = this.props;

            getSpecialitiesAllV2({
                clientIdVisibilityQualifier: clientId,
                nameField: "clientVisibilitySpecialitiesDict",
            });
        }

        if (name === "orderFilterEnabled") {
            _state.smartLinkIdFilterEnabled = false;
            _state.smartLinkIdFilter = "";
        }

        if (name === "smartLinkIdFilterEnabled") {
            _state.orderFilterEnabled = false;
            _state.orderNumFilter = "";
        }

        if (name === "filterSender") {
            _state.orderFilterEnabled = false;
            _state.orderNumFilter = "";
            _state.smartLinkIdFilterEnabled = false;
            _state.smartLinkIdFilter = "";
        }

        if (name === "smartLinkIdFilterEnabled" && !checked) {
            _state.smartLinkIdFilter = "";
        }

        if (name === "orderFilterEnabled" && !checked) {
            _state.orderNumFilter = "";
        }

        this.setState(prevState => ({
            ...prevState,
            ..._state,
        }), () => {
            if (!["title", "text", "sendType", "dateTime"].includes(name)) {
                this.getRecipientsCount();
            }
        });
    };

    isValidForm = (data) => {
        const {
            filterType,
            addressFiasIds,
            specialityIds,
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
            phones,
            sendType,
            sendDateTime,
            text,
            title,
            contractorOnly,
            categoryIds,
            clientIds,
            roles,
            filterSender,
            orderFilterEnabled,
            orderNumFilter,
            smartLinkIdFilterEnabled,
            smartLinkIdFilter,
            name,
        } = data;

        let error = {};

        if (filterSender === FILTER_SENDER.BY_CONTRACTOR && filterType === FILTER_TYPE.BY_PARAMS &&
            !addressFiasIds.length &&
            !specialityIds.length &&
            !gender &&
            !lastLoginDateFrom &&
            !lastLoginDateTo &&
            !contractorOnly
        ) {
            error = {
                paramsError: "Заполните хотя бы один из предложенных фильтров",
            };
        }

        if (filterSender === FILTER_SENDER.BY_CLIENT && filterType === FILTER_TYPE.BY_PARAMS &&
            !clientIds.length &&
            !roles.length &&
            !categoryIds.length
        ) {
            error = {
                paramsError: "Заполните хотя бы один из предложенных фильтров",
            };
        }

        if (smartLinkIdFilterEnabled && isNullOrWhitespace(smartLinkIdFilter)) {
            error = {
                smartLinkIdFilter: "Обязательное поле",
            };
        }

        if (orderFilterEnabled && isNullOrWhitespace(orderNumFilter)) {
            error = {
                orderNumFilter: "Обязательное поле",
            };
        }

        if (isNullOrWhitespace(text)) {
            error = {
                ...error,
                text: "Обязательное поле",
            };
        }

        if (isNullOrWhitespace(name)) {
            error = {
                ...error,
                name: "Обязательное поле",
            };
        }

        if (isNullOrWhitespace(title)) {
            error = {
                ...error,
                title: "Обязательное поле",
            };
        }

        if (filterType === FILTER_TYPE.BY_PHONE_NUMBERS && !phones.length) {
            error = {
                ...error,
                phonesError: "Укажите хотя бы один номер телефона исполнителя",
            };
        }

        if (sendType === SEND_TYPE.DELAYED.VALUE && isNullOrWhitespace(sendDateTime)) {
            error = {
                ...error,
                sendDateTime: "Обязательное поле",
            };
        }

        this.setState({
            error,
        });

        return Object.keys(error).length === 0;
    };

    isValidFirstStep = (data) => {
        const {sendType} = this.state;
        const {
            text,
            title,
            name,
            sendDateTime,
        } = data;

        let error = {};

        if (isNullOrWhitespace(text)) {
            error = {
                ...error,
                text: "Обязательное поле",
            };
        }

        if (isNullOrWhitespace(name)) {
            error = {
                ...error,
                name: "Обязательное поле",
            };
        }

        if (isNullOrWhitespace(title)) {
            error = {
                ...error,
                title: "Обязательное поле",
            };
        }

        if (sendType === SEND_TYPE.DELAYED.VALUE && isNullOrWhitespace(sendDateTime)) {
            error = {
                ...error,
                sendDateTime: "Обязательное поле",
            };
        }

        this.setState({
            error,
        });

        return Object.keys(error).length === 0;
    };

    getRequestFormData() {
        const {
            text,
            name,
            title,
            files,
            filterSender,
            sendType,
            filterType,
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
            specialityIds,
            contractorOnly,
            excludeClientGroupContractors,
            addressFiasIds,
            phoneNumberList: phones,
            dateTime: sendDateTime,
            categoryIds,
            clientIds,
            roles,
            notification,
            orderFilterEnabled,
            orderNumFilter,
            smartLinkIdFilterEnabled,
            smartLinkIdFilter,
        } = this.state;

        const {
            filesNameLinkList: filesNameList,
            taskId,
        } = notification;

        const filesNameLinkList = filesNameList || [];
        const formData = new FormData();
        formData.append("name", name);
        formData.append("text", text);
        formData.append("title", title);
        formData.append("sendType", sendType);
        formData.append("filterSender", filterSender);
        formData.append("filterType", filterType);


        files.filter(({name}) => !filesNameLinkList.find(item => item.name === name)).forEach(file => {
            formData.append("files", file);
        });

        taskId && formData.append("oldTaskId", taskId);

        const oldFiles = files.filter(({link}) => !!link);
        oldFiles.forEach(file => {
            formData.append("filesNames", file.name);
        });

        if (filterType === FILTER_TYPE.BY_PHONE_NUMBERS) {
            formData.append("phones", phones.map(item => removePhoneMask(item)));
        } else if (filterType === FILTER_TYPE.BY_PARAMS && filterSender === FILTER_SENDER.BY_CONTRACTOR) {
            formData.append("gender", gender);
            lastLoginDateFrom && formData.append("lastLoginDateFrom", getStartFilterDateWithTimeInUtc(lastLoginDateFrom));
            lastLoginDateTo && formData.append("lastLoginDateTo", getEndFilterDateWithTimeInUtc(lastLoginDateTo));
            formData.append("specialityIds", specialityIds);
            formData.append("contractorOnly", contractorOnly);
            formData.append("addressFiasIds", addressFiasIds);
        } else if (filterType === FILTER_TYPE.BY_PARAMS && filterSender === FILTER_SENDER.BY_CLIENT) {
            formData.append("categoryIds", categoryIds);
            formData.append("clientIds", clientIds);
            formData.append("roles", roles);
        }
        if (sendType === SEND_TYPE.DELAYED.VALUE) {
            formData.append("sendDateTime", sendDateTime.toISOString());
        }
        if (filterSender === FILTER_SENDER.BY_CONTRACTOR) {
            formData.append("excludeClientGroupContractors", excludeClientGroupContractors);
        }

        smartLinkIdFilterEnabled && formData.append("smartLinkIdFilterEnabled", smartLinkIdFilterEnabled);
        smartLinkIdFilterEnabled && formData.append("smartLinkIdFilter", handleFormString(smartLinkIdFilter));
        orderFilterEnabled && formData.append("orderFilterEnabled", orderFilterEnabled);
        orderFilterEnabled && formData.append("orderNumFilter", handleFormString(orderNumFilter));

        return formData;
    }

    //Отбираем данные для отправки, в зависимости от выбранных радио кнопок
    getRequestData() {
        const {clientId} = this.props;
        const {
            text,
            title,
            filterSender,
            sendType,
            filterType,
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
            specialityIds,
            contractorOnly,
            excludeClientGroupContractors,
            addressFiasIds,
            phoneNumberList: phones,
            dateTime: sendDateTime,
            categoryIds,
            clientIds,
            roles,
            orderFilterEnabled,
            smartLinkIdFilterEnabled,
            smartLinkIdFilter,
            orderNumFilter,
            name,
        } = this.state;

        let data = {
            text,
            title,
            sendType,
            filterSender,
            filterType,
            name,
        };

        if (filterType === FILTER_TYPE.BY_PHONE_NUMBERS) {
            data = {
                ...data,
                phones: phones.map(item => removePhoneMask(item)),
            };
        } else if (filterType === FILTER_TYPE.BY_PARAMS && filterSender === FILTER_SENDER.BY_CONTRACTOR) {
            if (clientId) {
                data = {
                    ...data,
                    gender,
                    specialityIds,
                    addressFiasIds,
                    orderFilterEnabled,
                    orderNumFilter: orderFilterEnabled ? handleFormString(orderNumFilter) : undefined,
                };
            } else {
                data = {
                    ...data,
                    gender,
                    lastLoginDateFrom: getStartFilterDateWithTimeInUtc(lastLoginDateFrom),
                    lastLoginDateTo: getEndFilterDateWithTimeInUtc(lastLoginDateTo),
                    specialityIds,
                    contractorOnly,
                    addressFiasIds,
                    orderFilterEnabled,
                    smartLinkIdFilterEnabled,
                    smartLinkIdFilter: orderFilterEnabled ? smartLinkIdFilter : undefined,
                    orderNumFilter: orderFilterEnabled ? handleFormString(orderNumFilter) : undefined,
                };
            }
        } else if (filterType === FILTER_TYPE.BY_PARAMS && filterSender === FILTER_SENDER.BY_CLIENT) {
            data = {
                ...data,
                categoryIds,
                clientIds,
                roles,
            };
        }

        if (sendType === SEND_TYPE.DELAYED.VALUE) {
            data = {
                ...data,
                sendDateTime,
            };
        }

        if (filterSender === FILTER_SENDER.BY_CONTRACTOR) {
            if (clientId) {
                data = {
                    ...data,
                    orderFilterEnabled,
                    orderNumFilter: orderFilterEnabled ? handleFormString(orderNumFilter) : undefined,
                };
            } else {
                data = {
                    ...data,
                    orderFilterEnabled,
                    smartLinkIdFilterEnabled,
                    smartLinkIdFilter: smartLinkIdFilterEnabled ? smartLinkIdFilter : undefined,
                    orderNumFilter: orderFilterEnabled ? handleFormString(orderNumFilter) : undefined,
                    excludeClientGroupContractors,
                };
            }
        }

        return data;
    }

    submit = () => {
        const {
            addNotification,
        } = this.props;
        const {
            recipientsCount,
        } = this.state;

        const data = this.getRequestData();

        if (!this.isValidForm(data)) {
            return;
        }

        const {
            clientId,
        } = this.props;

        if (clientId) {
            addNotification({
                data,
                handleResponse: this.handleResponseAdd,
            });

            return;
        }

        const formData = this.getRequestFormData();

        if (recipientsCount === 0) {
            this.setState({
                isShowModal: true,
            });

            return;
        }

        addNotification({
            formData,
            handleResponse: this.handleResponseAdd,
        });
    };

    toSecondPage = () => {
        const data = this.getRequestData();

        if (!this.isValidFirstStep(data)) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            activeSteps: MAILING_NEW_STEP.SECOND,
        }));
    };

    handleResponseAdd = (result) => {
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return;
        }

        toastSuccess("Рассылка успешно создана");

        const {
            onClose,
        } = this.props;

        onClose();
    };

    closeModal = () => {
        this.setState({
            isShowModal: false,
        });
    };

    renderModalWindow = () => {
        const {isShowModal, filterSender} = this.state;

        if (!isShowModal) {return;}

        const content = filterSender === FILTER_SENDER.BY_CONTRACTOR ? "Под заданные параметры не подходит ни один исполнитель. Пожалуйста, укажите другие параметры."
            : "Под заданные параметры не подходит ни одна компания. Пожалуйста, укажите другие параметры";

        return (
            <NmConfirmV2
                size="sm"
                content={content}
                onCancel={this.closeModal}
                onConfirm={this.closeModal}
                confirmButton="ОК"
                isOnlyConfirm
                mobile="column"
            />
        );
    };

    mapToOptions(arr) {
        return arr.map(item => ({
            key: item.id,
            text: item.value,
            value: item.id,
        }));
    }

    get subCategoriesAllOptions() {
        const {subCategoriesAll} = this.props;
        const {categoryIds} = this.state;

        if (isEmpty(categoryIds)) {
            return this.mapToOptions(subCategoriesAll);
        }

        return this.mapToOptions(subCategoriesAll.filter(item => item.categoryIds.find(categoryId => categoryIds.find(itemsub => itemsub === categoryId))));
    }

    renderContractorParamsBlock() {
        const {
            contractorOnly,
            specialityIds,
            addressFiasIds,
            error,
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
        } = this.state;

        const {
            clientId,
        } = this.props;

        return (
            <>
                <NmDadata
                    size="xl"
                    multiple
                    isAllSelectionShown={false}
                    name="addressFiasIds"
                    label="Города ведения деятельности"
                    toBound="settlement"
                    placeholder="Выберите города ведения деятельности"
                    fromBound="city"
                    onChange={this.handleChange}
                    initialAddressIds={addressFiasIds}
                />
                <NotificationSpecialityDropdown
                    clientId={clientId}
                    value={specialityIds}
                    onChange={this.handleChange}
                />
                <div className="row gx-4 gy-4">
                    {
                        !clientId &&
                        <div className="col-16 col-md-8">
                            <NmDateRangePickerV2
                                size="xl"
                                startFieldName="lastLoginDateFrom"
                                endFieldName="lastLoginDateTo"
                                value={{
                                    lastLoginDateFrom,
                                    lastLoginDateTo,
                                }}
                                isClearable={true}
                                label="Дата последнего визита"
                                onChange={this.handleChange}
                            />
                        </div>
                    }
                    <div className={clientId ? "col-16" : "col-16 col-md-8"}>
                        <NmDropdownV2
                            size="xl"
                            className="mailings-edit-segmentation__params-multi-dropdown"
                            placeholder="Выберите пол"
                            label="Пол"
                            name="gender"
                            value={gender}
                            onChange={this.handleChange}
                            options={GENDER_OPTIONS}
                        />
                    </div>
                </div>
                {
                    !clientId &&
                    <NmCheckboxV2
                        name="contractorOnly"
                        label="Отправить только самозанятым"
                        checked={contractorOnly}
                        onChange={this.handleChange}
                    />
                }
                {error.paramsError && <Message negative>
                    {error.paramsError}
                </Message>}
            </>
        );
    }

    renderClientParamsBlock() {
        const {
            error,
            categoryIds,
            clientIds,
            roles,
        } = this.state;

        const {
            categoriesAllOptions,
            clientAllOptions,
            roleAllOptions,
        } = this.props;

        return (
            <>
                <NmDropdownV2
                    className="mailings-edit-segmentation__params-multi-dropdown"
                    search
                    label="Категории"
                    name="categoryIds"
                    options={categoriesAllOptions}
                    value={categoryIds}
                    onChange={this.handleChange}
                    multiple
                />
                <NmDropdownV2
                    className="mailings-edit-segmentation__params-multi-dropdown"
                    search
                    label="Заказчик"
                    name="clientIds"
                    options={clientAllOptions}
                    value={clientIds}
                    onChange={this.handleChange}
                    multiple
                />
                <NmDropdownV2
                    className="mailings-edit-segmentation__params-multi-dropdown"
                    search
                    label="Роли сотрудников"
                    name="roles"
                    options={roleAllOptions}
                    value={roles}
                    onChange={this.handleChange}
                    multiple
                />
                {error.paramsError && <Message negative>
                    {error.paramsError}
                </Message>}
            </>
        );
    }

    setAnimationRefresh = (spin) => {
        this.setState({spin});
    };

    getRecipientsCount = () => {
        const {
            getContractorCount,
            getCountOfClientsForPushNotifications,
        } = this.props;
        this.setAnimationRefresh(true);

        const {
            phones,
            gender,
            lastLoginDateFrom,
            lastLoginDateTo,
            specialityIds,
            categoryIds,
            contractorOnly,
            filterSender,
            filterType,
            clientIds,
            roles,
            addressFiasIds,
            excludeClientGroupContractors,
        } = this.getRequestData();

        if (
            (filterType === FILTER_TYPE.BY_PHONE_NUMBERS && !phones.length) ||
            (
                filterSender === FILTER_SENDER.BY_CLIENT &&
                filterType === FILTER_TYPE.BY_PARAMS &&
                !categoryIds.length &&
                !clientIds.length &&
                !roles.length
            )
        ) {
            this.setRecipientsCount(0);

            return;
        }

        if (filterSender === FILTER_SENDER.BY_CONTRACTOR) {
            const {clientId} = this.props;
            const clientData = {
                phones,
                gender,
                specialityIds,
                addressFiasIds,
            };

            getContractorCount({
                data: clientId
                    ? clientData
                    : {
                        ...clientData,
                        lastLoginDateFrom: getStartFilterDateWithTimeInUtc(lastLoginDateFrom),
                        lastLoginDateTo: getEndFilterDateWithTimeInUtc(lastLoginDateTo),
                        contractorOnly,
                        excludeClientGroupContractors,

                    },
                handleResponse: this.setRecipientsCount,
            });

            return;
        }

        getCountOfClientsForPushNotifications({
            data: {
                categoryIds,
                clientIds,
                roles,
            },
            handleResponse: this.setRecipientsCount,
        });
    };

    setRecipientsCount = (recipientsCount) => {
        this.setState({
            recipientsCount,
        });
        setTimeout(() => {
            this.setAnimationRefresh(false);
        }, 500);
    };

    uploadPhones = ({target}) => {
        const {
            getPhonesByXlsxFile,
            clientId,
        } = this.props;

        const file = target.files[0];

        if (!file) {
            return;
        }

        this.setState({
            error: {
                phonesError: "",
            },
        });

        const {phoneNumberList = []} = this.state;

        const formData = new FormData();
        formData.append("phonesFile", file);

        phoneNumberList.forEach(phone => {
            formData.append("oldPhoneSet", removePhoneMask(phone));
        });

        getPhonesByXlsxFile({
            clientId,
            formData,
            handleResponse: this.addPhones,
        });

        target.value = "";
    };

    addPhones = (response) => {
        const {set: phones, message: phonesError} = response;

        if (phonesError) {
            this.setState({
                error: {
                    phonesError,
                },
            });
        }

        toastSuccess("Файл успешно загружен");

        this.setState(prevState => ({
            ...prevState,
            successNumbers: phones.map(phone => phoneFormat(phone)),
        }));
    };

    checkExists = (params) => {
        const {
            addContractor,
        } = this.props;

        const {
            number,
            saveNumber,
        } = params;

        addContractor({
            data: {
                phoneFilter: removePhoneMask(number),
            },
            saveNumber: (result) => {
                const {
                    isSuccess,
                    isNotFound,
                } = result;

                saveNumber({
                    success: isSuccess,
                });

                if (isNotFound) {
                    this.setState({
                        error: {
                            phonesError: "Исполнитель с данным номером не найден",
                        },
                    });
                }
            },
        });
    };

    renderPhoneNumberBlock() {
        const {
            phoneNumberList,
            successNumbers,
            error,
        } = this.state;

        const {
            progressAction,
        } = this.props;

        return (
            <ImportMultipleValuesForm
                confirmTextDeleteAll="Вы действительно хотите удалить все номера телефонов из рассылки?"
                onChange={(event, {value}) => {
                    this.setState({
                        phoneNumberList: value,
                    }, this.getRecipientsCount);
                }}
                addMask={(value) => {
                    return phoneFormat(removePhoneMask(value));
                }}
                inputLabel="Телефон"
                showDeleteAllCount={2}
                deletingConfirmText="Вы действительно хотите удалить номер телефона из рассылки?"
                inputMask={PHONE_MASK.FOREIGN}
                nums={phoneNumberList}
                checkExists={this.checkExists}
                requiredMessage={REQUIRED_MESSAGE}
                getValidation={(yup) => {
                    return yup
                        .transform(removePhoneMask)
                        .min(11, REQUIRED_MESSAGE);
                }}
                inputPlaceholder="Введите номер телефона"
                progress={progressAction}
                patternLink="/files/Шаблон_создания_ссылки_по_номерам.xlsx"
                headerAllNumbers="Номера телефонов"
                onClickImportXlsx={() => {
                    this.fileInputRef.current.click();
                }}
                error={error.phonesError}
                successNumbers={successNumbers}
                progressImport={progressAction}
                onChangeFileInput={this.uploadPhones}
                updateError={({error}) => {
                    this.setState({
                        error: {
                            phonesError: error,
                        },
                    });
                }}
                setIsHiddenModal={(isHiddenModal) => {
                    this.setState({
                        isHiddenModal,
                    });
                }}
                duplicateErrorMessage="Исполнитель с данным номером уже добавлен"
            />
        );
    }

    renderAssignedBlock() {
        const {
            currentMember,
            clientId,
        } = this.props;
        const {
            filterSender,
            filterType,
            excludeClientGroupContractors,
        } = this.state;

        return (
            <>
                <div className="mailings-edit__assigned-block">
                    <NmRadioV2
                        label="Все"
                        checked={filterType === FILTER_TYPE.ALL}
                        onChange={() => {
                            this.handleChange(null, {value: FILTER_TYPE.ALL, name: "filterType"});
                        }}
                    />
                    <NmRadioV2
                        className="mailings-edit__assigned-block-item"
                        label="По параметрам"
                        checked={filterType === FILTER_TYPE.BY_PARAMS}
                        onChange={() => {
                            this.handleChange(null, {
                                value: FILTER_TYPE.BY_PARAMS,
                                name: "filterType",
                            });
                        }}
                    />
                    {
                        filterSender === FILTER_SENDER.BY_CONTRACTOR &&
                        <div className="mailings-edit__assigned-block-item">
                            <NmRadioV2
                                label="По номеру телефона"
                                checked={filterType === FILTER_TYPE.BY_PHONE_NUMBERS}
                                onChange={() => {
                                    this.handleChange(null, {
                                        value: FILTER_TYPE.BY_PHONE_NUMBERS,
                                        name: "filterType",
                                    });
                                }}
                            />
                            <div className="mailings-edit-card__tooltip">
                                <HelpTooltip
                                    type="light"
                                    text="Push-уведомления будут отправлены исполнителям с указанными номерами телефона"
                                />
                            </div>
                        </div>
                    }
                </div>
                {filterSender === FILTER_SENDER.BY_CLIENT && filterType === FILTER_TYPE.BY_PARAMS && this.renderClientParamsBlock()}
                {filterSender === FILTER_SENDER.BY_CONTRACTOR && filterType === FILTER_TYPE.BY_PARAMS && this.renderContractorParamsBlock()}
                {filterType === FILTER_TYPE.BY_PHONE_NUMBERS && this.renderPhoneNumberBlock()}
                {
                    filterSender === FILTER_SENDER.BY_CONTRACTOR && !clientId &&
                    <NmCheckboxV2
                        disabled={!isAccessToClosedGroups(currentMember.email)}
                        name="excludeClientGroupContractors"
                        label="Исключить исполнителей из закрытого контура"
                        checked={excludeClientGroupContractors}
                        onChange={this.handleChange}
                    />
                }
            </>
        );
    }

    get minTime() {
        const {
            dateTime,
        } = this.state;

        const {
            iso: {
                start,
            },
            current: {
                now: currentNow,
            },
        } = today;

        if (!isNullOrWhitespace(dateTime) && !compareDatesWithoutTime(dateTime, currentNow())) {
            return start();
        }

        return currentNow();
    }

    onDropFile = files => {
        this.setState({fileError: ""});

        const {files: filesState} = this.state;

        if (filesState.length + files.length >= 20) {
            this.setState({
                isDisabledFileInput: true,
            });
        }

        this.setState(prevState => {
            return {
                ...prevState,
                files,
            };
        });
    };

    renderCard() {
        const {clientId} = this.props;
        const {
            text,
            title,
            name,
            files,
            sendType,
            error,
            fileError,
            isDisabledFileInput,
        } = this.state;

        return (
            <NmForm addMargin>
                <NmInputV2
                    maxLength={50}
                    size="xl"
                    label="Наименование рассылки"
                    placeholder="Введите наименование рассылки"
                    name="name"
                    value={name}
                    required={true}
                    error={error.name}
                    onChange={this.handleChange}
                    tooltip={
                        clientId &&
                        <HelpTooltip
                            info
                            hover
                            text="Пользовательское название рассылки, у исполнителей не отображается"
                            position="bottom-left"
                        />
                    }
                />
                <InputEmoji
                    label="Заголовок"
                    placeholder="Например: Найдены неподтвержденные выплаты"
                    name="title"
                    value={title}
                    required={true}
                    error={error.title}
                    type="textarea"
                    minRows={1}
                    maxRows={2}
                    maxLength={50}
                    onChange={this.handleChange}
                    tooltip={
                        clientId &&
                        <HelpTooltip
                            info
                            hover
                            text="Заголовок уведомления для исполнителей"
                            position="bottom-left"
                        />
                    }
                />
                <InputEmoji
                    label="Содержание"
                    placeholder="Подробно опишите суть рассылки"
                    name="text"
                    value={text}
                    required={true}
                    error={error.text}
                    type="textarea"
                    maxLength={3000}
                    minRows={3}
                    maxRows={500}
                    onChange={this.handleChange}
                    tooltip={
                        clientId &&
                        <HelpTooltip
                            info
                            hover
                            text="Содержание уведомления для исполнителей"
                            position="bottom-left"
                        />
                    }
                />
                {
                    !clientId &&
                    <div>
                        <DropzoneV2
                            files={files}
                            maxSize={10}
                            isEqualCheck
                            multiple
                            onChange={this.onDropFile}
                            format="*.DOC, *.DOCX, *.PDF"
                            accept={".doc, .docx, .pdf"}
                            className="mt-4"
                            innerLabel="Выберите или перетащите файлы"
                            disabled={isDisabledFileInput}
                        />
                        {
                            fileError &&
                            <Message negative>
                                {fileError}
                            </Message>
                        }
                    </div>
                }
                <Text
                    medium
                    type="sub"
                >
                    Дата и время отправки
                </Text>
                <div className="flex flex-column">
                    <NmRadioV2
                        label="Отправить сразу"
                        checked={sendType === SEND_TYPE.IMMEDIATE.VALUE}
                        onChange={() => {
                            this.handleChange(null, {value: SEND_TYPE.IMMEDIATE.VALUE, name: "sendType"});
                        }}
                    />
                    <NmRadioV2
                        className="mt-2"
                        label="Выбрать дату и время"
                        checked={sendType === SEND_TYPE.DELAYED.VALUE}
                        onChange={() => {
                            this.handleChange(null, {value: SEND_TYPE.DELAYED.VALUE, name: "sendType"});
                        }}
                    />
                </div>
                {sendType === SEND_TYPE.DELAYED.VALUE && this.renderDatepicker()}
            </NmForm>
        );
    }

    renderDatepicker() {
        const {
            dateTime,
            sendType,
            error,
        } = this.state;

        return (
            <NmDatePicker
                showTimeSelect
                timeCaption="Время"
                disabled={sendType === SEND_TYPE.IMMEDIATE.VALUE}
                minDate={new Date()}
                timeIntervals={1}
                placeholderText="00.00.00"
                dateFormat="dd.MM.yy HH:mm"
                dateFormatMask="99.99.99 99:99"
                maxTime={today.iso.end()}
                minTime={this.minTime}
                selected={dateTime}
                error={error.sendDateTime}
                name="dateTime"
                onChange={this.handleChange}
                isClearable
                className="mt-15 mailings-edit-card__datepicker"
            />
        );
    }

    renderSegmentation() {
        const {clientId} = this.props;
        const {
            filterSender,
            recipientsCount,
            spin,
            orderNumFilter,
            orderFilterEnabled,
            smartLinkIdFilter,
            smartLinkIdFilterEnabled,
            error,
        } = this.state;

        return (
            <NmForm addMargin>
                {
                    !clientId &&
                    <div className="flex">
                        <NmRadioV2
                            label="Исполнители"
                            checked={filterSender === FILTER_SENDER.BY_CONTRACTOR}
                            onChange={() => {
                                this.handleChange(null, {value: FILTER_SENDER.BY_CONTRACTOR, name: "filterSender"});
                            }}
                        />
                        <NmRadioV2
                            className="ms-3"
                            label="Заказчики"
                            checked={filterSender === FILTER_SENDER.BY_CLIENT}
                            onChange={() => {
                                this.handleChange(null, {
                                    value: FILTER_SENDER.BY_CLIENT,
                                    name: "filterSender",
                                });
                            }}
                        />
                    </div>
                }
                <Divider />
                {this.renderAssignedBlock()}
                <div className="flex flex-aligned-center">
                    <div>
                        Количество получателей:
                        <div className="mailings-edit-segmentation__counter">
                            {" "}
                            {formatAmount(recipientsCount)}
                        </div>
                    </div>
                    <ResetIcon
                        className={`material-icons notranslate mailings-edit-segmentation__refresh ml-2 ${spin ? "spin-animation" : ""}`}
                        onClick={this.getRecipientsCount}
                        color={COLOR.SECONDARY_45}
                    />
                </div>
                {filterSender === FILTER_SENDER.BY_CONTRACTOR &&
                    <div className="d-flex flex-column">
                        <NmCheckboxV2
                            name="orderFilterEnabled"
                            label="Ссылка на заказ"
                            checked={orderFilterEnabled}
                            onChange={this.handleChange}
                        />
                        {
                            !clientId &&
                            <NmCheckboxV2
                                className="mt-2"
                                name="smartLinkIdFilterEnabled"
                                label="Ссылка на подборку заказов/заданий"
                                checked={smartLinkIdFilterEnabled}
                                onChange={this.handleChange}
                            />
                        }
                    </div>
                }
                {
                    filterSender === FILTER_SENDER.BY_CONTRACTOR && smartLinkIdFilterEnabled &&
                    <SmartLinksDropdown
                        required={true}
                        name="smartLinkIdFilter"
                        label="Название ссылки"
                        placeholder="Выберите ссылку по ее названию"
                        value={smartLinkIdFilter}
                        error={error.smartLinkIdFilter}
                        onChange={this.handleChange}
                    />
                }
                {filterSender === FILTER_SENDER.BY_CONTRACTOR && orderFilterEnabled &&
                    <div className="flex flex-aligned-center mt-2">
                        <NmInputV2
                            required={true}
                            size="xl"
                            error={error.orderNumFilter}
                            mask="999999999999999999"
                            maskChar={null}
                            label="Номер заказа"
                            placeholder="Введите номер заказа"
                            value={orderNumFilter}
                            name="orderNumFilter"
                            onChange={this.handleChange}
                        />
                    </div>
                }
            </NmForm>
        );
    }

    renderFooter() {
        const {onClose} = this.props;
        const {activeSteps} = this.state;

        const isFirstStep = isEqual(activeSteps, MAILING_NEW_STEP.FIRST);

        return (
            <ApplyButtons
                isHiddenCancelOnMobile
                mobile="column"
                submit={() => {
                    isFirstStep ? this.toSecondPage() : this.submit();
                }}
                onClose={onClose}
                submitBtnContent={isFirstStep ? "Далее" : "Сохранить"}
                cancelBtnContent="Отменить"
                additionalBtnContent={!isFirstStep && "Назад"}
                onClickAdditional={() => {
                    this.setState(prevState => ({
                        ...prevState,
                        activeSteps: MAILING_NEW_STEP.FIRST,
                    }));
                }}
            />
        );
    }

    render() {
        const {
            progressAdd,
            onClose,
        } = this.props;
        const {activeSteps, isHiddenModal} = this.state;

        return (
            <NmModal
                size="md"
                onClose={onClose}
                header={
                    <NmTitle size="lg">
                        Создание рассылки
                    </NmTitle>
                }
                footer={this.renderFooter()}
                loading={progressAdd}
                classNameContent="d-flex flex-column"
                isHiddenModal={isHiddenModal}
            >
                {this.renderModalWindow()}
                <Steps
                    className="mb-2 mb-md-4"
                    items={MAILING_NEW_STEPS}
                    activeItems={activeSteps}
                />
                {
                    isEqual(activeSteps, MAILING_NEW_STEP.FIRST) ?
                        this.renderCard() : this.renderSegmentation()
                }
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        workRegionsOptions: getWorkRegionsOptionsSelector(state),
        categoriesAllOptions: getOrderCategoriesAllOptionsSelector(state),
        subCategoriesAll: subCategoriesAllSelector(state),
        clientAllOptions: clientNameIdMapSelector(state),
        roleAllOptions: clientRoleMapSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        getSpecialitiesAllV2,
        getCountOfClientsForPushNotifications,
        getPhonesByXlsxFile,
        updateStoreMarketingNotifications,
    },
)(withTranslation()(MailingsEditForm));