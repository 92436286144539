export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_ERROR";

export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_ERROR";

export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_REQUEST = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_REQUEST";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_SUCCESS = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_SUCCESS";
export const SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_ERROR = "SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_ERROR";

export const UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CONTRACTORS_STORE = "UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CONTRACTORS_STORE";