import {
    CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER,
    DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
    GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
} from "./actions";

export function getDirectoriesSpecialtyOfferPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
        payload,
    };
}

export function editDirectoriesSpecialtyOffer(payload) {
    return {
        type: EDIT_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
        payload,
    };
}

export function decisionDirectoriesSpecialtyOffer(payload) {
    return {
        type: DECISION_DIRECTORIES_SPECIALTY_OFFER_REQUEST,
        payload,
    };
}

export function clearFieldsDirectoriesSpecialtyOffer() {
    return {
        type: CLEAR_FIELDS_DIRECTORIES_SPECIALTY_OFFER,
    };
}