export const KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST = "KEDO_DEPARTMENTS_STAFF_GET_PAGE_REQUEST";
export const KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS = "KEDO_DEPARTMENTS_STAFF_GET_PAGE_SUCCESS";
export const KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR = "KEDO_DEPARTMENTS_STAFF_GET_PAGE_ERROR";

export const KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST = "KEDO_DEPARTMENTS_STAFF_MOVE_REQUEST";
export const KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS = "KEDO_DEPARTMENTS_STAFF_MOVE_SUCCESS";
export const KEDO_DEPARTMENTS_STAFF_MOVE_ERROR = "KEDO_DEPARTMENTS_STAFF_MOVE_ERROR";

export const KEDO_DEPARTMENTS_STAFF_CLEAR_STORE = "KEDO_DEPARTMENTS_STAFF_CLEAR_STORE";

export const UPDATE_KEDO_DEPARTMENTS_STAFF_STORE = "UPDATE_KEDO_DEPARTMENTS_STAFF_STORE";