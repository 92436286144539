import React from "react";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";

import {KEDO_ERROR_CODE_TYPE} from "../../../../../constants/kedo";

import {bffKedoStaffErrorSelector} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffRegistryWarningsModal = (props) => {
    const {
        onConfirm,
        onCancel,
    } = props;

    const error = useSelector(bffKedoStaffErrorSelector);
    const {
        errorCode,
        errorMessage,
    } = error;

    if (errorCode === KEDO_ERROR_CODE_TYPE.DEPARTMENT_ROLE_EXISTS) {
        return (
            <NmConfirmV2
                size="sm"
                title="Сотрудник занимает руководящую должность"
                content={errorMessage}
                isOnlyConfirm={true}
                confirmButton="Понятно"
                onCancel={onCancel}
            />
        );
    }

    if (errorCode === KEDO_ERROR_CODE_TYPE.STAFF_EXISTS_IN_ROUTE) {
        return (
            <NmConfirmV2
                size="sm"
                title="Сотрудник является участником маршрута подписания"
                content={errorMessage}
                isOnlyConfirm={true}
                confirmButton="Понятно"
                onCancel={onCancel}
            />
        );
    }

    if (errorCode === KEDO_ERROR_CODE_TYPE.STAFF_EXIST_ON_SIGN_DOCUMENTS) {
        return (
            <NmConfirmV2
                size="sm"
                title="У данного сотрудника есть неподписанные документы"
                content={errorMessage}
                confirmButton="Продолжить"
                cancelButton="Отменить"
                isNeedClosing={false}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
        );
    }
};