import {useState} from "react";
import {useDispatch} from "react-redux";

import {getInfoContractorCard} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {
    confirmReviewsContractor,
    declineReviewsContractor,
    deleteReviewsContractor,
    updateStatusReviewsContractorReply,
} from "../../../../ducks/bff/reviews/contractor/actionCreators";

const initialStateConfirmModal = {
    isOpen: false,
};

export function useContractorReviewsAction({fetchList, contractorId}) {
    const [isOpenAddForm, setIsOpenAddForm] = useState(false);
    const [confirmAction, setConfirmAction] = useState(initialStateConfirmModal);

    const dispatch = useDispatch();

    const fetchContractorInfo = () => {
        dispatch(getInfoContractorCard(contractorId));
    };

    const onConfirmContractorScore = (id) => {
        dispatch(confirmReviewsContractor({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const onDeleteContractorScore = (id) => {
        dispatch(deleteReviewsContractor({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const onDeclineContractorScore = (id) => {
        dispatch(declineReviewsContractor({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const changeStatusScoreReply = (reviewId, status) => {
        return dispatch(updateStatusReviewsContractorReply({
            reviewId,
            status,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const closeConfirmModal = () => {
        setConfirmAction(initialStateConfirmModal);
    };

    return {
        confirmAction,
        setConfirmAction,
        closeConfirmModal,
        isOpenAddForm,
        setIsOpenAddForm,
        onConfirmContractorScore,
        onDeleteContractorScore,
        onDeclineContractorScore,
        changeStatusScoreReply,
    };
}