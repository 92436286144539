import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import dateFormat, {
    convertUtcToLocal,
    getHoursFromTime,
    getMinutesFromTime,
} from "../../../../utils/dateFormat";
import {convertStringToDate} from "../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

import {
    addOrderContractorWorkInfoClientTime, updateOrderContractorWorkInfoClientTime,
} from "../../../../ducks/bff/orders/contractor-work-log/actionCreators";

export const TimeSheetClientTimeEditModal = (props) => {
    const {
        onClose,
        data,
        fetchInfo,
    } = props;

    const dispatch = useDispatch();

    const isEdit = data.clientDatetime;

    const initialValues = useMemo(() => {
        const {clientDatetime} = data;

        if (!clientDatetime) {
            return {
                date: null,
                time: "",
            };
        }

        return {
            date: convertStringToDate(clientDatetime),
            time: dateFormat(clientDatetime, "HH:mm"),
        };
    }, [data]);

    const {
        handleSubmit,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        isValid,
    } = useFormik({
        initialValues,
        onSubmit: () => {
            if (!isValid) {
                return;
            }

            const {
                date,
                time,
            } = values;

            const valueTime = time.split(":");
            const localDatetime = new Date(date).setHours(valueTime[0], valueTime[1], 0);

            const reqData = {
                clientId: data.clientId,
                orderId: data.orderId,
                contractorId: data.contractorId,
                contractorWorkLogId: data.contractorWorkLogId,
                type: data.type,
                localDatetime: convertUtcToLocal(localDatetime),
                onSuccess: () => {
                    if (fetchInfo) {
                        fetchInfo();
                    }

                    onClose();
                },
            };

            if (isEdit) {
                dispatch(updateOrderContractorWorkInfoClientTime(reqData));

                return;
            }

            dispatch(addOrderContractorWorkInfoClientTime(reqData));
        },
        validationSchema: () => {
            return yup.object().shape({
                date: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                time: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test("time", "Некорректное время", function (value) {
                        const hour = getHoursFromTime(value);
                        const minutes = getMinutesFromTime(value);

                        return hour <= 23 && minutes <= 59;
                    }),
            });
        },
        validateOnBlur: false,
        validateOnMount: false,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        isEdit
                            ? "Редактирование времени заказчика"
                            : "Добавление времени заказчика"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    loading={false}
                    disabled={false}
                    submitBtnContent={
                        isEdit
                            ? "Сохранить"
                            : "Добавить"
                    }
                />
            }
            onClose={onClose}
        >
            <NmForm>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmDatePicker
                            size="xl"
                            label="Дата"
                            dateFormat="dd.MM.yyyy"
                            dateFormatMask="99.99.9999"
                            name="date"
                            selected={values.date}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            error={touched.date && errors.date}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Время"
                            placeholder="чч:мм"
                            name="time"
                            mask="99:99"
                            value={values.time}
                            onChange={handleChange}
                            error={touched.time && errors.time}
                        />
                    </div>
                </div>
            </NmForm>
        </NmModal>
    );
};