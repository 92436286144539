import React, {useCallback, useState} from "react";
import Media, {useMedia} from "react-media";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import ButtonArchive from "../../../components/ButtonArchive";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import ForbiddenPage from "../../../components/ForbiddenPage";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import OrderTemplatesEdit from "./components/edit";
import ErrorArchiveConfirmModal from "./components/error-archive-confirm-modal";
import OrderTemplatesFilter from "./components/filter";
import OrderTemplatesMobileHeaderDropdown from "./components/mobile-header-dropdown";
import OrderTemplatesObjectList from "./components/order-templates-object-list";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import useOrderTemplateAction from "./hooks/useAction";
import {useOrderTemplatesFetchList} from "./hooks/useFetchList";
import {useOrderTemplateFilterDto} from "./hooks/useOrderTemplateFilterDto";

import {isNMUsers} from "../../../utils/access";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {mapOrderTemplateFilter} from "./utils/mapOrderTemplateFilter";

import {CLIENT_FIELD_NAME} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {EMPTY_OPTION_KEY} from "../../../constants/dropdown";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../../constants/links";
import {ORDER_CREATION_MODE} from "../../../constants/order";
import {CLIENT_ADMIN, NM_OPERATOR} from "../../../constants/roles";

import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    getOrderTemplatesProgressSelector,
    orderTemplatesRichListSelector,
    orderTemplatesTotalCountRichSelector,
    orderTemplatesTotalPageRichSelector,
} from "../../../ducks/order";

import "./style.sass";

const initFilterForm = {
    name: "",
    objectIdsFilter: [],
    projectIdsFilter: [],
    specialityIds: [],
    addressFiasIds: [],
    hasOrderWorkReportTemplateFilter: EMPTY_OPTION_KEY,
    templateObjectId: "",
};

function OrderTemplates(props) {
    const {
        match: {
            params: {
                clientId,
                projectId,
                objectId,
            },
        },
    } = props;
    const role = ls(USER_ROLE);

    const isAccessPage = isNMUsers() || [CLIENT_ADMIN].includes(role);

    const orderWorkTemplateActionActionProgress = useSelector(state => state.orderWorkReportTemplate.actionProgress);
    const list = useSelector(orderTemplatesRichListSelector);
    const totalCount = useSelector(orderTemplatesTotalCountRichSelector);
    const totalPages = useSelector(orderTemplatesTotalPageRichSelector);
    const loading = useSelector(getOrderTemplatesProgressSelector);
    const {
        archived: isClientArchived,
    } = useSelector(clientCardInfoSelector);

    const [isSearch, setIsSearch] = useState(false);
    const [modalObjectListData, setModalObjectListData] = useState({});
    const isShowControls = totalCount || isSearch;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();
    const {filterDto, setFilterDto} = useOrderTemplateFilterDto();
    const {
        setArchived,
        archived,
        fetchList,
    } = useOrderTemplatesFetchList({
        clientId,
        pageNum,
        pageSize,
        filter: filterDto,
    });
    const {
        getMediaControls,
        isShowArchiveErrorConfirm,
        failedTemplateObjects,
        toggleArchiveErrorConfirm,
        isOpenEditTemplate,
        onCloseTemplateEdit,
        onOpenTemplateEdit,
        templateEditData,
        onOpenFilter,
        confirmData,
        onCloseConfirm,
        openFilter,
    } = useOrderTemplateAction({
        archived,
        fetchList,
    });
    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});
    const isMobile = useMedia({query: {maxWidth: 767}});

    const toggleArchived = useCallback(() => {
        setArchived(!archived);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    }, [archived, pageSize]);

    const submitFilter = (filter) => {
        setFilterDto(mapOrderTemplateFilter(filter));
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(true);
    };


    function renderLink(text, link, key) {
        const {historyData} = props;

        return (
            <ExtLink
                key={key}
                extData={historyData}
                pageData={{
                    pageNum, pageSize,
                }}
                to={link}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    }

    function mapObjects(item) {
        const {
            templateObjects,
            templateObjectTotalCount,
        } = item;

        if (!templateObjects) {
            return null;
        }

        const list = templateObjects.slice(0, 3).map((item, index) => {
            const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
                .replace(":projectId", item.projectId)
                .replace(":objectId", item.objectId)
                .replace(":clientId", item[CLIENT_FIELD_NAME.ID]);

            return (
                <div
                    key={item.objectId}
                    className="flex flex-aligned-center"
                >
                    {
                        index !== 0 &&
                        <div className="order-templates__delimiter" />
                    }
                    {renderLink(item.name, objectLink, index)}
                </div>
            );
        });

        if (templateObjectTotalCount > 3) {
            list.push(
                <div className="flex flex-aligned-center">
                    <div className="order-templates__delimiter" />
                    <Text
                        className="order-templates__more-count"
                        color={COLOR.PASSIVE_100}
                        onClick={() => setModalObjectListData({
                            projectIdsFilter: item.templateProjectIds,
                            objectIdsFilter: item.templateObjectIds,
                        })}
                    >
                        Еще 
                        {" "}
                        {templateObjectTotalCount - 3}
                    </Text>
                </div>,
            );
        }

        return list;
    }

    function mapProjects(item) {
        const {
            templateProjects,
            templateProjectTotalCount,
        } = item;

        if (!templateProjects) {
            return null;
        }

        const list = templateProjects.slice(0, 3).map((item, index) => {
            const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
                .replace(":clientId", item.clientId)
                .replace(":projectId", item.projectId);

            return (
                <div
                    key={item.projectId}
                    className="flex flex-aligned-center"
                >
                    {
                        index !== 0 &&
                        <div className="nm-selected-list__delimiter" />
                    }
                    {renderLink(item.name, projectLink, index)}
                </div>
            );
        });

        if (templateProjectTotalCount > 3) {
            list.push(
                <div className="flex flex-aligned-center">
                    <div className="order-templates__delimiter" />
                    <Text
                        className="order-templates__more-count"
                        color={COLOR.PASSIVE_100}
                        onClick={() => setModalObjectListData({
                            isProjectList: true,
                            projectIdsFilter: item.templateProjectIds,
                        })}
                    >
                        Еще 
                        {" "}
                        {templateProjectTotalCount - 3}
                    </Text>
                </div>,
            );
        }

        return list;
    }

    function renderRows(list = []) {
        return list.map(item => {
            const {
                templateName,
                speciality,
            } = item;

            return {
                key: item.orderId,
                contentRow: (
                    <NmListCard
                        noDivider
                        fluidPrimaryHeader
                        classNameMainContent="col-16 col-md-14 col-xxl-8"
                        primaryHeader={templateName}
                        labels={[
                            {
                                label: "Вид деятельности",
                                className: "order-templates__list-links",
                                noWrap: false,
                                text: getText(speciality?.value),
                            },
                            {
                                label: "Проект",
                                className: "order-templates__list-links",
                                wrapped: true,
                                text: getText(mapProjects(item)),
                            },
                            {
                                label: "Объект",
                                className: "order-templates__list-links",
                                wrapped: true,
                                text: getText(mapObjects(item)),
                            },
                        ]}
                        actionsClassName="col-1 col-xxl-8 justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }

    function getText(value) {
        return value ? value : "Нет данных";
    }

    function renderTemplateNewModal() {
        return isOpenEditTemplate &&
            <OrderTemplatesEdit
                creatingMode={ORDER_CREATION_MODE.TEMPLATE}
                handleClose={onCloseTemplateEdit}
                objectId={objectId}
                projectId={projectId}
                clientId={clientId}
                orderStatus={templateEditData.status}
                editOrderId={templateEditData.orderId}
                editActTemplateData={templateEditData}
                disabledOfferedSpeciality
                fetchList={fetchList}
            />;
    }

    if (!isAccessPage) {
        return <ForbiddenPage />;
    }

    function renderButtonCreateTemplate() {
        if (role === NM_OPERATOR) {
            return null;
        }
        if (!isShowControls) {
            return null;
        }

        if (isClientArchived || archived) {
            return null;
        }

        return (
            <NmButton
                icon={<AddIcon />}
                size="xl"
                onClick={onOpenTemplateEdit}
                className="order-templates__add"
            >
                Создать шаблон
            </NmButton>
        );
    }

    const getConfirm = () => {
        if (!confirmData) {
            return null;
        }

        return (
            <NmConfirmV2
                disabled={orderWorkTemplateActionActionProgress}
                title={confirmData.title}
                content={confirmData.content}
                onCancel={onCloseConfirm}
                onConfirm={confirmData.onConfirm}
                confirmButton={confirmData.confirmButton}
                cancelButton={confirmData.cancelButton}
                isNeedClosing={false}
            />
        );
    };

    const renderOrderTemplatesObjectList = () => {
        if (isEmpty(modalObjectListData)) {
            return null;
        }

        const {
            isProjectList,
            projectIdsFilter,
            objectIdsFilter,
        } = modalObjectListData;
        return (
            <OrderTemplatesObjectList
                onClose={() => setModalObjectListData({})}
                clientId={clientId}
                isProjectList={isProjectList}
                projectIdsFilter={projectIdsFilter}
                objectIdsFilter={objectIdsFilter}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Шаблоны заказов
                </NmTitle>
            }
            openFilter={openFilter}
            onOpenFilter={onOpenFilter}
            isLoaded={loading}
            controls={
                <Media
                    query={{maxWidth: 767}}
                >
                    {
                        mobile => !mobile &&
                            <>
                                <ButtonArchive
                                    onClick={toggleArchived}
                                    disabled={loading}
                                    archivedFilter={archived}
                                />
                                {
                                    renderButtonCreateTemplate()
                                }
                            </>
                    }
                </Media>
            }
            contextMenu={
                isMobile &&
                <OrderTemplatesMobileHeaderDropdown
                    archived={archived}
                    onOpenFilter={onOpenFilter}
                    onToggleArchived={toggleArchived}
                    onOpenTemplateEdit={onOpenTemplateEdit}
                />
            }
            typeFilter="vertical"
            filtersBase={
                <OrderTemplatesFilter
                    initForm={initFilterForm}
                    clientId={clientId}
                    submitFilter={submitFilter}
                    setFilter={setFilter}
                    onChangeFilter={onChangeFilter}
                    filter={filter}
                    filterDto={filterDto}
                    role={role}
                    setIsSearch={setIsSearch}
                />
            }
            classNameContent="order-templates-table"
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
        >
            {getConfirm()}
            {renderOrderTemplatesObjectList()}
            {
                !(totalCount || loading) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isShowAction={!(isClientArchived || archived) && ![NM_OPERATOR].includes(role)}
                        textAction="Создать шаблон"
                        onClickAction={!isClientArchived && ![NM_OPERATOR].includes(role) && onOpenTemplateEdit}
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        rows={renderRows(list)}
                    />
            }
            {
                isShowArchiveErrorConfirm &&
                <ErrorArchiveConfirmModal
                    toggleVisibleModal={toggleArchiveErrorConfirm}
                    failedTemplateObjects={failedTemplateObjects}
                />
            }
            {renderTemplateNewModal()}
        </NmPage>
    );
}

export default OrderTemplates;