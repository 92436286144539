import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffMarketingNotificationsSelector = state => state.bff.marketingNotifications;
export const bffMarketingNotificationsListSelector  = createSelector(bffMarketingNotificationsSelector, ({list}) => list);
export const bffMarketingNotificationsTotalCountSelector  = createSelector(bffMarketingNotificationsSelector, ({totalCount}) => totalCount);
export const bffMarketingNotificationsTotalPagesSelector  = createSelector(bffMarketingNotificationsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffMarketingNotificationsProgressSelector  = createSelector(bffMarketingNotificationsSelector, ({progress}) => progress);
export const bffMarketingNotificationsProgressActionSelector  = createSelector(bffMarketingNotificationsSelector, ({progressAction}) => progressAction);
export const bffMarketingNotificationsMailingStatsSelector  = createSelector(bffMarketingNotificationsSelector, ({mailingStats}) => mailingStats);
export const bffMarketingNotificationsMailingStatsProgressSelector  = createSelector(bffMarketingNotificationsSelector, ({mailingStatsProgress}) => mailingStatsProgress);
export const bffMarketingNotificationsMailingCardSelector  = createSelector(bffMarketingNotificationsSelector, ({card}) => card);