import React from "react";

import AmountInput from "../../../../AmountInput";
import NmLoader from "../../../../NmLoader";
import HelpTooltip from "../../../HelpTooltip";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";

import {useClientSettingsWorkWithContractorsForm} from "./hooks/useForm";

import {ADMIN, NM_MANAGER} from "../../../../../constants/roles";

export const ClientSettingsWorkWithContractors = (props) => {
    const {
        onClose,
        clientId,
        role,
        isAccess,
        refFooter,
    } = props;

    const {
        isLoading,
        onChange,
        onSubmit,
        values,
        errors,
        touched,
    } = useClientSettingsWorkWithContractorsForm({
        onClose,
        clientId,
    });

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            {
                isLoading &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="prohibitNonResidents"
                onChange={onChange}
                checked={values.prohibitNonResidents}
                label="Запрет на заключение договоров с нерезидентами"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="hideContractorContacts"
                onChange={onChange}
                checked={values.hideContractorContacts}
                label="Скрыть номер телефона и email исполнителя"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={role !== ADMIN}
                name="allowSigningFrameContractWithoutTaxpayerAndPartner"
                onChange={onChange}
                checked={values.allowSigningFrameContractWithoutTaxpayerAndPartner}
                label="Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={role !== ADMIN}
                name="snilsRequiredForFrameContractSigning"
                onChange={onChange}
                checked={values.snilsRequiredForFrameContractSigning}
                label="Разрешить подписывать РД только при наличии СНИЛС"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={role !== ADMIN}
                name="allowSigningDocumentWithoutSms"
                onChange={onChange}
                checked={values.allowSigningDocumentWithoutSms}
                label="Разрешить подписывать документы без кода"
                isVisibleTooltip={true}
                tooltip={
                    <HelpTooltip
                        info
                        hover
                        text="При включении данной настройки, исполнители в личном кабинете онбординга смогут подписать РД или документ от заказчика без подтверждения по смс-коду"
                        position="bottom"
                    />
                }
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="allowRegistrationWithoutInn"
                onChange={onChange}
                checked={values.allowRegistrationWithoutInn}
                label="Разрешить регистрацию без ИНН"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="allowCreatingRequestsForAnyContractors"
                onChange={onChange}
                checked={values.allowCreatingRequestsForAnyContractors}
                label="Разрешить создавать заявки от не своих исполнителей"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="grantPersonalDataAccess"
                onChange={onChange}
                checked={values.grantPersonalDataAccess}
                label="Доступ к персональным данным исполнителя"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || !values.grantPersonalDataAccess}
                name="grantDocumentScansAccess"
                onChange={onChange}
                checked={values.grantDocumentScansAccess}
                label="Доступ к сканам документов исполнителя"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="canViewContractorEventLog"
                onChange={onChange}
                checked={values.canViewContractorEventLog}
                label="Доступ к журналу событий"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="warnPaymentsCountRepeatsLastMonth"
                onChange={onChange}
                checked={values.warnPaymentsCountRepeatsLastMonth}
                label="Предупреждать о совпадении количества актов в текущем и предыдущем месяцах"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="warnPaymentsAmountRepeatsLastMonth"
                onChange={onChange}
                checked={values.warnPaymentsAmountRepeatsLastMonth}
                label="Предупреждать, если сумма оплат в текущем месяце не отличается на заданный процент от суммы оплат предыдущего месяца"
            />
            <AmountInput
                newInput
                size="xl"
                required={values.warnPaymentsAmountRepeatsLastMonth}
                label="Процент от выплат, %"
                disabled={
                    ![ADMIN, NM_MANAGER].includes(role) ||
                    !values.warnPaymentsAmountRepeatsLastMonth
                }
                error={
                    touched?.paymentAmountWarnThreshold &&
                    errors?.paymentAmountWarnThreshold
                }
                name="paymentAmountWarnThreshold"
                value={values.paymentAmountWarnThreshold}
                onChange={onChange}
            />
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
