import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getInfoContractorCard} from "../../../../../../ducks/bff/contractor-сard/actionCreators";
import {getContractorAdditionalDocumentAggregation} from "../../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    updateContractorAdditionalDocumentStore,
} from "../../../../../../ducks/contractorAdditionalDocuments";
import {
    getContractorsAdditionalDocumentTypeDict,
} from "../../../../../../ducks/contractorsDicts";

export const useContractorAdditionalDocumentsVerificationFetch = (params) => {
    const {
        contractorId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContractorsAdditionalDocumentTypeDict());
        dispatch(getContractorAdditionalDocumentAggregation({
            contractorId,
        }));
        dispatch(getInfoContractorCard(contractorId));

        return () => {
            dispatch(updateContractorAdditionalDocumentStore({
                documentsAggregation: {},
            }));
            dispatch(updateContractorAdditionalDocumentStore({
                document: {
                    data: {},
                },
            }));
        };
    }, []);

    return null;
};