import {
    REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS,
    REGISTRY_PAYMENT_LOG_UPDATE_STORE,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    progressList: false,
    progressAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.registryPaymentsLogEvents,
                totalCount: payload.totalCount,
                progressList: false,
            };
        case REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        case REGISTRY_PAYMENT_LOG_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};