import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearFieldsDirectoriesCategorySpecialities,
    getDirectoriesCategorySpecialitiesPage,
} from "../../../../../ducks/bff/settings/directories/naimix/category-specialities/actionCreators";

export const useCategorySpecialitiesFetch = ({filterData, pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(clearFieldsDirectoriesCategorySpecialities());
        };
    }, []);

    const fetchList = () => {
        dispatch(getDirectoriesCategorySpecialitiesPage({
            ...filterData,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};