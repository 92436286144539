import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {ceil, isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import Steps from "../../../../../components/Steps";
import CrowdTaskEditAdditionalTermForm from "./components/additional-terms-form";
import CrowdTaskEditContextProvider from "./components/context-provider";
import CrowdTaskEditGeneralInformationForm from "./components/general-information-form";
import CrowdTaskEditPreConditionsForm from "./components/pre-conditions-form";
import CrowdTaskEditPublishingForm from "./components/publishing-form";

import {useModal} from "../../../../../hooks/useModal";
import useCrowdTaskEditForm from "./hooks/useForm";

import bem from "../../../../../utils/bem";
import {getNumberFromFormattedAmount} from "../../../../../utils/stringFormat";

import {CROWD_TASK_STATUS} from "../../../../../constants/crowd/task";
import {CROWD_TASK_EDIT_STEP, CROWD_TASK_EDIT_STEPS} from "./constants";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {
    bffCrowdTasksProgressActionSelector,
    bffCrowdTasksProgressCardSelector,
} from "../../../../../ducks/bff/crowd/tasks/selectors";

import "./style.sass";

const CrowdTaskEditForm = (props) => {
    const {
        clientId,
        onClose,
        editData = {},
        fetchList = () => {},
        openSuccessPublishModalData,
        isDuplicate,
    } = props;

    const progressAction = useSelector(bffCrowdTasksProgressActionSelector);
    const progressCard = useSelector(bffCrowdTasksProgressCardSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);

    const [block, element] = bem("crowd-task-edit-form");

    const isEdit = !isEmpty(editData);
    const steps = CROWD_TASK_EDIT_STEPS;

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        onSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        handleChange,
        activeStepsItems,
        currentStep,
        isFirstStep,
        isLastStep,
        goBackStep,
    } = useCrowdTaskEditForm({
        clientId,
        onClose,
        editData,
        isEdit,
        fetchList,
        steps,
        openSuccessPublishModalData,
        onOpenConfirm,
        onCloseConfirm,
        isDuplicate,
    });

    const calculatorData = useMemo(() => {
        const {crowdTaskPaymentsCommission} = clientProperties;
        const paymentAmount = getNumberFromFormattedAmount(values.paymentAmount);

        const platformTaxAmount = paymentAmount * crowdTaskPaymentsCommission;
        const fromDepositAmount = paymentAmount + platformTaxAmount;
        const contractorTaxAmount = ceil(paymentAmount * 0.06, 2);
        const amountToCard = paymentAmount - contractorTaxAmount;

        return {
            paymentAmount,
            platformTaxAmount,
            fromDepositAmount,
            contractorTaxAmount,
            amountToCard,
            crowdTaskPaymentsCommission,
        };
    }, [values]);

    const getDraftButton = () => {
        if (isFirstStep) {
            return {
                cancelBtnContent: "Отменить",
                onClose,
            };
        }

        if (isDuplicate || !isEdit || editData.status === CROWD_TASK_STATUS.DRAFT.VALUE) {
            return {
                cancelBtnContent: "Сохранить черновик",
                onClose: () => onSubmit({isDraft: true}),
            };
        }

        return null;
    };

    const getSubmitButton = () => {
        if (!isLastStep) {
            return {
                submitBtnContent: "Далее",
                submit: onSubmit,
            };
        }

        if (editData.status === CROWD_TASK_STATUS.DRAFT.VALUE || !isEdit || isDuplicate) {
            return {
                submitBtnContent: "Опубликовать",
                submit: onSubmit,
            };
        }

        return {
            submitBtnContent: null,
        };
    };

    const getForm = () => {
        switch (currentStep) {
            case CROWD_TASK_EDIT_STEP.PRE_CONDITIONS.VALUE: {
                return <CrowdTaskEditPreConditionsForm />;
            }
            case CROWD_TASK_EDIT_STEP.GENERAL_INFORMATION.VALUE: {
                return <CrowdTaskEditGeneralInformationForm />;
            }
            case CROWD_TASK_EDIT_STEP.ADDITIONAL_TERMS.VALUE: {
                return <CrowdTaskEditAdditionalTermForm />;
            }
            case CROWD_TASK_EDIT_STEP.PUBLISHING.VALUE: {
                return <CrowdTaskEditPublishingForm />;
            }
            default:
                return;
        }
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            warning,
            isOnlyConfirm,
            confirmButton,
        } = confirmData || {};

        return (
            <NmConfirmV2
                isOnlyConfirm={isOnlyConfirm}
                warning={warning}
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton || "Подтвердить"}
                cancelButton="Отменить"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    return (
        isOpenConfirm ?
            renderConfirm() :
            <NmModal
                className={block()}
                classNameContent="flex flex-column"
                size="lg"
                header={
                    <NmTitle size="lg">
                        {isEdit ? "Редактирование задания" : "Создание задания"}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        mobile="column"
                        disabled={progressAction}
                        onClickAdditional={goBackStep}
                        additionalBtnContent={!isFirstStep && "Назад"}
                        isHiddenAdditionalOnMobile
                        isSubmitCancelButton
                        onClose={() => {
                        }}
                        {...getDraftButton()}
                        {...getSubmitButton()}
                    />
                }
                onClose={onClose}
                loading={progressAction || progressCard}
            >
                <CrowdTaskEditContextProvider
                    clientId={clientId}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    calculatorData={calculatorData}
                >
                    <Steps
                        withAdditionalParams
                        items={steps}
                        activeItems={activeStepsItems}
                        className="mb-4"
                    />
                    {getForm()}
                </CrowdTaskEditContextProvider>
            </NmModal>
    );
};

export default CrowdTaskEditForm;