import {useState} from "react";
import {useDispatch} from "react-redux";

import {updateDepartureAddresses} from "../../../../../../../ducks/bff/contractor-сard/profile/actionCreators";

export const defaultWorkCitiesForm = {
    departureAddressesFiasIds: [],
    hasDepartureAddresses: false,
    initialOptions: [],
};

export default function useUpdateWorkCities({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultWorkCitiesForm});
    const dispatch = useDispatch();

    const {
        departureAddressesFiasIds,
        hasDepartureAddresses,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "hasDepartureAddresses" && checked) {
            setForm(prevState => ({
                ...prevState,
                hasDepartureAddresses: checked,
                departureAddressesFiasIds: [],
            }));
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const submit = () => {
        dispatch(updateDepartureAddresses({
            contractorId,
            departureAddressesFiasIds,
            hasDepartureAddresses: !hasDepartureAddresses,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
    };
}