import {
    CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR,
    CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
    GET_INFO_CONTRACTOR_CARD_ERROR,
    GET_INFO_CONTRACTOR_CARD_REQUEST,
    GET_INFO_CONTRACTOR_CARD_SUCCESS,
    GET_NOTE_CONTRACTOR_CARD_SUCCESS,
    GET_RATING_CONTRACTOR_CARD_SUCCESS,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
    UPDATE_CONTRACTOR_BLOCKED_ERROR,
    UPDATE_CONTRACTOR_BLOCKED_REQUEST,
    UPDATE_CONTRACTOR_BLOCKED_SUCCESS,
} from "./actions";

export const getContractorCardInitialState = () => {
    return {
        card: "",
        cardProgress: "",
        note: "",
        ratings: {
            contractorId: null,
            estimatedTimeRating: 0,
            rating: 0,
            workQualityRating: 0,
            scoreCount: 0,
            isLoaded: false,
        },
        progressAction: false,
        error: null,
    };
};

const initialState = getContractorCardInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_NOTE_CONTRACTOR_CARD_SUCCESS:
            return {
                ...state,
                note: payload || "",
            };
        case GET_INFO_CONTRACTOR_CARD_REQUEST:
            return {
                ...state,
                cardProgress: true,
            };
        case GET_INFO_CONTRACTOR_CARD_SUCCESS:
            return {
                ...state,
                card: payload,
                cardProgress: false,
            };
        case GET_INFO_CONTRACTOR_CARD_ERROR:
            return {
                ...state,
                cardProgress: false,
                error: payload,
            };
        case GET_RATING_CONTRACTOR_CARD_SUCCESS:
            return {
                ...state,
                ratings: {
                    ...payload,
                    isLoaded: true,
                },
            };
        case CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST:
        case REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST:
        case UPDATE_CONTRACTOR_BLOCKED_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS:
        case CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR:
        case REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS:
        case REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR:
        case UPDATE_CONTRACTOR_BLOCKED_SUCCESS:
        case UPDATE_CONTRACTOR_BLOCKED_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        default: return state;
    }
};