export const TASK_STATUS = {
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Ошибка выполнения",
        MOD: "red",
    },
    CONTINUATION: {
        VALUE: "CONTINUATION",
        TEXT: "Продолжение выполнения",
        MOD: "orange",
    },
    COMPLETED: {
        VALUE: "COMPLETED",
        TEXT: "Завершено",
        MOD: "green",
    },
    IN_PROGRESS: {
        VALUE: "IN_PROGRESS",
        TEXT: "В процессе",
        MOD: "gray",
    },
    CANCELLED: {
        VALUE: "CANCELLED",
        TEXT: "Отменено",
        MOD: "red",
    },
    QUEUED: {
        VALUE: "QUEUED",
        TEXT: "В очереди",
        MOD: "gray",
    },
};

export const TASK_STATUS_FILTER_DICT = {
    [TASK_STATUS.IN_PROGRESS.VALUE]: TASK_STATUS.IN_PROGRESS.TEXT,
    [TASK_STATUS.COMPLETED.VALUE]: TASK_STATUS.COMPLETED.TEXT,
    [TASK_STATUS.ERROR.VALUE]: TASK_STATUS.ERROR.TEXT,
    [TASK_STATUS.QUEUED.VALUE]: TASK_STATUS.QUEUED.TEXT,
    [TASK_STATUS.CANCELLED.VALUE]: TASK_STATUS.CANCELLED.TEXT,
};

export const TASK_TYPE = {
    ORDERS_CLOSING: {
        VALUE: "ORDERS_CLOSING",
        TEXT: "Закрытие заказов",
        SUCCEEDED_COUNT_TEXT: "Закрыто",
        FAILED_COUNT_TEXT: "Не удалось закрыть",
    },
    ORDER_PAYMENTS_CONFIRMATION: {
        VALUE: "ORDER_PAYMENTS_CONFIRMATION",
        TEXT: "Подтверждение платежа",
        SUCCEEDED_COUNT_TEXT: "Подтверждено",
        FAILED_COUNT_TEXT: "Не удалось подтвердить",
    },
    ORDER_PAYMENTS_PAYMENT: {
        VALUE: "ORDER_PAYMENTS_PAYMENT",
        TEXT: "Оплата",
        SUCCEEDED_COUNT_TEXT: "Оплачено",
        FAILED_COUNT_TEXT: "Не удалось оплатить",
    },
    ORDER_CONTRACTOR_INVITATION: {
        VALUE: "ORDER_CONTRACTOR_INVITATION",
        TEXT: "Приглашение",
        SUCCEEDED_COUNT_TEXT: "Приглашено",
        FAILED_COUNT_TEXT: "Не удалось пригласить",
    },
    ORDER_REJECTED_PAYMENT: {
        VALUE: "ORDER_REJECTED_PAYMENT",
        TEXT: "Отклонение оплат",
        SUCCEEDED_COUNT_TEXT: "Отклонено",
        FAILED_COUNT_TEXT: "Не удалось отклонить",
    },
    DISCARD_REGISTRY_PAYMENT_ERRORS: {
        VALUE: "DISCARD_REGISTRY_PAYMENT_ERRORS",
        TEXT: "Удаление строк в реестре",
        SUCCEEDED_COUNT_TEXT: "Успешно удалены",
        FAILED_COUNT_TEXT: "Не удалось удалить",
    },
    EDOC_UPLOAD_DOCUMENTS: {
        VALUE: "EDOC_UPLOAD_DOCUMENTS",
        TEXT: "Создание печатных форм в ЭДО",
        SUCCEEDED_COUNT_TEXT: "Успешно созданы",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    IMPORT_ORDERS: {
        VALUE: "IMPORT_ORDERS",
        TEXT: "Загрузка заказов из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    PATENT_REGISTRY_PAYMENTS_HANDLING: {
        VALUE: "PATENT_REGISTRY_PAYMENTS_HANDLING",
        TEXT: "Оплата патентов",
        SUCCEEDED_COUNT_TEXT: "Успешно",
        FAILED_COUNT_TEXT: "Не удалось оплатить",
    },
    IMPORT_CONTRACTORS_GROUP: {
        VALUE: "IMPORT_CONTRACTORS_GROUP",
        TEXT: "Загрузка исполнителей",
        SUCCEEDED_COUNT_TEXT: "Успешно",
        FAILED_COUNT_TEXT: "Не удалось загрузить",
    },
    IMPORT_CLIENTS_GROUP: {
        VALUE: "IMPORT_CLIENTS_GROUP",
        TEXT: "Загрузка заказчиков",
        SUCCEEDED_COUNT_TEXT: "Успешно",
        FAILED_COUNT_TEXT: "Не удалось загрузить",
    },
    IMPORT_REGISTRY_PAYMENTS: {
        VALUE: "IMPORT_REGISTRY_PAYMENTS",
        TEXT: "Загрузка списка исполнителей из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    // Новый тип таска после изменения метода для загрузки
    REGISTRY_PAYMENT_IMPORT: {
        VALUE: "REGISTRY_PAYMENT_IMPORT",
        TEXT: "Загрузка списка исполнителей из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    IMPORT_STAFF: {
        VALUE: "IMPORT_STAFF",
        TEXT: "Загрузка исполнителей в кадры ЭДО из файла",
        SUCCEEDED_COUNT_TEXT: "Добавлено",
        FAILED_COUNT_TEXT: "Не удалось добавить",
    },
    ORDERS_ARCHIVING: {
        VALUE: "ORDERS_ARCHIVING",
        TEXT: "Архивирование заказов",
        SUCCEEDED_COUNT_TEXT: "Архивировано",
        FAILED_COUNT_TEXT: "Не удалось архивировать",
    },
    ORDERS_DRAFT_POSTING: {
        VALUE: "ORDERS_DRAFT_POSTING",
        TEXT: "Публикация заказов",
        SUCCEEDED_COUNT_TEXT: "Опубликовано",
        FAILED_COUNT_TEXT: "Не удалось опубликовать",
    },
    ORDERS_DRAFT_DELETING: {
        VALUE: "ORDERS_DRAFT_DELETING",
        TEXT: "Удаление черновиков",
        SUCCEEDED_COUNT_TEXT: "Удалено",
        FAILED_COUNT_TEXT: "Не удалось удалить",
    },
    CLOSE_ADVERT: {
        VALUE: "CLOSE_ADVERT",
        TEXT: "Удаление объявлений",
        SUCCEEDED_COUNT_TEXT: "Удалено",
        FAILED_COUNT_TEXT: "Не удалось удалить",
    },
    IMPORT_ADVERT: {
        VALUE: "IMPORT_ADVERT",
        TEXT: "Загрузка объявлений через файл",
        SUCCEEDED_COUNT_TEXT: "Загружено",
        FAILED_COUNT_TEXT: "Не удалось загрузить",
    },
    PUBLISH_ADVERT: {
        VALUE: "PUBLISH_ADVERT",
        TEXT: "Публикация объявлений",
        SUCCEEDED_COUNT_TEXT: "Опубликовано",
        FAILED_COUNT_TEXT: "Не удалось опубликовать",
    },
    ORDERS_PUBLISH_IN_WORK: {
        VALUE: "ORDERS_PUBLISH_IN_WORK",
        TEXT: "Публикация заказов",
        SUCCEEDED_COUNT_TEXT: "Опубликовано",
        FAILED_COUNT_TEXT: "Не удалось опубликовать",
    },
    ORDERS_END_CONTRACTOR_SEARCH: {
        VALUE: "ORDERS_END_CONTRACTOR_SEARCH",
        TEXT: "Завершение подбора",
        SUCCEEDED_COUNT_TEXT: "Переведено в статус \"В работе\"",
        FAILED_COUNT_TEXT: "Не удалось перевести в статус \"В работе\"",
    },
    IMPORT_REGISTRY_INVITATION_CONTRACTOR: {
        VALUE: "IMPORT_REGISTRY_INVITATION_CONTRACTOR",
        TEXT: "Загрузка списка исполнителей из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    IMPORT_OBJECTS_PROJECTS: {
        VALUE: "IMPORT_OBJECTS_PROJECTS",
        TEXT: "Загрузка проектов и объектов из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    ACT_REGISTRY_PAYMENT_IMPORT: {
        VALUE: "ACT_REGISTRY_PAYMENT_IMPORT",
        TEXT: "Загрузка списка исполнителей из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    CROWD_ACT_REGISTRY_IMPORT: {
        VALUE: "CROWD_ACT_REGISTRY_IMPORT",
    },
    PAY_PAYMENT_API: {
        VALUE: "PAY_PAYMENT_API",
        TEXT: "Подтверждение выплат",
        SUCCEEDED_COUNT_TEXT: "Подтверждено",
        FAILED_COUNT_TEXT: "Не удалось подтвердить",
    },
    IMPORT_EDO_DOCUMENTS_REGISTRY_ITEMS: {
        VALUE: "IMPORT_EDO_DOCUMENTS_REGISTRY_ITEMS",
        TEXT: "Реестр ЭДО. Загрузка списка исполнителей из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    // Не отображается со всеми
    CONTRACTOR_STATUS_PROCESSING: {
        VALUE: "CONTRACTOR_STATUS_PROCESSING",
    },
    ANALYSIS_CONTRACTOR_LOCATION: {
        VALUE: "ANALYSIS_CONTRACTOR_LOCATION",
    },
    PRE_VALIDATION_CONTRACTOR_BANK_CHECK: {
        VALUE: "PRE_VALIDATION_CONTRACTOR_BANK_CHECK",
    },
    EDO_DOCUMENTS_REGISTRY_UPDATE_RECEIVERS_DATA: {
        VALUE: "EDO_DOCUMENTS_REGISTRY_UPDATE_RECEIVERS_DATA",
        TEXT: "Реестр ЭДО. Обновление параметров исполнителей",
        SUCCEEDED_COUNT_TEXT: "Успешно",
        FAILED_COUNT_TEXT: "Не удалось обновить",
    },
    PAY_ACT_REGISTRY_PAYMENTS: {
        VALUE: "PAY_ACT_REGISTRY_PAYMENTS",
        TEXT: "Реестр актов. Отправка актов в оплату",
        SUCCEEDED_COUNT_TEXT: "Успешно",
        FAILED_COUNT_TEXT: "Не удалось обновить",
    },
    IMPORT_PREFILLED_CONTRACTORS: {
        VALUE: "IMPORT_PREFILLED_CONTRACTORS",
        TEXT: "Импорт исполнителей в простую регистрацию из Excel файла",
        SUCCEEDED_COUNT_TEXT: "Добавлено",
        FAILED_COUNT_TEXT: "Не удалось добавить",
    },
    DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE: {
        VALUE: "DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE",
        TEXT: "Пополнение депозита объектов из файла",
        SUCCEEDED_COUNT_TEXT: "Пополнено",
        FAILED_COUNT_TEXT: "Не удалось пополнить",
    },
    IMPORT_FORBIDDEN_INN_CLIENT: {
        VALUE: "IMPORT_FORBIDDEN_INN_CLIENT",
        TEXT: "Загрузка исполнителей для стоп-листа из файла",
        SUCCEEDED_COUNT_TEXT: "Создано",
        FAILED_COUNT_TEXT: "Не удалось создать",
    },
    CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK: {
        VALUE: "CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK",
        TEXT: "Загрузка исполнителей для стоп-листа из файла",
    },
    IMPORT_OBJECTS_PROJECTS_V2: {
        VALUE: "IMPORT_OBJECTS_PROJECTS_V2",
        TEXT: "Загрузка проектов и объектов из файла",
    },
    IMPORT_ONBOARDING_LEADS: {
        VALUE: "IMPORT_ONBOARDING_LEADS",
        TEXT: "Импорт лидов в сценарий онбординга из Excel файла",
    },
    CROWD_TASKS_MULTIPLE_DELETE_OPERATIONS_EXECUTION: {
        VALUE: "CROWD_TASKS_MULTIPLE_DELETE_OPERATIONS_EXECUTION",
    },
    CROWD_TASKS_MULTIPLE_PUBLISH_OPERATIONS_EXECUTION: {
        VALUE: "CROWD_TASKS_MULTIPLE_PUBLISH_OPERATIONS_EXECUTION",
    },
    CROWD_TASKS_MULTIPLE_CLOSE_OPERATIONS_EXECUTION: {
        VALUE: "CROWD_TASKS_MULTIPLE_CLOSE_OPERATIONS_EXECUTION",
    },
    CROWD_TASKS_MULTIPLE_ADD_TO_ARCHIVE_OPERATIONS_EXECUTION: {
        VALUE: "CROWD_TASKS_MULTIPLE_ADD_TO_ARCHIVE_OPERATIONS_EXECUTION",
    },
    KEDO_STAFF_IMPORT: {
        VALUE: "KEDO_STAFF_IMPORT",
    },
    TICKET_MULTIPLE_CHANGE_MANAGER: {
        VALUE: "TICKET_MULTIPLE_CHANGE_MANAGER",
    },
    CROWD_TASKS_MULTIPLE_EXTEND_WORK_END_DATE_OPERATIONS_EXECUTION: {
        VALUE: "CROWD_TASKS_MULTIPLE_EXTEND_WORK_END_DATE_OPERATIONS_EXECUTION",
    },
    RECRUITMENT_VACANCIES_IMPORT: {
        VALUE: "RECRUITMENT_VACANCIES_IMPORT",
    },
    PUBLISH_VACANCY: {
        VALUE: "PUBLISH_VACANCY",
    },
    DELETE_VACANCY: {
        VALUE: "DELETE_VACANCY",
    },
    SEND_CONTRACTOR_INVITES_FROM_MAP: {
        VALUE: "SEND_CONTRACTOR_INVITES_FROM_MAP",
    },
    CROWD_TASKS_IMPORT_INTO_TASK_GROUP: {
        VALUE: "CROWD_TASKS_IMPORT_INTO_TASK_GROUP",
    },
    ORDER_CONTRACTORS_APPROVE_ALL: {
        VALUE: "ORDER_CONTRACTORS_APPROVE_ALL",
    },
    ORDER_CONTRACTORS_DECLINE_ALL: {
        VALUE: "ORDER_CONTRACTORS_DECLINE_ALL",
    },
    ORDER_CONTRACTORS_REJECT_ALL: {
        VALUE: "ORDER_CONTRACTORS_REJECT_ALL",
    },
    RECRUITMENT_CHECK_JOB_BOARD_PUBLISH_STATUS: {
        VALUE: "RECRUITMENT_CHECK_JOB_BOARD_PUBLISH_STATUS",
    },
};

export const TASK_TYPE_LIST = [
    TASK_TYPE.ORDERS_CLOSING.VALUE,
    TASK_TYPE.ORDER_PAYMENTS_CONFIRMATION.VALUE,
    TASK_TYPE.ORDER_PAYMENTS_PAYMENT.VALUE,
    TASK_TYPE.ORDER_REJECTED_PAYMENT.VALUE,
    TASK_TYPE.ORDER_CONTRACTOR_INVITATION.VALUE,
    TASK_TYPE.DISCARD_REGISTRY_PAYMENT_ERRORS.VALUE,
    TASK_TYPE.EDOC_UPLOAD_DOCUMENTS.VALUE,
    TASK_TYPE.IMPORT_ORDERS.VALUE,
    TASK_TYPE.PATENT_REGISTRY_PAYMENTS_HANDLING.VALUE,
    TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE,
    TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE,
    TASK_TYPE.IMPORT_REGISTRY_PAYMENTS.VALUE,
    TASK_TYPE.REGISTRY_PAYMENT_IMPORT.VALUE,
    TASK_TYPE.ORDERS_ARCHIVING.VALUE,
    TASK_TYPE.ORDERS_DRAFT_DELETING.VALUE,
    TASK_TYPE.ORDERS_DRAFT_POSTING.VALUE,
    TASK_TYPE.IMPORT_STAFF.VALUE,
    TASK_TYPE.CLOSE_ADVERT.VALUE,
    TASK_TYPE.IMPORT_ADVERT.VALUE,
    TASK_TYPE.PUBLISH_ADVERT.VALUE,
    TASK_TYPE.ORDERS_PUBLISH_IN_WORK.VALUE,
    TASK_TYPE.ORDERS_END_CONTRACTOR_SEARCH.VALUE,
    TASK_TYPE.IMPORT_REGISTRY_INVITATION_CONTRACTOR.VALUE,
    TASK_TYPE.IMPORT_OBJECTS_PROJECTS.VALUE,
    TASK_TYPE.ACT_REGISTRY_PAYMENT_IMPORT.VALUE,
    TASK_TYPE.PAY_PAYMENT_API.VALUE,
    TASK_TYPE.IMPORT_EDO_DOCUMENTS_REGISTRY_ITEMS.VALUE,
    TASK_TYPE.EDO_DOCUMENTS_REGISTRY_UPDATE_RECEIVERS_DATA.VALUE,
    TASK_TYPE.IMPORT_PREFILLED_CONTRACTORS.VALUE,
    TASK_TYPE.DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE.VALUE,
    TASK_TYPE.IMPORT_FORBIDDEN_INN_CLIENT,
];