import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffFinancesNdflSummarySelector = state => state.bff.financesNdflSummary;

export const bffFinancesNdflSummaryListSelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({reports}) => reports,
);
export const bffFinancesNdflSummaryProgressSelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({progress}) => progress,
);
export const bffFinancesNdflSummaryProgressReportsSelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({progressReports}) => progressReports,
);
export const bffFinancesNdflSummaryTotalSummarySelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({totalSummary}) => totalSummary,
);
export const bffFinancesNdflSummaryTotalCountSelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({totalCount}) => totalCount,
);
export const bffFinancesNdflSummaryTotalPagesSelector = createSelector(
    bffFinancesNdflSummarySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);