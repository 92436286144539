import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const selector = state => state.kedoStaff;
export const bffKedoStaffListSelector = createSelector(
    selector,
    ({list}) => list,
);
export const bffKedoStaffTotalCountSelector = createSelector(
    selector,
    ({totalCount}) => totalCount,
);
export const bffKedoStaffTotalPageSelector = createSelector(
    selector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffKedoStaffProgressSelector = createSelector(
    selector,
    ({progress}) => progress,
);
export const bffKedoStaffProgressActionSelector = createSelector(
    selector,
    ({progressAction}) => progressAction,
);
export const bffKedoStaffCardSelector = createSelector(
    selector,
    ({card}) => card,
);
export const bffKedoStaffProgressCardSelector = createSelector(
    selector,
    ({progressCard}) => progressCard,
);
export const bffKedoStaffProgressCommonCardSelector = createSelector(
    selector,
    ({progressCommonCard}) => progressCommonCard,
);
export const bffKedoStaffCommonCardSelector = createSelector(
    selector,
    ({commonCard}) => commonCard,
);

export const bffKedoStaffSignatureTransactionsListSelector = createSelector(
    selector,
    ({signatureTransactionsList}) => signatureTransactionsList,
);

export const bffKedoStaffProgressSignatureTransactionsSelector = createSelector(
    selector,
    ({progressSignatureTransactions}) => progressSignatureTransactions,
);

export const bffKedoStaffSignatureTransactionsTotalCountSelector = createSelector(
    selector,
    ({signatureTransactionsTotalCount}) => signatureTransactionsTotalCount,
);

export const bffKedoStaffSignatureTransactionsTotalPageSelector = createSelector(
    selector,
    ({signatureTransactionsTotalCount, signatureTransactionsPageData: {pageSize = 0}}) => {
        return getTotalPages(signatureTransactionsTotalCount, pageSize);
    },
);

export const bffKedoStaffErrorSelector = createSelector(
    selector,
    ({error}) => error,
);

