import {all, put, takeEvery} from "@redux-saga/core/effects";

import {setInvisibleTask} from "./actionCreators";
import {
    TASK_LIST_GET_PAGE_ERROR,
    TASK_LIST_GET_PAGE_REQUEST,
    TASK_LIST_GET_PAGE_SUCCESS,
    TASK_LIST_SET_INVISIBLE_ERROR,
    TASK_LIST_SET_INVISIBLE_REQUEST,
    TASK_LIST_SET_INVISIBLE_SUCCESS,
} from "./actions";

import request from "../../utils/postman";

// Получение страницы задач
const getTaskListPageSaga = function* ({payload}) {
    const {
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: TASK_LIST_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: TASK_LIST_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: TASK_LIST_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const setInvisibleTaskSaga = function* ({payload}) {
    const {
        url,
        handleResponse,
        data,
    } = payload;

    try {
        const result = yield request.bff.post(`${url}?taskId=${data.taskId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: TASK_LIST_SET_INVISIBLE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: TASK_LIST_SET_INVISIBLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: TASK_LIST_SET_INVISIBLE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(TASK_LIST_GET_PAGE_REQUEST, getTaskListPageSaga),
        takeEvery(TASK_LIST_SET_INVISIBLE_REQUEST, setInvisibleTaskSaga),
    ]);
}