import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    MARKETING_NOTIFICATIONS_ADD_ERROR,
    MARKETING_NOTIFICATIONS_ADD_REQUEST,
    MARKETING_NOTIFICATIONS_ADD_SUCCESS,
    MARKETING_NOTIFICATIONS_CANCEL_ERROR,
    MARKETING_NOTIFICATIONS_CANCEL_REQUEST,
    MARKETING_NOTIFICATIONS_CANCEL_SUCCESS,
    MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_ERROR,
    MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST,
    MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_SUCCESS,
    MARKETING_NOTIFICATIONS_GET_PAGE_ERROR,
    MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST,
    MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS,
    MARKETING_NOTIFICATIONS_GET_STATS_ERROR,
    MARKETING_NOTIFICATIONS_GET_STATS_REQUEST,
    MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS,
    MARKETING_NOTIFICATIONS_RETRY_ERROR,
    MARKETING_NOTIFICATIONS_RETRY_REQUEST,
    MARKETING_NOTIFICATIONS_RETRY_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/marketing/notifications";

const getPageMarketingNotifications = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addMarketingNotification = function* ({payload}) {
    try {
        const {
            handleResponse,
            formData,
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_ADD_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_ADD_ERROR,
        });
    }
};

const retryMarketingNotification = function* ({payload}) {
    try {
        const {
            handleResponse,
            id,
        } = payload;

        const result = yield request.bff.post(`${controller}/${id}/retry`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_RETRY_ERROR,
            });

            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_RETRY_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_RETRY_ERROR,
        });
    }
};

const cancelMarketingNotification = function* ({payload}) {
    try {
        const {
            handleResponse,
            id,
            removePosted,
        } = payload;

        const result = yield request.bff.post(`${controller}/${id}/cancel?removePosted=${removePosted}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_CANCEL_ERROR,
            });

            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_CANCEL_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_CANCEL_ERROR,
        });
    }
};

const getStatsMarketingNotification = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get-stats`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_GET_STATS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_GET_STATS_ERROR,
        });
    }
};

const getContractorsCountMarketingNotification = function* ({payload}) {
    try {
        const {
            data,
            handleResponse,
        } = payload;

        const result = yield request.bff.post(`${controller}/contractors-count`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST, getPageMarketingNotifications),
        takeEvery(MARKETING_NOTIFICATIONS_ADD_REQUEST, addMarketingNotification),
        takeEvery(MARKETING_NOTIFICATIONS_CANCEL_REQUEST, cancelMarketingNotification),
        takeEvery(MARKETING_NOTIFICATIONS_GET_STATS_REQUEST, getStatsMarketingNotification),
        takeEvery(MARKETING_NOTIFICATIONS_RETRY_REQUEST, retryMarketingNotification),
        takeEvery(MARKETING_NOTIFICATIONS_GET_CONTRACTORS_COUNT_REQUEST, getContractorsCountMarketingNotification),
    ]);
}