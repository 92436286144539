import {
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
} from "./actions";

export function getDirectoriesCategorySpecialitiesPage(payload) {
    return {
        type: GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
        payload,
    };
}

export function getDirectoriesCategorySpecialitiesCard(payload) {
    return {
        type: GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
        payload,
    };
}

export function updateDirectoriesCategorySpecialities(payload) {
    return {
        type: UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
        payload,
    };
}

export function addDirectoriesCategorySpecialities(payload) {
    return {
        type: ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
        payload,
    };
}

export function deleteDirectoriesCategorySpecialities(payload) {
    return {
        type: DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
        payload,
    };
}

export function updateFieldsDirectoriesCategorySpecialities(payload) {
    return {
        type: UPDATE_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
        payload,
    };
}

export function clearFieldsDirectoriesCategorySpecialities() {
    return {
        type: CLEAR_FIELDS_DIRECTORIES_CATEGORY_SPECIALITIES,
    };
}