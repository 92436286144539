import React from "react";

import RecruitmentResponseSource from "../../../../../../../components/Recruitment/ResponseSource";

import {RECRUITMENT_CANDIDATE_CREATED_FEED_SOURCE} from "../../../constants";

const DICT = {
    COLD_SEARCH: "Холодный поиск",
    MANUAL: "Добавлен вручную",
    PLUGIN_SEARCH: "Плагин",
};

export const getFeedCandidateSourceLabelText = (item) => {
    const {
        candidateSource,
        jobBoardTypeSource,
    } = item;

    const result = {
        label: "Источник",
    };

    if (candidateSource === RECRUITMENT_CANDIDATE_CREATED_FEED_SOURCE.FROM_RESPONSE) {
        result.text = (
            <RecruitmentResponseSource
                isVisibleOnlyJobBoardName={true}
                source={{
                    responseSource: jobBoardTypeSource,
                }}
            />
        );

        result.alignItems = "center";

        return result;
    }

    result.text = DICT[candidateSource];

    return result;
};