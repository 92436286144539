import React from "react";
import Media from "react-media";
import {useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../components/ActualComponents/Text";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ContractorNoteForClientTooltip from "../../../../components/ContractorNoteForClientTooltip";
import ContractorPassportStatus from "../../../../components/ContractorPassportStatus";
import ExtLink from "../../../../components/ExtLink";
import InstrumentPaymentIndicator from "../../../../components/InstrumentPaymentIndicator";
import InvitePerformerToNaimix from "../../../../components/InvitePerformerToNaimix";
import LocatedOutsideRussiaTooltip from "../../../../components/LocatedOutsideRussiaTooltip";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import Task from "../../../../components/NmTask";
import RegistryCardButton from "../../../../components/RegistryCardButton";
import RegistryPaymentError from "../../../../components/RegistryPaymentError";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import {ReactComponent as AddBoxIcon} from "../../../../images/add_box.svg";
import {ReactComponent as IconClose} from "../../../../images/close.svg";
import {ReactComponent as IconDone} from "../../../../images/done_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";
import {ReactComponent as UploadIcon} from "../../../../images/file_upload.svg";
import {ReactComponent as UserIcon} from "../../../../images/user_24.svg";
import OrderTemplateInfo from "../../template-info";
import ActDuplicateConfirm from "./components/act-duplicate-confirm";
import ActRegistryAddContractorForm from "./components/add-contractor-form";
import ActRegistryCardCreatorInfo from "./components/creator-info";
import OrderActRegistryCardEditPayment from "./components/edit-payment";
import ActRegistryCardFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import useActRegistryCardAction from "./hooks/useAction";
import {useOrderActRegistryCardEditPayment} from "./hooks/useEditPayment";
import {useActRegistryCardFetchData} from "./hooks/useFetchData";
import {useActRegistryCardFilterDto} from "./hooks/useFilterDto";

import bem from "../../../../utils/bem";
import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";
import formatDate from "../../../../utils/dateFormat";
import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {getFcStatusForRegistryItem} from "../../../../utils/fcRegistries";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {
    formatAmount,
    formatAmountWithNullChecking,
    formatNumber,
    getFullName,
    phoneFormat,
} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {getOrderActRegistryPaymentStatus} from "./utils/getStatus";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ACT_REGISTRY_PAYMENTS_STATUS_DICT} from "../../../../constants/actRegistry";
import {PAYMENT_METHOD_DICT} from "../../../../constants/clientSettings";
import {COLOR} from "../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_ORDER_CARD, LinkNavigator,
} from "../../../../constants/links";
import {
    MIN_PAYOUT_TO_CONTRACTOR,
    ORDER_ACT_REGISTRY_MIN_IMPORT_FIELD_LENGTH,
    ORDER_KIND,
} from "../../../../constants/order";
import {
    REGISTRY_CARD_OPTIONS,
    REGISTRY_OPERATION,
} from "../../../../constants/registry";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";
import {phoneRegWithForeign} from "../../../../constants/validationRules";

import {history} from "../../../../store/configureStore";

import {actRegistriesCardSelector} from "../../../../ducks/actRegistries";
import {
    actRegistryPaymentListSelector,
    actRegistryPaymentProgressActionSelector,
    actRegistryPaymentProgressSelector,
    actRegistryPaymentTotalCountSelector,
    actRegistryPaymentTotalPagesSelector,
} from "../../../../ducks/actRegistryPayment";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {clientCurrentMemberSelector} from "../../../../ducks/clientMember";
import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";
import {jobTasksActRegistrySelector} from "../../../../ducks/job";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../constants/link-params";

export const ACT_REGISTRY_STATUS_DICT = {
    FORMED: {
        VALUE: "FORMED",
        TEXT: "Формируется",
        COLOR: "gray",
    },
    ERROR_SEND_ACT: {
        VALUE: "ERROR_SEND_ACT",
        TEXT: "Ошибка при отправке акта",
        COLOR: "red",
    },
    IN_WORK: {
        VALUE: "IN_WORK",
        TEXT: "В работе",
        COLOR: "light-green",
    },
    SIGNED: {
        VALUE: "SIGNED",
        TEXT: "Подписано исполнителем",
        COLOR: "green",
    },
    DECLINED: {
        VALUE: "DECLINED",
        TEXT: "Отклонено",
        COLOR: "red",
    },

};

const initFilterForm = {};

function ActRegistryCard(props) {
    const {
        match: {
            params: {
                clientId,
                registryId,
            },
        },
    } = props;

    const [, element] = bem("act-registry-card");

    const clientProperties = useSelector(clientCardPropertiesSelector);
    const card = useSelector(actRegistriesCardSelector);
    const list = useSelector(actRegistryPaymentListSelector);
    const totalCount = useSelector(actRegistryPaymentTotalCountSelector);
    const totalPages = useSelector(actRegistryPaymentTotalPagesSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const progress = useSelector(actRegistryPaymentProgressSelector);
    const progressAction = useSelector(actRegistryPaymentProgressActionSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const {amountForReport: isVisibleAmountForReport} = clientProperties;

    const {
        name,
        ordinalNumber,
        description,
        registryPaymentSum,
        actOrderKind,
    } = card;

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useActRegistryCardFilterDto();

    const tasks = useSelector(jobTasksActRegistrySelector);
    const tasksProgress = Boolean(tasks.length);

    const {
        fetchList,
        fetchTasks,
    } = useActRegistryCardFetchData({
        list,
        clientId,
        registryId,
        pageNum,
        pageSize,
        filter: filterDto,
    });

    const {
        isAddContractorModalOpen,
        isInnTypeImportContractors,
        setIsInnTypeImportContractors,
        setIsAddContractorModalOpen,
        isHideDescriptionInList,
        setIsHideDescriptionInList,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        setIsImportModalOpen,
        isImportModalOpen,
        importPayments,
        onClickDownloadDocument,
        exportRegistry,
        confirmData,
        isOpenConfirm,
        closeConfirm,
        openDeleteConfirm,
        checkDuplicateAct,
        closeActDuplicateConfirm,
        dataDuplicates,
        payRegistry,
    } = useActRegistryCardAction({
        clientId,
        registryId,
        selectedList,
        fetchList,
        fetchTasks,
        clearSelectedRows,
    });

    const {
        editPaymentData,
        isOpenEditPayment,
        onOpenEditPayment,
        onCloseEditPayment,
    } = useOrderActRegistryCardEditPayment({setIsAddContractorModalOpen});

    const selectedPaymentsSum = selectedList
        .filter(item => item.isSelected)
        .reduce((accum, {orderAmount}) => {
            return accum + orderAmount;
        }, 0);

    const getSubContextMenuOptions = () => {
        if (currentMember.contractorInvitationProhibited) {
            return [
                REGISTRY_CARD_OPTIONS.INVITE_TO_NAIMIX,
            ];
        }

        return [
            REGISTRY_CARD_OPTIONS.ADD_PERFORMER,
            REGISTRY_CARD_OPTIONS.LOAD_PERFORMERS_FROM_FILE,
            REGISTRY_CARD_OPTIONS.INVITE_TO_NAIMIX,
        ];

    };

    const toggleTypeImportContractors = (event, {checked}) => {
        setIsInnTypeImportContractors(checked);
    };

    const onClickActionItem = (option) => {
        switch (option.value) {
            case REGISTRY_CARD_OPTIONS.ADD_PERFORMER.value: {
                setIsAddContractorModalOpen(true);

                return;
            }
            case REGISTRY_CARD_OPTIONS.LOAD_PERFORMERS_FROM_FILE.value: {
                setIsImportModalOpen(true);

                return;
            }
            case REGISTRY_CARD_OPTIONS.INVITE_TO_NAIMIX.value: {
                setIsInviteViaEmailOpen(false);

                return;
            }
            default:
                return;
        }
    };

    const renderRegistrySubActionsContextMenu = () => {
        const options = getSubContextMenuOptions();

        if (options === null) {
            return null;
        }

        return (
            <ContextMenu
                className={element("sub-context-menu")}
                options={options}
                onClickItem={(option) => {
                    onClickActionItem(option);
                }}
            />
        );
    };

    const renderRegistrySubActions = () => {
        return (
            [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT, FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER].includes(role) &&
            <Media query={{maxWidth: 1199}}>
                {
                    mobile =>
                        mobile ?
                            renderRegistrySubActionsContextMenu() :
                            <>
                                {
                                    !currentMember.contractorInvitationProhibited &&
                                    <>
                                        <RegistryCardButton
                                            onClick={() => {
                                                setIsAddContractorModalOpen(true);
                                            }}
                                            className="registry-card__page-button"
                                            icon={<AddBoxIcon className="registry-card__page-button-icon" />}
                                        >
                                            Добавить исполнителя
                                        </RegistryCardButton>
                                        <RegistryCardButton
                                            onClick={() => setIsImportModalOpen(true)}
                                            className="registry-card__page-button"
                                            icon={<FileDownloadIcon className="registry-card__page-button-icon" />}
                                        >
                                            Загрузить список исполнителей
                                        </RegistryCardButton>
                                    </>
                                }
                                <RegistryCardButton
                                    onClick={() => setIsInviteViaEmailOpen(true)}
                                    className="registry-card__page-button"
                                    icon={<UserIcon className="registry-card__page-button-icon" />}
                                >
                                    Пригласить исполнителя в Наймикс
                                </RegistryCardButton>
                            </>
                }
            </Media>
        );
    };

    const getHeaderMediaControls = () => {
        if (![ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, FOREMAN].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                desktop: 2,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: () => checkDuplicateAct(),
                        children: "Отправить акты",
                    },
                    visible: FOREMAN !== role,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: exportRegistry,
                        children: "Выгрузить реестр",
                        icon: <UploadIcon />,
                    },
                },
            ],
        };
    };

    const getMediaActions = (item) => {
        const {
            status,
            orderWorkReportStatus,
            paymentNumber,
            checkLink,
            registrySeqNum,
            contractorLastName,
            contractorFirstName,
            contractorPatronymic,
            contractorFio,
        } = item;
        const fullNameContractor = getContractorName(contractorLastName, contractorFirstName, contractorPatronymic, contractorFio);

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEditPayment(item);
                        },
                        children: "Редактировать",
                    },
                    visible: [
                        ACT_REGISTRY_STATUS_DICT.FORMED.VALUE,
                        ACT_REGISTRY_STATUS_DICT.ERROR_SEND_ACT.VALUE,
                    ].includes(status)
                        && orderWorkReportStatus !== ACT_REGISTRY_PAYMENTS_STATUS_DICT.PAYMENT_ERROR.VALUE
                        && [
                            ADMIN,
                            NM_MANAGER,
                            CLIENT_ADMIN,
                            CLIENT_ACCOUNTANT,
                            PROJECT_MANAGER,
                            OBJECT_MANAGER,
                            FOREMAN,
                        ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => checkDuplicateAct(fullNameContractor, [registrySeqNum]),
                        children: "Повторить",
                    },
                    visible: [
                        ACT_REGISTRY_STATUS_DICT.DECLINED.VALUE,
                        ACT_REGISTRY_STATUS_DICT.ERROR_SEND_ACT.VALUE,
                    ].includes(status)
                        && orderWorkReportStatus !== ACT_REGISTRY_PAYMENTS_STATUS_DICT.PAYMENT_ERROR.VALUE
                        && [
                            ADMIN,
                            CLIENT_ADMIN,
                            PROJECT_MANAGER,
                        ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => checkDuplicateAct(fullNameContractor, [registrySeqNum]),
                        children: "Отправить акт",
                    },
                    visible: [
                        ACT_REGISTRY_STATUS_DICT.FORMED.VALUE,
                    ].includes(status)
                        && [
                            ADMIN,
                            CLIENT_ADMIN,
                            PROJECT_MANAGER,
                        ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            openLinkByUrl(checkLink);
                        },
                        children: "Чек ФНС России",
                    },
                    visible: Boolean(checkLink)
                        && [
                            ADMIN,
                            NM_MANAGER,
                            CLIENT_ADMIN,
                            CLIENT_ACCOUNTANT,
                            PROJECT_MANAGER,
                        ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            const link = `${window.location.origin}/api/documents/getActOfAcceptanceOfWorkFile/?paymentNumber=${paymentNumber}`;

                            onClickDownloadDocument(link);
                        },
                        children: "Акт выполненных работ",
                    },
                    visible: Boolean(paymentNumber) &&
                        [ACT_REGISTRY_PAYMENTS_STATUS_DICT.PAYMENT_COMPLETED.VALUE].includes(orderWorkReportStatus)
                        && [
                            ADMIN,
                            NM_MANAGER,
                            CLIENT_ADMIN,
                            CLIENT_ACCOUNTANT,
                            PROJECT_MANAGER,
                        ].includes(role),
                },
            ],
        };
    };

    const getMobileDropdownMassActionOptions = () => {
        const deleteRow = {
            key: REGISTRY_OPERATION.DELETE_ROW,
            text: "Удалить строку",
            value: REGISTRY_OPERATION.DELETE_ROW,
            disabled: countSelected === 0,
        };

        const hideDescription = {
            key: REGISTRY_OPERATION.HIDE_DESCRIPTION,
            text: isHideDescriptionInList ? "Показать описание работ" : "Скрыть описание работ",
            value: REGISTRY_OPERATION.HIDE_DESCRIPTION,
        };

        return [
            deleteRow,
            hideDescription,
        ];
    };

    const onClickMobileDropdownItem = ({value: action}) => {
        switch (action) {
            case REGISTRY_OPERATION.DELETE_ROW:

                return;
            case REGISTRY_OPERATION.HIDE_DESCRIPTION:
                setIsHideDescriptionInList(!isHideDescriptionInList);

                return;
            default:
                return;
        }
    };

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilterForm});
        setFilterDto({...initFilterForm});
        setIsSearch(false);
    };

    const handleOnClickBack = () => {
        history.push(LINK_CLIENT_ORDER_ACT_REGISTRY_LIST.replace(":clientId", clientId));
    };

    const getContractorName = (lastName, firstName, patronymic, contractorFio) => {
        if (!isNullOrWhitespace(lastName)) {
            return getFullName(lastName, firstName, patronymic);
        }
        if (!isNullOrWhitespace(contractorFio)) {
            return contractorFio;
        }
        return "Не определено";
    };

    const renderOrderLink = ({orderNum, orderId, orderName}) => {
        if (isNullOrWhitespace(orderId)) {
            return "-";
        }

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        const orderText = `${orderNum} - ${orderName}`;

        return (
            orderId ?
                <ExtLink
                    to={orderLink}
                    pageData={{pageNum, pageSize}}
                    historyEnabled
                >
                    {orderText}
                </ExtLink> :
                orderText
        );
    };

    const getRDContent = (item) => {
        const {contractFrameDate, actualFrameContract} = item;
        const msg = getFcStatusForRegistryItem(item);

        return (
            <NmAdvancedTooltip
                trigger={
                    <div className="registry-card__fc-status">
                        {
                            actualFrameContract ?
                                <IconDone className="registry-card__fc-status-done" /> :
                                <IconClose className="registry-card__fc-status-error" />
                        }
                        {dateFormat(contractFrameDate, "dd.MM.yyyy")}
                    </div>
                }
                hover
            >
                {msg}
            </NmAdvancedTooltip>
        );
    };

    const getShowMore = (content) => {
        return (
            <NmShowMoreText
                lines={1}
                more="Подробнее"
                anchor="blue"
            >
                {content || "-"}
            </NmShowMoreText>
        );
    };

    const renderStatus = (item) => {
        const {
            orderWorkReportStatus: status,
            confirmationErrorDetails,
            message,
        } = item;

        if (!status) {
            return "-";
        }

        const {
            color,
            text,
        } = getOrderActRegistryPaymentStatus(status);

        return (
            <RegistryPaymentError
                color={color}
                statusText={text}
                error={confirmationErrorDetails || message}
            />
        );
    };

    const getPaymentNumber = ({paymentNumber, orderContractPaymentType}) => {
        if (!paymentNumber) {
            return null;
        }

        const link = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ ?
            LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId) :
            LinkNavigator.client.finance.civilPerformerPayments.replace(":clientId", clientId).replace(":paymentNumberFilter?", "");

        return (
            <ExtLink
                filterData={{
                    paymentNumber,
                }}
                isLoadDataTarget
                historyEnabled
                to={link}
            >
                {paymentNumber}
            </ExtLink>
        );
    };

    const renderSecondaryHeaderStatus = ({status, orderWorkReportStatus, message}) => {
        if (!message && orderWorkReportStatus === ACT_REGISTRY_PAYMENTS_STATUS_DICT.REJECTED_BY_CONTRACTOR.VALUE) {
            message = ACT_REGISTRY_PAYMENTS_STATUS_DICT.REJECTED_BY_CONTRACTOR.MESSAGE;
        }

        if (message && orderWorkReportStatus === ACT_REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_CONFIRMATION.VALUE) {
            message = "";
        }

        return (
            <NmBadge
                condition
                mod={ACT_REGISTRY_STATUS_DICT[status]?.COLOR}
                text={ACT_REGISTRY_STATUS_DICT[status]?.TEXT}
                description={[ACT_REGISTRY_STATUS_DICT.ERROR_SEND_ACT.VALUE, ACT_REGISTRY_STATUS_DICT.DECLINED.VALUE].includes(status) && message}
                tooltipPosition="bottom"
            />
        );
    };

    const checkError = (item) => {
        const requiredFields = [
            item.orderNum,
            item.orderDescription,
            item.orderDescriptionFns,
            item.orderWorkStartDate,
            item.orderWorkEndDate,
            item.sumOrAmount,
        ];

        if (!phoneRegWithForeign.test(item.contractorPhone)) {
            return true;
        }

        if (card.actOrderKind === ORDER_KIND.AMOUNT_BASED_ORDER && item.sumOrAmount < MIN_PAYOUT_TO_CONTRACTOR) {
            return true;
        }

        if (card.actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER && item.orderAmount < MIN_PAYOUT_TO_CONTRACTOR) {
            return true;
        }

        if (
            item.orderDescription?.length < ORDER_ACT_REGISTRY_MIN_IMPORT_FIELD_LENGTH
            || item.orderDescriptionFns?.length < ORDER_ACT_REGISTRY_MIN_IMPORT_FIELD_LENGTH
            || item.comment?.length < ORDER_ACT_REGISTRY_MIN_IMPORT_FIELD_LENGTH
        ) {
            return true;
        }

        return requiredFields.some(elem => isNullOrWhitespace(elem) || isEqual(elem, "Не определено"));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                contractorId,
                contractorFirstName,
                contractorLastName,
                contractorPatronymic,
                contractorFio,
                contractorNoteForClient,
                registrySeqNum,
                contractorPhone,
                orderDescription,
                orderDescriptionFns,
                comment,
                orderWorkStartDate,
                orderWorkEndDate,
                actDate,
                sumOrAmount,
                contractorOrderStatus,
                units,
                lastCompletedPayment,
                hasPaymentMethodCard,
                locatedOutsideRussia,
                amountForReport,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.registrySeqNum === registrySeqNum)) || {};

            return {
                ...item,
                isWithSubHeader: true,
                key: registrySeqNum,
                showCheckBox: true,
                isSelected,
                error: checkError(item),
                avatar: (
                    <Avatar
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                    />
                ),
                contentRow: (
                    <NmListCard
                        avatar
                        checkbox
                        alignItems="flex-end"
                        classNameMainContent="col-16 col-xxl-7"
                        primaryHeader={getContractorName(contractorLastName, contractorFirstName, contractorPatronymic, contractorFio)}
                        primaryHeaderTooltip={
                            <ContractorNoteForClientTooltip
                                className="ms-2"
                                note={contractorNoteForClient}
                            />
                        }
                        primaryHeaderNoWrap
                        secondaryHeaderStatus={renderSecondaryHeaderStatus(item)}
                        secondaryHeader={`Период работ ${dateFormat(orderWorkStartDate, "dd.MM.yyyy")}–${dateFormat(orderWorkEndDate, "dd.MM.yyyy")}`}
                        actionsClassName="col-1"
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-8 mt-md-4 mt-xxl-0"
                        labels={[
                            {label: "Номер строки", text: registrySeqNum},
                            {label: "Номер и наименование заказа", text: renderOrderLink(item)},
                            {
                                label: "Телефон",
                                text: phoneFormat(contractorPhone, locatedOutsideRussia),
                                classNameText: "flex align-items-center",
                                textOverflowUnset: true,
                                textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
                            },
                            {label: "РД", text: getRDContent(item), alignItems: "center", noWrap: false},
                            {
                                label: "Паспорт проверен",
                                text: (
                                    <ContractorPassportStatus
                                        contractor={item}
                                        size="md"
                                        isOnlyIcon
                                    />
                                ),
                                alignItems: "center",
                                noWrap: false,
                            },
                            {
                                label: "Средство платежа",
                                text: (
                                    <InstrumentPaymentIndicator
                                        value={hasPaymentMethodCard}
                                        size="md"
                                        showLabel={false}
                                    />
                                ),
                                alignItems: "center",
                                noWrap: false,
                            },
                            !isHideDescriptionInList && {
                                label: "Описание выполненных работ для акта",
                                columnOnMobile: true,
                                fluidText: true,
                                text: getShowMore(orderDescription),
                            },
                            !isHideDescriptionInList && {
                                label: "Описание выполненных работ для чека ФНС",
                                columnOnMobile: true,
                                fluidText: true,
                                text: getShowMore(orderDescriptionFns),
                            },
                            {label: "Комментарий", columnOnMobile: true, fluidText: true, text: getShowMore(comment)},
                            {
                                text: (
                                    <Text color={COLOR.SECONDARY_70}>
                                        {`Дата последнего оплаченного акта: ${lastCompletedPayment && formatDate(convertUtcToLocal(lastCompletedPayment.paymentCompletionDate),
                                            "dd.MM.yyyy") || "-"} Сумма: ${lastCompletedPayment && formatAmount(formatNumber(lastCompletedPayment.sumOfMoney)) || "-"}`}
                                    </Text>
                                ),
                            },
                            isVisibleAmountForReport && {
                                label: "Сумма для отчёта",
                                text:  amountForReport || "-",
                            },
                        ]}
                        cards={[
                            {
                                title: "Дата акта",
                                value: actDate ? dateFormat(actDate, "dd.MM.yyyy") : "-",
                                className: "col-16 col-md-4 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: card.actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER ? "Объем работ" : "Сумма",
                                value: sumOrAmount ? `${sumOrAmount} ${units}` : "-",
                                className: "col-16 col-md-4 mt-md-4 mt-xxl-0",
                                wrapValue: true,
                            },
                            {
                                title: "Статус исполнителя",
                                subTitle: (
                                    contractorOrderStatus ?
                                        <Text level="2">
                                            {contractorOrderStatus}
                                        </Text> :
                                        "-"
                                ),
                                className: "col-16 col-md-4 mt-md-4 mt-xxl-0",
                                wrapValue: true,
                            },
                            {
                                title: "Статус и номер оплаты",
                                subTitle: renderStatus(item),
                                value: getPaymentNumber(item),
                                className: "col-16 col-md-4 mt-md-4 mt-xxl-0",
                                wrapValue: true,
                            },
                        ]}
                        mediaControls={getMediaActions(item)}
                    />
                ),
            };
        });
    };

    const renderAddContractorForm = () => {
        return isAddContractorModalOpen &&
            <ActRegistryAddContractorForm
                fetchList={fetchList}
                clientId={clientId}
                registryId={registryId}
                onClose={() => {
                    setIsAddContractorModalOpen(false);
                }}
                editPaymentData={editPaymentData}
            />;
    };

    const renderInvitePerformerToNaimix = () => {
        return isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                close={() => setIsInviteViaEmailOpen(false)}
                clientId={clientId}
            />;
    };

    const getImportFilePatternLink = () => {

        if (isVisibleAmountForReport && actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр_актов_по_ИНН_тип2_сумма_для_отчёта.xlsx"
                : "/files/Шаблон_Реестр_актов_по_номеру_телефона_тип2_сумма_для_отчёта.xlsx";
        }

        if (isVisibleAmountForReport && actOrderKind === ORDER_KIND.AMOUNT_BASED_ORDER) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр_актов_по_ИНН_тип1_сумма_для_отчёта.xlsx"
                : "/files/Шаблон_Реестр_актов_по_номеру_телефона_тип1_сумма_для_отчёта.xlsx";
        }

        if (actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр_актов_по_ИНН_тип2.xlsx"
                : "/files/Шаблон_Реестр_актов_по_номеру_телефона_тип2.xlsx";
        }

        return isInnTypeImportContractors
            ? "/files/Шаблон_Реестр_актов_по_ИНН_тип1.xlsx"
            : "/files/Шаблон_Реестр_актов_по_номеру_телефона_тип1.xlsx";
    };

    const renderImportForm = () => {
        return isImportModalOpen &&
            <ImportFromFilePatternV2
                isDefiningParam={true}
                definingParamChecked={isInnTypeImportContractors}
                onChangeDefiningParam={toggleTypeImportContractors}
                patternLink={getImportFilePatternLink()}
                onSubmit={importPayments}
                onClose={() => setIsImportModalOpen(false)}
                progress={progressAction}
                templateText={
                    isInnTypeImportContractors
                        ? "предложенному шаблону по ИНН"
                        : "предложенному шаблону по номерам телефона"
                }
            />;
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = confirmData;

        return isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={closeConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    };

    const renderEditPayment = () => {
        if (!isOpenEditPayment) {
            return null;
        }

        const {
            contractorId,
        } = editPaymentData;

        return (
            <OrderActRegistryCardEditPayment
                clientId={clientId}
                registryId={registryId}
                fetchList={fetchList}
                contractorData={{contractorId}}
                editData={editPaymentData}
                onClose={onCloseEditPayment}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    statusLoading={tasksProgress}
                    text={`Реестр актов №${ordinalNumber}`}
                    handleOnClickBack={handleOnClickBack}
                />
            }
            mediaControls={getHeaderMediaControls()}
            subHeader={
                <>
                    <div className="col-16 col-xxl-9">
                        <Text.Title level="4">
                            {name}
                        </Text.Title>
                    </div>
                    <ActRegistryCardCreatorInfo
                        card={card}
                    />
                    <Text
                        className="col-16 col-xxl-9"
                        level="3"
                    >
                        {description}
                    </Text>
                    <NmLabelText
                        type="page"
                        label="Шаблон акта"
                        textTitle={card.actOfAcceptanceOfWorkTemplateName}
                        text={
                            <OrderTemplateInfo
                                id={card.actOfAcceptanceOfWorkTemplateId}
                                linkName={card.actOfAcceptanceOfWorkTemplateName}
                                link={card.actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                            />
                        }
                    />
                    <NmLabelText
                        type="page"
                        label="Способ проведения оплат"
                        text={PAYMENT_METHOD_DICT[card.paymentMethod]}
                    />
                </>
            }
            typeFilter="vertical"
            filtersBase={
                <ActRegistryCardFilter
                    filter={filter}
                    onChange={onChangeFilter}
                    submitFilter={submitFilter}
                    clearFilter={clearFilter}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalCount={totalCount}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
        >
            <Task />
            {
                !!dataDuplicates.length &&
                <ActDuplicateConfirm
                    close={closeActDuplicateConfirm}
                    dataDuplicate={dataDuplicates}
                    submit={(seqNums, seqNumsToDelete) => {
                        payRegistry(false, seqNums, seqNumsToDelete);
                        closeActDuplicateConfirm();
                    }}
                />
            }
            <Task />
            {
                !isEmpty(list) &&
                <NmPageInfoCardsAccordion
                    className="mb-4"
                    bootstrap={true}
                    cards={
                        [
                            {
                                title: "Сумма выбранных строк, ₽",
                                value: `${formatAmountWithNullChecking(selectedPaymentsSum)}`,
                                className: "col-xl-4 col-xxl-2",
                            },
                            {
                                title: "Сумма реестра, ₽",
                                value: `${formatAmountWithNullChecking(registryPaymentSum)}`,
                                className: "col-xl-4 col-xxl-2",
                            },
                        ]
                    }
                />
            }
            <div className={"flex mb-4"}>
                <Text.Title level="1">
                    Исполнители
                </Text.Title>
                {renderRegistrySubActions()}
            </div>
            {renderAddContractorForm()}
            {renderInvitePerformerToNaimix()}
            {renderImportForm()}
            {renderConfirm()}
            {
                list?.length ?
                    <>
                        {renderEditPayment()}
                        <CheckboxList
                            rows={getRows()}
                            header={
                                [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(role) &&
                                <SelectionCountWithAction
                                    adaptiveLogic
                                    count={countSelected}
                                    buttonColor="grey"
                                    buttonContent="Удалить строку"
                                    onClick={openDeleteConfirm}
                                />
                            }
                            additionalActions={
                                <NmHorizontalToggleV2
                                    noWrapLabel
                                    leftLabel="Скрыть описание работ"
                                    single
                                    onChange={(e, {checked}) => {
                                        setIsHideDescriptionInList(checked);
                                    }}
                                    checked={isHideDescriptionInList}
                                    duplex={false}
                                />
                            }
                            isCheckBox
                            actionOptions={getMobileDropdownMassActionOptions()}
                            onClickActionItem={onClickMobileDropdownItem}
                            onSelectedRows={handleSelectedRows}
                        />
                    </> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
}

export default ActRegistryCard;