import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {addReviewsContractor} from "../../../../../../ducks/bff/reviews/contractor/actionCreators";

const initialStateConfirmModal = {
    isOpen: false,
};

const useDataForm = (props) => {
    const {
        contractorId,
        isEdit,
        editData,
        onClose, 
        submit,
    } = props;

    const [confirmAction, setConfirmAction] = useState(initialStateConfirmModal);

    const dispatch = useDispatch();

    const initialValues = {
        workQualityScore: "",
        reviewText: "",
    };

    useEffect(() => {
        if (isEdit) {
            setValues({
                ...values,
                workQualityScore: editData.score  || editData.workQualityScore,
                reviewText: editData.reviewText,
            });
        }
    }, [editData]);

    const handleChange = (e, {name, value}) => setFieldValue(name, value);

    const closeConfirmModal = () => {
        setConfirmAction(initialStateConfirmModal);
    };

    const showSuccessModal = () => {
        setConfirmAction({
            isOpen: true,
            content: "Ваш отзыв направлен на модерацию администратором. После успешного прохождения модерации отзыв будет опубликован на форме просмотра сведений об исполнителе.",
            confirmButton: "Ок",
            onConfirm: onClose,
        });
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        if (submit) {
            submit(values);

            return;
        }

        dispatch(addReviewsContractor({
            contractorId,
            ...values,
            estimatedTimeScore: values.workQualityScore,
            onSuccess: showSuccessModal,
        }));
    };

    const onCancel = () => {
        const {
            workQualityScore,
            reviewText,
        } = values;

        if (!workQualityScore && !reviewText) {
            onClose();

            return;
        }

        setConfirmAction({
            isOpen: true,
            content: `Вы уверены, что хотите выйти с формы ${isEdit ? "редактирования" : "формирования"} отзыва? Все несохраненные данные будут потеряны.`,
            confirmButton: "Продолжить",
            cancelButton: "Отменить",
            onConfirm: onClose,
        });
    };

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        errors,
        isValid,
        touched,
        handleBlur,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        confirmAction,
        setConfirmAction,
        closeConfirmModal,
        showSuccessModal,
        onCancel,
    };
};

export default useDataForm;