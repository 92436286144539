import {
    SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST,
    SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST,
    SETTINGS_NAIMIX_INFO_LOAD_REQUEST,
    SETTINGS_NAIMIX_INFO_SAVE_REQUEST,
    UPDATE_SETTINGS_NAIMIX_INFO_STORE,
} from "./actions";

export const loadSettingsNaimixInfo = () => {
    return {
        type: SETTINGS_NAIMIX_INFO_LOAD_REQUEST,
    };
};

export const saveSettingsNaimixInfo = (payload) => {
    return {

        type: SETTINGS_NAIMIX_INFO_SAVE_REQUEST,
        payload,
    };
};

export const getFileSettingsNaimixInfo = (payload) => {
    return {

        type: SETTINGS_NAIMIX_INFO_GET_FILE_REQUEST,
        payload,
    };
};

export const addFileSettingsNaimixInfo = (payload) => {
    return {

        type: SETTINGS_NAIMIX_INFO_ADD_FILE_REQUEST,
        payload,
    };
};

export const updateStoreSettingsNaimixInfo = (payload) => {
    return {
        type: UPDATE_SETTINGS_NAIMIX_INFO_STORE,
        payload,
    };
};