import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {CONTRACTOR_MESSAGE} from "../../../../constants/messages";

const controller = "/adm/settings/contractor-support";

const getPageContractorSupport = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const getCardContractorSupport = function* ({payload}) {
    try {
        const {caseNumber} = payload;

        const result = yield request.bff.get(`${controller}/get/${caseNumber}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR,
            payload: error,
        });
    }
};

const updateContractorSupport = function* ({payload}) {
    try {
        const {errorMessage} = yield request.bff.post(`${controller}/update`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_SUPPORT_UPDATE_SUCCESS_TEXT);

        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST, getPageContractorSupport),
        takeEvery(SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST, getCardContractorSupport),
        takeEvery(SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST, updateContractorSupport),
    ]);
}