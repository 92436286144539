import {
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE,
} from "./actions";

const initialState = {
    list: [],
    pageData: {},
    totalCount: 0,
    progressAction: false,
    progress: false,
    card: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST: {
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS: {
            return {
                ...state,
                list:  payload.isLoadMore ? state.list.concat(payload.results) : payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST: {
            return {
                ...state,
                progress: true,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS: {
            return {
                ...state,
                card: payload.result,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST:
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS:
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR:
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS:
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};