import React from "react";

import {ReactComponent as InfoIcon} from "../../images/info_24.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";

import {getUserRole} from "../../utils/access";

import {COLOR} from "../../constants/color";
import {NM_CONSULTANT, NM_PARTNER, RECRUITMENT_OBSERVER, RNKO} from "../../constants/roles";

const ContractorNoteForClientTooltip = (props) => {
    const {
        note,
        className,
    } = props;

    const role = getUserRole();
    const isVisible = ![
        NM_CONSULTANT,
        RNKO,
        NM_PARTNER,
        RECRUITMENT_OBSERVER,
    ].includes(role);

    return (
        Boolean(note) &&
        isVisible &&
        <NmAdvancedTooltip
            className={className}
            position="bottom-left"
            children={note}
            trigger={
                <div
                    className="flex align-items-center me-2"
                    style={{cursor: "pointer"}}
                >
                    <InfoIcon
                        className="me-1"
                        color={COLOR.NEGATIVE_100}
                        width={20}
                        height={20}
                    />
                    <Text
                        level="3"
                        color={COLOR.NEGATIVE_100}
                        noWrap={true}
                    >
                        Есть примечание
                    </Text>
                </div>
            }
        />
    );
};

export default ContractorNoteForClientTooltip;