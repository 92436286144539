import React from "react";
import Media from "react-media";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink";
import NmBadge from "../../../../../components/NmBadge";
import NmIcon from "../../../../../components/NmIcon";
import NmPage from "../../../../../components/NmPage";
import NmSvg from "../../../../../components/NmSvg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as IconDone} from "../../../../../images/done_24.svg";
import {DEPOSIT_SUB_PAGE} from "../../index";
import DepositClientListRegistryOrders from "../registry-orders";

import dateFormat, {compareDatesWithTime, convertUtcToLocal} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {floatToPercent} from "../../../../../utils/mathHelper";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";
import {
    formatAmount,
    formatAmountWithNullChecking,
    formatNumber,
} from "../../../../../utils/stringFormat";
import {getStatusTooltipText} from "../../../../finance/finance-crowd-payments/utils/getCrowdPaymentStatusTooltipText";
import {getFinanceListCommission} from "../../../../finance/finance-payment-list/utils/getCommission";
import {getPaymentError} from "../../../../finance/finance-payment-list/utils/getError";
import {getFinanceListNdflCommission} from "../../../../finance/finance-payment-list/utils/getNdflCommission";
import {getPaymentStatus} from "../../../../finance/finance-payment-list/utils/getPaymentStatus";

import {BANK_TYPE} from "../../../../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../../constants/color";
import {DEPOSIT_OPERATION_TRANSCRIPTION} from "../../../../../constants/deposit";
import {ORDER_SOURCE_TYPE} from "../../../../../constants/finance";
import {IS_CREATE_ORDER_OPEN} from "../../../../../constants/history";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_ORDER_CARD,
    LINK_SETTINGS_INSTRUCTION,
} from "../../../../../constants/links";
import {
    FINANCE_PATENTS_PAYMENTS_STATUS_DICT,
    FINANCE_PATENTS_PAYMENTS_STATUS_MOD,
    PATENTS_PAYMENTS_STATUS_CODE,
} from "../../../../../constants/patentsPayments";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    isClientUser,
    isUserFromNm,
    NM_MANAGER,
} from "../../../../../constants/roles";
import {
    STATUS_OF_TRANSFER_NEW,
    STATUS_PAYMENT,
} from "../../../../../constants/status";

import {history} from "../../../../../store/configureStore";

import {clientCardInfoSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {currentUserRestrictionsSelector} from "../../../../../ducks/clientUserRestrictions";
import {depositHistoryApisDictSelector} from "../../../../../ducks/order";

import "./style.sass";

import {STATUS_TASK_PAYMENT} from "../../../../../constants/crowd/task-payment";
import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../../constants/link-params";

const OPERATION_TOOLTIP_MESSAGE_DATE = {
    ADD_DEPOSIT: "Дата пополнения в банке",
    RETURN_DEPOSIT: "Дата списания в банке",
};

const OPERATION_TOOLTIP_MESSAGE_NOT_DATE = {
    ADD_DEPOSIT: "Отсутствует информация о дате пополнения в банке",
    RETURN_DEPOSIT: "Отсутствует информация о дате списания в банке",
};

export default function DepositTabList(props) {
    const {
        match: {
            params: {
                clientId,
                subPage,
            },
        },
        isCivil,
        subTab,
        tabs,
        list,
        cards,
        onClickOpenModal,
        dataModal,
        progress,
    } = props;

    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const orderLink = LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId);
    const tasksLink = LINK_CLIENT_CROWD_TASK_REGISTRY.replace(":clientId", clientId);
    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);
    const isIndividualEntrepreneurPage = subTab === DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR;
    const isAccessEditDeposit = (isAccessByRestrictions({
        currentUserRestrictions,
        checkList: [
            isCivil && CLIENT_USER_RESTRICTIONS_VARIABLE.editCivilClientDepositHistory,
            !isCivil && CLIENT_USER_RESTRICTIONS_VARIABLE.editClientDepositHistory,
        ].filter(item => Boolean(item)),
    }));

    const {
        archived: isClientArchived,
    } = useSelector(clientCardInfoSelector);
    const depositHistoryApisDict = useSelector(depositHistoryApisDictSelector);

    function renderBankStatus({statusOfTheTransfer, bankDetailsError, paymentStatus}) {
        const {
            color = "",
            text = "",
        } = STATUS_OF_TRANSFER_NEW[statusOfTheTransfer?.value] || {};

        return (
            <div className="flex flex-aligned-center">
                <div
                    style={{color}}
                    className="mr-2"
                >
                    {text}
                </div>
                {
                    (paymentStatus?.value === STATUS_PAYMENT.PAYMENT_ERROR && bankDetailsError !== null) &&
                    <HelpTooltip
                        width={20}
                        height={20}
                        type="light"
                        attention
                        position="bottom-right"
                    >
                        {bankDetailsError}
                    </HelpTooltip>
                }
            </div>
        );
    }

    function renderHeaderStatus(payment) {
        const {
            status,
            color,
        } = getPaymentStatus({
            isClientRole,
            paymentStatus: payment.paymentStatus,
        });

        const rightTooltipText = getPaymentError(payment);

        return (
            <NmBadge
                text={status}
                mod={color}
                rightTooltipText={rightTooltipText}
            />
        );
    }

    function renderContractorInfo({contractorId, contractorFullName}, isBlocked) {
        const classNameWrapper = "finance-payment-list__contractor-name";
        const classNameIcon = "finance-payment-list__icon-block";

        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

        return (
            <div className={classNameWrapper}>
                {
                    isBlocked &&
                    <NmIcon
                        className={classNameIcon}
                        name="lock"
                    />
                }
                <ExtLink
                    to={contractorLink}
                    historyEnabled
                >
                    {contractorFullName}
                </ExtLink>
            </div>
        );
    }

    const renderRegistryInfo = (registryName, registryNumber, registryId, registrySeqNum) => {
        const link = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", registryId)
            .replace(":clientId", clientId);

        const seqNum = registrySeqNum ? `, номер строки: ${registrySeqNum}` : "registrySeqNum";

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {`№${registryNumber} ${registryName}${seqNum}`}
            </ExtLink>
        );
    };

    const getRegistryLabel = (item) => {
        const {
            sourceType,
            registryName,
            registryNumber,
            registryId,
            registrySeqNum,
        } = item;

        const isVisibleRegistryInfo = isIndividualEntrepreneurPage
            ? sourceType?.value !== ORDER_SOURCE_TYPE.API
            : sourceType?.value === ORDER_SOURCE_TYPE.REGISTRY;

        return (
            isVisibleRegistryInfo && {
                label: "Реестр оплат",
                text: renderRegistryInfo(registryName, registryNumber, registryId, registrySeqNum),
            }
        );
    };

    const renderPatentRegistryInfo = (registryName, patentRegistryId) => {
        const link = LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD
            .replace(":patentRegistryId", patentRegistryId)
            .replace(":clientId", clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {registryName}
            </ExtLink>
        );
    };

    const renderProjectInfo = (projectName, projectId) => {
        if (!projectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    const renderObjectInfo = (objectName, projectId, objectId) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    function renderModalWindow() {
        const {
            isOpen,
            content,
        } = dataModal;

        if (!isOpen) {
            return null;
        }

        return content;
    }

    function getShowMore(content) {
        return (
            <Media
                queries={{
                    mobile: {maxWidth: 767},
                    tablet: {minWidth: 768, maxWidth: 1199},
                }}
            >
                {
                    ({mobile}) => (
                        <NmShowMoreText
                            width={mobile ? 200 : 300}
                            anchor="green"
                            lines={1}
                            children={content}
                            more="Подробнее"
                        />
                    )
                }
            </Media>
        );
    }

    const renderCommonOrderName = (orderNum, orderName, orderId) => {
        const linkOrder = LINK_ORDER_CARD.replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return {
            label: "Номер и наим. заказа", text: <ExtLink to={linkOrder}>
                {`${orderNum} ${orderName}`}
            </ExtLink>,
        };
    };

    const getPaymentLink = () => {
        if (isIndividualEntrepreneurPage) {
            return LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId);
        }

        if (isCivil) {
            return LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId);
        }

        return LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId);
    };

    const renderPaymentNumber = (paymentNumber) => {
        if (!paymentNumber) {
            return null;
        }

        const link = getPaymentLink();

        return (
            <ExtLink
                filterData={{
                    paymentNumber,
                }}
                isLoadDataTarget
                historyEnabled
                to={link}
            >
                {`№ ${paymentNumber}`}
            </ExtLink>
        );
    };

    function getRowByTab() {
        const limitForCommission = 3000;

        if (
            subTab === DEPOSIT_SUB_PAGE.TASKS &&
            [
                DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS,
                DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS,
                DEPOSIT_SUB_PAGE.PAYMENTS_PENDING,
            ].includes(subPage)
        ) {
            return list.map(item => {
                const {
                    paymentId,
                    status,
                    createDate,
                    statusReason,
                    paymentNumber,
                    projectName,
                    projectId,
                    objectName,
                    objectId,
                    clientId,
                    taskId,
                    taskNumber,
                    taskName,
                    amount,
                    clientCommission,
                    contractorId,
                    contractorName,
                    description,
                } = item;

                const isCompletedPage = subPage === DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS;

                const renderTaskLink = () => {
                    const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                        .replace(":clientId", clientId)
                        .replace(":taskId", taskId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

                    return (
                        <ExtLink to={taskLink}>
                            №
                            {taskNumber}
                            {" "}
                            -
                            {" "}
                            {taskName}
                        </ExtLink>
                    );
                };
                const paymentsLink = LINK_CLIENT_PAYMENTS_TASKS_LIST
                    .replace(":clientId", clientId)
                    .replace(":paymentNumberFilter?", paymentNumber);

                const renderPrimaryHeader = () => {
                    if (!isCompletedPage) {
                        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);
                        const statusTooltipText = getStatusTooltipText(item);

                        return (
                            <div className="flex flex-aligned-center">
                                <ExtLink to={contractorLink}>
                                    {contractorName}
                                </ExtLink>
                                <NmBadge
                                    className="ms-2"
                                    text={STATUS_TASK_PAYMENT[status]?.TEXT || status}
                                    mod={STATUS_TASK_PAYMENT[status]?.COLOR || "gray"}
                                    rightTooltipText={statusTooltipText}
                                    tooltipPosition="bottom"
                                    rightTooltipProps={
                                        statusTooltipText && {
                                            hover: true,
                                        }
                                    }
                                />
                            </div>
                        );
                    }

                    return renderTaskLink();
                };

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(createDate))}
                        primaryHeader={renderPrimaryHeader()}
                        classNamePrimaryHeaderValue="deposit-tab-list__patents-primary-header"
                        primaryHeaderNoWrap
                        labels={[
                            {
                                label: "Номер оплаты",
                                text: isCompletedPage ?
                                    <ExtLink
                                        historyEnabled
                                        to={paymentsLink}
                                    >
                                        {paymentNumber}
                                    </ExtLink> :
                                    paymentNumber,
                            },
                            {label: "Номер и наим. задания", text: renderTaskLink()},
                            {label: "Описание платежа", text: description},
                            isCompletedPage && {
                                label: "ФИО исполнителя на задании", text: renderContractorInfo({
                                    contractorId,
                                    contractorFullName: contractorName,
                                }, false),
                            },
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmount(formatNumber(amount, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽",
                                value: formatAmount(formatNumber(clientCommission, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (subTab === DEPOSIT_SUB_PAGE.TASKS && subPage === DEPOSIT_SUB_PAGE.SPENT_LIMIT) {
            return list.map(item => {
                const {
                    taskId,
                    name,
                    number,
                    creationDateTime,
                    projectData,
                    objectData,
                    paymentAmount,
                    commission,
                } = item;

                const renderTaskLink = () => {
                    const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                        .replace(":clientId", clientId)
                        .replace(":taskId", taskId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

                    return (
                        <ExtLink to={taskLink}>
                            №
                            {number}
                            {" "}
                            -
                            {" "}
                            {name}
                        </ExtLink>
                    );
                };

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(creationDateTime))}
                        primaryHeader={renderTaskLink()}
                        classNamePrimaryHeaderValue="deposit-tab-list__patents-primary-header"
                        primaryHeaderNoWrap
                        labels={[
                            {label: "Проект", text: renderProjectInfo(projectData.value, projectData.id)},
                            {label: "Объект", text: renderObjectInfo(objectData.value, projectData.id, objectData.id)},
                        ]}
                        cards={[
                            {
                                title: "Сумма задания, ₽",
                                value: formatAmount(formatNumber(paymentAmount, 2)),
                                subValue: formatAmount(formatNumber(commission, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (subTab === DEPOSIT_SUB_PAGE.PATENTS) {
            return list.map(row => {
                const {
                    clientId,
                    contractorFio,
                    contractorId,
                    objectId,
                    objectName,
                    patentRegistryId,
                    paymentCommission,
                    paymentNumber,
                    paymentSum,
                    projectId,
                    projectName,
                    registryName,
                    statusDate,
                    paymentStatus,
                    statusReason,
                } = row;

                const renderPrimaryHeader = () => {
                    const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

                    return (
                        <div className="flex flex-aligned-center">
                            <ExtLink to={contractorLink}>
                                {contractorFio}
                            </ExtLink>
                            {
                                (paymentStatus === PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR && isClientUser(role)) ? null :
                                    <NmBadge
                                        className="ms-2"
                                        noWrap
                                        text={FINANCE_PATENTS_PAYMENTS_STATUS_DICT[paymentStatus]}
                                        mod={FINANCE_PATENTS_PAYMENTS_STATUS_MOD[paymentStatus] || "gray"}
                                        description={paymentStatus === PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR && statusReason}
                                        tooltipType="dark"
                                        tooltipPosition="bottom"
                                        tooltipHover
                                    />
                            }
                        </div>
                    );
                };

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(statusDate))}
                        primaryHeader={renderPrimaryHeader()}
                        classNamePrimaryHeaderValue="deposit-tab-list__patents-primary-header"
                        primaryHeaderNoWrap
                        labels={[
                            {label: "Номер оплаты", text: paymentNumber},
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                            {label: "Реестр оплат", text: renderPatentRegistryInfo(registryName, patentRegistryId)},
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmount(formatNumber(paymentSum, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽",
                                value: formatAmount(formatNumber(paymentCommission, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS === subPage) {
            return list.map(row => {
                const {
                    action,
                    amount,
                    datetime,
                    clientId,
                    comment,
                    transactionDateBank,
                    edit,
                    bankType,
                } = row;

                const getTooltip = () => {
                    const isCheck = Boolean(transactionDateBank);
                    const message = isCheck ? `${OPERATION_TOOLTIP_MESSAGE_DATE[action]} ${dateFormat(convertUtcToLocal(transactionDateBank), "dd.MM.yyyy")}` :
                        OPERATION_TOOLTIP_MESSAGE_NOT_DATE[action];

                    return (
                        <NmAdvancedTooltip
                            trigger={
                                isCheck ?
                                    <IconDone
                                        color={COLOR.PRIMARY_100}
                                    /> :
                                    <CloseIcon
                                        color={COLOR.NEGATIVE_100}
                                    />
                            }
                            hover
                            children={message}
                        />
                    );

                };

                const getOptions = () => {
                    return [
                        {
                            key: "edit-payments-deposit",
                            text: "Редактировать сумму",
                            value: "edit-payments-deposit",
                        },
                    ];
                };

                const onClickOption = (option) => {
                    if (option.value === "edit-payments-deposit") {
                        onClickOpenModal(row);
                    }
                };

                const commentLabel = !isCivil ? [
                    {
                        label: "Комментарий",
                        text: <p>
                            {comment}
                            {
                                (
                                    [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role) &&
                                        isAccessEditDeposit
                                ) &&
                                    <span
                                        className="deposit-client-common__link ml-2"
                                        onClick={() => {
                                            onClickOpenModal(row, true);
                                        }}
                                    >
                                            Показать
                                    </span>
                            }
                        </p>,
                        className: "col-16 col-md-16 col-xxl-16 mt-xxl-0",
                        noWrap: false,
                    },
                ] :
                    [];

                const bankTypeText = () => {
                    if (!bankType) {
                        return "-";
                    }

                    if ([
                        BANK_TYPE.HERMES_W1.VALUE,
                        BANK_TYPE.PLUTONIUM_W1.VALUE,
                    ].includes(bankType)) {
                        return "РНКО Ед. Касса";
                    }

                    return BANK_TYPE[bankType].TEXT;
                };

                return {
                    clientId,
                    contentRow: (
                        <NmListCard
                            classNameMainContent="col-16 col-xxl-6"
                            secondaryHeader={dateFormat(convertUtcToLocal(datetime), "dd.MM.yyyy HH:mm:ss")}
                            primaryHeader={DEPOSIT_OPERATION_TRANSCRIPTION[action]}
                            labels={[
                                ...commentLabel,
                                isUserFromNm(role) && {
                                    label: "Банк",
                                    text: bankTypeText(),
                                },
                            ]}
                            primaryHeaderStatusTooltip={role === ADMIN && getTooltip()}
                            cards={[
                                {
                                    title: "Сумма операции, ₽",
                                    value: (
                                        <div className="deposit-client-common__amount-wrapper">
                                            <div>
                                                {formatAmount(formatNumber(amount, 2))}
                                            </div>
                                            {
                                                edit &&
                                                <div
                                                    title="Запись была отредактирована"
                                                    className="deposit-tab-list__edit-icon-container"
                                                >
                                                    <NmSvg
                                                        className="deposit-tab-list__edit-icon"
                                                        name="pen"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ),
                                    className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                                },
                            ]}
                            actionsClassName="col-6 justify-content-end"
                            actions={
                                (
                                    role === ADMIN &&
                                    compareDatesWithTime(datetime, 48) &&
                                    !transactionDateBank &&
                                    isAccessEditDeposit
                                ) &&
                                <ContextMenu
                                    options={getOptions()}
                                    onClickItem={(option) => {
                                        onClickOption(option);
                                    }}
                                />
                            }
                        />
                    ),
                };
            });
        }

        if (DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS === subPage) {
            return list.map(row => {
                const {
                    lastUpdate,
                    paymentNumber,
                    description,
                    projectName,
                    objectName,
                    sumOfMoney,
                    objectId,
                    projectId,
                    orderName,
                    orderNum,
                    orderId,
                    sourceType,
                    bankType,
                    paymentPath,
                    paymentLocation,
                } = row;

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(lastUpdate))}
                        primaryHeader={renderContractorInfo(row, false)}
                        labels={[
                            {label: "Номер оплаты", text: renderPaymentNumber(paymentNumber)},
                            getRegistryLabel(row),
                            !isClientRole && {
                                label: "Способ оплаты",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            !isClientRole && {
                                label: "Местонахождение платежа",
                                text: paymentLocation?.description || "-",
                            },
                            orderId && renderCommonOrderName(orderNum, orderName, orderId),
                            {label: "Описание платежа", text: getShowMore(description)},
                            {label: "Тип оплаты", text: sourceType?.description},
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmountWithNullChecking(sumOfMoney),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽",
                                values: isCivil ?
                                    getFinanceListNdflCommission(row) :
                                    getFinanceListCommission(row),
                                className: "col-16 col-md-4 col-xxl-3 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS === subPage) {
            return list.map(row => {
                const {
                    lastUpdate,
                    paymentNumber,
                    description,
                    projectName,
                    objectName,
                    sumOfMoney,
                    objectId,
                    projectId,
                    orderId,
                    orderNum,
                    orderName,
                    sourceType,
                    bankType,
                    paymentPath,
                    paymentLocation,
                } = row;

                const bankStatus = row.statusOfTheTransfer && !isClientRole
                    ? [{label: "Банковский статус", text: renderBankStatus(row), noWrap: false}]
                    : [];

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(lastUpdate))}
                        secondaryHeaderStatus={renderHeaderStatus(row)}
                        classNamePrimaryHeader="d-flex flex-wrap"
                        primaryHeader={renderContractorInfo(row, false)}
                        labels={[
                            {label: "Номер оплаты", text: renderPaymentNumber(paymentNumber)},
                            getRegistryLabel(row),
                            !isClientRole && {
                                label: "Способ оплаты",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            !isClientRole && {
                                label: "Местонахождение платежа",
                                text: paymentLocation?.description || "-",
                            },
                            orderId && renderCommonOrderName(orderNum, orderName, orderId),
                            {label: "Описание платежа", text: getShowMore(description)},
                            {label: "Тип оплаты", text: sourceType?.description},
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                            ...bankStatus,
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmountWithNullChecking(sumOfMoney),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽",
                                values: isCivil ?
                                    getFinanceListNdflCommission(row) :
                                    getFinanceListCommission(row),
                                className: "col-16 col-md-4 col-xxl-3 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (DEPOSIT_SUB_PAGE.PAYMENTS_PENDING === subPage) {
            return list.map(row => {
                const {
                    lastUpdate,
                    paymentNumber,
                    description,
                    projectName,
                    objectName,
                    legalEntityCommissionAmount,
                    legalEntityCommissionMinAmount,
                    sumOfMoney,
                    objectId,
                    projectId,
                    orderId,
                    orderNum,
                    orderName,
                    sourceType,
                    currentCommissionRate,
                    bankType,
                    paymentPath,
                    paymentLocation,
                } = row;

                const _legalEntityCommissionMinAmount = `${sumOfMoney < limitForCommission ? "+" : ""}${formatAmountWithNullChecking(legalEntityCommissionMinAmount)}`;

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={dateFormat(convertUtcToLocal(lastUpdate))}
                        secondaryHeaderStatus={renderHeaderStatus(row)}
                        primaryHeader={renderContractorInfo(row, false)}
                        labels={[
                            {label: "Номер оплаты", text: renderPaymentNumber(paymentNumber)},
                            getRegistryLabel(row),
                            !isClientRole && {
                                label: "Способ оплаты",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            !isClientRole && {
                                label: "Местонахождение платежа",
                                text: paymentLocation?.description || "-",
                            },
                            orderId && renderCommonOrderName(orderNum, orderName, orderId),
                            {label: "Описание платежа", text: getShowMore(description)},
                            {label: "Тип оплаты", text: sourceType?.description},
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmountWithNullChecking(sumOfMoney),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽",
                                value: `${formatAmountWithNullChecking(legalEntityCommissionAmount)} (${floatToPercent(currentCommissionRate)}%) / ${_legalEntityCommissionMinAmount}`,
                                className: "col-16 col-md-4 col-xxl-3 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (DEPOSIT_SUB_PAGE.SPENT_LIMIT === subPage) {
            return list.map(row => {
                const {
                    name,
                    orderNum,
                    createDate,
                    projectId,
                    objectId,
                    amount,
                    paidSum,
                    limitSpentAmount,
                    orderCommissionAmount,
                    paidCommissionAmount,
                    limitSpentCommissionAmount,
                    projectName,
                    objectName,
                    orderId,
                } = row;

                const renderOrderName = () => {
                    const linkOrder = LINK_ORDER_CARD.replace(":clientId", clientId)
                        .replace(":orderId", orderId)
                        .replace(":page", SELF_CONTRACTOR)
                        .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

                    return (
                        <ExtLink
                            to={linkOrder}
                        >
                            {`№ ${orderNum} - ${name}`}
                        </ExtLink>
                    );
                };

                return {
                    clientId,
                    contentRow: <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={`Заказ от ${dateFormat(convertUtcToLocal(createDate))}`}
                        primaryHeader={renderOrderName()}
                        primaryHeaderNoWrap
                        labels={[
                            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
                            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
                        ]}
                        cards={[
                            {
                                title: "Сумма заказа, ₽",
                                value: formatAmount(formatNumber(amount, 2)),
                                subValue: formatAmount(formatNumber(orderCommissionAmount, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Выплачено по заказу, ₽",
                                value: formatAmount(formatNumber(paidSum, 2)),
                                subValue: formatAmount(formatNumber(paidCommissionAmount, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Израсходовано лимита, ₽",
                                value: formatAmount(formatNumber(limitSpentAmount, 2)),
                                subValue: formatAmount(formatNumber(limitSpentCommissionAmount, 2)),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />,
                };
            });
        }

        if (subPage === DEPOSIT_SUB_PAGE.PAID_APIS) {
            return list.map(row => {
                const {
                    datetime,
                    paidApiType,
                    clientUserEmail,
                    contractorId,
                    contractorFio,
                    paymentNumber,
                    url,
                    amount,
                } = row;

                return {
                    clientId,
                    contentRow: <NmListCard
                        alignItems="flex-end"
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={`${dateFormat(convertUtcToLocal(datetime), "dd.MM.yy HH:mm:ss")}`}
                        primaryHeader={depositHistoryApisDict[paidApiType] || "Не определено"}
                        primaryHeaderNoWrap
                        labels={[
                            {label: "Инициатор", text: clientUserEmail},
                            {label: "Запрос", text: url},
                            {
                                label: "Исполнитель", text: renderContractorInfo({
                                    contractorId,
                                    contractorFullName: contractorFio,
                                }, false),
                            },
                            paymentNumber && {label: "Номер оплаты", text: renderPaymentNumber(paymentNumber)},
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmount(formatNumber(amount, 2)),
                                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-10"
                    />,
                };
            });
        }
    }

    const DATA_EMPTY_PAGE_BY_TAB = {
        [DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS]: {
            title: "Пополнения и возвраты депозита отсутствуют",
            description: "В реестре будет отображаться список пополнений и возвратов депозита компании. Для инициирования пополнения депозита сформируйте счет-основание и пополните расчетный счет компании в банке",
        },
        [DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS]: {
            title: "Выплаты исполнителям отсутствуют",
            description: "В реестре будет отображаться список платежей по заказам. Для инициирования выплаты исполнителю за оказанные услуги сформируйте акт выполненных работ в разделе “Заказы”",
            textLink: "Перейти в реестр заказов",
            patchLink: orderLink,
        },
        [DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS]: {
            title: "Выплаты исполнителям отсутствуют",
            description: "В реестре будет отображаться список платежей по заказам. Для инициирования выплаты исполнителю за оказанные услуги сформируйте акт выполненных работ в разделе “Заказы”",
            textLink: "Перейти в реестр заказов",
            patchLink: orderLink,
        },
        [DEPOSIT_SUB_PAGE.PAYMENTS_PENDING]: {
            title: "Выплаты исполнителям отсутствуют",
            description: "В реестре будет отображаться список платежей по заказам. Для инициирования выплаты исполнителю за оказанные услуги сформируйте акт выполненных работ в разделе “Заказы”",
            textLink: "Перейти в реестр заказов",
            patchLink: orderLink,
        },
        [DEPOSIT_SUB_PAGE.SPENT_LIMIT]: {
            title: "Создавайте, размещайте и управляйте вашими заказами на площадке Наймикс",
            description: "Для начала работы просто создайте свой первый заказ и сохраните его как черновик или опубликуйте его на нашей площадке для поиска возможных исполнителей.",
            textAction: "Создать заказ",
            textLink: "Подробная инструкция",
            onClickAction: () => history.push(orderLink, {action: IS_CREATE_ORDER_OPEN}),
            isShowAction: !isClientArchived,
            patchLink:
                clientId !== "00000000-0000-0000-0000-000000000000"
                    ? LINK_INSTRUCTION_CLIENT_ADMIN.replace(":clientId", clientId)
                    : LINK_SETTINGS_INSTRUCTION,
        },
        [DEPOSIT_SUB_PAGE.PATENTS]: {
            title: "Выплаты по патентам отсутствуют",
            description: "В реестре будет отображаться список платежей по патентам. Для инициирования оплаты патента исполнителя сформируйте реестр на оплату в разделе \"Патенты\".",
            warningText: "Внимание! Модуль оплаты патентов является подключаемым (для подключения обратитесь к администрации Наймикс)",
        },
        [DEPOSIT_SUB_PAGE.PAID_APIS]: {
            title: "Вызовы платных методов API отсутствуют",
        },
        [DEPOSIT_SUB_PAGE.TASKS]: {
            title: "Выплаты исполнителям отсутствуют",
            description: "В реестре будет отображаться список платежей по заданиям. Для инициирования выплаты исполнителю за оказанные услуги сформируйте акт выполненных работ в разделе “Задания”",
            textLink: "Перейти в реестр заданий",
            patchLink: tasksLink,
        },
    };

    const getEmptyPage = () => {
        const tab = [
            DEPOSIT_SUB_PAGE.PATENTS,
            DEPOSIT_SUB_PAGE.TASKS,
        ].includes(subTab) ? subTab : subPage;

        const textLink = subTab === DEPOSIT_SUB_PAGE.REGISTRY_ORDERS ? null : DATA_EMPTY_PAGE_BY_TAB[tab].textLink;

        return (
            <NmEmptyPageV2
                title={DATA_EMPTY_PAGE_BY_TAB[tab].title}
                description={DATA_EMPTY_PAGE_BY_TAB[tab].description}
                warningText={DATA_EMPTY_PAGE_BY_TAB[tab].warningText}
                textLink={textLink}
                patchLink={DATA_EMPTY_PAGE_BY_TAB[tab].patchLink}
                textAction={DATA_EMPTY_PAGE_BY_TAB[tab].textAction}
                onClickAction={DATA_EMPTY_PAGE_BY_TAB[tab].onClickAction}
                isShowAction={typeof DATA_EMPTY_PAGE_BY_TAB[tab].isShowAction === "boolean" && DATA_EMPTY_PAGE_BY_TAB[tab].isShowAction}
                fetchProgress={progress}
            />
        );
    };

    const getContent = () => {
        return list?.length ?
            getList() :
            getEmptyPage();
    };

    const getList = () => {
        if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS && subTab === DEPOSIT_SUB_PAGE.REGISTRY_ORDERS) {
            return (
                <DepositClientListRegistryOrders
                    list={list}
                    renderProjectInfo={renderProjectInfo}
                    renderObjectInfo={renderObjectInfo}
                />
            );
        }

        return (
            <CheckboxList
                rows={getRowByTab()}
            />
        );
    };

    return (
        <NmPage
            header={
                <>
                    {
                        tabs && tabs.length > 1 &&
                        <Tabs
                            secondary
                            className="mb-4 mb-xxl-5"
                            panes={tabs}
                        />
                    }
                    {
                        !isEmpty(list) &&
                        <NmPageInfoCardsAccordion
                            cards={cards}
                        />
                    }
                </>
            }
            titleClassName="deposit-tab-list__title"
            isLoaded={progress}
            isRelative={true}
        >
            {getContent()}
            {renderModalWindow()}
        </NmPage>
    );
}