export const SECTION_DICT = {
    CLIENT: "Компании",
    CONTRACTORS: "Самозанятые",
    DEPOSIT: "Депозит",
    FINANCE: "Финансы",
    UPLOAD: "Выгрузка в 1C",
    PROMOTION: "Каналы привлечения",
    DISPUTES: "Споры",
    SETTINGS: "Настройки Наймикс",
};

export const CLIENT_TYPE_CODE = {
    RUSSIAN_LEGAL_ENTITY: "RUSSIAN_LEGAL_ENTITY",
    INDIVIDUAL_ENTREPRENEUR: "INDIVIDUAL_ENTREPRENEUR",
    FOREIGN_LEGAL_ENTITY: "FOREIGN_LEGAL_ENTITY",
};

export const BUSINESS_REGISTRATION_FORM_DICT = {
    [CLIENT_TYPE_CODE.RUSSIAN_LEGAL_ENTITY]: "Юридическое лицо",
    [CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR]: "Индивидуальный предприниматель",
    [CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY]: "Иностранная организация",
};

export const USER_INSTRUCTION_TYPE_DICT = {
    CLIENT_ADMIN_INSTRUCTION: {
        TEXT: "Инструкция для Администратора компании",
        VALUE: "CLIENT_ADMIN_INSTRUCTION",
    },
    FOREMAN_INSTRUCTION: {
        TEXT: "Инструкция для Координатора компании",
        VALUE: "FOREMAN_INSTRUCTION",
    },
    PROJECT_MANAGER_INSTRUCTION: {
        TEXT: "Инструкция для Менеджера проекта",
        VALUE: "PROJECT_MANAGER_INSTRUCTION",
    },
    OBJECT_MANAGER_INSTRUCTION: {
        TEXT: "Инструкция для Менеджера объекта",
        VALUE: "OBJECT_MANAGER_INSTRUCTION",
    },
    HR_MANAGER_INSTRUCTION: {
        TEXT: "Инструкция для HR-Менеджера",
        VALUE: "HR_MANAGER_INSTRUCTION",
    },
    CLIENT_ACCOUNTANT_INSTRUCTION: {
        TEXT: "Инструкция для Бухгалтера компании",
        VALUE: "CLIENT_ACCOUNTANT_INSTRUCTION",
    },
};

export const USER_KEDO_INSTRUCTION_TYPE_DICT = {
    KEDO_HR_WORK_INSTRUCTION: {
        TEXT: "Инструкция по работе в КЭДО для кадрового работника",
        VALUE: "KEDO_HR_WORK_INSTRUCTION",
    },
    KEDO_HR_CRYPTO_PRO_INSTRUCTION: {
        TEXT: "Инструкция по работе с плагином подписания для кадрового работника",
        VALUE: "KEDO_HR_CRYPTO_PRO_INSTRUCTION",
    },
    KEDO_HR_1C_INSTRUCTION: {
        TEXT: "Инструкция по работе с 1С для кадрового работника",
        VALUE: "KEDO_HR_1C_INSTRUCTION",
    },
    KEDO_CLIENT_USER_WORK_INSTRUCTION: {
        TEXT: "Инструкция по работе в КЭДО для сотрудника",
        VALUE: "KEDO_CLIENT_USER_WORK_INSTRUCTION",
    },
    KEDO_CLIENT_USER_GOS_KEY_INSTRUCTION: {
        TEXT: "Инструкция по работе с Госключом для сотрудника",
        VALUE: "KEDO_CLIENT_USER_GOS_KEY_INSTRUCTION",
    },
};

export const KEDO_DOCUMENTS_ROUTE_TYPE = {
    BASE_ROUTE: "BASE_ROUTE",
    USER_ROUTE: "USER_ROUTE",
    FLEX_ROUTE: "FLEX_ROUTE",
};

export const KEDO_DOCUMENTS_ROUTE_TYPE_DICT = {
    [KEDO_DOCUMENTS_ROUTE_TYPE.USER_ROUTE]: "Пользовательский",
    [KEDO_DOCUMENTS_ROUTE_TYPE.FLEX_ROUTE]: "Гибкий",
};

export const KEDO_DOCUMENTS_ROUTE_TYPE_ADMIN_DICT = {
    ...KEDO_DOCUMENTS_ROUTE_TYPE_DICT,
    [KEDO_DOCUMENTS_ROUTE_TYPE.BASE_ROUTE]: "Базовый",
};