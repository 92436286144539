export const BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST = "BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST";
export const BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS = "BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS";
export const BFF_CLIENTS_GET_MONITORING_RISKS_ERROR = "BFF_CLIENTS_GET_MONITORING_RISKS_ERROR";

export const BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST = "BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST";
export const BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS = "BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS";
export const BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR = "BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR";

export const BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST = "BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST";
export const BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS = "BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS";
export const BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR = "BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR";

export const BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST = "BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST";
export const BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS = "BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS";
export const BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR = "BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR";

export const BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE = "BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE";
export const BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE = "BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE";