import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_BANK_W1_HISTORY_GET_PAGE_ERROR,
    BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST,
    BFF_BANK_W1_HISTORY_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/finances/bank/w1";

// POST /bff/adm/finances/bank/w1/history/get-rich-page
// Получение обогащенной страницы с депозитами из W1
const getPageBankW1History = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/history/get-rich-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_BANK_W1_HISTORY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_BANK_W1_HISTORY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_BANK_W1_HISTORY_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST, getPageBankW1History),
    ]);
}