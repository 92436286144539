export const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST";
export const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS";
export const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR";

export const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS";
export const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS";
export const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR";

export const MAINTENANCE_NOTICE_PUBLISHED_REQUEST = "MAINTENANCE_NOTICE_PUBLISHED_REQUEST";
export const MAINTENANCE_NOTICE_PUBLISHED_SUCCESS = "MAINTENANCE_NOTICE_PUBLISHED_SUCCESS";
export const MAINTENANCE_NOTICE_PUBLISHED_ERROR = "MAINTENANCE_NOTICE_PUBLISHED_ERROR";

export const SYSTEM_SETTING_GET_REQUEST = "SYSTEM_SETTING_GET_REQUEST";
export const SYSTEM_SETTING_GET_SUCCESS = "SYSTEM_SETTING_GET_SUCCESS";
export const SYSTEM_SETTING_GET_ERROR = "SYSTEM_SETTING_GET_ERROR";

export const SYSTEM_SETTING_SET_REQUEST = "SYSTEM_SETTING_SET_REQUEST";
export const SYSTEM_SETTING_SET_SUCCESS = "SYSTEM_SETTING_SET_SUCCESS";
export const SYSTEM_SETTING_SET_ERROR = "SYSTEM_SETTING_SET_ERROR";

export const SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST = "SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST";
export const SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS = "SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS";
export const SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR = "SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR";

export const GET_EMPTY_RECORD_REQUEST = "GET_EMPTY_RECORD_REQUEST";
export const GET_EMPTY_RECORD_SUCCESS = "GET_EMPTY_RECORD_SUCCESS";
export const GET_EMPTY_RECORD_ERROR = "GET_EMPTY_RECORD_ERROR";

export const DELETE_EMPTY_RECORD_REQUEST = "DELETE_EMPTY_RECORD_REQUEST";
export const DELETE_EMPTY_RECORD_SUCCESS = "DELETE_EMPTY_RECORD_SUCCESS";
export const DELETE_EMPTY_RECORD_ERROR = "DELETE_EMPTY_RECORD_ERROR";

export const NPD_DEPOSIT_CORRECT_REQUEST = "NPD_DEPOSIT_CORRECT_REQUEST";
export const NPD_DEPOSIT_CORRECT_SUCCESS = "NPD_DEPOSIT_CORRECT_SUCCESS";
export const NPD_DEPOSIT_CORRECT_ERROR = "NPD_DEPOSIT_CORRECT_ERROR";

export const NDFL_DEPOSIT_CORRECT_REQUEST = "NDFL_DEPOSIT_CORRECT_REQUEST";
export const NDFL_DEPOSIT_CORRECT_SUCCESS = "NDFL_DEPOSIT_CORRECT_SUCCESS";
export const NDFL_DEPOSIT_CORRECT_ERROR = "NDFL_DEPOSIT_CORRECT_ERROR";

export const UPDATE_SETTINGS_SERVICE_STORE = "UPDATE_SETTINGS_SERVICE_STORE";