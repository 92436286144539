export const GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST = "GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST";
export const GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS = "GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS";
export const GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR = "GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR";

export const EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST = "EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST";
export const EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS = "EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS";
export const EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR = "EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR";

export const UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST = "UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST";
export const UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS = "UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS";
export const UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR = "UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR";

export const CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS = "CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS";