import {NM_ADMIN_INFO} from "./contractorInfo";
import {USER_TYPE} from "./roles";

export const IMAGE_EXTENSIONS = ["raw", "bmp", "gif", "png", "jpeg", "jpg"];
export const AUDIO_EXTENSIONS = ["aac", "flac", "mp3", "ogg", "wav", "m4a", "amr"];

export const CHAT_FILES_ACCEPT=".raw, .tiff, .tif, .bmp, .gif, .png, .jpeg, .jpg, .odt, .doc, .docx, .pdf, .aac, .flac, .mp3, .ogg, .wav, .m4a, .amr";

export const naimixDefaultMessage = {
    partiesId: NM_ADMIN_INFO.ID,
    partiesType: USER_TYPE.NAIMIX_USER.VALUE,
    content: "Здравствуйте, наша команда всегда рада помочь, если вы столкнулись с трудностями. Расскажите о вашей проблеме.",
    isUserNamemix: true,
    files: [],
};

export const naimixSupportPhone = "74953477379";