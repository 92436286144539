import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CONTRACTOR_DOCUMENTS_GET_COUNTS_ERROR,
    CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST,
    CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS,
    CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR,
    CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST,
    CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS,
    CONTRACTOR_DOCUMENTS_REFRESH_ERROR,
    CONTRACTOR_DOCUMENTS_REFRESH_REQUEST,
    CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS,
    GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR,
    GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST,
    GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST,
    GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS,
    PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR,
    PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST,
    PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/contractors/contractors-registry/contractor-card/documents/common",
        client: "/client-adm/contractors/contractor-card/documents/common",
    });
};

const getPageContractorDocuments = function* ({payload}) {
    try {
        const {
            field,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DOCUMENTS_GET_PAGE_SUCCESS,
            payload: {
                field,
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DOCUMENTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const refreshContractorDocuments = function* ({payload}) {
    try {
        const {
            contractorId,
        } = payload;

        const result = yield request.bff.post(`${getController()}/refresh-contractor-documents?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DOCUMENTS_REFRESH_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DOCUMENTS_REFRESH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DOCUMENTS_REFRESH_ERROR,
            payload: error,
        });
    }
};

const getCountsContractorDocuments = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/get-contractor-page-counts`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DOCUMENTS_GET_COUNTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DOCUMENTS_GET_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DOCUMENTS_GET_COUNTS_ERROR,
            payload: error,
        });
    }
};

const getRegistryFrameContractShortItems = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/contractors/contractor-card/documents/registry-frame-contract/get-registry-frame-contract-page",
                adminRolesUrl: "/adm/contractors/contractors-registry/contractor-card/documents/registry-frame-contract/get-registry-frame-contract-page",
            },
        );

        const result = yield request.bff.post(url, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR, payload: error});
    }
};

const getContractorIdentificationSheet = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/contractors/contractor-card/documents/identification-sheet/get-page",
                adminRolesUrl: "/adm/contractors/contractors-registry/contractor-card/documents/identification-sheet/get-page",
            },
        );

        const result = yield request.bff.get(url, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR, payload: error});
    }
};

const getContractorPendingContractsList = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/contractors/contractor-card/documents/pending-contracts/page",
                adminRolesUrl: "/adm/contractors/contractors-registry/contractor-card/documents/pending-contracts/page",
            },
        );

        const result = yield request.bff.get(url, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_DOCUMENTS_GET_PAGE_REQUEST, getPageContractorDocuments),
        takeEvery(CONTRACTOR_DOCUMENTS_REFRESH_REQUEST, refreshContractorDocuments),
        takeEvery(CONTRACTOR_DOCUMENTS_GET_COUNTS_REQUEST, getCountsContractorDocuments),
        takeEvery(GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST, getRegistryFrameContractShortItems),
        takeEvery(GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST, getContractorIdentificationSheet),
        takeEvery(PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST, getContractorPendingContractsList),
    ]);
}