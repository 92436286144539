import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";

import {usePagination} from "../../../../hooks/usePagination";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {getFormattedFullName, getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {SUB_PAGE_CRM_TICKET_CARD, TICKET_SORT_TYPE, TICKET_STATUS_DICT} from "../../../../constants/crm/ticket";
import {LINK_CLIENT_INFO, LINK_CONTRACTOR_PERSONAL_INFO, LINK_CRM_TICKET_CARD} from "../../../../constants/links";

import {
    getTicketHistoryRichPage,
    getTicketTotalCount,
    ticketCallTypeDictSelector,
    ticketCategoryDictSelector,
    ticketListSelector,
    ticketTotalCountSelector,
    ticketTotalPagesSelector,
    ticketTypeDictSelector,
} from "../../../../ducks/crm/ticket";

function TicketHistory(props) {
    const {
        card: {
            baseModel,
        },
        subpageList,
    } = props;
    const list = useSelector(ticketListSelector);
    const {
        fromNumber: phoneFilter,
    } = baseModel || {};

    const totalCount = useSelector(ticketTotalCountSelector);
    const totalPages = useSelector(ticketTotalPagesSelector);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const ticketCategoryDict = useSelector(ticketCategoryDictSelector);
    const ticketCallTypeDict = useSelector(ticketCallTypeDictSelector);
    const ticketTypeDict = useSelector(ticketTypeDictSelector);

    useEffect(() => {
        if(phoneFilter){
            fetchList();
        }
    }, [pageNum, pageSize, phoneFilter]);
    const fetchList = () => {
        const reqData = {
            pageSize,
            pageNum,
            phoneFilter,
            orderType: TICKET_SORT_TYPE.RECALL_IN_WORK_MISSED_CLOSED,
            statusesFilter: [
                TICKET_STATUS_DICT.RECALL.VALUE,
                TICKET_STATUS_DICT.IN_WORK.VALUE,
                TICKET_STATUS_DICT.CLOSED.VALUE,
                TICKET_STATUS_DICT.MISSED.VALUE,
            ],
        };
        dispatch(getTicketHistoryRichPage(reqData));
        dispatch(getTicketTotalCount(reqData));
    };

    function renderLabelByModels(item) {
        const {
            clientUserModel,
            contractorModel,
            clientModel,
        } = item;

        if (contractorModel) {
            const {
                lastName,
                firstName,
                patronymic,
                fio,
                contractorId,
            } = contractorModel || {};

            const content = lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : fio;
            const linkCard = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId);

            return  <ExtLink
                pageData={{
                    pageSize,
                    pageNum,
                }}
                historyEnabled
                to={linkCard}
            >
                {content}
            </ExtLink>;
        }

        if (clientUserModel && clientModel) {
            const {
                lastName,
                firstName,
                patronymic,
                fio,
            } = clientUserModel || {};
            const {name, clientId} = clientModel;

            const _fio = lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : fio;
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);

            return <div>
                <ExtLink
                    pageData={{
                        pageSize,
                        pageNum,
                    }}
                    historyEnabled
                    to={linkCard}
                >
                    {name}
                </ExtLink>
                &nbsp;
                {_fio}
            </div>;
        }

        return "Не определено";
    }

    function toCard(content, ticketId) {
        const linkCard = LINK_CRM_TICKET_CARD
            .replace(":subpage", subpageList)
            .replace(":ticketId", ticketId)
            .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK);

        return (
            <ExtLink
                pageData={{
                    pageSize,
                    pageNum,
                }}
                historyEnabled
                to={linkCard}
            >
                {content}
            </ExtLink>
        );
    }

    const renderStatus = status => {
        return (
            <NmBadge
                noWrap
                condition
                mod={TICKET_STATUS_DICT[status].MOD}
                text={TICKET_STATUS_DICT[status].TEXT}
            />
        );
    };

    function getRows() {
        return list.map(item => {
            const {
                baseModel: {
                    createDate,
                    orderNumber,
                    fromNumber,
                    type,
                    category,
                    callType,
                    ticketId,
                    status,
                },
                managerModel,
            } = item;
            const {
                lastName,
                firstName,
                patronymic,
            } = managerModel || {};

            const label = renderLabelByModels(item);

            return {
                ...item,
                ticketId,
                contentRow: (
                    <NmListCard
                        secondaryHeaderStatus={renderStatus(status)}
                        checkbox
                        primaryHeaderLink
                        secondaryHeader={`Обращение от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={toCard(`Обращение №${orderNumber} - ${type ? ticketTypeDict[type] : "Не определено"}`, ticketId)}
                        cards={[
                            {
                                title: "Категория обращения",
                                className: "col-16 col-md-3 col-xl-2 col-xxl-2",
                                value: ticketCategoryDict[category] || "-",
                                overflowEllipseText: true,
                            },
                            {
                                title: "Источник",
                                className: "col-16 col-md-3 col-xl-2 col-xxl-2",
                                value: ticketCallTypeDict[callType] || "-",
                                overflowEllipseText: true,
                            },
                            {
                                title: "Ответственный",
                                className: "col-16 col-md-3 col-xl-2 col-xxl-2",
                                value: lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : "-",
                                overflowEllipseText: true,
                            },
                        ]}
                        classNameMainContent="col-16 col-md-7 col-xl-10 col-xxl-10"
                        cardsContainerClassName="col-16 col-md-9 col-xl-6 col-xxl-6 mt-md-4 mt-xxl-0"
                        labels={[
                            {

                                label,
                                text: phoneFormat(fromNumber),
                                noWrapLabel: false,
                                className: "flex-aligned-end col-md-14 col-xl-14 col-xxl-14",
                                noWrap: false,
                                flexWrap: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmPage
            noPadding
            currentPageNum={pageNum}
            totalPages={totalPages}
            currentPageSize={pageSize}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
        >
            {list.length === 0 && <NmEmptyPageV2 title="Информация отсутствует" />}
            {list.length > 0 && <CheckboxList
                rows={getRows()}
            />}
        </NmPage>
    );
}

export default TicketHistory;