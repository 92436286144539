import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    TARIFF_SCALE_ADD_ERROR,
    TARIFF_SCALE_ADD_REQUEST,
    TARIFF_SCALE_ADD_SUCCESS,
    TARIFF_SCALE_CHECK_DOWNLOAD_ERROR,
    TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST,
    TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS,
    TARIFF_SCALE_CHECK_EXIST_ERROR,
    TARIFF_SCALE_CHECK_EXIST_REQUEST,
    TARIFF_SCALE_CHECK_EXIST_SUCCESS,
    TARIFF_SCALE_DELETE_ERROR,
    TARIFF_SCALE_DELETE_REQUEST,
    TARIFF_SCALE_DELETE_SUCCESS,
    TARIFF_SCALE_GET_BY_ID_ERROR,
    TARIFF_SCALE_GET_BY_ID_REQUEST,
    TARIFF_SCALE_GET_BY_ID_SUCCESS,
    TARIFF_SCALE_GET_PAGE_ERROR,
    TARIFF_SCALE_GET_PAGE_REQUEST,
    TARIFF_SCALE_GET_PAGE_SUCCESS,
    TARIFF_SCALE_IMPORT_ERROR,
    TARIFF_SCALE_IMPORT_REQUEST,
    TARIFF_SCALE_IMPORT_SUCCESS,
    TARIFF_SCALE_UPDATE_ERROR,
    TARIFF_SCALE_UPDATE_REQUEST,
    TARIFF_SCALE_UPDATE_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../../../../utils/downloadBlob";
import request, {getMultipartHeaders} from "../../../../../../utils/postman";
import {toastError} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/tariff-grid";

const getTariffScaleListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: TARIFF_SCALE_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_PAGE_ERROR, payload: error});
    }
};

const addTariffScaleListSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...requestData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: TARIFF_SCALE_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_ADD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_ADD_ERROR, payload: error});
    }
};

const deleteTariffScaleListSaga = function* ({payload}) {
    const {
        onSuccess,
        tariffScaleId,
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/${tariffScaleId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_DELETE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_DELETE_ERROR, payload: error});
    }
};

const updateTariffScaleSaga = function* ({payload}) {
    try {
        const {
            tariffScaleId,
            onError,
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.bff.patch(`${controller}/${tariffScaleId}`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError(result);

            yield put({
                type: TARIFF_SCALE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_UPDATE_ERROR, payload: error});
    }
};

const checkExistTariffScaleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.bff.post(`${controller}/exist`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_CHECK_EXIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_CHECK_EXIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_EXIST_ERROR, payload: error});
    }
};

const getByIdTariffScaleSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: TARIFF_SCALE_GET_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_BY_ID_ERROR, payload: error});
    }
};

const importTariffScaleSaga = function* ({payload}) {
    const {
        formData,
        onSuccess,
        onError,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/import`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_IMPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_IMPORT_SUCCESS, payload: result});
    } catch (error) {
        onError();
        yield put({type: TARIFF_SCALE_IMPORT_ERROR, payload: error});
    }
};

const checkExistByUpdateTariffScaleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/exist-by-update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR, payload: error});
    }
};

const downloadTariffScaleSaga = function* () {
    try {
        const result = yield request.bff.getFile(`${controller}/download`, {method: "POST"});

        if (result.status === 200 && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                return {
                    done: true,
                };
            }
        }

        const fileName = result.headers.get("Content-Disposition").split("filename=")[1];
        const blob = yield result.blob();

        yield downloadBlob(blob, result.headers, fileName);

        yield put({type: TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_DOWNLOAD_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(TARIFF_SCALE_GET_PAGE_REQUEST, getTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_ADD_REQUEST, addTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_DELETE_REQUEST, deleteTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_UPDATE_REQUEST, updateTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_EXIST_REQUEST, checkExistTariffScaleSaga),
        takeEvery(TARIFF_SCALE_GET_BY_ID_REQUEST, getByIdTariffScaleSaga),
        takeEvery(TARIFF_SCALE_IMPORT_REQUEST, importTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST, checkExistByUpdateTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST, downloadTariffScaleSaga),
    ]);
}