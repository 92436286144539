import React from "react";

import NmDropdownV2 from "../NmDropdownV2";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {dictionaryToOptions} from "../../../utils/objectHelper";

import {ORDER_EDIT_ACCESS, ORDER_EDIT_ACCESS_DICT} from "../../../constants/order";

import "./style.sass";

interface OrderEditAccessFieldProps {
    isColumn?: boolean,
    isAccessEdit?: boolean,
    children: React.ReactNode,
    onChange: (event: null, data: OptionType) => void,
    value?: string,
    className?: string,
}

const AccessEditField = (props: OrderEditAccessFieldProps) => {
    const {
        isAccessEdit,
        children,
        onChange,
        isColumn,
        value = ORDER_EDIT_ACCESS.ALLOWED,
        className,
    } = props;

    const [block, element] = bem("edit-field-access", className);

    if (isAccessEdit) {
        return (
            <div className={block({column: isColumn})}>
                <div className="edit-field-access__target">
                    {children}
                </div>
                <div className={element("manage", {column: isColumn})}>
                    <NmDropdownV2
                        options={dictionaryToOptions(ORDER_EDIT_ACCESS_DICT)}
                        value={value}
                        onChange={onChange}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default AccessEditField;