import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputPassword from "../../../../components/ActualComponents/NmInputPassword";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";
import {UserRolesDropdown} from "../../../../components/UserRolesDropdown";
import {ReactComponent as LockIcon} from "../../../../images/lock_24.svg";

import {DataChange} from "../../../document-management/document-management-statement/filter/hooks/useStatementFilter";
import {
    changeFormAction,
    changePasswordFormAction,
    changeVisibleForm,
    PasswordFormType,
    submitFormAction,
    toggleEditPasswordAction,
    validatePassword,
} from "../hooks/useUserFormEdit";

import {OptionType} from "../../../document-management/document-management-statement/list/item/utils/getOptions";

import {CLIENT_MEMBER_FIELD_NAME} from "../../../../constants/clientList";
import {NM_COORDINATOR, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";
import {USERS_ROLES_TYPE} from "../../../../constants/settingsUsersRoles";

import {
    bffSettingsSettingsEmployeesClientsForSelectionOptionsSelector,
} from "../../../../ducks/bff/settings/employees/selectors";

import "./style.sass";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../../constants/link-params";

export type UserType = {
    clientId: string,
    lastName: string,
    firstName: string,
    patronymic?: string,
    password?: string | null,
    position?: string,
    phone: string,
    login: string,
    role: string,
    roleId: string,
    email: string,
    fullName?: string,
    inn?: string,
    locked?: boolean,
    deleted?: boolean,
    archived?: boolean,
    clientUserId: string,
    active?: boolean,
    snils?: string,
    partnerPromotionClientIdList?: string[],
    partnerPromotionContractorIdList?: string[],
    availableClients?: string[],
    canManageAllClients?: boolean,
    sampleType?: string,
    sipNumber?: string,
    extensionNumber?: string,
    sendPassword: boolean
}

export type UserEditModalTypeFormErrorType = {
    lastName?: string,
    firstName?: string,
    patronymic?: string,
    password?: string,
    position?: string,
    phone?: string,
    role?: string,
    roleId?: string,
    email?: string,
    login?: string,
    snils?: string,
    partnerPromotionClientIdList?: string[],
    partnerPromotionContractorIdList?: string[],
    repeatPassword?: string,
    availableClients?: string[],
    sipNumber?: string,
    extensionNumber?: string
}

export type UserEditModalPropsType = {
    loading: boolean,
    subpage: string,
    user: UserType,
    t: Function,
    formError: UserEditModalTypeFormErrorType,
    dispatchForm: Function,
    promocodePartnerClientsOptions: OptionType[],
    promocodePartnerContractorsOptions: OptionType[],
    passwordForm: PasswordFormType,
    title: string,
    isEditPassword: boolean,
    positionsOptions: OptionType[],
    roleOptions: OptionType[],
    currentClientUserId: string
};

export const COMPANY_SAMPLE_TYPE = {
    ALL: "ALL",
    MULTIPLE: "MULTIPLE",
};

function UserEditModal(props: UserEditModalPropsType) {
    const {
        loading,
        subpage,
        formError,
        user,
        t: translate,
        dispatchForm,
        promocodePartnerClientsOptions,
        promocodePartnerContractorsOptions,
        passwordForm,
        title,
        isEditPassword,
        roleOptions,
        currentClientUserId,
    } = props;

    const clientsForSelectionOptions = useSelector(bffSettingsSettingsEmployeesClientsForSelectionOptionsSelector);

    const handleClose = () => {
        dispatchForm(changeVisibleForm());
    };

    const submit = () => {
        dispatchForm(submitFormAction());
    };

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>, {value, name}: any) => {
        const form = {
            fieldFormName: name,
            value,
        };

        if (name === CLIENT_MEMBER_FIELD_NAME.ROLE && value !== NM_OPERATOR) {
            dispatchForm(changeFormAction({
                fieldFormName: CLIENT_MEMBER_FIELD_NAME.EXTENSION_NUMBER,
                value: "",
            }));
            dispatchForm(changeFormAction({
                fieldFormName: CLIENT_MEMBER_FIELD_NAME.SIP_NUMBER,
                value: "",
            }));
        }

        dispatchForm(changeFormAction(form));
    };

    const onChangePasswordForm = (e: React.ChangeEvent<HTMLInputElement>, {value, name}: DataChange) => {
        const form = {
            fieldFormName: name,
            value,
        };
        dispatchForm(changePasswordFormAction(form));
    };

    function renderPasswords() {
        return (
            <>
                <div className="row">
                    <div className="col-16 col-md-4">
                        <NmRadioV2
                            label="Ввести пароль"
                            checked={!user.sendPassword}
                            name="sendPassword"
                            value={false}
                            onChange={handleChangeForm}
                        />
                    </div>
                    <div className="col-16 col-md-12">
                        <NmRadioV2
                            label="Сгенерировать автоматически и отправить на почту"
                            checked={user.sendPassword}
                            name="sendPassword"
                            value={true}
                            onChange={handleChangeForm}
                        />
                    </div>
                </div>
                {
                    !user.sendPassword &&
                    <>
                        <NmInputPassword
                            labelClassName="d-flex"
                            required
                            label={translate("client-member-new.password")}
                            size="xl"
                            error={formError.password}
                            autoComplete="new-password"
                            name="password"
                            placeholder="Введите пароль"
                            value={passwordForm.password}
                            onChange={onChangePasswordForm}
                        />
                        <NmInputPassword
                            labelClassName="d-flex"
                            required
                            size="xl"
                            error={formError.repeatPassword}
                            autoComplete="new-password"
                            name="repeatPassword"
                            placeholder={translate("client-member-new.repeat-password")}
                            value={passwordForm.repeatPassword}
                            onBlur={() => dispatchForm(validatePassword())}
                            onChange={onChangePasswordForm}
                        />
                    </>
                }
            </>
        );
    }

    // @ts-ignore
    return (
        <NmModal
            size="md"
            onClose={handleClose}
            className="user-edit-modal"
            header={<NmTitle
                size="lg"
                    >
                {title}
            </NmTitle>}
            footer={
                <ApplyButtons
                    disabled={loading}
                    className="user-edit-modal__buttons-apply"
                    onClose={handleClose}
                    submitBtnContent={user.clientUserId ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={submit}
                />
            }
        >
            <NmForm
                autoComplete="off"
                className="user-edit-modal__form"
                addMargin
            >
                <NmTitle
                    className="user-edit-modal__form-sub-title"
                    size="md"
                >
                    Персональные данные
                </NmTitle>
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.last-name")}
                    name={CLIENT_MEMBER_FIELD_NAME.LAST_NAME}
                    error={formError?.lastName}
                    value={user.lastName}
                    required
                    placeholder="Введите фамилию"
                    onChange={handleChangeForm}
                />
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.first-name")}
                    name={CLIENT_MEMBER_FIELD_NAME.FIRST_NAME}
                    error={formError.firstName}
                    value={user.firstName}
                    required
                    placeholder="Введите имя"
                    onChange={handleChangeForm}
                />
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.patronymic")}
                    name={CLIENT_MEMBER_FIELD_NAME.PATRONYMIC}
                    error={formError.patronymic}
                    value={user.patronymic}
                    placeholder="Введите отчество"
                    onChange={handleChangeForm}
                />
                {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK &&
                <>
                    <NmTitle
                        className="user-edit-modal__form-sub-title"
                        size="md"
                    >
                        Роль
                    </NmTitle>
                    <NmInputV2
                        size="xl"
                        maxLength={100}
                        placeholder="Введите должность"
                        label={translate("client-member-new.position")}
                        name={CLIENT_MEMBER_FIELD_NAME.POSITION}
                        onChange={handleChangeForm}
                        error={formError.position}
                        value={user.position}
                    />
                    <NmDropdownV2
                        required
                        size="xl"
                        placeholder="Выберите роль"
                        disabled={user.clientUserId === currentClientUserId}
                        label={translate("client-member-new.role")}
                        name={CLIENT_MEMBER_FIELD_NAME.ROLE}
                        onChange={handleChangeForm}
                        error={formError.role}
                        value={user.role}
                        options={roleOptions}
                    />
                    <UserRolesDropdown
                        size="xl"
                        placeholder="Выберите роль"
                        disabled={user.clientUserId === currentClientUserId}
                        label="Новая роль"
                        name={CLIENT_MEMBER_FIELD_NAME.ROLE_ID}
                        onChange={handleChangeForm}
                        error={formError.roleId}
                        value={user.roleId}
                        type={USERS_ROLES_TYPE.NAIMIX}
                    />
                </>
                }
                {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK && [NM_MANAGER, NM_COORDINATOR].includes(user.role) &&
                <>
                    <NmTitle
                        className="user-edit-modal__form-sub-title"
                        size="md"
                    >
                        Компании
                    </NmTitle>
                    <NmRadioV2
                        label="Все компании"
                        checked={COMPANY_SAMPLE_TYPE.ALL === user.sampleType}
                        name={CLIENT_MEMBER_FIELD_NAME.SAMPLE_TYPE}
                        value={COMPANY_SAMPLE_TYPE.ALL}
                        onChange={handleChangeForm}
                    />
                    <NmRadioV2
                        label="Выбрать одну или несколько компаний"
                        checked={COMPANY_SAMPLE_TYPE.MULTIPLE === user.sampleType}
                        name={CLIENT_MEMBER_FIELD_NAME.SAMPLE_TYPE}
                        value={COMPANY_SAMPLE_TYPE.MULTIPLE}
                        onChange={handleChangeForm}
                    />
                    <NmDropdownV2
                        placeholder="Показать все"
                        size="xl"
                        search
                        disabled={COMPANY_SAMPLE_TYPE.ALL === user.sampleType}
                        multiple
                        name={CLIENT_MEMBER_FIELD_NAME.AVAILABLE_CLIENTS}
                        value={user.availableClients || []}
                        options={clientsForSelectionOptions}
                        onChange={handleChangeForm}
                    />
                </>
                }
                <NmTitle
                    className="user-edit-modal__form-sub-title"
                    size="md"
                >
                    Контакты
                </NmTitle>
                <NmInputV2
                    required
                    size="xl"
                    maskChar={null}
                    mask="+7 999 999 9999"
                    label={translate("client-member-new.phone-number")}
                    name={CLIENT_MEMBER_FIELD_NAME.PHONE}
                    error={formError.phone}
                    value={user.phone}
                    placeholder="Введите телефон"
                    onChange={handleChangeForm}
                />
                <NmInputV2
                    required
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Email"
                    placeholder="Введите email"
                    name={CLIENT_MEMBER_FIELD_NAME.EMAIL}
                    error={formError.email}
                    value={user.email}
                    onChange={handleChangeForm}
                />
                <NmTitle
                    className="member-edit__sub-title"
                    size="md"
                >
                    Логин
                </NmTitle>
                <NmInputV2
                    required
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Логин"
                    placeholder="Введите логин"
                    name={CLIENT_MEMBER_FIELD_NAME.LOGIN}
                    error={formError.login}
                    value={user.login}
                    onChange={handleChangeForm}
                />
                {subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK &&
                <>
                    <NmTitle
                        className="user-edit-modal__form-sub-title"
                        size="md"
                    >
                        Каналы привлечения
                    </NmTitle>
                    <NmDropdownV2
                        size="xl"
                        label="Каналы привлечения для компаний"
                        placeholder="Каналы привлечения для компаний"
                        search
                        name={CLIENT_MEMBER_FIELD_NAME.PARTNER_PROMOTION_CLIENTS_ID_LIST}
                        onChange={handleChangeForm}
                        error={formError.partnerPromotionClientIdList}
                        value={user.partnerPromotionClientIdList || []}
                        options={promocodePartnerClientsOptions}
                        multiple
                    />
                    <NmDropdownV2
                        size="xl"
                        placeholder="Каналы привлечения для исполнителей"
                        label="Каналы привлечения для исполнителей"
                        search
                        name={CLIENT_MEMBER_FIELD_NAME.PARTNER_PROMOTION_CONTRACTOR_ID_LIST}
                        onChange={handleChangeForm}
                        error={formError.partnerPromotionContractorIdList}
                        value={user.partnerPromotionContractorIdList || []}
                        options={promocodePartnerContractorsOptions}
                        multiple
                    />
                </>
                }
                {user.role === NM_OPERATOR &&
                <>
                    <NmTitle
                        className="user-edit-modal__form-sub-title"
                        size="md"
                    >
                        Доп. параметры
                    </NmTitle>
                    <NmInputV2
                        required
                        size="xl"
                        maxLength={255}
                        autoComplete="off"
                        label="SIP"
                        placeholder="Введите SIP номер"
                        name={CLIENT_MEMBER_FIELD_NAME.SIP_NUMBER}
                        error={formError.sipNumber}
                        value={user.sipNumber}
                        onChange={handleChangeForm}
                    />
                    <NmInputV2
                        required
                        size="xl"
                        maxLength={255}
                        autoComplete="off"
                        label="Внутренний номер"
                        type="number"
                        placeholder="Введите внутренний номер"
                        name={CLIENT_MEMBER_FIELD_NAME.EXTENSION_NUMBER}
                        error={formError.extensionNumber}
                        value={user.extensionNumber}
                        onChange={handleChangeForm}
                    />
                </>
                }
                <NmTitle
                    className="user-edit-modal__form-sub-title"
                    size="md"
                >
                    Пароль
                </NmTitle>
                {
                    user.clientUserId &&
                    <NmButton
                        size="xl"
                        color="white"
                        className="user-edit-modal__toggle-password"
                        onClick={() => dispatchForm(toggleEditPasswordAction())}
                        icon={
                            <LockIcon
                                className="nm-icon nm-icon_gray"
                            />
                        }
                    >
                        {isEditPassword ? "Отменить" : "Сменить пароль"}
                    </NmButton>
                }
                {
                    isEditPassword && user.clientUserId &&
                    <NmTitle
                        className="user-edit-modal__form-sub-title"
                        size="md"
                    >
                        Смена пароля
                    </NmTitle>
                }
                {isEditPassword && renderPasswords()}
            </NmForm>
        </NmModal>
    );
}

export default UserEditModal;