import React from "react";
import {useDispatch, useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";

import {useOrderEditRecommendation} from "./hooks/useRecommendation";

import bem from "../../../../utils/bem";
import {
    formatAmountWithNullChecking,
    getNumberFromFormattedAmount,
} from "../../../../utils/stringFormat";
import {getOrderRecommendationIndicatorText} from "./utils/getRecommendationText";

import {mlModelsPredictAmountSelector} from "../../../../ducks/bff/settings/machine-learning/selectors";
import {addOrderRecommendStat, unitsAbbreviationDictSelector} from "../../../../ducks/order";

import "./style.sass";

export const OrderEditTariffScaleIndicator = (props) => {
    const {
        scale,
        isSecondType,
        workUnitPrice,
        workUnit,
        orderId,
        objectId,
        clientId,
        name,
        workStartDate,
        workEndDate,
        description,
        longitude,
        latitude,
        specialityId,
    } = props;

    const [block, element] = bem("order-edit-tariff-scale-indicator");
    const predictAmount = useSelector(mlModelsPredictAmountSelector);
    const abbreviationMap = useSelector(unitsAbbreviationDictSelector);

    const dispatch = useDispatch();

    const isRecommend = predictAmount &&
        workUnit === "HOUR" &&
        name &&
        description &&
        longitude &&
        latitude &&
        specialityId;

    const isTariff = typeof scale === "number" && workUnitPrice;
    const matchTariff = isTariff && getNumberFromFormattedAmount(workUnitPrice) >= getNumberFromFormattedAmount(scale);

    const {
    } = useOrderEditRecommendation({
        workUnitPrice,
        workUnit,
        name,
        description,
        longitude,
        latitude,
        workStartDate,
        specialityId,
    });

    const getRecommendationText = () => {
        if (!isRecommend) {
            return null;
        }

        const unitText = isSecondType ? `/${abbreviationMap.get(workUnit) || ""}` : "";

        return getOrderRecommendationIndicatorText(predictAmount, unitText);
    };

    const getTariffText = () => {
        if (!isTariff) {
            return null;
        }

        if (matchTariff) {
            return "Предложенная вами ставка по данному виду деятельности заинтересует исполнителей";
        }

        const unitText = isSecondType ? `/${abbreviationMap.get(workUnit) || ""}` : "";

        return (
            <>
                <>
Указанная вами ставка по данному виду деятельности и локации ниже рекомендованной:&nbsp;
                </>
                <span className="order-edit-tariff-scale-indicator__amount">
                    {`${formatAmountWithNullChecking(scale)}₽${unitText} \n`}
                </span>
            </>
        );
    };

    if (!isTariff && !isRecommend) {
        return null;
    }

    const onIconClick = () => {
        if (isRecommend) {
            dispatch(addOrderRecommendStat(
                {
                    data: {
                        name,
                        description,
                        latitude,
                        longitude,
                        specialityId,
                        orderId,
                        objectId,
                        predictedAmount: predictAmount,
                        amount: workUnitPrice,
                        workStartDate,
                        workEndDate,
                        clientId,
                    },
                },
            ));
        }
    };

    return (
        <HelpTooltip
            hover
            onClick={onIconClick}
            info
            position="bottom-left"
            classNameTooltip={element("tooltip")}
            classNameIcon={element("icon", {matchTariff})}
            text={
                <>
                    {getTariffText()}
                    {getRecommendationText()}
                </>
            }
        />
    );
};