import React, {FC, ReactNode} from "react";

import {ReactComponent as CloseIcon} from "../../../../images/close_24.svg";
import NmTitle from "../../../NmTitle";
import Portal from "../../../Portal";

import bem from "../../../../utils/bem";

import "./style.sass";

interface INmPageVerticalFilter {
    className?: string,
    open: boolean,
    filtersBase?: ReactNode,
    toggleFilter: () => void,
    hardVisibleFilter?: boolean,
}

const NmPageVerticalFilter: FC<INmPageVerticalFilter> = (props) => {
    const {
        hardVisibleFilter = true,
        open,
        className,
        toggleFilter,
        filtersBase,
    } = props;
    const [block, element] = bem("nm-page-vertical-filter", className);

    return (
        <Portal node={document.body}>
            <div className={block({open, hidden: !hardVisibleFilter && !open})}>
                <div className={element("body")}>
                    <div className={element("title")}>
                        <NmTitle size="md">
Фильтры
                        </NmTitle>
                        <CloseIcon
                            className={element("hide")}
                            onClick={toggleFilter}
                        >
                            Свернуть
                        </CloseIcon>
                    </div>
                    {
                        filtersBase &&
                        <div className={element("fields")}>
                            {filtersBase}
                        </div>
                    }
                </div>
            </div>
            <div
                onClick={toggleFilter}
                className={element("layer", {hidden: !hardVisibleFilter && !open})}
            />
        </Portal>
    );
};

export default NmPageVerticalFilter;