import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import validate from "../../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {clientCardRule} from "../../../../constants/validationRules";

import {updateClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardInfoSelector, getRepresentativeInfoSelector} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class RepresentativeCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            errorForm: {},
            editRepresentativeInfo: {},
            representativeInfo: {},
        };
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            representativeInfo: _representativeInfo,
            isEdit,
        } = state;
        const {representativeInfo} = newProps;

        if (JSON.stringify(representativeInfo) !== JSON.stringify(_representativeInfo) && !isEdit) {
            return {
                ...state,
                editRepresentativeInfo: {
                    ...representativeInfo,
                },
                representativeInfo,
            };
        }

        return null;
    }

    isValidEditClient(editClient) {
        const rule = clientCardRule.representativeInfo;

        const errorForm = validate(editClient, rule, "");

        this.setState(prevState => ({
            ...prevState,
            errorForm,
        }));

        return Object.values(errorForm).length === 0;
    }

    submitRepresentativeInfo = () => {
        const {updateClientCardInfo, representativeInfo, client} = this.props;

        const {editRepresentativeInfo} = this.state;

        if (JSON.stringify(editRepresentativeInfo) === JSON.stringify(representativeInfo)) {
            return;
        }

        const requestData = {
            ...client,
            ...editRepresentativeInfo,
        };

        updateClientCardInfo(requestData);
    };

    cancelEdit = () => {
        const {editRepresentativeInfoCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            editRepresentativeInfo: editRepresentativeInfoCopy,
        }));
    };

    toggleCard = cardType => {
        const {isEdit, editRepresentativeInfo} = this.state;

        if (isEdit) {
            if (!this.isValidEditClient(editRepresentativeInfo, cardType)) {
                return;
            }
        } else {
            this.setState({
                editRepresentativeInfoCopy: editRepresentativeInfo,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {
                    this.submitRepresentativeInfo();
                }
            },
        );
    };

    handleChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            editRepresentativeInfo: {
                ...prevState.editRepresentativeInfo,
                [name]: value,
            },
        }));
    };

    renderRepresentativeReadOnly(client) {
        const {t} = this.props;

        return (
            <>
                <LabelTextApp
                    label={t("client-representative-info.represented-by")}
                    className="client-card-block__label mt-15"
                    text={
                        client[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE] ||
                        t("is-not-specified.content")
                    }
                />
                <LabelTextApp
                    label={t("client-representative-info.based-on")}
                    className="client-card-block__label"
                    text={
                        client[CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS] ||
                        t("is-not-specified.content")
                    }
                />
            </>
        );
    }

    renderRepresentativeEdit(client) {
        const {t} = this.props;
        const {errorForm} = this.state;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            required
                            label={t("client-representative-info.represented-by")}
                            placeholder={t("client-representative-info.represented-by")}
                            name={CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE}
                            value={client[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmTextareaV2
                            size="lg"
                            required
                            label={t("client-representative-info.based-on")}
                            placeholder={t("client-representative-info.based-on")}
                            name={CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS}
                            value={client[CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS] || ""}
                            onChange={this.handleChange}
                            error={errorForm[CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS]}
                        />
                    </div>
                </div>
            </NmForm>
        );
    }

    render() {
        const {isEdit, editRepresentativeInfo} = this.state;
        const {isEditable, t} = this.props;

        return (
            <CardApp
                title={t("client-representative-info.client-representative-title")}
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                isEdit={isEdit}
                onClickCancelIcon={this.cancelEdit}
            >
                {!isEdit
                    ? this.renderRepresentativeReadOnly(editRepresentativeInfo)
                    : this.renderRepresentativeEdit(editRepresentativeInfo)
                }
            </CardApp>
        );
    }
}

export default connect(state => ({
    client: clientCardInfoSelector(state),
    representativeInfo: getRepresentativeInfoSelector(state),
}), {
    updateClientCardInfo,
})(withTranslation()(RepresentativeCardBlock));
