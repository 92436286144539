import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";

import AddClientsForm from "../components/add-clients-form";

import {
    addSettingsClientGroupsCardClients,
    deleteSettingsClientGroupsCardClients,
} from "../../../../../../ducks/bff/settings/client-groups/card/clients/actionCreators";

const INITIAL_STATE_MODAL_WINDOW = {
    isShow: false,
    title: "",
    formId: undefined,
    content: "",
    submit: undefined,
};

const INITIAL_STATE_CONFIRM_WINDOW = {
    confirmButton: "Подтвердить",
    cancelButton: "Отменить",
    isOnlyConfirm: true,
    onConfirm: undefined,
    onCancel: undefined,
    content: "",
    isShow: false,
};

export function useClientsActions(props) {
    const {
        groupId,
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const [dataModal, setDataModal] = useState({
        ...INITIAL_STATE_MODAL_WINDOW,
    });

    const [dataConfirm, setDataConfirm] = useState(INITIAL_STATE_CONFIRM_WINDOW);

    const onCloseModal = () => {
        setDataModal(INITIAL_STATE_MODAL_WINDOW);
    };

    const onCloseConfirm = () => {
        setDataConfirm(INITIAL_STATE_CONFIRM_WINDOW);
    };

    const onSubmitAddClient = (data) => {
        dispatch(addSettingsClientGroupsCardClients({
            ...data,
            groupId,
            onSuccess: () => {
                onCloseModal();
                fetchList();
            },
        }));
    };

    const onClickAddClient = () => {
        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            title: "Добавление заказчика в группу компаний",
            formId: "AddClientForm",
            content: <AddClientsForm
                submit={onSubmitAddClient}
            />,
        }));
    };

    const onClickDeleteClient = useCallback((item, groupName) => {
        const {
            clientName,
            clientId,
            groupId,
        } = item;
        setDataConfirm(prevState => ({
            ...prevState,
            isShow: true,
            content: `Удалить заказчика "${clientName}" из группы "${groupName}"?`,
            onConfirm: () => dispatch(deleteSettingsClientGroupsCardClients({
                clientId,
                groupId,
                onSuccess: () => {
                    onCloseModal();
                    fetchList();
                    onCloseConfirm();
                },
            })),
            onCancel: onCloseConfirm,
        }));
    }, []);

    return {
        dataModal,
        dataConfirm,
        onCloseModal,
        onClickAddClient,
        onClickDeleteClient,
    };
}