import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Tabs from "../../../../../../components/ActualComponents/Tabs";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmPage from "../../../../../../components/NmPage";
import NmTitle from "../../../../../../components/NmTitle";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useKedoDocumentRouteLinkedTypesModalFetch} from "./hooks/useFetch";

import bem from "../../../../../../utils/bem";

import {COLOR} from "../../../../../../constants/color";
import {TAB} from "./constants";

import {updateCommonPaymentDicts} from "../../../../../../ducks/bff/common/dicts/actionCreators";
import {
    kedoDirectoriesTemplateDocumentTypesSelector,
    kedoDirectoriesUploadedDocumentTypesSelector,
} from "../../../../../../ducks/bff/common/dicts/selectors";

import "./style.sass";

export const KedoDocumentRouteLinkedTypesModal = (props) => {
    const {
        onClose,
        routeId,
        title = "Привязанные типы документов",
        subTitle,
        description,
        isShowCancelButton,
        isShowTabs,
    } = props;

    const uploadedDocumentTypes = useSelector(kedoDirectoriesUploadedDocumentTypesSelector);
    const templateDocumentTypes = useSelector(kedoDirectoriesTemplateDocumentTypesSelector);

    const dispatch = useDispatch();

    const [tab, setTab] = useState(TAB.UPLOADED_DOCUMENTS);

    const [block, element] = bem("kedo-document-route-linked-types-modal");

    const {
        list,
        totalCount,
        totalPages,
        progress,
    } = useMemo(() => {
        if (tab === TAB.UPLOADED_DOCUMENTS) {
            return uploadedDocumentTypes;
        }

        return templateDocumentTypes;
    }, [
        tab,
        uploadedDocumentTypes,
        templateDocumentTypes,
    ]);

    const {
        pageNum,
        pageSize,
        setPagination,
        onPaginationChange,
        onChangePageSize,
    } = usePagination("bg-modal");

    useKedoDocumentRouteLinkedTypesModalFetch({
        routeId,
        tab,
        pageSize,
        pageNum,
    });

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                uploadedDocumentTypes: {},
                templateDocumentTypes: {},
            }));
        };
    }, []);

    useEffect(() => {
        setPagination({
            pageSize, 
            pageNum: 1,
        });
    }, [tab]);

    const tabs = useMemo(() => {
        return [
            {
                name: "Загруженные типы документов",
                id: TAB.UPLOADED_DOCUMENTS,
                active: tab === TAB.UPLOADED_DOCUMENTS,
                onClick: () => setTab(TAB.UPLOADED_DOCUMENTS),
            },
            {
                name: "Шаблонные типы документов",
                id: TAB.TEMPLATE_DOCUMENTS,
                active: tab === TAB.TEMPLATE_DOCUMENTS,
                onClick: () => setTab(TAB.TEMPLATE_DOCUMENTS),
            },
        ];
    }, [tab]);

    return (
        <NmModal
            className={block()}
            size="md"
            contentAlign="left"
            classNameContent="flex-column"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    cancelBtnContent={isShowCancelButton && "Отменить"}
                    onClose={onClose}
                    submit={onClose}
                    submitBtnContent="Понятно"
                />
            }
        >
            {
                subTitle &&
                <Text.Title
                    color={COLOR.SECONDARY_100}
                    level="4"
                    className="mb-6"
                >
                    {subTitle}
                </Text.Title>
            }
            {
                description &&
                <Text
                    color={COLOR.SECONDARY_90}
                    level="4"
                    className="mb-6"
                >
                    {description}
                </Text>
            }
            {
                isShowTabs &&
                <Tabs
                    secondary={true}
                    panes={tabs}
                    className="mb-6"
                />
            }
            <NmPage
                overflowUnset={true}
                showHeaderBlock={false}
                noPadding={true}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                isLoaded={progress}
                classNameContent={element("list")}
            >
                {
                    list.map(item => {
                        return (
                            <Text
                                color={COLOR.SECONDARY_70}
                                level="3"
                                className="mb-4"
                            >
                                {item.name}
                            </Text>
                        );
                    })
                }
            </NmPage>
        </NmModal>
    );
};