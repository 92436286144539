import {
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST,
    BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR,
    BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS,
    BFF_FOREMEN_REQUESTS_CLEAR_STORE,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    progressList: false,
    progressUpdate: false,
    totalCount: 0,
    newRequestsAndPaymentsCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_REQUEST:
            return {
                ...state,
                progressList: true,
                pageData: payload,
            };
        case BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_SUCCESS:
            const {
                foremanRequests,
                totalCount,
            } = payload;

            return {
                ...state,
                list: foremanRequests,
                progressList: false,
                totalCount,
            };
        case BFF_CLIENT_FOREMEN_REQUESTS_RICH_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_REQUEST:
            return {
                ...state,
                progressUpdate: true,
                progressList: true,
            };
        case BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_SUCCESS:
            return {
                ...state,
                progressUpdate: false,
            };
        case BFF_CLIENT_FOREMEN_REQUESTS_UPDATE_ERROR:
            return {
                ...state,
                progressUpdate: false,
                progressList: false,
            };
        case BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS:
            return {
                ...state,
                newRequestsAndPaymentsCount: payload,
            };
        case BFF_FOREMEN_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case BFF_FOREMEN_REQUESTS_CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};