import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useKedoStaffDropdown} from "./hooks/useKedoStaff";

export const KedoStaffDropdown = (props) => {
    const {
        clientId,
        excludeRootDepartmentHead,
        sortType,
        ...otherProps
    } = props;

    const defaultComponentProps = useKedoStaffDropdown({
        clientId,
        excludeRootDepartmentHead,
        sortType,
    });

    return (
        <NmDropdownV2
            {...defaultComponentProps}
            {...otherProps}
        />
    );
};