import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const ordersOrderGroupSelector = state => state.bff.orderGroups;

export const getOrdersOrderGroupsListSelector = createSelector(
    ordersOrderGroupSelector,
    ({list}) => list,
);
export const getOrdersOrderGroupsTotalCountSelector = createSelector(
    ordersOrderGroupSelector,
    ({totalCount}) => totalCount,
);
export const getOrdersOrderGroupsTotalPagesSelector = createSelector(
    ordersOrderGroupSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const getOrdersOrderGroupsProgressSelector = createSelector(
    ordersOrderGroupSelector,
    ({progress}) => progress,
);
export const getOrdersOrderGroupsActionProgressSelector = createSelector(
    ordersOrderGroupSelector,
    ({actionProgress}) => actionProgress,
);
export const getOrdersOrderGroupCardSelector = createSelector(
    ordersOrderGroupSelector,
    ({card}) => card,
);