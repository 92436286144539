import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {FINANCE_EXPORT_MESSAGES} from "../constants/messages";

const controller = "/documents/edo/registries";

const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST";
const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS";
const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR";

const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST";
const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS";
const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR";

const ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST = "ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const ADD_DOCUMENTS_EDO_REGISTRIES_ERROR = "ADD_DOCUMENTS_EDO_REGISTRIES_ERROR";

const UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST = "UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR";

const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR";

const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR";

const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR";

const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR";

const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR";

const SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST = "SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR = "SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR";

const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST";
const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS";
const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR";

const EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST = "EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST";
const EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
const EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR = "EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR";

const CHECK_DUPLICATES_REGISTRY_REQUEST = "CHECK_DUPLICATES_REGISTRY_REQUEST";
const CHECK_DUPLICATES_REGISTRY_SUCCESS = "CHECK_DUPLICATES_REGISTRY_SUCCESS";
const CHECK_DUPLICATES_REGISTRY_ERROR = "CHECK_DUPLICATES_REGISTRY_ERROR";

const CONFIRM_DUPLICATES_REGISTRY_REQUEST = "CONFIRM_DUPLICATES_REGISTRY_REQUEST";
const CONFIRM_DUPLICATES_REGISTRY_SUCCESS = "CONFIRM_DUPLICATES_REGISTRY_SUCCESS";
const CONFIRM_DUPLICATES_REGISTRY_ERROR = "CONFIRM_DUPLICATES_REGISTRY_ERROR";

const DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE = "DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE";

const initialState = {
    list: [],
    card: {},
    totalCount: 0,
    pageData: {},
    progressList: false,
    progressAction: false,
    progressGetFile: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
                progressList: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST:
            return {
                ...state,
                progressGetFile: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                list: payload.results,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS:
            return {
                ...state,
                progressGetFile: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR:
            return {
                ...state,
                progressGetFile: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export const getDocumentsEdoRegistriesPage = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST,
    payload,
});

export const getByIdDocumentsEdoRegistries = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST,
    payload,
});

export const addDocumentsEdoRegistries = (payload) => ({
    type: ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistries = (payload) => ({
    type: UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});


export const archiveDocumentsEdoRegistries = (payload) => ({
    type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesStore = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE,
    payload,
});

export const checkContractorsExistsDocumentsEdoRegistries = (payload) => ({
    type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const sendSmsDocumentsEdoRegistries = (payload) => ({
    type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const signDocumentsEdoRegistries = (payload) => ({
    type: SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const getDocumentsEdoRegistriesAttachmentsFile = (payload) => ({
    type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST,
    payload,
});

export const exportDocumentsEdoRegistries = (payload) => ({
    type: EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const checkDuplicatesRegistry = (payload) => ({
    type: CHECK_DUPLICATES_REGISTRY_REQUEST,
    payload,
});

export const confirmDuplicatesRegistry = (payload) => ({
    type: CONFIRM_DUPLICATES_REGISTRY_REQUEST,
    payload,
});

export const checkDuplicatesDocumentsEdoRegistries = (payload) => ({
    type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const confirmDuplicatesDocumentsEdoRegistries = (payload) => ({
    type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});



export const documentsEdoRegistriesSelector = state => state.documentsEdoRegistries;
export const documentsEdoRegistriesListSelector = createSelector(documentsEdoRegistriesSelector, ({list}) => list);
export const documentsEdoRegistriesTotalPagesSelector = createSelector(documentsEdoRegistriesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const documentsEdoRegistriesTotalCountSelector = createSelector(documentsEdoRegistriesSelector, ({totalCount}) => totalCount);
export const documentsEdoRegistriesProgressListSelector = createSelector(documentsEdoRegistriesSelector, ({progressList}) => progressList);
export const documentsEdoRegistriesCardSelector = createSelector(documentsEdoRegistriesSelector, ({card}) => card);
export const documentsEdoRegistriesProgressActionSelector = createSelector(documentsEdoRegistriesSelector, ({progressAction}) => progressAction);
export const documentsEdoRegistriesGetFileProgressActionSelector = createSelector(documentsEdoRegistriesSelector, ({progressGetFile}) => progressGetFile);

//POST /api/documents/edo/registries/richPage
export const getDocumentsEdoRegistriesPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/richPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR, payload: error.message});
    }
};

//GET /api/documents/edo/registries/{clientId}/{registryId}/rich
export const getByIdDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.get(`${controller}/${clientId}/${registryId}/rich`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/add
export const addDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/add`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: ADD_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/update
export const updateDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/update`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//PATCH /api/documents/edo/registries/archive
export const archiveDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.patch(`${controller}/archive`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//GET /api/documents/edo/registries/{clientId}/{registryId}/check/contractorsExists
export const checkContractorsExistsDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/${clientId}/${registryId}/check/contractorsExists`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/sendSms
export const sendSmsDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/sendSms`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/sign
export const signDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/sign`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

export const getDocumentsEdoRegistriesAttachmentsFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            fileName,
            getResult = () => {
            },
        } = payload;

        const url = `${new URL(window.location.href).origin}/api/filestore/edocuments/file?clientId=${clientId}&registryId=${registryId}`;
        const response = yield request.getFile(url, {params: {clientId, registryId}});

        const blob = yield response.blob();

        const documentFile = new File([blob], fileName, {type: blob.type});

        getResult(documentFile);

        yield put({type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS});
    } catch (error) {
        toastError("Не удалось получить файл");
        yield put({type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR, payload: error});
    }
};

//POST /api/documents/edo/registries/export
export const exportDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/export`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_EDM_REGISTRY_SUCCESS);
        onSuccess();
        yield put({
            type: EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//проверяем дубли в рамках строк реестра
//GET /api/documents/edo/registries/{clientId}/{registryId}/check/duplicates/registry
export const checkDuplicatesRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/${clientId}/${registryId}/check/duplicates/registry`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_DUPLICATES_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_DUPLICATES_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CHECK_DUPLICATES_REGISTRY_ERROR, payload: error.message});
    }
};

//Подтверждение проверки на дубликаты в рамках реестра
//POST /api/documents/edo/registries/check/duplicates/registry/confirm
export const confirmDuplicatesRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/check/duplicates/registry/confirm`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONFIRM_DUPLICATES_REGISTRY_SUCCESS, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: CONFIRM_DUPLICATES_REGISTRY_ERROR,
            payload: result,
        });
    } catch (error) {
        yield put({type: CONFIRM_DUPLICATES_REGISTRY_ERROR, payload: error.message});
    }
};

//Проверка на дубликаты перед подписанием реестра
//GET /api/documents/edo/registries/{clientId}/{registryId}/check/duplicates
export const checkDuplicatesDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/${clientId}/${registryId}/check/duplicates`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

//POST /api/documents/edo/registries/check/duplicates/confirm
export const confirmDuplicatesDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/check/duplicates/confirm`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST, getDocumentsEdoRegistriesPageSaga),
        takeEvery(DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST, getByIdDocumentsEdoRegistriesSaga),
        takeEvery(ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST, addDocumentsEdoRegistriesSaga),
        takeEvery(UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST, updateDocumentsEdoRegistriesSaga),
        takeEvery(ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST, archiveDocumentsEdoRegistriesSaga),
        takeEvery(CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST, checkContractorsExistsDocumentsEdoRegistriesSaga),
        takeEvery(SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST, sendSmsDocumentsEdoRegistriesSaga),
        takeEvery(SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST, signDocumentsEdoRegistriesSaga),
        takeEvery(GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST, getDocumentsEdoRegistriesAttachmentsFileSaga),
        takeEvery(EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST, exportDocumentsEdoRegistriesSaga),
        takeEvery(CHECK_DUPLICATES_REGISTRY_REQUEST, checkDuplicatesRegistrySaga),
        takeEvery(CONFIRM_DUPLICATES_REGISTRY_REQUEST, confirmDuplicatesRegistrySaga),
        takeEvery(CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST, checkDuplicatesDocumentsEdoRegistriesSaga),
        takeEvery(CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST, confirmDuplicatesDocumentsEdoRegistriesSaga),
    ]);
}