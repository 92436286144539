import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Redirect, Route} from "react-router";
import {isEmpty, isEqual} from "lodash";

import Layouts from "../../containers/layouts/layouts";
import {store} from "../../index";
import {CheckRoleRoute} from "../CheckRoleRoute";
import {Dimmer, Loader} from "semantic-ui-react";

import {
    getAccessDataByRole,
    isNMUsers,
} from "../../utils/access";
import {addByFirebaseTokenAndClientUserId} from "../../utils/firebaseHelper";
import {
    ACCESS_TOKEN_KEY,
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
    REFRESH_TOKEN_KEY,
    setPrevPathToLs,
    USER_LOGIN,
    USER_ROLE,
} from "../../utils/localstorage";

import {
    LINK_FORBIDDEN_PAGE,
    LINK_LOGIN,
} from "../../constants/links";
import {
    ADMIN,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    PROJECT_MANAGER,     RNKO,
} from "../../constants/roles";

import {catalogsDispatchList} from "../../store/dispatchCatalogs";

import {
    logoutRequest,
    updateFieldAuthorizationStore,
    userInfoSelector,
} from "../../ducks/auth";
import {
    getClientCardInfo,
    getClientCardProperties,
    getCurrentClientCardInfo,
} from "../../ducks/bff/clients/info/actionCreators";
import {
    clientCardPropertiesSelector,
    getCurrentClientNameSelector,
    getMigrantLicensePaymentProperties,
} from "../../ducks/bff/clients/info/selectors";
import {
    clientCurrentMemberFullNameSelector,
    clientCurrentMemberSelector,
    getClientCurrentMemberByLogin,
    getProgressCurrentUser,
} from "../../ducks/clientMember";
import {currentUserRestrictionsSelector} from "../../ducks/clientUserRestrictions";
import {
    clientUserStaffExistSelector,
    edmStaffUserExist,
} from "../../ducks/edmStaff";
import {
    edoAccessListSelector,
    getEdoAccessList,
    updateEdoDocumentsStore,
} from "../../ducks/edocuments";
import {getDadataKey} from "../../ducks/strapi";

class ProtectedRoute extends Component {
    constructor(props) {
        super(props);

        this.role = ls(USER_ROLE);
        this.isAuth = ls(ACCESS_TOKEN_KEY) && ls(REFRESH_TOKEN_KEY);
        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
        const clientId = ls(CURRENT_CLIENT_ID);

        this.state = {
            loadingEdoAccess: ![ADMIN,
                NM_MANAGER,
                NM_COORDINATOR,
                NM_CONSULTANT,
                RNKO,
                NM_PARTNER,
            ].includes(this.role),
            accessData: {},
            clientId,
        };
    }

    componentDidMount() {
        const {
            clientName,
            getCurrentClientCardInfo,
            getClientCurrentMemberByLogin,
            currentUserName,
            userInfo: {
                login,
            },
            getClientCardProperties,
            edoAccessList,
            clientUserStaffExist,
            path,
            migrantLicensePaymentEnabled,
            getClientCardInfo,
            edmStaffUserExist,
            currentUserRestrictions,
        } = this.props;
        const isAuth = ls(ACCESS_TOKEN_KEY) && ls(REFRESH_TOKEN_KEY);
        const clientId = ls(CURRENT_CLIENT_ID);

        if (isAuth && !clientName && this.role === FOREMAN) {
            getCurrentClientCardInfo({clientId});
        }

        if (!isNMUsers()) {
            getClientCardInfo({clientId});
        }

        if (![ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CONSULTANT,
            RNKO,
            NM_PARTNER,
            NM_OPERATOR,
        ].includes(this.role)) {
            getClientCardProperties({clientId});
        }

        const accessData = getAccessDataByRole({
            path,
            edoAccessList,
            clientUserStaffExist,
            migrantLicensePaymentEnabled,
            currentUserRestrictions,
        });

        this.setState({accessData});

        this.fetchEdoAccessList();

        if ([CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role)) {
            edmStaffUserExist({
                clientId,
                clientUserId: this.currentClientUserId,
            });
        }

        if (isAuth && !currentUserName) {
            getClientCurrentMemberByLogin(login || ls(USER_LOGIN));
        }

        if (isAuth) {
            const {getDadataKey} = this.props;

            getDadataKey();

            catalogsDispatchList.forEach(func => {
                store.dispatch(func());
            });
            //addBitrixScript();
            addByFirebaseTokenAndClientUserId()
                .catch((error) => console.log("addByFirebaseTokenAndClientUserId ERROR", error));
        }
    }

    componentWillUnmount() {
        const {updateEdoDocumentsStore} = this.props;

        //removeBitrixScript();
        updateEdoDocumentsStore({accessList: []});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            currentUserName,
            userInfo: {
                login,
            },
            path,
            getClientCurrentMemberByLogin,
            edoAccessList,
            migrantLicensePaymentEnabled,
            clientUserStaffExist,
            clientProperties,
            currentMember,
            currentUserRestrictions,
        } = this.props;
        const {
            userInfo: {
                login: oldLogin,
            },
        } = prevProps;
        const clientId = ls(CURRENT_CLIENT_ID);

        if (path !== prevProps.path) {
            setPrevPathToLs(prevProps.path);
        }

        if (this.isAuth && !currentUserName && login && login === oldLogin) {
            this.setState({loadingEdoAccess: true});

            this.fetchEdoAccessList();

            getClientCurrentMemberByLogin(login);
        }

        if (
            (
                !isEmpty(edoAccessList)
                && !isEqual(edoAccessList, prevProps.edoAccessList)
            )
            || (
                !isEmpty(clientProperties)
                && !isEqual(clientProperties, prevProps.clientProperties)
            )
            || !isEqual(clientUserStaffExist, prevProps.clientUserStaffExist)
            || !isEqual(currentMember, prevProps.currentMember)
            || !isEqual(currentUserRestrictions, prevProps.currentUserRestrictions)
            || clientId !== this.state.clientId
        ) {
            const accessData = getAccessDataByRole({
                path,
                edoAccessList,
                clientUserStaffExist,
                migrantLicensePaymentEnabled,
                clientProperties,
                currentMember,
                currentUserRestrictions,
            });

            this.setState({
                accessData,
                clientId,
            });
        }
    }

    fetchEdoAccessList = () => {
        const {
            getEdoAccessList,
        } = this.props;

        getEdoAccessList({
            getResult: () => {
                this.setState({loadingEdoAccess: false});
            },
            onError: () => {
                this.setState({loadingEdoAccess: false});
            },
        });
    };

    render() {
        const {
            path,
            progressCurrentUser,
            clientName,
            component: Component,
            t,
            userInfo,
            edoAccessList,
            migrantLicensePaymentEnabled,
            pathname,
            updateFieldAuthorizationStore,
            ...rest
        } = this.props;

        const isAuth = ls(ACCESS_TOKEN_KEY) && ls(REFRESH_TOKEN_KEY);

        if (!isAuth) {
            updateFieldAuthorizationStore("prevPathObj", {
                pathname,
                path,
            });

            return <Redirect to={LINK_LOGIN} />;
        }

        const {loadingEdoAccess} = this.state;

        if (progressCurrentUser || loadingEdoAccess) {
            return (
                <Dimmer active>
                    <Loader content={t("loader.content")} />
                </Dimmer>
            );
        }

        const {accessData} = this.state;
        const {menuList} = accessData;

        if (!accessData.isAccess) {
            return <Redirect to={LINK_FORBIDDEN_PAGE} />;
        }

        return (
            <Layouts
                role={this.role}
                path={path}
                menuList={menuList}
            >
                <CheckRoleRoute
                    {...rest}
                    path={path}
                    render={props => <Component {...props} />}
                />
            </Layouts>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        auth: state.auth,
        clientName: getCurrentClientNameSelector(state),
        currentUserName: clientCurrentMemberFullNameSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        progressCurrentUser: getProgressCurrentUser(state),
        userInfo: userInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        edoAccessList: edoAccessListSelector(state),
        migrantLicensePaymentEnabled: getMigrantLicensePaymentProperties(state),
        clientUserStaffExist: clientUserStaffExistSelector(state),
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        logoutRequest,
        getCurrentClientCardInfo,
        getClientCurrentMemberByLogin,
        addByFirebaseTokenAndClientUserId,
        getClientCardProperties,
        getEdoAccessList,
        updateEdoDocumentsStore,
        updateFieldAuthorizationStore,
        getClientCardInfo,
        getDadataKey,
        edmStaffUserExist,
    },
)(withTranslation()(ProtectedRoute));
