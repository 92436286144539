import {
    KEDO_DOCUMENTS_DELETE_DRAFT_ERROR,
    KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST,
    KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS,
    KEDO_DOCUMENTS_EXPORT_ERROR,
    KEDO_DOCUMENTS_EXPORT_REQUEST,
    KEDO_DOCUMENTS_EXPORT_SUCCESS,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS,
    KEDO_DOCUMENTS_GET_PAGE_ERROR,
    KEDO_DOCUMENTS_GET_PAGE_REQUEST,
    KEDO_DOCUMENTS_GET_PAGE_SUCCESS,
    KEDO_DOCUMENTS_UPDATE_STORE,
} from "./actions";

export const getKedoDocumentsInitialState = () => {
    return {
        history: {
            progress: false,
            list: [],
            pageData: {},
            totalCount: 0,
        },
        list: [],
        pageData: {},
        totalCount: 0,
        card: {},
        progress: false,
        progressAction: false,
    };
};

const initialState = getKedoDocumentsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case KEDO_DOCUMENTS_DELETE_DRAFT_ERROR:
        case KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS:
        case KEDO_DOCUMENTS_EXPORT_ERROR:
        case KEDO_DOCUMENTS_EXPORT_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST:
        case KEDO_DOCUMENTS_EXPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR:
            return {
                ...state,
                history: {
                    ...state.history,
                    progress: false,
                },
            };
        case KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST:
            return {
                ...state,
                history: {
                    ...state.history,
                    pageData: payload,
                    progress: true,
                },
            };
        case KEDO_DOCUMENTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case KEDO_DOCUMENTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case KEDO_DOCUMENTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case KEDO_DOCUMENTS_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default: return state;
    }
};