import axios from "axios";
import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect/lib/index";

import {call} from "../../node_modules/redux-saga/effects";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/filestore";
//*  TYPES  *//

const CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST = "CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST";
const CONTRACTOR_GET_OTHER_FILE_LIST_SUCCESS = "CONTRACTOR_GET_OTHER_FILE_LIST_SUCCESS";
const CONTRACTOR_GET_OTHER_FILE_LIST_ERROR = "CONTRACTOR_GET_OTHER_FILE_LIST_ERROR";

const CONTRACTOR_ADD_OTHER_FILE_REQUEST = "CONTRACTOR_ADD_OTHER_FILE_REQUEST";
const CONTRACTOR_ADD_OTHER_FILE_SUCCESS = "CONTRACTOR_ADD_OTHER_FILE_SUCCESS";
const CONTRACTOR_ADD_OTHER_FILE_ERROR = "CONTRACTOR_ADD_OTHER_FILE_ERROR";

const CONTRACTOR_GET_OTHER_FILE_REQUEST = "CONTRACTOR_GET_OTHER_FILE_REQUEST";
const CONTRACTOR_GET_OTHER_FILE_SUCCESS = "CONTRACTOR_GET_OTHER_FILE_SUCCESS";
const CONTRACTOR_GET_OTHER_FILE_ERROR = "CONTRACTOR_GET_OTHER_FILE_ERROR";

const CONTRACTOR_DELETE_OTHER_FILE_REQUEST = "CONTRACTOR_DELETE_OTHER_FILE_REQUEST";
const CONTRACTOR_DELETE_OTHER_FILE_SUCCESS = "CONTRACTOR_DELETE_OTHER_FILE_SUCCESS";
const CONTRACTOR_DELETE_OTHER_FILE_ERROR = "CONTRACTOR_DELETE_OTHER_FILE_ERROR";

const ADD_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST = "ADD_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST";
const ADD_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS = "ADD_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS";
const ADD_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR = "ADD_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR";

const DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST = "DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST";
const DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS = "DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS";
const DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR = "DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR";

const GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_REQUEST = "GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_REQUEST";
const GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_SUCCESS = "GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_SUCCESS";
const GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_ERROR = "GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_ERROR";


//*  INITIAL STATE  *//

const initial = {
    error: null,
    progress: false,
    files: [],
    docMap: {},
};

const updateDocumentStore = (docMap, payload) => {
    const newDocMap = JSON.parse(JSON.stringify(docMap));
    newDocMap[payload.fileName] = payload.base64str;
    return newDocMap;
};

const updateDocumentStateStore = (docMap, fileName) => {
    const newDocMap = JSON.parse(JSON.stringify(docMap));
    newDocMap[fileName] = true;
    return newDocMap;
};

const updateFilesStore = (files, payload) => {
    const newFiles = JSON.parse(JSON.stringify(files));
    const index = files.indexOf(payload.fileName);
    if (index !== -1) {
        newFiles.splice(index, 1);
    }
    return newFiles;
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_GET_OTHER_FILE_REQUEST:
            return {
                ...state,
                docMap: updateDocumentStateStore(state.docMap, payload.contractorOtherName),
            };
        case CONTRACTOR_DELETE_OTHER_FILE_REQUEST:
            return {
                ...state,
                docMap: updateDocumentStateStore(state.docMap, payload.fileName),
            };
        case CONTRACTOR_ADD_OTHER_FILE_REQUEST:
        case CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CONTRACTOR_GET_OTHER_FILE_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                files: payload.documents,
            };
        case CONTRACTOR_GET_OTHER_FILE_SUCCESS:
            return {
                ...state,
                docMap: updateDocumentStore(state.docMap, payload),
            };
        case CONTRACTOR_ADD_OTHER_FILE_ERROR:
        case CONTRACTOR_GET_OTHER_FILE_ERROR:
        case CONTRACTOR_GET_OTHER_FILE_LIST_ERROR:
        case CONTRACTOR_DELETE_OTHER_FILE_SUCCESS:
            return {
                ...state,
                progress: false,
                docMap: updateDocumentStore(state.docMap, payload),
                files: updateFilesStore(state.files, payload),
            };
        case CONTRACTOR_DELETE_OTHER_FILE_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//


export function getContractorOtherFileList(payload) {
    return {
        type: CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST,
        payload,
    };
}

export function addOtherContractorFile(payload) {
    return {
        type: CONTRACTOR_ADD_OTHER_FILE_REQUEST,
        payload,
    };
}

export function getOtherContractorFile(payload) {
    return {
        type: CONTRACTOR_GET_OTHER_FILE_REQUEST,
        payload,
    };
}

export function deleteOtherContractorFile(payload) {
    return {
        type: CONTRACTOR_DELETE_OTHER_FILE_REQUEST,
        payload,
    };
}

export function addContractorVaccinationCertificate(payload) {
    return {
        type: ADD_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST,
        payload,
    };
}

export function deleteContractorVaccinationCertificate(payload) {
    return {
        type: DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST,
        payload,
    };
}

export function getContractorFilesVaccinationCertificateThumbnail(payload) {
    return {
        type: GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_REQUEST,
        payload,
    };
}

export const contractorOtherFilesSelector = state => state.contractorOtherFiles;

export const contractorOtherFilesProgressSelector = createSelector(contractorOtherFilesSelector, ({progress}) => progress);

export const contractorOtherFilesDocMapSelector = createSelector(contractorOtherFilesSelector, ({docMap}) => docMap);

export const contractorOtherFileListSelector = createSelector(contractorOtherFilesSelector, ({files}) => files);


//*  SAGA  *//
const deleteOtherContractorFileSaga = function* (action) {
    try {
        const {payload} = action;

        const {contractorId, fileName} = payload;

        const result = yield request.get(`${controller}/contractorOtherFiles/delete?contractorId=${contractorId}&fileName=${fileName}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_DELETE_OTHER_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_DELETE_OTHER_FILE_SUCCESS,
            payload: {fileName},
        });

        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST,
            payload: contractorId,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_DELETE_OTHER_FILE_ERROR,
            payload: error,
        });
    }
};

const getOtherContractorFileSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            contractorOtherName,
            thumbnail,
            isNeedDownload,
        } = payload;

        const result = yield request.get(`${controller}/contractorOtherFiles/getOtherFile?contractorId=${contractorId}&contractorOtherName=${contractorOtherName}&thumbnail=${thumbnail}`);

        const {errorMessage, base64str} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_GET_OTHER_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        result.fileName = contractorOtherName;

        if (isNeedDownload) {
            const tempLink = document.createElement("a");

            tempLink.setAttribute("href", "data:image/jpeg;base64," + base64str);
            tempLink.setAttribute("download", contractorOtherName + ".jpg");
            document.body.appendChild(tempLink);

            tempLink.click();
            tempLink.remove();
        }

        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_ERROR,
            payload: error,
        });
    }
};

const getContractorOtherFileListSaga = function* (action) {
    try {
        const {payload: contractorId} = action;

        const result = yield request.get(`${controller}/contractorOtherFiles/getOthersFileNameList?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_GET_OTHER_FILE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/filestore/contractorFiles/vaccinationCertificate/thumbnail/getBase64
const getContractorFilesVaccinationCertificateThumbnailSaga = function* (action) {
    try {
        const {
            payload: {
                contractorId,
                getResult,
            },
        } = action;

        const result = yield request.get(`${controller}/contractorFiles/vaccinationCertificate/thumbnail/getBase64?contractorId=${contractorId}`);

        const {errorMessage} = result;

        yield getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_ERROR,
            payload: error,
        });
    }
};

const addContractorVaccinationCertificateSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                formData,
            },
        } = action;

        const result = yield request.post(`${controller}/contractorFiles/vaccinationCertificate/add`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        yield getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_GET_OTHER_FILE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/contractorFiles/vaccinationCertificate/delete
const deleteContractorVaccinationCertificateSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.post(`${controller}/contractorFiles/vaccinationCertificate/delete?contractorId=${contractorId}`);

        const {errorMessage} = result;

        yield getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_GET_OTHER_FILE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
            payload: error,
        });
    }
};

const addOtherContractorFileSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            file: data,
        } = payload;

        yield call(axios, {
            url: `/api${controller}/contractorFiles/addOther`,
            method: "post",
            params: {
                contractorId,
            },
            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        },
        );

        yield put({
            type: CONTRACTOR_ADD_OTHER_FILE_SUCCESS,
            payload,
        });

        yield put({
            type: CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST,
            payload: contractorId,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADD_OTHER_FILE_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_GET_OTHER_FILE_LIST_REQUEST, getContractorOtherFileListSaga),
        takeEvery(CONTRACTOR_ADD_OTHER_FILE_REQUEST, addOtherContractorFileSaga),
        takeEvery(CONTRACTOR_GET_OTHER_FILE_REQUEST, getOtherContractorFileSaga),
        takeEvery(CONTRACTOR_DELETE_OTHER_FILE_REQUEST, deleteOtherContractorFileSaga),
        takeEvery(ADD_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST, addContractorVaccinationCertificateSaga),
        takeEvery(DELETE_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST, deleteContractorVaccinationCertificateSaga),
        takeEvery(GET_CONTRACTOR_VACCINATION_CERTIFICATE_THUMBNAIL_REQUEST, getContractorFilesVaccinationCertificateThumbnailSaga),
    ]);
}
