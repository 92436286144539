import {
    BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_ERROR,
    BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_SUCCESS,
    BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_NDFL_PAYMENTS_REJECT_ERROR,
    BFF_FINANCES_NDFL_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_NDFL_PAYMENTS_REJECT_SUCCESS,
    UPDATE_FINANCES_FINANCES_NDFL_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    data: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_SUCCESS: {
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                list: results,
                totalCount: totalCount,
                progress: false,
            };
        }
        case BFF_FINANCES_NDFL_PAYMENTS_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_REQUEST:
        case BFF_FINANCES_NDFL_PAYMENTS_REJECT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_SUCCESS:
        case BFF_FINANCES_NDFL_PAYMENTS_REJECT_SUCCESS:
        case BFF_FINANCES_NDFL_PAYMENTS_CONFIRM_ERROR:
        case BFF_FINANCES_NDFL_PAYMENTS_REJECT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_FINANCES_FINANCES_NDFL_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};