import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getDirectoriesCategorySpecialitiesPage} from "./actionCreators";
import {
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST,
    UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
} from "./actions";
import {settingsDirectoriesNaimixCategorySpecialitiesPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/category-specialities";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsDirectoriesNaimixCategorySpecialitiesPageDataSelector(state);

    yield put(getDirectoriesCategorySpecialitiesPage(pageData));
};

const getDirectoriesCategorySpecialitiesPageSaga = function* ({payload}) {
    try {
        const {
            nameField,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
            payload: {
                nameField,
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const getDirectoriesCategorySpecialitiesCardSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const addDirectoriesCategorySpecialitiesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield fetchList();

        yield put({
            type: ADD_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const updateDirectoriesCategorySpecialitiesSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.patch(`${controller}/${id}`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield fetchList();

        yield put({
            type: UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const deleteDirectoriesCategorySpecialitiesSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield fetchList();

        yield put({
            type: DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST, getDirectoriesCategorySpecialitiesPageSaga),
        takeEvery(GET_CARD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST, getDirectoriesCategorySpecialitiesCardSaga),
        takeEvery(UPDATE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST, updateDirectoriesCategorySpecialitiesSaga),
        takeEvery(DELETE_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST, deleteDirectoriesCategorySpecialitiesSaga),
        takeEvery(ADD_DIRECTORIES_CATEGORY_SPECIALITIES_REQUEST, addDirectoriesCategorySpecialitiesSaga),
    ]);
}