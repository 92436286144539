import {
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    listLoading: false,
    listTotalCount: 0,
    listPageData: {},
    addLoading: false,
    cardList: [],
    cardListLoading: false,
    cardListTotalCount: 0,
    cardListPageData: {},
    cardName: "",
    cardAddRecruitersLoading: false,
    cardAvailableRecruitersList: [],
    cardAvailableRecruitersListTotalCount: [],
    cardAvailableRecruitersListLoading: [],
    cardAvailableRecruitersListPageData: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST:
            return {
                ...state,
                listLoading: true,
                listPageData: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS:
            return {
                ...state,
                listLoading: false,
                list: payload.results,
                listTotalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR:
            return {
                ...state,
                listLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST:
            return {
                ...state,
                addLoading: true,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS:
            return {
                ...state,
                addLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR:
            return {
                ...state,
                addLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST:
            return {
                ...state,
                cardListLoading: true,
                cardListPageData: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS:
            return {
                ...state,
                cardListLoading: false,
                cardListTotalCount: payload.result.users.totalCount,
                cardList: payload.result.users.results,
                cardName: payload.result.name,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR:
            return {
                ...state,
                cardListLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST:
            return {
                ...state,
                cardAvailableRecruitersListLoading: true,
                cardAvailableRecruitersListPageData: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS:
            return {
                ...state,
                cardAvailableRecruitersListLoading: false,
                cardAvailableRecruitersList: payload.results,
                cardAvailableRecruitersListTotalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR:
            return {
                ...state,
                cardAvailableRecruitersListLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST:
            return {
                ...state,
                cardAddRecruitersLoading: true,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS:
            return {
                ...state,
                cardAddRecruitersLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR:
            return {
                ...state,
                cardAddRecruitersLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE:
            return {
                ...state,
                cardAvailableRecruitersListLoading: false,
                cardAvailableRecruitersList: [],
                cardAvailableRecruitersListTotalCount: 0,
                cardAvailableRecruitersListPageData: {},
                cardAddRecruitersLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE:
            return {
                ...state,
                cardList: [],
                cardListTotalCount: 0,
                cardListPageData: {},
                cardListLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE:
            return initial;
        default: return state;
    }
};