import React, {ReactNode, useState} from "react";

import {ReactComponent as MoreVertIcon} from "../../../images/more_vert_24.svg";
import NmButton, {TNmButtonColor, TNmButtonSize} from "../../NmButton";
import {TNmTooltipPosition} from "../NmTooltip";
import ContextMenuContent from "./Content";

import {useClickOutside} from "../../../hooks/useClickOutside";

import {OptionType} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";

import "./style.sass";

export interface IContextMenu {
    disabled?: boolean,
    className?: string,
    onClickItem?: (option: OptionType) => void;
    options?: Array<OptionType> | [],
    color?: TNmButtonColor,
    inline?: boolean,
    // для использования в старых списках в виде таблицы
    table?: boolean,
    size?: TNmButtonSize,
    transform?: boolean,
    horizontalIcon?: boolean,
    isModalSortSize?: boolean,
    clearWidth?: boolean,
    notBorder?: boolean,
    trigger?: ReactNode,
}

// Высота кнопки
const BUTTON_HEIGHT = 40;
// Высота пункта
const ITEM_HEIGHT = 40;
// Хвостик
const ICON_HEIGHT = 8;

const ContextMenu = (props: IContextMenu) => {
    const {
        disabled = false,
        className = "",
        onClickItem,
        options = [],
        color = "grey",
        inline = true,
        table = false,
        size = "lg",
        transform = true,
        horizontalIcon = false,
        notBorder = false,
        clearWidth,
        trigger,
    } = props;
    const [block, element] = bem("context-menu", className);
    const [position, setPosition] = useState<TNmTooltipPosition>("bottom-right");
    const [open, setOpen] = useState(false);
    const [innerOptions, setInnerOptions] = useState<Array<OptionType>>([]);

    const ref = useClickOutside<HTMLDivElement>(() => {
        setInnerOptions([]);
        setOpen(false);
    }, open);

    const dropdownClick = (event: any) => {
        if (disabled) {
            return;
        }

        const difference = document.documentElement.clientHeight - event.clientY - BUTTON_HEIGHT;

        if (
            options &&
            options.length !== 0
        ) {
            const dropdownHeight = options.length * ITEM_HEIGHT + ICON_HEIGHT;

            if (difference < dropdownHeight) {
                setPosition("top-right");
            }

            if (difference >= dropdownHeight && position === "top-right") {
                setPosition("bottom-right");
            }
        }

        setOpen(!open);
        setInnerOptions([]);
    };

    return (
        <div
            ref={ref}
            className={block({
                inline,
                table,
                disabled,
            })}
        >
            {
                trigger ?
                    <div
                        className={element("trigger")}
                        onClick={dropdownClick}
                    >
                        {trigger}
                    </div> :
                    <NmButton
                        clearWidth={clearWidth}
                        className={element("button", {"no-border": notBorder})}
                        disabled={disabled}
                        size={size}
                        color={color}
                        onClick={dropdownClick}
                        onlyIcon
                        icon={
                            <MoreVertIcon
                                className={element("icon", {transform, horizontal: horizontalIcon})}
                            />
                        }
                    />
            }
            {
                open &&
                <ContextMenuContent
                    inline={inline}
                    onClickItem={onClickItem}
                    className={element("context-menu-content", {position, clearWidth})}
                    onCloseContextMenu={() => setOpen(false)}
                    options={innerOptions && innerOptions.length ? innerOptions : options}
                    position={position}
                    setInnerOptions={setInnerOptions}
                    setOpen={setOpen}
                />
            }
        </div>
    );
};

export default React.memo(ContextMenu);
