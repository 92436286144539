import {
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    progress: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS: {
            return {
                ...state,
                list: payload,
            };
        }
        case BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE: {
            return initialState;
        }
        default: return state;
    }
};