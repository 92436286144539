export const GET_PAGE_DOCUMENTS_CORRECTION_REQUEST = "GET_PAGE_DOCUMENTS_CORRECTION_REQUEST";
export const GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS = "GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS";
export const GET_PAGE_DOCUMENTS_CORRECTION_ERROR = "GET_PAGE_DOCUMENTS_CORRECTION_ERROR";

export const PERFORM_DOCUMENTS_CORRECTION_REQUEST = "PERFORM_DOCUMENTS_CORRECTION_REQUEST";
export const PREFORM_DOCUMENTS_CORRECTION_SUCCESS = "PREFORM_DOCUMENTS_CORRECTION_SUCCESS";
export const PREFORM_DOCUMENTS_CORRECTION_ERROR = "PREFORM_DOCUMENTS_CORRECTION_ERROR";

export const PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST = "PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST";
export const PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS = "PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS";
export const PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR = "PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR";

export const GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST = "GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_REQUEST";
export const GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS = "GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS";
export const GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_ERROR = "GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_ERROR";

export const CLEAR_FIELDS_DOCUMENTS_CORRECTION = "CLEAR_FIELDS_DOCUMENTS_CORRECTION";