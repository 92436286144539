import React from "react";
import {Route, Switch} from "react-router";

import ClientsList from "./clients";
import ContractorList from "./contractors";
import GroupMembersList from "./members-list";

import {
    LINK_SETTINGS_CLIENT_GROUP_CARD,
} from "../../../../constants/links";

export function GroupCardRoutes(params) {
    const {
        groupId,
        isAccessEdit,
    } = params;
    
    const linkGroupCard = LINK_SETTINGS_CLIENT_GROUP_CARD.replace(":groupId", groupId);

    return (
        <Switch>
            <Route
                exact
                path={linkGroupCard.replace(":tab", "clients")}
                render={(props) => <ClientsList
                    {...props}
                    groupId={groupId}
                    isAccessEdit={isAccessEdit}
                />}
            />
            <Route
                exact
                path={linkGroupCard.replace(":tab", "contractors")}
                render={(props) => <ContractorList
                    {...props}
                    groupId={groupId}
                    isAccessEdit={isAccessEdit}
                />}
            />
            <Route
                exact
                path={linkGroupCard.replace(":tab", "members-list")}
                render={(props) => <GroupMembersList
                    {...props}
                    groupId={groupId}
                    isAccessEdit={isAccessEdit}
                />}
            />
        </Switch>
    );
}