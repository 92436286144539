import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {handleFormString} from "../../utils/stringHelper";

import {getSmartLinkPage, updateSmartLinkStore} from "../../ducks/smart-link/actionCreators";
import {smartLinkListOptionsSelector} from "../../ducks/smart-link/selectors";

const SmartLinksDropdown = (props) => {
    const {
        size = "xl",
        ...otherProps
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(smartLinkListOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateSmartLinkStore({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    const fetchList = (name) => {
        dispatch(getSmartLinkPage({
            pageNum: 1,
            pageSize: 25,
            name: handleFormString(name),
            archived: false,
        }));
    };

    const onSearchChange = (valueFilter) => {
        fetchList(valueFilter);
    };

    return (
        <NmDropdownV2
            {...otherProps}
            size={size}
            search
            options={options}
            onSearchChange={onSearchChange}
        />
    );
};

export default SmartLinksDropdown;