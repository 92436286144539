import {
    KEDO_DEPARTMENTS_ADD_REQUEST,
    KEDO_DEPARTMENTS_CLEAR_LOGS,
    KEDO_DEPARTMENTS_CLEAR_STORE,
    KEDO_DEPARTMENTS_DELETE_REQUEST,
    KEDO_DEPARTMENTS_GET_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_PAGE_REQUEST,
    KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST,
    KEDO_DEPARTMENTS_GET_ROOT_REQUEST,
    KEDO_DEPARTMENTS_IMPORT_REQUEST,
    KEDO_DEPARTMENTS_LOGS_REQUEST,
    KEDO_DEPARTMENTS_UPDATE_REQUEST,
    KEDO_DEPARTMENTS_UPDATE_STORE,
} from "./actions";

export const getKedoDepartments = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getKedoDepartmentCard = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_GET_CARD_REQUEST,
        payload,
    };
};

export const deleteKedoDepartment = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_DELETE_REQUEST,
        payload,
    };
};

export const getRootKedoDepartment = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_GET_ROOT_REQUEST,
        payload,
    };
};

export const importKedoDepartments = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_IMPORT_REQUEST,
        payload,
    };
};


export const addKedoDepartment = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_ADD_REQUEST,
        payload,
    };
};

export const updateKedoDepartment = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_UPDATE_REQUEST,
        payload,
    };
};

export const getRichKedoDepartmentCard = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_GET_PRE_UPDATE_CARD_REQUEST,
        payload,
    };
};

export const updateKedoDepartmentsStore = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_UPDATE_STORE,
        payload,
    };
};

export const getKedoDepartmentsLogs = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_LOGS_REQUEST,
        payload,
    };
};

export const clearKedoDepartmentsStore = (payload) => {
    return {
        type: KEDO_DEPARTMENTS_CLEAR_STORE,
        payload,
    };
};

export const clearKedoDepartmentsLogs = () => {
    return {
        type: KEDO_DEPARTMENTS_CLEAR_LOGS,
    };
};