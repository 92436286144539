import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmListCardTitle from "../../../../../components/ActualComponents/NmList/CardTitle";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../components/ActualComponents/NmRadioV2";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as InfoIcon} from "../../../../../images/info_24.svg";
import {Divider} from "semantic-ui-react";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {COLOR} from "../../../../../constants/color";

import {
    bffCrowdActRegistryEntryProgressActionSelector,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/selectors";

const CrowdTaskActRegistryDuplicatesEntryModal = (props) => {
    const {
        onClose = () => {
        },
        onSubmit = () => {
        },
        list = [],
    } = props;

    const progressAction = useSelector(bffCrowdActRegistryEntryProgressActionSelector);

    const initialValues = useMemo(() => {
        if (!list?.length) {
            return {};
        }

        return list.reduce((result, item) => {
            return {
                ...result,
                [item.taskId]: item.duplicateEntries[0]?.entryId || null,
            };
        }, {});
    }, [list]);

    const [selectedRows, setSelectedRows] = useState(initialValues);

    const handleChange = (taskId, entryId) => {
        setSelectedRows(prevState => ({
            ...prevState,
            [taskId]: entryId,
        }));
    };

    const _onSubmit = () => {
        const selectedEntryIds = Object.values(selectedRows);
        const entryIds = list
            .map(item => item.duplicateEntries.map(item => item.entryId))
            .flat()
            .filter(item => !selectedEntryIds.includes(item));

        onSubmit(entryIds);
    };

    return (
        <NmModal
            isAutoSize={true}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Обнаружены совпадения
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    submitBtnContent="Сохранить и продолжить запуск"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={_onSubmit}
                />
            }
        >
            <div className="col-16">
                <div className="flex align-items-start">
                    <InfoIcon
                        className="me-2"
                        color={COLOR.NEGATIVE_100}
                        width={24}
                        height={24}
                    />
                    <Text>
                        В реестре найдены строки с одинаковым заданием. Сохраните одну для каждого задания, чтобы продолжить
                        запуск реестра. Лишние строки будут удалены.
                    </Text>
                </div>
                {
                    list.length ?
                        <div className="col-16">
                            {
                                list.map((item, index) => {
                                    const {
                                        taskId,
                                        taskNumber,
                                        taskName,
                                        duplicateEntries = [],
                                    } = item;

                                    const selectedEntry = duplicateEntries.find(item => item.entryId === selectedRows[taskId]) || {};

                                    return (
                                        <div className="mt-4 mb-4">
                                            {
                                                index !== 0 &&
                                                <Divider className="mt-4 mb-4" />
                                            }
                                            <NmListCardTitle>
                                                Задание №
                                                {taskNumber}
                                                {" "}
- 
                                                {" "}
                                                {taskName}
                                            </NmListCardTitle>
                                            <NmForm
                                                addMargin={true}
                                                className="mt-2 mb-2"
                                            >
                                                {
                                                    duplicateEntries.map(item => {
                                                        return (
                                                            <NmRadioV2
                                                                label={`Строка №${item.rowNumber}`}
                                                                onChange={() => handleChange(taskId, item.entryId)}
                                                                checked={item.entryId === selectedRows[taskId]}
                                                            />
                                                        );
                                                    })
                                                }
                                            </NmForm>
                                            <Text
                                                className="mt-2 mb-2"
                                                bold={true}
                                            >
                                                Детали строки
                                            </Text>
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Описание выполненных работ для акта"
                                                text={
                                                    <NmShowMoreText
                                                        lines={1}
                                                        more="Подробнее"
                                                        anchor="blue"
                                                    >
                                                        {selectedEntry.workActDescription || "-"}
                                                    </NmShowMoreText>
                                                }
                                                columnOnMobile={true}
                                                fluidText={true}
                                            />
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Описание выполненных работ для чека ФНС"
                                                text={
                                                    <NmShowMoreText
                                                        lines={1}
                                                        more="Подробнее"
                                                        anchor="blue"
                                                    >
                                                        {selectedEntry.workFNSDescription || "-"}
                                                    </NmShowMoreText>
                                                }
                                                columnOnMobile={true}
                                                fluidText={true}
                                            />
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Комментарий"
                                                text={
                                                    <NmShowMoreText
                                                        lines={1}
                                                        more="Подробнее"
                                                        anchor="blue"
                                                    >
                                                        {selectedEntry.comment || "-"}
                                                    </NmShowMoreText>
                                                }
                                                columnOnMobile={true}
                                                fluidText={true}
                                            />
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Период работ"
                                                text={`Период работ ${formatLocalDate(selectedEntry.workStartDate, "dd.MM.yyyy")} - ${formatLocalDate(selectedEntry.workEndDate, "dd.MM.yyyy")}`}
                                            />
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Дата акта"
                                                text={formatLocalDate(selectedEntry.actFormingDate, "dd.MM.yyyy")}
                                            />
                                            <NmLabelText
                                                className="mb-2"
                                                type="list"
                                                label="Оценка качества выполненных работ"
                                                text={selectedEntry.qualityRating}
                                            />
                                            <NmLabelText
                                                type="list"
                                                label="Оценка сроков выполненных работ"
                                                text={selectedEntry.timingRating}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div> :
                        <NmEmptyPageV2 />
                }
            </div>
        </NmModal>
    );
};

export default CrowdTaskActRegistryDuplicatesEntryModal;