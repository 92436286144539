import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageSettingsClientGroupsCardClients,
    updateStoreSettingsClientGroupsClients,
} from "../../../../../../ducks/bff/settings/client-groups/card/clients/actionCreators";

export function useGetListClients(props) {
    const {
        filter,
        pageSize,
        pageNum,
        groupId,
        importProgress,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsClientGroupsClients({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (!importProgress) {
            fetchList();
        }
    }, [filter, pageSize, pageNum, groupId, importProgress]);

    function fetchList() {
        dispatch(getPageSettingsClientGroupsCardClients({
            pageSize,
            pageNum,
            groupId,
            ...filter,
        }));
    }

    return {
        fetchList,
    };
}