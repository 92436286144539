import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const orderContractorWorkLogSelector = state => state.bff.orderContractorWorkLog;
export const orderContractorWorkLogListSelector  = createSelector(orderContractorWorkLogSelector, ({list}) => list);
export const orderContractorWorkLogTotalCountSelector  = createSelector(orderContractorWorkLogSelector, ({totalCount}) => totalCount);
export const orderContractorWorkLogTotalPagesSelector  = createSelector(orderContractorWorkLogSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const orderContractorWorkLogSelectorInfoSelector = createSelector(orderContractorWorkLogSelector, ({info}) => info);
export const orderContractorWorkLogSelectorProgressSelector = createSelector(orderContractorWorkLogSelector, ({progress}) => progress);
export const orderContractorWorkLogSelectorProgressActionSelector = createSelector(orderContractorWorkLogSelector, ({progressAction}) => progressAction);