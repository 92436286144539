import {
    CLEAR_FIELDS_DOCUMENTS_CORRECTION,
    GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS,
    GET_PAGE_DOCUMENTS_CORRECTION_ERROR,
    GET_PAGE_DOCUMENTS_CORRECTION_REQUEST,
    GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS,
    PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST,
    PERFORM_DOCUMENTS_CORRECTION_REQUEST,
    PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR,
    PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS,
    PREFORM_DOCUMENTS_CORRECTION_ERROR,
    PREFORM_DOCUMENTS_CORRECTION_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    list: [],
    dictFieldName: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_DOCUMENTS_CORRECTION_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_DOCUMENTS_CORRECTION_SUCCESS:
            const {
                documents,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: documents,
            };
        case GET_PAGE_DOCUMENTS_CORRECTION_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case PERFORM_DOCUMENTS_CORRECTION_REQUEST:
        case PERFORM_DEMO_DOCUMENTS_CORRECTION_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case PREFORM_DOCUMENTS_CORRECTION_SUCCESS:
        case PREFORM_DOCUMENTS_CORRECTION_ERROR:
        case PREFORM_DEMO_DOCUMENTS_CORRECTION_SUCCESS:
        case PREFORM_DEMO_DOCUMENTS_CORRECTION_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_FIELD_NAMES_DICT_DOCUMENTS_CORRECTION_SUCCESS:
            return {
                ...state,
                dictFieldName: payload,
            };
        case CLEAR_FIELDS_DOCUMENTS_CORRECTION:
            return initial;
        default:
            return state;
    }
};