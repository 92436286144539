import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {PATTERN_NUMBER} from "../../../../../../deposit/client-list/hooks/useGetlDepositData";
import {useDataContractorEmploymentHistoryEditForm} from "./hooks/useData";

import {
    contractorAdditionalDocumentsProgressUpdateDataSelector,
} from "../../../../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {
    fileContractorScanProgressAddSelector,
    fileContractorScanProgressSelector,
} from "../../../../../../../ducks/fileStore";

const ContractorEmploymentHistoryEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
        data,
    } = props;

    const progress = useSelector(contractorAdditionalDocumentsProgressUpdateDataSelector);
    const progressScan = useSelector(fileContractorScanProgressAddSelector);
    const progressGetScan = useSelector(fileContractorScanProgressSelector);

    const {
        files,
        onDropScan,
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
    } = useDataContractorEmploymentHistoryEditForm({
        contractorId,
        data,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Трудовая книжка (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress || progressScan || progressGetScan}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    label="Номер"
                    onChange={handleChange}
                    pattern={PATTERN_NUMBER}
                    value={values.employmentHistoryNum}
                    name="employmentHistoryNum"
                    onBlur={() => setFieldTouched("employmentHistoryNum", true)}
                    error={
                        touched?.employmentHistoryNum &&
                        errors?.employmentHistoryNum
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография разворота трудовой книжки с Вашим ФИО или сведения о трудовой деятельности, предоставляемые работнику работодателем, форма СТД-Р"
                    isVisibleLabel
                    files={files}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={onDropScan}
                    uploadBtnText={isEmpty(files) ? "Выбрать файл" : "Заменить"}
                    error={errors && errors.file}
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorEmploymentHistoryEditForm;