import {addYears, addYearsOrDays, calculateAge, getStartDate} from "./dateFormat";
import {pluralizeSymbols, pluralizeValues} from "./pluralize";
import {clearSpace, getNumberFromFormattedAmount, removePhoneMask, removeSnilsMask} from "./stringFormat";
import {isNullOrWhitespace} from "./stringHelper";

import {FILE_ERROR} from "../constants/messages";
import {LENGTH_FOREIGN_PHONE} from "../constants/phone";
import {
    emailReg,
    extensionsImage,
    maxSizeFile,
    okvedFormatRegex,
    okvedSymbolsRegex,
} from "../constants/validationRules";

const _postFix = "ErrorMessage";
const maxPasswordLength = 20;
const minPasswordLength = 6;

const validate = (object, rule, postFix = _postFix) => {
    const errorMessage = {};

    for (const key in rule) {
        if (!rule[key]) {
            rule[key] = {};
        }
    }

    Object.keys(rule).forEach(key => {
        const {required, minLength, maxLength, match, minValue, func, length, maxValue, agree, secondArg, minValueNotStrong, notZero} = rule[key];

        if (required && isNullOrWhitespace(object[key])) {
            errorMessage[`${key}${postFix}`] = required;
        }

        if (length && object[key] && object[key].length !== length.value) {
            errorMessage[`${key}${postFix}`] = length.message;
        }

        if (notZero && getNumberFromFormattedAmount(object[key]) === 0) {
            errorMessage[`${key}${postFix}`] = notZero;
        }

        if (maxLength && object[key] && object[key].length > maxLength.value) {
            errorMessage[`${key}${postFix}`] = maxLength.message;
        }

        if (minLength && object[key] && object[key].length < minLength.value) {
            errorMessage[`${key}${postFix}`] = minLength.message;
        }

        if (match && object[key] && !match.value.test(object[key])) {
            errorMessage[`${key}${postFix}`] = match.message;
        }

        if (maxValue && object[key] && (getNumberFromFormattedAmount(object[key]) > maxValue.value)) {
            errorMessage[`${key}${postFix}`] = maxValue.message;
        }

        if (minValue && (object[key] || object[key] === 0) && (parseFloat(object[key]) < minValue.value || parseFloat(object[key]) === minValue.value)) {
            errorMessage[`${key}${postFix}`] = minValue.message;
        }

        if (minValueNotStrong && object[key] && (parseFloat(object[key]) < minValueNotStrong.value)) {
            errorMessage[`${key}${postFix}`] = minValueNotStrong.message;
        }

        if (agree && object[key] !== undefined && object[key] === false) {
            errorMessage[`${key}${postFix}`] = agree;
        }

        if (object[key] && func) {
            let error;

            if (secondArg) {
                error = func(object[key], object[rule[key].secondArg]);
            } else {
                error = func(object[key]);
            }

            if (error) {
                errorMessage[`${key}${postFix}`] = error;
            }
        }
    });

    return errorMessage;
};

export default validate;


export function validatePassportDate(data) {
    const {
        passportDate,
        birthDate,
        intervalAges: {
            first,
            second,
            third,
        },
        isBy,
    } = data;

    if (!passportDate) {
        return true;
    }

    const userAge = calculateAge(birthDate);
    const isValidBirthData = Boolean(birthDate);

    let isValidPassportDate;

    const checkIntervalDate = (firstAge, lastAge) => {
        const leftEquation = addYears(birthDate, firstAge);
        const rightEquation = addYears(birthDate, lastAge);

        if (!lastAge) {
            return (getStartDate(leftEquation) <= getStartDate(passportDate));
        }

        return ((getStartDate(leftEquation) <= getStartDate(passportDate)) && (getStartDate(passportDate) < getStartDate(rightEquation)));
    };

    if (first <= userAge && userAge < second) {
        isValidPassportDate = Boolean(passportDate) && checkIntervalDate(isBy ? 16 : first, second);
    }

    if (second <= userAge && userAge < third) {
        isValidPassportDate = Boolean(passportDate) && checkIntervalDate(second, third);
    }

    if (third <= userAge) {
        isValidPassportDate = Boolean(passportDate) && checkIntervalDate(third);
    }

    return (isValidBirthData && isValidPassportDate);
}

export function validatePassportDateRu(data) {
    const {
        passportDate,
        birthDate,
        isForEditForm,
    } = data;

    const intervalAges = {
        first: 14,
        second: 20,
        third: 45,
    };

    if (!passportDate || !birthDate) {
        return true;
    }

    const userAges = calculateAge(birthDate);
    const checkByYears = (years) => {
        const leftFirst = addYears(birthDate, years);
        const rightFirst = addYearsOrDays(birthDate, years, 90);
        const exceptionCheck = addYears(birthDate, 14);

        if (getStartDate(exceptionCheck) > getStartDate(passportDate) && isForEditForm) {
            return false;
        }

        if (getStartDate(leftFirst) > getStartDate(passportDate) && getStartDate(rightFirst) < getStartDate(new Date())) {
            return false;
        }

        return true;
    };

    if (userAges >= intervalAges.first && userAges < intervalAges.second && isForEditForm) {
        const leftEquation = addYears(birthDate, intervalAges.first);
        const rightEquation = addYears(birthDate, intervalAges.second);

        return ((getStartDate(leftEquation) <= getStartDate(passportDate)) && (getStartDate(passportDate) < getStartDate(rightEquation)));
    }

    if (userAges >= intervalAges.second && userAges < intervalAges.third) {
        return checkByYears(intervalAges.second);
    }

    if (userAges >= intervalAges.third) {
        return checkByYears(intervalAges.third);
    }

    return true;
}

export function validatePassword(pass) {
    if (pass.length > maxPasswordLength) {
        return "Пароль не может быть более 20 символов";
    } else if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]/.test(pass)) {
        return "Пароль должен содержать только латинские буквы: минимум одну строчную букву, одну заглавную букву и одну цифру";
    } else if (/[^!#$%&'*+-/=?^_`{|}~0-9a-zA-Z]/.test(pass)) {
        return "Можно использовать следующие спецсимволы: !#$%&'*+-/=?^_`{|}~";
    } else if (pass.length < minPasswordLength) {
        return "Пароль не может быть менее 6 символов";
    }

    return undefined;
}

export function validatePromocodeMultiCount(count, arg2) {
    const number = Number(count.toString().trim());

    return number < 2 || number > 1_000_000 ? "от 2 до 1 000 000" : undefined;
}

export function validatePromocodeCount(count, arg2) {
    if (!count) {
        return "Обязательное поле";
    }

    const number = Number(count.toString().trim());

    return number < 1 || number > 1_000 ? "от 1 до 1 000" : undefined;
}

export function validateSnils(inputSnils) {
    if (isNullOrWhitespace(inputSnils)) {
        return "СНИЛС пуст";
    }

    const snils = removeSnilsMask(inputSnils);

    if (/[^0-9]/.test(snils)) {
        return "СНИЛС может состоять только из цифр";
    } else if (snils.length !== 11) {
        return "СНИЛС может состоять только из 11 цифр";
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(snils[i]) * (9 - i);
    }

    let checkDigit = 0;
    if (sum < 100) {
        checkDigit = sum;
    } else if (sum > 101) {
        checkDigit = parseInt(sum % 101);
        if (checkDigit === 100) {
            checkDigit = 0;
        }
    }

    if (checkDigit === parseInt(snils.slice(-2))) {
        return undefined;
    }
    return "Неправильно введен СНИЛС";
}

export function validateKpp(kpp) {
    if (typeof kpp === "number") {
        kpp = kpp.toString();
    } else if (typeof kpp !== "string") {
        kpp = "";
    }

    if (isNullOrWhitespace(kpp)) {
        return "Обязательное поле";
    } else if (kpp.length !== 9) {
        return "КПП может состоять только из 9 знаков";
    } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
        return "Неправильный формат КПП";
    }

    return undefined;
}

export function validateKs(ks, bik) {
    if (isNullOrWhitespace(bik)) {
        return "Сначала заполните БИК";
    }

    if (typeof ks === "number") {
        ks = ks.toString();
    } else if (typeof ks !== "string") {
        ks = "";
    }

    if (isNullOrWhitespace(ks)) {
        return "Обязательное поле";
    } else if (/[^0-9]/.test(ks)) {
        return "К/С может состоять только из цифр";
    } else if (ks.length !== 20) {
        return "К/С может состоять только из 20 цифр";
    } else {
        const bikKs = "0" + bik.toString().slice(4, 6) + ks;
        let checksum = 0;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
        for (const i in coefficients) {
            checksum += coefficients[i] * (bikKs[i] % 10);
        }
        if (checksum % 10 !== 0) {
            return "Неправильное контрольное число";
        }
    }

    return undefined;
}

export function validateBik(bik) {
    if (typeof bik === "number") {
        bik = bik.toString();
    } else if (typeof bik !== "string") {
        bik = "";
    }

    if (isNullOrWhitespace(bik)) {
        return "Обязательное поле";
    }
    if (/[^0-9]/.test(bik)) {
        return "БИК может состоять только из цифр";
    }
    if (!/^04[0-9]+/.test(bik)) {
        return "БИК должен начинаться с 04";
    }
    if (parseFloat(bik.slice(6, 9)) < 50) {
        return "Три крайних цифры должны быть в диапазоне от 050 до 999";
    }
    if (bik.length !== 9) {
        return "БИК может состоять только из 9 цифр";
    }

    return undefined;
}

export function validateOgrnIP(checkedValue) {
    if (!checkedValue) {
        return "Обязательное поле";
    }

    if (checkedValue.length !== 15) {
        return "ОГРНИП может состоять только из 15 цифр";
    }

    if (checkedValue.slice(14, 15) !== ((checkedValue.slice(0, -1)) % 13 + "").slice(-1)) {
        return "ОГРНИП введен неверно";
    }
}

export function validateOgrn(ogrn) {
    if (typeof ogrn === "number") {
        ogrn = ogrn.toString();
    } else if (typeof ogrn !== "string") {
        ogrn = "";
    }

    if (isNullOrWhitespace(ogrn)) {
        return "Обязательное поле";
    }
    if (/[^0-9]/.test(ogrn)) {
        return "ОГРН может состоять только из цифр";
    }
    if (ogrn.length !== 13) {
        return "ОГРН может состоять только из 13 цифр";
    }

    const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));

    if (n13 === parseInt(ogrn[12])) {
        return undefined;
    }

    return "Неверный формат ОГРН";
}

export function validateRs(rs, bik) {
    if (isNullOrWhitespace(bik)) {
        return "Сначала заполните БИК";
    }

    if (typeof rs === "number") {
        rs = rs.toString();
    } else if (typeof rs !== "string") {
        rs = "";
    }

    if (isNullOrWhitespace(rs)) {
        return "Обязательное поле";
    }
    if (/[^0-9]/.test(rs)) {
        return "Р/С может состоять только из цифр";
    }
    if (rs.length !== 20) {
        return "Р/С может состоять только из 20 цифр";
    }

    const bikRs = bik.toString().slice(-3) + rs;

    let checksum = 0;

    const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

    for (const i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
    }
    if (checksum % 10 !== 0) {
        return "Неправильное контрольное число";
    }


    return undefined;
}

export function validateIndividualInn(inn) {
    return validateInn(inn, 12);
}

export function validateInnVersion2(inn) {
    let _inn = inn;

    if (isNullOrWhitespace(inn)) {
        return;
    }

    if (typeof inn === "number") {
        _inn = inn.toString();
    } else if (typeof inn !== "string") {
        _inn = "";
    }
    _inn = clearSpace(_inn);

    if (!/^[\d+]{10,12}$/.test(_inn)) {
        return "Неверный формат ИНН";
    }

    return checkInnFormat(_inn);
}

export function checkInnFormat(_inn) {
    let result = false;

    const checkDigit = function (_inn, coefficients) {
        let n = 0;
        for (const i in coefficients) {
            n += coefficients[i] * _inn[i];
        }
        return parseInt(n % 11 % 10);
    };

    switch (_inn?.length) {
        case 10:
            const n10 = checkDigit(_inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(_inn[9])) {
                result = true;
            }
            break;
        case 12:
            const n11 = checkDigit(_inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            const n12 = checkDigit(_inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if ((n11 === parseInt(_inn[10])) && (n12 === parseInt(_inn[11]))) {
                result = true;
            }
            break;
        default:
            break;
    }

    if (!result) {
        return "Неверный формат ИНН";
    }

    return undefined;
}

export function validateInn(inn, checkLength = 10) {
    let _inn = inn;
    if (typeof inn === "number") {
        _inn = inn.toString();
    } else if (typeof inn !== "string") {
        _inn = "";
    }
    if (!_inn.length) {
        return "Обязательное поле";
    }

    _inn = clearSpace(_inn);

    if (/[^0-9]/.test(_inn)) {
        return "ИНН может состоять только из цифр";
    }

    if (_inn.length !== checkLength) {
        return `ИНН может состоять только из ${checkLength} цифр`;
    }

    return checkInnFormat(_inn);
}

export function validateCardNumber(number) {
    const luhnCheck = digits => {
        let sum = 0;

        for (let i = 0; i < digits.length; i++) {
            let cardNum = parseInt(digits[i]);

            if ((digits.length - i) % 2 === 0) {
                cardNum = cardNum * 2;

                if (cardNum > 9) {
                    cardNum = cardNum - 9;
                }
            }

            sum += cardNum;
        }

        return sum % 10 === 0;
    };

    if (isNullOrWhitespace(number) || !number.length) {
        return "Номер карты не заполнен";
    }

    if (!/^\d+$/.test(number)) {
        return "Номер может состоять только из цифр";
    }

    if (number.length < 16 || number.length > 19) {
        return "Количество цифр в номере банковской карты от 16 до 19";
    }

    if (luhnCheck(number)) {
        return undefined;
    }

    return "Неверный номер карты";
}

export function detectAge(date, inputRegistrationDate, minAge = 18) {
    return calculateAge(date) < minAge ? `Данный сервис доступен для лиц старше ${minAge} лет` : null;
}

export function validateMigrationNumber(number) {
    const migrationCardRegExp = /^[0-9]{4}\s{1}[0-9]{7}$/;
    const numbersRegExp = /[^0-9\s]+/;

    if (!number.length) {
        return "Номер карты не заполнен";
    }

    if (number.length !== 12) {
        return "Номер карты может состоять из 12 символов вместе с пробелом";
    }

    if (numbersRegExp.test(number)) {
        return "Недопустимые символы";
    }

    if (!migrationCardRegExp.test(number)) {
        return "Формат для ввода: 1234 1234567";
    }

    return null;
}

export function validateInstructionDate(date) {
    if (date < new Date("2019-12-31")) {
        return "Минимальная дата 01.01.2020";
    }
    const currentDate = new Date();

    if (date > new Date(currentDate.getFullYear() + 50, currentDate.getMonth(), currentDate.getDate())) {
        return "Имеются некорректные значения";
    }
    return undefined;
}


const passportRuRegExp = /^[0-9]{4}\s{1}[0-9]{6}$/;
const passportRuNumbersRegExp = /[^0-9\s]+/;
const passportOthersRegExp = /^[a-zA-Z][a-zA-Z0-9][\s0-9]+$/;

export const isValidPassportNumber = (citizenship, idDocNumber) => {
    if (!idDocNumber) {
        return "rejection-reason.error-message-required";
    }

    if (citizenship === "RU") {
        if (idDocNumber.length !== 11) {
            return "contractor-documents-info.error-number-characters-11";
        }
        if (passportRuNumbersRegExp.test(idDocNumber)) {
            return "contractor-documents-info.error-unacceptable-symbols";
        }
        if (!passportRuRegExp.test(idDocNumber)) {
            return "contractor-documents-info.error-passport-format";
        }

        return null;
    }

    if (idDocNumber.length !== 10) {
        return "contractor-documents-info.error-number-characters-9";
    }
    if (!passportOthersRegExp.test(idDocNumber)) {
        return "contractor-documents-info.error-unacceptable-symbols";
    }

    return null;
};

export const isValidFile = (filesProperties, maxSize = maxSizeFile, types = extensionsImage, errorFileSize = FILE_ERROR.SIZE) => {
    const parts = filesProperties[0].name.split(".");

    if (filesProperties[0].size > maxSize) {
        return errorFileSize;
    }

    if (parts.length < 2 || types.indexOf(parts.pop()) === -1) {
        return FILE_ERROR.TYPE;
    }

    return null;
};

export const getEmailError = (email) => {
    const maxLength = 255;

    if (!email) {
        return "Обязательное поле";
    }

    if (email.length > maxLength) {
        return "Максимальная длина - 255 символов";
    }

    if (!emailReg.test(email)) {
        return "Укажите, пожалуйста, корректный email";
    }

    return null;
};

export function validateOkved(str) {
    if (!okvedSymbolsRegex.test(str)) {
        return "Разрешен ввод цифр и знаков \".\"";
    }

    if (!okvedFormatRegex.test(str)) {
        return "Неверный формат";
    }

    return null;
}

export function validateInvoiceOfDepositReplenishmentSum(sum) {
    if (getNumberFromFormattedAmount(sum)< 0.01) {
        return "Минимальное значение 0,01.";
    }

    return null;
}

export const validateInnWithYup = function (value) {
    if (!value) {
        return true;
    }

    return !checkInnFormat(value);
};

export const validateOgrnipWithYup = function (value) {
    if (!value) {
        return true;
    }

    return value.slice(14, 15) === ((value.slice(0, -1)) % 13 + "").slice(-1);
};

export const validateUUID = function (value) {
    if (!value) {
        return true;
    }

    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value);
};

export const validateAge = (age, minNumbersOfYears) => {
    if (!age) {
        return true;
    }

    return age >= minNumbersOfYears;
};

export const validatePhone = function (value) {
    const phone = removePhoneMask(value);

    if (!phone) {
        return false;
    }

    return phone.length >= LENGTH_FOREIGN_PHONE.MIN && phone.length <= LENGTH_FOREIGN_PHONE.MAX;
};

export const getYupError = (touched, errors, name) => {
    return touched[name]
    && errors[name]
        ? errors[name]
        : undefined;
};

export const getValidationMessage = () => {
    return {
        required: () => "Обязательное поле",
        minLength: (min) => `Минимальная длина строки ${pluralizeSymbols(min)}`,
        maxLength: (max) => `Не более ${pluralizeSymbols(max)}`,
        maxArray: (max) => `Не более ${pluralizeValues(max)}`,
        minArray: (min) => `Добавьте хотя бы ${pluralizeValues(min)}`,
        minValue: (min) => `Минимальное значение - ${min}`,
        maxValue: (max) => `Максимальное значение - ${max}`,
        invalidFormat: () => "Неверный формат",
    };
};