import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ADMIN, isClientUser, NM_MANAGER, RECRUITER} from "../../../../constants/roles";

export const getRecruitmentDirectoriesAccessActions = (params) => {
    const {
        role,
        currentUserRestrictions,
        clientProperties,
    } = params;

    if (
        role === RECRUITER
        && clientProperties?.restrictUpdateFunnelToRecruiters
    ) {
        return false;
    }

    return (
        (
            [ADMIN, NM_MANAGER].includes(role)
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.manageDirectoriesAdmin)
        )
        || (isClientUser(role))
    );
};