import {
    BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS,
    BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS,
    CLEAR_CLIENT_REPORTS_ONE_C_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    progressExport: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS:
            const {
                financesModels = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: financesModels,
                totalCount,
            };
        case BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS:
        case BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS:
            return {
                ...state,
                progressExport: payload,
            };
        case CLEAR_CLIENT_REPORTS_ONE_C_STORE:
            return initial;
        default:
            return state;
    }
};