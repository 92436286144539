import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {getFullName} from "../../../../../utils/stringFormat";

const clientForemenPaymentsSelector = state => state.bff.clientForemenPayments;

export const clientForemenPaymentsListSelector = createSelector(
    clientForemenPaymentsSelector,
    ({list}) => list,
);
export const clientForemenPaymentsTotalCountSelector = createSelector(
    clientForemenPaymentsSelector,
    ({totalCount}) => totalCount,
);
export const clientForemenPaymentsTotalPagesSelector = createSelector(
    clientForemenPaymentsSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);
export const clientForemenPaymentsTotalAmountSelector = createSelector(
    clientForemenPaymentsSelector,
    ({totalAmount}) => totalAmount,
);
export const clientForemenPaymentsProgressListSelector = createSelector(
    clientForemenPaymentsSelector,
    ({progressList}) => progressList,
);
export const clientForemenPaymentsProgressActionSelector = createSelector(
    clientForemenPaymentsSelector,
    ({progressAction}) => progressAction,
);
export const confirmTextDuplicateClientForemenPaymentsSelector = createSelector(
    clientForemenPaymentsSelector,
    ({confirmTextDuplicateForemanPayment}) => confirmTextDuplicateForemanPayment,
);
export const clientForemenPaymentsContractorsOptionsSelector = createSelector(
    clientForemenPaymentsSelector,
    ({foremanContractorsRichList}) => {
        return foremanContractorsRichList.map(foreman => {
            const {
                contractorId,
                foremanContractorFirstName,
                foremanContractorLastName,
                foremanContractorPatronymic,
            } = foreman;

            return {
                key: contractorId,
                value: contractorId,
                text: getFullName(foremanContractorLastName, foremanContractorFirstName, foremanContractorPatronymic),
            };
        });
    },
);