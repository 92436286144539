import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_HISTORY_FINANCES_ORDERS_ERROR,
    GET_HISTORY_FINANCES_ORDERS_REQUEST,
    GET_HISTORY_FINANCES_ORDERS_SUCCESS,
    GET_PAGE_FINANCES_ORDERS_ERROR,
    GET_PAGE_FINANCES_ORDERS_REQUEST,
    GET_PAGE_FINANCES_ORDERS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/finances/orders";

const getFinancesOrdersPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_FINANCES_ORDERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_FINANCES_ORDERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_FINANCES_ORDERS_ERROR,
            payload: error,
        });
    }
};

const getFinancesOrdersHistory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get-order-history`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HISTORY_FINANCES_ORDERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_HISTORY_FINANCES_ORDERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_HISTORY_FINANCES_ORDERS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_FINANCES_ORDERS_REQUEST, getFinancesOrdersPage),
        takeEvery(GET_HISTORY_FINANCES_ORDERS_REQUEST, getFinancesOrdersHistory),
    ]);
}