import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getDirectoriesFederalMigrationPage} from "./actionCreators";
import {
    ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST,
    UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
} from "./actions";
import {settingsDirectoriesNaimixFederalMigrationPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/federal-migration";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsDirectoriesNaimixFederalMigrationPageDataSelector(state);

    yield put(getDirectoriesFederalMigrationPage(pageData));
};

const getDirectoriesFederalMigrationPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/list`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
            payload: error,
        });
    }
};

const getDirectoriesFederalMigrationCardSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
            payload: error,
        });
    }
};

const addDirectoriesFederalMigrationSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Подразделение успешно добавлено");

        yield fetchList();

        yield put({
            type: ADD_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
            payload: error,
        });
    }
};

const updateDirectoriesFederalMigrationSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Подразделение успешно изменено");

        yield fetchList();

        yield put({
            type: UPDATE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
            payload: error,
        });
    }
};

const deleteDirectoriesFederalMigrationSaga = function* ({payload}) {
    try {
        const result = yield request.bff.delete(`${controller}/delete`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Подразделение ФМС успешно удалено");

        yield fetchList();

        yield put({
            type: DELETE_DIRECTORIES_FEDERAL_MIGRATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DIRECTORIES_FEDERAL_MIGRATION_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST, getDirectoriesFederalMigrationPageSaga),
        takeEvery(GET_CARD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST, getDirectoriesFederalMigrationCardSaga),
        takeEvery(UPDATE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST, updateDirectoriesFederalMigrationSaga),
        takeEvery(DELETE_DIRECTORIES_FEDERAL_MIGRATION_REQUEST, deleteDirectoriesFederalMigrationSaga),
        takeEvery(ADD_DIRECTORIES_FEDERAL_MIGRATION_REQUEST, addDirectoriesFederalMigrationSaga),
    ]);
}