import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS,
    BFF_CLIENT_REPORTS_ONE_C_CANCEL_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_CANCEL_SUCCESS,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS,
    BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_ERROR,
    BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST,
    BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

import {TASK_STATUS} from "../../../../../constants/task";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/reports/one-c",
        client: "/client-adm/reports/one-c",
    });
};

const getPageClientReportsOneCSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get-finances-export-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const getTasksProgressClientReportsOneCSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            reqData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/get-by-ids`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const tasksCompleted = result.filter(item => [
            TASK_STATUS.ERROR.VALUE,
            TASK_STATUS.COMPLETED.VALUE,
        ].includes(item.status));

        if (handleResponse && tasksCompleted.length !== 0) {
            handleResponse();
        }

        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_ERROR,
            payload: error,
        });
    }
};

const addClientReportOneCSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-export`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR,
        });
    }
};

const cancelClientReportOneCSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/cancel-export-by-id`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_REPORTS_ONE_C_CANCEL_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_CANCEL_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_CLIENT_REPORTS_ONE_C_CANCEL_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST, getPageClientReportsOneCSaga),
        takeEvery(BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST, getTasksProgressClientReportsOneCSaga),
        takeEvery(BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST, addClientReportOneCSaga),
        takeEvery(BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST, cancelClientReportOneCSaga),
    ]);
}