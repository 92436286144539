import {select} from "@redux-saga/core/effects";
import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getPageFinancesPayments} from "./financePayments/actionCreators";

import {
    filterTaskByTypes,
    getTasksAdvertisement,
    getTasksByType,
    getTasksInProgress,
    getTasksRegistryDiscardPayments,
    getTasksRegistryPaymentsImport,
} from "../utils/jobHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {ORDER_WORK_REPORT} from "../constants/messages";
import {ORDER_MASS_ACTION_MESSAGE} from "../constants/order";
import {TASK_STATUS, TASK_TYPE} from "../constants/task";

const controller = "/job";

//*  TYPES  *//
const GET_ALL_TASKS_REQUEST = "GET_ALL_TASKS_REQUEST";
const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
const GET_ALL_TASKS_ERROR = "GET_ALL_TASKS_ERROR";

const CLOSE_ORDERS_REQUEST = "CLOSE_ORDERS_REQUEST";
const CLOSE_ORDERS_SUCCESS = "CLOSE_ORDERS_SUCCESS";
const CLOSE_ORDERS_ERROR = "CLOSE_ORDERS_ERROR";

const SET_INVISIBLE_TASK_REQUEST = "SET_INVISIBLE_TASK_REQUEST";
const SET_INVISIBLE_TASK_SUCCESS = "SET_INVISIBLE_TASK_SUCCESS";
const SET_INVISIBLE_TASK_ERROR = "SET_INVISIBLE_TASK_ERROR";

const ADD_BANK_DEPOSIT_EXPORT_REQUEST = "ADD_BANK_DEPOSIT_EXPORT_REQUEST";
const ADD_BANK_DEPOSIT_EXPORT_SUCCESS = "ADD_BANK_DEPOSIT_EXPORT_SUCCESS";
const ADD_BANK_DEPOSIT_EXPORT_ERROR = "ADD_BANK_DEPOSIT_EXPORT_ERROR";

const UPDATE_JOB_FILED_STORE = "UPDATE_JOB_FILED_STORE";

const GET_PROGRESS_EXPORT_REQUEST = "PROGRESS_EXPORT_REQUEST";
const GET_PROGRESS_EXPORT_SUCCESS = "PROGRESS_EXPORT_SUCCESS";
const GET_PROGRESS_EXPORT_ERROR = "PROGRESS_EXPORT_ERROR";

const ARCHIVE_ORDERS_REQUEST = "ARCHIVE_ORDERS_REQUEST";
const ARCHIVE_ORDERS_SUCCESS = "ARCHIVE_ORDERS_SUCCESS";
const ARCHIVE_ORDERS_ERROR = "ARCHIVE_ORDERS_ERROR";

const DELETE_ORDERS_REQUEST = "DELETE_ORDERS_REQUEST";
const DELETE_ORDERS_SUCCESS = "DELETE_ORDERS_SUCCESS";
const DELETE_ORDERS_ERROR = "DELETE_ORDERS_ERROR";

const PUBLISH_ORDERS_REQUEST = "PUBLISH_ORDERS_REQUEST";
const PUBLISH_ORDERS_SUCCESS = "PUBLISH_ORDERS_SUCCESS";
const PUBLISH_ORDERS_ERROR = "PUBLISH_ORDERS_ERROR";

const PUBLISH_ORDERS_IN_WORK_STATUS_REQUEST = "PUBLISH_ORDERS_IN_WORK_STATUS_REQUEST";
const PUBLISH_ORDERS_IN_WORK_STATUS_SUCCESS = "PUBLISH_ORDERS_IN_WORK_STATUS_SUCCESS";
const PUBLISH_ORDERS_IN_WORK_STATUS_ERROR = "PUBLISH_ORDERS_IN_WORK_STATUS_ERROR";

const ORDERS_END_CONTRACTOR_SEARCH_REQUEST = "ORDERS_END_CONTRACTOR_SEARCH_REQUEST";

const ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_REQUEST = "ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_REQUEST";
const ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_SUCCESS = "ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_SUCCESS";
const ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_ERROR = "ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_ERROR";

const ADD_TASK_ANALYSE_LOCATION_REQUEST = "ADD_TASK_ANALYSE_LOCATION_REQUEST";
const ADD_TASK_ANALYSE_LOCATION_SUCCESS = "ADD_TASK_ANALYSE_LOCATION_SUCCESS";
const ADD_TASK_ANALYSE_LOCATION_ERROR = "ADD_TASK_ANALYSE_LOCATION_ERROR";

const CANCEL_EXPORT_BY_ID_REQUEST = "CANCEL_EXPORT_BY_ID_REQUEST";
const CANCEL_EXPORT_BY_ID_SUCCESS = "CANCEL_EXPORT_BY_ID_SUCCESS";
const CANCEL_EXPORT_BY_ID_ERROR = "CANCEL_EXPORT_BY_ID_ERROR";

const CANCEL_TASK_BY_ID_REQUEST = "CANCEL_TASK_BY_ID_REQUEST";
const CANCEL_TASK_BY_ID_SUCCESS = "CANCEL_TASK_BY_ID_SUCCESS";
const CANCEL_TASK_BY_ID_ERROR = "CANCEL_TASK_BY_ID_ERROR";

const BFF_RECRUITMENT_VACANCY_IMPORT_REQUEST = "BFF_RECRUITMENT_VACANCY_IMPORT_REQUEST";
const BFF_RECRUITMENT_VACANCY_IMPORT_SUCCESS = "BFF_RECRUITMENT_VACANCY_IMPORT_SUCCESS";
const BFF_RECRUITMENT_VACANCY_IMPORT_ERROR = "BFF_RECRUITMENT_VACANCY_IMPORT_ERROR";

const JOB_REJECT_ORDER_PAYMENT_REQUEST = "JOB_REJECT_ORDER_PAYMENT_REQUEST";
const JOB_REJECT_ORDER_PAYMENT_SUCCESS = "JOB_REJECT_ORDER_PAYMENT_SUCCESS";
const JOB_REJECT_ORDER_PAYMENT_ERROR = "JOB_REJECT_ORDER_PAYMENT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    tasks: [],
    totalCount: 0,
    loadSignal: true,
    progressExport: [],
    progress: false,
    progressPublishInWorkStatus: false,
    progressOrdersMassAction: false,
    progressPaymentsUpdateBankStatus: false,
    progressPaymentsUpdateBankStatusAction: false,
    progressStats: false,
    stats: {
        pageData: {},
        results: [],
        totalCount: 0,
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLOSE_ORDERS_REQUEST:
        case PUBLISH_ORDERS_REQUEST:
        case DELETE_ORDERS_REQUEST:
        case ARCHIVE_ORDERS_REQUEST:
            return {
                ...state,
                progressOrdersMassAction: true,
            };
        case CLOSE_ORDERS_ERROR:
        case CLOSE_ORDERS_SUCCESS:
        case PUBLISH_ORDERS_ERROR:
        case PUBLISH_ORDERS_SUCCESS:
        case DELETE_ORDERS_ERROR:
        case DELETE_ORDERS_SUCCESS:
        case ARCHIVE_ORDERS_ERROR:
        case ARCHIVE_ORDERS_SUCCESS:
            return {
                ...state,
                progressOrdersMassAction: false,
            };
        case GET_ALL_TASKS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case UPDATE_JOB_FILED_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_ALL_TASKS_SUCCESS:
            return {
                ...state,
                tasks: payload,
                loadSignal: false,
                progress: false,
            };
        case PUBLISH_ORDERS_IN_WORK_STATUS_REQUEST:
            return {
                ...state,
                progressPublishInWorkStatus: true,
            };
        case PUBLISH_ORDERS_IN_WORK_STATUS_SUCCESS:
        case PUBLISH_ORDERS_IN_WORK_STATUS_ERROR:
            return {
                ...state,
                progressPublishInWorkStatus: false,
            };
        case GET_ALL_TASKS_ERROR:
            return {
                ...state,
                error: payload,
                loadSignal: false,
            };
        case GET_PROGRESS_EXPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_PROGRESS_EXPORT_SUCCESS:
            return {
                ...state,
                progressExport: payload,
                progress: false,
            };
        case BFF_RECRUITMENT_VACANCY_IMPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_RECRUITMENT_VACANCY_IMPORT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_IMPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getAllTasks(payload) {
    return {
        type: GET_ALL_TASKS_REQUEST,
        payload,
    };
}

export function closeOrders(payload) {
    return {
        type: CLOSE_ORDERS_REQUEST,
        payload,
    };
}

export function setInvisible(payload) {
    return {
        type: SET_INVISIBLE_TASK_REQUEST,
        payload,
    };
}

export function updateJobFieldStore(payload) {
    return {
        type: UPDATE_JOB_FILED_STORE,
        payload,
    };
}

export function addBankDepositExport(payload) {
    return {
        type: ADD_BANK_DEPOSIT_EXPORT_REQUEST,
        payload,
    };
}

export function getProgressExport(payload) {
    return {
        type: GET_PROGRESS_EXPORT_REQUEST,
        payload,
    };
}

export function archiveOrders(payload) {
    return {
        type: ARCHIVE_ORDERS_REQUEST,
        payload,
    };
}

export function deleteOrders(payload) {
    return {
        type: DELETE_ORDERS_REQUEST,
        payload,
    };
}

export function publishOrders(payload) {
    return {
        type: PUBLISH_ORDERS_REQUEST,
        payload,
    };
}

export function publishOrdersInWorkStatus(payload) {
    return {
        type: PUBLISH_ORDERS_IN_WORK_STATUS_REQUEST,
        payload,
    };
}

export function ordersEndContractorSearch(payload) {
    return {
        type: ORDERS_END_CONTRACTOR_SEARCH_REQUEST,
        payload,
    };
}

export function addPumpingOutDepositReceiptPlanFromGoogleDisk(payload) {
    return {
        type: ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_REQUEST,
        payload,
    };
}

export function addTaskAnalyseLocation(payload) {
    return {
        type: ADD_TASK_ANALYSE_LOCATION_REQUEST,
        payload,
    };
}

export function cancelExportById(payload) {
    return {
        type: CANCEL_EXPORT_BY_ID_REQUEST,
        payload,
    };
}

export function cancelTaskById(payload) {
    return {
        type: CANCEL_TASK_BY_ID_REQUEST,
        payload,
    };
}

export const importRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_IMPORT_REQUEST,
        payload,
    };
};

export function rejectOrderPayments(payload) {
    return {
        type: JOB_REJECT_ORDER_PAYMENT_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//
export const jobSelector = state => state.job;
export const jobTasksNotEmptySelector = createSelector(jobSelector, ({tasks}) => {
    try {
        return !!tasks.find(({status}) => status === null ||
            [
                TASK_STATUS.IN_PROGRESS.VALUE,
                TASK_STATUS.CONTINUATION.VALUE,
                TASK_STATUS.QUEUED.VALUE,
            ].includes(status));
    } catch (e) {
        console.error(e);
        return [];
    }
});
export const tasksImportClientGroupSelector = createSelector(jobSelector, ({tasks}) => getTasksByType(tasks, [TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE]));
export const tasksImportContractorsGroupSelector = createSelector(jobSelector, ({tasks}) => getTasksByType(tasks, [TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE]));
export const tasksImportGroupSelector = createSelector(jobSelector, ({tasks}) => getTasksByType(tasks, [TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE, TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE]));
export const jobTasksOrderCloseInfoSelector = createSelector(jobSelector, ({tasks}) => filterTaskByTypes(tasks, [TASK_TYPE.ORDERS_CLOSING.VALUE]));
export const jobTasksPaymentsInfoSelector = createSelector(jobSelector, ({tasks}) => getTasksInProgress(tasks, [
    TASK_TYPE.ORDER_PAYMENTS_PAYMENT.VALUE,
    TASK_TYPE.ORDER_PAYMENTS_CONFIRMATION.VALUE,
    TASK_TYPE.ORDER_REJECTED_PAYMENT.VALUE,
]));
// Невыполненные таски для реестров при удалении строк, для апдейта строк реестра по завершению тасков
export const jobTasksRegistryDiscardPaymentsSelector = createSelector(jobSelector, ({tasks}) => getTasksRegistryDiscardPayments(tasks));

export const jobTasksAdvertisementSelector = createSelector(jobSelector, ({tasks}) => getTasksAdvertisement(tasks));

export const jobTaskLoadSignalSelector = createSelector(jobSelector, ({loadSignal}) => loadSignal);

export const progressExportDataSelector = createSelector(jobSelector, ({progressExport}) => progressExport);
export const progressSelector = createSelector(jobSelector, ({progress}) => progress);
export const jobTasksOrdersImportClosingSelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.IMPORT_ORDERS.VALUE, TASK_TYPE.ORDERS_CLOSING.VALUE].includes(item.type) &&
    item.status !== TASK_STATUS.COMPLETED.VALUE,
));

export const jobTasksRegistryPaymentsImportSelector = createSelector(jobSelector, ({tasks}) => getTasksRegistryPaymentsImport(tasks));

// Таски в процессе по завершению/обновлению заказа, расторжению РД
export const jobTasksContractorStatusProcessingSelector = createSelector(jobSelector, ({tasks}) => getTasksInProgress(tasks, [TASK_TYPE.CONTRACTOR_STATUS_PROCESSING.VALUE]));

export const jobTasksOrderMassActionProcessingSelector = createSelector(jobSelector, ({tasks}) => getTasksInProgress(tasks, [
    // Таски в процессе по публикации заказов
    TASK_TYPE.ORDERS_DRAFT_POSTING.VALUE,
    // Таски в процессе по завершению/обновлению заказа, расторжению РД
    TASK_TYPE.CONTRACTOR_STATUS_PROCESSING.VALUE,
    // Таски в процессе по удалению черновиков заказов
    TASK_TYPE.ORDERS_DRAFT_DELETING.VALUE,
    // Таски в процеессе по архивированию заказов
    TASK_TYPE.ORDERS_ARCHIVING.VALUE,
    // Таски в процессе по публикации заказов в статус "В работе"
    TASK_TYPE.ORDERS_PUBLISH_IN_WORK.VALUE,
],
));

export const jobProgressPublishInWorkStatusSelector = createSelector(jobSelector, ({progressPublishInWorkStatus}) => progressPublishInWorkStatus);
export const progressOrdersMassActionSelector = createSelector(jobSelector, ({progressOrdersMassAction}) => progressOrdersMassAction);

export const jobTasksImportRegistryInvitationsSelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.IMPORT_REGISTRY_INVITATION_CONTRACTOR.VALUE].includes(item.type) &&
    item.status !== TASK_STATUS.COMPLETED.VALUE,
));

export const jobTasksImportEdoRegistrySelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.IMPORT_EDO_DOCUMENTS_REGISTRY_ITEMS.VALUE, TASK_TYPE.EDO_DOCUMENTS_REGISTRY_UPDATE_RECEIVERS_DATA.VALUE].includes(item.type) &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

export const jobTasksActRegistrySelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.ACT_REGISTRY_PAYMENT_IMPORT.VALUE, TASK_TYPE.PAY_ACT_REGISTRY_PAYMENTS.VALUE].includes(item.type) &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

export const jobTasksCrowdActRegistrySelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    item.type === TASK_TYPE.CROWD_ACT_REGISTRY_IMPORT.VALUE &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

export const jobTasksAnalysisLocationSelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.ANALYSIS_CONTRACTOR_LOCATION.VALUE].includes(item.type) &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

export const jobTasksImportForbiddenInnListSelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.IMPORT_FORBIDDEN_INN_CLIENT.VALUE].includes(item.type) &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

export const jobTasksCancelNotificationSelector = createSelector(jobSelector, ({tasks}) => tasks.filter(item =>
    [TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE].includes(item.type) &&
    ![TASK_STATUS.COMPLETED.VALUE, TASK_STATUS.ERROR.VALUE].includes(item.status),
));

//*  SAGA  *//

//GET /api/job/creatorTasks/getAll
export const getAllTasksSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/creatorTasks/getAll`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: GET_ALL_TASKS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ALL_TASKS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message || "Произошла внутренняя ошибка - попробуйте повторить операцию позднее.");
        yield put({type: GET_ALL_TASKS_ERROR, payload: error.message});
    }
};

//POST /api/job/orders/closeOrders
export const closeOrdersSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/orders/closeOrders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CLOSE_ORDERS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.CLOSE_ORDERS.SUCCESS);
        yield put({type: CLOSE_ORDERS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);
        yield put({type: CLOSE_ORDERS_ERROR, payload: error.message});
    }
};

//POST /api/job/creatorTasks/setInvisible/{taskId}
export const setInvisibleSaga = function* (action) {
    try {
        const {
            payload: {
                data: {
                    taskId,
                },
                handleResponse,
            },
        } = action;
        const result = yield request.post(`${controller}/creatorTasks/setInvisible/${taskId}`);
        const {errorMessage} = result;

        handleResponse(result);

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: SET_INVISIBLE_TASK_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({type: SET_INVISIBLE_TASK_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);
        yield put({type: SET_INVISIBLE_TASK_ERROR, payload: error.message});
    }
};

//POST /bff/adm/finances/bank/addBankDepositExport
export const addBankDepositExportSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse,
                ...data
            },
        } = action;

        const result = yield request.bff.post("/adm/finances/bank/addBankDepositExport", data);

        handleResponse(result);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: ADD_BANK_DEPOSIT_EXPORT_ERROR, payload: errorMessage});
        } else {
            yield put({type: ADD_BANK_DEPOSIT_EXPORT_SUCCESS, payload: result});
        }
    } catch (error) {
        yield put({type: ADD_BANK_DEPOSIT_EXPORT_ERROR, payload: error.message});
    }
};

//POST /api/job/export/getByIds
export const getProgressExportSaga = function* ({payload}) {
    try {
        const {
            reqData,
            handleResponse = () => {
            },
        } = payload;
        const result = yield request.post(`${controller}/export/getByIds`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_PROGRESS_EXPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const tasksCompleted = result.filter(item => [
            TASK_STATUS.ERROR.VALUE,
            TASK_STATUS.COMPLETED.VALUE,
        ].includes(item.status));

        if (tasksCompleted.length !== 0) {
            handleResponse();
        }

        yield put({type: GET_PROGRESS_EXPORT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PROGRESS_EXPORT_ERROR, payload: error.message});
    }
};

//POST /api/job/orders/archiveOrders
export const archiveOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;
        const result = yield request.post(`${controller}/orders/archiveOrders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ARCHIVE_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.ARCHIVE_CLOSED_ORDERS.SUCCESS);
        yield put({type: ARCHIVE_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: ARCHIVE_ORDERS_ERROR});
    }
};

//POST /api/job/orders/archiveOrders
export const deleteOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;
        const result = yield request.post(`${controller}/orders/deleteOrders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.DELETE_DRAFTS.SUCCESS);
        yield put({type: DELETE_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: DELETE_ORDERS_ERROR});
    }
};

//POST /api/job/orders/publishOrders
export const publishOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;
        const result = yield request.post(`${controller}/orders/publishOrders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PUBLISH_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.PUBLISH_ORDERS.SUCCESS);
        yield put({type: PUBLISH_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: PUBLISH_ORDERS_ERROR});
    }
};

//POST /api/job/namemix13173/addTask - задача перекачивания таблицы план поступления депозитов из гуглдиска
export const addPumpingOutDepositReceiptPlanFromGoogleDiskSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/namemix13173/addTask`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            payload.onError();
            yield put({type: ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_ERROR});

            return {
                done: true,
            };
        }

        payload.onSuccess();

        toastSuccess("Задача по перекачиванию таблицы \"План поступления депозитов\" из GoogleDisk успешно запущена");
        yield put({type: ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_SUCCESS});
    } catch (error) {
        payload.onError();
        yield put({type: ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_ERROR});
    }
};

//POST /api/job/orders/publishOrdersInWorkStatus
export const publishOrdersInWorkStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;
        const result = yield request.post(`${controller}/orders/publishOrdersInWorkStatus`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_ERROR});

            return {
                done: true,
            };
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.PUBLISH_ORDERS.SUCCESS);
        onSuccess();

        yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_SUCCESS});
    } catch (error) {
        yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_ERROR});
    }
};

//POST /api/job/orders/ordersEndContractorSearch
export const ordersEndContractorSearchSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;
        const result = yield request.post(`${controller}/orders/ordersEndContractorSearch`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_ERROR});

            return {
                done: true,
            };
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.COMPLETE_SELECTION.SUCCESS);
        onSuccess();

        yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_SUCCESS});
    } catch (error) {
        yield put({type: PUBLISH_ORDERS_IN_WORK_STATUS_ERROR});
    }
};

//POST /api/job/analyselocation/addTask
export const addTaskAnalyseLocationSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;
        const result = yield request.post(`${controller}/analyselocation/addTask`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_TASK_ANALYSE_LOCATION_ERROR});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: ADD_TASK_ANALYSE_LOCATION_SUCCESS});
    } catch (error) {
        yield put({type: ADD_TASK_ANALYSE_LOCATION_ERROR});
    }
};

//POST /api/job/export/cancelExportById
export const cancelExportByIdSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/export/cancelExportById`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CANCEL_EXPORT_BY_ID_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CANCEL_EXPORT_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);
        yield put({type: CANCEL_EXPORT_BY_ID_ERROR, payload: error.message});
    }
};

//POST /api/job/SingleIdempotent/cancelTaskById
export const cancelTaskByIdSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            taskId,
        } = payload;

        const result = yield request.post(`${controller}/SingleIdempotent/cancelTaskById?taskId=${taskId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CANCEL_TASK_BY_ID_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CANCEL_TASK_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);
        yield put({type: CANCEL_TASK_BY_ID_ERROR, payload: error.message});
    }
};

//POST /api/job/vacancies/import
// Импортировать вакансии из файла
const importRecruitmentVacancySaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/vacancies/import`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_IMPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_IMPORT_ERROR,
        });
    }
};

// POST /api/job/orderpayments/rejectedOrderPayments
// Отмена оплат
export const rejectPaymentsSaga = function* (action) {
    const {payload} = action;

    try {
        const result = yield request.post(`${controller}/orderpayments/rejectedOrderPayments`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: JOB_REJECT_ORDER_PAYMENT_ERROR, payload: {errorMessage}});

            return {
                done: true,
            };
        }

        toastSuccess(ORDER_WORK_REPORT.PAYMENT_REJECT.SUCCESS);

        yield put({type: JOB_REJECT_ORDER_PAYMENT_SUCCESS});

        const state = yield select();

        yield put(getPageFinancesPayments(state.financePayments.pageData));

    } catch (error) {
        toastError(ORDER_WORK_REPORT.PAYMENT_REJECT.SIMPLE_ERROR);
        yield put({
            type: JOB_REJECT_ORDER_PAYMENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ALL_TASKS_REQUEST, getAllTasksSaga),
        takeEvery(CLOSE_ORDERS_REQUEST, closeOrdersSaga),
        takeEvery(SET_INVISIBLE_TASK_REQUEST, setInvisibleSaga),
        takeEvery(ADD_BANK_DEPOSIT_EXPORT_REQUEST, addBankDepositExportSaga),
        takeEvery(GET_PROGRESS_EXPORT_REQUEST, getProgressExportSaga),
        takeEvery(ARCHIVE_ORDERS_REQUEST, archiveOrdersSaga),
        takeEvery(DELETE_ORDERS_REQUEST, deleteOrdersSaga),
        takeEvery(PUBLISH_ORDERS_REQUEST, publishOrdersSaga),
        takeEvery(ADD_PUMPING_OUT_DEPOSIT_RECEIPT_PLAN_FROM_GOOGLE_DISK_REQUEST, addPumpingOutDepositReceiptPlanFromGoogleDiskSaga),
        takeEvery(PUBLISH_ORDERS_IN_WORK_STATUS_REQUEST, publishOrdersInWorkStatusSaga),
        takeEvery(ORDERS_END_CONTRACTOR_SEARCH_REQUEST, ordersEndContractorSearchSaga),
        takeEvery(ADD_TASK_ANALYSE_LOCATION_REQUEST, addTaskAnalyseLocationSaga),
        takeEvery(CANCEL_EXPORT_BY_ID_REQUEST, cancelExportByIdSaga),
        takeEvery(CANCEL_TASK_BY_ID_REQUEST, cancelTaskByIdSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_IMPORT_REQUEST, importRecruitmentVacancySaga),
        takeEvery(JOB_REJECT_ORDER_PAYMENT_REQUEST, rejectPaymentsSaga),
    ]);
}