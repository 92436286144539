import {
    BFF_EXPORT_REPORTS_ADD_ERROR,
    BFF_EXPORT_REPORTS_ADD_REQUEST,
    BFF_EXPORT_REPORTS_ADD_SUCCESS,
    BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS,
    BFF_EXPORT_REPORTS_GET_PAGE_ERROR,
    BFF_EXPORT_REPORTS_GET_PAGE_REQUEST,
    BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS,
    UPDATE_EXPORT_REPORTS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    edoAccessList: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_EXPORT_REPORTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_EXPORT_REPORTS_GET_PAGE_SUCCESS:
            const {
                financesModels = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: financesModels,
                totalCount,
            };
        case BFF_EXPORT_REPORTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_EXPORT_REPORTS_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_EXPORT_REPORTS_ADD_SUCCESS:
        case BFF_EXPORT_REPORTS_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_EXPORT_REPORTS_GET_EDO_ACCESS_LIST_SUCCESS:
            return {
                ...state,
                edoAccessList: payload || [],
            };
        case UPDATE_EXPORT_REPORTS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};