import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const clientDepositReplenishmentAndRefundsSelector = state => state.bff.clientDepositReplenishmentAndRefunds;
export const clientDepositReplenishmentAndRefundsPageDataSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({pageData}) => pageData);
export const clientDepositReplenishmentAndRefundsTotalPagesSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const clientDepositReplenishmentAndRefundsTotalCountSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({totalCount}) => totalCount);
export const clientDepositReplenishmentAndRefundsListSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({list}) => list);
export const clientDepositReplenishmentAndRefundsProgressSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({progress}) => progress);
export const clientDepositReplenishmentAndRefundsProgressActionSelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({progressAction}) => progressAction);
export const clientDepositReplenishmentAndRefundsTotalAddedAmountelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({totalAddedAmount}) => totalAddedAmount);
export const clientDepositReplenishmentAndRefundsTotalReturnedAmountelector = createSelector(clientDepositReplenishmentAndRefundsSelector, ({totalReturnedAmount}) => totalReturnedAmount);