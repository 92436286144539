import {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {
    getClientUserRestrictionsByRole,
} from "../../../../../../ducks/clientUserRestrictions";
import {
    addSettingsUsersRole,
    updateSettingsUsersRole,
} from "../../../../../../ducks/settingsUsersRoles";

const useUsersRolesEditFormData = ({card, isEdit, fetchList, subPage, onClose}) => {
    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        return {
            name: card.name || "",
            description: card.description || "",
            restrictions: card.restrictions?.map(item => item?.variable) || [],
        };
    }, [card]);

    useEffect(() => {
        dispatch(getClientUserRestrictionsByRole({
            role: subPage,
        }));
    }, []);

    const handleChange = (e, {name, value}) => setFieldValue(name, value);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const onSuccess = () => {
            fetchList();
            onClose();
        };

        const {
            name,
            description,
            restrictions,
        } = values;

        const reqData = {
            name,
            description,
            restrictions,
        };

        if (isEdit) {
            dispatch(updateSettingsUsersRole({
                id: card.id,
                type: subPage,
                ...reqData,
                onSuccess,
            }));

            return;
        }

        dispatch(addSettingsUsersRole({
            type: subPage,
            ...reqData,
            onSuccess,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
    };
};

export default useUsersRolesEditFormData;