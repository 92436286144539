import {
    CLEAR_FIELD_DIRECTORIES_DOCUMENTS,
    GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR,
    GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST,
    GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    progressPage: false,
    totalCount: 0,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_DIRECTORIES_DOCUMENTS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressPage: true,
            };
        case GET_PAGE_DIRECTORIES_DOCUMENTS_SUCCESS:
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                progressPage: false,
                totalCount,
                list: results,
            };
        case GET_PAGE_DIRECTORIES_DOCUMENTS_ERROR:
            return {
                ...state,
                progressPage: false,
                error: payload,
            };
        case CLEAR_FIELD_DIRECTORIES_DOCUMENTS:
            return initial;
        default:
            return state;
    }
};