import {
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE,
} from "./actions";

const initial = {
    list: [],
    listLoading: false,
    listTotalCount: 0,
    listPageData: {},
    progressAction: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST:
            return {
                ...state,
                listLoading: true,
                listPageData: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS:
            return {
                ...state,
                listLoading: false,
                list: payload.results,
                listTotalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR:
            return {
                ...state,
                listLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE:
            return initial;
        default: return state;
    }
};