import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {
    DELETE_EMPTY_RECORD_ERROR,
    DELETE_EMPTY_RECORD_REQUEST,
    DELETE_EMPTY_RECORD_SUCCESS,
    GET_EMPTY_RECORD_ERROR,
    GET_EMPTY_RECORD_REQUEST,
    GET_EMPTY_RECORD_SUCCESS,
    MAINTENANCE_NOTICE_PUBLISHED_ERROR,
    MAINTENANCE_NOTICE_PUBLISHED_REQUEST,
    MAINTENANCE_NOTICE_PUBLISHED_SUCCESS,
    NDFL_DEPOSIT_CORRECT_ERROR,
    NDFL_DEPOSIT_CORRECT_REQUEST,
    NDFL_DEPOSIT_CORRECT_SUCCESS,
    NPD_DEPOSIT_CORRECT_ERROR,
    NPD_DEPOSIT_CORRECT_REQUEST,
    NPD_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS,
    SYSTEM_SETTING_GET_ERROR,
    SYSTEM_SETTING_GET_REQUEST,
    SYSTEM_SETTING_GET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS,
    SYSTEM_SETTING_SET_ERROR,
    SYSTEM_SETTING_SET_REQUEST,
    SYSTEM_SETTING_SET_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "/adm/settings/service/settings";

const getMaintenanceNoticeSettings = function* ({payload}) {
    try {
        const {getResult} = payload;

        const result = yield request.bff.get(`${controller}/maintenance-notice/get`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR, payload: error});
    }
};

const setMaintenanceNoticeSettings = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/maintenance-notice/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS});
        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_REQUEST});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR, payload: error});
    }
};

const getMaintenanceNoticePublished = function* () {
    try {
        const result = yield call(axios, {
            url: "/nmapi/public/isMaintenanceNoticePublished",
            method: "get",
        });

        const {errorMessage} = result.data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MAINTENANCE_NOTICE_PUBLISHED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_SUCCESS, payload: result.data});
    } catch (error) {
        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_ERROR, payload: error});
    }
};

const setSystemSetting = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/system-setting/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SYSTEM_SETTING_SET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_SET_ERROR, payload: error});
    }
};

const setExpasoftCollingSetting = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/expasoft-calling/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR, payload: error});
    }
};

const getSystemSetting = function* ({payload}) {
    try {
        const {getResult, key} = payload;

        const result = yield request.bff.get(`${controller}/system-setting/get`, {params: {key}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: SYSTEM_SETTING_GET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_GET_ERROR, payload: error});
    }
};

const getEmptyRecord = function* ({payload}) {
    try {
        const {
            contractorPhone,
            getResult,
        } = payload;
        const result = yield request.bff.get(`${controller}/contractors/get-empty-record`, {params: {contractorPhone}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

const deleteEmptyRecord = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${controller}/contractors/delete-empty-record?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

const npdDepositCorrect = function* ({payload}) {
    try {
        const {
            clientId,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${controller}/client-deposit/correct/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: NPD_DEPOSIT_CORRECT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: NPD_DEPOSIT_CORRECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: NPD_DEPOSIT_CORRECT_ERROR,
            payload: error,
        });
    }
};

const ndflDepositCorrect = function* ({payload}) {
    try {
        const {
            clientId,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${controller}/client-civil-deposit/correct/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: NDFL_DEPOSIT_CORRECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: NDFL_DEPOSIT_CORRECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: NDFL_DEPOSIT_CORRECT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST, getMaintenanceNoticeSettings),
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST, setMaintenanceNoticeSettings),
        takeEvery(MAINTENANCE_NOTICE_PUBLISHED_REQUEST, getMaintenanceNoticePublished),
        takeEvery(SYSTEM_SETTING_SET_REQUEST, setSystemSetting),
        takeEvery(SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST, setExpasoftCollingSetting),
        takeEvery(SYSTEM_SETTING_GET_REQUEST, getSystemSetting),
        takeEvery(GET_EMPTY_RECORD_REQUEST, getEmptyRecord),
        takeEvery(DELETE_EMPTY_RECORD_REQUEST, deleteEmptyRecord),
        takeEvery(NPD_DEPOSIT_CORRECT_REQUEST, npdDepositCorrect),
        takeEvery(NDFL_DEPOSIT_CORRECT_REQUEST, ndflDepositCorrect),
    ]);
}