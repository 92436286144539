import {
    addClientBrand,
    addClientBrandLogo,
    deleteClientBrandLogo,
    getAssignBrandsObjectsPage,
    getAssignBrandsProjectsObjectsList,
    getAssignBrandsProjectsPage,
    getClientBrands,
    getLogsBrandChange,
    saveAssignBrandsObjects,
    updateClientBrand,
    updateClientBrandArchiveStatus,
} from "../../../../client/brands/actionCreators";

const controller = "/adm/clients/client-card/client/brands";

export const updateCompaniesBrand = (payload) => {
    return updateClientBrand({
        url: `${controller}/update`,
        ...payload,
    });
};

export const updateCompaniesArchiveStatus = (payload) => {
    return updateClientBrandArchiveStatus({
        url: `${controller}/updateArchiveStatus`,
        ...payload,
    });
};

export const getCompaniesBrandsClient = (payload) => {
    return getClientBrands({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const deleteCompaniesBrandLogo = (payload) => {
    return deleteClientBrandLogo({
        url: `${controller}/deleteLogo`,
        ...payload,
    });
};

export const addCompaniesBrand = (payload) => {
    return addClientBrand({
        url: `${controller}/add`,
        ...payload,
    });
};

export const getAdmAssignBrandsProjectsPage = (payload) => {
    return getAssignBrandsProjectsPage({
        url: `${controller}/assignBrandsProjectsPage`,
        ...payload,
    });
};

export const getAdmAssignBrandsObjectsPage = (payload) => {
    return getAssignBrandsObjectsPage({
        url: `${controller}/assignBrandsObjectsPage`,
        ...payload,
    });
};

export const saveAdmAssignBrandsObjects = (payload) => {
    return saveAssignBrandsObjects({
        url: `${controller}/saveAssignBrandsObjects`,
        ...payload,
    });
};

export const getAdmLogsBrandChange = (payload) => {
    return getLogsBrandChange({
        url: `${controller}/getLogsBrandChange`,
        ...payload,
    });
};

export const getAdmAssignBrandsProjectsObjectsList = (payload) => {
    return getAssignBrandsProjectsObjectsList({
        url: `${controller}/assignBrandsProjectsObjectsList`,
        ...payload,
    });
};

export const addCompaniesBrandLogo = (payload) => {
    return addClientBrandLogo({
        url: `${controller}/addLogo`,
        ...payload,
    });
};