import {all, put} from "@redux-saga/core/effects";
import {takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/contractors/contractors-registry/contractor-card/finances/npd-income/orders";

//*  TYPES  *//

const CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_ERROR";

const CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_ERROR";

const CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_ERROR";

const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_REQUEST = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_REQUEST";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_SUCCESS = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_SUCCESS";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_ERROR = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_ERROR";

const CONTRACTOR_FINANCE_INCOME_NPD_UPDATE_STORE = "CONTRACTOR_FINANCE_INCOME_NPD_UPDATE_STORE";

//*  INITIAL STATE  *//
const initial = {
    list: [],
    totalCount: 0,
    progress: false,
    pageData: {},
    contractorBalance: {},
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_REQUEST:
        case CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_REQUEST:
        case CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_SUCCESS:
        case CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_SUCCESS:
        case CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_SUCCESS:
            return {
                ...state,
                list: payload.results,
                progress: false,
                totalCount: payload.totalCount,
            };
        case CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_ERROR:
        case CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_ERROR:
        case CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_SUCCESS:
            return {
                ...state,
                contractorBalance: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorInProgressFinanceNpdPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorAwaitingFinanceNpdPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorCompletedFinanceNpdPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_REQUEST,
        payload,
    };
}

export function getSummaryContractorFinanceIncomeNpd(payload) {
    return {
        type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_REQUEST,
        payload,
    };
}

export function updateContractorFinanceIncomeNpdStore(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NPD_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorFinanceIncomeNpdSelector = state => state.contractorFinanceIncomeNpd;
export const contractorFinanceIncomeNpdProgressSelector = createSelector(contractorFinanceIncomeNpdSelector, ({progress}) => progress);
export const contractorFinanceIncomeNpdListSelector = createSelector(contractorFinanceIncomeNpdSelector, ({list}) => list);
export const contractorFinanceIncomeNpdTotalCountSelector = createSelector(contractorFinanceIncomeNpdSelector, ({totalCount}) => totalCount);
export const contractorFinanceIncomeNpdTotalPagesSelector = createSelector(contractorFinanceIncomeNpdSelector, ({totalCount, pageData: {pageSize = 0}}) => {
    return getTotalPages(totalCount, pageSize);
});
export const contractorSummaryFinanceIncomeNpdSelector = createSelector(contractorFinanceIncomeNpdSelector, ({contractorBalance}) => contractorBalance);

//POST bff/adm/contractors-registry/contractor/finance/income-NPD/in-progress/page
export const getContractorInProgressFinanceNpdPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/in-progress/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-NPD/awaiting/page
export const getContractorAwaitingFinanceNpdPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/awaiting/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-NPD/completed/page
export const getContractorCompletedFinanceNpdPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/completed/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/contractors-registry/contractor/finance/income-npd/get-summary/{contractorId}
export const getSummaryContractorFinanceIncomeNpdSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/get-summary/${contractorId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_FINANCE_INCOME_NPD_IN_PROGRESS_REQUEST, getContractorInProgressFinanceNpdPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_NPD_AWAITING_PROGRESS_REQUEST, getContractorAwaitingFinanceNpdPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_NPD_COMPLETED_REQUEST, getContractorCompletedFinanceNpdPaymentsSaga),
        takeEvery(GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NPD_REQUEST, getSummaryContractorFinanceIncomeNpdSaga),
    ]);
}
