export const BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST = "BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_REQUEST";
export const BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS = "BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_SUCCESS";
export const BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR = "BFF_CLIENT_REPORTS_ONE_C_GET_PAGE_ERROR";

export const BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST = "BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_REQUEST";
export const BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS = "BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_SUCCESS";
export const BFF_CLIENT_REPORTS_ONE_C_GET_TASKS_IDS_ERROR = "BFF_CLIENT_REPORTS_ONE_C_GET_PROGRESS_IDS_ERROR";

export const BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST = "BFF_CLIENT_REPORTS_ONE_C_ADD_REQUEST";
export const BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS = "BFF_CLIENT_REPORTS_ONE_C_ADD_SUCCESS";
export const BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR = "BFF_CLIENT_REPORTS_ONE_C_ADD_ERROR";

export const BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST = "BFF_CLIENT_REPORTS_ONE_C_CANCEL_REQUEST";
export const BFF_CLIENT_REPORTS_ONE_C_CANCEL_SUCCESS = "BFF_CLIENT_REPORTS_ONE_C_CANCEL_SUCCESS";
export const BFF_CLIENT_REPORTS_ONE_C_CANCEL_ERROR = "BFF_CLIENT_REPORTS_ONE_C_CANCEL_ERROR";

export const CLEAR_CLIENT_REPORTS_ONE_C_STORE = "CLEAR_CLIENT_REPORTS_ONE_C_STORE";