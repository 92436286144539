import * as yup from "yup";

import {validateInnWithYup} from "../../../utils/validate";

import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_STATUS} from "../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_COMPONENT_TYPE, FULL_REGISTRATION_STEP} from "../constants";

export const getFullRegistrationInnStep = (params) => {
    const {
        isRequiredFields,
        contractor,
    } = params;

    return {
        value: FULL_REGISTRATION_STEP.INN,
        modalTitle: "Регистрация",
        validation: (values) => {
            function getInnValidation () {
                if (contractor.fakeInn && contractor.inn === values.inn) {
                    return undefined;
                }

                return yup.string().test("inn", "Неверный формат ИНН", validateInnWithYup);
            }

            if (values.hasInn) {
                return yup.object().shape({
                    inn: getInnValidation(),
                    [CONTRACTOR_FILE_TYPES.INN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            }

            return yup.object().shape({
                inn: getInnValidation(),
            });
        },
        fields: (values) => {
            const isDisabledInput = [
                CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED,
                CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
            ].includes(contractor.registrationStatus) && !contractor.fakeInn;

            if (values.hasInn) {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        name: "inn",
                        label: "ИНН",
                        required: true,
                        maskChar: null,
                        mask: "999999999999",
                        placeholder: "Введите ИНН",
                        disabled: isDisabledInput,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX,
                        label: "Лист с ИНН присутствует",
                        name: "hasInn",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.INN,
                        label: "Фотография листа с ИНН",
                    },
                ];
            }

            return [
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                    name: "inn",
                    label: "ИНН",
                    maskChar: null,
                    mask: "999999999999",
                    placeholder: "Введите ИНН",
                    disabled: isDisabledInput,
                },
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX,
                    label: "Лист с ИНН присутствует",
                    name: "hasInn",
                },
            ];
        },
    };
};