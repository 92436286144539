import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
    BFF_CROWD_ACT_REGISTRY_RUN_REQUEST,
    BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/actregistry",
        client: "/client-adm/crowd-tasks/actregistry",
    });
};

const getPageCrowdActRegisty = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
        });
    }
};

const createCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/create`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
        });
    }
};

const editCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/edit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
        });
    }
};

const archiveCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
        });
    }
};

const unarchiveCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/unarchive/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
        });
    }
};

const getCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
        });
    }
};

const deleteCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
        });
    }
};

const runCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${getController()}/run/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
        });
    }
};

const getPageCrowdActRegistryLog = function* ({payload}) {
    try {
        const controller = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/crowd-tasks/actregistry/actregistryevent",
            client: "/client-adm/crowd-tasks/actregistry/actregistryevent",
        });

        const result = yield request.bff.post(`${controller}/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST, getPageCrowdActRegisty),
        takeEvery(BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST, createCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST, editCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST, archiveCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST, unarchiveCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST, getCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST, deleteCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_RUN_REQUEST, runCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST, getPageCrowdActRegistryLog),
    ]);
}