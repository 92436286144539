import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR,
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST,
    BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_GET_MONITORING_RISKS_ERROR,
    BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS,
    BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR, BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST,
    BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const controllers = {
    client: "/client-adm/reports/monitoring-risk",
    admin: "/adm/clients/client-card/reports/monitoring-risk",
};

//GET /client-adm/reports/monitoring-risk/getRisks
//Получение списка рисков
const getClientsMonitoringRisks = function* ({payload}) {
    const {
        clientId,
    } = payload;

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}/getRisks`,
            adminRolesUrl: `${controllers.admin}/getRisks?clientId=${clientId}`,
        },
    );

    try {
        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_MONITORING_RISKS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_MONITORING_RISKS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_MONITORING_RISKS_ERROR,
        });
    }
};

//POST /bff/client-adm/reports/monitoring-risk/getPageContractor
//Получение списка пользователей для риска
const getClientsMonitoringRisksContractors = function* ({payload}) {
    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}/getPageContractor`,
            adminRolesUrl: `${controllers.admin}/getPageContractor`,
        },
    );

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_ERROR,
        });
    }
};

//POST /bff/client-adm/reports/monitoring-risk/generateReport
// Формирование отчета
const generateClientsMonitoringRisksReport = function* ({payload}) {
    const {
        onSuccess,
        onError,
    } = payload;

    try {
        const result = yield request.bff.post(`${controllers.client}/generateReport`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            if (onError) {
                onError({errorMessage});
            }

            yield put({
                type: BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_ERROR,
        });
    }
};

//POST /bff/adm/clients/client/reports/monitoring-risk/updateData/
// Обновление данных
const updateClientsMonitoringRisksData = function* ({payload}) {
    const {
        clientId,
        onSuccess,
    } = payload;

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}/updateData?clientId=${clientId}`,
            adminRolesUrl: `${controllers.admin}/updateData/${clientId}`,
        },
    );

    try {
        const result = yield request.bff.put(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST, getClientsMonitoringRisks),
        takeEvery(BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST, getClientsMonitoringRisksContractors),
        takeEvery(BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST, generateClientsMonitoringRisksReport),
        takeEvery(BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST, updateClientsMonitoringRisksData),
    ]);
}