import React from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderHistoryPopup from "../order-history-popup";

import {
    getOrderHistory,
    getOrderHistorySelector,
    orderHistoryTotalCountSelector,
    orderHistoryTotalPagesSelector,
    updateFieldOrderStore,
} from "../../../ducks/order";

const ClientCardOrderHistoryPopup = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(getOrderHistorySelector);
    const totalCount = useSelector(orderHistoryTotalCountSelector);
    const totalPages = useSelector(orderHistoryTotalPagesSelector);

    return (
        <OrderHistoryPopup
            {...props}
            events={list}
            totalCount={totalCount}
            totalPages={totalPages}
            onFetchList={(data) => {
                dispatch(getOrderHistory(data));
            }}
            onClearStore={() => {
                dispatch(updateFieldOrderStore("events", []));
                dispatch(updateFieldOrderStore("historyPageData", {
                    pageNum: 1,
                    pageSize: 25,
                    totalCount: 0,
                }));
            }}
        />
    );
};

export default ClientCardOrderHistoryPopup;