import {useState} from "react";
import {useDispatch} from "react-redux";

import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {
    massApproveCrowdPayment,
    massRejectCrowdPayment,
    massRevalidateCrowdPayment,
    rejectCrowdPayment,
    repeatCrowdPayment,
} from "../../../../ducks/bff/crowd/payment/actionCreators";
import {downloadDocument} from "../../../../ducks/documents";

import {STATUS_TASK_PAYMENT} from "../../../../constants/crowd/task-payment";

const useFinanceCrowdPaymentsAction = (props) => {
    const {
        selectedList,
        clearSelectedRows,
        fetchList,
        clientId,
        isActionsCrowdPayments,
    } = props;

    const dispatch = useDispatch();

    const [rejectReasonModalData, setRejectReasonModal] = useState({});
    const [logModalData, setLogModalData] = useState({});

    const selectedPayments = selectedList.filter(item => item.isSelected);
    const selectedPaymentNumbers = selectedPayments.map(item => item.paymentNumber);
    const selectedPaymentIds = selectedPayments.map(item => item.paymentId);

    const role = ls(USER_ROLE);

    const getListMediaControls = (item) => {
        if (
            (![ADMIN].includes(role) && !isClientUser(role))
            || !isActionsCrowdPayments
        ) {
            return null;
        }

        const isAccessAction = [
            ADMIN,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            FOREMAN,
        ].includes(role);
        const isAccessDocumentView = [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            NM_OPERATOR,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            FOREMAN,
        ].includes(role);

        const {
            paymentNumber,
            paymentId,
            linkFNS,
            applicationDocumentFileLink,
            contractDocumentFileLink,
            status,
        } = item;

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Лог оплаты по заданию",
                        onClick: () => setLogModalData(item),
                    },
                    visible: isAccessDocumentView,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Чек ФНС России",
                        onClick: () => {
                            openLinkByUrl(linkFNS);
                        },
                    },
                    visible: Boolean(linkFNS) && isAccessDocumentView,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Повторить платеж",
                        onClick: () => repeatPayment(item),
                    },
                    visible: (
                        [STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE].includes(status) &&
                            isAccessAction
                    )
                        || (
                            [
                                STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                                STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                                STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                            ].includes(status) &&
                            [ADMIN].includes(role)
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отменить оплату",
                        onClick: () => setRejectReasonModal({
                            isOpen: true,
                            paymentId,
                        }),
                    },
                    visible: (
                        [
                            STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                            STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                            STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                        ].includes(status) &&
                            isAccessAction
                    ) ||
                        (
                            [
                                STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
                                STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                            ].includes(status) &&
                            [ADMIN].includes(role)
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Просмотр акта",
                        onClick: () => downloadFile(`/bff/adm/clients/client-card/crowd-tasks/registry/task/files/act-document?paymentNumber=${paymentNumber}`),
                    },
                    visible: isAccessDocumentView
                    && ![
                        STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                        STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
                        STATUS_TASK_PAYMENT.CANCELLED.VALUE,
                    ].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Заявка по заданию",
                        onClick: () => downloadFile(applicationDocumentFileLink),
                    },
                    visible: Boolean(applicationDocumentFileLink) && isAccessDocumentView,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Рамочный договор",
                        onClick: () => downloadFile(contractDocumentFileLink),
                    },
                    visible: Boolean(contractDocumentFileLink) && isAccessDocumentView,
                },
            ],
        };
    };

    const rejectPayment = ({paymentId, rejectReason}) => {
        dispatch(rejectCrowdPayment({
            paymentId,
            rejectReason: handleFormString(rejectReason),
            onSuccess: () => {
                fetchList();
                setRejectReasonModal({});
            },
        }));
    };

    const repeatPayment = ({paymentId, paymentNumber}) => {
        dispatch(repeatCrowdPayment({
            paymentId,
            onSuccess: () => {
                toastSuccess(`Запущена задача по повтору оплаты по заданию ${paymentNumber}`);
                fetchList();
            },
        }));
    };

    const massRejectPayments = (rejectReason) => {
        dispatch(massRejectCrowdPayment({
            paymentIds: selectedPaymentIds,
            rejectReason: handleFormString(rejectReason),
            onSuccess: () => {
                fetchList();
                clearSelectedRows();
                setRejectReasonModal({});
            },
        }));
    };

    const massApprovePayments = () => {
        dispatch(massApproveCrowdPayment({
            paymentIds: selectedPaymentIds,
            onSuccess: () => {
                fetchList();
                toastSuccess(`Запущена задача по подтверждению оплат по заданию ${selectedPaymentNumbers.join(", ")}`);
                clearSelectedRows();
            },
        }));
    };

    const revalidatePayments = () => {
        dispatch(massRevalidateCrowdPayment({
            clientId,
            onSuccess: () => {
                toastSuccess("Проверка запущена");
            },
        }));
    };

    const downloadFile = (downloadLink) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
        }));
    };

    return {
        getListMediaControls,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
        massRejectPayments,
        massApprovePayments,
        logModalData,
        setLogModalData,
        revalidatePayments,
    };
};

export default useFinanceCrowdPaymentsAction;