import {KEDO_STAFF_REGISTRY_INPUT_TYPE} from "../constants";

export const getKedoStaffInitialValues = (params) => {
    const {
        fields,
        card = {},
        isEdit,
    } = params;

    const values = fields.reduce((result, item) => {
        item.items.forEach(item => {
            if (item.editFields) {
                item.editFields.forEach(item => {
                    const name = item.name;

                    const value = getEditValueByInputType(item, card);

                    if (value === undefined) {
                        return result;
                    }

                    result[name] = value;
                });

                return result;
            }

            if (!item.name) {
                return result;
            }

            result[item.name] = getEditValueByInputType(item, card);
        });

        return result;
    }, {});

    return {
        ...values,
        generateAndSendPassword: !isEdit,
        password: "",
        repeatPassword: "",
    };
};

const getEditValueByInputType = ({type, name}, card) => {
    switch (type) {
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT:
            return card[name] || "";
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.DATE:
            return card[name] ? new Date(card[name]) : null;
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.CHECKBOX:
            return Boolean(card[name]);
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.POSITIONS:
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.SUBDIVISIONS:
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS:
        case KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN:
            return card[name] || "";
        default: break;
    }
};
