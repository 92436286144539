export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_ERROR = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_ERROR = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_ERROR = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE = "BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE";