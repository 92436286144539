import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {CREATE_ACTS_TYPE} from "../../../registryActsType";
import {
    registryNewAdminValidationSchema,
    registryNewValidationSchema,
} from "../validation";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {getUnavailableTemplatesFlags} from "../../../../../components/UnavailableTemplates/utils";
import {getPaymentMethodOptionsByClientPaymentsSettings} from "../../../../../utils/paymentMethodHelper";
import {useGetRegistryNewInitFormValues} from "../utils/getRegistryNewInitFormValues";

import {PAYMENT_METHOD_OPTIONS} from "../../../../../constants/clientSettings";
import {
    ORDER_AMOUNT_CALCULATION_METHOD,
    ORDER_WORK_REPORT_TYPE,
} from "../../../../../constants/finance";
import {OBJECT_STATUS_DICT} from "../../../../../constants/objects";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../../constants/roles";
import {PAYMENT_TYPE} from "../../../../../constants/settings";
import {
    ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES,
} from "../../../../order/order-edit/context-provider/constants";

import {getClientCardProperties} from "../../../../../ducks/bff/clients/info/actionCreators";
import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../../../../ducks/bff/clients/settings/actionCreators";
import {getClientMemberList} from "../../../../../ducks/clientMember";
import {getClientObjectList} from "../../../../../ducks/clientObject";
import {
    getDocumentCustomTemplateShortActual,
    getDocumentCustomTemplateShortDefault,
    getDocumentsCustomTemplateReplacementsForUnavailableList,
    updateDocumentCustomTemplateStore,
} from "../../../../../ducks/documentsCustomTemplate";
import {addRegistry, updateRegistry} from "../../../../../ducks/registry";

function useRegistryNewForm(props) {
    const {
        registryObj,
        clientInfo,
        isNmAdmin,
        objectList,
        clientUserId,
        clientId,
        clientsSettingsPayments,
    } = props;

    const validationSchema = isNmAdmin ? registryNewAdminValidationSchema : registryNewValidationSchema;

    const dispatch = useDispatch();
    const [isOpenUnavailableTemplates, setIsOpenUnavailableTemplates] = useState(false);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState(PAYMENT_METHOD_OPTIONS);

    const initialValues = useGetRegistryNewInitFormValues({
        registryObj,
        clientInfo,
        objectList,
        clientsSettingsPayments,
    });

    const onCloseUnavailableTemplates = () => {
        setIsOpenUnavailableTemplates(false);
    };

    const onOpenUnavailableTemplates = () => {
        setIsOpenUnavailableTemplates(true);
    };

    const fetchObjects = () => {
        const {
            valueObjectFilter,
        } = values;

        dispatch(getClientObjectList({
            clientId,
            clientUserId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
            nameSubstringFilter: valueObjectFilter,
        }));
    };

    const fetchTemplates = ({isFetchDefault, orderContractPaymentType} = {}) => {
        ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES.forEach(item => {
            const {
                documentTypeFilter,
                fieldName,
            } = item;

            // для рд не нужно подтягивать список
            if (fieldName !== "frameContractTemplateId") {
                fetchTemplateList({
                    documentTypeFilter,
                    fieldName,
                    orderContractPaymentType,
                });
            }

            if (values[fieldName] && !isFetchDefault) {
                return;
            }

            fetchDefaultTemplate({
                documentTypeFilter,
                fieldName,
                orderContractPaymentType,
            });
        });
    };

    const fetchTemplateList = (params) => {
        const {
            documentTypeFilter,
            fieldName,
        } = params;

        const {
            registryId,
        } = registryObj;

        dispatch(getDocumentCustomTemplateShortActual({
            isAggregation: true,
            aggregationFieldName: fieldName,
            orderContractPaymentTypeFilter: params.orderContractPaymentType
                ? params.orderContractPaymentType
                : values.orderContractPaymentType,
            clientIdFilter: clientId,
            documentTypeFilter,
            pageNum: 1,
            pageSize: 300,
            paymentsRegistryIdFilter: registryId,
            fieldName: "aggregationShortTemplates",
        }));
    };

    const fetchDefaultTemplate = (params) => {
        const {
            documentTypeFilter,
            fieldName,
        } = params;

        const {
            registryId,
        } = registryObj;

        dispatch(getDocumentCustomTemplateShortDefault({
            clientIdFilter: clientId,
            orderContractPaymentTypeFilter: params.orderContractPaymentType
                ? params.orderContractPaymentType
                : values.orderContractPaymentType,
            documentTypeFilter,
            paymentsRegistryIdFilter: registryId,
            onSuccess: ({id}) => {
                setFieldValue(fieldName, id);
            },
        }));
    };

    const fetchClientMembers = () => {
        const {
            objectId,
            projectId,
        } = values;

        dispatch(getClientMemberList({
            clientId,
            projectId,
            objectId,
            archiveFilter: false,
            roleListFilter: [FOREMAN, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT],
            pageNum: 1,
            pageSize: 300,
            fieldName: "adminMembers",
        }));
    };

    const onChangeRegistry = (e, {name, value, checked}) => {
        if (name === "objectId") {
            const object = objectList.find((item) => {
                return item.objectId === value;
            });

            setValues({
                ...values,
                objectId: object.objectId,
                projectId: object.projectId,
            });

            return;
        }

        if (name === "documentTablesEnabled" && checked) {
            setFieldValue("orderAmountCalculationMethod", ORDER_AMOUNT_CALCULATION_METHOD.NET);
        }

        if (name === "orderContractPaymentType") {
            fetchTemplates({
                isFetchDefault: true,
                orderContractPaymentType: value,
            });
        }

        if (name === "orderContractPaymentType" && value === ORDER_WORK_REPORT_TYPE.SMZ) {
            setFieldValue("documentTablesEnabled", clientInfo.documentTablesEnabled);
        }

        if (name === "orderContractPaymentType" && value === ORDER_WORK_REPORT_TYPE.CIVIL) {
            setFieldValue("documentTablesEnabled", clientInfo.civilDocumentTablesEnabled);
        }

        if (name === "orderContractPaymentType" && value === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            setFieldValue("documentTablesEnabled", false);
        }

        setFieldValue(name, value || checked);
    };

    const onSearchChangeObject = (valueObjectFilter) => {
        setFieldValue("valueObjectFilter", valueObjectFilter);
    };

    const onSubmitEdit = (registryId) => {
        const {
            name,
            description,
            objectId,
            projectId,
            orderApplicationTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            creatorClientUserId,
            orderContractPaymentType,
            orderAmountCalculationMethod,
            isCreateActs,
            createActsOfAcceptanceOfWorkType,
            documentTablesEnabled,
            paymentMethod,
        } = values;

        const {
            frameContractTemplateId,
            status,
        } = registryObj;

        const creatorData = {
            creatorClientId: isNmAdmin ? clientId : undefined,
            creatorClientUserId: isNmAdmin ? creatorClientUserId : undefined,
        };

        if (registryId) {
            dispatch(updateRegistry({
                registryId,
                name,
                description,
                clientId,
                projectId,
                objectId,
                status,
                createActsOfAcceptanceOfWorkType: isCreateActs ? createActsOfAcceptanceOfWorkType : CREATE_ACTS_TYPE.NONE,
                frameContractTemplateId,
                orderApplicationTemplateId,
                actOfAcceptanceOfWorkTemplateId,
                documentTablesEnabled,
                paymentMethod,
                ...creatorData,
            }));

            return;
        }

        dispatch(addRegistry({
            name,
            description,
            clientId,
            projectId,
            objectId,
            orderContractPaymentType,
            createActsOfAcceptanceOfWorkType: isCreateActs ? createActsOfAcceptanceOfWorkType : CREATE_ACTS_TYPE.NONE,
            frameContractTemplateId: frameContractTemplateId || values.frameContractTemplateId,
            orderApplicationTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            orderAmountCalculationMethod: orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL ? ORDER_AMOUNT_CALCULATION_METHOD.NET : orderAmountCalculationMethod,
            documentTablesEnabled,
            paymentMethod,
            ...creatorData,
        }));
    };

    const checkUnavailableTemplates = () => {
        if (!isValid) {
            return;
        }

        const {
            registryId,
            frameContractTemplateId,
            frameContractByObjectTemplate,
        } = registryObj;

        const {
            orderApplicationTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            orderContractPaymentType,
        } = values;

        // Проверяем на недоступные шаблоны
        dispatch(getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId,
            paymentsRegistryId: registryId,
            paymentType: orderContractPaymentType,
            frameContractByObjectTemplate,
            customDocumentIds: [
                frameContractTemplateId,
                orderApplicationTemplateId,
                actOfAcceptanceOfWorkTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isTemplatesDeleted,
                    isDisabledTemplateClientSetting,
                    isChangedDefaultTemplateRD,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем для удаленных шаблонов
                // При отключенной опции "Свои шаблоны"
                // При изменении дефолтного шаблона РД у клиента,
                if (isTemplatesDeleted || isDisabledTemplateClientSetting || isChangedDefaultTemplateRD) {
                    onOpenUnavailableTemplates();

                    return;
                }

                onSubmitEdit(registryId);
            },
        }));
    };

    const updatePaymentMethodOptions = () => {
        setPaymentMethodOptions(getPaymentMethodOptionsByClientPaymentsSettings({
            paymentsSettings: clientsSettingsPayments,
            paymentType: PAYMENT_TYPE.REGISTRY,
            contractType: values.orderContractPaymentType,
        }));
    };

    const getPaymentMethod = () => {
        if (values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            return clientsSettingsPayments.civilRegistry?.paymentMethod;
        }

        if (values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            return clientsSettingsPayments.individualRegistry?.paymentMethod;
        }

        return clientsSettingsPayments.smzRegistry?.paymentMethod;
    };

    const {
        values,
        errors,
        handleSubmit,
        isValid,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: checkUnavailableTemplates,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: false,
    });

    const prevOrderContractPaymentType = useGetPrevValue(values.orderContractPaymentType);

    useEffect(() => {
        if (isNmAdmin) {
            fetchClientMembers();
        }

        if (isEmpty(clientInfo) || clientId !== clientInfo.clientId) {
            dispatch(getClientCardProperties({clientId}));
        }

        fetchTemplates();
        dispatch(getClientsSettingsPayments({clientId}));

        return () => {
            dispatch(updateDocumentCustomTemplateStore({defaultTemplatesAggregation: {}}));
            dispatch(updateStoreClientsSetting({payments: {}}));
        };
    }, []);

    useEffect(() => {
        fetchObjects();
    }, [values.valueObjectFilter]);

    useEffect(() => {
        fetchClientMembers();
    }, [values.objectId]);

    useEffect(() => {
        if (
            !isEmpty(clientsSettingsPayments) &&
            (prevOrderContractPaymentType && prevOrderContractPaymentType !== values.orderContractPaymentType) ||
            !values.paymentMethod
        ) {
            updatePaymentMethodOptions();
            setFieldValue("paymentMethod", getPaymentMethod());
        }
    }, [values.orderContractPaymentType, clientInfo, clientsSettingsPayments]);

    useEffect(() => {
        const optionValue = paymentMethodOptions.find(option => option.key === values.paymentMethod) || {};

        if (isEmpty(optionValue) && !isEmpty(clientInfo) && !isEmpty(clientsSettingsPayments)) {
            setFieldValue("paymentMethod", null);
        }
    }, [paymentMethodOptions]);

    return {
        values,
        errors,
        setFieldValue,
        isOpenUnavailableTemplates,
        handleSubmit,
        onSubmitEdit,
        onSearchChangeObject,
        onChangeRegistry,
        onCloseUnavailableTemplates,
        paymentMethodOptions,
    };
}

export default useRegistryNewForm;