import {
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_CARD_STORE,
} from "./actions";

export function getSettingsClientGroupsCard(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST,
        payload,
    };
}

export function importSettingsClientGroupsCard(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST,
        payload,
    };
}

export const updateStoreSettingsClientGroupsCard = (payload) => {
    return {
        type: UPDATE_SETTINGS_CLIENT_GROUPS_CARD_STORE,
        payload,
    };
};