import {useDispatch} from "react-redux";

import {useModal} from "../../../../../hooks/useModal";

import {getUserRole} from "../../../../../utils/access";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../../constants/dicts";
import {ADMIN} from "../../../../../constants/roles";

import {deleteKedoDocumentRoutes} from "../../../../../ducks/kedo/directories/actionCreators";

export const useKedoDocumentRoutesAction = (props) => {
    const {
        isAccessActions,
    } = props;

    const dispatch = useDispatch();

    const role = getUserRole();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const deleteRoute = (routeId) => {
        dispatch(deleteKedoDocumentRoutes({
            routeId,
        }));
    };

    const getMediaControls = (item) => {
        if (!isAccessActions) {
            return null;
        }

        const {
            type,
            routeId,
            name,
        } = item;

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenEdit: true,
                            editData: item,
                        }),
                        children: "Редактировать маршрут",
                    },
                    visible: [ADMIN].includes(role)
                        || type === KEDO_DOCUMENTS_ROUTE_TYPE.USER_ROUTE,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenEditDocumentTypes: true,
                            routeId,
                            routeName: name,
                        }),
                        children: "Редактировать типы документов",
                    },
                    visible: [ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenDetails: true,
                                routeId,
                                confirmButton: "Понятно",
                                typeRoute: type,
                            });
                        },
                        children: "Детали маршрута",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            title: "Вы действительно хотите удалить данный маршрут из справочника?",
                            content: "Документы на подписании продолжат движение по данному маршруту, для отправки новых документов маршрут будет недоступен?",
                            confirmButton: "Удалить",
                            size: "sm",
                            routeId,
                            onConfirm: () => deleteRoute(routeId),
                        }),
                        children: "Удалить",
                    },
                },
            ],
        };
    };

    const openLinkedDocumentsTypesModal = (item) => {
        onOpenModal({
            isOpenLinkedTypesModal: true,
            subTitle: item.name,
            routeId: item.routeId,
            isShowTabs: true,
        });
    };

    return {
        getMediaControls,
        modalData,
        onOpenModal,
        onCloseModal,
        openLinkedDocumentsTypesModal,
    };
};