import {
    ADD_KEYWORD_REQUEST,
    CLEAR_FIELD_KEYWORDS,
    DELETE_KEYWORD_REQUEST,
    GET_PAGE_KEYWORDS_REQUEST,
    UPDATE_KEYWORD_REQUEST,
} from "./actions";

export function getKeywordsPage(payload) {
    return {
        type: GET_PAGE_KEYWORDS_REQUEST,
        payload,
    };
}

export function updateKeyword(payload) {
    return {
        type: UPDATE_KEYWORD_REQUEST,
        payload,
    };
}

export function addKeyword(payload) {
    return {
        type: ADD_KEYWORD_REQUEST,
        payload,
    };
}

export function deleteKeyword(payload) {
    return {
        type: DELETE_KEYWORD_REQUEST,
        payload,
    };
}

export function clearFieldKeyword() {
    return {
        type: CLEAR_FIELD_KEYWORDS,
    };
}