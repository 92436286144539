import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDadataInput from "../../../../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateMainWorkCity, {defaultMainWorkCityForm} from "./hooks/useUpdateMainWorkCity";

import {
    dadataValueCityFormatter,
    dadataValueUniqCitySettlementFilter,
} from "../../../../../../utils/dadata";

export default function ContractorMainWorkCityEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultMainWorkCityForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const {
        handleChangeAddressMain,
        handleChangeRemoteWork,
        submit,
        form,
        setForm,
        error,
    } = useUpdateMainWorkCity({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm(prevState => ({
            ...prevState,
            ...editData,
        }));
    }, [editData, setForm]);

    const {
        query,
        remoteWork,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Город ведения деятельности
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    label="Работаю удаленно"
                    checked={remoteWork}
                    onChange={handleChangeRemoteWork}
                />
                <NmDadataInput
                    size="xl"
                    required={true}
                    label="Город ведения деятельности"
                    name="workAddressFiasId"
                    query={query}
                    onChange={handleChangeAddressMain}
                    fromBound="city"
                    toBound="settlement"
                    formatter={dadataValueCityFormatter}
                    filter={dadataValueUniqCitySettlementFilter}
                    error={error.workAddressFiasId}
                    address={{}}
                />
            </NmForm>
        </NmModal>
    );
}