import {all, put, takeEvery} from "redux-saga/effects";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/scores";

//*  TYPES  *//

const GET_SCORE_ACTUAL_CONTRACTOR_REQUEST = "GET_SCORE_ACTUAL_CONTRACTOR_REQUEST";
const GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS = "GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS";
const GET_SCORE_ACTUAL_CONTRACTOR_ERROR = "GET_SCORE_ACTUAL_CONTRACTOR_ERROR";

//*  INITIAL STATE  *//

const initial = {
    contractorScore: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progressPage: false,
    progress: false,
    clientFeedbackListProgress: false,
    clientFeedbackListTotalCount: 0,
    clientFeedbackList: [],
    clientFeedbackListPageData: {},
    actionProgress: false,
};

//*  REDUCER  *//


export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_SCORE_ACTUAL_CONTRACTOR_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractorScore: payload,
                progress: false,
            };
        case GET_SCORE_ACTUAL_CONTRACTOR_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getActualScoreContractor(payload) {
    return {
        type: GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
        payload,
    };
}

//api/scores/contractor/actual
export const getActualScoreContractorSaga = function* (action) {
    try {
        const {payload} = action;
        const {data: params, handleResponse} = payload;

        const result = yield request.get(`${controller}/contractor/actual`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({type: GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_SCORE_ACTUAL_CONTRACTOR_REQUEST, getActualScoreContractorSaga),
    ]);
}
