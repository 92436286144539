import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    DIRECTORIES_BANKS_GET_SETTINGS_ERROR,
    DIRECTORIES_BANKS_GET_SETTINGS_REQUEST,
    DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS,
    DIRECTORIES_BANKS_SET_SETTINGS_ERROR,
    DIRECTORIES_BANKS_SET_SETTINGS_REQUEST,
    DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS,
    REFRESH_DIRECTORIES_SBP_BANKS_ERROR,
    REFRESH_DIRECTORIES_SBP_BANKS_REQUEST,
    REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS,
    SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR,
    SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST,
    SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST,
    SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/banks";

function* getSettingsDirectoriesBanksPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getPage`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesSbpBanksPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/common/dicts/getSbpParticipants", reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR, payload: e});
    }
}

function* refreshSettingsDirectoriesSbpBanksSaga() {
    try {
        const result = yield request.bff.post("/adm/common/dicts/refreshSbpParticipants");

        if (result.errorMessage) {
            toastError(result.errorMessage || "Не удалось обновить справочник");

            yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Справочник успешно обновлен");

        yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS, payload: result});
    } catch (e) {
        toastError("Не удалось обновить справочник");
        yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_ERROR, payload: e});
    }
}

function* updateSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/updateBank`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            key,
            ...reqData
        } = payload;

        const result = yield request.bff.get(`${controller}/getSystemSettings?key=${key}`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: DIRECTORIES_BANKS_GET_SETTINGS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS, payload: {
                fieldName: key,
                value: result,
            },
        });
    } catch (e) {
        yield put({type: DIRECTORIES_BANKS_GET_SETTINGS_ERROR, payload: e});
    }
}

function* setSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/setSystemSettings`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesBanksLogsPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getSystemSettingsLogPage`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances
// Баланс на р/с - Получить баланс на р/с
function* getSettingsDirectoriesBanksBalancesPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/balances`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances/add
// Баланс на р/с - Добавить банк
function* addSettingsDirectoriesBanksBalanceSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/balances/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR, payload: e});
    }
}

//POST PUT /bff/adm/settings/directories/banks/balances/edit
// Баланс на р/с - Редактировать банк
function* editSettingsDirectoriesBanksBalanceSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/balances/edit`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR, payload: e});
    }
}

//GET /bff/adm/settings/directories/banks/balances/new
// Баланс на р/с - Получить незаполненные модели банков для списка названий банков
function* getNewSettingsDirectoriesBanksBalanceSaga() {
    try {
        const result = yield request.bff.get(`${controller}/balances/new`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances/changes
// Баланс на р/с - Получить историю изменений
function* getSettingsDirectoriesBanksBalanceLogPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/balances/changes`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR, payload: e});
    }
}

//GET /bff/adm/settings/directories/banks/balances/rights/preCheck
// Баланс на р/с - проверить доступ ко вкладке
function* checkAccessSettingsDirectoriesBanksBalanceSaga() {
    try {
        const result = yield request.bff.get(`${controller}/balances/rights/preCheck`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR, payload: e});
    }
}

export function* saga() {
    yield all([
        takeEvery(SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST, getSettingsDirectoriesBanksPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST, getSettingsDirectoriesSbpBanksPageSaga),
        takeEvery(REFRESH_DIRECTORIES_SBP_BANKS_REQUEST, refreshSettingsDirectoriesSbpBanksSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST, updateSettingsDirectoriesBankSaga),
        takeEvery(DIRECTORIES_BANKS_GET_SETTINGS_REQUEST, getSettingsDirectoriesBankSaga),
        takeEvery(DIRECTORIES_BANKS_SET_SETTINGS_REQUEST, setSettingsDirectoriesBankSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST, getSettingsDirectoriesBanksLogsPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST, getSettingsDirectoriesBanksBalancesPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST, addSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST, editSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST, getNewSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST, getSettingsDirectoriesBanksBalanceLogPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST, checkAccessSettingsDirectoriesBanksBalanceSaga),
    ]);
}