import {
    SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CONTRACTORS_STORE,
} from "./actions";

export function getPageSettingsClientGroupsCardContractors(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_GET_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsClientGroupsCardContractors(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_ADD_REQUEST,
        payload,
    };
}

export function deleteSettingsClientGroupsCardContractors(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CONTRACTORS_DELETE_REQUEST,
        payload,
    };
}

export const updateStoreSettingsClientGroupsContractors = (payload) => {
    return {
        type: UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CONTRACTORS_STORE,
        payload,
    };
};