export const REVIEWS_CLIENT_GET_PAGE_REQUEST = "REVIEWS_CLIENT_GET_PAGE_REQUEST";
export const REVIEWS_CLIENT_GET_PAGE_SUCCESS = "REVIEWS_CLIENT_GET_PAGE_SUCCESS";
export const REVIEWS_CLIENT_GET_PAGE_ERROR = "REVIEWS_CLIENT_GET_PAGE_ERROR";

export const REVIEWS_CLIENT_GET_COUNT_REQUEST = "REVIEWS_CLIENT_GET_COUNT_REQUEST";
export const REVIEWS_CLIENT_GET_COUNT_SUCCESS = "REVIEWS_CLIENT_GET_COUNT_SUCCESS";
export const REVIEWS_CLIENT_GET_COUNT_ERROR = "REVIEWS_CLIENT_GET_COUNT_ERROR";

export const REVIEWS_CLIENT_UPDATE_REQUEST = "REVIEWS_CLIENT_UPDATE_REQUEST";
export const REVIEWS_CLIENT_UPDATE_SUCCESS = "REVIEWS_CLIENT_UPDATE_SUCCESS";
export const REVIEWS_CLIENT_UPDATE_ERROR = "REVIEWS_CLIENT_UPDATE_ERROR";

export const REVIEWS_CLIENT_DELETE_REQUEST = "REVIEWS_CLIENT_DELETE_REQUEST";
export const REVIEWS_CLIENT_DELETE_SUCCESS = "REVIEWS_CLIENT_DELETE_SUCCESS";
export const REVIEWS_CLIENT_DELETE_ERROR = "REVIEWS_CLIENT_DELETE_ERROR";

export const REVIEWS_CLIENT_DECLINE_REQUEST = "REVIEWS_CLIENT_DECLINE_REQUEST";
export const REVIEWS_CLIENT_DECLINE_SUCCESS = "REVIEWS_CLIENT_DECLINE_SUCCESS";
export const REVIEWS_CLIENT_DECLINE_ERROR = "REVIEWS_CLIENT_DECLINE_ERROR";

export const REVIEWS_CLIENT_CONFIRM_REQUEST = "REVIEWS_CLIENT_CONFIRM_REQUEST";
export const REVIEWS_CLIENT_CONFIRM_SUCCESS = "REVIEWS_CLIENT_CONFIRM_SUCCESS";
export const REVIEWS_CLIENT_CONFIRM_ERROR = "REVIEWS_CLIENT_CONFIRM_ERROR";

export const REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST = "REVIEWS_CLIENT_SEND_COMPLAINT_REQUEST";
export const REVIEWS_CLIENT_SEND_COMPLAINT_SUCCESS = "REVIEWS_CLIENT_SEND_COMPLAINT_SUCCESS";
export const REVIEWS_CLIENT_SEND_COMPLAINT_ERROR = "REVIEWS_CLIENT_SEND_COMPLAINT_ERROR";

export const REVIEWS_CLIENT_REPLY_ADD_REQUEST = "REVIEWS_CLIENT_REPLY_ADD_REQUEST";
export const REVIEWS_CLIENT_REPLY_ADD_SUCCESS = "REVIEWS_CLIENT_REPLY_ADD_SUCCESS";
export const REVIEWS_CLIENT_REPLY_ADD_ERROR = "REVIEWS_CLIENT_REPLY_ADD_ERROR";

export const REVIEWS_CLIENT_REPLY_UPDATE_REQUEST = "REVIEWS_CLIENT_REPLY_UPDATE_REQUEST";
export const REVIEWS_CLIENT_REPLY_UPDATE_SUCCESS = "REVIEWS_CLIENT_REPLY_UPDATE_SUCCESS";
export const REVIEWS_CLIENT_REPLY_UPDATE_ERROR = "REVIEWS_CLIENT_REPLY_UPDATE_ERROR";

export const REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST = "REVIEWS_CLIENT_REPLY_STATUS_UPDATE_REQUEST";
export const REVIEWS_CLIENT_REPLY_STATUS_UPDATE_SUCCESS = "REVIEWS_CLIENT_REPLY_STATUS_UPDATE_SUCCESS";
export const REVIEWS_CLIENT_REPLY_STATUS_UPDATE_ERROR = "REVIEWS_CLIENT_REPLY_STATUS_UPDATE_ERROR";

export const REVIEWS_CLIENT_CLEAR_STORE = "REVIEWS_CLIENT_CLEAR_STORE";