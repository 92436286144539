import {
    CLEAR_FIELDS_FINANCES_ORDERS,
    GET_HISTORY_FINANCES_ORDERS_ERROR,
    GET_HISTORY_FINANCES_ORDERS_REQUEST,
    GET_HISTORY_FINANCES_ORDERS_SUCCESS,
    GET_PAGE_FINANCES_ORDERS_ERROR,
    GET_PAGE_FINANCES_ORDERS_REQUEST,
    GET_PAGE_FINANCES_ORDERS_SUCCESS,
    UPDATE_FIELDS_FINANCES_ORDERS,
} from "./actions";

const initial = {
    pageData: {},
    progress: false,
    totalCount: 0,
    list: [],
    historyPageData: {},
    historyProgress: false,
    historyTotalCount: 0,
    historyList: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_FINANCES_ORDERS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_FINANCES_ORDERS_SUCCESS:
            const {
                orders,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: orders,
            };
        case GET_PAGE_FINANCES_ORDERS_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case GET_HISTORY_FINANCES_ORDERS_REQUEST:
            return {
                ...state,
                historyPageData: payload,
                historyProgress: true,
            };
        case GET_HISTORY_FINANCES_ORDERS_SUCCESS:
            return {
                ...state,
                historyProgress: false,
                historyTotalCount: payload.totalCount,
                historyList: payload.events,
            };
        case GET_HISTORY_FINANCES_ORDERS_ERROR:
            return {
                ...state,
                historyProgress: false,
                error: payload,
            };
        case UPDATE_FIELDS_FINANCES_ORDERS:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_FIELDS_FINANCES_ORDERS:
            return initial;
        default:
            return state;
    }
};