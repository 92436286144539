import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import MultiSelect from "../../../components/MultiSelect";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";

import dateFormat, {formatLocalDate} from "../../../utils/dateFormat";
import {getFileDataByLink} from "../../../utils/gueryStringHelper";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName, phoneFormat} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {DOCUMENT_EXPORT_UPLOAD_STATUS} from "../../../constants/documentsExport";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {
    getDictExportDocumentType,
    getPageExportDocuments,
    updateStoreExportDocuments,
} from "../../../ducks/bff/export/documents/actionCreators";
import {
    bffExportDocumentsListSelector,
    bffExportDocumentsProgressSelector,
    bffExportDocumentsTotalCountSelector,
    bffExportDocumentsTotalPagesSelector,
    bffExportDocumentTypeDictSelector,
} from "../../../ducks/bff/export/documents/selectors";
import {
    downloadDocument,
} from "../../../ducks/documents";
import {
    addDocumentsExport,
} from "../../../ducks/documentsExport";
import {cancelExportById} from "../../../ducks/job";

import "./style.sass";

class DocumentExportList extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.clientId = clientId;

        this.pageSizes = [25, 50, 100];
        const [pageSize] = this.pageSizes;

        this.state = {
            pageNum: 1,
            pageSize,
            openConfirmWindow: false,
            confirmData: {},
        };

        this.role = ls(USER_ROLE);
        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
    }

    componentDidMount() {
        const {getDictExportDocumentType} = this.props;

        this.fetchList();
        getDictExportDocumentType();
    }

    componentWillUnmount() {
        const {updateStoreExportDocuments} = this.props;

        updateStoreExportDocuments({
            list: [],
            totalCount: 0,
            pageData: {},
        });
    }

    get localizationData() {
        const {t} = this.props;

        return {
            title: t("documents-export.title"),
            downloadLabel: t("button.download"),
        };
    }

    get isShowTitle() {
        return [CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(this.role);
    }

    fetchList = () => {
        const {getPageExportDocuments} = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        getPageExportDocuments({
            clientId: this.clientId,
            pageNum,
            pageSize,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    renderDocuments(item) {
        const {documentTypeOptions} = this.props;
        const {documentList, documentsCount} = item;
        const typesDoc = Object.keys(documentsCount);

        const mapOptions = documentTypeOptions.filter(doc => typesDoc.includes(doc.value)).map((value) => {
            return {
                ...value,
                text: `${value.text}: ${documentsCount[value.key]}`,
            };
        });

        const options = isEmpty(documentsCount) ? documentTypeOptions : mapOptions;
        const value = isEmpty(documentsCount) ? [...new Set(documentList)] : typesDoc;

        let infoCount = 0;

        for (const count of Object.values(documentsCount)) {
            infoCount += count;
        }

        return (
            <MultiSelect
                classNameContainer="document-export-list__count"
                isShowTitle={false}
                widthReadonly={290}
                isShowContent={false}
                options={options}
                value={value}
                infoCount={infoCount}
                readOnly
            />
        );
    }

    handleDownloadDocument(downloadLink, id, fileName) {
        const {downloadDocument} = this.props;
        const fileData = getFileDataByLink(downloadLink, fileName);

        downloadDocument({
            ...fileData,
            id,
        });
    }

    cancelExport = (exportId) => {
        const {cancelExportById} = this.props;

        cancelExportById({
            exportId,
            onSuccess: this.fetchList,
        });
    };

    renderActions(data) {
        const {
            link,
            documentExportId,
            fileName,
            uploadStatus,
            clientUserId,
        } = data;
        const {progressDataDownload: {
            id,
        }} = this.props;

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        loading: id === documentExportId,
                        onClick: () => {
                            this.handleDownloadDocument(link, documentExportId, fileName);
                        },
                        color: "light-green",
                        children: "Скачать",
                    },
                    visible: Boolean(link),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.setState({
                                openConfirmWindow: true,
                                confirmData: {
                                    content: "Вы уверены, что хотите отменить формирование документа?",
                                    onConfirm: () => this.cancelExport(documentExportId),
                                    confirmButton: "Да",
                                    cancelButton: "Нет",
                                },
                            });
                        },
                        color: "grey",
                        children: "Отменить",
                    },
                    visible: uploadStatus === DOCUMENT_EXPORT_UPLOAD_STATUS.INITIALIZATION.VALUE && ([ADMIN].includes(this.role) || clientUserId === this.currentClientUserId),
                },
            ],
        };
    }

    getRows() {
        const {documentExportPage} = this.props;

        return documentExportPage.map(item => {
            const {
                createDatetime,
                lastName,
                firstName,
                patronymic,
                fromDate,
                toDate,
                uploadStatus,
                contractorFio,
                contractorPhone,
                orderNum,
                orderName,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                noWrap
                                mod={DOCUMENT_EXPORT_UPLOAD_STATUS[uploadStatus]?.MOD}
                                text={DOCUMENT_EXPORT_UPLOAD_STATUS[uploadStatus]?.TEXT}
                            />
                        }
                        primaryHeader={`Выгрузка от ${formatLocalDate(createDatetime, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {
                                label: "Период",
                                text: `${dateFormat(fromDate, "dd.MM.yyyy")} - ${dateFormat(toDate, "dd.MM.yyyy")}`,
                            },
                            {
                                label: "Исполнитель",
                                text: contractorFio || contractorPhone ?
                                    `${contractorFio || ""} ${contractorPhone ? phoneFormat(contractorPhone) : ""}` :
                                    "-",
                            },
                            {
                                label: "Номер и наименование заказа",
                                text: orderNum || orderName ?
                                    `${orderNum ? `№ ${orderNum} - ` : ""}${orderName || ""}` :
                                    "-",
                            },
                            {
                                label: "Инициатор",
                                text: getFullName(lastName, firstName, patronymic),
                            },
                            {
                                label: "Документы",
                                text: this.renderDocuments(item),
                                classNameText: "document-export-list__label-documents",
                            },
                        ]}
                        classNameMainContent="col-16 col-xxl-6"
                        mediaControls={this.renderActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    renderModalWindow() {
        const {
            openConfirmWindow,
            confirmData: {
                content,
                onConfirm,
                confirmButton,
                cancelButton,
            },
        } = this.state;

        return openConfirmWindow &&
            <NmConfirmV2
                content={content}
                onCancel={() => {
                    this.setState({
                        openConfirmWindow: false,
                        confirmData: {},
                    });
                }}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    }

    render() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            totalPages,
            totalCount,
        } = this.props;

        const {title} = this.localizationData;

        return (
            <NmPage
                className="document-export-list"
                header={
                    this.isShowTitle ?
                        <NmTitle
                            size="xl"
                            count={totalCount}
                        >
                            {title}
                        </NmTitle> :
                        null
                }
                totalCount={totalCount}
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
            >
                {this.renderModalWindow()}
                <div className="document-export-list__list">
                    {
                        totalCount ?
                            <CheckboxList
                                rows={this.getRows()}
                            /> :
                            <NmEmptyPageV2 />
                    }
                </div>
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        totalPages: bffExportDocumentsTotalPagesSelector(state),
        totalCount: bffExportDocumentsTotalCountSelector(state),
        documentExportPage: bffExportDocumentsListSelector(state),
        documentTypeOptions: bffExportDocumentTypeDictSelector(state),
        progressDataDownload: bffExportDocumentsProgressSelector(state),
    }),
    {
        getPageExportDocuments,
        downloadDocument,
        addDocumentsExport,
        cancelExportById,
        getDictExportDocumentType,
        updateStoreExportDocuments,
    },
)(withTranslation()(DocumentExportList));
