import {all, call, put, select, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_GET_INFO_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_IMPORT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";

const controller = "/adm/settings/client-groups/card";

const getSettingsClientGroupsCard = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get-info`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_GET_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_GET_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_GET_INFO_ERROR,
            payload: error,
        });
    }
};

const importSettingsClientGroupsCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            requestData: {
                groupId,
                file,
            },
        } = payload;

        const result = yield request.bff.post(`${controller}/import?groupId=${groupId}`, file, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_IMPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }
        yield put({type: SETTINGS_CLIENT_GROUP_CARD_IMPORT_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_IMPORT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_GET_INFO_REQUEST, getSettingsClientGroupsCard),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_IMPORT_REQUEST, importSettingsClientGroupsCard),
    ]);
}