import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import {ClientCardCompanyRoute} from "./Route";

import {getUserRole} from "../../../../utils/access";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    LINK_BRIGADE,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_INFO,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_LIST,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../../ducks/clientUserRestrictions";

import "./style.sass";

const ClientCardCompany = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const {
        foremanEnabled,
        withoutContract,
        addBrandNameToObject,
    } = useSelector(clientCardPropertiesSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const role = getUserRole();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientBrands,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientScores,
            ],
        }));
    }, []);

    const getLinks = () => {
        const info = LINK_CLIENT_INFO.replace(":clientId", clientId);
        const members = LINK_CLIENT_MEMBERS_LIST.replace(":clientId", clientId);
        const projects = LINK_CLIENT_PROJECTS_LIST.replace(":clientId", clientId);
        const objects = LINK_CLIENT_OBJECTS_LIST.replace(":clientId", clientId);
        const brigadiers = LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST.replace(":clientId", clientId);
        const reviews = LINK_CLIENT_FEEDBACKS.replace(":clientId", clientId);
        const brands = LINK_CLIENT_BRANDS.replace(":clientId", clientId);

        return {
            info,
            members,
            projects,
            objects,
            brigadiers,
            reviews,
            brands,
        };
    };

    const getTabLinks = () => {
        const {
            info,
            members,
            projects,
            brigadiers,
            reviews,
            objects,
            brands,
        } = getLinks();

        const links = [
            {
                id: "info",
                active: info,
                link: info,
                name: "Информация",
            },
            {
                id: "members",
                active: members,
                link: members,
                name: "Сотрудники",
            },
            {
                id: "projects",
                active: projects,
                link: projects,
                name: "Проекты",
                isVisible: !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects),
            },
            {
                active: objects,
                link: objects,
                name: "Объекты",
                isVisible: !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects),
            },
            {
                id: "brigade",
                active: [
                    LINK_BRIGADE,
                ],
                link: brigadiers,
                name: "Бригадиры",
                isVisible: isAccessByRestrictions({
                    currentUserRestrictions,
                    checkList: [CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientForemans]}),
            },
            {
                active: reviews,
                link: reviews,
                name: "Отзывы",
                isVisible: !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientScores),
            },
            {
                active: brands,
                link: brands,
                name: "Бренды компании",
                isVisible: !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientBrands),
            },
        ];

        const filterTabs = (item) => {
            if (!item) {
                return false;
            }

            if ([projects, objects].includes(item.link) && role === NM_CONSULTANT) {
                return false;
            }

            if (withoutContract && !["info", "members", "projects"].includes(item.id)) {
                return false;
            }

            if (item.link === brands && !addBrandNameToObject) {
                return false;
            }

            return !(
                item.id === "brigade"
                && (!foremanEnabled || [NM_CHIEF_ACCOUNTANT, NM_CONSULTANT, NM_COORDINATOR].includes(role))
            );
        };

        return links.filter(filterTabs);
    };

    return (
        <div className="client-card-company">
            {
                ![
                    CLIENT_ADMIN,
                    CLIENT_ACCOUNTANT,
                    PROJECT_MANAGER,
                    OBJECT_MANAGER,
                    FOREMAN,
                    HR_MANAGER,
                    RECRUITER,
                ].includes(role) &&
                <>
                    <NmTitle size="xl">
                        Компания
                    </NmTitle>
                    <Tabs
                        {...props}
                        className="client-card-company__tabs"
                        panes={getTabLinks()}
                        secondary
                    />
                </>
            }
            <ClientCardCompanyRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardCompany;
