import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions, sortOptionsAlphabetically} from "../utils/objectHelper";
import request from "../utils/postman";

const controller = "/contractors/log/event";
//*  TYPES  *//

const CONTRACTOR_EVENT_DICT_REQUEST = "CONTRACTOR_EVENT_DICT_REQUEST";
const CONTRACTOR_EVENT_DICT_SUCCESS = "CONTRACTOR_EVENT_DICT_SUCCESS";
const CONTRACTOR_EVENT_DICT_ERROR = "CONTRACTOR_EVENT_DICT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    contractorEventDict: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_EVENT_DICT_SUCCESS:
            return {
                ...state,
                contractorEventDict: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorEventDict() {
    return {
        type: CONTRACTOR_EVENT_DICT_REQUEST,
    };
}

//*  SELECTORS  *//

const contractorEventLogSelector = state => state.contractorEventLog;
export const contractorEventDictSelector = createSelector(contractorEventLogSelector, ({contractorEventDict}) => contractorEventDict);
export const contractorEventDictOptionsSelector = createSelector(contractorEventLogSelector, ({contractorEventDict}) => {
    const list = dictionaryToOptions(contractorEventDict);

    return sortOptionsAlphabetically(list);
});

//*  SAGA  *//

const getContractorEventDictSaga = function* ({}) {
    try {
        const {errorMessage, ...result} = yield request.post(`${controller}/dict`);

        if (errorMessage) {
            console.error(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_EVENT_DICT_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: CONTRACTOR_EVENT_DICT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_EVENT_DICT_REQUEST, getContractorEventDictSaga),
    ]);
}