import React from "react";

import NmDropdownV2, {INmDropdownV2} from "../NmDropdownV2";
import {TextareaWithParams} from "../TextareaWithParams";

interface ParamPickerWithTextarea {
    paramDict: Record<string, number>,
    dropdownProps: INmDropdownV2,
    textareaProps: any,
}

export const ParamPickerWithTextarea: React.FC<ParamPickerWithTextarea> = (props) => {
    const {
        paramDict,
        dropdownProps,
        textareaProps,
    } = props;

    return (
        <>
            <NmDropdownV2
                label="Вставить параметр"
                placeholder="Выберите параметр"
                {...dropdownProps}
            />
            <TextareaWithParams
                paramDict={paramDict}
                {...textareaProps}
            />
        </>
    );
};