import {
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
} from "./actions";

export function paymentsUpdateAllBankStatus(payload) {
    return {
        type: PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
        payload,
    };
}

export function getRunningPaymentsUpdateAllBankStatus(payload) {
    return {
        type: GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
        payload,
    };
}