export const BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST = "BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST";
export const BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS = "BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS";
export const BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR = "BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR";

export const BFF_PROMOTION_CHANNELS_ADD_REQUEST = "BFF_PROMOTION_CHANNELS_ADD_REQUEST";
export const BFF_PROMOTION_CHANNELS_ADD_SUCCESS = "BFF_PROMOTION_CHANNELS_ADD_SUCCESS";
export const BFF_PROMOTION_CHANNELS_ADD_ERROR = "BFF_PROMOTION_CHANNELS_ADD_ERROR";

export const BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST = "BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_REQUEST";
export const BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS = "BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS";
export const BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_ERROR = "BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_ERROR";

export const BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST = "BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_REQUEST";
export const BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_SUCCESS = "BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_SUCCESS";
export const BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_ERROR = "BFF_PROMOTION_CHANNELS_CHECK_HAS_PROMOTION_ERROR";

export const UPDATE_PROMOTION_CHANNELS_STORE = "UPDATE_PROMOTION_CHANNELS_STORE";