import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";

import {getOrderGroupsPage} from "../../../../../ducks/bff/orders/order-groups/actionCreators";
import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";

export function useOrderGroupsFetchList({clientId, pageSize, pageNum, filter}) {
    const dispatch = useDispatch();

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.addGroupOrder,
                CLIENT_USER_RESTRICTIONS_VARIABLE.changeGroupOrder,
            ],
        }));
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, archived, pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getOrderGroupsPage({
            archived,
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        archived,
        setArchived,
        fetchList,
    };
}