import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getPageRegistryPayment} from "./registryPayment/actionCreators";
import {getRegistryPayments, registryPaymentsPageDataSelector} from "./registryPayments";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffUrl} from "../utils/url";

import {LINK_FINANCE_REGISTRY_PAYMENTS} from "../constants/links";
import {REGISTRY_STATUS_DICT} from "../constants/registry";
import {REGISTRY_TOAST_TEXT} from "../constants/registry";

const controller = "/registries";

//*  TYPES  *//

const REGISTRY_DELETE_REQUEST = "REGISTRY_DELETE_REQUEST";
const REGISTRY_DELETE_SUCCESS = "REGISTRY_DELETE_SUCCESS";
const REGISTRY_DELETE_ERROR = "REGISTRY_DELETE_ERROR";

const REGISTRY_UPDATE_REQUEST = "REGISTRY_UPDATE_REQUEST";
const REGISTRY_UPDATE_SUCCESS = "REGISTRY_UPDATE_SUCCESS";
const REGISTRY_UPDATE_ERROR = "REGISTRY_UPDATE_ERROR";

const GET_REGISTRY_REQUEST = "GET_REGISTRY_REQUEST";
const GET_REGISTRY_SUCCESS = "GET_REGISTRY_SUCCESS";
const GET_REGISTRY_ERROR = "GET_REGISTRY_ERROR";

const ADD_REGISTRY_REQUEST = "ADD_REGISTRY_REQUEST";
const ADD_REGISTRY_SUCCESS = "ADD_REGISTRY_SUCCESS";
const ADD_REGISTRY_ERROR = "ADD_REGISTRY_ERROR";

const ARCHIVE_REGISTRY_REQUEST = "ARCHIVE_REGISTRY_REQUEST";
const ARCHIVE_REGISTRY_SUCCESS = "ARCHIVE_REGISTRY_SUCCESS";
const ARCHIVE_REGISTRY_ERROR = "ARCHIVE_REGISTRY_ERROR";

const DUBLICATE_REGISTRY_REQUEST = "DUBLICATE_REGISTRY_REQUEST";
const DUBLICATE_REGISTRY_SUCCESS = "DUBLICATE_REGISTRY_SUCCESS";
const DUBLICATE_REGISTRY_ERROR = "DUBLICATE_REGISTRY_ERROR";

const PAY_REGISTRY_REQUEST = "PAY_REGISTRY_REQUEST";
const PAY_REGISTRY_SUCCESS = "PAY_REGISTRY_SUCCESS";
const PAY_REGISTRY_ERROR = "PAY_REGISTRY_ERROR";

const GET_REGISTRY_STATUS_DICT_REQUEST = "GET_REGISTRY_STATUS_DICT_REQUEST";
const GET_REGISTRY_STATUS_DICT_SUCCESS = "GET_REGISTRY_STATUS_DICT_SUCCESS";
const GET_REGISTRY_STATUS_DICT_ERROR = "GET_REGISTRY_STATUS_DICT_ERROR";

const GET_ENRICHED_REGISTRY_REQUEST = "CLIENT_REGISTRY_READ_REQUEST";

const UPDATE_FIELD_REGISTRY_STORE = "UPDATE_FIELD_REGISTRY_STORE";

const TRANSFER_REGISTRY_TO_PAY_ERROR = "TRANSFER_REGISTRY_TO_PAY_ERROR";
const TRANSFER_REGISTRY_TO_PAY_REQUEST = "TRANSFER_REGISTRY_TO_PAY_REQUEST";
const TRANSFER_REGISTRY_TO_PAY_SUCCESS = "TRANSFER_REGISTRY_TO_PAY_SUCCESS";

const DISCARD_OUTSTANDING_ERROR = "DISCARD_OUTSTANDING_ERROR";
const DISCARD_OUTSTANDING_REQUEST = "DISCARD_OUTSTANDING_REQUEST";
const DISCARD_OUTSTANDING_SUCCESS = "DISCARD_OUTSTANDING_SUCCESS";

const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST";
const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS";
const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    registries: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    isSuccessAddOrEdit: false,
    registryStatusDict: {},
    isSuccessAction: false,
};

//*  REDUCER  *//

const handleResponse = function* (data) {
    const {
        isFetchList,
    } = data;

    if (isFetchList) {
        const state = yield select();

        yield put(getPageRegistryPayment(state.registryPayment.pageData));
    }
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case TRANSFER_REGISTRY_TO_PAY_SUCCESS:
            return {
                ...state,
                payProcess: false,
            };
        case TRANSFER_REGISTRY_TO_PAY_REQUEST:
            return {
                ...state,
                payProcess: true,
            };
        case REGISTRY_UPDATE_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case GET_ENRICHED_REGISTRY_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case PAY_REGISTRY_REQUEST:
        case DUBLICATE_REGISTRY_REQUEST:
        case ARCHIVE_REGISTRY_REQUEST:
        case REGISTRY_DELETE_REQUEST:
        case ADD_REGISTRY_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case UPDATE_FIELD_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_REGISTRY_STATUS_DICT_SUCCESS:
            return {
                ...state,
                registryStatusDict: payload,
            };
        case DUBLICATE_REGISTRY_SUCCESS:
        case ARCHIVE_REGISTRY_SUCCESS:
        case PAY_REGISTRY_SUCCESS:
        case REGISTRY_DELETE_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
                isSuccessAction: true,
            };
        case ADD_REGISTRY_SUCCESS:
        case REGISTRY_UPDATE_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
                isSuccessAddOrEdit: true,
            };
        case GET_REGISTRY_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case ARCHIVE_REGISTRY_ERROR:
        case DUBLICATE_REGISTRY_ERROR:
        case REGISTRY_DELETE_ERROR:
        case PAY_REGISTRY_ERROR:
        case ADD_REGISTRY_ERROR:
            return {
                ...state,
                error: payload,
                actionRegistryProgress: false,
            };
        case GET_REGISTRY_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};


//*  ACTION CREATORS  *//

export function deleteRegistry(payload) {
    return {
        type: REGISTRY_DELETE_REQUEST,
        payload,
    };
}

export function archiveRegistry(payload) {
    return {
        type: ARCHIVE_REGISTRY_REQUEST,
        payload,
    };
}

export function createDublicateRegistry(payload) {
    return {
        type: DUBLICATE_REGISTRY_REQUEST,
        payload,
    };
}

export function updateRegistryStoreField(payload) {
    return {
        type: UPDATE_FIELD_REGISTRY_STORE,
        payload,
    };
}

export function updateRegistry(payload) {
    return {
        type: REGISTRY_UPDATE_REQUEST,
        payload,
    };
}

export function getRegistryById(payload) {
    return {
        type: GET_REGISTRY_REQUEST,
        payload,
    };
}

export function getEnrichedRegistryById(payload) {
    return {
        type: GET_ENRICHED_REGISTRY_REQUEST,
        payload,
    };
}

export function addRegistry(payload) {
    return {
        type: ADD_REGISTRY_REQUEST,
        payload,
    };
}

export function getRegistryStatusDict(payload) {
    return {
        type: GET_REGISTRY_STATUS_DICT_REQUEST,
        payload,
    };
}

export function transferRegistryToPay(payload) {
    return {
        type: TRANSFER_REGISTRY_TO_PAY_REQUEST,
        payload,
    };
}

export function payRegistry(payload) {
    return {
        type: PAY_REGISTRY_REQUEST,
        payload,
    };
}

export function discardOutstandingPayments(payload) {
    return {
        type: DISCARD_OUTSTANDING_REQUEST,
        payload,
    };
}

// Проверка наличия на реестре оплат в стаутсах "Отклонено", "Ошибка при передаче в оплату" с не резидентами без рд
export function getIsFrameContractSignedForContractorsOnRegistry(payload) {
    return {
        type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const registriesSelector = state => state.registries;
export const registriesListFormattedToTaskIdsSelector = createSelector(registriesSelector, ({registries}) =>
    registries.map(value => `${value.clientId}:${value.registryId}`),
);
export const registriesSuccessAddSelector = createSelector(registriesSelector, ({isSuccessAddOrEdit}) => isSuccessAddOrEdit);
export const registriesSuccessActionSelector = createSelector(registriesSelector, ({isSuccessAction}) => isSuccessAction);
export const registriesActionRegistryProgressSelector = createSelector(registriesSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const registriesCardSelector = createSelector(registriesSelector, ({card}) => card);
export const registryStatusDictSelector = createSelector(registriesSelector, ({registryStatusDict}) => registryStatusDict);
export const registryStatusOptionsSelector = createSelector(registriesSelector, ({registryStatusDict, pageData}) => {
    const {archivedFilter} = pageData;
    const options = dictionaryToOptions(registryStatusDict);
    return archivedFilter ? options.filter(item => ![REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE, REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE].includes(item.value)) : options;
});
export const registryProgressCardSelector = createSelector(registriesSelector, ({progressCard}) => progressCard);
export const registryPayProcessSelector = createSelector(registriesSelector, ({payProcess}) => payProcess);

//*  SAGA  *//

export const getEnrichedRegistrySaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.get(`${controller}/getRichById`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: GET_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_ERROR, payload: error});
    }
};

//updateRegistry

export const updateRegistrySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно изменен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: REGISTRY_UPDATE_ERROR, payload: error});
    }
};

export const deleteRegistrySaga = function* (action) {
    try {
        const {payload} = action;

        const {clientId, registryId} = payload;

        const result = yield request.delete(`${controller}/${clientId}/${registryId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно удалён");

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: REGISTRY_DELETE_ERROR, payload: error});
    }
};

export const archiveRegistrySaga = function* (action) {
    try {
        const {payload} = action;

        const {clientId, registryId, archived} = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/archived/${archived}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVE_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        if (archived) {
            toastSuccess("Реестр успешно добавлен в архив");
        } else {
            toastSuccess("Реестр успешно восстановлен из архива");
        }

        yield handleResponse({isFetchList: true});

        yield put({
            type: ARCHIVE_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: ARCHIVE_REGISTRY_ERROR, payload: error});
    }
};

export const createDublicateRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const url = getBffUrl(
            {
                [LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", false)]: `/adm/finances/registry-payments/${clientId}/${registryId}/addDuplicate`,
                isClientCard: true,
                clientRolesUrl: `/client-adm/finances/registry-payments/registry/${clientId}/${registryId}/add-duplicate`,
                adminRolesUrl: `/adm/clients/client-card/finances/registry-payments/registry/${clientId}/${registryId}/add-duplicate`,
            },
        );

        const result = yield request.bff.post(url);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DUBLICATE_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Дубликат успешно создан");

        yield handleResponse({isFetchList: true});

        yield put({type: DUBLICATE_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DUBLICATE_REGISTRY_ERROR, payload: error});
    }
};


export const addRegistrySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: ADD_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: ADD_REGISTRY_ERROR, payload: error});
    }
};

export const payRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            handleError,
        } = payload;

        const result = yield request.post(`${controller}/pay`, data);

        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);
            yield put({type: TRANSFER_REGISTRY_TO_PAY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PAY_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (payModel && payload.handleResponse) {
            payload.handleResponse(result);

            return {
                done: true,
            };
        }

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage);
        }

        toastSuccess("Успешно запущена задача оплаты.");

        yield handleResponse({isFetchList: true});

        yield put({
            type: PAY_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: PAY_REGISTRY_ERROR, payload: error});
    }
};

export const getRegistryByIdSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getById`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_ERROR, payload: error});
    }
};

export const getRegistryStatusDictSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get("/registrystatuses/all", {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_STATUS_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_STATUS_DICT_ERROR, payload: error});
    }
};

export const transferRegistryToPaySaga = function* ({payload}) {
    try {
        const {
            data,
            handleError,
            onSuccess = () => {},
        } = payload;

        const {clientId, registryId} = data;

        const result = yield call(request.post, `${controller}/pay`, data);

        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);
            yield put({type: TRANSFER_REGISTRY_TO_PAY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TRANSFER_REGISTRY_TO_PAY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (payModel && payload.handleResponse) {
            payload.handleResponse(result);

            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.PAY_SUCCESS);
        onSuccess();

        yield handleResponse({isFetchList: true});

        yield put({type: TRANSFER_REGISTRY_TO_PAY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TRANSFER_REGISTRY_TO_PAY_ERROR, payload: error});
    }
};

export const discardOutstandingPaymentsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/discardErrors`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DISCARD_OUTSTANDING_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        toastSuccess("Невыполненные платежи успешно отменены");

        yield put({type: DISCARD_OUTSTANDING_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISCARD_OUTSTANDING_ERROR, payload: error});
    }
};

export const isFrameContractSignedForContractorsOnRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/isFrameContractSignedForContractorsOnRegistry`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_UPDATE_REQUEST, updateRegistrySaga),
        takeEvery(REGISTRY_DELETE_REQUEST, deleteRegistrySaga),
        takeEvery(GET_REGISTRY_REQUEST, getRegistryByIdSaga),
        takeEvery(GET_ENRICHED_REGISTRY_REQUEST, getEnrichedRegistrySaga),
        takeEvery(ADD_REGISTRY_REQUEST, addRegistrySaga),
        takeEvery(GET_REGISTRY_STATUS_DICT_REQUEST, getRegistryStatusDictSaga),
        takeEvery(ARCHIVE_REGISTRY_REQUEST, archiveRegistrySaga),
        takeEvery(DUBLICATE_REGISTRY_REQUEST, createDublicateRegistrySaga),
        takeEvery(PAY_REGISTRY_REQUEST, payRegistrySaga),
        takeEvery(TRANSFER_REGISTRY_TO_PAY_REQUEST, transferRegistryToPaySaga),
        takeEvery(DISCARD_OUTSTANDING_REQUEST, discardOutstandingPaymentsSaga),
        takeEvery(IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST, isFrameContractSignedForContractorsOnRegistrySaga),
    ]);
}
