import {
    ADD_CLIENT_CATEGORIES_ERROR,
    ADD_CLIENT_CATEGORIES_REQUEST,
    ADD_CLIENT_CATEGORIES_SUCCESS,
    CLEAR_FIELDS_CLIENT_CATEGORIES,
    DELETE_CLIENT_CATEGORIES_ERROR,
    DELETE_CLIENT_CATEGORIES_REQUEST,
    DELETE_CLIENT_CATEGORIES_SUCCESS,
    GET_PAGE_CLIENT_CATEGORIES_ERROR,
    GET_PAGE_CLIENT_CATEGORIES_REQUEST,
    GET_PAGE_CLIENT_CATEGORIES_SUCCESS,
    UPDATE_CLIENT_CATEGORIES_ERROR,
    UPDATE_CLIENT_CATEGORIES_REQUEST,
    UPDATE_CLIENT_CATEGORIES_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_CLIENT_CATEGORIES_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_CLIENT_CATEGORIES_SUCCESS:
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: results,
            };
        case GET_PAGE_CLIENT_CATEGORIES_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ADD_CLIENT_CATEGORIES_REQUEST:
        case UPDATE_CLIENT_CATEGORIES_REQUEST:
        case DELETE_CLIENT_CATEGORIES_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_CLIENT_CATEGORIES_SUCCESS:
        case ADD_CLIENT_CATEGORIES_ERROR:
        case UPDATE_CLIENT_CATEGORIES_SUCCESS:
        case UPDATE_CLIENT_CATEGORIES_ERROR:
        case DELETE_CLIENT_CATEGORIES_SUCCESS:
        case DELETE_CLIENT_CATEGORIES_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CLEAR_FIELDS_CLIENT_CATEGORIES:
            return initial;
        default:
            return state;
    }
};