import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmRadioV2 from "../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import InvitePerformerToNaimix from "../../../components/InvitePerformerToNaimix";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {STATUS_PASSPORT_INN} from "../../../components/NmStatusPassportInnContractor";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as BlackListIcon} from "../../../images/blacklist.svg";
import {ReactComponent as CheckedBlackListIcon} from "../../../images/blacklist-checked.svg";
import {ReactComponent as FavouriteIcon} from "../../../images/favorite.svg";
import {ReactComponent as CheckedFavouriteIcon} from "../../../images/favorite-checked.svg";
import {ReactComponent as PeopleIcon} from "../../../images/people.svg";
import OnboardingList from "../../onboarding";
import StopList from "../../stop-list";
import ContractorNew from "../new";
import InviteContractorToOrderForm from "../nm-contractor-list/invite-contractor-to-order-form";
import NmGeneralContractorListFilter from "../nm-contractor-list/nm-general-contractor-list-filter";
import {NmContractorsTabs} from "../nm-contractors-tabs";
import {OrderContractorsErrors} from "../order-errors";
import ContractorListInfo from "./contractor-list-info";

import {isNMUsers} from "../../../utils/access";
import {getContractorLastLoginFilters, showInviteContractorsWarning} from "../../../utils/contractors";
import {extractExtData} from "../../../utils/historyNavigationhelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {clearSpace, getFullName, removePhoneMask} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastSuccess} from "../../../utils/toastHelper";
import {
    isAcceptedAddBlacklistedContractor,
    isAcceptedAddContractorToNmByEmailAndSms,
    isAcceptedAddFavoritesContractor,
    isAcceptedAddingContractorByForm,
    isAcceptedAddOrCloseContractorToOrder,
    isAcceptedMassAddContractorToOrder,
    isAcceptedShowProjectParamsFilter,
    isShowClientContractorListTabs,
    isShowContractorListCheckBoxes,
    isShowUnitedContractorListFilterForNmUser,
} from "../../../utils/user-rights/contractor";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    CONTRACTOR_RECOMMENDATION_TYPE,
    CONTRACTOR_STATUS,
    CONTRACTORS_TABS_TYPES,
    TIME_INTERVAL_PAYMENT_POSSIBILITY,
} from "../../../constants/contractor";
import {
    CONTRACTOR_LIST_FILTER_TYPE,
    CONTRACTOR_ON_CHECK_LEAD_TYPE,
    CONTRACTOR_ON_CHECK_LEAD_TYPES_FILTER,
    INVITING_SORT_TYPE,
    SORT_TYPE,
} from "../../../constants/contractorList";
import {FC_REGISTRIS_STATUS_DICT} from "../../../constants/fcRegistries";
import {headersNmContractorList} from "../../../constants/headersTable";
import {
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ADMIN,
    HR_MANAGER,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../../constants/roles";
import {STATUS} from "../../../constants/status";

import {history} from "../../../store/configureStore";

import {adressByIdProgressSelector, adressByIdSelector, getAddressById} from "../../../ducks/address";
import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector, clientCardPropertiesCanViewContractorsContactsSelector,
    clientCardPropertiesSelector,
    getClientTypeSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {
    getContractorEducationLevelDict,
    getContractorLanguagesDict,
    getContractorProfileResumeFile,
    getDriverLicenseDictionary,
} from "../../../ducks/bff/contractor-сard/profile/actionCreators";
import {contractorEducationLevelDictOptionsSelector} from "../../../ducks/bff/contractor-сard/profile/selectors";
import {
    getPageContractorsRegistry,
    importContractorsRegistry,
    updateStoreContractorsRegistry,
} from "../../../ducks/bff/contractors/registry/actionCreators";
import {
    bffContractorsRegistryActionSelector,
    bffContractorsRegistryListSelector,
    bffContractorsRegistryProgressSelector,
    bffContractorsRegistryTotalCountSelector,
    bffContractorsRegistryTotalPagesSelector,
} from "../../../ducks/bff/contractors/registry/selectors";
import {getClientGroupPage} from "../../../ducks/client";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../ducks/clientUserRestrictions";
import {
    addContractorToBlackList,
    addFavoriteContractors,
    contractorActionProgressSelector,
    contractorListSelector,
    contractorProgressSelector,
    contractorsSecondTryingIndicatorSelector,
    contractorStatusDictSelector,
    contractorTotalCountSelector,
    contractorTotalPagesSelector,
    deleteContractorFromBlackList,
    deleteFavoriteContractors,
    getCitizenshipSelector,
    getContractorOrderStatusDict,
    getContractorRegistrationStates,
    getContractorStatusDict,
    getHiringContractorOptimizedList,
    getPendingAdditionalDocumentsRichPage,
    importContractorsProgressSelector,
    recommendationModelTypeSelector,
    updateFieldContractorStore,
} from "../../../ducks/contractor";
import {
    bankCheckCancelToken,
    contractorsPaymentPossibilityListSelector,
    getContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../ducks/contractorBankCheck";
import {getWorkSchedulesByContractorId} from "../../../ducks/contractorWorkSchedules";
import {getAllTasks} from "../../../ducks/job";
import {
    getOrderCardSelector,
    getOrderRichById,
    getProgressOrderCardSelector,
    updateFieldOrderStore,
} from "../../../ducks/order";
import {
    addOrderContractor,
    addSeveralOrderContractors,
    orderProgressListSelector,
    rejectContractorByOrder,
} from "../../../ducks/orderContractors";
import {getProjectParamList} from "../../../ducks/projectParams";
import {specialitiesAllV2DictSelector, specialitiesAllV2OptionsSelector} from "../../../ducks/speciality";

import "./style.sass";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../constants/link-params";

//ToDo: на рефакторинг
class NmContractorList extends Component {
    constructor(props) {

        super(props);

        const {
            match: {
                params: {
                    clientId,
                    type,
                    orderId,
                },
                path,
            },
            filterData = {},
            pageNum,
            pageSize,
            location,
        } = props;

        const {
            orderNum = "",
            name = "",
            specialityIdsFilter = [],
            addressFiasIdsFilter,
            clientGroupClientIds,
            initRegionValueArr,
        } = extractExtData(location);

        const isAdditionalDocuments = path.includes("additional-documents");
        const isOnCheckPage = path.includes("on-check") || isAdditionalDocuments;

        this.state = {
            pageSize: pageSize || 50,
            pageNum: pageNum || 1,
            pageSizes: [25, 50, 100],
            headers: headersNmContractorList,
            openAddContractorForm: false,
            openAddInFavoritesWindow: false,
            contractorsType: type,
            openFilter: true,
            filterData: {
                ...filterData,
                specialityIdsFilter,
                addressFiasIdsFilter,
                initRegionValueArr,
                clientGroupClientIds: clientGroupClientIds || [],
                registrationStatusFilter: [],
                leadTypesFilter: CONTRACTOR_ON_CHECK_LEAD_TYPE.ALL,
                lastLoginFilter: null,
            } || {},
            isOnCheckPage,
            isAdditionalDocuments,
            isInvitingContractorsPage: !isNullOrWhitespace(orderId),
            selectedFavoriteOrBlacklistContractorsIds: [],
            isClientPage: !isNullOrWhitespace(clientId),
            orderInfo: {
                orderNum,
                name,
            },
            isSearch: false,
            isShowImportContractorForm: false,
            searchCriteria: {},
        };

        this.role = ls(USER_ROLE);
        this.isNmUser = [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO, NM_OPERATOR].includes(this.role);
        this.orderId = orderId;
        this.clientId = clientId;
    }

    componentDidMount() {
        const {
            getContractorOrderStatusDict,
            getContractorRegistrationStates,
            getProjectParamList,
            getContractorEducationLevelDict,
            getContractorLanguagesDict,
            getDriverLicenseDictionary,
            getClientUserRestrictionsList,
        } = this.props;

        const {
            isAdditionalDocuments,
            isOnCheckPage,
        } = this.state;

        if (!isAdditionalDocuments) {
            this.setState(prevState => ({
                ...prevState,
                isOnCheckPage,
                filterData: {
                    ...prevState.filterData,
                    registrationStatusFilter: [],
                },
            }), this.fetchList);
        } else {
            this.fetchList();
        }

        if (!isEmpty(this.clientId)) {
            this.fetchClientProperties();
        }

        if (this.isNmUser) {
            this.fetchClientGroupList();
        }

        getContractorOrderStatusDict();
        getContractorRegistrationStates();
        getContractorEducationLevelDict();
        getContractorLanguagesDict();
        getDriverLicenseDictionary();

        this.fetchContractorStatusDict();

        if (!isNullOrWhitespace(this.orderId)) {
            this.fetchOrder();
        }

        if (isAcceptedShowProjectParamsFilter(this.role, !isNullOrWhitespace(this.clientId))) {
            getProjectParamList({});
        }

        getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.addContractors,
            ],
        });
    }

    componentDidUpdate(prevProps) {
        const {
            match: {
                params: {
                    type: oldType,
                    orderId: oldOrderId,
                },
                path: oldPath,
            },
            order: _order,
        } = prevProps;

        const {
            match: {
                params: {
                    type,
                    orderId,
                    clientId,
                },
                path,
            },
            list,
            bffList,
            order,
        } = this.props;

        if (path !== oldPath) {
            const isAdditionalDocuments = path.includes("additional-documents");
            const isOnCheckPage = path.includes("on-check") || isAdditionalDocuments;

            this.setState(prevState => ({
                ...prevState,
                isOnCheckPage,
                isAdditionalDocuments,
                filterData: {
                    ...prevState.filterData,
                    registrationStatusFilter: [],
                },
            }), this.fetchList);
        }


        if (!isEqual(order, _order) && !isEmpty(order)) {
            const {orderNum, name} = order;

            this.setState({
                orderInfo: {
                    orderNum,
                    name,
                },
            });
        }

        if (type !== oldType) {
            this.setState({
                contractorsType: type,
                selectedList: [],
                selectedListCounter: 0,
                pageNum: 1,
            }, this.fetchList);
        }

        if (orderId !== oldOrderId) {
            this.orderId = orderId;
            this.setState({
                isInvitingContractorsPage: !isNullOrWhitespace(orderId),
                isClientPage: !isNullOrWhitespace(clientId),
                filterData: {
                    specialityIdsFilter: [],
                    addressFiasIdsFilter: [],
                },
            }, this.fetchList);
        }

        if (
            (!isEmpty(list) && !isEqual(list, prevProps.list))
            || (!isEmpty(bffList) && !isEqual(bffList, prevProps.bffList))
        ) {
            this.getContractorsPaymentPossibility();
        }
    };

    static getDerivedStateFromProps(props, state) {
        const {
            list: oldList,
            bffList: oldBffList,
            selectedList,
            isInvitingContractorsPage,
            isAdditionalDocuments,
        } = state;
        const {
            list,
            bffList,
        } = props;

        const isUseBff = !isInvitingContractorsPage && !isAdditionalDocuments;
        const _list = isUseBff ? bffList : list;
        const _oldList = isUseBff ? oldBffList : oldList;

        if (!isEqual(_list, _oldList) || (_list.length !== selectedList.length)) {
            return {
                ...state,
                selectedList: _list,
                selectedListCounter: 0,
                contractorNameList: [],
                selectedFavoriteOrBlacklistContractorsIds: [],
                selectedInvitedContractorsIds: [],
                list,
                bffList,
            };
        }

        return state;
    };

    componentWillUnmount() {
        const {
            updateFieldContractorStore,
            updateFieldsContractorPaymentPossibilityState,
            updateStoreContractorsRegistry,
        } = this.props;
        const {isInvitingContractorsPage} = this.state;

        updateFieldContractorStore({
            list: [],
        });

        updateStoreContractorsRegistry({
            list: [],
        });

        if (isInvitingContractorsPage) {
            const {updateFieldOrderStore} = this.props;

            updateFieldOrderStore("card", {});
        }

        updateFieldsContractorPaymentPossibilityState({
            contractorsPossibility: [],
        });
        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);
    };

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    get pageData() {
        const {
            list,
            totalCount,
            totalPages,
            progressList,
            actionProgress,
            bffList,
            bffTotalCount,
            bffTotalPages,
            bffProgressList,
            bffActionProgress,
        } = this.props;

        const {
            isInvitingContractorsPage,
            isAdditionalDocuments,
        } = this.state;

        if (isInvitingContractorsPage || isAdditionalDocuments) {
            return {
                list,
                totalCount,
                totalPages,
                progressList,
                actionProgress,
            };
        }

        return {
            list: bffList,
            totalCount: bffTotalCount,
            totalPages: bffTotalPages,
            progressList: bffProgressList,
            actionProgress: bffActionProgress,
        };
    }

    getContractorsPaymentPossibility = () => {
        const {getContractorsPaymentPossibility} = this.props;
        const {list} = this.pageData;

        if (isEmpty(list) || this.isOnboardingPage) {
            return;
        }

        const contractorIds = [...new Set(list.map(item => item.contractorId))];

        getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(this.checkBankPossibleTimer);
            },
            handleResponse: this.handleResponsePaymentPossibility,
            clientId: this.clientId,
            contractorIds,
        });
    };

    handleResponsePaymentPossibility = (result = {}) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getContractorsPaymentPossibility, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    fetchContractorStatusDict = () => {
        const {
            getContractorStatusDict,
        } = this.props;

        getContractorStatusDict();
    };

    fetchClientProperties = () => {
        const {
            getClientCardProperties,
        } = this.props;

        getClientCardProperties({
            clientId: this.clientId,
        });
    };

    fetchOrder = () => {
        const {
            getOrderRichById,
        } = this.props;

        getOrderRichById({
            clientId: this.clientId,
            orderId: this.orderId,
        });
    };

    fetchClientGroupList = () => {
        const {
            getClientGroupPage,
        } = this.props;

        getClientGroupPage({});
    };

    sendFilter = (filterData, isSearch = true) => {
        return () => {
            this.setState({
                pageNum: 1,
                filterData,
                isSearch,
            }, this.fetchList);
        };
    };

    getSortType() {
        const {
            isInvitingContractorsPage,
            isOnCheckPage,
            isAdditionalDocuments,
            orderMatchOnly,
        } = this.state;

        if (isOnCheckPage && !isAdditionalDocuments) {
            return SORT_TYPE.PENDING_PERSONAL_DATA_FIRST_FULL_REGISTRATION_JOIN_DATE_DESC;
        }

        if (isInvitingContractorsPage) {
            //для поиска на заказ
            /**
             * если переключатель все исполнители включен, сортировка по умолчанию = WORK_ADDRESS_MATCH_CONFIRMED_FIRST, если выключен = WORK_ADDRESS_SPECIALITY_MATCH_CONFIRMED_FIRST
             */
            return orderMatchOnly ? INVITING_SORT_TYPE.WORK_ADDRESS_SPECIALITY_MATCH_CONFIRMED_FIRST : INVITING_SORT_TYPE.WORK_ADDRESS_MATCH_CONFIRMED_FIRST;
        }

        //для простых списков
        return this.isNmUser ? SORT_TYPE.FULL_REGISTRATION_JOIN_DATE_DESC : SORT_TYPE.CONFIRMED_FIRST;
    }

    fetchList = () => {
        if (this.isOnboardingPage) {
            return;
        }

        const {
            getPageContractorsRegistry,
            getHiringContractorOptimizedList,
            getPendingAdditionalDocumentsRichPage,
        } = this.props;

        const {
            pageNum,
            pageSize,
            filterData,
            isInvitingContractorsPage,
            isOnCheckPage,
            isAdditionalDocuments,
        } = this.state;

        const {
            innFilter,
            sortType,
            innPassportDataValidStatusFilter,
            leadTypesFilter,
            lastLoginFilter,
            ...otherFilters
        } = filterData;

        const tabSettings = this.getListParamsByContactorsType();
        const _innPassportDataValidStatusFilter = innPassportDataValidStatusFilter && [innPassportDataValidStatusFilter];
        const lastLoginFilters = getContractorLastLoginFilters(lastLoginFilter);

        const requestData = {
            pageNum,
            pageSize,
            clientId: this.clientId,
            ...otherFilters,
            initRegionValueArr: undefined,
            innFilter: isNullOrWhitespace(innFilter) ? null : clearSpace(innFilter),
            sortType: isNullOrWhitespace(sortType) ? this.getSortType() : sortType,
            phoneFilter: removePhoneMask(filterData.phoneFilter),
            statusesSearchConditionOr: isOnCheckPage && !isAdditionalDocuments,
            onlyPendingCheckContractorsFilter: isOnCheckPage,
            innPassportDataValidStatusFilter: innPassportDataValidStatusFilter === STATUS_PASSPORT_INN.NOT_CHECKED.VALUE ?
                [STATUS_PASSPORT_INN.NOT_CHECKED.VALUE, STATUS_PASSPORT_INN.FNS_ERROR.VALUE] : _innPassportDataValidStatusFilter,
            ...lastLoginFilters,
            ...tabSettings,
        };

        this.setState({
            searchCriteria: requestData,
        });

        if (isAdditionalDocuments) {
            getPendingAdditionalDocumentsRichPage(requestData);

            return;
        }

        if (isInvitingContractorsPage) {
            getHiringContractorOptimizedList({
                ...requestData,
                orderId: this.orderId,
            });

            return;
        }

        getPageContractorsRegistry(requestData);
    };

    analyzeFilterData = (cbFn) => {
        const {
            filterData,
            orderMatchOnly,
        } = this.state;

        //если в фильтре пусто, то слйдер на всех исполнителей не переключаем
        //также не переключаем, если заполнена только сортировка
        const isFilterEmpty = this.isFilterDataEmpty(filterData);

        this.setState({
            isFilterEmpty,
            orderMatchOnly: isFilterEmpty ? orderMatchOnly : false,
        }, cbFn);
    };

    isFilterDataEmpty = (filterData) => {
        return !Object.keys(filterData).some((key) => {
            return key !== "sortType" && (!isEmpty(filterData[key]) || !isNullOrWhitespace(filterData[key]));
        });
    };

    getActions = () => {
        const {
            t,
            order,
        } = this.props;

        return {
            ADD_FAVORITE_CONTRACTOR: (selectedContractor) => {
                return {
                    title: this.localizationData.addInFavoritesConfirm(selectedContractor.fullName),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.addFavoriteContractor(selectedContractor),
                };
            },
            ADD_SOME_FAVORITE_CONTRACTORS: (contractorsCounter) => {
                return {
                    title: contractorsCounter ? this.localizationData.addSeveralInFavoritesConfirm("") : this.localizationData.addSeveralInFavoritesError,
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    isError: !contractorsCounter,
                    submit: this.addFavoriteContractors,
                };
            },
            INVITE_CONTRACTOR_TO_ORDER: (selectedContractor, isShowInviteContractorsWarning) => {
                return {
                    title: `${this.localizationData.addOnceToOrderConfirm(selectedContractor.fullName, order.name)} \n`,
                    content: isShowInviteContractorsWarning ? t("order-micro-job-seekers.migration-warning-msg") : "",
                    submitBtnContent: this.localizationData.btnConfirmText,
                    cancelBtnContent: this.localizationData.btnCancelText,
                    submit: this.inviteContractorToOrder(selectedContractor),
                };
            },
            CANCEL_INVITE_CONTRACTOR_TO_ORDER: (selectedContractor) => {
                return {
                    title: this.localizationData.cancelInviteToOrderConfirm(selectedContractor.fullName, order.name),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.cancelInviteContractorToOrder(selectedContractor),
                };
            },
            INVITE_SOME_CONTRACTORS_TO_ORDER: (contractorsCounter, isShowInviteContractorsWarning) => {
                return {
                    title: contractorsCounter ? (`${this.localizationData.addSeveralToOrderConfirm("", order.name)} \n`) : this.localizationData.addSeveralToOrderError,
                    content: contractorsCounter && isShowInviteContractorsWarning ? t("order-micro-job-seekers.migration-warning-msg") : "",
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.inviteContractorsToOrder,
                    isError: !contractorsCounter,
                };
            },
            DELETE_SOME_CONTRACTORS_FROM_FAVORITES: (contractorNameList) => {
                return {
                    title: this.localizationData.deleteSeveralFromFavoritesConfirm(contractorNameList),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.deleteFavoriteContractors,
                };
            },
            DELETE_SOME_CONTRACTORS_FROM_BLACK_LIST: (contractorNameList) => {
                return {
                    title: this.localizationData.deleteSeveralFromBlackListConfirm(contractorNameList),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.deleteContractorsFromBlackList,
                };
            },
            DELETE_FROM_FAVORITES: (selectedContractor) => {
                return {
                    title: this.localizationData.deleteFromFavoritesConfirm(selectedContractor.fullName),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.deleteFavoriteContractor(selectedContractor),
                };
            },
            ADD_CONTRACTOR_TO_BLACK_LIST: (selectedContractor) => {
                return {
                    title: this.localizationData.addOnceToBlackListConfirm(selectedContractor.fullName),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.addContractorToBlacklist(selectedContractor),
                    showReason: true,
                };
            },
            DELETE_CONTRACTOR_FROM_BLACK_LIST: (selectedContractor) => {
                return {
                    title: this.localizationData.deleteContractorFromBlackListConfirm(selectedContractor.fullName),
                    submitBtnContent: this.localizationData.btnYesText,
                    cancelBtnContent: this.localizationData.btnNoText,
                    submit: this.deleteContractorFromBlackList(selectedContractor),
                };
            },
        };
    };

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    handleDownloadResume = (contractor) => {
        const {
            contractorId,
            lastName,
            firstName,
            patronymic,
            fullName,
        } = contractor;

        const formattedFullName = lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName;

        return () => {
            const {
                getContractorProfileResumeFile,
            } = this.props;

            getContractorProfileResumeFile({
                contractorId,
                fileName: `Резюме_${formattedFullName}`,
            });
        };
    };


    addFavoriteContractor = ({contractorId}) => {
        return () => {
            const {
                addFavoriteContractors,
            } = this.props;

            addFavoriteContractors({
                data: {
                    contractorId,
                    clientId: this.clientId,
                },
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                    }, this.fetchList);
                },
            });
        };
    };

    getRecommendationModelType = () => {
        const {
            recommendationModelType,
        } = this.props;

        const {
            searchCriteria,
            isInvitingContractorsPage,
            filterData,
        } = this.state;

        const isFilterEmpty = this.isFilterDataEmpty(filterData);

        if (!isInvitingContractorsPage) {
            return CONTRACTOR_RECOMMENDATION_TYPE.CONTRACTOR_LIST;
        }

        const {
            fioFilter,
            innFilter,
            phoneFilter,
        } = searchCriteria;

        if ((fioFilter || innFilter || phoneFilter) && recommendationModelType === CONTRACTOR_RECOMMENDATION_TYPE.DEFAULT) {
            return CONTRACTOR_RECOMMENDATION_TYPE.FILTER;
        }

        if (!isFilterEmpty && recommendationModelType === CONTRACTOR_RECOMMENDATION_TYPE.DEFAULT) {
            return CONTRACTOR_RECOMMENDATION_TYPE.OTHER_FILTER;
        }

        return recommendationModelType;
    };

    inviteContractorsToOrder = () => {
        const {
            addSeveralOrderContractors,
            t,
        } = this.props;

        const {
            selectedInvitedContractorsIds,
            searchCriteria,
        } = this.state;

        addSeveralOrderContractors({
            contractorIds: selectedInvitedContractorsIds,
            clientId: this.clientId,
            orderIds: [this.orderId],
            recommendationType: this.getRecommendationModelType(),
            searchCriteria,
        }, {
            isMassOperation: true,
            onSuccess: () => {
                this.setState({
                    isShowConfirmWindow: false,
                }, this.fetchList);
            },
            toastMessage: t("contractor-list.invite-contractors-task-success"),
        });
    };

    inviteContractorToOrder = ({contractorId}) => {
        return () => {
            const {
                addOrderContractor,
                t,
            } = this.props;

            const {
                searchCriteria,
            } = this.state;

            addOrderContractor({
                contractorId,
                clientId: this.clientId,
                orderId: this.orderId,
                contractorStatus: STATUS.AWAITING_CONTRACTOR_CONFIRMATION,
                recommendationType: this.getRecommendationModelType(),
                searchCriteria,
            },
            {
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                    }, this.fetchList);
                },
                toastMessage: t("contractor-list.invite-contractor-success"),
            });
        };
    };

    cancelInviteContractorToOrder = ({contractorId}) => {
        return () => {
            const {
                rejectContractorByOrder,
                t,
            } = this.props;

            rejectContractorByOrder({
                contractorId,
                clientId: this.clientId,
                orderId: this.orderId,
                toastMsg: t("contractor-list.cancel-invitation-contractor-success"),
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                    }, this.fetchList);
                },
            });
        };
    };

    addFavoriteContractors = () => {
        const {
            addFavoriteContractors,
        } = this.props;

        const {
            selectedFavoriteOrBlacklistContractorsIds,
        } = this.state;

        addFavoriteContractors({
            data: {
                contractorIds: selectedFavoriteOrBlacklistContractorsIds,
                clientId: this.clientId,
            },
            onSuccess: () => {
                this.setState({
                    isShowConfirmWindow: false,
                }, this.fetchList);
            },
        });
    };

    addContractorToBlacklist = ({contractorId}) => {
        const {
            addContractorToBlackList,
        } = this.props;

        return () => {
            const {
                reason,
            } = this.state;

            this.validateBlacklistReason() && addContractorToBlackList({
                data: {
                    contractorId,
                    clientId: this.clientId,
                    reason,
                },
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                        reason: "",
                        reasonErrorMsg: null,
                    }, this.fetchList);
                },
            });
        };
    };

    deleteFavoriteContractors = () => {
        const {
            deleteFavoriteContractors,
        } = this.props;

        const {
            selectedFavoriteOrBlacklistContractorsIds,
        } = this.state;

        deleteFavoriteContractors({
            data: {
                contractorIds: selectedFavoriteOrBlacklistContractorsIds,
                clientId: this.clientId,
            },
            onSuccess: () => {
                this.setState({
                    isShowConfirmWindow: false,
                }, this.fetchList);
            },
        });
    };

    deleteContractorsFromBlackList = () => {
        const {
            deleteContractorFromBlackList,
        } = this.props;

        const {
            selectedFavoriteOrBlacklistContractorsIds,
        } = this.state;

        deleteContractorFromBlackList({
            data: {
                contractorIds: selectedFavoriteOrBlacklistContractorsIds,
                clientId: this.clientId,
            },
            onSuccess: () => {
                this.setState({
                    isShowConfirmWindow: false,
                }, this.fetchList);
            },
        });
    };

    validateBlacklistReason = () => {
        const {t} = this.props;
        const {
            reason,
        } = this.state;

        if (isNullOrWhitespace(reason)) {
            this.setState({
                reasonErrorMsg: t("contractor-list.required-reason-field"),
            });
            return false;
        }

        return true;
    };

    deleteContractorFromBlackList = ({contractorId}) => {
        const {
            deleteContractorFromBlackList,
        } = this.props;

        return () => {
            deleteContractorFromBlackList({
                data: {
                    contractorId,
                    clientId: this.clientId,
                },
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                    }, this.fetchList);
                },
            });
        };
    };

    deleteFavoriteContractor = ({contractorId}) => {
        return () => {
            const {
                deleteFavoriteContractors,
            } = this.props;

            deleteFavoriteContractors({
                data: {
                    contractorId,
                    clientId: this.clientId,
                },
                onSuccess: () => {
                    this.setState({
                        isShowConfirmWindow: false,
                    }, this.fetchList);
                },
            });
        };
    };

    getListParamsByContactorsType = () => {
        const {
            contractorsType,
        } = this.state;

        switch (contractorsType) {
            case CONTRACTORS_TABS_TYPES.ALL:
                return {};
            case CONTRACTORS_TABS_TYPES.MY_CONTRACTORS:
                return {
                    addedToMyClientGroupFilter: true,
                };
            case CONTRACTORS_TABS_TYPES.FAVORITE:
                return {
                    favouriteOnly: true,
                };
            case CONTRACTORS_TABS_TYPES.BLACK_LIST:
                return {
                    blacklistOnly: true,
                };
            case CONTRACTORS_TABS_TYPES.ML_SEARCH:
                return {
                    useMlSearch: true,
                };
            case CONTRACTORS_TABS_TYPES.BY_ORDER:
                return {
                    orderMatchOnly: true,
                };
        }
        return null;
    };

    get localizationData() {
        const {
            t,
        } = this.props;

        return {
            workRegionLabel: t("contractor-list.work-region"),
            workRegionsLabel: t("contractor-list.work-regions"),
            citizenship: t("contractor-list.citizenship"),
            emptyList: t("contractor-list.empty-list"),
            invited: t("contractor-list.invited"),
            online: t("contractor-list.online"),
            specialtiesLabel: t("contractor-list.specialties"),
            FNS: t("contractor-list.FNS"),
            isCheckPassport: t("contractor-list.check-passport"),
            completedOrders: t("contractor-list.completed-orders"),
            completedOrdersHelp: t("contractor-list.completed-orders-help"),
            inviteContractorToOrder: t("contractor-list.invite-contractor-to-order"),
            cancelInvite: t("contractor-list.cancel-invite"),
            lastLoginDate: t("contractor-list.last-login-date-text"),
            unknown: t("contractor-list.unknown"),
            addInFavorites: t("contractor-list.add-in-favorites"),
            addToBlackList: t("contractor-list.add-to-blacklist"),
            firstRegistration: t("contractor-list.first-registration"),
            fullRegistration: t("contractor-list.full-registration"),
            deleteFromBlackList: t("contractor-list.delete-from-blacklist"),
            contractorFnsError: t("contractor-list.contractor-fns-error"),
            myTaxFnsError: t("contractor-list.my-tax-fns-error"),
            fnsSuccess: t("contractor-list.fns-success"),
            bankRegistrationError: t("contractor-list.bank-registration-error"),
            checkPassportError: t("contractor-list.check-passport-error"),
            checkPassportSuccess: t("contractor-list.check-passport-success"),
            deleteFromFavorites: t("contractor-list.delete-from-favorites"),
            maxOrderDate: t("contractor-list.max-order-date"),
            age: t("contractor-list.age"),
            btnYesText: t("button.yes"),
            btnNoText: t("button.no"),
            btnConfirmText: t("button.confirm"),
            btnCancelText: t("button.cancel"),
            addInFavoritesConfirm: (name) => {
                return t("contractor-list.add-contractor-in-favorites-confirm", {name});
            },
            addOnceToOrderConfirm: (name, orderName) => {
                return t("contractor-list.invite-contractor-to-order-confirm", {name, orderName});
            },
            addSeveralToOrderConfirm: (names, orderName) => {
                return t("contractor-list.invite-contractors-to-order-confirm", {names, orderName});
            },
            addSeveralToOrderError: t("contractor-list.invite-contractors-to-order-error"),
            cancelInviteToOrderConfirm: (name, orderName) => {
                return t("contractor-list.cancel-invite-contractor-to-order-confirm", {name, orderName});
            },
            addSeveralInFavoritesConfirm: (names) => {
                return t("contractor-list.add-contractors-in-favorites-confirm", {names});
            },
            addSeveralInFavoritesError: t("contractor-list.add-contractors-in-favorites-error"),
            deleteSeveralFromFavoritesConfirm: (names) => {
                return t("contractor-list.delete-contractors-from-favorites-confirm", {names});
            },
            deleteSeveralFromBlackListConfirm: (names) => {
                return t("contractor-list.delete-contractors-from-blacklist-confirm", {names});
            },
            deleteFromFavoritesConfirm: (name) => {
                return t("contractor-list.delete-contractor-from-favorites-confirm", {name});
            },
            addOnceToBlackListConfirm: (name) => {
                return t("contractor-list.add-contractor-to-black-list-confirm", {name});
            },
            deleteContractorFromBlackListConfirm: (name) => {
                return t("contractor-list.delete-contractor-from-blacklist-confirm", {name});
            },
        };
    }

    handleChange = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    submitFilter = () => {
        this.setState({pageNum: 1}, this.fetchList);
    };

    isShowCheckboxByContractorType = (contractor) => {
        const {
            contractorsType,
        } = this.state;

        if (contractorsType === CONTRACTORS_TABS_TYPES.ALL) {
            return !contractor.blocked;
        }

        return true;
    };

    getOptions = (contractor) => {
        const {t} = this.props;

        const ADD_TO_BLACK_LIST = {
            key: "ADD_TO_BLACK_LIST",
            text: t("contractor-list.add-to-blacklist"),
            action: this.handleChange({
                action: this.getActions().ADD_CONTRACTOR_TO_BLACK_LIST(contractor),
                isShowConfirmWindow: true,
            }),
        };

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) && !contractor.blacklist) {
            return [ADD_TO_BLACK_LIST];
        }

        return [];
    };

    showInviteToOrderButton = ({orderContractorStatus, forbidden}) => {
        const {
            isInvitingContractorsPage,
        } = this.state;

        if (isInvitingContractorsPage) {
            //при подборе на заказ показываем только для тех, у кого статус на заказе пуст или для определённых статусов
            //также проверяем, что исполнитель, не в стоплисте
            return !forbidden && isNullOrWhitespace(orderContractorStatus) ||
                [
                    STATUS.CLIENT_REVOKED,
                    STATUS.CONTRACTOR_DECLINED,
                    STATUS.CLIENT_DECLINED,
                    STATUS.DECLINED,
                    STATUS.COMPLETED,
                ].includes(orderContractorStatus);
        }
        return true;
    };

    showCancelInviteToOrderButton = ({orderContractorStatus}) => {
        return [
            STATUS.AWAITING_CONTRACTOR_CONFIRMATION,
            STATUS.AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING,
        ].includes(orderContractorStatus);
    };

    handleChangeField = (e, {name, value, checked}, fnc = () => {
    }) => {
        this.setState({[name]: value || checked}, fnc);
    };

    handleChangeOrderFinding = (e, data, fnc = () => {
    }) => {
        const {
            orderMatchOnly: prevOrderMatchOnly,
        } = this.state;

        this.setState({
            orderMatchOnly: !prevOrderMatchOnly,
            filterData: {},
        }, fnc);
    };

    getContractorFullName = (contractor) => {
        const {
            fullName,
            firstName,
            lastName,
            patronymic,
            registrationStatus,
        } = contractor;

        if (
            !isEmpty(lastName)
            && (
                this.isNmUser
                || ![
                    CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
                    CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
                ].includes(registrationStatus)
            )
        ) {
            return getFullName(lastName, firstName, patronymic);
        }

        return fullName;
    };

    getContractorStatusClass = (contractor) => {
        const {
            registrationStatus,
            personalDataCheckStatus,
        } = contractor;
        const {isAdditionalDocuments} = this.state;

        if (isAdditionalDocuments) {
            return "";
        }

        if (personalDataCheckStatus?.checkInProgress) {
            return "nm-contractor-list__operator-in-progress";
        }

        const statuses = [
            CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
            CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
        ];

        if ((statuses.includes(registrationStatus)) && this.isNmUser) {
            return "nm-contractor-list__warning-row";
        }
        return "";
    };

    filterByLeadType = ({leadTypes = []}) => {
        const {
            filterData: {
                leadTypesFilter,
            },
        } = this.state;

        if (leadTypesFilter === CONTRACTOR_ON_CHECK_LEAD_TYPE.WITHOUT_LEADS) {
            return isEmpty(leadTypes);
        }

        if (leadTypesFilter === CONTRACTOR_ON_CHECK_LEAD_TYPE.SMZ) {
            return leadTypes.includes(CONTRACTOR_ON_CHECK_LEAD_TYPE.SMZ);
        }

        if (leadTypesFilter === CONTRACTOR_ON_CHECK_LEAD_TYPE.CIVIL) {
            return leadTypes.includes(CONTRACTOR_ON_CHECK_LEAD_TYPE.CIVIL);
        }

        return true;
    };

    get list() {
        const {
            selectedList,
            isOnCheckPage,
            isAdditionalDocuments,
        } = this.state;

        if (isOnCheckPage && !isAdditionalDocuments) {
            return selectedList.filter(item => this.filterByLeadType(item));
        }

        return selectedList;
    };

    mapTableData = () => {
        return this.list.map(item => {
            return {
                ...item,
                key: item.contractorId,
                isSelected: item.isSelected,
                showCheckBox: this.isShowCheckboxByContractorType(item),
                error: [FC_REGISTRIS_STATUS_DICT.DECLINED, FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED].includes(item.status),
                contentRow: this.renderContractorInfo(item),
                dynamicRowClassName: this.getContractorStatusClass(item),
                avatar: (
                    <Avatar
                        avatarPresent={item.avatarPresent}
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={item.contractorId}
                    />
                ),
            };
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handleOnClickBack = () => {
        history.push(LINK_ORDER_CARD.replace(":clientId", this.clientId)
            .replace(":orderId", this.orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK));
    };

    checkOrderContractorStatus = ({orderContractorStatus}) => {
        return isNullOrWhitespace(orderContractorStatus) ||
            [
                STATUS.CLIENT_REVOKED,
                STATUS.CONTRACTOR_DECLINED,
                STATUS.CLIENT_DECLINED,
                STATUS.DECLINED,
            ].includes(orderContractorStatus);
    };

    onSelectedRows = (selectedList) => {
        const {
            clientType,
        } = this.props;

        const {
            contractorsType,
        } = this.state;


        const selectedContractors = selectedList.filter(item => item.isSelected);
        const selectedListCounter = selectedContractors.length;

        /*
        Заблокированных на заказ не приглашаем
         */
        const selectedInvitedContractors = selectedContractors.filter(item => this.checkOrderContractorStatus(item) && !item.blocked);
        const selectedInvitedContractorsIds = selectedInvitedContractors.map((item) => {
            return item.contractorId;
        });
        let selectedFavoriteOrBlacklistContractorsIds = [];
        /*
        На странице поиска добавляем список для добавления в избранное
         */
        if ([CONTRACTORS_TABS_TYPES.ALL].includes(contractorsType)) {
            const selectedFavoriteContractors = selectedContractors.filter(item => !item.favourite && !item.blacklist);
            selectedFavoriteOrBlacklistContractorsIds = selectedFavoriteContractors.map((item) => {
                return item.contractorId;
            });
        }

        /*
        На странице ибзранных или чёрного списка добавляем список для удаления
         */
        if ([CONTRACTORS_TABS_TYPES.FAVORITE, CONTRACTORS_TABS_TYPES.BLACK_LIST].includes(contractorsType)) {
            selectedFavoriteOrBlacklistContractorsIds = selectedContractors.map((item) => {
                return item.contractorId;
            });
        }

        //список фио выбранных пока не используется
        // const contractorNameList = selectedContractors.map((item) => {
        //     return item.fullName
        // }).join(', ');
        const contractorNameList = "";

        const isShowInviteContractorsWarning = showInviteContractorsWarning(selectedInvitedContractors, clientType);


        this.setState({
            selectedList,
            selectedListCounter,
            contractorNameList,
            selectedInvitedContractorsIds,
            selectedFavoriteOrBlacklistContractorsIds,
            isShowInviteContractorsWarning,
        });
    };

    onOpenFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            openFilter: !prevState.openFilter,
        }));
    };

    handleShowAddContractorForm = (isShow) => {
        return () => {
            this.setState({
                isShowAddContractorForm: isShow,
            });
        };
    };

    handleShowImportContractorsForm = (isShow) => {
        this.setState({
            isShowImportContractorForm: isShow,
        });
    };

    uploadFile = ({file}) => {
        const {importContractorsRegistry} = this.props;

        const formData = new FormData();

        formData.append("file", file);

        importContractorsRegistry({
            formData,
            onSuccess: () => {
                this.handleShowImportContractorsForm(false);
                this.getTasks();
                toastSuccess("Задача по импорту исполнителей передана в обработку");
            },
        });
    };

    getClassList = () => {
        const {
            isInvitingContractorsPage,
            isClientPage,
        } = this.state;

        const classes = ["nm-contractor-list"];

        if (this.showTableCheckboxes()) {
            classes.push("nm-contractor-list_client");
        } else {
            isShowUnitedContractorListFilterForNmUser(this.role, isClientPage, isInvitingContractorsPage) && classes.push("nm-contractor-list_admin");
        }

        return classes.join(" ");
    };

    showTableCheckboxes = () => {
        const {
            isClientPage,
        } = this.state;

        return isShowContractorListCheckBoxes(this.role, isClientPage);
    };

    get isStopListPage() {
        const {contractorsType} = this.state;

        return contractorsType === CONTRACTORS_TABS_TYPES.STOP_LIST;
    };

    get isOnboardingPage() {
        const {contractorsType} = this.state;

        return contractorsType === CONTRACTORS_TABS_TYPES.ONBOARDING;
    };

    getVisibleInviteContractorToNm() {
        const {
            isClientPage,
            isInvitingContractorsPage,
        } = this.state;
        if (isInvitingContractorsPage) {
            return true;
        }
        return !this.isClientArchived && isClientPage && isAcceptedAddContractorToNmByEmailAndSms(this.role);
    }

    getMediaActions = () => {
        const {
            currentUserRestrictions,
            t,
        } = this.props;

        const isAccessAdd = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.addContractors);

        const inviteContractorToNm = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.handleChange({openAddContractorForm: true}),
                children: t("contractor-list.invite-contractor-to-nm"),
                icon: <PeopleIcon />,
            },
            visible: this.getVisibleInviteContractorToNm(),
        };

        const addContractor = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.handleChange({isShowAddContractorForm: true}),
                children: t("contractor-list.add-contractor-button"),
                icon: <AddIcon />,
            },
            visible: !this.isClientArchived
                && isAccessAdd
                && isAcceptedAddingContractorByForm(this.role, !isNullOrWhitespace(this.clientId)),
        };

        const importContractors = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => this.handleShowImportContractorsForm(true),
                children: "Загрузить исполнителей",
            },
            visible: !this.isClientArchived
                && isAccessAdd
                && [ADMIN, NM_MANAGER, NM_OPERATOR].includes(this.role),
        };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 3,
            },
            buttons: [
                inviteContractorToNm,
                addContractor,
                importContractors,
            ],
        };
    };

    renderAdvSearchInfoBlock() {
        const {
            secondTryingSearchIndicator,
            list,
        } = this.props;

        if (!secondTryingSearchIndicator || isEmpty(list)) {
            return null;
        }

        return (
            <div
                className="nm-contractor-list__adv-search-info"
            >
                <div className="nm-contractor-list__adv-search-info-header">
                    Поиск по заданным параметрам не дал
                    результатов.
                </div>
                <div className="nm-contractor-list__adv-search-info-text">
                    Отображаются исполнители, которые совпадают с
                    параметрами поиска по номеру телефона или ФИО
                </div>
            </div>
        );
    };

    getContractorMediaActions = (contractor) => {
        const {
            isInvitingContractorsPage,
            isClientPage,
            contractorsType,
        } = this.state;

        const {
            clientType,
            currentMember,
        } = this.props;

        const {
            favourite,
            blacklist,
        } = contractor;

        const mobileFavouriteText = {children: favourite ? "Удалить из избранных" : "Добавить в избранные"};

        const addToFavourite = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: favourite ?
                    this.handleChange({
                        action: this.getActions().DELETE_FROM_FAVORITES(contractor),
                        isShowConfirmWindow: true,
                    }) :
                    this.handleChange({
                        action: this.getActions().ADD_FAVORITE_CONTRACTOR(contractor),
                        isShowConfirmWindow: true,
                    }),
                icon: favourite ? <CheckedFavouriteIcon /> : <FavouriteIcon />,
                onlyIcon: true,
                color: "grey",
            },
            visible: isAcceptedAddFavoritesContractor(this.role, isClientPage) &&
                [CONTRACTORS_TABS_TYPES.ALL, CONTRACTORS_TABS_TYPES.FAVORITE].includes(contractorsType),
            asset: {
                mobile: mobileFavouriteText,
                tablet: mobileFavouriteText,
            },

        };

        const mobileBlackListText = {children: blacklist ? "Удалить из чёрного списка" : "Добавить в чёрный список"};

        const addToBlackList = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: blacklist ?
                    this.handleChange({
                        action: this.getActions().DELETE_CONTRACTOR_FROM_BLACK_LIST(contractor),
                        isShowConfirmWindow: true,
                    }) :
                    this.handleChange({
                        action: this.getActions().ADD_CONTRACTOR_TO_BLACK_LIST(contractor),
                        isShowConfirmWindow: true,
                    }),
                icon: blacklist ? <CheckedBlackListIcon /> : <BlackListIcon />,
                onlyIcon: true,
                color: "grey",
            },
            visible: isAcceptedAddBlacklistedContractor(this.role, isClientPage) &&
                [CONTRACTORS_TABS_TYPES.ALL, CONTRACTORS_TABS_TYPES.BLACK_LIST].includes(contractorsType),
            asset: {
                mobile: mobileBlackListText,
                tablet: mobileBlackListText,
            },

        };

        const downloadResumeText = {children: "Скачать резюме"};

        const downloadResume = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.handleDownloadResume(contractor),
                color: "grey",
                children: "Скачать резюме",
            },
            asset: {
                mobile: downloadResumeText,
                tablet: downloadResumeText,
            },
        };

        const inviteToOrderText = {children: "Пригласить на заказ"};

        const inviteToOrder = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.orderId ?
                    this.handleChange({
                        action: this.getActions().INVITE_CONTRACTOR_TO_ORDER(contractor, showInviteContractorsWarning([contractor], clientType)),
                        isShowConfirmWindow: true,
                    })
                    : this.handleChange({
                        showInviteContractorToOrderForm: true,
                        selectedContractor: contractor,
                    }),
                children: this.localizationData.inviteContractorToOrder,
            },
            visible:
                [
                    CONTRACTORS_TABS_TYPES.ALL,
                    CONTRACTORS_TABS_TYPES.FAVORITE,
                    CONTRACTORS_TABS_TYPES.MY_CONTRACTORS,
                    CONTRACTORS_TABS_TYPES.BY_ORDER,
                    CONTRACTORS_TABS_TYPES.ML_SEARCH,
                ].includes(contractorsType)
                && isAcceptedAddOrCloseContractorToOrder(this.role, isInvitingContractorsPage)
                && this.showInviteToOrderButton(contractor)
                && !currentMember.contractorInvitationProhibited,
            asset: {
                mobile: inviteToOrderText,
                tablet: inviteToOrderText,
            },
        };

        const cancelInvitingToOrderText = {children: this.localizationData.cancelInvite};

        const cancelInvitingToOrder = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.handleChange({
                    action: this.getActions().CANCEL_INVITE_CONTRACTOR_TO_ORDER(contractor),
                    isShowConfirmWindow: true,
                }),
                children: this.localizationData.cancelInvite,
            },
            visible: isAcceptedAddOrCloseContractorToOrder(this.role, isInvitingContractorsPage) && this.showCancelInviteToOrderButton(contractor),
            asset: {
                mobile: cancelInvitingToOrderText,
                tablet: cancelInvitingToOrderText,
            },

        };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 5,
            },
            buttons: [
                addToFavourite,
                addToBlackList,
                downloadResume,
                inviteToOrder,
                cancelInvitingToOrder,
            ],
        };
    };

    renderLink = (contractor) => {
        const {
            pageNum,
            pageSize,
            filterData,
            orderInfo,
        } = this.state;

        const {
            contractorId,
            status,
        } = contractor;

        const {
            specialityIdsFilter,
            addressFiasIdsFilter,
            initRegionValueArr,
            clientGroupClientIds,
        } = filterData;

        let link = LINK_CONTRACTOR_PROFILE;

        if (this.role === HR_MANAGER) {
            link = LINK_CONTRACTOR_REVIEWS_LIST;
        }

        if (RNKO === this.role || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        link = link.replace(":contractorId", contractorId);

        const contractorName = this.getContractorFullName(contractor);

        return (
            <ExtLink
                extData={
                    {
                        ...orderInfo,
                        specialityIdsFilter,
                        addressFiasIdsFilter,
                        initRegionValueArr,
                        clientGroupClientIds,
                    }
                }
                title={contractorName}
                pageData={{pageNum, pageSize}}
                filterData={
                    filterData
                }
                historyEnabled
                to={link}
            >
                {contractorName}
            </ExtLink>
        );
    };

    renderContractorInfo = (contractor, showTableCheckboxes) => {
        const {
            t,
            citizenshipList,
            contractorStatusDict,
            specialityOptions,
            specialityDict,
            educationLevelDictOptions,
            contractorsPossibility,
        } = this.props;

        const {
            openFilter,
            isOnCheckPage,
            isAdditionalDocuments,
        } = this.state;

        return (
            <ContractorListInfo
                contractor={contractor}
                role={this.role}
                renderLinkFnc={this.renderLink}
                t={t}
                contractorsPossibility={contractorsPossibility}
                citizenshipList={citizenshipList}
                contractorStatusDict={contractorStatusDict}
                specialityOptions={specialityOptions}
                specialityDict={specialityDict}
                educationLevelDictOptions={educationLevelDictOptions}
                showPaymentInfo={!isClientUser(this.role)}
                showTableCheckboxes={showTableCheckboxes}
                actions={this.getContractorMediaActions(contractor)}
                openFilter={openFilter}
                isShowLeadTypeLabel={isOnCheckPage && !isAdditionalDocuments}
            />
        );
    };

    renderAddContractorToNmForm() {
        const {
            openAddContractorForm,
        } = this.state;

        return (openAddContractorForm &&
            <InvitePerformerToNaimix
                close={this.handleChange({openAddContractorForm: false})}
                clientId={this.clientId}
            />
        );
    };

    renderConfirmWindow() {
        const {
            t,
        } = this.props;

        const {
            reason,
            reasonErrorMsg,
            isShowConfirmWindow,
            action,
        } = this.state;

        return (
            isShowConfirmWindow &&
            <NmConfirmV2
                content={
                    <>
                        {action.title}
                        <NmForm addMargin>
                            {!isEmpty(action.content) && action.content}
                            {
                                action.showReason &&
                                <NmTextareaV2
                                    className="nm-contractor-list__confirm-reason"
                                    placeholder={t("contractor-list.reason-placeholder")}
                                    name="reason"
                                    maxLength={255}
                                    error={reasonErrorMsg}
                                    value={reason}
                                    onChange={this.handleChangeField}
                                />
                            }
                        </NmForm>
                    </>
                }
                onCancel={this.handleChange({
                    isShowConfirmWindow: false,
                    reason: "",
                    reasonErrorMsg: null,
                })}
                onConfirm={action.isError ? this.handleChange({
                    isShowConfirmWindow: false,
                    reason: "",
                    reasonErrorMsg: null,
                }) : action.submit}
                confirmButton={action.isError ? t("button.close") : action.submitBtnContent}
                cancelButton={!action.isError && action.cancelBtnContent}
            />
        );
    };

    renderBaseContractorsFilter() {
        const {
            isInvitingContractorsPage,
            isClientPage,
            filterData,
        } = this.state;

        if (isInvitingContractorsPage) {
            return (
                <NmGeneralContractorListFilter
                    filterType={CONTRACTOR_LIST_FILTER_TYPE.INVITE_FILTER}
                    filter={filterData}
                    sendFilter={this.sendFilter}
                    isInvitingContractorsPage={isInvitingContractorsPage}
                />
            );
        }
        if (isClientPage || isClientUser(this.role)) {
            return (
                <NmGeneralContractorListFilter
                    filterType={CONTRACTOR_LIST_FILTER_TYPE.CLIENT_FILTER}
                    filter={filterData}
                    sendFilter={this.sendFilter}
                />
            );
        }

        return (
            <NmGeneralContractorListFilter
                filterType={CONTRACTOR_LIST_FILTER_TYPE.ADMIN_FILTER}
                filter={filterData}
                sendFilter={this.sendFilter}
            />
        );
    };

    renderInviteContractorToOrderForm() {
        const {
            clientType,
        } = this.props;

        const {
            showInviteContractorToOrderForm,
            selectedContractor,
            selectedInvitedContractorsIds,
            isShowInviteContractorsWarning,
            searchCriteria,
            isInvitingContractorsPage,
            filterData,
        } = this.state;

        const isFilterEmpty = this.isFilterDataEmpty(filterData);

        return (
            showInviteContractorToOrderForm &&
            <InviteContractorToOrderForm
                close={this.handleChange({
                    showInviteContractorToOrderForm: false,
                    selectedContractor: null,
                })}
                isMassOperation={!selectedContractor}
                contractorIds={selectedContractor ? [selectedContractor.contractorId] : selectedInvitedContractorsIds}
                isShowInviteContractorsWarning={selectedContractor ? showInviteContractorsWarning([selectedContractor], clientType) : isShowInviteContractorsWarning}
                clientId={this.clientId}
                searchCriteria={searchCriteria}
                isInvitingContractorsPage={isInvitingContractorsPage}
                isFilterEmpty={isFilterEmpty}
            />
        );
    };

    renderTabs() {
        const {
            pathname,
            clientSettings: {retailClient},
        } = this.props;

        const panes = NmContractorsTabs({
            clientId: this.clientId,
            projectId: this.projectId,
            orderId: this.orderId,
            pathname,
            role: this.role,
            retailClient,
        });

        return (
            <Tabs
                className="client-card-contractors__tabs"
                {...this.props}
                secondary
                panes={panes}
            />
        );
    };

    renderAddContractorForm() {
        const {isShowAddContractorForm} = this.state;

        if (isShowAddContractorForm) {
            return (
                <ContractorNew
                    handleCancel={this.handleShowAddContractorForm(false)}
                    fetchContractorList={this.fetchList}
                />
            );
        }
        return null;
    };

    getSelectionMediaControls = () => {
        const {
            selectedListCounter,
            selectedFavoriteOrBlacklistContractorsIds,
            isInvitingContractorsPage,
            selectedInvitedContractorsIds,
            contractorsType,
            isShowInviteContractorsWarning,
        } = this.state;

        const {
            canViewContractorContacts,
            currentMember,
        } = this.props;

        return {
            renderCount: {
                desktop: 7,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        onClick: this.handleChange({
                            action: this.getActions().ADD_SOME_FAVORITE_CONTRACTORS(selectedFavoriteOrBlacklistContractorsIds.length),
                            isShowConfirmWindow: true,
                        }),
                        children: this.localizationData.addInFavorites,
                        disabled: selectedListCounter === 0,
                    },
                    visible: [CONTRACTORS_TABS_TYPES.ALL].includes(contractorsType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        onClick: this.handleChange({
                            action: this.getActions().DELETE_SOME_CONTRACTORS_FROM_FAVORITES(""),
                            isShowConfirmWindow: true,
                        }),
                        children: this.localizationData.deleteFromFavorites,
                        disabled: selectedListCounter === 0,
                    },
                    visible: [CONTRACTORS_TABS_TYPES.FAVORITE].includes(contractorsType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        onClick: this.handleChange({
                            action: this.getActions().DELETE_SOME_CONTRACTORS_FROM_BLACK_LIST(""),
                            isShowConfirmWindow: true,
                        }),
                        children: this.localizationData.deleteFromBlackList,
                        disabled: selectedListCounter === 0,
                    },
                    visible: [CONTRACTORS_TABS_TYPES.BLACK_LIST].includes(contractorsType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        onClick: isInvitingContractorsPage ? this.handleChange({
                            action: this.getActions().INVITE_SOME_CONTRACTORS_TO_ORDER(selectedInvitedContractorsIds.length, isShowInviteContractorsWarning),
                            isShowConfirmWindow: true,
                        }) : this.handleChange({
                            showInviteContractorToOrderForm: true,
                        }),
                        children: this.localizationData.inviteContractorToOrder,
                        disabled: selectedListCounter === 0,
                    },
                    visible: [
                        CONTRACTORS_TABS_TYPES.ALL,
                        CONTRACTORS_TABS_TYPES.FAVORITE,
                        CONTRACTORS_TABS_TYPES.BLACK_LIST,
                        CONTRACTORS_TABS_TYPES.ML_SEARCH,
                        CONTRACTORS_TABS_TYPES.BY_ORDER,
                        CONTRACTORS_TABS_TYPES.MY_CONTRACTORS,
                    ].includes(contractorsType)
                        && isAcceptedMassAddContractorToOrder(this.role, isInvitingContractorsPage, canViewContractorContacts)
                        && !currentMember.contractorInvitationProhibited,
                },
            ],
        };
    };

    renderTable(showTableCheckboxes) {
        const {
            selectedList,
            selectedListCounter,
            isSearch,
            isInvitingContractorsPage,
        } = this.state;

        const {
            progressList,
        } = this.pageData;
        /*
        для Админов  НМ при пустом списке показываем заглушку
         */
        if (isEmpty(selectedList) && !progressList) {
            return (
                <NmEmptyPageV2
                    isSearch={isInvitingContractorsPage ? true : isSearch}
                />
            );
        }

        return (
            <CheckboxList
                isShowCheckboxLabel
                rows={this.mapTableData(showTableCheckboxes)}
                onSelectedRows={showTableCheckboxes && this.onSelectedRows}
                className="nm-contractor-list__table"
                count={selectedListCounter}
                mediaControls={showTableCheckboxes && this.getSelectionMediaControls()}
            />
        );
    };

    renderStopList() {
        return (
            <StopList
                clientId={this.clientId}
            >
                {(this.renderTabs())}
            </StopList>
        );
    };

    renderOnboarding() {
        return (
            <OnboardingList
                clientId={this.clientId}
                tabs={(this.renderTabs())}
            />
        );
    };

    getTitle() {
        const {
            isInvitingContractorsPage,
            isOnCheckPage,
            orderInfo: {
                name,
                orderNum,
            },
        } = this.state;

        const {t, title} = this.props;

        if (title) {
            return title;
        }

        if (isOnCheckPage) {
            return "Исполнители на проверке";
        }

        if (isInvitingContractorsPage) {
            return `${t("contractor-list.search-contractors-by-order")} №${orderNum} ${name}`;
        }

        return t("contractor-list.contractor-list-title");
    }

    renderImportGroupOrdersForm = () => {
        const {bffActionProgress} = this.props;
        const {
            isShowImportContractorForm,
        } = this.state;

        return (
            isShowImportContractorForm &&
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Исполнителей.xlsx"
                onSubmit={this.uploadFile}
                onClose={() => this.handleShowImportContractorsForm(false)}
                progress={bffActionProgress}
            />
        );
    };

    renderSubHeader = () => {
        const {
            match: {path},
        } = this.props;

        const {
            isOnCheckPage,
        } = this.state;

        if (isOnCheckPage) {
            return (
                <Tabs
                    panes={[
                        {
                            name: "Исполнители на проверке",
                            link: LINK_CLIENT_ON_CHECK_CONTRACTORS,
                            active: path.includes("on-check"),
                        },
                        {
                            name: "Дополнительные документы",
                            link: LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
                            active: path.includes("additional-documents"),
                        },
                    ]}
                />
            );
        }
    };

    renderLeadTypesFilter = () => {
        const {
            isOnCheckPage,
            isAdditionalDocuments,
            filterData,
        } = this.state;

        if (!isOnCheckPage || isAdditionalDocuments) {
            return null;
        }

        return (
            <div className="flex flex-wrap align-items-center mb-4">
                <Text>
                    Показать по типу лида:
                </Text>
                {
                    CONTRACTOR_ON_CHECK_LEAD_TYPES_FILTER.map(item => {
                        return (
                            <NmRadioV2
                                className="ms-2"
                                label={item.label}
                                checked={filterData.leadTypesFilter === item.value}
                                value={item.value}
                                onChange={() => this.setState(prevState => ({
                                    ...prevState,
                                    filterData: {
                                        ...prevState, filterData,
                                        leadTypesFilter: item.value,
                                    },
                                }))}
                            />
                        );
                    })
                }
            </div>
        );
    };

    renderContent = () => {
        const {
            pageSize,
            pageNum,
            pageSizes,
            isInvitingContractorsPage,
            isClientPage,
            openFilter,
        } = this.state;

        const {
            orderActionProgress,
            orderProgress,
        } = this.props;

        const {
            totalCount,
            totalPages,
            progressList,
            actionProgress,
        } = this.pageData;

        const showTableCheckboxes = this.showTableCheckboxes();

        if (this.isOnboardingPage) {
            return this.renderOnboarding();
        }

        if (this.isStopListPage) {
            return this.renderStopList();
        }

        return (
            <NmPage
                widthByFilter
                openFilter={openFilter}
                onOpenFilter={this.onOpenFilter}
                titleClassName="nm-contractor-list__title"
                header={
                    <NmPageHeader
                        text={this.getTitle()}
                        totalCount={totalCount}
                        handleOnClickBack={isInvitingContractorsPage ? this.handleOnClickBack : null}
                    />
                }
                subHeader={this.renderSubHeader()}
                mediaControls={this.getMediaActions()}
                filtersBase={
                    this.renderBaseContractorsFilter()
                }
                typeFilter="vertical"
                isLoaded={progressList || actionProgress || orderActionProgress || orderProgress}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                pageSizes={pageSizes}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                {isShowClientContractorListTabs(this.role, isClientPage) && this.renderTabs()}
                {this.renderAdvSearchInfoBlock()}
                {this.renderLeadTypesFilter()}
                {this.renderTable(showTableCheckboxes)}
            </NmPage>
        );
    };

    render() {
        return (
            <div className={this.getClassList()}>
                <OrderContractorsErrors />
                {this.renderImportGroupOrdersForm()}
                {this.renderAddContractorToNmForm()}
                {this.renderInviteContractorToOrderForm()}
                {this.renderAddContractorForm()}
                {this.renderConfirmWindow()}
                {this.renderContent()}
            </div>
        );
    };
}

export default withPageData(connect(
    state => ({
        list: contractorListSelector(state),
        bffList: bffContractorsRegistryListSelector(state),
        secondTryingSearchIndicator: contractorsSecondTryingIndicatorSelector(state),
        progressList: contractorProgressSelector(state),
        totalPages: contractorTotalPagesSelector(state),
        totalCount: contractorTotalCountSelector(state),
        bffProgressList: bffContractorsRegistryProgressSelector(state),
        bffTotalPages: bffContractorsRegistryTotalPagesSelector(state),
        bffTotalCount: bffContractorsRegistryTotalCountSelector(state),
        citizenshipList: getCitizenshipSelector(state),
        canViewContractorContacts: clientCardPropertiesCanViewContractorsContactsSelector(state),
        clientType: getClientTypeSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        specialityDict: specialitiesAllV2DictSelector(state),
        pathname: state.router.location.pathname,
        actionProgress: contractorActionProgressSelector(state),
        bffActionProgress: bffContractorsRegistryActionSelector(state),
        order: getOrderCardSelector(state),
        orderActionProgress: orderProgressListSelector(state),
        contractorStatusDict: contractorStatusDictSelector(state),
        orderProgress: getProgressOrderCardSelector(state),
        orderAddress: adressByIdSelector(state),
        orderAddressProgress: adressByIdProgressSelector(state),
        clientSettings: clientCardPropertiesSelector(state),
        client: clientCardInfoSelector(state),
        educationLevelDictOptions: contractorEducationLevelDictOptionsSelector(state),
        contractorsPossibility: contractorsPaymentPossibilityListSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        importContractorsProgress: importContractorsProgressSelector(state),
        recommendationModelType: recommendationModelTypeSelector(state),
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        addFavoriteContractors,
        deleteFavoriteContractors,
        addContractorToBlackList,
        deleteContractorFromBlackList,
        getContractorRegistrationStates,
        addSeveralOrderContractors,
        getHiringContractorOptimizedList,
        addOrderContractor,
        rejectContractorByOrder,
        getOrderRichById,
        updateFieldOrderStore,
        getContractorEducationLevelDict,
        getClientCardProperties,
        getContractorProfileResumeFile,
        getContractorStatusDict,
        getWorkSchedulesByContractorId,
        updateFieldsContractorPaymentPossibilityState,
        getContractorOrderStatusDict,
        getContractorLanguagesDict,
        getDriverLicenseDictionary,
        getProjectParamList,
        getAddressById,
        getContractorsPaymentPossibility,
        getClientGroupPage,
        updateFieldContractorStore,
        getPendingAdditionalDocumentsRichPage,
        importContractorsRegistry,
        getAllTasks,
        updateStoreContractorsRegistry,
        getPageContractorsRegistry,
        getClientUserRestrictionsList,
    },
)(withTranslation()(NmContractorList)));