import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const newController = "/client/categories";
//*  TYPES  *//

const UPDATE_FIELD_ORDER_CATEGORY_LIST = "UPDATE_FIELD_ORDER_CATEGORY_LIST";

const GET_ORDER_CATEGORY_ALL_REQUEST = "GET_ORDER_CATEGORY_ALL_REQUEST";
const GET_ORDER_CATEGORY_ALL_SUCCESS = "GET_ORDER_CATEGORY_ALL_SUCCESS";
const GET_ORDER_CATEGORY_ALL_ERROR = "GET_ORDER_CATEGORY_ALL_ERROR";

//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    progressPage: false,
    progress: false,
    totalCount: 0,
    orderCategoryList: [],
    isActionSuccess: false,
    orderCategoriesAll: [],
    categoryCard: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ORDER_CATEGORY_ALL_SUCCESS:
            return {
                ...state,
                orderCategoriesAll: payload,
            };
        case UPDATE_FIELD_ORDER_CATEGORY_LIST:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function updateFieldOrderCategory(payload) {
    return {
        type: UPDATE_FIELD_ORDER_CATEGORY_LIST,
        payload,
    };
}

export function getOrderCategoriesAll() {
    return {
        type: GET_ORDER_CATEGORY_ALL_REQUEST,
    };
}

//*  SELECTORS  *//
export const orderCategorySelector = state => state.orderCategory;
export const getOrderCategoriesActionSuccessSelector = createSelector(orderCategorySelector, ({isActionSuccess}) => isActionSuccess);
export const orderCategoriesAllSelector = createSelector(orderCategorySelector, ({orderCategoriesAll}) => orderCategoriesAll);
export const getOrderCategoriesAllOptionsSelector = createSelector(orderCategorySelector, ({orderCategoriesAll}) => {
    return orderCategoriesAll.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.value,
        };
    });
});

//*  SAGA  *//

export const getOrderCategoryAllSaga = function* (action) {
    try {
        const result = yield request.get(`${newController}/all`);
        const {errorMessage} = result;
        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_ORDER_CATEGORY_ALL_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        yield put({type: GET_ORDER_CATEGORY_ALL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ORDER_CATEGORY_ALL_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ORDER_CATEGORY_ALL_REQUEST, getOrderCategoryAllSaga),
    ]);
}
