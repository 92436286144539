import {
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST,
    SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS,
    UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressCard: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_GET_CARD_ERROR:
            return {
                ...state,
                error: payload,
                progressCard: false,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                list: payload.supports,
                progress: false,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_GET_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_CONTRACTOR_SUPPORT_UPDATE_SUCCESS:
        case SETTINGS_CONTRACTOR_SUPPORT_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case UPDATE_SETTINGS_CONTRACTOR_SUPPORT_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};