import {
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST, BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_NAIMIX_UPDATE_STORE,
} from "./actions";

export const saveNaimixInstruction = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_SAVE_REQUEST,
        payload,
    };
};

export const addNaimixInstructionFile = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_ADD_FILE_REQUEST,
        payload,
    };
};

export const getNaimixInstructions = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_GET_LIST_REQUEST,
        payload,
    };
};

export const clearNaimixInstructionStore = () => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_NAIMIX_CLEAR_STORE,
    };
};