export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR =  "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR";

export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR =  "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR";

export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS";
export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR =  "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR";

export const BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE = "BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_CLEAR_STORE";