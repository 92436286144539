import axios from "axios";
import {isEmpty} from "lodash";
import {all, call,put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getNonDecisionCount} from "./clientSpecialityOffer";
import {getContractorCount} from "./contractor";
import {getProjectById} from "./projects";

import {getWorkAddressInfoArr} from "../utils/dadata";
import {
    ls,
    USER_ROLE,
} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {transformIndustryProfessions} from "../utils/professionHelper";
import {getFullName} from "../utils/stringFormat";
import {isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffUrl} from "../utils/url";

import {ORDER_STATUS} from "../constants/clientList";
import {ORDER} from "../constants/messages";
import {
    ORDER_ERROR_CODE,
    ORDER_TEMPLATE_ARCHIVE,
} from "../constants/order";
import {
    ADMIN, FOREMAN,
    NM_MANAGER,
} from "../constants/roles";

const controller = "/orders";

export const FIELD_NAME_ORDER_LIST_ENUM = {
    TEMPLATE: {
        FIELD_LIST: "templatesListRich",
        PAGE_DATA: "pageDataTemplatesRich",
        TOTAL_COUNT: "totalCountTemplatesRich",
    },
    DEFAULT: {
        FIELD_LIST: "list",
        PAGE_DATA: "pageData",
        TOTAL_COUNT: "totalCount",
    },
};

export const ORDERS_ARCHIVED_SUCCESS_MSG = {
    pageData: {
        archived: "Заказ успешно добавлен в архив",
        unArchive: "Заказ успешно восстановлен",
    },
    pageDataTemplatesRich: {
        archived: "Шаблон заказов успешно добавлен в архив",
        unArchive: "Шаблон успешно восстановлен",
    },
};

//*  TYPES  *//

const GET_FINANCE_PAGE_REQUEST = "GET_FINANCE_PAGE_REQUEST";
const GET_FINANCE_PAGE_SUCCESS = "GET_FINANCE_PAGE_SUCCESS";
const GET_FINANCE_PAGE_ERROR = "GET_FINANCE_PAGE_ERROR";

const ADD_ORDER_RECOMMEND_STAT_REQUEST = "ADD_ORDER_RECOMMEND_STAT_REQUEST";
const ADD_ORDER_RECOMMEND_STAT_SUCCESS = "ADD_ORDER_RECOMMEND_STAT_SUCCESS";
const ADD_ORDER_RECOMMEND_STAT_ERROR = "ADD_ORDER_RECOMMEND_STAT_ERROR";

const GET_ORDER_FINANCE_REQUEST = "GET_ORDER_FINANCE_REQUEST";
const GET_ORDER_FINANCE_SUCCESS = "GET_ORDER_FINANCE_SUCCESS";
const GET_ORDER_FINANCE_ERROR = "GET_ORDER_FINANCE_ERROR";

const ORDER_CARD_REQUEST = "ORDER_CARD_REQUEST";
const ORDER_CARD_SUCCESS = "ORDER_CARD_SUCCESS";
const ORDER_CARD_ERROR = "ORDER_CARD_ERROR";

const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
const ORDER_LIST_ERROR = "ORDER_LIST_ERROR";

const ORDER_LIST_FOR_INVITATION_REQUEST = "ORDER_LIST_FOR_INVITATION_REQUEST";
const ORDER_LIST_FOR_INVITATION_SUCCESS = "ORDER_LIST_FOR_INVITATION_SUCCESS";
const ORDER_LIST_FOR_INVITATION_ERROR = "ORDER_LIST_FOR_INVITATION_ERROR";

const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
const ORDER_CREATE_ERROR = "ORDER_CREATE_ERROR";

const CONTRACTOR_STATUS_LIST_REQUEST = "CONTRACTOR_STATUS_LIST_REQUEST";
const CONTRACTOR_STATUS_LIST_SUCCESS = "CONTRACTOR_STATUS_LIST_SUCCESS";
const CONTRACTOR_STATUS_LIST_ERROR = "CONTRACTOR_STATUS_LIST_ERROR";

const ORDER_STATUS_LIST_REQUEST = "ORDER_STATUS_LIST_REQUEST";
const ORDER_STATUS_LIST_SUCCESS = "ORDER_STATUS_LIST_SUCCESS";
const ORDER_STATUS_LIST_ERROR = "ORDER_STATUS_LIST_ERROR";

const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
const ORDER_UPDATE_ERROR = "ORDER_UPDATE_ERROR";

const ORDER_ARCHIVE_UPDATE_REQUEST = "ORDER_ARCHIVE_UPDATE_REQUEST";
const ORDER_ARCHIVE_UPDATE_SUCCESS = "ORDER_ARCHIVE_UPDATE_SUCCESS";
const ORDER_ARCHIVE_UPDATE_ERROR = "ORDER_ARCHIVE_UPDATE_ERROR";

const ORDER_UPDATE_FIELD_STORE = "ORDER_UPDATE_FIELD_STORE";

const ORDER_COMPLETE_REQUEST = "ORDER_COMPLETE_REQUEST";

const UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST = "UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST";
const UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS = "UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS";
const UPDATE_ORDER_CONTRACTORS_FOUND_ERROR = "UPDATE_ORDER_CONTRACTORS_FOUND_ERROR";

const ORDER_COMPLETE_SUCCESS = "ORDER_COMPLETE_SUCCESS";
const ORDER_COMPLETE_ERROR = "ORDER_COMPLETE_ERROR";

const CONTRACTOR_PROFESSIONS_REQUEST = "CONTRACTOR_PROFESSIONS_REQUEST";
const CONTRACTOR_PROFESSIONS_SUCCESS = "CONTRACTOR_PROFESSIONS_SUCCESS";

const CLEAR_ORDER_STORE = "CLEAR_ORDER_STORE";

const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
const ORDER_HISTORY_ERROR = "ORDER_HISTORY_ERROR";

const ORDER_INDUSTRY_PROFESSIONS_REQUEST = "ORDER_INDUSTRY_PROFESSIONS_REQUEST";
const ORDER_INDUSTRY_PROFESSIONS_SUCCESS = "ORDER_INDUSTRY_PROFESSIONS_SUCCESS";
const ORDER_INDUSTRY_PROFESSIONS_ERROR = "ORDER_INDUSTRY_PROFESSIONS_ERROR";

const ORDER_EDIT_REQUEST = "ORDER_EDIT_REQUEST";
const ORDER_EDIT_SUCCESS = "ORDER_EDIT_SUCCESS";
const ORDER_EDIT_ERROR = "ORDER_EDIT_ERROR";

const ORDER_TOGGLE_CARD = "ORDER_TOGGLE_CARD";

const ORDER_CARD_RICH_REQUEST = "ORDER_CARD_RICH_REQUEST";
const ORDER_CARD_RICH_SUCCESS = "ORDER_CARD_RICH_SUCCESS";
const ORDER_CARD_RICH_ERROR = "ORDER_CARD_RICH_ERROR";

const ORDER_RICH_LIST_REQUEST = "ORDER_RICH_LIST_REQUEST";
const ORDER_RICH_LIST_SUCCESS = "ORDER_RICH_LIST_SUCCESS";
const ORDER_RICH_LIST_ERROR = "ORDER_RICH_LIST_ERROR";

const ORDER_TOGGLE_ARCHIVED = "ORDER_TOGGLE_ARCHIVED";

const ORDER_TEMPLATES_RICH_LIST_REQUEST = "ORDER_TEMPLATES_RICH_LIST_REQUEST";

const ORDER_ANALYTICS_REQUEST = "ORDER_ANALYTICS_REQUEST";
const ORDER_ANALYTICS_SUCCESS = "ORDER_ANALYTICS_SUCCESS";
const ORDER_ANALYTICS_ERROR = "ORDER_ANALYTICS_ERROR";

const ORDER_ANALYTICS_PAGE_REQUEST = "ORDER_ANALYTICS_PAGE_REQUEST";
const ORDER_ANALYTICS_PAGE_SUCCESS = "ORDER_ANALYTICS_PAGE_SUCCESS";
const ORDER_ANALYTICS_PAGE_ERROR = "ORDER_ANALYTICS_PAGE_ERROR";

const GET_FOREMAN_PAYMENT_STATUSES_REQUEST = "GET_FOREMAN_PAYMENT_STATUSES_REQUEST";
const GET_FOREMAN_PAYMENT_STATUSES_SUCCESS = "GET_FOREMAN_PAYMENT_STATUSES_SUCCESS";
const GET_FOREMAN_PAYMENT_STATUSES_ERROR = "GET_FOREMAN_PAYMENT_STATUSES_ERROR";

const GET_FOREMAN_REQUEST_STATUSES_REQUEST = "GET_FOREMAN_REQUEST_STATUSES_REQUEST";
const GET_FOREMAN_REQUEST_STATUSES_SUCCESS = "GET_FOREMAN_REQUEST_STATUSES_SUCCESS";
const GET_FOREMAN_REQUEST_STATUSES_ERROR = "GET_FOREMAN_REQUEST_STATUSES_ERROR";

const GET_FOREMAN_REQUEST_TYPES_REQUEST = "GET_FOREMAN_REQUEST_TYPES_REQUEST";
const GET_FOREMAN_REQUEST_TYPES_SUCCESS = "GET_FOREMAN_REQUEST_TYPES_SUCCESS";
const GET_FOREMAN_REQUEST_TYPES_ERROR = "GET_FOREMAN_REQUEST_TYPES_ERROR";

const ORDER_CLEAR_FIELD_STORE = "ORDER_CLEAR_FIELD_STORE";

const GET_ORDER_SHARED_BY_ID_REQUEST = "GET_ORDER_SHARED_BY_ID_REQUEST";
const GET_ORDER_SHARED_BY_ID_SUCCESS = "GET_ORDER_SHARED_BY_ID_SUCCESS";
const GET_ORDER_SHARED_BY_ID_ERROR = "GET_ORDER_SHARED_BY_ID_ERROR";

const GET_ORDER_WORK_UNITS_REQUEST = "GET_ORDER_WORK_UNITS_REQUEST";
const GET_ORDER_WORK_UNITS_SUCCESS = "GET_ORDER_WORK_UNITS_SUCCESS";
const GET_ORDER_WORK_UNITS_ERROR = "GET_ORDER_WORK_UNITS_ERROR";

const GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST = "GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST";
const GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS = "GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS";
const GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR = "GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR";

const ORDER_LIST_FOR_TEMPLATES_REQUEST = "ORDER_LIST_FOR_TEMPLATES_REQUEST";

const ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST = "ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST";
const ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS = "ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS";
const ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR = "ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR";

const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST";
const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS";
const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR";

const ORDER_DELETE_DRAFT_REQUEST = "ORDER_DELETE_DRAFT_REQUEST";
const ORDER_DELETE_DRAFT_ERROR = "ORDER_DELETE_DRAFT_ERROR";

const ORDER_GET_REGISTRY_ORDERS_REQUEST = "ORDER_GET_REGISTRY_ORDERS_REQUEST";
const ORDER_GET_REGISTRY_ORDERS_SUCCESS = "ORDER_GET_REGISTRY_ORDERS_SUCCESS";
const ORDER_GET_REGISTRY_ORDERS_ERROR = "ORDER_GET_REGISTRY_ORDERS_ERROR";

const ORDER_GET_REGISTRY_ORDERS_AMOUNT_REQUEST = "ORDER_GET_REGISTRY_ORDERS_AMOUNT_REQUEST";
const ORDER_GET_REGISTRY_ORDERS_AMOUNT_SUCCESS = "ORDER_GET_REGISTRY_ORDERS_AMOUNT_SUCCESS";
const ORDER_GET_REGISTRY_ORDERS_AMOUNT_ERROR = "ORDER_GET_REGISTRY_ORDERS_AMOUNT_ERROR";

const GET_SEARCH_ORDER_PAGE_REQUEST = "GET_SEARCH_ORDER_PAGE_REQUEST";
const GET_SEARCH_ORDER_PAGE_SUCCESS = "GET_SEARCH_ORDER_PAGE_SUCCESS";
const GET_SEARCH_ORDER_PAGE_ERROR = "GET_SEARCH_ORDER_PAGE_ERROR";

const GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST = "GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST";
const GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS = "GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS";
const GET_DEPOSIT_HISTORY_APIS_DICT_ERROR = "GET_DEPOSIT_HISTORY_APIS_DICT_ERROR";

const SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST = "SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST";
const SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS = "SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS";
const SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR = "SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR";

const BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST = "UPDATE_ORDER_CONTRACTOR_PIN_REQUEST";
const BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS = "UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS";
const BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR = "UPDATE_ORDER_CONTRACTOR_PIN_ERROR";

const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST = "BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS = "BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS";
const BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR = "BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR";

const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR";

const BFF_ORDER_MANAGERS_REQUEST = "BFF_ORDER_MANAGERS_REQUEST";
const BFF_ORDER_MANAGERS_SUCCESS = "BFF_ORDER_MANAGERS_SUCCESS";
const BFF_ORDER_MANAGERS_ERROR = "BFF_ORDER_MANAGERS_ERROR";

const BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST = "BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST";
const BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS = "BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS";
const BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR = "BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    registryOrdersAmount: {
        commission: 0,
        amount: 0,
    },
    progressRegistryOrdersAmount: false,
    list: [],
    card: {},
    orders: [],
    events: [],
    orderFinance: {},
    listFinance: [],
    financePageData: {
        pageNum: 1,
        pageSize: 25,
        totalCount: 0,
    },
    isOpenOrderCard: false,
    historyPageData: {
        pageNum: 1,
        pageSize: 25,
        totalCount: 0,
    },
    pageData: {
        pageNum: 1,
        pageSize: 25,
        statusFilter: [ORDER_STATUS.DRAFT, ORDER_STATUS.PUBLISHED],
        isSearch: false,
    },
    contractorOrderList: [],
    totalCount: 0,
    totalCountContractorOrderList: 0,
    pageDataContractorOrderList: {
        pageNum: 1,
        pageSize: 25,
    },
    error: null,
    createdOrderInfo: {},
    isSuccessAdd: false,
    contractorStatusList: {},
    orderStatusList: {},
    industryProfessions: {},
    progress: false,
    updateContractorsFoundProgress: false,
    progressCard: false,
    professions: {},
    statusFilter: [],
    progressGetList: false,
    completeProgress: false,
    orderListByIds: [],
    orderAnalytics: {},
    orderAnalyticsPage: {
        orderAnalyticsResultModels: [],
    },
    orderCreationData: {},
    registryOrders: [],
    progressRegistryOrders: false,
    registryOrdersPageData: {
        pageNum: 1,
        pageSize: 25,
    },
    registryOrdersTotalCount: 0,
    isOrdersArchived: false,
    foremanPaymentStatusesDict: [],
    foremanRequestStatusesDict: [],
    foremanRequestTypesDict: [],
    orderWorkUnitsDict: [],
    warningOrderList: [],
    sharedOrder: {},
    progressUpdate: false,
    progressAction: false,
    orderTemplates: [],
    orderTemplatesProgress: false,
    checkMultipleProgress: true,
    checkMultipleList: [],
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.FIELD_LIST]: [],
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.TOTAL_COUNT]: 0,
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.PAGE_DATA]: {},
    progressFinance: false,
    depositHistoryApisDict: {},
    orderManagers: [],
    applicationCountMap: {},
    orderContractorPageData: {},
    orderContractorList: [],
    orderContractorTotalCount: 0,
    orderContractorProgress: false,
    ordersNotByTemplateCreationAvailability: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_ORDER_MANAGERS_SUCCESS:
            return {
                ...state,
                orderManagers: payload.results,
            };
        case ORDER_GET_REGISTRY_ORDERS_AMOUNT_REQUEST:
            return {
                ...state,
                progressRegistryOrdersAmount: true,
            };
        case ORDER_GET_REGISTRY_ORDERS_AMOUNT_SUCCESS:
            return {
                ...state,
                registryOrdersAmount: payload,
                progressRegistryOrdersAmount: false,
            };
        case ORDER_GET_REGISTRY_ORDERS_AMOUNT_ERROR:
            return {
                ...state,
                progressRegistryOrdersAmount: false,
            };
        case ORDER_GET_REGISTRY_ORDERS_ERROR:
            return {
                ...state,
                progressRegistryOrders: false,
            };
        case ORDER_GET_REGISTRY_ORDERS_SUCCESS: {
            const {results, totalCount} = payload;

            return {
                ...state,
                registryOrders: results,
                progressRegistryOrders: false,
                registryOrdersTotalCount: totalCount,
            };
        }
        case ORDER_GET_REGISTRY_ORDERS_REQUEST:
            return {
                ...state,
                progressRegistryOrders: true,
                registryOrdersPageData: payload,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR:
            return {
                ...state,
                progressWarningOrders: false,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST:
            return {
                ...state,
                progressWarningOrders: true,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS: {
            const {orders} = payload;

            return {
                ...state,
                progressWarningOrders: false,
                warningOrderList: orders,
            };
        }
        case ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS:
            return {
                ...state,
                checkMultipleList: payload,
                progressCheckMultiple: false,
            };
        case GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS:
            return {
                ...state,
                depositHistoryApisDict: payload,
            };
        case ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR:
            return {
                ...state,
                progressCheckMultiple: false,
            };
        case ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST:
            return {
                ...state,
                progressCheckMultiple: true,
            };
        case ORDER_LIST_FOR_TEMPLATES_REQUEST:
            return {
                ...state,
                orderTemplatesProgress: true,
            };
        case ORDER_EDIT_REQUEST:
            return {
                ...state,
                createdOrderInfo: {},
                progressGetList: true,
                progressUpdate: true,
            };
        case ORDER_COMPLETE_ERROR:
            return {
                ...state,
                progressGetList: false,
            };
        case GET_ORDER_SHARED_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case GET_ORDER_SHARED_BY_ID_SUCCESS:
            return {
                ...state,
                sharedOrder: payload,
                progressCard: false,
            };
        case ORDER_CLEAR_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case ORDER_COMPLETE_REQUEST:
            return {
                ...state,
                progressGetList: true,
            };
        case GET_ORDER_WORK_UNITS_SUCCESS:
            return {
                ...state,
                orderWorkUnitsDict: payload,
            };
        case GET_FOREMAN_REQUEST_TYPES_SUCCESS:
            return {
                ...state,
                foremanRequestTypesDict: payload,
            };
        case GET_FOREMAN_REQUEST_STATUSES_SUCCESS:
            return {
                ...state,
                foremanRequestStatusesDict: payload,
            };
        case GET_FOREMAN_PAYMENT_STATUSES_SUCCESS:
            return {
                ...state,
                foremanPaymentStatusesDict: payload,
            };
        case ORDER_TOGGLE_CARD:
            return {
                ...state,
                isOpenOrderCard: payload,
            };
        case ORDER_INDUSTRY_PROFESSIONS_SUCCESS:
            return {
                ...state,
                industryProfessions: payload,
            };
        case ORDER_COMPLETE_SUCCESS:
            return {
                ...state,
                completeProgress: true,
            };
        case BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS:
            return {
                ...state,
                ordersNotByTemplateCreationAvailability: payload.result,
            };
        case ORDER_UPDATE_FIELD_STORE:
            const {
                field,
                value,
            } = payload;

            return {
                ...state,
                progress: false,
                [field]: value,
            };
        case CLEAR_ORDER_STORE:
            const {
                foremanPaymentStatusesDict,
                foremanRequestStatusesDict,
                foremanRequestTypesDict,
                industryProfessions,
                orderStatusList,
                contractorStatusList,
                orderWorkUnitsDict,
            } = state;

            return {
                ...initial,
                //Справочник не чистим
                orderWorkUnitsDict,
                contractorStatusList,
                orderStatusList,
                foremanPaymentStatusesDict,
                foremanRequestStatusesDict,
                foremanRequestTypesDict,
                industryProfessions,
            };
        case ORDER_CREATE_REQUEST:
            return {
                ...state,
                createdOrderInfo: {},
                progressGetList: true,
                progressUpdate: true,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST:
            return {
                ...state,
                updateContractorsFoundProgress: true,
            };
        case GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS:
        case GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR:
            return {
                ...state,
                progressCheckWarningBeforePublish: false,
            };
        case GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST:
            return {
                ...state,
                progressCheckWarningBeforePublish: true,
            };
        case ORDER_STATUS_LIST_REQUEST:
            return {
                ...state,
                card: {},
            };
        case ORDER_UPDATE_SUCCESS:
            const {
                guid,
                status,
                orderNum,
                name,
            } = payload;

            return {
                ...state,
                createdOrderInfo: {
                    orderId: guid,
                    status,
                    orderNum,
                    name,
                },
                progressUpdate: false,
            };
        case ORDER_UPDATE_REQUEST:
            return {
                ...state,
                createdOrderInfo: {},
                progressUpdate: true,
            };
        case ORDER_EDIT_ERROR:
            return {
                ...state,
                progressGetList: false,
            };
        case ORDER_UPDATE_ERROR:
            return {
                ...state,
                progressUpdate: false,
                progressGetList: false,
            };
        case ORDER_CARD_RICH_REQUEST:
        case ORDER_CARD_REQUEST:
            return {
                ...state,
                // card: {},
                progressCard: true,
            };
        case ORDER_ANALYTICS_PAGE_REQUEST:
        case ORDER_ANALYTICS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CONTRACTOR_STATUS_LIST_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_HISTORY_REQUEST:
            const {pageNum, pageSize} = payload;

            return {
                ...state,
                historyPageData: {
                    ...state.historyPageData,
                    pageNum,
                    pageSize,
                },
            };
        case ORDER_HISTORY_SUCCESS:
            const {events, totalCount: historyTotalCount} = payload;

            return {
                ...state,
                events,
                historyPageData: {
                    ...state.historyPageData,
                    totalCount: historyTotalCount,
                },
            };
        case ORDER_TEMPLATES_RICH_LIST_REQUEST:
            const {
                nameFieldPageData = FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.PAGE_DATA,
                ...pageData
            } = payload;
            return {
                ...state,
                orderTemplatesProgress: true,
                [nameFieldPageData]: {
                    ...pageData,
                },
            };
        case ORDER_RICH_LIST_REQUEST:
        case ORDER_LIST_FOR_INVITATION_REQUEST:
        case ORDER_LIST_REQUEST:
            return {
                ...state,
                progressGetList: true,
                pageData: {...payload},
            };
        case ORDER_CREATE_SUCCESS: {
            const {
                guid,
                status,
                name,
                orderNum,
                specialityId,
            } = payload;

            return {
                ...state,
                createdOrderInfo: {
                    orderId: guid,
                    status,
                    name,
                    orderNum,
                    specialityId,
                },
                progressUpdate: false,
            };
        }
        case ORDER_ANALYTICS_PAGE_SUCCESS:
            return {
                ...state,
                orderAnalyticsPage: payload,
                progress: false,
            };
        case ORDER_ANALYTICS_SUCCESS:
            return {
                ...state,
                orderAnalytics: payload,
                progress: false,
            };
        case ORDER_EDIT_SUCCESS: {
            const {
                guid: orderId,
                name,
                status,
                orderNum,
                specialityId,
            } = payload;

            return {
                ...state,
                createdOrderInfo: {
                    orderId,
                    status,
                    name,
                    orderNum,
                    specialityId,
                },
                progressUpdate: false,
            };
        }
        case CONTRACTOR_PROFESSIONS_SUCCESS:
            return {
                ...state,
                professions: payload,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS:
            return {
                ...state,
                updateContractorsFoundProgress: false,
            };
        case CONTRACTOR_STATUS_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                contractorStatusList: payload,
            };
        case ORDER_STATUS_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                orderStatusList: payload,
            };
        case ORDER_CARD_RICH_SUCCESS:
        case ORDER_CARD_SUCCESS: {
            const {
                result,
                nameField = "card",
            } = payload;
            return {
                ...state,
                [nameField]: result,
                progressCard: false,
            };
        }
        case ORDER_LIST_FOR_INVITATION_SUCCESS:
        case ORDER_RICH_LIST_SUCCESS:
        case ORDER_LIST_SUCCESS:
            const {
                nameField = "list",
                nameFieldTotalCount = "totalCount",
                orders,
                totalCount,
            } = payload;

            return {
                ...state,
                progressGetList: false,
                orderTemplatesProgress: false,
                [nameField]: orders,
                [nameFieldTotalCount]: totalCount,
            };
        case GET_SEARCH_ORDER_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_ERROR:
            return {
                ...state,
                updateContractorsFoundProgress: false,
                error: payload,
            };
        case ORDER_CREATE_ERROR:
            return {
                ...state,
                progressUpdate: false,
                progressCard: false,
                progressGetList: false,
            };
        case ORDER_CARD_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case ORDER_ANALYTICS_PAGE_ERROR:
        case ORDER_ANALYTICS_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case GET_ORDER_SHARED_BY_ID_ERROR:
        case GET_FOREMAN_REQUEST_TYPES_ERROR:
        case GET_FOREMAN_REQUEST_STATUSES_ERROR:
        case GET_FOREMAN_PAYMENT_STATUSES_ERROR:
        case ORDER_CARD_RICH_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case ORDER_RICH_LIST_ERROR:
        case ORDER_LIST_FOR_INVITATION_ERROR:
        case ORDER_LIST_ERROR:
            return {
                ...state,
                error: payload,
                progressGetList: false,
                orderTemplatesProgress: false,
            };
        case ORDER_HISTORY_ERROR:
        case ORDER_INDUSTRY_PROFESSIONS_ERROR:
        case ORDER_STATUS_LIST_ERROR:
        case CONTRACTOR_STATUS_LIST_ERROR:
            return {
                ...state,
                error: payload,
            };
        case ORDER_TOGGLE_ARCHIVED:
            return {
                ...state,
                isOrdersArchived: payload,
            };
        case GET_FINANCE_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                financePageData: payload.data,
            };
        case GET_ORDER_FINANCE_REQUEST:
            return {
                ...state,
                progressFinance: true,
                financePageData: payload.data,
            };
        case GET_FINANCE_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                listFinance: payload?.orders,
                financePageData: {
                    ...state.financePageData,
                    totalCount: payload?.totalCount,
                },
            };
        case GET_FINANCE_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case GET_ORDER_FINANCE_ERROR:
            return {
                ...state,
                progressFinance: false,
            };
        case GET_ORDER_FINANCE_SUCCESS:
            return {
                ...state,
                progressFinance: false,
                orderFinance: payload,
            };
        case SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS:
        case SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS:
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST:
            return {
                ...state,
                orderContractorPageData: payload,
                orderContractorProgress: true,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS:
            return {
                ...state,
                orderContractorList: payload.contractors,
                orderContractorTotalCount: payload.totalCount,
                applicationCountMap: payload.applicationCountMap,
                orderContractorProgress: false,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR:
            return {
                ...state,
                orderContractorProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export const addOrderRecommendStat = (payload) => ({
    type: ADD_ORDER_RECOMMEND_STAT_REQUEST,
    payload,
});


export const getOrderFinance = (payload) => ({
    type: GET_ORDER_FINANCE_REQUEST,
    payload,
});

export const orderTemplatesRichList = (payload) => ({
    type: ORDER_TEMPLATES_RICH_LIST_REQUEST,
    payload,
});

export const getSharedOrderById = (payload) => ({
    type: GET_ORDER_SHARED_BY_ID_REQUEST,
    payload,
});

export function orderClearFieldStore(payload) {
    return {
        type: ORDER_CLEAR_FIELD_STORE,
        payload,
    };
}

export function getForemanRequestTypes() {
    return {
        type: GET_FOREMAN_REQUEST_TYPES_REQUEST,
    };
}

export function getForemanRequestStatues() {
    return {
        type: GET_FOREMAN_REQUEST_STATUSES_REQUEST,
    };
}

export function getForemanPaymentStatuses() {
    return {
        type: GET_FOREMAN_PAYMENT_STATUSES_REQUEST,
    };
}

export function getOrderHistory(data) {
    return {
        type: ORDER_HISTORY_REQUEST,
        payload: data,
    };
}

export function createOrder(requestedData, duplicate) {
    return {
        type: ORDER_CREATE_REQUEST,
        payload: {
            requestedData,
            duplicate,
        },
    };
}

export function getProfessions() {
    return {
        type: CONTRACTOR_PROFESSIONS_REQUEST,
    };
}

export function getIndustryProfessions() {
    return {
        type: ORDER_INDUSTRY_PROFESSIONS_REQUEST,
    };
}

export function updateFieldOrderStore(field, value) {
    return {
        type: ORDER_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function completeOrder(payload) {
    return {
        type: ORDER_COMPLETE_REQUEST,
        payload,
    };
}


export function clearOrderStore() {
    return {
        type: CLEAR_ORDER_STORE,
    };
}

export function updateOrder(result) {
    return {
        type: ORDER_UPDATE_REQUEST,
        payload: result,
    };
}

export function editOrder(result) {
    return {
        type: ORDER_EDIT_REQUEST,
        payload: result,
    };
}

export function getOrderById(payload) {
    return {
        type: ORDER_CARD_REQUEST,
        payload,
    };
}

export function getOrderRichById(payload) {
    return {
        type: ORDER_CARD_RICH_REQUEST,
        payload,
    };
}

export function getOrderList(pageData) {
    return {
        type: ORDER_LIST_REQUEST,
        payload: {...pageData},
    };
}

export function getOrderListForTemplates(payload) {
    return {
        type: ORDER_LIST_FOR_TEMPLATES_REQUEST,
        payload,
    };
}

export function getOrderListForInvitation(pageData) {
    return {
        type: ORDER_LIST_FOR_INVITATION_REQUEST,
        payload: {...pageData},
    };
}

export function getOrderRichList(pageData) {
    return {
        type: ORDER_RICH_LIST_REQUEST,
        payload: {...pageData},
    };
}

export function getContractorStatuses() {
    return {
        type: CONTRACTOR_STATUS_LIST_REQUEST,
    };
}

export function getOrderStatusList() {
    return {
        type: ORDER_STATUS_LIST_REQUEST,
    };
}

export function updateOrderContractorsFound(payload) {
    return {
        type: UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST,
        payload,
    };
}

export function updateOrderArchive(payload) {
    return {
        type: ORDER_ARCHIVE_UPDATE_REQUEST,
        payload,
    };
}

export function toggleOrderCard(payload) {
    return {
        type: ORDER_TOGGLE_CARD,
        payload,
    };
}

export function toggleOrdersArchived(payload) {
    return {
        type: ORDER_TOGGLE_ARCHIVED,
        payload,
    };
}

export function getOrderAnalytics(payload) {
    return {
        type: ORDER_ANALYTICS_REQUEST,
        payload,
    };
}

export function getOrderAnalyticsPage(payload) {
    return {
        type: ORDER_ANALYTICS_PAGE_REQUEST,
        payload,
    };
}

export function getOrderWorkUnits() {
    return {
        type: GET_ORDER_WORK_UNITS_REQUEST,
    };
}

export function getWarningBeforePublishOrder(payload) {
    return {
        type: GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST,
        payload,
    };
}

export function checkMultipleAction(payload) {
    return {
        type: ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST,
        payload,
    };
}

export function checkToWarningBeforePublish(payload) {
    return {
        type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST,
        payload,
    };
}

export function getFinancePage(payload) {
    return {
        type: GET_FINANCE_PAGE_REQUEST,
        payload,
    };
}

export function deleteDraftOrder(payload) {
    return {
        type: ORDER_DELETE_DRAFT_REQUEST,
        payload,
    };
}

export function getRegistryOrders(payload) {
    return {
        type: ORDER_GET_REGISTRY_ORDERS_REQUEST,
        payload,
    };
}

export function getRegistryOrdersAmount(payload) {
    return {
        type: ORDER_GET_REGISTRY_ORDERS_AMOUNT_REQUEST,
        payload,
    };
}

export function getSearchOrderList(payload) {
    return {
        type: GET_SEARCH_ORDER_PAGE_REQUEST,
        payload,
    };
}

export function getDepositHistoryApisDict() {
    return {
        type: GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST,
    };
}

export function setEditAvailableOrderStatus(payload) {
    return {
        type: SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST,
        payload,
    };
}

export function updateOrderContractorPin(payload) {
    return {
        type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST,
        payload,
    };
}

export function rejectOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function declineOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function approveOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function getPageOrderContractors(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST,
        payload,
    };
}

export function getPageShortOrderContractors(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST,
        payload,
    };
}

export function getOrderManagers(payload) {
    return {
        type: BFF_ORDER_MANAGERS_REQUEST,
        payload,
    };
}

export function getOrdersNotByTemplateCreationAvailability(payload) {
    return {
        type: BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const orderSelector = state => state.order;
export const orderFinanceSelector = createSelector(orderSelector, ({orderFinance}) => orderFinance);
export const listFinanceSelector = createSelector(orderSelector, ({listFinance}) => listFinance);
export const financeTotalPageSelector = createSelector(orderSelector, ({financePageData: {totalCount, pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const financeTotalCountSelector = createSelector(orderSelector, ({financePageData: {totalCount}}) => totalCount);
export const orderListSelector = createSelector(orderSelector, ({list}) => list);
export const orderListTaskIdsSelector = createSelector(orderSelector, ({list}) => list.map(value => `${value.clientId}:${value.orderId}`));
export const orderIsSearchSelector = createSelector(orderSelector, ({pageData: {isSearch}}) => isSearch);
export const orderAnalyticsSelector = createSelector(orderSelector, ({orderAnalytics}) => orderAnalytics);
export const orderAnalyticsPageSelector = createSelector(orderSelector, ({orderAnalyticsPage}) => orderAnalyticsPage);
export const getOrderCardSelector = createSelector(orderSelector, ({card}) => card);
export const getCreatedOrderInfoSelector = createSelector(orderSelector, ({createdOrderInfo}) => createdOrderInfo);
export const getOrderHistorySelector = createSelector(orderSelector, ({events}) => events);
export const getOrderListProgressSelector = createSelector(orderSelector, ({progressGetList}) => progressGetList);
export const orderHistoryTotalPagesSelector = createSelector(orderSelector, ({historyPageData: {totalCount, pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const orderHistoryTotalCountSelector = createSelector(orderSelector, ({historyPageData: {totalCount}}) => totalCount);
export const contractorStatusSelector = createSelector(orderSelector, ({contractorStatusList}) => contractorStatusList);
export const orderProgressSelector = createSelector(orderSelector, ({progress}) => progress);
export const getProgressFinanceSelector = createSelector(orderSelector, ({progressFinance}) => progressFinance);
export const orderCardSelector = createSelector(orderSelector, ({card}) => card);
export const orderCreationDataSelector = createSelector(orderSelector, ({orderCreationData}) => orderCreationData);
export const orderProgressCheckWarningBeforePublishSelector = createSelector(orderSelector, ({progressCheckWarningBeforePublish}) => progressCheckWarningBeforePublish);

export const orderIndustryProfessionsOptionsWithoutPostfixSelector = createSelector(orderSelector, ({industryProfessions}) => dictionaryToOptions(transformIndustryProfessions(industryProfessions, false)));

export const orderTotalCountSelector = createSelector(orderSelector, ({totalCount}) => totalCount);
export const orderTotalPagesSelector = createSelector(orderSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const updateOrderContractorsFoundProgressSelector = createSelector(orderSelector, ({updateContractorsFoundProgress}) => updateContractorsFoundProgress);
export const getProgressOrderCardSelector = createSelector(orderSelector, ({progressCard}) => progressCard);
export const ordersOptionsForCreatingDisputeSelector = createSelector(orderSelector, ({list}) => list.map((item) => {
    const {
        orderId: value,
        name,
        orderManagerId,
        registryId,
        registrySeqNum,
        registryContractor = {},
        registry,
    } = item;

    let text, registryContractorId;

    if (isNullOrWhitespace(registryId)) {
        text = name;
    } else {
        const {
            ordinalNumber,
        } = registry;

        registryContractorId = registryContractor && registryContractor.contractorId;

        const fullName = isEmpty(registryContractor) ? "" : registryContractor.fio;

        text = `Реестр № ${ordinalNumber} ${name}, строка ${registrySeqNum}, ${fullName}`;
    }

    return {
        key: value, value, text, orderManagerId, registryId, registryContractorId,
    };
}));

export const getOrderCardOpenSelector = createSelector(orderSelector, ({isOpenOrderCard}) => isOpenOrderCard);
export const foremanPaymentStatusesDictSelector = createSelector(orderSelector, ({foremanPaymentStatusesDict}) => foremanPaymentStatusesDict);
export const foremanRequestStatusesDictSelector = createSelector(orderSelector, ({foremanRequestStatusesDict}) => foremanRequestStatusesDict);
export const orderWorkUnitsOptionsSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    orderWorkUnitsDict.map(({name, orderWorkUnits}) => ({
        key: orderWorkUnits,
        text: name,
        value: orderWorkUnits,
    })),
);
export const unitsAbbreviationDictSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    new Map(
        orderWorkUnitsDict.map(({abbreviation, orderWorkUnits}) => [orderWorkUnits, abbreviation]),
    ),
);
export const orderWorkUnitsDictSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    orderWorkUnitsDict.reduce((dict, value) => {
        const {orderWorkUnits, name} = value;

        dict[orderWorkUnits] = name;

        return dict;
    }, {}),
);
export const orderStatusSelector = createSelector(orderSelector, ({orderStatusList}) => orderStatusList);
export const orderProgressUpdateSelector = createSelector(orderSelector, ({progressUpdate}) => progressUpdate);
export const orderProgressActionSelector = createSelector(orderSelector, ({progressAction}) => progressAction);
export const orderTemplatesOptionsSelector = createSelector(orderSelector, ({orderTemplates}) => orderTemplates.map(item => ({
    key: item.orderId,
    value: item.orderId,
    text: item.templateName,
})));
export const getOrderTemplatesProgressSelector = createSelector(orderSelector, ({orderTemplatesProgress}) => orderTemplatesProgress);
export const orderTemplatesRichListSelector = createSelector(orderSelector, (state) => state[FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.FIELD_LIST]);
export const orderTemplatesTotalPageRichSelector = createSelector(orderSelector, (state) => getTotalPages(state[FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.TOTAL_COUNT], state[FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.PAGE_DATA].pageSize));
export const orderTemplatesTotalCountRichSelector = createSelector(orderSelector, (state) => state[FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.TOTAL_COUNT]);


export const ordersRegistryTotalCountSelector = createSelector(orderSelector, ({registryOrdersTotalCount}) => registryOrdersTotalCount);
export const ordersRegistryTotalPagesSelector = createSelector(orderSelector, ({registryOrdersTotalCount, registryOrdersPageData: {pageSize = 0}}) =>
    getTotalPages(registryOrdersTotalCount, pageSize),
);
export const ordersRegistrySelector = createSelector(orderSelector, ({registryOrders}) => registryOrders);
export const ordersRegistryProgressSelector = createSelector(orderSelector, ({progressRegistryOrders}) => progressRegistryOrders);

export const orderRegistryOrdersAmountSelector = createSelector(orderSelector, ({registryOrdersAmount}) => registryOrdersAmount);
export const orderRegistryOrdersAmountProgressSelector = createSelector(orderSelector, ({progressRegistryOrdersAmount}) => progressRegistryOrdersAmount);
export const orderListOptionsSelector = createSelector(orderSelector, ({list}) => {
    return list.map(item => ({
        key: item.orderId,
        value: item.orderId,
        text: `№${item.orderNum} ${item.name}`,
    }));
},
);
export const depositHistoryApisDictSelector = createSelector(orderSelector, ({depositHistoryApisDict}) => depositHistoryApisDict);
export const orderManagersOptionsSelector = createSelector(orderSelector, ({orderManagers}) => {
    return orderManagers.map(item => {
        return {
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(
                item.lastName,
                item.firstName,
                item.patronymic,
            ),
        };
    });
});
export const orderContractorListSelector = createSelector(orderSelector, ({orderContractorList}) => {
    return orderContractorList.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});
export const orderContractorTotalPagesSelector = createSelector(orderSelector, ({orderContractorTotalCount, orderContractorPageData: {pageSize}}) => getTotalPages(orderContractorTotalCount, pageSize));
export const orderContractorTotalCountSelector = createSelector(orderSelector, ({orderContractorTotalCount}) => orderContractorTotalCount);
export const orderContractorProgressSelector = createSelector(orderSelector, ({orderContractorProgress}) => orderContractorProgress);
export const orderApplicationCountMapSelector = createSelector(orderSelector, ({applicationCountMap}) => applicationCountMap);
export const ordersNotByTemplateCreationAvailabilitySelector = createSelector(orderSelector, ({ordersNotByTemplateCreationAvailability}) => ordersNotByTemplateCreationAvailability);

//*  SAGA  *//

export const addOrderRecommendStatSaga = function* ({payload}) {
    try {
        const {
            data,
        } = payload;
        const result = yield request.post("/order/view/stat/recommends/add", data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ORDER_RECOMMEND_STAT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_ORDER_RECOMMEND_STAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_ORDER_RECOMMEND_STAT_ERROR,
            payload: error,
        });
    }
};

export const getOrderFinanceSaga = function* ({payload}) {
    try {
        const {
            data,
        } = payload;
        const result = yield request.post(`${controller}/getOrderFinance`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_FINANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_FINANCE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_ORDER_FINANCE_ERROR,
            payload: error,
        });
    }
};

export const getFinancePageSaga = function* ({payload}) {
    try {
        const {
            data,
        } = payload;
        const result = yield request.post(`${controller}/getFinancePage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FINANCE_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FINANCE_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error);

        yield put({
            type: GET_FINANCE_PAGE_ERROR,
            payload: error,
        });
    }
};

export const orderReadSaga = function* (action) {
    try {

        const {payload} = action;
        const {clientId, orderId} = payload;
        const params = clientId ? `getById/${clientId}/${orderId}` : `getById?orderId=${orderId}`;
        const result = yield request.get(`${controller}/${params}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_CARD_SUCCESS, payload: {result}});
    } catch (error) {
        yield put({type: ORDER_CARD_ERROR, payload: error});
    }
};

export const getOrderListForInvitationSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPageForInvitation`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_LIST_FOR_INVITATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_LIST_FOR_INVITATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_LIST_FOR_INVITATION_ERROR,
            payload: error,
        });
    }
};


export const orderListReadSaga = function* ({payload}) {
    try {
        const {
            nameField,
            nameFieldTotalCount,
            nameFieldPageData,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_LIST_SUCCESS,
            payload: {
                nameField,
                nameFieldTotalCount,
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: ORDER_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/orderStatus
export const getOrderStatusListSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/orderStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_STATUS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/contractorStatus
export const getContractorStatusesReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_STATUS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/profession
export const getContractorProfessionReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/profession`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/foremanPaymentStatuses

export const getForemanPaymentStatusesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/foremanPaymentStatuses`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_PAYMENT_STATUSES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_PAYMENT_STATUSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_PAYMENT_STATUSES_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/getForemanRequestTypes

export const getForemanRequestTypesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/getForemanRequestTypes`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_REQUEST_TYPES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_REQUEST_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_REQUEST_TYPES_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/getForemanRequestStatuses

export const getForemanRequestStatusesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/getForemanRequestStatuses`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_REQUEST_STATUSES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_REQUEST_STATUSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_REQUEST_STATUSES_ERROR,
            payload: error,
        });
    }
};

export const getIndustryProfessionsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/industryProfessions`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_INDUSTRY_PROFESSIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_INDUSTRY_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_INDUSTRY_PROFESSIONS_ERROR,
            payload: error,
        });
    }
};

export const getOrderAnalyticsPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getOrderAnalyticsPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_ANALYTICS_PAGE_ERROR,
                payload: result,
            });
        }

        yield put({
            type: ORDER_ANALYTICS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_ANALYTICS_PAGE_ERROR,
            payload: error,
        });
    }
};

export const getOrderAnalyticsSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getOrderAnalytics`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_ANALYTICS_ERROR,
                payload: result,
            });
        }

        yield put({
            type: ORDER_ANALYTICS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_ANALYTICS_ERROR,
            payload: error,
        });
    }
};

export const updateOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            addDocumentFile,
            close,
            successToast,
            errorToast,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/update`, reqData);

        const {errorMessage, errorCode, guid} = result;

        // Для этих ошибок не нужно закрывать форму заказа
        if (close && ![ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL, ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR].includes(errorCode)) {
            close();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const {offeredSpecialityName} = reqData;

        if (addDocumentFile) {
            addDocumentFile(guid);
        }

        successToast && toastSuccess(successToast);

        yield put({
            type: ORDER_UPDATE_SUCCESS,
            payload: {
                ...result,
                name: reqData.name,
            },
        });

        if (onSuccess) {
            onSuccess(result);
        } else {
            const state = yield select();

            yield put(getOrderRichList(state.order.pageData));
        }

        const userRole = ls(USER_ROLE);

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }
    } catch (error) {
        toastError(payload.errorToast);

        yield put({
            type: ORDER_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const editOrderSaga = function* ({payload}) {
    try {
        const {
            addDocumentFile,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/edit`, reqData);

        const {errorMessage} = result;

        if (!errorMessage) {
            const {orderId, offeredSpecialityName} = reqData;

            addDocumentFile(orderId);

            toastSuccess(ORDER.ORDER_UPDATE_SUCCESS_TEXT);

            const {status, guid, orderNum} = result;
            const {name} = payload;

            const {
                clientId,
                specialityId,
                objectAddressFiasIds,
            } = reqData;

            const data = {
                clientId,
                specialityIdsFilter: specialityId ? [
                    specialityId,
                ] : undefined,
                remoteWorkFilter: null,
                strictAddressFiasIdsFilter: objectAddressFiasIds,
                orderSearchParams: {
                    orderId: guid,
                    searchByObjectAddressFiasIdsStrict: false,
                    searchByObjectAddressFiasIds: true,
                },
            };

            yield put(getContractorCount(data));

            yield put({
                type: ORDER_EDIT_SUCCESS,
                payload: {
                    status,
                    guid,
                    name,
                    orderNum,
                    specialityId,
                },
            });

            const state = yield select();
            yield put(getOrderRichList(state.order.pageData));

            const userRole = ls(USER_ROLE);

            // Обновляем счетчик справочника ВД, если предложен свой ВД
            if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
                yield put(getNonDecisionCount());
            }

        } else {
            toastError(errorMessage);

            yield put({
                type: ORDER_EDIT_ERROR,
                payload: result,
            });
        }
    } catch (error) {
        toastError(ORDER.ORDER_UPDATE_ERROR_TEXT);

        yield put({
            type: ORDER_EDIT_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/completeOrder
export const completeOrderSaga = function* ({payload}) {
    try {
        const {handleResponse, ...reqData} = payload;

        const result = yield request.post(`${controller}/completeOrder`, reqData);

        const {errorMessage, success, status} = result;

        if (!errorMessage && success) {
            const message = status === ORDER_STATUS.CLOSED ? ORDER.COMPLETE_SUCCESS : ORDER.COMPLETE_LATER_SUCCESS;

            toastSuccess(message);

            yield put({
                type: ORDER_COMPLETE_SUCCESS,
            });

            const state = yield select();

            if (handleResponse) {
                handleResponse(result);
            } else {
                yield put(getOrderRichList(state.order.pageData));
            }
        } else {
            toastError(errorMessage);

            yield put({
                type: ORDER_COMPLETE_ERROR,
                payload: result,
            });
        }
    } catch (error) {
        toastError(ORDER.ORDER_COMPLETE_ERROR_TEXT);

        yield put({
            type: ORDER_COMPLETE_ERROR,
            payload: error,
        });
    }
};

export const createOrderSaga = function* ({payload}) {
    try {
        const {requestedData, duplicate} = payload;
        const {
            addDocumentFile,
            close,
            isNeedFetch = true,
            onError,
            errorToast,
            onSuccess,
            successToast,
            fetchList,
            ...otherData
        } = requestedData;

        const result = yield request.post(`${controller}/add`, otherData);

        const {guid, errorMessage, status, orderNum, errorCode} = result;

        // Для этих ошибок не нужно закрывать форму заказа
        if (![
            ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL,
            ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR,
            ORDER_ERROR_CODE.TEMPLATE_IS_OUT_OF_DATE,
            ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED,
        ].includes(errorCode)) {
            close();
        }

        const {
            order: {
                pageData,
            },
        } = yield select();

        if (errorMessage) {
            ![
                ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED,
            ].includes(errorCode) &&
            toastError(errorMessage);

            onError(result);

            yield put({
                type: ORDER_CREATE_ERROR,
                payload: result,
            });

            yield put(getOrderRichList(pageData));

            return {
                done: true,
            };
        }

        const {
            clientId,
            specialityId,
            name,
            offeredSpecialityName,
        } = otherData;

        addDocumentFile(guid);

        const data = {
            clientId,
            specialityIdsFilter: specialityId ? [
                specialityId,
            ] : undefined,
            remoteWorkFilter: null,
            orderSearchParams: {
                orderId: guid,
                searchByObjectAddressFiasIdsStrict: false,
                searchByObjectAddressFiasIds: true,
            },
        };

        const toastMessage = duplicate ? ORDER.ORDER_CREATE_DUPLICATE : successToast;

        toastSuccess(toastMessage);

        if (isNeedFetch && !duplicate && !fetchList) {
            yield put(getOrderRichList(pageData));
        }

        if (isNeedFetch && !duplicate && fetchList) {
            fetchList();
        }

        yield put(getContractorCount(data));

        yield put({
            type: ORDER_CREATE_SUCCESS,
            payload: {
                guid,
                name,
                status,
                orderNum,
                specialityId,
            },
        });

        const userRole = ls(USER_ROLE);

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }

        if (onSuccess) {
            onSuccess(result);
        }
    } catch (error) {
        toastError(payload.errorToast);
        yield put({
            type: ORDER_CREATE_ERROR,
            payload: error,
        });
    }
};

///api/orders/updateOrderContractorsFound  "contractorsFound": true,
export const updateOrderContractorsFoundSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            contractorsFound, orderId, clientId, getResult = () => {
            },
        } = payload;

        const requestBody = {orderId, clientId};

        const result = yield request.post(`${controller}/updateOrderContractorsFound?contractorsFound=${contractorsFound}`, requestBody);

        const {errorMessage} = result;

        getResult(errorMessage);

        if (!errorMessage) {
            toastSuccess(ORDER.ORDER_UPDATE_SUCCESS_TEXT);

            yield put({
                type: UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS,
            });
        } else {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
                payload: result,
            });
        }
    } catch (error) {
        toastError(ORDER.ORDER_UPDATE_ERROR_TEXT);

        yield put({
            type: UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
            payload: error,
        });
    }
};

export const orderHistorySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getOrderHistory`, payload);

        const {events, totalCount, errorMessage} = result;

        if (!errorMessage) {
            yield put({
                type: ORDER_HISTORY_SUCCESS,
                payload: {events, totalCount},
            });
        } else {
            yield put({
                type: ORDER_HISTORY_ERROR,
                payload: errorMessage,
            });
        }

    } catch (error) {
        toastError("Не удалось загрузить историю заказа");
        yield put({
            type: ORDER_HISTORY_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/unarchiveOrder
//POST /api/orders/archiveOrder

export const updateOrderArchiveSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
                orderId,
                isArchive,
                projectId,
                isFetchList = true,
                isFetchCard = false,
                nameFieldPageData = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.PAGE_DATA,
                getResult = () => {
                },
            },
        } = action;
        const method = isArchive ? "archiveOrder" : "unarchiveOrder";

        const result = yield request.post(`${controller}/${method}`, {
            clientId,
            orderId,
        });

        getResult(result);

        if (projectId) {
            yield put(getProjectById({projectId, clientId}));
        }

        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {

            ![ORDER_TEMPLATE_ARCHIVE.LAST_TEMPLATE_IN_OBJECT_ERROR].includes(errorCode) &&
            toastError(errorMessage);

            yield put({
                type: ORDER_ARCHIVE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(isArchive ? ORDERS_ARCHIVED_SUCCESS_MSG[nameFieldPageData].archived : ORDERS_ARCHIVED_SUCCESS_MSG[nameFieldPageData].unArchive);

        if (isFetchList) {
            const state = yield select();
            yield put(getOrderRichList(state.order[nameFieldPageData]));
        }

        if (isFetchCard) {
            yield put(getOrderRichById({
                orderId,
                clientId,
            }));
        }

        yield put({
            type: ORDER_ARCHIVE_UPDATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_ARCHIVE_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const orderRichReadSaga = function* ({payload}) {
    try {
        const {
            clientId,
            orderId,
            nameField,
            callBack,
        } = payload;
        const params = `getRichById/${clientId}/${orderId}`;
        const result = yield request.get(`${controller}/${params}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CARD_RICH_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (callBack) {
            callBack(result);
        }

        yield put({
            type: ORDER_CARD_RICH_SUCCESS,
            payload: {
                result,
                nameField,
            },
        });
    } catch (error) {
        yield put({type: ORDER_CARD_RICH_ERROR, payload: error});
    }
};

export const orderRichListReadSaga = function* (action) {
    try {
        const {
            payload: {
                nameField = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.FIELD_LIST,
                nameFieldTotalCount = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.TOTAL_COUNT,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/getRichPage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_RICH_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_RICH_LIST_SUCCESS,
            payload: {
                ...result,
                nameField,
                nameFieldTotalCount,
            },
        });
    } catch (error) {
        yield put({
            type: ORDER_RICH_LIST_ERROR,
            payload: error,
        });
    }
};

export const getSharedOrderByIdSaga = function* ({payload}) {
    try {
        const {clientId, orderId} = payload;

        const result = yield call(axios, {
            url: `/nmapi/public/getSharedOrderById?clientId=${clientId}&orderId=${orderId}`,
            method: "get",
        });
        const {
            data = {},
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_RICH_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_SHARED_BY_ID_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: GET_ORDER_SHARED_BY_ID_ERROR,
            payload: error,
        });
    }
};

export const getOrderWorkUnitsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/orderWorkUnits`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_WORK_UNITS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_WORK_UNITS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_ORDER_WORK_UNITS_ERROR,
            payload: error,
        });
    }
};

// POST /api/orders/getWarningBeforePublishOrder
export const getWarningBeforePublishOrderSaga = function* ({payload}) {
    try {
        const isOrderPublicationProhibited = yield request.get("/clients/users/isOrderPublicationProhibited");
        const role = ls(USER_ROLE);

        if (isOrderPublicationProhibited && role === FOREMAN) {
            toastError("Возможность опубликовать заказ недоступна для вашей учетной записи. Обратитесь к руководству вашей компании");

            yield put({
                type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
            });

            return {
                done: true,
            };
        }

        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/getWarningBeforePublishOrder`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            onError();
            toastError(errorMessage);

            yield put({
                type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS,
            payload: result,
        });

        onSuccess(result);
    } catch (error) {
        payload.onError();
        toastError(String(error));
        yield put({
            type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
            payload: error,
        });
    }
};

export const checkMultipleActionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/multiple/checkToAction`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR});

            return {
                done: true,
            };
        }

        onSuccess(result);
        yield put({type: ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR});
    }
};


export const checkToWarningBeforePublishSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/multiple/checkToWarningBeforePublish`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR});

            return {
                done: true,
            };
        }

        onSuccess(result);
        yield put({type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR});
    }
};

export const deleteDraftOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/deleteDraftOrder `, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ORDER_DELETE_DRAFT_ERROR});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        yield put({type: ORDER_DELETE_DRAFT_ERROR});
    }
};

//POST /api/orders/getContractorOrdersHistory
export const getRegistryOrdersSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getRegistryOrdersPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_GET_REGISTRY_ORDERS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_GET_REGISTRY_ORDERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_GET_REGISTRY_ORDERS_ERROR,
        });
    }
};

export const getRegistryOrdersAmountSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getRegistryOrdersAmount`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: ORDER_GET_REGISTRY_ORDERS_AMOUNT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_GET_REGISTRY_ORDERS_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_GET_REGISTRY_ORDERS_AMOUNT_ERROR,
        });
    }
};

//POST /api/orders/chats-search/page
export const getSearchOrderListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/chats-search/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SEARCH_ORDER_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_SEARCH_ORDER_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SEARCH_ORDER_PAGE_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/deposit/history/apis
export const getDepositHistoryApisDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/deposit/history/apis`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_HISTORY_APIS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DEPOSIT_HISTORY_APIS_DICT_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/setEditAvailableStatus
// Перевод статуса заказа для возможности редактирования
export const setEditAvailableOrderStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/setEditAvailableStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR,
            payload: error,
        });
    }
};

const controllers = {
    admin: "/adm/clients/client-card/orders/orders-registry\n",
    client: "/client-adm/orders/orders-registry",
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/update/pin
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/update/pin
// Закрепление заявки в заказе
export const updateOrderContractorsPinSaga = function* ({payload}) {
    const {
        onSuccess = () => {},
        isClientCard = true,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/update/pin`,
            adminRolesUrl: `${controllers.admin}/order/contractors/update/pin`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/reject-all
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/reject-all
// Массовый отзыв приглашений
export const orderContractorsRejectAllInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/reject-all`,
            adminRolesUrl: `${controllers.admin}/order/contractors/reject-all`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/decline-all
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/decline-all
// Массовый отзыв приглашений
export const declineAllOrderContractorsInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/decline-all`,
            adminRolesUrl: `${controllers.admin}/order/contractors/decline-all`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/approve-all
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/approve-all
// Массовый отзыв приглашений
export const approveAllOrderContractorsInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/approve-all`,
            adminRolesUrl: `${controllers.admin}/order/contractors/approve-all`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/get-page
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/get-page
export const getPageOrderContractorsSaga = function* ({payload}) {
    const {
        onSuccess = () => {},
        isClientCard = true,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/get-page`,
            adminRolesUrl: `${controllers.admin}/order/contractors/get-page`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/get-applications-page
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/get-applications-page
export const getPageShortOrderContractorsSaga = function* ({payload}) {
    const {
        isClientCard = true,
        getResult,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}/order/contractors/get-applications-page`,
            adminRolesUrl: `${controllers.admin}/order/contractors/get-applications-page`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/orders-registry/order-managers/page
// Получить список менеджеров для фильтра
export const getOrderManagersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controllers.admin}/order-managers/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_MANAGERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDER_MANAGERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_MANAGERS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/orders/orders-registry/get-orders-not-by-template-creation-availability
// Получение флага возможности создавать свободные заказы для клиента
export const getOrdersNotByTemplateCreationAvailabilitySaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controllers.admin}/get-orders-not-by-template-creation-availability`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_CARD_REQUEST, orderReadSaga),
        takeEvery(ORDER_LIST_REQUEST, orderListReadSaga),
        takeEvery(ORDER_LIST_FOR_INVITATION_REQUEST, getOrderListForInvitationSaga),
        takeEvery(ORDER_CREATE_REQUEST, createOrderSaga),
        takeEvery(CONTRACTOR_STATUS_LIST_REQUEST, getContractorStatusesReadSaga),
        takeEvery(ORDER_STATUS_LIST_REQUEST, getOrderStatusListSaga),
        takeEvery(ORDER_UPDATE_REQUEST, updateOrderSaga),
        takeEvery(ORDER_COMPLETE_REQUEST, completeOrderSaga),
        takeEvery(UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST, updateOrderContractorsFoundSaga),
        takeEvery(CONTRACTOR_PROFESSIONS_REQUEST, getContractorProfessionReadSaga),
        takeEvery(ORDER_HISTORY_REQUEST, orderHistorySaga),
        takeEvery(ORDER_ARCHIVE_UPDATE_REQUEST, updateOrderArchiveSaga),
        takeEvery(ORDER_INDUSTRY_PROFESSIONS_REQUEST, getIndustryProfessionsSaga),
        takeEvery(ORDER_EDIT_REQUEST, editOrderSaga),
        takeEvery(ORDER_ANALYTICS_REQUEST, getOrderAnalyticsSaga),
        takeEvery(ORDER_ANALYTICS_PAGE_REQUEST, getOrderAnalyticsPageSaga),
        takeEvery(ORDER_CARD_RICH_REQUEST, orderRichReadSaga),
        takeEvery(ORDER_RICH_LIST_REQUEST, orderRichListReadSaga),
        takeEvery(GET_FOREMAN_PAYMENT_STATUSES_REQUEST, getForemanPaymentStatusesSaga),
        takeEvery(GET_FOREMAN_REQUEST_STATUSES_REQUEST, getForemanRequestStatusesSaga),
        takeEvery(GET_FOREMAN_REQUEST_TYPES_REQUEST, getForemanRequestTypesSaga),
        takeEvery(GET_ORDER_SHARED_BY_ID_REQUEST, getSharedOrderByIdSaga),
        takeEvery(GET_ORDER_WORK_UNITS_REQUEST, getOrderWorkUnitsSaga),
        takeEvery(ORDER_LIST_FOR_TEMPLATES_REQUEST, orderListReadSaga),
        takeEvery(ORDER_TEMPLATES_RICH_LIST_REQUEST, orderRichListReadSaga),
        takeEvery(GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST, getWarningBeforePublishOrderSaga),
        takeEvery(ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST, checkMultipleActionSaga),
        takeEvery(ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST, checkToWarningBeforePublishSaga),
        takeEvery(GET_FINANCE_PAGE_REQUEST, getFinancePageSaga),
        takeEvery(ORDER_DELETE_DRAFT_REQUEST, deleteDraftOrderSaga),
        takeEvery(GET_ORDER_FINANCE_REQUEST, getOrderFinanceSaga),
        takeEvery(ADD_ORDER_RECOMMEND_STAT_REQUEST, addOrderRecommendStatSaga),
        takeEvery(ORDER_GET_REGISTRY_ORDERS_REQUEST, getRegistryOrdersSaga),
        takeEvery(ORDER_GET_REGISTRY_ORDERS_AMOUNT_REQUEST, getRegistryOrdersAmountSaga),
        takeEvery(GET_SEARCH_ORDER_PAGE_REQUEST, getSearchOrderListSaga),
        takeEvery(GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST, getDepositHistoryApisDictSaga),
        takeEvery(SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST, setEditAvailableOrderStatusSaga),
        takeEvery(BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST, updateOrderContractorsPinSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST, orderContractorsRejectAllInvitationsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST, declineAllOrderContractorsInvitationsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST, approveAllOrderContractorsInvitationsSaga),
        takeEvery(BFF_ORDER_MANAGERS_REQUEST, getOrderManagersSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST, getPageOrderContractorsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST, getPageShortOrderContractorsSaga),
        takeEvery(BFF_ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST, getOrdersNotByTemplateCreationAvailabilitySaga),
    ]);
}