import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_CLIENT_SYSTEM_PROPERTIES_ERROR,
    GET_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    GET_CLIENT_SYSTEM_PROPERTIES_SUCCESS,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_ERROR,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/clients/system-properties";

// GET /bff/adm/clients/system-properties/get
// Изменение системных свойств заказчика
const getClientSystemProperties = function* () {
    try {
        const result = yield request.bff.get(`${controller}/get`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_SYSTEM_PROPERTIES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_SYSTEM_PROPERTIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_SYSTEM_PROPERTIES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/system-properties/update
// Изменение системных свойств заказчика
const updateClientSystemProperties = function* ({payload}) {
    const {
        onSuccess,
        data,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_SYSTEM_PROPERTIES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: UPDATE_CLIENT_SYSTEM_PROPERTIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_SYSTEM_PROPERTIES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_SYSTEM_PROPERTIES_REQUEST, getClientSystemProperties),
        takeEvery(UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST, updateClientSystemProperties),
    ]);
}