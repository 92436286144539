import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffContractorsRegistrySelector = state => state.bff.contractorsRegistry;
export const bffContractorsRegistryListSelector  = createSelector(bffContractorsRegistrySelector, ({list}) => list);
export const bffContractorsRegistryTotalCountSelector  = createSelector(bffContractorsRegistrySelector, ({totalCount}) => totalCount);
export const bffContractorsRegistryTotalPagesSelector  = createSelector(bffContractorsRegistrySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffContractorsRegistryProgressSelector  = createSelector(bffContractorsRegistrySelector, ({progress}) => progress);
export const bffContractorsRegistryActionSelector  = createSelector(bffContractorsRegistrySelector, ({progressAction}) => progressAction);
