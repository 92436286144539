import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_PAYMENT_ADD_ERROR,
    BFF_CROWD_PAYMENT_ADD_REQUEST,
    BFF_CROWD_PAYMENT_ADD_SUCCESS,
    BFF_CROWD_PAYMENT_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
    BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST,
    BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS,
    BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
    BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS,
    BFF_CROWD_PAYMENT_REJECT_ERROR,
    BFF_CROWD_PAYMENT_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_REPEAT_ERROR,
    BFF_CROWD_PAYMENT_REPEAT_REQUEST,
    BFF_CROWD_PAYMENT_REPEAT_SUCCESS,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_REQUEST,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/finances/crowd-payments";

// POST /bff/adm/finances/crowd-payments/get/page
const getPageCrowdPayment = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/get/totalAmount
const getTotalAmountCrowdPayment = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get/totalAmount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/repeat
const repeatCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/repeat`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_REPEAT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_REPEAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_REPEAT_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/reject
const rejectCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_REJECT_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/mass/reject
const massRejectCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/mass/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/approve
const approveCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_APPROVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_APPROVE_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/mass/approve
const massApproveCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/mass/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/add
const addCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_PAYMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_ADD_ERROR,
        });
    }
};

// GET /bff/adm/finances/crowd-payments/{id}
const getByIdCrowdPayment = function* ({payload}) {
    try {
        const {
            paymentId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${paymentId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
        });
    }
};

// POST /bff/adm/finances/crowd-payments/events/get/page
const getPageCrowdPaymentLog = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/finances/crowd-payments/events/get/page", payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/finances/crowd-payments/mass/revalidate
const massRevalidateCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/mass/revalidate`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
        });
    }
};

//POST /bff/adm/clients/crowd-payments/exists
const checkExistsCrowdPayments = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/clients/crowd-payments/exists", payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_PAYMENT_GET_PAGE_REQUEST, getPageCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST, getTotalAmountCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_REPEAT_REQUEST, repeatCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_REJECT_REQUEST, rejectCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST, massRejectCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_APPROVE_REQUEST, approveCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST, massApproveCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_ADD_REQUEST, addCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST, getByIdCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST, getPageCrowdPaymentLog),
        takeEvery(BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST, massRevalidateCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENTS_CHECK_EXISTS_REQUEST, checkExistsCrowdPayments),
    ]);
}