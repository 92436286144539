import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getSettingsDirectoriesSbpBanksPage,
    updateStoreSettingsDirectoriesBanks,
} from "../../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";

export const useSettingsDirectoriesBanksSbpFetch = ({pageNum, pageSize, subPage, filter}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsDirectoriesBanks({
                sbpPageData: {},
                sbpList: [],
                sbpTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter]);

    const fetchList = () => {
        dispatch(getSettingsDirectoriesSbpBanksPage({
            ...filter,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};