import axios from "axios";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";

const controller = "/feedback";

const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
const FEEDBACK_ERROR = "FEEDBACK_ERROR";
const FEEDBACK_STORE_UPDATE = "FEEDBACK_STORE_UPDATE";


const initial = {
    error: "",
    isSuccess: false,
    response: {},
    isShowSurvey: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case FEEDBACK_STORE_UPDATE: {
            return {
                ...state,
                ...payload,
            };
        }
        case FEEDBACK_REQUEST: {
            return {
                ...state,
            };
        }
        case FEEDBACK_SUCCESS: {
            return {
                ...state,
                isSuccess: true,
                response: payload,
            };
        }
        case FEEDBACK_ERROR: {
            return {
                ...state,
                error: payload,
            };
        }
        default:
            return state;
    }
};

export function feedbackSend(payload) {
    return {
        type: FEEDBACK_REQUEST,
        payload,
    };
}

export function updateFeedbackStore(payload) {
    return {
        type: FEEDBACK_STORE_UPDATE,
        payload,
    };
}


export const feedBackSelector = state => state.feedback;
export const feedBackProgressSelector = createSelector(feedBackSelector, ({isSuccess}) => isSuccess);
export const feedBackResponseSelector = createSelector(feedBackSelector, ({response}) => response);


export const feedbackSendSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            fio,
            phone,
            timezoneOffsetToMinutes,
            email,
            accept,
            question,
            feedbackType,
            file: data,
        } = payload;

        const response = yield call(axios, {
            url: `/api${controller}/send`,
            method: "post",
            params: {
                feedbackType,
                fio,
                phone: phone ? phone : undefined,
                accept,
                email: email ? email : undefined,
                question,
                timezoneOffsetToMinutes,
            },
            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        },
        );

        const {data: result} = response;

        yield put({
            type: FEEDBACK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FEEDBACK_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FEEDBACK_REQUEST, feedbackSendSaga),
    ]);
}