import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import NmBadge from "../../../../components/NmBadge";
import ContractorMainWorkCityEditModal from "./components/contractor-main-work-city-edit-modal";

import {
    contractorRemoteWorkSelector,
    contractorWorkAddressInfoSelector,
} from "../../../../ducks/bff/contractor-сard/profile/selectors";

export const MAIN_WORK_CITY_OPTION = {
    EDIT: "EDIT",
};

export const OPTIONS = [
    {
        key: MAIN_WORK_CITY_OPTION.EDIT,
        text: "Редактировать",
        value: MAIN_WORK_CITY_OPTION.EDIT,
    },
];

export default function ContractorMainWorkCity(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const {key: workAddressFiasId, text: query} = useSelector(contractorWorkAddressInfoSelector);
    const remoteWork = useSelector(contractorRemoteWorkSelector);

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [editData, setEditData] = useState({});

    const onClickAction = ({value}) => {
        if (value === MAIN_WORK_CITY_OPTION.EDIT) {
            toggleEditModal({
                workAddressFiasId,
                query,
                remoteWork,
            });
        }
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    function mapContent() {
        if (!query || !workAddressFiasId) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return (
            <div className="d-flex mt-xl-1">
                {remoteWork && <Text className="mx-2">
(Удалённая работа)
                </Text>}
                <NmBadge
                    maxContent
                    mod="gray"
                    text={query}
                />
            </div>
        );
    }

    return (
        <>
            {isShowEditModal && <ContractorMainWorkCityEditModal
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                editData={editData}
                contractorId={contractorId}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Город ведения деятельности"
                options={OPTIONS}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}