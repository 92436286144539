import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../components/ActualComponents/Tabs";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {
    clearRecruitmentAccessControlStore,
    getTotalCountsRecruitmentAccessControl,
} from "../../../ducks/bff/recruitment/access-control/actionsCreators";
import {
    bffRecruitmentAccessControlJobBoardsListTotalCountSelector,
} from "../../../ducks/bff/recruitment/access-control/job-boards/selectors";
import {
    bffRecruitmentAccessControlObserverTotalCountSelector,
} from "../../../ducks/bff/recruitment/access-control/observers/selectors";
import {
    bffRecruitmentAccessControlRecruitersGroupsListTotalCountSelector,
} from "../../../ducks/bff/recruitment/access-control/recruiters-groups/selectors";
import {
    bffRecruitmentAccessControlTotalCountsSelector,
} from "../../../ducks/bff/recruitment/access-control/selectors";
import {updateRecruitmentJobBoardsStore} from "../../../ducks/bff/recruitment/jobBoards/actionCreators";
import {ClientRecruitmentAccessControlRoute} from "./route";

import {getUserRole} from "../../../utils/access";

import {
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ADMIN,
    NM_OPERATOR,
    RECRUITER,
} from "../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";

const ClientRecruitmentAccessControl = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();

    const recruitmentAccessControlTotalCounts = useSelector(bffRecruitmentAccessControlTotalCountsSelector);
    const _recruitersGroupsTotalCount = useSelector(bffRecruitmentAccessControlRecruitersGroupsListTotalCountSelector);
    const _observersTotalCount = useSelector(bffRecruitmentAccessControlObserverTotalCountSelector);
    const _jobBoardsTotalCount = useSelector(bffRecruitmentAccessControlJobBoardsListTotalCountSelector);
    const {seniorRecruiter} = useSelector(clientCurrentMemberSelector);
    const role = getUserRole();

    useEffect(() => {
        dispatch(getTotalCountsRecruitmentAccessControl({clientId}));

        return () => {
            dispatch(clearRecruitmentAccessControlStore());
            dispatch(updateRecruitmentJobBoardsStore({
                list: [],
            }));
        };
    }, []);

    const getTabs = () => {
        const groups = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS.replace(":clientId", clientId);
        const jobBoards = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS.replace(":clientId", clientId);
        const observers = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS.replace(":clientId", clientId);

        const {
            jobBoardsTotalCount = 0,
            observersTotalCount = 0,
            recruitersGroupsTotalCount = 0,
        } = recruitmentAccessControlTotalCounts || {};

        const groupsTab = {
            active: groups,
            link: groups,
            name: "Группы рекрутеров",
            count: _recruitersGroupsTotalCount || recruitersGroupsTotalCount,
            visible: false,
        };

        const jobBoardsTab = {
            active: jobBoards,
            link: jobBoards,
            name: "Джоб борды",
            count: _jobBoardsTotalCount || jobBoardsTotalCount,
        };

        const observersTab =  {
            active: observers,
            link: observers,
            name: "Наблюдатели",
            count: _observersTotalCount || observersTotalCount,
        };

        if (
            [ADMIN, CLIENT_ADMIN, NM_OPERATOR].includes(role) ||
            role === RECRUITER && seniorRecruiter
        ) {
            return [
                groupsTab,
                jobBoardsTab,
                observersTab,
            ];
        }

        return [
            jobBoardsTab,
            observersTab,
        ];
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    Управление доступом
                </NmTitle>
            }
        >
            <Tabs
                panes={getTabs()}
                className="mb-3 mb-md-4"
            />
            <ClientRecruitmentAccessControlRoute />
        </NmPage>
    );
};

export default ClientRecruitmentAccessControl;