import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/registries/invitations";
//*  TYPES  *//

const GET_REGISTRY_INVITATIONS_PAGE_REQUEST = "GET_REGISTRY_INVITATIONS_PAGE_REQUEST";
const GET_REGISTRY_INVITATIONS_PAGE_SUCCESS = "GET_REGISTRY_INVITATIONS_PAGE_SUCCESS";
const GET_REGISTRY_INVITATIONS_PAGE_ERROR = "GET_REGISTRY_INVITATIONS_PAGE_ERROR";

const GET_REGISTRY_INVITATIONS_BY_ID_REQUEST = "GET_REGISTRY_INVITATIONS_BY_ID_REQUEST";
const GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS = "GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS";
const GET_REGISTRY_INVITATIONS_BY_ID_ERROR = "GET_REGISTRY_INVITATIONS_BY_ID_ERROR";

const ADD_REGISTRY_INVITATIONS_REQUEST = "ADD_REGISTRY_INVITATIONS_REQUEST";
const ADD_REGISTRY_INVITATIONS_SUCCESS = "ADD_REGISTRY_INVITATIONS_SUCCESS";
const ADD_REGISTRY_INVITATIONS_ERROR = "ADD_REGISTRY_INVITATIONS_ERROR";

const DELETE_REGISTRY_INVITATIONS_REQUEST = "DELETE_REGISTRY_INVITATIONS_REQUEST";
const DELETE_REGISTRY_INVITATIONS_SUCCESS = "DELETE_REGISTRY_INVITATIONS_SUCCESS";
const DELETE_REGISTRY_INVITATIONS_ERROR = "DELETE_REGISTRY_INVITATIONS_ERROR";

const UPDATE_REGISTRY_INVITATIONS_REQUEST = "UPDATE_REGISTRY_INVITATIONS_REQUEST";
const UPDATE_REGISTRY_INVITATIONS_SUCCESS = "UPDATE_REGISTRY_INVITATIONS_SUCCESS";
const UPDATE_REGISTRY_INVITATIONS_ERROR = "UPDATE_REGISTRY_INVITATIONS_ERROR";

const ARCHIVED_REGISTRY_INVITATIONS_REQUEST = "ARCHIVED_REGISTRY_INVITATIONS_REQUEST";
const ARCHIVED_REGISTRY_INVITATIONS_SUCCESS = "ARCHIVED_REGISTRY_INVITATIONS_SUCCESS";
const ARCHIVED_REGISTRY_INVITATIONS_ERROR = "ARCHIVED_REGISTRY_INVITATIONS_ERROR";

const RUN_REGISTRY_INVITATIONS_REQUEST = "RUN_REGISTRY_INVITATIONS_REQUEST";
const RUN_REGISTRY_INVITATIONS_SUCCESS = "RUN_REGISTRY_INVITATIONS_SUCCESS";
const RUN_REGISTRY_INVITATIONS_ERROR = "RUN_REGISTRY_INVITATIONS_ERROR";

const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST";
const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS";
const CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR = "CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    card: {},
    pageData: {},
    progress: false,
    actionProgress: false,
    totalCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_REGISTRY_INVITATIONS_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_REGISTRY_INVITATIONS_BY_ID_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ADD_REGISTRY_INVITATIONS_REQUEST:
        case DELETE_REGISTRY_INVITATIONS_REQUEST:
        case UPDATE_REGISTRY_INVITATIONS_REQUEST:
        case ARCHIVED_REGISTRY_INVITATIONS_REQUEST:
        case RUN_REGISTRY_INVITATIONS_REQUEST:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case GET_REGISTRY_INVITATIONS_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case ADD_REGISTRY_INVITATIONS_SUCCESS:
        case DELETE_REGISTRY_INVITATIONS_SUCCESS:
        case UPDATE_REGISTRY_INVITATIONS_SUCCESS:
        case ARCHIVED_REGISTRY_INVITATIONS_SUCCESS:
        case RUN_REGISTRY_INVITATIONS_SUCCESS:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_REGISTRY_INVITATIONS_PAGE_ERROR:
        case GET_REGISTRY_INVITATIONS_BY_ID_ERROR:
            return {
                ...state,
                progress: false,
            };
        case ADD_REGISTRY_INVITATIONS_ERROR:
        case DELETE_REGISTRY_INVITATIONS_ERROR:
        case UPDATE_REGISTRY_INVITATIONS_ERROR:
        case ARCHIVED_REGISTRY_INVITATIONS_ERROR:
        case RUN_REGISTRY_INVITATIONS_ERROR:
        case CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getRegistryInvitationsPage(payload) {
    return {
        type: GET_REGISTRY_INVITATIONS_PAGE_REQUEST,
        payload,
    };
}

export function getRegistryInvitationsById(payload) {
    return {
        type: GET_REGISTRY_INVITATIONS_BY_ID_REQUEST,
        payload,
    };
}

export function addRegistryInvitations(payload) {
    return {
        type: ADD_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function deleteRegistryInvitations(payload) {
    return {
        type: DELETE_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function updateRegistryInvitations(payload) {
    return {
        type: UPDATE_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function archivedRegistryInvitations(payload) {
    return {
        type: ARCHIVED_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function runRegistryInvitations(payload) {
    return {
        type: RUN_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function checkValidResidentRegistryInvitations(payload) {
    return {
        type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//
export const registryInvitationsSelector = state => state.registryInvitations;
export const getRegistryInvitationsTotalPagesSelector = createSelector(registryInvitationsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getRegistryInvitationsTotalCountSelector = createSelector(registryInvitationsSelector, ({totalCount}) => totalCount);
export const getRegistryInvitationsListSelector = createSelector(registryInvitationsSelector, ({list}) => list);
export const getRegistryInvitationsCardSelector = createSelector(registryInvitationsSelector, ({card}) => card);
export const getRegistryInvitationsProgressSelector = createSelector(registryInvitationsSelector, ({progress}) => progress);
export const getRegistryInvitationsActionProgressSelector = createSelector(registryInvitationsSelector, ({actionProgress}) => actionProgress);

//*  SAGA  *//

//POST /api/registries/invitations/getPage
export const getRegistryInvitationsPageSaga = function* ({payload} ) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_INVITATIONS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_INVITATIONS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_INVITATIONS_PAGE_ERROR, payload: error});
    }
};

//GET /api/registries/invitations/getById
export const getRegistryInvitationsByIdSaga = function* ({payload: params} ) {
    try {
        const result = yield request.get(`${controller}/getById`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_INVITATIONS_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_INVITATIONS_BY_ID_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/add
export const addRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//DELETE /api/registries/invitations/{clientId}/{registryInvitationId}
export const deleteRegistryInvitationsSaga = function* ({payload} ) {
    try {
        const {
            clientId,
            registryInvitationId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.delete(`${controller}/${clientId}/${registryInvitationId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/update
export const updateRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/{clientId}/{registryInvitationId}/archived/{archived}
export const archivedRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryInvitationId,
            archived,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/${clientId}/${registryInvitationId}/archived/${archived}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVED_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ARCHIVED_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ARCHIVED_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/run
export const runRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/run`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RUN_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Реестр приглашений передан в работу");
        onSuccess();
        yield put({type: RUN_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: RUN_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

//POST /api/registries/invitations/isValidNonResidentFrameSigned
export const checkValidResidentRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.post(`${controller}/isValidNonResidentFrameSigned`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_REGISTRY_INVITATIONS_PAGE_REQUEST, getRegistryInvitationsPageSaga),
        takeEvery(GET_REGISTRY_INVITATIONS_BY_ID_REQUEST, getRegistryInvitationsByIdSaga),
        takeEvery(ADD_REGISTRY_INVITATIONS_REQUEST, addRegistryInvitationsSaga),
        takeEvery(DELETE_REGISTRY_INVITATIONS_REQUEST, deleteRegistryInvitationsSaga),
        takeEvery(UPDATE_REGISTRY_INVITATIONS_REQUEST, updateRegistryInvitationsSaga),
        takeEvery(ARCHIVED_REGISTRY_INVITATIONS_REQUEST, archivedRegistryInvitationsSaga),
        takeEvery(RUN_REGISTRY_INVITATIONS_REQUEST, runRegistryInvitationsSaga),
        takeEvery(CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST, checkValidResidentRegistryInvitationsSaga),
    ]);
}
