import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {simpleArrayToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const contractorsController = "/contractors";

const GET_PROJECT_PARAM_LIST_REQUEST = "GET_PROJECT_PARAM_LIST_REQUEST";
const GET_PROJECT_PARAM_LIST_SUCCESS = "GET_PROJECT_PARAM_LIST_SUCCESS";
const GET_PROJECT_PARAM_LIST_ERROR = "GET_PROJECT_PARAM_LIST_ERROR";


const initial = {
    projectParamList: [],
    listProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PROJECT_PARAM_LIST_REQUEST:
            return {
                ...state,
                listProgress: true,
            };
        case GET_PROJECT_PARAM_LIST_SUCCESS:
            return {
                ...state,
                listProgress: false,
                projectParamList: payload,
            };
        case GET_PROJECT_PARAM_LIST_ERROR: {
            return {
                ...state,
                error: payload,
                listProgress: false,
            };
        }
        default:
            return state;
    }
};

export function getProjectParamList(payload) {
    return {
        type: GET_PROJECT_PARAM_LIST_REQUEST,
        payload,
    };
}

export const projectParamsSelector = state => state.projectParams;

export const projectParamListOptionsSelector = createSelector(projectParamsSelector, ({projectParamList}) => simpleArrayToOptions(projectParamList));

export const getProjectParamListSaga = function* (action) {
    try {
        const {payload} = action;

        const {data, onSuccess} = payload;

        const result = yield request.post(`${contractorsController}/getProjectParamsList`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: GET_PROJECT_PARAM_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }
        yield put({type: GET_PROJECT_PARAM_LIST_SUCCESS, payload: result});

        onSuccess && onSuccess();

    } catch (error) {
        yield put({
            type: GET_PROJECT_PARAM_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PROJECT_PARAM_LIST_REQUEST, getProjectParamListSaga),
    ]);
}