import {
    CLEAR_CONTRACTOR_PERSONAL_DATA_EVENTS_LOG_STORE,
    CONTRACTOR_PERSONAL_DATA_EVENTS_LOG_GET_PAGE_REQUEST,
} from "./actions";

export const getPageContractorPersonalDataEventsLog = (payload) => {
    return {
        type: CONTRACTOR_PERSONAL_DATA_EVENTS_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearStoreContractorPersonalDataEventsLog = (payload) => {
    return {
        type: CLEAR_CONTRACTOR_PERSONAL_DATA_EVENTS_LOG_STORE,
        payload,
    };
};