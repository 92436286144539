import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffFinancesPatentsSummarySelector = state => state.bff.financesPatentsSummary;

export const patentsSummaryListSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryData}) => summaryData,
);

export const patentsSummaryProgressSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryDataProgress}) => summaryDataProgress,
);

export const patentsSummaryTotalCountSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryTotalCount}) => summaryTotalCount,
);

export const patentsSummaryListTotalPagesSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryTotalCount, summaryPageData: {pageSize = 0}}) => getTotalPages(summaryTotalCount, pageSize),
);

export const patentsTotalSummaryDataSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryTotalData}) => summaryTotalData,
);

export const patentsTotalSummaryProgressSelector = createSelector(
    bffFinancesPatentsSummarySelector,
    ({summaryTotalProgress}) => summaryTotalProgress,
);