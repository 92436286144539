import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import NmSelectedList from "../../../../components/NmSelectedList";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {KedoDirectoriesTabs} from "../tabs";
import {KedoDocumentRoutesDetailsModal} from "./components/details-modal";
import {KedoDocumentRouteEditModal} from "./components/edit-modal";

import {usePagination} from "../../../../hooks/usePagination";
import {useSuccessActionCallback} from "../../../../hooks/useSuccessAction";
import {useKedoDirectoriesSort} from "../hooks/useSort";
import {useKedoDocumentRoutesAction} from "./hooks/useAction";
import {useKedoDocumentRoutesFetch} from "./hooks/useFetch";
import {useKedoDocumentRoutesFilter} from "./hooks/useFilter";

import {getUserRole} from "../../../../utils/access";
import dateFormat from "../../../../utils/dateFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ADMIN,
    NM_MANAGER,
} from "../../../../constants/roles";
import {KEDO_DICT_DOCUMENT_ROUTES_ERROR_CODES} from "./constants";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";
import {updateKedoDirectoriesStore} from "../../../../ducks/kedo/directories/actionCreators";
import {
    kedoDirectoriesDocumentRoutesSelector,
    kedoDirectoriesErrorSelector,
    kedoDirectoriesProgressActionSelector,
} from "../../../../ducks/kedo/directories/selectors";

import {KedoDocumentRouteEditTypesModal} from "./components/edit-document-types";

import {KedoDocumentRouteLinkedTypesModal} from "./components/linked-document-types-modal";

export const KedoDocumentRoutes = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const {
        totalPages,
        totalCount,
        list,
        progress,
    } = useSelector(kedoDirectoriesDocumentRoutesSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);
    const error = useSelector(kedoDirectoriesErrorSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const dispatch = useDispatch();

    const role = getUserRole();
    const isAccessActions = !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingDirectory);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {
        onClickSort,
        sortType,
        sortOptions,
    } = useKedoDirectoriesSort({
        isDocumentRoutePage: true,
        initialSortType: "CREATED_DESC",
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    } = useKedoDocumentRoutesFilter({
        clientId,
        pageSize,
        setPagination,
    });

    useKedoDocumentRoutesFetch({
        filterData,
        pageNum,
        pageSize,
        clientId,
        sortType,
    });

    const {
        getMediaControls,
        modalData,
        onCloseModal,
        onOpenModal,
        openLinkedDocumentsTypesModal,
    } = useKedoDocumentRoutesAction({
        isAccessActions,
    });

    useSuccessActionCallback({
        callback: () => {
            if (error?.errorCode === KEDO_DICT_DOCUMENT_ROUTES_ERROR_CODES.DOCUMENT_TYPES_EXISTS) {
                onOpenModal({
                    isOpenLinkedTypesModal: true,
                    routeId: modalData.routeId,
                    title: "Удаление маршрута невозможно",
                    description: (role === ADMIN && !clientId)
                        ? "Данный маршрут привязан к следующим типам документов. Назначьте для них новый маршрут и повторите попытку удаления"
                        : "Назначьте новый маршрут для загруженных типов и обратитесь к Администратору Наймикс, чтобы назначить новый маршрут для шаблонных типов документов",
                    isShowTabs: true,
                    isShowCancelButton: true,
                });
            }

            if (error) {
                dispatch(updateKedoDirectoriesStore({
                    error: null,
                }));

                return;
            }

            onCloseModal();
        },
        progressAction,
    });

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.documentTypeId,
                contentRow: (
                    <NmListCard
                        className="align-items-stretch"
                        primaryHeader={
                            clientId
                                ? item.nameForClient
                                : item.name
                        }
                        noDivider={true}
                        labels={[
                            !clientId && {label: "Наименование для компании", text: item.nameForClient || "-"},
                            !clientId && {label: "Компания", text: item.clientName || "-"},
                            {label: "Тип маршрута", text: item.typeStr || "-"},
                            {
                                label: "Типы загруженных документов",
                                text: !isEmpty(item.linkedUploadedDocumentTypesNames) ?
                                    <NmSelectedList
                                        type="page"
                                        showListWithoutValue
                                        modalTitle="Типы загруженных документов"
                                        isShowDetailModal={true}
                                        highlightingFirstcard={false}
                                        list={item.linkedUploadedDocumentTypesNames.map(item => ({text: item}))}
                                        onClickShowMore={() => openLinkedDocumentsTypesModal(item)}
                                        isPreventDefaultClickShowMore={true}
                                        showMoreCount={item.linkedUploadedDocumentTypesNamesCount - item.linkedUploadedDocumentTypesNames.length}
                                    /> : "-",
                            },
                            !clientId && {
                                label: "Типы шаблонных документов",
                                text: !isEmpty(item.linkedTemplateDocumentTypesNames) ?
                                    <NmSelectedList
                                        type="page"
                                        showListWithoutValue
                                        modalTitle="Типы шаблонных документов"
                                        isShowDetailModal={true}
                                        highlightingFirstcard={false}
                                        list={item.linkedTemplateDocumentTypesNames.map(item => ({text: item}))}
                                        onClickShowMore={() => openLinkedDocumentsTypesModal(item)}
                                        isPreventDefaultClickShowMore={true}
                                        showMoreCount={item.linkedTemplateDocumentTypesNamesCount - item.linkedTemplateDocumentTypesNames.length}
                                    /> : "-",
                            },
                            {
                                label: "Дата создания", text:
                                    dateFormat(item.createdAt, "dd.MM.yyyy"),
                            },
                            {
                                label: "Создатель", text:
                                    item.creatorFio || "-",
                            },
                        ]}
                        classNameMainContent="col-16 col-xxl-14"
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const renderDetailsModal = () => {
        return (
            modalData?.isOpenDetails &&
            <KedoDocumentRoutesDetailsModal
                onClose={onCloseModal}
                routeId={modalData.routeId}
                typeRoute={modalData.typeRoute}
            />
        );
    };

    const renderEditModal = () => {
        return (
            modalData?.isOpenEdit &&
            <KedoDocumentRouteEditModal
                clientId={clientId}
                editData={modalData.editData}
                onClose={onCloseModal}
            />
        );
    };

    const renderEditDocumentTypesModal = () => {
        return (
            modalData?.isOpenEditDocumentTypes &&
            <KedoDocumentRouteEditTypesModal
                routeId={modalData.routeId}
                routeName={modalData.routeName}
                onClose={onCloseModal}
            />
        );
    };

    const renderConfirmModal = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                {...modalData}
                onCancel={onCloseModal}
                confirmButton={modalData.confirmButton || "Подтвердить"}
                cancelButton="Отменить"
                disabled={progressAction}
                isNeedClosing={false}
            />
        );
    };

    return (
        <>
            <KedoDirectoriesTabs
                clientId={clientId}
                isSearch={isSearch}
                routesCount={totalCount}
                {...props}
            />
            <NmPage
                noPadding
                header={
                    <NmPageHeader text="Маршруты подписания" />
                }
                controls={
                    isAccessActions &&
                    [CLIENT_ADMIN, ADMIN, NM_MANAGER].includes(role) &&
                    <NmButton
                        size="xl"
                        onClick={() => onOpenModal({
                            isOpenEdit: true,
                        })}
                        icon={<AddIcon />}
                    >
                        Добавить маршрут
                    </NmButton>
                }
                filtersBase={
                    <Filter
                        initState={filterData}
                        filters={filters}
                        onSubmit={onSearch}
                        clearFilter={onClear}
                    />
                }
                typeFilter="vertical"
                totalCount={totalCount}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                isLoaded={progress}
            >
                {renderConfirmModal()}
                {renderDetailsModal()}
                {renderEditModal()}
                {renderEditDocumentTypesModal()}
                {
                    modalData?.isOpenLinkedTypesModal &&
                    <KedoDocumentRouteLinkedTypesModal
                        {...modalData}
                        onClose={onCloseModal}
                    />
                }
                {
                    list.length
                        ? <CheckboxList
                            rows={rows}
                            sort={true}
                            sortOptions={sortOptions}
                            onClickSort={onClickSort}
                        />
                        : <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </>
    );
};