import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const registryPaymentLogSelector = state => state.registryPaymentLog;
export const registryPaymentLogListSelector = createSelector(
    registryPaymentLogSelector,
    ({list}) => list,
);
export const registryPaymentLogTotalCountSelector = createSelector(
    registryPaymentLogSelector,
    ({totalCount}) => totalCount,
);
export const registryPaymentLogTotalPagesSelector = createSelector(
    registryPaymentLogSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);
export const registryPaymentLogProgressListSelector = createSelector(
    registryPaymentLogSelector,
    ({progressList}) => progressList,
);