import {
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
    GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST,
    PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS,
} from "./actions";

const initialState = {
    progress: false,
    progressPaymentsUpdateBankStatus: false,
    progressPaymentsUpdateBankStatusAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST:
            return {
                ...state,
                progressPaymentsUpdateBankStatusAction: true,
                progressPaymentsUpdateBankStatus: true,
            };
        case GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS:
            return {
                ...state,
                progressPaymentsUpdateBankStatusAction: false,
            };
        case GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_SUCCESS:
            const {guid} = payload;

            return {
                ...state,
                progressPaymentsUpdateBankStatus: Boolean(guid),
            };
        case PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR:
            return {
                ...state,
                progressPaymentsUpdateBankStatusAction: false,
            };
        case GET_RUNNING_PAYMENTS_UPDATE_ALL_BANK_STATUS_ERROR:
            return {
                ...state,
                progressPaymentsUpdateBankStatus: false,
            };
        default:
            return state;
    }
};