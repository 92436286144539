import {
    BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE,
    BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE, BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST, BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST,
} from "./actions";

export const getClientsMonitoringRisks = (payload) => {
    return {
        type: BFF_CLIENTS_GET_MONITORING_RISKS_REQUEST,
        payload,
    };
};

export const getClientsMonitoringRisksContractors = (payload) => {
    return {
        type: BFF_CLIENTS_GET_MONITORING_RISKS_CONTRACTORS_REQUEST,
        payload,
    };
};

export const generateClientsMonitoringRisksReport = (payload) => {
    return {
        type: BFF_CLIENTS_GENERATE_MONITORING_RISKS_REPORT_REQUEST,
        payload,
    };
};

export const updateClientsMonitoringRisksData = (payload) => {
    return {
        type: BFF_CLIENTS_UPDATE_MONITORING_RISKS_DATA_REQUEST,
        payload,
    };
};

export const clearClientsMonitoringRisksContractorsStore = () => {
    return {
        type: BFF_CLIENTS_CLEAR_MONITORING_RISKS_CONTRACTORS_STORE,
    };
};

export const clearClientsMonitoringRisksStore = () => {
    return {
        type: BFF_CLIENTS_CLEAR_MONITORING_RISKS_STORE,
    };
};