import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST,
    BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/foremen/foreman-contractors",
        client: "/client-adm/client/foremen/foreman-contractors",
    });
};

// POST /bff/adm/clients/client-card/foremen/foreman-contractors/getRichPage
// POST /bff/client-adm/client/foremen/foreman-contractors/getRichPage
// Получение страницы Бригадиры
function* getClientForemenForemanContractorsRichPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_ERROR,
            payload: error,
        });
    }
}

// POST /bff/adm/clients/client-card/foremen/foreman-contractors/delete
// POST /bff/client-adm/client/foremen/foreman-contractors/delete
// Снять самозанятого с должности бригадир
function* deleteClientForemenForemanContractorsSaga({payload}) {
    try {
        const {
            handleResponse,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);
        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_ERROR,
            payload: error,
        });
    }
}

// POST /bff/adm/clients/client-card/foremen/foreman-contractors/add
// POST /bff/client-adm/client/foremen/foreman-contractors/add
// Назначить бригадиром
function* addClientForemenForemanContractorsSaga({payload}) {
    try {
        const {
            handleResponse,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError && onError();

            yield put({
                type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);
        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_RICH_PAGE_REQUEST, getClientForemenForemanContractorsRichPageSaga),
        takeEvery(BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_DELETE_REQUEST, deleteClientForemenForemanContractorsSaga),
        takeEvery(BFF_CLIENT_FOREMEN_FOREMAN_CONTRACTORS_ADD_REQUEST, addClientForemenForemanContractorsSaga),
    ]);
}