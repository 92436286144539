import {
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST,
    UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENT_USERS_STORE,
} from "./actions";

export function getPageSettingsClientGroupsCardClientUsers(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_GET_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsClientGroupsCardClientUsers(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_ADD_REQUEST,
        payload,
    };
}

export function deleteSettingsClientGroupsCardClientUsers(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_CLIENT_USERS_DELETE_REQUEST,
        payload,
    };
}

export const updateStoreSettingsClientGroupsClientUsers = (payload) => {
    return {
        type: UPDATE_SETTINGS_CLIENT_GROUPS_CARD_CLIENT_USERS_STORE,
        payload,
    };
};