import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as CopyIcon} from "../../../images/file_copy_24.svg";
import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import SettingsUsersRolesEditForm from "./components/edit-form";

import {usePagination} from "../../../hooks/usePagination";
import {useActionSettingsUsersRoles} from "./hooks/useActionSettingsUsersRoles";
import {useFetchSettingsUsersRoles} from "./hooks/useFetchSettingsUsersRoles";

import bem from "../../../utils/bem";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {USERS_ROLES_ACTION_TYPE, USERS_ROLES_TYPE} from "../../../constants/settingsUsersRoles";

import {
    settingsUsersRolesCountsSelector,
    settingsUsersRolesListSelector,
    settingsUsersRolesProgressActionSelector,
    settingsUsersRolesProgressSelector,
    settingsUsersRolesTotalCountSelector,
    settingsUsersRolesTotalPagesSelector,
} from "../../../ducks/settingsUsersRoles";

import "./style.sass";

function SettingsUsersRoles() {
    const list = useSelector(settingsUsersRolesListSelector);
    const totalCount = useSelector(settingsUsersRolesTotalCountSelector);
    const totalPages = useSelector(settingsUsersRolesTotalPagesSelector);
    const progress = useSelector(settingsUsersRolesProgressSelector);
    const actionProgress = useSelector(settingsUsersRolesProgressActionSelector);
    const counts = useSelector(settingsUsersRolesCountsSelector);

    const [subPage, setSubPage] = useState(USERS_ROLES_TYPE.NAIMIX);

    const [, element] = bem("settings-users-roles");

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        fetchList,
        restrictionDescriptions,
    } = useFetchSettingsUsersRoles({
        pageNum,
        pageSize,
        subPage,
    });

    const {
        isOpenEditForm,
        toggleOpenEditForm,
        confirmAction,
        deleteAction,
        closeConfirmModal,
        editAction,
        editModalData,
    } = useActionSettingsUsersRoles({
        fetchList,
    });

    const getActions = (item) => {
        return ({
            renderCount: {
                mobile: 0,
                tablet: 3,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <ModeIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => {
                            editAction(USERS_ROLES_ACTION_TYPE.EDIT, item);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <CopyIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => {
                            editAction(USERS_ROLES_ACTION_TYPE.COPY, item);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Копировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <DeleteIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => {
                            deleteAction(item);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        });
    };

    const renderRestrictions = (restrictions) => {
        return restrictions.map((item) => {
            const {name, variable} = item || {};
            const tooltipText = restrictionDescriptions[variable] || "";

            return (
                <NmBadge
                    key={variable}
                    text={name}
                    mod="light-green"
                    rightIcon={
                        <HelpTooltip
                            info
                            classNameIcon={element("restrictions-item-icon")}
                            width={16}
                            height={16}
                            color={COLOR.PRIMARY_100}
                            children={
                                <div>
                                    <div className="flex flex-aligned-center">
                                        <InfoIcon
                                            className="me-2"
                                            width={16}
                                            height={16}
                                        />
                                        <Text level="2">
                                            {variable}
                                        </Text>
                                    </div>
                                    <Text bold>
                                        {name}
                                    </Text>
                                    {tooltipText}
                                </div>
                            }
                            outline
                            position="right-top"
                        />
                    }
                />
            );
        });
    };

    const getRows = () => {
        return list.map(item => {
            const {
                name,
                description,
                clientUserCount,
                restrictions,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={name}
                        classNameMainContent="col-16 col-md-14"
                        labels={[
                            {
                                label: "Описание",
                                text: description,
                            },
                            {
                                label: "Пользователей",
                                text: clientUserCount,
                            },
                            {
                                wrapped: true,
                                noWrap: false,
                                classNameText: element("restrictions-container"),
                                text: (
                                    <>
                                        <Text
                                            level="2"
                                            color={COLOR.SECONDARY_70}
                                        >
                                            Ограничения:
                                        </Text>
                                        {renderRestrictions(restrictions)}
                                    </>
                                ),
                            },
                        ]}
                        mediaControls={getActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    const getTabsPanes = () => {
        return ([
            {
                count: counts[USERS_ROLES_TYPE.NAIMIX] || 0,
                name: "Роли Наймикс",
                onClick: () => setSubPage(USERS_ROLES_TYPE.NAIMIX),
                active: subPage === USERS_ROLES_TYPE.NAIMIX,
            },
            {
                count: counts[USERS_ROLES_TYPE.CLIENT] || 0,
                name: "Роли компании",
                onClick: () => setSubPage(USERS_ROLES_TYPE.CLIENT),
                active: subPage === USERS_ROLES_TYPE.CLIENT,
            },
        ]);
    };

    const renderConfirmWindow = () => {
        if (isEmpty(confirmAction)) {
            return null;
        }

        const {
            content,
            onConfirm,
        } = confirmAction;

        return (
            <NmConfirmV2
                content={content}
                onConfirm={onConfirm}
                onCancel={closeConfirmModal}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                disabled={actionProgress}
                loading={actionProgress}
                isOnlyConfirm
            />
        );
    };

    return (
        <NmPage
            noPadding
            header={
                <Tabs
                    secondary
                    className="flex-grow-1"
                    panes={getTabsPanes()}
                />
            }
            controls={
                <NmButton
                    size="xl"
                    onClick={toggleOpenEditForm}
                    icon={<AddIcon />}
                >
                    Добавить роль
                </NmButton>
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        >
            {renderConfirmWindow()}
            {
                isOpenEditForm &&
                <SettingsUsersRolesEditForm
                    card={editModalData}
                    subPage={subPage}
                    restrictionDescriptions={restrictionDescriptions}
                    fetchList={fetchList}
                    onClose={toggleOpenEditForm}
                    isEdit={editModalData.actionType === USERS_ROLES_ACTION_TYPE.EDIT}
                />
            }
            {
                totalCount > 0 ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        title="Информация отсутствует"
                    />
            }
        </NmPage>
    );
}

export default SettingsUsersRoles;
