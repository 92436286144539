import React, {useState} from "react";
import Media from "react-media";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmDivider from "../../../components/ActualComponents/NmDivider";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import BankIndicator from "../../../components/BankIndicator";
import ContractorEditParams from "../../../components/ContractorEditParams";
import ContractorIndividualEntrepreneurIndicator from "../../../components/ContractorIndividualEntrepreneurIndicator";
import InstrumentPaymentIndicator from "../../../components/InstrumentPaymentIndicator";
import NmAccordion from "../../../components/NmAccordion";
import NmReadonlyRating from "../../../components/NmReadonlyRating";
import NmSelectedList from "../../../components/NmSelectedList";
import NmStatusPassportInnContractor from "../../../components/NmStatusPassportInnContractor";
import SelfEmployedIndicator from "../../../components/SelfEmployedIndicator";
import {ReactComponent as SuccessIcon} from "../../../images/check-bg.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import {ReactComponent as Reward} from "../../../images/reward.svg";
import {ReactComponent as WorldSkills} from "../../../images/reward-worldskills.svg";
import BankRequisiteRequiredEditForm from "../bank-requisites-required-edit-form";
import PromocodeInfo from "../contractor_info/promocode-info";
import ContractorNoteBlock from "./components/note-block";
import ContractorGeneralInfoPassportChecking from "./components/passport-checking";

import {useFetchAvatar} from "./hooks/useFetchAvatar";
import {usePaymentPossibleCheck} from "./hooks/usePaymentPossibleCheck";

import {
    getClientGroupListForContractorInfo,
    getContractorCitizenGenderAgeInfo,
} from "../../../utils/contractors";
import {getDadataAddressObj} from "../../../utils/dadata";
import {formatLocalDate} from "../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {
    CONTRACTOR_LEAD_TYPE_TRANSLATE,
} from "../../../constants/contractorInfo";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../../constants/roles";

import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";
import {getCitizenshipSelector} from "../../../ducks/contractor";
import {avatarBase64ImageSelector} from "../../../ducks/fileStore";

import "./style.sass";

export default function ContractorGeneralInfo(props) {
    const {
        className,
        contractor = {},
        role,
        clientId,
        isNmEmployees,
        rating,
    } = props;

    const {
        citizenship,
        contractorId,
        taxStatus,
        taxRegistrationDate,
        naimixJoiningDate,
        clientGroupModelList,
        projectParams,
        leadsCompleted = [],
        utm_source,
        gender,
        age,
        mainSpeciality,
        inviteWorldSkills,
        fullRegistrationDate,
        contractorFullRegistrationDateTime,
        workAddressInfo,
        familyStatus,
        bankRequisitesRequired,
        avatarPresent,
        leadTypes,
        individualEntrepreneurStatus,
        individualEntrepreneurLegalInfo,
        bankCardNumber,
    } = contractor;

    const avatarBase64 = useSelector(avatarBase64ImageSelector);
    const citizenshipDict = useSelector(getCitizenshipSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const workAddressInfoText = workAddressInfo ? getDadataAddressObj(workAddressInfo).text : "";

    const [timeoutForCheckPossible, setTimeoutForCheckPossible] = useState(0);
    const [isEditParams, setEditParams] = useState(false);
    const [isEditBankRequisiteRequired, setEditBankRequisiteRequired] = useState(false);
    const {
        refreshPaymentPossibility,
        contractorsPossibility,
    } = usePaymentPossibleCheck({startTimer, clientId, contractorId: props.params.contractorId});

    useFetchAvatar({contractorId, avatarPresent});

    const toggleParamsEdit = (flag) => {
        return () => {
            setEditParams(flag);
        };
    };

    const toggleBankRequisiteRequiredEdit = (flag) => {
        setEditBankRequisiteRequired(flag);
    };

    const isShowClientGroupInfo = () => {
        return [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_OPERATOR].includes(role);
    };

    const renderClientGroupInfo = () => {
        if (!isShowClientGroupInfo() || isEmpty(clientGroupModelList)) {
            return null;
        }

        const list = getClientGroupListForContractorInfo(clientGroupModelList);

        return (
            <NmSelectedList
                list={list}
                label="Заказчик"
                showedItemsCount={1}
                showListWithoutValue
                labelClassName="contractor-general-info__client-group-label"
                className="contractor-general-info__client-group-info mb-2"
                itemClassName="contractor-general-info__list-item"
            />
        );
    };

    function renderParams() {
        if (![ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)) {
            return null;
        }

        function renderProjectParams() {
            const edit = [ADMIN].includes(role)
                && isAccessByRestrictions({
                    currentUserRestrictions,
                    checkList: [
                        CLIENT_USER_RESTRICTIONS_VARIABLE.editContractorProjectParam,
                        CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractorProjectParams,
                    ],
                }) && <ModeIcon
                onClick={toggleParamsEdit(true)}
                className="contractor-general-info__edit-icon"
            />;

            if (isEmpty(projectParams)) {
                return (
                    <NmLabelText
                        label="ProjectParams"
                        type="page"
                        className="mb-2"
                        text={<div className="d-flex">
                            <div>
                                -
                            </div>
                            {edit}
                        </div>}
                    />
                );
            }

            return <div className="flex flex-aligned-center mb-2">
                <NmSelectedList
                    list={projectParams.map((text) => ({
                        text:
                            <div className="flex flex-aligned-center">
                                {text}
                                {
                                    leadsCompleted.includes(text) && [ADMIN, NM_MANAGER].includes(role) &&
                                    <NmAdvancedTooltip
                                        className="ms-1"
                                        hover
                                        position="bottom-left"
                                        trigger={
                                            <SuccessIcon
                                                width={16}
                                                height={16}
                                            />
                                        }
                                        children="Лид с данным параметром является завершенным (находится в коллекции leadsCompleted)"
                                    />
                                }
                            </div>,
                    }))}
                    label="ProjectParams"
                    showedItemsCount={3}
                    showListWithoutValue
                    className="contractor-general-info__client-group-info contractor-general-info__project-params"
                />
                {edit}
            </div>;
        }

        return (
            <>
                <NmLabelText
                    className="mb-md-2 mb-xxl-2 mb-2"
                    type="page"
                    label="UTM"
                    text={isNullOrWhitespace(utm_source) ? "-" : utm_source}
                />
                {renderProjectParams()}
            </>
        );
    }

    function renderBankRequisitesRequired() {
        if (![ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO, NM_OPERATOR].includes(role)) {
            return null;
        }

        const edit = [ADMIN].includes(role)
            && isAccessByRestrictions({
                currentUserRestrictions,
                checkList: [
                    CLIENT_USER_RESTRICTIONS_VARIABLE.needBankDetails,
                    CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractorProjectParams,
                ],
            }) && <ModeIcon
            onClick={() => toggleBankRequisiteRequiredEdit(true)}
            className="contractor-general-info__edit-icon"
        />;

        return (
            <NmLabelText
                label="Банковские реквизиты"
                type="page"
                className="mb-2"
                text={<div className="d-flex">
                    <div>
                        {bankRequisitesRequired ? "Требуются" : "Не требуются"}
                    </div>
                    {edit}
                </div>}
            />
        );
    }

    function startTimer(secToNextRefresh) {
        if (secToNextRefresh === 0) {
            return;
        }

        let counter = secToNextRefresh;

        setTimeoutForCheckPossible(secToNextRefresh);

        const tick = () => {
            timerId = setTimeout(tick, 1000);

            const nextSeconds = --counter;

            if (nextSeconds < 0) {
                clearTimeout(timerId);

                return;
            }

            setTimeoutForCheckPossible(nextSeconds);
        };

        let timerId = setTimeout(tick, 1000);
    }

    const renderDetailedInformation = (desktop) => {
        return (
            <>
                <div className="col-md-16 col-xl-5">
                    {[ADMIN, NM_MANAGER, NM_COORDINATOR, NM_OPERATOR].includes(role) && <NmStatusPassportInnContractor
                        role={role}
                        classNameRefreshButton="contractor-general-info__refresh-button"
                        contractor={contractor}
                        className="mb-2"
                    />}
                    <ContractorGeneralInfoPassportChecking className="mb-2" />
                    {
                        !isClientUser(role) &&
                        <BankIndicator
                            showLabel
                            size="lg"
                            className="mb-2"
                            refreshPaymentPossibility={refreshPaymentPossibility}
                            timeoutForCheckPossible={timeoutForCheckPossible}
                            contractorsPossibility={contractorsPossibility}
                            contractorId={props.params.contractorId}
                        />
                    }
                    <InstrumentPaymentIndicator
                        value={Boolean(bankCardNumber)}
                        size="lg"
                        className="mb-2"
                    />
                    {
                        !isUzOrTj &&
                        <SelfEmployedIndicator
                            className="mb-2"
                            taxStatus={taxStatus}
                            taxRegistrationDate={taxRegistrationDate}
                            isShowTaxRegistrationDate={![
                                NM_CONSULTANT,
                                NM_PARTNER,
                            ].includes(role)}
                        />
                    }
                    <ContractorIndividualEntrepreneurIndicator
                        status={individualEntrepreneurStatus}
                        statusDate={individualEntrepreneurLegalInfo?.registrationIssuedDateTime}
                    />
                </div>
                {
                    !desktop &&
                    <NmDivider
                        color={COLOR.SECONDARY_5}
                        className="mb-3 mt-3 mb-md-4 mt-md-4"
                    />
                }
                <div className="col-md-16 col-xl-3">
                    {
                        isNmEmployees &&
                        (naimixJoiningDate || fullRegistrationDate) &&
                        <>
                            {
                                naimixJoiningDate &&
                                <NmLabelText
                                    type="page"
                                    label="Первичная регистрация"
                                    className="mb-2"
                                    text={formatLocalDate(naimixJoiningDate, "dd.MM.yyyy HH:mm")}
                                />
                            }
                            {
                                fullRegistrationDate &&
                                <NmLabelText
                                    type="page"
                                    label="Полная регистрация"
                                    className="mb-2"
                                    text={formatLocalDate(fullRegistrationDate, "dd.MM.yyyy HH:mm")}
                                />
                            }
                            <NmLabelText
                                type="page"
                                label="Проверка в банке"
                                className="mb-2"
                                text={formatLocalDate(contractorFullRegistrationDateTime, "dd.MM.yyyy HH:mm")}
                            />
                            {
                                renderClientGroupInfo()
                            }
                        </>
                    }
                    {renderParams()}
                    {renderBankRequisitesRequired()}
                    <PromocodeInfo />
                    {
                        !isClientUser(role) &&
                        <NmLabelText
                            type="page"
                            label="Тип лида"
                            className="mt-2"
                            text={
                                leadTypes?.length ?
                                    leadTypes
                                        .map(item => CONTRACTOR_LEAD_TYPE_TRANSLATE[item] || item)
                                        .join(", ") :
                                    "-"
                            }
                        />
                    }
                </div>
                {
                    !desktop &&
                    <NmDivider
                        color={COLOR.SECONDARY_5}
                        className="mb-2 mt-3 mt-md-4"
                    />
                }
                {
                    [
                        CLIENT_ADMIN,
                        PROJECT_MANAGER,
                        OBJECT_MANAGER,
                        FOREMAN,
                        CLIENT_ACCOUNTANT,
                        ADMIN,
                        NM_MANAGER,
                        NM_OPERATOR,
                        NM_COORDINATOR,
                        NM_CHIEF_ACCOUNTANT,
                    ].includes(role) &&
                    <ContractorNoteBlock
                        isDesktop={desktop}
                        contractorId={contractorId}
                    />
                }
            </>
        );
    };

    const isUzOrTj = [citizenshipsDict.UZ.value, citizenshipsDict.TJ.value].includes(citizenship);

    const additionalInformation = getContractorCitizenGenderAgeInfo({
        citizenship: citizenship ? citizenshipDict[citizenship] : null,
        gender,
        age,
        familyStatus,
    });

    return (
        <Media
            queries={{
                mobile: {maxWidth: 767},
                tablet: {minWidth: 768, maxWidth: 1199},
                desktop: {minWidth: 1200},
            }}
        >
            {
                ({desktop}) => (
                    <div className={`${desktop ? "d-flex" : ""} contractor-general-info ${className}`}>
                        {isEditParams && <ContractorEditParams
                            projectParams={projectParams}
                            leadsCompleted={leadsCompleted}
                            role={role}
                            close={toggleParamsEdit(false)}
                        />}
                        {isEditBankRequisiteRequired && <BankRequisiteRequiredEditForm
                            contractorId={contractorId}
                            bankRequisitesRequired={bankRequisitesRequired}
                            close={() => toggleBankRequisiteRequiredEdit(false)}
                        />}
                        {
                            desktop &&
                            <div className="col-1">
                                <Avatar
                                    size="xl"
                                    addedToMyClientGroup={contractor.addedToMyClientGroup}
                                    base64={avatarBase64}
                                    contractorId={contractorId}
                                    isEditable={[ADMIN, NM_MANAGER].includes(role)}
                                />
                            </div>
                        }
                        <div className="col-md-16 col-xl-4 d-flex flex-column">
                            <Text
                                bold
                                className="mb-md-2 mb-1 mb-xxl-2"
                                type="sub"
                            >
                                {mainSpeciality?.value}
                            </Text>
                            {
                                additionalInformation &&
                                <Text className="mb-md-2 mb-1 mb-xxl-2">
                                    {additionalInformation}
                                </Text>}
                            <Text
                                className="mb-md-2 mb-1 mb-xxl-2"
                            >
                                {workAddressInfoText}
                            </Text>
                            <NmLabelText
                                className="mb-md-2 mb-1 mb-xxl-2"
                                alignItems="center"
                                type="page"
                                label="Общий рейтинг"
                                text={
                                    <NmReadonlyRating
                                        rating={rating || 0}
                                        maxRating={1}
                                        fixedNumber={2}
                                        isMiniRatingInfo
                                    />
                                }
                            />
                            <NmLabelText
                                className="mb-md-2"
                                alignItems="center"
                                type="page"
                                noWrap={false}
                                label="Награды"
                                text={
                                    <div className="d-flex flex-aligned-end">
                                        {!inviteWorldSkills && <WorldSkills
                                            className="contractor-general-info__reward-ws-icon me-2"
                                        />}
                                        {inviteWorldSkills && <NmAdvancedTooltip
                                            hover
                                            type="dark"
                                            position="bottom-left"
                                            trigger={<WorldSkills
                                                className="contractor-general-info__reward-ws-icon contractor-general-info__reward-ws-icon_active me-2"
                                            />}
                                        >
                                            Прошел обучение по стандартам Ворлдскиллс Россия.
                                        </NmAdvancedTooltip>
                                        }
                                        <NmAdvancedTooltip
                                            hover
                                            type="dark"
                                            position="bottom-left"
                                            trigger={<Reward
                                                className={"contractor-general-info__reward-icon"}
                                            />}
                                        >
                                            Исполнитель заполнил профиль на 100%
                                        </NmAdvancedTooltip>
                                    </div>
                                }
                            />
                        </div>
                        {
                            !desktop ?
                                <NmAccordion
                                    defaultOpen
                                    className="mt-4"
                                    name="Подробная информация"
                                    //classNameTitle="order-edit-additional__header order-edit-additional__header_no-mb"
                                >
                                    {renderDetailedInformation(desktop)}
                                </NmAccordion> :
                                renderDetailedInformation(desktop)
                        }
                    </div>
                )
            }
        </Media>
    );
}