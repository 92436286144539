import {
    BFF_EXPORT_DOCUMENTS_ADD_ERROR,
    BFF_EXPORT_DOCUMENTS_ADD_REQUEST,
    BFF_EXPORT_DOCUMENTS_ADD_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST,
    BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS,
    UPDATE_EXPORT_DOCUMENTS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    exportDocumentTypeDict: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_EXPORT_DOCUMENTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_EXPORT_DOCUMENTS_GET_PAGE_SUCCESS:
            const {
                results,
                documentsExportModels,
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results || documentsExportModels,
                totalCount,
            };
        case BFF_EXPORT_DOCUMENTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_EXPORT_DOCUMENTS_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_EXPORT_DOCUMENTS_ADD_SUCCESS:
        case BFF_EXPORT_DOCUMENTS_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_EXPORT_DOCUMENTS_GET_DOCUMENT_TYPE_DICT_SUCCESS:
            return {
                ...state,
                exportDocumentTypeDict: payload,
            };
        case UPDATE_EXPORT_DOCUMENTS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};