import {
    addKedoDirectoryPosition,
    addKedoDirectorySubdivision,
    addKedoUploadDocumentType,
    deleteKedoDirectoryPosition,
    deleteKedoDirectorySubdivision,
    deleteKedoUploadDocumentType,
    getKedoDirectoryPageCount,
    getKedoDirectoryPositionsPage,
    getKedoDirectorySubdivisionsPage,
    getKedoUploadDocumentTypesPage,
    updateKedoDirectoryPosition,
    updateKedoDirectorySubdivision,
    updateKedoUploadDocumentType,
} from "../../../../kedo/directories/actionCreators";

const controller = "/client-adm/kedo/directories";

// Типы документов

export const getClientsKedoDocumentTypePage = (payload) => {
    return getKedoUploadDocumentTypesPage({
        url: `${controller}/getDocumentTypePage`,
        ...payload,
    });
};

export const updateClientsKedoDocumentType = (payload) => {
    return updateKedoUploadDocumentType({
        url: `${controller}/updateDocumentType`,
        ...payload,
    });
};

export const addClientsKedoDocumentType = (payload) => {
    return addKedoUploadDocumentType({
        url: `${controller}/addDocumentType`,
        ...payload,
    });
};

export const deleteClientsKedoDocumentType = (payload) => {
    return deleteKedoUploadDocumentType({
        url: `${controller}/deleteDocumentType`,
        ...payload,
    });
};

// Должности

export const getClientsKedoPositionsPage = (payload) => {
    return getKedoDirectoryPositionsPage({
        url: `${controller}/getPositionsPage`,
        ...payload,
    });
};

export const updateClientsKedoPosition = (payload) => {
    return updateKedoDirectoryPosition({
        url: `${controller}/updatePosition`,
        ...payload,
    });
};

export const addClientsKedoPosition = (payload) => {
    return addKedoDirectoryPosition({
        url: `${controller}/addPosition`,
        ...payload,
    });
};

export const deleteClientsKedoPosition = (payload) => {
    return deleteKedoDirectoryPosition({
        url: `${controller}/deletePosition`,
        ...payload,
    });
};

// Структурные подразделения

export const getClientsKedoSubdivisionsPage = (payload) => {
    return getKedoDirectorySubdivisionsPage({
        url: `${controller}/getSubdivisionsPage`,
        ...payload,
    });
};

export const updateClientsKedoSubdivision = (payload) => {
    return updateKedoDirectorySubdivision({
        url: `${controller}/updateSubdivision`,
        ...payload,
    });
};

export const addClientsKedoSubdivision = (payload) => {
    return addKedoDirectorySubdivision({
        url: `${controller}/addSubdivision`,
        ...payload,
    });
};

export const deleteClientsKedoSubdivision = (payload) => {
    return deleteKedoDirectorySubdivision({
        url: `${controller}/deleteSubdivision`,
        ...payload,
    });
};

// Счетчики

export const getClientsKedoPageCount = (payload) => {
    return getKedoDirectoryPageCount({
        url: `${controller}/getPageCount`,
        ...payload,
    });
};