export const GET_PAGE_CLIENT_CATEGORIES_REQUEST = "GET_PAGE_CLIENT_CATEGORIES_REQUEST";
export const GET_PAGE_CLIENT_CATEGORIES_SUCCESS = "GET_PAGE_CLIENT_CATEGORIES_SUCCESS";
export const GET_PAGE_CLIENT_CATEGORIES_ERROR = "GET_PAGE_CLIENT_CATEGORIES_ERROR";

export const ADD_CLIENT_CATEGORIES_REQUEST = "ADD_CLIENT_CATEGORIES_REQUEST";
export const ADD_CLIENT_CATEGORIES_SUCCESS = "ADD_CLIENT_CATEGORIES_SUCCESS";
export const ADD_CLIENT_CATEGORIES_ERROR = "ADD_CLIENT_CATEGORIES_ERROR";

export const UPDATE_CLIENT_CATEGORIES_REQUEST = "UPDATE_CLIENT_CATEGORIES_REQUEST";
export const UPDATE_CLIENT_CATEGORIES_SUCCESS = "UPDATE_CLIENT_CATEGORIES_SUCCESS";
export const UPDATE_CLIENT_CATEGORIES_ERROR = "UPDATE_CLIENT_CATEGORIES_ERROR";

export const DELETE_CLIENT_CATEGORIES_REQUEST = "DELETE_CLIENT_CATEGORIES_REQUEST";
export const DELETE_CLIENT_CATEGORIES_SUCCESS = "DELETE_CLIENT_CATEGORIES_SUCCESS";
export const DELETE_CLIENT_CATEGORIES_ERROR = "DELETE_CLIENT_CATEGORIES_ERROR";

export const CLEAR_FIELDS_CLIENT_CATEGORIES = "CLEAR_FIELDS_CLIENT_CATEGORIES";