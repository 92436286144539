import {
    CLEAR_MARKETING_NOTIFICATIONS_STORE,
    MARKETING_NOTIFICATIONS_ADD_ERROR,
    MARKETING_NOTIFICATIONS_ADD_REQUEST,
    MARKETING_NOTIFICATIONS_ADD_SUCCESS,
    MARKETING_NOTIFICATIONS_CANCEL_ERROR,
    MARKETING_NOTIFICATIONS_CANCEL_REQUEST,
    MARKETING_NOTIFICATIONS_CANCEL_SUCCESS,
    MARKETING_NOTIFICATIONS_GET_PAGE_ERROR,
    MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST,
    MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS,
    MARKETING_NOTIFICATIONS_GET_STATS_ERROR,
    MARKETING_NOTIFICATIONS_GET_STATS_REQUEST,
    MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS,
    UPDATE_MARKETING_NOTIFICATIONS_STORE,
} from "./actions";

const initial = {
    pushNotification: {},
    mailingStats: {},
    mailingStatsProgress: false,
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case MARKETING_NOTIFICATIONS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
                fetchError: false,
            };
        case MARKETING_NOTIFICATIONS_GET_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                fetchError: false,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case MARKETING_NOTIFICATIONS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                fetchError: true,
                error: payload,
            };
        case MARKETING_NOTIFICATIONS_ADD_REQUEST:
        case MARKETING_NOTIFICATIONS_CANCEL_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case MARKETING_NOTIFICATIONS_ADD_SUCCESS:
        case MARKETING_NOTIFICATIONS_ADD_ERROR:
        case MARKETING_NOTIFICATIONS_CANCEL_SUCCESS:
        case MARKETING_NOTIFICATIONS_CANCEL_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case MARKETING_NOTIFICATIONS_GET_STATS_REQUEST:
            return {
                ...state,
                mailingStatsProgress: true,
            };
        case MARKETING_NOTIFICATIONS_GET_STATS_SUCCESS:
            return {
                ...state,
                mailingStats: payload,
                mailingStatsProgress: false,
            };
        case MARKETING_NOTIFICATIONS_GET_STATS_ERROR:
            return {
                ...state,
                mailingStatsProgress: false,
            };
        case UPDATE_MARKETING_NOTIFICATIONS_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_MARKETING_NOTIFICATIONS_STORE:
            return initial;
        default:
            return state;
    }
};