import {
    all,
    put,
    select,
    takeEvery,
} from "@redux-saga/core/effects";
import axios from "axios";
import {createSelector} from "reselect";

import {call} from "../../node_modules/redux-saga/effects";
import {getUserAvatarListThumbnails} from "./fileStore";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess, toastWarning} from "../utils/toastHelper";

import {REGISTRY_TOAST_TEXT} from "../constants/registry";

const controller = "/registrypayments";
const bffController = "/adm/companies/company/finances/registry-payments";

const REGISTRY_PAYMENT_LIST_REQUEST = "REGISTRY_PAYMENT_LIST_REQUEST";
const REGISTRY_PAYMENT_LIST_SUCCESS = "REGISTRY_PAYMENT_LIST_SUCCESS";
const REGISTRY_PAYMENT_LIST_ERROR = "REGISTRY_PAYMENT_LIST_ERROR";

const REGISTRY_PAYMENT_UPDATE_STORE = "REGISTRY_PAYMENT_UPDATE_STORE";

const REGISTRY_PAYMENT_DELETE_REQUEST = "REGISTRY_PAYMENT_DELETE_REQUEST";
const REGISTRY_PAYMENT_DELETE_SUCCESS = "REGISTRY_PAYMENT_DELETE_SUCCESS";
const REGISTRY_PAYMENT_DELETE_ERROR = "REGISTRY_PAYMENT_DELETE_ERROR";

const REGISTRY_PAYMENT_ROW_PAY_REQUEST = "REGISTRY_PAYMENT_ROW_PAY_REQUEST";
const REGISTRY_PAYMENT_ROW_PAY_SUCCESS = "REGISTRY_PAYMENT_ROW_PAY_SUCCESS";
const REGISTRY_PAYMENT_ROW_PAY_ERROR = "REGISTRY_PAYMENT_ROW_PAY_ERROR";

const REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST";
const REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS";
const REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR";

const REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST";
const REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS";
const REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR";

const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST";
const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS";
const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR";

const REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST";
const REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS";
const REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR";

const CANCEL_REGISTRY_PAYMENT_REQUEST = "CANCEL_REGISTRY_PAYMENT_REQUEST";
const CANCEL_REGISTRY_PAYMENT_SUCCESS = "CANCEL_REGISTRY_PAYMENT_SUCCESS";
const CANCEL_REGISTRY_PAYMENT_ERROR = "CANCEL_REGISTRY_PAYMENT_ERROR";

const REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST = "REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST";
const REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS = "REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS";
const REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR = "REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR";

const REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST";
const REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS";
const REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR";

const GET_REGISTRY_PAYMENT_LOG_REQUEST = "GET_REGISTRY_PAYMENT_LOG_REQUEST";
const GET_REGISTRY_PAYMENT_LOG_SUCCESS = "GET_REGISTRY_PAYMENT_LOG_SUCCESS";
const GET_REGISTRY_PAYMENT_LOG_ERROR = "GET_REGISTRY_PAYMENT_LOG_ERROR";

const REGISTRY_EMAIL_INVITATION_SEND_REQUEST = "REGISTRY_EMAIL_INVITATION_SEND_REQUEST";
const REGISTRY_EMAIL_INVITATION_SEND_SUCCESS = "REGISTRY_EMAIL_INVITATION_SEND_SUCCESS";
const REGISTRY_EMAIL_INVITATION_SEND_ERROR = "REGISTRY_EMAIL_INVITATION_SEND_ERROR";

const CLEAR_REGISTRY_PAYMENT_LOG = "CLEAR_REGISTRY_PAYMENT_LOG";

const initial = {
    registryPayments: [],
    progressRegistryPayments: false,
    totalCount: 0,
    successSaveContractor: false,
    progressSaveContractor: false,
    progressImportContractors: false,
    importFileSuccess: false,
    progressActionSuccess: false,
    errorMessage: "",
    progressValidation: false,
    progressChangeCategory: false,
    violations: [],
    refreshPerformerActionProcess: false,
    progressDownload: false,
    progressData: {},
    actionProgress: false,
    nmContractDateConclusion: null,
    registryPaymentsHasInProgress: false,
    registryPaymentLog: [],
    registryPaymentLogPageData: {
        pageSize: 25,
        pageNum: 1,
    },
    registryPaymentLogTotalCount: 0,
    invitationProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CANCEL_REGISTRY_PAYMENT_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case CLEAR_REGISTRY_PAYMENT_LOG:
            return {
                ...state,
                registryPaymentLog: [],
                registryPaymentLogPageData: {
                    pageSize: 25,
                    pageNum: 1,
                },
                registryPaymentLogTotalCount: 0,
            };
        case REGISTRY_PAYMENT_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case GET_REGISTRY_PAYMENT_LOG_REQUEST: {
            return {
                ...state,
                registryPaymentLogPageData: {
                    pageSize: payload.pageSize,
                    pageNum: payload.pageNum,
                },
            };
        }
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST:
            return {
                ...state,
                progressSaveContractor: true,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST:
            return {
                ...state,
                progressImportContractors: true,
            };
        case REGISTRY_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                ...payload,
                progressRegistryPayments: true,
            };
        case CANCEL_REGISTRY_PAYMENT_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_REGISTRY_PAYMENT_LOG_SUCCESS:
            return {
                ...state,
                registryPaymentLog: payload.registryPaymentsLogEvents,
                registryPaymentLogTotalCount: payload.totalCount,
            };
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS:
            return {
                ...state,
                progressSaveContractor: false,
                successSaveContractor: true,
            };
        case REGISTRY_PAYMENT_LIST_SUCCESS:
            const {registryPayments, totalCount, nmContractDateConclusion} = payload;

            return {
                ...state,
                registryPayments,
                nmContractDateConclusion,
                totalCount,
                progressRegistryPayments: false,
            };
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR:
            return {
                ...state,
                progressSaveContractor: false,
                successSaveContractor: false,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS:
            return {
                ...state,
                importFileSuccess: true,
                progressImportContractors: false,
                registryPaymentsHasInProgress: true,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR:
            return {
                ...state,
                errorMessage: payload,
                progressImportContractors: false,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST:
            return {
                ...state,
                refreshPerformerActionProcess: true,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS:
            return {
                ...state,
                refreshPerformerActionProcess: false,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR:
            return {
                ...state,
                refreshPerformerActionProcess: false,
            };
        case CANCEL_REGISTRY_PAYMENT_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        case REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS:
            return {
                ...state,
                registryPaymentsHasInProgress: payload,
            };
        case REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR:
            return {
                ...state,
                registryPaymentsHasInProgress: false,
            };
        case REGISTRY_EMAIL_INVITATION_SEND_REQUEST:
            return {
                ...state,
                invitationProgress: true,
            };
        case REGISTRY_EMAIL_INVITATION_SEND_SUCCESS:
            return {
                ...state,
                invitationProgress: false,
            };
        case REGISTRY_EMAIL_INVITATION_SEND_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

export function getRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_LIST_REQUEST,
        payload,
    };
}

export function cancelRegistryPayment(payload) {
    return {
        type: CANCEL_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
}

export function clearRegistryPaymentLog() {
    return {
        type: CLEAR_REGISTRY_PAYMENT_LOG,
    };
}

export function updateRegistryPaymentsStore(payload) {
    return {
        type: REGISTRY_PAYMENT_UPDATE_STORE,
        payload,
    };
}

export function deleteOrCancelRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_DELETE_REQUEST,
        payload,
    };
}

export function registryPaymentPay(payload) {
    return {
        type: REGISTRY_PAYMENT_ROW_PAY_REQUEST,
        payload,
    };
}

export function addContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST,
        payload,
    };
}


export function importFromFileRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST,
        payload,
    };
}


export function updateContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST,
        payload,
    };
}

export function editContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST,
        payload,
    };
}

export function registryPaymentRefreshPerformer(payload) {
    return {
        type: REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST,
        payload,
    };
}

export function registryPaymentHasInProgress(payload) {
    return {
        type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST,
        payload,
    };
}

export function getRegistryPaymentLog(payload) {
    return {
        type: GET_REGISTRY_PAYMENT_LOG_REQUEST,
        payload,
    };
}

export const registryInviteViaEmail = payload => {
    return {
        type: REGISTRY_EMAIL_INVITATION_SEND_REQUEST,
        payload,
    };
};

const registryPaymentsSelector = state => state.registryPayments;
export const registryPaymentsPageDataSelector = createSelector(registryPaymentsSelector, ({pageNum, pageSize, dataCheckFilter}) => {
    return {
        pageNum,
        pageSize,
        dataCheckFilter,
    };
});
export const registryPaymentsListSelector = createSelector(registryPaymentsSelector, ({registryPayments}) => {
    // isEditPossible
    return registryPayments;
});
export const registryPaymentsTotalCountSelector = createSelector(registryPaymentsSelector, ({totalCount}) => totalCount);
export const registryPaymentsContractDateConclusionSelector = createSelector(registryPaymentsSelector, ({nmContractDateConclusion}) => nmContractDateConclusion);
export const registryPaymentsTotalPagesSelector = createSelector(registryPaymentsSelector, ({totalCount, pageSize = 0}) => getTotalPages(totalCount, pageSize));
export const registryPaymentsProgressSelector = createSelector(registryPaymentsSelector, ({progressRegistryPayments}) => progressRegistryPayments);
export const registryProgressDataSelector = createSelector(registryPaymentsSelector, ({successSaveContractor, progressSaveContractor}) => ({
    successSaveContractor,
    progressSaveContractor,
}));
export const registryViolationsSelector = createSelector(registryPaymentsSelector, ({violations}) => (violations));
export const registryProgressImportFileSelector = createSelector(registryPaymentsSelector, ({progressImportContractors}) => (progressImportContractors));
export const registryPaymentsProgressValidation = createSelector(registryPaymentsSelector, ({progressValidation}) => (progressValidation));
export const refreshPerformerActionProcessSelector = createSelector(registryPaymentsSelector, ({refreshPerformerActionProcess}) => (refreshPerformerActionProcess));
export const registryPaymentsHasInProgressSelector = createSelector(registryPaymentsSelector, ({registryPaymentsHasInProgress}) => registryPaymentsHasInProgress);
export const registryPaymentLogTotalCountSelector = createSelector(registryPaymentsSelector, ({registryPaymentLogTotalCount}) => registryPaymentLogTotalCount);
export const registryPaymentLogTotalPagesSelector = createSelector(registryPaymentsSelector, ({registryPaymentLogTotalCount, registryPaymentLogPageData: {pageSize = 0}}) => getTotalPages(registryPaymentLogTotalCount, pageSize));
export const registryPaymentLogListSelector = createSelector(registryPaymentsSelector, ({registryPaymentLog}) => registryPaymentLog);
export const registryEmailInvitationProgressSelector = createSelector(registryPaymentsSelector, ({invitationProgress}) => invitationProgress);

export const getRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {registryPayments} = result;

        if (registryPayments?.length) {
            const contractorIds = registryPayments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: REGISTRY_PAYMENT_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_LIST_ERROR, payload: error});
    }
};

export const importFromFileRegistryPaymentsSaga = function* (action) {
    const {payload} = action;

    const {
        clientId,
        registryId,
        definingRegistryParameterType,
        documentTablesEnabled,
        file: data,
        onSuccess = () => {
        },
        onError = () => {
        },
    } = payload;

    try {

        const result = yield call(axios, {
            url: `/api/job/registryPayments/import/${documentTablesEnabled ? "withTable/" : ""}${clientId}/${registryId}/${definingRegistryParameterType}`,
            method: "post",

            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        },
        );

        const {data: {errorMessage}} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR,
                payload: errorMessage,
            });
            onError();
            return {
                done: true,
            };
        }

        onSuccess();

        const state = yield select();
        yield put(getRegistryPayments({clientId, registryId, ...registryPaymentsPageDataSelector(state)}));

        yield put({type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS, payload: result});
    } catch (error) {
        toastError("Не удалось загрузить список исполнителей");
        onError();
        yield put({type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR, payload: error});
    }
};
///POST api/registrypayments/add

export const addContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            yield put({type: REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const state = yield select();
        const {clientId, registryId} = payload;
        yield put(getRegistryPayments({clientId, registryId, ...registryPaymentsPageDataSelector(state)}));

        toastSuccess("Исполнитель успешно добавлен в реестр");

        yield put({type: REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR, payload: error});
    }
};

///POST api/registrypayments/update

export const updateContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            yield put({type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const state = yield select();
        yield put(getRegistryPayments({
            clientId: payload.clientId,
            registryId: payload.registryId,
            ...registryPaymentsPageDataSelector(state),
        }));

        toastSuccess("Параметры исполнителя успешно изменены");

        yield put({type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR, payload: error});
    }
};

//PUT /api/registrypayments/edit
export const editContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
        } = payload;

        const result = yield request.put(`${controller}/edit`, payload);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            yield put({type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const state = yield select();
        yield put(getRegistryPayments({
            clientId: payload.clientId,
            registryId: payload.registryId,
            ...registryPaymentsPageDataSelector(state),
        }));

        toastSuccess("Параметры исполнителя успешно изменены");

        yield put({type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR, payload: error});
    }
};

export const deleteOrCancelRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/deleteOrCancelAll`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: REGISTRY_PAYMENT_DELETE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: REGISTRY_PAYMENT_DELETE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_DELETE_ERROR, payload: error});
    }
};

export const cancelRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            registryId,
            registrySeqNum,
        } = data;

        const result = yield request.post(`${controller}/${clientId}/${registryId}/${registrySeqNum}/cancel`, data);

        const {errorMessage, warningMessage, errorCode} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CANCEL_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.CANCEL_SUCCESS);

        onSuccess && onSuccess();

        const state = yield select();
        yield put(getRegistryPayments({clientId, registryId, ...registryPaymentsPageDataSelector(state)}));

        yield put({type: CANCEL_REGISTRY_PAYMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CANCEL_REGISTRY_PAYMENT_ERROR, payload: error});
    }
};

export const registryPaymentPaySaga = function* ({payload}) {
    try {
        const {data, handleError} = payload;
        const {clientId, registryId, registrySeqNum} = data;
        const result = yield request.post(`${controller}/${clientId}/${registryId}/${registrySeqNum}/pay`, data);
        const {errorMessage, warningMessage, errorCode} = result;

        if (warningMessage && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ROW_PAY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ROW_PAY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.PAY_SUCCESS);

        const state = yield select();
        yield put(getRegistryPayments({clientId, registryId, ...registryPaymentsPageDataSelector(state)}));

        yield put({type: REGISTRY_PAYMENT_ROW_PAY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_ROW_PAY_ERROR, payload: error});
    }
};

//POST/api/registrypayments/{clientId}/{registryId}/refreshContractors
export const registryPaymentRefreshPerformerSaga = function* ({payload}) {
    try {
        const {clientId, registryId} = payload;
        const result = yield request.post(`${controller}/${clientId}/${registryId}/refreshContractors`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const state = yield select();
        yield put(getRegistryPayments({clientId, registryId, ...registryPaymentsPageDataSelector(state)}));

        yield put({type: REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR, payload: error});
    }
};

//GET api/job/import/registryPayments/hasInProgress
export const registryPaymentHasInProgressSaga = function* (action) {
    try {
        const {payload: params} = action;

        const result = yield request.get("/job/import/registryPayments/hasInProgress", {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR, payload: error});
    }
};

//POST registrypayments/log/getPage
const getRegistryPaymentLogSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/log/getPage`, data);

        getResult(result);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: GET_REGISTRY_PAYMENT_LOG_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_REGISTRY_PAYMENT_LOG_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: GET_REGISTRY_PAYMENT_LOG_ERROR,
        });
    }
};

const registryInviteViaEmailSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const {errorMessage} = yield request.bff.post(`${bffController}/registry/sendInvitationMail`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_EMAIL_INVITATION_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(REGISTRY_TOAST_TEXT.SUCCESS_INVITE_VIA_EMAIL);
        yield put({type: REGISTRY_EMAIL_INVITATION_SEND_SUCCESS});
    } catch (error) {
        yield put({type: REGISTRY_EMAIL_INVITATION_SEND_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_LIST_REQUEST, getRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_DELETE_REQUEST, deleteOrCancelRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_ROW_PAY_REQUEST, registryPaymentPaySaga),
        takeEvery(CANCEL_REGISTRY_PAYMENT_REQUEST, cancelRegistryPaymentSaga),
        takeEvery(REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST, addContractorToRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST, updateContractorToRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST, importFromFileRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST, registryPaymentRefreshPerformerSaga),
        takeEvery(REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST, registryPaymentHasInProgressSaga),
        takeEvery(GET_REGISTRY_PAYMENT_LOG_REQUEST, getRegistryPaymentLogSaga),
        takeEvery(REGISTRY_EMAIL_INVITATION_SEND_REQUEST, registryInviteViaEmailSaga),
        takeEvery(REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST, editContractorToRegistryPaymentsSaga),
    ]);
}