import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../../../../components/ProjectsObjectsCheckboxList/constants";

export const bffClientsSettingsSelector = state => state.bff.clients.settings;
export const bffClientsSettingsErrorSelector = createSelector(bffClientsSettingsSelector, ({error}) => error);
export const bffClientsSettingsPaymentsProgressActionSelector = createSelector(bffClientsSettingsSelector, ({progressAction}) => progressAction);
export const bffClientsSettingsPaymentsProgressSelector = createSelector(bffClientsSettingsSelector, ({progress}) => progress);
export const bffClientsSettingsPaymentsSelector = createSelector(bffClientsSettingsSelector, ({payments}) => payments);
export const bffClientsSettingsPaymentsObjectForPaidApisOptionsSelector = createSelector(bffClientsSettingsSelector, ({payments}) => {
    return payments?.objects?.map(item => {
        return {
            key: item.objectId,
            text: item.name,
            value: item.objectId,
        };
    });
});
export const bffClientsSettingsOtherSelector = createSelector(bffClientsSettingsSelector, ({other}) => other);
export const bffClientsSettingsApiSelector = createSelector(bffClientsSettingsSelector, ({api}) => api);
export const bffClientsSettingsContractorsSelector = createSelector(bffClientsSettingsSelector, ({contractors}) => contractors);
export const bffClientsSettingsAdditionalModulesFormSelector = createSelector(bffClientsSettingsSelector, ({additionalModules}) => {
    if (isEmpty(additionalModules)) {
        return {};
    }

    const {
        retailClientUsers,
        ...values
    } = additionalModules;

    return {
        ...values,
        monitoringRisksFnsTariff: additionalModules.monitoringRisksFnsTariff || "",
        edmTariff: additionalModules.edmTariff || "",
        clientWorkType: additionalModules.clientWorkType || "",
        retailClientUsersIds: retailClientUsers ? retailClientUsers.filter(item => item.enabled).map(item => item.clientUserId) : [],
        migrantLicensePaymentCommission: additionalModules.migrantLicensePaymentCommission || "",
    };
},
);
export const bffClientsSettingsDocumentsSelector = createSelector(bffClientsSettingsSelector, ({documents}) => documents);

const getClientUsersIds = (list = []) => {
    return list.filter(item => item.enabled).map(item => item.clientUserId);
};
const getClientUsersOptions = (list) => {
    return list?.map(item => {
        return {
            key: item.clientUserId,
            text: item.fio,
            value: item.clientUserId,
        };
    });
};

export const bffClientsSettingsOtherAvailableClientManagersIdsSelector = createSelector(bffClientsSettingsSelector, ({other}) => {
    return getClientUsersIds(other?.availableClientManagers);
});
export const bffClientsSettingsOtherAvailableClientManagersOptionsSelector = createSelector(bffClientsSettingsSelector, ({other}) => {
    return getClientUsersOptions(other?.availableClientManagers);
});
export const bffClientsSettingsAdditionalModulesRetailClientUsersOptionsSelector = createSelector(bffClientsSettingsSelector, ({additionalModules}) => {
    return getClientUsersOptions(additionalModules?.retailClientUsers);
});
export const bffClientsSettingsHidingOrdersSelector = createSelector(bffClientsSettingsSelector, ({hidingOrders}) => {
    return hidingOrders;
});

const hidingOrdersProjectsSelector = (state) => state.bff.clients.settings.hidingOrders.projects;
export const bffClientsSettingsHidingOrdersProjectsSelector = createSelector(hidingOrdersProjectsSelector, (projects) => {
    return {
        ...projects,
        list: projects.list.map(item => {
            return {
                ...item,
                boolParam: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                isPartial: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
            };
        }),
        totalPages: getTotalPages(projects.totalCount, projects.pageData.pageSize),
    };
});

const hidingOrdersObjectsSelector = (state) => state.bff.clients.settings.hidingOrders.objects;
export const bffClientsSettingsHidingOrdersObjectsSelector = createSelector(hidingOrdersObjectsSelector, (objects) => {
    return objects;
});

const hidingOrdersSelector = (state) => state.bff.clients.settings.hidingOrders;
export const bffClientsSettingsHidingOrdersProjectsTreeNodesSelector = createSelector(hidingOrdersSelector, (hidingOrders) => {
    return hidingOrders;

    // const {
    //     projects,
    //     objects,
    // } = hidingOrders;
    //
    // return [
    //     {
    //         label: projects.clientInfo.clientName,
    //         value: projects.clientInfo.clientId,
    //         type: TreeCheckboxProjectsObjects.CLIENT,
    //         checked: projects.clientInfo.clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
    //         partiallyChecked: projects.clientInfo.clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
    //         children: projects.list.map(item => {
    //             return {
    //                 label: item.projectName,
    //                 checked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
    //                 type: TreeCheckboxProjectsObjects.PROJECT,
    //                 partiallyChecked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
    //                 value: item.projectId,
    //                 children: objects.lists[item.projectId]?.list ?
    //                     objects.lists[item.projectId]?.list.map(item => {
    //                         return {
    //                             label: item.objectName,
    //                             checked: item.boolParam,
    //                             value: item.objectId,
    //                             type: TreeCheckboxProjectsObjects.OBJECT,
    //                         };
    //                     }) :
    //                     [],
    //             }
    //         })
    //     }
    // ];

    // return [
    //     {
    //         label: projects.clientInfo.clientName,
    //         value: projects.clientInfo.clientId,
    //         children: projects.list.map(item => {
    //             return {
    //                 label: item.projectName,
    //                 value: item.projectId,
    //                 children: objects.lists[item.projectId]?.list ?
    //                     objects.lists[item.projectId]?.list.map(item => {
    //                         return {
    //                             label: item.objectName,
    //                             value: item.objectId,
    //                         };
    //                     }) :
    //                     [],
    //             }
    //         })
    //     }
    // ];
});

export const hidingOrdersProjectsAndObjectsSelector = (state) => state.bff.clients.settings.hidingOrders.projectsAndObjects;

export const bffClientsSettingsHidingOrdersProjectsAndObjectsSelector = createSelector(hidingOrdersProjectsAndObjectsSelector, (projectsAndObjects) => projectsAndObjects);
// список для дерева при поиске
export const bffClientsSettingsHidingOrdersProjectsAndObjectsTreeNodesSelector = createSelector(hidingOrdersProjectsAndObjectsSelector, (projectsAndObjects) => {
    const {
        clientId,
        clientName,
        projects,
    } = projectsAndObjects;

    if (!projects) {
        return [];
    }

    return [
        {
            label: clientName,
            value: clientId,
            children: projects.map(item => {
                return {
                    label: item.projectName,
                    value: item.projectId,
                    children: item.objects ?
                        item.objects.map(item => {
                            return {
                                label: item.objectName,
                                value: item.objectId,
                            };
                        }) :
                        [],
                };
            }),
        },
    ];
});

// список выбранных для дерева при поиске
export const bffClientsSettingsHidingOrdersProjectsAndObjectsSelectedIdsSelector = createSelector(hidingOrdersProjectsAndObjectsSelector, (projectsAndObjects) => {
    const {
        projects,
    } = projectsAndObjects;

    if (!projects) {
        return [];
    }

    return projects.reduce((result, item) => {
        if (item.objects) {
            const objectIds = item.objects.filter(item => item.boolParam).map(item => item.objectId);

            return [
                ...result,
                ...objectIds,
                item.projectId,
            ];
        }

        return [
            ...result,
            item.projectId,
        ];
    }, []);
});

// список развернутых списков для дерева при поиске
export const bffClientsSettingsHidingOrdersProjectsAndObjectsExpandedIdsSelector = createSelector(hidingOrdersProjectsAndObjectsSelector, (projectsAndObjects) => {
    const {
        projects,
        clientSelectorBoolType,
    } = projectsAndObjects;

    if (!projects) {
        return [];
    }

    const clientId = clientSelectorBoolType !== CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY ?
        projectsAndObjects.clientId :
        "";

    const projectIds = projects.reduce((result, item) => {
        if (item.selectorBoolType !== CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY) {
            return [
                ...result,
                item.projectId,
            ];
        }

        return result;
    }, []);

    return [clientId, ...projectIds].filter(Boolean);
});
export const bffClientsSettingsLogListSelector = createSelector(bffClientsSettingsSelector, ({logList}) => logList);
export const bffClientsSettingsLogListTotalCountSelector = createSelector(bffClientsSettingsSelector, ({logListTotalCount}) => logListTotalCount);
export const bffClientsSettingsLogListTotalPagesSelector = createSelector(bffClientsSettingsSelector, ({logListTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logListTotalCount, pageSize));
export const bffClientsSettingsLogListProgressSelector = createSelector(bffClientsSettingsSelector, ({logListProgress}) => logListProgress);