import React, {useMemo} from "react";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import StepList from "../../../../../../components/ActualComponents/StepList";
import Text from "../../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../../components/CheckboxList";
import RecruitmentCommentFiles from "../../../../../../components/CommentFiles";
import ExtLink from "../../../../../../components/ExtLink";
import {RecruitmentCandidateCommentsEdit} from "../../../../../../components/Recruitment/CandidateCommentsEdit";
import {ReactComponent as UserIcon} from "../../../../../../images/user_24.svg";

import {useModal} from "../../../../../../hooks/useModal";
import useUploadCommentFiles from "../../../comments/hooks/useUploadCommentFiles";
import useRecruitmentCandidatesFeedOperationListAction from "./hooks/useAction";

import {formatLocalDate} from "../../../../../../utils/dateFormat";
import {getFeedCandidateSourceLabelText} from "./utils/getCandidateSource";

import {COLOR} from "../../../../../../constants/color";
import {LINK_ORDER_CARD} from "../../../../../../constants/links";
import {
    RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE,
    RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE_TRANSLATE,
} from "../../constants";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../../../constants/link-params";

export const RecruitmentCandidatesFeedOperationList = (props) => {
    const {
        clientId,
        vacancyId,
        candidateId,
        list = [],
        fetchList = () => {},
    } = props;

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        modalData: editData,
        onOpenModal: onOpenEditModal,
        onCloseModal: onCloseEditModal,
    } = useModal();

    const {
        uploadFiles,
    } = useUploadCommentFiles({
        clientId,
        fetchList,
    });

    const {
        getMediaControls,
        isMediaControlsVisible,
    } = useRecruitmentCandidatesFeedOperationListAction({
        vacancyId,
        candidateId,
        onOpenConfirm,
        onCloseConfirm,
        onOpenEditModal,
        onCloseEditModal,
        fetchList,
        uploadFiles,
    });

    const getCardSecondaryHeader = ({initiatorName, createDate}) => {
        return (
            <div className="flex flex-column-reverse flex-wrap flex-xl-row align-items-sm-start align-items-xxl-center">
                <div className="flex align-items-center me-4 mt-2 mt-xl-0">
                    <UserIcon
                        color={COLOR.SECONDARY_45}
                        width={18}
                        height={18}
                    />
                    <Text
                        className="ms-2"
                        color={COLOR.SECONDARY_70}
                    >
                        {initiatorName}
                    </Text>
                </div>
                <Text
                    color={COLOR.SECONDARY_70}
                >
                    {formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}
                </Text>
            </div>
        );
    };

    const getCardPrimaryHeaderText = (item) => {
        const {
            operation,
            oldStatus,
            newStatus,
            clientId,
            orderId,
            orderNum,
            orderName,
            comment,
        } = item;

        if (operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.CHANGED_STATUS) {
            const statusList = [
                oldStatus?.name || "Новый кандидат",
                newStatus?.name || "-",
            ];

            return (
                <StepList
                    list={statusList}
                />
            );
        }

        if (operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.INVITED_FOR_ORDER) {
            const orderLink = LINK_ORDER_CARD
                .replace(":clientId", clientId)
                .replace(":orderId", orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
            const orderText = `№${orderNum} - ${orderName}`;

            return (
                <ExtLink
                    to={orderLink}
                    historyEnabled
                >
                    {orderText}
                </ExtLink>
            );
        }

        if (operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.NEW_COMMENT) {
            return comment?.comment || "-";
        }
    };

    const getLabels = (item) => {
        const {
            operation,
            rejectionReasonId,
            rejectionReason,
            rejectionComment,
        } = item;

        const labels = [];

        if (operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.CANDIDATE_CREATED) {
            const value = getFeedCandidateSourceLabelText(item);

            labels.push(value);
        }

        if (
            operation === RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE.CHANGED_STATUS
            && (
                rejectionReason
                || rejectionComment
            )
        ) {
            if (rejectionReasonId && rejectionReason) {
                labels.push({
                    label: "Причина отказа",
                    text: rejectionReason,
                });
            }

            if (rejectionComment) {
                labels.push({
                    label: "Комментарий",
                    noWrap: false,
                    text: rejectionComment,
                });
            }
        }

        if (isEmpty(labels)) {
            return;
        }

        return labels;
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                operation,
                comment = {},
            } = item;

            const isAddMediaControlsPadding = isMediaControlsVisible(item);

            return {
                ...item,
                contentRow: <NmListCard
                    noDivider
                    secondaryHeader={getCardSecondaryHeader(item)}
                    classNameMainContent={isAddMediaControlsPadding && "col-xl-14"}
                    primaryHeader={
                        <NmLabelText
                            type="page"
                            boldLabel={true}
                            label={RECRUITMENT_CONTRACTOR_FEED_OPERATION_TYPE_TRANSLATE[operation] || operation}
                            text={getCardPrimaryHeaderText(item)}
                            noWrap={false}
                            columnOnTablet={true}
                            columnOnMobile={true}
                        />
                    }
                    labels={getLabels(item)}
                    classNamePrimaryHeader={isAddMediaControlsPadding && "me-5"}
                    primarySubHeader={
                        comment?.id && !!comment.fileNames?.length &&
                        <RecruitmentCommentFiles
                            clientId={clientId}
                            commentId={comment.id}
                            createdBy ={comment.createdBy}
                            fileNames={comment.fileNames}
                        />
                    }
                    isFixedActions={true}
                    mediaControls={getMediaControls(item)}
                />,
            };
        });
    }, [list]);

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const renderEditModal = () => {
        return (
            isOpenEdit &&
            <RecruitmentCandidateCommentsEdit
                candidateId={candidateId}
                vacancyId={vacancyId}
                editData={editData}
                fetchList={fetchList}
                onClose={onCloseEditModal}
            />
        );
    };

    return (
        <>
            {renderEditModal()}
            {renderConfirmModal()}
            {
                list.length ?
                    <CheckboxList
                        rows={rows}
                    /> :
                    <NmEmptyPageV2 />
            }
        </>
    );
};