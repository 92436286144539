import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../components/ActualComponents/Filter";
import {IconButton} from "../../../components/ActualComponents/IconButton";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as LinkCopyIcon} from "../../../images/link-copy_24.svg";
import {SmartLinkEdit} from "../smart-link-edit";
import {SmartLinkAnalytics} from "./components/analytics";
import {SmartLinkDetails} from "./components/details";

import {useFilter} from "../../../hooks/useFilter";
import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {
    formatLocalDate,
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {handleFormString} from "../../../utils/stringHelper";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {SMART_LINK_FILTER_BODY_TYPE} from "../smart-link-edit/constants";

import {getClientsCategories} from "../../../ducks/bff/common/dicts/actionCreators";
import {archiveSmartLink, getSmartLinkPage, updateSmartLinkStore} from "../../../ducks/smart-link/actionCreators";
import {getSmartLinkInitialState} from "../../../ducks/smart-link/reducer";
import {
    smartLinkListSelector,
    smartLinkProgressSelector,
    smartLinkTotalCountSelector,
    smartLinkTotalPagesSelector,
} from "../../../ducks/smart-link/selectors";

export const LIST_TYPE = {
    ORDER: "Заказы",
    TASK: "Задания",
};

const SmartLink = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [archivedFilter, setArchivedFilter] = useState(false);

    const list = useSelector(smartLinkListSelector);
    const totalCount = useSelector(smartLinkTotalCountSelector);
    const totalPages = useSelector(smartLinkTotalPagesSelector);
    const progress = useSelector(smartLinkProgressSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        mapFilterDto,
        pageSize,
        setPagination,
    });

    function mapFilterDto(filters) {
        return {
            name: handleFormString(filters.name),
            fromDateTimeFilter: getStartFilterDateWithTimeInUtc(filters.fromDateTimeCreate),
            toDateTimeFilter: getEndFilterDateWithTimeInUtc(filters.toDateTimeCreate),
            type: filters.type,
        };
    }

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const fetchList = () => {
        dispatch(getSmartLinkPage({
            pageNum,
            pageSize,
            archived: archivedFilter,
            ...filterData,
        }));
    };

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        archivedFilter,
    ]);

    useEffect(() => {
        dispatch(getClientsCategories());

        return () => {
            const initialState = getSmartLinkInitialState();

            dispatch(updateSmartLinkStore(initialState));
        };
    }, []);

    const headerControls = useMemo(() => {
        const archiveButton = getArchiveButton(t, archivedFilter, {mobile: true});

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
                    props: {
                        buttonProps: {
                            size: "xl",
                            color: "green",
                            children: "Создать ссылку",
                            isIconPositionEnd: true,
                            isContentFullWidth: true,
                        },
                        children: "Создать ссылку",
                        options: [
                            {
                                key: "numbers",
                                value: "numbers",
                                text: "По номерам",
                                onClick: () => {
                                    onOpenModal({
                                        isOpenEdit: true,
                                        filterType: SMART_LINK_FILTER_BODY_TYPE.NUMBERS,
                                    });
                                },
                            },
                            {
                                key: "filter",
                                value: "filter",
                                text: "По фильтрам",
                                onClick: () => {
                                    onOpenModal({
                                        isOpenEdit: true,
                                        filterType: SMART_LINK_FILTER_BODY_TYPE.FILTERS,
                                    });
                                },
                            },
                        ],
                    },
                    visible: !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archiveButton.props,
                        onClick: () => {
                            setArchivedFilter(!archivedFilter);
                        },
                        disabled: progress,
                    },
                },
            ],
        };
    }, [
        archivedFilter,
        progress,
    ]);

    const getConfirm = () => {
        if (!modalData?.isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                onCancel={onCloseModal}
                onConfirm={modalData.onConfirm}
                content={modalData.content}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getDetails = () => {
        if (!modalData?.isOpenDetails) {
            return null;
        }

        return (
            <SmartLinkDetails
                smartLinkId={modalData.smartLinkId}
                type={modalData.type}
                onClose={onCloseModal}
            />
        );
    };

    const getAnalytics = () => {
        if (!modalData?.isOpenAnalytics) {
            return null;
        }

        return (
            <SmartLinkAnalytics
                {...modalData}
                onCloseModal={onCloseModal}
            />
        );
    };

    const getEdit = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <SmartLinkEdit
                onClose={onCloseModal}
                fetchList={fetchList}
                smartLinkId={modalData.smartLinkId}
                isEdit={modalData.isEdit}
                isDuplicate={modalData.isDuplicate}
                filterType={modalData.filterType}
            />
        );
    };

    const getListMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Создать дубликат",
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                isDuplicate: true,
                                filterType: item.filterType,
                                smartLinkId: item.smartLinkId,
                            });
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Посмотреть детали",
                        onClick: () => {
                            onOpenModal({
                                isOpenDetails: true,
                                smartLinkId: item.smartLinkId,
                                type: item.type,
                            });
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Редактировать",
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                isEdit: true,
                                filterType: item.filterType,
                                smartLinkId: item.smartLinkId,
                            });
                        },
                    },
                    visible: !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: item.archived ? "Из архива" : "В архив",
                        onClick: () => {
                            onOpenModal({
                                isOpenConfirm: true,
                                content: item.archived
                                    ? `Вы действительно хотите восстановить ссылку "${item.name}" из архива?`
                                    : `Вы действительно хотите переместить ссылку "${item.name}" в архив?`,
                                onConfirm: () => {
                                    dispatch(archiveSmartLink({
                                        smartLinkId: item.smartLinkId,
                                        archived: item.archived,
                                        onSuccess: () => {
                                            onCloseModal();
                                            fetchList();
                                        },
                                    }));
                                },
                            });
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Посмотреть аналитику",
                        onClick: () => {
                            onOpenModal({
                                isOpenAnalytics: true,
                                smartLinkId: item.smartLinkId,
                                smartLinkName: item.name,
                            });
                        },
                    },
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            const link = `${process.env.REACT_APP_NAIMIX_OPEN_SITE_LINK}/sl/${item.smartLinkId}`;

            return {
                key: item.smartLinkId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-9"
                        primaryHeader={item.name}
                        secondaryHeader={`Дата создания  ${formatLocalDate(item.dateTimeCreate, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {
                                label: "Тип списка",
                                text: LIST_TYPE[item.type],
                            },
                            {
                                label: "Создатель",
                                text: item.creatorName,
                            },
                        ]}
                        cards={[
                            {
                                title: "Переходы по ссылке",
                                value: item.linkUsingCounter,
                                className: "col-16 col-md-3 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Ссылка",
                                value: link,
                                valueColor: COLOR.PASSIVE_100,
                                overflowEllipseText: true,
                                icon: !archivedFilter && (
                                    <IconButton
                                        icon={LinkCopyIcon}
                                        title="Скопировать ссылку"
                                        width={16}
                                        height={16}
                                        color={COLOR.SECONDARY_45}
                                        onClick={() => {
                                            copy(link);
                                            toastSuccess("Ссылка скопирована");
                                        }}
                                    />
                                ),
                                className: "col-16 col-md-3 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        actionsClassName="col-1"
                        mediaControls={getListMediaControls(item)}
                    />
                ),
            };
        });
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "name",
                        placeholder: "Введите название",
                        label: "Название",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "type",
                        label: "Тип списка",
                        options: dictionaryToOptions(LIST_TYPE),
                        placeholder: "Не выбрано",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "fromDateTimeCreate",
                        endFieldName: "toDateTimeCreate",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
        ];
    }, []);

    return (
        <NmPage
            noPadding
            header={
                <NmTitle size="xl">
                    Конструктор ссылок
                </NmTitle>
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            mediaControls={headerControls}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            isLoaded={progress}
        >
            {getConfirm()}
            {getDetails()}
            {getAnalytics()}
            {getEdit()}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};

export default SmartLink;