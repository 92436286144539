import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../../images/add.svg";

import useConfirm, {
    closeConfirmAction,
    onConfirmAction,
    openConfirmAction,
} from "../../../../../hooks/useConfirm";

import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {handleTarrifGridEditTo} from "../utils/dto";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {TARIFF_GRID_ERROR_CODE} from "../constants";

import {
    addTariffScale,
    checkExistByUpdateTariffScale,
    checkExistTariffScale,
    deleteTariffScale,
    downloadTariffScale,
    updateTariffScale,
} from "../../../../../ducks/bff/settings/directories/naimix/tariff-grid/actionCreators";
import {tariffScaleProgressSelector} from "../../../../../ducks/bff/settings/directories/naimix/tariff-grid/selectors";

export const useTariffGridAction = (props) => {
    const {
        fetchList,
        isAccessEdit,
    } = props;

    const [isEdit, setEdit] = useState(false);
    const [isImport, setImportOpen] = useState(false);
    const [editData, setEditData] = useState(undefined);
    const [editProgress, setEditProgress] = useState(false);
    const [isOpenCityDetect, setOpenCityDetect] = useState(false);

    const dispatch = useDispatch();

    const progress = useSelector(tariffScaleProgressSelector);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const onOpenEdit = (editData) => {
        if (editData) {
            const {tariffScaleId} = editData;

            setEditData({tariffScaleId});
        }

        setEdit(true);
    };

    const onAddTariffScale = ({values, onClose, replaceExist = false}) => {
        const onSuccess = () => {
            const message = editData ?
                "Тариф был успешно отредактирован" :
                "Тариф был успешно добавлен";

            toastSuccess(message);

            setEditProgress(false);
            onCloseEdit();
            onClose();
            fetchList();
        };

        const onError = ({errorCode, errorMessage}) => {
            setEditProgress(false);

            if (errorCode === TARIFF_GRID_ERROR_CODE.TARIFF_DUPLICATE_EXIST) {
                onOpenEditDuplicate(values);

                return;
            }

            toastError(errorMessage);

            fetchList();
        };

        if (editData) {
            const {tariffScaleId} = editData;

            dispatch(updateTariffScale({
                ...values,
                tariffScaleId,
                replaceExist,
                onSuccess,
                onError,
            }));

            return;
        }

        dispatch(addTariffScale({
            ...values,
            replaceExist,
            onSuccess,
            onError,
        }));
    };

    const onSubmitEdit = (values) => {
        const formValues = handleTarrifGridEditTo(values);

        setEditProgress(true);

        const onSuccess = (replaceExist) => {
            // Если дубликат найден
            if (replaceExist) {
                onOpenEditDuplicate({
                    values: formValues,
                    onSubmit: (data) => onAddTariffScale(data),
                });

                return;
            }

            onAddTariffScale({
                values: formValues,
                replaceExist,
                onClose: onCloseEdit,
            });
        };

        // Проверяем на дубликаты
        if (editData) {
            const {tariffScaleId} = editData;
            const {
                rate,
                ...otherFormValues
            } = formValues;

            dispatch(checkExistByUpdateTariffScale({
                ...otherFormValues,
                tariffScaleId,
                onSuccess,
            }));

            return;
        }

        dispatch(checkExistTariffScale({
            ...formValues,
            onSuccess,
        }));
    };

    const onOpenEditDuplicate = ({values, onSubmit}) => {
        dispatchConfirm(
            openConfirmAction(
                "В справочнике уже существует тариф для указанного города и вида деятельности. Вы хотите перезаписать данные?",
                ({onClose}) => onSubmit({onClose, values, replaceExist: true}),
            ),
        );
    };

    const onCloseEdit = () => {
        setEditProgress(false);
        setEditData(undefined);
        setEdit(false);
    };

    const onDelete = (tariffScaleId, onClose) => {
        dispatch(deleteTariffScale({
            tariffScaleId,
            onSuccess: () => {
                onClose();
                fetchList();
                toastSuccess("Тариф был успешно удален");
            },
        }));
    };

    const onCancelConfirm = () => {
        setEditProgress(false);
        dispatchConfirm(closeConfirmAction());
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({tariffScaleId}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы действительно хотите удалить данный тариф из справочника?",
                ({onClose}) => onDelete(tariffScaleId, onClose),
            ),
        );
    };

    const onCloseCityDetect = () => {
        setOpenCityDetect(false);
    };

    const onDownloadTariffScale = () => {
        dispatch(downloadTariffScale());
    };

    const mediaButtons  = useMemo(() => {
        return {
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 0,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {onOpenEdit();},
                        children: "Добавить сведения",
                        icon: <AddIcon />,
                    },
                    visible: isAccessEdit,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {setImportOpen(true);},
                        children: "Загрузить список",
                    },
                    visible: isAccessEdit,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {onDownloadTariffScale();},
                        children: "Выгрузить",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {setOpenCityDetect(true);},
                        children: "Определить код города",
                    },
                },
            ],
        };
    }, [isAccessEdit]);

    return {
        isImport,
        isEdit,
        mediaButtons,
        editData,
        editProgress,
        setImportOpen,
        onOpenEdit,
        onCloseEdit,
        onOpenDelete,
        onCancelConfirm,
        progress,
        onConfirm,
        onSubmitEdit,
        onCloseCityDetect,
        isOpenConfirm,
        contentConfirm,
        isOpenCityDetect,
    };
};