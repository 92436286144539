import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CONTRACTOR_EVENT_LOG_GET_PAGE_ERROR,
    CONTRACTOR_EVENT_LOG_GET_PAGE_REQUEST,
    CONTRACTOR_EVENT_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/contractors/contractors-registry/contractor-card/event-log/event-log-section",
        client: "/client-adm/contractors/contractor-card/event-log/event-log-section",
    });
};

const getPageContractorEventLog = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_EVENT_LOG_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_EVENT_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_EVENT_LOG_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_EVENT_LOG_GET_PAGE_REQUEST, getPageContractorEventLog),
    ]);
}