import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {SUBSCRIBE_TO_CONTRACTORS_SUCCESS_TEXT} from "../constants/contractor";

const controller = "/contractorsubscriptions";

const CONTRACTOR_SUBSCRIPTION_REQUEST = "CONTRACTOR_SUBSCRIPTION_REQUEST";
const CONTRACTOR_SUBSCRIPTION_SUCCESS = "CONTRACTOR_SUBSCRIPTION_SUCCESS";
const CONTRACTOR_SUBSCRIPTION_ERROR = "CONTRACTOR_SUBSCRIPTION_ERROR";

const initial = {
    subscriptionProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                subscriptionProgress: true,
            };
        case CONTRACTOR_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionProgress: false,
            };
        case CONTRACTOR_SUBSCRIPTION_ERROR:
            return {
                ...state,
                subscriptionProgress: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const subscribeToContractors = payload => {
    return {
        type: CONTRACTOR_SUBSCRIPTION_REQUEST,
        payload,
    };
};

export const contractorsSubscription = state => state.contractorsSubscription;
export const contractorsSubscriptionProgressSelector = createSelector(contractorsSubscription, ({contractorsSubscription}) => contractorsSubscription);

const subscribeToContractorsSaga = function* (action) {
    try {
        const {payload} = action;
        const {errorMessage} = yield request.post(`${controller}/save`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUBSCRIPTION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(SUBSCRIBE_TO_CONTRACTORS_SUCCESS_TEXT);
        yield put({type: CONTRACTOR_SUBSCRIPTION_SUCCESS});
    } catch (error) {
        yield put({type: CONTRACTOR_SUBSCRIPTION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_SUBSCRIPTION_REQUEST, subscribeToContractorsSaga),
    ]);
}