import {
    BFF_PROMOTION_CHANNELS_ADD_ERROR,
    BFF_PROMOTION_CHANNELS_ADD_REQUEST,
    BFF_PROMOTION_CHANNELS_ADD_SUCCESS, BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS,
    BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR,
    BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS,
    UPDATE_PROMOTION_CHANNELS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    clientsWithPartners: [],
    error: null,
    fetchError: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_PROMOTION_CHANNELS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
                fetchError: false,
            };
        case BFF_PROMOTION_CHANNELS_GET_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                fetchError: false,
                list: payload.contractorPromotions,
                totalCount: payload.totalCount,
            };
        case BFF_PROMOTION_CHANNELS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                fetchError: true,
                error: payload,
            };
        case BFF_PROMOTION_CHANNELS_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_PROMOTION_CHANNELS_ADD_SUCCESS:
        case BFF_PROMOTION_CHANNELS_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_PROMOTION_CHANNELS_CLIENTS_WITH_PARTNERS_GET_PAGE_SUCCESS:
            return {
                ...state,
                clientsWithPartners: payload.clients,
            };
        case UPDATE_PROMOTION_CHANNELS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};